import React from 'react'
import he from 'he'

const HEImages = (props) => {

    const userGeneratedSrc = he.encode(props.userGeneratedSrc)

  return (
    <>
    <img src={userGeneratedSrc} alt="user's images" style={props.style} className={props.classname} onClick={props.onclickAction}></img>
    </>
  )
}

export default HEImages