import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import colors from '../../Utils/colors';
import CheerioButton from '../../Components/CheerioButton';
import images from '../../Utils/images';
import TicketComponent from './Components/TicketComponent';
import ResponseComponent from './Components/ResponseComponent';
import { useSelector } from 'react-redux';
import {
  V2AgentGet,
  downloadAllAgentAnalytics,
  downloadSingleAgentAnalytics,
  getAllAgentAnalytics,
  getAllAgentsGraphData,
  getSingleAgentAnalytics,
  getSingleAgentsGraphData,
} from '../../Services';
import SpinnerLoader from '../../Components/SpinnerLoader';
import GraphComponent from './Components/GraphComponent';
import { toast } from 'react-toastify';
import { MailCsvModal } from '../CreatorAnalyticsNew/MailCsvModal';
import { trackPages } from '../../Utils/brevo';

const AgentAnalytics = ({ excepted }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);

  const [timeFrame, setTimeFrame] = useState(99999);
  const [agentName, setAgentName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState();
  const [agentList, setAgentList] = useState({});
  const [graphTimeframe, setGraphTimeframe] = useState('1w');
  const [graphData, setGraphData] = useState();
  const [graphIsLoading, setGraphIsLoading] = useState(false);
  const [showMailModal, setShowMailModal] = useState(false);

  const analyticsTimeframes = [
    { id: 3, label: '3 hr' },
    { id: 9, label: '9 hr' },
    { id: 12, label: '12 hr' },
    { id: 24, label: '24 hr' },
    { id: 168, label: '1 week' },
    { id: 672, label: '1 month' },
    { id: 99999, label: 'Lifetime' },
  ];

  useEffect(() => {
    setIsLoading(true);
    setGraphIsLoading(true);
    getAllAgentAnalyticsAPICall();
    getAgentsAPICall();
    getGraphDataAPICall();
    trackPages('analytics_agent_analytics_page', localdata);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (agentName !== 'none' && agentName?.length > 0) {
      getSingleAgentAnalyticsAPICall();
    } else {
      getAllAgentAnalyticsAPICall();
    }
  }, [timeFrame]);

  const getAllAgentAnalyticsAPICall = () => {
    let token = authtoken;
    let data = {
      hours: timeFrame,
    };
    getAllAgentAnalytics(token, data).then((res) => {
      if (res?.flag) {
        setAnalyticsData(res?.message);
      } else {
        console.error('error occurred please try again later 1');
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    });
  };

  const getAgentsAPICall = () => {
    let token = authtoken;
    V2AgentGet(token).then((res) => {
      if (res?.flag) {
        const isObjectWithKeys = (obj) =>
          obj && typeof obj === 'object' && Object.keys(obj).length > 0;
        if (isObjectWithKeys(res?.data)) {
          setAgentList(res?.data);
        } else {
          if (!toast.isActive(1)) {
            toast.error(
              'Please create an agent and assign them conversations to see agent analytics',
              {
                position: 'top-right',
                toastId: 1,
              }
            );
          }
        }
      } else {
        if (!toast.isActive(1)) {
          toast.error(
            'Please create an agent and assign them conversations to see agent analytics',
            {
              position: 'top-right',
              toastId: 1,
            }
          );
        }
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    setGraphIsLoading(true);
    if (agentName !== 'none' && agentName?.length > 0) {
      getSingleAgentAnalyticsAPICall();
      getAgentGraphDataAPICall();
    } else {
      getAllAgentAnalyticsAPICall();
      getGraphDataAPICall();
    }
  }, [agentName]);

  useEffect(() => {
    setGraphIsLoading(true);
    if (agentName !== 'none' && agentName?.length > 0) {
      getAgentGraphDataAPICall();
    } else {
      getGraphDataAPICall();
    }
  }, [graphTimeframe]);

  const getSingleAgentAnalyticsAPICall = () => {
    let token = authtoken;
    let data = {
      _id: agentName,
      hours: timeFrame,
    };
    getSingleAgentAnalytics(token, data).then((res) => {
      if (res?.flag) {
        setAnalyticsData(res?.message);
      } else {
        toast.error('error occurred please try again later', { position: 'top-right' });
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    });
  };

  const getGraphDataAPICall = () => {
    let token = authtoken;
    let data = {
      time: graphTimeframe,
    };
    getAllAgentsGraphData(token, data).then((res) => {
      if (res?.flag) {
        setGraphData(res?.message);
      } else {
        console.error('error occurred please try again later 4');
      }
      setTimeout(() => {
        setGraphIsLoading(false);
      }, 500);
    });
  };

  const getAgentGraphDataAPICall = () => {
    let token = authtoken;
    let data = {
      _id: agentName,
      time: graphTimeframe,
    };
    getSingleAgentsGraphData(token, data).then((res) => {
      if (res?.flag) {
        setGraphData(res?.message);
      } else {
        toast.error('error occurred please try again later', { position: 'top-right' });
      }
      setTimeout(() => {
        setGraphIsLoading(false);
      }, 500);
    });
  };

  const downloadAnalyticsAPICall = (email) => {
    let token = authtoken;
    let data = {
      _id: agentName,
      hours: timeFrame,
      email: email ?? localdata?.email,
    };
    if (agentName !== 'none' && agentName?.length > 0) {
      downloadSingleAgentAnalytics(token, data).then((res) => {
        if (res?.flag) {
          toast.success('Report sent to the entered email successfully', {
            position: 'top-right',

          });
        } else {
          toast.error('Failed to send report, please try again later', {
            position: 'top-right',

          });
        }
      });
    } else {
      downloadAllAgentAnalytics(token, data).then((res) => {
        if (res?.flag) {
          toast.success('Report sent to the entered email successfully', {
            position: 'top-right',

          });
        } else {
          toast.error('Failed to send report, please try again later', {
            position: 'top-right',

          });
        }
      });
    }
  };

  return (
    <>
      <div className="agent_dashboard_container">
        {showMailModal && (
          <MailCsvModal
            show={showMailModal}
            setShow={setShowMailModal}
            agentAnalytics={true}
            agentAnalyticsReportAction={(email) => {
              downloadAnalyticsAPICall(email);
            }}
          />
        )}
        <div className="agent_dashboard_container__header_row">
          <h3>{'Agent Analytics'}</h3>
          <div className="agent_dashboard_container__header_row__right">
            <Form.Select
              type="text"
              placeholder="time"
              className="btncustom dropdown"
              style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
              value={timeFrame}
              onChange={(e) => {
                setTimeFrame(e.target.value);
              }}
            >
              {analyticsTimeframes.map((item, index) => {
                return (
                  <option
                    style={{ marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                    value={item.id}
                    key={item.id}
                  >
                    {item.label}
                  </option>
                );
              })}
            </Form.Select>
            <Form.Select
              type="text"
              placeholder="agent name"
              className="btncustom dropdown"
              style={{
                borderRadius: 8,
                fontSize: 16,
                fontWeight: 400,
                color: colors.greys01,
                marginInline: 16,
                width: 'auto',
              }}
              value={agentName}
              onChange={(e) => {
                setAgentName(e.target.value);
              }}
            >
              <option
                style={{ marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                key="blankchoice"
                hidden
                value
              >
                {'Agent name'}
              </option>
              <option
                style={{ marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                value={'none'}
                key={'none'}
              >
                {'All Agents'}
              </option>
              {Object.keys(agentList)?.length > 0 &&
                Object.values(agentList).map((item, index) => {
                  return (
                    <option
                      style={{ marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                      value={item._id}
                      key={item._id}
                    >
                      {item.name}
                    </option>
                  );
                })}
            </Form.Select>
            {!excepted && (
              <CheerioButton
                textStyle={{ fontSize: 16, fontWeight: 400, color: colors.white01 }}
                borderStyle={{ border: "none", paddingInline: 20 }}
                btnText={'Export'}
                backColor={colors.darkPurple}
                icon={images.downloadWhite}
                onclick={() => {
                  setShowMailModal(true);
                  // downloadAnalyticsAPICall();
                }}
              />
            )}
          </div>
        </div>
        {isLoading ? (
          <>
            <div
              className="d-flex flex-column justify-content-center align-items-center w-100"
              style={{ flex: 1 }}
            >
              <SpinnerLoader />
            </div>
          </>
        ) : (
          <>
            <div className="agent_dashboard_container__tickets_row">
              <TicketComponent
                count={analyticsData?.totalTickets ?? 'NA'}
                label={'Tickets Assigned'}
              />
              <TicketComponent
                count={analyticsData?.resolvedTickets ?? 'NA'}
                label={'Tickets Resolved'}
              />
              <TicketComponent
                count={analyticsData?.unresolvedTickets ?? 'NA'}
                label={'Unresolved Tickets'}
              />
              <TicketComponent
                count={analyticsData?.expiredTickets ?? 'NA'}
                label={'Expired Tickets'}
              />
            </div>
            <div className="agent_dashboard_container__response_row">
              <ResponseComponent responseData={analyticsData} />
              <GraphComponent
                graphTimeframe={graphTimeframe}
                setGraphTimeframe={setGraphTimeframe}
                graphData={graphData}
                graphIsLoading={graphIsLoading}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AgentAnalytics;
