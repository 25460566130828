import React, { useEffect, useRef, useState } from 'react'
import colors from '../../../Utils/colors'
import { ClickAwayListener } from '@mui/material'
import { useSelector } from 'react-redux';
import { getCampignCount } from '../../../Services';
import { toast } from 'react-toastify';

const AttributesModal = ({
    setOpenAttributes,
    attributeObject,
    selectedAttributeAction,
    filterValue = '',
    keyPressed = 'none',
    setKeyPressed,
}) => {

    const authtoken = useSelector((state) => state.main.auth_token);
    const mobileWidth = window.innerWidth < 700 ? true : false;

    // const defaultValues = ['name','phone','email']
    // const defaultValues = [
    //     { attribute: 'name', value: data?.user_details?.name ?? 'NA' },
    //     { attribute: 'phone', value: data?.user_details?.phone ?? 'NA' },
    //     { attribute: 'email', value: details?.email ?? 'NA' }
    // ]

    const [filteredList, setFilteredList] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const filteredRef = useRef();

    useEffect(() => {
        createFilteredList();
    }, [filterValue])

    const createFilteredList = () => {
        const defaultAttributes = ['name', 'email', 'phone'];
        defaultAttributes.forEach(attr => {
            if (!attributeObject.includes(attr)) {
                attributeObject.push(attr);
            }
        });
        let attributesFilteredList = (attributeObject && attributeObject.length > 0) ? attributeObject.filter((value) => {
            if (filterValue?.length > 0) {
                return value?.toLowerCase().startsWith(filterValue?.toLowerCase());
            } else {
                return true;
            }
        }) : [];
        if (filterValue?.length > 0 && filterValue.endsWith('%')) {
            setOpenAttributes(false)
        }
        setFilteredList(attributesFilteredList);
        setSelectedIndex(0);
    }

    useEffect(() => {
        if (keyPressed === 'Up') {
            if (selectedIndex !== 0) {
                setSelectedIndex(selectedIndex - 1)
                setKeyPressed('none');
            }
        } else if (keyPressed === 'Down') {
            console.log('down detected')
            if (selectedIndex < filteredList?.length - 1) {
                setSelectedIndex(selectedIndex + 1)
                setKeyPressed('none');
            }
        } else if (keyPressed === 'Tab') {
            // if(defaultValues.includes(filteredList[selectedIndex]?.item)){
            if (filteredList[selectedIndex]) {
                selectedAttributeAction(filteredList[selectedIndex])
            } else {
                selectedAttributeAction(filteredList[selectedIndex]?.length > 0 ? filteredList[selectedIndex] : 'NA')
            }
            setKeyPressed('none');
        }
    }, [keyPressed])

    useEffect(() => {
        if (selectedIndex >= 0 && filteredList?.length > 0) {
            const element = filteredRef.current.querySelector(`[data-index="${selectedIndex}"]`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
            }
        }
    }, [selectedIndex])

    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <>
            <ClickAwayListener onClickAway={() => { setOpenAttributes(false) }}>
                <div style={{
                    position: 'absolute',
                    bottom: 290,
                    zIndex: 2,
                    boxShadow: `0px 8px 8px 0px rgba(0,0,0,0.2)`,
                    height: 200,
                    width: 250,
                    backgroundColor: colors.white,
                    border: `1px solid ${colors.borderwhite}`,
                    borderRadius: 8,
                    left: !mobileWidth && '50%',
                    overflowY: 'auto',
                }}
                    className='d-flex flex-column justify-content-start align-items-start px-2 py-3 verythinscrollnew'
                >
                    {filteredList && filteredList?.length > 0 && (
                        <div className='w-100' ref={filteredRef}>
                            <>
                                {filteredList.map((item, index) => {
                                    return (
                                        <>
                                            <div
                                                key={index}
                                                data-index={index}
                                                className={`w-100 ${index == selectedIndex && 'selectedIndex'}`}>

                                                <div className='d-flex flex-row justify-content-start align-items-center w-100 my-1 px-2 py-2 onhoverattr'
                                                    style={{ cursor: 'pointer' }}
                                                    key={item}
                                                    onClick={() => {
                                                        selectedAttributeAction(item?.length > 0 ? item : 'NA')
                                                    }}
                                                >
                                                    <p style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', wordBreak: 'break-all' }}>
                                                        {`${item?.length > 0 ? item : 'NA'}`}
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </>
                        </div>
                    )}
                </div>
            </ClickAwayListener>

        </>
    )
}

export default AttributesModal