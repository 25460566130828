import React from 'react'
import { toast } from 'react-toastify'

const UploadValidator = (file, type) => {
    if (type === 'image' || type === 'IMAGE' || type === 'Image') {
        if (file?.type === 'image/jpeg' || file?.type === 'image/png') {
            if (file?.size < 5242880) {
                return true;
            } else {
                toast.error('File size limit exceeded, please check the size of file uploaded', { position: 'top-center' });
                return false;
            }
        } else {
            toast.error("Wrong file format uploaded, only JPEG and PNG formats are supported", { position: 'top-center' });
            return false;
        }
    } else if (type === 'video' || type === 'VIDEO' || type === 'Video') {
        if (file?.type === 'video/mp4' || file?.type === 'video/3gpp') {
            if (file?.size < 16777216) {
                return true;
            } else {
                toast.error('File size limit exceeded, please check the size of file uploaded', { position: 'top-center' });
                return false;
            }
        } else {
            toast.error("Wrong file format uploaded, only MP4 and 3GP formats are supported", { position: 'top-center' });
            return false;
        }
    } else if (type === 'document' || type === 'DOCUMENT' || type === 'Document') {
        if (file?.type === 'text/csv' || file?.type === 'application/msword' || file?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file?.type === 'application/pdf' || file?.type === 'application/vnd.ms-powerpoint' || file?.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || file?.type === 'application/vnd.ms-excel' || file?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            if (file?.size < 104857600) {
                return true;
            } else {
                toast.error('File size limit exceeded, please check the size of file uploaded', { position: 'top-center' });
                return false;
            }
        } else {
            toast.error("Wrong file format uploaded, only CSV, DOC, DOCX, PDF, PPT, PPTX, XLS and XLSX formats are supported", { position: 'top-center' });
            return false;
        }
    }
}

export default UploadValidator