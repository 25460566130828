import React from 'react'
import colors from '../../../Utils/colors'

const ResponseItem = ({ count, label, countStyle }) => {
    return(
        <div className='response_item'>
            <p className='response_item__count' style={{...countStyle}}>
                {count}
            </p>
            <p className='response_item__label'>
                {label}
            </p>
        </div>
    )
}

const ResponseComponent = ({ responseData }) => {
  return (
    <>
    <div className='response_component_container'>
        <div className='response_component_div shadow_small'>
            <h3>
                {'Response Performance'}
            </h3>
            <div className='response_component_div__row'>
                <ResponseItem 
                    count={responseData?.avgFrt ?? 'NA'}
                    label={'Avg First Response Time'}
                />
                <ResponseItem 
                    count={responseData?.avgResponseTime ?? 'NA'}
                    label={'Avg Response Time'}
                />
            </div>
            <div className='response_component_div__row'>
                <ResponseItem 
                    count={responseData?.avgResolutionTime ?? 'NA'}
                    label={'Avg Resolution Time'}
                />
                <ResponseItem 
                    count={responseData?.frtBreachedCount ?? 'NA'}
                    label={'FRT Exceeded Count'}
                />
            </div>
            <div className='response_component_div__row'>
                <ResponseItem 
                    count={responseData?.unrepliedTickets ?? 'NA'}
                    label={'Unreplied Tickets'}
                    countStyle={{color:colors.error03}}
                />
                <ResponseItem 
                    count={responseData?.pendingTickets ?? 'NA'}
                    label={'Pending Tickets'}
                    countStyle={{color:colors.error03}}
                />
            </div>
        </div>
    </div>
    </>
  )
}

export default ResponseComponent