import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import { useDispatch, useSelector } from 'react-redux';
import { getUserApi, setDefaultWalletCurrency } from '../../../Services';
import { toast } from 'react-toastify';

export const DefaultCurrencyModal = ({ setShowDefaultCurrencyModal, showCurrencyDefaultModal }) => {
  const [currency, setCurrency] = useState(null);
  const authtoken = useSelector((state) => state.main.auth_token);
  const dispatch = useDispatch();

  const getuserapicall = () => {
    let token = authtoken;
    getUserApi(token).then((res) => {
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
      } else {
        console.log('error get user api ====> ', res);
      }
    });
  };

  const handleClose = () => {
    setShowDefaultCurrencyModal(false);
  };
  const setDefaultCurrencyApiCall = () => {
    const data = {
      currency: currency,
    };
    setDefaultWalletCurrency(authtoken, data).then((res) => {
      if (res?.flag) {
        handleClose();
        getuserapicall();
      } else {
        toast.error(res?.message, { position: 'top-center' });
      }
    });
  };
  return (
    <div>
      <Modal show={showCurrencyDefaultModal} onHide={handleClose} className="custom-modal">
        <Modal.Body style={{}}>
          <div>
            <div className="d-flex justify-content-between " style={{ marginBottom: '2%' }}>
              <p style={{ fontWeight: '700', fontSize: '16px' }}>Default Currency</p>

              <div className="d-flex flex-row">
                <img
                  src={images.CMClose}
                  onClick={() => handleClose()}
                  style={{
                    cursor: 'pointer',
                    height: 22,
                    width: 22,
                    marginInlineEnd: 8,
                  }}
                ></img>
              </div>
            </div>
            <hr></hr>
            <div style={{ marginBlockEnd: '10px' }}>
              Select your default wallet currency for message billing & topup recharges.
            </div>
            <div
              style={{
                fontWeight: '600',
                padding: '10px',
                backgroundColor: '#F5F5F5',
                borderRadius: '10px',
                marginBottom: '10px',
              }}
            >
              <label>
                <input
                  type="radio"
                  name="currency"
                  value="INR"
                  onClick={() => setCurrency('INR')}
                />{' '}
                INR (₹ Ruppee)
              </label>
            </div>
            <div
              style={{
                fontWeight: '600',
                padding: '10px',
                backgroundColor: '#F5F5F5',
                borderRadius: '10px',
              }}
            >
              <label>
                <input
                  type="radio"
                  name="currency"
                  value="USD"
                  onClick={() => setCurrency('USD')}
                />{' '}
                USD ($ Dollars)
              </label>
            </div>
            <div className="d-flex justify-content-end" style={{ marginTop: '5%' }}>
              <CheerioButton
                borderStyle={{
                  border: 'none',
                  width: '30%',
                  borderRadius: 8,
                }}
                textStyle={{
                  fontWeight: 600,
                  color: 'white',
                }}
                btnText={'Submit'}
                backColor={'#8E53F7'}
                disabled={!currency}
                onclick={() => {
                  setDefaultCurrencyApiCall();
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
