import React, { useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import '../index.scss';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CheerioButton from '../../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import { V2AgentCreate } from '../../../Services';
import { toast } from 'react-toastify';
import { BiError } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const ValidatePassword = (password) => {
  if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}/.test(password)) {
    return true;
  }
  toast(
    'You need to enter a password of minimum 8 characters with one uppercase and lowercase letter, one number, and one special character',
    { postion: 'top-center' }
  );
  return false;
};

const InputItems = ({ title, type, placeholder, value, onChange, name, autocomplete }) => {
  return (
    <>
      <InputGroup className="" style={{ width: '100%', marginBlock: 4 }}>
        <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black, marginBlock: 4 }}>
          {title}
        </Form.Label>
        <Form.Control
          type={type}
          name={name}
          autocomplete={autocomplete}
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

const SwitchItems = ({ title, setField, Checked, fieldName }) => {
  return (
    <>
      <div className="Row w-100" style={{ marginBlock: 4 }}>
        <p style={{ padding: 0, margin: 0, fontSize: 14, fontWeight: 400, color: colors.black }}>
          {title}
        </p>
        <Form.Check
          type="switch"
          className="btncustom"
          defaultChecked={Checked}
          onChange={(e) => setField(fieldName, e.target.checked)}
        />
      </div>
    </>
  );
};

const AddPeopleModal = ({ memberLimit, setIsAPModalOpen, tableData }) => {
  const agentCount = Object.keys(tableData)?.length;
  const navigate = useNavigate();
  const PermissionsLabels = [
    { title: 'Contacts', field: 'contact' },
    { title: 'Campaigns', field: 'campaign' },
    { title: 'Analytics', field: 'analytics' },
    { title: 'Chat Replies', field: 'chatReplies' },
    { title: 'Access all chats', field: 'allChats' },
    { title: 'Email', field: 'email' },
    { title: 'Integrations', field: 'integrations' },
    { title: 'Automations', field: 'automation' },
  ];
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [Mobile, setMobile] = useState('');
  const [Role, setRole] = useState('');
  const [Password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);

  const [DropDown, setDropDown] = useState(false);

  const authtoken = useSelector((state) => state.main.auth_token);
  const localData = useSelector((state) => state.main.localdata);

  const agentLimit = localData?.addOnSubscriptionActive ? 12 : 2;

  const [permissions, setPermissions] = useState({
    contact: false,
    campaign: false,
    analytics: false,
    chatReplies: false,
    email: false,
    integrations: false,
    automation: false,
  });

  const setField = (field, value) => {
    console.log('setField ===> ', field, value);
    setPermissions({
      ...permissions,
      [field]: value,
    });
  };

  const TickItems = ({ text }) => {
    return (
      <>
        <div className="Row" style={{ marginBlock: 8, justifyContent: 'flex-start', width: '70%' }}>
          <img
            src={images.SUCCESSFULL}
            style={{ height: 20, width: 20, objectFit: 'contain' }}
          ></img>
          <p
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: colors.greys04,
              padding: 0,
              margin: 0,
              marginInlineStart: 16,
            }}
          >
            {text}
          </p>
        </div>
      </>
    );
  };

  const CreateAgentV2APICall = () => {
    let data = {
      name: Name,
      email: Email.toLowerCase(),
      phone: Mobile,
      role: Role,
      permission: permissions,
      status: 1,
      password: Password,
    };
    let token = authtoken;
    console.log('data being sent ===> ', data);
    if (ValidatePassword(Password)) {
      V2AgentCreate(data, token).then((res) => {
        if (res?.flag) {
          console.log('success response ===> ', res);
          setSuccess(true);
        } else {
          toast.error(res?.message, { position: 'top-center', zIndex: 5 });
        }
      });
    }
  };

  return (
    <>
      <div className="DarkBg" onClick={() => setIsAPModalOpen(false)}>
        <div className="Center">
          <div className={'APModalContainer Column'} onClick={(e) => e.stopPropagation()}>
            <div className={'APMHeader Row'}>
              <div className="d-flex flex-row justify-content-start align-items-center">
                {agentCount >= agentLimit && localData?.isAppsumoUser && (
                  <BiError size={24} color={colors.error03} style={{ marginInlineEnd: 6 }} />
                )}
                <h2
                  style={{
                    fontSize: 24,
                    fontWeight: 700,
                    color:
                      agentCount >= agentLimit && localData?.isAppsumoUser
                        ? colors.error03
                        : colors.black,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {'Invite members to your account'}
                </h2>
              </div>

              <img
                src={images.CMClose}
                style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                onClick={() => {
                  setIsAPModalOpen(false);
                }}
              ></img>
            </div>

            {success ? (
              <>
                <img
                  src={images.CATSuccess}
                  style={{ height: 160, width: 160, objectFit: 'contain', marginBlock: 20 }}
                ></img>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 16,
                    fontWeight: 700,
                    color: colors.black,
                    textAlign: 'center',
                    width: '60%',
                  }}
                >
                  {'Invitation sent'}
                </p>
                <CheerioButton
                  borderStyle={{ borderWidth: 0, width: '100%', marginBlockStart: 16 }}
                  textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
                  btnText={'Close'}
                  backColor={colors.black}
                  onclick={() => {
                    setIsAPModalOpen(false);
                  }}
                // icon={images.MPPlus}
                // iconStyle={{height}}
                // disabled={builderReady ? false : true}
                />
              </>
            ) : (
              <>
                <div className="w-100 Row" style={{ height: 'calc( 100% - 60px )' }}>
                  <div
                    className="w-50 h-100 verythinscrollnew d-flex flex-column justify-content-start align-items-center"
                    style={{ overflowY: 'scroll', paddingInline: 16 }}
                  >
                    {agentCount >= agentLimit && localData?.isAppsumoUser && (
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: colors.error03,
                          padding: 0,
                          margin: 0,
                          marginBlockEnd: 12,
                        }}
                      >
                        {
                          'You have reached your maximum agent invitation limit. To add more agents please '
                        }
                        <span
                          style={{
                            color: colors.linkblue,
                            textDecorationLine: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            navigate('/addons');
                          }}
                        >
                          {'upgrade'}
                        </span>
                      </p>
                    )}
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.greys04,
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {
                        'Invite/ add your all team members to access your WhatsApp business account at once. You can set restrictions for each member feature by feature.'
                      }
                    </p>

                    <InputItems
                      title={'Name'}
                      type={'text'}
                      placeholder={'Enter name here'}
                      value={Name}
                      autocomplete={'new-name'}
                      onChange={setName}
                    />
                    <InputItems
                      title={'Email ID'}
                      type={'email'}
                      placeholder={'Enter email here'}
                      value={Email}
                      autocomplete={'new-email'}
                      name={'AgentEmail'}
                      onChange={setEmail}
                    />
                    <InputItems
                      title={'Phone No'}
                      type={'tel'}
                      name={'AgentPhone'}
                      autocomplete={'new-mobile'}
                      placeholder={'Enter number here'}
                      value={Mobile}
                      onChange={setMobile}
                    />
                    <InputItems
                      title={'Password'}
                      type={'password'}
                      name={'AgentPassword'}
                      autocomplete={'new-password'}
                      placeholder={'Enter password here'}
                      value={Password}
                      onChange={setPassword}
                    />

                    <InputGroup className="" style={{ width: '100%', marginBlock: 4 }}>
                      <Form.Label
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: colors.black,
                          marginBlock: 4,
                        }}
                      >
                        {'Role'}
                      </Form.Label>
                      <Form.Select
                        type="text"
                        placeholder="Select Role"
                        className="w-100 btncustom"
                        style={{
                          borderRadius: 8,
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.greys01,
                        }}
                        value={Role}
                        onChange={(e) => {
                          setRole(e.target.value);
                          setDropDown(true);
                        }}
                      >
                        <option
                          style={{ width: '100%', marginBlock: 4, borderRadius: 8 }}
                          key="blankchoice"
                          hidden
                          value
                        >
                          {'Choose Role'}
                        </option>
                        {/* <option
                                        style={{width: '100%',marginTop: 4,marginBottom: 4,borderRadius: 8}}
                                        value={'owner'}
                                        key={`key=Owner`}
                                    >
                                        {'Owner'}
                                    </option>
                                    <option
                                        style={{width: '100%',marginTop: 4,marginBottom: 4,borderRadius: 8}}
                                        value={'admin'}
                                        key={`key=Admin`}
                                    >
                                        {'Admin'}
                                    </option> */}
                        <option
                          style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                          value={'agent'}
                          key={`key=Agent`}
                        >
                          {'Agent'}
                        </option>
                      </Form.Select>
                    </InputGroup>

                    <div className="Row w-100" style={{ marginBlock: 16 }}>
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 16,
                          fontWeight: 600,
                          color: colors.black,
                        }}
                      >
                        {'Manage Permissions'}
                      </p>
                      <img
                        src={images.DownArrowTip}
                        style={{
                          height: 20,
                          width: 20,
                          objectFit: 'contain',
                          transform: DropDown ? 'rotate( 180deg )' : '',
                          transition: 'all 0.5s ease-in-out',
                        }}
                        onClick={() => {
                          setDropDown(!DropDown);
                        }}
                      ></img>
                    </div>

                    {DropDown && (
                      <>
                        <div className="Column w-100" style={{ justifyContent: 'flex-start' }}>
                          <ul style={{ paddingInlineStart: 16 }}>
                            <li style={{ fontSize: 14, fontWeight: 400, color: colors.greys04 }}>
                              {
                                'Team members will not be able to access the features with no permissions'
                              }
                            </li>
                            <li style={{ fontSize: 14, fontWeight: 400, color: colors.greys04 }}>
                              {'In order to access they can request access anytime'}
                            </li>
                            <li style={{ fontSize: 14, fontWeight: 400, color: colors.greys04 }}>
                              {'You can also add/revoke permissions anytime you want'}
                            </li>
                          </ul>
                        </div>

                        <div className="Row w-100" style={{ marginBlock: 8 }}>
                          <h3
                            style={{
                              padding: 0,
                              margin: 0,
                              fontSize: 16,
                              fontWeight: 600,
                              color: colors.black,
                            }}
                          >
                            {'Features'}
                          </h3>
                          <h3
                            style={{
                              padding: 0,
                              margin: 0,
                              fontSize: 16,
                              fontWeight: 600,
                              color: colors.black,
                            }}
                          >
                            {'Access'}
                          </h3>
                        </div>

                        {PermissionsLabels.map((item, index) => {
                          return (
                            <SwitchItems
                              title={item.title}
                              setField={setField}
                              Checked={permissions?.[item.field]}
                              fieldName={item.field}
                            />
                          );
                        })}
                      </>
                    )}

                    <CheerioButton
                      borderStyle={{ borderWidth: 0, width: '100%', marginBlock: 8 }}
                      textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
                      btnText={'Share Invitation'}
                      backColor={colors.primary03}
                      onclick={() => {
                        console.log('invite button click');
                        CreateAgentV2APICall();
                      }}
                      disabled={agentCount >= agentLimit && localData?.isAppsumoUser && localData?._id !== '664cf714f3e3a40203785e71' ? true : false}
                    // icon={images.MPPlus}
                    // iconStyle={{height}}
                    // disabled={builderReady ? false : true}
                    />
                  </div>

                  <div
                    className="w-50 h-100 verythinscrollnew d-flex flex-column justify-content-start align-items-center"
                    style={{ overflowY: 'auto', paddingInline: 16 }}
                  >
                    <img
                      src={images.InviteModalPic}
                      style={{ width: '90%', objectFit: 'contain', marginBlock: 16 }}
                    ></img>
                    <TickItems text={`You can invite maximum ${memberLimit} members`} />
                    <TickItems text={'Set permissions for every member'} />
                    <TickItems text={'Manage customers with your team'} />
                    <TickItems text={'Plan, schedule, launch campaigns'} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPeopleModal;
