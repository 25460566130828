import { Route, Routes, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { styledSx } from '../Tags/variableDependencies';
import { ContactsDepsColsCustom } from '../ContactsAll/variableDependencies';
import { timeStampToDated } from '../Labels/variableDependencies';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { fetchLabelContactsV2API, fetchTagsContactAPI } from '../../../Services';
import EditSidebar from '../EditSidebar';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {getLocaltime} from '../../../Utils/localdate'
import SpinnerLoader from '../../../Components/SpinnerLoader';
const EditableTable = ({ header, search, userAttributes, excepted }) => {
  const FilterValues = ['name', 'phone', 'email'];

  const [userAttributesColumn, setUserAttributesColumn] = useState([]);
  const { reqId, type } = useParams();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const authtoken = useSelector((state) => state.main.auth_token);

  useEffect(() => {
    // console.log("user attri ---> ",userAttributes)
    if (userAttributes?.length > 0) {
      setUserAttributesColumn(() => {
        let temp = [];
        userAttributes
          .filter((item) => item?.attribute && !FilterValues.includes(item?.attribute))
          .map((item) => {
            temp.push({
              field: item?.attribute + `AAA` + uuidv4(),
              headerName: item?.attribute,
              width: 200,
              align: 'center',
              headerAlign: 'center',
            });
          });
        return temp;
      });
    }
  }, [userAttributes]);

  function mergeArrays(array1, array2) {
    let mergedArray = array1.slice();
    let insertIndex = mergedArray.length - 1;
    mergedArray.splice(insertIndex, 0, ...array2);
    return mergedArray;
  }

  let pageData = {
    _id: reqId,
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
    search: search,
  };
  const trigger = useSelector((state) => state.labelRedux.triggerState);
  let metaData;
  if (type === 'label') {
    metaData = useQuery(['contactAllDiff', trigger, paginationModel, search], () => {
      return fetchLabelContactsV2API(pageData, authtoken);
    });
  } else if (type === 'tags') {
    metaData = useQuery(['contactLabelDiff', trigger, paginationModel, search], () => {
      return fetchTagsContactAPI(pageData, authtoken);
    });
  }
  const { data, isLoading, isError } = metaData;

  if (isLoading) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center w-100"
        style={{ flex: 1 }} >
        <SpinnerLoader></SpinnerLoader>
      </div>
    );
  }
  let row_data = [];
  data.data.docs.forEach((vls) => {
    let AttrRowObject =
      userAttributesColumn?.length > 0 &&
      userAttributesColumn.reduce((obj, item) => {
        let varName = String(item?.field).split('AAA')[0];
        obj[item?.field] = vls?.customData?.[varName] ? vls?.customData?.[varName] : '--';
        return obj;
      }, {});

    row_data.push(
      Object.keys(AttrRowObject).length > 0
        ? Object.assign(
          {},
          {
            id: vls._id,
            name: vls.name,
            addedOn: getLocaltime(vls.createdAt),
            updatedOn: getLocaltime(vls.updatedAt),
            phno: vls.mobile,
            email: vls.email,
            actions: vls,
            version: vls.version,
            visible: !excepted,
          },
          AttrRowObject
        )
        : {
          id: vls._id,
          name: vls.name,
          addedOn: getLocaltime(vls.createdAt),
          updatedOn: getLocaltime(vls.updatedAt),
          phno: vls.mobile,
          email: vls.email,
          actions: vls,
          version: vls.version,
          visible: !excepted,
        }
    );
  });
  return (
    <>
      <DataGrid
        columns={
          userAttributesColumn?.length > 0
            ? mergeArrays(ContactsDepsColsCustom, userAttributesColumn)
            : ContactsDepsColsCustom
        }
        rows={row_data}
        sx={styledSx}
        paginationModel={paginationModel}
        pageSizeOptions={[25, 50, 100]}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        rowCount={data.data.totalDocs}
      />
      <Routes>
        <Route path={'/edit'} element={<EditSidebar />} />
      </Routes>
    </>
  );
};
export default EditableTable;
