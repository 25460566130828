import React, { useState, useEffect } from 'react';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const getLastNumber = (plan_id) => {
  if (!plan_id) return null;
  const match = plan_id.match(/\d+$/);
  return match ? Number(match[0]) : null;
};

const PlanDetails = () => {
  const localdata = useSelector((state) => state.main.localdata);
  const [tier, setTier] = useState(null);
  useEffect(() => {
    if (localdata?.premium_details) {
      const tierNumber = getLastNumber(localdata.premium_details.appsumo?.plan_id);
      setTier(tierNumber);
    }
  }, [localdata]);
  const [workshops, setWorkshops] = useState(0);
  const [emailCredits, setEmailCredits] = useState(0);
  const [members, setMembers] = useState(0);
  const [workflowConnectors, setWorkflowConnectors] = useState(0);
  const navigate = useNavigate();
  const listItemStyle = {
    fontWeight: '400',
    fontSize: '16px',
    // lineHeight: '21.24px',
    // marginBottom: '6%',
    marginBlockEnd: 16,
  };

  const listItemStyle2 = {
    fontWeight: '400',
    fontSize: '16px',
    // lineHeight: '21.24px',
    // marginBottom: '6%',
    marginBlockEnd: 16,
    color: '#666666',
  };
  useEffect(() => {
    if (tier === 1) {
      setWorkshops(1);
      setMembers(1);
      setEmailCredits(20000);
      setWorkflowConnectors(50);
    } else if (tier === 2) {
      setWorkshops(2);
      setMembers(2);
      setEmailCredits(50000);
      setWorkflowConnectors(50);
    } else if (tier === 3) {
      setWorkshops(4);
      setMembers(3);
      setEmailCredits(100000);
      setWorkflowConnectors(50);
    } else if (tier === 4) {
      setWorkshops(6);
      setMembers(4);
      setEmailCredits(200000);
      setWorkflowConnectors(50);
    } else if (tier === 5) {
      setWorkshops(10);
      setMembers(5);
      setEmailCredits(40000);
      setWorkflowConnectors(50);
    }
  }, [tier]);
  useEffect(() => {
    if (localdata?.addOnSubscriptionActive) {
      setWorkflowConnectors((prev) => prev + 200);
      setMembers((prev) => prev + 10);
      setWorkshops((prev) => prev + 5);
    }
  }, [localdata?.addOnSubscriptionActive, tier]);
  useEffect(() => {
    if (localdata?.emailSubscriptionActive) {
      setEmailCredits((prev) => prev + 100000);
    }
  }, [localdata?.emailSubscriptionActive, tier]);

  const planBenefits1 = [
    `${workshops} Workspace`,
    `${emailCredits} Email credits per month`,
    'Manage unlimited contacts',
    `Team Members (Admin + ${members} Agent)`,
    `${workflowConnectors} workflow connectors`,
    'Official WABA Cloud API Setup',
    'Custom Template Builder (Email & WhatsApp)',
    // '1 Workspace',
    // 'Official WABA Cloud API Setup',
    // 'Manage unlimited contacts',
    // 'Custom Template Builder (Email & WhatsApp)',
    // 'Analytics & Reports (Basic)',
    // 'Team Members (Admin + 1 Agent)',
    // 'WhatsApp Chat Inbox & Verification',
  ];

  const planBenefits2 = [
    'Campaign Link Tracking',
    'WhatsApp Chatbot Builder',
    'Dynamic Workflow Automation',
    'Ecommerce Integration (Shopify, WooCommerce)',
    'Developer API Library',
    'WhatsApp Chat Inbox & Verification',
    'Analytics & Reports (Basic)',
    // 'Max of 50 nodes across all workflows',
    // 'Campaign Link Tracking',
    // 'WhatsApp Chatbot Builder',
    // 'Dynamic Workflow Automation',
    // 'Ecommerce Integration (Shopify, WooCommerce)',
    // 'Developer API Library',
    // '20000 Emails per month',
  ];

  const NotIncluded = [
    'Growth consultation calls',
    'WhatsApp balance',
    'SMS balance',
    'Custom integrations',
  ];

  const handleClick = () => {
    window.open(
      'https://www.cheerio.in/post/how-to-get-onboarding-support-for-your-cheerio-premium-account'
    );
  };

  return (
    <>
      <div className={'PeopleHeader Row'}>
        <p className={'PeopleHeaderText'}>{`Cheerio AppSumo Tier ${tier}`}</p>
        <CheerioButton
          btnText={'Buy Add Ons'}
          textStyle={{ color: colors.white, fontSize: '14px' }}
          backColor={colors.primary03}
          borderStyle={{
            width: '11vw',
            border: '1px solid white',
            borderRadius: '8px',
            marginRight: '20px',
          }}
          onclick={() => {
            navigate('/addons');
          }}
        />
      </div>
      <div
        style={{
          // fontFamily: 'Segoe UI',
          fontWeight: 700,
          fontSize: '18px',
          margin: '7px',
          paddingRight: '85%',
          color: '#666666',
        }}
      >
        What's included
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: '2%',
          paddingRight: '10%',
        }}
      >
        <div>
          <div>
            {planBenefits1.map((feature, index) => (
              <div key={index} style={listItemStyle}>
                <img
                  src={images.tickLogo}
                  style={{
                    width: '21.17px',
                    height: '21.24px',
                  }}
                />
                {feature}
              </div>
            ))}
          </div>
        </div>
        <div>
          <div>
            {planBenefits2.map((feature, index) => (
              <div key={index} style={listItemStyle}>
                <img
                  src={images.tickLogo}
                  style={{
                    width: '21.17px',
                    height: '21.24px',
                  }}
                />
                {feature}
              </div>
            ))}
          </div>
        </div>
        <div>
          <div style={listItemStyle}>
            <img
              src={images.tickLogo}
              style={{
                width: '21.17px',
                height: '21.24px',
              }}
            />
            Personalized Onboarding call
            <span
              style={{
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer',
                marginLeft: '5px',
              }}
              onClick={handleClick}
            >
              (Book Now)
            </span>
          </div>

          <div style={listItemStyle}>
            <img
              src={images.tickLogo}
              style={{
                width: '21.17px',
                height: '21.24px',
              }}
            />
            Email Support (Support SLA: 2 working days)
          </div>
        </div>
      </div>
      <div
        style={{
          // fontFamily: 'Segoe UI',
          fontWeight: 700,
          fontSize: '18px',
          marginTop: '3%',
          paddingRight: '82.5%',
          marginBottom: '1%',
          color: '#666666',
        }}
      >
        What's not included
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', marginLeft: '4%' }}
      >
        <div>
          <div>
            {NotIncluded.map((feature, index) => (
              <div key={index} style={listItemStyle2}>
                <img
                  src={images.crossLogo}
                  style={{
                    width: '14.74px',
                    height: '14.16px',
                    marginRight: '4px',
                  }}
                />
                {feature}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanDetails;
