import React, { useEffect, useRef, useState } from 'react';
import { SMSPreview, SpinnerComponent, WAV2Preview } from './NewComponents';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import colors from '../../../Utils/colors';
import { useSelector } from 'react-redux';

const LIMIT = 300;

const SmsDetails = ({ item, bodyPar, setBodyPar, setExceedLength }) => {
  console.log('yyyyyy', item);
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);

  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [count, setCount] = useState();
  const [inputFields, setInputField] = useState([]);
  const [modifiedBody, setModifiedBody] = useState();

  useEffect(() => {
    setIsLoadingDetails(true);
    // checkRegex();
    LoadDynamicFields();
  }, []);

  useEffect(() => {
    if (modifiedBody && modifiedBody.length > LIMIT) {
      setExceedLength(false);
    } else {
      setExceedLength(true);
    }
  }, [modifiedBody]);

  const BodyElement = item?.templateData?.MessageTemplate;
  console.log('BEEE', BodyElement);

  const LoadDynamicFields = () => {
    const variableRegex = /\{#var#\}/g;
    if (variableRegex.test(BodyElement)) {
      console.log('text =======>', BodyElement);
      const count = BodyElement?.match(variableRegex).length;
      console.log('count ======>', count);
      setCount(count);
    }

    setIsLoadingDetails(false);
  };

  useEffect(() => {
    if (count > 0) {
      let demoObj = [];
      console.log('body par exists ', bodyPar);
      console.log('body par exists ', bodyPar[1]);
      for (let i = 0; i < count; i++) {
        console.log(' for loop count ======>', i);

        demoObj.push({ variable: bodyPar[i] ? bodyPar[i] : `{{${i + 1}}}` });
      }
      setInputField(demoObj);
    }
  }, [count]);

  useEffect(() => {
    if (inputFields?.length > 0) {
      console.log(' input fields ====> ', inputFields);
      modifyBodyElement();
    }
  }, [inputFields]);

  const handleChange = (index, e) => {
    const tempObj = [...inputFields];
    tempObj[index][e.target.name] = e.target.value;
    setInputField(tempObj);
  };

  const modifyBodyElement = () => {
    let newtext = BodyElement;
    let newArr = inputFields.map((a) => a.variable);

    setBodyPar(newArr);

    var count = 0;
    console.log('7777777777777777', newArr);
    const variableRegex = /\{#var#\}/g;
    newtext = newtext.replace(variableRegex, function ($0) {
      if (count === newArr.length) count = 0;
      return newArr[count++];
    });
    setModifiedBody(newtext);
  };
  return (
    <>
      {isLoadingDetails ? (
        <>
          <SpinnerComponent />
        </>
      ) : (
        <>
          <div
            className="ann_details_container"
            // className="w-100 d-flex flex-row justify-content-between align-items-center"
            // style={{ height: 'calc( 100% - 80px )' }}
          >
            <div
              className="ann_details_container__form"
              // className="d-flex flex-column justify-content-start align-items-center verythinscrollnew h-100"
              // style={{ width: '60%', overflowY: 'scroll', paddingInline: 16 }}
            >
              {inputFields &&
                inputFields.map((item, index) => {
                  let availableLength = 0;
                  if (modifiedBody) {
                    availableLength = LIMIT - modifiedBody.length;
                  } else {
                    availableLength = LIMIT - BodyElement.length;
                  }
                  return (
                    <>
                      <InputGroup className="mb-3" key={index}>
                        {index === 0 && (
                          <p style={{ fontSize: '10px', marginBottom: '10px' }}>
                            Available Length:-{availableLength < 0 ? '0' : availableLength}
                          </p>
                        )}{' '}
                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                          <Form.Label
                            style={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                          >{`Variable ${index + 1}`}</Form.Label>
                          <p style={{ fontSize: '10px', marginTop: '5px' }}>
                            {item.variable.length}
                          </p>
                        </div>
                        <Form.Control
                          type="text"
                          placeholder="Enter here"
                          className="w-100 btncustom"
                          name="variable"
                          style={{ borderRadius: 8 }}
                          value={item.variable}
                          onChange={(e) => {
                            handleChange(index, e);
                          }}
                        />
                      </InputGroup>
                    </>
                  );
                })}
            </div>
            <div
              className="ann_details_container__preview"
              // className="d-flex flex-column justify-content-start align-items-center verythinscrollnewinv h-100"
              // style={{ width: '40%', overflowY: 'auto', paddingInline: 16, paddingBlockStart: 30 }}
            >
              <SMSPreview BodyElement={modifiedBody ? modifiedBody : BodyElement} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SmsDetails;
