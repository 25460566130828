import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import {
    createInvoice,
    createPaymentSubscription,
    newSignUp,
    paymentOrder,
    sendAgentOTPV2,
    sendEmailOTPV2,
    sendOTP_V3,
    sendUserOTPV2,
    userLogin,

} from '../../../Services';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import { TextField } from '@mui/material';
import { Modal, Button, Form, Card, ListGroup } from 'react-bootstrap';
import PremiumSucess from './PremiumSuccess';


const mobileWidth = window.innerWidth < 700 ? true : false;

const PremiumModal = ({ setShowPremiumModal, showPremiumModal, planName, planDuration, currency }) => {
    const auth_token = useSelector((state) => state.main?.auth_token);

    const planDetails = [
        // {name: "Basic", duration: "Quarterly", priceINR: '4,500', priceUSD: '56', INRID: 'plan_OBsrkWdl90hiYZ', USDID: 'plan_OSAQhGj7slsjPf'},
        {
            name: 'Basic',
            duration: 'Quarterly',
            priceINR: '4,500',
            priceUSD: '56',
            INRID: 'plan_OyCNaABf59WSMO',
            USDID: 'plan_OSAQhGj7slsjPf',
        },
        {
            name: 'Startup',
            duration: 'Quarterly',
            priceINR: '9,000',
            priceUSD: '113',
            INRID: 'plan_OyCPF8T7TiRc6A',
            USDID: 'plan_OSASamBeIYDFtB',
        },
        {
            name: 'Growth',
            duration: 'Quarterly',
            priceINR: '15,000',
            priceUSD: '188',
            INRID: 'plan_OyCQApFJFdJTqI',
            USDID: 'plan_OSATjFb7Apvowu',
        },
        {
            name: 'Pro',
            duration: 'Quarterly',
            priceINR: '20,000',
            priceUSD: '250',
            INRID: 'plan_OyCR3y5LHeMQKL',
            USDID: 'plan_OSAUdRUvleKmf6',
        },
        {
            name: 'Enterprise',
            duration: 'Quarterly',
            priceINR: '70,000',
            priceUSD: '875',
            INRID: 'plan_OyCRqbjVLaR3QG',
            USDID: 'plan_OSAVJLbMULJTU4',
        },
        {
            name: 'Basic',
            duration: 'Half-Yearly',
            priceINR: '7,500',
            priceUSD: '94',
            INRID: 'plan_OyCTRCXBKTIvg0',
            USDID: 'plan_OTJfFLMqpOMMQW',
        },
        {
            name: 'Startup',
            duration: 'Half-Yearly',
            priceINR: '15,000',
            priceUSD: '188',
            INRID: 'plan_OyCU80cFjVWIiL',
            USDID: 'plan_OTJigtGioONAId',
        },
        {
            name: 'Growth',
            duration: 'Half-Yearly',
            priceINR: '21,000',
            priceUSD: '263',
            INRID: 'plan_OyCV8j9XXqFB5z',
            USDID: 'plan_OTJlRB9Y4Cx8g6',
        },
        {
            name: 'Pro',
            duration: 'Half-Yearly',
            priceINR: '27,000',
            priceUSD: '338',
            INRID: 'plan_OyCW5dwoi4RNyw',
            USDID: 'plan_OTJnFdKl9FkqTY',
        },
        {
            name: 'Enterprise',
            duration: 'Half-Yearly',
            priceINR: '120,000',
            priceUSD: '1,500',
            INRID: 'plan_OyCWsK02DtUGGj',
            USDID: 'plan_OTJvKy4q6aMBqm',
        },
        {
            name: 'Basic',
            duration: 'Yearly',
            priceINR: '12,000',
            priceUSD: '150',
            INRID: 'plan_OyCXxb0OQKcuF2',
            USDID: 'plan_OTK3czSgCcIbtT',
        },
        {
            name: 'Startup',
            duration: 'Yearly',
            priceINR: '24,000',
            priceUSD: '300',
            INRID: 'plan_OyCa7f7nLYgGpZ',
            USDID: 'plan_OTK4xBePPvCsgZ',
        },
        {
            name: 'Growth',
            duration: 'Yearly',
            priceINR: '36,000',
            priceUSD: '450',
            INRID: 'plan_OyCac2ehZK1zsZ',
            USDID: 'plan_OTK7MlmvW0JCRR',
        },
        {
            name: 'Pro',
            duration: 'Yearly',
            priceINR: '48,000',
            priceUSD: '600',
            INRID: 'plan_OyCbEeFG2gayDo',
            USDID: 'plan_OTK9hXOmFvYmoo',
        },
        {
            name: 'Enterprise',
            duration: 'Yearly',
            priceINR: '200,000',
            priceUSD: '2,500',
            INRID: 'plan_OyCc4AbjOyMslf',
            USDID: 'plan_OTKBhayTDmzmth',
        },
    ];

    const [authToken, setAuthToken] = useState('');

    const [selectedPlan, setSelectedPlan] = useState(null);

    const [ModalOpen, setModalOpen] = useState(false);

    const [subscriptionId, setSubscriptionId] = useState();

    // function getQueryParam(param) {
    //     let url = new URL(window.location.href);
    //     return url.searchParams.get(param);
    // }

    // useEffect(() => {
    //     // const planName = getQueryParam('planName');
    //     // const planDuration = getQueryParam('planDuration');
    //     // const currency = getQueryParam('currency');
    //     setPlanName(getQueryParam('planName'));
    //     setPlanDuration(getQueryParam('planDuration'));
    //     setCurrency(getQueryParam('currency'));
    //     // console.log("params received ---> ",planName,planDuration,currency)
    // }, []);

    useEffect(() => {
        if (planName && planDuration && currency) {
            setSelectedPlan(
                planDetails.filter((item) => item?.name == planName && item?.duration == planDuration)[0]
            );
        }
    }, []);

    const handleClose = () => setShowPremiumModal(false);

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    let planId = currency == 'INR' ? selectedPlan?.INRID : selectedPlan?.USDID;



    useEffect(() => {
        console.log('subscriptionid', subscriptionId);
    }, [subscriptionId])

    // useEffect(() => {
    //     if (authToken && selectedPlan && auth_token) {
    //         displayRazorpaySubscription(planId, null);
    //     }
    // }, [authToken, selectedPlan]);


    async function displayRazorpaySubscription(planID) {
        console.log('1111111111 ', auth_token);
        console.log('1111111 1 ', planID);
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }
        const jsonPlan = {
            plan: planName,
            planId: planID,
        };
        console.log({ jsonPlan });
        let data = {}
        data = await paymentOrder(jsonPlan, auth_token);
        console.log({ data });
        setSubscriptionId(data?.response?.subscriptionId);

        const options = {
            // key: 'rzp_test_DYzBKHfkjKzuOh',
            key: 'rzp_live_1IEjlHGI3q0yMR',
            subscription_id: data?.response?.subscriptionId,
            name: 'Cheerio',
            description: 'Premium',
            handler: function (response) {
                setModalOpen(true);
                // toast.success(
                //   'Your subscription has been done. You will be redirected to our home page now, kindly wait for a couple of minutes and refresh the website to see updated balance.',
                //   { position: 'top-center' }
                // );
                // setTimeout(() => {
                //   navigate('/creator');
                // }, 3000);
                // window.location.reload();
            },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
        if (data?.flag) {
            // console.log("success")
            //   getuserapicall();
        }
    }

    async function generateInvoice(subscriptionId) {
        try {
            let data = {
                plan: subscriptionId,
            };
            let token = auth_token;
            const response = await createInvoice(data, token);
            window.open(response?.response?.data, '_blank');
            // navigate('/creator');
        }
        catch (err) {
            toast.error('Unexpected error occurred, contact our support for help')
            console.log(err);
        }
    }

    return (
        <>

            <Modal show={showPremiumModal} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title><img style={{ width: 162, height: 32 }} src={images.CheerioAILogo} alt="" /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2 style={{
                        display: 'flex',
                        paddingBottom: '2%',
                        color: '#8E53F7'
                    }}>You're a few steps away from investing in the best marketing tool for your business</h2>
                    <div className="row">
                        <div className="col-md-6" style={{ paddingLeft: '1%' }}>
                            <p className=" mb-3">Here are few things to know before getting started</p>
                            <div className="mb-3">
                                <p className="mb-2 font-weight-bold">You must have admin access to your meta business manager</p>
                                <ul className="list-unstyled pl-3">
                                    <li>• Make sure you have an official business website with privacy policy page to proceed smoothly</li>
                                    <li>• Learn about allowed businesses in WhatsApp Commerce Policy</li>
                                    <li>• If your Meta Business Manager is not verified you can send proactive messages to only 250 unique contacts every 24 hours</li>
                                    <li>• You can always send unlimited replies to user-initiated conversations</li>
                                </ul>
                            </div>
                            <p className="mb-2 font-weight-bold">You must have a phone number that does not have a WhatsApp Account</p>
                            <p className="mb-2 font-weight-bold">For SMS Channel in India, you should have a registered DLT & header for ease of usage</p>
                            <p className="mb-2 font-weight-bold">For Global SMS, you must be ready to share business documents with us to procure your sender account</p>
                            <p className="mb-2 font-weight-bold">For Email Channel, you must verify your sender ID (preferably corporate email ID)</p>
                        </div>
                        <div className="col-md-6">
                            <div
                                style={{
                                    paddingBottom: '32%'
                                }}
                            >
                                <p
                                    style={{
                                        marginBottom: '2%'
                                    }}
                                >Plan you're opting for</p>
                                {selectedPlan && (
                                    <div className="d-flex justify-content-between align-items-center p-2 border rounded mb-3">
                                        <span>{`${selectedPlan.name} plan (${selectedPlan.duration})`}</span>
                                        <span className="font-weight-bold" style={{ color: colors.primary03 }}>
                                            {currency === 'INR' ? `₹${selectedPlan.priceINR}` : `$${selectedPlan.priceUSD}`}
                                        </span>
                                    </div>
                                )}
                                <p className="text-muted small" >
                                    This will be a recurring plan which means you will be charged automatically after each plan period until you cancel your subscription from your Cheerio dashboard. You can cancel anytime you want.
                                </p>
                                <p className="mt-4" style={{ color: colors.black }}>
                                    {'Still have doubts? '}
                                    <span
                                        style={{ color: colors.primary03, cursor: 'pointer' }}
                                        onClick={() => {
                                            window.open('mailto:support@cheerio.freshdesk.com?subject=Contact Us', '_blank');
                                        }}
                                    >
                                        {'Contact sales'}
                                    </span>
                                </p>
                                <CheerioButton
                                    borderStyle={{ width: '100%', marginBlockStart: 16, borderColor: colors.primary03 }}
                                    textStyle={{ color: colors.white }}
                                    backColor={colors.primary03}
                                    btnText={'Proceed to Buy'}
                                    onclick={() => {
                                        displayRazorpaySubscription(planId);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
            <PremiumSucess
                ModalOpen={ModalOpen}
                setModalOpen={setModalOpen}
                downloadInvoiceAction={() => {
                    generateInvoice(subscriptionId)
                }}
            />

        </>
    );
};

export default PremiumModal;
