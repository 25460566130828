import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { fetchContactLabels } from '../../../Services';
import { useSelector } from 'react-redux';
import { columns, SxStyling, timeStampToDated } from './variableDependencies';
import { useEffect, useMemo, useState } from 'react';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { trackPages } from '../../../Utils/brevo';
import {getLocaltime} from '../../../Utils/localdate'
const Labels = ({ search, excepted }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  //constant to retrigger render.
  const trigger = useSelector((state) => state.labelRedux.triggerState);
  const { data, isLoading, isError } = useQuery(['contactLabels', trigger], () =>
    fetchContactLabels(authtoken)
  );
  const localdata = useSelector((state) => state.main.localdata);
  const agentdata = useSelector((state) => state.main.agentdata);

  useEffect(() => {
    trackPages('contacts_labels_page', localdata);
  }, []);
  
  const row_data = useMemo(() => {
    const rows = [];
    if (data) {
      data.data.v2.forEach((vls) => {
        // if (
        //   vls?.privateLabel &&
        //   (vls?.uploadedUser === localdata?._id || vls?.uploadedUser === agentdata?._id)
        // ) {
        //   rows.push({
        //     id: vls._id,
        //     label: vls.label_name,
        //     contacts: vls.contactsLength,
        //     addedOn: timeStampToDated(vls.createdAt),
        //     action: {
        //       id: vls._id,
        //       deletion: true,
        //     },
        //     source: vls?.source,
        //     privateLabel: vls?.privateLabel,
        //     uploadedUser: vls?.uploadedUser,
        //   });
        // } else if (!vls?.privateLabel) {
        rows.push({
          id: vls._id,
          label: vls.label_name,
          contacts: vls.contactsLength,
          addedOn: getLocaltime(vls.createdAt),
          action: {
            id: vls._id,
            deletion: true,
          },
          source: vls?.source,
          privateLabel: vls?.privateLabel ?? false,
          uploadedUser: vls?.uploadedUser ?? '',
          visible: !excepted,
          isUploading: vls?.isUploading,
        });
      });
      data.data.v1.forEach((vls) => {
        rows.push({
          id: vls._id,
          label: vls.label_name,
          tags: vls.tags,
          contacts: vls.contactsLength,
          addedOn: getLocaltime(vls.createdAt),
          action: {
            id: vls._id,
            deletion: false,
          },
          source: vls?.source,
          visible: !excepted,
          isUploading: vls?.isUploading,          
        });
      });
    }

    return rows;
  }, [data]);

  const [filteredData, setFilteredData] = useState(row_data);

  useEffect(() => {
    if (search) {
      setFilteredData(
        row_data.filter((row) => row.label.toLowerCase().includes(search.toLowerCase()))
      );
    } else {
      setFilteredData(row_data);
    }
  }, [search, row_data]);
  if (isLoading) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center w-100"
        style={{ flex: 1 }} >
        <SpinnerLoader></SpinnerLoader>
      </div>
    );
  }

  return (
    <>
      <DataGrid
        rows={filteredData.filter((item) => {
          return !(
            item?.label.includes('abandoned-cart-on') ||
            item?.label.includes('orders-feedback-on') ||
            item?.label.includes('orders-cancelled-on') ||
            item?.label.includes('Workflows -') ||
            item?.label.includes('Workflows-') ||
            item?.label.includes('WhatsApp Contacts who') ||
            item?.label.includes('orders-fulfilled-on') ||
            item?.label.includes('orders-placed-on') ||
            item?.label.includes('cod-confirmation-on')
          );
        })}
        columns={columns}
        disableColumnMenu={true}
        sx={SxStyling}
        pageSizeOptions={[25, 50, 100]}
      />
    </>
  );
};
export default Labels;
