import React from 'react'
import images from '../../Utils/images'
import colors from '../../Utils/colors'

const DeploymentPage = () => {
  return (
    <>
    <div className='d-flex flex-column justify-content-center align-items-center' 
        style={{height:'100vh',width:'100%'}}
    >
        <img src={images.Maintenance}
            style={{width:350,height:300,objectFit:'contain'}}
        />
        <h3 style={{marginBlock:16,fontSize:24,fontWeight:700,color:colors.black,textAlign:'center'}}>
            {'We’re under maintenance, Please try again after sometime'}
        </h3>
        <p style={{fontSize:16,fontWeight:400,color:colors.black,textAlign:'center'}}>
            {'Sorry for the inconvenience caused. Your patience is greatly appreciated.'}
        </p>
    </div>
    </>
  )
}

export default DeploymentPage