import { useEffect, useState } from 'react'
import colors from '../../../Utils/colors';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const WooCommerceModal = ({ data, setData, btnDisabled, setBtnDisabled }) => {

    const [selectedTemplate, setSelectedTemplate] = useState();
    const [templateData, setTemplateData] = useState([
        // 'Prepaid Order Create',
        'Order Create',
        'Abandoned Cart',
        'Order Cancel',
        // 'Order Feedback',
        'Order Fullfill',
    ]);
    useEffect(() => {
        if (data) {
            setSelectedTemplate(data?.wooEvent);
        }
    }, []);

    useEffect(() => {
        setBtnDisabled(true);
    }, []);
    return (
        <div className="w-100 h-100 flex-column justify-content-start align-items-start">
            <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 20 }}>
                <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                    {'Choose an Event'}
                </Form.Label>
                <Form.Select
                    type="text"
                    placeholder="select"
                    className="w-100 btncustom dropdown"
                    style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
                    value={selectedTemplate}
                    onChange={(e) => {
                        console.log('er', e.target.value);
                        setSelectedTemplate(e.target.value);
                        setBtnDisabled(false);
                        setData({ ...data, wooEvent: e.target.value });
                    }}
                >
                    <option
                        style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                        key="blankchoice"
                        hidden
                        value
                    >
                        {'Select Event'}
                    </option>
                    {templateData &&
                        templateData
                            // .filter((item, index) => {
                            //   return item?.status === 'APPROVED';
                            // })
                            .map((item, index) => {
                                return (
                                    <option
                                        style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                                        value={item.id}
                                    >
                                        {item}
                                    </option>
                                );
                            })}
                </Form.Select>
            </InputGroup>
        </div>
    )
}

export default WooCommerceModal