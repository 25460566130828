import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Subscription from './Pages/BuyPremium';
import Addons from './Pages/AddOns';
import Clubchat from './Pages/Clubchat';
import Clublist from './Pages/Clublist';
import CreatorHome from './Pages/Creator';
import CreatorLogin from './Pages/CreatorLogin';
import CreatorLoginV2 from './Pages/CreatorLoginV2';
import CreatorNew from './Pages/CreatorNew';
import CreatorProfile from './Pages/CreatorProfile';
import Documentation from './Pages/Documentation';
import Explore from './Pages/Explore';
import Home from './Pages/Home';
import Login from './Pages/Login';
import MyProfile from './Pages/MyProfile';
import Profile from './Pages/Profile';
import ShortURL from './Pages/ShortURL';
import CreatorProfileNew from './Pages/CreatorProfileNew';
import TopUp from './Pages/TopUp';
import Auth from './Pages/Auth';
import PremiumPlans from './Pages/PremiumPlans';
import { ToastContainer, toast } from 'react-toastify'
import 'primeicons/primeicons.css';
import { googleLogout } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from './Config/axiosConfig';
import WorkFlow from './Pages/WorkFlow';

// import SocketConnect, { isSocketConnected } from './Components/Socket/SocketConnection';
import ManageProfile from './Pages/ManageProfile';
import AgentShortURL from './Pages/AgentShortURL';
import WixQRCode from './Pages/WixQRCode';
import { Beacon } from '@beacon.li/bar';
import { AppSumoLogin } from './Pages/AppSumoLogin';
import ResellerLogin from './Pages/Reseller';
import DeploymentPage from './Pages/DeploymentPage';
import { ShopifyLogin } from './Pages/ShopifyLogin';
import PricingPlan from './Pages/PricingPlan';
import FormURL from './Pages/FormURL';
import PremiumPricing from './Pages/PremiumPricing';
// import AITemplate from './Pages/AITemplate/AITemplate';

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const localdata = useSelector((state) => state.main.localdata);

  //logout when token expire
  axiosInstance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if (response.data.message === 'Failed to authenticate token.') {
        googleLogout();
        dispatch({ type: 'logout' });
        localStorage.removeItem('token');
        navigate('/login');
      }

      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );

  // useEffect(() => {
  //   //adminLogin();
  //   if (localdata?._id) {
  //     if (!isSocketConnected) {
  //       SocketConnect.connectUser();
  //     } else {
  //       SocketConnect.socketDataEvent('addPerson', localdata?._id);
  //     }
  //   }

  //   // SocketConnect.socketDataEvent('addPerson', localdata?._id);
  // }, [isSocketConnected, localdata]);

  useEffect(() => {
    const CheckDeployment = async () => {
      try {
        const response = await fetch('https://dev.api.cheerio.in/api/v2/guest/deployment-status', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();

        if (result?.data === true) {
          console.log('true result');
          navigate('/maintenance');
        } else {
          console.log('false result');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle the error (optional: redirect to an error page)
      }
    };

    CheckDeployment();
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      Beacon.load('LX7EZK');
    }
  }, []);

  return (
    // isMobile ? <MobileView>Please visit </MobileView>:
    <>
      <ToastContainer autoClose={6000}></ToastContainer>
      <Routes>
        {/* <Route path='/newlogin' element={<Auth/>} /> */}
        {/* <Route path="/aiTemplate" element={<AITemplate />} /> */}
        <Route path="/appsumo-login" element={<AppSumoLogin />} exact />
        <Route path="/shopify-login" element={<ShopifyLogin />} exact />
        <Route path="/v2" element={<CreatorLogin />} />:
        <Route path="/*" element={<Auth />} exact />
        <Route path="/partner/*" element={<ResellerLogin />} />
        <Route path="/maintenance" element={<DeploymentPage />} />
        {/* <Route path='/*' element={<Login/>} exact/> */}
        {/* <Route path='/profile/*' element={<Profile/>}/>    */}
        {/* <Route path='/explore/*' element={<Explore/>}/>    */}
        {/* <Route path='/clublist/*' element={<Clublist/>}/>    */}
        {/* <Route path='/clubchat/*' element={<Clubchat/>}/>    */}
        {/* <Route path='/myprofile' element={<MyProfile/>}/>    */}
        <Route path="/ss/:code" element={<ShortURL />} />
        {/* <Route path='/creator/*' element={<CreatorHome/>}/>    */}
        {/* <Route path='/creatorprofile' element={<CreatorProfile/>}/>    */}
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/addons" element={<Addons />} />
        <Route path="/premiumplans" element={<PremiumPlans />} />
        <Route path="/premiumpricing" element={<PremiumPricing />} />
        <Route path="/documentation/*" element={<Documentation />} />
        <Route path="/topup/*" element={<TopUp />} />
        <Route path="/creator/*" element={<CreatorNew />} />
        <Route path="/creatorprofile" element={<CreatorProfileNew />} />
        <Route path="/workflow" element={<WorkFlow />} />
        <Route path="/workflow/:id" element={<WorkFlow />} />
        <Route path="/manageprofile/*" element={<ManageProfile />} />
        <Route path="/invite/member/*" element={<AgentShortURL />} />
        <Route path="/qrcode/:code" element={<WixQRCode />} />
        <Route path="/pricingplan/*" element={<PricingPlan />} />
        <Route path="/form/:id" element={<FormURL />} />
        {/* <Route path='/creatorloginv2' element={<CreatorLoginV2/>}/>    */}
      </Routes>
    </>
  );
}

export default App;
