import React, { useEffect, useState } from 'react';
import { VariableHeader } from './Header';
import { VariableBody } from './Body';
import { VariableButtons } from './Buttons';
import { toast } from 'react-toastify';

const MessageCard = ({ cardData, setCardData, index }) => {
  const [headerData, setHeaderData] = useState({
    type: 'HEADER',
    format: 'IMAGE', // NONE, TEXT, IMAGE, VIDEO, DOCUMENT, LOCATION
    text: '',
    example: {
      header_text: [], // Array of string with length 1
      header_handle: [], // {file_name, base64}[]
    },
  });

  const [bodyData, setBodyData] = useState({
    type: 'BODY',
    text: '',
    example: {
      body_text: [], // Array of string with length 1
    },
  });

  const [buttonData, setButtonData] = useState({
    type: 'BUTTONS',
    buttons: [],
  });

  const btnzero = cardData[0]?.components?.filter((item) => item.type === 'BUTTONS')[0]?.buttons;
  const headerZero = cardData[0]?.components?.filter((item) => item.type === 'HEADER')[0]?.format;

  useEffect(() => {
    if (btnzero) {
      if (index !== 0) {
        setButtonData((data) => {
          let tempBtnsArr = btnzero?.map((item, index) => {
            if (data?.buttons[index]?.type === item?.type) {
              return data?.buttons[index];
            } else {
              return item;
            }
          });

          return {
            ...data,
            buttons: tempBtnsArr,
          };
        });
      }
    } else {
      setButtonData({
        type: 'BUTTONS',
        buttons: [],
      });
    }
  }, [btnzero]);

  useEffect(() => {
    if (headerZero) {
      if (index !== 0) {
        setHeaderData((data) => {
          let tempHeader = { ...data };
          if (tempHeader.format === headerZero) {
            return tempHeader;
          } else {
            return cardData[0]?.components?.filter((item) => item.type === 'HEADER')[0];
          }
        });
      }
    }
  }, [headerZero]);

  useEffect(() => {
    const tempHeaderData = { ...headerData };
    const components = [];
    delete tempHeaderData.example.header_text;
    components.push(tempHeaderData);
    if (bodyData?.text !== '') {
      if (!bodyData.text.match(/\{\{(\d+)\}\}/g)) {
        components.push({ ...bodyData, example: null });
      } else if (
        bodyData.example.body_text.length ===
          (bodyData.text.match(/\{\{(\d+)\}\}/g)?.length || 0) &&
        !bodyData.example.body_text.includes('')
      ) {
        components.push({ ...bodyData, example: { body_text: [bodyData.example.body_text] } });
      }
    }
    if (buttonData.buttons.length > 0) {
      const tempButtonData = { ...buttonData };
      tempButtonData.buttons = tempButtonData.buttons.map((e) => {
        delete e.uuid;
        return e;
      });
      components.push(tempButtonData);
    }

    setCardData((data) => {
      let tempArr = [...data];
      tempArr[index] = {
        components: components,
      };
      return tempArr;
    });
  }, [bodyData, headerData, buttonData]);

  return (
    <>
      <VariableHeader data={headerData} setData={setHeaderData} Carousel={true} index={index} />

      <div style={{ width: '100%', marginTop: '10px' }}>
        <VariableBody data={bodyData} setData={setBodyData} Carousel={true} textLength={160} />
      </div>

      <div style={{ width: '100%', marginTop: '10px', marginBottom: '20px' }}>
        <VariableButtons
          data={buttonData.buttons}
          setData={(data) => {
            setButtonData({
              ...buttonData,
              buttons: data,
            });
          }}
          Carousel={true}
          index={index}
        />
      </div>
    </>
  );
};

export default MessageCard;
