import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import { stopCampaign, stopAllCampaign } from '../../../Services';
import { toast } from 'react-toastify';



const StopCampaignModal = ({ page, rowsPerPage, campaignId, setShow, show, campaignListApiCall, searchData, selectedStatus, selectedSort, selectedChannel }) => {

    const authtoken = useSelector((state) => state.main.auth_token);
    console.log("campaign ", campaignId)

    const handleClose = () => {
        setShow(false);
    }

    const handleStopCampaign = () => {
        const token = authtoken;
        const data = {
            campaignId: campaignId
        }
        console.log(data)
        console.log(token)
        if (campaignId !== '') {
            stopCampaign(data, token).then((res) => {
                if (res?.flag) {
                    toast.success(res?.message)
                    campaignListApiCall(page, rowsPerPage, searchData, selectedStatus, selectedSort, selectedChannel)

                }
                else {
                    toast.error(res?.message)
                }
            }).catch((err) => {
                console.log(err)
                toast.error("Failed to stop campaign")
            })
        } else {
            stopAllCampaign(token).then((res) => {
                if (res?.flag) {
                    toast.success(res?.message)
                    campaignListApiCall(page, rowsPerPage, searchData, selectedStatus, selectedSort, selectedChannel)
                }
                else {
                    toast.error(res?.message)
                }
            }).catch((err) => {
                console.log(err)
                toast.error("Failed to stop campaign")
            })
        }
    }



    return (
        <div >
            <Modal
                show={show}
                onHide={handleClose}
                className="custom-modal"
                centered
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginLeft: "3%",
                    marginRight: "3%",
                    marginTop: "3%"
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 3,
                        width: '100%'
                    }}>
                        <img
                            src={images.Stop}
                            style={{
                                cursor: 'pointer',
                                height: 30,
                                width: 30,
                            }}
                            onClick={handleClose}
                        />
                        <p style={{
                            fontSize: 20,
                            fontWeight: 700,
                        }}>{campaignId === '' ? "Stop Campaigns" : "Stop Campaign"}</p>
                    </div>
                    <img
                        src={images.CMClose}
                        style={{
                            cursor: 'pointer',
                            height: 22,
                            width: 22,
                        }}
                        onClick={handleClose}
                    />
                </div>
                <hr style={{
                    marginLeft: "4%",
                    marginRight: "4%",
                }} />
                <Modal.Body
                    style={{
                        paddingTop: '0',
                        padding: '4%'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 20,
                        }}
                    >
                        <p style={{
                            color: "#666666"
                        }}>
                            Stopping the campaign will stop sending messages to left over contacts. Kindly note amount/credits already deducted is not refundable. Are you sure you want stop?
                        </p>

                    </div>
                    <div
                        style={{
                            fontSize: 15,
                            fontWeight: 700,
                            textAlign: "flex-start",
                            marginTop: "10px"
                        }}
                    >
                        Need help? <span style={{
                            color: "#3366CC",
                            fontWeight: 400,
                            cursor: "pointer"
                        }}
                            onClick={() => { window.open('https://cheerio.freshdesk.com/support/tickets/new', '_blank') }}
                        >Contact us</span>
                    </div>
                    <div style={{
                        marginTop: 30,
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 20,
                        alignItems: 'center',
                    }}>
                        <CheerioButton
                            btnText={"Stop Campaign"}
                            backColor={"#FF0000"}
                            textStyle={{ color: colors.white }}
                            borderStyle={{ borderColor: colors.white }}
                            onclick={() => {
                                handleStopCampaign()
                                handleClose()
                            }}
                        />
                        <CheerioButton
                            btnText={"Keep Sending"}
                            backColor={"#fff"}
                            textStyle={{ color: colors.black }}
                            borderStyle={{ borderColor: colors.black }}
                            onclick={() => {
                                handleClose()
                            }}
                        />
                    </div>

                </Modal.Body>
            </Modal>
        </div >
    )
}

export default StopCampaignModal