import React, { useCallback, useEffect, useState } from 'react'
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import images from '../../../Utils/images';

const handleStyle = {backgroundColor: colors.success03,height:8,width:8,borderRadius:4};

// const DRChildNode = 

const DefaultResponseNode = ({data, isConnectable}) => {

    const onChange = useCallback((evt)=>{
        console.log(evt.target.value)
        data.inputText = evt.target.value;
    },[]);

    const [drBodyText, setDrBodyText] = useState('');
    const [optionsArr, setOptionsArr] = useState(['Track my order','Talk to support','Close chat','4th option']);

    useEffect(()=>{
        if(optionsArr.length > 0){
            data.options = optionsArr;
        }
    },[optionsArr])

  return (
    <>
    <div className='d-flex flex-column justify-content-between align-items-center' style={{border:`1px solid ${colors.borderwhite}`,borderRadius:16,backgroundColor:colors.white01,width:250}}>
        <Handle type='target' position={Position.Left} isConnectable={isConnectable} style={handleStyle} />
        <div className='w-100 d-flex flex-row justify-content-between align-items-center' style={{paddingInline:16,paddingBlock:8,backgroundColor:'#04B100',borderTopLeftRadius:16,borderTopRightRadius:16}}>
            <p style={{padding:0,margin:0,fontSize:16,fontWeight:700,color:colors.white}}>
                {'Default Response'}
            </p>
            <img src={images.Edit} style={{height:20,width:20,objectFit:'contain'}}></img>
        </div>
        <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{paddingInline:16,paddingBlockStart:8,paddingBlockEnd:16}}>
            {/* <p style={{padding:0,margin:0,fontSize:12,fontWeight:400,color:colors.black}}>
                {'Default Response'}
            </p> */}
            <p style={{padding:0,margin:0,marginBlock:4,fontSize:10,fontWeight:600,color:colors.black,width:'100%'}}>
                {'Add body text'}
            </p>

            <InputGroup className="my-1">
                {/* <Form.Label style={{fontSize:16,fontWeight:600,color:colors.black}}>{heading}</Form.Label> */}
                {/* <span className='ms-auto' style={{color: renewalText.length > 640 ? colors.error04 : colors.greys04,fontSize:12,fontWeight:400,padding:0,margin:0}}>
                {`${renewalText.length}/640`}
                </span> */}
                <Form.Control as="textarea" rows={3} placeholder={'Enter here'} className='w-100 btncustom'
                style={{borderRadius:8,fontSize:10,fontWeight:400,color:colors.black}}
                // isInvalid={(renewalText.length >= 640)}
                maxLength={640}
                // value={drBodyText}
                onChange={onChange}
                />
            </InputGroup>

            <p style={{padding:0,margin:0,marginBlock:4,fontSize:10,fontWeight:600,color:colors.black,width:'100%'}}>
                {'Add quick replies'}
            </p>
            
            {optionsArr && optionsArr.map((item,index)=>{
                return(
                    <>
                    <div className='d-flex flex-row justify-content-center align-items-center w-100'  style={{borderRadius:8,backgroundColor:colors.white03,marginBlock:4,paddingBlock:6}}>
                        <p style={{padding:0,margin:0,fontSize:12,fontWeight:600,color:colors.black}}>
                            {item}
                        </p>
                        <Handle
                            type="source"
                            // position={Position.Right}
                            position={Position.Right}
                            id={`child_node_${index}`}
                            style={{...handleStyle,top:'auto',bottom:`calc( ${index}*16 )`}}
                            isConnectable={isConnectable}
                        />
                    </div>
                    
                    </>
                )
            })}
            
        </div>
        
        {/* <Handle
        type="source"
        position={Position.Right}
        id="a"
        style={{ bottom:105,top:'auto',right:14,...handleStyle}}
        isConnectable={isConnectable}
      />
        <Handle
        type="source"
        position={Position.Right}
        id="a"
        style={{ bottom:65,top:'auto',right:14,...handleStyle}}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        style={{ bottom:30, top: 'auto',right:14,...handleStyle}}
        isConnectable={isConnectable}
      /> */}
    </div>
    </>
  )
}

export default DefaultResponseNode