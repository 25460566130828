import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { GetTemplatesV2API } from '../../../Services';
import { useSelector } from 'react-redux';
import AnnDetails from '../../AnnouncementsNew/Components/AnnDetails';
import AnnDetailsWF from './AnnDetailsWF';
import SpinnerLoader from '../../../Components/SpinnerLoader';

const SendShopifyTemplateModal = ({ data, setData, btnDisabled, setBtnDisabled }) => {
  //console.log('dddd111', data);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [templateData, setTemplateData] = useState([
    'Prepaid Order Create',
    'COD Order Create',
    'Abandoned Cart',
    'Order Cancel',
    'Order Feedback',
    'Order Fullfill',
  ]);

  const [ItemV2, setItemV2] = useState([]);
  // const [mediaIDV2, setMediaIDV2] = useState('');
  const [headerTypeV2, setHeaderTypeV2] = useState('');
  const [headerFileNameV2, setHeaderFileNameV2] = useState('');
  // const [URLValueV2, setURLValueV2] = useState('');
  const [BodyParV2, setBodyParV2] = useState([]);
  // const [ImageV2, setImageV2] = useState('');
  // const [mpm, setMpm] = useState(false);
  const [s3Link, setS3Link] = useState('');

  const [actionList, setActionList] = useState({});
  const [headerInputText, setHeaderInputText] = useState('');
  // const [headerInputExists, setHeaderInputExists] = useState(false);

  // const [posURL, setPosURL] = useState(null);
  const [annv2CTA, setAnnv2CTA] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [existingData, setExistingData] = useState();
  const [quickReplyBtn, setQuickReplyBtn] = useState([]);

  const authtoken = useSelector((state) => state.main.auth_token);

  useEffect(() => {
    setBtnDisabled(true);
    // setIsLoading(true);
    // setExistingData(data);
    // GetTemplatesV2APIcall();
  }, []);

  //   useEffect(() => {
  //     if (templateData) {
  //       // console.log("useeffect called......",existingData?.templateID)
  //       if (existingData?.templateID) {
  //         setSelectedTemplate(existingData?.templateID);
  //       }
  //     }
  //   }, [templateData]);

  //   useEffect(() => {
  //     if (ItemV2) {
  //       // setTimeout(() => {
  //       //     loadExistingData();
  //       // }, 2000);
  //       // setTimeout(() => {
  //       loadExistingData();
  //       // }, 2000);
  //     }
  //   }, [ItemV2]);

  //   const loadExistingData = () => {
  //     if (existingData) {
  //       setHeaderTypeV2(existingData?.header?.type);
  //       setHeaderFileNameV2(existingData?.header?.fileName);
  //       setBodyParV2(existingData?.bodyParameters);
  //       setActionList(existingData?.mpm);
  //       setHeaderInputText(existingData?.header?.text);
  //       // console.log("culprit 1....")
  //       setAnnv2CTA(existingData?.cta);
  //       setS3Link(existingData?.header?.imgeURL);
  //     }
  //   };

  useEffect(() => {
    if (data) {
      setSelectedTemplate(data?.shopifyEvent);
      //         setMediaIDV2(data?.header?.mediaId);
      //         setHeaderTypeV2(data?.header?.type);
      //         setHeaderFileNameV2(data?.header?.fileName);
      //         setURLValueV2(data?.cta?.value);
      //         setBodyParV2(data?.bodyParameters);
      //         setActionList(data?.mpm);
      //         setHeaderInputText(data?.header?.text)
      //         setPosURL(data?.cta?.index)
      //         setAnnv2CTA(data?.cta)
      //         setS3Link(data?.header?.imgeURL)
    }
  }, []);

  //   useEffect(() => {
  //     // console.log("data changed ===> ",data);
  //   }, [data]);

  //   useEffect(() => {
  //     setData({
  //       ...data,
  //       templateName: ItemV2[0]?.name,
  //       templateID: selectedTemplate,
  //       language_code: ItemV2[0]?.language,

  //       cta: annv2CTA,
  //       header: {
  //         type: headerTypeV2,
  //         text: headerInputText,
  //         imgeURL: s3Link,
  //         fileName: headerFileNameV2 ? headerFileNameV2 : null,
  //       },
  //       bodyParameters: BodyParV2,
  //       quickReplyButtons: quickReplyBtn,
  //       mpm: actionList,
  //     });
  //     checkValidation();
  //   }, [
  //     selectedTemplate,
  //     headerTypeV2,
  //     headerFileNameV2,
  //     BodyParV2,
  //     actionList,
  //     headerInputText,
  //     ItemV2,
  //     annv2CTA,
  //     s3Link,
  //     quickReplyBtn,
  //   ]);

  //   const checkValidation = () => {
  //     let ctaValid = annv2CTA?.filter((item, index) => {
  //       return item.value === '';
  //     });
  //     let bodyParValid = BodyParV2?.filter((item, index) => {
  //       return item === '';
  //     });
  //     let headerValid =
  //       (headerTypeV2 && s3Link && headerFileNameV2) ||
  //       (headerTypeV2 === 'text' && headerInputText) ||
  //       (headerTypeV2 === 'text' && !headerInputText) ||
  //       headerTypeV2 === 'NONE';
  //     let mpmValid =
  //       actionList?.index &&
  //       actionList?.thumbnail_product_retailer_id &&
  //       actionList?.sections?.length !== 0 &&
  //       actionList?.sections?.filter((item, index) => {
  //         return !item?.title || item?.product_items?.length === 0;
  //       }).length !== 0;
  // console.log("================= ",data);
  // console.log("================= ",data?.templateName);
  // console.log("================= ",data?.templateID);
  // console.log("================= ",data?.language_code);
  // console.log("================= ",((data?.cta && ctaValid?.length === 0) || !data?.cta));
  // console.log("================= ",(data?.cta && ctaValid?.length === 0));
  // console.log("================= ",(data?.header && headerValid));
  // console.log("================= ",(data?.header && headerValid));
  // console.log("================= ",((data?.bodyParameters && bodyParValid?.length === 0) || !data?.bodyParameters ));
  // console.log("================= ",(data?.bodyParameters && bodyParValid?.length === 0));
  // console.log("================= ",((data?.mpm && mpmValid) || data?.mpm?.index === null || !data?.mpm));
  // console.log("================= ",((data?.mpm && mpmValid) || data?.mpm?.index === null ));
  //     if (
  //       data?.templateName &&
  //       data?.templateID &&
  //       data?.language_code &&
  //       ((data?.cta && ctaValid?.length === 0) || !data?.cta) &&
  //       data?.header &&
  //       headerValid &&
  //       ((data?.bodyParameters && bodyParValid?.length === 0) || !data?.bodyParameters) &&
  //       ((data?.mpm && mpmValid) || data?.mpm?.index === null || !data?.mpm)
  //     ) {
  //       setBtnDisabled(false);
  //     } else {
  //       setBtnDisabled(true);
  //     }
  //   };

  //   useEffect(() => {
  //     if (selectedTemplate && templateData) {
  //       // console.log("set item useeffect called.....")
  //       setIsLoading(true);
  //       setItemV2(templateData.filter((e) => e.id === selectedTemplate));
  //       setTimeout(() => {
  //         setIsLoading(false);
  //       }, 2000);
  //     }
  //   }, [selectedTemplate, templateData]);

  //   const GetTemplatesV2APIcall = () => {
  //     let token = authtoken;
  //     let data = {
  //       limit: 100,
  //     };
  //     GetTemplatesV2API(data, token).then((res) => {
  //       if (res) {
  //         console.log('TemplatesV2API response: ' + res);
  //         // setTemplateData(res?.data);
  //       } else {
  //         console.log('errror: ' + res);
  //       }
  //     });
  //   };

  //   useEffect(() => {
  //     if (templateData) {
  //       setIsLoading(false);
  //     }
  //   }, [templateData]);

  return (
    <>
      <div className="w-100 h-100 flex-column justify-content-start align-items-start">
        <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 20 }}>
          <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
            {'Choose an Event'}
          </Form.Label>
          <Form.Select
            type="text"
            placeholder="select"
            className="w-100 btncustom dropdown"
            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
            value={selectedTemplate}
            onChange={(e) => {
              console.log('er', e.target.value);
              setSelectedTemplate(e.target.value);
              setBtnDisabled(false);
              setData({ ...data, shopifyEvent: e.target.value });
            }}
          >
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              key="blankchoice"
              hidden
              value
            >
              {'Select Event'}
            </option>
            {templateData &&
              templateData
                // .filter((item, index) => {
                //   return item?.status === 'APPROVED';
                // })
                .map((item, index) => {
                  return (
                    <option
                      style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                      value={item.id}
                      // key={`CWAT_${item?.id}`}
                    >
                      {item}
                    </option>
                  );
                })}
          </Form.Select>
        </InputGroup>

        {/* {isLoading ? (
          <>
            <div className="d-flex flex-column justify-content-center align-items-center w-100 my-3">
              <SpinnerLoader />
            </div>
          </>
        ) : (
          <>
            {ItemV2[0] && (
              <AnnDetailsWF
                item={ItemV2[0]}
                setHeaderType={setHeaderTypeV2}
                headerType={headerTypeV2}
                headerFileName={headerFileNameV2}
                setHeaderFileName={setHeaderFileNameV2}
                bodyPar={BodyParV2}
                setBodyPar={setBodyParV2}
                actionList={actionList}
                setActionList={setActionList}
                headerInputText={headerInputText}
                setHeaderInputText={setHeaderInputText}
                setAnnv2CTA={setAnnv2CTA}
                s3Link={s3Link}
                setS3Link={setS3Link}
                existingData={existingData}
                quickReplyBtn={quickReplyBtn}
                setQuickReplyBtn={setQuickReplyBtn}
              />
            )}
          </>
        )} */}
      </div>
    </>
  );
};

export default SendShopifyTemplateModal;
