import React, { useEffect } from 'react';
import { Form, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ToggleButton } from 'react-bootstrap';
import CheerioButton from '../../Components/CheerioButton';
import { Dropdown } from 'react-bootstrap';
import './styles.css';
import images from '../../Utils/images';
import DropzoneCustom from './Dropzone';
import { useSelector } from 'react-redux';
import {
  dialogFlowApi,
  dialogFlowConfigAPI,
  dialogFlowReturnPolicyAPI,
  getChatBotDetailsAPI,
  getToggleChatBotDetailsAPI,
  toggleChatBotAPI,
  trainChatBotAPI,
} from '../../Services';
import { Modal } from 'react-bootstrap';
import video1 from '../../Videos/1.mp4';
import video2 from '../../Videos/2.mp4';
import ReactPlayer from 'react-player';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import colors from '../../Utils/colors';
import { trackPages } from '../../Utils/brevo';

export function ValidateEmail(mail) {
  if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(mail)) {
    return true;
  }
  toast('You have entered an invalid email address!', { position: 'top-center' });
  return false;
}

const SetupBot = () => {
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const navigate = useNavigate();
  const isPremiumUser = localdata?.premium;

  const [offStartTime, setOffStartTime] = useState('');
  const [offEndTime, setOffEndTime] = useState('');
  const [timing, setTiming] = useState('');
  const [faqFiles, setFaqFiles] = useState(null);
  const [userKey, setUserKey] = useState(null);
  const [queryFiles, setQueryFiles] = useState(null);
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [website, setWebsite] = useState('');
  const [faqWebPage, setFaqWebPage] = useState('');
  // const [enableChatBot,setEnableChatBot] = useState(true);
  const [workingDays, setWorkingDays] = useState([
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thrusday',
    'Friday',
  ]);
  const [shippingDays, setShippingDays] = useState();
  const [refundDays, setRefundDays] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [returnPolicy, setReturnPolicy] = useState(false);
  const [returnPolicyFiles, setReturnPolicyFiles] = useState(null);
  const [returnPolicyText, setReturnPolicyText] = useState('');
  const [chatBotKey, setChatBotKey] = useState(null);
  const [time, setTime] = useState();
  const [showChatBotModal, setChatBotModal] = useState(false);
  const [gAuthToken, setgAuthToken] = useState();
  const [successModal, setSuccessModal] = useState(false);
  const [checkDetails, setCheckDetails] = useState(false);
  const [editOption, setEditOption] = useState(false);
  const [responseFetched, setResponseFetched] = useState(false);

  function ValidateMobile(mobile) {
    if (/^[6-9]\d{9}$/.test(mobile)) {
      return true;
    }
    toast('You have entered an invalid mobile number', { position: 'top-center' });
    return false;
  }

  const returnPolicyData = (childData) => {
    setReturnPolicyFiles(childData);
  };

  const FaqData = (childData) => {
    setFaqFiles(childData);
  };

  const queryData = (childData) => {
    setQueryFiles(childData);
  };

  const UserKey = (childData) => {
    // console.log("json data",childData[0]);
    setUserKey(childData);
  };

  const GetToggleBotDetailsApiCall = () => {
    let token = authtoken;
    let data = {
      user_id: localdata?._id ? localdata?._id : _id,
    };
    getToggleChatBotDetailsAPI(data, token).then((res) => {
      if (res?.flag) {
        console.log('toggle chatbot get api', res);
        //console.log("check",res?.data?.saveConfig[0].send_autobotmsg);

        if (res?.data) {
          if (res?.data?.saveConfig[0]?.send_autobotmsg) {
            document.getElementById('flexSwitchCheckDefault').checked = true;
          }
        }
      } else {
        console.log('error in toggle chatbot get API');
      }
    });
  };

  const GetChatBotKeyAPIcall = () => {
    let token = authtoken;
    let data = {
      user_id: localdata?._id ? localdata?._id : _id,
    };
    // console.log("token",token);
    //console.log("data2",data);
    getChatBotDetailsAPI(data, token).then((res) => {
      if (res?.flag) {
        //console.log('key details',res);

        if (res?.data?.main_config?.project_id) {
          setChatBotKey(res?.data?.main_config?.project_id);
          let timeChatbot = res?.data?.updatedAt;
          if (timeChatbot) {
            timeChatbot = timeChatbot.split('T');
            setTime(timeChatbot[0]);
          }

          if (res?.data?.auto_reply_after) {
            let autoReply = res?.data?.auto_reply_after;
            setEmail(res?.data?.support_email_id);
            setContact(res?.data?.support_phone_number);
            setOffStartTime(res?.data?.office_start_time);
            setOffEndTime(res?.data?.office_end_time);
            setWebsite(res?.data?.support_website);
            setFaqWebPage(res?.data?.faq_webpage);
            setTiming(res?.data?.auto_reply_after);
            setShippingDays((res?.data?.shipping_arr).slice(1, -1));
            setRefundDays(res?.data?.refund_arr.slice(1, -1));

            setReturnPolicyText(res?.data?.return_policy);
            let newArr = res?.data?.working_days;
            setWorkingDays(newArr);
            if (autoReply && autoReply !== '') {
              if (!editOption) {
                setFaqFiles(res?.data?.faqList_filedir);
                setQueryFiles(res?.data?.chatSupportQuery_filedir);
                setCheckDetails(true);
              }
            }
          }
        }
      } else {
        console.log('error getting chatbot details res', res);
      }
    });
  };

  const ReturnPolicyAPIcall = () => {
    let form = new FormData();
    form.append('file', returnPolicyFiles[0]);
    let token = authtoken;
    dialogFlowReturnPolicyAPI(form, token).then((res) => {
      if (res?.flag) {
        // console.log('return policy',res);
        setReturnPolicyText(res?.data);
      } else {
        console.log('error return policy res', res);
      }
    });
  };

  const DialogFlowConfigAPIcall = () => {
    let form = new FormData();
    form.append('user_id', localdata?._id);
    form.append('file', userKey[0]);
    let token = authtoken;
    dialogFlowConfigAPI(form, token).then((res) => {
      if (res?.flag) {
        //console.log("success response dialogFlowconfig ",res);
        GetChatBotKeyAPIcall();
      } else {
        console.log('error response ', res);
      }
    });
  };

  const TrainChatBotAPIcall = () => {
    let data = {
      user_id: localdata?._id ? localdata?._id : _id,
      maincomponent: true,
      gauth_token: gAuthToken,
    };

    let token = authtoken;
    trainChatBotAPI(data, token).then((res) => {
      // console.log("train chatbot api data",data);
      if (res?.flag) {
        console.log('success response ', res);
        setSuccessModal(true);
      } else {
        console.log('error response ', res);
      }
    });
  };

  const ToggleBotApiCall = () => {
    let data = {
      user_id: localdata?._id ? localdata?._id : _id,
    };

    let token = authtoken;
    // console.log("token",token);
    toggleChatBotAPI(data, token).then((res) => {
      // console.log("train chatbot api data",data);
      if (res?.flag) {
        console.log('togglebot api response ', res);
      } else {
        console.log('error togglebot api response ', res);
      }
    });
  };

  const dialogFlowApiCall = () => {
    let form = new FormData();

    const timeStamp = new Date().toISOString().replace(/[-:.]/g, '').split('T');
    form.append('user_id', localdata?._id);
    form.append('office_start_time', offStartTime);
    form.append('office_end_time', offEndTime);

    form.append('working_days', JSON.stringify(workingDays));

    form.append('auto_reply_after', timing);
    form.append('support_email_id', email);
    form.append('support_phone_number', contact);
    form.append('support_website', website);
    form.append('shipping_arr', JSON.stringify(shippingDays));
    form.append('refund_arr', JSON.stringify(refundDays));
    form.append('return_policy', returnPolicyText);
    form.append('faq_webpage', faqWebPage);
    //console.log("faq files",faqFiles);
    console.log('faqqqqqqq', faqWebPage);
    if (faqFiles) {
      form.append('faqList', faqFiles[0], faqFiles[0].name + timeStamp[1]);
    } else if (!faqWebPage) {
      toast('Please upload faq files', { position: 'top-center' });
    }
    if (queryFiles) {
      form.append('chatSupportQuery', queryFiles[0], queryFiles[0].name + timeStamp[1]);
    } else {
      toast('Please upload query files', { position: 'top-center' });
    }

    // let data = {
    //     user_id: _id,
    //     office_start_time: offStartTime,
    //     office_end_time: offEndTime,
    //     working_days: workingDays,
    //     auto_reply_after:timing,
    //     support_email_id:email,
    //     support_phone_number:contact,
    //     support_website:website,
    //     shipping_arr:shippingDays,
    //     refund_arr:refundDays,
    //     return_policy:"",
    //     faq_webpage:faqWebPage,
    //     faqList:faqFiles[0],
    //     chatSupportQuery:queryFiles[0]
    // }
    console.log('data dialogue flow=>', form);

    // console.log("token",token);
    let token = authtoken;
    if ((faqFiles || faqWebPage) && queryFiles) {
      dialogFlowApi(form, token)
        .then((res) => {
          console.log('get user api response ==> ', res);
          if (res.flag) {
            console.log('response fetch:', res);
            setResponseFetched(true);
          } else {
            console.log('error get user api ===> ', res);
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  useEffect(() => {
    //console.log("ressppppp",responseFetched);
    if (responseFetched) {
      setChatBotModal(true);
    }
  }, [responseFetched]);

  useEffect(() => {
    if (userKey) {
      DialogFlowConfigAPIcall();
    }
  }, [userKey]);

  useEffect(() => {
    if (returnPolicyFiles) {
      ReturnPolicyAPIcall();
    } else {
      setReturnPolicyText('');
    }
  }, [returnPolicyFiles]);

  useEffect(() => {
    GetChatBotKeyAPIcall();
    GetToggleBotDetailsApiCall();
    if (checkDetails) {
      handlePreFilledWorkingDays();
    }
  }, [checkDetails]);

  useEffect(() => {
    if (editOption) {
      setFaqFiles(null);
      setQueryFiles(null);
    }
  }, [editOption]);

  // useEffect(()=>{
  //   if(offStartTime !== "" || offEndTime !== "" || timing !== "" || email !== "" || contact !== "" ||
  //   website !== "" || returnPolicyText !== "" || faqFiles || faqWebPage !== "" || queryFiles || returnPolicyFiles && checkDetails){
  //     console.log("update button");
  //     setShowUpdateButton(true);
  //   }
  //   else{
  //     if(!arrUpdate){
  //     console.log("not update");
  //     setShowUpdateButton(false);
  //   }
  //   }

  //     },[offStartTime,offEndTime,workingDays,timing,email,contact,website,shippingDays,refundDays,returnPolicyText,
  //       faqFiles,queryFiles,faqWebPage])

  const handlePreFilledWorkingDays = () => {
    const label = document.getElementById('workingButton');
    const descendent = label.getElementsByTagName('input');
    let e;
    for (let i = 0; i < descendent.length; i++) {
      e = descendent[i];
      const buttonLabel = document.querySelector(`label[for=${e.id}]`);

      if (workingDays.includes(e.value)) {
        buttonLabel.style.backgroundColor = '#ECE1FF';
      } else {
        buttonLabel.style.backgroundColor = '#F5F5F5';
      }
      //    console.log("ele",e);
    }
    //console.log("lvl",);
  };

  const handleWorkingDays = (e) => {
    // console.log("id..",e.target.id);
    const label = document.querySelector(`label[for=${e.target.id}]`);
    //   if(checkDetails){
    //   console.log("update");
    //   setUpdateArr(true);
    // }
    // if(e.target.checked){
    const index = workingDays.indexOf(e.target.value);
    // console.log("i",index);
    if (index >= 0) {
      //  setWorkingDays(workingDays.splice(index,1));
      setWorkingDays(workingDays.filter((day) => day !== e.target.value));
      label.style.backgroundColor = '#F5F5F5';
      label.style.border = '0px';
    } else {
      setWorkingDays((workingDays) => [...workingDays, e.target.value]);
      label.style.backgroundColor = '#ECE1FF';
      label.style.border = '1px solid black';
    }
    // console.log("wd",workingDays)
    // }else{
    //   console.log("wd",workingDays)
    //   label.style.backgroundColor = "#F5F5F5";
    //   // const index = workingDays.indexOf(e.target.value)
    //   // workingDays.splice(index,1)
    //   setWorkingDays(workingDays.filter((day)=> day !== e.target.value));
    //   console.log("wd",workingDays);
    // }
  };

  const editButtonClicked = () => {
    //console.log("edit clicked");
    setEditOption(true);

    setCheckDetails(false);
  };

  useEffect(() => {
    console.log('triggered');
  }, checkDetails);

  useEffect(() => {
    console.log('parent', faqFiles);
  }, [FaqData]);

  useEffect(() => {
    trackPages('automation_setup_chatbot_page', localdata);
  }, []);

  return (
    <div
      className="d-flex flex-column"
      style={{ width: '100%', overflowY: 'auto', height: '100%', scrollbarWidth: 'thin' }}
    // style={{ width: '90%', overflowY: 'auto', height: '100%', scrollbarWidth: 'thin' }}
    >
      <div className="d-flex justify-content-between mt-3 pt-2">
        <div style={{ width: '70%', paddingLeft: '30px' }}>
          <h3 style={{ color: colors.black }}>Setup Chatbot</h3>
          <div style={{ fontWeight: '400px', fontSize: '16px', color: '#666666', marginTop: 10 }}>
            To set up chat bot fill up all the following details and hit train bot. Chat bot will
            automatically enable once training get completed.
          </div>
        </div>
        <div>
          <div
            className="container d-flex justify-content-end"
            style={{ width: '300px', height: '25px' }}
          >
            <label
              className=" pt-0"
              style={{ fontWeight: '700', fontSize: '16px', color: 'black' }}
            >
              Enable Chatbot
            </label>
            <div class="form-check form-switch " style={{ marginLeft: '10px' }}>
              <input
                class="form-check-input shadow-none"
                style={{ cursor: isPremiumUser ? 'pointer' : 'not-allowed' }}
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                disabled={!isPremiumUser}
                onChange={(e) => (e.target.checked ? ToggleBotApiCall() : ToggleBotApiCall())}
              ></input>
            </div>
          </div>
          <div className="d-flex justify-content-end" style={{ marginRight: '10px' }}>
            {/* { showUpdateButton ? <CheerioButton
            className="button"
                        borderStyle={{
                          height:"36",
                          width: "172px",
                          marginBlockStart: 16,
                          marginBlockEnd: 8,
                          
                        }}
                        textStyle={{ color: "white",fontSize: 12, fontWeight: 600 }}
                        btnText={"Update Details"}
                        backColor={"#000000"}    
                        onclick={()=>{
                          dialogFlowApiCall();
                                      setChatBotModal(true);
                        }}                  
                        
                      />: */}
            {editOption ? (
              // <CheerioButton
              //           className="button"
              //                       borderStyle={{
              //                         height:"36",
              //                         width: "172px",
              //                         marginBlockStart: 16,
              //                         marginBlockEnd: 8,

              //                       }}
              //                       disabled={!checkDetails}
              //                       textStyle={{ color: "white",fontSize: 12, fontWeight: 600 }}
              //                       btnText={"Save Changes"}
              //                       backColor={"#9357FF"}
              //                       onclick={()=>{
              //                         dialogFlowApiCall();
              //                                     setChatBotModal(true);
              //                       }}

              //                     />
              ''
            ) : (
              <CheerioButton
                className="button"
                borderStyle={{
                  height: '36',
                  width: '172px',
                  marginBlockStart: 16,
                  marginBlockEnd: 8,
                  borderRadius: '9px',
                }}
                disabled={!checkDetails}
                textStyle={{ color: 'black', fontSize: 12, fontWeight: 600 }}
                btnText={'Edit bot details'}
                backColor={'white'}
                onclick={() => {
                  editButtonClicked();
                }}
              />
            )}
          </div>
        </div>
      </div>
      <hr />

      {/* Modal for Train Chatbot */}
      <Modal
        show={showChatBotModal}
        onHide={() => setChatBotModal(false)}
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="my-modal"
        contentClassName="Train-chatbot"
      >
        <Modal.Body style={{ overflowY: 'auto', scrollbarWidth: 'thin' }}>
          <div className="d-flex justify-content-between ">
            <p style={{ fontSize: '20px', fontWeight: '700', marginLeft: '10px' }}>
              Train Chat Bot
            </p>
            <div className="d-flex flex-row">
              <img
                src={images.CMClose}
                onClick={() => setChatBotModal(false)}
                style={{
                  cursor: 'pointer',
                  height: 22,
                  width: 22,
                  marginInlineEnd: 8,
                }}
              ></img>
            </div>
          </div>
          <div style={{ marginLeft: '10px' }}>
            {' '}
            <p style={{ fontWeight: '400', fontSize: '16px', color: 'grey' }}>
              In order to train your chat bot you’re required to enter{' '}
              <strong style={{ color: 'black' }}>gcloud auth print-access-token</strong>{' '}
            </p>
          </div>
          <div class="form-group mt-4" style={{ marginLeft: '10px' }}>
            <label
              for="exampleFormControlInput1"
              style={{ fontWeight: '700', fontSize: '14px', color: 'black' }}
            >
              Token
            </label>
            <input
              class="form-control shadow-none"
              onChange={(e) => setgAuthToken(e.target.value)}
              placeholder="paste token"
              style={{ width: '93%', borderRadius: '8px' }}
            />
          </div>
          <div style={{ marginLeft: '10px', marginTop: '30px' }}>
            <CheerioButton
              className="button"
              borderStyle={{
                height: '36px',
                borderRadius: '9px',
                width: '93%',
                marginBlockStart: 12,
                marginBlockEnd: 8,
              }}
              textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
              btnText={'Authenticate token'}
              backColor={'#000000'}
              onclick={() => {
                setChatBotModal(false);
                TrainChatBotAPIcall();
                //   if(!checkDetails){
                //    dialogFlowApiCall();}
              }}
            />
          </div>
          <div style={{ marginLeft: '10px', marginTop: '40px' }}>
            <p style={{ fontSize: '16px', fontWeight: '700' }}>
              Didn’t find access token? Watch video.
            </p>
          </div>
          <div style={{ marginTop: '2%', paddingTop: '20px' }}>
            <div style={{ width: '90%', height: '10%' }}>
              <video
                style={{
                  border: '1px solid #CCCCCC',
                  borderRadius: '12px',
                  marginLeft: '10px',
                  width: '100%',
                  height: '70%',
                }}
                controls="controls"
              >
                <source src={video1} type="video/mp4" />
              </video>
            </div>
          </div>{' '}
        </Modal.Body>
      </Modal>

      {/* Modal for Train Bot success */}

      <Modal
        show={successModal}
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="my-modal"
        contentClassName="Train-chatbot"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between ">
            <p style={{ fontSize: '20px', fontWeight: '700', marginLeft: '10px' }}>
              Train Chat Bot
            </p>
            <div className="d-flex flex-row">
              <img
                src={images.CMClose}
                onClick={() => setSuccessModal(false)}
                style={{
                  cursor: 'pointer',
                  height: 22,
                  width: 22,
                  marginInlineEnd: 8,
                }}
              ></img>
            </div>
          </div>
          <div className="d-flex align-items-center flex-column" style={{ marginTop: '50px' }}>
            <img
              src={images.CircleCheckGreen}
              style={{
                alignItems: 'center',
                marginInlineEnd: 8,
              }}
            ></img>
            <p style={{ fontWeight: '700', fontSize: '16px', marginTop: '20px' }}>
              Chat Bot trained successfully
            </p>
            <p style={{ fontSize: '14px', color: 'grey' }}>
              Congratulation! Your chat bot is ready for automation.
            </p>
          </div>

          <div style={{ marginLeft: '10px', marginTop: '30px' }}>
            <CheerioButton
              className="button"
              borderStyle={{
                height: '36px',
                borderRadius: '9px',
                width: '95%',
                marginBlockStart: 12,
                marginBlockEnd: 8,
                marginBottom: '20px',
              }}
              textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
              btnText={'Close'}
              backColor={'#000000'}
              onclick={() => {
                navigate('/creator');
              }}
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal for Successfully Saved Changes */}

      <Modal
        show={false}
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="my-modal"
        contentClassName="Train-chatbot"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between ">
            <p style={{ fontSize: '20px', fontWeight: '700', marginLeft: '10px' }}></p>
            <div className="d-flex flex-row">
              <img
                src={images.CMClose}
                onClick={() => setSuccessModal(false)}
                style={{
                  cursor: 'pointer',
                  height: 22,
                  width: 22,
                  marginInlineEnd: 8,
                }}
              ></img>
            </div>
          </div>
          <div className="d-flex align-items-center flex-column" style={{ marginTop: '50px' }}>
            <img
              src={images.CircleCheckGreen}
              style={{
                alignItems: 'center',
                marginInlineEnd: 8,
              }}
            ></img>
            <p style={{ fontWeight: '700', fontSize: '16px', marginTop: '20px' }}>Saved</p>
            <p style={{ fontSize: '14px', color: 'grey' }}>Your changes are saved successfully</p>
          </div>

          <div style={{ marginLeft: '10px', marginTop: '30px' }}>
            <CheerioButton
              className="button"
              borderStyle={{
                height: '36px',
                borderRadius: '9px',
                width: '95%',
                marginBlockStart: 12,
                marginBlockEnd: 8,
                marginBottom: '20px',
              }}
              textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
              btnText={'Close'}
              backColor={'#000000'}
              onclick={() => {
                navigate('/creator');
              }}
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal for Integrating dialogue flow */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="my-modal"
        contentClassName="chatflow-modal-height"
      >
        <Modal.Body style={{ overflowY: 'auto', scrollbarWidth: 'thin' }}>
          <div className="d-flex justify-content-between ]">
            <p style={{ fontSize: '16px', fontWeight: '700', marginLeft: '10px' }}>
              Integrate your Dialog Flow chat bot
            </p>
            <div className="d-flex flex-row">
              <img
                src={images.CMClose}
                onClick={() => setShowModal(false)}
                style={{
                  cursor: 'pointer',
                  height: 22,
                  width: 22,
                  marginInlineEnd: 8,
                }}
              ></img>
            </div>
          </div>
          <div style={{ marginLeft: '10px' }}>
            {' '}
            <p style={{ fontWeight: '400', fontSize: '16px', color: 'grey' }}>
              Upload a your Google dailog flow chat bot key here.
            </p>
          </div>
          <div
            className="mx-2"
            style={{
              border: userKey ? '' : '2px dashed #B3B3B3',
              borderRadius: '8px',
              marginTop: '40px',
            }}
          >
            <DropzoneCustom handleCallback={UserKey}></DropzoneCustom>
          </div>
          <div className="d-flex flex-row-reverse">
            <CheerioButton
              className="button"
              borderStyle={{
                height: '36px',
                borderRadius: '9px',
                width: '165px',
                marginBlockStart: 12,
                marginBlockEnd: 8,
              }}
              textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
              btnText={'Close'}
              backColor={'#000000'}
              onclick={() => {
                setShowModal(false);
              }}
            />
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ marginTop: '2%', paddingTop: '20px' }}
          >
            <div style={{ width: '30%', height: '20%' }}>
              <video
                style={{
                  border: '1px solid #CCCCCC',
                  borderRadius: '12px',
                  marginLeft: '30px',
                  width: '80%',
                  height: '70%',
                }}
                controls="controls"
              >
                <source src={video1} type="video/mp4" />
              </video>
              <p
                style={{
                  marginLeft: '60px',
                  fontWeight: '400',
                  fontSize: '16px',
                  marginTop: '10px',
                }}
              >
                1. Create you own Chatbot
              </p>
            </div>

            <div style={{ width: '30%', height: '20%' }}>
              <video
                style={{
                  al: 'center',
                  border: '1px solid #CCCCCC',
                  borderRadius: '12px',
                  width: '80%',
                }}
                height="70%"
                controls="controls"
              >
                <source src={video2} type="video/mp4" />
              </video>
              <p
                style={{
                  marginLeft: '60px',
                  fontWeight: '400',
                  fontSize: '16px',
                  marginTop: '10px',
                }}
              >
                2. Generate Key
              </p>
            </div>

            <div style={{ width: '25%', marginRight: '10px' }}>
              {/*<video style={{border:"2px solid green",width:"80%"}} height="70%" controls="controls">   */}
              {/* <source src={'https://drive.google.com/file/d/12_tMyCHVvS2iSnc3NFcayijO_4REWOeY/view?usp=drive_link'} type='video/mp4'/></video><p>3. Connect & test your chatbot</p></div> */}
            </div>
          </div>{' '}
        </Modal.Body>
      </Modal>

      {/* New Div Element */}
      <div
        className="my-3 pt-2 px-3"
        style={{
          border: '1px solid #E6E6E6',
          marginLeft: '20px',
          marginRight: '20px',
          borderRadius: '16px',
          paddingBottom: '6%',
          paddingLeft: '10%',
          backgroundColor: 'white',
        }}
      >
        <div class="form-group mt-4">
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
          >
            Chatbot Key
          </label>
          <input
            class="form-control"
            value={chatBotKey ? chatBotKey : ''}
            style={{ width: '502px' }}
            disabled
          />
          {checkDetails && (
            <p style={{ color: '#666666', fontWeight: '400', fontSize: '16px' }}>
              Last updated on {time ? time : ''}{' '}
            </p>
          )}{' '}
        </div>
        <div style={{ marginTop: '30px' }}>
          <CheerioButton
            className="button"
            borderStyle={{
              height: '36px',
              borderRadius: '9px',
              width: '165px',
              marginBlockStart: 12,
              marginBlockEnd: 8,
            }}
            textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
            btnText={checkDetails ? 'Change Key' : 'Upload key'}
            backColor={'#000000'}
            onclick={() => setShowModal(true)}
          />
        </div>
      </div>

      {/* New div Element */}
      <div
        className="pt-2 px-3"
        style={{
          border: '1px solid #E6E6E6',
          marginLeft: '20px',
          marginRight: '20px',
          borderRadius: '16px',
          height: '480px',
          backgroundColor: 'white',
        }}
      >
        <div style={{ fontWeight: '700', fontSize: '16px', color: 'black', marginTop: '10px' }}>
          Working Hours
        </div>

        <div class="form-group mt-4">
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
          >
            Office Start Time
          </label>
          <input
            disabled={checkDetails}
            value={offStartTime}
            onChange={(e) => {
              setOffStartTime(e.target.value);
            }}
            class="form-control shadow-none"
            placeholder="09:00AM"
            style={{ width: '502px' }}
          />
        </div>
        <div class="form-group mt-3">
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
          >
            Office End Time
          </label>
          <input
            disabled={checkDetails}
            value={offEndTime}
            onChange={(e) => {
              setOffEndTime(e.target.value);
            }}
            class="form-control shadow-none"
            placeholder="06:00AM"
            style={{ width: '502px' }}
          />
        </div>
        <div className="mt-3">
          <label style={{ fontSize: '16px', fontWeight: '600' }}>Working days</label>
        </div>
        <div class="btn-group mt-3" id="workingButton" role="group" data-bs-toggle="button">
          <input
            type="checkbox"
            disabled={checkDetails}
            class="btn-check"
            id="btncheck1"
            value="Monday"
            autocomplete="off"
            onChange={(e) => handleWorkingDays(e)}
          />
          <label
            class="btn mx-2 rounded-5 d-flex align-items-center"
            for="btncheck1"
            style={{
              height: '32px',
              width: '32px',
              fontSize: '16px',
              fontWeight: '600',
              color: 'black',
              backgroundColor: '#ECE1FF',
              border: '1px solid black',
              paddingLeft: '9px',
            }}
          >
            M
          </label>

          <input
            type="checkbox"
            disabled={checkDetails}
            class="btn-check"
            id="btncheck2"
            autocomplete="off"
            value="Tuesday"
            onChange={(e) => handleWorkingDays(e)}
          />
          <label
            class="btn mx-2 rounded-5 d-flex align-items-center"
            for="btncheck2"
            style={{
              height: '32px',
              width: '32px',
              paddingLeft: '11px',
              fontSize: '16px',
              fontWeight: '600',
              color: 'black',
              backgroundColor: '#ECE1FF',
              border: '1px solid black',
            }}
          >
            T
          </label>

          <input
            type="checkbox"
            disabled={checkDetails}
            class="btn-check"
            id="btncheck3"
            value="Wednesday"
            onChange={(e) => handleWorkingDays(e)}
            autocomplete="off"
          />
          <label
            class="btn mx-2 rounded-5 d-flex align-items-center"
            for="btncheck3"
            style={{
              height: '32px',
              width: '32px',
              paddingLeft: '9px',
              fontSize: '16px',
              fontWeight: '600',
              color: 'black',
              backgroundColor: '#ECE1FF',
              border: '1px solid black',
            }}
          >
            W
          </label>

          <input
            type="checkbox"
            disabled={checkDetails}
            class="btn-check"
            id="btncheck4"
            value="Thrusday"
            onChange={(e) => handleWorkingDays(e)}
            autocomplete="off"
          />
          <label
            class="btn mx-2 rounded-5 d-flex align-items-center"
            for="btncheck4"
            style={{
              height: '32px',
              width: '32px',
              paddingLeft: '12px',
              fontSize: '16px',
              fontWeight: '600',
              color: 'black',
              backgroundColor: '#ECE1FF',
              border: '1px solid black',
            }}
          >
            T
          </label>

          <input
            type="checkbox"
            disabled={checkDetails}
            class="btn-check"
            id="btncheck5"
            value="Friday"
            onChange={(e) => handleWorkingDays(e)}
            autocomplete="off"
          />
          <label
            class="btn  mx-2 rounded-5 d-flex align-items-center"
            for="btncheck5"
            style={{
              height: '32px',
              width: '32px',
              paddingLeft: '12px',
              fontSize: '16px',
              fontWeight: '600',
              color: 'black',
              backgroundColor: '#ECE1FF',
              border: '1px solid black',
            }}
          >
            F
          </label>

          <input
            type="checkbox"
            disabled={checkDetails}
            class="btn-check"
            id="btncheck6"
            value="Saturday"
            onChange={(e) => handleWorkingDays(e)}
            autocomplete="off"
          />
          <label
            class="btn mx-2 rounded-5 d-flex align-items-center"
            for="btncheck6"
            style={{
              height: '32px',
              width: '32px',
              paddingLeft: '12px',
              fontSize: '16px',
              fontWeight: '600',
              color: 'black',
              backgroundColor: '#F5F5F5',
              border: '1px solid black',
            }}
          >
            S
          </label>

          <input
            type="checkbox"
            disabled={checkDetails}
            class="btn-check"
            id="btncheck7"
            value="Sunday"
            onChange={(e) => handleWorkingDays(e)}
            autocomplete="off"
          />
          <label
            class="btn mx-2 rounded-5 d-flex align-items-center"
            for="btncheck7"
            style={{
              height: '32px',
              width: '32px',
              paddingLeft: '12px',
              fontSize: '16px',
              fontWeight: '600',
              color: 'black',
              backgroundColor: '#F5F5F5',
              border: '0px',
            }}
          >
            S
          </label>
        </div>
        <hr class="hr" />
        <div style={{ fontSize: '16px', fontWeight: '700', color: 'black', marginBottom: '20px' }}>
          Auto reply after
        </div>
        <div style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}>Select timing</div>
        <div style={{ marginBottom: '40px' }}>
          <select
            disabled={checkDetails}
            onChange={(e) => {
              setTiming(e.target.value);
            }}
            class="form-select  shadow-none"
            aria-label="Default select example"
            style={{ width: '502px', fontWeight: '400', fontSize: '16px' }}
          >
            <option selected>Immediately</option>
            {/* <option>One minute</option>
  <option >Five minutes</option>
  <option >Ten minutes</option> */}
          </select>
        </div>
      </div>
      {/* New div element */}
      <div
        className="my-3 pt-2 px-3"
        style={{
          border: '1px solid #E6E6E6',
          marginLeft: '20px',
          marginRight: '20px',
          borderRadius: '16px',
          paddingBottom: '30px',
          backgroundColor: 'white',
        }}
      >
        <div style={{ fontWeight: '700', fontSize: '16px', color: 'black', marginTop: '10px' }}>
          Support Contact details
        </div>

        <div class="form-group  shadow-none mt-4">
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
          >
            Support Email ID
          </label>
          <input
            disabled={checkDetails}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            class="form-control  shadow-none"
            placeholder="Enter here"
            style={{ width: '502px' }}
          />
        </div>
        <div class="form-group mt-3">
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
          >
            Support Contact no.
          </label>
          <input
            disabled={checkDetails}
            value={contact}
            maxLength={10}
            onChange={(e) => {
              setContact(e.target.value);
            }}
            class="form-control  shadow-none"
            placeholder="Enter here"
            style={{ width: '502px' }}
          />
        </div>
        <div class="form-group mt-3" style={{ marginBottom: '10px' }}>
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
          >
            Website
          </label>
          <input
            disabled={checkDetails}
            value={website}
            onChange={(e) => {
              setWebsite(e.target.value);
            }}
            class="form-control shadow-none"
            placeholder="Enter website url here"
            style={{ width: '502px' }}
          />
        </div>
      </div>
      {/* New div element */}

      <div
        className="pt-2 px-3 my-3"
        style={{
          border: '1px solid #E6E6E6',
          marginLeft: '20px',
          marginRight: '20px',
          borderRadius: '16px',
          paddingBottom: '30px',
          backgroundColor: 'white',
        }}
      >
        <div style={{ fontWeight: '700', fontSize: '16px', color: 'black', marginTop: '10px' }}>
          Shipping & Refund days
        </div>

        {/* <div className='mt-3'><label style={{fontSize:"16px",fontWeight:"600"}}>Shipping</label></div>
  <div class="btn-group mt-3" data-bs-toggle="button">
  <input type="checkbox" class="btn-check" id="btncheck8" value="Monday" onChange={(e)=>handleShippingDays(e)} autocomplete="off" />
  <label class="btn  mx-2 rounded-5 d-flex align-items-center" for="btncheck8" style={{height:"32px",width:"32px",fontSize:"16px",fontWeight:"600",color:"black",backgroundColor:"#ECE1FF",border:"0px",paddingLeft:"9px"}}>M</label>

  <input type="checkbox" class="btn-check" id="btncheck9" value="Tuesday" onChange={(e)=>handleShippingDays(e)} autocomplete="off"/>
  <label class="btn  mx-2 rounded-5 d-flex align-items-center" for="btncheck9" style={{height:"32px",width:"32px",paddingLeft:"12px",fontSize:"16px",fontWeight:"600",color:"black",backgroundColor:"#ECE1FF",border:"0px"}}>T</label>

  <input type="checkbox" class="btn-check" id="btncheck10" value="Wednesday" onChange={(e)=>handleShippingDays(e)} autocomplete="off"/>
  <label class="btn  mx-2 rounded-5 d-flex align-items-center" for="btncheck10" style={{height:"32px",width:"32px",paddingLeft:"9px",fontSize:"16px",fontWeight:"600",color:"black",backgroundColor:"#ECE1FF",border:"0px"}}>W</label>
  <input type="checkbox" class="btn-check" id="btncheck11" value="Thrusday" onChange={(e)=>handleShippingDays(e)} autocomplete="off"/>
  <label class="btn  mx-2 rounded-5 d-flex align-items-center" for="btncheck11" style={{height:"32px",width:"32px",paddingLeft:"12px",fontSize:"16px",fontWeight:"600",color:"black",backgroundColor:"#ECE1FF",border:"0px"}}>T</label>

  <input type="checkbox" class="btn-check" id="btncheck12" value="Friday" onChange={(e)=>handleShippingDays(e)} autocomplete="off"/>
  <label class="btn  mx-2 rounded-5 d-flex align-items-center" for="btncheck12" style={{height:"32px",width:"32px",paddingLeft:"12px",fontSize:"16px",fontWeight:"600",color:"black",backgroundColor:"#ECE1FF",border:"0px"}}>F</label>

  <input type="checkbox" class="btn-check" id="btncheck13" value="Saturday" onChange={(e)=>handleShippingDays(e)} autocomplete="off"/>
  <label class="btn  mx-2 rounded-5 d-flex align-items-center" for="btncheck13" style={{height:"32px",width:"32px",paddingLeft:"10px", fontSize:"16px",fontWeight:"600",color:"black",backgroundColor:"#F5F5F5",border:"0px"}}>S</label>
  
  <input type="checkbox" class="btn-check" id="btncheck14" value="Sunday" onChange={(e)=>handleShippingDays(e)} autocomplete="off"/>
  <label class="btn  mx-2 rounded-5 d-flex align-items-center" for="btncheck14" style={{height:"32px",width:"32px",paddingLeft:"10px",fontSize:"16px",fontWeight:"600",color:"black",backgroundColor:"#F5F5F5",border:"0px"}}>S</label>
</div> */}
        <div class="form-group mt-3">
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
          >
            Shipping Days
          </label>
          <input
            disabled={checkDetails}
            value={shippingDays}
            onChange={(e) => {
              setShippingDays(e.target.value);
            }}
            class="form-control shadow-none"
            placeholder="Enter number of days"
            style={{ width: '502px' }}
          />
        </div>

        {/* <div className='mt-3'><label style={{fontSize:"16px",fontWeight:"600",marginBottom:"20px"}}>Refund</label></div>
  <div class="btn-group" data-bs-toggle="button">
  <input type="checkbox" class="btn-check" id="btncheck15" value="Monday" onChange={(e)=>handleRefundDays(e)} autocomplete="off" />
  <label class="btn  mx-2 rounded-5 d-flex align-items-center" for="btncheck15" style={{height:"32px",width:"32px",fontSize:"16px",fontWeight:"600",color:"black",backgroundColor:"#ECE1FF",border:"0px",paddingLeft:"9px"}}>M</label>

  <input type="checkbox" class="btn-check" id="btncheck16" value="Tuesday" onChange={(e)=>handleRefundDays(e)} autocomplete="off"/>
  <label class="btn  mx-2 rounded-5 d-flex align-items-center" for="btncheck16" style={{height:"32px",width:"32px",paddingLeft:"12px",fontSize:"16px",fontWeight:"600",color:"black",backgroundColor:"#ECE1FF",border:"0px"}}>T</label>

  <input type="checkbox" class="btn-check" id="btncheck17" value="Wednesday" onChange={(e)=>handleRefundDays(e)} autocomplete="off"/>
  <label class="btn  mx-2 rounded-5 d-flex align-items-center" for="btncheck17" style={{height:"32px",width:"32px",paddingLeft:"9px",fontSize:"16px",fontWeight:"600",color:"black",backgroundColor:"#ECE1FF",border:"0px"}}>W</label>
  
  <input type="checkbox" class="btn-check" id="btncheck18" value="Thrusday" onChange={(e)=>handleRefundDays(e)} autocomplete="off"/>
  <label class="btn  mx-2 rounded-5 d-flex align-items-center" for="btncheck18" style={{height:"32px",width:"32px",paddingLeft:"12px",fontSize:"16px",fontWeight:"600",color:"black",backgroundColor:"#ECE1FF",border:"0px"}}>T</label>

  <input type="checkbox" class="btn-check" id="btncheck19" value="Friday" onChange={(e)=>handleRefundDays(e)} autocomplete="off"/>
  <label class="btn  mx-2 rounded-5 d-flex align-items-center" for="btncheck19" style={{height:"32px",width:"32px",paddingLeft:"12px",fontSize:"16px",fontWeight:"600",color:"black",backgroundColor:"#ECE1FF",border:"0px"}}>F</label>

  <input type="checkbox" class="btn-check" id="btncheck20" value="Saturday" onChange={(e)=>handleRefundDays(e)} autocomplete="off"/>
  <label class="btn  mx-2 rounded-5 d-flex align-items-center" for="btncheck20" style={{height:"32px",width:"32px",paddingLeft:"10px", fontSize:"16px",fontWeight:"600",color:"black",backgroundColor:"#F5F5F5",border:"0px"}}>S</label>
  
  <input type="checkbox" class="btn-check" id="btncheck21" value="Sunday" onChange={(e)=>handleRefundDays(e)} autocomplete="off"/>
  <label class="btn  mx-2 rounded-5 d-flex align-items-center" for="btncheck21" style={{height:"32px",width:"32px",paddingLeft:"10px",fontSize:"16px",fontWeight:"600",color:"black",backgroundColor:"#F5F5F5",border:"0px"}}>S</label>
</div> */}

        <div class="form-group mt-3">
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
          >
            Refund days
          </label>
          <input
            disabled={checkDetails}
            value={refundDays}
            onChange={(e) => {
              setRefundDays(e.target.value);
            }}
            class="form-control shadow-none"
            placeholder="Enter number of days"
            style={{ width: '502px' }}
          />
        </div>
      </div>
      {/* New Div element */}
      <div
        className="pt-2 px-3 my-3"
        style={{
          border: '1px solid #E6E6E6',
          marginLeft: '20px',
          marginRight: '20px',
          borderRadius: '16px',
          paddingBottom: '30px ',
          backgroundColor: 'white',
        }}
      >
        <div className="d-flex justify-content-between">
          <p style={{ fontWeight: '700', fontSize: '16px', color: 'black', marginTop: '10px' }}>
            Return policy
          </p>

          {returnPolicy ? (
            <CheerioButton
              className="button"
              borderStyle={{
                height: '36px',
                width: '165px',
                marginBlockStart: 12,
                marginBlockEnd: 8,
              }}
              textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
              btnText={'Add Text Manually'}
              backColor={'#000000'}
              onclick={() => setReturnPolicy(false)}
            />
          ) : (
            <CheerioButton
              className="button"
              borderStyle={{
                height: '36px',
                width: '165px',
                marginBlockStart: 12,
                marginBlockEnd: 8,
              }}
              disabled={checkDetails}
              textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
              btnText={'Upload File'}
              backColor={'#000000'}
              icon={images.UploadWhite}
              onclick={() => setReturnPolicy(true)}
            />
          )}
        </div>
        {returnPolicy ? (
          <div style={{ marginTop: '10px' }}>
            <p style={{ fontWeight: '600', fontSize: '14px' }}>
              Please upload your text file here, Cheerio will auto read text{' '}
            </p>
            <div
              style={{ border: returnPolicyFiles ? '' : '2px dashed #B3B3B3', borderRadius: '8px' }}
            >
              <DropzoneCustom handleCallback={returnPolicyData}></DropzoneCustom>
              {returnPolicyFiles && (
                <div style={{ paddingLeft: '20px' }}>
                  <p style={{ fontWeight: '600', fontSize: '16px' }}>File Text</p>
                  <div
                    style={{
                      border: '1px solid #999999',
                      borderRadius: '8px',
                      width: '100%',
                      maxHeight: '15em',
                      marginBottom: '30px',
                      overflowY: 'auto',
                    }}
                  >
                    <p
                      style={{
                        color: 'grey',
                        fontSize: '16px',
                        padding: '20px',
                        overflowY: 'auto',
                      }}
                    >
                      "{returnPolicyText}"
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div class="form-group" style={{ marginBottom: '20px' }}>
            <label
              style={{ fontSize: '16px', fontWeight: '600' }}
              for="exampleFormControlTextarea1"
            >
              Add text
            </label>
            <textarea
              class="form-control shadow-none"
              id="exampleFormControlTextarea1"
              rows="6"
              disabled={checkDetails}
              value={returnPolicyText}
              onChange={(e) => setReturnPolicyText(e.target.value)}
              placeholder="Thank you for your purchase! If for any reason you're not completely satisfied with your order,you may return it for a refund or exchange within 7 days from the date of delivery. To initiate a return, please contact our customer service team and provide your order number and reason for return."
            ></textarea>
          </div>
        )}
      </div>
      {/* New Div Element */}
      <div
        className="pt-2 px-3 my-3"
        style={{
          border: '1px solid #E6E6E6',
          marginLeft: '20px',
          marginRight: '20px',
          borderRadius: '16px',
          paddingBottom: '30px ',
          backgroundColor: 'white',
        }}
      >
        <div className="d-flex justify-content-between">
          <p style={{ fontWeight: '700', fontSize: '16px', color: 'black', marginTop: '10px' }}>
            Frequently asked questions
          </p>
        </div>

        {checkDetails ? (
          <div style={{ marginLeft: '2px', fontWeight: '600' }}>FAQ File: {faqFiles}</div>
        ) : (
          <div style={{ border: faqFiles ? '' : '2px dashed #B3B3B3', borderRadius: '8px' }}>
            <DropzoneCustom handleCallback={FaqData}></DropzoneCustom>
          </div>
        )}
        <div>
          <p
            style={{ textAlign: 'center', fontWeight: '600', fontSize: '16px', marginTop: '20px' }}
          >
            AND/OR
          </p>
        </div>
        <div class="form-group mt-3" style={{ marginBottom: '20px' }}>
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
          >
            Webpage link
          </label>
          <input
            disabled={checkDetails}
            value={faqWebPage}
            onChange={(e) => setFaqWebPage(e.target.value)}
            class="form-control shadow-none"
            placeholder="Paste link here"
            style={{}}
          />
        </div>
      </div>
      {/* New div Element */}
      <div
        className="pt-2 px-3 my-3"
        style={{
          border: '1px solid #E6E6E6',
          marginLeft: '20px',
          marginRight: '20px',
          borderRadius: '16px',
          paddingBottom: '30px ',
          backgroundColor: 'white',
        }}
      >
        <div>
          <p style={{ fontWeight: '700', fontSize: '16px', color: 'black', marginTop: '10px' }}>
            Chat supported queries document
          </p>
          <p style={{ fontWeight: '400', fontSize: '16px', color: 'grey' }}>
            Upload a chat support queries document so that chat bot get trained using your previous
            database. Upload .csv, .pdf, .doc files and tap train bot at the top.
          </p>
        </div>
        {checkDetails ? (
          <div style={{ marginLeft: '2px', fontWeight: '600' }}>Query File: {queryFiles}</div>
        ) : (
          <div
            style={{
              border: queryFiles ? '' : '2px dashed #B3B3B3',
              borderRadius: '8px',
              marginTop: '20px',
              marginBottom: '50px',
            }}
          >
            <DropzoneCustom handleCallback={queryData}></DropzoneCustom>
          </div>
        )}
      </div>
      <div
        className="d-flex flex-row-reverse"
        style={{ paddingRight: '20px', marginBottom: '20px' }}
      >
        {' '}
        <CheerioButton
          className="button"
          borderStyle={{
            height: '60%',
            width: '20%',
            marginBlockStart: 16,
            marginBlockEnd: 8,
            border: 'None',
            borderRadius: '10px',
          }}
          disabled={checkDetails}
          textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
          btnText={'Save & Train Chatbot'}
          backColor={'black'}
          onclick={() => {
            //console.log("chatbotkkkk",chatBotKey);
            if (chatBotKey) {
              if (ValidateEmail(email) && ValidateMobile(contact)) {
                dialogFlowApiCall();
              }
            } else {
              toast('Please upload Chatbot key', { position: 'top-center' });
            }
          }}
        />
      </div>
    </div>
  );
};
export default SetupBot;
