import React, { useEffect } from 'react';
import { useState } from 'react';
import TemplateSidemenu from './Components/TemplateSidemenu';
import MyTemplates from './Components/MyTemplates';
import ExploreTemplates2 from './Components/ExploreTemplates2';
import SubmitModal from './Components/SubmitModal';
import { useSelector } from 'react-redux';
import SuccessFailureModal from './Components/SuccessFailureModal';
import { Routes, Route, useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Dropdown, Toast } from 'react-bootstrap';
import { PremiumOptionModal } from '../../Components/Modal/PremiumOptionModal';
import { eventsend } from '../../Config/analyticsFunctions';
import colors from '../../Utils/colors';
import CheerioButton from '../../Components/CheerioButton';
import { Form, InputGroup } from 'react-bootstrap';
import WhatsAppTemplates from './Components/WhatsAppTemplates';
import EmailTemplates from './Components/EmailTemplates';
import EmailBuild from './Components/EmailBuild';
import SmsTemplate from './Components/SmsTemplates';
import { trackPages } from '../../Utils/brevo';
import MyDrafts from './Components/MyDrafts';
import { IoIosSearch } from 'react-icons/io';
import { RxCross1 } from 'react-icons/rx';
import { MenuItem, Select } from '@mui/material';

const Templates = () => {
  const navigate = useNavigate();
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const agentdata = useSelector((state) => state.main.agentdata);
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState('');
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [templateType, setTemplateType] = useState('whatsapp');
  const [isSFModalOpen, setIsSFModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [customTemplateCateogary, setCustomTemplateCateogary] = useState(false);

  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filterItem, setFilterItem] = useState('');
  const isPremiumUser = localdata?.premium;
  const [searchData, setSearchData] = useState('');

  const updateCustomTemplateCateogary = (value) => {
    setCustomTemplateCateogary(value);
  };
  useEffect(() => {
    eventsend('templates_pageview_web');
    if (agentdata && !agentdata?.permission?.campaign) {
      toast.error(
        'Feature access denied. Kindly request the owner to grant you permission to access this feature'
      );
      setTimeout(() => {
        navigate('/creator');
      }, 200);
    } else if (location.pathname === '/creator/templates/myTemplates') {
      setSelectedItem('My Templates');
    } else if (location.pathname === '/creator/templates/whatsappTemplates') {
      setSelectedItem('WhatsApp Templates');
    } else if (
      location.pathname === '/creator/templates' &&
      localdata?.whatsapp_config === 'Active'
    ) {
      setSelectedItem('My Templates');
    } else if (
      location.pathname === '/creator/templates' &&
      localdata?.whatsapp_config !== 'Active'
    ) {
      setSelectedItem('WhatsApp Templates');
    } else if (location.pathname === '/creator/templates/emailTemplates') {
      setSelectedItem('Email Templates');
    } else if (location.pathname === '/creator/templates/myDrafts') {
      setSelectedItem('My Drafts');
    }
  }, []);

  useEffect(() => {
    trackPages('templates_library_page', localdata);
  }, []);

  return (
    <>
      <div className="d-flex flex-column justify-content-between align-items-center w-100 h-100">
        {isSubmitModalOpen && (
          <SubmitModal
            setIsSubmitModalOpen={setIsSubmitModalOpen}
            templateType={templateType}
            onSuccessAction={() => {
              setIsSuccess(true);
              setIsSFModalOpen(true);
            }}
            onFailureAction={() => {
              setIsSuccess(false);
              setIsSFModalOpen(true);
            }}
          />
        )}
        {isSFModalOpen && (
          <SuccessFailureModal
            setIsSFModalOpen={setIsSFModalOpen}
            isSuccess={isSuccess}
            onButtonClickAction={() => {
              if (isSuccess) {
                setIsSubmitModalOpen(false);
                setIsSFModalOpen(false);
                setSelectedItem('My Templates');
              } else {
                setIsSFModalOpen(false);
              }
            }}
          />
        )}

        <div
          className="d-flex flex-row justify-content-between align-items-center w-100"
          style={{ marginBlock: 24, paddingInline: 24 }}
        >
          <h3 style={{ color: colors.black }}>{'Template Library'}</h3>
          <div
            className="d-flex flex-row justify-content-end align-items-center"
            style={{ width: '50%' }}
          >
            {selectedItem === 'My Templates' && (
              <div style={{ height: 36 }}>
                <Select
                  sx={{
                    height: 36,
                    fontSize: 14,
                    fontWeight: 400,
                    color: colors.greys04,
                    width: 'auto',
                    borderRadius: '8px',
                    borderColor: '#CCCCCC',
                    '& .MuiSvgIcon-root': {
                      color: colors.greys04,
                    },
                  }}
                  value={templateType}
                  onChange={(e) => setTemplateType(e.target.value)}
                >
                  <MenuItem value="whatsapp">WhatsApp Templates</MenuItem>
                  <MenuItem value="sms">SMS Templates</MenuItem>
                </Select>
              </div>
            )}
            {/* {templateType !== 'sms' && selectedItem === 'My Templates' && (
              <div style={{ height: '36px' }}>
                <InputGroup
                  className="btncustom"
                  style={{
                    width: 'fit-content',
                    height: '100%',
                  }}
                >
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      color: colors.black,
                      cursor: 'default',
                    }}
                  >
                    {'FILTER'}
                  </InputGroup.Text>
                  <Form.Select
                    className="btncustom"
                    aria-label="select campaign"
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      color: colors.greys04,
                      cursor: 'pointer',
                    }}
                    value={filterItem}
                    onChange={(e) => {
                      setFilterItem(e.target.value);
                    }}
                  >
                    <option value={''} selected>
                      {'All'}
                    </option>
                    <option value={'MARKETING'} key={'1'}>
                      Marketing
                    </option>
                    <option value={'AUTHENTICATION'} key={'2'}>
                      {' '}
                      Authentication
                    </option>
                    <option value={'UTILITY'} key={'3'}>
                      {' '}
                      Utility
                    </option>
                  </Form.Select>
                </InputGroup>
              </div>
            )} */}
            <div>
              <Dropdown
                style={{
                  marginInlineStart: 6,
                  borderRadius: '8px',
                  fontSize: '12px',
                }}
              >
                <Dropdown.Toggle
                  style={{
                    backgroundColor: '#8E53F7',
                    borderRadius: '8px',
                    fontSize: '13px',
                    border: '1px solid #8E53F7',
                  }}
                  variant="success"
                  id="dropdown-basic"
                  // onClick={() => {
                  //   eventsend("Create_template_ Template_library_clicked");
                  //   console.log('Dropdown.Toggle clicked!');
                  // }}
                >
                  Create a template{' '}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    borderRadius: 8,
                    border: 'none',
                    backgroundColor: 'white',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.3)',
                  }}
                >
                  {localdata?.whatsapp_config === 'Active' && (
                    <Dropdown.Item
                      onClick={() => {
                        eventsend('Create_template_ Template_library_clicked');

                        eventsend(
                          'WhatsApp_template_ Create_template_list_Template_library_clicked'
                        );
                        navigate('/creator/templates/create');
                      }}
                    >
                      WhatsApp Template
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() => {
                      eventsend('Create_template_ Template_library_clicked');

                      eventsend('Email_template_ Create_template_list_Template_library_clicked');
                      navigate('/creator/design');
                    }}
                  >
                    Email Template
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* {localdata?.whatsapp_config === 'Active' && (
              <CheerioButton
                borderStyle={{ marginInlineStart: 16 }}
                textStyle={{ color: colors.white }}
                btnText={'Create template'}
                backColor={colors.black}
                onclick={() => {
                  navigate('/creator/templates/create');
                }}
              />
            )} */}
          </div>
        </div>

        <div
          className="d-flex flex-row justify-content-start align-items-center w-100"
          style={{
            borderBottom: `1px solid ${colors.borderwhite}`,
            paddingInline: 24,
          }}
        >
          {localdata?.whatsapp_config === 'Active' && (
            <div
              className="d-flex justify-content-center align-items-center py-2 px-4"
              style={{
                borderBottom:
                  selectedItem === 'My Templates' ? `3px solid ${colors.primary03}` : '',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSelectedItem('My Templates');
                navigate('/creator/templates/myTemplates');
              }}
            >
              <h4>{'My Templates'}</h4>
            </div>
          )}
          <div
            className="d-flex justify-content-center align-items-center py-2 px-4"
            style={{
              borderBottom:
                selectedItem === 'WhatsApp Templates' ? `3px solid ${colors.primary03}` : '',
              cursor: 'pointer',
            }}
            onClick={() => {
              setSelectedItem('WhatsApp Templates');
              navigate('/creator/templates/whatsappTemplates');
            }}
          >
            <h4>{'WhatsApp Templates'}</h4>
          </div>

          <div
            className="d-flex justify-content-center align-items-center py-2 px-4"
            style={{
              borderBottom:
                selectedItem === 'Email Templates' ? `3px solid ${colors.primary03}` : '',
              cursor: 'pointer',
            }}
            onClick={() => {
              setSelectedItem('Email Templates');
              navigate('/creator/templates/emailTemplates');
            }}
          >
            <h4>{'Email Templates'}</h4>
          </div>
          {localdata?.whatsapp_config === 'Active' && (
            <div
              className="d-flex justify-content-center align-items-center py-2 px-4"
              style={{
                borderBottom: selectedItem === 'My Drafts' ? `3px solid ${colors.primary03}` : '',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSelectedItem('My Drafts');
                navigate('/creator/templates/myDrafts');
              }}
            >
              <h4>{'My Drafts'}</h4>
            </div>
          )}
        </div>
        {selectedItem === 'WhatsApp Templates' && <WhatsAppTemplates />}
        {selectedItem === 'Email Templates' && <EmailTemplates />}
        <div
          className="d-flex flex-column justify-content-start align-items-center w-100"
          style={{ flex: 1, overflowY: 'auto' }}
        >
          {templateType === 'sms' ? (
            <SmsTemplate />
          ) : (
            <>
              {selectedItem === 'My Templates' && localdata?.whatsapp_config === 'Active' && (
                <MyTemplates
                  setSelectedItem={setSelectedItem}
                  createTemplateAction={() => {
                    setIsSFModalOpen(true);
                  }}
                  searchData={searchData}
                  setSearchData={setSearchData}
                  customTemplateCateogary={customTemplateCateogary}
                  updateCustomTemplateCateogary={updateCustomTemplateCateogary}
                  filterItem={filterItem}
                  setFilterItem={setFilterItem}
                />
              )}
              {selectedItem === 'WhatsApp Templates' && <WhatsAppTemplates />}
              {selectedItem === 'Email Templates' && <EmailTemplates />}
              {selectedItem === 'My Drafts' && <MyDrafts />}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Templates;
