import React from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import images from '../../../Utils/images'
import colors from '../../../Utils/colors'
import CheerioButton from '../../../Components/CheerioButton'


const AppSumoReviewModal = ({ showreviewModal, setShowreviewModal }) => {
    const navigate = useNavigate()

    const handleClose = () => {
        sessionStorage.setItem('reviewClose', true)
        setShowreviewModal(false)
    }
    return (
        <div>
            <Modal
                show={showreviewModal}
                onHide={handleClose}
                className="custom-modal"
                centered

            >
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: "3%", marginBottom: 0 }}>
                    <h3 style={{
                        margin: '2%',
                        fontWeight: 'bold'
                    }}>Enjoying Cheerio?</h3>
                    <img
                        src={images.CMClose}
                        style={{
                            cursor: 'pointer',
                            height: 22,
                            width: 22,
                            margin: '2%',
                        }}
                        onClick={handleClose}
                    />
                </div>
                <hr style={{
                    marginLeft: '3%',
                    marginRight: '3%',
                    marginTop: "1%"
                }} />
                <Modal.Body
                    style={{
                        padding: '4%'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '4%',
                            gap: 20,
                            marginTop: 20
                        }}
                    >
                        <img
                            src={images.ReviewImage}
                            style={{
                                height: 150,
                                // width: 100,
                            }}
                        ></img>
                        <span
                            style={{
                                fontSize: 16,
                                textAlign: "center"
                            }}
                        >
                            Please take a moment to review us on AppSumo
                        </span>
                    </div>
                    <div style={{
                        marginTop: 30,
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <CheerioButton
                            btnText={"Rate now"}
                            backColor={"#8E53F7"}
                            textStyle={{ color: colors.white }}
                            borderStyle={{ borderColor: colors.white, width: '45%' }}
                            onclick={() => {
                                localStorage.setItem('reviewed', true)
                                window.open("https://appsumo.com/products/cheerio/?p=1#reviews", "_blank")
                                setShowreviewModal(false)
                            }}
                        />
                        <CheerioButton
                            btnText={"Remind me later"}
                            backColor={"#FFF"}
                            textStyle={{ color: colors.black }}
                            borderStyle={{ borderColor: colors.black, width: '45%' }}
                            onclick={() => {
                                handleClose()
                            }}
                        />
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AppSumoReviewModal