import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import colors from '../../../Utils/colors';
import { getClientAttributes } from '../../../Services';
import { useSelector } from 'react-redux';
import PreviewCustomTemplate from '../../CustomTemplate/Preview';
const WAPaymentModal = ({ data, setData, setBtnDisabled }) => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const [productType, setProductType] = useState('');
    const [chargeTax, setChargeTax] = useState(false);
    const [discountPresent, setDiscountPresent] = useState(false);
    const [shippingOk, setShippingOk] = useState(true);
    const [bodyOk, setBodyOk] = useState(false);
    const [taxOk, setTaxOk] = useState(true);
    const [discountOk, setDiscountOk] = useState(true);
    const [taxRate, setTaxRate] = useState(0);
    const [discountRate, setDiscountRate] = useState(0);
    const [physicalGoods, setPhysicalGoods] = useState(false);
    const [shippingCost, setShippingCost] = useState(0);
    const [cityAttribute, setCityAttribute] = useState("");
    const [stateAttribute, setStateAttribute] = useState("");
    const [pincodeAttribute, setPincodeAttribute] = useState("");
    const [addressLine1Attribute, setAddressLine1Attribute] = useState("");
    const [addressLine2Attribute, setAddressLine2Attribute] = useState("");
    const [inclusiveOfTax, setInclusiveOfTax] = useState("");
    const [discountAdded, setDiscountAdded] = useState("");
    const [bodyText, setBodyText] = useState('');
    const [footerText, setFooterText] = useState('');
    const [headerText, setHeaderText] = useState('');
    const [allAttributes, setAllAttributes] = useState([]);

    useEffect(() => {
        const indAttributesAPIcall = () => {
            let token = authtoken;
            getClientAttributes(token).then((res) => {
                if (res?.flag) {
                    const attributes = res.data.map((item) => item.attribute);
                    setAllAttributes(attributes);
                } else {
                    // console.log("ind attributes err ---> ",res)
                }
            });
        };
        indAttributesAPIcall();
    }, []);

    useEffect(() => {
        console.log("All Attributes", allAttributes);
    }, [allAttributes])

    useEffect(() => {
        if (physicalGoods) {
            if (cityAttribute && stateAttribute && addressLine1Attribute && pincodeAttribute && addressLine2Attribute && shippingCost) {
                setShippingOk(true);
            } else {
                setShippingOk(false);
            }
        } else {
            setShippingOk(true);
        }
    }, [physicalGoods, cityAttribute, stateAttribute, addressLine1Attribute, pincodeAttribute, addressLine2Attribute, shippingCost])

    useEffect(() => {
        if (chargeTax) {
            if (taxRate) {
                setTaxOk(true);
            } else {
                setTaxOk(false);
            }
        } else {
            setTaxOk(true);
        }
    }, [chargeTax, taxRate])

    useEffect(() => {
        if (discountPresent) {
            if (discountRate) {
                setDiscountOk(true);
            } else {
                setDiscountOk(false);
            }
        } else {
            setDiscountOk(true);
        }
    }, [discountPresent, discountRate])

    useEffect(() => {
        if (bodyText) {
            setBodyOk(true);
        }
    }, [bodyText])

    useEffect(() => {
        if (data?.taxExcluded) {
            setChargeTax(true);
            setTaxRate(data?.taxRate);
            setInclusiveOfTax("no");
        } else {
            setChargeTax(false);
        }
        if (data?.discountPresent) {
            setDiscountPresent(true);
            setDiscountRate(data?.discountRate);
            setDiscountAdded("yes");
        } else {
            setDiscountPresent(false);
        }
        if (data?.physicalGoods) {
            setPhysicalGoods(true);
            setShippingCost(data?.shippingCost);
            setCityAttribute(data?.cityAttribute);
            setStateAttribute(data?.stateAttribute);
            setPincodeAttribute(data?.pincodeAttribute);
            setAddressLine1Attribute(data?.beneficiaryAddressLine1);
            setAddressLine2Attribute(data?.beneficiaryAddressLine2);
        }
        if (data?.orderDetailsMessage) {
            setBodyText(data?.orderDetailsMessage);
        }
        if (data?.footerText) {
            setFooterText(data?.footerText);
        }
        if (data?.headerText) {
            setHeaderText(data?.headerText);
        }
    }, [])

    useEffect(() => {
        setData({ orderDetailsMessage: bodyText, taxExcluded: chargeTax, taxRate: taxRate, discountPresent: discountPresent, discountRate: discountRate, physicalGoods: physicalGoods, footerText: footerText, headerText: headerText, beneficiaryAddressLine1: addressLine1Attribute, beneficiaryAddressLine2: addressLine2Attribute, cityAttribute: cityAttribute, stateAttribute: stateAttribute, shippingCost: shippingCost, pincodeAttribute: pincodeAttribute })
        if (bodyOk && shippingOk && taxOk && discountOk) {
            setBtnDisabled(false);
        } else {
            setBtnDisabled(true);
        }
    }, [bodyOk, shippingOk, taxOk, footerText, discountOk, bodyText, taxRate, chargeTax, discountPresent, physicalGoods, discountRate, shippingCost, addressLine1Attribute, addressLine2Attribute, cityAttribute, stateAttribute, pincodeAttribute, headerText]);



    const handleModeChange = (e) => {
        setProductType(e.target.value);
        if (e.target.value === "physical") {
            setPhysicalGoods(true);
        } else {
            setPhysicalGoods(false);
        }
    };

    const handleTaxModeChange = (e) => {
        setInclusiveOfTax(e.target.value);
        if (e.target.value === "yes") {
            setChargeTax(false);
        } else {
            setChargeTax(true);
        }
    };

    const handleDiscountModeChange = (e) => {
        setDiscountAdded(e.target.value);
        if (e.target.value === "yes") {
            setDiscountPresent(true);
        } else {
            setDiscountPresent(false);
        }
    };

    const handleBodyText = (e) => {
        setBodyText(e.target.value);
    };

    const handleFooterText = (e) => {
        setFooterText(e.target.value);
    };

    const handleHeaderText = (e) => {
        setHeaderText(e.target.value);
    }

    const handleAddressAttribute1Change = (event) => {
        setAddressLine1Attribute(event.target.value);
    };

    const handleAddressAttribute2Change = (event) => {
        setAddressLine2Attribute(event.target.value);
    };

    const handleCityAttributeChange = (event) => {
        setCityAttribute(event.target.value);
    };

    const handleStateAttributeChange = (event) => {
        setStateAttribute(event.target.value);
    };

    const handlePincodeAttributeChange = (event) => {
        setPincodeAttribute(event.target.value);
    };

    const handleShippingCostChange = (event) => {
        setShippingCost(event.target.value);
    };

    const handleTaxRateChange = (event) => {
        setTaxRate(event.target.value);
    };

    const handleDiscountRateChange = (event) => {
        setDiscountRate(event.target.value);
    };

    return (
        <div>
            <Form.Group className="mb-3" style={{ width: '100%', marginBlock: 16 }}>
                <Form.Label htmlFor="dropdown" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                    Product Type
                </Form.Label>
                <InputGroup>
                    <Form.Select
                        id="dropdown"
                        className='w-100 btncustom dropdown'
                        style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                        onChange={handleModeChange}
                        value={productType}
                    >
                        <option disabled value="">
                            Select
                        </option>
                        <option value={'digital'} key={'Digital'}>
                            {'Digital Goods'}
                        </option>
                        <option value={'physical'} key={'Physical'}>
                            {'Physical'}
                        </option>
                    </Form.Select>
                </InputGroup>

            </Form.Group>
            {productType === 'physical' && (
                <div>
                    <Form.Group className="mb-3" style={{ width: '100%', marginBlock: 16 }}>
                        <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                            Benificiary Address Line 1 Attribute:
                        </Form.Label>
                        <Form.Select
                            id="dropdown2"
                            className='w-100 btncustom dropdown'
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                            onChange={handleAddressAttribute1Change}
                            value={addressLine1Attribute}
                        >
                            <option disabled value="">
                                Select
                            </option>
                            {allAttributes.map((attribute, index) => {
                                console.log("attribute", attribute)
                                return (<option key={index} value={attribute} >
                                    {attribute}
                                </option>)
                            })}
                        </Form.Select>
                        <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                            Benificiary Address Line 2 Attribute:
                        </Form.Label>
                        <Form.Select
                            id="dropdown3"
                            className='w-100 btncustom dropdown'
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                            onChange={handleAddressAttribute2Change}
                            value={addressLine2Attribute}
                        >
                            <option disabled value="">
                                Select
                            </option>
                            {allAttributes.map((attribute, index) => {
                                console.log("attribute", attribute)
                                return (<option key={index} value={attribute} >
                                    {attribute}
                                </option>)
                            })}
                        </Form.Select>
                        <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                            City Attribute:
                        </Form.Label>
                        <Form.Select
                            id="dropdown4"
                            className='w-100 btncustom dropdown'
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                            onChange={handleCityAttributeChange}
                            value={cityAttribute}
                        >
                            <option disabled value="">
                                Select
                            </option>
                            {allAttributes.map((attribute, index) => {
                                console.log("attribute", attribute)
                                return (<option key={index} value={attribute} >
                                    {attribute}
                                </option>)
                            })}
                        </Form.Select>
                        <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                            State Attribute:
                        </Form.Label>
                        <Form.Select
                            id="dropdown5"
                            className='w-100 btncustom dropdown'
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                            onChange={handleStateAttributeChange}
                            value={stateAttribute}
                        >
                            <option disabled value="">
                                Select
                            </option>
                            {allAttributes.map((attribute, index) => {
                                console.log("attribute", attribute)
                                return (<option key={index} value={attribute} >
                                    {attribute}
                                </option>)
                            })}
                        </Form.Select>
                        <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                            Pincode Attribute:
                        </Form.Label>
                        <Form.Select
                            id="dropdown6"
                            className='w-100 btncustom dropdown'
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                            onChange={handlePincodeAttributeChange}
                            value={pincodeAttribute}
                        >
                            <option disabled value="">
                                Select
                            </option>
                            {allAttributes.map((attribute, index) => {
                                console.log("attribute", attribute)
                                return (<option key={index} value={attribute} >
                                    {attribute}
                                </option>)
                            })}
                        </Form.Select>
                        <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                            Set Shipping Cost:
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                id="textInput"
                                type="number"
                                className="w-100 btncustom"
                                style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                                onChange={handleShippingCostChange}
                                value={shippingCost}
                                step="0.01"
                                min="0"
                            />
                        </InputGroup>
                    </Form.Group>
                </div>
            )
            }
            <div>
                <Form.Group>
                    <Form.Label htmlFor="dropdown" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                        Inclusive of all taxes
                    </Form.Label>
                    <InputGroup>
                        <Form.Select
                            id="dropdown"
                            className='w-100 btncustom dropdown'
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                            onChange={handleTaxModeChange}
                            value={inclusiveOfTax}
                        >
                            <option disabled value="">
                                Select
                            </option>
                            <option value={'yes'} key={'Yes'}>
                                {'Yes'}
                            </option>
                            <option value={'no'} key={'no'}>
                                {'No'}
                            </option>
                        </Form.Select>
                    </InputGroup>
                </Form.Group>
            </div>
            {
                inclusiveOfTax === 'no' && (
                    <div>
                        <Form.Group className="mb-3" style={{ width: '100%', marginBlock: 16 }}>
                            <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                                Select Tax Rate in %:
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    id="textInput"
                                    type="number"
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                                    onChange={handleTaxRateChange}
                                    value={taxRate}
                                    step="0.01"
                                    min="0"
                                    max="18"
                                />
                            </InputGroup>
                        </Form.Group>
                    </div>
                )
            }
            <div>
                <Form.Group>
                    <Form.Label htmlFor="dropdown" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                        Discount
                    </Form.Label>
                    <InputGroup>
                        <Form.Select
                            id="dropdown"
                            className='w-100 btncustom dropdown'
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                            onChange={handleDiscountModeChange}
                            value={discountAdded}
                        >
                            <option disabled value="">
                                Select
                            </option>
                            <option value={'yes'} key={'Yes'}>
                                {'Yes'}
                            </option>
                            <option value={'no'} key={'no'}>
                                {'No'}
                            </option>
                        </Form.Select>
                    </InputGroup>
                </Form.Group>
            </div>
            {
                discountAdded === 'yes' && (
                    <div>
                        <Form.Group className="mb-3" style={{ width: '100%', marginBlock: 16 }}>
                            <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                                Select Discount Rate in %:
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    id="textInput"
                                    type="number"
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                                    onChange={handleDiscountRateChange}
                                    value={discountRate}
                                    step="0.01"
                                    min="0"
                                    max="18"
                                />
                            </InputGroup>
                        </Form.Group>
                    </div>
                )
            }
            <div>
                <Form.Group>
                    <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                        Header Text:
                    </Form.Label>
                    <Form.Label htmlFor="textInput" style={{ fontSize: 12, color: '#888888', fontWeight: 400 }}>
                        If left empty, the product image will be used.
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            id="textInput"
                            type="text"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 600, color: colors.black02 }}
                            onChange={handleHeaderText}
                            value={headerText}
                            placeholder='Enter header text here'
                            maxLength={60} 
                        />
                    </InputGroup>
                    <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                        Body Text:
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            id="textInput"
                            type="text"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                            onChange={handleBodyText}
                            value={bodyText}
                            placeholder='Enter body text here'
                            maxLength={1024} 
                        />
                    </InputGroup>
                    <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                        Footer Text:
                    </Form.Label>
                    <Form.Label htmlFor="textInput" style={{ fontSize: 12, color: '#888888', fontWeight: 600 }}>
                        Optional field.
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            id="textInput"
                            type="text"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                            onChange={handleFooterText}
                            value={footerText}
                            placeholder='Enter footer text here'
                            maxLength={60} 
                        />
                    </InputGroup>
                    <div
                        className="w-100 d-flex flex-column justify-content-start align-items-center WAPreviewScroll"
                        style={{
                            flex: 1,
                            border: `1px solid ${colors.borderwhite}`,
                            marginBlockEnd: 16,
                            marginBlockStart: 8,
                            borderRadius: 16,
                            overflowY: 'auto',
                        }}
                    >
                        <h4 style={{ fontSize: 16, fontWeight: 700, color: colors.black, paddingTop: '3%' }}>
                            {'Message Preview'}
                        </h4>
                        <div>
                            {(() => {
                                const header = headerText
                                    ? {
                                        type: "HEADER",
                                        format: "IMAGE",
                                        example: {
                                            header_text: headerText,
                                            header_handle: [],
                                        },
                                    }
                                    : {
                                        type: "HEADER",
                                        text: headerText,
                                    };

                                return (
                                    <PreviewCustomTemplate
                                        body={{
                                            type: "BODY",
                                            text: bodyText || "Here is your final order. Review and complete the payment here",
                                            example: {
                                                body_text: [],
                                            },
                                        }}
                                        header={header}
                                        footerData={{
                                            type: "FOOTER",
                                            text: footerText || "Now shop for your favourite products right here on WhatsApp.",
                                        }}
                                        buttonData={{
                                            type: "BUTTONS",
                                            buttons: [{ type: "QUICK_REPLY", text: "Review and Pay" }],
                                        }}
                                    />
                                );
                            })()}
                        </div>
                    </div>
                </Form.Group>
            </div>
        </div >
    );
};

export default WAPaymentModal;
