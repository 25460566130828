import React, { useEffect, useState } from 'react';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { fetchAllWorkflows, setupKeywordAction, updateKeywordAction } from '../../../Services';
import { toast } from 'react-toastify';
import Helper from '../../../Utils/Helper';

const WorkflowAction = ({ object, options, dataObject, setDataObject, saveSuccessAction }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;

  useEffect(() => {
    getWorkflowListAPICall();
  }, []);

  const getWorkflowListAPICall = () => {
    fetchAllWorkflows(authtoken).then((res) => {
      console.log('res====> ', res);
      setRows(res?.data);
    });
  };
  const timeStampToDated = (timestamp) => {
    const dateObj = new Date(timestamp);
    const dateStr = dateObj.toISOString().split('T')[0];
    return dateStr;
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
    console.log('selected row ---> ', row);
    // Perform action when a row is selected
  };

  const columns = [
    { id: 'checkbox', label: 'Select', align: 'center', minWidth: 40, width: 50 },
    { id: 'workflowName', label: 'Workflow Name', align: 'left', minWidth: 100, width: 150 },
    { id: 'createdOn', label: 'Created On', align: 'center', minWidth: 100, width: 150 },
    { id: 'nodeType', label: 'Starting node', align: 'center', minWidth: 100, width: 150 },
  ];

  const row_data =
    rows.length > 0 &&
    rows
      .filter((item, index) => item?.nodes[0]?.type == 'incomingWA')
      .map((item, index) => ({
        _id: item?._id,
        checkbox: true,
        workflowName: item?.name,
        createdOn: timeStampToDated(item?.createdAt),
        nodeType:
          item?.nodes[0]?.type === 'shopify'
            ? 'Shopify ' + item?.nodes[0]?.data?.shopifyEvent
            : item?.nodes[0]?.type,
      }));

  useEffect(() => {
    if (row_data?.length > 0 && dataObject?.workflowId) {
      setSelectedRow(row_data.filter((item) => item?._id == dataObject?.workflowId)[0] ?? null);
    }
  }, [rows]);

  const saveKeywordAction = () => {
    let apidata = {
      action: 'assignWorkflow',
      keywords: options[0]?.keywords,
      keywordCriteria: options[0]?.criteria,
      data: {
        key: 'Workflow',
        workflowId: selectedRow?._id,
      },
    };

    if (object && 'name' in object && 'startTime' in object && 'endTime' in object &&
      object?.days.length > 0) {
      apidata = {
        ...apidata,
        timeFrame: {
          name: object?.name,
          startTime: object?.startTime,
          endTime: object?.endTime,
          days: object?.days,
        },
      };
    }

    if (dataObject?.workflowId || Helper.HelperEditKeywordAction) {
      updateKeywordAction(apidata, authtoken, Helper.HelperKeywordActionID).then((res) => {
        if (res?.flag) {
          toast.success('keyword action updated');
          saveSuccessAction();
        } else {
          toast.error(res?.message);
        }
      });
    } else {
      setupKeywordAction(apidata, authtoken).then((res) => {
        if (res?.flag) {
          toast.success('keyword action saved');
          saveSuccessAction();
        } else {
          toast.error(res?.message);
        }
      });
    }
    // console.log("api data ---> ",apidata)
  };

  return (
    <>
      <div className="workflow_responses_container">
        <div className="workflow_responses_container__header_row">
          <h3>{`Workflows`}</h3>
          <CheerioButton
            borderStyle={{
              borderWidth: 0,
            }}
            textStyle={{
              fontWeight: 600,
              color: colors.white01,
            }}
            btnText={'Save'}
            backColor={colors.primary03}
            disabled={selectedRow?._id && options?.length > 0 ? false : true}
            onclick={() => {
              console.log('next clicked');
              saveKeywordAction();
              // nextClickAction();
            }}
          />
        </div>
        <div
          className="w-100 d-flex flex-row justify-content-start align-item-start mt-2"
          style={{ flex: 1, overflow: 'hidden' }}
        >
          <TableContainer sx={{ flex: 1 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        width: column.width,
                        fontSize: 16,
                        fontWeight: 600,
                        color: colors.black,
                        backgroundColor: colors.white04,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {row_data?.length > 0 &&
                  row_data.map((row, index) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      onClick={() => handleRowClick(row)}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <>
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                width: column.width,
                                maxWidth: column.width,
                                overflow: 'hidden',
                              }}
                            >
                              {column.id === 'checkbox' ? (
                                <>
                                  {value && (
                                    <Checkbox
                                      checked={selectedRow?._id == row?._id ? true : false}
                                    />
                                  )}
                                </>
                              ) : (
                                <p
                                  style={{
                                    color: colors.black,
                                    fontSize: 14,
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {value}
                                </p>
                              )}
                              {/* {column.id === 'workflowName' || column.id === 'nodeType' ? (
                                                    <div style={{ color: colors.black, fontSize: 14, display: "flex", flexWrap: "wrap" }} className="keywordData">
                                                        {value.map((v, i) => (
                                                            <Tooltip title={v} key={i}>
                                                                <span className={`${column.label === 'Keyword' ? "keyword" : "replyAction"}`}>
                                                                    {column.id === 'Keyword' && v.length > 10 ? v.slice(0, 8) + '...' : v}
                                                                </span>
                                                            </Tooltip>
                                                        ))}
                                                    </div>
                                                ) : column.id !== 'Action' ? (
                                                    <p style={{ color: colors.black, fontSize: 14 }}>{value}</p>
                                                ) : (
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        gap: 15,
                                                    }}>
                                                        {actionButtons.map((btn, i) =>
                                                            <Tooltip title={i === 0 ? 'Edit' : 'Delete'} key={i}>
                                                                <div style={{ cursor: 'pointer' }} onClick={()=>onclickAction(i,row)}>
                                                                    {btn}
                                                                </div>
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                )
                                                } */}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default WorkflowAction;
