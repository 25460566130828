import React, { useState, useEffect } from 'react';

const CountdownRectangularTimer = ({ duration, initialRemainingTime, colors, colorsTime }) => {
  const [remainingTime, setRemainingTime] = useState(initialRemainingTime);

  useEffect(() => {
    if (remainingTime > 0) {
      const timerId = setInterval(() => {
        setRemainingTime((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [remainingTime]);

  const hours = Math.floor(remainingTime / 3600);
  const minutes = Math.floor((remainingTime % 3600) / 60);
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');

  const getColor = () => {
    for (let i = 0; i < colorsTime.length; i++) {
      if (remainingTime >= colorsTime[i]) {
        return colors[i];
      }
    }
    return colors[colors.length - 1];
  };

  const color = getColor();

  return (
    <div
      style={{
        width: '51px',
        height: '30px',
        backgroundColor: hexToRgba(color, 0.05),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '37px',
        border: '2px solid ' + color,
        padding: '7px 10px',
      }}
    >
      <span
        style={{
          fontSize: '12px',
          fontWeight: 700,
          color: hexToRgba(color, 1),
        }}
      >
        {`${paddedHours}:${paddedMinutes}`}
      </span>
    </div>
  );
};

export default CountdownRectangularTimer;

function hexToRgba(hex, opacity) {
  hex = hex.replace(/^#/, '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
