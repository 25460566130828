import { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const UpdateChatStatusModal = ({ data, setData }) => {
  const [chatStatus, setChatStatus] = useState('Pending');

  useEffect(() => {
    if (data?.chatStatus) {
      setChatStatus(data.chatStatus);
    }
  }, []);

  return (
    <div className="w-100 h-100 flex-column justify-content-start align-items-start">
      <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 16 }}>
        <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
          Chat Status
        </Form.Label>
        <Form.Select
          type="text"
          placeholder="select"
          className="w-100 btncustom dropdown"
          style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
          value={chatStatus}
          onChange={(e) => {
            setChatStatus(e.target.value);
            setData({
              ...data,
              chatStatus: e.target.value,
            });
          }}
        >
          {ChatStatusOptions.map((cso) => (
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={cso}
              key={cso}
            >
              {cso}
            </option>
          ))}
        </Form.Select>
      </InputGroup>
    </div>
  );
};

const ChatStatusOptions = ['Pending', 'Unresolved', 'Resolved'];

export default UpdateChatStatusModal;
