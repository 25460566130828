import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { GetTemplatesV2API, fetechTemplatesApi, getSmsTemplates } from '../../../Services';
import { useSelector } from 'react-redux';
import { AnnTemplateItem, SmsTemplateItem, SpinnerComponent } from './NewComponents';
import { Autocomplete, InputAdornment, TablePagination, TextField } from '@mui/material';
import '../styles.css';
import { AiOutlineSearch } from 'react-icons/ai';
import { toast } from 'react-toastify';
import SMSDLTModal from '../../CreatorNew/Components/SMSDLTModal';
import CreateSMSTemplate from './CreateSMSTemplate';
import { eventsend } from '../../../Config/analyticsFunctions';

const SmsTemplates = ({ onclickAction, setOpenANModal, setOpenCreateSmsModal, openCreateSmsModal, setCustomSMSText, customSMSText }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const [showSmsDLT, setShowSmsDLT] = useState(false);

  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [templateData, setTemplateData] = useState(null);
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //   const [before, setBefore] = useState('');
  //   const [after, setAfter] = useState('');
  const [searchValue, setSearchValue] = useState('');
  //const [tempData,setTempData] = useState();

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  useEffect(() => {
    setIsLoadingTemplates(true);
    GetTemplatesV2APIcall();
    eventsend("SMS_templates_Campaign_pop_up_clicked")
  }, []);

  //  useEffect(()=>{
  //   if(templateData){
  // //     const data = templateData;

  // //   if(searchValue!==""){

  // //     const newArr = templateData.filter((data)=>searchValue === data.name);
  // //     if(newArr.length>0){
  // //       setTemplateData(newArr);
  // //     }else{
  // //       setTemplateData(tempData);
  // //     }

  // //   }else{
  // //     setTemplateData(tempData);

  // console.log("searc",searchValue);
  //   }

  //  },[searchValue])

  const GetTemplatesV2APIcall = () => {
    let token = authtoken;

    getSmsTemplates(token).then((res) => {
      if (res?.data) {
        setTemplateData(res?.data);
        //setTempData(res?.data);
      } else {
        // toast.error(res?.message, { position: 'top-center' });
      }
      setIsLoadingTemplates(false);
    });
  };

  //   const fetechTemplatesApiCall = (af, bef) => {
  //     const data = {
  //       before: bef,
  //       after: af,
  //       limit: '100',
  //     };
  //     fetechTemplatesApi(data, authtoken).then((res) => {
  //       if (res?.data.length) {
  //         console.log('success response: ', res);

  //         setTemplateData(res?.data);
  //         if (res?.paginate) {
  //           setBefore(res?.paginate?.before);
  //           setAfter(res?.paginate?.after);
  //         }
  //         setIsLoadingTemplates(false);
  //       } else if (res?.data.length === 0) {
  //         setTemplateData(res?.data);
  //         setIsLoadingTemplates(false);
  //         // if(data.after == ""){
  //         //   setAfter("");
  //         // }else{
  //         //   setBefore("");
  //         // }
  //       } else {
  //         console.log('error response: ', res);
  //       }
  //     });
  //   };

  return (
    <>
      {isLoadingTemplates ? (
        <>
          <SpinnerComponent />
        </>
      ) : (
        <>
          <div className="w-100 verythinscrollnew" style={{ flex: 1, overflowY: 'scroll' }}>
            {templateData && (
              <div
                className="d-flex justify-content-between"
                style={{ height: '25px', width: '95%', marginBottom: '20px' }}
              >
                {/* <div style={{ width: '90%', height: '30px', marginLeft: '16px' }}>
                  <div className={'loggedInput'}>
                    <AiOutlineSearch size={18} />
                    <input
                      style={{ width: '100%' }}
                      className="input"
                      type={'text'}
                      placeholder={`Search for templates`}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                    />
                  </div>
                </div> */}
                {/* {localdata?.whatsapp_credentials?.is_ai_sensy_user && (
                  <div className="d-flex" style={{ width: '7%', marginTop: '6px' }}>
                    <img
                      src={images.Left}
                      style={{
                        color: '#3366CC',
                        cursor: 'pointer',
                        height: '24px',
                        marginRight: '16px',
                      }}
                      onClick={() => {
                        const af = '';
                        const bef = before;
                        fetechTemplatesApiCall(af, bef);
                      }}
                    ></img>
                    {/* <p style={{marginLeft:"5px",marginRight:"5px"}}>{"<-->"}</p> */}
                {/* <img
                      src={images.Right}
                      style={{ color: '#3366CC', cursor: 'pointer', height: '24px' }}
                      onClick={() => {
                        const af = after;
                        const bef = '';

                        fetechTemplatesApiCall(af, bef);
                      }}
                    ></img>
                  </div>
                )} */}
              </div>
            )}

            <div
              className="d-flex flex-row justify-content-between align-items-start w-100"
              style={{ flexWrap: 'wrap' }}
            >
              {
                <>
                  {templateData && templateData.length > 0 ? (
                    templateData
                      .filter((value) => {
                        if (searchValue === '') {
                          return true;
                        }
                        if (value?.name.includes(searchValue)) {
                          return value;
                        } else {
                          return false;
                        }
                      })
                      .sort((a) => {
                        if (a.status === 'APPROVED') {
                          return -1;
                        }
                        if (a.status === 'REJECTED') {
                          return 1;
                        }
                        return 0;
                      })
                      .map((item) => {
                        return (
                          <>
                            <SmsTemplateItem item={item} onclickAction={onclickAction} />
                          </>
                        );
                      })
                  ) :
                    <>
                      {templateData?.smsProvider === "twilio" ? (
                        <>
                          {setOpenCreateSmsModal(true)}
                          <CreateSMSTemplate setCustomSMSText={setCustomSMSText} customSMSText={customSMSText} />
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              textAlign: 'center',
                              width: '100%',
                              fontWeight: '600',
                              marginTop: '10px',
                            }}
                          >
                            Your DLT registration might be in process or not yet submitted.<br></br> You
                            can submit it{' '}
                            <a
                              onClick={() => {
                                setShowSmsDLT(true);

                                //setOpenANModal(false);
                              }}
                              style={{ color: 'blue', cursor: 'pointer' }}
                            >
                              here.
                            </a>
                          </div>
                          {showSmsDLT && (
                            <SMSDLTModal setIsOpenSMSDLTModal={setShowSmsDLT}></SMSDLTModal>
                          )}{' '}
                        </>
                      )}
                    </>}
                </>
              }
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SmsTemplates;
