import React from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';

const DeleteContacts = ({ setOpenDelete, item, deleteAction }) => {
  const getlabeldate = (e) => {
    const date = new Date(e);
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const MM = mm < 10 ? '0' + mm : mm;
    const yy = date.getFullYear();
    return dd + '-' + MM + '-' + yy;
  };

  return (
    <>
      <div
        className="ModalDarkBg"
        style={{ top: 300 }}
        onClick={() => {
          setOpenDelete(false);
        }}
      >
        <div className="Center">
          <div
            className="d-flex flex-column justify-content-start align-items-center"
            style={{
              width: 400,
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 0,
              border: `1px solid ${colors.borderwhite}`,
              paddingBlock: 20,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className="d-flex flex-row justify-content-between align-items-center w-100"
              style={{
                paddingBlockEnd: 16,
                borderBottom: `1px solid ${colors.borderwhite}`,
                paddingInline: 30,
              }}
            >
              <h4
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 24,
                  fontWeight: 700,
                  color: colors.black,
                }}
              >
                {'Delete Label'}
              </h4>
              <img
                src={images.CMClose}
                style={{
                  height: 32,
                  width: 32,
                  objectFit: 'contain',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setOpenDelete(false);
                }}
              ></img>
            </div>
            <p
              style={{
                margin: 0,
                padding: 0,
                paddingInline: 30,
                fontSize: 16,
                fontWeight: 400,
                color: colors.black,
                flexWrap: 'wrap',
                marginBlockStart: 16,
                marginBlockEnd: 4,
                width: '100%',
              }}
            >
              {`Label Name: ${item?.label ? item?.label : item?.label_name}`}
            </p>
            <p
              style={{
                margin: 0,
                padding: 0,
                paddingInline: 30,
                fontSize: 16,
                fontWeight: 400,
                color: colors.black,
                flexWrap: 'wrap',
                marginBlock: 4,
                width: '100%',
              }}
            >
              {`Created on: ${getlabeldate(item?.createdAt)}`}
            </p>
            <p
              style={{
                margin: 0,
                padding: 0,
                paddingInline: 30,
                fontSize: 16,
                fontWeight: 400,
                color: colors.black,
                flexWrap: 'wrap',
                marginBlockStart: 4,
                width: '100%',
                marginBlockEnd: 16,
              }}
            >
              {`Total Contacts: ${item?.count}`}
            </p>
            <p
              style={{
                margin: 0,
                padding: 0,
                paddingInline: 30,
                fontSize: 16,
                fontWeight: 400,
                color: colors.black,
                marginBlockStart: 16,
                marginBlockEnd: 4,
              }}
            >
              {'Are you sure you want to delete?'}
            </p>
            <div
              className="d-flex flex-row justify-content-center align-items-center w-100"
              style={{ marginBlockStart: 16 }}
            >
              <CheerioButton
                borderStyle={{
                  borderColor: colors.primary03,
                  width: '30%',
                  marginInlineEnd: 8,
                }}
                textStyle={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: colors.white,
                }}
                btnText={'Delete'}
                backColor={colors.primary03}
                onclick={() => {
                  deleteAction(item?._id);
                }}
              />
              <CheerioButton
                borderStyle={{
                  width: '30%',
                  marginInlineStart: 8,
                  border: `1px solid ${colors.primary03}`,
                }}
                textStyle={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: colors.primary03,
                }}
                btnText={'Cancel'}
                backColor={colors.white}
                onclick={() => {
                  console.log('cancel clicked . . .');
                  setOpenDelete(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteContacts;

const Styles = {
  DarkBg: {
    // backgroundColor: 'rgba(0,0,0,0.4)',
    width: '100%',
    height: '100%',
    zIndex: 1000,
    top: '300px',
    left: 0,
    position: 'fixed',
  },
  Center: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
};
