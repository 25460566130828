import React from 'react';
import colors from '../Utils/colors';

const CheerioButton = ({
  borderStyle,
  textStyle,
  backColor,
  btnText,
  disabled = false,
  onclick,
  icon,
  iconStyle = {},
  id,
}) => {
  return (
    <>
      <div
        id={id}
        className='CheerioBtn'
        style={{
          ...Styles.Conatiner,
          ...borderStyle,
          backgroundColor: backColor ? backColor : colors.white,
          filter: disabled ? 'opacity(40%)' : 'none',
          cursor: disabled ? '' : 'pointer',
        }}
        onClick={() => {
          if (!disabled) {
            onclick();
          }
        }}
      >
        {icon && (
          <img
            src={icon}
            style={{
              height: 18,
              width: 18,
              objectFit: 'contain',
              marginInlineEnd: 8,
              ...iconStyle,
            }}
          ></img>
        )}
        <span className="p-0 m-0" style={{ ...Styles.ButtonText, ...textStyle }}>
          {btnText}
        </span>
      </div>
    </>
  );
};

export default CheerioButton;

const Styles = {
  Conatiner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    paddingInline: 20,
    paddingBlock: 6,
    border: '1px solid #000',
  },
  ButtonText: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.black,
  },
};
