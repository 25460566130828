/* eslint-disable prettier/prettier */
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Styles } from './Styles';
import colors from '../../Utils/colors';
import { useRef, useState } from 'react';
import { Chip } from '@mui/material';
import { useSelector } from 'react-redux';

interface IPhoneNumber {
  type: 'PHONE_NUMBER';
  text: string;
  phone_number: string;
  uuid: number;
}

interface IUrl {
  type: 'URL';
  text: string;
  url: string;
  example: string[];
  uuid: number;
}

interface IQuickReply {
  type: 'QUICK_REPLY';
  text: string;
  uuid: number;
}

const buttonList = [
  {
    key: 'ADD_BUTTON',
    value: '+ Add a Button',
  },
  {
    key: 'CALL_PHONE_NUMBER',
    value: 'Call Phone Number',
  },
  {
    key: 'VISIT_WEBSITE',
    value: 'Visit Website',
  },
  {
    key: 'QUICK_REPLY',
    value: 'Quick Reply',
  },
];

type IButton = IPhoneNumber | IUrl | IQuickReply;

export const VariableButtons = ({
  data,
  setData,
  Carousel = false,
  index = 0,
}: {
  data: IButton[];
  setData: (data: IButton[]) => void;
  Carousel: boolean;
  index: number;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const localdata = useSelector((state: any) => state?.main?.localdata);
  const getDisabledButton = (key: string) => {
    if (key === 'ADD_BUTTON') return true;
    if (data?.length === 10) return true;
    if (key === 'CALL_PHONE_NUMBER' && data?.some((e) => e.type === 'PHONE_NUMBER')) {
      return true;
    }
    if (key === 'VISIT_WEBSITE' && data?.filter((e) => e.type === 'URL').length >= 2) {
      return true;
    }
    return false;
  };
  return (
    <div className="mb-2 py-2 px-3">
      <div>
        {' '}
        <Form.Label style={{ ...Styles.TxtHeading2 }}>
          Button
          {Carousel ? (
            <>
              <span
                style={{
                  color: colors.error03,
                  fontSize: '14px',
                  marginLeft: '10px',
                  fontWeight: '600',
                }}
              >
                {'*Atleast one button is required.'}
                <br />
                {`${
                  index === 0
                    ? 'Please Note: Changing button types will change the buttons in all subsequent cards. Please verify the buttons data in other cards before submitting and also please make sure each button have different name.'
                    : 'Please Note: Make sure each buttons are of different name.'
                }`}
              </span>
            </>
          ) : (
            <>
              <span
                style={{ color: 'grey', fontSize: '14px', marginLeft: '10px', fontWeight: '600' }}
              >
                Optional
              </span>
            </>
          )}
        </Form.Label>
        {/* <p style={{fontSize:"14px",color:"grey"}}>Create buttons that let your customers either to reply or take action</p> */}
        <p
          style={{
            fontSize: '14px',
            color: 'grey',
            marginTop: '-5px',
          }}
        >
          Create buttons that let customers respond to your message or take action
        </p>
      </div>
      <InputGroup className="btncustom" style={{ width: '50%', height: '100%' }}>
        <InputGroup.Text
          id="basic-addon1"
          style={{
            fontSize: 12,
            fontWeight: 600,
            color: colors.black,
            cursor: 'default',
          }}
        >
          {'Button'}
        </InputGroup.Text>
        <Form.Select
          className="btncustom"
          aria-label="Default select example"
          style={{
            fontSize: 12,
            fontWeight: 600,
            color: colors.greys04,
            cursor: 'pointer',
          }}
          disabled={Carousel && (data?.length == 2 || index !== 0) ? true : false}
          value={'ADD_BUTTON'}
          onChange={(e) => {
            if (e.target.value === 'CALL_PHONE_NUMBER') {
              setData([
                {
                  type: 'PHONE_NUMBER',
                  text: '',
                  phone_number: '',
                  uuid: new Date().getMilliseconds(),
                },
                ...data,
              ]);
            }
            if (e.target.value === 'VISIT_WEBSITE') {
              setData([
                {
                  // type: 'URL',
                  // text: '',
                  // url: '',
                  // example: [''],
                  type: 'URL',
                  text: 'Visit Website',
                  url: 'https://web.cheerio.in/ss/{{1}}',
                  example: [localdata?.company_name ?? 'ESMY'],
                  uuid: new Date().getMilliseconds(),
                },
                ...data,
              ]);
            }
            if (e.target.value === 'QUICK_REPLY') {
              setData([
                ...data,
                {
                  type: 'QUICK_REPLY',
                  text: '',
                  uuid: new Date().getMilliseconds(),
                },
              ]);
            }
          }}
        >
          {buttonList &&
            buttonList.map((e) => {
              return (
                <>
                  <option
                    style={{
                      width: '100%',
                      marginTop: 4,
                      marginBottom: 4,
                      borderRadius: 8,
                    }}
                    value={e.key}
                    key={e.key}
                    disabled={getDisabledButton(e.key)}
                  >
                    {e.value}
                  </option>
                </>
              );
            })}
        </Form.Select>
      </InputGroup>
      {data.map((button, ind) => {
        if (button.type === 'PHONE_NUMBER') {
          return (
            <CallPhoneNumber
              key={button.uuid}
              data={button}
              setData={(value) => {
                const newData = [...data];
                newData[ind] = value;
                setData(newData);
              }}
              onDelete={() => {
                const newData = [...data];
                newData.splice(ind, 1);
                setData(newData);
              }}
              index={index}
            />
          );
        }
        if (button.type === 'URL') {
          return (
            <VisitWebsite
              key={button.uuid}
              data={button}
              setData={(value) => {
                const newData = [...data];
                newData[ind] = value;
                setData(newData);
              }}
              onDelete={() => {
                const newData = [...data];
                newData.splice(ind, 1);
                setData(newData);
              }}
              index={index}
            />
          );
        }
        if (button.type === 'QUICK_REPLY') {
          return (
            <QuickReply
              key={button.uuid}
              data={button}
              setData={(value) => {
                const newData = [...data];
                newData[ind] = value;
                setData(newData);
              }}
              onDelete={() => {
                const newData = [...data];
                newData.splice(ind, 1);
                setData(newData);
              }}
              index={index}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

const CallPhoneNumber = ({
  data,
  setData,
  onDelete,
  index,
}: {
  data: IPhoneNumber;
  setData: (data: IPhoneNumber) => void;
  onDelete: () => void;
  index: number;
}) => {
  return (
    <div
      style={{
        borderRadius: '16px',
        border: '1px solid #CCCCCC',
        padding: '20px',
        margin: '20px',
      }}
    >
      <div
        className="d-flex justify-content-between"
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '10px',
        }}
      >
        Call Phone Number
        <Button
          onClick={() => {
            onDelete();
          }}
          variant="danger"
          size="sm"
          disabled={index !== 0 ? true : false}
        >
          Delete
        </Button>
      </div>

      <div
        className="d-flex justify-content-between mb-3"
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '10px',
        }}
      >
        <div
          style={{
            width: '100%',
            marginRight: '10px',
          }}
        >
          <div>
            <Form.Label
              style={{
                fontWeight: '700',
              }}
            >
              Button Text
            </Form.Label>
          </div>
          <Form.Control
            type="text"
            placeholder="Call Now"
            className="w-100 btncustom"
            style={{ borderRadius: 8 }}
            value={data.text}
            onChange={(e) => {
              setData({ ...data, text: e.target.value });
            }}
            maxLength={25}
          />
        </div>
        <div
          style={{
            width: '100%',
          }}
        >
          <Form.Label
            style={{
              // color: !caToogle1 ? '#999' : '#000',
              fontWeight: '700',
            }}
          >
            Phone Number
          </Form.Label>
          <Form.Control
            type="text"
            className="btncustom"
            placeholder="91xxxxxxxxxx"
            style={{
              borderRadius: 8,
            }}
            value={data.phone_number}
            onChange={(e) => {
              setData({ ...data, phone_number: e.target.value });
            }}
          />
          <p style={{ fontSize: '0.6rem', fontWeight: 500 }}>
            Please Add Phone Number with Country code without (+)
          </p>
        </div>
      </div>
    </div>
  );
};

const VisitWebsite = ({
  data,
  setData,
  onDelete,
  index,
}: {
  data: IUrl;
  setData: (data: IUrl) => void;
  onDelete: () => void;
  index: number;
}) => {
  const [linkType, setLinkType] = useState(data?.example?.length > 0 ? 'Dynamic' : 'Static');
  const editorRef = useRef<HTMLInputElement>(null);
  const dontResetCursor = (cursorPosition: number) => {
    setTimeout(() => {
      editorRef.current?.focus();
      editorRef.current?.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBodyTextChange = (event: any) => {
    const { value } = event.target;
    // if key is backspace remove char before cursor
    const cursorPosition = event.target.selectionStart;
    let modifiedText = value;
    const patternFullVariable = /\{\{+\d+\}\}+/g;
    const variablesDetected = value.match(patternFullVariable);
    const charactersBeforeCursor = value.substring(cursorPosition - 2, cursorPosition);
    if (event.nativeEvent.inputType === 'deleteContentBackward') {
      event.preventDefault();

      setData({
        ...data,
        url: modifiedText,
      });
      return;
    }
    if (
      cursorPosition >= 2 &&
      charactersBeforeCursor === '{{' &&
      (!variablesDetected || variablesDetected.length === 0)
    ) {
      modifiedText = value.substring(0, cursorPosition) + `1}}` + value.substring(cursorPosition);
    }

    if (variablesDetected?.length > 1) {
      modifiedText = modifiedText.substring(0, cursorPosition - 5);
    }
    setData({
      ...data,
      url: modifiedText.replace(patternFullVariable, '{{1}}'),
    });

    dontResetCursor(cursorPosition);
  };
  console.log(data.url.match(/\{\{(\d+)\}\}/g));
  return (
    <div style={{ borderRadius: '16px', border: '1px solid #CCCCCC', padding: '20px', margin: 20 }}>
      <div
        className="d-flex justify-content-between"
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '10px',
        }}
      >
        Visit Website
        <Button
          onClick={() => {
            onDelete();
          }}
          variant="danger"
          size="sm"
          disabled={index !== 0 ? true : false}
        >
          Delete
        </Button>
      </div>
      <div
        className="d-flex justify-content-between mb-3"
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '10px',
        }}
      >
        <div
          style={{
            width: '100%',
            marginRight: '10px',
          }}
        >
          <div>
            <Form.Label
              style={{
                fontWeight: '700',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              Button Text
              <span
                style={{
                  color: 'grey',
                  textAlign: 'end',
                  fontSize: '12px',
                }}
              >
                {data.text.length}/25
              </span>
            </Form.Label>
          </div>
          <Form.Control
            type="text"
            placeholder="Visit Website"
            className="w-100 btncustom"
            value={data.text}
            onChange={(e) => {
              setData({ ...data, text: e.target.value });
            }}
          />
        </div>

        <div
          style={{
            width: '100%',
            marginRight: '10px',
          }}
        >
          <Form.Label
            style={{
              fontWeight: '700',
            }}
          >
            URL Type
          </Form.Label>
          <Form.Select
            className="btncustom "
            aria-label="Default select example"
            style={{
              fontSize: 14,
              fontWeight: 600,
              color: colors.greys04,
              cursor: 'pointer',
              width: '100%',
            }}
            value={linkType}
            // value={data.example.length > 0 ? 'Dynamic' : 'Static'}
            onChange={(e) => {
              setLinkType(e.target.value)
              if (e.target.value === 'Dynamic') {
                setData({ ...data, example: [''],url: 'https://web.cheerio.in/ss/{{1}}' });
              }else if (e.target.value === 'Static') {
                setData({ ...data, example: [],url:'https://example.com' });
              }else if(e.target.value === 'Dynamic w/o link tracking'){
                setData({ ...data, example: [''],url: 'https://web.cheerio.in/ss/{{1}}' });
              }
            }}
            // disabled
          >
            {['Static', 'Dynamic', 'Dynamic w/o link tracking'].map((e) => {
              return (
                <option
                  style={{
                    width: '100%',
                    marginTop: 4,
                    marginBottom: 4,
                    borderRadius: 8,
                  }}
                  value={e}
                  key={e}
                >
                  {e}
                </option>
              );
            })}
          </Form.Select>
        </div>
        <div
          style={{
            width: '100%',
          }}
        >
          <Form.Label
            style={{
              fontWeight: '700',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            Website URL{' '}
            <span
              style={{
                color: 'grey',
                textAlign: 'end',
                fontSize: '12px',
              }}
            >
              {data.text.length}/2000
            </span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={
              data?.example?.length > 0 ? 'https://example.com/{{1}}' : 'https://example.com'
            }
            className="btncustom"
            value={data.url}
            onChange={
              data?.example?.length > 0
                ? handleBodyTextChange
                : (e) => {
                    setData({ ...data, url: e.target.value });
                  }
            }
            maxLength={2000}
            disabled={data?.example?.length > 0 && linkType == 'Dynamic' ? true : false}
          />
        </div>
      </div>

      <div style={{ width: '100%' }}>
        {data?.example?.length > 0 &&
          data.url.match(/\{\{(\d+)\}\}/g)?.map((tag, index) => (
            <div
              className="w-100"
              key={index}
              style={{ display: 'flex', width: '500px', marginTop: '20px' }}
            >
              <Chip label={tag} style={{ margin: '5px' }} />
              {/* {inputBoxes[index]} */}
              <div key={index} className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Enter content for sample variable"
                  className="w-100 btncustom"
                  style={{ borderRadius: 8 }}
                  value={data.example[index] ?? ''}
                  onChange={(e) => {
                    const newData = [...data.example];
                    newData[index] = e.target.value;
                    console.log('Data ---> ', data);
                    setData({ ...data, example: newData });
                  }}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

const QuickReply = ({
  data,
  setData,
  onDelete,
  index,
}: {
  data: IQuickReply;
  setData: (data: IQuickReply) => void;
  onDelete: () => void;
  index: number;
}) => {
  return (
    <div
      style={{
        borderRadius: '16px',
        border: '1px solid #CCCCCC',
        padding: '20px',
        margin: '20px',
      }}
    >
      <div
        className="d-flex justify-content-between"
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '10px',
        }}
      >
        Quick Reply
        <Button
          onClick={() => {
            onDelete();
          }}
          variant="danger"
          size="sm"
          disabled={index !== 0 ? true : false}
        >
          Delete
        </Button>
      </div>

      <div
        className="d-flex justify-content-between mb-3"
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '10px',
        }}
      >
        <div
          style={{
            width: '100%',
            marginRight: '10px',
          }}
        >
          <div>
            <Form.Label
              style={{
                fontWeight: '700',
              }}
            >
              Button Text
            </Form.Label>
          </div>
          <Form.Control
            type="text"
            placeholder="Quick Reply"
            className="w-100 btncustom"
            style={{ borderRadius: 8 }}
            value={data.text}
            onChange={(e) => {
              setData({ ...data, text: e.target.value });
            }}
            maxLength={25}
          />
        </div>
      </div>
    </div>
  );
};
