import React, { useEffect, useRef, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { V2WorkflowS3Link } from '../../../Services';
import { getImagePath } from '../../../Utils';
import UploaderText from '../../../Components/UploaderText';
import UploadValidator from '../../../Components/UploadValidator';

const EndFlowModal = ({ data, setData }) => {
  const [messageText, setMessageText] = useState('');
  const [mediaType, setMediaType] = useState('Image');
  const [upFile, setUpFile] = useState();
  const [upFile64, setUpFile64] = useState('');
  const [upFileName, setUpFileName] = useState('');
  const [s3Link, setS3Link] = useState('');
  const [dragActive, setDragActive] = useState(false);

  const authtoken = useSelector((state) => state.main.auth_token);

  const upImageRef = useRef();
  const upVideoRef = useRef();

  const handlefileupload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (UploadValidator(file, mediaType)) {
        setUpFile(file);
        setUpFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', { position: 'top-center' });
    }
  };

  const handleDragFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDropFile = async (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('file ===> ', e.dataTransfer.files[0]);
    const file = e.dataTransfer.files[0];
    if (file && type) {
      if (UploadValidator(file, type)) {
        setUpFile(file);
        setUpFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', { position: 'top-center' });
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (upFile64) {
      getS3Link();
    }
  }, [upFile64]);

  const getS3Link = () => {
    let data = {
      image: upFile64,
      type: upFile?.type?.split('/')[0],
      format: upFile?.type?.split('/')[1],
    };
    let token = authtoken;
    V2WorkflowS3Link(data, token).then((res) => {
      if (res) {
        setS3Link(res?.data?.url);
        console.log('s3 link success response', res);
        toast.success('file uploaded successfully', { position: 'top-center' });
      } else {
        toast.error('Error occurred while uploading file, please try again later', {
          position: 'top-center'
        });
        console.log('s3 link error response', res);
      }
    });
  };

  useEffect(() => {
    console.log('incoming edit data ===> ', data);
    if (data?.body?.message) {
      setMessageText(data?.body?.message);
    }
    if (data?.header?.mediaType) {
      setMediaType(data?.header?.mediaType);
    }
    if (data?.header?.mediaName) {
      setUpFileName(data?.header?.mediaName);
    }
    if (data?.header?.mediaFile) {
      setS3Link(data?.header?.mediaFile);
    }
  }, []);

  useEffect(() => {
    setData({
      ...data,
      header: {
        mediaType: mediaType,
        mediaName: upFileName,
        mediaFile: s3Link,
      },
      body: {
        message: messageText,
      },
    });
  }, [messageText, mediaType, upFile, upFile64, s3Link]);

  return (
    <>
      <div className="w-100 h-100 d-flex flex-column justify-content-start align-items-start">
        <p
          style={{
            padding: 0,
            margin: 0,
            marginBlock: 4,
            fontSize: 16,
            fontWeight: 600,
            color: colors.black,
            width: '100%',
          }}
        >
          {'Upload media'}
        </p>

        <InputGroup className="mb-2" style={{ width: '50%', marginBlock: 16 }}>
          <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
            {'Select media type'}
          </Form.Label>
          <Form.Select
            type="text"
            placeholder="Select media type"
            className="w-100 btncustom dropdown"
            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
            value={mediaType}
            onChange={(e) => {
              setMediaType(e.target.value);
            }}
          >
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'Image'}
              key={'Image'}
            >
              {'Image'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'Video'}
              key={'Video'}
            >
              {'Video'}
            </option>
          </Form.Select>
        </InputGroup>

        <div
          className="d-flex flex-column justify-content-center align-items-center w-100 my-2"
          style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 8, height: 260 }}
          onDragEnter={handleDragFile}
          onDragExit={handleDragFile}
          onDragOver={handleDragFile}
          onDrop={(e) => {
            handleDropFile(e, mediaType);
          }}
          onClick={() => {
            if (mediaType === 'Image') {
              upImageRef.current.click();
            } else if (mediaType === 'Video') {
              upVideoRef.current.click();
            }
          }}
        >
          {data?.header?.mediaFile || upFile || s3Link ? (
            <>
              {s3Link && (
                <>
                  {mediaType === 'Image' ? (
                    <img
                      src={getImagePath(s3Link)}
                      style={{ height: 180, width: '80%', objectFit: 'contain' }}
                    ></img>
                  ) : (
                    mediaType === 'Video' && (
                      <video
                        src={getImagePath(s3Link)}
                        style={{ height: 180, width: '80%', objectFit: 'contain' }}
                      ></video>
                    )
                  )}
                </>
              )}
              {upFileName && (
                <div className="d-flex flex-row justify-content-between align-items-center w-75">
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      marginBlock: 4,
                      fontSize: 16,
                      fontWeight: 600,
                      color: colors.greys04,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      wordBreak: 'break-all',
                    }}
                  >
                    {upFileName}
                  </p>
                  <img
                    src={images.Cross}
                    style={{ height: 18, width: 18, marginInlineStart: 24, cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setUpFile(null);
                      setUpFile64(null);
                      setUpFileName('');
                      setS3Link(null);
                    }}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <img
                src={images.ImgUpIcon}
                style={{ height: 100, width: 70, objectFit: 'contain' }}
              ></img>
              <UploaderText
                type={mediaType}
                textStyle={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: colors.greys04,
                  textAlign: 'center',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  wordBreak: 'break-all',
                }}
              />
              {/* <p style={{padding:0,margin:0,marginBlock:4,fontSize:16,fontWeight:600,color:colors.greys04}}>
            {'Upload Image'}
          </p>
          <p style={{padding:0,margin:0,marginBlock:8,fontSize:14,fontWeight:600,color:colors.greys04,textAlign:'center'}}>
            {'350px x 350px to 1500px x 1500px'}<br/>
            {'Max file size: 5 MB'}
          </p> */}
            </>
          )}
        </div>

        <input
          type="file"
          style={{ display: 'none' }}
          ref={upImageRef}
          accept="image/*"
          onChange={(e) => {
            handlefileupload(e);
          }}
        />
        <input
          type="file"
          style={{ display: 'none' }}
          ref={upVideoRef}
          accept="video/*"
          onChange={(e) => {
            handlefileupload(e);
          }}
        />

        <div
          className="w-100"
          style={{ height: 1, backgroundColor: colors.borderwhite, marginBlock: 16 }}
        ></div>

        <p
          style={{
            padding: 0,
            margin: 0,
            marginBlock: 4,
            fontSize: 16,
            fontWeight: 600,
            color: colors.black,
            width: '100%',
          }}
        >
          {'Type a message'}
        </p>

        <InputGroup
          className="my-1"
          style={{ borderBottom: `1px solid ${colors.borderwhite}`, paddingBlockEnd: 20 }}
        >
          <Form.Control
            as="textarea"
            rows={4}
            placeholder={'Enter here'}
            className="w-100 btncustom"
            style={{ borderRadius: 8, fontSize: 14, fontWeight: 400, color: colors.black }}
            // isInvalid={(renewalText.length >= 640)}
            maxLength={640}
            value={messageText}
            onChange={(e) => {
              setMessageText(e.target.value);
            }}
          />
        </InputGroup>
      </div>
    </>
  );
};

export default EndFlowModal;
