import React from 'react';
import colors from '../../Utils/colors';
import images from '../../Utils/images';

const SMSDocs = () => {
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center w-75">
        <img
          src={images.CreatorSMSS}
          style={{ height: 64, width: 64, objectFit: 'contain', marginBlock: 16 }}
        />
        <text style={{ ...Styles.TxtHeading, marginBlock: 8 }}>
          {'SMS registration for new business'}
        </text>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-start w-75">
        {/* <text style={{...Styles.TxtBody,marginBlock:8,flexWrap:'wrap'}}>
            {'PreRequisites'}
        </text> */}
        <text
          style={{ ...Styles.TxtBody2, marginBlockStart: 8, marginBlockEnd: 8, flexWrap: 'wrap' }}
        >
          {
            '1. TRAI (TCCCPR 2018) has mandated all businesses that want to send SMS to register their business, headers (sender IDs), and templates on any of the DLT platforms.'
          }
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 8, flexWrap: 'wrap' }}>
          {
            '2. Please note that all steps in the DLT registration process must be completed to send SMS. It’s incredibly easy to register. You can register on any one of the following DLT providers -'
          }
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 2, flexWrap: 'wrap' }}>
          {'JIO Registration link - '}
          <text
            style={{ color: colors.linkblue, textDecorationLine: 'underline' }}
            onClick={() => {
              window.open('https://trueconnect.jio.com/#/', '_blank', 'noopener,noreferrer');
            }}
          >
            {'https://trueconnect.jio.com/#/'}
          </text>
          <br />
          {'Airtel Registration link - '}
          <text
            style={{ color: colors.linkblue, textDecorationLine: 'underline' }}
            onClick={() => {
              window.open(
                'https://www.airtel.in/business/commercial-communication/home',
                '_blank',
                'noopener,noreferrer'
              );
            }}
          >
            {'https://www.airtel.in/business/commercial-communication/home'}
          </text>
          <br />
          {'Videocon Registration link - '}
          <text
            style={{ color: colors.linkblue, textDecorationLine: 'underline' }}
            onClick={() => {
              window.open(
                'https://smartping.live/entity/signup/Indian',
                '_blank',
                'noopener,noreferrer'
              );
            }}
          >
            {'https://smartping.live/entity/signup/Indian'}
          </text>
          <br />
          {'VI Registration link - '}
          <text
            style={{ color: colors.linkblue, textDecorationLine: 'underline' }}
            onClick={() => {
              window.open('https://www.vilpower.in/#', '_blank', 'noopener,noreferrer');
            }}
          >
            {'https://www.vilpower.in/#'}
          </text>
          <br />
          {'BSNL Registration link - '}
          <text
            style={{ color: colors.linkblue, textDecorationLine: 'underline' }}
            onClick={() => {
              window.open('https://www.ucc-bsnl.co.in', '_blank', 'noopener,noreferrer');
            }}
          >
            {'https://www.ucc-bsnl.co.in'}
          </text>
          <br />
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 8, flexWrap: 'wrap' }}>
          {
            '3. Once you have followed the required steps and uploaded your documents, you will be able to see your DLT PE ID or Registration ID which you can add to Cheerio.'
          }
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 8, flexWrap: 'wrap' }}>
          {'4. For header registration please follow the below steps:'}
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 8, flexWrap: 'wrap' }}>
          {'5. There are 2 types of Headers to choose from in DLT platforms.'}
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 8, flexWrap: 'wrap' }}>
          {'6. Please view below the registration process for each of the headers:'}
        </text>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-start w-75">
        <text style={{ ...Styles.TxtBody, marginBlock: 8, flexWrap: 'wrap' }}>
          {'Others (for SMS such as OTPs, alerts, etc.):'}
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 4, flexWrap: 'wrap' }}>
          {
            'Add your preferred 6-character alpha headers (sender ID) in the text box > Justify the chosen headers in the description box and/or by attaching a supporting document'
          }
        </text>
      </div>
      <div
        className="d-flex flex-column justify-content-center align-items-start w-75"
        style={{ marginBlockStart: 8, marginBottom: 30 }}
      >
        <text style={{ ...Styles.TxtBody, marginBlock: 8, flexWrap: 'wrap' }}>
          {'Promotional (for SMS such as offers, discounts, etc.):'}
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 4, flexWrap: 'wrap' }}>
          {
            'Add your preferred 6-digit numeric headers (sender ID) in ‘Find your Header’ text box > Select your category/industry type from the drop-down > Justify the chosen headers in the description box and/or by attaching a supporting document'
          }
        </text>
        <text
          style={{ ...Styles.TxtBody2, marginBlockStart: 8, marginBlockEnd: 8, flexWrap: 'wrap' }}
        >
          {
            '7. Once your promotional and others headers are approved on your DLT platform provider, add it to Cheerio'
          }
        </text>
      </div>
    </>
  );
};

export default SMSDocs;

const Styles = {
  TxtHeading: {
    fontSize: 24,
    fontWeight: 700,
    color: colors.black,
  },
  TxtBody: {
    fontSize: 18,
    fontWeight: 700,
    color: colors.black,
  },
  TxtBody2: {
    fontSize: 18,
    fontWeight: 600,
    color: colors.black04,
  },
};
