import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const AppSumoLogin = () => {
  // get token from query string
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Get a specific query parameter
  const token = searchParams.get('access');

  // Check if token exists

  useEffect(() => {
    console.log('token', token);
    if (token) {
      // Save token to local storage
      localStorage.setItem('token', token);
      // Redirect to dashboard
      setTimeout(() => {
        navigate('/?token=' + token);
      }, 1000);
      dispatch({ type: 'addAuthToken', payload: token });
      dispatch({ type: 'addLogin', payload: true });
      navigate('/');
    }
  }, [token]);

  return <div>AppSumo Login</div>;
};
