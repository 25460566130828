import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { eventsend } from '../../../Config/analyticsFunctions';
import ReactGA from 'react-ga4';
import { clickClubpollsApi, getupdatedClubpollsApi } from '../../../Services';
import { getImagePath } from '../../../Utils';
import colors from '../../../Utils/colors';

const PollsGen = (props) => {
  const tag_id = props.tag_id;
  const sender_id = props.sender_id;
  const text = props.text;

  const [pollgendata, setPollgendata] = useState();
  const [loading, setLoading] = useState(false);

  const authtoken = useSelector((state) => state.main.auth_token);
  const _id = useSelector((state) => state.main._id);

  useEffect(() => {
    setLoading(true);
    getpollstatuscallapi();
  }, []);

  const getpollstatuscallapi = () => {
    let data = {
      poll_id: tag_id,
      user_id: _id,
    };
    let token = authtoken;
    getupdatedClubpollsApi(data, token).then((res) => {
      if (res.flag) {
        setPollgendata(res.data);
      } else {
        console.log(res);
      }
    });
  };

  const onClickPoll = (index) => {
    ReactGA.event('clubs_general_polls_voted_web', { poll_id: pollgendata._id });
    eventsend('clubs_general_polls_voted_web', { poll_id: pollgendata._id });
    if (!pollgendata.votedStatus) {
      let data = {
        poll_id: pollgendata._id,
        option_id: index,
        user_id: _id,
      };
      let token = authtoken;
      clickClubpollsApi(data, token).then((res) => {
        if (res.flag) {
          getpollstatuscallapi();
        } else {
          console.log(res);
        }
      });
    } else {
      alert('you already voted');
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [pollgendata]);

  return (
    <>
      {loading ? (
        <>
          <SpinnerLoader />
        </>
      ) : (
        <>
          <div className="d-flex flex-column justify-content-start align-items-center mx-auto w-100">
            <div className="text-start align-self-start mt-1" style={styles.bodyTxt}>
              {text}
            </div>
            <img
              className="my-1"
              src={getImagePath(pollgendata?.image)}
              alt="poll image"
              style={pollgendata?.image ? { ...styles.imgStyle } : { display: 'none' }}
            />
            <div className="text-start align-self-start mt-1" style={styles.bodyTxt}>
              {pollgendata?.description}
            </div>
            <hr className="w-100" />
            <div
              className="mb-1"
              style={{ ...styles.bodytxt, flexWrap: 'wrap', wordBreak: 'break-word' }}
            >
              {pollgendata?.question}
            </div>
            {pollgendata?.options.map((elem, index) => (
              <div
                key={index}
                className="d-flex flex-row justify-content-between align-items-center mx-auto my-2"
                style={{ width: '80%', ...styles.pollbox }}
                onClick={() => onClickPoll(index)}
              >
                <div
                  className="w-100 text-center py-1 d-flex flex-row justify-content-between align-items-center px-3"
                  style={
                    elem.users.includes(_id)
                      ? {
                          background: `linear-gradient(90deg, #A470FF ${
                            elem.percentage - 20
                          }%, #fff ${elem.percentage}%)`,
                          ...styles.pollboxint,
                        }
                      : {
                          background: `linear-gradient(90deg, #FEAF52 ${
                            elem.percentage - 20
                          }%, #fff ${elem.percentage}%)`,
                          ...styles.pollboxint,
                        }
                  }
                >
                  <div style={styles.bodytxt}>{elem.text}</div>
                  {pollgendata.votedStatus ? (
                    <div className="" style={styles.bodytxt}>
                      {elem.percentage}
                      {'%'}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default PollsGen;

const styles = {
  bodytxt: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.black,
  },
  bodyTxt: {
    fontSize: 16,
    fontWeight: 400,
    color: colors.black,
  },
  pollbox: {
    borderStyle: 'solid',
    borderRadius: 10,
    borderColor: colors.black,
    borderWidth: 1,
  },
  pollboxint: {
    borderStyle: 'solid',
    borderRadius: 10,
    borderColor: colors.black,
    borderWidth: 0,
  },
  imgStyle: {
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
    borderRadius: 10,
  },
};
