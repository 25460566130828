import React from 'react';
import NewTemplateCreate from './Components/NewTemplateCreate';

const CreateTemplate = () => {
  return (
    <>
      <NewTemplateCreate />
    </>
  );
};

export default CreateTemplate;
