import React from 'react'
import colors from '../../../Utils/colors'
import { BiError } from "react-icons/bi";
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import { useNavigate } from 'react-router-dom';

const ConnectorLimitorModal = ({ setIsModalOpen }) => {

  const navigate = useNavigate();

  return (
    <>
    <div className='DarkBg' onClick={(e)=>{e.stopPropagation()}}>
      <div className='Center'>
        <div className='d-flex flex-column justify-content-between align-items-center' 
          style={{
            width: 510,
            maxHeight: '80vh',
            backgroundColor: colors.white01,
            borderRadius: 16,
            zIndex: 0,
            border: `1px solid ${colors.borderwhite}`,
            paddingBlock: 16,
            paddingInline: 20,
          }}
          onClick={(e)=>{e.stopPropagation()}}
        >
          <div className='d-flex flex-row justify-content-between align-items-center w-100' 
            style={{paddingBlockEnd:16,borderBottom:`1px solid ${colors.borderwhite}`}}
          >
            <BiError size={20} color={colors.error03} style={{marginInlineEnd:6}}/>
            <p style={{fontSize:20,fontWeight:600,color:colors.error03,marginInlineStart:0,marginInlineEnd:'auto'}}>
              {'Connector Limit'}
            </p>
            <img src={images.CMClose}
              style={{height:20,width:20,objectFit:'contain',cursor:'pointer'}}
              onClick={()=>{setIsModalOpen(false)}}
            >
            </img>
          </div>
          <p style={{fontSize:16,fontWeight:400,color:colors.error03,marginBlock:16,width:'100%'}}>
            {'You have reached your maximum workflow connectors limit. To add more connectors in a flow please upgrade'}
          </p>
          <p style={{fontSize:16,fontWeight:400,color:colors.black,marginBlockEnd:16,width:'100%'}}>
            {'Need help? '}
            <span style={{color:colors.linkblue,textDecorationLine:'underline',cursor:'pointer'}} 
              onClick={()=>{window.open('https://cheerio.freshdesk.com/support/tickets/new','_blank');}}
            >
              {'Contact us'}
            </span>
          </p>
          <div
              className="d-flex flex-row justify-content-between align-items-center w-100"
              style={{marginBlockStart:10}}
            >
              <CheerioButton
                borderStyle={{ width: '45%' }}
                textStyle={{
                  fontSize: 16,
                  padding: 0,
                  margin: 0,
                  fontWeight: 600,
                  color: colors.white,
                }}
                btnText={'Upgrade'}
                backColor={colors.primary03}
                // disabled={btndisabled}
                onclick={()=>{navigate('/addons')}}
              />
              <CheerioButton
                borderStyle={{ width: '45%', border: `1px solid ${colors.black}` }}
                textStyle={{
                  fontSize: 16,
                  padding: 0,
                  margin: 0,
                  fontWeight: 600,
                  color: colors.black,
                }}
                btnText={'Cancel'}
                backColor={colors.white}
                onclick={() => {
                  setIsModalOpen(false)
                }}
              />
            </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default ConnectorLimitorModal