import React, { useEffect, useState } from 'react';
import colors from '../../Utils/colors';
import CheerioButton from '../../Components/CheerioButton';
import images from '../../Utils/images';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCampaignListAPI, getScheduledCampaignListAPI } from '../../Services';
import AnnNewModal from './Components/AnnNewModal';
import ListSchedulersV2 from './Components/ListSchedulersV2';
import { toast } from 'react-toastify';
import { PremiumOptionModal } from '../../Components/Modal/PremiumOptionModal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { eventsend } from '../../Config/analyticsFunctions';
import SpinnerLoader from '../../Components/SpinnerLoader';
import StopCampaignModal from './Components/StopCampaignModal';
import { trackPages } from '../../Utils/brevo';
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { TfiReload } from "react-icons/tfi";
import { IoIosSearch } from 'react-icons/io';
import MailCSVModal from './Components/MailCSVModal';
import BetaFeatureBanner from '../../Components/BetaFeatureBanner';

export const newLineRemover = (strings) => {
  const words = strings.replace(/\n/g, ' ');
  return words;
};

const AnnouncementsNew = () => {
  const navigate = useNavigate();

  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const agentdata = useSelector((state) => state.main.agentdata);

  const [campaignList, setCampaignList] = useState();
  const [scheduledCampaignList, setScheduledCampaignList] = useState();
  const [openANModal, setOpenANModal] = useState(false);
  const isPremiumUser = localdata?.premium;

  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [rowsSch, setRowsSch] = useState([]);
  const [pageSch, setPageSch] = React.useState(0);
  const [rowsPerPageSch, setRowsPerPageSch] = React.useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [show, setShow] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const [enable, setEnable] = useState(false);
  const [isFTUser, setIsFTUser] = useState(false);
  const [searchData, setSearchData] = useState('');
  const [percent, setPercent] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedSort, setSelectedSort] = useState('latest');
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [showMailCsv, setShowMailCsv] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.openModal) {
      setOpenANModal(true);
    }
  }, [location.state]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    trackPages('campaigns_page', localdata);
  }, []);

  const getRemainingDays = (e) => {
    const d = new Date(e);
    const date = new Date();
    let dd = d.toDateString();
    let cd = date.toDateString();
    let diff = Math.floor((Date.parse(dd) - Date.parse(cd)) / 86400000);
    return diff;
  };

  const remainingDays = getRemainingDays(localdata?.free_trail?.end_date);
  useEffect(() => {
    if (remainingDays > 0) {
      setIsFTUser(true);
    }
  }, [localdata]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    campaignListApiCall(0, +event.target.value, searchData, selectedStatus, selectedSort, selectedChannel); // call when row per page option is changed
  };
  const handleChangePageSch = (event, newPage) => {
    setPageSch(newPage);
  };

  const handleChangeRowsPerPageSch = (event) => {
    setRowsPerPageSch(+event.target.value);
    setPageSch(0);
  };

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const newTimeout = setTimeout(() => {
      campaignListApiCall(page, rowsPerPage, searchData, selectedStatus, selectedSort, selectedChannel);
    }, 300);
    setDebounceTimeout(newTimeout);
    return () => clearTimeout(newTimeout);
  }, [page, rowsPerPage, searchData, selectedStatus, selectedSort, selectedChannel]);

  console.log("Component rendered with:", { page, rowsPerPage, searchData, selectedStatus, selectedSort });

  useEffect(() => {
    const isAnyRunning = rows.some((campaign) => campaign.status === 'Running');
    setEnable(isAnyRunning);
  }, [rows]);

  useEffect(() => {
    setIsLoading(true);
    eventsend('announcements_pageview_web');
    if (agentdata && !agentdata?.permission?.campaign) {
      toast.error(
        'Feature access denied. Kindly request the owner to grant you permission to access this feature'
      );
      navigate('/creator');
    }
    campaignListApiCall(page, rowsPerPage, searchData, selectedStatus, selectedSort, selectedChannel);
    schedulerCampaignListAPICall();
  }, []);

  const campaignListApiCall = (page, limit, searchData, selectedStatus, selectedSort, selectedChannel) => {
    let token = authtoken;
    console.log('page and limit', page, limit);
    setTableLoading(true);
    getCampaignListAPI(token, page + 1, limit, searchData, selectedStatus, selectedSort, selectedChannel).then((res) => {
      if (res?.flag) {
        setRows(res?.data?.list?.docs);
        setTotalCount(res?.data?.list?.total);
        // if (!selectedChannel || selectedChannel === 'all') {
        //   if (res?.data?.list?.docs[0]?.whatsapp_stats?.total > 0) {
        //     setSelectedChannel('whatsapp_stats');
        //   } else if (res?.data?.list?.docs[0]?.sms?.total > 0) {
        //     setSelectedChannel('sms');
        //   } else if (res?.data?.list?.docs[0]?.email_stats?.total > 0) {
        //     setSelectedChannel('email_stats');
        //   }
        // }
        console.log('campaign list res ===> ', res);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
        setTableLoading(false);
      } else {
        console.log('err campaign list res ===> ', res);
        setTableLoading(false);
      }
    });
  };

  const schedulerCampaignListAPICall = () => {
    let token = authtoken;
    getScheduledCampaignListAPI(token).then((res) => {
      if (res?.flag) {
        setScheduledCampaignList(res?.data?.result);
        setRowsSch(res?.data?.result);
      } else {
        console.log('error scheduler campaign list ===> ', res);
      }
    });
  };

  const getLabelDate = (e) => {
    const date = new Date(e);
    const mm = date.toLocaleString('en-EN', { month: 'short' });
    const dd = date.getDate();
    return dd + '-' + mm;
  };
  const getLabelDateTime = (e) => {
    const date = new Date(e);
    const mm = date.toLocaleString('en-EN', { month: 'short' });
    const dd = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return dd + '-' + mm + ' ' + hours + ':' + minutes;
  };

  console.log('scheduledCampaignList ===> ', rows);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-EN', { month: 'short' });
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${day}-${month}-${year}, ${formattedHours}:${formattedMinutes}${period}`;
  };

  const scheduledColumns = [
    { id: 'campaign_name', label: 'Campaign Name', minWidth: 225, align: 'start', width: '25%' },
    {
      id: 'created_on',
      label: 'Scheduled on',
      minWidth: 135,
      align: 'center',
      width: '15%',
      // format: (value) => {
      //   getLabelDate(value);
      // },
      format: (value) => {
        const date = new Date(value);
        const mm = date.toLocaleString('en-EN', { month: 'short' });
        const dd = date.getDate();
        return dd + '-' + mm;
      },
    },
    {
      id: 'ScheduleAt',
      label: 'Scheduled for',
      minWidth: 135,
      align: 'center',
      width: '15%',
      // format: (value) => {
      //   getLabelDate(value);
      // },
      format: (value) => {
        const date = new Date(value);
        const mm = date.toLocaleString('en-EN', { month: 'short' });
        const dd = date.getDate();
        return dd + '-' + mm;
      },
    },
    {
      id: 'template_type',
      label: 'Campaign Type',
      minWidth: 150,
      align: 'start',
      width: '25%',
      // align: 'right',
      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'label_column',
      label: 'Label',
      minWidth: 180,
      align: 'start',
      width: '20%',
      // align: 'right',
      format: (value) => {
        return value ? value : 'NA';
      },
    },
  ];

  const columns = [
    {
      id: 'template_name',
      label: 'Campaign Name',
      minWidth: 225,
      align: 'start',
      width: '25%',
      color: colors.linkblue
    },
    // {
    //   id: 'date',
    //   label: 'Timestamp',
    //   minWidth: 135,
    //   align: 'center',
    //   width: '15%',
    //   // format: (value) => {
    //   //   getLabelDate(value);
    //   // },
    //   format: (value) => {
    //     const date = new Date(value);
    //     const mm = date.toLocaleString('en-EN', { month: 'short' });
    //     const dd = date.getDate();
    //     const hours = date.getHours();
    //     const minutes = date.getMinutes();
    //     const period = hours >= 12 ? 'PM' : 'AM';
    //     const formattedHours = hours % 12 || 12;
    //     const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    //     return `${dd} ${mm}, ${formattedHours}:${formattedMinutes} ${period}`;
    //   },
    // },
    {
      id: 'label',
      label: 'Label',
      minWidth: 135,
      align: 'center',
      width: '10%',
      // align: 'right',
      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'label_name',
      label: 'Campaign Type',
      minWidth: 225,
      align: 'start',
      width: '25%',
      // align: 'right',
      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'channels',
      label: 'Channels',
      minWidth: 125,
      align: 'center',
      width: '25%',
      // align: 'right',
      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 125,
      align: 'center',
      width: '25%',
      // align: 'right',
    }, {
      id: 'total',
      label: 'Contacts',
      minWidth: 120,
      align: 'center',
      width: '25%',
      // align: 'right',
    },
    {
      id: 'sent',
      label: 'Sent',
      minWidth: 120,
      align: 'center',
      width: '25%',
      // align: 'right',
    },
    {
      id: 'delivery',
      label: 'Delivered',
      minWidth: 120,
      align: 'center',
      width: '25%',
      // align: 'right',
    },
    {
      id: 'opened',
      label: 'Opened',
      minWidth: 120,
      align: 'center',
      width: '25%',
      // align: 'right',
    },
    {
      id: 'link_Clicks',
      label: 'Clicked',
      minWidth: 120,
      align: 'center',
      width: '25%',
      // align: 'right',
    },
    {
      id: 'action',
      label: 'Action',
      minWidth: 90,
      align: 'center',
      width: '10%',
      // align: 'right',
      // format: (value) => value.toFixed(2),
    },
  ];

  return (
    <>
      {!isFTUser && !isPremiumUser && (
        <PremiumOptionModal
          option={'Campaigns'}
          openPremiumModal={true}
          setOpenPremiumModal={() => { }}
        ></PremiumOptionModal>
      )}
      {!isPremiumUser ? (
        <>
          <div className="announcement_container" >
            <div className="announcement_container__header_container">
              {/* <div className="" style={{ ...Styles.Row, width: '100%' }}> */}
              <h3>{'Campaigns'}</h3>
              <div style={{ display: 'flex', alignItems: ' center' }}>
                <CheerioButton
                  borderStyle={{}}
                  textStyle={{ color: colors.white }}
                  btnText={'Create a Campaign'}
                  backColor={colors.black}
                  onclick={() => {
                    setOpenANModal(true);
                  }}
                />
              </div>
            </div>
            <div className="campaign_table_container">
              <TableContainer sx={{ flex: 1 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                            fontSize: 16,
                            fontWeight: 500,
                            color: colors.black,
                            backgroundColor: colors.white04,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center" justifyContent="center">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={images.EmptyCampaign}
                            alt="No Data"
                            style={{ maxWidth: '40%', height: 'auto' }}
                          />
                          <p style={{ fontWeight: '700', margin: '6px' }}>
                            Here you will find all your campaigns{' '}
                          </p>
                          <p>Create your first campaign</p>
                          <CheerioButton
                            borderStyle={{ width: '20%', margin: '12px' }}
                            textStyle={{ color: colors.white }}
                            btnText={'Create a Campaign'}
                            backColor={colors.black}
                            onclick={() => {
                              setOpenANModal(true);
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
            {openANModal && (
              <AnnNewModal
                setOpenANModal={setOpenANModal}
                campaignListApiCall={campaignListApiCall}
              />
            )}
          </div>
        </>
      ) : (
        <>
          {isLoading ? (
            <>
              <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
                <SpinnerLoader />
              </div>
            </>
          ) : (
            <>
              <div
                className="announcement_container" style={{ overflowY: "unset" }}
              // className="d-flex flex-column justify-content-start align-items-center w-100 h-100"
              // style={{ paddingInline: 40, paddingBlock: 20 }}
              >
                {/* <BetaFeatureBanner 
                  BannerText={"We are experiencing some issues in analytics and campaign data. Please do not re-run your campaigns. Inconvenience is regretted and issue will be resolved soon."}
                /> */}
                {openANModal && (
                  <AnnNewModal
                    setOpenANModal={setOpenANModal}
                    campaignListApiCall={campaignListApiCall}
                  />
                )}

                <ListSchedulersV2 />
                {scheduledCampaignList?.length > 0 && (
                  <>
                    <div className="announcement_container__scheduled_header_container">
                      <h3>
                        {'Scheduled Campaign '}
                        <span style={{ fontSize: 18 }}>{'(Recommended)'}</span>
                      </h3>
                    </div>

                    <div className="scheduled_campaign_table_container">
                      <TableContainer sx={{ flex: 1 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {scheduledColumns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    minWidth: column.minWidth,
                                    width: column.width,
                                    fontSize: 16,
                                    fontWeight: 500,
                                    color: colors.black,
                                    backgroundColor: colors.white04,
                                  }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rowsSch
                              .filter((item) => {
                                return (
                                  item?.template_type !== 'shopify_order_cancel' &&
                                  item?.template_type !== 'shopify_order_confirm' &&
                                  item?.template_type !== 'shopify_order_fulfill' &&
                                  item?.template_type !== 'shopify_order_feedback' &&
                                  item?.template_type !== 'shopify_abandon_cart' &&
                                  item?.template_type !== 'woocommerce_abandoned_cart'
                                );
                              })
                              .slice(
                                pageSch * rowsPerPageSch,
                                pageSch * rowsPerPageSch + rowsPerPageSch
                              )
                              .map((row) => {
                                return (
                                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {scheduledColumns.map((column) => {
                                      const value = row[column.id];
                                      return (
                                        <TableCell key={column.id} align={column.align}>
                                          {column.format ? (
                                            // {column.format && typeof value === 'number'
                                            <p style={{ color: colors.black, fontSize: 14 }}>
                                              {column.format(value)}
                                            </p>
                                          ) : (
                                            <p style={{ color: colors.black, fontSize: 14 }}>
                                              {value}
                                            </p>
                                          )}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rowsSch.length}
                        rowsPerPage={rowsPerPageSch}
                        page={pageSch}
                        onPageChange={handleChangePageSch}
                        onRowsPerPageChange={handleChangeRowsPerPageSch}
                      />
                    </div>
                  </>
                )}

                <div className="announcement_container__header_container">
                  {/* <div className="" style={{ ...Styles.Row, width: '100%' }}> */}
                  <h3 style={{ display: 'inline-flex', alignItems: 'center' }}>
                    {'Recent Campaigns'}
                    <span
                      style={{ marginLeft: 5, cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                      onClick={() => {
                        campaignListApiCall(page, rowsPerPage, searchData, selectedStatus, selectedSort, selectedChannel);
                      }}
                    >
                      <TfiReload size={18} />
                    </span>
                  </h3>

                  <div style={{ display: 'flex', alignItems: ' center', gap: 7 }}>
                    {enable && (
                      <CheerioButton
                        borderStyle={{}}
                        textStyle={{ color: colors.black }}
                        btnText={'Stop all campaigns'}
                        backColor={colors.white}
                        icon={images.Stop}
                        onclick={() => {
                          setShow(true);
                          setSelectedCampaignId('');
                        }}
                      />
                    )}
                    <CheerioButton
                      btnText={'Export Report'}
                      backColor={colors.white}
                      onclick={() => {
                        setShowMailCsv(true);
                      }}
                      icon={images.FILEEXPORT}
                    />
                    <CheerioButton
                      borderStyle={{ border: "none" }}
                      textStyle={{ color: colors.white }}
                      btnText={'New Campaign'}
                      backColor={colors.darkPurple}
                      onclick={() => {
                        setOpenANModal(true);
                      }}
                      icon={images.IPlusWhite}
                    />

                  </div>
                </div>

                <div className='announcement_container__header_container'>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }} >
                    <InputGroup style={{ width: '100%', marginRight: "8px" }}>
                      <InputGroup.Text
                        style={{
                          borderRightWidth: 0,
                          backgroundColor: 'transparent',
                          borderColor: '#CCCCCC',
                          paddingInline: '8px',
                        }}
                      >
                        <IoIosSearch size={22} />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Search for campaign"
                        style={{
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          borderColor: '#CCCCCC',
                          borderLeftWidth: 0,
                          paddingInlineStart: 0,
                          zIndex: 1,
                          outline: 'none',
                          boxShadow: 'none',
                          paddingRight: '10px',
                          transition: 'border-color 0.2s ease-in-out, padding 0.2s ease-in-out',
                        }}
                        value={searchData}
                        onFocus={(e) => {
                          e.target.style.borderColor = '#B3B3B3';
                        }}
                        onBlur={(e) => {
                          e.target.style.borderColor = '#CCCCCC';
                        }}
                        onChange={(e) => {
                          setSearchData(e.target.value);
                        }}
                      />
                    </InputGroup>

                    <FormControl fullWidth style={{ borderRadius: 8, width: "86%" }}>

                      <Select
                        labelId="select-label"
                        defaultValue="whatsapp_stats"
                        style={{ borderRadius: 8, fontSize: 12, height: 39, width: 150 }}
                        onFocus={
                          (e) => {
                            e.target.style.borderColor = colors.darkPurple;
                          }
                        }
                        MenuProps={{
                          PaperProps: {
                            style: {
                              borderRadius: 8,
                            },
                          },
                        }}
                        value={selectedChannel}
                        onChange={(e) => {
                          setSelectedChannel(e.target.value);
                        }}
                      >
                        <MenuItem value="all">All Channels</MenuItem>
                        <MenuItem value="whatsapp_stats">WhatsApp</MenuItem>
                        <MenuItem value="sms">SMS</MenuItem>
                        <MenuItem value="email_stats">Email</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth style={{ borderRadius: 8, width: "66%" }}>

                      <Select
                        labelId="select-label"
                        defaultValue="all"
                        style={{ borderRadius: 8, fontSize: 12, height: 39, width: "110px" }}
                        onFocus={
                          (e) => {
                            e.target.style.borderColor = colors.darkPurple;
                          }
                        }
                        MenuProps={{
                          PaperProps: {
                            style: {
                              borderRadius: 8,
                            },
                          },
                        }}
                        value={selectedStatus}
                        onChange={(e) => {
                          setSelectedStatus(e.target.value);
                        }}
                      >
                        <MenuItem value="all">All Status</MenuItem>
                        <MenuItem value="Completed">Completed</MenuItem>
                        <MenuItem value="Stopped">Stopped</MenuItem>
                        <MenuItem value="Running">Running</MenuItem>
                        <MenuItem value="Queued">Queued</MenuItem>
                        <MenuItem value="Failed">Failed</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth style={{ borderRadius: 8, width: "72%" }}>

                      <Select
                        labelId="select-label"
                        defaultValue="latest"
                        style={{ borderRadius: 8, fontSize: 12, height: 39, width: 120 }}
                        onFocus={
                          (e) => {
                            e.target.style.borderColor = colors.darkPurple;
                          }
                        }
                        MenuProps={{
                          PaperProps: {
                            style: {
                              borderRadius: 8,
                            },
                          },
                        }}
                        value={selectedSort}
                        onChange={(e) => {
                          setSelectedSort(e.target.value);
                        }}
                      >
                        <MenuItem value="latest">Latest</MenuItem>
                        <MenuItem value="oldest">Oldest</MenuItem>
                      </Select>
                    </FormControl>

                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      <div
                        style={{
                          background: percent ? colors.white : colors.darkPurple,
                          height: 39,
                          width: 39,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderTopLeftRadius: 8,
                          borderBottomLeftRadius: 8,
                          color: colors.black,
                          cursor: 'pointer',
                        }
                        } onClick={() => setPercent(false)}>
                        <svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.73193 12.0431V3.17412H0.702148V0.95686H4.76171V12.0431H2.73193ZM21 9.82588C21 10.4139 20.7861 10.9779 20.4055 11.3937C20.0248 11.8095 19.5085 12.0431 18.9702 12.0431H14.9106V9.82588H18.9702V7.60863H16.9404V5.39137H18.9702V3.17412H14.9106V0.95686H18.9702C19.5085 0.95686 20.0248 1.19046 20.4055 1.60628C20.7861 2.02209 21 2.58606 21 3.17412V4.83706C21 5.2781 20.8396 5.70107 20.5541 6.01293C20.2686 6.3248 19.8814 6.5 19.4776 6.5C19.8814 6.5 20.2686 6.6752 20.5541 6.98706C20.8396 7.29892 21 7.7219 21 8.16294V9.82588ZM12.8808 9.82588V12.0431H6.7915V7.60863C6.7915 7.02057 7.00535 6.45661 7.38601 6.04079C7.76666 5.62497 8.28295 5.39137 8.82128 5.39137H10.8511V3.17412H6.7915V0.95686H10.8511C11.3894 0.95686 11.9057 1.19046 12.2863 1.60628C12.667 2.02209 12.8808 2.58606 12.8808 3.17412V5.39137C12.8808 5.97942 12.667 6.54339 12.2863 6.95921C11.9057 7.37502 11.3894 7.60863 10.8511 7.60863H8.82128V9.82588H12.8808Z" fill={percent ? "#CCCCCC" : '#F5F5F5'} />
                        </svg>
                      </div>
                      <div
                        style={{
                          background: percent ? colors.darkPurple : colors.white,
                          height: 39,
                          width: 39,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          cursor: 'pointer',
                        }
                        } onClick={() => setPercent(true)}>
                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.7057 5.06183C4.09491 5.06183 4.42516 4.91756 4.69643 4.62903C4.9736 4.33423 5.11219 3.97984 5.11219 3.56586C5.11219 3.15188 4.9736 2.80063 4.69643 2.5121C4.42516 2.21729 4.09491 2.06989 3.7057 2.06989C3.31648 2.06989 2.98329 2.21729 2.70612 2.5121C2.43484 2.80063 2.29921 3.15188 2.29921 3.56586C2.29921 3.97984 2.43484 4.33423 2.70612 4.62903C2.98329 4.91756 3.31648 5.06183 3.7057 5.06183ZM6.99636 3.56586C6.99636 4.53181 6.67496 5.35663 6.03216 6.04032C5.39526 6.72401 4.61977 7.06586 3.7057 7.06586C2.79752 7.06586 2.02204 6.72401 1.37924 6.04032C0.736438 5.35663 0.415039 4.53181 0.415039 3.56586C0.415039 2.59991 0.736438 1.77509 1.37924 1.0914C2.02204 0.407706 2.79752 0.0658602 3.7057 0.0658602C4.61387 0.0658602 5.38936 0.407706 6.03216 1.0914C6.67496 1.77509 6.99636 2.59991 6.99636 3.56586ZM13.7104 10.4153C13.7104 10.0013 13.5718 9.65009 13.2946 9.36156C13.0233 9.06676 12.6931 8.91935 12.3039 8.91935C11.9146 8.91935 11.5815 9.06676 11.3043 9.36156C11.033 9.65009 10.8974 10.0013 10.8974 10.4153C10.8974 10.8293 11.033 11.1837 11.3043 11.4785C11.5815 11.767 11.9146 11.9113 12.3039 11.9113C12.6931 11.9113 13.0233 11.767 13.2946 11.4785C13.5718 11.1837 13.7104 10.8293 13.7104 10.4153ZM15.5945 10.4153C15.5945 11.3813 15.2731 12.2061 14.6303 12.8898C13.9875 13.5735 13.212 13.9153 12.3039 13.9153C11.3957 13.9153 10.6202 13.5735 9.97741 12.8898C9.33461 12.2061 9.01321 11.3813 9.01321 10.4153C9.01321 9.4431 9.33461 8.61828 9.97741 7.94086C10.6202 7.25717 11.3957 6.91532 12.3039 6.91532C13.212 6.91532 13.9875 7.25717 14.6303 7.94086C15.2731 8.62455 15.5945 9.44937 15.5945 10.4153ZM10.8974 0H12.2419L5.0945 14H3.72339L10.8974 0Z" fill={percent ? '#F5F5F5' : "#CCCCCC"} />
                        </svg>

                      </div>
                    </div>


                  </div>
                </div>

                <div style={{
                  width: '100%',
                }}>
                  <TableContainer sx={{ flex: 1, height: "68vh", marginTop: 2 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                width: column.width,
                                fontSize: 16,
                                fontWeight: 500,
                                color: colors.black,
                                backgroundColor: colors.white04,
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {tableLoading ?
                        <TableBody style={{ height: '100%' }}>
                          <TableRow>
                            <TableCell colSpan={columns.length} align="center" style={{ padding: 0, height: '100%' }}>
                              <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: '50vh',  // Ensures the loader is centered within the full height of the viewport
                                width: '100%'
                              }}>
                                <SpinnerLoader />
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>

                        : <TableBody>
                          {rows
                            .filter((item) => {
                              return (
                                item?.label_name !== 'shopify_order_cancel' &&
                                item?.label_name !== 'shopify_order_confirm' &&
                                item?.label_name !== 'shopify_order_fulfill' &&
                                item?.label_name !== 'shopify_order_feedback' &&
                                item?.label_name !== 'shopify_abandon_cart' &&
                                item?.label_name !== 'woocommerce_abandoned_cart'
                              );
                            })
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                              const getChannelName = () => {
                                if (selectedChannel === 'email_stats') return 'Email';
                                if (selectedChannel === 'sms') return 'SMS';
                                if (selectedChannel === 'whatsapp_stats') return 'WhatsApp';
                                if (selectedChannel === "all") {
                                  if (row?.email_stats?.total > 0) return 'Email';
                                  if (row?.sms?.total > 0) return 'SMS';
                                  if (row?.whatsapp_stats?.total > 0) return 'WhatsApp';
                                }
                                return '-';
                              };

                              return (
                                <>
                                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {columns.map((column) => {
                                      let value = '';
                                      let total = 0;
                                      let stats = {};
                                      if (['sent', 'delivery', 'opened', 'link_Clicks', 'total'].includes(column.id)) {
                                        if (selectedChannel === 'all') {
                                          const channelName = getChannelName();
                                          stats = channelName === 'Email' ? row?.email_stats || {} :
                                            channelName === 'SMS' ? row?.sms || {} :
                                              channelName === 'WhatsApp' ? row?.whatsapp_stats || {} : {};
                                        } else {
                                          stats = selectedChannel === 'whatsapp_stats' ? row?.whatsapp_stats || {} :
                                            selectedChannel === 'email_stats' ? row?.email_stats || {} :
                                              selectedChannel === 'sms' ? row?.sms || {} : {};
                                        }

                                        const statKey = column.id;
                                        value = stats[statKey] || 0;
                                        total = stats.total || 1;
                                        if (percent) {
                                          value = statKey === 'total' ? (stats[statKey] || 0) : (((stats[statKey] || 0) / total * 100).toFixed(2) + '%');
                                        } else {
                                          value = stats[statKey] || 0;
                                        }
                                      } else if (column.id !== 'channels') {
                                        value = row?.announcement[column.id] || '-';
                                      }
                                      const statuVal = row?.status ? row?.status : 'Completed';
                                      const dateValue = row?.announcement?.date ? formatDate(new Date(row?.announcement?.date)) : '';
                                      return (
                                        <TableCell key={column.id} align={column.align}>
                                          {column.format ? (
                                            // {column.format && typeof value === 'number'
                                            <p style={{ color: colors.black, fontSize: 14 }}>
                                              {column.format(value)}
                                            </p>
                                          ) : column.id === 'action' ? (
                                            <div>
                                              <img
                                                src={images.BarChart}
                                                height={18}
                                                style={{
                                                  cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                  navigate(`/creator/analytics?campaign=${row?._id}`, {
                                                    state: { CID: row.campaign_id },
                                                  });
                                                }}
                                              />
                                              {row?.status === 'Running' && (
                                                <img
                                                  src={images.Stop}
                                                  height={22}
                                                  style={{
                                                    cursor: 'pointer',
                                                    marginLeft: 10,
                                                  }}
                                                  onClick={() => {
                                                    setShow(true);
                                                    setSelectedCampaignId(row?._id);
                                                  }}
                                                />
                                              )}
                                            </div>
                                          ) : (
                                            <>
                                              <p
                                                style={{
                                                  color:
                                                    column.id === 'status'
                                                      ? row?.status === 'Running'
                                                        ? colors.green
                                                        : row?.status === 'Stopped'
                                                          ? colors.red49
                                                          : colors.black
                                                      : column.id === 'template_name'
                                                        ? colors.linkblue
                                                        : colors.black,
                                                  fontSize: 14,
                                                  textDecoration:
                                                    column.id === 'template_name' ? 'underline' : 'none',
                                                  cursor: column.id === 'template_name' ? 'pointer' : 'default',
                                                }}
                                                onClick={() => {
                                                  if (column.id === 'template_name') {
                                                    navigate(`/creator/analytics?campaign=${row?._id}`, {
                                                      state: { CID: row.campaign_id },
                                                    });
                                                  }
                                                }}
                                              >
                                                {column.id === 'channels' ? getChannelName() : ""}
                                                {column.id === 'status' ? statuVal : value}
                                              </p>
                                              {(dateValue && column.id === 'template_name') && (
                                                <p style={{ fontSize: 12, color: "#666666" }}>
                                                  {dateValue}
                                                </p>
                                              )}
                                            </>
                                          )}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                </>
                              );
                            })}
                        </TableBody >}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[15, 25, 100]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </>
          )}
          {show && (
            <StopCampaignModal
              campaignId={selectedCampaignId}
              setShow={setShow}
              page={page}
              rowsPerPage={rowsPerPage}
              show={show}
              searchData={searchData}
              selectedStatus={selectedStatus}
              selectedSort={selectedSort}
              selectedChannel={selectedChannel}
              campaignListApiCall={campaignListApiCall}
            />
          )}
          {showMailCsv && (
            <MailCSVModal
              show={showMailCsv}
              setShow={setShowMailCsv}
            />
          )}
        </>
      )}
    </>
  );
};

export default AnnouncementsNew;

const Styles = {
  Row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
