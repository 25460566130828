import React, { useState } from 'react';
import { CardImg } from 'react-bootstrap';
import images from '../../../Utils/images';
import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';

function WAUserProfile(props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.white,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ marginTop: 20 }}>
        <p style={styles.title}>Customer details</p>
      </div>
      <CardImg src={images.user1} style={{ height: 98, width: 98, marginTop: 30 }} />
      <div style={styles.profileBody}>
        <p
          style={{
            fontWeight: 'bolder',
            color: colors.black,
            fontSize: 16,
            margin: 0,
            marginBottom: 10,
          }}
        >
          Chetan Mishra
        </p>
        <div style={{ width: '100%', backgroundColor: colors.white, height: 1 }}></div>
        <div style={styles.profileRow}>
          <p style={styles.txt}>Query Status</p>
          <p style={styles.txt}>Unresolved</p>
        </div>
        <div style={styles.profileRow}>
          <p style={styles.txt}>Template messages</p>
          <p style={styles.txt}>1</p>
        </div>
        <div style={styles.profileRow}>
          <p style={styles.txt}>Session messages</p>
          <p style={styles.txt}>6</p>
        </div>
        <div style={styles.profileRow}>
          <p style={styles.txt}>Phone noCampaign name</p>
          <p style={styles.txt}>98766554444</p>
        </div>
        <div style={styles.profileRow}>
          <p style={styles.txt}>Campaign name</p>
          <p style={styles.txt}>Share an offer</p>
        </div>
        <div style={styles.profileRow}>
          <p style={styles.txt}>Campaign date</p>
          <p style={styles.txt}>23-Jun-23, 5:00PM</p>
        </div>
        <div style={styles.profileRow}>
          <p style={styles.txt}>Contact labels</p>
          <p style={styles.txt}>New leads, drop offers</p>
        </div>

        <CheerioButton
          borderStyle={{ width: '80%', marginTop: '32%', borderWidth: 0 }}
          backColor={colors.themeBlue}
          icon={images.HNProfileSetup}
          textStyle={{ color: colors.white }}
          btnText={'Mark as resolved'}
        />
      </div>
    </div>
  );
}

export default WAUserProfile;

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: { marginLeft: 12, color: colors.black, fontWeight: 'bolder' },
  profileRow: {
    display: 'flex',
    width: '100%',
    paddingRight: 14,
    paddingLeft: 14,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  txt: {
    margin: 0,
    fontSize: 14,
    fontWeight: 'normal',
    color: colors.darkgrey,
    textAlign: 'start',
    marginTop: 10,
  },
  profileBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 14,
    paddingBottom: 14,
    backgroundColor: colors.white04,
    marginTop: 30,
    width: '100%',
    borderRadius: 8,
  },
};
