import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import '../../../Components/Modal/Modal.css';

const SendOtpReq = ({
  closeOtpAction,
  facebookId,
  setFacebookId,
  startVerifyAction,
  agentLogin,
}) => {
  return (
    <>
      <div className="ModalDarkBg">
        <div className="Center">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              startVerifyAction();
            }}
          >
            <div className="otp_verify_modal">
              <div className="otp_verify_modal__header">
                <img src={images.CMClose} onClick={closeOtpAction}></img>
              </div>
              <div className="Row">
                <p style={Styles.freeTrial}>Forgot Password</p>
              </div>

              <div className="otp_verify_modal__body">
                <h6 className="enter_otp_text">
                  {agentLogin && 'Enter Email ID to reset password'}
                  {!agentLogin && 'Enter Email ID / Contact to reset password'}
                </h6>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter Phone No. / Email Id"
                  value={facebookId}
                  onChange={(e) => setFacebookId(e.target.value)}
                />
              </div>

              <div style={{ width: '100%', marginTop: 'auto', marginBottom: '5%' }}>
                <button style={{ width: '100%' }} type="submit" className="btn btn-dark">
                  Send OTP
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SendOtpReq;

const Styles = {
  DarkBg: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    width: '100vw',
    height: '100vh',
    // flex:1,
    zIndex: 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'fixed',
    zIndex: '1231423',
  },
  Center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  TxtHeading: {
    fontSize: 42,
    fontWeight: 900,
    color: colors.primary03,
  },
  TxtBody: {
    fontSize: 20,
    fontWeight: 600,
    color: colors.black03,
  },
  TxtBody2: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.black03,
  },
  itemborder: {
    backgroundColor: colors.white,
    borderRadius: 16,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.15),-2px -2px 10px 0 rgba(0, 0, 0, 0.15)',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  freeTrial: {
    margin: 0,
    fontSize: 24,
    fontWeight: 700,
    color: colors.black,
    fontWeight: 'bold',
  },
};
