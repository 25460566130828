import React from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images'
import CheerioButton from '../../../Components/CheerioButton'
import { Modal } from 'react-bootstrap'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const PremiumSuccess = ({ ModalOpen, setModalOpen, downloadInvoiceAction }) => {
    const navigate = useNavigate();
    const handleClose = () => setModalOpen(false);
    useEffect(() => {
        let timer;
        if (ModalOpen) {
            timer = setTimeout(() => {
                handleClose();
                navigate('/');
            }, 10000);
        }
        return () => clearTimeout(timer);
    }, [ModalOpen, navigate]);

    return (
        <Modal show={ModalOpen} onHide={handleClose} centered>
            <Modal.Body className='p-0'>
                <div className='d-flex flex-column justify-content-start align-items-center' style={{
                    maxHeight: '80vh',
                    width: '100%',
                    backgroundColor: colors.white01,
                    border: `1px solid ${colors.borderwhite}`,
                    borderRadius: 16,
                    paddingBlock: 20,
                    paddingInline: 30,
                }}>
                    <img
                        src={images.Success}
                        style={{
                            height: 200,
                            width: 200,
                            objectFit: 'contain',
                            marginBlock: 32,
                        }}
                    />
                    <h3 className='my-2' style={{ fontSize: 20, fontWeight: 700, color: colors.black, textAlign: 'center' }}>
                        {'Congratulations'}<br />
                        {'Thanks for Subscribing to Cheerio AI'}
                    </h3>
                    <p className='my-3' style={{ fontSize: 16, fontWeight: 400, color: colors.black, textAlign: 'center' }}>
                        {'It will take a couple of minutes for your payment to reflect on your Cheerio Dashboard. Please reload the page after 2 mins. Redirecting to Cheerio Dashboard in 10 sec'}
                    </p>
                    <CheerioButton
                        borderStyle={{ width: '96%', marginBlockStart: 24, borderColor: colors.primary03 }}
                        textStyle={{ color: colors.white }}
                        backColor={colors.primary03}
                        icon={images.downloadWhite}
                        btnText={'Download Invoice'}
                        onclick={() => {
                            console.log("btn clicked ...")
                            downloadInvoiceAction();
                        }}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PremiumSuccess