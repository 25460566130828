import { Modal, Button } from "react-bootstrap";
export const KeyPressOverviewModal = ({ show, onHide }) => {
    return (
        <Modal
            show={show}
            centered
            style={{ borderRadius: '16px' }}
        >
            <Modal.Header>
                <Modal.Title>Updates for You!✨</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p
                    style={{
                        paddingBottom: '1%'
                    }}
                >Now, You can use shortcuts in workflow builder to ease up your process.</p>
                <ul>
                    <li><strong>Ctrl + D:</strong> to duplicate the node</li>
                    <li><strong>Ctrl + Z:</strong> to undo the nodes/Connections</li>
                    <li><strong>Ctrl + Y:</strong> to redo the nodes/Connections</li>
                    <li><strong>Delete / Backspace:</strong> to delete the node</li>
                </ul>
            </Modal.Body>
            <Modal.Footer style={{ border: 'none', justifyContent: 'center', padding: '0 16px 16px' }}>
                <Button
                    variant="primary"
                    onClick={onHide}
                    style={{
                        backgroundColor: '#8E53F7',
                        borderColor: '#8E53F7',
                        borderRadius: '12px',
                        width: '100%',
                        padding: '10px 0'
                    }}
                >
                    Okay, I understand
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
