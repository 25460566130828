import moment from 'moment';
import * as React from 'react';

export default class Helper extends React.Component {
  static login_type = false;
  static device_token = false;
  static isSocketConnected = false;
  static ChatlistData = [];
  static currentUserID = false;
  static isChatLoad = true;
  static JoinClubLink = '';
  static isSocketConnect = false;
  static autoSubmitShopifyTemplates = true;
  static EmbeddedAI = false;

  static SelectedLabelName = '';
  static HelperKeywordActionID = '';
  static HelperEditKeywordAction = false;
  static PartnerToken = '';
  static ReferralBanner = true;
  static ConnectionCounter = 0;

  static getTime = (e) => {
    const d = new Date(e);
    return moment().format('DD-MMM-yy') == moment(d).format('DD-MMM-yy')
      ? moment(d).format('DD-MMM-YY, hh:mm A')
      : moment(d).format('DD-MMM-YY, hh:mm A');
  };

  static base64(file, callback) {
    var coolFile = {};
    function readerOnload(e) {
      var base64 = btoa(e.target.result);
      coolFile.base64 = base64;
      callback(coolFile);
    }

    var reader = new FileReader();
    reader.onload = readerOnload;

    var file = file;
    coolFile.filetype = file.type;
    coolFile.size = file.size;
    coolFile.filename = file.name;
    reader.readAsBinaryString(file);
  }
}
