import React, { useEffect, useState } from 'react';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import { Checkbox } from '@mui/material';
import { useSelector } from 'react-redux';
import { setupKeywordAction, updateKeywordAction } from '../../../Services';
import { toast } from 'react-toastify';
import Helper from '../../../Utils/Helper';

const AssignAgentAction = ({ object, options, dataObject, setDataObject, saveSuccessAction }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const [assign, setAssign] = useState(false);
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;

  useEffect(() => {
    if (dataObject?.key == 'Assign chat') {
      setAssign(true);
    }
  }, [dataObject]);

  const saveKeywordAction = () => {
    let apidata = {
      action: 'assignAgent',
      keywords: options[0]?.keywords,
      keywordCriteria: options[0]?.criteria,
      data: {
        key: 'Assign chat',
      },
    };

    if (object && 'name' in object && 'startTime' in object && 'endTime' in object) {
      apidata = {
        ...apidata,
        timeFrame: {
          name: object?.name,
          startTime: object?.startTime,
          endTime: object?.endTime,
        },
      };
    }

    if (dataObject?.key == 'Assign chat' || Helper.HelperEditKeywordAction) {
      updateKeywordAction(apidata, authtoken, Helper.HelperKeywordActionID).then((res) => {
        if (res?.flag) {
          toast.success('keyword action updated');
          saveSuccessAction();
        } else {
          toast.error(res?.message);
        }
      });
    } else {
      setupKeywordAction(apidata, authtoken).then((res) => {
        if (res?.flag) {
          toast.success('keyword action saved');
          saveSuccessAction();
        } else {
          toast.error(res?.message);
        }
      });
    }
    // console.log("api data ---> ",apidata)
  };

  return (
    <>
      <div className="agent_responses_container">
        <div className="agent_responses_container__header_row">
          <h3>{`Assign Chat`}</h3>
          <CheerioButton
            borderStyle={{
              borderWidth: 0,
            }}
            textStyle={{
              fontWeight: 600,
              color: colors.white01,
            }}
            btnText={'Save'}
            backColor={colors.primary03}
            disabled={assign && options?.length > 0 && isPremiumUser ? false : true}
            onclick={() => {
              console.log('next clicked');
              saveKeywordAction();
              // nextClickAction();
            }}
          />
        </div>
        <div
          className="d-flex flex-column justify-content-start align-items-center px-3 py-3 mt-3"
          style={{
            border: `1px solid ${colors.borderwhite}`,
            width: 320,
            justifySelf: 'flex-start',
            borderRadius: 10,
            alignSelf: 'flex-start',
          }}
        >
          <div className="d-flex flex-row justify-content-start align-items-center w-100">
            <Checkbox
              checked={assign}
              onChange={() => {
                setAssign(!assign);
              }}
            />
            <p style={{ fontWeight: 600 }}>{'Agent'}</p>
          </div>
          <p style={{ color: colors.greys04, marginBlock: 16 }}>
            {'Chat will be assign to agents automatically based on the ticket loads per agent'}
          </p>
        </div>
      </div>
    </>
  );
};

export default AssignAgentAction;
