import React, { useEffect } from 'react'
import { TextField } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import colors from '../../../Utils/colors';

const FormInput = ({ field, userResponse, index, handleInputChange, general, error, setError }) => {

    useEffect(() => {
        console.log("ssssss ", userResponse)
    }, [])
    const [errorMsg, setErrorMsg] = React.useState('');

    const onchangePhone = (e, d, rv, fv) => {
        let no = fv.substring(fv.indexOf(' ') + 1);
        handleInputChange(e)
        // console.log("dsdsds ",e)
        // console.log('no  ', no);
        // console.log('d.dialCode  ', d.dialCode);
    };
    const handleEmailChange = (e) => {
        const email = e.target.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setErrorMsg((prev) => ({ ...prev, email: 'Invalid email address' }));
            setError(true)
        } else {
            setErrorMsg((prev) => ({ ...prev, email: '' }));
            setError(false)
        }
        handleInputChange(email);
    };

    return (
        <>
            <div className='d-flex flex-column justify-content-start align-items-start w-100 my-2'>
                <p style={{
                    fontFamily: general.font,
                    fontSize: general.fieldHeaderSize,
                    color: general.textColor,
                }}>
                    {field.fieldName}
                    <span style={{ color: colors.error03 }}>
                        {field.mandatory ? ' *' : ''}
                    </span>
                </p>
                {field.fieldType == 'phone' ? (
                    <>
                        <PhoneInput
                            inputStyle={{ width: '100%', height: 40, marginBlockStart: 8, backgroundColor: general?.textFieldColor ?? colors.white01 }}
                            country={'in'}
                            // value={phoneNumber}
                            onChange={onchangePhone}
                        />
                    </>
                ) : field.fieldType === 'email' ? (
                    <>
                        <TextField
                            name="email"
                            type="email"
                            size="small"
                            error={!!errorMsg.email}
                            helperText={errorMsg.email}
                            onChange={handleEmailChange}
                            style={{
                                fontSize: general.textSize,
                                marginBlockStart: 8,
                                width: '100%',
                                backgroundColor: general?.textFieldColor ?? colors.white01,
                            }}
                            className='btncustom'
                            FormHelperTextProps={{
                                style: {
                                    backgroundColor: general?.bodyColor, // Set the background color for the helper text
                                    margin: 0,
                                },
                            }}
                        />
                    </>
                ) : (
                    <>
                        <TextField
                            name="fieldName"
                            // value={userResponse?.response}
                            onChange={(e) => {
                                handleInputChange(e.target.value)
                            }}
                            type='text'
                            size='small'
                            style={{
                                fontSize: general.textSize,
                                marginBlockStart: 8,
                                width: '100%',
                                backgroundColor: general?.textFieldColor ?? colors.white01
                            }}
                            className='btncustom'
                        />
                    </>
                )}
                {/* <TextField 
            name="fieldName"
            value={field.fieldName}
            onChange={handleFieldNameChange}
            type='text'
            size='small'
            style={{
                marginBlockStart:8,
                width:'100%',
            }}
            className='btncustom'
        /> */}
            </div>
        </>
    )
}

export default FormInput