import React, { useEffect, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import images from '../../Utils/images';
import CheerioButton from '../../Components/CheerioButton';
import './styles.css';
import { getLabelDate } from '.';
import { GetTemplatesV2API, individualTemplateBasedAnalytics, templateBasedAnalytics } from '../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const DateSelector = ({ Cname, setDate, date }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="d-flex justify-content-between" style={{ marginBottom: '10px' }}>
        <div style={{ width: '50%' }}>
          <DatePicker
            value={date.startDate}
            disabled={Cname}
            onChange={(newVal) =>
              setDate((prev) => {
                return { ...prev, startDate: newVal };
              })
            }
            slotProps={{ textField: { size: 'small', placeholder: 'Start' } }}
            sx={{
              '& .MuiInputBase-root': {
                height: '30px',
              },
            }}
          />
        </div>
        <div style={{ marginLeft: '10px', marginRight: '10px' }}>-</div>
        <div style={{ width: '50%' }}>
          <DatePicker
            minDate={date.startDate}
            value={date.endDate}
            slotProps={{ textField: { size: 'small', placeholder: 'End' } }}
            disabled={Cname}
            sx={{
              '& .MuiInputBase-root': {
                height: '30px',
              },
            }}
            onChange={(newVal) =>
              setDate((prev) => {
                return { ...prev, endDate: newVal };
              })
            }
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};

export const FilterMenu = ({
  setShowFilters,
  templateName,
  setTemplateName,
  setCampaignDetails,
  Cname,
  setCname,
  setUserAnalytics,
  setCampaignName,
  campaignList,
  showFeature,
  date,
  setDate,
  numberOfDate,
  setNumberOfDate,
  setGraphLoading,
  setUserAnalyticsPagination,
  setRows,
  setAnalyticsLoading,
  page,
  setPage,
  setPaginationData,
  templateBasedIndividualAnalyticsApiCall,
}) => {
  const [templateData, setTemplateData] = useState();

  const [toggleFilter, setToggleFilter] = useState({
    day: true,
  });
  const filterOptions = {
    day: ['1', '2', '3 ', '7 ', '30 '],
  };
  const authtoken = useSelector((state) => state.main.auth_token);

  const templateBasedAnalyticsApiCall = () => {
    setGraphLoading(true);
    const data = {
      templateName: templateName,
      selectedNumberOfDate: numberOfDate,
      startDate: date.startDate,
      endDate: date.endDate,
    };
    templateBasedAnalytics(data, authtoken).then((res) => {
      if (res?.data) {
        setCampaignDetails(res?.data?.result);
        // setUserAnalytics(null);
        // setRows(null);
        // if (res?.data?.campaignDetails?.length > 0) {
        //   setUserAnalytics(null);
        //   setRows(null);
        // }
        // else {
        //   setUserAnalytics(null)
        // }
        setGraphLoading(false);
      } else {
        toast.error('errror: ' + res?.message, { position: 'top-center' });
        setGraphLoading(false);
      }
    });
  };
  // const templateBasedIndividualAnalyticsApiCall = () => {
  //   setAnalyticsLoading(true);
  //   const data = {
  //     templateName: templateName,
  //     selectedNumberOfDate: numberOfDate,
  //     startDate: date.startDate,
  //     endDate: date.endDate,
  //   };
  //   individualTemplateBasedAnalytics(data, authtoken, page).then((res) => {
  //     if (res?.data) {
  //       setUserAnalytics(res?.data?.docs);
  //       setRows(res?.data?.docs);
  //       setPaginationData(res?.data)
  //       // if (res?.data?.campaignDetails?.length > 0) {
  //       //   setUserAnalytics(null);
  //       //   setRows(null);
  //       // }
  //       // else {
  //       //   setUserAnalytics(null)
  //       // }
  //       setAnalyticsLoading(false);
  //     } else {
  //       toast.error('errror: ' + res?.message, { position: 'top-center' });
  //       setAnalyticsLoading(false);
  //     }
  //   });
  // };

  useEffect(() => {
    GetTemplatesV2APIcall();
  }, []);

  const GetTemplatesV2APIcall = () => {
    let token = authtoken;
    const data = {
      limit: '500',
    };
    GetTemplatesV2API(data, token).then((res) => {
      if (res?.data) {
        setTemplateData(res?.data);
      } else {
        console.log('errror: ' + res);
      }
    });
  };
  return (
    <div
      class="analyticsFilterDropdown"
      style={{ padding: '10px' }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        style={{
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          // height: '70vh',
          padding: '15px',
          marginBottom: '20px',
        }}
      >
        {/* <div style={{ marginBottom: '10px' }}>
          <p style={{ fontWeight: '700', fontSize: '14px' }}>Channel</p>
          <select
            class="form-select"
            aria-label="Default select example"
            style={{ boxShadow: 'none', marginTop: '-10px' }}
            value={channel}
            onChange={(e) => {
              setChannel(e.target.value);
            }}
          >
            <option selected hidden>
              Select{' '}
            </option>
            <option value={'WhatsApp'} key={'1'}>
              WhatsApp
            </option>
            <option value={'Email'} key={'2'}>
              Email
            </option>
            <option value={'SMS'} key={'3'}>
              SMS
            </option>
          </select>
        </div> */}
        {/* <div style={{ marginBottom: '15px' }}>
          <p style={{ fontWeight: '700', fontSize: '16px', marginBottom: '10px' }}>
            Filter by Campaign
          </p>
          <select
            class="form-select"
            aria-label="Default select example"
            value={Cname}
            onChange={(e) => {
              setCname(e.target.value);
              setTemplateName('');
              showFeature(true);
            }}
            style={{ boxShadow: 'none' }}
          >
            <option selected hidden>
              Select{' '}
            </option>
            {campaignList &&
              campaignList
                .filter((e) => {
                  return (
                    e?.label_name !== 'shopify_order_cancel' &&
                    e?.label_name !== 'shopify_order_confirm' &&
                    e?.label_name !== 'shopify_order_fulfill' &&
                    e?.label_name !== 'shopify_order_feedback' &&
                    e?.label_name !== 'shopify_abandon_cart' &&
                    e?.label_name !== 'shopify_cod_confirm' &&
                    e?.label_name !== 'woocommerce_abandoned_cart' &&
                    e?.label_name !== 'woocommerce_order_fulfill' &&
                    e?.label_name !== 'woocommerce_order_confirm' &&
                    e?.label_name !== 'woocommerce_order_cancel' &&
                    e?.label_name !== 'woocommerce_order_feedback'
                  );
                })
                .map((e) => {
                  return (
                    <option
                      style={{
                        width: '100%',
                        marginTop: 4,
                        marginBottom: 4,
                        borderRadius: 8,
                      }}
                      value={e?.campaign_id}
                      key={`key=${e?.campaign_id}`}
                    >
                      {e?.label_name == 'customer_enquiry'
                        ? `Follow up with discount (${getLabelDate(e.date)}, ${e.template_name})`
                        : e?.label_name == 'renewal_remainder'
                          ? `Send a Reminder (${getLabelDate(e.date)}, ${e.template_name})`
                          : e?.label_name == 'renewal_reminder'
                            ? `Send a Reminder (${getLabelDate(e.date)}, ${e.template_name})`
                            : e?.label_name == 'share_info'
                              ? `Share info/update/quote (${getLabelDate(e.date)}, ${e.template_name})`
                              : e?.label_name == 'thank_you_customer'
                                ? `Thank your customer (${getLabelDate(e.date)}, ${e.template_name})`
                                : e?.label_name == 'additional_discount_offer'
                                  ? `Additional discount offer (${getLabelDate(e.date)}, ${e.template_name})`
                                  : e?.label_name == 'repeatcustomer_offer' ||
                                    e?.label_name == 'repeatcustomer_offer2'
                                    ? `Repeat customer offer (${getLabelDate(e.date)}, ${e.template_name})`
                                    : e?.label_name == 'customer_win_back'
                                      ? `Customer win back (${getLabelDate(e.date)}, ${e.template_name})`
                                      : e?.label_name == 'business_info_newsletter'
                                        ? `Business info newsletter (${getLabelDate(e.date)}, ${e.template_name})`
                                        : e?.label_name == 'welcome_insta_message_2'
                                          ? `Welcome Instagram (${getLabelDate(e.date)}, ${e.template_name})`
                                          : e?.label_name == 'loyal_customers_new'
                                            ? `X% off with discount code (${getLabelDate(e.date)}, ${e.template_name})`
                                            : e?.label_name == 'discount_all_products_new'
                                              ? `Thank you message (${getLabelDate(e.date)}, ${e.template_name})`
                                              : e?.label_name == 'CustomEmail'
                                                ? `Custom HTML (${getLabelDate(e.date)}, ${e.template_name})`
                                                : e?.label_name == 'maintainance_update'
                                                  ? `Maintenance update (${getLabelDate(e.date)}, ${e.template_name})`
                                                  : e?.label_name == 'new_product_service_marathi'
                                                    ? `New product service Marathi (${getLabelDate(e.date)}, ${e.template_name
                                                    })`
                                                    : e?.label_name == 'reply_multiple'
                                                      ? `Share awareness multiple replies (${getLabelDate(e.date)}, ${e.template_name
                                                      })`
                                                      : e?.label_name == 'do_you_know'
                                                        ? `Do you know (${getLabelDate(e.date)}, ${e.template_name})`
                                                        : e?.label_name == 'Course'
                                                          ? `Sell a ${e.label_name} (${getLabelDate(e.date)}, ${e.template_name})`
                                                          : e?.label_name == 'Product'
                                                            ? `Sell a ${e.label_name} (${getLabelDate(e.date)}, ${e.template_name})`
                                                            : e?.label_name == 'Offer'
                                                              ? `Share an ${e.label_name} (${getLabelDate(e.date)}, ${e.template_name})`
                                                              : `${e.label_name} (${getLabelDate(e.date)}, ${e.template_name})`}
                    </option>
                  );
                })}
          </select>
        </div> */}
        {/* <div style={{ width: '100%', textAlign: 'center' }}>
          <p
            style={{
              fontSize: '16px',
              fontWeight: '600',
              color: '#666',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            OR
          </p>
        </div> */}
        <div style={{ marginBottom: '10px' }}>
          <p style={{ fontWeight: '700', fontSize: '16px', marginBottom: '10px' }}>
            Filter by Template
          </p>
          <select
            class="form-select"
            aria-label="Default select example"
            style={{ boxShadow: 'none', marginBottom: '10px' }}
            value={templateName}
            onChange={(e) => {
              setCname('');
              setCampaignName('');
              setTemplateName(e.target.value);
            }}
          >
            <option hidden value={''}>
              Select{' '}
            </option>
            <option value="order_confirm">Shopify Order Confirm</option>
            <option value="order_cod">Shopify Order COD</option>
            <option value="order_fulfill">Shopify Order Fulfill</option>
            <option value="order_feedback">Shopify Order Feedback</option>
            <option value="order_cancelled">Shopify Order Cancelled</option>
            <option value="abandoned_cart">Shopify Abandoned Cart</option>
            {templateData?.length > 0 &&
              templateData
                .filter((e) => {
                  return (
                    e.name !== 'customer_enquiry' &&
                    e.name !== 'renewal_remainder' &&
                    e.name !== 'renewal_reminder' &&
                    e.name !== 'share_info' &&
                    e.name !== 'thank_you_customer' &&
                    e.name !== 'additional_discount_offer' &&
                    e.name !== 'repeatcustomer_offer' &&
                    e.name !== 'repeatcustomer_offer2' &&
                    e.name !== 'customer_win_back' &&
                    e.name !== 'business_info_newsletter' &&
                    e.name !== 'welcome_insta_message_2' &&
                    e.name !== 'loyal_customers_new' &&
                    e.name !== 'discount_all_products_new' &&
                    e.name !== 'do_you_know' &&
                    e.name !== 'maintainance_update' &&
                    e.name !== 'new_product_service_marathi' &&
                    e.name !== 'reply_multiple' &&
                    e.name !== 'how_to_video' &&
                    e.name !== 'share_an_offer_media_button' &&
                    e.name !== 'new_product_media_button'
                  );
                })
                .map((item) => {
                  return <option value={item.name}>{item.name}</option>;
                })}
          </select>
        </div>
        <div>
          <div className="d-flex justify-content-between">
            <p
              style={{
                fontWeight: '700',
                fontSize: '16px',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              Date
            </p>
            {/* <img
              height={20}
              width={20}
              style={{ marginTop: '2px' }}
              onClick={() =>
                setToggleFilter((prev) => {
                  const obj = { ...prev, day: !prev.day };
                  return obj;
                })
              }
              src={toggleFilter.day ? images.Up : images.Down}
            ></img> */}
          </div>
          <form
            onChange={(e) => {
              setNumberOfDate(e.target.value);
            }}
          >
            {toggleFilter.day &&
              filterOptions.day.map((option) => (
                <>
                  <div className="d-flex" style={{ marginBottom: '20px' }}>
                    <input
                      type="radio"
                      style={{ width: '16px', height: '16px', marginRight: '6px' }}
                      id={option}
                      checked={option === numberOfDate}
                      name={'group'}
                      value={option}
                      disabled={Cname}
                    />
                    <label for={option} style={{ marginTop: '-4px', fontSize: '16px' }}>
                      {option == 1 ? 'Today' : option == 2 ? 'Yesterday' : option + 'days'}
                    </label>
                    {/* <p style={{ marginTop: '-2px', fontSize: '16px' }}>{option}</p> */}
                  </div>
                </>
              ))}
          </form>

          <DateSelector Cname={Cname} setDate={setDate} date={date}></DateSelector>
        </div>
        <CheerioButton
          btnText={'Apply filters'}
          textStyle={{ color: 'white', fontSize: '12px', fontWeight: '600' }}
          backColor={'#9357FF'}
          // icon={images.filter}
          borderStyle={{ width: '100%', height: '30px', border: 'none', borderRadius: '8px' }}
          onclick={() => {
            //getInboxListing();
            if (Cname !== '') {
              setShowFilters(false);
            } else {
              if (templateName && (numberOfDate || date.startDate || date.endDate)) {
                setPage(1);
                templateBasedAnalyticsApiCall();
                templateBasedIndividualAnalyticsApiCall();
                setUserAnalyticsPagination(false);
                setShowFilters(false);
              } else {
                toast.error('Please select a date', { position: 'top-center' });
              }
            }
          }}
        />
      </div>
    </div>
  );
};
