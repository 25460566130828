import { DataGrid } from '@mui/x-data-grid';
import { ContactsDepsCols } from './variableDependencies';
import { styledSx } from '../Tags/variableDependencies';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { fetchAllContacts } from '../../../Services';
import { timeStampToDated } from '../Labels/variableDependencies';
import { Route, Routes } from 'react-router-dom';
import EditSidebar from '../EditSidebar';
import { useEffect, useState } from 'react';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { v4 as uuidv4 } from 'uuid';
import { trackPages } from '../../../Utils/brevo';
import {getLocaltime} from '../../../Utils/localdate'
const ContactsAll = ({ search, userAttributes, excepted }) => {
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const trigger = useSelector((state) => state.labelRedux.triggerState);
  const [userAttributesColumn, setUserAttributesColumn] = useState([]);

  useEffect(() => {
    trackPages('contacts_all_contacts_page', localdata);
  }, []);

  useEffect(() => {
    if (userAttributes?.length > 0) {
      setUserAttributesColumn(() => {
        let temp = [];
        userAttributes
          .filter((item) => item?.attribute)
          .map((item) => {
            temp.push({
              field: item?.attribute + `AAA` + uuidv4(),
              headerName: item?.attribute,
              width: 200,
              align: 'center',
              headerAlign: 'center',
            });
          });
        return temp;
      });
    }
  }, [userAttributes]);

  function mergeArrays(array1, array2) {
    let mergedArray = array1.slice();
    let insertIndex = mergedArray.length - 1;
    mergedArray.splice(insertIndex, 0, ...array2);
    return mergedArray;
  }

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  let pageData = {
    user_id: localdata?._id ? localdata?._id : _id,
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
    search: search,
  };
  const { data, isLoading, isError } = useQuery(
    ['contactAll', trigger, paginationModel, search],
    () => fetchAllContacts(pageData, authtoken)
  );
  if (isLoading || data?.data?.docs<1) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center w-100"
        style={{ flex: 1 }} >
        <SpinnerLoader></SpinnerLoader>
      </div>
    );
  }
  let row_data = [];
  data.data.docs.forEach((vls) => {
    let AttrRowObject =
      userAttributesColumn?.length > 0 &&
      userAttributesColumn.reduce((obj, item) => {
        let varName = String(item?.field).split('AAA')[0];
        obj[item?.field] = vls?.customData?.[varName] ? vls?.customData?.[varName] : '--';
        // obj[varName] = vls?.customData?.[varName] ? vls?.customData?.[varName] : '--';
        return obj;
      }, {});
    row_data.push(
      Object.keys(AttrRowObject).length > 0
        ? Object.assign(
          {},
          {
            id: vls._id,
            name: vls.name,
            addedOn: getLocaltime(vls.createdAt),
            updatedOn: getLocaltime(vls.updatedAt),
            phno: vls.mobile,
            email: vls.email,
            actions: vls,
            version: vls.version,
            visible: !excepted,
          },
          AttrRowObject
        )
        : {
          id: vls._id,
          name: vls.name,
          addedOn: getLocaltime(vls.createdAt),
          updatedOn: getLocaltime(vls.updatedAt),
          phno: vls.mobile,
          email: vls.email,
          actions: vls,
          version: vls.version,
          visible: !excepted,
        }
    );
  });
  return (
    <>
      <DataGrid
        sx={styledSx}
        columns={
          userAttributesColumn?.length > 0
            ? mergeArrays(ContactsDepsCols, userAttributesColumn)
            : ContactsDepsCols
        }
        rows={row_data}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[25, 50, 100]}
        paginationMode="server"
        rowCount={data.data.totalDocs}
        initialState={{ pinnedColumns: { left: ['name'], right: ['action'] } }}
      />
      <Routes>
        <Route path={'/edit'} element={<EditSidebar />} />
      </Routes>
    </>
  );
};

export default ContactsAll;
