import React, { useEffect, useRef, useState } from 'react';
import colors from '../../../Utils/colors';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import { toast } from 'react-toastify';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { IoWarningOutline } from 'react-icons/io5';
import { IoMdCheckmark } from 'react-icons/io';
import { useSelector } from 'react-redux';
import {
  getAllAttributes,
  getAttributeValue,
  getPipelineById,
  getPipelines,
} from '../../../Services';

const ConditionRowComponent = ({ item, setOptionsAction, onDeleteAction, pipelineData }) => {
  const [variable, setVariable] = useState(item?.attributeName);
  const [condition, setCondition] = useState(item?.conditionOperator);
  const [value, setValue] = useState(item?.value);
  const [attributesData, setAttributesData] = useState([]);
  const [attributeValues, setAttributesValues] = useState([]);
  const authtoken = useSelector((state) => state.main.auth_token);

  useEffect(() => {
    if (variable && item?.attributeName !== variable) {
      setVariable(item?.attributeName);
    }
    if (condition && item?.conditionOperator !== condition) {
      setCondition(item?.conditionOperator);
    }
    if (value && item?.value !== value) {
      setValue(item?.value);
    }
  }, [item]);

  useEffect(() => {
    setOptionsAction(variable, condition, value);
  }, [variable, condition, value]);

  useEffect(() => {
    getAttributesApiCall();
  }, []);

  useEffect(() => {
    if (
      item?.attributeName &&
      item?.attributeName?.name !== 'Lead Stage' &&
      item?.attributeName?.hasPickList
    ) {
      getAttributeValuesApiCall(item?.attributeName?.name);
    } else if (item?.attributeName && item?.attributeName?.name === 'Lead Stage') {
      setAttributesValues(pipelineData);
    }
  }, [variable, pipelineData]);

  const getAttributesApiCall = () => {
    getAllAttributes(authtoken).then((res) => {
      if (res?.flag) {
        setAttributesData(res?.data?.attributes);
      } else {
        toast.error(res?.message, { position: 'top-center' });
      }
    });
  };

  const getAttributeValuesApiCall = (value) => {
    const data = {
      attributeName: value,
    };
    getAttributeValue(authtoken, data).then((res) => {
      if (res?.flag) {
        setAttributesValues(res?.data?.values);
      } else {
        toast.error(res?.message, { position: 'top-center' });
      }
    });
  };

  return (
    <>
      <div
        style={{ marginTop: '10px', alignItems: 'center' }}
        className="d-flex flex-row justify-content-evenly align-items-center w-100 "
      >
        <InputGroup className="mb-2" style={{ width: '30%' }}>
          <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
            {'Attribute'}
          </Form.Label>
          <Form.Select
            type="text"
            placeholder="variable"
            className="w-100 btncustom dropdown"
            style={{
              borderRadius: 8,
              fontSize: 16,
              fontWeight: 400,
              color: colors.greys01,
              backgroundColor: '#FAFAFA',
            }}
            value={variable?.displayName}
            onChange={(e) => {
              if (e.target.value === 'Lead Stage') {
                if (pipelineData.length > 0) {
                  setAttributesValues(pipelineData);
                  const data = {
                    displayName: e.target.value,
                    name: e.target.value,

                    hasPickList: true,
                  };

                  setVariable(data);
                } else {
                  toast.error('Please select a pipeline!', { position: 'top-center' });
                  const data = {
                    displayName: 'Select',
                    hasPickList: false,
                  };
                  setVariable(data);
                }
              } else {
                const value = attributesData.filter((item) => item?.displayName === e.target.value);
                setVariable(value[0]);
                if (value[0]?.hasPickList) {
                  getAttributeValuesApiCall(value[0]?.name);
                }
              }
            }}
          >
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              key="blankchoice"
              hidden
              value
            >
              {'Select'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              key="blankchoice"
              value={'Lead Stage'}
            >
              {'Lead Stage'}
            </option>
            {attributesData.map((item) => {
              return (
                <option
                  style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                  value={item?.displayName}
                >
                  {item?.displayName}
                </option>
              );
            })}
          </Form.Select>
        </InputGroup>
        <InputGroup className="mb-2" style={{ width: '30%' }}>
          <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
            {'Condition'}
          </Form.Label>
          <Form.Select
            type="text"
            placeholder="condition"
            className="w-100 btncustom dropdown"
            style={{
              borderRadius: 8,
              fontSize: 16,
              fontWeight: 400,
              color: colors.greys01,
              backgroundColor: '#FAFAFA',
            }}
            value={condition}
            onChange={(e) => {
              setCondition(e.target.value);
            }}
          >
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              key="blankchoice"
              hidden
              value
            >
              {'Select'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'equal to'}
              key={'equal to'}
            >
              {'equal to (==)'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'not equal to'}
              key={'not equal to'}
            >
              {'not equal to (!=)'}
            </option>
          </Form.Select>
        </InputGroup>
        {variable?.hasPickList ? (
          <InputGroup className="mb-2" style={{ width: '30%' }}>
            <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
              {'Value'}
            </Form.Label>
            <Form.Select
              type="text"
              placeholder="value"
              className="w-100 btncustom dropdown"
              style={{
                borderRadius: 8,
                fontSize: 16,
                fontWeight: 400,
                color: colors.greys01,
                backgroundColor: '#FAFAFA',
              }}
              value={variable?.displayName === 'Lead Stage' ? value?.id : value?.displayName}
              onChange={(e) => {
                if (variable?.displayName === 'Lead Stage') {
                  const data = attributeValues.filter((item) => {
                    return item?.id === Number(e.target.value);
                  });
                  setValue(data[0]);
                } else {
                  const data = attributeValues.filter(
                    (item) => item?.displayName === e.target.value
                  );

                  setValue(data[0]);
                }
              }}
            >
              <option
                style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                key="blankchoice"
                hidden
                value
              >
                {'Select'}
              </option>
              {attributeValues.map((item) => {
                return (
                  <option
                    style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                    value={variable?.displayName === 'Lead Stage' ? item?.id : item?.displayName}
                  >
                    {variable?.displayName === 'Lead Stage' ? item?.name : item?.displayName}
                  </option>
                );
              })}
            </Form.Select>
          </InputGroup>
        ) : (
          <InputGroup className="mb-2" style={{ width: '30%' }}>
            <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
              {'Value'}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={'Enter here'}
              className="w-100 btncustom"
              style={{
                borderRadius: 8,
                fontSize: 16,
                fontWeight: 400,
                color: colors.greys01,
                backgroundColor: '#FAFAFA',
              }}
              value={value?.displayName}
              onChange={(e) => {
                const data = {
                  displayName: e.target.value,
                  name: e.target.value,

                  hasPickList: false,
                };
                setValue(data);
              }}
            />
          </InputGroup>
        )}
        <img
          src={images.DeleteBlack}
          style={{
            height: 24,
            width: 20,
            objectFit: 'contain',
            marginInlineStart: 8,
            cursor: 'pointer',
          }}
          onClick={() => {
            onDeleteAction();
          }}
        ></img>
      </div>
    </>
  );
};

const KylasEventUpdateModal = ({
  data,
  setData,
  error,
  verifyStep,
  setVerifyStep,
  setSaveConditionText,
}) => {
  const [existingData, setExistingData] = useState();
  const authtoken = useSelector((state) => state.main.auth_token);
  const [pipelineOptions, setPipelineOptions] = useState([]);
  const [pipelineData, setPipelineData] = useState([]);
  const [pipeline, setPipeline] = useState(data?.pipeline ?? '');
  const [options, setOptions] = useState(
    data?.options
      ? data?.options
      : [
        {
          condition: [
            {
              attributeName: '',
              conditionOperator: '',
              value: '',
            },
          ],
          query: 'AND',
        },
      ]
  );
  const errorRef = useRef();

  useEffect(() => {
    if (data?.options) {
      setExistingData(data?.options);
    }
    getPipelineApiCall();
    if (data?.pipeline) {
      getPipelineByIdApiCall(data?.pipeline);
    }
  }, []);

  useEffect(() => {
    setData({
      ...data,
      options: options,
      pipeline: pipeline,
    });
    setVerifyStep(true);
    setSaveConditionText('Verify Conditions');
    console.log('options changed ---> ', options);
  }, [options, pipeline]);

  useEffect(() => {
    if (error.length > 0) {
      errorRef.current?.scrollIntoView({ behavior: 'instant' });
    }
  }, [error]);

  const getPipelineApiCall = () => {
    getPipelines(authtoken).then((res) => {
      if (res?.flag) {
        setPipelineOptions(res?.data);
      } else {
        toast.error(res?.message, { position: 'top-center' });
      }
    });
  };

  const getPipelineByIdApiCall = (id) => {
    const data = {
      id: id,
    };
    setPipeline(id);

    getPipelineById(authtoken, data).then((res) => {
      if (res?.flag) {
        setPipelineData(res?.data?.stages);
      } else {
        toast.error(res?.message, { position: 'top-center' });
        setPipeline('');
      }
    });
  };

  return (
    <div style={{ padding: '10px' }}>
      <div className="w-100 h-100 flex-column justify-content-start align-items-start">
        <p style={{ padding: '5px', color: '#666666' }}>
          You can add a condition node based on attributes for segmented actions. Action will
          trigger only if the following condition is true.
        </p>

        <div
          style={{
            marginTop: '5%',
            padding: '20px',
            backgroundColor: '#FAFAFA',
            borderRadius: '20px',
          }}
        >
          <p style={{ fontWeight: '600', marginBlockEnd: '2px' }}>Pipeline</p>
          <Form.Select
            type="text"
            placeholder="variable"
            className="w-100 btncustom dropdown"
            style={{
              borderRadius: 8,
              fontSize: 16,
              fontWeight: 400,
              color: colors.greys01,
              backgroundColor: '#FAFAFA',
            }}
            value={pipeline}
            onChange={(e) => {
              getPipelineByIdApiCall(e.target.value);
            }}
          >
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              key="blankchoice"
              hidden
              value
            >
              {'Select Option'}
            </option>
            {pipelineOptions.map((item) => {
              return (
                <option
                  style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                  value={item?.id}
                >
                  {item?.name}
                </option>
              );
            })}
          </Form.Select>
          <p style={{ marginBlockStart: '5px', color: '#666666' }}>
            Your selected pipeline attributes will be shown below.
          </p>
        </div>
        <hr style={{ color: 'grey', marginBlock: '5%' }}></hr>

        {options.map((option, index) => {
          return (
            <>
              <div
                style={{
                  marginBlock: '5%',
                  padding: '20px',
                  backgroundColor: '#FAFAFA',
                  borderRadius: '20px',
                }}
                className="d-flex flex-column justify-content-start align-items-center w-100 py-3 px-3"
              >
                <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                  <p style={{ fontWeight: '700', fontSize: '16px' }}>Condition Node {index + 1}</p>
                  {options.length > 1 && (
                    <img
                      src={images.DeleteBlack}
                      style={{
                        height: 24,
                        width: 20,
                        objectFit: 'contain',
                        marginInlineStart: 8,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (index === 0) {
                          toast.error(
                            'Cannot delete first condition node, at least one condition is mandatory',
                            {}
                          );
                        } else {
                          setOptions((prevObjects) => {
                            return prevObjects.filter((obj, i) => i !== index);
                          });
                          toast.error('Deleted condition node successfully!', {

                          });
                        }
                      }}
                    ></img>
                  )}
                </div>
                {option.condition.length > 0 &&
                  option.condition.map((item, innerIndex) => {
                    return (
                      <div>
                        {innerIndex > 0 && (
                          <div
                            class="container"
                            style={{
                              width: '30%',
                              marginBlock: '10px',
                              backgroundColor: '#FAFAFA',
                              borderRadius: '12px',
                            }}
                          >
                            <div class="row" style={{ borderRadius: '12px', width: '100%' }}>
                              <div
                                class="col"
                                style={{
                                  borderRight: '1px solid black',
                                  padding: '7px',
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                  width: '100%',
                                  borderRadius: '8px 0px 0px 8px',
                                  backgroundColor: option?.query === 'AND' ? '#8E53F7' : 'white',
                                  color: option?.query === 'AND' ? 'white' : 'black',
                                }}
                                onClick={() =>
                                  setOptions((prev) => {
                                    return prev.map((obj, i) => {
                                      if (i === index) {
                                        // Create a new object to modify only the condition array
                                        return {
                                          ...obj,

                                          query: 'AND',
                                        };
                                      }
                                      return obj;
                                    });
                                  })
                                }
                              >
                                AND
                              </div>
                              <div
                                class="col"
                                onClick={() =>
                                  setOptions((prev) => {
                                    return prev.map((obj, i) => {
                                      if (i === index) {
                                        // Create a new object to modify only the condition array
                                        return {
                                          ...obj,

                                          query: 'OR',
                                        };
                                      }
                                      return obj;
                                    });
                                  })
                                }
                                style={{
                                  backgroundColor: option?.query === 'OR' ? '#8E53F7' : 'white',
                                  color: option?.query === 'OR' ? 'white' : 'black',
                                  width: '100%',
                                  padding: '7px',
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                  borderRadius: '0px 8px 8px 0px',
                                }}
                              >
                                OR
                              </div>
                            </div>
                          </div>
                        )}
                        <ConditionRowComponent
                          item={item}
                          setOptionsAction={(attributeName, conditionOperator, attributeValue) => {
                            setOptions((prev) => {
                              return prev.map((obj, i) => {
                                if (i === index) {
                                  // Create a new object to modify only the condition array
                                  return {
                                    ...obj,
                                    condition: obj.condition.map((conditionItem, indx) => {
                                      if (indx === innerIndex) {
                                        return {
                                          ...conditionItem,
                                          attributeName: attributeName,
                                          conditionOperator: conditionOperator,
                                          value: attributeValue,
                                        };
                                      }
                                      return conditionItem;
                                    }),
                                  };
                                }
                                return obj;
                              });
                            });
                          }}
                          onDeleteAction={() => {
                            if (innerIndex === 0) {
                              toast.error(
                                'Cannot delete first attribute row, at least one attribute row is mandatory',
                                {}
                              );
                            } else {
                              setOptions((prev) => {
                                const updatedOptions = [...prev];
                                const conditionArray = updatedOptions[index].condition;

                                if (conditionArray.length > 1) {
                                  conditionArray.splice(innerIndex, 1);
                                  toast.error('Deleted attribute row successfully!', {

                                  });
                                }

                                return updatedOptions;
                              });
                            }
                          }}
                          pipelineData={pipelineData}
                        />
                      </div>
                    );
                  })}
                <CheerioButton
                  borderStyle={{
                    marginBlockStart: 16,
                    width: '100%',
                    borderColor: '#666666',
                    borderRadius: '10px',
                  }}
                  textStyle={{
                    color: '#666666',
                    fontWeight: '600',
                  }}
                  btnText={'Add sub condition'}
                  icon={images.IconAdd}
                  backColor={'#FAFAFA'}
                  onclick={() => {
                    if (options.length < 5) {
                      setOptions((prev) => {
                        return prev.map((obj, i) => {
                          if (i === index) {
                            return {
                              ...obj,

                              condition: [
                                ...obj.condition,
                                { attributeName: '', conditionOperator: '', value: '' },
                              ],
                            };
                          }
                          return obj;
                        });
                      });
                      toast.success('Added a sub-condition successfully!', {});
                    } else {
                      toast.error('You can only set up to 5 conditions', {});
                    }
                  }}
                />
              </div>
              <hr style={{ color: 'grey' }}></hr>
            </>
          );
        })}
      </div>

      <div style={{ paddingBlock: '20px' }}>
        {' '}
        <CheerioButton
          borderStyle={{ borderColor: '#666666' }}
          textStyle={{
            color: 'black',
            fontWeight: '600',
          }}
          btnText={'Add a node'}
          icon={images.IconAdd}
          backColor={''}
          onclick={() => {
            if (options.length < 5) {
              setOptions((prev) => {
                return [
                  ...prev,
                  {
                    condition: [
                      {
                        attributeName: '',
                        conditionOperator: '',
                        value: '',
                      },
                    ],
                    query: 'AND',
                  },
                ];
              });
              toast.success('Added a condition node successfully!', {});
            } else {
              toast.error('You can only set up to 5 conditions', {});
            }
          }}
        />
      </div>

      {error.length > 0 && (
        <div
          ref={errorRef}
          style={{
            marginBlock: '10px',
            padding: '10px',
            backgroundColor: '#FEAF52',
            borderRadius: '8px',
            alignItems: 'center',
          }}
          className="d-flex"
        >
          <div>
            <div className="d-flex" style={{ alignItems: 'center' }}>
              <IoWarningOutline style={{ heigth: '20px', marginInlineEnd: '10px' }} />{' '}
              <p style={{ fontSize: '14px', fontWeight: '600', marginInlineEnd: '10px' }}>
                Warning
              </p>
            </div>
            {error.map((item, index) => {
              return (
                <p style={{ fontSize: '14px', fontWeight: '400', marginInlineStart: '22px' }}>{`${index + 1
                  }. Value ${item?.attributeName?.displayName === 'Lead Stage'
                    ? item?.value?.name
                    : item?.value?.displayName
                  } for {{${item?.attributeName?.displayName}}} does not exists. `}</p>
              );
            })}{' '}
          </div>
        </div>
      )}

      {error.length === 0 && !verifyStep && (
        <div
          style={{
            marginBlock: '10px',
            padding: '10px',
            backgroundColor: '#04B100',
            borderRadius: '8px',
            alignItems: 'center',
          }}
          className="d-flex"
        >
          <IoMdCheckmark style={{ heigth: '20px', marginInlineEnd: '10px', color: 'white' }} />{' '}
          <div>
            <p style={{ fontSize: '14px', fontWeight: '600', color: 'black', color: 'white' }}>
              Condition Verified
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default KylasEventUpdateModal;
