import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import images from '../../../Utils/images';
import colors from '../../../Utils/colors';
import Button from 'react-bootstrap/Button';

const SuccessModal = ({ setIsSuccessModal, isSuccessModal }) => {
    const handleClose = () => {
        setIsSuccessModal(false);
    }
    return (
        <div>
            <Modal
                show={isSuccessModal}
                centered
                className="custom-modal"
            >
                <Modal.Body style={{ paddingX: '20px' }}>
                    <div className="d-flex justify-content-end ">
                        <div className="d-flex flex-row">
                            <img
                                src={images.CMClose}
                                style={{
                                    cursor: 'pointer',
                                    height: 22,
                                    width: 22,
                                    marginInlineEnd: 8,
                                }}
                                onClick={handleClose}
                            ></img>
                        </div>
                    </div>
                    {(
                        <>
                            <div className="d-flex align-items-center flex-column" style={{ marginTop: '50px' }}>
                                <img
                                    src={images.CircleCheckGreen}
                                    style={{
                                        alignItems: 'center',
                                        marginInlineEnd: 8,
                                    }}
                                ></img>
                            </div>

                            <div style={{
                                marginLeft: '10px', marginTop: '30px',
                                fontFamily: 'Segoe UI',
                                fontWeight: 700,
                                fontSize: '20px',
                                lineHeight: '25px',
                                paddingLeft: '20%',
                                paddingBottom: '20%'
                            }}>
                                Auto top up setup completed
                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SuccessModal