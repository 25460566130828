import React, { useState, useEffect, useRef } from 'react';
import CheerioButton from '../../../Components/CheerioButton';
import { Modal } from 'react-bootstrap';
import images from '../../../Utils/images';
import { getWebhookRelayURL, updateWebhookRelayURL, deleteWebhookRelayURL } from '../../../Services';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import Popover from '@mui/material/Popover';
import { Form, InputGroup } from 'react-bootstrap';
import colors from '../../../Utils/colors';
import '../index.scss';

const formatDate = (updatedAt) => {
  const date = new Date(updatedAt);
  const day = String(date.getDate()).padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear()
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;

  return `${day}-${month}-${year}, ${formattedHours}:${minutes}${ampm}`;
};

const options = [
  {
    id: "messageReceived",
    name: "Message Received"
  },
  {
    id: "messageSent",
    name: "Message sent"
  },
  {
    id: "messageDelivered",
    name: "Sent message is delivered"
  },
  {
    id: "messageRead",
    name: "Sent message is read"
  },
  {
    id: "messageFailed",
    name: "Sent message is failed"
  }
]

const DeleteWebhookModal = ({ openNewModal, setExist, setNewModal, handleDelete }) => {
  console.log('DeleteWebMHook Modal', openNewModal);
  return (
    <>
      <Modal dialogClassName="deleteWebhook" show={openNewModal} centered>
        <Modal.Body>
          <div>
            <div className="d-flex justify-content-between">
              <p style={{ fontSize: '16px', fontWeight: '700' }}>Delete Webhook</p>
              <img
                src={images.CMClose}
                onClick={() => setNewModal(false)}
                style={{
                  cursor: 'pointer',
                  height: 22,
                  width: 22,
                  marginInlineEnd: 8,
                }}
              ></img>
            </div>
            <hr />

            <div >
              <p>Are you sure you want to delete Webhook URL and Events?</p>
              <div style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '3%',
                marginTop: "5%",
                marginBottom: "3%"

              }}>
                <CheerioButton
                  borderStyle={{ borderRadius: '8px', width: "100%", border: "none" }}
                  textStyle={{ fontSize: 16, fontWeight: 600, color: 'white' }}
                  btnText={'Yes, Delete'}
                  backColor={'red'}
                  onclick={() => {
                    handleDelete();
                  }}
                />
                <CheerioButton
                  borderStyle={{ borderRadius: '8px', width: "100%" }}
                  textStyle={{ fontSize: 16, fontWeight: 600, color: 'black' }}
                  btnText={'No'}
                  backColor={'white'}
                  onclick={() => {
                    setNewModal(false);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
const DeleteEventWebhookModal = ({ deleteEvent, setDeleteEvent, handleUpdateWebhook, optionList, optionId, setToggleList }) => {
  console.log('DeleteWebMHook Modal', deleteEvent);
  return (
    <>
      <Modal dialogClassName="deleteWebhook" show={deleteEvent} centered>
        <Modal.Body>
          <div>
            <div className="d-flex justify-content-between">
              <p style={{ fontSize: '16px', fontWeight: '700' }}>Delete Webhook Event</p>
              <img
                src={images.CMClose}
                onClick={() => setDeleteEvent(false)}
                style={{
                  cursor: 'pointer',
                  height: 22,
                  width: 22,
                  marginInlineEnd: 8,
                }}
              ></img>
            </div>
            <hr />

            <div >
              <p>Are you sure you want to delete this event?</p>
              <div style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '3%',
                marginTop: "5%",
                marginBottom: "3%"

              }}>
                <CheerioButton
                  borderStyle={{ borderRadius: '8px', width: "100%", border: "none" }}
                  textStyle={{ fontSize: 16, fontWeight: 600, color: 'white' }}
                  btnText={'Yes, Delete'}
                  backColor={'red'}
                  onclick={() => {
                    if (optionList.length === 1) {
                      toast.error('Atleast one event is required');
                      return;
                    }
                    const index = optionList.indexOf(optionId);
                    if (index > -1) {
                      optionList.splice(index, 1);
                    }
                    handleUpdateWebhook();
                    setDeleteEvent(false);
                    setToggleList(false);
                  }}
                />
                <CheerioButton
                  borderStyle={{ borderRadius: '8px', width: "100%" }}
                  textStyle={{ fontSize: 16, fontWeight: 600, color: 'black' }}
                  btnText={'No'}
                  backColor={'white'}
                  onclick={() => {
                    setDeleteEvent(false);
                    setToggleList(false);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const Webhooks = () => {
  const [webhookURL, setWebhookURL] = useState('');
  const [openNewModal, setNewModal] = useState(false);
  const [exist, setExist] = useState(false);
  const [updatedAt, setUpdatedAt] = useState('');
  const [editOn, setEditOn] = useState(false);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [toggleList, setToggleList] = useState(false);
  const addEventRef = useRef(null);
  const [optionList, setOptionList] = useState([]);
  const [addEvent, setAddEvent] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState(false);
  const [optionId, setOptionId] = useState('');

  console.log('optionList', optionList);

  const getOptionNameById = (id) => {
    const option = options.find((opt) => opt.id === id);
    return option ? option.name : '';
  };


  const fetchWebhookUrl = async () => {
    const token = authtoken
    const response = await getWebhookRelayURL(token);
    if (response?.flag) {
      setExist(true);
      setWebhookURL(response?.data?.webhookRelay);
      setUpdatedAt(response?.data?.updatedAt);
      setOptionList(response?.data?.webhookRelayOptions);
    }
  }


  useEffect(() => {
    fetchWebhookUrl();
  }, [])

  useEffect(() => {
    if (optionList.length > 3) {
      setToggleList(false);
    }
  }, [optionList])

  const handleUpdateWebhook = async () => {
    try {
      const token = authtoken
      const data = {
        webhookRelay: webhookURL,
        webhookRelayOptions: optionList
      }
      const response = await updateWebhookRelayURL(token, data);
      if (response?.flag) {
        setExist(true);
        setEditOn(false);
        setAddEvent(false);
        fetchWebhookUrl();
        toast.success(response?.message);
      }
      else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const handleDelete = async () => {
    try {
      const token = authtoken
      const response = await deleteWebhookRelayURL(token);
      if (response?.flag) {
        setExist(false);
        setNewModal(false);
        setWebhookURL('');
        setOptionList([]);
        fetchWebhookUrl();
        toast.success(response?.message);
      }
      else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  return (
    <>
      <div style={{ borderBottom: '1px solid #CCCCCC', width: '100%' }}>
        <p style={{ fontSize: '22px', fontWeight: '700', marginTop: '10px', marginLeft: '25px', marginBottom: 16 }}>
          Setup WhatsApp Webhooks
        </p>
      </div>
      <div
        className=""
        style={{
          border: '1px solid #CCCCCC',
          backgroundColor: '#FFFFFF',
          borderRadius: '16px',
          padding: '20px',
          width: '98%',
          marginTop: '1%',
        }}
      >
        <div>
          <p style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}>Webhook URL</p>
          <div
            className="d-flex"
            style={{ width: '40%', border: '1px solid #666666', borderRadius: '8px', marginTop: '0.5%' }}
          >
            <input
              value={webhookURL}
              type="url"
              className="form-control shadow-none"
              placeholder="Paste here"
              disabled={!exist ? false : !editOn ? true : false}
              onChange={(e) => {
                setWebhookURL(e.target.value);
              }}
              style={{ marginTop: '1px', border: 'none', marginBottom: "1px" }}
            />
          </div>

          <p style={{ fontWeight: '600', fontSize: '16px', color: 'black', marginTop: "30px" }}>Events</p>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: "10px",
            marginTop: "10px",
            marginBottom: "20px"
          }}>
            {optionList.map((option) => (
              <div key={option} style={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: '8px',
                justifyContent: "center",
                backgroundColor: "#F5F5F5",
                paddingInline: "12px",
                paddingTop: "8px",
                paddingBottom: "8px",
                borderRadius: "50px"
              }}>
                <p style={{ fontSize: '16px', color: 'black', margin: 0 }}>{getOptionNameById(option)}</p>
                <img
                  src={images.CROSS_BLACK}
                  style={{ cursor: 'pointer', height: 14, width: 14 }}
                  onClick={() => {
                    console.log('option', option);
                    setOptionId(option);
                    setDeleteEvent(true);
                  }}
                  alt="Delete"
                />
              </div>
            ))}
          </div>
          <div
            ref={addEventRef}
          >
            <div className='ButtonContainer'>
              <button className="CustomButton" onClick={() => setToggleList(!toggleList)}>
                <img src={images.IconAdd} alt="Add Icon" width="18" height="18" />
                Add Event
              </button>
            </div>
            {optionList?.length < 5 && <ListOfWebhookChoice toggleList={toggleList} setToggleList={setToggleList} anchorEl={addEventRef.current} optionList={optionList} setOptionList={setOptionList} setAddEvent={setAddEvent} setEditOn={setEditOn} />}
          </div>
        </div>
        {exist ? (
          <div>
            <div class="mt-5">
              <p style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}>Updated On</p>
              <p>{formatDate(updatedAt)}</p>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '1%',
            }}>
              {(editOn || addEvent) ? (
                <CheerioButton
                  borderStyle={{ width: '10vw', marginTop: '20px', borderColor: colors.darkPurple }}
                  textStyle={{ fontSize: 16, fontWeight: 600, color: 'white' }}
                  btnText={'Save'}
                  backColor={colors.darkPurple}
                  onclick={() => {
                    handleUpdateWebhook()
                    setToggleList(false);
                  }}
                  disabled={!webhookURL || optionList.length === 0 ? true : false}
                />
              ) : (
                <CheerioButton
                  borderStyle={{ width: '10vw', marginTop: '20px', borderColor: colors.darkPurple, flexWrap: "nowrap" }}
                  textStyle={{ fontSize: 16, fontWeight: 600, color: 'white' }}
                  btnText={'Edit'}
                  icon={images.EditWhite}
                  backColor={colors.darkPurple}
                  onclick={() => {
                    setEditOn(true);
                  }}
                />
              )}
              <CheerioButton
                borderStyle={{ width: '10vw', marginTop: '20px' }}
                textStyle={{ fontSize: 16, fontWeight: 600, color: 'black' }}
                btnText={'Delete'}
                icon={images.DeleteBlack}
                backColor={'white'}
                onclick={() => {
                  setNewModal(true);
                  console.log(openNewModal);
                }}
              />

              {openNewModal && DeleteWebhookModal({ openNewModal, setExist, setNewModal, handleDelete })}
              {deleteEvent && DeleteEventWebhookModal({ deleteEvent, setDeleteEvent, handleUpdateWebhook, optionList, optionId, setToggleList })}
            </div>
          </div>
        ) : (
          <div class="mt-5">
            <CheerioButton
              borderStyle={{ width: '10vw', marginTop: '20px', borderColor: colors.darkPurple }}
              textStyle={{ fontSize: 16, fontWeight: 600, color: 'white' }}
              btnText={'Save'}
              backColor={colors.darkPurple}
              onclick={() => {
                handleUpdateWebhook()
                setToggleList(false);
              }}
              disabled={!webhookURL || optionList.length === 0 ? true : false}
            />
          </div>
        )}
      </div >
      <ToastContainer />
    </>
  );
};


const ListOfWebhookChoice = ({ toggleList, setToggleList, anchorEl, optionList, setOptionList, setAddEvent, setEditOn }) => {
  const [search, setSearch] = useState('');


  const handleClose = () => {
    setToggleList(false);
  }
  return (
    <>
      <div>
        <Popover
          open={toggleList}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiPopover-paper': {
              width: '300px',
              borderRadius: '16px',
              padding: '20px',
            },
          }}
        >

          {options.filter(option => !optionList.includes(option.id))
            .map((option) => (
              <div style={{
                fontWeight: "16px",
                gap: '5%',
                cursor: 'pointer',
                marginBlockEnd: 10,
              }}
                onClick={() => {
                  setOptionList([...optionList, option.id])
                  setAddEvent(true);
                  setEditOn(true);
                }}
              >
                {option.name}
              </div>
            ))}


        </Popover>
      </div>
    </>
  );
}

export default Webhooks;
