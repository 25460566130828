import { Modal } from 'react-bootstrap';
import '../../../../src/Pages/Templates/styles.css';
import { useSelector } from 'react-redux';
import images from '../../../Utils/images';
import { LiaHandPointer } from "react-icons/lia";
import { MdOutlineReply } from "react-icons/md";
import { FaRegCopy } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import { InputGroup } from "react-bootstrap";
import PreviewCustomTemplate from '../../CustomTemplate/Preview';
import colors from '../../../Utils/colors';
import { getPaymentByUserId } from '../../../Services';

const AITemplateModal = ({ setIsAITemplateModal, isAITemplateModal }) => {

    const authtoken = useSelector((state) => state.main.auth_token);

    const handleClose = () => {
        setIsAITemplateModal(false);
    }


    return (
        <div>

            <Modal
                show={isAITemplateModal}
                onHide={handleClose}
                className="custom-modal"
                // dialogClassName="custom-modal-dialog"
                size='xl'
            >
                <Modal.Body
                    style={{
                        padding: '2%',
                        maxHeight: '90vh',
                        overflowY: 'auto'
                    }}
                >
                    <div
                        style={
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '2%'
                            }
                        }
                    >
                        <p
                            style={{
                                fontWeight: 700,
                                fontSize: '20px',
                            }}
                        >
                            Generate Templates optimized for your business with AI
                        </p>
                        <img
                            src={images.CMClose}
                            style={{
                                cursor: 'pointer',
                                height: 22,
                                width: 22,
                                marginLeft: '47%',
                            }}
                            onClick={handleClose}
                        ></img>
                    </div>
                    <div style={{ border: '1px solid #E6E6E6' }}></div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div
                            style={{
                                paddingLeft: '1%',
                                paddingTop: '2%'
                            }}
                        >
                            <p
                                style={{
                                    fontWeight: 700,
                                    fontSize: 14
                                }
                                }
                            >
                                Optimize message for?
                                <div
                                    style={{
                                        paddingTop: '1%',
                                        display: 'flex',
                                        gap: 10
                                    }}
                                >
                                    <button style={{
                                        background: 'white',
                                        color: '#666666',
                                        border: '1px solid #666666',
                                        borderRadius: 8,
                                        padding: '10px 20px',
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        cursor: 'pointer',
                                    }}><LiaHandPointer style={{ width: 24, height: 24, marginTop: '-6%' }} />Button Clicks</button>

                                    <button style={{
                                        background: 'white',
                                        color: '#666666',
                                        border: '1px solid #666666',
                                        borderRadius: 8,
                                        padding: '10px 20px',
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        cursor: 'pointer',
                                    }}><MdOutlineReply style={{ width: 24, height: 24, marginTop: '-6%' }} />Message Replies</button>
                                </div>
                            </p>
                            <p
                                style={{
                                    fontWeight: 700,
                                    fontSize: 14,
                                    marginTop: '8%'
                                }}
                            >Choose a mood for your message
                                <InputGroup className="mb-3" style={{ width: '99%', marginBlock: 2, }}>
                                    <Form.Select type='text' placeholder="select" className='w-100 btncustom dropdown'
                                        style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02, borderColor: '#CCCCCC' }}
                                        value=''
                                        onChange={() => { }}
                                    >
                                        <option style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }} value={'Automatic'} key={'Automatic'}>
                                            {'Normal'}
                                        </option>
                                        <option style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }} value={'Manual'} key={'Manual'}>
                                            {'Manual'}
                                        </option>
                                    </Form.Select>
                                </InputGroup>
                            </p>
                            <div>
                                <Form.Label
                                    style={{
                                        fontWeight: 700,
                                        fontSize: 14,
                                        marginTop: '8%'
                                    }}
                                >Write a prompt</Form.Label>
                                <Form.Control as="textarea"
                                    style={{
                                        width: 605,
                                        height: 190,
                                        borderColor: '#CCCCCC',
                                    }}
                                />
                                <div
                                    style={{
                                        paddingTop: '8%'
                                    }}
                                >
                                    <button style={{
                                        background: '#8E53F7',
                                        color: 'white',
                                        border: '1px solid #8E53F7',
                                        borderRadius: 12,
                                        padding: '9px 272px',
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        cursor: 'pointer',
                                    }}>Generate</button>
                                </div>
                            </div>
                            <p
                                style={{
                                    fontWeight: 700,
                                    fontSize: 14,
                                    paddingTop: '2%'
                                }}
                            >
                                Suggested Prompts
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '9px', border: 'none', borderRadius: '5px', backgroundColor: '#f9f9f9', width: '100%', maxWidth: '600px', margin: '2% 0' }}>
                                    <p style={{ flex: '1', marginRight: '10px', fontSize: '16px', color: 'black' }}>
                                        Generate a promotional message to give a discount of 50% off on our product Logitech Master M3 mouse, which is about to end in a week
                                    </p>
                                    <button style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer', color: 'black' }} onClick={() => navigator.clipboard.writeText("Generate a promotional message to give a discount of 50% off on our product Logitech Master M3 mouse, which is about to end in a week")}>
                                        <FaRegCopy size={24} color="#333" />
                                    </button>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '9px', border: 'none', borderRadius: '5px', backgroundColor: '#f9f9f9', width: '100%', maxWidth: '600px', margin: '2% 0' }}>
                                    <p style={{ flex: '1', marginRight: '10px', fontSize: '16px', color: 'black' }}>
                                        Generate a Diwali wishes message for our customers with an offer of 10% OFF sitewide.
                                    </p>
                                    <button style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer', color: 'black' }} onClick={() => navigator.clipboard.writeText("Generate a promotional message to give a discount of 50% off on our product Logitech Master M3 mouse, which is about to end in a week")}>
                                        <FaRegCopy size={24} color="#333" />
                                    </button>
                                </div><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '9px', border: 'none', borderRadius: '5px', backgroundColor: '#f9f9f9', width: '100%', maxWidth: '600px', margin: '2% 0' }}>
                                    <p style={{ flex: '1', marginRight: '10px', fontSize: '16px', color: 'black' }}>
                                        Write a welcome message for new customers who get’s onboarded to our SAAS Platform.
                                    </p>
                                    <button style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer', color: 'black' }} onClick={() => navigator.clipboard.writeText("Generate a promotional message to give a discount of 50% off on our product Logitech Master M3 mouse, which is about to end in a week")}>
                                        <FaRegCopy size={24} color="#333" />
                                    </button>
                                </div>
                            </p>
                        </div >
                        <div
                            style={{
                                paddingLeft: '5%'
                            }}
                        >
                            <div
                                className="d-flex flex-column"
                                style={{ overflowY: 'scroll', width: '90%', paddingTop: '6%' }}
                            >
                                <div
                                    className="w-100 d-flex flex-column justify-content-start align-items-center WAPreviewScroll"
                                    style={{
                                        flex: 1,
                                        border: `1px solid ${colors.borderwhite}`,
                                        marginBlockEnd: 16,
                                        marginBlockStart: 8,
                                        borderRadius: 16,
                                        paddingBlock: 10,
                                        overflowY: 'auto',
                                    }}
                                >
                                    <h4 style={{ fontSize: 16, fontWeight: 700, color: colors.black, paddingTop: '3%' }}>
                                        {'Message Preview'}
                                    </h4>
                                    <div
                                        style={{
                                            paddingTop: '5%',
                                            paddingBottom: '5%'
                                        }}
                                    >
                                        <PreviewCustomTemplate
                                            body={{
                                                type: "BODY",
                                                text: "🚨 Flash Sale Alert! 🚨\n\nHey! 👋 Don't miss out on our 50% OFF on all boAt Headphones! Perfect sound, half the price! 🎧💥\n\nThis exclusive offer ends TONIGHT! ⏳ Act fast before time runs out! Get your groove on with boAt and enjoy premium sound quality at an unbeatable price! 🎶",
                                                example: {
                                                    body_text: [],
                                                },
                                            }}
                                            header={{
                                                type: "HEADER",
                                                format: "plain",
                                                text: "Welcome to the Header Section",
                                                example: {
                                                    header_text: [],
                                                    header_handle: [],
                                                },
                                            }}
                                            footerData={{
                                                type: "FOOTER",
                                                text: "© 2024 Example Company. All rights reserved.",
                                            }}
                                            buttonData={{
                                                type: "BUTTONS",
                                                buttons: [],
                                            }}
                                        />
                                    </div>
                                </div>
                                <button style={{
                                    background: 'white',
                                    color: 'black',
                                    border: '1px solid black',
                                    borderRadius: 8,
                                    padding: '15px 20px',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                }}><FaRegCopy style={{ width: 12.92, height: 16.25, marginRight: '2%' }} />Copy Text</button>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default AITemplateModal