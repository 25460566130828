import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import { ShopifyAnalyticsAPIV2 } from '../../../Services';
//import DatePicker from 'react-datepicker';
//import 'react-datepicker/dist/react-datepicker.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import images from '../../../Utils/images';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { DetailUserAnalytics } from './DetailUserAnalytics';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const ShopifyAnalytics = ({ selectedCampaignFlow }) => {
  const localdata = useSelector((state) => state.main.localdata);
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [CampaignType, setCampaignType] = useState('');
  const [openDateSelector, setOpenDateSelector] = useState(false);
  const [graphData, setGraphData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (CampaignType) {
      console.log('Selected campaign type ===> ', CampaignType);
    }
  }, [CampaignType]);

  useEffect(() => {
    if (selectedCampaignFlow) {
      setCampaignType(selectedCampaignFlow);
    }
    eventsend("Analytics__Shopify_integration_page_clicked");
  }, []);

  useEffect(() => {
    if (CampaignType && dateSelected) {
      getShopifyAnalytics();
    }
  }, [startDate, endDate, CampaignType]);

  const getShopifyAnalytics = () => {
    setIsLoading(true);

    let from = startDate['$d'];
    let to = endDate['$d'];
    from = from.getFullYear() + '-' + (from.getMonth() + 1) + '-' + from.getDate();
    to = to.getFullYear() + '-' + (to.getMonth() + 1) + '-' + to.getDate();
    let data = {
      from_date: from,
      to_date: to,
      hook_loc: CampaignType,
    };
    let token = authtoken;
    ShopifyAnalyticsAPIV2(data, token).then((res) => {
      if (res.flag) {
        console.log('shopify ananlytics res ===> ', res);
        setGraphData(res?.data?.result);
      } else {
        console.log('shopify analytics failure res ===> ', res);
      }
      setIsLoading(false);
    });
  };

  const DateSelectorModal = ({ setOpen }) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div
          className="d-flex"
          style={{
            backgroundColor: colors.white01,
            border: `1px solid ${colors.borderwhite}`,
            borderRadius: 6,
            position: 'sticky',
            marginLeft: '34vw',
            marginTop: '-10px',
            paddingInline: 20,
            paddingBlock: 16,
            width: '24vw',
          }}
        >
          <div className="d-flex justify-content-between" style={{}}>
            <div style={{ width: '50%' }}>
              <p style={{ height: '' }}>Start Date</p>
              <DatePicker
                slotProps={{ textField: { size: 'small' } }}
                value={startDate}
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px',
                  },
                }}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
              />
            </div>
            <div style={{ width: '10px' }}></div>
            <div style={{ width: '50%' }}>
              <p style={{ height: '' }}>End Date</p>
              <DatePicker
                slotProps={{ textField: { size: 'small' } }}
                value={endDate}
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px',
                  },
                }}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
              />
            </div>
            {/* <Form.Group className="btncustom" style={{ width: '100%', marginBlockEnd: 8 }}>
              <Form.Label style={{ fontSize: 14, fontWeight: 400, color: colors.black }}>
                Start date
              </Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="btncustom" style={{ width: '100%' }}>
              <Form.Label style={{ fontSize: 14, fontWeight: 400, color: colors.black }}>
                End date
              </Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}  
              />
            </Form.Group> */}
          </div>
          <div>
            <img
              style={{ cursor: 'pointer ', marginTop: '-22px', marginRight: '-5px' }}
              height={18}
              src={images.CMClose}
              onClick={() => setOpen(false)}
            ></img>
          </div>
        </div>
      </LocalizationProvider>
    );
  };

  const StatsCard = ({ text, value, totalCount = 0 }) => {
    return (
      <>
        <div
          className="d-flex flex-column justify-content-between align-items-start"
          style={{ flex: 1, paddingInline: 8 }}
        >
          <div style={{ width: '100%' }} className="d-flex justify-content-between">
            <p
              style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 600, color: colors.black }}
            >
              {text}
            </p>
            <p style={{ padding: 0, margin: 0, fontSize: 18, fontWeight: 600, color: '#008BAD' }}>
              {totalCount}
            </p>
          </div>
          <p style={{ padding: 0, margin: 0, fontSize: 48, fontWeight: 700, color: colors.black }}>
            {value}
            {value !== 'NA' && <span style={{ fontSize: 38 }}>{'%'}</span>}
          </p>
        </div>
      </>
    );
  };

  const dateSelected = startDate && endDate;

  const nullValueMessage =
    graphData?.sentRate === null ||
    graphData?.deliveryRate === null ||
    graphData?.openedRate === null;

  const getddMM = (e) => {
    const date = new Date(e);
    const mm = date.toLocaleString('en-EN', { month: 'short' });
    const dd = date.getDate();
    return dd + '-' + mm;
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler
    // Legend
  );

  const data = {
    labels: ['Sent Rate', 'Delivery Rate', 'Opened Rate', 'Clicked Rate', ''],
    datasets: [
      {
        data: [graphData?.sentRate, graphData?.deliveryRate, null, null, null],
        fill: true,
        backgroundColor: '#008BAD',
        borderColor: '#008BAD',
        tension: 0.4,
        pointRadius: 0,
      },
      {
        data: [-graphData?.sentRate, -graphData?.deliveryRate, null, null, null],
        fill: true,
        backgroundColor: '#008BAD',
        borderColor: '#008BAD',
        tension: 0.4,
        pointRadius: 0,
      },
      {
        data: [null, graphData?.deliveryRate, graphData?.openedRate, null, null],
        fill: true,
        backgroundColor: '#009FC7',
        borderColor: '#009FC7',
        tension: 0.4,
        pointRadius: 0,
      },
      {
        data: [null, -graphData?.deliveryRate, -graphData?.openedRate, null, null],
        fill: true,
        backgroundColor: '#009FC7',
        borderColor: '#009FC7',
        tension: 0.4,
        pointRadius: 0,
      },
      {
        data: [
          null,
          null,
          graphData?.openedRate,
          graphData?.clickedRate == null ? -graphData?.clickedRate : graphData?.clickedRate,
          null,
        ],
        fill: true,
        backgroundColor: '#00B3E0',
        borderColor: '#00B3E0',
        tension: 0.4,
        pointRadius: 0,
      },
      {
        data: [null, null, -graphData?.openedRate, -graphData?.clickedRate, null],
        fill: true,
        backgroundColor: '#00B3E0',
        borderColor: '#00B3E0',
        tension: 0.4,
        pointRadius: 0,
      },
      {
        data: [null, null, null, graphData?.clickedRate, graphData?.clickedRate],
        fill: true,
        backgroundColor: '#00C2F2',
        borderColor: '#00C2F2',
        tension: 0.4,
        pointRadius: 0,
      },
      {
        data: [null, null, null, -graphData?.clickedRate, -graphData?.clickedRate],
        fill: true,
        backgroundColor: '#00C2F2',
        borderColor: '#00C2F2',
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
          color: '#fff',
          width: '4px',
        },
        ticks: {
          display: false,
        },
      },
      y: {
        min: -110,
        max: 110,
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 20,
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        display: false,
      },
    },
  };

  return (
    <>
      <div
        className="w-100 h-100 verythinscrollnew"
        id="main"
        style={{
          overflowY: 'auto',
          backgroundColor: colors.white02,
          border: `1px solid ${colors.borderwhite}`,
        }}
      >
        <div
          className="d-flex flex-row justify-content-between align-items-center w-100"
          style={{ paddingInline: 30, marginBlock: 20 }}
        >
          <h3 style={{ color: colors.black }}>
            {'Analytics'}
          </h3>
        </div>

        <div
          className="d-flex flex-row justify-content-between align-items-center w-100"
          style={{ paddingInline: 30, marginBlock: 20 }}
        >
          <h3 style={{ color: colors.black }}>
            {'Campaign Performance'}
          </h3>
          <div
            className="d-flex flex-row justify-content-between align-items-center w-50"
            style={{}}
          >
            <InputGroup
              className="btncustom"
              style={{ width: '40%', marginInlineEnd: 8, height: '100%' }}
            >
              <InputGroup.Text
                id="basic-addon1"
                style={{ fontSize: 12, fontWeight: 600, color: colors.black, cursor: 'default' }}
              >
                <img
                  src={images.ANCalendar}
                  style={{ height: 18, width: 18, objectFit: 'contain' }}
                />
              </InputGroup.Text>
              <div
                className="btncstom d-flex flex-row justify-content-between align-items-center px-1"
                style={{
                  flex: 1,
                  height: 32,
                  border: `1px solid ${colors.grey2}`,
                  backgroundColor: colors.white01,
                  borderTopRightRadius: 6,
                  borderBottomRightRadius: 6,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setOpenDateSelector(!openDateSelector);
                }}
              >
                <p
                  className=""
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 12,
                    fontWeight: 600,
                    color: colors.greys04,
                  }}
                >
                  {dateSelected ? `${getddMM(startDate)} to ${getddMM(endDate)}` : 'Select date'}
                </p>
                <img
                  src={images.DownArrowTip}
                  style={{ height: 19, width: 19, objectFit: 'contain' }}
                ></img>
              </div>
            </InputGroup>

            <InputGroup
              className="btncustom"
              style={{ width: '60%', marginInlineStart: 8, height: '100%' }}
            >
              <InputGroup.Text
                id="basic-addon1"
                style={{ fontSize: 12, fontWeight: 600, color: colors.black, cursor: 'default' }}
              >
                {'CAMPAIGN'}
              </InputGroup.Text>
              <Form.Select
                className="btncustom"
                aria-label="select campaign"
                style={{ fontSize: 12, fontWeight: 600, color: colors.greys04, cursor: 'pointer' }}
                value={CampaignType}
                onChange={(e) => {
                  setCampaignType(e.target.value);
                }}
              >
                {/* <option key="blankchoice" hidden value>
                  {'Select Campaign'}
                </option>
                <option value={'orders-placed'} key={'1'}>
                  Orders placed
                </option>
                <option value={'orders-fulfilled'} key={'2'}>
                  Orders fulfilled
                </option>
                <option value={'order_cancelled'} key={'3'}>
                  Orders cancelled
                </option>
                <option value={'order_feedback'} key={'4'}>
                  Orders feedback
                </option>
                <option value={'abandoned_cart'} key={'5'}>Abandoned cart</option> */}
                <option key="blankchoice" hidden value>
                  {'Select Campaign'}
                </option>
                <option value={'order_confirm'} key={'1'}>
                  Orders placed
                </option>
                <option value={'order_fulfill'} key={'2'}>
                  Orders fulfilled
                </option>
                <option value={'order_cancelled'} key={'3'}>
                  Orders cancelled
                </option>
                <option value={'order_feedback'} key={'4'}>
                  Orders feedback
                </option>
                <option value={'abandoned_cart'} key={'5'}>
                  Abandoned cart
                </option>
                <option value={'order_cod'} key={'6'}>
                  COD Order
                </option>
              </Form.Select>
            </InputGroup>
          </div>
        </div>
        {openDateSelector && <DateSelectorModal setOpen={setOpenDateSelector} />}
        <div
          className="d-flex flex-column justify-content-start align-items-center w-100"
          style={{ paddingInline: 30, marginBlock: 20 }}
        >
          {isLoading ? (
            <>
              <div
                className="d-flex flex-column justify-content-center align-items-center w-100"
                style={{ height: 400 }}
              >
                <SpinnerLoader />
              </div>
            </>
          ) : graphData ? (
            <>
              <div
                className="w-100 d-flex flex-row justify-content-between align-items-center"
                style={{ marginBlockEnd: 20 }}
              >
                <StatsCard
                  text={'Sent'}
                  value={graphData?.sentRate === null ? 'NA' : graphData?.sentRate}
                  //value={100}
                  totalCount={graphData?.total}
                />
                <StatsCard
                  text={'Delivery'}
                  value={graphData?.deliveryRate === null ? 'NA' : graphData?.deliveryRate}
                  totalCount={graphData?.delivered}
                />
                <StatsCard
                  text={'Opened'}
                  value={graphData?.openedRate === null ? 'NA' : graphData?.openedRate}
                  totalCount={graphData?.opened}
                />
                <StatsCard
                  text={'Clicked'}
                  value={graphData?.clickedRate === null ? 'NA' : graphData?.clickedRate}
                  totalCount={graphData?.clicked}
                />
              </div>
              {nullValueMessage && (
                <>
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 16,
                      fontWeight: 600,
                      color: colors.black04,
                    }}
                  >
                    {'Not enough data to show right now. Please check back later.'}
                  </p>
                </>
              )}
              <div className="w-100" style={{ height: 400, paddingBlock: 16 }}>
                <Line options={options} data={data} />
              </div>
            </>
          ) : (
            <>
              <div
                className="d-flex flex-column justify-content-center align-items-center w-100"
                style={{ height: 400 }}
              >
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: 16,
                    fontWeight: 600,
                    color: colors.black04,
                  }}
                >
                  {'Select a Campaign and duration to view Analytics'}
                </p>
              </div>
            </>
          )}
        </div>
        <DetailUserAnalytics></DetailUserAnalytics>
      </div>
    </>
  );
};

export default ShopifyAnalytics;
