import { useEffect, useState } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { getAllAssistant, getClientAttributes } from '../../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import colors from '../../../Utils/colors';
import AttributesModal from './AttributesModal';

const LlamaNodeModal = ({ data, setData, setBtnDisabled }) => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const [e, setE] = useState(null);
    // const localdata = useSelector((state) => state.main.localdata);
    const [openAttributes, setOpenAttributes] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [assistantsList, setAssistantsList] = useState([]);
    const [assistant, setAssistant] = useState(null);
    const [exitKeyword, setExitKeyWord] = useState('');
    const [bufferMessageFive, setBufferMessageFive] = useState('');
    const [bufferMessageTen, setBufferMessageTen] = useState('');
    const [firstMessage, setFirstMessage] = useState('');
    const [allAttributes, setAllAttributes] = useState([]);
    const [keyPressed, setKeyPressed] = useState('none');
    const [IAFilterValue, setIAFilterValue] = useState('');

    useEffect(() => {
        setIsLoading(true);
        getUserAssistantsAPICall();
    }, []);

    useEffect(() => {
        if (firstMessage) {
            let currentWord = getWordAtCursor();
            if (currentWord[0] === '%' && currentWord?.length > 1) {
                setIAFilterValue(currentWord?.substring(1));
            } else {
                setIAFilterValue('');
            }
        }
    }, [firstMessage]);

    useEffect(() => {
        const indAttributesAPIcall = () => {
            let token = authtoken;
            getClientAttributes(token).then((res) => {
                if (res?.flag) {
                    const attributes = res.data.map((item) => item.attribute);
                    setAllAttributes(attributes);
                } else {
                    // console.log("ind attributes err ---> ",res)
                }
            });
        };
        indAttributesAPIcall();
    }, []);

    const selectedAttributeAction = (value) => {
        const currentWord = getWordAtCursor();
        const wrappedValue = `%${value}%`;

        setFirstMessage((prev) => {
            let editedText = prev;

            if (!currentWord || prev.endsWith(currentWord)) {
                editedText = prev.replace(new RegExp(`${currentWord}$`), wrappedValue);
            } else {
                editedText = prev.replace(currentWord, wrappedValue);
            }

            return editedText;
        });

        document.getElementById('textInputAttr').focus();
        setOpenAttributes(false);
    };

    function getWordAtCursor() {
        const input = document.getElementById('textInputAttr');
        const cursorPosition = input.selectionStart;
        const text = input.value;

        let startIndex = cursorPosition;
        let endIndex = cursorPosition;

        while (startIndex > 0 && !isWordBoundary(text[startIndex - 1])) {
            startIndex--;
        }

        while (endIndex < text.length && !isWordBoundary(text[endIndex])) {
            endIndex++;
        }

        const currentWord = text.substring(startIndex, endIndex);
        return currentWord;
    }

    function isWordBoundary(char) {
        return char === ' ' || char === '\n';
    }

    useEffect(() => {
        if (data?.assistant) {
            setAssistant(data?.assistant);
        }
        if (data?.exitKeyword) {
            setExitKeyWord(data?.exitKeyword);
        }
        if (data?.firstMessage) {
            setFirstMessage(data?.firstMessage);
        }
        if (data?.bufferMessageFive) {
            setBufferMessageFive(data?.bufferMessageFive);
        }
        if (data?.bufferMessageTen) {
            setBufferMessageTen(data?.bufferMessageTen);
        }
    }, [])

    const workflowReservedWords = ["back", "prev", "exit", "menu"];

    useEffect(() => {
        setData({ ...data, exitKeyword: exitKeyword, assistantId: assistant?.assistantId, assistant: assistant, firstMessage: firstMessage, bufferMessageFive: bufferMessageFive, bufferMessageTen: bufferMessageTen });
        if (exitKeyword && assistant) {
            setBtnDisabled(false);
        } else {
            setBtnDisabled(true);
        }
    }, [exitKeyword, assistant, firstMessage, bufferMessageFive, bufferMessageTen]);

    const getUserAssistantsAPICall = () => {
        let token = authtoken;
        getAllAssistant(token).then((res) => {
            if (res?.flag) {
                setAssistantsList(() => {
                    const arr = res?.data.map((item) => item);
                    return [...arr];
                });
            } else {
                setAssistantsList([]);
            }
        });
    };


    return (
        <>
            <div className="w-100 h-100 py-3 d-flex flex-column justify-content-start align-items-start">
                <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 20 }}>
                    <FormControl
                        variant="outlined"
                        size="small"
                        style={{ marginBottom: '5px', width: '100%' }}
                    >
                        <InputLabel>Assistant</InputLabel>
                        <Select
                            value={assistant}
                            onChange={(e) => setAssistant(e.target.value)}
                            label="Assistant"
                        >
                            {assistantsList?.length > 0 ? (
                                assistantsList.map((val) => (
                                    <MenuItem key={val?.assistantId} value={val}>
                                        {val?.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>
                                    Please create an assistant
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </InputGroup>
                <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 10 }}>
                    <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                        {'Initial Message'}
                    </Form.Label>
                    <Form.Control
                        id="textInputAttr"
                        type="text"
                        placeholder={'An initial message to send to Llama for better responses'}
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={firstMessage}
                        onChange={(e) => {
                            setFirstMessage(e.target.value);
                        }}
                        onKeyDown={(event) => {
                            if (
                                event.key === 'Backspace' &&
                                firstMessage.charAt(event.target.selectionStart - 1) === '%'
                            ) {
                                setOpenAttributes(false);
                            } else if (event.key === 'ArrowUp' && openAttributes) {
                                event.preventDefault();
                                setKeyPressed('Up');
                            } else if (event.key === 'ArrowDown' && openAttributes) {
                                event.preventDefault();
                                setKeyPressed('Down');
                            } else if (event.key === 'Tab' && openAttributes) {
                                event.preventDefault();
                                setKeyPressed('Tab');
                            } else if (event.key === '%' && event.shiftKey) {
                                setOpenAttributes(true);
                            } else if (event.code === 'Space') {
                                setOpenAttributes(false);
                            } else if (event.key === 'Enter') {
                                setOpenAttributes(false);
                            }
                            setE(event);
                        }}
                    />
                </InputGroup>
                {openAttributes && (
                    <AttributesModal
                        setOpenAttributes={setOpenAttributes}
                        attributeObject={allAttributes}
                        selectedAttributeAction={selectedAttributeAction}
                        filterValue={IAFilterValue}
                        keyPressed={keyPressed}
                        setKeyPressed={setKeyPressed}
                    />
                )}
                <Form.Text style={{ fontSize: 12, color: colors.greys04 }}>
                    {'This message will be the initial message that the Llama Assistant receives from our end. It adds better context for more personalized responses. Example, you can send "Hi my name is %name%". Then the Llama Assistant will address you as that. You can use any attribute using % character'}
                </Form.Text>
                <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 10 }}>
                    <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                        {'Exit Phrase to return to workflow'}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Enter Exit Phrase'}
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={exitKeyword}
                        onChange={(e) => {
                            const containsReservedWord = workflowReservedWords.some(word =>
                                e.target.value.toLowerCase().includes(word)
                            );
                            if (containsReservedWord) {
                                toast.error("Exit phrase cannot include exit, back, prev and menu. Choose a phrase without these words");
                            } else {
                                setExitKeyWord(e.target.value);
                            }
                        }}
                    />
                </InputGroup>
                <Form.Text style={{ fontSize: 12, color: colors.greys04 }}>
                    {'Note - Do not use exit, menu, back and prev in exit phrase'}
                </Form.Text>
                <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black, marginBlock: 10 }}>
                    {'Buffer messages'}
                </Form.Label>
                <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 10 }}>
                    <Form.Label style={{ fontSize: 14, fontWeight: 400, color: colors.black }}>
                        {'5 Seconds Buffer Message'}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Enter Buffer Message'}
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={bufferMessageFive}
                        onChange={(e) => {
                            setBufferMessageFive(e.target.value);
                        }}
                    />
                </InputGroup>
                <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 10 }}>
                    <Form.Label style={{ fontSize: 14, fontWeight: 400, color: colors.greys04 }}>
                        {'10 Seconds Buffer Message'}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={'Enter Buffer Message'}
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={bufferMessageTen}
                        onChange={(e) => {
                            setBufferMessageTen(e.target.value);
                        }}
                    />
                </InputGroup>
                <Form.Text style={{ fontSize: 12, color: colors.greys04 }}>
                    {'Note - Llama takes 10 to 20 seconds to respond'}
                </Form.Text>
            </div>
        </>
    );
};

export default LlamaNodeModal;
