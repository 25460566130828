import React, { useState } from 'react'
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { referralSave } from '../../../Services';
import { toast } from 'react-toastify';

const ReferralModal = ({ setIsReferralModalOpen, token }) => {

    const [rname, setRname] = useState('')
    const [rCompanyName, setRCompanyName] = useState('');
    const [rCompanyWebsite, setRCompanyWebsite] = useState('');
    const [rCompanyEmail, setRCompanyEmail] = useState('');
    const [rCompanyNumber, setRCompanyNumber] = useState('');

    const referralSaveAPICall = () => {
        let data = {
            name: rname,
            companyName: rCompanyName,
            companyMobile: rCompanyNumber,
            companyEmail: rCompanyEmail,
            companyWebsite: rCompanyWebsite,
        }
        referralSave(data, token).then((res) => {
            if (res?.flag) {
                toast.success('referral successfully saved!', { position: 'top-right' })
                setIsReferralModalOpen(false)
            } else {
                toast.error('unexpected error occurred, please try again later', { position: 'top-right' })
            }
        })
    }

    return (
        <>
            <div className='DarkBg' onClick={() => { setIsReferralModalOpen(false) }}>
                <div className='Center'>
                    <div className='referral_modal_container' onClick={(e) => { e.stopPropagation() }}>
                        <div className='referral_modal_container__header'>
                            <h3>
                                {'Refer and earn'}
                            </h3>
                            <img src={images.CMClose}
                                style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}
                                onClick={() => { setIsReferralModalOpen(false) }}
                            ></img>
                        </div>
                        <div className='referral_modal_container__body verythinscrollnewinv'>
                            <p style={{ width: '100%', color: colors.greys04, marginBlock: 12 }}>
                                {'Refer Cheerio to other businesses and earn $10 in your Cheerio wallet for each new customer who purchases a plan.'}
                            </p>
                            <InputGroup className="my-2">
                                <Form.Label className='form_heading_style'>
                                    {'Name'}<span style={{ color: colors.error03 }}>{'*'}</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Name of Owner of referred business"
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={rname}
                                    onChange={(e) => {
                                        setRname(e.target.value);
                                    }}
                                />
                            </InputGroup>
                            <InputGroup className="my-2">
                                <Form.Label className='form_heading_style'>
                                    {'Company Name'}<span style={{ color: colors.error03 }}>{'*'}</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Company name"
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={rCompanyName}
                                    onChange={(e) => {
                                        setRCompanyName(e.target.value);
                                    }}
                                />
                            </InputGroup>
                            <InputGroup className="my-2">
                                <Form.Label className='form_heading_style'>
                                    {'Company Website'}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Website of referred business"
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={rCompanyWebsite}
                                    onChange={(e) => {
                                        setRCompanyWebsite(e.target.value);
                                    }}
                                />
                            </InputGroup>
                            <InputGroup className="my-2">
                                <Form.Label className='form_heading_style'>
                                    {'Company Email'}<span style={{ color: colors.error03 }}>{'*'}</span>
                                </Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Email of referred business"
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={rCompanyEmail}
                                    onChange={(e) => {
                                        setRCompanyEmail(e.target.value);
                                    }}
                                />
                            </InputGroup>
                            <InputGroup className="my-2">
                                <Form.Label className='form_heading_style'>
                                    {'Owner WhatsApp Number'}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Owner WhatsApp number including country code"
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={rCompanyNumber}
                                    onChange={(e) => {
                                        setRCompanyNumber(e.target.value);
                                    }}
                                />
                            </InputGroup>
                        </div>
                        <CheerioButton
                            borderStyle={{
                                borderColor: colors.black,
                                width: '100%',
                                paddingBlock: 10,
                            }}
                            textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white01 }}
                            btnText={'Submit'}
                            backColor={colors.black}
                            disabled={(rname && rCompanyName && rCompanyEmail) ? false : true}
                            onclick={() => {
                                console.log("add account clicked")
                                referralSaveAPICall();
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReferralModal