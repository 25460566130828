import React, { useEffect, useState, useCallback } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { freeTrialApi } from '../../../Services';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import '../../../Components/Modal/Modal.css';
import { eventsend } from '../../../Config/analyticsFunctions';

const TwoFactorLoginPage = ({
    closeOtpAction,
    phoneOTPvalue,
    incorrectPhoneOTP,
    setPhoneOTPvalue,
    twoFactorLoginAction,
    numbervalue,
    resendPhoneOtp,
}) => {

    const [timer, setTimer] = useState(10);
    const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);

    useEffect(() => {
        timer > 0 && setTimeout(timeOutCallback, 1000);
    }, [timer, timeOutCallback]);

    const resetTimer = function () {
        if (!timer) {
            setTimer(30);
        }
    };

    const handleResendOTP = () => {
        resendPhoneOtp();
        resetTimer();
    };
    return (
        <>
            <div className='ModalDarkBg'>
                <div className="Center">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            twoFactorLoginAction();
                        }}
                    >
                        <div className='otp_verify_modal'>
                            <div className='otp_verify_modal__header'>
                                <img src={images.CMClose} onClick={closeOtpAction}></img>
                            </div>
                            <div className="Row">
                                <p
                                    style={Styles.freeTrial}
                                >
                                    Two Factor Login
                                </p>
                            </div>


                            <div className='otp_verify_modal__body'>
                                <h6 className='enter_otp_text'>
                                    Enter OTP
                                </h6>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="x-x-x-x-x-x"
                                    value={phoneOTPvalue}
                                    onChange={(e) => setPhoneOTPvalue(e.target.value)}
                                />
                                <h6 className='otp_sent_text'>
                                    OTP sent to your registered mobile number {`“${numbervalue}”`}
                                </h6>

                                <button
                                    onClick={handleResendOTP}
                                    disabled={timer != 0}
                                    type="button"
                                    className="btn btn-outline-dark mt-3"
                                >
                                    Resend OTP
                                </button>

                                {timer > 0 && (
                                    <p style={{ fontSize: 12, color: colors.greys04, marginTop: "1%" }} className="form-label">
                                        {`Retry in ${timer} seconds`}
                                    </p>
                                )}

                                {incorrectPhoneOTP && (
                                    <>
                                        <div className='incorrect_otp_text'>
                                            <text
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {'Incorrect OTP entered'}
                                            </text>
                                        </div>
                                    </>
                                )}

                                <div style={{ width: '100%', marginTop: 'auto', marginBottom: '5%' }}>
                                    <button
                                        style={{ width: '100%' }}
                                        type="submit"
                                        className="btn btn-dark"
                                        disabled={phoneOTPvalue.length !== 6}
                                    >
                                        Verify OTP
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div >
            </div >
        </>
    );
};

export default TwoFactorLoginPage;

const Styles = {
    DarkBg: {
        backgroundColor: 'rgba(0,0,0,0.4)',
        width: '100vw',
        height: '100vh',
        // flex:1,
        zIndex: 0,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        position: 'fixed',
        zIndex: '1231423',
    },
    Center: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    },
    TxtHeading: {
        fontSize: 42,
        fontWeight: 900,
        color: colors.primary03,
    },
    TxtBody: {
        fontSize: 20,
        fontWeight: 600,
        color: colors.black03,
    },
    TxtBody2: {
        fontSize: 16,
        fontWeight: 600,
        color: colors.black03,
    },
    itemborder: {
        backgroundColor: colors.white,
        borderRadius: 16,
        boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.15),-2px -2px 10px 0 rgba(0, 0, 0, 0.15)',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
    },
    freeTrial: {
        margin: 0,
        fontSize: 24,
        fontWeight: 700,
        color: colors.black,
        fontWeight: 'bold',
    },
};
