import Form from 'react-bootstrap/Form';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import { useState } from 'react';
import '../styles.css';

const SelectPageModal = ({ setSelectedPageId, setListPageModalOpen, pagesList }) => {

    const [page, setPage] = useState("");

    const handleClose = () => {
        setListPageModalOpen(false);
    }

    const handleSelect = () => {
        console.log("In select modal");
        if (!page?.instagram_business_account?.id) {
            toast.error("No instagram account is connected with this account");
        } else {
            setSelectedPageId(page.id);
        }
    }

    return (
        <div className="modal-backdrop">
            <div className="modal-container">
                <Form.Group className="mb-3" style={{ width: '100%' }}>
                    <Form.Label className="modal-header" htmlFor="igPageList">
                        Select Page
                    </Form.Label>
                    <Form.Select
                        id="igPageList"
                        value={page ? page.id : ""}
                        onChange={(e) => setPage(pagesList.find(page => page.id === e.target.value))}
                        className="modal-select"
                    >
                        <option value="" disabled>Select a page</option>
                        {pagesList.map((pageItem) => (
                            <option key={pageItem.id} value={pageItem.id}>
                                {pageItem.name}
                            </option>
                        ))}
                    </Form.Select>
                    <div className="modal-buttons">
                        <CheerioButton
                            borderStyle={{ borderColor: colors.primary03 }}
                            textStyle={{ color: colors.primary03 }}
                            className="modal-button"
                            btnText={'Cancel'}
                            backColor={colors.white}
                            onclick={handleClose}
                        />
                        <CheerioButton
                            borderStyle={{ borderColor: 'blue' }}
                            textStyle={{ color: colors.white }}
                            className="modal-button"
                            btnText={'Select'}
                            backColor={'blue'}
                            onclick={handleSelect}
                        />
                    </div>
                </Form.Group>
            </div>
        </div>
    );
};

export default SelectPageModal