import React from 'react'
import CheerioButton from '../../../Components/CheerioButton'
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';

const PremiumTile = ({ ItemStyle , ItemHeader , ItemImage , ItemText , ButtonText ,onclickAction}) => {
  return (
    <>
    <div className='tile premium_tile_container' style={{...ItemStyle}}>
        <img
            src={ItemImage}
            style={{height: 66, width: 66, objectFit: 'contain', alignSelf: 'flex-start'}}
        ></img>
        <h3 className='mt-2 mb-1'>{ItemHeader}</h3>
        <p className='mt-1 mb-2'>{ItemText}</p>
        <CheerioButton 
            borderStyle={{
                width: '100%',
                marginBlockStart: 10,
                border: '1px solid black',
            }}
            textStyle={{
                color: colors.black,
            }}
            btnText={ButtonText}
            backColor='transparent'
            onclick={onclickAction}
        />
    </div>
    </>
  )
}

export default PremiumTile