import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import colors from '../../../Utils/colors';

const CreateSMSTemplate = ({ setCustomSMSText, customSMSText }) => {
    return (
        <div style={{
            paddingInline: 16,
            width: '98%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            gap: 16,
        }}>
            <div style={{
                width: '60%',
            }}>
                <InputGroup className="mb-2">
                    <Form.Label style={{ ...Styles.TxtHeading2 }}>
                        {'Body'}
                    </Form.Label>
                    <span
                        className="ms-auto"
                        style={{
                            color: customSMSText.length > 120 ? colors.error04 : colors.greys04,
                            fontSize: 12,
                            fontWeight: 400,
                            padding: 0,
                            margin: 0,
                        }}
                    >
                        {`${customSMSText.length}/120`}
                    </span>
                    <Form.Control
                        as="textarea"
                        rows={6}
                        placeholder="Enter message"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        isInvalid={customSMSText.length >= 120}
                        maxLength={640}
                        value={customSMSText}
                        onChange={(e) => {
                            setCustomSMSText(e.target.value);
                        }}
                    />
                </InputGroup>
                <p style={{ color: colors.greys01 }}>{"NOTE: Customize your message using %name% or %<custom attribute>% with custom contact attributes (e.g., %age%)."}</p>

            </div>
            <div
                className="d-flex flex-column justify-content-start align-items-start my-2 py-2 px-3"
                style={{
                    backgroundColor: '#1F2C34',
                    width: '30%',
                    paddingBlock: 24,
                    borderRadius: 16,
                }}
            >
                <p
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: colors.white,
                        marginBlock: 16,
                        width: '100%',
                        flexWrap: 'wrap',
                        whiteSpace: 'pre-wrap',
                    }}>
                    {customSMSText}
                </p>

            </div>
        </div>
    )
}

export default CreateSMSTemplate

const Styles = {
    DarkBg: {
        backgroundColor: 'rgba(0,0,0,0.4)',
        width: '100vw',
        height: '100vh',
        // flex:1,
        zIndex: 1,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        position: 'fixed',
    },
    Center: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    },
    TxtHeading: {
        fontSize: 24,
        fontWeight: 700,
        color: colors.black,
    },
    TxtHeading2: {
        fontSize: 16,
        fontWeight: 700,
        color: colors.black,
    },
    TxtBody2: {
        fontSize: 14,
        fontWeight: 400,
        color: colors.greys04,
    },
    TxtBody1: {
        fontSize: 16,
        fontWeight: 400,
        color: colors.primary03,
    },
    borderstyle: {
        backgroundColor: colors.white,
        borderRadius: 16,
        boxShadow: '0px 0px 10px 0 rgba(0, 0, 0, 0.15)',
    },
    borderDashed: {
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: colors.primary04,
        borderRadius: 40,
    },
    TxtBody: {
        fontSize: 16,
        fontWeight: 600,
        color: colors.black04,
    },
};
