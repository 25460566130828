import React, { useEffect, useRef, useState } from 'react';
import { ClickAwayListener } from '@mui/material';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { IoIosSearch } from 'react-icons/io';
import { RxCross1 } from 'react-icons/rx';
import colors from '../../../Utils/colors'; 
import './styles.css';
import images from '../../../Utils/images';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { Select } from '@mui/material';
import { Modal, Button } from 'react-bootstrap';
import {
  fetchContactLabels,
  createSingleContact,
} from '../../../Services';


export const AddLabelModal = ({ handleClose, handleAdd, newLabel, setNewLabel }) => {
    return (
        <Modal show onHide={handleClose} centered size="sm"> 
            <Modal.Header closeButton>
                <Modal.Title>Add New Label</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="formLabelName">
                    <Form.Label>Label Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter label name"
                        value={newLabel}
                        onChange={(e) => setNewLabel(e.target.value)}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleAdd}>
                    Add Label
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const LabelNamesModal = ({
    setLabelNamesOpen,
    tocalllatestlables,
    selectedLabelAction,
    positionStyle,
    labelNames,
    username,
    userNumber, 
    useremail,
    updateLables
}) => {

    const [filteredLabels, setFilteredLabels] = useState(labelNames);
    const [filterValue, setFilterValue] = useState('');
    const [showAddLabelForm, setShowAddLabelForm] = useState(false);
    const [newLabel, setNewLabel] = useState('');
    const authtoken = useSelector((state) => state.main.auth_token);

    const createSingleContactLabelApiCall = (labelName) => {
        const payLoad = {
            labelName: labelName, 
            data: {
                name: username,
                phone: userNumber, 
                email: useremail,
            },
        };
    
        createSingleContact(authtoken, payLoad).then((res) => {
            if (res?.flag) {
                toast.success(res?.message, {
                    autoClose: 1000,
                    position: 'top-right',
                  });
                updateLables();
                tocalllatestlables(); 
                setLabelNamesOpen(false); 
            } else {
                toast.error(res?.message, { position: 'top-right' });
            }
        });
    };
    useEffect(() => {
        if (filterValue) {
            setFilteredLabels(
                labelNames.filter((label) => label.toLowerCase().includes(filterValue.toLowerCase()))
            );
        } else {
            setFilteredLabels(labelNames);
        }
    }, [filterValue, labelNames]);

    const handleAddLabel = () => {
        if (newLabel.trim() && !labelNames.includes(newLabel.trim())) {
            createSingleContactLabelApiCall(newLabel.trim());
            setNewLabel('');
            setShowAddLabelForm(false);
        }
    };

    const handleLabelSelect = (label) => {
        createSingleContactLabelApiCall(label);
    };

    return (
        <ClickAwayListener onClickAway={() => setLabelNamesOpen(false)}>
            <div
                style={{
                    position: 'absolute',
                    zIndex: 1000,
                    height: '250px',
                    width: '298px',
                    padding: '16px',
                    backgroundColor: '#fff',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    overflowY: 'auto',
                    ...positionStyle,
                }}
            >
                <InputGroup className="mb-2">
                    <InputGroup.Text
                        style={{
                            borderRightWidth: 0,
                            backgroundColor: 'transparent',
                            borderColor: '#B3B3B3',
                            paddingInline: '8px',
                        }}
                    >
                        <IoIosSearch size={16} />
                    </InputGroup.Text>
                    <Form.Control
                        type="text"
                        placeholder="Search labels"
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                        style={{
                            borderTopRightRadius: filterValue ? '0' : 8,
                            borderBottomRightRadius: filterValue ? '0' : 8,
                            borderColor: '#B3B3B3',
                            borderLeftWidth: 0,
                            paddingInlineStart: 0,
                            outline: 'none',
                            boxShadow: 'none', 
                        }}
                    />
                    {filterValue && (
                        <InputGroup.Text
                            onClick={() => setFilterValue('')}
                            style={{
                                borderLeftWidth: 0,
                                backgroundColor: 'transparent',
                                borderColor: '#B3B3B3',
                                paddingInline: '8px',
                                cursor: 'pointer',
                            }}
                        >
                            <RxCross1 size={16} />
                        </InputGroup.Text>
                    )}
                </InputGroup>

                {!showAddLabelForm ? (
                    <Button
                        onClick={() => setShowAddLabelForm(true)}
                        style={{
                            display: 'block',
                            width: '100%',
                            padding: '8px',
                            backgroundColor: 'transparent', 
                            color: '#6f42c1', 
                            border: 'none',
                            borderRadius: '4px',
                            marginBottom: '8px',
                            cursor: 'pointer',
                            textAlign: 'left',
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = '#f0f0f0'; 
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = 'transparent'; 
                        }}
                    >
                        Create new label           
                    </Button>
                ) : (
                    <div style={{ marginBottom: '16px' }}>
                        <Form.Group controlId="formLabelName" className="mb-2">
                            <Form.Label>Label Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter label name"
                                value={newLabel}
                                onChange={(e) => setNewLabel(e.target.value)}
                            />
                        </Form.Group>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button variant="secondary" onClick={() => setShowAddLabelForm(false)}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={handleAddLabel}>
                                Add Label
                            </Button>
                        </div>
                    </div>
                )}

                {filteredLabels?.length > 0 && !showAddLabelForm && (
                    <div>
                        {filteredLabels?.map((label, index) => (
                            <div
                                key={label}
                                className="d-flex align-items-center gap-2 my-1 py-2 px-2"
                                style={{
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s ease',
                                    borderRadius: '4px', 
                                }}
                                onClick={() => handleLabelSelect(label)}  // Trigger the API call on label select
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor = '#f0f0f0'; 
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = 'transparent'; 
                                }}
                            >
                                <p
                                    style={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        color: '#000', 
                                    }}
                                >
                                    {label}
                                </p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
};
export default LabelNamesModal;





