import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import colors from '../../Utils/colors';
import { useSelector } from 'react-redux';
import { V2AgentAccept, loginADMIN } from '../../Services';
import images from '../../Utils/images';

const AgentShortURL = () => {

  const [isLoading, setIsLoading] = useState(true)
  const [authToken, setAuthToken] = useState('');
  const [status, setStatus] = useState('');
  const location = (window.location.href).toString();
  const splitarr = location.replace("r/", "$").replace("?name=", "$").split("$");
  const [timeLeft, setTimeLeft] = useState(null);
  // useEffect(()=>{
  //   adminLogin();
  // },[])

  useEffect(() => {
    if (splitarr[1] && splitarr[2]) {
      AcceptAgentV2APICall();
    }
  }, [])

  useEffect(() => {
    const timer =
      timeLeft > 0 && setInterval(() => setTimeLeft(timeLeft - 1), 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  const AcceptAgentV2APICall = () => {
    const data = {
      code: splitarr[1],
      name: splitarr[2],
    };
    console.log("data being sent ===> ", data);
    V2AgentAccept(data).then((res) => {
      setIsLoading(false);
      if (res?.flag) {
        if (res?.accepted) {
          console.log("success response: " + res)
          setStatus('Success');
          setTimeLeft(10)
          setTimeout(() => {
            window.open('https://app.cheerio.in/', '_self');
            // window.open('https://dev.app.cheerio.in/','_self');
          }, 10000);
        } else {
          console.log("error response: " + res)
          setStatus('Failure');
          setTimeLeft(10);
          setTimeout(() => {
            // window.open('https://app.cheerio.in/','_self');
            window.close();
          }, 10000);
        }

      } else {
        console.log("error response: " + res)
      }
      // setIsLoading(false);


    })
  }


  // setTimeout(() => {
  //   // window.open('https://app.cheerio.in/')
  //   window.open('https://dev.app.cheerio.in/');
  // }, 13000);




  return (
    <>
      <div className='agent_short_url_container'
      // className='d-flex flex-column justify-content-center align-items-center' style={{height:'100vh',width:'100vw'}}
      >
        {isLoading ? (
          <>
            <Spinner style={{ color: colors.primary03 }} />
          </>
        ) : (
          <>
            {status === 'Success' ? (
              <>
                <img
                  src={images.CATSuccess}
                // style={{height:300, width: 300 ,objectFit:'contain', marginBlock:16}}
                ></img>
                <p
                // style={{fontSize:24,fontWeight:700,color:colors.black,marginBlock:16,width:'40%',textAlign:'center'}}
                >
                  {`Account successfully activated. Please login as 'Agent' using this email ID and admin provided password. Redirecting to login page in ${timeLeft}s`}
                </p>
              </>
            ) : status === 'Failure' && (
              <>
                <img
                  src={images.CATFail}
                // style={{height:300, width: 300 ,objectFit:'contain', marginBlock:16}}
                ></img>
                <p
                // style={{fontSize:24,fontWeight:700,color:colors.black,marginBlock:16,width:'40%',textAlign:'center'}}
                >
                  {`Failed to activate your account. Please try again later or contact our support team if the issue persists.`}
                </p>
              </>
            )}

          </>
        )}
      </div>
    </>
  )
}

export default AgentShortURL