import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import colors from '../Utils/colors';
import images from '../Utils/images';

const SpinnerLoader = (props) => {
  return (
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color={props.color ? props.color : colors.themeBlue}
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
      style={props.style}
    />
  );
};

export const NewLoader = (props) => {
  return (
    <>
      {props.style ? (
        <img style={props.style} src={images.NewLoader}></img>
      ) : (
        <img style={{ height: '100px', width: '100px' }} src={images.NewLoader}></img>
      )}
    </>
  );
};

export default SpinnerLoader;
