import React from 'react';
import { Modal } from 'react-bootstrap';
import images from '../../Utils/images';
import CheerioButton from '../CheerioButton';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Badge } from '../../Images/creatorNew/badge.svg';

const CampaignsPlan = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <img src={images.CampaignPlan}></img>
      <div style={{ marginTop: '20px', fontWeight: '500', fontSize: '16px' }}>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Keep track of your campaigns.
        </p>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Schedule campaigns for later.
        </p>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Plan & Run campaigns quickly
        </p>
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex">
            {' '}
            <Badge></Badge>
            <p style={{ marginLeft: '10px', marginTop: '-5px' }}> Premium Feature </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ShopifyPlans = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <img src={images.ShopifyPlan}></img>
      <div style={{ marginTop: '20px', fontWeight: '500', fontSize: '16px' }}>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Integrate with Shopify in minutes.
        </p>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Automate messages for customers.
        </p>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Get Meta approved templates.
        </p>
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex">
            {' '}
            <Badge></Badge>
            <p style={{ marginLeft: '10px', marginTop: '-5px' }}> Premium Feature </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const WaInboxPlans = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <img src={images.WaInboxPlan}></img>
      <div style={{ marginTop: '20px', fontWeight: '500', fontSize: '16px' }}>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Send & receive WhatsApp messages.
        </p>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Save up to 10 canned messages.
        </p>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Send template messages to reopen chat.
        </p>
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex">
            {' '}
            <Badge></Badge>
            <p style={{ marginLeft: '10px', marginTop: '-5px' }}> Premium Feature </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const WaTemplatePlans = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <img src={images.WaTemplatePlan}></img>
      <div style={{ marginTop: '20px', fontWeight: '500', fontSize: '16px' }}>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Get Meta Approved templates.
        </p>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Create your custom templates.
        </p>
        {/* <p>
                    <img src={images.BlackCircle} style={{marginRight:"10px"}}></img>
                    Send template messages to reopen chat.
                </p> */}
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex">
            {' '}
            <Badge></Badge>
            <p style={{ marginLeft: '10px', marginTop: '-5px' }}> Premium Feature </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ChatBotPlans = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <img src={images.ChatBotPlan}></img>
      <div style={{ marginTop: '20px', fontWeight: '500', fontSize: '16px' }}>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Drag & drop automation flows.
        </p>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Setup Google dialog flow bot.
        </p>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Automate your customer’s message.
        </p>
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex">
            {' '}
            <Badge></Badge>
            <p style={{ marginLeft: '10px', marginTop: '-5px' }}> Premium Feature </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const WooCommerce = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <img src={images.WooCommercePlan} width={'400px'}></img>
      <div style={{ marginTop: '20px', fontWeight: '500', fontSize: '16px' }}>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Integrate with WooCommerce in minutes.
        </p>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Automate messages for customers.
        </p>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Get Meta approved templates.
        </p>
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex">
            {' '}
            <Badge></Badge>
            <p style={{ marginLeft: '10px', marginTop: '-5px' }}> Premium Feature </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const OptionComponent = ({ ImagePlan, Option1 = '', Option2 = '', Option3 = '' }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <img src={ImagePlan} width={''}></img>
      <div style={{ marginTop: '20px', fontWeight: '500', fontSize: '16px' }}>
        {Option1 !== '' && (
          <p>
            <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
            {Option1}
          </p>
        )}
        {Option2 !== '' && (
          <p>
            <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
            {Option2}
          </p>
        )}
        {Option3 !== '' && (
          <p>
            <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
            {Option3}
          </p>
        )}
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex">
            {' '}
            <Badge></Badge>
            <p style={{ marginLeft: '10px', marginTop: '-5px' }}> Premium Feature </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContactPlans = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <img src={images.ContactsPlans}></img>
      <div style={{ marginTop: '20px', fontWeight: '500', fontSize: '16px' }}>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Integrate with Facebook to sync leads.
        </p>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          Upload contact using csv files.
        </p>
        <p>
          <img src={images.BlackCircle} style={{ marginRight: '10px' }}></img>
          {'Manage using Label/Tag(s).'}
        </p>
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex">
            {' '}
            <Badge></Badge>
            <p style={{ marginLeft: '10px', marginTop: '-5px' }}> Premium Feature </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PremiumOptionModal = ({ option, openPremiumModal, setOpenPremiumModal }) => {
  const navigate = useNavigate();
  return (
    <div>
      <Modal show={openPremiumModal}>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <p style={{ fontSize: '20px', fontWeight: '600' }}>{option}</p>
            <img
              src={images.CMClose}
              onClick={() => {
                setOpenPremiumModal(false);
                navigate('/creator');
              }}
              style={{
                cursor: 'pointer',
                height: 22,
                width: 22,
                marginInlineEnd: 8,
              }}
            ></img>
          </div>

          {option === 'Campaigns' && <CampaignsPlan></CampaignsPlan>}
          {option === 'Shopify Store' && <ShopifyPlans></ShopifyPlans>}

          {option === 'WhatsApp Inbox' && <WaInboxPlans></WaInboxPlans>}

          {option === 'WhatsApp templates' && <WaTemplatePlans></WaTemplatePlans>}
          {option === 'ChatBot' && <ChatBotPlans></ChatBotPlans>}
          {option === 'WooCommerce' && <WooCommerce></WooCommerce>}
          {option === 'Contacts' && <ContactPlans></ContactPlans>}
          {option === 'Email Builder' && (
            <OptionComponent
              ImagePlan={images.EmailBuilderPlan}
              Option1="Just drag & drop items to create Email."
              Option2="Send Email campaigns in just 10 mins."
              Option3="Get AI generate content & images."
            ></OptionComponent>
          )}
          {option === 'Manage SenderId' && (
            <OptionComponent
              ImagePlan={images.SenderIdPlan}
              Option1="Create & manage Sender IDs easily."
              Option2="Automate Email campaigns using IDs."
            ></OptionComponent>
          )}
          {option === 'Global Analytics' && (
            <OptionComponent
              ImagePlan={images.CampaignPlan}
              Option1="Get Analytics record data of camapigns"
            ></OptionComponent>
          )}
          <CheerioButton
            className="button"
            borderStyle={{
              height: '42px',
            }}
            textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
            btnText={'Get a Plan'}
            onclick={() => {
              navigate('/subscription');
            }}
            backColor={'#000000'}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
