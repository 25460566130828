import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { useSelector } from 'react-redux';
import { V2EmailBuilderAdd, V2EmailBuilderList, V2EmailDesignDelete } from '../../../Services';
import { SpinnerComponent } from '../../AnnouncementsNew/Components/NewComponents';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import { ToastContainer, toast } from 'react-toastify';
import EmailTemplates from '../../Templates/Components/EmailTemplates';
import CheerioButton from '../../../Components/CheerioButton';
import { trackPages } from '../../../Utils/brevo';
import { eventsend } from '../../../Config/analyticsFunctions';

const Home = ({ setCurrentScreen, setSelectedJSON }) => {
  const [selectedTab, setSelectedTab] = useState('Your Design');
  const [templateList, setTemplateList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;

  useEffect(() => {
    trackPages('email_library_page', localdata);
    setIsLoading(true);
    getDesignAPIcall();
  }, []);

  const getDesignAPIcall = () => {
    let token = authtoken;
    V2EmailBuilderList(token).then((res) => {
      if (res?.flag) {
        console.log('success response ===> ', res);
        const arr = res?.data;
        setTemplateList(arr?.reverse());
      } else {
        console.log('error response ===> ', res);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    });
  };

  const deleteDesignAction = (item) => {
    let data = {
      _id: item._id,
    };
    let token = authtoken;
    V2EmailDesignDelete(data, token).then((res) => {
      if (res) {
        console.log('delete success ===> ', res);
        getDesignAPIcall();
      } else {
        console.log('delete fail ===> ', res);
      }
    });
  };

  const duplicateDesignAction = (item) => {
    let data = {
      emailJSON: item.emailJSON,
      emailName: `${item.emailName} copy`,
    };
    let token = authtoken;
    V2EmailBuilderAdd(data, token).then((res) => {
      if (res?.flag) {
        console.log('success response ===> ', res);
        setIsLoading(true);
        getDesignAPIcall();
      } else {
        console.log('error response ===> ', res);
        toast(`Error occured while saving template. Please try again later`, {
          position: 'top-center',
        });
      }
    });
  };

  const TabItem = ({ title }) => {
    return (
      <>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: '100%',
            paddingInline: 40,
            paddingBlock: 10,
            cursor: 'pointer',
            borderBottom: title === selectedTab ? `3px solid ${colors.primary03}` : ``,
          }}
          onClick={() => {
            eventsend("Use_template_Email_templates_page_clicked");
            setSelectedTab(title);
          }}
        >
          <p
            style={{
              padding: 0,
              margin: 0,
              fontSize: 16,
              fontWeight: title === selectedTab ? 600 : 400,
              color: title === selectedTab ? colors.black : colors.greys04,
            }}
          >
            {title}
          </p>
        </div>
      </>
    );
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();

        onClick(e);
      }}
    >
      {children}
      <img src={images.ThreeDot} style={{ height: 30, width: 30 }} />
    </a>
  ));

  const YourDesignItem = ({
    ImageSrc,
    Title,
    OnclickAction,
    id,
    dropdown = true,
    onclickRunCampaign,
    onclickDeleteDesign,
    onclickDuplicateDesign,
  }) => {
    return (
      <>
        <div
          key={id}
          className="d-flex flex-column justify-content-start align-items-center"
          style={{ marginInlineEnd: 20, marginBlockEnd: 20, width: 200 }}
        >
          <div
            id={id}
            className="d-flex justify-content-center align-items-center"
            style={{
              height: 180,
              width: 180,
              borderRadius: 16,
              border: `1px solid ${colors.greys03}`,
              padding: 20,
              backgroundColor: colors.white01,
              cursor: 'pointer',
            }}
            onClick={() => {
              OnclickAction();
            }}
          >
            <img
              src={ImageSrc}
              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
            ></img>
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center w-100 mb-2 py-2 px-2">
            <p
              style={{
                margin: 0,
                padding: 0,
                width: '80%',
                fontSize: 16,
                fontWeight: 600,
                color: colors.black,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                wordBreak: 'break-all',
              }}
            >
              {Title}
            </p>
            {dropdown && (
              <Dropdown>
                <DropdownToggle as={CustomToggle}></DropdownToggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      if (!isPremiumUser) return;
                      onclickRunCampaign();
                    }}
                  >
                    <img
                      src={images.SNCampaigns}
                      style={{ height: 20, width: 20, objectFit: 'contain', marginInlineEnd: 8 }}
                    ></img>
                    {'Run Campaign'}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => onclickDeleteDesign()}>
                    <img
                      src={images.DeleteBlack}
                      style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}
                    ></img>
                    {'Delete'}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => onclickDuplicateDesign()}>
                    <img
                      src={images.DesignDuplicate}
                      style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}
                    ></img>
                    {'Duplicate'}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="d-flex flex-column justify-content-start align-items-center w-100 h-100"
        style={{ paddingInline: 40 }}
      >
        <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <h3
            style={{
              marginBlock: 12,
              // fontSize: 24,
              // fontWeight: 700,
              color: colors.black,
              // width: '100%',
            }}
          >
            {'Design library'}
          </h3>
          <CheerioButton
            borderStyle={{
              border: `1px solid ${colors.darkPurple}`,
              paddingBlock: 6,
              paddingInline: 20,
            }}
            textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white01 }}
            btnText={'Create a template'}
            onclick={() => {
              setSelectedJSON('');
              setCurrentScreen('Builder');
            }}
            backColor={colors.darkPurple}
          />
        </div>

        <div
          className="d-flex flex-row justify-content-start align-items-center w-100"
          style={{ borderBottom: `1px solid ${colors.greys04}` }}
        >
          <TabItem title={'Your Design'} />
          <TabItem title={'Email Templates'} />
        </div>
        {isLoading ? (
          <>
            <SpinnerComponent />
          </>
        ) : (
          <>
            {selectedTab === 'Your Design' ? (
              <div
                className="verythinscrollnew w-100"
                style={{ overflowY: 'auto', flex: 1, paddingBlock: 20 }}
              >
                <div
                  className="d-flex flex-row justify-content-start align-items-center w-100"
                  style={{ flexWrap: 'wrap' }}
                >
                  <YourDesignItem
                    key={`create`}
                    ImageSrc={images.CreateDesign}
                    Title={'Create new design'}
                    OnclickAction={() => {
                      setSelectedJSON('');
                      setCurrentScreen('Builder');
                    }}
                    dropdown={false}
                  />
                  {templateList.length > 0 &&
                    templateList.map((item) => {
                      if (!item?.globalTemplate || localdata?._id == '6489675b4f598e055ed74e34') {
                        return (
                          <YourDesignItem
                            ImageSrc={images.DesignDemo}
                            id={item._id}
                            Title={item.emailName}
                            OnclickAction={() => {
                              console.log('onclick action');
                              setSelectedJSON(item);
                              setCurrentScreen('Builder');
                            }}
                            onclickRunCampaign={() => {
                              console.log('onclick action');
                              setSelectedJSON(item);
                              setCurrentScreen('Builder');
                            }}
                            onclickDeleteDesign={() => {
                              deleteDesignAction(item);
                            }}
                            onclickDuplicateDesign={() => {
                              duplicateDesignAction(item);
                            }}
                          />
                        );
                      }
                    })}
                </div>
              </div>
            ) : (
              selectedTab === 'Email Templates' && (
                <>
                  <EmailTemplates />
                </>
              )
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Home;
