import React from 'react';
import images from '../../../Utils/images';
import colors from '../../../Utils/colors';

const NewTemplateCreate = () => {
  const CategorySelector = ({ header, body }) => {
    return (
      <>
        <div
          className="d-flex flex-row justify-content-between align-items-center w-100"
          style={{
            border: `1px solid ${colors.borderwhite}`,
            borderRadius: 8,
            marginBlock: 8,
            padding: 8,
          }}
        >
          <div
            className="d-flex flex-row justify-content-center align-items-center"
            style={{
              height: 20,
              width: 20,
              borderRadius: '50%',
              border: `1px solid ${colors.black}`,
              marginInline: 16,
            }}
          ></div>
          <div
            className="d-flex flex-column justify-content-evenly align-items-start"
            style={{ flex: 1 }}
          >
            <h4
              style={{
                margin: 0,
                padding: 0,
                fontSize: 16,
                fontWeight: 700,
                color: colors.black,
                width: '100%',
                marginBlock: 4,
              }}
            >
              {header}
            </h4>
            <p
              style={{
                margin: 0,
                padding: 0,
                fontSize: 14,
                fontWeight: 400,
                color: colors.greys04,
                width: '100%',
              }}
            >
              {body}
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="d-flex flex-column justify-content-start align-items-center"
        style={{ width: '100%', height: '100%', paddingBlock: 20, paddingInline: 30 }}
      >
        <div className="d-flex flex-row justify-content-start align-items-center w-100">
          <img
            src={images.BackArrowBlack}
            style={{ height: 24, width: 24, objectFit: 'contain' }}
          ></img>
          <h2
            style={{
              margin: 0,
              padding: 0,
              marginInlineStart: 16,
              fontSize: 20,
              fontWeight: 700,
              color: colors.black,
            }}
          >
            {'New message template'}
          </h2>
        </div>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ width: '100%', flex: 1, paddingBlock: 30 }}
        >
          <div
            className="d-flex flex-column justify-content-between align-items-center"
            style={{
              width: 500,
              backgroundColor: colors.white,
              border: `1px solid ${colors.borderwhite}`,
              borderRadius: 16,
              paddingBlock: 16,
              paddingInline: 30,
            }}
          >
            <h4
              style={{
                margin: 0,
                padding: 0,
                fontSize: 16,
                fontWeight: 700,
                color: colors.black,
                width: '100%',
              }}
            >
              {'Category'}
            </h4>
            <p
              style={{
                padding: 0,
                margin: 0,
                marginBlock: 8,
                fontSize: 14,
                fontWeight: 400,
                color: colors.greys04,
                width: '100%',
              }}
            >
              {'Choose a category which best defines your message template'}
            </p>
            <CategorySelector
              header={'Marketing'}
              body={
                'Promotional or informational messages about your business, products or sevices'
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewTemplateCreate;
