import React, { useEffect } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import Helper from '../../../Utils/Helper';
import { eventsend } from '../../../Config/analyticsFunctions';

const ReferralBanner = ({ setIsReferralModalOpen, onclickCloseAction }) => {

  useEffect(() => {
    eventsend("Close_refer_&_earn_Home_page_clicked");
  }, [])

  return (
    <>
      <div
        className="d-flex flex-row justify-content-between align-items-center w-100"
        style={{
          borderRadius: 16,
          background: 'linear-gradient(100deg, #696EFF, #CD7AFF)',
          paddingBlock: 16,
          paddingInline: 24,
          marginBlockEnd: 24,
        }}
      >
        <div
          className="d-flex flex-row justify-content-center align-items-center"
          style={{ flex: 1 }}
        >
          <p style={{ fontSize: 18, fontWeight: 600, color: colors.white01 }}>
            {'Refer Cheerio to businesses & get $10 on each new purchase'}
          </p>
          <div
            className="d-flex flex-row justify-content-center align-items-center py-2 px-3 ms-3"
            style={{ backgroundColor: colors.themeDarkBlue, borderRadius: 10, cursor: 'pointer' }}
            onClick={() => {
              setIsReferralModalOpen(true);
            }}
          >
            <img
              src={images.ReferEarn}
              style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 8 }}
            ></img>
            <p style={{ fontSize: 16, fontWeight: 600, color: colors.white01 }}>{'Refer & Earn'}</p>
          </div>
        </div>
        <img
          src={images.CMCloseWhite}
          style={{
            height: 24,
            width: 24,
            objectFit: 'contain',
            cursor: 'pointer',
            color: colors.white01,
          }}
          onClick={() => {
            onclickCloseAction();
          }}
        ></img>
      </div>
    </>
  );
};

export default ReferralBanner;
