import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { mainReducer } from './Reducers';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import contactsRedux from '../Pages/ContactModule/contactsRedux/contactsRedux';
import labelRedux from '../Pages/ContactModule/Labels/redux/index';

const reducers = combineReducers({
  main: mainReducer,
  contactsRedux,
  labelRedux,
});
// const appreducers = combineReducers({
//     main : mainReducer,
// })

// const rootReducer = (state, action) => {
//     if(action.type === si)
// }

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

let persistor = persistStore(store);

// export default store
export { store, persistor };
