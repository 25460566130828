import React, { useEffect } from 'react';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import CheerioButton from '../CheerioButton';
import { useSelector } from 'react-redux';

const TemplateModal = ({ setIsTemplateOpen, backAction, gotowebhook }) => {
  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);

  const CampaignTemplate = ({ icon, header, greeting, body, WA = false }) => {
    return (
      <>
        <div
          className="d-flex flex-row justify-content-between align-items-center w-50 h-100"
          style={{
            margin: 8,
            borderRadius: 16,
            padding: 20,
            backgroundColor: colors.white02,
            border: `1px solid ${colors.borderwhite}`,
          }}
        >
          <img src={icon} style={{ height: 100, width: 100, objectFit: 'contain' }}></img>
          <div
            className="d-flex flex-column justify-content-between align-items-start h-100"
            style={{ flex: 1, marginInlineStart: 16 }}
          >
            <p
              style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.black }}
            >
              {header}
            </p>
            <div className="d-flex flex-row justify-content-start align-items-center w-100">
              {WA && (
                <img
                  src={images.WAOnlyTemplate}
                  style={{ height: 20, width: 20, objectFit: 'contain', marginInlineEnd: 10 }}
                ></img>
              )}
              <p
                className=""
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 10,
                  fontWeight: 400,
                  color: colors.white,
                  backgroundColor: colors.warning04,
                  paddingBlock: 4,
                  paddingInline: 8,
                  borderRadius: 6,
                }}
              >
                {'Under review'}
              </p>
            </div>
            {greeting && (
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.black,
                  marginBlockStart: 4,
                }}
              >
                {greeting}
              </p>
            )}
            <p
              style={{
                padding: 0,
                margin: 0,
                fontSize: 14,
                fontWeight: 400,
                color: colors.black,
                marginBlockStart: 4,
              }}
            >
              {body}
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="DarkBg" onClick={() => {}}>
        <div className="Center">
          <div
            className="d-flex flex-column justify-content-start align-items-center"
            style={{
              width: 990,
              height: '80vh',
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 0,
              border: `1px solid ${colors.borderwhite}`,
              paddingBlock: 16,
              paddingInline: 30,
            }}
          >
            {/* Header */}
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <img
                src={images.BackArrowBlack}
                style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                onClick={() => {
                  backAction();
                }}
              ></img>
              <h2
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 18,
                  fontWeight: 700,
                  color: colors.black,
                  flex: 1,
                  marginInline: 16,
                }}
              >
                {
                  'Templates we have auto submitted for you. You can check templates status from manage templates section later.'
                }
              </h2>
              <CheerioButton
                borderStyle={{
                  marginBlock: 8,
                  borderColor: colors.primary03,
                  paddingInline: 16,
                  paddingBlock: 8,
                  marginInlineEnd: 16,
                }}
                textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                btnText={'Go to webhook setup'}
                backColor={colors.primary03}
                onclick={() => {
                  gotowebhook();
                }}
              />
              <img
                src={images.CMClose}
                style={{ height: 32, width: 32, objectFit: 'contain', cursor: 'pointer' }}
                onClick={() => {
                  setIsTemplateOpen(false);
                }}
              ></img>
            </div>
            {/* <-----> */}

            {/* Body */}
            <div
              className="d-flex flex-column justify-content-start align-items-center verythinscroll w-100"
              style={{ flex: 1, overflowY: 'scroll', marginBlockEnd: 16 }}
            >
              <div
                className="d-flex flex-column justify-content-start align-items-center w-100 "
                style={{ paddingBlock: 16 }}
              >
                {/* Row */}
                <div
                  className="d-flex flex-row justify-content-between align-items-center w-100"
                  style={{ marginBlock: 8, height: 180 }}
                >
                  <CampaignTemplate
                    icon={images.CAOffer}
                    header={'Share an Offer'}
                    body={
                      '“Seller name” has an exclusive offer for you for “product name”. “Discount” only for next “days” days is available for you ...'
                    }
                  />
                  <CampaignTemplate
                    icon={images.CAProduct}
                    header={'Sell a Product'}
                    body={
                      '“Seller name” has an exclusive offer for you for “product name”. “Discount” only for next “days” days is available for you.'
                    }
                  />
                </div>
                {/* <---> */}

                {/* Row */}
                <div
                  className="d-flex flex-row justify-content-between align-items-center w-100"
                  style={{ marginBlock: 8, height: 180 }}
                >
                  <CampaignTemplate
                    icon={images.CACourse}
                    header={'Sell a Course'}
                    body={
                      '“Seller name” has an exclusive offer for you for “product name”. “Discount” only for next “days” days is available for you ...'
                    }
                  />
                  <CampaignTemplate
                    icon={images.CAFollowUp}
                    header={'Follow up with discount'}
                    WA={true}
                    greeting={'Hello "customername",'}
                    body={`I am "sellername" from "companyname". You had enquired about our services in the recent past.  I just wanted to touch base with you...`}
                  />
                </div>
                {/* <---> */}

                {/* Row */}
                <div
                  className="d-flex flex-row justify-content-between align-items-center w-100"
                  style={{ marginBlock: 8, height: 180 }}
                >
                  <CampaignTemplate
                    icon={images.CARemainder}
                    header={'Send a Reminder'}
                    WA={true}
                    greeting={'Dear "customer name",'}
                    body={
                      'We would like to inform you that "description". Please contact us at "details" for more information/questions ...'
                    }
                  />
                  <CampaignTemplate
                    icon={images.CAThankYou}
                    header={'Thank your customer & share info'}
                    WA={true}
                    greeting={'Hello "customername",'}
                    body={`Thank you for "action". Please find below "supporting". Below are some of the key features: "feature 1" "feature 2" ...`}
                  />
                </div>
                {/* <---> */}

                {/* Row */}
                <div
                  className="d-flex flex-row justify-content-between align-items-center w-100"
                  style={{ marginBlock: 8, height: 180 }}
                >
                  <CampaignTemplate
                    icon={images.CAShare}
                    header={'Share an info/update/quote'}
                    WA={true}
                    greeting={'Hey "customer name",'}
                    body={
                      'Good "timeofday", Here’s a %(type of message) we wanted to share with you today...'
                    }
                  />
                  <CampaignTemplate
                    icon={images.CAHTML}
                    header={'Upload custom HTML'}
                    // WA={true}
                    greeting={'Didn’t find a template?'}
                    body={`Just upload template and share with your customers in few clicks`}
                  />
                </div>
                {/* <---> */}
              </div>
            </div>
            {/* <-----> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateModal;
