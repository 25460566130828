import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import TimePicker from './TimePicker';
import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';
import images from '../../../Utils/images';

const SetTimeComponent = ({ object, setObject, setSelectedItem, setStateView }) => {
  const OkToProceed = object.name && object.startTime && object.endTime && object.days.length > 0;

  
  const donotshowagainTFaction = () => {
    localStorage.setItem('doNotShowAgainTF','true');
  }

  return (
    <>
      <div className="AccountDetailsHeader Row">
        <div className="flex" style={{ flexDirection: 'column' }}>
          <h1 style={{ fontWeight: '700', fontSize: '18px' }}>Select Time Frame</h1>
          <p style={{ color: '#666666', fontSize: '12px', marginTop: '5px', textWrap: 'nowrap' }}>
            If a message is received in between the selected time frame. Trigger an action
          </p>
        </div>

        <CheerioButton
          btnText="Cancel"
          borderStyle={{}}
          backColor={colors.black}
          textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
          onclick={() => {
            setStateView('list');
          }}
        />
      </div>
      <div className={'ADScrollable verythinscrollnew Column'}>
        <div
          className="d-flex flex-column justify-content-start align-items-start w-100"
          style={{ paddingInline: 50, marginBlock: 20 }}
        >
          {!(localStorage.getItem('doNotShowAgainTF') === 'true') && 
            <>
            <div className='d-flex flex-row justify-content-between align-items-start w-100 my-2 py-3 px-3' 
              style={{backgroundColor:colors.primary01,borderRadius:16}}
            >
              <img src={images.PurpleInfoIcon} style={{height:20,width:20,objectFit:'contain',marginBlockStart:2}}></img>
              <div className='d-flex flex-column justify-content-start align-items-center px-2' style={{flex:1}}>
                <p style={{width:'100%',color:colors.primary03,marginBlockEnd:16}}>
                  {'Time trigger considers a day from 12:00AM (Night) to 11:59PM(Night). Please make sure that you select a time frame accordingly'}
                </p>
                <p style={{width:'100%',color:colors.primary03}}>
                  {'For Eg: If you want to set a time trigger for (10:00PM to 07:00AM) you have to set two different time triggers like  (10:00PM-11:59PM) and (12:00AM - 07:00AM).'}
                </p>
              </div>
              <img src={images.CMClose} style={{height:20,width:20,objectFit:'contain',cursor:'pointer'}}
                onClick={()=>{donotshowagainTFaction()}}
              ></img>
            </div>
            </>
          }
          
          <div
            className="d-flex flex-column justify-content-start align-items-center"
            style={{ width: '40%' }}
          >
            <InputItems
              name="name"
              title="Name Trigger"
              type="text"
              placeholder="Enter name here"
              value={object.name}
              onChange={setObject}
            />
            <TimePicker object={object} setObject={setObject} />
          </div>
        </div>
        <div
          className="d-flex flex-row justify-content-start align-items-center w-100"
          style={{ paddingInline: 50 }}
        >
          <CheerioButton
            borderStyle={{ borderColor: colors.primary03, marginBlock: 16 }}
            textStyle={{ color: colors.white }}
            btnText="Next"
            backColor={colors.primary03}
            disabled={!OkToProceed}
            onclick={() => {
              setSelectedItem('Reply Actions');
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SetTimeComponent;

const InputItems = ({ name, title, type, placeholder, onChange, value }) => {
  return (
    <>
      <InputGroup className="" style={{ width: '100%', marginBlock: 4 }}>
        <Form.Label style={{ fontSize: 16, fontWeight: 700, color: colors.black, marginBlock: 4 }}>
          {title}
        </Form.Label>
        <Form.Control
          type={type}
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black }}
          value={value}
          onChange={(e) => {
            onChange((p) => ({ ...p, [name]: e.target.value }));
          }}
        />
      </InputGroup>
    </>
  );
};
