import React, { useState, useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import colors from '../../../Utils/colors';
import { useSelector } from 'react-redux';
import { getShopifyCampaignAnalytics, getShopifyCampaignList } from '../../../Services';
import { toast } from 'react-toastify';
import { styledSx } from '../../ContactModule/Tags/variableDependencies';
import { DataGrid } from '@mui/x-data-grid';
import { uuid4 } from '@sentry/utils';
import { eventsend } from '../../../Config/analyticsFunctions';

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'mobile',
    headerName: 'Phone no.',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'send',
    headerName: 'Sent',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'deliveryAt',
    headerName: 'Delivered',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'read',
    headerName: 'Read',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'click',
    headerName: 'Link Clicked',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
];

export const DetailUserAnalytics = () => {
  // const [list, setList] = useState(null);
  const [ananlyticsData, setAnalyticsData] = useState();
  const authtoken = useSelector((state) => state.main.auth_token);
  const [hookLoc, setHookLoc] = useState();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  let row_data = [];

  useEffect(() => {
    // const campaignListApiCall = ()=>{
    //     getShopifyCampaignList(authtoken).then((res)=>{
    //         if(res?.flag){
    //             setList(res?.data);
    //         }else{
    //             toast.error(res,{position:"top-center"});
    //         }
    //     })
    // }
    // campaignListApiCall();
    eventsend("Analytics__Shopify_integration_page_clicked");
  }, [])

  const getShopifyCampaignAnalyticsApiCall = (data) => {
    getShopifyCampaignAnalytics(data, authtoken).then((res) => {
      if (res?.flag) {
        setIsLoading(false);
        setAnalyticsData(res?.data);
      } else {
        toast.error(res, { position: 'top-center' });
      }
    });
  };

  const handleChange = (e) => {
    setIsLoading(true);
    const data = {
      hookloc: e.target.value,
    };
    getShopifyCampaignAnalyticsApiCall(data);
    setHookLoc(e.target.value);
  };

  if (ananlyticsData) {
    ananlyticsData?.campaignAnalysis.forEach((vls) => {
      row_data.push({
        id: uuid4(),
        name: vls.name,
        mobile: vls.mobile,
        send:
          vls.send !== ''
            ? new Date(vls.send).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
            : '---',
        deliveryAt:
          vls.deliveryAt !== ''
            ? new Date(vls.deliveryAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
            : '---',
        read:
          vls.read !== ''
            ? new Date(vls.read).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
            : '---',
        click:
          vls.click !== ''
            ? new Date(vls.click).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
            : '---',
      });
    });
  }

  useEffect(() => {
    if (hookLoc) {
      setIsLoading(true);
      const data = {
        hookloc: hookLoc,
        page: paginationModel.page + 1,
      };
      getShopifyCampaignAnalyticsApiCall(data);
    }
  }, [paginationModel]);

  return (
    <div
      style={{
        border: '1px solid #CCCCCC',
        margin: '10px',
        borderRadius: '12px',
        backgroundColor: 'white',
      }}
    >
      <div className="d-flex justify-content-between" style={{ padding: '20px' }}>
        <h3 style={{ color: colors.black }}>Detailed User Analytics</h3>
        <InputGroup
          className="btncustom"
          style={{ width: '40%', marginInlineStart: 8, height: '100%' }}
        >
          <InputGroup.Text
            id="basic-addon1"
            style={{ fontSize: 12, fontWeight: 600, color: colors.black, cursor: 'default' }}
          >
            {'CAMPAIGN'}
          </InputGroup.Text>
          <Form.Select
            className="btncustom"
            aria-label="select campaign"
            style={{ fontSize: 12, fontWeight: 600, color: colors.greys04, cursor: 'pointer' }}
            onChange={(e) => {
              handleChange(e);
            }}
          >
            {/* <option key="blankchoice" hidden value>
                  {'Select Campaign'}
                </option>
                {list && list.map((value)=>(
                     <option key={value?._id} value={value?._id}>{value?.announcement?.template_name}</option>
                ))} */}
            <option key="blankchoice" hidden value>
              {'Select Campaign'}
            </option>
            <option value={'order_confirm'} key={'1'}>
              Orders placed
            </option>
            <option value={'order_fulfill'} key={'2'}>
              Orders fulfilled
            </option>
            <option value={'order_cancelled'} key={'3'}>
              Orders cancelled
            </option>
            <option value={'order_feedback'} key={'4'}>
              Orders feedback
            </option>
            <option value={'abandoned_cart'} key={'5'}>
              Abandoned cart
            </option>
            <option value={'order_cod'} key={'6'}>
              COD order
            </option>
            {/* <option value={'abandoned_cart'} key={'5'}>Abandoned cart</option> */}
          </Form.Select>
        </InputGroup>
      </div>
      <div style={{ padding: '20px' }}>
        {row_data.length > 0 ? (
          <DataGrid
            loading={isLoading}
            disableColumnMenu={true}
            sx={styledSx}
            columns={columns}
            rows={row_data}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            paginationMode="server"
            rowCount={ananlyticsData?.paginateDetails?.totalRecords}
          />
        ) : (
          <div
            className="d-flex justify-content-center"
            style={{ fontWeight: 500, marginBottom: '50px' }}
          >
            {hookLoc ? 'No data found' : 'Please select a campaign.'}
          </div>
        )}
      </div>
    </div>
  );
};
