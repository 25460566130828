import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import CheerioButton from '../../../Components/CheerioButton';
import { getImagePath } from '../../../Utils';
import { useEffect } from 'react';

const mobileWidth = window.innerWidth < 900 ? true : false;

export const CampaignNameInput = ({ campaignName, setCampaignName }) => {
  return (
    <InputGroup className="" style={{ width: '90%', marginBlock: 16 }}>
      <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black, marginBlock: 4 }}>
        {'Name your Campaign'}
      </Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter Campaign Name here"
        className="w-100 btncustom"
        style={{ borderRadius: 8 }}
        value={campaignName}
        onChange={(e) => {
          setCampaignName(e.target.value);
        }}
      />
    </InputGroup>
  );
};

export const SpinnerComponent = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center w-100"
      style={{ flex: 1 }}
    >
      <SpinnerLoader />
    </div>
  );
};

export const TagsInput = ({
  tagsList,
  onclickaddselected,
  selectedTagsList,
  onclickremoveselected,
}) => {
  return (
    <>
      <div style={{ width: '90%', marginBlock: 16 }}>
        <h4
          style={{
            padding: 0,
            margin: 0,
            fontSize: 16,
            fontWeight: 600,
            color: colors.black,
            marginBlock: 4,
          }}
        >
          {'Enter Tags'}
        </h4>
        <div
          className="btncustom d-flex flex-row justify-content-start align-items-center px-2 py-2"
          // style={{minHeight:46,width:'100%',border:`1px solid ${colors.greys03}`,borderRadius:8,marginBlock:4,overflowX:'auto',overflowY:'hidden',whiteSpace:'nowrap'}}>
          style={{
            minHeight: 46,
            width: '100%',
            border: `1px solid ${colors.greys03}`,
            borderRadius: 8,
            marginBlock: 4,
            flexWrap: 'wrap',
          }}
        >
          {selectedTagsList &&
            selectedTagsList.map((item) => {
              return (
                <>
                  <div
                    key={`key${item._id}`}
                    className="d-flex flex-row justify-content-between align-items-center"
                    style={{
                      paddingInline: 16,
                      paddingBlock: 4,
                      borderRadius: 4,
                      backgroundColor: colors.borderwhite,
                      marginInlineEnd: 16,
                      marginBlockEnd: 6,
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.greys04,
                      }}
                    >
                      {item?.tag_name}
                    </p>
                    <img
                      src={images.CMClose}
                      style={{
                        height: 14,
                        width: 14,
                        objectFit: 'contain',
                        marginInlineStart: 8,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        onclickremoveselected(item);
                      }}
                    />
                  </div>
                </>
              );
            })}
        </div>
        <div
          className="d-flex flex-row justify-content-start align-items-center w-100"
          style={{ flexWrap: 'wrap' }}
        >
          {tagsList &&
            tagsList.map((item) => {
              return (
                <>
                  <div
                    key={item._id}
                    className="d-flex flex-row justify-content-center align-items-center"
                    style={{
                      paddingInline: 16,
                      paddingBlock: 6,
                      borderRadius: 4,
                      backgroundColor: colors.borderwhite,
                      marginInlineEnd: 16,
                      marginBlock: 6,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      onclickaddselected(item);
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 12,
                        fontWeight: 400,
                        color: colors.greys04,
                      }}
                    >
                      {item?.tag_name}
                    </p>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export const AnnTemplateItem = ({ item, onclickAction, final = false }) => {
  const disableButton = final || item.status !== 'APPROVED';
  const BodyComponent = item.components.filter((e) => e?.type === 'BODY');
  // const Icon = item.name === 'customer_enquiry' ? images.CAFollowUp :
  //     item.name === 'renewal_remainder' ? images.CARemainder :
  //     item.name === 'thank_you_customer' ? images.CAThankYou :
  //     item.name === 'share_info' ? images.CAShare :
  //     item.name === 'additional_discount_offer' ? images.CAAddDiscount :
  //     item.name === 'repeatcustomer_offer' ? images.CARCOffer :
  //     item.name === 'customer_win_back' ? images.CAWinback :
  //     item.name === 'business_info_newsletter' ? images.CABINews :
  //     item.name === 'welcome_insta_message_2' ? images.CAWelcomeInsta :
  //     item.name === 'loyal_customers_new' ? images.CALoyalCustomer :
  //     item.name === 'discount_all_products_new' ? images.CAThankYou2 :
  //     item.name === 'do_you_know' ? images.CADoYouKnow :
  //     item.name === 'maintainance_update' ? images.CAMaintenance :
  //     item.name === 'new_product_service_marathi' ? images.CAServiceMarathi :
  //     item.name === 'reply_multiple' ? images.CAMultiple :
  //     item.name === 'renewal_reminder' ? images.CARemainder : images.CADefault;

  return (
    <>
      <div className="ann_template_v2_item_container" key={item.id}>
        {/* <div className="" style={{ width: '50%', padding: 10 }} key={item.id}> */}
        <div
          className="ann_template_v2_item"
        // className="d-flex flex-column justify-content-between align-items-center w-100 hovercustom"
        // style={{
        //   height: 220,
        //   border: `1px solid ${colors.borderwhite}`,
        //   borderRadius: 16,
        //   paddingInline: 20,
        //   paddingBlock: 16,
        //   backgroundColor: colors.white02,
        // }}
        >
          {/* <div className="w-100" style={{ flex: 1, overflowY: 'hidden' }}> */}
          <div
            className="ann_template_v2_item__row1"
          // className="d-flex flex-row justify-content-between align-items-center w-100 h-100"
          // style={{ flex: 1 }}
          >
            <img
              src={images.CATemplate}
            // style={{ height: 100, width: 100, objectFit: 'contain' }}
            ></img>
            <div
              className="text_container"
            // className="d-flex flex-column justify-content-start align-items-start h-100"
            // style={{ flex: 1, paddingInlineStart: 16 }}
            >
              <p
                className="header_text"
              // style={{
              //   margin: 0,
              //   padding: 0,
              //   fontSize: 16,
              //   fontWeight: 600,
              //   color: colors.black,
              //   marginBlock: 4,
              // }}
              >
                {item.name}
              </p>
              <div className="d-flex flex-row justify-content-start align-items-center w-100">
                <img
                  src={images.WhatsApp}
                  style={{ height: 20, width: 20, objectFit: 'contain', marginBlock: 4 }}
                ></img>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    marginInline: 10,
                    paddingInline: 12,
                    paddingBlock: 4,
                    borderRadius: 6,
                    backgroundColor:
                      item.status === 'APPROVED'
                        ? '#CFFFCE'
                        : item.status === 'PENDING'
                          ? '#FFF5C6'
                          : '#FFB1A3',
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 12,
                      fontWeight: 400,
                      color:
                        item.status === 'APPROVED'
                          ? colors.success03
                          : item.status === 'PENDING'
                            ? colors.textChrome
                            : colors.error04,
                    }}
                  >
                    {item.status}
                  </p>
                </div>
              </div>

              <p
              // style={{
              //   padding: 0,
              //   margin: 0,
              //   fontSize: 14,
              //   fontWeight: 400,
              //   color: colors.black,
              //   width: '100%',
              //   whiteSpace: 'pre-line',
              //   maxHeight: '5.2rem',
              //   overflow: 'hidden',
              //   textOverflow: 'ellipsis',
              // }}
              >
                {`${BodyComponent[0].text}`}
              </p>
            </div>
          </div>
          {/* </div> */}

          <div className="ann_template_v2_item__row2">
            {/* <div className="d-flex flex-row justify-content-end align-items-center w-100"> */}
            {/* <CheerioButton 
                        borderStyle={{borderColor:colors.black,width:'48%'}}
                        textStyle={{fontSize:12,fontWeight:400,color:colors.black}}
                        btnText={'See Preview'}
                        backColor={colors.white01}
                        icon={images.CANor}
                        onclick={()=>{
                          console.log('see preview clicked');
                        }}
                    /> */}
            <CheerioButton
              borderStyle={{ borderColor: colors.primary03, width: mobileWidth ? '100%' : '48%' }}
              textStyle={{ fontSize: 12, fontWeight: 400, color: colors.white }}
              btnText={'Use Template'}
              backColor={colors.primary03}
              // icon={images.CANor}
              onclick={() => {
                console.log('use template clicked');
                onclickAction(item);
              }}
              disabled={disableButton ? true : false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const WAV2Preview = ({
  BodyElement,
  upimage,
  ButtonList,
  uptype,
  HeaderInputText,
  CarouselData,
  CarouselInput,
  upFile,
  HeadElement,
  HeaderOption
}) => {
  console.log("preview", HeaderOption)
  return (
    <>
      <div
        className="w-100 d-flex flex-column justify-content-start"
        style={{
          // overflowX: 'hidden',
          height: '100%',
          alignItems: CarouselData ? 'flex-start' : 'center',
        }}
      >
        <div
          className="d-flex flex-column justify-content-start align-items-center"
          style={{
            // width: '70%',
            width: 260,
            borderRadius: 10,
            padding: 16,
            backgroundColor: '#1F2C34',
            position: 'relative',
          }}
        >
          <img
            src={images.CreatorWA}
            style={{
              height: 32,
              width: 32,
              objectFit: 'contain',
              position: 'absolute',
              top: -14,
              left: -14,
            }}
          ></img>
          {uptype === 'TEXT' ? (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 16,
                  fontWeight: 700,
                  color: colors.white,
                  width: '100%',
                  marginBlockStart: 8,
                }}
              >
                {HeaderInputText ? HeaderInputText : ''}
              </p>
            </>
          ) : HeadElement[0] && HeadElement[0]?.format !== 'TEXT' ? (
            <>
              <div
                className=""
                style={{
                  minHeight: '100px',
                  width: '100%',
                  backgroundColor: colors.borderwhite,
                  borderRadius: 8,
                }}
              >
                {
                  HeaderOption === 'fetchShopify' &&
                  <img
                    src={images.ShopifyDisplayImage}
                    style={{
                      height: '100%',
                      width: '100%',
                      borderRadius: 8,
                      objectFit: 'cover',
                    }}
                  />
                }
                {
                  HeaderOption === 'fetchWoo' &&
                  <img
                    src={images.WoocommerceDisplayImage}
                    style={{
                      height: '100%',
                      width: '100%',
                      borderRadius: 8,
                      objectFit: 'cover',
                    }}
                  />
                }
                {upimage && (
                  <>
                    {uptype === 'VIDEO' ? (
                      <>
                        <video
                          controls
                          src={getImagePath(upimage)}
                          style={{
                            height: '100%',
                            width: '100%',
                            borderRadius: 8,
                            objectFit: 'cover',
                          }}
                        />
                      </>
                    ) : uptype === 'IMAGE' ? (
                      <>
                        {HeaderOption === 'uploadShopify' &&
                          <img
                            src={getImagePath(upimage)}
                            style={{
                              height: '100%',
                              width: '100%',
                              borderRadius: 8,
                              objectFit: 'cover',
                            }}
                          />}
                      </>
                    ) : (
                      <>
                        {upFile ? (
                          <>
                            <embed
                              src={URL.createObjectURL(upFile)}
                              width="100%"
                              height={'100%'}
                            ></embed>
                          </>
                        ) : (
                          <>
                            <img
                              src={images.CATREmail}
                              style={{
                                height: '100%',
                                width: '100%',
                                borderRadius: 8,
                                objectFit: 'cover',
                              }}
                            />
                          </>
                        )}

                        {/* <img 
                    src={URL.createObjectURL(upFile)}
                    style={{ height: '100%', width: '100%', borderRadius: 8, objectFit: 'cover' }}
                  >
                  </img> */}
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )}

          <p
            style={{
              padding: 0,
              margin: 0,
              fontSize: 14,
              fontWeight: 400,
              color: colors.white,
              width: '100%',
              marginBlockStart: 8,
              whiteSpace: 'pre-line',
            }}
          >
            {BodyElement.replace(/\\+n/g, '\n')}
            {/* {BodyElement} */}
          </p>
        </div>
        {ButtonList &&
          ButtonList.map((item, index) => {
            return (
              <>
                <div
                  className="d-flex flex-row justify-content-center align-items-center"
                  style={{
                    // width: '70%',
                    width: 260,
                    borderRadius: 10,
                    paddingBlock: 8,
                    backgroundColor: '#1F2C34',
                    marginBlock: 4,
                  }}
                  key={index}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 14,
                      fontWeight: 400,
                      color: colors.white,
                    }}
                  >
                    {item.text}
                  </p>
                </div>
              </>
            );
          })}

        {/* carousel */}
        {CarouselData && (
          <>
            <div
              className="d-flex flex-row justify-content-start align-items-stretch my-2"
              style={{ width: 'fit-content', overflowX: 'auto' }}
            >
              {CarouselData.map((item, index) => {
                const cHeader = item?.components?.filter((e) => e?.type === 'HEADER');
                const cBody = item?.components?.filter((e) => e?.type === 'BODY');
                const cButton = item?.components?.filter((e) => e?.type === 'BUTTONS');
                let newtext = cBody[0]?.text;
                let count = 0;
                let newArr = CarouselInput[index]?.bodyParameters.map((a) => a);
                if (CarouselInput[index]?.bodyParameters.length > 0) {
                  const variableRegex = /\{+\d+\}+/g;
                  newtext = newtext.replace(variableRegex, function ($0) {
                    if (count === newArr?.length) count = 0;
                    return newArr[count++];
                  });
                }
                return (
                  <>
                    <div
                      className="me-2 d-flex flex-column justify-content-start"
                      style={{ width: 260 }}
                    >
                      <div
                        className="d-flex flex-column justify-content-start align-items-center"
                        style={{
                          width: '100%',
                          borderRadius: 10,
                          padding: 16,
                          backgroundColor: '#1F2C34',
                          flex: 1,
                          // whiteSpace:'pre-wrap',
                          // position: 'relative',
                        }}
                      >
                        <div
                          className=""
                          style={{
                            // minHeight: '100px',
                            height: '100px',
                            width: '100%',
                            backgroundColor: colors.borderwhite,
                            borderRadius: 8,
                          }}
                        >
                          {CarouselInput[index]?.header?.link ? (
                            <>
                              {cHeader[0]?.format === 'VIDEO' ? (
                                <>
                                  <video
                                    controls
                                    src={getImagePath(CarouselInput[index]?.header?.link)}
                                    style={{
                                      height: '100%',
                                      width: '100%',
                                      borderRadius: 8,
                                      objectFit: 'cover',
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={getImagePath(CarouselInput[index]?.header?.link)}
                                    style={{
                                      height: '100%',
                                      width: '100%',
                                      borderRadius: 8,
                                      objectFit: 'cover',
                                    }}
                                  />
                                </>
                              )}
                            </>
                          ) : (
                            cHeader[0]?.example?.header_handle && (
                              <>
                                {cHeader[0]?.format === 'VIDEO' ? (
                                  <>
                                    <video
                                      controls
                                      src={cHeader[0]?.example?.header_handle}
                                      style={{
                                        height: '100%',
                                        width: '100%',
                                        borderRadius: 8,
                                        objectFit: 'cover',
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={cHeader[0]?.example?.header_handle}
                                      style={{
                                        height: '100%',
                                        width: '100%',
                                        borderRadius: 8,
                                        objectFit: 'cover',
                                      }}
                                    />
                                  </>
                                )}
                              </>
                            )
                          )}
                        </div>

                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            fontSize: 14,
                            fontWeight: 400,
                            color: colors.white,
                            width: '100%',
                            marginBlockStart: 8,
                          }}
                        >
                          {newtext}
                          {/* {BodyElement[0].text} */}
                        </p>
                      </div>
                      {cButton &&
                        cButton[0]?.buttons.map((item, index) => {
                          return (
                            <>
                              <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{
                                  width: '100%',
                                  borderRadius: 10,
                                  paddingBlock: 8,
                                  backgroundColor: '#1F2C34',
                                  marginBlock: 4,
                                }}
                                key={index}
                              >
                                <p
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: colors.white,
                                  }}
                                >
                                  {item.text}
                                </p>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const SmsTemplateItem = ({ item, onclickAction, final = false }) => {
  const disableButton = final || !item.IsApproved;
  return (
    <>
      <div className="ann_template_v2_item_container" key={item.TemplateId}>
        <div className="ann_template_v2_item">
          <div className="ann_template_v2_item__row1">
            <img src={images.CATemplate}></img>
            <div className="text_container">
              <p className="header_text">{item.TemplateName}</p>
              <div className="d-flex flex-row justify-content-start align-items-center w-100">
                {/* <img
                  src={images.WhatsApp}
                  style={{ height: 20, width: 20, objectFit: 'contain', marginBlock: 4 }}
                ></img> */}
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    marginInline: 10,
                    paddingInline: 12,
                    paddingBlock: 4,
                    borderRadius: 6,
                    backgroundColor:
                      item.IsApproved === true
                        ? '#CFFFCE'
                        : item.IsApproved === false
                          ? '#FFB1A3'
                          : '#FFB1A3',
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 12,
                      fontWeight: 400,
                      color:
                        item.IsApproved === true
                          ? colors.success03
                          : item.IsApproved === false
                            ? colors.error04
                            : colors.error04,
                    }}
                  >
                    {item.IsApproved ? 'APPROVED' : 'PENDING'}
                  </p>
                </div>
              </div>

              <p
              // style={{
              //   padding: 0,
              //   margin: 0,
              //   fontSize: 14,
              //   fontWeight: 400,
              //   color: colors.black,
              //   width: '100%',
              //   whiteSpace: 'pre-line',
              //   maxHeight: '5.2rem',
              //   overflow: 'hidden',
              //   textOverflow: 'ellipsis',
              // }}
              >
                {`${item?.MessageTemplate}`}
              </p>
            </div>
          </div>

          <div className="ann_template_v2_item__row2">
            <CheerioButton
              borderStyle={{ borderColor: colors.primary03, width: mobileWidth ? '100%' : '48%' }}
              textStyle={{ fontSize: 12, fontWeight: 400, color: colors.white }}
              btnText={'Use Template'}
              backColor={colors.primary03}
              onclick={() => {
                console.log('use template clicked');
                onclickAction(item);
              }}
              disabled={disableButton ? true : false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const SMSPreview = ({ BodyElement }) => {
  return (
    <>
      <div
        className="w-100 d-flex flex-column justify-content-start"
        style={{
          // overflowX: 'hidden',
          alignItems: 'center',
        }}
      >
        <div
          className="d-flex flex-column justify-content-start align-items-center"
          style={{
            // width: '70%',
            width: 260,
            borderRadius: 10,
            padding: 16,
            backgroundColor: '#1F2C34',
            position: 'relative',
          }}
        >
          <p
            style={{
              padding: 0,
              margin: 0,
              fontSize: 14,
              fontWeight: 400,
              color: colors.white,
              width: '100%',
              marginBlockStart: 8,
              whiteSpace: 'pre-line',
            }}
          >
            {BodyElement.replace(/\\+n/g, '\n')}
            {/* {BodyElement} */}
          </p>
        </div>
        <p style={{ fontSize: '9px', marginTop: '10px', fontWeight: '600' }}>
          <i>
            {' '}
            Please Note:-Total message length should not be more than 160chars
            {'(including variables).'}
          </i>
        </p>
      </div>
    </>
  );
};
