import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { BiLogoShopify } from 'react-icons/bi';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { FaShopify, FaWhatsapp } from 'react-icons/fa';
import { HiArrowLongDown } from 'react-icons/hi2';
import { Form, InputGroup } from 'react-bootstrap';
import ShopifyTemplateFlow from './ShopifyTemplateFlow';
import { useNavigate } from 'react-router-dom';
import CheerioButton from '../../../Components/CheerioButton';
import { getShopifyDetailsApi, saveCodPrepaid } from '../../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const FlowComponent = ({ data }) => {
  const [expiry, setExpiry] = useState(data?.expiry ?? null);
  const [expiryOnFullfill, setExpiryOnFullfill] = useState(data?.expiryOnFullfill ?? false);
  const authtoken = useSelector((state) => state.main.auth_token);

  useEffect(() => {
    if (data?.expiry) {
      setExpiry(data?.expiry);
    }
    if (data?.expiryOnFullfill) {
      setExpiryOnFullfill(data?.expiryOnFullfill);
    }
  }, [data]);

  const saveCodPrepaidApiCall = () => {
    const data = {
      expiry,
      expiryOnFullfill,
    };
    saveCodPrepaid(authtoken, data).then((res) => {
      if (res?.flag) {
        toast.success('Data saved', { position: 'top-center' });
      } else {
        toast.error(res);
      }
    });
  };
  return (
    <div
      style={{
        marginBlock: '15px',
        marginInline: '20px',
        borderRadius: '10px',
        paddingBlock: '15px',
        paddingInline: '15px',
        backgroundColor: '#FFFFFF',
      }}
    >
      <p style={{ fontWeight: '700' }}>Few things to know about this flow</p>
      <ul style={{ color: '#666666' }}>
        <li>
          When a customer is in COD to Prepaid flow, all automations will be paused for this
          contact. It will be resumed as soon as the customer complete or abort a flow.
        </li>
        <li>
          While setting up the COD to Prepaid flow, you’ll need to set an expiry period on the
          pre-payment option. Doing so will help prevent situations where customers pre-pay for
          orders after they’ve been shipped. For the expiry period, it is recommended to set the
          minimum time it would take for the order to be shipped.
        </li>
      </ul>
      <div class="form-group mt-4">
        <label
          for="exampleFormControlInput1"
          style={{ fontWeight: '700', fontSize: '14px', color: 'black', marginBlockEnd: '10px' }}
        >
          Trigger
        </label>
        <input class="form-control shadow-none" value={'COD order create'} disabled={true} />
      </div>
      <InputGroup className="mb-3" style={{ marginBlock: 16 }}>
        <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
          {'Expire After'}
        </Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter hour"
          className="w-100 btncustom dropdown"
          style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
          value={expiry}
          min={1}
          max={24}
          onChange={(e) => {
            setExpiry(e.target.value);
          }}
        ></Form.Control>
      </InputGroup>
      <div className="d-flex align-items-center" style={{ gap: '8px' }}>
        <input
          type="checkbox"
          checked={expiryOnFullfill}
          onChange={() => {
            setExpiryOnFullfill(!expiryOnFullfill);
          }}
          style={{
            width: '16px',
            height: '16px',
          }}
        />
        <p style={{ fontSize: '16px' }}>Disable flow if order is already fulfilled</p>
      </div>
      <div className="d-flex justify-content-end px-3" style={{ marginBlockEnd: '2%' }}>
        <CheerioButton
          btnText={'Save'}
          textStyle={{ color: colors.white }}
          backColor={colors.black}
          borderStyle={{
            borderWidth: 0,
            width: '30%',
            borderRadius: '8px',
          }}
          onclick={() => {
            saveCodPrepaidApiCall();
          }}
        />
      </div>
    </div>
  );
};

const RowItem = ({ itemName, arrow = true, shopifyIcon, action, setAction }) => {
  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center"
        onClick={() => setAction(itemName)}
        style={{
          width: '100%',
          padding: '10px',
          borderRadius: '10px',
          cursor: 'pointer',
          backgroundColor: action === itemName ? '#ECE1FF' : '#FAFAFA',
        }}
      >
        <div className="d-flex  align-items-center " style={{ gap: '10px' }}>
          {shopifyIcon ? (
            <FaShopify size={'18px'} style={{ color: action === itemName ? '#8E53F7' : 'black' }} />
          ) : (
            <FaWhatsapp
              style={{ color: action === itemName ? '#8E53F7' : 'black' }}
              size={'18px'}
            />
          )}
          <p style={{ fontSize: '16px', color: action === itemName ? '#8E53F7' : 'black' }}>
            {itemName}
          </p>
        </div>
        <MdKeyboardArrowRight
          size={18}
          style={{ color: action === itemName ? '#8E53F7' : 'black' }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '40px',
        }}
      >
        {arrow && (
          <HiArrowLongDown
            color="#666666"
            size={16}
            style={{
              fontWeight: 400,
              transform: 'scale(1.3, 3)',
              transformOrigin: 'center',
            }}
          />
        )}
      </div>
    </>
  );
};

export const CodPrepaidFlow = () => {
  const [action, setAction] = useState('Trigger: COD order create');
  const navigate = useNavigate();
  const authtoken = useSelector((state) => state.main.auth_token);

  const [shopifyDetails, setShopifyDetails] = useState(null);

  const getShopifyDetailsAPIcall = () => {
    let token = authtoken;
    getShopifyDetailsApi(token).then((res) => {
      if (res?.flag) {
        setShopifyDetails(res?.data?.result);
      } else {
        toast.error(res?.message, { position: 'top-center' });
      }
    });
  };

  useEffect(() => {
    getShopifyDetailsAPIcall();
  }, [action]);

  function getExistingData(topicName) {
    const existingData = shopifyDetails?.nodeData?.filter((item) => item?.topic === topicName);
    return existingData ? existingData[0]?.data : null;
  }

  return (
    <div className="d-flex h-100 w-100">
      <div
        className="d-flex flex-column justify-content-start align-items-center h-100"
        style={{
          width: '25%',
          backgroundColor: colors.white,
          border: `1px solid ${colors.borderwhite}`,
        }}
      >
        <div
          className="d-flex"
          style={{
            width: '100%',
            padding: '12px',
            gap: '10px',
            borderBlockEnd: `1px solid ${colors.borderwhite}`,
          }}
        >
          <img
            src={images.BackArrowBlack}
            style={{
              height: 22,
              width: 22,
              objectFit: 'contain',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/creator/manageIntegrations/shopify/Flows');
            }}
          ></img>
          <p style={{ fontSize: '16px', fontWeight: '700' }}>COD to Prepaid</p>
        </div>
        <div style={{ paddingBlock: '10px', paddingInline: '15px', width: '100%' }}>
          <RowItem
            itemName={'Trigger: COD order create'}
            shopifyIcon={true}
            action={action}
            setAction={setAction}
          ></RowItem>
          <RowItem
            itemName={'Order Confirmation'}
            shopifyIcon={false}
            action={action}
            setAction={setAction}
          ></RowItem>
          <RowItem
            itemName={'COD to Prepaid'}
            shopifyIcon={false}
            action={action}
            setAction={setAction}
          ></RowItem>
          <RowItem
            itemName={'Share payment link'}
            shopifyIcon={false}
            action={action}
            setAction={setAction}
          ></RowItem>
          <RowItem
            itemName={'New order placed'}
            arrow={false}
            shopifyIcon={false}
            action={action}
            setAction={setAction}
          ></RowItem>
        </div>
      </div>
      <div style={{ width: '75%' }}>
        <p
          style={{
            padding: '12px',
            fontWeight: '700',
            width: '100%',
            borderBlockEnd: `1px solid ${colors.borderwhite}`,
          }}
        >
          Edit Node Details
        </p>
        {action === 'Trigger: COD order create' && (
          <FlowComponent data={shopifyDetails?.CODtoPrepaidData}></FlowComponent>
        )}{' '}
        {action === 'Order Confirmation' && (
          <ShopifyTemplateFlow
            action={action}
            data={getExistingData('Order Confirmation')}
          ></ShopifyTemplateFlow>
        )}{' '}
        {action === 'COD to Prepaid' && (
          <ShopifyTemplateFlow action={action} data={getExistingData(action)}></ShopifyTemplateFlow>
        )}{' '}
        {action === 'Share payment link' && (
          <ShopifyTemplateFlow action={action} data={getExistingData(action)}>
            {' '}
          </ShopifyTemplateFlow>
        )}{' '}
        {action === 'New order placed' && (
          <ShopifyTemplateFlow action={action} data={getExistingData(action)}></ShopifyTemplateFlow>
        )}{' '}
      </div>
    </div>
  );
};
