import React, { useState } from 'react';
import './index.scss';
import { LiaTrashAltSolid } from 'react-icons/lia';
import { AiOutlineSearch } from 'react-icons/ai';
import { getRandomColor } from '../variableDependencies';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { fetchContactLabelList } from '../../../../Services';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material';

function LabelInput({ value, onChecked }) {
  const [currentTag, setCurrentTag] = useState('');
  const [tags, setTags] = useState(new Set(value));

  const handleInputChange = (e) => {
    setCurrentTag(e.target.value);
  };

  const authtoken = useSelector((state) => state.main.auth_token);
  const { data, isLoading, isError } = useQuery(['fetchContactLabelList'], () =>
    fetchContactLabelList(authtoken)
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }
  let allLabels = [
    ...data.data.v2.map((vls) => {
      return { label: vls.label_name };
    })
  ];

  const handleKeyPress = (e) => {
    if (e.key === ',') {
      e.preventDefault();
      const trimmedTag = currentTag.trim();
      if (trimmedTag !== '' && !tags.has(trimmedTag)) {
        const newTags = new Set(tags);
        newTags.add(trimmedTag);
        onChecked(newTags);
        setTags(newTags);
        setCurrentTag('');
      }
    }
  };
  const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // transform: "translate(34px, 20px) scale(1);"
    },
    "&.Mui-focused .MuiInputLabel-outlined": {
      color: "grey"
    },
    "& .MuiAutocomplete-inputRoot": {
      color: "grey",
      height: "40px",
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type': {
        height: "10px"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        border: "1px solid var(--border-color-border-medium, #CCC)"
      },
      // "&:hover .MuiOutlinedInput-notchedOutline": {
      //   borderColor: "red"
      // },
      // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      //   borderColor: "purple"
      // }
    }
  });
  return (
    <div className={'commaSeperator'}>
      {allLabels.length &&
        <StyledAutocomplete
          disablePortal
          id="combo-box-demo"
          style={{
            marginTop: '5px',
          }}
          renderInput={(params) => (
            <TextField
              size={'small'}
              placeholder={'Search label.'}
              {...params}
            />
          )}
          freeSolo={true}
          options={allLabels}
          onChange={(event, newValue) => {
            if (newValue?.label === undefined) {
              const trimmedValue = (newValue || '').trim();
              if (trimmedValue !== '' && !tags.has(trimmedValue)) {
                const newTags = new Set(tags);
                newTags.add(trimmedValue);
                onChecked(newTags);
                setTags(newTags);
              }
            } else {
              if (newValue.label !== '' && !tags.has(newValue.label)) {
                const newTags = new Set(tags);
                newTags.add(newValue.label);
                onChecked(newTags);
                setTags(newTags);
              }
            }
          }}
        />
      }
      <div className="tags-container">
        {[...tags].map((tag, index) => {
          const getColor = getRandomColor();
          return (
            <div
              key={index}
              className="tag-boxs"
              style={{
                color: getColor,
                borderColor: getColor,
              }}
            >
              <span>{tag}</span>
              <LiaTrashAltSolid
                onClick={() => {
                  setTags((tags) => {
                    let prevTags = new Set(tags);
                    prevTags.delete(tag);
                    onChecked(prevTags);
                    return prevTags;
                  });
                }}
              />
            </div>
          );
        })}
      </div>
      <p className={'tag-header'}>Create new labels</p>
      <input
        type="text"
        value={currentTag}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        className={'editor-form'}
        placeholder="Use ( , ) to separate tags"
      />
    </div>
  );
}

export default LabelInput;
