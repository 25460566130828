import React, { useEffect, useState } from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images'
import { Checkbox } from '@mui/material'

const GuidelineItem = ({ imageSource, itemText }) => {
    return (
        <>
            <div className='d-flex flex-column justify-content-start align-items-center px-2 py-2 my-1'
                style={{ height: 200, width: 200, borderRadius: 9, border: `1px solid ${colors.borderwhite}`, marginInlineEnd: 24 }}
            >
                <img src={imageSource}
                    style={{ height: 80, width: 80, objectFit: 'contain', marginBlockStart: 20 }}
                ></img>
                <p style={{ color: colors.greys04, textAlign: 'center', marginBlockStart: 10, width: '100%', textWrap: 'wrap' }}>
                    {itemText}
                </p>
            </div>
        </>
    )
}

const EmailGuidelines = ({ setDNSA }) => {

    const [dontShow, setDontShow] = useState(false);
    const [iUnderstand, setIUnderstand] = useState(false);

    useEffect(() => {
        if ((dontShow && iUnderstand) || localStorage.getItem('doNotShowAgain') === 'true') {
            localStorage.setItem('doNotShowAgain', 'true');
            setDNSA(true);
        } else {
            setDNSA(false);
        }
    }, [dontShow, iUnderstand])

    return (
        <>
            <div className='my-2 d-flex flex-column justify-content-start align-items-start'
                style={{ overflow: 'hidden', width: '98%', borderRadius: 12, backgroundColor: colors.white01, border: `1px solid ${colors.borderwhite}`, paddingBlock: 24, paddingInline: 30 }}
            >
                <div className='d-flex w-100'
                    style={{ overflowX: 'scroll', height: 220, overflowY: 'hidden', whiteSpace: 'nowrap', paddingBlock: 8 }}
                >
                    <div className='d-flex flex-row justify-content-start align-items-center' style={{}}>
                        <GuidelineItem
                            imageSource={images.EGuideline01}
                            itemText={'Personalize using %name%'}
                        />
                        <GuidelineItem
                            imageSource={images.EGuideline02}
                            itemText={'Avoid sending cold emails'}
                        />
                        <GuidelineItem
                            imageSource={images.EGuideline03}
                            itemText={'We add unsubscribe button automatically to emails'}
                        />
                        <GuidelineItem
                            imageSource={images.EGuideline04}
                            itemText={'Logo, footer & links should be of your brand only'}
                        />
                        <GuidelineItem
                            imageSource={images.EGuideline05}
                            itemText={'Email customers who have interacted with you in past 6 months'}
                        />
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-start align-items-center w-100' style={{ marginBlockStart: 16 }}>
                    <Checkbox
                        checked={dontShow}
                        onClick={() => { setDontShow(!dontShow) }}
                        style={{ marginBlock: 0 }}
                    />
                    <p style={{ color: colors.black, marginInlineEnd: 24 }}>
                        {'Don’t show again'}
                    </p>
                    <Checkbox
                        checked={iUnderstand}
                        onClick={() => { setIUnderstand(!iUnderstand) }}
                        style={{ marginBlock: 0 }}
                    />
                    <p style={{ color: colors.black, marginInlineEnd: 24 }}>
                        {'I understand'}
                    </p>
                </div>
            </div>
        </>
    )
}

export default EmailGuidelines