import React, { useState, useRef } from 'react';
import { styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

const SearchWithDropdown = ({ items, searchPlaceholder, onSelect, loading, onScroll, searchTerm, onSearchChange }) => {
    const dropdownRef = useRef(null);

    const handleScroll = () => {
        if (dropdownRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = dropdownRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 20 && !loading) {
                onScroll();
            }
        }
    };

    return (
        <DropdownContainer ref={dropdownRef} onScroll={handleScroll}>
            <SearchBar
                placeholder={searchPlaceholder}
                value={searchTerm}
                onChange={(e) => onSearchChange(e.target.value)}
                style={{
                    paddingTop: "2%"
                }}
            />
            {items.map((item, index) => (
                <DropdownItem
                    key={index}
                    onClick={() => onSelect(item)}
                >
                    {item}
                </DropdownItem>
            ))}
            {loading && (
                <LoaderContainer>
                    <CircularProgress size={20} />
                </LoaderContainer>
            )}
        </DropdownContainer>
    );
};

const DropdownContainer = styled('div')({
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    maxHeight: '300px',
    overflowY: 'auto',
    overflowX: 'hidden',
    border: '1px solid #CCC',
    borderRadius: '8px',
    backgroundColor: 'white',
    zIndex: 1,
    position: 'relative',
});

const SearchBar = styled(TextField)({
    margin: '8px',
    width: 'calc(100% - 16px)',
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: 'white',
    '& .MuiOutlinedInput-root': {
        height: 'auto',
        '& input': {
            padding: '6px',
        },
        '& fieldset': {
            borderColor: '#CCC',
            borderRadius: '8px',
        },
    },
});

const DropdownItem = styled('div')({
    padding: '8px 16px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#f5f5f5',
    },
});

const LoaderContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    padding: '8px',
});

export default SearchWithDropdown;
