import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import colors from "../../../Utils/colors";
import PaymentHistory from "./PaymentHistory";
import Subscriptions from "./Subscriptions";
import AutomandateCancel from'./AutoMandateCancel'
import { useDispatch, useSelector } from 'react-redux';
import { DeleteDraftTemplate, GetAllDraftTemplate,getsetupautorechargedetails } from '../../../Services';

const PaymentDetails = () => {
    const [selectedItem, setSelectedItem] = useState('Subscriptions');
    const navigate = useNavigate();
    const authtoken = useSelector((state) => state.main.auth_token);
    const [autotopdata, setautotopdata] = useState([]);
    const [createdAtdate,setcreatedAt]  =useState(String || 'No such payment done')

    const fetchautorechargedetails = async () => {
        try {
            const res = await getsetupautorechargedetails(authtoken);
            if (res?.flag && res?.data) {
                setautotopdata(res?.data)   
                setcreatedAt(res?.data[0]?.createdAt)
                console.log("Payment details************",res.data)   
            }
        } catch (err) {
          console.log('Error fetching notifications', err);
        } 
      };
      
      useEffect(() => {
        fetchautorechargedetails();
      }, [authtoken,selectedItem,setSelectedItem]);

     function formatDate  (createdAt) {
        if (!createdAt) {
          throw new RangeError('Invalid time value: Date is undefined or null');
        }
    
        const date = new Date(createdAt);
    
        if (isNaN(date.getTime())) {
          throw new RangeError('Invalid time value: Date parsing failed');
        }
    
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
    
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    
        let [month, day, year] = formattedDate.split(' ');
    
        day = day.padStart(2, '0');
    
        return `Active since ${month}, ${day} ${year}`;
      }

    return (
        <>
            <div className={'PeopleHeader Row'}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    paddingBottom: '130px',
                }}
            >
                <div className={'PeopleHeaderText'}
                    style={{
                        paddingTop: '2%'
                    }}
                >Payments & Subscriptions</div>
                <div
                    style={{
                        display: 'flex',
                        gap: 20,
                    }}
                >
                    {<div className="d-flex justify-content-center align-items-center px-4"
                        style={{
                            borderBottom: selectedItem === 'Subscriptions' ? `3px solid ${colors.primary03}` : '', cursor: 'pointer',
                            paddingTop: '2.5rem', paddingBottom: '0.8rem', position: 'relative'
                        }}
                        onClick={() => { setSelectedItem('Subscriptions'); }}>
                        <h4>{'Subscriptions AutoMandate'}</h4>
                    </div>}
                    {/* {<div className="d-flex justify-content-center align-items-center px-4"
                        style={{
                            borderBottom: selectedItem === 'Payment History' ? `3px solid ${colors.primary03}` : '', cursor: 'pointer',
                            paddingTop: '2.5rem', paddingBottom: '0.8rem'
                        }}
                        onClick={() => { setSelectedItem('Payment History'); }}>
                        <h4>{'Payment History'}</h4>
                    </div>} */}
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: 3.28,
                    marginRight: '5%',
                    marginLeft: 300,
                    marginTop: '1.5%',
                    left: 0,
                    top: '15%',
                    position: 'absolute'
                }}
            >
                {selectedItem === 'Subscriptions' && <p> Manage your Cheerio Subscription Add ons on sub</p>}
                {selectedItem === 'Subscriptions' && autotopdata.length>0 && <AutomandateCancel formatDate = {formatDate(createdAtdate)} fetchautorechargedetailsapicall= {()=>fetchautorechargedetails()}/>}
                {/* {selectedItem === 'Subscriptions' && autotopdata.length>0 && <AutomandateCancel/>} */}

                {/* {selectedItem === 'Subscriptions' && <Subscriptions selectedItem={selectedItem} setSelectedItem={setSelectedItem} />} */}
                
                {/* {selectedItem === 'Payment History' && <PaymentHistory />} */}
            </div>
        </>
    )
}

export default PaymentDetails