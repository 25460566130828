import { Modal, Box } from '@mui/material';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import { delinkInstagram, getUserApi } from '../../../Services';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import SpinnerLoader from '../../../Components/SpinnerLoader';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const DelinkModal = ({ delinkModal, setDelinkModal }) => {

    const authtoken = useSelector((state) => state.main.auth_token);
    const localdata = useSelector((state) => state.main.localdata);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);


    const getuserapicall = () => {
        let token = authtoken;
        getUserApi(token).then((res) => {
            // console.log('get user api response ==> ', res);
            if (res?.flag) {
                dispatch({ type: 'addLocaldata', payload: res?.data });
            } else {
                navigate('/');
                toast.error(res?.message);
            }
        });
    };

    const handleClose = () => {
        setDelinkModal(false)
    }

    const handleDelink = () => {
        setIsLoading(true);
        const token = authtoken;
        delinkInstagram(token)
            .then((res) => {
                console.log('delink api response ==> ', res);
                if (res?.flag) {
                    getuserapicall();
                    setDelinkModal(false);
                    setIsLoading(false);
                    toast.success(res?.message, { position: 'top-center' });
                } else {
                    setIsLoading(false);
                    toast.error(res?.message, { position: 'top-center' });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                console.log(e.message);
            });
    }
    return (
        <>
            {isLoading ?
                <>
                    <div className='d-flex flex-column justify-content-center align-items-center w-100' style={{ flex: 1 }}>
                        <SpinnerLoader />
                    </div>
                </> :
                <Modal
                    open={delinkModal}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box sx={{ ...style, width: 350 }}>
                        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', marginBottom: "10px" }}>
                            <h2 id="child-modal-title">Delink</h2>
                            <img src={images?.CMClose} style={{ width: "25px", cursor: "pointer" }} onClick={handleClose}></img>
                        </div>
                        <hr />
                        <p id="child-modal-description" style={{ color: colors?.black, fontWeight: 500 }}>
                            Are you sure you want to delink your Instagram DM from Cheerio?
                        </p>
                        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', marginTop: "20px", gap: "40px" }}>
                            <CheerioButton
                                borderStyle={{
                                    borderColor: colors.primary03,
                                    width: '100%',
                                    height: '6%',
                                    // marginBottom: '5px',
                                }}
                                textStyle={{ fontSize: 14, fontWeight: 600, color: colors.primary03 }}
                                btnText={'Cancel'}
                                backColor={colors.white}
                                onclick={handleClose}
                            />
                            <CheerioButton
                                borderStyle={{
                                    borderColor: 'red',
                                    width: '100%',
                                    height: '6%',
                                    // marginBottom: '5px',
                                }}
                                textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                                btnText={'Delete'}
                                backColor={'red'}
                                onclick={handleDelink}
                            />

                        </div>
                    </Box>
                </Modal>}
        </>
    )
}

export default DelinkModal