import React from 'react';
import images from '../../../Utils/images';

export const AppSumoDetails = () => {
  return (
    <div style={{ width: '100%', overflowY: 'auto' }}>
      <div style={{ borderBottom: '1px solid #CCCCCC', width: '100%' }}>
        <p style={{ fontSize: '22px', fontWeight: '700', marginTop: '10px', marginLeft: '17px' }}>
          AppSumo Details{' '}
        </p>
      </div>
      <img src={images.AppSumo} style={{height:'auto',width:'90%',objectFit:'contain'}}></img>
      <div style={{ padding: '10px' }}>
        <p style={{ fontWeight: '600', fontSize: '16px' }}>All plans include below:</p>
        <ul>
          <li>Official WABA Cloud API setup</li>
          <li>Custom email integration</li>
          <li>Drag & drop email builder</li>
          <li>Advanced analytics & reports</li>
          <li>Pre-approved template library</li>
          <li>Mobile app Access</li>
          <li>WhatsApp inbox & green tick apply</li>
          <li>WhatsApp chatbot builder</li>
          <li>Unlimited Contact Management</li>
          <li>Max of 50 connectors across all workflows</li>
          {/* <li>Max of 50 nodes per dynamic workflow</li> */}
          <li>Developer API library up to 1,000 API calls per month</li>
          <li>Intergration with Shopify and WooCommerce</li>
        </ul>{' '}
        <p style={{ fontWeight: '600', fontSize: '16px' }}>Not included:</p>
        <ul>
          <li>
            Whatsapp for business setup is included but balance and usage is not. You will need to
            add balance to get started.
          </li>
          <li>
            SMS setup is included but but balance and usage is not. You will need to add balance to
            get started.
          </li>
          <li>
            Any integrations not mentioned above such as GPT 4- Basic access available with rate
            limits as per discretion of Cheerio AI.
          </li>
        </ul>{' '}
      </div>
    </div>
  );
};
