import React, { useEffect, useState } from 'react';
import colors from '../../Utils/colors';

import './styles.css';
import styles from './styles.module.css';
import CheerioButton from '../../Components/CheerioButton';
import { Form, InputGroup } from 'react-bootstrap';
import images from '../../Utils/images';
import FirstTemplate from './FirstTemplate';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export function ValidateSpecialChars(name) {
  if (/^([a-z0-9 _]+)$/.test(name)) {
    return true;
  }
  toast.error('Please remove special characters!', { position: 'top-center' });

  //setIncorrectEmail(true);
  return false;
}
export const languageList = [
  {
    key: 'en',
    value: 'English',
  },
  {
    key: 'hi',
    value: 'Hindi',
  },
  {
    key: 'mr',
    value: 'Marathi',
  },
  {
    key: 'gu',
    value: 'Gujarati',
  },
  {
    key: 'kn',
    value: 'Kannada',
  },
  {
    key: 'ta',
    value: 'Tamil',
  },
  {
    key: 'te',
    value: 'Telugu',
  },
  {
    key: 'pa',
    value: 'Punjabi',
  },
  {
    key: 'bn',
    value: 'Bengali',
  },
  {
    key: 'ml',
    value: 'Malayalam',
  },
  {
    key: 'es',
    value: 'Spanish',
  },
  {
    key: 'de',
    value: 'German',
  },
  {
    key: 'fr',
    value: 'French',
  },
  {
    key: 'en_US',
    value: 'English (US)'
  },
  {
    key: 'pt_BR',
    value: 'Portuguese (BR)'
  },
  {
    key: 'id',
    value: 'Indonesian'
  }
];

const CustomTemplateStart = () => {
  const location = useLocation();
  const ReceivedTemplateData = location?.state?.templateData;
  const DraftData = location?.state?.draftData;


  const [firstTemplate, setFirstTemplate] = useState(false);
  const [data, setData] = useState({
    category: '',
    templateName: '',
    language: 'en',
    templateType: '',
  });

  const [key, setkey] = useState('-1');
  const [templateName, setTemplateName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (ReceivedTemplateData) {
      setTemplateName(ReceivedTemplateData?.templateName?.replace(/\s/g, "_").toLowerCase());
      setData({
        ...data,
        category: ReceivedTemplateData?.type,
        templateName: ReceivedTemplateData?.templateName?.replace(/\s/g, "_").toLowerCase(),
        language: ReceivedTemplateData?.language ?? 'en',
      })
      if (ReceivedTemplateData?.type === 'MARKETING') {
        setkey('1');
      } else if (ReceivedTemplateData?.type === 'UTILITY') {
        setkey('2');
      } else if (ReceivedTemplateData?.type === 'AUTHENTICATION') {
        setkey('3');
      }
      // setFirstTemplate(true);
    }
  }, [])

  useEffect(()=>{
    if(DraftData){
      setTemplateName(DraftData?.data?.name?.replace(/\s/g, "_").toLowerCase());
      setData({
        ...data,
        category: DraftData?.data?.category,
        templateName: DraftData?.data?.name?.replace(/\s/g, "_").toLowerCase(),
        language: DraftData?.data?.language ?? 'en',
        components: DraftData?.data?.components,
      })
      if (DraftData?.data?.category === 'MARKETING') {
        setkey('1');
      } else if (DraftData?.data?.category === 'UTILITY') {
        setkey('2');
      } else if (DraftData?.data?.category === 'AUTHENTICATION') {
        setkey('3');
      }
    }
  },[])

  useEffect(() => {
    const divs = document.getElementsByClassName('d-flex justify-content-between pt-2 my-3');
    for (let i = 0; i < divs.length; i++) {
      if (divs[i].id === key) {
        setData({
          ...data,
          category:
            divs[i].id === '1'
              ? 'MARKETING'
              : divs[i].id === '2'
                ? 'UTILITY'
                : divs[i].id === '3'
                  ? 'AUTHENTICATION'
                  : '',
        });

        divs[i].style.border = '1px solid #9357FF';
      } else {
        divs[i].style.border = '1px solid #d3d3d3';
      }
    }
  }, [key, firstTemplate]);

  return (
    <>
      {/* Border Changes */}
      {/* <div className='d-flex flex-column h-100 w-100' style={{border:"2px solid black"}}> */}
      <div className="d-flex flex-column h-100 w-100" style={{}}>
        {!firstTemplate ? (
          <div className="h-100" style={{ display: 'flex', flexDirection: 'column' }}>
            {/* <div style={{width:"100%",height:"100px",position:"relative",top:"200px"}}> */}
            <div
              style={{
                width: '100%',
                height: '100px',
                top: '200px',
                marginTop: '20px',
              }}
            >
              <img
                alt="back_button"
                src={images.BackArrowBlack}
                style={{
                  cursor: 'pointer',
                  height: 24,
                  width: 24,
                  objectFit: 'contain',
                  marginInlineEnd: 8,
                  position: 'relative',
                  top: '5px',
                  left: '20px',
                }}
                onClick={() => {
                  if(DraftData){
                    navigate('/creator/templates/myDrafts');
                  } else if (ReceivedTemplateData) {
                    navigate('/creator/templates/whatsappTemplates');
                  } else {
                    navigate('/creator/templates/myTemplates');
                  }
                }}
              // onClick={()=>{updateCustomTemplateCateogary(false)}}
              ></img>
              {/* <span style={{position:"relative",top:"10px",left:"30px",fontWeight: "1000"}}>New Message Template</span> */}
              <span
                className={`${styles['BodyHeader']}`}
                style={{ position: 'relative', top: '10px', left: '30px' }}
              >
                New Message Template
              </span>
            </div>

            {/* <div className='d-flex justify-content-evenly container' style={{maxWidth:"100%"}}>   */}

            {/* <div style={{border: '1px solid #d3d3d3 ',marginRight:"10px",height:"94%", borderRadius:"16px",overflow:"auto"}} className='d-flex justify-content-evenly container mt-3 pt-3'> */}
            {/* </div>  */}
            {/* <div className={`${styles["BodyScrollable"]} verythinscrollnew`} style={{ marginLeft:"300px",width:"481px",marginTop:"200px",border:"1px solid grey", borderRadius:"16px",height:"700px"}}>
             */}
            <div
              style={{
                padding: '10px',
                marginLeft: '30%',
                width: '40%',
                backgroundColor: 'white',
                border: '1px solid #CCCCCC',
                borderRadius: '16px',
                overflowY: 'scroll',
                overflowX: 'hidden',
                scrollbarWidth: 'thin',
              }}
            >
              <h6
                className={`${styles['BodyHeader']}`}
                style={{
                  textAlign: 'start',
                  color: colors.black,
                  fontSize: '20px',
                  marginTop: '10px',
                  marginLeft: '9px',
                }}
              >
                {'Category'}
              </h6>
              <p
                style={{
                  position: 'relative',
                  bottom: '5px',
                  width: '90%',
                  left: '25px',
                  color: 'grey',
                  fontWeight: '400',
                  padding: '4px',
                }}
              >
                Choose a category which best defines your message template{' '}
              </p>
              <hr style={{ position: 'relative', bottom: '5px' }} />
              <div
                id="1"
                style={{
                  cursor: 'pointer',
                  width: '82%',
                  height: 'auto',
                  marginLeft: '29px',
                  border: '1px solid #d3d3d3',
                  borderRadius: '10px',
                  padding: '2px',
                }}
                onClick={() => {
                  setkey('1');
                }}
                email="1000"
                price="100"
                className="d-flex justify-content-between pt-2 my-3"
              >
                {/* <input type='radio' style={{ accentColor: "9357FF",width:"33px",height:"33px", marginLeft: "10px",marginTop:"17px" }}></input> */}
                {/* <img alt='heart' src={selectedItem === "" ? images.HeartSel : images.Heart} style={{height:24,width:24,objectFit:'contain'}}></img> */}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <strong
                    style={{
                      position: 'relative',
                      textAlign: 'left',
                      marginBottom: '8px',
                      marginLeft: '10px',
                      top: '5px',
                    }}
                  >
                    Marketing
                  </strong>
                  <p
                    style={{
                      color: 'grey',
                      fontWeight: '400',
                      marginLeft: '10px',
                      padding: '2px',
                      marginBottom: '10px',
                    }}
                  >
                    Promotional or informational messages about your business, products or services
                  </p>
                </div>
              </div>

              <div
                id="2"
                style={{
                  cursor: 'pointer',
                  width: '82%',
                  height: 'auto',
                  marginLeft: '29px',
                  border: '1px solid #d3d3d3',
                  borderRadius: '10px',
                  padding: '2px',
                }}
                onClick={() => {
                  setkey('2');
                }}
                email="5000"
                price="500"
                className="d-flex justify-content-between pt-2  my-3 "
              >
                {/* <img alt='heart' src={selectedItem === "" ? images.HeartSel : images.Heart} style={{height:24,width:24,objectFit:'contain'}}></img> */}

                {/* <p style={{paddingLeft:"5px"}}><strong style={{position:"relative",right:"82px"}}>Utility</strong></p>
                <p style={{color: "#8E53F7", fontWeight: "600",paddingRight:"10px"}}>&#x20b9;500</p> */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <strong
                    style={{
                      position: 'relative',
                      textAlign: 'left',
                      marginBottom: '8px',
                      marginLeft: '18px',
                      top: '5px',
                    }}
                  >
                    Utility
                  </strong>
                  <p
                    style={{
                      color: 'grey',
                      fontWeight: '400',
                      marginLeft: '18px',
                      marginBottom: '10px',
                    }}
                  >
                    Message about particular account, transaction, orders, customers requests
                  </p>
                </div>
              </div>

              <div
                id="3"
                style={{
                  cursor: 'pointer',
                  width: '82%',
                  height: 'auto',
                  marginLeft: '29px',
                  border: '1px solid #d3d3d3',
                  borderRadius: '10px',
                  padding: '2px',
                }}
                onClick={() => {
                  setkey('3');
                }}
                email="10000"
                price="1000"
                className="d-flex justify-content-between pt-2  my-3"
              >
                {/* <img alt='heart' src={selectedItem === "" ? images.HeartSel : images.Heart} style={{height:24,width:24,objectFit:'contain'}}></img> */}

                {/* <p style={{paddingLeft:"5px"}}><strong style={{position:"relative",right:"43px"}}>Authentication</strong></p>
                  <p style={{color: "#8E53F7", fontWeight: "600",paddingRight:"10px"}}>&#x20b9;1000</p> */}
                {/* </div> */}
                {/* <div><p style={{paddingLeft:"8px",fontSize:"14px",color:"#666666"}}>Recommended for you</p></div>} */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <strong
                    style={{
                      position: 'relative',
                      textAlign: 'left',
                      marginBottom: '8px',
                      marginLeft: '18px',
                      top: '5px',
                    }}
                  >
                    Authentication
                  </strong>
                  <p
                    style={{
                      color: 'grey',
                      fontWeight: '400',
                      marginLeft: '18px',
                      marginBottom: '10px',
                    }}
                  >
                    One-time passwords that your customers use to authenticate a transaction or
                    login
                  </p>
                </div>
              </div>

              <InputGroup
                className=""
                style={{ width: '82%', marginLeft: '29px', marginTop: '30px' }}
              >
                <Form.Label
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: colors.black,
                    marginBlock: 4,
                  }}
                >
                  {'Template Name'}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter here"
                  style={{ borderRadius: 8, width: '84%' }}
                  value={templateName}
                  onChange={(e) => {
                    const val = e.target.value.replace(/[^a-z0-9_]/g, '') ?? '';
                    setTemplateName(val);
                    //  setData({ ...data, templateName: e.target.value });
                  }}
                />
                <p style={{ fontSize: '11px', marginLeft: '2px', marginTop: '2px', }}>
                  Note: Uppercase and special characters are not allowed!
                </p>
              </InputGroup>

              <InputGroup
                className=""
                style={{ width: '82%', marginLeft: '29px', marginTop: '30px' }}
              >
                <Form.Label
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: colors.black,
                    marginBlock: 4,
                  }}
                >
                  {'Template Type'}
                </Form.Label>
              </InputGroup>
              <Form.Select
                className="btncustom"
                aria-label="Select Template Type"
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  color: colors.greys04,
                  cursor: 'pointer',
                  position: 'relative',
                  width: '82%',
                  height: '40px',
                  left: '25px',
                }}
                value={data?.templateType}
                onChange={(e) => {
                  setData({ ...data, templateType: e.target.value });
                }}
              >
                <option
                  style={{
                    width: '100%',
                    marginTop: 4,
                    marginBottom: 4,
                    borderRadius: 8,
                  }}
                  value={'Default'}
                  key={'Default'}
                >
                  {'Default'}
                </option>
                <option
                  style={{
                    width: '100%',
                    marginTop: 4,
                    marginBottom: 4,
                    borderRadius: 8,
                  }}
                  value={'Carousel'}
                  key={'Carousel'}
                >
                  {'Carousel'}
                </option>
              </Form.Select>

              <InputGroup
                className=""
                style={{ width: '82%', marginLeft: '29px', marginTop: '30px' }}
              >
                <Form.Label
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: colors.black,
                    marginBlock: 4,
                  }}
                >
                  {'Language'}
                </Form.Label>
                {/* <Form.Control type='text' placeholder="Search Language" className='w-100 btncustom'
                  style={{borderRadius:8,marginRight:"20px"}}
                  value={language}
                  onChange={(e)=>{setLanguage(e.target.value)}}
                /> */}
              </InputGroup>
              <Form.Select
                className="btncustom"
                aria-label="Default select example"
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  color: colors.greys04,
                  cursor: 'pointer',
                  position: 'relative',
                  width: '82%',
                  height: '40px',
                  left: '25px',
                }}
                value={data.language}
                onChange={(e) => {
                  setData({ ...data, language: e.target.value });
                }}
              >
                {languageList &&
                  languageList.map((e, index) => {
                    return (
                      <option
                        style={{
                          width: '100%',
                          marginTop: 4,
                          marginBottom: 4,
                          borderRadius: 8,
                        }}
                        value={e.key}
                        key={e.key}
                      >
                        {e.value}
                      </option>
                    );
                  })}
              </Form.Select>

              <div style={{ marginTop: '42px' }} align="center">
                <CheerioButton
                  borderStyle={{
                    border: 'none',
                    width: '72%',
                    marginBlockStart: 16,
                    marginBlockEnd: 8,
                    padding: '10px',
                    borderRadius: '8px',
                  }}
                  disabled={
                    data.category === '' ||
                    templateName === '' ||
                    data.language === '' ||
                    data.language === 'None'
                  }
                  textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
                  btnText={'Next'}
                  backColor={'#000'}
                  onclick={() => {
                    if (ValidateSpecialChars(templateName)) {
                      setData({ ...data, templateName: templateName });
                      setFirstTemplate(true);
                    }
                  }}
                />
              </div>
              {/* <div  className='mt-5' align="center"><button type="button" style={{width:"250px",backgroundColor:"#ab5de0"}} class="btn btn-primary">Add to bucket</button></div>   */}
            </div>
          </div>
        ) : (
          <>
            <FirstTemplate
              templateCategory={data.category}
              templateName={data.templateName}
              templateType={data.templateType}
              setTemplateCategory={(value)=>{
                setData({
                  ...data,
                  category: value,
                })
              }}
              // lang = {language === "English" ? "en" : language === "Hindi" ? "hi" : language === "Gujarati" ? "gu" : language === "Tamil" ? "ta" : language === "Marathi" ? "mr" : ""}
              lang={data.language}
              setLang={(value)=>{
                setData({
                  ...data,
                  language: value,
                })
              }}
              setFirstTemplate={setFirstTemplate}
              ReceivedTemplateData={ReceivedTemplateData}
              DraftData={DraftData}
              setTemplateName={(value)=>{
                setData({
                  ...data,
                  templateName: value,
                })
              }}
            />
            {/* <TextAreaWithSelectedWord/> */}
          </>
        )}
      </div>
    </>
  );
};

export default CustomTemplateStart;
