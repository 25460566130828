import React, { useState } from 'react'
import colors from '../../../Utils/colors'
import { BiQuestionMark } from "react-icons/bi";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useSelector } from 'react-redux';

const CheerioWidget = ({ openReferralModalAction , setIsModalOpen }) => {

    const localdata = useSelector((state) => state.main.localdata);
    // const [isOptionsOpen, setIsOptionsOpen] = useState(false)

    const OptionsItems = ({ optionsText, onclickAction }) => {
        return(
            <>
            <div 
                className='d-flex flex-row justify-content-start align-items-center w-100 py-2 px-3 hoverPrimary'
                onClick={()=>{onclickAction()}}
            >
                <p style={{fontSize:14,fontWeight:400,color:colors.black}}>
                    {optionsText}
                </p>
            </div>
            </>
        )
    }

  return (
    <>
    <div 
        style={{position:'absolute',bottom:92,left:90,zIndex:42}}
        className='d-flex flex-column justify-content-flex-end align-items-end'
    >
        {/* {isOptionsOpen && 
            <> */}
            {/* <ClickAwayListener onClickAway={()=>{setIsOptionsOpen(false)}}> */}
            <ClickAwayListener onClickAway={()=>{setIsModalOpen(false)}}>
                <div 
                    className='d-flex flex-column justify-content-end align-items-center'
                    style={{width:200,backgroundColor:colors.white01,border:`1px solid ${colors.white01}`,boxShadow:`0px 2px 4px 0px rgba(0, 0, 0, 0.24)`,borderRadius:12}}
                >
                    <OptionsItems 
                        optionsText={'Help articles'}
                        onclickAction={()=>{window.open('https://www.cheerio.in/help','_blank')}}
                    />
                    <OptionsItems 
                        optionsText={'Blog'}
                        onclickAction={()=>{window.open('https://www.cheerio.in/blog','_blank')}}
                    />
                    <OptionsItems 
                        optionsText={'YouTube videos'}
                        onclickAction={()=>{window.open('https://www.youtube.com/watch?v=SrxHNa0zvRU&t=191s','_blank')}}
                    />
                    <OptionsItems 
                        optionsText={'Submit feedback'}
                        onclickAction={()=>{window.open('https://www.trustpilot.com/review/cheerio.in','_blank')}}
                        // onclickAction={()=>{window.open('https://appsumo.com/products/Cheerio/?p=1#reviews','_blank')}}
                    />
                    {localdata?.isAppsumoUser && 
                    <OptionsItems 
                        optionsText={'Review us on AppSumo'}
                        // onclickAction={()=>{window.open('https://www.trustpilot.com/review/cheerio.in','_blank')}}
                        onclickAction={()=>{window.open('https://appsumo.com/products/cheerio/?p=1#reviews','_blank')}}
                    />
                    }
                    <OptionsItems 
                        optionsText={'Contact support'}
                        onclickAction={()=>{window.open('https://cheerio.freshdesk.com/support/tickets/new','_blank');}}
                    />
                    <OptionsItems 
                        optionsText={'Refer and earn'}
                        onclickAction={()=>{
                            openReferralModalAction()
                            // setIsOptionsOpen(false);
                            setIsModalOpen(false);
                        }}
                    />
                </div>
            </ClickAwayListener>
            {/* </>
        } */}
        {/* <div 
            className='d-flex justify-content-center align-items-center' 
            style={{height:54,width:54,borderRadius:'50%',backgroundColor:colors.themeDarkBlue,cursor:'pointer',boxShadow:`0px 2px 6px 2px rgba(0, 0, 0, 0.48)`}}
            onClick={()=>{
                setIsOptionsOpen(!isOptionsOpen)
            }}
        >
            <BiQuestionMark size={24} color='white'/>
        </div> */}
    </div>
    </>
  )
}

export default CheerioWidget