import { identify, Identify, init, track, setUserId } from '@amplitude/analytics-browser';

const API_KEY = process.env?.REACT_APP_API_KEY;

init(API_KEY);

export const setuserid = (id) => {
  setUserId(id);
};

export const eventsend = (eventTxt, eventProps = {}) => {
  const event = eventTxt;
  API_KEY && track(event, eventProps);
};

export const userproperties = (key, value) => {
  const identifyObj = new Identify();
  identifyObj.set(key, value);
  identify(identifyObj);
};
