import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TemplateData from './TemplateDataV1.json';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';

const AnnTemplateV1 = ({ onclickSeePreviewAction, onclickUseTemplateAction, sSelected }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);

  const mobileWidth = window.innerWidth < 900 ? true : false;

  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);

  useEffect(() => {
    // setIsLoadingTemplates(true);
    setTimeout(() => {
      setIsLoadingTemplates(false);
    }, 1000);
    console.log('json data ===> ', TemplateData);
  }, []);

  const TemplateV1Item = ({ item }) => {
    let srcimg = {};
    switch (item.image) {
      case 'CAOffer':
        srcimg = images.CAOffer;
        break;
      case 'CAProduct':
        srcimg = images.CAProduct;
        break;
      case 'CACourse':
        srcimg = images.CACourse;
        break;
      case 'CAHTML':
        srcimg = images.CAHTML;
        break;
      case 'CADesignEmail':
        srcimg = images.CADesignEmail;
        break;
      case 'CARemainder':
        srcimg = images.CARemainder;
        break;
      case 'CAThankYou':
        srcimg = images.CAThankYou;
        break;
      case 'CAShare':
        srcimg = images.CAShare;
        break;
      case 'CAAddDiscount':
        srcimg = images.CAAddDiscount;
        break;
      case 'CARCOffer':
        srcimg = images.CARCOffer;
        break;
      case 'CAWinback':
        srcimg = images.CAWinback;
        break;
      case 'CABINews':
        srcimg = images.CABINews;
        break;
      case 'CAWelcomeInsta':
        srcimg = images.CAWelcomeInsta;
        break;
      case 'CALoyalCustomer':
        srcimg = images.CALoyalCustomer;
        break;
      case 'CAThankYou2':
        srcimg = images.CAThankYou2;
        break;
      case 'CADoYouKnow':
        srcimg = images.CADoYouKnow;
        break;
      case 'CAMaintenance':
        srcimg = images.CAMaintenance;
        break;
      case 'CAServiceMarathi':
        srcimg = images.CAServiceMarathi;
        break;

      default:
        srcimg = images.Default;
    }
    let isHTML = item.templateName === 'HTML' || item.templateName === 'CustomHTML';
    return (
      <>
        <div className="ann_template_v1_item_container">
          <div className="ann_template_v1_item">
            <div className="ann_template_v1_item__row1">
              <img src={srcimg}></img>
              <div className="text_container">
                <p className="header_text">{item?.templateHeader}</p>
                <div className="d-flex flex-row justify-content-start align-items-center w-100 my-1">
                  {item?.templateName === 'Offer' ||
                  item?.templateName === 'Product' ||
                  item?.templateName === 'Course' ? (
                    <>
                      <p
                        className=""
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 10,
                          fontWeight: 400,
                          color: colors.white,
                          backgroundColor: colors.success03,
                          paddingBlock: 4,
                          paddingInline: 8,
                          borderRadius: 6,
                        }}
                      >
                        {'WhatsApp'}
                      </p>
                      <p
                        className=""
                        style={{
                          padding: 0,
                          margin: 0,
                          marginInline: 8,
                          fontSize: 10,
                          fontWeight: 400,
                          color: colors.white,
                          backgroundColor: colors.linkblue,
                          paddingBlock: 4,
                          paddingInline: 8,
                          borderRadius: 6,
                        }}
                      >
                        {'Email'}
                      </p>
                      <p
                        className=""
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 10,
                          fontWeight: 400,
                          color: colors.white,
                          backgroundColor: colors.yellow03,
                          paddingBlock: 4,
                          paddingInline: 8,
                          borderRadius: 6,
                        }}
                      >
                        {'SMS'}
                      </p>
                    </>
                  ) : item?.templateName === 'HTML' || item?.templateName === 'CustomHTML' ? (
                    <>
                      <p
                        className=""
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 10,
                          fontWeight: 400,
                          color: colors.white,
                          backgroundColor: colors.linkblue,
                          paddingBlock: 4,
                          paddingInline: 8,
                          borderRadius: 6,
                        }}
                      >
                        {'Email'}
                      </p>
                    </>
                  ) : (
                    <>
                      <p
                        className=""
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 10,
                          fontWeight: 400,
                          color: colors.white,
                          backgroundColor: colors.success03,
                          paddingBlock: 4,
                          paddingInline: 8,
                          borderRadius: 6,
                        }}
                      >
                        {'WhatsApp'}
                      </p>
                    </>
                  )}
                </div>
                <p>
                  {item?.templateText}
                  <br />
                  {item?.templateSubText}
                </p>
              </div>
            </div>
            <div className="ann_template_v1_item__row2">
              {!isHTML && (
                <CheerioButton
                  borderStyle={{
                    width: mobileWidth ? '100%' : '46%',
                    paddingBlock: 6,
                    // marginInlineEnd: 10,
                    border: `1px solid #000`,
                    marginBlock: 4,
                  }}
                  textStyle={{ fontSize: 14, fontWeight: 600, color: '#000' }}
                  btnText={'See preview'}
                  backColor={'#FFFF'}
                  onclick={() => {
                    onclickSeePreviewAction(item.templateName);
                  }}
                  // onclick={() => {
                  //     // setPreview1(true);
                  //     // setType('Offer');
                  // }}
                  icon={images.CANor}
                />
              )}

              <CheerioButton
                borderStyle={{
                  borderColor: colors.primary03,
                  width: mobileWidth ? '100%' : '46%',
                  paddingBlock: 6,
                  // marginInlineStart: 10,
                  marginBlock: 4,
                }}
                textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                btnText={'Use Template'}
                backColor={colors.primary03}
                onclick={() => {
                  onclickUseTemplateAction(item.templateName);
                  // setType('Offer');
                  // setPreview1(false);
                  // setEnterDetails(true);
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="ann_templates_v1_container">
        {isLoadingTemplates ? (
          <>
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
              <SpinnerLoader />
            </div>
          </>
        ) : (
          <>
            <div className="row_wrapper">
              {TemplateData &&
                TemplateData.map((item) => {
                  if (sSelected) {
                    if (
                      item?.templateName === 'Offer' ||
                      item?.templateName === 'Product' ||
                      item?.templateName === 'Course'
                    ) {
                      return <TemplateV1Item item={item} />;
                    }
                  } else {
                    return <TemplateV1Item item={item} />;
                  }
                })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AnnTemplateV1;
