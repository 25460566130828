import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import Button from 'react-bootstrap/Button';
import SuccessModal from './SuccessModal';
import '../../../../src/Pages/Templates/styles.css';
import { paymentOrder, orderPayment, getUserApi, partnerCreatePaymentOrder } from '../../../Services';
import { toast } from 'react-toastify';
import { updateWelcomeStatus } from '../../../Services';
import { eventsend } from '../../../Config/analyticsFunctions';


const AppsumoWelcomeModal = ({ show, setShow }) => {

    const localdata = useSelector((state) => state.main.localdata);
    const authtoken = useSelector((state) => state.main.auth_token);

    const handleClose = () => {
        // if (!localdata?.isWelcomeMessageSent) {
        //     const token = authtoken
        //     const data = {
        //         email: localdata?.email,
        //     }
        //     updateWelcomeStatus(data, token).then((res) => {
        //         console.log(res);
        //     }).catch((err) => {
        //         console.log(err);
        //     });
        // }
        setShow(false);
    }
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        eventsend("App_sumo_users?_Home_page_clicked");
    }, []);


    return (
        <div >
            <Modal
                show={show}
                onHide={handleClose}
                className="custom-modal"
                centered

            >
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img
                        src={images.CMClose}
                        style={{
                            cursor: 'pointer',
                            height: 22,
                            width: 22,
                            margin: '2%',
                        }}
                        onClick={handleClose}
                    />
                </div>
                <Modal.Body
                    style={{
                        padding: '4%'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '4%',
                            gap: 20,
                            marginTop: 20
                        }}
                    >
                        <img
                            src={images.Success}
                            style={{
                                height: 150,
                                // width: 100,
                            }}
                        ></img>
                        <span
                            style={{
                                fontSize: 20,
                                fontWeight: 700,
                                textAlign: "center"
                            }}
                        >
                            Congratulations your Cheerio <br /> premium plan is active
                        </span>
                    </div>
                    <div style={{
                        marginTop: 60
                    }}>
                        <CheerioButton
                            btnText={"View plan details"}
                            backColor={"#8E53F7"}
                            textStyle={{ color: colors.white }}
                            borderStyle={{ borderColor: colors.white }}
                            onclick={() => {
                                navigate('/manageprofile/planDetails')
                                handleClose()
                            }}
                        />
                    </div>

                </Modal.Body>
            </Modal>
        </div >
    )
}

export default AppsumoWelcomeModal