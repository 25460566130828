import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import SidemenuOpenAi from './Sidemenu';
import { SetupOpenAi } from './SetupOpenAi';
import Assistants from './Assistants';
import { PremiumOptionModal } from '../../../Components/Modal/PremiumOptionModal';

const OpenAi = () => {
    const [openSetup, setOpenSetup] = useState(true);
    const [selectedMenuItem, setSelectedMenuItem] = useState('Integration');
    const [selectedCampaignFlow, setSelectedCampaignFlow] = useState('');
    const localData = useSelector((state) => state.main.localdata);

    const isPremiumUser = localData?.premium;
    const specialID = (localData?._id === '636be67d42c9a5cb33f60d98' || localData?._id === '660e878b6d6015c4e49b0434' || localData?._id === "6489675b4f598e055ed74e34" || localData?._id === '65f7d39aecb11f98652fffcd' || localData?._id === '65f7d42aecb11f98652fffd2' || localData?.integratedApp.some(app => app.includes("Chat GPT")))
    return (
        <>
            {!isPremiumUser && (
                <PremiumOptionModal
                    option={'WooCommerce'}
                    openPremiumModal={true}
                    setOpenPremiumModal={() => { }}
                ></PremiumOptionModal>
            )}
            {specialID && (
                <div className="Row w-100 h-100" style={{}}>
                    <SidemenuOpenAi
                        openSetup={openSetup}
                        setOpenSetup={setOpenSetup}
                        selectedMenuItem={selectedMenuItem}
                        setSelectedMenuItem={setSelectedMenuItem}
                    />

                    <div className="Column justify-content-start h-100" style={{ flex: 1 }}>
                        {selectedMenuItem == 'Integration' && (
                            <SetupOpenAi setSelectedMenuItem={setSelectedMenuItem} />
                        )}
                        {selectedMenuItem == 'Assistants' && (
                            <Assistants setSelectedMenuItem={setSelectedMenuItem} />
                        )}
                    </div>
                </div>)}
        </>
    );
};

export default OpenAi;
