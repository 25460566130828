import { useEffect, useState } from 'react';
import { TextField, MenuItem, Grid, Typography, Box } from '@mui/material';
import colors from '../../../Utils/colors';
import '../../Chatbot/styles.css'
import '../index.scss'

const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));
const periods = ['AM', 'PM'];

const formatTime = (HH, MM, period) => {
  let hour = parseInt(HH);
  if (period === 'PM' && hour !== 12) {
    hour += 12;
  } else if (period === 'AM' && hour === 12) {
    hour = 0;
  }
  return `${hour.toString().padStart(2, '0')}:${MM ?? '00'}`;
};

const parseTimeString = (timeString) => {
  if (!timeString) {
    return {
      hh: '',
      mm: '',
      pr: 'AM',
    };
  }
  const [HH, MM] = timeString.split(':');
  const period = parseInt(HH) >= 12 ? 'PM' : 'AM';
  return {
    hh: (parseInt(HH) >= 12 ? String(parseInt(HH) - 12) : HH).padStart(2, '0') ?? '',
    mm: MM ?? '',
    pr: period ?? '',
  };
};

const formatOffset = (offset) => {
  offset = Number(offset);
  const sign = offset > 0 ? '-' : '+';
  const absOffset = Math.abs(offset);
  const hours = Math.floor(absOffset / 60)
    .toString()
    .padStart(2, '0');
  const minutes = (absOffset % 60).toString().padStart(2, '0');
  return `${sign}${hours}${minutes}`;
};


const daysOfWeek = [
  { label: 'M', value: 'mon', },
  { label: 'T', value: 'tue', },
  { label: 'W', value: 'wed', },
  { label: 'T', value: 'thu', },
  { label: 'F', value: 'fri', },
  { label: 'S', value: 'sat', },
  { label: 'S', value: 'sun', },
];


const TimePicker = ({ object, setObject }) => {
  const [startTime, setStartTime] = useState(parseTimeString(object.startTime ?? ''));
  const [endTime, setEndTime] = useState(parseTimeString(object.endTime ?? ''));
  const allDays = daysOfWeek.map(day => day?.value);
  const [workingDays, setWorkingDays] = useState(object?.days && object?.days.length > 0 ? object?.days : allDays);

  useEffect(() => {
    const now = new Date();
    setObject((po) => ({
      ...po,
      startTime: formatTime(startTime.hh, startTime.mm, startTime.pr),
      endTime: formatTime(endTime.hh, endTime.mm, endTime.pr),
      offset: formatOffset(now.getTimezoneOffset()),
      days: workingDays,

    }));
  }, [startTime, endTime, workingDays]);

  // const handleWorkingDays = (e) => {
  //   // console.log("id..",e.target.id);
  //   const label = document.querySelector(`label[for=${e.target.id}]`);
  //   //   if(checkDetails){
  //   //   console.log("update");
  //   //   setUpdateArr(true);
  //   // }
  //   // if(e.target.checked){
  //   const index = workingDays.indexOf(e.target.value);
  //   // console.log("i",index);
  //   if (index >= 0) {
  //     //  setWorkingDays(workingDays.splice(index,1));
  //     setWorkingDays(workingDays.filter((day) => day !== e.target.value));
  //     label.style.backgroundColor = '#F5F5F5';
  //     label.style.border = '0px';
  //   } else {
  //     setWorkingDays((workingDays) => [...workingDays, e.target.value]);
  //     label.style.backgroundColor = '#ECE1FF';
  //     label.style.border = '1px solid #8E53F7';
  //     label.style.color = "#8E53F7"
  //   }
  //   // console.log("wd",workingDays)
  //   // }else{
  //   //   console.log("wd",workingDays)
  //   //   label.style.backgroundColor = "#F5F5F5";
  //   //   // const index = workingDays.indexOf(e.target.value)
  //   //   // workingDays.splice(index,1)
  //   //   setWorkingDays(workingDays.filter((day)=> day !== e.target.value));
  //   //   console.log("wd",workingDays);
  //   // }
  // };


  const handleWorkingDays = (e) => {
    const value = e.target.value;
    const newWorkingDays = workingDays.includes(value)
      ? workingDays.filter((day) => day !== value)
      : [...workingDays, value];
    setWorkingDays(newWorkingDays);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}
    >
      <div>
        <Typography
          variant="h6"
          sx={{ fontSize: 16, fontWeight: 700, color: colors.black, marginBlock: 1 }}
        >
          Start Time
        </Typography>
        <Grid container spacing={1}>
          <Grid item>
            <TextField
              sx={{ width: '68px' }}
              select
              label="HH"
              variant="outlined"
              value={startTime.hh}
              onChange={(e) => {
                setStartTime((p) => ({ ...p, hh: e.target.value }));
              }}
            >
              {hours.map((hour) => (
                <MenuItem key={hour} value={hour}>
                  {hour}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              sx={{ width: '68px' }}
              select
              label="MM"
              variant="outlined"
              value={startTime.mm}
              onChange={(e) => {
                setStartTime((p) => ({ ...p, mm: e.target.value }));
              }}
            >
              {minutes.map((minute) => (
                <MenuItem key={minute} value={minute}>
                  {minute}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              sx={{ width: '72px' }}
              select
              label="AM"
              variant="outlined"
              value={startTime.pr}
              onChange={(e) => {
                setStartTime((p) => ({ ...p, pr: e.target.value }));
              }}
            >
              {periods.map((period) => (
                <MenuItem key={period} value={period}>
                  {period}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </div>
      <div>
        <Typography
          variant="h6"
          sx={{ fontSize: 16, fontWeight: 700, color: colors.black, marginBlock: 1 }}
        >
          End Time
        </Typography>
        <Grid container spacing={1}>
          <Grid item>
            <TextField
              sx={{ width: '68px' }}
              select
              label="HH"
              variant="outlined"
              value={endTime.hh}
              onChange={(e) => {
                setEndTime((p) => ({ ...p, hh: e.target.value }));
              }}
            >
              {hours.map((hour) => (
                <MenuItem key={hour} value={hour}>
                  {hour}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              sx={{ width: '68px' }}
              select
              label="MM"
              variant="outlined"
              value={endTime.mm}
              onChange={(e) => {
                setEndTime((p) => ({ ...p, mm: e.target.value }));
              }}
            >
              {minutes.map((minute) => (
                <MenuItem key={minute} value={minute}>
                  {minute}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              sx={{ width: '72px' }}
              select
              label="AM"
              variant="outlined"
              value={endTime.pr}
              onChange={(e) => {
                setEndTime((p) => ({ ...p, pr: e.target.value }));
              }}
            >
              {periods.map((period) => (
                <MenuItem key={period} value={period}>
                  {period}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </div>
      <div>
        <Typography
          variant="h6"
          sx={{ fontSize: 16, fontWeight: 700, color: colors.black, marginBlock: 1 }}
        >
          Days
          <p style={{ color: "#666666", fontSize: 15 }}>Select the days for trigger</p>
        </Typography>
        <div class="btn-group mt-3" id="workingButton" role="group" data-bs-toggle="button">
          {daysOfWeek.map((day) => (
            <div key={day.value}>
              <input
                type="checkbox"
                className="btn-check"
                id={`btncheck-${day.value}`}
                value={day.value}
                checked={workingDays.includes(day.value)}
                onChange={handleWorkingDays}
              />
              <div>
                <label
                  // className="btn mx-2 rounded-5 d-flex align-items-center "
                  class="btn mx-2 rounded-5 d-flex align-items-center btnHover"
                  htmlFor={`btncheck-${day.value}`}
                  style={{
                    height: '32px',
                    width: '32px',
                    paddingLeft: '9px',
                    fontSize: '16px',
                    fontWeight: '600',
                    color: workingDays.includes(day.value) ? '#8E53F7' : "#6c6c6c",
                    backgroundColor: workingDays.includes(day.value) ? '#ECE1FF' : "#F5F5F5",
                    border: "",
                  }}
                >
                  {day.label}
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
};

export default TimePicker;


