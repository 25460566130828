import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import colors from '../../../Utils/colors';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Tooltip } from '@mui/material';

const TimeDelayModal = ({ data, setData }) => {

    const [hours, setHours] = useState("00");
    const [minutes, setMinutes] = useState("00");
    const [skipReply, setSkipReply] = useState(data?.executeNextScheduler);
    // const [seconds, setSeconds] = useState("00");
    const [days, setDays] = useState(0)

    useEffect(()=>{
        if(data?.hours){
            if(data?.hours > 24){
                setDays(Math.floor(data?.hours/24))
                setHours(data.hours - Math.floor(data?.hours/24)*24)
            }else{
                setHours(data.hours)
            }
        }
        if(data?.minutes){
            setMinutes(data.minutes)
        }
        if(data?.executeNextScheduler){
            setSkipReply(data.executeNextScheduler);
        }
        // if(data?.seconds){
        //     setSeconds(data.seconds)
        // }
    },[])

    useEffect(()=>{

    })

    useEffect(()=>{
        setData({...data,
            hours: Number(hours) + Number(days*24),
            minutes: minutes,
            executeNextScheduler: skipReply,
            // seconds: seconds,
        })
    },[hours,minutes,skipReply,days])

    useEffect(()=>{
        if(minutes){
            const timer = setTimeout(() => {
                const value = Math.max(0, Math.min(59, Number(minutes)))
                const multiplesValue = Math.floor(value / 5) * 5
                if(multiplesValue == minutes){
                    return;
                }else{
                    setMinutes(multiplesValue)
                }
            }, 1000)
            return () => clearTimeout(timer)
        }
    },[minutes])
    useEffect(()=>{
        if(hours){
            const htimer = setTimeout(() => {
                const value = Math.max(0, Math.min(Number(hours)))
                // const value = Math.max(0, Math.min(23, Number(hours)))
                console.log("hvalue ====> ",value)
                const multiplesValue = Math.round(value / 1)*1
                if(multiplesValue === hours){
                    return;
                }else{
                    setHours(value)
                }
            }, 1000)
            return () => clearTimeout(htimer)
        }
    },[hours])

  return (
    <>
    <div className='w-100 h-100 flex-column justify-content-start align-items-start'>
        <p className='' style={{padding:0,margin:0,marginBlockStart:16,fontSize:16,fontWeight:600,color:colors.black,width:'100%'}}>
            {'Set timer'}
        </p>
        {(Number(hours)>23 || days > 1) && 
        <p className='' style={{padding:0,margin:0,marginBlockStart:16,fontSize:16,fontWeight:600,color:colors.black,width:'100%'}}>
            {'If the delay is more than 24 hours you can only use template messages or email'}
        </p>
        }
        <div className='d-flex flex-row justify-content-evenly align-items-center w-100 mb-2'>
        <InputGroup className="mb-3" style={{width:'30%',marginBlock:16}}>
            <Form.Label style={{fontSize:14,fontWeight:600,color:colors.black}}>{'Days'}</Form.Label>
            <Form.Control type='number' placeholder="select days" className='w-100 btncustom dropdown'
            style={{borderRadius:8,fontSize:16,fontWeight:400,color:colors.greys01}}
            value={days}
            onChange={(e)=>{setDays(e.target.value)}}
            min={0}
            // max={23}
            >
            </Form.Control> 
        </InputGroup>
        <InputGroup className="mb-3" style={{width:'30%',marginBlock:16}}>
            <Form.Label style={{fontSize:14,fontWeight:600,color:colors.black}}>{'Hours'}</Form.Label>
            <Form.Control type='number' placeholder="select hours" className='w-100 btncustom dropdown'
            style={{borderRadius:8,fontSize:16,fontWeight:400,color:colors.greys01}}
            value={hours}
            onChange={(e)=>{setHours(e.target.value)}}
            min={0}
            max={23}
            >
            </Form.Control> 
        </InputGroup>
        <InputGroup className="mb-3" style={{width:'30%',marginBlock:16}}>
            <Form.Label style={{fontSize:14,fontWeight:600,color:colors.black}}>{'Minutes'}</Form.Label>
            <Form.Control type='number' placeholder="select minutes" className='w-100 btncustom dropdown'
            style={{borderRadius:8,fontSize:16,fontWeight:400,color:colors.greys01}}
            value={minutes}
            onChange={(e)=>{setMinutes(e.target.value)}}
            min={0}
            step={5}
            max={59}
            >
            </Form.Control> 
        </InputGroup>
        {/* <InputGroup className="mb-3" style={{width:'30%',marginBlock:16}}>
            <Form.Label style={{fontSize:14,fontWeight:600,color:colors.black}}>{'Seconds'}</Form.Label>
            <Form.Control type='number' placeholder="select seconds" className='w-100 btncustom dropdown'
            style={{borderRadius:8,fontSize:16,fontWeight:400,color:colors.greys01}}
            value={seconds}
            onChange={(e)=>{setSeconds(e.target.value)}}
            min={0}
            max={59}
            >
            </Form.Control> 
        </InputGroup> */}
        </div>

        <div className='d-flex flex-row justify-content-between align-items-center w-100 mb-4'>
            <Form.Check 
                // disabled={disabled}
                type='checkbox'
                className='btncustom'
                // value={skipReply ? true : false}
                checked={skipReply}
                label='Skip user reply'
                onChange={()=>{setSkipReply(!skipReply)}}
            />
            <Tooltip title={'Time delay will trigger itself even if recipient does not reply'} placement='bottom-end'>
                <div>
                    <AiOutlineInfoCircle size={20} style={{marginInlineEnd:16}}/>
                </div>
                
            </Tooltip>    
        </div>
    </div>
    </>
  )
}

export default TimeDelayModal
