import './index.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Container from '@mui/material/Container';
import images from '../../Utils/images';
import SetTimeComponent from './Components/SetTimeComponent';
import ReplyActionsComponent from './Components/ReplyActionsComponent';

const TimeTriggerSetup = ({ setStateView, currentRow }) => {
  const navigate = useNavigate();
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;
  const [selectedItem, setSelectedItem] = useState('Set Time');
  const [object, setObject] = useState({
    name: currentRow?.timeFrame?.name ?? '',
    startTime: currentRow?.timeFrame?.startTime ?? '',
    endTime: currentRow?.timeFrame?.endTime ?? '',
    keywords: currentRow?.keywords || [],
    criteria: currentRow?.keywordCriteria ?? '',
    data: currentRow?.data ?? {},
    offset: '',
    days: currentRow?.timeFrame?.days ?? [],
  });

  const SidemenuItem = ({ title }) => {
    let img = images.CheckBoxIcon;
    if (title == 'Set Time' && selectedItem === 'Reply Actions') {
      img = images.CheckBoxIconSel;
    }

    return (
      <>
        <div
          className="SidemenuItem Row"
          onClick={() => {
            if (object.name === '' || object.startTime === '' || object.endTime === '' || object.days.length === 0) {
              return;
            } else {
              setSelectedItem(title);
            }
          }}
        >
          <div className={`InnerContainer Row ${selectedItem === title ? 'selectedItem' : ''}`}>
            <img src={img} alt="not completed ratio" width={14} height={14} />
            <p className={`InnerText ${selectedItem === title ? 'selectedText' : ''}`}>{title}</p>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (!isPremiumUser) {
      navigate('/creator');
    }
  }, [object]);

  return (
    <Container maxWidth="false" disableGutters style={{ height: '100dvh' }}>
      <div className="ManageProfileBox Row">
        <div className="BoxColumn1 Column">
          <div className="SidemenuHeader Row">
            <img
              src={images.BackArrowBlack}
              className="IconStyle"
              onClick={() => {
                setStateView('list');
              }}
            />
            <p className="TextStyle">Time Trigger</p>
          </div>

          <SidemenuItem title={'Set Time'} />
          <SidemenuItem title={'Reply Actions'} />
        </div>
        <div className={'BoxColumn2 Column'}>
          {selectedItem === 'Set Time' ? (
            <SetTimeComponent
              object={object}
              setObject={setObject}
              setSelectedItem={setSelectedItem}
              setStateView={setStateView}
            />
          ) : (
            <ReplyActionsComponent object={object} setStateView={setStateView} />
          )}
        </div>
      </div>
    </Container>
  );
};

export default TimeTriggerSetup;
