import React, { useEffect, useState } from 'react';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import { Form } from 'react-bootstrap';
import SpinnerLoader from '../../Components/SpinnerLoader';
import { useSelector } from 'react-redux';
import { getCreditReportAPI } from '../../Services';

const DownloadCredit = ({ setIsDownloadCreditOpen }) => {
  const [dateChoosen, setDateChoosen] = useState('');
  const [isClicked, setIsClicked] = useState('false');
  const [downloadRes, setDownloadRes] = useState('false');
  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);

  const getCreditReport = async (val) => {
    let data = {
      user_id: localdata?._id,
      day: val,
    };
    let token = authtoken;
    let response = await getCreditReportAPI(data, token);
    if (response.status === '200') {
      setDownloadRes('true');
    }
    let url;
    const blob = await response.blob();
    url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'creditReport.csv');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    setDateChoosen('');
    setTimeout(() => {
      setDownloadRes('false');
      setIsClicked('false');
    }, 4000);
  };
  return (
    <>
      <div className="DarkBg" onClick={() => {}}>
        <div className="Center">
          <div
            style={{
              width: 450,
              height: '55vh',
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 0,
              border: `1px solid ${colors.borderwhite}`,
              paddingBlock: 30,
              paddingInline: 30,
              overflowY: 'scroll',
            }}
          >
            <div
              className="d-flex flex-column justify-content-between align-items-center w-100 h-100"
              style={{}}
            >
              <div className="d-flex flex-row justify-content-between align-items-center w-100">
                <h2
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 20,
                    fontWeight: 700,
                    color: colors.black,
                  }}
                >
                  {'Download Credits Report'}
                </h2>
                <img
                  src={images.CMClose}
                  style={{ height: 32, width: 32, objectFit: 'contain', cursor: 'pointer' }}
                  onClick={() => {
                    setIsDownloadCreditOpen(false);
                  }}
                ></img>
              </div>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  marginBlock: 16,
                  fontSize: 16,
                  fontWeight: 400,
                  color: '#666666',
                  width: '100%',
                }}
              >
                {
                  'Specify the duration (in days) for which you would like to download your reports..'
                }
              </p>
              <h2
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 16,
                  fontWeight: 700,
                  color: colors.black,
                  marginBlock: 16,
                  marginBottom: 9,
                  width: '100%',
                }}
              >
                {'Enter no. of days'}
              </h2>
              <Form.Control
                type="text"
                placeholder="Enter here"
                className="w-100 btncustom"
                style={{ borderRadius: 8, borderColor: '#000' }}
                value={dateChoosen}
                onChange={(e) => {
                  setDateChoosen(e.target.value);
                }}
              />

              {isClicked === 'false' ? (
                <>
                  <div
                    style={{
                      ...Styles.Conatiner,
                      width: '100%',
                      marginTop: 60,
                      backgroundColor: colors.black,
                      cursor: dateChoosen ? 'pointer' : '',
                      filter: !dateChoosen ? 'opacity(40%)' : 'none',
                    }}
                    onClick={() => {
                      if (dateChoosen) {
                        setIsClicked('true');
                        getCreditReport(dateChoosen);
                      }
                    }}
                  >
                    <img
                      src={images.ANExport}
                      alt="download"
                      style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}
                    />
                    <span
                      className="p-0 m-0"
                      style={{
                        ...Styles.ButtonText,
                        fontSize: 16,
                        fontWeight: 600,
                        color: colors.white,
                      }}
                    >
                      {'Download Report'}
                    </span>
                  </div>
                </>
              ) : !downloadRes && isClicked ? (
                <>
                  <div
                    className="d-flex flex-column justify-content-center align-items-center w-100"
                    style={{ height: 300 }}
                  >
                    <SpinnerLoader />
                  </div>
                </>
              ) : downloadRes ? (
                <>
                  <div style={{ display: 'flex', width: '100%', marginTop: 60 }}>
                    <img
                      src={images.SUCCESSFULL}
                      style={{
                        height: 18,
                        width: 18,
                        objectFit: 'contain',
                        marginTop: '4px',
                        marginRight: '4px',
                      }}
                    ></img>
                    <span>Your report has been downloaded successfully</span>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      marginTop: 60,
                      backgroundColor: '#CDCD00',
                    }}
                  >
                    <img
                      src={images.UNSUCESS}
                      style={{
                        height: 18,
                        width: 18,
                        objectFit: 'contain',
                        marginTop: '4px',
                        marginRight: '4px',
                      }}
                    ></img>
                    <span>Error in downloading Credit Report</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadCredit;
const Styles = {
  Conatiner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    paddingInline: 20,
    paddingBlock: 6,
    border: '1px solid #000',
  },
  ButtonText: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.black,
  },
};
