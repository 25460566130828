import React from 'react';
import colors from '../../../Utils/colors';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CheerioButton from '../../../Components/CheerioButton';
import { getImagePath } from '../../../Utils';
import images from '../../../Utils/images';
import styles from './../styles.module.css';
import { Tooltip } from '@mui/material';
import HEImages from './HEImages';

const Header = ({ setSidemenuCollapse, setIsFTMOpen, setProfilePopup }) => {
  const localdata = useSelector((state) => state.main.localdata);
  const agentdata = useSelector((state) => state.main.agentdata);
  const navigate = useNavigate();
  const isPremiumUser = localdata?.premium || localdata?.free_trail?.status;

  const getTodaysDate = () => {
    const date = new Date();
    const mm = date.toLocaleString('en-EN', { month: 'short' });
    const dd = date.getDate();
    const yy = date.getFullYear();
    const DD = date.toLocaleDateString('en-EN', { weekday: 'long' });
    return dd + '’ ' + mm + ' ' + yy;
  };

  const getRemainingDays = (e) => {
    const d = new Date(e);
    const date = new Date();
    let dd = d.toDateString();
    let cd = date.toDateString();
    let diff = Math.floor((Date.parse(dd) - Date.parse(cd)) / 86400000);
    return diff;
  };

  const getDate = (e) => {
    const d = new Date(e);
    let dd = d.getDate();
    let mm = d.toLocaleString('en-EN', { month: 'short' });
    let yy = d.getFullYear();
    return dd + '-' + mm + '-' + yy;
  };

  return (
    <>
      <div className='creator_header'>
        <div className='creator_header__sidemenu'>
          <img
            src={images.NHeaderIcon}
            className='sidemenu_img'
            onClick={() => {
              setSidemenuCollapse();
            }}
          />
          <h1>
            {'Cheerio for business'}
          </h1>
        </div>

        <div className={`creator_header__right_container `}>
          <h4>
          {/* <h2 className={`${styles['HeaderBodyText']}`} style={{ color: colors.black }}> */}
            {`Hello, ${agentdata?.name ? agentdata?.name : localdata?.name}`}
          </h4>
          <h4>
            {getTodaysDate()}
          </h4>
          {localdata?.premium ? (
            <>
              <div className='validity_container'>
                <h4>
                  {`${localdata?.premium_details?.plan_type} plan: Valid till ${getDate(
                    localdata?.premium_details?.plan_end
                  )}`}
                </h4>
              </div>
            </>
          ) : localdata?.free_trail?.status ? (
            <>
              <h4 className='invalid_text'>
                {`Trial expiring in ${getRemainingDays(localdata.free_trail.end_date)} days`}
              </h4>
            </>
          ) : localdata?.free_trail?.used_status == true ? (
            <>
              <h4 className='invalid_text'>
                {`Free trial expired`}
              </h4>
            </>
          ) : (
            <>
              <CheerioButton
                borderStyle={{
                  borderWidth: 0,
                  marginInline: 8,
                  cursor: 'pointer',
                  display: window.innerWidth < 900 ? 'none' : 'flex',
                }}
                backColor={colors.black}
                textStyle={{ color: colors.white01 }}
                btnText={'Try free trial'}
                onclick={() => {
                  setIsFTMOpen(true);
                }}
                disabled={isPremiumUser}
              />
            </>
          )}

          <CheerioButton
            borderStyle={{
              borderWidth: 0,
              marginInline: 8,
              background: 'linear-gradient(to right, #A470FF, #F9BA28)',
              display: window.innerWidth < 900 ? 'none' : 'flex',
            }}
            textStyle={{ color: colors.white01 }}
            btnText={'Upgrade'}
            onclick={() => {
              navigate('/subscription');
            }}
          />
          <Tooltip title={'Profile'}>
            {agentdata?.profileImg || localdata?.picture ? (
            <>
            <HEImages 
              userGeneratedSrc={agentdata?.profileImg ? agentdata?.profileImg : localdata?.picture && getImagePath(localdata?.picture)}
              classname={`${styles['HeaderProfileImage']} ${localdata?.premium ? 'bordergradient' : ''}`}
              onclickAction={()=>{setProfilePopup()}}
            />
            </>
          ):(
            <>
            <img src={images.ProfilePic} className={`${styles['HeaderProfileImage']} ${localdata?.premium ? 'bordergradient' : ''}`} 
            onClick={()=>{
              setProfilePopup();
            }}></img>
            </>
          )}
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default Header;
