import React from 'react';
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import images from '../../../Utils/images';
import CustomHandle from './CustomHandle';

const handleStyle = { height: 8, width: 8, borderRadius: 4, backgroundColor: '#C10808' };

const WebhookTriggerNode = ({ data, isConnectable }) => {
  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          border: `1px solid ${colors.borderwhite}`,
          borderRadius: 16,
          backgroundColor: colors.white01,
          width: 250,
        }}
      >
        <CustomHandle
          type="target"
          position={Position.Left}
          isConnectable={isConnectable}
          style={handleStyle}
        />
        <div
          className="w-100 d-flex flex-column justify-content-start align-items-center"
          style={{
            paddingInline: 16,
            paddingBlock: 8,
            backgroundColor: '#39CDBC',
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          }}
        >
          <div
            style={{
              height: 4,
              width: '20%',
              borderRadius: 2,
              backgroundColor: colors.white,
              marginBlockEnd: 4,
            }}
          ></div>
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <p
              style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.white }}
            >
              {'Webhook Trigger'}
            </p>
            <img
              src={images.Edit}
              style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}
            ></img>
          </div>
        </div>

        <div
          className="w-100 d-flex flex-column justify-content-start align-items-center"
          style={{ paddingInline: 16, paddingBlockStart: 8, paddingBlockEnd: 16 }}
        >
          {/* <div class="form-group my-2" style={{ width: '100%' }}>
            <label
              for="exampleFormControlInput1"
              style={{
                padding: 0,
                margin: 0,
                marginBlock: 4,
                fontSize: 10,
                fontWeight: 600,
                color: colors.black,
                width: '100%',
              }}
            >
              URL
            </label>

            <input
              class="form-control shadow-none"
              placeholder="Enter value"
              style={{ borderRadius: 8, fontSize: 10, fontWeight: 400, color: colors.black }}
              value={data?.url ?? ''}
            />
          </div>
          <p
            style={{
              padding: 0,
              margin: 0,
              marginBlockEnd: 5,
              fontSize: 10,
              fontWeight: 600,
              color: colors.black,
              width: '100%',
            }}
          >
            {'Choose variables/attributes'}
          </p> */}
          {/* {data?.values?.map((val, index) => {
            return (
              <div class="w-100">
                <p style={{ fontSize: 10, fontWeight: 600 }}>Input {index + 1}</p>
                <InputGroup className="my-1">
                  <Form.Control
                    type="text"
                    placeholder={'Select'}
                    className="w-100 btncustom"
                    style={{ borderRadius: 8, fontSize: 10, fontWeight: 400, color: colors.black }}
                    // isInvalid={(renewalText.length >= 640)}
                    maxLength={640}
                    value={val ?? ''}
                    // onChange={onChangeEmail}
                  />
                </InputGroup>
              </div>
            );
          })} */}
          <div className="d-flex flex-column justify-content-start align-items-center w-100 py-2 px-2">
            {data?.values?.length > 0 ? (
              <>
                <div
                  className="d-flex flex-row justify-content-start align-items-center w-100"
                  style={{
                    borderRadius: 8,
                    backgroundColor: colors.white03,
                    marginBlock: 8,
                    paddingBlock: 6,
                    paddingInline: 16,
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 12,
                      fontWeight: 600,
                      color: colors.black,
                    }}
                  >
                    {`On Success`}
                  </p>
                  <CustomHandle
                    type="source"
                    // position={Position.Right}
                    position={Position.Right}
                    id={`child_node_${0}`}
                    style={{ ...handleStyle, top: 'auto', bottom: `calc( ${0}*16 )` }}
                    isConnectable={1}
                  />
                </div>
                <div
                  className="d-flex flex-row justify-content-start align-items-center w-100"
                  style={{
                    borderRadius: 8,
                    backgroundColor: colors.white03,
                    marginBlock: 8,
                    paddingBlock: 6,
                    paddingInline: 16,
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 12,
                      fontWeight: 600,
                      color: colors.black,
                    }}
                  >
                    {`On Failure`}
                  </p>
                  <CustomHandle
                    type="source"
                    // position={Position.Right}

                    position={Position.Right}
                    id={`child_node_${1}`}
                    style={{ ...handleStyle, top: 'auto', bottom: `calc( ${1}*16 )` }}
                    isConnectable={1}
                  />
                </div>
              </>
            ) : (
              <>
                <p
                  style={{
                    marginBlock: 0,
                    fontSize: 12,
                    color: colors.black,
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  {'Add webhook trigger'}
                </p>
              </>
            )}
          </div>
          {/* <CustomHandle
            type="source"
            position={Position.Right}
            id="Event_UserInit"
            style={handleStyle}
            isConnectable={1}
          /> */}
        </div>
      </div>
    </>
  );
};

export default WebhookTriggerNode;
