import React, { useEffect, useState } from 'react';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import AddPeopleModal from './AddPeopleModal';
import { useDispatch, useSelector } from 'react-redux';
import { V2AgentDelete, V2AgentGet, getUserApi } from '../../../Services';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import {
  toggleStatus,
  updateAgentActivity,
} from '../../../Services';
import { Row, Col, CardImg, Card, Dropdown } from 'react-bootstrap'; import PermissionsModal from './PermissionsModal';
import { eventsend } from '../../../Config/analyticsFunctions';




const People = () => {
  const [isAPModalOpen, setIsAPModalOpen] = useState(false);
  const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false);
  const [tableData, setTableData] = useState();
  const [selectedPeople, setSelectedPeople] = useState();

  const authtoken = useSelector((state) => state.main.auth_token);
  const agentdata = useSelector((state) => state.main.agentdata);
  const localData = useSelector((state) => state.main.localdata);
  const dispatch = useDispatch();
  let btnDisabled = true;


  const planLimits = {
    Basic: 0,
    Startup: 0,
    Growth: 2,
    Pro: 4,
    Enterprise: 10
  };

  const currentPlan = localData?.premiumPlanName || localData?.premium_details?.plan_type;
  const memberLimit = currentPlan.includes("App Sumo")  ? localData?.addOnSubscriptionActive ? 12 : 2 : planLimits[currentPlan] ?? planLimits['Enterprise'];

  const activeAgentsCount = tableData ? Object.values(tableData).filter(item => item?.status !== 3).length : 0;
  const canInviteMembers = !localData?.premiumPlanName || ['Growth', 'Pro', 'Enterprise'].includes(currentPlan);


  if (localData?._id === '6489675b4f598e055ed74e34' || '6480473d1ac8493f59bbc0b8') {
    btnDisabled =
      (tableData && Object?.values(tableData)?.filter((item) => item?.status !== 3).length > 9) ||
      agentdata?._id;
  } else {
    btnDisabled =
      (tableData && Object?.values(tableData)?.filter((item) => item?.status !== 3).length > 4) ||
      agentdata?._id;
  }

  const handleInviteClick = () => {
    if (!canInviteMembers) {
      toast.error(`Your ${currentPlan} plan does not allow inviting members. Upgrade your plan to invite members.`);
    } else if (activeAgentsCount >= memberLimit) {
      toast.error(`You have reached the maximum number of ${memberLimit} members allowed in your ${currentPlan} plan. Upgrade your plan to add more members.`);
    } else if (!btnDisabled) {
      console.log('invite button click');
      eventsend("invite_members_people_page_Manage_profile_clicked");
      setIsAPModalOpen(true);
    }
  };

  const updateAgentActivityApiCall = (agentId, activity) => {
    const data = {
      agentId: agentId,
      activity: activity,
    };
    updateAgentActivity(authtoken, data).then((res) => {
      if (res?.flag) {
        GetAgentsV2APICall();
      } else {
        toast.error(res?.message);
      }
    });
  };

  useEffect(() => {
    GetAgentsV2APICall();
    getuserapicall();
  }, []);

  useEffect(() => {
    if (tableData) {
      console.log('tableData === ', tableData);
    }
  }, [tableData]);

  const getuserapicall = () => {
    let token = authtoken;
    getUserApi(token).then((res) => {
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
      } else {
        console.log('error get user api ====> ', res);
      }
    });
  };

  const GetAgentsV2APICall = () => {
    let token = authtoken;
    V2AgentGet(token).then((res) => {
      if (res) {
        console.log('success response ===> ', res);
        setTableData(res?.data);
      } else {
        console.log('error response ===> ', res);
      }
    });
  };

  const DeleteAgentV2APICall = (_id) => {
    let data = {
      id: _id,
    };
    let token = authtoken;
    V2AgentDelete(data, token).then((res) => {
      if (res?.flag) {
        console.log('successful delete ', res);
      } else {
        console.log('failed to delete', res);
      }
      GetAgentsV2APICall();
    });
  };

  useEffect(() => {
    if (!isPermissionsModalOpen) {
      GetAgentsV2APICall();
    }
  }, [isPermissionsModalOpen]);

  return (
    <>
      {isAPModalOpen && <AddPeopleModal memberLimit={memberLimit} setIsAPModalOpen={setIsAPModalOpen} tableData={tableData} />}
      {isPermissionsModalOpen && (
        <PermissionsModal
          setIsPermissionsModalOpen={setIsPermissionsModalOpen}
          selectedPeople={selectedPeople}
        />
      )}
      <div className={'PeopleHeader Row'}>
        <p className={'PeopleHeaderText'}>{'People'}</p>
        <CheerioButton
          borderStyle={{}}
          textStyle={{ fontSize: 16, fontWeight: 600, color: colors.black }}
          btnText={'Invite Members'}
          backColor={colors.white}
          onclick={handleInviteClick}
          icon={images.MPPlus}
          // iconStyle={{height}}
          disabled={btnDisabled ? true : false}
        />
      </div>
      <div className="PeopleBody">
        <p className="PeopleBodyText">
          {'Add/ invite members to your account with restricted access.'}
        </p>
      </div>
      {localData?.isKylasActive && (
        <div
          style={{
            paddingInline: '30px',
            paddingBlock: '10px',
            marginBlock: '10px',
            width: '95%',
            borderRadius: '8px',
            backgroundColor: 'whitesmoke',
          }}
        >
          <p style={{ fontWeight: '700', fontSize: '16px' }}>Steps for agent to login</p>
          <p style={{ color: '#666666', fontWeight: '400', fontSize: '14px', marginTop: '5px' }}>
            1. New agents are created.<br></br> 2. You can manage agent’s access from actions.
            <br></br>
            3. For agents to access their Cheerio agent account they have to login using password.
            <br></br>4. Password: For example If agent’s name is Karan Makhija and his phone no is
            9076951243. Password will be first name followed by @ followed by agent’s phone number
            registered on Kylas In this case it is <strong>Karan@9076951243</strong>.
          </p>
        </div>
      )}
      <div className="my-2 w-100" style={{ paddingInlineStart: 30, paddingInlineEnd: 40 }}>
        <Table bordered hover>
          <thead>
            <tr style={{}}>
              <th style={{ width: '35%', backgroundColor: '#F0F2F2' }}>Email ID</th>
              <th style={{ width: '25%', backgroundColor: '#F0F2F2' }}>Name</th>
              <th style={{ width: '15%', textAlign: 'center', backgroundColor: '#F0F2F2' }}>
                Phone No
              </th>
              {agentdata?.role !== "agent" && <th style={{ width: '10%', textAlign: 'center', backgroundColor: '#F0F2F2' }}>
                Activity
              </th>}
              <th style={{ width: '10%', textAlign: 'center', backgroundColor: '#F0F2F2' }}>
                Role
              </th>
              <th style={{ width: '15%', textAlign: 'center', backgroundColor: '#F0F2F2' }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData &&
              Object.values(tableData)
                .filter((item) => item?.status !== 3)
                // .slice(0, memberLimit)
                .map((item, index) => {
                  return (
                    <tr>
                      <td>{item?.email}</td>
                      <td>{item?.name}</td>
                      <td>{item?.phone}</td>
                      {agentdata?.role !== "agent" && <td>
                        <Dropdown
                          style={{
                            borderRadius: '13px',
                            fontSize: '12px',
                          }}
                        >
                          <Dropdown.Toggle
                            className="d-flex"
                            style={{
                              backgroundColor: 'white',
                              borderRadius: '13px',
                              color: '#666666',
                              border: '1px solid #666666',
                              fontWeight: '400',
                              alignItems: 'center',
                              fontSize: '12px',
                            }}
                            variant="success"
                            id="dropdown-basic"
                          >
                            <div className="d-flex" style={{ alignItems: 'center' }}>
                              <div
                                style={{
                                  width: '8px',
                                  height: '8px',
                                  backgroundColor:
                                    item?.activity == 'online'
                                      ? '#04B100'
                                      : item?.activity == 'away'
                                        ? '#FEAF52'
                                        : '#FF0000',
                                  borderRadius: '50%',
                                  marginInlineEnd: '5px',
                                }}
                              ></div>
                              <p style={{ fontSize: '12px', marginInlineEnd: '10px' }}>
                                {item?.activity == 'online'
                                  ? 'Active'
                                  : item?.activity == 'away'
                                    ? 'Away'
                                    : 'Offline'}{' '}
                              </p>
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                updateAgentActivityApiCall(item?._id, 'online')
                              }}
                            >
                              {' '}
                              <div className="d-flex" style={{ alignItems: 'center' }}>
                                <div
                                  style={{
                                    width: '8px',
                                    height: '8px',
                                    backgroundColor: '#04B100',
                                    borderRadius: '50%',
                                    marginInlineEnd: '10px',
                                  }}
                                ></div>
                                <div>
                                  <p style={{ fontSize: '16px' }}>Active</p>
                                  <p style={{ fontSize: '12px', color: '#666666' }}>
                                    Chat will assign automatically
                                  </p>
                                </div>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                updateAgentActivityApiCall(item?._id, 'away')
                              }}
                            >
                              {' '}
                              <div className="d-flex" style={{ alignItems: 'center' }}>
                                <div
                                  style={{
                                    width: '8px',
                                    height: '8px',
                                    backgroundColor: '#FEAF52',
                                    borderRadius: '50%',
                                    marginInlineEnd: '10px',
                                  }}
                                ></div>
                                <div>
                                  <p style={{ fontSize: '16px' }}>Away</p>
                                  <p style={{ fontSize: '12px', color: '#666666' }}>
                                    Chat will not be assigned{' '}
                                  </p>
                                </div>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                updateAgentActivityApiCall(item?._id, 'offline')
                              }}
                            >
                              <div className="d-flex" style={{ alignItems: 'center' }}>
                                <div
                                  style={{
                                    width: '8px',
                                    height: '8px',
                                    backgroundColor: '#FF0000',
                                    borderRadius: '50%',
                                    marginInlineEnd: '10px',
                                  }}
                                ></div>
                                <div>
                                  <p style={{ fontSize: '16px' }}>Offline</p>
                                  <p style={{ fontSize: '12px', color: '#666666' }}>
                                    Chat will not be assigned{' '}
                                  </p>
                                </div>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>}
                      <td
                        style={{
                          textAlign: 'center',
                          color: item?.role === 'Admin' ? colors.success03 : colors.yellow03,
                        }}
                      >
                        {item?.role}
                      </td>
                      <td>
                        <div className="d-flex flex-row justify-content-center align-items-center w-100">
                          {agentdata?._id ? (
                            <>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 600,
                                  color:
                                    agentdata?._id === item?._id ? colors.linkblue : colors.black,
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  if (agentdata?._id === item?._id) {
                                    setSelectedPeople(item);
                                    setIsPermissionsModalOpen(true);
                                  }
                                }}
                              >
                                {agentdata?._id === item?._id ? 'View' : 'NA'}
                              </p>
                            </>
                          ) : (
                            <>
                              <img
                                src={images.EditSender}
                                style={{
                                  height: 20,
                                  width: 20,
                                  objectFit: 'contain',
                                  marginInline: 4,
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setSelectedPeople(item);
                                  setIsPermissionsModalOpen(true);
                                }}
                              ></img>
                              <img
                                src={images.DeleteBlack}
                                style={{
                                  height: 20,
                                  width: 20,
                                  objectFit: 'contain',
                                  marginInline: 4,
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  DeleteAgentV2APICall(item?._id);
                                }}
                              ></img>
                            </>
                          )}

                          {/* <img src={images.CANor} style={{height:20,width:20,objectFit:'contain',marginInline:4,cursor:'pointer'}}></img> */}
                        </div>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      </div >
    </>
  );
};

export default People;
