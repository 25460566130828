import React, { useEffect, useRef, useState } from 'react';
import { SpinnerComponent, WAV2Preview } from './NewComponents';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { getImagePath } from '../../../Utils';
import { useSelector } from 'react-redux';
import { getClientAttributes, V2MediaIDAPI, V2WorkflowS3Link } from '../../../Services';
import CheerioButton from '../../../Components/CheerioButton';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import CarouselCardsComponent from './CarouselCardsComponent';
import UploadValidator from '../../../Components/UploadValidator';
import UploaderText from '../../../Components/UploaderText';
import { Autocomplete, TextField } from '@mui/material';

const AnnDetails = ({
  item,
  setMediaID,
  setHeaderType,
  headerType,
  setHeaderFileName,
  URLvalue,
  setURLvalue,
  bodyPar,
  setBodyPar,
  mpm,
  setMpm,
  actionList,
  setActionList,
  headerInputText,
  setHeaderInputText,
  setHeaderInputExists,
  setPosURL,
  annv2CTA,
  setAnnv2CTA,
  setCarouselCardAPI,
  setS3Link,
  // setPosMPM,
}) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);

  const [posMPM, setPosMPM] = useState(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [count, setCount] = useState();
  const [inputFields, setInputField] = useState([]);
  const [modifiedBody, setModifiedBody] = useState();
  const [URLexists, setURLexists] = useState(false);
  // const [mpm,setMpm] = useState(false);
  const [thumbnailId, setThumbnailId] = useState();
  // const  [actionList,setActionList] = useState({
  //   thumbnail_product_retailer_id: thumbnailId,
  //   sections:[]
  // })
  const [sectionList, setSectionList] = useState([
    {
      title: '',
      product_items: [{ product_retailer_id: '', uniqueId: uuidv4() }],
    },
  ]);
  const [attributesList, setAttributesList] = useState(['name', 'email', 'phone']);
  const [productList, setProductList] = useState([{}]);
  const [title, setTitle] = useState();
  const [productId, setProductId] = useState('');
  const [mcta, setMcta] = useState([]);

  // const [URLvalue, setURLvalue] = useState('');

  // const [mediaID, setMediaID] = useState('');

  const [upFile, setUpFile] = useState();
  const [upFile64, setUpFile64] = useState('');
  const [upFileName, setUpFileName] = useState('');
  const [dragActive, setDragActive] = useState(false);

  const [carouselCards, setCarouselCards] = useState([]);

  const [carouselData, setCarouselData] = useState([]);

  const upDocRef = useRef();
  const upImageRef = useRef();
  const upVideoRef = useRef();

  const upDocRefsub = useRef();
  const upImageRefsub = useRef();
  const upVideoRefsub = useRef();

  useEffect(() => {
    setIsLoadingDetails(true);
    setActionList({ index: posMPM, thumbnail_product_retailer_id: thumbnailId, sections: [] });
    checkCarousel();
    // checkRegex();
    LoadDynamicFields();
    if (item?.components?.filter((e) => e?.type === 'BUTTONS')?.length > 0) {
      checkURL();
    }
    getUserAttributesAPICall();
  }, []);

  useEffect(() => {
    setActionList({
      index: posMPM,
      thumbnail_product_retailer_id: thumbnailId,
      sections: sectionList,
    });
  }, [thumbnailId, sectionList]);

  const BodyElement = item?.components?.filter((e) => e?.type === 'BODY');
  const HeadElement = item?.components?.filter((e) => e?.type === 'HEADER');
  const ButtonElement = item?.components?.filter((e) => e?.type === 'BUTTONS');
  const CarouselElement = item?.components?.filter((e) => e?.type === 'CAROUSEL');

  const checkCarousel = () => {
    if (CarouselElement) {
      console.log('carousel exists ---> ', CarouselElement);
      console.log('carousel exists ---> ', CarouselElement[0]?.cards);
      setCarouselCards(CarouselElement[0]?.cards);
      for (let i = 0; i < CarouselElement[0]?.cards?.length; i++) {
        setCarouselData((prev) => {
          const temparr = [...prev];
          temparr[i] = {
            header: {
              type: '',
              text: '',
              fileName: '',
              link: '',
            },
            cta: [],
            bodyParameters: [],
          };
          return temparr;
        });
      }
    }
  };

  useEffect(() => {
    if (carouselData) {
      console.log('carousel data updated ----> ', carouselData);
      setCarouselCardAPI(carouselData);
    }
  }, [carouselData]);

  const ButtonList = ButtonElement[0]?.buttons;

  useEffect(() => {
    if (ButtonElement.length > 0) {
      // checkURL();
      checkMPM();
      //console.log("////////////////",ButtonElement);
    }
  }, [ButtonElement]);

  const checkMPM = () => {
    const mpm = ButtonElement[0]?.buttons?.filter((e) => e?.type === 'MPM');
    console.log('mpmm', mpm);
    if (mpm[0]?.text) {
      setMpm(true);
      setPosMPM(ButtonElement[0]?.buttons.indexOf(mpm[0]));
    } else {
      setMpm(false);
      setPosMPM(null);
    }
  };

  const checkURL = () => {
    // const variableRegex = /\{+\d+\}+/g;
    const variableRegex = /\{\{\d+\}\}/;

    const url = ButtonElement[0]?.buttons?.filter((e) => {
      return (
        (e?.type === 'URL' && e?.example && variableRegex.test(e?.url)) ||
        (e?.type === 'COPY_CODE' && e?.example) ||
        e?.type === 'FLOW' ||
        e?.type === 'CATALOG'
      );
    });
    console.log('CHECK URL    ///////////////////////////////////////////', annv2CTA[0]?.value);
    console.log('/////////// ', url);
    // console.log('/////////// ', url[0]?.example);
    console.log('///////////////////////////////////////////');
    if (url.length > 0) {
      setURLexists(true);
      for (let i = 0; i < url.length; i++) {
        setMcta((prev) => {
          const tempmcta = [...prev];
          tempmcta[i] = {
            index: ButtonElement[0]?.buttons.indexOf(url[i]),
            type:
              url[i]?.type === 'URL'
                ? 'url'
                : url[i]?.type === 'CATALOG'
                  ? 'catalog'
                  : url[i]?.type === 'COPY_CODE'
                    ? 'copy_code'
                    : url[i]?.type === 'FLOW'
                      ? 'flow'
                      : '',
            value: annv2CTA[i]?.value ? annv2CTA[i]?.value : '',
          };
          return tempmcta;
        });
        // mcta[i] = {
        //   index: ButtonElement[0]?.buttons.indexOf(url[i]),
        //   type: 'url',
        //   value: '',
        // }
      }
      // setPosURL(ButtonElement[0]?.buttons.indexOf(url[0]));
    } else {
      setMcta([]);
    }
  };

  const getUserAttributesAPICall = () => {
    let token = authtoken;
    getClientAttributes(token).then((res) => {
      if (res?.flag) {
        setAttributesList((prev) => {
          const arr = res?.data.map((item) => item?.attribute);
          return [...prev, ...arr];
        });
        // console.log("user attributes success res ---> ",res);
      } else {
        // console.log("user attributes failed res ---> ",res);
      }
    });
  };

  useEffect(() => {
    if (mcta?.length > 0) {
      console.log('mcta changed ===> ', mcta);
      setAnnv2CTA(mcta);
    }
  }, [mcta]);

  // const checkRegex = () => {
  //   console.log(variableRegex.test("Hey {{1}} 😊,\n\nThankyou for recently buying {{2}} Products.😍\n\nTo show our appreciation🥳, we've got a special offer for you *FLAT {{3}}* discount on all products.\n\nDiscount Code : *{{4}}*.\n\nShop Now."))
  // }
  // const variableRegex = new RegExp("{{\d}}", "g");
  // const variableRegex = /\{+\d\}+/

  const LoadDynamicFields = () => {
    const variableRegex = /\{+\d+\}+/g;
    if (variableRegex.test(BodyElement[0].text)) {
      console.log('text =======>', BodyElement[0].text);
      const count = BodyElement[0]?.text?.match(variableRegex).length;
      console.log('count ======>', count);
      setCount(count);
    }

    setIsLoadingDetails(false);
  };

  useEffect(() => {
    if (count > 0) {
      let demoObj = [];
      console.log('body par exists ', bodyPar);
      console.log('body par exists ', bodyPar[1]);
      for (let i = 0; i < count; i++) {
        console.log(' for loop count ======>', i);
        if (i === 0) {
          demoObj.push({ variable: bodyPar[i] ? bodyPar[i] : `%name%` });
        } else {
          demoObj.push({ variable: bodyPar[i] ? bodyPar[i] : `{{${i + 1}}}` });
        }
      }
      setInputField(demoObj);
    }
  }, [count]);

  useEffect(() => {
    if (inputFields?.length > 0) {
      console.log(' input fields ====> ', inputFields);
      modifyBodyElement();
    }
  }, [inputFields]);

  const handleChange = (index, e) => {
    const tempObj = [...inputFields];
    tempObj[index][e.target.name] = e.target.value;
    setInputField(tempObj);
  };

  const handleAddProduct = (index) => {
    // setProductList([...productList,{}])
    const newArr = [...sectionList[index].product_items];
    //setSectionList([...sectionList[index].product_items,{}]);
    console.log('new', newArr);
    sectionList[index].product_items = [...newArr, { product_retailer_id: '', uniqueId: uuidv4() }];
    setSectionList([...sectionList]);
    console.log('lisrr', sectionList);
  };

  useEffect(() => { }, [sectionList]);

  const handleAddSection = () => {
    const newArr = [...sectionList];
    setSectionList([
      ...newArr,
      {
        title: '',
        product_items: [{}],
      },
    ]);
  };

  const handleRemoveSection = (index) => {
    setSectionList(sectionList.filter((el, i) => i !== index));
  };

  const handleRemoveProduct = (index, i) => {
    //const elem = document.getElementById(i);
    // elem.value = "";
    const newArr = [...sectionList[index].product_items];
    sectionList[index].product_items = newArr.filter((el, arrIndex) => arrIndex !== i);
    //setProductList(productList.filter((el,i)=> i!==index));
    // elem.value = sectionList[index].product_items[i].product_retailer_id;

    console.log('after delete', sectionList);
    setSectionList([...sectionList]);
  };

  // const handleProductChange = (index,e) =>{
  //   const obj = [...productList]
  //   obj[index][e.target.name]= e.target.value;
  //   console.log("objeee",obj)

  //   setProductList(obj);
  // }

  // const handleSectionChange = (index,e)=>{
  //   const obj = [...sectionList]
  //    if(productList.length !== 0 && productList[0] !== null){
  //   obj[index] = {
  //     title: e.target.value,
  //     product_items: productList
  //   }
  //   console.log("sectionn",obj);

  //   setSectionList(obj);
  //   setProductList([{}]);
  // }

  // }

  const modifyBodyElement = () => {
    let newtext = BodyElement[0].text;
    let newArr = inputFields.map((a) => a.variable);
    if (!mpm) {
      setBodyPar(newArr);
    } else {
      setBodyPar(newtext);
    }
    var count = 0;
    console.log('7777777777777777', newArr);
    const variableRegex = /\{+\d+\}+/g;
    newtext = newtext.replace(variableRegex, function ($0) {
      if (count === newArr.length) count = 0;
      return newArr[count++];
    });
    setModifiedBody(newtext);
  };

  const handleDragFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDropFile = async (e, type) => {
    console.log('handle drop triggered ----------------- ');
    e.preventDefault();
    e.stopPropagation();
    console.log('file ===> ', e.dataTransfer.files[0]);
    const file = e.dataTransfer.files[0];
    if (file && type) {
      if (UploadValidator(file, type)) {
        setUpFile(file);
        setUpFileName(file.name);
        setHeaderFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',

      });
    }

    // if (type === 'IMAGE') {
    //   if (
    //     e.dataTransfer.files &&
    //     e.dataTransfer.files[0] &&
    //     e.dataTransfer.files[0].type == 'image/jpg' || 'image/jpeg' || 'image/png'
    //   ) {
    //     handleDropUpload(e.dataTransfer.files[0], type);
    //   } else {
    //     toast.error('Error in file upload, please check file type and try again.', { position: 'top-center' });
    //   }
    // } else if (type === 'VIDEO') {
    //   if (
    //     e.dataTransfer.files &&
    //     e.dataTransfer.files[0] &&
    //     e.dataTransfer.files[0].type == 'video/*'
    //   ) {
    //     handleDropUpload(e.dataTransfer.files[0], type);
    //   } else {
    //     toast.error('Error in file upload, please check file type and try again.', { position: 'top-center' });
    //   }
    // } else if (type === 'DOCUMENT') {
    //   if (
    //     e.dataTransfer.files &&
    //     e.dataTransfer.files[0] &&
    //     e.dataTransfer.files[0].type == 'application/msword' || 'text/csv' || 'application/pdf' || 'application/vnd.ms-powerpoint'
    //   ) {
    //     handleDropUpload(e.dataTransfer.files[0], type);
    //   } else {
    //     toast.error('Error in file upload, please check file type and try again.', { position: 'top-center' });
    //   }
    // } else {
    //   toast.error('Unknown error occurred, please try again later', { position: 'top-center' });
    // }
  };

  const handleDropUpload = async (file, type) => {
    // const file = file;
    if (file.size < 5242880) {
      console.log('upload setup triggered');
      setUpFile(file);
      setUpFileName(file.name);
      if (headerType === 'document' || headerType === 'DOCUMENT') {
        setHeaderFileName(file.name);
      }
      const base64 = await convertToBase64(file);
      setUpFile64(base64);
    } else {
      toast.error('File size limit exceeded. Please check the size of the file to be uploaded.', {
        position: 'top-center',

      });
    }
  };

  const handlefileupload = async (e) => {
    const file = e.target.files[0];
    if (file && headerType) {
      if (UploadValidator(file, headerType)) {
        setUpFile(file);
        setUpFileName(file?.name);
        setHeaderFileName(file?.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',

      });
    }

    // console.log("header type: ", headerType)
    // if (headerType === 'image' || headerType === 'IMAGE') {
    //   if (file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png') {
    //     if (file.size < 5242880) {
    //       console.log("handlefile upload success triggered")
    //       setUpFile(file);
    //       setUpFileName(file.name);
    //       const base64 = await convertToBase64(file);
    //       setUpFile64(base64);
    //     } else {
    //       toast.error("File size limit exceeded. Please check the size of the file to be uploaded.", { position: 'top-center' });
    //     }
    //   } else {
    //     toast.error('Wrong file format uploaded. Only jpeg, png, and jpg are supported', {  position: 'top-center' });
    //   }
    // } else {
    //   console.log("handle file upload type fail triggered");
    //   setUpFile(file);
    //   setUpFileName(file.name);
    //   if (headerType === 'document' || headerType === 'DOCUMENT') {
    //     setHeaderFileName(file.name);
    //   }
    //   const base64 = await convertToBase64(file);
    //   setUpFile64(base64);
    // }
  };

  const handlefileupload2 = async (e, index, type) => {
    console.log('upload index ', index);
    const file = e.target.files[0];
    if (file && type) {
      if (UploadValidator(file, type)) {
        const base64 = await convertToBase64(file);
        getS3LinkC(file, base64, index, type);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',

      });
    }
  };

  const setbodyparams = (bodypar, index) => {
    setCarouselData((prev) => {
      let tempArr = [...prev];
      tempArr[index].bodyParameters = [...bodypar];
      return tempArr;
    });
  };

  const setanncta = (anncta, index) => {
    setCarouselData((prev) => {
      let tempArr = [...prev];
      tempArr[index].cta = [...anncta];
      return tempArr;
    });
  };

  const getS3LinkC = (file, file64, index, type) => {
    let data = {
      image: file64,
      type: file?.type?.split('/')[0],
      format: file?.type?.split('/')[1],
    };
    let token = authtoken;
    V2WorkflowS3Link(data, token).then((res) => {
      if (res) {
        setCarouselData((prev) => {
          let temparr = [...prev];
          temparr[index].header = {
            type: type,
            text: '',
            fileName: file.name,
            link: res?.data?.url,
          };
          return temparr;
        });
      } else {
        console.log('err s3link ---> ', res);
      }
    });
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (upFile) {
      console.log('upfile =====> ', upFile);
      getV2MediaIDapicall();
    }
  }, [upFile]);

  const getS3Link = async () => {
    const file64 = await convertToBase64(upFile);
    let data = {
      image: file64,
      type: upFile?.type?.split('/')[0],
      format: upFile?.type?.split('/')[1],
    };
    let token = authtoken;
    V2WorkflowS3Link(data, token).then((res) => {
      if (res) {
        setS3Link(res?.data?.url);
        // toast.success('file uploaded successfully', { position: 'top-center' });
      } else {
        toast.error('file uploaded S3 failed, please try again later', {
          position: 'top-center',

        });
      }
    });
  };

  const getV2MediaIDapicall = () => {
    getS3Link();
    const id = toast.loading('Uploading file. Please wait.');
    let form = new FormData();
    form.append('file', upFile);
    let token = authtoken;
    V2MediaIDAPI(form, token).then((res) => {
      if (res) {
        toast.update(id, {
          render: 'File uploaded.',
          type: 'success',
          autoClose:true,
          isLoading: false

        });
        setMediaID(res?.data?.id);
      } else {
        toast.update(id, {
          render: 'File upload failed!',
          type: 'error',
          isLoading: false,
          autoClose:true

        });
      }
    });
  };

  useEffect(() => {
    console.log('header type =====> ', HeadElement);
    if (!HeadElement[0]) {
      setHeaderType('NONE');
    } else if (HeadElement[0]?.format === 'TEXT') {
      setHeaderType('text');
      if (HeadElement[0].example) {
        setHeaderInputExists(true);
      }
    } else {
      setHeaderType(HeadElement[0]?.format);
    }
  }, [HeadElement]);

  return (
    <>
      {isLoadingDetails ? (
        <>
          <SpinnerComponent />
        </>
      ) : (
        <>
          <div
            className="ann_details_container"
          // className="w-100 d-flex flex-row justify-content-between align-items-center"
          // style={{ height: 'calc( 100% - 80px )' }}
          >
            <div
              className="ann_details_container__form"
            // className="d-flex flex-column justify-content-start align-items-center verythinscrollnew h-100"
            // style={{ width: '60%', overflowY: 'scroll', paddingInline: 16 }}
            >
              {HeadElement && (
                <>
                  {HeadElement[0]?.format === 'TEXT' && HeadElement[0].example && (
                    <>
                      <InputGroup className="mb-3" key={'headerinputtext'}>
                        <Form.Label
                          style={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                        >{`Header Text Input`}</Form.Label>
                        <p
                          style={{
                            width: '100%',
                            margin: 0,
                            padding: 0,
                            marginBlockEnd: 8,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.greys04,
                          }}
                        >
                          {`${HeadElement[0].text}`}
                        </p>
                        <Form.Control
                          type="text"
                          placeholder={
                            HeadElement[0].example.header_text
                              ? `ex : ${HeadElement[0].example.header_text}`
                              : `Enter here`
                          }
                          className="w-100 btncustom"
                          style={{ borderRadius: 8 }}
                          value={headerInputText}
                          onChange={(e) => {
                            setHeaderInputText(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </>
                  )}
                  {!mpm && HeadElement[0] && HeadElement[0]?.format !== 'TEXT' && (
                    <>
                      <InputGroup className="mb-2">
                        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                          {'Upload file'}
                        </Form.Label>
                        <div
                          className="d-flex flex-column justify-content-around align-items-center"
                          style={{
                            width: '100%',
                            border: `1px dotted ${colors.greys03}`,
                            borderRadius: 16,
                            cursor: 'pointer',
                            height: 230,
                            backgroundColor: colors.white02,
                          }}
                          onDragEnter={handleDragFile}
                          onDragExit={handleDragFile}
                          onDragOver={handleDragFile}
                          onDrop={(e) => {
                            handleDropFile(e, HeadElement[0]?.format);
                          }}
                          onClick={() => {
                            if (!upFile64) {
                              // console.log("Head element format -------- ",HeadElement)
                              if (HeadElement[0]?.format === 'IMAGE') {
                                setHeaderType('image');
                                upImageRef.current.click();
                              } else if (HeadElement[0]?.format === 'VIDEO') {
                                setHeaderType('video');
                                upVideoRef.current.click();
                              } else if (HeadElement[0]?.format === 'DOCUMENT') {
                                setHeaderType('document');
                                upDocRef.current.click();
                              }
                            }
                          }}
                        >
                          {HeadElement[0]?.format === 'VIDEO' ? (
                            <>
                              {upFile64 ? (
                                <>
                                  <video
                                    src={getImagePath(upFile64)}
                                    style={{
                                      width: 140,
                                      height: 90,
                                      objectFit: 'contain',
                                      marginTop: 20,
                                    }}
                                  ></video>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={images.ImgUpIcon}
                                    style={{
                                      width: 140,
                                      height: 90,
                                      objectFit: 'contain',
                                      marginTop: 20,
                                    }}
                                  ></img>
                                </>
                              )}
                            </>
                          ) : HeadElement[0]?.format === 'DOCUMENT' ? (
                            <>
                              <img
                                src={upFile64 ? images.SBDocument : images.ImgUpIcon}
                                style={{
                                  width: 140,
                                  height: 90,
                                  objectFit: 'contain',
                                  marginTop: 20,
                                }}
                              ></img>
                            </>
                          ) : (
                            <>
                              <img
                                src={upFile64 ? getImagePath(upFile64) : images.ImgUpIcon}
                                style={{
                                  width: 140,
                                  height: 90,
                                  objectFit: 'contain',
                                  marginTop: 20,
                                }}
                              ></img>
                            </>
                          )}

                          {upFileName ? (
                            <>
                              <p
                                style={{
                                  fontSize: 12,
                                  fontWeight: 600,
                                  color: colors.black,
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {upFileName ? upFileName : 'Upload file here'}
                                {upFileName && (
                                  <img
                                    src={images.Cross}
                                    style={{
                                      height: 18,
                                      width: 18,
                                      marginInlineStart: 24,
                                      cursor: 'pointer',
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setUpFile(null);
                                      setUpFile64(null);
                                      setUpFileName('');
                                    }}
                                  />
                                )}
                              </p>
                            </>
                          ) : (
                            <>
                              <UploaderText
                                type={HeadElement[0]?.format}
                                textStyle={{
                                  fontSize: 12,
                                  fontWeight: 600,
                                  color: colors.black,
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  wordBreak: 'break-all',
                                  textAlign: 'center',
                                }}
                              />
                            </>
                          )}
                        </div>
                      </InputGroup>
                    </>
                  )}
                </>
              )}
              <input
                type="file"
                id="DocumentUpload"
                style={{ display: 'none' }}
                ref={upDocRef}
                accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,.pdf,.pdfx,.csv"
                onChange={(e) => {
                  handlefileupload(e);
                }}
              />
              <input
                type="file"
                id="ImageUpload"
                style={{ display: 'none' }}
                ref={upImageRef}
                accept="image/*"
                onChange={(e) => {
                  handlefileupload(e);
                }}
              />
              <input
                type="file"
                id="VideoUpload"
                style={{ display: 'none' }}
                ref={upVideoRef}
                accept="video/*"
                onChange={(e) => {
                  handlefileupload(e);
                }}
              />

              {inputFields &&
                inputFields?.map((item, index) => {
                  return (
                    <>
                      {index === 0 && (
                        <>
                          <p
                            style={{
                              width: '100%',
                              margin: 0,
                              padding: 0,
                              marginBlock: 8,
                              fontSize: 16,
                              fontWeight: 400,
                              color: colors.greys04,
                            }}
                          >
                            {`%name% is the value used for dynamically adding the customer name to the variable. In case your first variable isn't name, then replace %name% with the word you would like to send for variable 1 `}
                          </p>
                        </>
                      )}
                      <InputGroup className="mb-3" key={index}>
                        <Form.Label
                          style={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                        >{`Variable ${index + 1}`}</Form.Label>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          style={{
                            marginBottom: '5px',
                          }}
                          className="w-100 btncustom"
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              name="variable"
                              size={'small'}
                              placeholder={'Enter here'}
                              className={'restylingLabels'}
                              InputProps={{
                                notched: false,
                                style: {
                                  borderRadius: 8,
                                },
                              }}
                              {...params}
                            />
                          )}
                          value={item?.variable}
                          onInputChange={(e, newValue) => {
                            setInputField((prev) => {
                              const tempObj = [...prev];
                              tempObj[index].variable = newValue;
                              return tempObj;
                            });
                          }}
                          onChange={(e, newValue) => {
                            setInputField((prev) => {
                              const tempObj = [...prev];
                              tempObj[index].variable = newValue ? `%${newValue}%` : '';
                              return tempObj;
                            });
                          }}
                          freeSolo={true}
                          options={attributesList}
                          getOptionLabel={(option) => {
                            return `${option}`; // Since we are directly mapping the phone numbers
                          }}
                        />
                      </InputGroup>
                    </>
                  );
                })}
              {URLexists &&
                ButtonElement[0]?.buttons
                  ?.filter((e) => {
                    return (
                      (e?.type === 'URL' && e?.example) || (e?.type === 'COPY_CODE' && e?.example)
                    );
                  })
                  .map((item, index) => {
                    const example = item?.example?.toString() || '';
                    return (
                      <>
                        <InputGroup className="mb-3" key={`key_${index}`}>
                        <div>
                          <Form.Label
                            style={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                          >
                            {item?.type === 'COPY_CODE'
                              ? `${item?.text}`
                              : `Call to Action URL ${index + 1}`}
                          </Form.Label>
                          {example.includes('https://web.cheerio.in/ss/') && (
              <div
                style={{
                  backgroundColor: colors.primary01,
                  color: colors.greys01,
                  padding: '10px',
                  marginBottom: '10px',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}
              >
                <img
                  src={images.InfoCircle}
                  style={{ height: 20, width: 20, objectFit: 'contain' }}
                />
                <p style={{ margin: 0 }}>
                Dynamic URL does not support Deeplinks
                </p>
              </div>
            )}
            </div>
                          {item?.type === 'URL' && (
                            <p>
                              Copy pasting the URL may not work. Please enter the URL you want to
                              use.
                            </p>
                          )}

                          <Form.Control
                            type="text"
                            placeholder={
                              item?.type === 'COPY_CODE' ? 'Enter code here' : 'Enter link here'
                            }
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={mcta[index]?.value}
                            // value={URLvalue}
                            // onInput={(e)=>{
                            //   let tempmcta = mcta;
                            //   tempmcta[index].value = e.target.value;
                            //   setMcta(tempmcta);
                            // }}
                            onChange={(e) => {
                              setMcta((prev) => {
                                const newmcta = [...prev];
                                newmcta[index].value = e.target.value;
                                return newmcta;
                              });
                              // let tempmcta = mcta;
                              // tempmcta[index].value = e.target.value;
                              // setMcta(tempmcta);
                            }}
                          />
                        </InputGroup>
                      </>
                    );
                  })}
              {console.log('mpmabc', mpm)}
              {mpm && (
                <>
                  <InputGroup className="mb-3">
                    <Form.Label
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: colors.black,
                        marginTop: '3%',
                      }}
                    >{`Thumbnail Product Id`}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter here"
                      className="w-100 btncustom"
                      style={{ borderRadius: 8 }}
                      value={thumbnailId}
                      // onChange={(e)=>{setThumbnailId(e.target.value);setHeaderType('text')}}
                      onChange={(e) => {
                        setThumbnailId(e.target.value);
                      }}
                    />
                  </InputGroup>
                  <p
                    style={{
                      alignSelf: 'flex-start',
                      fontSize: '16px',
                      fontWeight: '600',
                      marginTop: '2%',
                    }}
                  >
                    Sections
                  </p>
                  <hr style={{ width: '100%', marginTop: '-5px' }}></hr>

                  {sectionList.map((section, index) => (
                    <div
                      style={{ alignSelf: 'flex-start', width: '100%', marginTop: '2%' }}
                      key={new Date().valueOf}
                    >
                      <div className="d-flex justify-content-between" style={{}}>
                        <div className="d-flex">
                          <InputGroup className="mb-3" key={new Date().valueOf}>
                            <Form.Control
                              type="text"
                              placeholder="Enter Section Name"
                              style={{ borderRadius: 8, boxShadow: 'none' }}
                              value={section.title}
                              onChange={(e) => {
                                //handleSectionChange(index,e)
                                setSectionList((prev) => {
                                  const newArr = [...prev];
                                  newArr[index].title = e.target.value;
                                  return newArr;
                                });
                                //section.title = e.target.value;
                                console.log('sec', section);
                              }}
                            />
                          </InputGroup>

                          {/* <img  style={{marginLeft:"8px",height:"50%",paddingTop:"2px"}} src={images.EditSender}></img> */}
                        </div>

                        {sectionList.length > 1 && (
                          <img
                            style={{
                              cursor: 'pointer',
                              marginLeft: '8px',
                              height: '25px',
                              paddingTop: '2px',
                              alignItems: 'flex-end',
                            }}
                            onClick={() => handleRemoveSection(index)}
                            src={images.DeleteBlack}
                          ></img>
                        )}
                      </div>

                      {sectionList[index].product_items.map((product, i) => (
                        <div style={{ marginTop: '-18px' }}>
                          {/* <InputGroup className="mb-3" key={i}>
        
      <Form.Label style={{fontSize:14,fontWeight:600,color:colors.black}}>{`Product Id`}</Form.Label>
      {  sectionList[index].product_items.length >1 &&
    
        <img style={{border:"2px solid red",cursor:"pointer",marginLeft:"8px",height:"20px",paddingTop:"1px",alignItems:"flex-end"}}  onClick={()=>handleRemoveProduct(index,i)} src={images.DeleteBlack}></img>
    }
     <Form.Control type='text' placeholder="Enter here" className='w-100 btncustom' key={i}
        style={{borderRadius:8}}
        name = 'product_retailer_id'
        
        onChange={(e)=>{console.log("damn",i);
        //handleProductChange(index,e);
        setSectionList(prev=>{
          const newArr = [...prev]
          newArr[index].product_items[i].product_retailer_id = e.target.value;
          return newArr
        })
        product[e.target.name]=e.target.value
      }}
      />
 
  </InputGroup> */}

                          <div class="form-group mt-4" key={product.uniqueId}>
                            <label
                              for="exampleFormControlInput1"
                              style={{ fontWeight: '600', fontSize: '14px', color: 'black' }}
                            >
                              Product Id
                            </label>
                            {sectionList[index].product_items.length > 1 && (
                              <img
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '8px',
                                  height: '22px',
                                  marginBottom: '5px',
                                  alignItems: 'flex-end',
                                }}
                                onClick={() => handleRemoveProduct(index, i)}
                                src={images.DeleteBlack}
                              ></img>
                            )}
                            <input
                              name="product_retailer_id"
                              value={product.product_retailer_id}
                              onChange={(e) => {
                                {
                                  console.log('damn', i);
                                  //handleProductChange(index,e);
                                  setSectionList((prev) => {
                                    const newArr = [...prev];
                                    newArr[index].product_items[i].product_retailer_id =
                                      e.target.value;
                                    return newArr;
                                  });
                                  //product[e.target.name]=e.target.value
                                }
                              }}
                              class="form-control shadow-none"
                              placeholder="Enter here"
                              style={{ width: '502px' }}
                            />
                          </div>
                          <></>
                        </div>
                      ))}

                      {sectionList[index].product_items.length <= 30 && (
                        <div className="d-flex flex-row-reverse">
                          <CheerioButton
                            className="button"
                            borderStyle={{
                              height: '36px',
                              width: '120px',
                              marginBlockStart: 12,
                              marginBlockEnd: 8,
                              border: 'none',
                            }}
                            textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
                            btnText={'Add Product'}
                            backColor={'black'}
                            onclick={() => handleAddProduct(index)}
                          />
                        </div>
                      )}
                      <hr></hr>
                    </div>
                  ))}
                  {sectionList.length <= 10 && (
                    <div style={{ width: '100%' }} className="d-flex flex-row-reverse">
                      <CheerioButton
                        className="button"
                        borderStyle={{
                          height: '36px',
                          width: '30%',
                          marginBlockStart: 12,
                          marginBlockEnd: 8,
                        }}
                        textStyle={{ color: 'black', fontSize: 12, fontWeight: 600 }}
                        btnText={'Create a section'}
                        backColor={'white'}
                        onclick={handleAddSection}
                      />
                    </div>
                  )}
                </>
              )}

              {carouselCards &&
                carouselCards.map((item, index) => {
                  const cHeader = item?.components?.filter((e) => e?.type === 'HEADER');
                  const cBody = item?.components?.filter((e) => e?.type === 'BODY');
                  const cButton = item?.components?.filter((e) => e?.type === 'BUTTON');

                  return (
                    <CarouselCardsComponent
                      data={item}
                      index={index}
                      carouselData={carouselData}
                      handlefileupload={handlefileupload2}
                      setbodyparams={setbodyparams}
                      setanncta={setanncta}
                      attributesList={attributesList}
                      workFlow={false}
                    />
                  );
                })}
            </div>
            <div
              className="ann_details_container__preview"
            // className="d-flex flex-column justify-content-start align-items-center verythinscrollnewinv h-100"
            // style={{ width: '40%', overflowY: 'auto', paddingInline: 16, paddingBlockStart: 30 }}
            >
              <WAV2Preview
                // upimage={}
                BodyElement={modifiedBody ? modifiedBody : BodyElement[0].text}
                uptype={HeadElement[0]?.format}
                upimage={upFile64}
                ButtonList={ButtonList}
                HeaderInputText={headerInputText}
                CarouselData={carouselCards}
                CarouselInput={carouselData}
                upFile={upFile}
                HeadElement={HeadElement}
                HeaderOption={'uploadShopify'}
              // BodyElement={BodyElement[0].text}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AnnDetails;
