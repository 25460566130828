import React, { useEffect, useState } from 'react';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { delinkOpenAi, checkOpenAi, setupOpenAi } from '../../../Services';
import { Modal, ModalBody } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { eventsend } from '../../../Config/analyticsFunctions';

export const SetupOpenAi = ({ setSelectedMenuItem }) => {
    const [apiKey, setApiKey] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const [userExist, setUserExist] = useState(false);
    const authtoken = useSelector((state) => state.main.auth_token);
    const [isLoading, setIsLoading] = useState(false);
    const [assistants, setAssistants] = useState([]);

    useEffect(() => {
        checkOpenAiApiCall();
        eventsend("ChatGPT_card_Discover_page_clicked");
    }, []);

    const setupApiKey = () => {
        const data = {
            apiKey,
        };
        setupOpenAi(authtoken, data).then((res) => {
            if (res?.flag) {
                setSuccess(true);
            } else {
                toast.error(res?.message, { position: 'top-center' });
            }
        });
    };

    const checkOpenAiApiCall = () => {
        checkOpenAi(authtoken).then((res) => {
            if (res?.flag) {
                if (res?.data) {
                    setUserExist(true);
                    setApiKey(res?.data?.openAiApiKey);
                    setIsLoading(false);
                }
            } else {
                // toast.error(res?.message, { position: 'top-center' });
                setIsLoading(false);
            }
        });
    };

    const delinkUserApiCall = () => {
        delinkOpenAi(authtoken).then((res) => {
            if (res?.flag) {
                if (res?.data) {
                    toast.success('Successfully delinked', { position: 'top-right' });
                    setUserExist(false);
                    setIsLoading(false);
                    setApiKey('');
                }
            } else {
                toast.error(res?.message, { position: 'top-center' });
                setIsLoading(false);
            }
        });
    };
    return (
        <div style={{ width: '100%', padding: '2%' }}>
            {
                <>
                    <div className="d-flex" style={{ alignItems: 'center' }}>
                        <img height={47} width={47} src={images.OpenAiLogo} style={{ marginRight: '1%' }}></img>
                        <div>
                            <p style={{ fontWeight: '700', fontSize: '20px' }}>Open AI Platform Integration</p>
                            <p style={{ color: '#666666', fontSize: '16px' }}>
                                Easily configure your Custom GPTs with Cheerio
                            </p>
                        </div>
                    </div>
                    <div>
                        <p style={{ marginTop: '3%', fontWeight: '700', marginBottom: '2px' }}>Api Key</p>
                        <input
                            type="email"
                            class="form-control shadow-none"
                            placeholder="Paste here"
                            style={{ width: '40%' }}
                            value={apiKey}
                            disabled={userExist}
                            onChange={(e) => setApiKey(e.target.value)}
                        />
                        {userExist && (
                            <div className="d-flex" style={{ alignItems: 'center', marginTop: '5px' }}>
                                <img
                                    style={{ marginRight: '8px' }}
                                    src={images.CircleCheckGreen}
                                    height={16}
                                    width={16}
                                ></img>
                                <p style={{ color: '#04B100' }}>Open AI Connected</p>
                            </div>
                        )}
                        <CheerioButton
                            borderStyle={{
                                height: '36',
                                width: '15%',
                                marginBlockStart: 25,
                                marginBlockEnd: 8,
                                borderRadius: '9px',
                            }}
                            disabled={isLoading}
                            textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
                            btnText={userExist ? 'Delink' : 'Connect'}
                            backColor={'black'}
                            onclick={() => {
                                if (apiKey !== '') {
                                    setIsLoading(true);
                                    if (userExist) {
                                        delinkUserApiCall();
                                        eventsend("Delink_GPT__ChatGPT_integration_page_clicked");
                                    } else {
                                        setupApiKey();
                                        eventsend("Connect_GPT_ChatGPT_integration_page_clicked");
                                    }
                                } else {
                                    toast.error('Please enter api key!', { position: 'top-center' });
                                }
                            }}
                        />
                        {success && (
                            <div>
                                <Modal show={success} onHide={() => { checkOpenAiApiCall(); setSuccess(false) }}>
                                    <div style={{ position: 'relative', padding: '10px' }}>
                                        <ModalBody>
                                            <div
                                                className="d-flex align-items-center flex-column"
                                                style={{ marginTop: '15%', backgroundColor: 'white' }}
                                            >
                                                <img
                                                    src={images.CircleCheckGreen}
                                                    style={{
                                                        alignItems: 'center',
                                                        marginInlineEnd: 8,
                                                    }}
                                                />
                                                <p style={{ fontWeight: '700', fontSize: '16px', marginTop: '10px' }}>
                                                    Integration completed successfully{' '}
                                                </p>
                                                <p style={{ fontSize: '14px', color: 'grey', marginTop: '5px' }}>
                                                    Your Open AI account is now connected on Cheerio{' '}
                                                </p>
                                            </div>
                                        </ModalBody>
                                    </div>
                                </Modal>
                            </div>
                        )}
                    </div>
                </>
            }
        </div >
    );
};
