import React, { useEffect, useState } from 'react'
import colors from '../../../Utils/colors'
import { FiShoppingCart } from "react-icons/fi";
import { BsBank } from "react-icons/bs";
import { RiGraduationCapLine } from "react-icons/ri";
import { FaRegBuilding } from "react-icons/fa";
import { FaRegPlusSquare } from "react-icons/fa";
import { BsPeople } from "react-icons/bs";
import { FiVideo } from "react-icons/fi";
import { RiCarLine } from "react-icons/ri";
import WATemplateData from './WATemplateData.json';
import { Button } from 'react-bootstrap';
import CheerioButton from '../../../Components/CheerioButton';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const WhatsAppTemplates = () => {

    const [selectedIndustry, setSelectedIndustry] = useState('All');
    const [filteredData, setFilteredData] = useState([]);
    const navigate = useNavigate();
    const localdata = useSelector((state) => state.main.localdata);

    const SidemenuItem = ({ ImageComponent , ItemText }) => {
        return (
            <>
            <div className='w-100 px-2 mt-3'>
                <div className='d-flex flex-row justify-content-start align-items-center w-100 px-2 py-2' 
                style={{borderRadius:10,backgroundColor:selectedIndustry === ItemText ? colors.primary01 : '',cursor: 'pointer'}}
                onClick={()=>{setSelectedIndustry(ItemText)}}>
                    {ImageComponent}
                    <h4 style={{color: selectedIndustry === ItemText ? colors.primary04 : colors.black, marginInlineStart:8}}>
                        {ItemText}
                    </h4>
                </div>
            </div>
            </>
        )
    }

    const TemplateItem = ({item}) => {
        return(
            <>
            <div className='w-50 px-2 py-2' style={{height:160}}>
                <div className='d-flex flex-row justify-content-between align-items-center w-100 h-100' 
                style={{borderRadius:16,border:`1px solid ${colors.borderwhite}`,backgroundColor:colors.white01}}>
                    <div className='d-flex flex-column justify-content-start align-items-start h-100' 
                    style={{width:'70%',paddingBlock:12,paddingInline:16}}>
                        <h4>{item?.templateName}</h4>
                        <p className='mt-2 w-100' 
                        style={{color:colors.greys04,whiteSpace: 'pre-line',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'}}>
                            {item?.body}
                        </p>
                    </div>
                    <div style={{width:'30%',paddingBlock:12,paddingInline:16}} className='d-flex flex-column justify-content-end align-items-center h-100'>
                        <CheerioButton
                            borderStyle={{width:'100%'}}
                            textStyle={{color: colors.white }}
                            btnText={'View & Add'}
                            backColor={colors.black}
                            disabled={localdata?.whatsapp_config === "Active"? false : true}
                            onclick={() => {
                                navigate('/creator/templates/create',{state: { templateData: item}});
                            }}
                        />
                    </div>
                </div>
            </div>
            </>
        )
    }

    useEffect(()=>{
        if(selectedIndustry === 'All'){
            setFilteredData(WATemplateData);
        }else if(selectedIndustry){
            setFilteredData(WATemplateData.filter((item)=> item.industry === selectedIndustry));
        }
    },[selectedIndustry])

  return (
    <>
    <div className='d-flex flex-row justify-content-between align-items-center w-100 h-100'>
        <div id='WTSidemenu' className='h-100 d-flex flex-column justify-content-start align-items-center' style={{width:220,overflowY:'auto',border:`1px solid ${colors.borderwhite}`}}>
            <div className='d-flex justify-content-center align-items-center w-100 py-3' style={{borderBottom:`1px solid ${colors.borderwhite}`,cursor:'pointer'}} onClick={()=>{
                setSelectedIndustry('All');
            }}>
                <h4 style={{color:selectedIndustry === 'All' ? colors.primary04 : colors.black}}>
                    {'All templates'}
                </h4>
            </div>
            <SidemenuItem 
                ImageComponent={<FiShoppingCart size={18} color={selectedIndustry === 'Ecommerce' ? colors.primary04 : colors.black}/>}
                ItemText={'Ecommerce'}
            />
            <SidemenuItem 
                ImageComponent={<BsBank size={18} color={selectedIndustry === 'Banking' ? colors.primary04 : colors.black}/>}
                ItemText={'Banking'}
            />
            <SidemenuItem 
                ImageComponent={<RiGraduationCapLine size={18} color={selectedIndustry === 'Education' ? colors.primary04 : colors.black}/>}
                ItemText={'Education'}
            />
            <SidemenuItem 
                ImageComponent={<FaRegBuilding size={18} color={selectedIndustry === 'Real Estate' ? colors.primary04 : colors.black}/>}
                ItemText={'Real Estate'}
            />
            <SidemenuItem 
                ImageComponent={<FaRegPlusSquare size={18} color={selectedIndustry === 'Healthcare' ? colors.primary04 : colors.black}/>}
                ItemText={'Healthcare'}
            />
            {/* <SidemenuItem 
                ImageComponent={<BsPeople size={18} color={selectedIndustry === 'Services' ? colors.primary04 : colors.black}/>}
                ItemText={'Services'}
            /> */}
            <SidemenuItem 
                ImageComponent={<FiVideo size={18} color={selectedIndustry === 'Webinar' ? colors.primary04 : colors.black}/>}
                ItemText={'Webinar'}
            />
            {/* <SidemenuItem 
                ImageComponent={<RiCarLine size={18} color={selectedIndustry === 'Automobile' ? colors.primary04 : colors.black}/>}
                ItemText={'Automobile'}
            /> */}
        </div>
        <div id='WTWorkspace' className='h-100 py-2 px-2 d-flex flex-row justify-content-start align-items-start' 
        style={{width:'calc( 100% - 220px )',flexWrap:'wrap',overflowY:'auto',alignContent:'flex-start'}}>
            {filteredData && filteredData.map((item,index) => {
                return(
                    <TemplateItem 
                        item={item}
                    />
                )
            })}
        </div>
    </div>
    </>
  )
}

export default WhatsAppTemplates