import React, { useEffect, useRef, useState } from 'react'
import colors from '../../../Utils/colors'
import { ClickAwayListener } from '@mui/material'
import { useSelector } from 'react-redux';
import { getCampignCount } from '../../../Services';
import { toast } from 'react-toastify';

const AttributesModal = ({ 
  data , 
  details , 
  setOpenAttributes , 
  attributeObject , 
  selectedAttributeAction , 
  filterValue='' ,
  keyPressed='none',
  setKeyPressed,
}) => {

  const authtoken = useSelector((state) => state.main.auth_token);
  const mobileWidth = window.innerWidth < 700 ? true : false;

  // const defaultValues = ['name','phone','email']
  const defaultValues = [
    { attribute: 'name', value: data?.user_details?.name ?? 'NA'},
    { attribute: 'phone', value: data?.user_details?.phone ?? 'NA'},
    { attribute: 'email', value: details?.email ?? 'NA'}
  ]

  const [filteredList, setFilteredList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const filteredRef = useRef();

  useEffect(()=>{
    createFilteredList();
  },[filterValue])

  const createFilteredList = () => {
    let defaultFilteredList = defaultValues.filter((item,index)=>{
      if(filterValue?.length > 0){
          if(item?.attribute.includes(filterValue)){
              return item;
          }
      }else{
          return item;
      }
    })
    let attributesFilteredList = (attributeObject && Object.keys(attributeObject).length !== 0) ? Object.entries(attributeObject).filter(([key,value])=>{
      if(filterValue?.length > 0){
        if(key.includes(filterValue)){
          return [key,value]
        }
      }else{
        return [key,value]
      }
    }) : [];
    setFilteredList([...defaultFilteredList,...attributesFilteredList]);
    setSelectedIndex(0);
  }

  useEffect(()=>{
    if(keyPressed === 'Up'){
        if(selectedIndex !== 0){
            setSelectedIndex(selectedIndex-1)
            setKeyPressed('none');
        }
    }else if(keyPressed === 'Down'){
        console.log('down detected')
        if(selectedIndex < filteredList?.length - 1){
            setSelectedIndex(selectedIndex+1)
            setKeyPressed('none');
        }
    }else if(keyPressed === 'Tab'){
        // if(defaultValues.includes(filteredList[selectedIndex]?.item)){
        if(filteredList[selectedIndex]?.attribute && defaultValues.some(obj => obj?.attribute == filteredList[selectedIndex]?.attribute)){
          selectedAttributeAction(filteredList[selectedIndex]?.value)
        }else{
          selectedAttributeAction(filteredList[selectedIndex][1]?.length > 0 ? filteredList[selectedIndex][1] : 'NA')
        }
        setKeyPressed('none');
    }
  },[keyPressed])

  useEffect(()=>{
    if(selectedIndex >= 0 && filteredList?.length > 0){
        const element = filteredRef.current.querySelector(`[data-index="${selectedIndex}"]`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        }
    }
  },[selectedIndex])

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
    <ClickAwayListener onClickAway={()=>{setOpenAttributes(false)}}>
      <div style={{
        position: 'absolute',
        bottom: 60,
        zIndex:2,
        boxShadow: `0px 8px 8px 0px rgba(0,0,0,0.2)`,
        height: 200,
        width: 340,
        backgroundColor: colors.white,
        border:`1px solid ${colors.borderwhite}`,
        borderRadius:8,
        left: !mobileWidth && '50%',
        overflowY:'auto',
      }}
      className='d-flex flex-column justify-content-start align-items-start px-2 py-3 verythinscrollnew'
      >
        {filteredList && filteredList?.length > 0 && (
          <div className='w-100' ref={filteredRef}>
            <>
            {filteredList.map((item,index)=>{
              return(
                <>
                <div 
                  key={index} 
                  data-index={index} 
                  className={`w-100 ${index == selectedIndex && 'selectedIndex'}`}>
                    {defaultValues.some(obj => obj?.attribute == item?.attribute) ? (
                    // {defaultValues[attribute].includes(item?.attribute) ? (
                      <>
                      <div 
                        className='d-flex flex-row justify-content-start align-items-center w-100 my-1 px-2 py-2 onhoverattr' 
                        style={{cursor:'pointer'}} 
                        key={item?.attribute} 
                        onClick={()=>{
                          selectedAttributeAction(item?.value)
                        }}
                      >
                        <p style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden',wordBreak: 'break-all'}}>
                          {`${item?.attribute} : ${item?.value}`}
                        </p>
                      </div>
                      </>
                    ):(
                      <>
                      <div className='d-flex flex-row justify-content-start align-items-center w-100 my-1 px-2 py-2 onhoverattr' 
                	      style={{cursor:'pointer'}} 
                        key={item[0]} 
                        onClick={()=>{
                          selectedAttributeAction(item[1]?.length > 0 ? item[1] : 'NA')
                        }}
                      >
                        <p style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden',wordBreak: 'break-all'}}>
                          {`${item[0]} : ${item[1]?.length>0 ? item[1] : 'NA'}`}
                        </p>
                      </div>
                      </>
                    )}
                  </div>
                </>
              )
            })}
            </>
          </div>
        )}





        {/* {data?.user_details?.name || data?.user_details?.phone || (attributeObject && Object.keys(attributeObject).length !== 0) ? (
          <>
          {data?.user_details?.name && (filterValue?.length == 0 || "name".includes(filterValue)) &&
            <div className='d-flex flex-row justify-content-start align-items-center w-100 my-1 px-2 py-2 onhoverattr' 
            style={{cursor:'pointer'}} key={`%name`} onClick={()=>{
              selectedAttributeAction(data?.user_details?.name)
            }}>
              <p style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden',wordBreak: 'break-all'}}>
                {`name : ${data?.user_details?.name}`}
              </p>
            </div>
          }
          {data?.user_details?.phone && (filterValue?.length == 0 || "phone".includes(filterValue)) &&
            <div className='d-flex flex-row justify-content-start align-items-center w-100 my-1 px-2 py-2 onhoverattr' 
            style={{cursor:'pointer'}} key={`%phone`} onClick={()=>{
              selectedAttributeAction(data?.user_details?.phone)
            }}>
              <p style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden',wordBreak: 'break-all'}}>
                {`phone : ${data?.user_details?.phone}`}
              </p>
            </div>
          }
          {details && details?.email && (filterValue?.length == 0 || "email".includes(filterValue)) &&
            <div className='d-flex flex-row justify-content-start align-items-center w-100 my-1 px-2 py-2 onhoverattr' 
            style={{cursor:'pointer'}} key={`%email`} onClick={()=>{
              selectedAttributeAction(details?.email)
            }}>
              <p style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden',wordBreak: 'break-all'}}>
                {`email : ${details?.email}`}
              </p>
            </div>
          }
          {(attributeObject && Object.keys(attributeObject).length !== 0) && Object.entries(attributeObject).filter(([key,value])=>{
            if(filterValue?.length > 0){
              if(key.includes(filterValue)){
                return [key,value]
              }
            }else{
              return [key,value]
            }
          }).map(([key,value])=>{
            return(
              <div className='d-flex flex-row justify-content-start align-items-center w-100 my-1 px-2 py-2 onhoverattr' 
              style={{cursor:'pointer'}} key={key} onClick={()=>{
                selectedAttributeAction(value)
              }}>
                <p style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden',wordBreak: 'break-all'}}>
                  {`${key} : ${value}`}
                </p>
              </div>
            )
          })}
          </>
        ):(
          <>
          <div className='d-flex flex-row justify-content-center align-items-center w-100 my-auto'>
            <p>
              {'There are no attributes linked to this contact'}
            </p>
          </div>
          </>
        )} */}
      </div>
    </ClickAwayListener>
    
    </>
  )
}

export default AttributesModal