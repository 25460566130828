import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import colors from '../../Utils/colors';
import CheerioButton from '../../Components/CheerioButton';
import images from '../../Utils/images';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginADMIN, userSignupV2Api } from '../../Services';
import { getImagePath } from '../../Utils';
import Helper from '../../Utils/Helper';

const CreatorProfileNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileinputRef = useRef();

  const google_id = useSelector((state) => state.main.google_id);
  const user_pic = useSelector((state) => state.main?.user_pic);
  const username = useSelector((state) => state.main?.username);
  const _email = useSelector((state) => state.main?.email);
  const mobile = useSelector((state) => state.main.mobile);
  const company_name = useSelector((state) => state.main.companyname);
  const auth_token = useSelector((state) => state.main?.auth_token);

  const localdata = useSelector((state) => state.main.localdata);

  const [ownerName, setOwnerName] = useState(localdata?.username ? localdata?.username : username ? username : '');
  const [number, setNumber] = useState(localdata?.mobile ? localdata?.mobile : mobile ? mobile : '');
  const [email, setEmail] = useState(localdata?.email ? localdata?.email : _email ? _email : '');
  const [companyName, setCompanyName] = useState(localdata?.company_name ? localdata?.company_name : company_name ? company_name : '');
  const [website, setWebsite] = useState(localdata?.website_url ? localdata?.website_url : '');
  const [GSTIN, setGSTIN] = useState(localdata?.gst_no ? localdata?.gst_no : '');
  const [PAN, setPAN] = useState(localdata?.pan_no ? localdata?.pan_no : '');
  const [profilepic, setProfilepic] = useState();
  const [preview, setPreview] = useState('');
  const [authToken, setAuthToken] = useState('');

  const OKtoProceed = ownerName && number && email;

  useEffect(() => {
    if (google_id) {
      if (!localdata?._id) {
        //adminLogin();
      }
      // adminLogin();
    }
  }, []);

  // const adminLogin = () => {
  //     console.log("admin login called")
  //     let data = {
  //         email : process.env.REACT_APP_ADMIN_ID,
  //         password : process.env.REACT_APP_ADMIN_PASSWORD,
  //     }
  //     loginADMIN(data).then((res)=>{
  //         if (res.flag){
  //             setAuthToken(res.token);
  //             console.log("admin login successful")
  //         }else{
  //             console.log(res);
  //         }
  //     });
  // };

  useEffect(() => {
    if (profilepic) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        // s3UpImageApiCall();

        // console.log(reader.result);
      };
      reader.readAsDataURL(profilepic);
    } else {
      setPreview(null);
    }
  }, [profilepic]);

  useEffect(() => {
    if (preview?.length > 0) {
      dispatch({ type: 'addUserPic', payload: preview });
    }
  }, [preview]);

  useEffect(() => {
    if (localdata?.picture) {
      dispatch({ type: 'addUserPic', payload: localdata?.picture });
    }
  }, [localdata]);

  useEffect(() => {
    if (ownerName.length > 0) {
      dispatch({ type: 'addUsername', payload: ownerName });
      // eventsend("username_entered_web",{Username : ownerName});
    }
  }, [ownerName]);

  useEffect(() => {
    if (email.length > 0) {
      dispatch({ type: 'addEmail', payload: email });
      // eventsend("email_entered_web",{Email : email});
    }
  }, [email]);

  useEffect(() => {
    if (companyName.length > 0) {
      dispatch({ type: 'addCompanyname', payload: companyName });
    }
  }, [companyName]);

  const proceedtosignup = () => {
    //adminLogin();
    callSignupV2api();
  };

  useEffect(() => {
    if (authToken.length > 0) {
      dispatch({ type: 'addAuthToken', payload: authToken });
      console.log('redux authtoken added');
      callSignupV2api();
    }
  }, [authToken]);

  const callSignupV2api = () => {
    console.log("##########################")
    console.log("##########################")
    console.log(localdata)
    console.log("##########################")
    console.log("##########################")
    const current = localdata?.email ? localdata?.email : email;
    let data = {
      //  facebook_id: google_id ? google_id : number,
      facebook_id: localdata?.facebook_id ? localdata?.facebook_id : email,
      old_facebook_id: current === email ? email : current,
      fcm_token: '',
      login_type: 'mobile',
      picture: preview ? preview : '',
      name: ownerName,
      nickname: ownerName,
      gender: '',
      age_range: '',
      about_user: '',
      user_album: ['', '', '', ''],
      mobile: number,
      email: email,
      whatsapp_notification: false,
      meet_up: false,
      user_type: 'expert',
      company_name: companyName,
      pan_no: PAN ? PAN : '',
      gst_no: GSTIN ? GSTIN : '',
      website_url: website ? website : '',
      // is_v2_user: Helper.EmbeddedAI == true ? "true" : "false",
    };
    console.log('//////////////////////////////// 127');
    console.log('******************************** 127');
    console.log('data sign up api NEW ======> ', data);
    console.log('******************************** 127');
    console.log('//////////////////////////////// 127');
    let token = auth_token;
    userSignupV2Api(data, token).then((res) => {
      if (res.flag) {
        dispatch({ type: 'addUserId', payload: res.data._id });
        // ReactGA.set({UserId : res.data._id});
        // setuserid(res.data._id);
        dispatch({ type: 'addLogin', payload: true });
        console.log(res);
        console.log('signupv2 success newCreator');
        // userInterestsCall(res.data._id);
        setTimeout(() => {
          navigate('/creator');
        }, 500);
      } else {
        console.log(res);
      }
    });
  };

  return (
    <div className="container-fluid Column Container justify-content-center">
      <div className="Column Inner_Container" style={{ justifyContent: 'flex-start' }}>
        <img
          src={images.CreatorClose}
          style={{
            height: 34,
            width: 34,
            objectFit: 'contain',
            position: 'absolute',
            top: 15,
            right: 15,
            cursor: 'pointer',
          }}
          onClick={() => {
            if (OKtoProceed) {
              navigate('/creator');
            } else {
              dispatch({ type: 'redirectToLogin', payload: true });
              dispatch({ type: 'logout' });
              localStorage.removeItem('token');
              navigate('/login');
            }
          }}
        ></img>
        <h2 className="HeaderTxt">{'Edit Business Details'}</h2>
        <div
          className="Column profileScroll"
          style={{
            marginBlock: 16,
            height: '100%',
            width: '100%',
            justifyContent: 'flex-start',
            overflowY: 'scroll',
          }}
        >
          <div className="Column">
            {preview ? (
              <>
                <img
                  src={preview}
                  className="profilepic"
                  style={{ marginBlock: 8 }}
                  onClick={() => {
                    setProfilepic(null);
                  }}
                ></img>
              </>
            ) : (
              <>
                <div className="" style={{ marginLeft: 30 }}>
                  {(user_pic || localdata?.picture) ? (
                    <img
                      src={getImagePath(localdata?.picture ?? user_pic)}
                      style={{ marginBlock: 8 }}
                      className="profilepic"
                    ></img>
                  ) : (
                    <img
                      src={images.PNPicture}
                      style={{ marginBlock: 8 }}
                      className="profilepic"
                    ></img>
                  )}
                  <img
                    src={images.AddImage}
                    style={{
                      height: 30,
                      width: 30,
                      objectFit: 'contain',
                      position: 'relative',
                      left: -30,
                      bottom: -50,
                    }}
                    onClick={() => {
                      fileinputRef.current.click();
                    }}
                  />
                </div>
                {/* <img src={user_pic ? getImagePath(user_pic) : images.PNPicture} style={{marginBlock:8}} className='profilepic'></img> */}
              </>
            )}
          </div>

          <p
            className="ImageTxt"
            style={{ marginBlockEnd: 24 }}
            onClick={() => {
              fileinputRef.current.click();
            }}
          >
            {'Add a logo here'}
          </p>
          <input
            type="file"
            required
            style={{ display: 'none' }}
            ref={fileinputRef}
            accept="image/png, image/jpeg"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                setProfilepic(file);
              } else {
                setProfilepic(null);
              }
            }}
          />
          <Form className="w-100 px-1 py-1" style={{}}>
            <InputGroup className="mb-3">
              <Form.Label className="ImageTxt">{'Bussiness Owner Name*'}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter here"
                className="w-100 btncustom"
                style={{ borderRadius: 8 }}
                value={ownerName}
                onChange={(e) => {
                  setOwnerName(e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <Form.Label className="ImageTxt">{'Phone*'}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter here"
                className="w-100 btncustom"
                style={{ borderRadius: 8 }}
                value={number}
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <Form.Label className="ImageTxt">{'Email*'}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter here"
                className="w-100 btncustom"
                style={{ borderRadius: 8 }}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <Form.Label className="ImageTxt">{'Company Name*'}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter here"
                className="w-100 btncustom"
                style={{ borderRadius: 8 }}
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <Form.Label className="ImageTxt">{'Website'}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Website URL"
                className="w-100 btncustom"
                style={{ borderRadius: 8 }}
                value={website}
                onChange={(e) => {
                  setWebsite(e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <Form.Label className="ImageTxt">{'GSTIN'}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter here"
                className="w-100 btncustom"
                style={{ borderRadius: 8 }}
                value={GSTIN}
                onChange={(e) => {
                  setGSTIN(e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <Form.Label className="ImageTxt">{'PAN'}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter here"
                className="w-100 btncustom"
                style={{ borderRadius: 8 }}
                value={PAN}
                onChange={(e) => {
                  setPAN(e.target.value);
                }}
              />
            </InputGroup>
          </Form>
        </div>
        <CheerioButton
          borderStyle={{ width: '70%', marginBlock: 0, borderWidth: 0 }}
          textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
          btnText={'Save'}
          backColor={colors.primary03}
          disabled={OKtoProceed ? false : true}
          onclick={() => {
            proceedtosignup();
          }}
        />
      </div>
    </div>
  );
};

export default CreatorProfileNew;
