import React, { useEffect, useState } from 'react';
import colors from '../../Utils/colors';
import CheerioButton from '../../Components/CheerioButton';
import {
  getInvoice,
  createPaymentSubscription,
  newSignUp,
  paymentOrder,
  sendAgentOTPV2,
  sendEmailOTPV2,
  sendOTP_V3,
  sendUserOTPV2,
  userLogin,
} from '../../Services';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import OTPVerifyForSignUp from '../Auth/components/OTPVerifyForSignUp';
import { setuserid } from '../../Config/analyticsFunctions';
import images from '../../Utils/images';
import { TextField } from '@mui/material';
import PaymentSuccessModal from './Components/PaymentSuccessModal';

const mobileWidth = window.innerWidth < 700 ? true : false;

const PricingPlan = () => {
  const auth_token = useSelector((state) => state.main?.auth_token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastId = React.useRef(null);
  const planDetails = [
    // {name: "Basic", duration: "Quarterly", priceINR: '4,500', priceUSD: '56', INRID: 'plan_OBsrkWdl90hiYZ', USDID: 'plan_OSAQhGj7slsjPf'},
    {
      name: 'Basic',
      duration: 'Quarterly',
      priceINR: '4,500',
      priceUSD: '56',
      INRID: 'plan_OyCNaABf59WSMO',
      USDID: 'plan_OSAQhGj7slsjPf',
    },
    {
      name: 'Startup',
      duration: 'Quarterly',
      priceINR: '9,000',
      priceUSD: '113',
      INRID: 'plan_OyCPF8T7TiRc6A',
      USDID: 'plan_OSASamBeIYDFtB',
    },
    {
      name: 'Growth',
      duration: 'Quarterly',
      priceINR: '15,000',
      priceUSD: '188',
      INRID: 'plan_OyCQApFJFdJTqI',
      USDID: 'plan_OSATjFb7Apvowu',
    },
    {
      name: 'Pro',
      duration: 'Quarterly',
      priceINR: '20,000',
      priceUSD: '250',
      INRID: 'plan_OyCR3y5LHeMQKL',
      USDID: 'plan_OSAUdRUvleKmf6',
    },
    {
      name: 'Enterprise',
      duration: 'Quarterly',
      priceINR: '70,000',
      priceUSD: '875',
      INRID: 'plan_OyCRqbjVLaR3QG',
      USDID: 'plan_OSAVJLbMULJTU4',
    },
    {
      name: 'Basic',
      duration: 'Half-Yearly',
      priceINR: '7,500',
      priceUSD: '94',
      INRID: 'plan_OyCTRCXBKTIvg0',
      USDID: 'plan_OTJfFLMqpOMMQW',
    },
    {
      name: 'Startup',
      duration: 'Half-Yearly',
      priceINR: '15,000',
      priceUSD: '188',
      INRID: 'plan_OyCU80cFjVWIiL',
      USDID: 'plan_OTJigtGioONAId',
    },
    {
      name: 'Growth',
      duration: 'Half-Yearly',
      priceINR: '21,000',
      priceUSD: '263',
      INRID: 'plan_OyCV8j9XXqFB5z',
      USDID: 'plan_OTJlRB9Y4Cx8g6',
    },
    {
      name: 'Pro',
      duration: 'Half-Yearly',
      priceINR: '27,000',
      priceUSD: '338',
      INRID: 'plan_OyCW5dwoi4RNyw',
      USDID: 'plan_OTJnFdKl9FkqTY',
    },
    {
      name: 'Enterprise',
      duration: 'Half-Yearly',
      priceINR: '120,000',
      priceUSD: '1,500',
      INRID: 'plan_OyCWsK02DtUGGj',
      USDID: 'plan_OTJvKy4q6aMBqm',
    },
    {
      name: 'Basic',
      duration: 'Yearly',
      priceINR: '12,000',
      priceUSD: '150',
      INRID: 'plan_OyCXxb0OQKcuF2',
      USDID: 'plan_OTK3czSgCcIbtT',
    },
    {
      name: 'Startup',
      duration: 'Yearly',
      priceINR: '24,000',
      priceUSD: '300',
      INRID: 'plan_OyCa7f7nLYgGpZ',
      USDID: 'plan_OTK4xBePPvCsgZ',
    },
    {
      name: 'Growth',
      duration: 'Yearly',
      priceINR: '36,000',
      priceUSD: '450',
      INRID: 'plan_OyCac2ehZK1zsZ',
      USDID: 'plan_OTK7MlmvW0JCRR',
    },
    {
      name: 'Pro',
      duration: 'Yearly',
      priceINR: '48,000',
      priceUSD: '600',
      INRID: 'plan_OyCbEeFG2gayDo',
      USDID: 'plan_OTK9hXOmFvYmoo',
    },
    {
      name: 'Enterprise',
      duration: 'Yearly',
      priceINR: '200,000',
      priceUSD: '2,500',
      INRID: 'plan_OyCc4AbjOyMslf',
      USDID: 'plan_OTKBhayTDmzmth',
    },
  ];

  const [authToken, setAuthToken] = useState('');
  const [facebookId, setFacebookId] = useState('');
  const [password, setPassword] = useState('');
  const [planName, setPlanName] = useState('');
  const [planDuration, setPlanDuration] = useState('');
  const [currency, setCurrency] = useState('');
  const [isLogin, setIsLogin] = useState(false);

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [numbervalue, setNumberValue] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');

  const [selectedPlan, setSelectedPlan] = useState(null);

  const [session_id, setSession_id] = useState('');
  const [emailOTPsent, setEmailOTPsent] = useState(false);
  const [phoneOTPsent, setPhoneOTPsent] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [phoneOTPvalue, setPhoneOTPvalue] = useState('');
  const [emailOTPvalue, setEmailOTPvalue] = useState('');
  const [incorrectOTP, setIncorrectOTP] = useState(false);
  const [incorrectEmail, setIncorrectEmail] = useState(false);
  const [incorrectFbId, setIncorrectFbId] = useState(false);
  const [incorrectPhoneOTP, setIncorrectPhoneOTP] = useState(false);
  const [incorrectEmailOTP, setIncorrectEmailOTP] = useState(false);
  const [verificationReqSent, setVerificationReqSent] = useState(false);
  const [OTPsent, setOTPsent] = useState(false);
  const [OTPvalue, setOTPvalue] = useState('');
  const [emailOtpVerified, setEmailOtpVerified] = useState(false);
  const [phoneOtpVerified, setPhoneOtpVerified] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [newPasswordValueOne, setNewPasswordValueOne] = useState('');
  const [newPasswordValueTwo, setNewPasswordValueTwo] = useState('');
  const [PSMoalOpen, setPSModalOpen] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState();

  function getQueryParam(param) {
    let url = new URL(window.location.href);
    return url.searchParams.get(param);
  }

  useEffect(() => {
    // const planName = getQueryParam('planName');
    // const planDuration = getQueryParam('planDuration');
    // const currency = getQueryParam('currency');
    setPlanName(getQueryParam('planName'));
    setPlanDuration(getQueryParam('planDuration'));
    setCurrency(getQueryParam('currency'));
    // console.log("params received ---> ",planName,planDuration,currency)
  }, []);

  useEffect(() => {
    if (planName && planDuration && currency) {
      setSelectedPlan(
        planDetails.filter((item) => item?.name == planName && item?.duration == planDuration)[0]
      );
    }
  }, [planName, planDuration, currency]);

  const newloginaction = () => {
    let data = {
      facebook_id: facebookId,
      password: password,
    };
    toast('Logging you in', { position: 'top-center', toastId: 1 });
    userLogin(data).then((res) => {
      if (res?.flag) {
        newExistingUsers(res);
        console.log('2222222222 ', res);
        toast.dismiss(1);
        if (!toast.isActive(toastId.current)) {
          toast('Sucessfully Logged you in...', {
            toastId: 1,
            position: 'top-center',
          });
        }
      } else {
        toast.dismiss(1);
        toast('Invalid Credentials', { toastId: 1, position: 'top-center' });
        setFacebookId('');
        setPassword('');
      }
    });
  };

  const newExistingUsers = (res) => {
    if (res?.data) {
      dispatch({
        type: 'addUserId',
        payload: res?.data?.userDetails?._id,
      });
      dispatch({
        type: 'addMobile',
        payload: res?.data?.userDetails?.mobile,
      });
      dispatch({
        type: 'addUsername',
        payload: res?.data?.userDetails?.name,
      });
      dispatch({
        type: 'addCompanyname',
        payload: res?.data?.userDetails?.company_name,
      });
      dispatch({
        type: 'addUserPic',
        payload: res?.data?.userDetails?.picture,
      });
      dispatch({
        type: 'addEmail',
        payload: res?.data?.userDetails?.email,
      });
      dispatch({
        type: 'addFacebookId',
        payload: res?.data?.userDetails?.facebook_id,
      });
      dispatch({
        type: 'addNumberVerified',
        payload: res?.data?.userDetails?.number_verified,
      });
      setAuthToken(res?.data?.token);
      dispatch({ type: 'addAuthToken', payload: res?.data?.token });
      dispatch({ type: 'addLogin', payload: true });
      dispatch({
        type: 'addBalance',
        payload: res?.data?.userDetails?.balance,
      });
    }
  };

  const onchangePhone = (e, d, rv, fv) => {
    // let no = fv.substring(fv.indexOf(' ') + 1);
    // console.log('no  ', no);
    // console.log('d.dialCode  ', d.dialCode);
    // setCountryCode(d.dialCode);
    // setNumberValue(no);
    setPhoneNumber(e);
  };

  const checkRequire = (name, value) => {
    if (value) {
      return true;
    } else {
      toast(`Please enter ${name}`, { position: 'top-center' });
      // Alert.alert('',`${validatorsTxt.PleaseEnter} ${name}`);
      return false;
    }
  };

  function ValidateEmail(mail) {
    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(mail)) {
      return true;
    }
    //  toast('You have entered an invalid email address!', { position: 'top-center' });
    if (!isLogin) {
      toast.dismiss(1);
      toast('You have entered an invalid email address!', { position: 'top-center', toastId: 1 });
    }
    //setIncorrectEmail(true);
    return false;
  }

  function ValidatePassword(password) {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+\[\]{};':"\\|,.<>\/?`~])[A-Za-z\d!@#$%^&*()\-_=+\[\]{};':"\\|,.<>\/?`~]{8,}/.test(
        password
      )
    ) {
      return true;
    }
    toast(
      'You need to enter a password of minimum 8 characters with one uppercase and lowercase letter, one number, and one special character',
      { position: 'top-center' }
    );
    return false;
  }

  const newSignunaction = () => {
    if (
      checkRequire('Name', name) &&
      checkRequire('Email', email) &&
      ValidateEmail(email) &&
      ValidatePassword(password) &&
      checkRequire('Company Name', companyName)
    ) {
      if (checkRequire('Phone Number', numbervalue)) {
        sendOtpApiCall();
      }
    }
  };

  const sendOtpApiCall = () => {
    if (!toast.isActive(toastId.current)) {
      toast('Sending OTP', { toastId: 1, position: 'top-center' });
    }
    let data = {
      country_code: countryCode.length > 1 ? `+${countryCode}` : '',
      mobile: numbervalue.split('-').join(''),
      // password: password,
      email_id: email,
      // name: name,
      // company: companyName,
    };
    console.log('OTP call generating ==> ', data);
    sendOTP_V3(data)
      .then((res) => {
        if (res?.flag) {
          console.log('OTP sent Response ', res);

          setPhoneOTPsent(true);
          setEmailOTPsent(true);
          setSession_id(res?.data?.mobileSessionId);
          toast.dismiss(1);
          setTimeout(() => {
            toast('OTP Sent', { toastId: 1, position: 'top-center' });
          }, 2000);
          setIsOpen(true);
        } else {
          toast.dismiss(1);
          setTimeout(() => {
            toast.error(`Error: ${res?.message}`, {
              position: 'top-center',
              toastId: 1,
            });
          }, 2000);
        }
      })
      .catch((e) => {
        toast.dismiss(1);
        setTimeout(() => {
          toast(e, { position: 'top-center', toastId: 1 });
        }, 2000);
      });
  };

  const resendEmailOtp = (type = 'google') => {
    sendNewEmailOtp();
  };

  const sendNewEmailOtp = () => {
    let data = {
      email_id: facebookId ? facebookId : email,
    };
    console.log(data);
    sendEmailOTPV2(data).then((res) => {
      console.log('send otp res', res);
      if (res?.flag) {
        setIsOpen(true);
        setEmailOTPsent(true);
        if (forgotPassword) {
          setVerificationReqSent(true);
        }
        console.log('Verification Req Sent? : ', verificationReqSent);
      }
    });
  };

  const resendPhoneOtp = (type = 'google') => {
    sendOTPapicall();
  };

  const sendOTPapicall = () => {
    if (numbervalue?.length > 5 || facebookId?.length > 5) {
      let data = {
        country_code: `${countryCode}`,
        mobile_number: numbervalue
          ? numbervalue.split('-').join('')
          : facebookId.split('-').join(''),
      };
      console.log('send otp data sent ===> ', data);
      sendUserOTPV2(data)
        .then((res) => {
          if (res?.flag) {
            console.log('send sms otp res ====> ', res);
            setSession_id(res?.data?.session_id);
            setOTPsent(true);
            toast.dismiss(1);
            if (!toast.isActive(toastId.current)) {
              toast('OTP Sent', { position: 'top-center', toastId: 1 });
            }
            setIsOpen(true);
            // if (res?.data?.user_details?._id) {
            //   dispatch({
            //     type: "addUserId",
            //     payload: res?.data?.user_details?._id,
            //   });
            //   dispatch({
            //     type: "addMobile",
            //     payload: res?.data?.user_details?.mobile,
            //   });
            //   dispatch({
            //     type: "addUsername",
            //     payload: res?.data?.user_details?.name,
            //   });
            //   dispatch({
            //     type: "addCompanyname",
            //     payload: res?.data?.user_details?.company_name,
            //   });
            //   dispatch({
            //     type: "addUserPic",
            //     payload: res?.data?.user_details?.picture,
            //   });
            //   dispatch({
            //     type: "addGender",
            //     payload: res?.data?.user_details?.gender,
            //   });
            //   dispatch({
            //     type: "addAge",
            //     payload: res?.data?.user_details?.age_range,
            //   });
            //   dispatch({
            //     type: "addInterest",
            //     payload: res?.data?.user_details?.interests,
            //   });
            //   dispatch({
            //     type: "addEmail",
            //     payload: res?.data?.user_details?.email,
            //   });
            //   dispatch({ type: "addAuthToken", payload: res?.data?.token });
            //   dispatch({ type: "addLogin", payload: true });
            //   dispatch({
            //     type: "addBalance",
            //     payload: res?.data?.user_details?.balance,
            //   });
            // }
          } else {
            if (!toast.isActive(toastId.current)) {
              toast.error(`Error: ${res?.response.data.message}`, {
                position: 'top-center',
                toastId: 1,
              });
            }
          }
        })
        .catch((e) => {
          if (!toast.isActive(toastId.current)) {
            toast(e, { position: 'top-center', toastId: 1 });
          }
        });
    } else {
      if (!toast.isActive(toastId.current)) {
        toast('Please enter mobile number', {
          position: 'top-center',
          toastId: 1,
        });
      }
    }
  };

  const verifyOtpAction = () => {
    newSignUpApiCall();
  };

  const resetAuthStates = () => {
    setNumberValue('');
    setEmail('');
    setPassword('');
    setNewPasswordValueOne('');
    setNewPasswordValueTwo('');
    setName('');
    setCompanyName('');
    setFacebookId('');
    setPhoneOTPvalue('');
    setEmailOTPvalue('');
    setPhoneNumber('');
  };

  const newSignUpApiCall = () => {
    if (!toast.isActive(toastId.current)) {
      toast('Verifying OTPs', { toastId: 1, position: 'top-center', id: 1 });
    }
    let data = {
      country_code: `+${countryCode}`,
      mobile: numbervalue.split('-').join(''),
      sessionId: session_id,
      password: password,
      email_id: user_email,
      name: name,
      company: companyName,
      phoneOTPvalue: phoneOTPvalue,
      emailOTPvalue: emailOTPvalue,
    };
    console.log('SignUp data sending ==> ', data);
    newSignUp(data)
      .then((res) => {
        console.log('Response : ', res);
        if (res?.flag) {
          console.log('New user sign up', res);
          Beacon.loadUser(res?.data?.userData?.newUserResponse?._id, {
            name: res?.data?.userData?.newUserResponse?.company_name,
            isPremiumUser: true,
            currentPlan: 'Premium',
          });
          console.log('User ID', res?.data?.userData?.newUserResponse?._id);
          console.log('Company name ', res?.data?.userData?.newUserResponse?.company_name);
          if (res?.data?.mobileSessionId) {
            setPhoneOtpVerified(true);
          }
          toast.dismiss(1);
          setTimeout(() => {
            toast('OTP Verified', { toastId: 1, position: 'top-center' });
          }, 2000);
          setEmailOtpVerified(true);
          setOtpVerified(true);
          setIncorrectEmailOTP(false);
          setIncorrectPhoneOTP(false);
          setIsLogin(true);
          setIsOpen(false);
          setuserid(res?.data?.userData?.newUserResponse?._id);
          if (res?.data?.userData?.newUserResponse?._id) {
            dispatch({
              type: 'addUserId',
              payload: res?.data?.userData.newUserResponse._id,
            });
            dispatch({
              type: 'addMobile',
              payload: res?.data?.userData.newUserResponse.mobile,
            });
            dispatch({
              type: 'addUsername',
              payload: res?.data?.userData.newUserResponse.name,
            });
            dispatch({
              type: 'addCompanyname',
              payload: res?.data?.userData.newUserResponse.company_name,
            });
            dispatch({
              type: 'addUserPic',
              payload: res?.data?.userData.newUserResponse.picture,
            });
            dispatch({
              type: 'addEmail',
              payload: res?.data?.userData.newUserResponse.email,
            });
            dispatch({
              type: 'addFacebookId',
              payload: res?.data?.userData.newUserResponse.facebook_id,
            });
            setAuthToken(res?.data?.userData.token);
            dispatch({ type: 'addAuthToken', payload: res?.data?.userData.token });
            dispatch({ type: 'addLogin', payload: true });
            dispatch({
              type: 'addBalance',
              payload: res?.data?.userData.newUserResponse.balance,
            });
            // onclickfreetrial(res?.token)
            // if (res?.data?.userData?.newUserResponse?.isAppsumoUser) {
            //   EventEmitter.emit('appSumoWelcome');
            //   navigate('/creator');
            // }
            // onclickfreetrial(res?.data?.userData.token, res?.data?.userData?.newUserResponse._id);
            resetAuthStates();
          }
        } else {
          setIncorrectEmailOTP(true);
          if (countryCode == '91') {
            setIncorrectPhoneOTP(true);
          }
          toast.dismiss(1);
          setTimeout(() => {
            toast.error(`Error: Incorrect OTP entered`, {
              position: 'top-center',
              toastId: 1,
            });
          }, 2000);
          setTimeout(() => {
            setIncorrectEmailOTP(false);
            setIncorrectPhoneOTP(false);
          }, 6000);
        }
      })
      .catch((e) => {
        toast.dismiss(1);
        setTimeout(() => {
          toast.error(`Error: ${e}`, { position: 'top-center', toastId: 1 });
        }, 2000);
      });
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  useEffect(() => {
    if (authToken && selectedPlan && auth_token) {
      let planId = currency == 'INR' ? selectedPlan?.INRID : selectedPlan?.USDID;
      displayRazorpaySubscription(planId, null);
    }
  }, [authToken, selectedPlan]);

  const createPaymentSubscriptionAction = () => {
    let planId = currency == 'INR' ? selectedPlan?.INRID : selectedPlan?.USDID;
    let data = {
      email: email,
      planId: planId,
      currency: currency,
      phone: phoneNumber,
    };
    createPaymentSubscription(data).then((res) => {
      if (res?.flag) {
        console.log('successfully created payment ---> ', res);
        setSubscriptionId(res?.data?.subscriptionId);
        displayRazorpaySubscription(planId, res?.data?.subscriptionId);
      } else {
        console.log('failed to create payment ---> ', res);
      }
    });
  };

  async function displayRazorpaySubscription(planID, id) {
    console.log('1111111111 ', auth_token);
    console.log('1111111 1 ', planID);
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }
    const jsonPlan = {
      plan: planName,
      planId: planID,
    };
    console.log({ jsonPlan });
    let data = {};
    if (!id) {
      data = await paymentOrder(jsonPlan, auth_token);
      console.log({ data });
    }

    const options = {
      // key: 'rzp_test_DYzBKHfkjKzuOh',
      key: 'rzp_live_1IEjlHGI3q0yMR',
      subscription_id: id ?? data?.response?.subscriptionId,
      name: 'Premium',
      description: 'Premium',
      handler: function (response) {
        setPSModalOpen(true);
        // toast.success(
        //   'Your subscription has been done. You will be redirected to our home page now, kindly wait for a couple of minutes and refresh the website to see updated balance.',
        //   { position: 'top-center' }
        // );
        // setTimeout(() => {
        //   navigate('/creator');
        // }, 3000);
        // window.location.reload();
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    if (data?.flag) {
      // console.log("success")
      //   getuserapicall();
    }
  }

  async function generateInvoice(subscriptionId) {
    try {
      let data = {
        subscriptionId: subscriptionId,
      };
      const response = await getInvoice(data);
      window.open(response?.response?.data, '_blank');
    } catch (err) {
      toast.error('Unexpected error occurred, contact our support for help');
      console.log(err);
    }
  }

  return (
    <>
      <div
        className="d-flex align-items-start"
        style={{
          height: mobileWidth ? 'auto' : '100vh',
          width: '100%',
          paddingBlock: 32,
          paddingInline: 40,
          flexDirection: mobileWidth ? 'column' : 'row',
          justifyContent: mobileWidth ? 'flex-start' : 'space-between',
        }}
      >
        {PSMoalOpen && (
          <PaymentSuccessModal
            setPSModalOpen={setPSModalOpen}
            downloadInvoiceAction={() => {
              if (subscriptionId) {
                generateInvoice(subscriptionId);
              }
            }}
          />
        )}
        <div
          className="d-flex flex-column justify-content-start align-items-start"
          style={{
            width: mobileWidth ? '100%' : '50%',
            height: mobileWidth ? 'auto' : '100%',
            paddingBlock: 16,
            paddingInline: 24,
            overflowY: mobileWidth ? 'hidden' : 'auto',
          }}
        >
          <h3 style={{ color: colors.primary03, width: '100%', fontSize: 24, fontWeight: 700 }}>
            {'You’re a few steps away from investing in the best marketing tool for your business'}
          </h3>
          <p className="my-2" style={{ color: colors.greys04 }}>
            {'Here are few things to know before getting started'}
          </p>
          <p className="mt-4" style={{ color: colors.black }}>
            {'You must have admin access to your meta business manager'}
          </p>
          <ul className="my-2">
            <li style={{ color: colors.greys04 }}>
              {
                'Make sure you have an official business website with privacy policy page to proceed smoothly'
              }
            </li>
            <li style={{ color: colors.greys04 }}>
              {'Learn about allowed businesses in '}
              <span
                style={{ cursor: 'pointer', textDecorationLine: 'underline' }}
                onClick={() => {
                  window.open('https://business.whatsapp.com/policy', '_blank');
                }}
              >
                {'WhatsApp Commerce Policy'}
              </span>
            </li>
            <li style={{ color: colors.greys04 }}>
              {'If your Meta Business Manager is not verified'}
            </li>
            <li style={{ color: colors.greys04 }}>
              {'You can send proactive messages to only 250 unique contacts every 24 hours'}
            </li>
            <li style={{ color: colors.greys04 }}>
              {'You can always send unlimited replies to user-initiated conversations'}
            </li>
          </ul>
          <p className="mt-3" style={{ color: colors.black }}>
            {'You must have a phone number that does not have a WhatsApp Account'}
          </p>
          <p className="my-3" style={{ color: colors.black }}>
            {
              'For SMS Channel in India, you should have a registered DLT & header for ease of usage'
            }
          </p>
          <p className="my-3" style={{ color: colors.black }}>
            {
              'For Global SMS, you must be ready to share business documents with us to procure your sender account'
            }
          </p>
          <p className="mb-3" style={{ color: colors.black }}>
            {'For Email Channel, you must verify your sender ID (preferably corporate email ID)'}
          </p>
          <h4 className="mt-4">{'Plan you’re opting for'}</h4>
          <div
            className="my-4 py-2 px-3 d-flex flex-row justify-content-between align-items-center w-100"
            style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 12 }}
          >
            <p style={{ color: colors.black }}>
              {selectedPlan
                ? `${selectedPlan?.name} plan (${selectedPlan?.duration})`
                : 'Plan name'}
            </p>
            <p style={{ color: colors.primary03 }}>
              {selectedPlan && currency == 'INR'
                ? `₹${selectedPlan?.priceINR}`
                : selectedPlan && currency == 'USD'
                  ? `$${selectedPlan?.priceUSD}`
                  : 'Plan price'}
            </p>
          </div>
          <p className="my-2">
            {
              'This will be a recurring plan which means you will charged automatically after each plan period until you cancel your subscription from your Cheerio dashboard. You can cancel anytime you want.'
            }
          </p>
          <p className="my-4" style={{ color: colors.black }}>
            {'Still have doubts? '}
            <span
              style={{ color: colors.primary03, cursor: 'pointer' }}
              onClick={() => {
                window.open('mailto:support@cheerio.freshdesk.com?subject=Contact Us', '_blank');
              }}
            >
              {'Contact sales'}
            </span>
          </p>
        </div>
        <div
          className=""
          style={{
            width: mobileWidth ? '100%' : '50%',
            height: mobileWidth ? 'auto' : '100%',
            paddingBlock: 16,
            paddingInline: 24,
          }}
        >
          <div
            className="d-flex flex-column justify-content-between align-items-center w-100 h-100"
            style={{
              border: `1px solid ${colors.borderwhite}`,
              borderRadius: 16,
              paddingBlock: 20,
              paddingInline: 24,
            }}
          >
            <div
              className="w-100 d-flex flex-column justify-content-around align-items-start verythinscrollnewinv"
              style={{
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              <img
                src={images.PremiumDiamond}
                style={{
                  height: 224,
                  width: 224,
                  objectFit: 'contain',
                }}
              ></img>
              <div className="d-flex flex-column justify-content-start align-items-start w-100 my-3 px-1">
                <h3 className="" style={{ fontSize: 24, fontWeight: 700, color: colors.black }}>
                  {'Cheerio Premium Plan'}
                </h3>
                <p className="" style={{ fontSize: 16, fontWeight: 400, color: colors.greys04 }}>
                  {
                    'Please enter your email ID and an active WhatsApp number to proceed. We will share a curated signup link with you via both email and WhatsApp.'
                  }
                </p>

                <p className="mt-3" style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                  {'Email'}
                </p>
                <TextField
                  name="email"
                  placeholder="Enter valid Email Id"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  type="email"
                  size="small"
                  style={{ marginBlock: 8, width: '100%' }}
                  className="btncustom"
                />
                <p className="mt-3" style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                  {'Phone'}
                </p>
                <PhoneInput
                  placeholder="Enter active WhatsApp number"
                  inputStyle={{ width: '100%', height: 40, marginBlockStart: 8 }}
                  country={'in'}
                  // value={phoneNumber}
                  onChange={onchangePhone}
                />
              </div>
              <CheerioButton
                borderStyle={{ width: '100%', marginBlockStart: 16, borderColor: colors.primary03 }}
                textStyle={{ color: colors.white }}
                backColor={colors.primary03}
                btnText={'Proceed to Buy'}
                disabled={phoneNumber && email ? false : true}
                onclick={() => {
                  if (ValidateEmail(email)) {
                    console.log('btn clicked. . .');
                    createPaymentSubscriptionAction();
                    // setPSModalOpen(true);
                  }
                }}
              />
            </div>
            {/* <div className='d-flex flex-column justify-content-start align-items-center w-100'
              style={{ flex: 1, overflowY: 'auto' }}
            >
              {isLogin ? (
                <>
                  <h3 style={{ width: '100%' }}>
                    {'Fill the following details'}
                  </h3>
                  <p className='py-3' style={{ color: colors.greys04 }}>
                    {'Login using email Id / mobile number of your Cheerio account in which you want your plan to be active.'}
                  </p>
                  <div className='my-3 w-100 px-1'>
                    <h6 className="form-label"
                      style={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                    >
                      {'EMAIL ID / Contact'}
                    </h6>
                    <input
                      className="form-control"
                      placeholder="Enter here"
                      id="FacebookId"
                      value={facebookId}
                      onChange={(e) => setFacebookId(e.target.value)}
                    />
                  </div>
                  <div className='my-3 w-100 px-1'>
                    <h6 style={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                      className="form-label"
                    >
                      {'Password'}
                    </h6>
                    <div className=""
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                      }}
                    >
                      <input
                        type="password"
                        style={{}}
                        className="form-control"
                        id="UserPassword"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        minLength={8}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {emailOTPsent && phoneOTPsent && modalIsOpen ? (
                    <>
                      <OTPVerifyForSignUp
                        countryCode={countryCode}
                        setPhoneOTPvalue={setPhoneOTPvalue}
                        phoneOTPvalue={phoneOTPvalue}
                        setEmailOTPvalue={setEmailOTPvalue}
                        emailOTPvalue={emailOTPvalue}
                        verifyOtpAction={verifyOtpAction}
                        closeOtpAction={() => {
                          setIsOpen(false);
                          setEmailOTPvalue('');
                          setPhoneOTPvalue('');
                          setIncorrectEmailOTP(false);
                          setIncorrectPhoneOTP(false);
                        }}
                        number={numbervalue}
                        email={email}
                        resendEmailOtpAction={resendEmailOtp}
                        resendPhoneOtpAction={resendPhoneOtp}
                        isIndia={countryCode == '91'}
                        incorrectPhoneOTP={incorrectPhoneOTP}
                        incorrectEmailOTP={incorrectEmailOTP}
                      />
                    </>
                  ) : (
                    <>
                      <h3 style={{ width: '100%' }}>
                        {'Fill the following details'}
                      </h3>
                      <p className='py-3 w-100' style={{ color: colors.greys04 }}>
                        {'Enter your details to create a Cheerio AI account'}
                      </p>
                      <div className='my-3 w-100 px-1'>
                        <h4 className='mb-2'>{'Name'}</h4>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter here"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className='my-3 w-100 px-1'>
                        <h4 className='mb-2'>{'Phone No'}</h4>
                        <PhoneInput
                          inputStyle={{ width: '100%' }}
                          country={'in'}
                          value={phoneNumber}
                          onChange={onchangePhone}
                        />
                      </div>
                      <div className='my-3 w-100 px-1'>
                        <h4 className='mb-2'>{'EMAIL ID'}</h4>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter here"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className='my-3 w-100 px-1'>
                        <h4 className='mb-2'>Create Password</h4>
                        <input
                          type="password"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter here"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className='my-3 w-100 px-1'>
                        <h4 className='mb-2'>Company</h4>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter here"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </div>
                    </>
                  )}

                </>
              )}
            </div>
            <div className='d-flex flex-column justify-content-end align-items-center w-100'>
              <p style={{ color: colors.black }} className='my-3'>
                {isLogin ? 'Don’t have Cheerio AI account? ' : 'Already have Cheerio AI account? '}
                <span style={{ cursor: 'pointer', color: colors.primary03 }}
                  onClick={() => { setIsLogin(!isLogin) }}
                >
                  {'Click here to proceed'}
                </span>
              </p>
              <CheerioButton
                borderStyle={{ width: '100%', marginBlockStart: 16, borderColor: colors.primary03 }}
                textStyle={{ color: colors.white }}
                backColor={colors.primary03}
                btnText={'Proceed to Buy'}
                disabled={(facebookId && password) || !isLogin ? false : true}
                onclick={() => {
                  console.log("btn clicked. . .")
                  if (isLogin) {
                    newloginaction();
                  } else {
                    newSignunaction();
                  }

                }}
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingPlan;
