import React, { useEffect, useState } from 'react';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import { useSelector } from 'react-redux';
import {
  GetTemplatesV2API,
  getClientAttributes,
  setupKeywordAction,
  updateKeywordAction,
} from '../../../Services';
import { Checkbox } from '@mui/material';
import SendTemplateModal from '../../WhatsAppReplies/components/SendTemplateModal';
import SendWATemplateModal from '../../WorkFlow/Modals/SendWATemplateModal';
import RASendWAModal from './RASendWAModal';
import { toast } from 'react-toastify';
import Helper from '../../../Utils/Helper';

const TemplateAction = ({ object, options, dataObject, setDataObject, saveSuccessAction }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const [templateData, setTemplateData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isOpenTemplateModal, setIsOpenTemplateModal] = useState(false);
  const [userAttributes, setUserAttributes] = useState();
  const [btndisabled, setBtndisabled] = useState(true);
  const [editedData, setEditedData] = useState({});
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;

  useEffect(() => {
    GetTemplatesV2APIcall();
    getUserAttributesAPICall();
  }, []);

  const GetTemplatesV2APIcall = () => {
    let token = authtoken;
    let data = {
      limit: 100,
    };
    GetTemplatesV2API(data, token).then((res) => {
      if (res) {
        console.log('TemplatesV2API response: ' + res);
        setTemplateData(res?.data);
        //if (!existingData?.templateID) {
        // setSelectedTemplate(res?.data[0]?.id);
        //}
      } else {
        console.log('errror: ' + res);
        // setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (templateData?.length > 0 && dataObject?.templateID) {
      setSelectedTemplate(
        templateData.filter((item) => item.id == dataObject?.templateID)[0] ?? null
      );
    }
  }, [templateData]);

  const getUserAttributesAPICall = () => {
    let token = authtoken;
    getClientAttributes(token).then((res) => {
      if (res?.flag) {
        setUserAttributes(res?.data);
        // console.log("user attributes success res ---> ",res);
      } else {
        // console.log("user attributes failed res ---> ",res);
      }
    });
  };

  const onSelectTemplateAction = (item) => {
    if (item?.id === selectedTemplate?.id) {
      setIsOpenTemplateModal(true);
    } else {
      setSelectedTemplate(item);
    }
  };

  useEffect(() => {
    if (selectedTemplate) {
      console.log('raid changed ---> ', selectedTemplate?.id);
      if (dataObject?.templateID == selectedTemplate?.id) {
        setEditedData(dataObject);
      } else {
        setEditedData({});
      }
      // setEditedData(dataObject?.templateID ? dataObject : {});
      setIsOpenTemplateModal(true);
    }
  }, [selectedTemplate]);

  const saveKeywordAction = () => {
    let apidata = {
      action: 'sendWATemplate',
      keywords: options[0]?.keywords,
      keywordCriteria: options[0]?.criteria,
      data: { ...editedData, key: 'Template' },
    };

    if (object && 'name' in object && 'startTime' in object && 'endTime' in object) {
      apidata = {
        ...apidata,
        timeFrame: {
          name: object?.name,
          startTime: object?.startTime,
          endTime: object?.endTime,
        },
      };
    }

    if (dataObject?.templateID || Helper.HelperEditKeywordAction) {
      updateKeywordAction(apidata, authtoken, Helper.HelperKeywordActionID).then((res) => {
        if (res?.flag) {
          toast.success('keyword action updated');
          saveSuccessAction();
        } else {
          toast.error(res?.message);
        }
      });
    } else {
      setupKeywordAction(apidata, authtoken).then((res) => {
        if (res?.flag) {
          toast.success('keyword action saved');
          saveSuccessAction();
        } else {
          toast.error(res?.message);
        }
      });
    }
  };

  return (
    <>
      <div className="template_responses_container">
        <div className="template_responses_container__header_row">
          <h3>{'Template Message'}</h3>
          <CheerioButton
            borderStyle={{
              borderWidth: 0,
            }}
            textStyle={{
              fontWeight: 600,
              color: colors.white01,
            }}
            btnText={'Save'}
            backColor={colors.primary03}
            disabled={!btndisabled && options?.length > 0 ? false : true}
            onclick={() => {
              console.log('next clicked');
              console.log('selected template data ===> ', editedData);
              saveKeywordAction();
              // nextClickAction();
            }}
          />
        </div>
        <div
          className="w-100 d-flex flex-row justify-content-start align-items-start"
          style={{
            flex: 1,
            overflowY: 'scroll',
            flexWrap: 'wrap',
          }}
        >
          {templateData?.length > 0 &&
            templateData
              .filter((item, index) => item?.status === 'APPROVED')
              .map((item, index) => {
                let BodyElement = item?.components?.filter((e) => e?.type === 'BODY');
                return (
                  <>
                    <div
                      className="py-1 px-1"
                      style={{
                        width: '50%',
                        height: 125,
                        cursor: 'pointer',
                      }}
                      key={item?.id}
                      onClick={() => {
                        onSelectTemplateAction(item);
                        // setSelectedTemplate(item);
                        // setIsOpenTemplateModal(true);
                      }}
                    >
                      <div
                        className="d-flex flex-column justify-content-start align-items-center w-100 px-3 py-2 h-100 crhover"
                        style={{
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderColor:
                            selectedTemplate?.id === item?.id
                              ? colors.primary03
                              : colors.borderwhite,
                          borderRadius: 8,
                        }}
                      >
                        <div className="d-flex flex-row justify-content-start align-items-center w-100">
                          <Checkbox
                            checked={selectedTemplate?.id == item?.id}
                            color="secondary"
                            size="small"
                            sx={{
                              paddingInline: 0,
                              marginInline: 0,
                              paddingBlock: 0,
                            }}
                          />
                          <p
                            style={{
                              fontWeight: 600,
                              width: '100%',
                              marginInlineStart: 4,
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              wordBreak: 'break-all',
                            }}
                          >
                            {item?.name}
                          </p>
                        </div>
                        <div
                          style={{
                            width: '100%',
                            flex: 1,
                            overflow: 'hidden',
                            marginBlockStart: 8,
                          }}
                        >
                          <p
                            style={{
                              // width: '100%',
                              textOverflow: 'ellipsis',
                              // whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              wordBreak: 'break-all',
                            }}
                          >
                            {BodyElement[0]?.text ?? ''}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
        </div>
        {isOpenTemplateModal && (
          // <SendWATemplateModal
          //     data={editedData}
          //     setData={setEditedData}
          //     setBtnDisabled={setBtndisabled}
          //     keywordsAction={true}
          //     kaID={selectedTemplate?.id}
          // />
          <RASendWAModal
            setIsOpenTemplateModal={setIsOpenTemplateModal}
            editedData={editedData}
            setEditedData={setEditedData}
            btnDisabled={btndisabled}
            setBtnDisabled={setBtndisabled}
            kaID={selectedTemplate?.id}
            onSubmitAction={() => {
              setIsOpenTemplateModal(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default TemplateAction;
