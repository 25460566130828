import React from 'react';
import colors from '../../../Utils/colors';
import AnnStart from './AnnStart';
import AnnTemplate from './AnnTemplate';
import AnnPrePreview from './AnnPrePreview';

const AnnNewComp = () => {
  return (
    <>
      <div
        className="d-flex flex-column justify-content-between align-items-center"
        style={{
          height: '80vh',
          width: 1012,
          backgroundColor: colors.white01,
          borderRadius: 16,
          zIndex: 0,
          border: `1px solid ${colors.borderwhite}`,
          paddingInline: 24,
        }}
      >
        {/* <AnnStart /> */}
        <AnnTemplate />
        {/* <AnnPrePreview /> */}
      </div>
    </>
  );
};

export default AnnNewComp;
