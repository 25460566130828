import React, { useEffect, useState } from 'react';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { checkKylasUser, delinkKylaUser, kylasSetup } from '../../../Services';
import { Modal, ModalBody } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { saveAmazonSESConfiguration, getSESConfiguration } from '../../../Services';

export const AmazonSES = ({ setSelectedMenuItem }) => {
    const [apiKey, setApiKey] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const [agentNumber, setAgentNumber] = useState('0');
    const [userExist, setUserExist] = useState(false);
    const authtoken = useSelector((state) => state.main.auth_token);
    const [isLoading, setIsLoading] = useState(false);
    const [isCredentials, setIsCredentials] = useState(false);
    const [isEditMode, setIsEditMode] = useState(true);

    const [credentials, setCredentials] = useState({
        clientId: '',
        clientSecret: '',
        awsRegion: ''
    });
    const handleChange = (e) => {
        const { id, value } = e.target;
        setCredentials(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmitOrEdit = () => {
        if (isEditMode) {
            // Save new credentials
            let token = authtoken;
            const data = credentials;
            saveAmazonSESConfiguration(token, data).then((res) => {
                if (res?.flag) {
                    toast.success(res?.message);
                    setIsEditMode(false);
                    setIsCredentials(true);
                    setCredentials(prev => ({
                        clientId: '********',
                        clientSecret: '********',
                        awsRegion: prev.awsRegion // Keep the current AWS Region
                    }));
                } else {
                    toast.error(res?.message);
                }
            });
        } else {
            // Enter edit mode
            setIsEditMode(true);
            setCredentials(prev => ({
                clientId: '',
                clientSecret: '',
                awsRegion: prev.awsRegion // Keep the current AWS Region
            }));
        }
    };

    useEffect(() => {
        let token = authtoken;
        getSESConfiguration(token).then((res) => {
            if (res?.flag) {
                if (res?.response) {
                    // Data exists
                    setIsCredentials(true);
                    setCredentials({
                        clientId: '********',
                        clientSecret: '********',
                        awsRegion: res?.response?.awsRegion || ''
                    });
                    setIsEditMode(false);
                } else {
                    // No data, but not an error
                    setIsCredentials(false);
                    setIsEditMode(true);
                    setCredentials({
                        clientId: '',
                        clientSecret: '',
                        awsRegion: ''
                    });
                }
            } else if (res?.message && res?.message !== "No data found") {
                // Actual error occurred
                toast.error(res.message);
                setIsCredentials(false);
                setIsEditMode(true);
            } else {
                // No data found, not treated as an error
                setIsCredentials(false);
                setIsEditMode(true);
                setCredentials({
                    clientId: '',
                    clientSecret: '',
                    awsRegion: ''
                });
            }
        }).catch(error => {
            console.error("Error fetching SES configuration:", error);
            toast.error("An unexpected error occurred. Please try again.");
            setIsCredentials(false);
            setIsEditMode(true);
        });
    }, [authtoken]);

    const formStyle = {
        width: '50%',
        padding: '20px',
        borderRadius: '5px',
    };

    const formGroupStyle = {
        marginBottom: '15px',
    };

    const labelStyle = {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
        fontSize: '14px',
    };

    const inputStyle = {
        width: '100%',
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxSizing: 'border-box',
        fontSize: '14px',
    };
    return (
        <div style={{ width: '100%', padding: '2%' }}>
            {
                <>
                    <div className="d-flex" style={{ alignItems: 'center' }}>
                        <img height={47} width={47} src={images.AmazonSESLogo} style={{ marginRight: '1%' }}></img>
                        <div>
                            <p style={{ fontWeight: '700', fontSize: '20px' }}>Amazon SES</p>
                            <p style={{ color: '#666666', fontSize: '16px' }}>
                                Streamline your email marketing process with amazon’s simple emailing services
                            </p>
                        </div>
                    </div>
                    <form onSubmit={(e) => e.preventDefault()} style={formStyle}>
                        <div style={formGroupStyle}>
                            <label htmlFor="clientId" style={labelStyle}>Client ID</label>
                            <input
                                type="password"
                                id="clientId"
                                placeholder="Enter here"
                                style={inputStyle}
                                value={credentials.clientId}
                                onChange={handleChange}
                                disabled={!isEditMode}
                            />
                        </div>
                        <div style={formGroupStyle}>
                            <label htmlFor="clientSecret" style={labelStyle}>Client Secret</label>
                            <input
                                type="password"
                                id="clientSecret"
                                placeholder="Enter here"
                                style={inputStyle}
                                value={credentials.clientSecret}
                                onChange={handleChange}
                                disabled={!isEditMode}
                            />
                        </div>
                        <div style={formGroupStyle}>
                            <label htmlFor="awsRegion" style={labelStyle}>AWS Region</label>
                            <input
                                type="text"
                                id="awsRegion"
                                placeholder="Enter here"
                                style={inputStyle}
                                value={credentials.awsRegion}
                                onChange={handleChange}
                                disabled={!isEditMode}
                            />
                        </div>
                        <CheerioButton
                            borderStyle={{
                                height: '36',
                                width: '25%',
                                marginBlockStart: 25,
                                marginBlockEnd: 8,
                                borderRadius: '9px',
                                border: 'none'
                            }}
                            textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
                            btnText={isCredentials && !isEditMode ? 'Edit' : 'Submit'}
                            backColor={'#8E53F7'}
                            onclick={handleSubmitOrEdit}
                        />
                    </form>
                </>
            }
        </div>
    );
};
