import React, { useEffect, useState } from 'react'
import images from '../../../Utils/images'
import SavedResponses from './SavedResponses'
import MediaResponse from './MediaResponse'
import WorkflowAction from './WorkflowAction'
import AssignAgentAction from './AssignAgentAction'
import TemplateAction from './TemplateAction'
import colors from '../../../Utils/colors'

const SidebarItem = ({ imageSrc , itemText , onclickAction, selectedItem }) => {
    return(
        <>
        <div 
            className='ra_sidebar_item rahover' 
            onClick={onclickAction} 
            style={{backgroundColor: (selectedItem === itemText || selectedItem === itemText.toLowerCase()) && '#F5F0FF'}}
        >
            <img className='image24' 
                src={imageSrc}
            ></img>
            <h4 style={{color: (selectedItem === itemText || selectedItem === itemText.toLowerCase()) ? colors.primary03: colors.black}}>
                {itemText}
            </h4>
        </div>
        </>
    )
}

const ReplyAction = ({
    options,
    dataObject,
    setDataObject,
    saveKeywordAction,
    saveSuccessAction,
}) => {

    const [selectedItem, setSelectedItem] = useState(dataObject?.key ?? 'Saved Responses')
    const MediaValidItems = ['Image', 'Video', 'Document', 'image', 'video', 'document'];

  return (
    <>
    <div className='replyactions_container'>
        <div className='replyactions_container__sidebar'>
            <SidebarItem 
                imageSrc={selectedItem==='Saved Responses' ? images.KWCannedSel : images.KWCanned}
                itemText={'Saved Responses'}
                onclickAction={()=>setSelectedItem('Saved Responses')}
                selectedItem={selectedItem}
            />
            <SidebarItem 
                imageSrc={selectedItem==='Image' || selectedItem === 'image' ? images.KWImageSel : images.KWImage}
                itemText={'Image'}
                onclickAction={()=>setSelectedItem('Image')}
                selectedItem={selectedItem}
            />
            <SidebarItem 
                imageSrc={selectedItem==='Video' || selectedItem === 'video' ? images.KWVideoSel : images.KWVideo}
                itemText={'Video'}
                onclickAction={()=>setSelectedItem('Video')}
                selectedItem={selectedItem}
            />
            <SidebarItem 
                imageSrc={selectedItem==='Document' || selectedItem === 'document' ? images.KWDocumentSel : images.KWDocument}
                itemText={'Document'}
                onclickAction={()=>setSelectedItem('Document')}
                selectedItem={selectedItem}
            />
            <SidebarItem 
                imageSrc={selectedItem==='Template' ? images.KWTemplateSel : images.KWTemplate}
                itemText={'Template'}
                onclickAction={()=>setSelectedItem('Template')}
                selectedItem={selectedItem}
            />
            <SidebarItem 
                imageSrc={selectedItem==='Workflow' ? images.KWWorkflowSel : images.KWWorkflow}
                itemText={'Workflow'}
                onclickAction={()=>setSelectedItem('Workflow')}
                selectedItem={selectedItem}
            />
            <SidebarItem 
                imageSrc={selectedItem==='Assign chat' ? images.KWAgentSel : images.KWAgent}
                itemText={'Assign chat'}
                onclickAction={()=>setSelectedItem('Assign chat')}
                selectedItem={selectedItem}
            />
        </div>
        <div className='replyactions_container__content'>
            {selectedItem === 'Saved Responses' ? (
                <>
                <SavedResponses 
                    options={options}
                    dataObject={dataObject}
                    setDataObject={setDataObject}
                    saveSuccessAction={saveSuccessAction}
                />
                </>
            ): MediaValidItems.includes(selectedItem) ? (
                <>
                <MediaResponse 
                    options={options}
                    dataObject={dataObject}
                    setDataObject={setDataObject}
                    selectedItem={selectedItem}
                    saveSuccessAction={saveSuccessAction}
                />
                </>
            ):selectedItem === 'Workflow' ? (
                <>
                <WorkflowAction 
                    options={options}
                    dataObject={dataObject}
                    setDataObject={setDataObject}
                    saveSuccessAction={saveSuccessAction}
                />
                </>
            ):selectedItem === 'Assign chat' ? (
                <>
                <AssignAgentAction 
                    options={options}
                    dataObject={dataObject}
                    setDataObject={setDataObject}
                    saveSuccessAction={saveSuccessAction}
                />
                </>
            ):selectedItem === 'Template' ? (
                <>
                <TemplateAction 
                    options={options}
                    dataObject={dataObject}
                    setDataObject={setDataObject}
                    saveSuccessAction={saveSuccessAction}
                />
                </>
            ): (
                <>
                </>
            )}
        </div>
    </div>
    </>
  )
}

export default ReplyAction