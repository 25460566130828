import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import './../styles.css';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import {
  ShopifyEnableWebhookAPI,
  ShopifyToggleWebhookAPI,
  getShopifyDetailsApi,
  getUserApi,
} from '../../../Services';
import { useNavigate } from 'react-router-dom';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from '../../../Config/axiosConfig';
import { eventsend } from '../../../Config/analyticsFunctions';
import { EnableFlowModal } from './EnableFlowModal';

const Flows = ({ selectedMenuItem, setSelectedCampaignFlow, setSelectedMenuItem }) => {
  const localdata = useSelector((state) => state.main.localdata);
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);

  const navigate = useNavigate();

  const [label, setLabel] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [ShopifyDetails, setShopifyDetails] = useState();
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [orderFulfilled, setOrderFulfilled] = useState(false);
  const [orderCancelled, setOrderCancelled] = useState(false);
  const [orderFeedback, setOrderFeedback] = useState(false);
  const [abandonedCart, setAbandonedCart] = useState(false);
  const [enableFlow, setEnableFlow] = useState(false);
  const [apiValues, setApiValues] = useState({ rowType: '', rowBool: '', status: '' });
  const dispatch = useDispatch();

  const getuserapicall = () => {
    let token = authtoken;
    refetch();
    getUserApi(token).then((res) => {
      // console.log('get user api response ==> ', res);
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
      } else {
        navigate('/');
        toast.error(res?.message);
      }
    });
  };

  useEffect(() => {
    //   setIsLoading(true);
    //   console.log('selectedMenuItem ===> ', selectedMenuItem);
    //   getShopifyDetailsAPIcall();
    eventsend('Flow_toggles__WooCommerce_integration_page_clicked');
  }, []);
  //
  // const getShopifyDetailsAPIcall = () => {
  //   let data = {
  //     _id: localdata?._id,
  //   };
  //   let token = authtoken;
  //   getShopifyDetailsApi(data, token).then((res) => {
  //     if (res.flag) {
  //       setShopifyDetails(res?.data?.result);
  //       // setIntegrationStatus(true)
  //       // setShopifyDetails(res?.data?.result);
  //     } else {
  //       console.log('shopify details error response ===>', res);
  //       // setIntegrationStatus(false)
  //       // setIsLoading(false);
  //       // console.log("no shopify details exist ===> ",res)
  //     }
  //   });
  // };
  //
  // useEffect(() => {
  //   if (ShopifyDetails?._id) {
  //     setOrderPlaced(ShopifyDetails?.orderCreateWebhook);
  //     setOrderFulfilled(ShopifyDetails?.orderFullfillWebhook);
  //     setOrderCancelled(ShopifyDetails?.orderCancelWebhook);
  //     setOrderFeedback(ShopifyDetails?.orderFeedbackWebhook);
  //     setAbandonedCart(ShopifyDetails?.abandonCartWebhook);
  //     setIsLoading(false);
  //   }
  // }, [ShopifyDetails]);

  // useEffect(()=>{
  //     if(orderPlaced){
  //         console.log("order placed selected ===> ",orderPlaced);
  //         EnableWebhookAPIcall('orders-placed')
  //     }
  // },[orderPlaced])

  // const EnableWebhookAPIcall = ( hookloc ) => {
  //     let data = {
  //         user_id: localdata?._id,
  //         hookloc: hookloc,
  //     };
  //     let token = authtoken;
  //     ShopifyEnableWebhookAPI(data,token).then((res)=>{
  //         if(res.flag){
  //             console.log("enable webhook success response ===> ",res);
  //         }else{
  //             console.log("enable webhook failure response ===> ",res);
  //         }
  //     })
  // }

  // const ToggleWebhookAPIcall = (hookloc) => {
  //   let data = {
  //     user_id: localdata?._id,
  //     hookloc: hookloc,
  //   };
  //   let token = authtoken;
  //   ShopifyToggleWebhookAPI(data, token).then((res) => {
  //     if (res?.flag) {
  //       console.log(' successfully toggled webhook ===> ', res);
  //     } else {
  //       console.log(' failed to toggled webhook ===> ', res);
  //     }
  //   });
  // };

  const AbandonedData = [
    { _id: 1, flow: 'Abandoned cart 1' },
    { _id: 2, flow: 'Abandoned cart 2' },
    { _id: 3, flow: 'Abandoned cart 3' },
  ];
  const OrderConfirmationData = [
    { _id: 1, flow: 'Order confirmation' },
    { _id: 2, flow: 'Order follow up status' },
    { _id: 3, flow: 'Order cancel status' },
    { _id: 4, flow: 'Order delivery status' },
  ];
  const OrderPlacementData = [
    { _id: 1, flow: 'Order received' },
    { _id: 2, flow: 'Order confirmed' },
    { _id: 3, flow: 'Order cancelled' },
    { _id: 4, flow: 'Order fulfilled' },
    { _id: 5, flow: 'Reorder' },
    { _id: 6, flow: 'Order Feedback' },
  ];

  const displayData =
    selectedMenuItem == 'Abandoned cart'
      ? AbandonedData
      : selectedMenuItem == 'Order placement'
      ? OrderPlacementData
      : selectedMenuItem == 'Order confirmation' && OrderConfirmationData;

  const callGetAllFlows = async () => {
    return axiosInstance
      .get('woocommerce/getWooComData' + `?userId=${localdata?._id}`, {
        headers: {
          'x-access-token': authtoken,
        },
      })
      .then((response) => response.data);
  };
  const {
    data,
    isLoading: loading,
    isError,
    refetch,
  } = useQuery(['flowsRestGetAPI'], () => callGetAllFlows());
  if (loading) {
    return <p>Loading....</p>;
  }
  if (data.data === null) {
    return (
      <div
        className="d-flex flex-column justify-content-start align-items-center w-100 "
        style={{
          flex: 1,
          filter: data.exists ? '' : 'grayscale(1)',
          pointerEvents: data.exists ? '' : 'none',
          marginTop: '20%',
        }}
      >
        <p
          style={{
            padding: 0,
            margin: 0,
            marginBlockStart: 20,
            fontSize: 16,
            fontWeight: 400,
            color: colors.greys04,
          }}
        >
          {'Integrate WooCommerce Store to get started.'}
        </p>
      </div>
    );
  }
  const RowItem = ({ value, checked, handleChange, hookloc }) => {
    return (
      <>
        <div
          className="d-flex flex-row justify-content-center align-items-center w-100"
          style={{ height: 60 }}
        >
          <div
            className="d-flex flex-row justify-content-center align-items-center h-100"
            style={{
              width: '45%',
              paddingBlock: 14,
              paddingInline: 10,
              borderRight: '1px solid #B3B3B3',
            }}
          >
            <p
              style={{
                padding: 0,
                margin: 0,
                fontSize: 14,
                fontWeight: 400,
                color: colors.black04,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                wordBreak: 'break-all',
              }}
            >
              {value}
            </p>
          </div>

          <div
            className="d-flex flex-row justify-content-center align-items-center h-100"
            style={{
              width: '35%',
              paddingBlock: 14,
              paddingInline: 10,
              borderRight: '1px solid #B3B3B3',
            }}
          >
            <Form.Check type="switch" defaultChecked={checked} onChange={handleChange} />
          </div>

          <div
            className="d-flex flex-row justify-content-center align-items-center h-100"
            style={{ width: '20%', paddingBlock: 14, paddingInline: 10, cursor: 'pointer' }}
            onClick={() => {
              // setSelectedCampaignFlow(hookloc);
              // setSelectedMenuItem('Analytics');
            }}
          >
            <img
              src={images.FlowAnalytics}
              style={{ height: 18, width: 18, objectFit: 'contain' }}
            ></img>
            <p
              style={{
                padding: 0,
                margin: 0,
                fontSize: 14,
                fontWeight: 400,
                color: colors.linkblue,
                marginInlineStart: 16,
              }}
            >
              {'Analytics'}
            </p>
          </div>
        </div>
      </>
    );
  };
  const toggleSwitch = (rowType, rowBool) => {
    console.log(rowType, rowBool);
    eventsend('Flow_check_box__WooCommerce_integration_page_clicked');
    axiosInstance
      .post(
        'woocommerce/controltoggle',
        {
          rowType,
          rowBool,
          userId: localdata._id,
        },
        {
          headers: {
            'x-access-token': authtoken,
          },
        }
      )
      .then((res) => {
        if (res?.data) {
          getuserapicall();
          callGetAllFlows();
        }
      });
  };
  return (
    <>
      <div
        className="h-100 w-100 verythinscrollnew"
        style={{
          overflowY: 'auto',
          backgroundColor: colors.white02,
          border: `1px solid ${colors.borderwhite}`,
        }}
      >
        {loading ? (
          <>
            <div
              className="d-flex flex-column justify-content-center align-items-center w-100"
              style={{ flex: 1 }}
            >
              <SpinnerLoader />
            </div>
          </>
        ) : (
          <>
            <div
              className="d-flex flex-column justify-content-start align-items-center w-100"
              style={{ flex: 1 }}
            >
              <h2
                style={{
                  marginBlockStart: 30,
                  marginBlockEnd: 16,
                  paddingInline: 30,
                  width: '100%',
                  fontSize: 20,
                  fontWeight: 700,
                  color: colors.black,
                }}
              >
                {selectedMenuItem}
              </h2>
              <div
                className="d-flex flex-row justify-content-between align-items-center"
                style={{
                  width: 'calc( 100% - 60px )',
                  height: 40,
                  backgroundColor: '#F0F2F2',
                  marginBlockStart: 16,
                }}
              >
                <div
                  className="h-100"
                  style={{
                    width: '45%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRight: '1px solid #B3B3B3',
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 14,
                      fontWeight: 600,
                      color: colors.black04,
                    }}
                  >
                    {'Flow'}
                  </p>
                </div>
                <div
                  className="h-100"
                  style={{
                    width: '35%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRight: '1px solid #B3B3B3',
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 14,
                      fontWeight: 600,
                      color: colors.black04,
                    }}
                  >
                    {'Status'}
                  </p>
                </div>
                <div
                  className="h-100"
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 14,
                      fontWeight: 600,
                      color: colors.black04,
                    }}
                  >
                    {'Action'}
                  </p>
                </div>
              </div>

              <div
                className="d-flex flex-column justify-content-start align-items-center"
                style={{ width: 'calc( 100% - 60px )' }}
              >
                <RowItem
                  value={`Orders placement`}
                  checked={data.data['orderCreateWebhook']}
                  handleChange={(e) => {
                    if (
                      e.target.checked &&
                      localdata?.whatsapp_credentials?.wooWorflowId?.orderCreate?.status
                    ) {
                      setEnableFlow(true);
                      setApiValues({
                        rowType: 'orderCreateWebhook',
                        rowBool: e.target.checked,
                        status: 'orders-placed',
                      });
                    } else {
                      toggleSwitch('orderCreateWebhook', e.target.checked);
                    }
                  }}
                  hookloc={'orders-placed'}
                />
                <RowItem
                  value={`Orders fulfilled`}
                  checked={data.data['orderFullfillWebhook']}
                  handleChange={(e) => {
                    if (
                      e.target.checked &&
                      localdata?.whatsapp_credentials?.wooWorflowId?.orderFulfilled?.status
                    ) {
                      setEnableFlow(true);
                      setApiValues({
                        rowType: 'orderFullfillWebhook',
                        rowBool: e.target.checked,
                        status: 'orders-fulfilled',
                      });
                    } else {
                      toggleSwitch('orderFullfillWebhook', e.target.checked);
                    }
                  }}
                  hookloc={'orders-fulfilled'}
                />
                <RowItem
                  value={`Orders cancelled`}
                  checked={data.data['orderCancelWebhook']}
                  handleChange={(e) => {
                    if (
                      e.target.checked &&
                      localdata?.whatsapp_credentials?.wooWorflowId?.orderCancelled?.status
                    ) {
                      setEnableFlow(true);
                      setApiValues({
                        rowType: 'orderCancelWebhook',
                        rowBool: e.target.checked,
                        status: 'orders-cancelled',
                      });
                    } else {
                      toggleSwitch('orderCancelWebhook', e.target.checked);
                    }
                  }}
                  hookloc={'order_cancelled'}
                />
                {/* <RowItem
                  value={`Orders feedback`}
                  checked={data.data['orderFeedbackWebhook']}
                  handleChange={(e) => {
                    toggleSwitch('orderFeedbackWebhook', e.target.checked);
                  }}
                  hookloc={'order_feedback'}
                /> */}
                <RowItem
                  value={`Abandoned cart`}
                  checked={data.data['abandonCartWebhook']}
                  handleChange={(e) => {
                    //setAbandonedCart(!abandonedCart);
                    //ToggleWebhookAPIcall('abandonedcart');
                    if (
                      e.target.checked &&
                      localdata?.whatsapp_credentials?.wooWorflowId?.abandonedCart?.status
                    ) {
                      setEnableFlow(true);
                      setApiValues({
                        rowType: 'abandonCartWebhook',
                        rowBool: e.target.checked,
                        status: 'abandoned_cart',
                      });
                    } else {
                      toggleSwitch('abandonCartWebhook', e.target.checked);
                    }
                  }}
                  hookloc={'abandoned_cart'}
                />
                {/* </> */}
                {/* ) */}
                {/* })} */}
              </div>
            </div>
          </>
        )}
      </div>
      {enableFlow && (
        <EnableFlowModal
          show={enableFlow}
          setShow={setEnableFlow}
          apiCall={toggleSwitch}
          apiValues={apiValues}
          // setOrder={setOrderPlaced}
        ></EnableFlowModal>
      )}
    </>
  );
};

export default Flows;
