import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import CheerioButton from '../../../Components/CheerioButton';
import '../../Chatbot/styles.css';
import { useSelector } from 'react-redux';
import { getEnterprise, setupEnterprise } from '../../../Services';
import { toast } from 'react-toastify';
import colors from '../../../Utils/colors';

const ChatPreferences = () => {
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const navigate = useNavigate();

  const [workingDays, setWorkingDays] = useState([
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thrusday',
    'Friday',
  ]);
  const [frtLimit, setFrtLimit] = useState(15);

  useEffect(() => {
    getEnterprise(authtoken).then((res) => {
      if (res?.flag) {
        setFrtLimit(res.data.frtLimit);
      } else {
        console.log('error api --------------> ', res);
      }
    });
  }, []);

  const setupEnterpriseApiCall = () => {
    if(frtLimit < 5 || frtLimit > 60){
        toast('FRT limit should be between 5 and 60 minutes', { position: 'top-center' });
        return;
    }
    const data = {
      frtLimit: frtLimit,
    };
    setupEnterprise(authtoken, data).then((res) => {
      if (res?.flag) {
        toast('Successfully saved preferences', { position: 'top-center' });
      } else {
        console.log('error api --------------> ', res);
      }
    });
  };

  /*     const handlePreFilledWorkingDays = () => {
            const label = document.getElementById('workingButton');
            const descendent = label.getElementsByTagName('input');
            let e;
            for (let i = 0; i < descendent.length; i++) {
                e = descendent[i];
                const buttonLabel = document.querySelector(`label[for=${e.id}]`);
                if (workingDays.includes(e.value)) {
                    buttonLabel.style.backgroundColor = '#ECE1FF';
                } else {
                    buttonLabel.style.backgroundColor = '#F5F5F5';
                }
            }
        }; */

  /* const handleWorkingDays = (e) => {
        const label = document.querySelector(`label[for=${e.target.id}]`);
        const index = workingDays.indexOf(e.target.value);
        if (index >= 0) {
            //  setWorkingDays(workingDays.splice(index,1));
            setWorkingDays(workingDays.filter((day) => day !== e.target.value));
            label.style.backgroundColor = '#F5F5F5';
            label.style.border = '0px';
        } else {
            setWorkingDays((workingDays) => [...workingDays, e.target.value]);
            label.style.backgroundColor = '#ECE1FF';
            label.style.border = '1px solid black';
        }
    };
 */

  return (
    <div
      className="d-flex flex-column"
      style={{ width: '100%', overflowY: 'auto', height: '100%', scrollbarWidth: 'thin' }}
    >
      <div className="d-flex justify-content-between mt-3 pt-2">
        <div style={{ width: '70%', paddingLeft: '30px' }}>
          <h3 style={{ color: colors.black }}>Chat Preferences</h3>
          <div style={{ fontWeight: '400px', fontSize: '16px', color: '#666666', marginTop: 10 }}>
            You can set or manage your chat inbox settings, add your preferences.
          </div>
        </div>
      </div>
      <hr />

      {/* New div Element */}
      {/* <div
                className="pt-2 px-3"
                style={{
                    border: '1px solid #E6E6E6',
                    marginLeft: '20px',
                    marginRight: '20px',
                    borderRadius: '16px',
                    height: '480px',
                    backgroundColor: 'white',
                }}
            >
                <div style={{ fontWeight: '700', fontSize: '16px', color: 'black', marginTop: '10px' }}>
                    Working Hours
                </div>

                <div class="form-group mt-4">
                    <label
                        for="exampleFormControlInput1"
                        style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                    >
                        Office Start Time
                    </label>
                    <input
                        disabled={checkDetails}
                        value={offStartTime}
                        onChange={(e) => {
                            setOffStartTime(e.target.value);
                        }}
                        class="form-control shadow-none"
                        placeholder="09:00AM"
                        style={{ width: '502px' }}
                    />
                </div>
                <div class="form-group mt-3">
                    <label
                        for="exampleFormControlInput1"
                        style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                    >
                        Office End Time
                    </label>
                    <input
                        disabled={checkDetails}
                        value={offEndTime}
                        onChange={(e) => {
                            setOffEndTime(e.target.value);
                        }}
                        class="form-control shadow-none"
                        placeholder="06:00AM"
                        style={{ width: '502px' }}
                    />
                </div>
                <div className="mt-3">
                    <label style={{ fontSize: '16px', fontWeight: '600' }}>Working days</label>
                </div>
                <div class="btn-group mt-3" id="workingButton" role="group" data-bs-toggle="button">
                    <input
                        type="checkbox"
                        disabled={checkDetails}
                        class="btn-check"
                        id="btncheck1"
                        value="Monday"
                        autocomplete="off"
                        onChange={(e) => handleWorkingDays(e)}
                    />
                    <label
                        class="btn mx-2 rounded-5 d-flex align-items-center"
                        for="btncheck1"
                        style={{
                            height: '32px',
                            width: '32px',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: 'black',
                            backgroundColor: '#ECE1FF',
                            border: '1px solid black',
                            paddingLeft: '9px',
                        }}
                    >
                        M
                    </label>

                    <input
                        type="checkbox"
                        disabled={checkDetails}
                        class="btn-check"
                        id="btncheck2"
                        autocomplete="off"
                        value="Tuesday"
                        onChange={(e) => handleWorkingDays(e)}
                    />
                    <label
                        class="btn mx-2 rounded-5 d-flex align-items-center"
                        for="btncheck2"
                        style={{
                            height: '32px',
                            width: '32px',
                            paddingLeft: '11px',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: 'black',
                            backgroundColor: '#ECE1FF',
                            border: '1px solid black',
                        }}
                    >
                        T
                    </label>

                    <input
                        type="checkbox"
                        disabled={checkDetails}
                        class="btn-check"
                        id="btncheck3"
                        value="Wednesday"
                        onChange={(e) => handleWorkingDays(e)}
                        autocomplete="off"
                    />
                    <label
                        class="btn mx-2 rounded-5 d-flex align-items-center"
                        for="btncheck3"
                        style={{
                            height: '32px',
                            width: '32px',
                            paddingLeft: '9px',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: 'black',
                            backgroundColor: '#ECE1FF',
                            border: '1px solid black',
                        }}
                    >
                        W
                    </label>

                    <input
                        type="checkbox"
                        disabled={checkDetails}
                        class="btn-check"
                        id="btncheck4"
                        value="Thrusday"
                        onChange={(e) => handleWorkingDays(e)}
                        autocomplete="off"
                    />
                    <label
                        class="btn mx-2 rounded-5 d-flex align-items-center"
                        for="btncheck4"
                        style={{
                            height: '32px',
                            width: '32px',
                            paddingLeft: '12px',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: 'black',
                            backgroundColor: '#ECE1FF',
                            border: '1px solid black',
                        }}
                    >
                        T
                    </label>

                    <input
                        type="checkbox"
                        disabled={checkDetails}
                        class="btn-check"
                        id="btncheck5"
                        value="Friday"
                        onChange={(e) => handleWorkingDays(e)}
                        autocomplete="off"
                    />
                    <label
                        class="btn  mx-2 rounded-5 d-flex align-items-center"
                        for="btncheck5"
                        style={{
                            height: '32px',
                            width: '32px',
                            paddingLeft: '12px',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: 'black',
                            backgroundColor: '#ECE1FF',
                            border: '1px solid black',
                        }}
                    >
                        F
                    </label>

                    <input
                        type="checkbox"
                        disabled={checkDetails}
                        class="btn-check"
                        id="btncheck6"
                        value="Saturday"
                        onChange={(e) => handleWorkingDays(e)}
                        autocomplete="off"
                    />
                    <label
                        class="btn mx-2 rounded-5 d-flex align-items-center"
                        for="btncheck6"
                        style={{
                            height: '32px',
                            width: '32px',
                            paddingLeft: '12px',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: 'black',
                            backgroundColor: '#F5F5F5',
                            border: '1px solid black',
                        }}
                    >
                        S
                    </label>

                    <input
                        type="checkbox"
                        disabled={checkDetails}
                        class="btn-check"
                        id="btncheck7"
                        value="Sunday"
                        onChange={(e) => handleWorkingDays(e)}
                        autocomplete="off"
                    />
                    <label
                        class="btn mx-2 rounded-5 d-flex align-items-center"
                        for="btncheck7"
                        style={{
                            height: '32px',
                            width: '32px',
                            paddingLeft: '12px',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: 'black',
                            backgroundColor: '#F5F5F5',
                            border: '0px',
                        }}
                    >
                        S
                    </label>
                </div>
                <hr class="hr" />
                <div style={{ fontSize: '16px', fontWeight: '700', color: 'black', marginBottom: '20px' }}>
                    Auto reply after
                </div>
                <div style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}>Select timing</div>
                <div style={{ marginBottom: '40px' }}>
                    <select
                        disabled={checkDetails}
                        onChange={(e) => {
                            setTiming(e.target.value);
                        }}
                        class="form-select  shadow-none"
                        aria-label="Default select example"
                        style={{ width: '502px', fontWeight: '400', fontSize: '16px' }}
                    >
                        <option selected>Immediately</option>
                    </select>
                </div>
            </div> */}

      <div
        className="pt-2 px-3"
        style={{
          border: '1px solid #E6E6E6',
          marginLeft: '20px',
          marginRight: '20px',
          borderRadius: '16px',
          height: '40%',
          backgroundColor: 'white',
        }}
      >
        <div style={{ fontSize: '16px', fontWeight: '700', color: 'black', marginBottom: '20px' }}>
          First Response Time
        </div>
        <div style={{ fontWeight: '400px', fontSize: '16px', color: '#666666', marginTop: 10 }}>
          Time between when a customer sends their first message and when your agent first follows
          up with it. You can set time limit for agents. If FRT limit is breached by an agent, the
          chat will be marked with a red dot.
        </div>
        <div style={{ fontSize: '16px', fontWeight: '600', color: 'black', marginTop: 20 }}>
          FRT Limit
        </div>
        <div style={{ marginBottom: '40px' }}>
          <InputGroup className="mb-3" style={{ width: '30%', marginTop: 10 }}>
            <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
              {'Minutes'}
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="select minutes"
              className="w-100 btncustom dropdown"
              style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
              value={frtLimit}
              onChange={(e) => {
                  setFrtLimit(e.target.value);
              }}
              min={5}
              max={60}
            ></Form.Control>
          </InputGroup>
        </div>
      </div>

      <div
        className="d-flex flex-row-reverse"
        style={{ paddingRight: '20px', marginBottom: '20px' }}
      >
        {' '}
        <CheerioButton
          className="button"
          borderStyle={{
            height: '60%',
            width: '20%',
            marginBlockStart: 16,
            marginBlockEnd: 8,
            border: 'None',
            borderRadius: '10px',
          }}
          textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
          btnText={'Save Preferences'}
          backColor={'black'}
          onclick={() => {
            setupEnterpriseApiCall();
          }}
        />
      </div>
    </div>
  );
};
export default ChatPreferences;
