import React from 'react';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';

export const ResolveChatBar = ({ handleChatStatus }) => {
  return (
    <div
      className="d-flex justify-content-between"
      style={{
        width: '100%',
        alignItems: 'center',
        backgroundColor: colors.white,
        padding: 20,
      }}
    >
      <p
        style={{
          color: colors.black,
          fontSize: 14,
          width: '70%',
        }}
      >
        This chat is marked as resolved by you
      </p>
      <div className="d-flex">
        <div style={{ marginRight: '10px' }}>
          <CheerioButton
            btnText={'Re-open Chat'}
            textStyle={{ color: colors.white, fontSize: '12px' }}
            backColor={'#9357FF'}
            borderStyle={{ height: '32px', borderRadius: '8px', border: 'none' }}
            onclick={() => {
              handleChatStatus('Pending');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const UnblockChatBar = ({ unblock, chatData }) => {
  return (
    <div
      className="d-flex justify-content-between"
      style={{
        width: '100%',
        alignItems: 'center',
        backgroundColor: colors.white,
        padding: 20,
      }}
    >
      <p
        style={{
          color: colors.black,
          fontSize: 16,
          width: '70%',
        }}
      >
        This chat is blocked by {chatData?.blocked?.by ?? 'you!'}
      </p>
      <div className="d-flex">
        <div style={{ marginRight: '10px' }}>
          <CheerioButton
            btnText={'Unblock Chat'}
            textStyle={{ color: colors.white, fontSize: '12px' }}
            backColor={'#9357FF'}
            borderStyle={{ height: '32px', borderRadius: '8px', border: 'none' }}
            onclick={() => {
              unblock('Pending');
            }}
          />
        </div>
      </div>
    </div>
  );
};
