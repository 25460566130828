import React from 'react';
import images from '../../../Utils/images';
import colors from '../../../Utils/colors';
import ReactPlayer from 'react-player';
import { useSearchParams } from 'react-router-dom';

export default function ExploreTemplate() {
  const [searchParams] = useSearchParams();
  const isAppSumo = searchParams.get('source') === 'appsumo';
  const isDirectPayment = searchParams.get('source') === 'payment';
  return (
    <div className="auth_exploretemplate">
      <div className="auth_exploretemplate__header">
        <div className="header_title">
          <img src={images.LogoNewLogin} />
        </div>
        <h3>{'Increase repeat sales by 56%'}</h3>
      </div>
      <div className="auth_exploretemplate__video">
        <ReactPlayer
          url={'https://m.youtube.com/watch?v=0podqA7LdSY&feature=youtu.be'}
          height="100%"
          width="100%"
          controls={true}
          playing={true}
          muted
        />
      </div>

      <div className="auth_exploretemplate__description1">
        <h2>
          {isAppSumo
            ? 'Welcome Sumoling, '
            : isDirectPayment
              ? 'Welcome to Cheerio, '
              : `Get started with your `}
          <span>
            {isAppSumo || isDirectPayment ? 'Please create your account' : `7 days free trial`}
          </span>
          {` today!`}
        </h2>
      </div>
      {!(isAppSumo || isDirectPayment) && (
        <div className="auth_exploretemplate__description2">
          <div className="col_45">
            <div className="row_item">
              <img src={images.GreenCheck} />
              <p>{'10 Email Credits'}</p>
            </div>
            <div className="row_item">
              <img src={images.GreenCheck} />
              <p>{'10 SMS Credits'}</p>
            </div>
            <div className="row_item">
              <img src={images.GreenCheck} />
              <p>{'10 WhatsApp Credits'}</p>
            </div>
          </div>
          <div className="col_45">
            <div className="row_item">
              <img src={images.GreenCheck} />
              <p>{'View Analytics'}</p>
            </div>
            <div className="row_item">
              <img src={images.GreenCheck} />
              <p>{'Send test message across all three channels'}</p>
            </div>
            {/* <div className="row_item"> */}
            {/* <img src={images.GreenCheck} style={{ height: 24, width: 24, objectFit: 'contain' }} />
            <p style={styles.txt}>View analytics</p> */}
            {/* </div> */}
          </div>
        </div>
      )}
    </div>
    // <div style={styles.body}>
    //   <div
    //     className="d-flex flex-row align-items-center justify-content-between"
    //     style={{ width: '100%' }}
    //   >
    //     <div className="d-flex flex-row align-items-center justify-content-between" style={{}}>
    //       <img src={images.HomeLogo} style={{ height: 50, width: 50 }} />
    //       <h1
    //         style={{
    //           color: colors.white,
    //           margin: 0,
    //           marginLeft: 12,
    //           fontSize: 34,
    //           fontWeight: 700,
    //         }}
    //       >
    //         Cheerio
    //       </h1>
    //     </div>

    //     <h2 style={styles.increaseText}>Increase repeat sales by 56%</h2>

    //   </div>
    //   <div style={styles.imgView}>
    //     <ReactPlayer
    //       url={'https://m.youtube.com/watch?v=0podqA7LdSY&feature=youtu.be'}
    //       height="100%"
    //       width="100%"
    //       controls={true}
    //       playing={true}
    //       muted
    //     />

    //   </div>

    //   <div className="d-flex flex-row justify-content-center align-items-center" style={{}}>
    //     <p
    //       style={{
    //         margin: 0,
    //         padding: 0,
    //         fontSize: 32,
    //         fontWeight: 300,
    //         color: colors.white,
    //       }}
    //     >
    //       {`Get started with your `}
    //       <span style={{ fontWeight: 700, color: colors.themeBlue }}>{`7 days free trial`}</span>
    //       {` today!`}
    //     </p>

    //   </div>
    //   <div className="d-flex flex-row justify-content-evenly align-items-center w-100">
    //     <div
    //       className="d-flex flex-column justify-content-between align-items-start"
    //       style={{ width: '45%' }}
    //     >
    //       <div
    //         className="d-flex flex-row justify-content-start align-items-center"
    //         style={{ marginBlock: 8 }}
    //       >
    //         <img src={images.GreenCheck} style={{ height: 24, width: 24, objectFit: 'contain' }} />
    //         <p style={styles.txt}>100 Email Credits</p>
    //       </div>
    //       <div
    //         className="d-flex flex-row justify-content-start align-items-center"
    //         style={{ marginBlock: 8 }}
    //       >
    //         <img src={images.GreenCheck} style={{ height: 24, width: 24, objectFit: 'contain' }} />
    //         <p style={styles.txt}>100 SMS Credits</p>
    //       </div>
    //       <div
    //         className="d-flex flex-row justify-content-start align-items-center"
    //         style={{ marginBlock: 8 }}
    //       >
    //         <img src={images.GreenCheck} style={{ height: 24, width: 24, objectFit: 'contain' }} />
    //         <p style={styles.txt}>10 WhatsApp Credits</p>
    //       </div>
    //     </div>
    //     <div
    //       className="d-flex flex-column justify-content-between align-items-start"
    //       style={{ width: '45%' }}
    //     >
    //       <div
    //         className="d-flex flex-row justify-content-start align-items-center"
    //         style={{ marginBlock: 8 }}
    //       >
    //         <img src={images.GreenCheck} style={{ height: 24, width: 24, objectFit: 'contain' }} />
    //         <p style={styles.txt}>Analytics</p>
    //       </div>
    //       <div
    //         className="d-flex flex-row justify-content-start align-items-center"
    //         style={{ marginBlock: 8 }}
    //       >
    //         <img src={images.GreenCheck} style={{ height: 24, width: 24, objectFit: 'contain' }} />
    //         <p style={styles.txt}>E-Commerce Integration</p>
    //       </div>
    //       <div
    //         className="d-flex flex-row justify-content-start align-items-center"
    //         style={{ marginBlock: 8 }}
    //       >
    //         <img src={images.GreenCheck} style={{ height: 24, width: 24, objectFit: 'contain' }} />
    //         <p style={styles.txt}>Launch campaigns</p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

const styles = {
  body: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingBlock: 30,
    paddingInline: 40,
    // padding: "5%",
  },
  imgView: {
    display: 'flex',
    flexDirection: 'row',
    height: '50%',
    width: '100%',
    borderRadius: 16,
    // marginTop: "4%",
    // marginBottom: "4%",
    justifyContent: 'center',
    // justifyItems: "center",
    alignItems: 'center',
  },
  imgThumb: {
    height: '100%',
    width: '100%',
    // marginLeft: "auto",
    // marginRight: "auto",
    borderRadius: '3%',
    objectFit: 'cover',
  },
  youtubeView: {
    position: 'absolute',
    backgroundColor: colors.blackHalf2,
    display: 'flex',
    height: '60%',
    width: '45%',
    borderRadius: 16,
    justifyItems: 'center',
    alignItems: 'center',
    borderWidth: 0,
  },
  youtubePlay: {
    // height: 80,
    width: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: 'contain',
  },
  increaseText: {
    color: colors.white,
    margin: 0,
    fontSize: 22,
    fontWeight: 700,
    // marginLeft: 12,
    // marginTop: 10,
  },
  txt: {
    margin: 0,
    color: colors.white,
    fontSize: 16,
    fontWeight: 700,
    marginLeft: 8,
    // textAlign: "left",
  },
};
