import React, { useEffect, useState } from 'react';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import validator from 'validator';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkGmailUserV2,
  checkUserOTP,
  checkUserOTPV2,
  getCountryCode,
  sendUserOTP,
  sendUserOTPV2,
  userLogin,
  twoFactorLogin,
} from '../../Services';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { eventsend, setuserid } from '../../Config/analyticsFunctions';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import Helper from '../../Utils/Helper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TwoFactorLoginPage from './TwoFactorLogin';

const mobileWidth = window.innerWidth;
const mobileHeight = window.innerHeight;

const CreatorLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [session_id, setSession_id] = useState('');
  const [OTPsent, setOTPsent] = useState(false);
  const [facebookId, setFacebookId] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [loginBtnEnable, setLoginBtnEnable] = useState(false);
  const [numbervalue, setNumberValue] = useState('');
  const [numberentered, setnumberentered] = useState(false);
  const [OTPvalue, setOTPvalue] = useState('');
  const [password, setPassword] = useState('');
  const [incorrectOTP, setIncorrectOTP] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [OKtoProceed, setOKtoProceed] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const toastId = React.useRef(null);
  const sessionId = useSelector((state) => state.main?.session_id);
  const mobile = useSelector((state) => state.main?.mobile);
  const login_status = useSelector((state) => state.main?.login_status);
  const _id = useSelector((state) => state.main?._id);
  const user_pic = useSelector((state) => state.main?.user_pic);
  const username = useSelector((state) => state.main?.username);
  const companyname = useSelector((state) => state.main?.companyname);
  const gender = useSelector((state) => state.main?.gender);
  const age = useSelector((state) => state.main?.age);
  const interest = useSelector((state) => state.main?.interest);
  const auth_token = useSelector((state) => state.main?.auth_token);
  const email = useSelector((state) => state.main?.email);
  const facebook_id = useSelector((state) => state.main?.facebook_id);
  const [twoFactorModal, setTwoFactorModal] = useState(false);
  const [incorrectPhoneOTP, setIncorrectPhoneOTP] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [phoneOTPvalue, setPhoneOTPvalue] = useState('');

  const profiledataexists = _id && facebook_id && auth_token && companyname && otpVerified;

  useEffect(() => {
    ReactGA.event('creator_login_page_pageview');
    eventsend('creator_login_page_pageview');
    if (_id) {
      navigate('/creator');
    }
  }, []);

  /* const gmailExistingUser = (res) => {
    if (res.data?.details) {
      dispatch({
        type: 'addUserId',
        payload: res.data?.details?.user_detail?._id,
      });
      dispatch({
        type: 'addMobile',
        payload: res.data?.details?.user_detail?.mobile,
      });
      dispatch({
        type: 'addUsername',
        payload: res.data?.details?.user_detail?.name,
      });
      dispatch({
        type: 'addCompanyname',
        payload: res.data?.details?.user_detail?.company_name,
      });
      dispatch({
        type: 'addUserPic',
        payload: res.data?.details?.user_detail?.picture,
      });
      dispatch({
        type: 'addGender',
        payload: res.data?.details?.user_detail?.gender,
      });
      dispatch({
        type: 'addAge',
        payload: res.data?.details?.user_detail?.age_range,
      });
      dispatch({
        type: 'addInterest',
        payload: res.data?.details?.user_detail?.interest,
      });
      dispatch({
        type: 'addEmail',
        payload: res.data?.details?.user_detail?.email,
      });

      dispatch({ type: 'addAuthToken', payload: res.data?.details?.token });
      dispatch({ type: 'addLogin', payload: true });
      dispatch({
        type: 'addBalance',
        payload: res.data?.details?.user_detail?.balance,
      });
    }
  }; */
  const existingUsers = (res) => {
    if (res.data) {
      dispatch({
        type: 'addUserId',
        payload: res.data._details?._id,
      });
      dispatch({
        type: 'addMobile',
        payload: res.data._details?.mobile,
      });
      dispatch({
        type: 'addUsername',
        payload: res.data._details?.name,
      });
      dispatch({
        type: 'addCompanyname',
        payload: res.data._details?.company_name,
      });
      dispatch({
        type: 'addUserPic',
        payload: res.data._details?.picture,
      });
      dispatch({
        type: 'addGender',
        payload: res.data._details?.gender,
      });
      dispatch({
        type: 'addAge',
        payload: res.data._details?.age_range,
      });
      dispatch({
        type: 'addInterest',
        payload: res.data._details?.interests,
      });
      dispatch({
        type: 'addEmail',
        payload: res.data._details?.email,
      });
      dispatch({
        type: 'addFacebookId',
        payload: res.data._details?.facebook_id,
      });
      dispatch({
        type: 'addLocaldata',
        payload: res.data?._details,
      });
      dispatch({ type: 'addAuthToken', payload: res?.data?.token });
      dispatch({ type: 'addLogin', payload: true });
      dispatch({
        type: 'addBalance',
        payload: res.data._details?.balance,
      });
    }
  };
  // const profiledataexists = _id && user_pic && username && gender && age && interest && auth_token;
  // const profiledataexists = _id && user_pic && username && email && auth_token && companyname;

  const newExistingUsers = (res) => {
    console.log(res.data);
    if (res.data) {
      dispatch({
        type: 'addUserId',
        payload: res.data.userDetails?._id,
      });
      dispatch({
        type: 'addMobile',
        payload: res.data.userDetails?.mobile,
      });
      dispatch({
        type: 'addUsername',
        payload: res.data.userDetails?.name,
      });
      dispatch({
        type: 'addCompanyname',
        payload: res.data.userDetails?.company_name,
      });
      dispatch({
        type: 'addUserPic',
        payload: res.data.userDetails?.picture,
      });
      dispatch({
        type: 'addEmail',
        payload: res.data.userDetails?.email,
      });
      dispatch({
        type: 'addFacebookId',
        payload: res.data.userDetails?.facebook_id,
      });
      dispatch({
        type: 'addLocaldata',
        payload: res.data?.userDetails,
      });

      dispatch({ type: 'addAuthToken', payload: res?.data?.token });
      dispatch({ type: 'addLogin', payload: true });
    }
  };

  useEffect(() => {
    if (otpVerified) {
      if (profiledataexists) {
        navigate('/creator');
      } else {
        navigate('/creatorprofile');
      }
    }
  }, [newExistingUsers]);

  useEffect(() => {
    if (otpVerified) {
      if (profiledataexists) {
        navigate('/creator');
      } else {
        navigate('/creatorprofile');
      }
    }
  }, [existingUsers]);

  useEffect(() => {
    if (auth_token && auth_token !== '' && _id !== '' && _id) {
      localStorage.setItem('token', auth_token);
    }
  }, [auth_token]);

  const resetAuthStates = () => {
    setFacebookId('');
    setPassword('');
  };

  const twoFactorLoginApiCall = () => {
    if (isNaN(phoneOTPvalue)) {
      toast.error('Please only enter numeric input for OTP', {
        position: 'top-center',
        toastId: 1,
      });
    } else {
      let data = {
        mobile: numbervalue,
        phoneOTPvalue: phoneOTPvalue,
        sessionId: session_id,
      };
      twoFactorLogin(data)
        .then((res) => {
          if (res?.flag) {
            console.log('User login', res);
            setOtpVerified(true);
            existingUsers(res);
            setIsLogin(true);
            if (!toast.isActive(toastId.current)) {
              toast('Sucessfully Logged you in...', { toastId: 1, position: 'top-center' });
            }
            if (location.pathname === '/v2') {
              Helper.EmbeddedAI = true;
            }
            setOKtoProceed(true);
            resetAuthStates();
          } else {
            setIncorrectPhoneOTP(true);
            setTimeout(() => {
              setIncorrectPhoneOTP(false);
            }, 5000);
          }
        })
        .catch((e) => {
          if (!toast.isActive(toastId.current)) {
            toast(e, { position: 'top-center', toastId: 1 });
          }
        });
    }
  };

  const onClickVerify = () => {
    ReactGA.event('login_page_login_button_clicked');
    eventsend('login_page_login_button_clicked');
    let data = {
      facebook_id: facebookId,
      password: password,
    };
    console.log('Login data sending ==> ', data);
    userLogin(data)
      .then((res) => {
        if (res?.flag) {
          if (!res.data.userDetails.isTwoFactorEnabled) {
            console.log('User login', res);
            setOtpVerified(true);
            newExistingUsers(res);
            setIsLogin(true);
            if (!toast.isActive(toastId.current)) {
              toast('Sucessfully Logged you in...', { toastId: 1, position: 'top-center' });
            }
            resetAuthStates();
            if (location.pathname === '/v2') {
              Helper.EmbeddedAI = true;
            }
            setOKtoProceed(true);
          } else {
            toast.dismiss(1);
            setTimeout(() => {
              toast('Onto OTP Page for 2 Factor Authentication', {
                toastId: 2,
                position: 'top-center',
              });
            }, 500);
            setNumberValue(res.data.userDetails?.mobile);
            setSession_id(res.data.phoneOTPSession);
            setTwoFactorModal(true);
            setCountryCode('91');
          }
        } else {
          toast('Invalid Credentials', { toastId: 1, position: 'top-center' });
          setIncorrectPassword(true);
          setTimeout(() => {
            setIncorrectPassword(false);
          }, 5000);
        }
      })
      .catch((e) => {
        if (!toast.isActive(toastId.current)) {
          toast(e, { position: 'top-center', toastId: 1 });
        }
      });
  };

  const twoFactorLoginAction = () => {
    twoFactorLoginApiCall();
  };

  const resendPhoneOtp = () => {
    sendOTPapicall();
  };

  const sendOTPapicall = () => {
    if (numbervalue?.length > 5) {
      let data = {
        country_code: `${countryCode}`,
        mobile_number: numbervalue.split('-').join(''),
      };
      console.log('send otp data sent ===> ', data);
      sendUserOTPV2(data)
        .then((res) => {
          if (res.flag) {
            console.log('send sms otp res ====> ', res);
            setSession_id(res.data.session_id);
            setOTPsent(true);
            toast.dismiss(1);
            if (!toast.isActive(toastId.current)) {
              toast('OTP Sent', { position: 'top-center', toastId: 1 });
            }
            setIsOpen(true);
          } else {
            if (!toast.isActive(toastId.current)) {
              toast.error(`Error: ${res.response.data.message}`, {
                position: 'top-center',
                toastId: 1,
              });
            }
          }
        })
        .catch((e) => {
          if (!toast.isActive(toastId.current)) {
            toast(e, { position: 'top-center', toastId: 1 });
          }
        });
    } else {
      if (!toast.isActive(toastId.current)) {
        toast('Please enter mobile number', { position: 'top-center', toastId: 1 });
      }
    }
  };

  // useEffect(() => {
  //   if (googleUserProfile.id) {
  //     console.log("+++++++++++++++++++++++++++++++++++++++++++");
  //     console.log("++++++++++", googleUserProfile);
  //     console.log("+++++++++++++++++++++++++++++++++++++++++++");
  //     dispatch({ type: "addEmail", payload: googleUserProfile.email });
  //     dispatch({ type: "addUsername", payload: googleUserProfile.name });
  //     dispatch({ type: "addUserPic", payload: googleUserProfile.picture });
  //     dispatch({ type: "addGoogleId", payload: googleUserProfile.id });
  //     navigate("/creatorprofile");
  //   }
  // }, [googleUserProfile]);

  const isMobile = window.innerWidth <= 500;
  const mobileWidth = window.innerWidth;
  const mobileHeight = window.innerHeight;

  const onMobileClickAction = () => {
    window.open('https://cheerio.app.link/NgZ2AeZRiub', '_blank');
  };

  return (
    <>
      {twoFactorModal ? (
        <>
          <TwoFactorLoginPage
            numbervalue={numbervalue}
            closeOtpAction={() => {
              setPhoneOTPvalue('');
              setTwoFactorModal(false);
              setIncorrectPhoneOTP(false), setFacebookId('');
              setPassword('');
            }}
            incorrectPhoneOTP={incorrectPhoneOTP}
            setPhoneOTPvalue={setPhoneOTPvalue}
            resendPhoneOtp={resendPhoneOtp}
            phoneOTPvalue={phoneOTPvalue}
            twoFactorLoginAction={twoFactorLoginAction}
          />
        </>
      ) : (
        <></>
      )}
      {isMobile ? (
        <>
          <div className="container-fluid" style={{ ...Styles.MobileContainer }}>
            <div className="" style={{ ...Styles.Column, width: '100%', height: '100%' }}>
              <img
                className="p-2"
                src={images.CLMobile}
                style={{ height: '60%', width: '100%', objectFit: 'contain' }}
              />
              <p style={{ ...Styles.MobileTxt, textAlign: 'center' }}>
                {'Cheerio web dashboard can be accessed only on desktop or laptop'}
              </p>
              <div className="p-2" style={{ ...Styles.Row, width: '100%' }}>
                <img
                  src={images.HomeLogo}
                  style={{ height: 54, width: 54, objectFit: 'contain' }}
                />
                <p
                  style={{
                    ...Styles.MobileTxt,
                    flexWrap: 'wrap',
                    wordBreak: 'break-word',
                  }}
                >
                  {'To use Cheerio CRM on your phone, download our app'}
                </p>
              </div>
              <div className="p-2" style={{ ...Styles.Row, width: '100%' }}>
                <img
                  src={images.HomeButton}
                  style={{ height: 46, objectFit: 'contain' }}
                  onClick={() => {
                    onMobileClickAction();
                  }}
                />
                <img
                  src={images.CLAppStore}
                  style={{ height: 43, objectFit: 'contain' }}
                  onClick={() => {
                    onMobileClickAction();
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container-fluid" style={{ ...Styles.Container }}>
            <div className="" style={{ ...Styles.Column, width: '100%', height: '100%' }}>
              {/* <div className='d-flex flex-row justify-content-end align-items-center' style={{height:'10vh',width:'100%'}}> */}
              <div
                className="d-flex flex-row justify-content-center align-items-center"
                style={{ height: '10vh', width: '30%', alignSelf: 'flex-end' }}
              >
                <img
                  src={images.CLLogo}
                  style={{ height: '80%', width: 'auto', objectFit: 'contain' }}
                ></img>
                <text
                  className="px-1"
                  style={{ fontSize: 32, fontWeight: 700, color: colors.white }}
                >
                  {'Cheerio'}
                </text>
              </div>
              {/* </div> */}
              <div
                className="d-flex flex-row justify-content-between align-items-center"
                style={{ height: '70vh', width: '100%' }}
              >
                <div
                  className="d-flex flex-column justify-content-between align-items-center"
                  style={{ height: '100%', width: '70%' }}
                >
                  <div className="p-2" style={{ height: '50%', width: '100%' }}>
                    <div
                      className="d-flex flex-row justify-content-between align-items-center p-2"
                      style={{
                        height: '100%',
                        width: '100%',
                        backgroundColor: colors.white,
                        borderRadius: 16,
                      }}
                    >
                      <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ height: '100%', width: '50%' }}
                      >
                        <div className="d-flex flex-column justify-content-center align-items-start">
                          <text
                            style={{
                              fontSize: 23,
                              fontWeight: 900,
                              color: colors.black,
                            }}
                          >
                            {'Increase repeat sales'}
                          </text>
                          <text
                            className="py-1"
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: colors.black,
                            }}
                          >
                            <img
                              src={images.CLCheck}
                              style={{
                                height: 14,
                                width: 14,
                                objectFit: 'contain',
                                marginInlineEnd: 8,
                              }}
                            ></img>
                            {'Using Cheerio CRM'}
                          </text>
                          <text
                            className="py-1"
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: colors.black,
                            }}
                          >
                            <img
                              src={images.CLCheck}
                              style={{
                                height: 14,
                                width: 14,
                                objectFit: 'contain',
                                marginInlineEnd: 8,
                              }}
                            ></img>
                            {'Remarket via sms, whatsapp, emails'}
                          </text>
                          <text
                            className="py-1"
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: colors.black,
                            }}
                          >
                            <img
                              src={images.CLCheck}
                              style={{
                                height: 14,
                                width: 14,
                                objectFit: 'contain',
                                marginInlineEnd: 8,
                              }}
                            ></img>
                            {'Sell to more customers'}
                          </text>
                        </div>
                      </div>
                      <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ height: '100%', width: '50%' }}
                      >
                        <img
                          src={images.CLImg1}
                          style={{
                            height: '80%',
                            width: '80%',
                            objectFit: 'contain',
                          }}
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="p-2" style={{ height: '50%', width: '100%' }}>
                    <div
                      className="d-flex flex-row justify-content-between align-items-center p-2"
                      style={{
                        height: '100%',
                        width: '100%',
                        backgroundColor: colors.white,
                        borderRadius: 16,
                      }}
                    >
                      <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ height: '100%', width: '50%' }}
                      >
                        <img
                          src={images.CLImg2}
                          style={{
                            height: '80%',
                            width: '80%',
                            objectFit: 'contain',
                          }}
                        ></img>
                      </div>
                      <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ height: '100%', width: '50%' }}
                      >
                        <div className="d-flex flex-column justify-content-center align-items-start">
                          <text
                            style={{
                              fontSize: 23,
                              fontWeight: 900,
                              color: colors.black,
                            }}
                          >
                            {'Collect payments via'}
                          </text>
                          <text
                            className="py-1"
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: colors.black,
                            }}
                          >
                            <img
                              src={images.CLCheck}
                              style={{
                                height: 14,
                                width: 14,
                                objectFit: 'contain',
                                marginInlineEnd: 8,
                              }}
                            ></img>
                            {'Payment links'}
                          </text>
                          <text
                            className="py-1"
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: colors.black,
                            }}
                          >
                            <img
                              src={images.CLCheck}
                              style={{
                                height: 14,
                                width: 14,
                                objectFit: 'contain',
                                marginInlineEnd: 8,
                              }}
                            ></img>
                            {'Delivered via sms, email, whatsapp'}
                          </text>
                          <text
                            className="py-1"
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: colors.black,
                            }}
                          >
                            <img
                              src={images.CLCheck}
                              style={{
                                height: 14,
                                width: 14,
                                objectFit: 'contain',
                                marginInlineEnd: 8,
                              }}
                            ></img>
                            {'Sell products & courses'}
                          </text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-2" style={{ height: '100%', width: '30%' }}>
                  <div
                    className="d-flex flex-column justify-content-between align-items-center px-2 py-3"
                    style={{
                      height: '100%',
                      width: '100%',
                      backgroundColor: colors.white,
                      borderRadius: 16,
                    }}
                  >
                    <div
                      className="d-flex flex-column justify-content-center align-items-center px-2"
                      style={{ width: '100%' }}
                    >
                      <text
                        className="w-100 py-2"
                        style={{
                          fontSize: 32,
                          fontWeight: 700,
                          color: colors.black,
                          textAlign: 'start',
                        }}
                      >
                        {isLogin ? 'Login' : 'Signup'}
                      </text>
                      <InputGroup className="py-2" style={{}}>
                        <Form.Control
                          style={{
                            height: 40,
                            backgroundColor: colors.white04,
                          }}
                          placeholder="Phone no / Email Id"
                          aria-label="Facebook Id"
                          aria-describedby="Phone no / Email / Gmail"
                          className="btncustom"
                          value={facebookId}
                          onInput={(e) => setFacebookId(e.target.value)}
                        />
                      </InputGroup>
                      <InputGroup className="py-2" style={{}}>
                        <Form.Control
                          style={{
                            height: 40,
                            backgroundColor: colors.white04,
                          }}
                          type="password"
                          placeholder="Enter your password"
                          aria-label="Password"
                          aria-describedby="Enter your password"
                          className="btncustom"
                          value={password}
                          onInput={(e) => setPassword(e.target.value)}
                        />
                      </InputGroup>
                      {incorrectPassword && (
                        <>
                          <div className="d-flex flex-row justify-content-between align-items-center w-100 py-1">
                            <text
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: colors.error03,
                              }}
                            >
                              {'Invalid Credentials. Please try again'}
                            </text>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className="d-flex flex-column justify-content-center align-items-center px-2"
                      style={{ width: '100%' }}
                    >
                      <Button
                        style={{
                          ...Styles.buttonStyle,
                          fontSize: 16,
                          fontWeight: 600,
                          color: colors.white,
                          borderWidth: 0,
                          width: '100%',
                        }}
                        disabled={password.length < 8 ? true : false}
                        className="d-flex flex-row justify-content-center align-items-center btncustom my-1"
                        onClick={() => {
                          onClickVerify();
                        }}
                      >
                        Login
                        <img
                          src={images.CLBtnImg}
                          style={{
                            height: 20,
                            width: 20,
                            objectFit: 'contain',
                            marginInline: 10,
                            display: numberentered ? 'none' : '',
                          }}
                        ></img>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div
                className="d-flex flex-row justify-content-end align-items-center"
                style={{ height: '10vh', width: '100%' }}
              >
                <div
                  className="d-flex flex-row justify-content-center align-items-center"
                  style={{ height: '100%', width: '30%' }}
                >
                  {isLogin ? (
                    <>
                      <text
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          ReactGA.event('new_user_signup_clicked');
                          eventsend('new_user_signup_clicked');
                          setIsLogin(false);
                        }}
                      >
                        {'New to Cheerio? '}
                        <text
                          style={{
                            fontSize: 16,
                            fontWeight: 700,
                            color: colors.primary03,
                          }}
                        >
                          {'Sign Up'}
                        </text>
                      </text>
                    </>
                  ) : (
                    <>
                      <text
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          ReactGA.event('old_user_login_clicked');
                          eventsend('old_user_login_clicked');
                          setIsLogin(true);
                        }}
                      >
                        {'Already a cheerio member? '}
                        <text
                          style={{
                            fontSize: 16,
                            fontWeight: 700,
                            color: colors.primary03,
                          }}
                        >
                          {'Login'}
                        </text>
                      </text>
                    </>
                  )}
                </div>
              </div> */}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          ></div>
        </>
      )}
    </>
  );
};

export default CreatorLogin;

const Styles = {
  buttonStyle: {
    height: 40,
    // borderWidth: 1,
    // borderStyle: "solid",
    // borderColor: colors.white,
    borderRadius: 8,
    backgroundColor: colors.primary03,
    // display: "inline-block",
  },
  Column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  MobileContainer: {
    // backgroundColor:colors.black02,
    backgroundColor: colors.black,
    height: mobileHeight,
    width: '100%',
    paddingBlock: '5vh',
    paddingInline: '10vw',
  },
  MobileTxt: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.white,
    paddingInline: 16,
  },
  Container: {
    backgroundColor: colors.black02,
    height: '100dvh',
    width: '100%',
    paddingBlock: '5vh',
    paddingInline: '10vw',
  },
};
