import React, { useEffect, useRef, useState } from 'react';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import { useSelector } from 'react-redux';
import images from '../../../Utils/images';
import UploaderText from '../../../Components/UploaderText';
import UploadValidator from '../../../Components/UploadValidator';
import {
  V2WorkflowS3Link,
  getKeywordMediaList,
  setupKeywordAction,
  updateKeywordAction,
} from '../../../Services';
import { getImagePath } from '../../../Utils';
import { toast } from 'react-toastify';
import Helper from '../../../Utils/Helper';

const MediaResponse = ({
  object,
  options,
  dataObject,
  setDataObject,
  selectedItem,
  saveSuccessAction,
}) => {
  const [messageText, setMessageText] = useState('');
  const [InputOptions, setInputOptions] = useState([{ InputValue: '' }]);
  const [mediaType, setMediaType] = useState(selectedItem);
  const [upFile, setUpFile] = useState();
  const [upFile64, setUpFile64] = useState('');
  const [upFileName, setUpFileName] = useState('');
  const [s3Link, setS3Link] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const [mediaList, setMediaList] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState();
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;

  const authtoken = useSelector((state) => state.main.auth_token);

  const upImageRef = useRef();
  const upVideoRef = useRef();
  const upDocRef = useRef();

  useEffect(() => {
    // console.log("selected item",selectedItem)
    setMediaType(selectedItem);
    getAllMediaList(selectedItem);
    resetFunction();
  }, [selectedItem]);

  const getAllMediaList = (media) => {
    let token = authtoken;
    let data = {
      type: media.toLowerCase(),
    };
    getKeywordMediaList(token, data).then((res) => {
      if (res?.flag) {
        setMediaList(res?.data);
        // console.log("success response ===> ",res)
      } else {
        // console.log("failure res ===> ",res)
      }
    });
  };

  useEffect(() => {
    if (mediaList?.length > 0 && dataObject?.header?.mediaFile?.length > 0) {
      setSelectedMedia(
        mediaList.filter(
          (item) => item?.data?.header?.mediaFile == dataObject?.header?.mediaFile
        )[0] ?? null
      );
    }
  }, [mediaList]);

  const resetFunction = () => {
    setUpFile(null);
    setUpFile64(null);
    setUpFileName('');
    setS3Link(null);
  };

  const handlefileupload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (UploadValidator(file, mediaType)) {
        setUpFile(file);
        setUpFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };

  const handleDragFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDropFile = async (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log('file ===> ', e.dataTransfer.files[0]);
    const file = e.dataTransfer.files[0];
    if (file && type) {
      if (UploadValidator(file, type)) {
        setUpFile(file);
        setUpFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (upFile64) {
      getS3Link();
    }
  }, [upFile64]);

  const getS3Link = () => {
    const s3toast = toast.loading('Uploading file. Please wait.');
    let data = {
      image: upFile64,
      type: upFile?.type?.split('/')[0],
      format: upFile?.type?.split('/')[1],
    };
    let token = authtoken;
    V2WorkflowS3Link(data, token).then((res) => {
      if (res) {
        setS3Link(res?.data?.url);
        // console.log('s3 link success response', res);
        toast.update(s3toast, {
          render: 'File uploaded.',
          type: 'success',
          autoClose: true,
          isLoading: false,

        });
      } else {
        toast.update(s3toast, {
          render: 'File upload failed! please try again later',
          type: 'error',
          autoClose: true,
          isLoading: false,

        });
        // console.log('s3 link error response', res);
      }
    });
  };

  const saveKeywordAction = () => {
    let apidata = {
      action: 'responseMessage',
      keywords: options[0]?.keywords,
      keywordCriteria: options[0]?.criteria,
      data: {
        key: mediaType,
        header: {
          mediaType: mediaType.toLowerCase(),
          mediaName: upFileName,
          mediaFile: s3Link,
        },
        body: {
          message: '',
        },
        options: [],
      },
    };

    if (object && 'name' in object && 'startTime' in object && 'endTime' in object) {
      apidata = {
        ...apidata,
        timeFrame: {
          name: object?.name,
          startTime: object?.startTime,
          endTime: object?.endTime,
        },
      };
    }

    if (dataObject?.header?.mediaFile?.length > 0 || Helper.HelperEditKeywordAction) {
      updateKeywordAction(apidata, authtoken, Helper.HelperKeywordActionID).then((res) => {
        if (res?.flag) {
          toast.success('keyword action updated');
          saveSuccessAction();
        } else {
          toast.error(res?.message);
        }
      });
    } else {
      setupKeywordAction(apidata, authtoken).then((res) => {
        if (res?.flag) {
          toast.success('keyword action saved');
          saveSuccessAction();
        } else {
          toast.error(res?.message);
        }
      });
    }

    // console.log("api data ---> ",apidata)
  };

  useEffect(() => {
    if (selectedMedia?._id) {
      setMediaType(selectedMedia?.data?.header?.mediaType);
      setUpFileName(selectedMedia?.data?.header?.mediaName);
      setS3Link(selectedMedia?.data?.header?.mediaFile);
    }
  }, [selectedMedia]);

  useEffect(() => {
    if (upFileName !== selectedMedia?.data?.header?.mediaName) {
      setSelectedMedia(null);
    }
  }, [upFileName]);

  return (
    <>
      <div className="media_responses_container">
        <div className="media_responses_container__header_row">
          <h3>{`${selectedItem} Responses`}</h3>
          <CheerioButton
            borderStyle={{
              borderWidth: 0,
            }}
            textStyle={{
              fontWeight: 600,
              color: colors.white01,
            }}
            btnText={'Save'}
            backColor={colors.primary03}
            disabled={options?.length > 0 ? false : true}
            onclick={() => {
              // console.log("next clicked");
              saveKeywordAction();
              // nextClickAction();
            }}
          />
        </div>
        <div className="media_responses_container__upload_box">
          <div
            className="d-flex flex-column justify-content-center align-items-center w-100 my-2"
            style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 8, height: 260 }}
            onDragEnter={handleDragFile}
            onDragExit={handleDragFile}
            onDragOver={handleDragFile}
            onDrop={(e) => {
              handleDropFile(e, mediaType);
            }}
            onClick={() => {
              if (mediaType === 'Image' || mediaType === 'image') {
                upImageRef.current.click();
              } else if (mediaType === 'Video' || mediaType === 'video') {
                upVideoRef.current.click();
              } else if (mediaType === 'Document' || mediaType === 'document') {
                upDocRef.current.click();
              }
            }}
          >
            {s3Link ? (
              <>
                {/* {s3Link &&  */}

                {/* <> */}
                {mediaType === 'Image' || mediaType === 'image' ? (
                  <img
                    src={getImagePath(s3Link)}
                    style={{ height: 180, width: '80%', objectFit: 'contain' }}
                  ></img>
                ) : mediaType === 'Document' || mediaType === 'document' ? (
                  <>
                    {/* <img src={getImagePath(s3Link)} style={{height:180,width:'80%',objectFit:'contain'}}></img> */}
                    {/* {upFile && 
              <embed src={URL.createObjectURL(s3Link)} style={{height:180,width:'80%',objectFit:'cover'}}></embed>
            } */}
                    <img
                      src={images.KWDoc}
                      style={{ height: 180, width: '80%', objectFit: 'contain' }}
                    ></img>
                  </>
                ) : (
                  (mediaType === 'Video' || mediaType === 'video') && (
                    <video
                      src={getImagePath(s3Link)}
                      style={{ height: 180, width: '80%', objectFit: 'contain' }}
                    ></video>
                  )
                )}
                {/* </> */}

                {/* } */}
                {upFileName && (
                  <div className="d-flex flex-row justify-content-between align-items-center w-75">
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        marginBlock: 4,
                        fontSize: 16,
                        fontWeight: 600,
                        color: colors.greys04,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        wordBreak: 'break-all',
                      }}
                    >
                      {upFileName}
                    </p>
                    <img
                      src={images.Cross}
                      style={{ height: 18, width: 18, marginInlineStart: 24, cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setUpFile(null);
                        setUpFile64(null);
                        setUpFileName('');
                        setS3Link(null);
                      }}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <img
                  src={images.ImgUpIcon}
                  style={{ height: 100, width: 70, objectFit: 'contain' }}
                ></img>
                <UploaderText
                  type={mediaType}
                  textStyle={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: colors.greys04,
                    textAlign: 'center',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    wordBreak: 'break-all',
                  }}
                />
                {/* <p style={{padding:0,margin:0,marginBlock:4,fontSize:16,fontWeight:600,color:colors.greys04}}>
            {'Upload Image'}
          </p>
          <p style={{padding:0,margin:0,marginBlock:8,fontSize:14,fontWeight:600,color:colors.greys04,textAlign:'center'}}>
            {'350px x 350px to 1500px x 1500px'}<br/>
            {'Max file size: 5 MB'}
          </p> */}
              </>
            )}
          </div>

          <input
            type="file"
            style={{ display: 'none' }}
            ref={upImageRef}
            accept="image/*"
            onChange={(e) => {
              handlefileupload(e);
            }}
          />
          <input
            type="file"
            style={{ display: 'none' }}
            ref={upVideoRef}
            accept="video/*"
            onChange={(e) => {
              handlefileupload(e);
            }}
          />
          <input
            type="file"
            style={{ display: 'none' }}
            ref={upDocRef}
            accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.pdfx,.csv"
            onChange={(e) => {
              handlefileupload(e);
            }}
          />
        </div>
        <div
          className="w-100 d-flex flex-row justify-content-start align-items-start"
          style={{ flex: 1, overflowY: 'scroll', flexWrap: 'wrap' }}
        >
          {mediaList.length > 0 &&
            mediaList.map((item, index) => {
              return (
                <>
                  <div
                    className="py-1 px-1"
                    style={{
                      width: '33%',
                      height: 196,
                      cursor: 'pointer',
                    }}
                    key={item?._id}
                    onClick={() => {
                      setSelectedMedia(item);
                      // console.log("selected media ===> ",selectedMedia);
                    }}
                  >
                    <div
                      className="d-flex flex-column justify-content-start align-items-center w-100 px-3 py-2 h-100 crhover"
                      style={{
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor:
                          selectedMedia?._id === item?._id ? colors.primary03 : colors.borderwhite,
                        borderRadius: 8,
                      }}
                    >
                      <div
                        className="w-100 d-flex justify-content-center align-items-center"
                        style={{ flex: 1, overflow: 'hidden' }}
                      >
                        {mediaType === 'Video' || mediaType === 'video' ? (
                          <>
                            <video
                              src={getImagePath(item?.data?.header?.mediaFile)}
                              style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'contain',
                              }}
                            ></video>
                          </>
                        ) : mediaType === 'Image' || mediaType === 'image' ? (
                          <>
                            <img
                              src={getImagePath(item?.data?.header?.mediaFile)}
                              style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'contain',
                              }}
                            ></img>
                          </>
                        ) : (
                          <>
                            <img
                              src={images.KWDoc}
                              style={{
                                height: '100%',
                                width: 'auto',
                                objectFit: 'contain',
                              }}
                            ></img>
                          </>
                        )}
                      </div>
                      <p
                        style={{
                          fontWeight: 500,
                          width: '100%',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          wordBreak: 'break-all',
                          marginBlock: 8,
                        }}
                      >
                        {item?.data?.header?.mediaName}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default MediaResponse;
