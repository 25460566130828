import React, { useEffect, useState } from 'react';
import colors from '../../Utils/colors';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import './index.scss';
import images from '../../Utils/images';
import { BiBriefcase } from 'react-icons/bi';
import { BsKey, BsPeople, BsChat } from 'react-icons/bs';
import { PiWebhooksLogoLight } from 'react-icons/pi';
import { IoWalletOutline } from 'react-icons/io5';
import { FaBook } from 'react-icons/fa6';
import { title } from 'process';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AccountDetails from './Components/AccountDetails';
import People from './Components/People';
import ChatPreferences from './Components/ChatPreferences';
import { APIKey } from './Components/APIKey';
import { useSelector } from 'react-redux';
import { eventsend } from '../../Config/analyticsFunctions';
import { AppSumoDetails } from './Components/AppSumoDetails';
import PlanDetails from './Components/PlanDetails';
import PaymentDetails from '../Templates/Components/PaymentDetails';
import { Webhooks } from './Components/Webhooks';
import { LuBookMinus } from 'react-icons/lu';
import AppsumoWelcomeModal from '../CreatorNew/Components/AppsumoWelcomeModal';
import EventEmitter from '../../Utils/EventEmitter';
import SubscriptionDetails from '../Templates/Components/SubscriptionDetails';

const ManageProfile = () => {
  const [selectedItem, setSelectedItem] = useState('');
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;
  const navigate = useNavigate();
  // const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  useEffect(() => {
    eventsend('manage_profile_pageview_web');
    // const appSumoWelcomeModal = () => {
    //   setShowWelcomeModal(true);
    // };
    // const listener = EventEmitter.addListener('appSumoWelcome', appSumoWelcomeModal);
    // return () => {
    //   listener.remove();
    // };
  }, []);

  useEffect(() => {
    const url = window.location.pathname;
    switch (url) {
      case '/manageprofile':
        setSelectedItem('Account details');
        break;
      case '/manageprofile/people':
        setSelectedItem('People');
        break;
      case '/manageprofile/apikey':
        setSelectedItem('API Key');
        break;
      case '/manageprofile/planDetails':
        setSelectedItem('Plan Details');
        break;
      case '/manageprofile/subscriptions':
        setSelectedItem('Subscriptions');
        break;
      case '/manageprofile/paymentDetails':
        setSelectedItem('Payments');
        break;
      case '/manageprofile/preferences':
        setSelectedItem('Chat Preferences');
        break;
      case '/manageprofile/appSumo':
        setSelectedItem('AppSumo Details');
        break;
      case '/manageprofile/webhooks':
        setSelectedItem('Webhooks');
        break;
    }
  }, []);

  const SidemenuItem = ({ icon, title }) => {
    return (
      <>
        <div
          className={'SidemenuItem Row'}
          onClick={() => {
            setSelectedItem(title);
            switch (title) {
              case 'Account details':
                navigate('/manageprofile');
                break;
              case 'People':
                navigate('/manageprofile/people');
                break;
              case 'API Key':
                navigate('/manageprofile/apikey');
                break;
              case 'Your AppSumo Plan':
                navigate('/manageprofile/planDetails');
                break;
              case 'Subscriptions':
                navigate('/manageprofile/subscriptions');
                break;
              case 'Payments':
                navigate('/manageprofile/paymentDetails');
                break;
              case 'Chat Preferences':
                navigate('/manageprofile/preferences');
                break;
              case 'All AppSumo Plans':
                navigate('/manageprofile/appSumo');
                break;
              case 'Webhooks':
                navigate('/manageprofile/webhooks');
                break;
            }
          }}
        >
          <div className={`InnerContainer Row ${selectedItem === title ? 'selectedItem' : ''}`}>
            {icon}
            <p className={`InnerText ${selectedItem === title ? 'selectedText' : ''}`}>{title}</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {/* {showWelcomeModal && <AppsumoWelcomeModal show={showWelcomeModal} setShow={setShowWelcomeModal} />} */}
      <Container maxWidth="false" disableGutters style={{ height: '100dvh' }}>
        <div className={'ManageProfileBox Row'}>
          <div className={'BoxColumn1 Column'}>
            <div className={'SidemenuHeader Row'}>
              <img
                src={images.BackArrowBlack}
                className={'IconStyle'}
                onClick={() => {
                  navigate('/creator');
                }}
              ></img>
              <p className={'TextStyle'}>{'Manage Profile'}</p>
            </div>
            <SidemenuItem
              icon={
                <BiBriefcase
                  size={18}
                  color={selectedItem === 'Account details' ? '#5A3897' : '#262626'}
                />
              }
              title={'Account details'}
            />
            {isPremiumUser && (
              <SidemenuItem
                icon={
                  <BsPeople size={18} color={selectedItem === 'People' ? '#5A3897' : '#262626'} />
                }
                title={'People'}
              />
            )}
            {isPremiumUser && (
              <SidemenuItem
                icon={
                  <BsKey size={18} color={selectedItem === 'API Key' ? '#5A3897' : '#262626'} />
                }
                title={'API Key'}
              />
            )}
            {isPremiumUser && localdata?.isAppsumoUser && (
              <SidemenuItem
                icon={
                  <LuBookMinus
                    size={18}
                    color={selectedItem === 'Plan Details' ? '#5A3897' : '#262626'}
                  />
                }
                title={'Your AppSumo Plan'}
              />
            )}
            {isPremiumUser && (
              <SidemenuItem
                icon={
                  <IoWalletOutline
                    size={18}
                    color={selectedItem === 'Payments' ? '#5A3897' : '#262626'}
                  />
                }
                title={'Payments'}
              />
            )}
            {isPremiumUser && (
              <SidemenuItem
                icon={
                  <IoWalletOutline
                    size={18}
                    color={selectedItem === 'Subscriptions' ? '#5A3897' : '#262626'}
                  />
                }
                title={'Subscriptions'}
              />
            )}
            {isPremiumUser && (
              <SidemenuItem
                icon={
                  <BsChat
                    size={18}
                    color={selectedItem === 'Chat Preferences' ? '#5A3897' : '#262626'}
                  />
                }
                title={'Chat Preferences'}
              />
            )}
            {isPremiumUser && localdata?.isAppsumoUser && (
              <SidemenuItem
                icon={
                  <BsPeople
                    size={18}
                    color={selectedItem === 'AppSumo Details' ? '#5A3897' : '#262626'}
                  />
                }
                title={'All AppSumo Plans'}
              />
            )}
            {isPremiumUser && (
              <SidemenuItem
                icon={
                  <PiWebhooksLogoLight
                    size={18}
                    color={selectedItem === 'Webhooks' ? '#5A3897' : '#262626'}
                  />
                }
                title={'Webhooks'}
              />
            )}
          </div>
          <div className={'BoxColumn2 Column'}>
            <Routes>
              <Route path="/" element={<AccountDetails />} />
              <Route path="people" element={<People />} />
              <Route path="apikey" element={<APIKey />} />
              <Route path="planDetails" element={<PlanDetails />} />
              <Route path="paymentDetails" element={<PaymentDetails />} />
              <Route path="subscriptions" element={<SubscriptionDetails />} />
              <Route path="preferences" element={<ChatPreferences />} />
              <Route path="appSumo" element={<AppSumoDetails />} />
              <Route path="webhooks" element={<Webhooks />} />
            </Routes>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ManageProfile;
