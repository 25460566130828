import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import CheerioButton from '../../Components/CheerioButton';
import countryList from 'react-select-country-list';
import { useState, useMemo } from 'react';
import myData from './india_states_codes.json';
import { useSelector } from 'react-redux';
import {
  AISensyExploreTemplatesApi,
  createSenderId,
  resendSenderIdVerification,
} from '../../Services';
import { updateSenderId } from '../../Services';
import images from '../../Utils/images';
import { toast } from 'react-toastify';
import { ValidateEmail } from '../Chatbot/Setupbot';

export const UpdateSenderIdModal = ({
  openUpdateIdModal,
  setOpenUpdateIdModal,
  data,
  modalRender,
}) => {
  const localdata = useSelector((state) => state.main.localdata);
  const auth_token = useSelector((state) => state.main.auth_token);
  const mobile = useSelector((state) => state.main.mobile);
  const [stateValue, setStateValue] = useState(null);
  const [senderName, setSenderName] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [replyEmail, setReplyEmail] = useState('');
  const [replyName, setReplyName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPinCode] = useState('');
  const [nickName, setNickName] = useState('');
  // const [missingError,setMissingError] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [senderId, setSenderId] = useState();

  const options = useMemo(() => countryList().getData(), []);

  const updateSenderIdApiCall = (senderID) => {
    const token = auth_token;
    const data = {
      nick_name: nickName,
      name: senderName,
      email: senderEmail,
      replier_email: replyEmail,
      replier_name: replyName,
      address: address1,
      address_2: address2,
      city: city,
      state: stateValue,
      zip_code: pincode,
      country: country,
    };
    updateSenderId(data, token, senderId).then((res) => {
      if (res?.flag) {
        console.log('updated successfully', res);
        modalRender(true);
        setOpenUpdateIdModal(false);
      } else {
        toast(res?.message, { position: 'top-center' });
      }
    });
  };

  const changeHandleState = (e) => {
    console.log('state', e.target.value);
    setStateValue(e.target.value);
  };

  const changeHandleCountry = (e) => {
    console.log('v', e.target.value);
    setCountry(e.target.value);
  };

  useEffect(() => {
    if (data) {
      // Object.entries(existingData).map((data,index)=>{
      console.log('daymnn', data);
      setSenderName(data[1]?.from?.name);
      setSenderEmail(data[1]?.from?.email);
      setReplyEmail(data[1]?.reply_to?.email);
      setReplyName(data[1]?.reply_to?.name);
      setAddress1(data[1]?.address);
      setAddress2(data[1]?.address_2);
      setCountry(data[1]?.country);
      setStateValue(data[1]?.state);
      setCity(data[1]?.city);
      setPinCode(data[1]?.zip);
      setNickName(data[1]?.nickname);
      setSenderId(data[1]?.id);
    }
  }, []);

  //   useEffect(()=>{
  //     if(senderName === "" || senderEmail === "" || replyEmail=== "" || address1 === "" || city === "" || country === "" || nickName === "" ){
  //       setMissingError(true);
  //     }else{
  //       setMissingError(false);
  //     }
  //   },[senderName ,senderEmail , replyEmail , address1, city , country,nickName])

  return (
    <div>
      <Modal
        show={openUpdateIdModal}
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="my-modal"
        contentClassName="sender-id-modal"
      >
        <Modal.Body
          style={{ padding: '30px', width: '100%', overflowY: 'auto', scrollbarWidth: 'thin' }}
        >
          <div className="d-flex justify-content-between">
            <p style={{ fontSize: '20px', fontWeight: '700' }}>Update sender identity</p>
            <div className="d-flex flex-row">
              <img
                src={images.CMClose}
                onClick={() => {
                  setOpenUpdateIdModal(false);
                }}
                style={{
                  cursor: 'pointer',
                  height: 22,
                  width: 22,
                  marginInlineEnd: 8,
                }}
              ></img>
            </div>
          </div>
          {/* <div style={{fontSize:"16px",fontWeight:"400"}}>
            You are required to setup a sender ID for every promotional email you send in order to comply with the anti- spam laws such
             as CAN-SPAM and CASL.</div> */}

          <div>
            <div className="d-flex justify-content-between" style={{ marginTop: '-20px' }}>
              <div class="form-group mt-4" style={{ width: '48%' }}>
                <label
                  for="exampleFormControlInput1"
                  style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                >
                  Sender Name*
                </label>
                <input
                  class="form-control shadow-none"
                  value={senderName}
                  placeholder="Enter Here"
                  onChange={(e) => setSenderName(e.target.value)}
                  style={{
                    width: '',
                    border: '1px solid grey',
                    marginTop: '5px',
                    borderRadius: '8px',
                  }}
                />
              </div>
              <div class="form-group mt-4" style={{ width: '48%' }}>
                <label
                  for="exampleFormControlInput1"
                  style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                >
                  Sender Email*
                </label>
                <input
                  class="form-control shadow-none"
                  value={senderEmail}
                  placeholder="Enter Here"
                  onChange={(e) => setSenderEmail(e.target.value)}
                  style={{
                    width: '',
                    border: '1px solid grey',
                    marginTop: '5px',
                    borderRadius: '8px',
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div class="form-group mt-4" style={{ width: '48%' }}>
                <label
                  for="exampleFormControlInput1"
                  style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                >
                  Reply to Email*
                </label>
                <input
                  class="form-control shadow-none"
                  value={replyEmail}
                  placeholder="Enter Here"
                  onChange={(e) => setReplyEmail(e.target.value)}
                  style={{
                    width: '',
                    border: '1px solid grey',
                    marginTop: '5px',
                    borderRadius: '8px',
                  }}
                />
              </div>
              <div class="form-group mt-4" style={{ width: '48%' }}>
                <label
                  for="exampleFormControlInput1"
                  style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                >
                  Reply to Name
                </label>
                <input
                  class="form-control shadow-none"
                  value={replyName}
                  placeholder="Enter Here"
                  onChange={(e) => setReplyName(e.target.value)}
                  style={{
                    width: '',
                    border: '1px solid grey',
                    marginTop: '5px',
                    borderRadius: '8px',
                  }}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div class="form-group mt-4" style={{ width: '48%' }}>
                <label
                  for="exampleFormControlInput1"
                  style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                >
                  Address 1*
                </label>
                <input
                  class="form-control shadow-none"
                  value={address1}
                  placeholder="Enter Here"
                  onChange={(e) => setAddress1(e.target.value)}
                  style={{
                    width: '',
                    border: '1px solid grey',
                    marginTop: '5px',
                    borderRadius: '8px',
                  }}
                />
              </div>
              <div class="form-group mt-4" style={{ width: '48%' }}>
                <label
                  for="exampleFormControlInput1"
                  style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                >
                  Address 2
                </label>
                <input
                  class="form-control shadow-none"
                  value={address2}
                  placeholder="Enter Here"
                  onChange={(e) => setAddress2(e.target.value)}
                  style={{
                    width: '',
                    border: '1px solid grey',
                    marginTop: '5px',
                    borderRadius: '8px',
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div class="form-group mt-4" style={{ width: '48%' }}>
                <label
                  for="exampleFormControlInput1"
                  style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                >
                  Country*
                </label>
                {/* <Select className="react-select shadow-none" options={options} value={value} onChange={changeHandler} /> */}
                <select
                  class="form-select shadow-none"
                  value={country}
                  onChange={(e) => changeHandleCountry(e)}
                  style={{
                    overflow: 'auto',
                    border: '1px solid grey',
                    marginTop: '5px',
                    borderRadius: '8px',
                  }}
                  aria-label="Default select example"
                >
                  <option selected disabled>
                    Select country
                  </option>
                  {options &&
                    options.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div class="form-group mt-4" style={{ width: '48%' }}>
                <label
                  for="exampleFormControlInput1"
                  style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                >
                  State
                </label>
                {country === 'IN' ? (
                  <select
                    class="form-select shadow-none"
                    value={stateValue}
                    onChange={(e) => changeHandleState(e)}
                    style={{
                      overflow: 'auto',
                      border: '1px solid grey',
                      marginTop: '5px',
                      borderRadius: '8px',
                    }}
                    aria-label="Default select example"
                  >
                    <option selected disabled>
                      Select state
                    </option>
                    {myData &&
                      Object.entries(myData).map(([key, val]) => {
                        // Pretty straightforward - use key for the key and value for the value.
                        // Just to clarify: unlike object destructuring, the parameter names don't matter here.
                        return <option value={key}>{val}</option>;
                      })}
                  </select>
                ) : (
                  <input
                    value={stateValue}
                    onChange={(e) => setStateValue(e.target.value)}
                    class="form-control shadow-none"
                    placeholder="Enter Here"
                    style={{
                      width: '',
                      border: '1px solid grey',
                      marginTop: '5px',
                      borderRadius: '8px',
                    }}
                  />
                )}
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div class="form-group mt-4" style={{ width: '48%' }}>
                <label
                  for="exampleFormControlInput1"
                  style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                >
                  City*
                </label>
                <input
                  class="form-control shadow-none"
                  value={city}
                  placeholder="Enter Here"
                  onChange={(e) => setCity(e.target.value)}
                  style={{
                    width: '',
                    border: '1px solid grey',
                    marginTop: '5px',
                    borderRadius: '8px',
                  }}
                />
              </div>
              <div class="form-group mt-4" style={{ width: '48%' }}>
                <label
                  for="exampleFormControlInput1"
                  style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                >
                  Pincode
                </label>
                <input
                  class="form-control shadow-none"
                  value={pincode}
                  placeholder="Enter Here"
                  onChange={(e) => setPinCode(e.target.value)}
                  style={{
                    width: '',
                    border: '1px solid grey',
                    marginTop: '5px',
                    borderRadius: '8px',
                  }}
                />
              </div>
            </div>
            <div class="form-group mt-4">
              <label
                for="exampleFormControlInput1"
                style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
              >
                Sender’s Nickname*
              </label>
              <input
                class="form-control shadow-none"
                value={nickName}
                placeholder="Enter Here"
                onChange={(e) => setNickName(e.target.value)}
                style={{
                  width: '',
                  border: '1px solid grey',
                  marginTop: '5px',
                  borderRadius: '8px',
                }}
              />
            </div>

            <CheerioButton
              className="button"
              borderStyle={{
                height: '',
                width: '',
                marginTop: '30px',
                padding: '2%',
              }}
              textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
              btnText={'Update sender ID'}
              backColor={'black'}
              onclick={() => {
                if (ValidateEmail(senderEmail) && ValidateEmail(replyEmail)) {
                  const sEmail = senderEmail.split('@');
                  if (
                    (sEmail[1] === 'cheerio.in' && (mobile === '9779003936' || localdata?.mobile === '9779003936')) ||
                    (mobile === '8496910755' || localdata?.mobile === '8496910755')
                  ) {
                    updateSenderIdApiCall();
                  } else if (sEmail[1] !== 'cheerio.in') {
                    updateSenderIdApiCall();
                  } else {
                    toast.error('You cannot use cheerio.in in your email.', {
                      position: 'top-center',
                    });
                  }

                  //setCheck(false);
                }
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
