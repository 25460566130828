import React, { useState, useEffect } from 'react';

import colors from '../../../Utils/colors';
import { Button, Form, InputGroup } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import images from '../../../Utils/images';
import OtpVerifyPage from './OtpVerifyPage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CheerioButton from '../../../Components/CheerioButton';
import { eventsend } from '../../../Config/analyticsFunctions';
import { trackEvent } from '../../../Services';

export default function AuthSignUp({
  loginAction,
  openOtpAction,
  closeOtpAction,
  name,
  setName,
  countryCode,
  setCountryCode,
  numbervalue,
  setNumberValue,
  email,
  setEmail,
  companyName,
  setCompanyName,
  setPhoneOTPvalue,
  phoneOTPvalue,
  emailOTPvalue,
  setEmailOTPValue,
  emailOTPsent,
  phoneOTPsent,
  googleloginaction,
  password,
  setPassword,
  startTrialAction,
  phoneNumber,
  setPhoneNumber,
  setDirectPaymentID,
}) {
  const handleTrackEvent = async (event) => {
    if (email !== 'priam@cheerio.in') {
      try {
        const data = {
          email: email,
          event: event,
          properties: {
            userName: name,
            userPhone: phoneNumber,
            companyName: companyName,
          },
        };
        const response = await trackEvent(data);
        if (response?.flag) {
          console.log('event tracked successfully');
        } else {
          console.log('error in track event');
        }
      } catch (error) {
        console.log('error in track event', error);
      }
    }
  };
  const navigate = useNavigate();
  const validateAndSendOtpAction = () => {
    handleTrackEvent(
      `${isAppSumo ? 'signup_create_appsumo_button_clicked' : 'signup_start_trial_button_clicked'}`
    );
    //  signUpAction();
    eventsend('start_free_trial_signup_page_clicked');
    startTrialAction();
  };
  const [searchParams] = useSearchParams();
  const isAppSumo = searchParams.get('source') === 'appsumo';
  const appSumoEmail = searchParams.get('email');
  const isDirectPayment = searchParams.get('source') === 'payment';
  const paymentId = searchParams.get('paymentId');

  useEffect(() => {
    eventsend('signup_pageview_web');
    if (appSumoEmail) {
      setEmail(appSumoEmail);
    }
    if (isDirectPayment) {
      setDirectPaymentID(paymentId);
      // console.log('ssssss ', paymentId);
    }
  }, []);

  const token = localStorage.getItem('token');
  useEffect(() => {
    if (token) {
      console.log('Testing....');
      navigate('/creator');
    }
  }, [token]);

  const onchangePhone = (e, d, rv, fv) => {
    let no = fv.substring(fv.indexOf(' ') + 1);
    console.log('no  ', no);
    console.log('d.dialCode  ', d.dialCode);
    setCountryCode(d.dialCode);
    eventsend('dialcode_field_Sign_up-page_clicked');
    setNumberValue(no);
    setPhoneNumber(e);
    eventsend('Phone_field_Sign_up-page_clicked');
  };

  return (
    <div className="auth_authsignup">
      {/* <DeploymentBanner></DeploymentBanner> */}
      <div className="auth_authsignup__row1">
        <h3 style={styles.haveAc}>
          {'Already have an account? '}
          <span
            style={{ color: colors.linkblue, cursor: 'pointer' }}
            onClick={() => {
              loginAction();
              setPassword('');
              setName('');
              setEmail('');
              setCompanyName('');
              setNumberValue('');
              setPhoneNumber('');
            }}
          >
            {/* <div className="auth_authsignup">
      <div className="auth_authsignup__row1">
        <h3>
          {'Already have an account?'}
          <span onClick={() => loginAction(true)}> */}
            {'Login'}
          </span>
          {/* </h3> */}
        </h3>
      </div>
      <div className="auth_authsignup__body">
        <div className="auth_authsignup__body__row1">
          <h2>{isAppSumo || isDirectPayment ? 'Create Account' : 'Start free trial'}</h2>
          <div className="no_credit">
            <p>{'No credit card needed'}</p>
          </div>
        </div>
        {!(isAppSumo || isDirectPayment) && (
          <p>{`Enter details and get started with your 7 days free trial & credits worth INR 500`}</p>
        )}{' '}
        <div className="auth_authsignup__body__input_container">
          <h4>{'Name'}</h4>
          <input
            type="email"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Enter here"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              eventsend('Name_field_Sign_up-page_clicked');
            }}
          />
        </div>
        <div className="auth_authsignup__body__input_container">
          <h4>{'Phone No'}</h4>
          <PhoneInput
            inputStyle={{ width: '100%' }}
            country={'in'}
            value={phoneNumber}
            onChange={onchangePhone}
          />
        </div>
        <div className="auth_authsignup__body__input_container">
          <h4>{'Email'}</h4>
          <input
            type="email"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Enter here"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              eventsend('Email_field_Sign_up-page_clicked');
            }}
          />
        </div>
        <div className="auth_authsignup__body__input_container">
          <h4>Create Password</h4>
          <input
            type="password"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Enter here"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              eventsend('Password_field_Sign_up-page_clicked');
            }}
          />
        </div>
        <div className="auth_authsignup__body__input_container">
          <h4>Company</h4>
          {/* <div className='auth_authsignup__body__input_container'>
          <h4>
            {'Company'}
          </h4> */}
          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Enter here"
            value={companyName}
            onChange={(e) => {
              eventsend('company_field_Sign_up-page_clicked');
              setCompanyName(e.target.value);
            }}
          />
        </div>
        <p>
          {`By continuing you are agreeing to our `}
          <span
            style={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => {
              window.open('https://www.cheerio.in/terms', '_blank');
            }}
          >
            {'Terms & Conditions'}
          </span>
          {` and `}
          <span
            style={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => {
              window.open('https://www.cheerio.in/privacy', '_blank');
            }}
          >
            {'Privacy policy'}
          </span>
        </p>
        <div className="auth_authsignup__body__button_container">
          <CheerioButton
            borderStyle={{ width: '100%', border: `1px solid ${colors.black}` }}
            textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
            btnText={isAppSumo || isDirectPayment ? 'Create Account' : 'Start Trial'}
            // icon={images.HNUploadContacts}
            backColor={colors.black}
            onclick={validateAndSendOtpAction}
          />
          {/* <button
            style={{ width: '100%' }}
            type="button"
            className="btn btn-dark"
            onClick={validateAndSendOtpAction}
          >
            Start Trial
          </button>
        </div>
        {/* <div
          className="d-flex flex-row align-items-center justify-content-center mb-2"
          style={{ width: '100%' }}
        >
          <div
            style={{
              width: '45%',
              height: 1,
              backgroundColor: colors.darkgrey,
            }}
          />
          <h5 style={{ margin: 0, width: '20%', textAlign: 'center' }} className="form-label">
            OR
          </h5>
          <div
            style={{
              width: '45%',
              height: 1,
              backgroundColor: colors.darkgrey,
            }}
          </button> */}
          {/* <div className='row_or'>
            <div className='row_or__line'/>
            <h4>
              {'OR'}
            </h4>
            <div className='row_or__line'/>
          </div>

          <CheerioButton 
            borderStyle={{width:'100%',border:`1px solid ${colors.black}`}}
            textStyle={{ fontSize: 16, fontWeight: 600,color:colors.black }}
            btnText={'Signup with Google'}
            icon={images.CLgoogle}
            backColor={colors.white}
            onclick={()=>{googleloginaction()}}
          /> */}
        </div>
        {/* <Button
          style={styles.buttonStyle}
          disabled={false}
          className="d-flex flex-row justify-content-center align-items-center btncustom my-1"
          onClick={() => googleloginaction()}
        >
          <img
            src={images.CLgoogle}
            style={{
              height: 24,
              width: 24,
              objectFit: 'contain',
              marginInline: 10,
            }}
          />
          {'Signup with Google'}
        </Button> */}
      </div>
    </div>
    // <div
    //   className="auth_authsignup"
    //   style={{
    //     flexDirection: 'column',
    //     width: '100%',
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     alignItems: 'center',
    //     paddingBlock: 30,
    //     paddingInline: 40,
    //     backgroundColor: '#f44',
    //   }}
    // >
    //   <div className="" style={styles.container}>
    //     <p style={styles.haveAc}>
    //       {'Already have an account? '}
    //       <span
    //         style={{ color: colors.linkblue, cursor: 'pointer' }}
    //         onClick={() => loginAction(true)}
    //       >
    //         {'Login'}
    //       </span>
    //     </p>

    //   </div>
    //   <div style={styles.body} className="">
    //     <div style={styles.row}>
    //       <p style={styles.freeTrial}>Start free trial</p>
    //       <div style={styles.noCredit}>No credit card needed</div>
    //     </div>
    //     <div
    //       style={{ fontSize: 16, fontWeight: 400, color: colors.greys04, marginTop: 5 }}
    //     >{`Enter details and get started with your 7 days free trial & credits worth INR 5000`}</div>
    //     <div className="mb-3 mt-3" style={{ width: '100%' }}>
    //       <h6 style={{ fontSize: 16, fontWeight: 600, color: colors.black }} className="form-label">
    //         Name
    //       </h6>
    //       <input
    //         type="email"
    //         className="form-control"
    //         id="exampleFormControlInput1"
    //         placeholder="Enter here"
    //         value={name}
    //         onChange={(e) => setName(e.target.value)}
    //       />
    //     </div>
    //     <div className="mb-3 mt-1" style={{ width: '100%' }}>
    //       <h6 style={{ fontSize: 16, fontWeight: 600, color: colors.black }} className="form-label">
    //         Phone No
    //       </h6>
    //       <PhoneInput
    //         inputStyle={{ width: '100%' }}
    //         country={'in'}
    //         value={phoneNumber}
    //         onChange={onchangePhone}
    //       />
    //     </div>
    //     <div className="mb-3 mt-1" style={{ width: '100%' }}>
    //       <h6 style={{ fontSize: 16, fontWeight: 600, color: colors.black }} className="form-label">
    //         Email
    //       </h6>
    //       <input
    //         type="email"
    //         className="form-control"
    //         id="exampleFormControlInput1"
    //         placeholder="Enter here"
    //         value={email}
    //         onChange={(e) => setEmail(e.target.value)}
    //       />
    //     </div>
    //     <div className="mb-3 mt-1" style={{ width: '100%' }}>
    //       <h6 style={{ fontSize: 16, fontWeight: 600, color: colors.black }} className="form-label">
    //         Company
    //       </h6>
    //       <input
    //         type="text"
    //         className="form-control"
    //         id="exampleFormControlInput1"
    //         placeholder="Enter here"
    //         value={companyName}
    //         onChange={(e) => setCompanyName(e.target.value)}
    //       />
    //     </div>
    //     <div className="mb-3 mt-5" style={{ width: '100%' }}>
    //       <button
    //         style={{ width: '100%' }}
    //         type="button"
    //         className="btn btn-dark"
    //         onClick={validateAndSendOtpAction}
    //       >
    //         Start Trial
    //       </button>
    //     </div>
    //     <div
    //       className="d-flex flex-row align-items-center justify-content-center mb-2"
    //       style={{ width: '100%' }}
    //     >
    //       <div
    //         style={{
    //           width: '45%',
    //           height: 1,
    //           backgroundColor: colors.darkgrey,
    //         }}
    //       />
    //       <h5 style={{ margin: 0, width: '20%', textAlign: 'center' }} className="form-label">
    //         OR
    //       </h5>
    //       <div
    //         style={{
    //           width: '45%',
    //           height: 1,
    //           backgroundColor: colors.darkgrey,
    //         }}
    //       />
    //     </div>
    //     <Button
    //       style={styles.buttonStyle}
    //       disabled={false}
    //       className="d-flex flex-row justify-content-center align-items-center btncustom my-1"
    //       onClick={() => googleloginaction()}
    //     >
    //       <img
    //         src={images.CLgoogle}
    //         style={{
    //           height: 24,
    //           width: 24,
    //           objectFit: 'contain',
    //           marginInline: 10,
    //         }}
    //       />
    //       {'Signup with Google'}
    //     </Button>
    //   </div>
    // </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // height: 30,
    justifyContent: 'flex-end',
    width: '100%',
    paddingBlock: 8,
    // paddingRight: 20,
    // marginTop: 10,
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    // marginTop: 30,
    paddingLeft: '25%',
    paddingRight: '25%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  buttonStyle: {
    height: 40,
    // borderWidth: 1,
    // borderStyle: "solid",
    // borderColor: colors.white,
    borderRadius: 8,
    backgroundColor: colors.primary03,
    // display: "inline-block",
    fontSize: 16,
    fontWeight: 600,
    color: colors.black04,
    borderWidth: 1,
    width: '100%',
    backgroundColor: colors.white,
    borderColor: colors.greys01,
    marginTop: 20,
  },
  haveAc: {
    margin: 0,
    fontWeight: 400,
    fontSize: 16,
    color: colors.greys04,
    // marginLeft: "auto",
    // alignSelf: "flex-end",
  },
  noCredit: {
    backgroundColor: colors.green,
    fontSize: 12,
    padding: 5,
    paddingRight: 12,
    paddingLeft: 12,
    borderRadius: 8,
    color: colors.white,
    marginLeft: 8,
  },
  freeTrial: {
    margin: 0,
    fontSize: 24,
    fontWeight: 700,
    color: colors.black,
    fontWeight: 'bold',
  },
};
