import React from 'react'
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import { FiLock } from "react-icons/fi";

const AgentPermModal = ({ setIsAPModalOpen }) => {
  return (
    <>
    <div className='DarkBg' onClick={()=>{}}>
        <div className='Center'>
            <div className='agent_perm_modal_container' onClick={(e)=>{e.stopPropagation();}}>
                <img
                    src={images.DNAgentPerm}
                    style={{height:140,width:210,objectFit:'contain'}}
                ></img>
                <FiLock size={32} className='my-2'/>
                <h3 className='my-2'>
                    {'Access Denied'}
                </h3>
                <p className='my-2'>
                    {'You don’t have access to this feature, Please ask dashboard owner to grant access'}
                </p>
                <CheerioButton
                    borderStyle={{
                        width: '100%',
                        marginBlockStart: 10,
                        border: '1px solid black',
                    }}
                    textStyle={{
                        color: colors.white01,
                    }}
                    btnText={'Back'}
                    backColor={colors.black}
                    onclick={() => {
                        setIsAPModalOpen(false);
                    }}
                />
            </div>
        </div>
    </div>
    </>
  )
}

export default AgentPermModal