import React from 'react'

const UploaderText = ({ type , textStyle}) => {
  return (
    <>
    {(type == 'image' || type == 'IMAGE' || type == 'Image') ? (
        <>
        <p style={{...textStyle}}>
            {'Upload Image here'}<br/>
            {'Max size: 5MB'}<br/>
            {'Allowed formats: PNG, JPEG'}
        </p>
        </>
    ):(type == 'video' || type == 'VIDEO' || type == 'Video') ? (
        <>
        <p style={{...textStyle}}>
            {'Upload Video here'}<br/>
            {'Max size: 16MB'}<br/>
            {'Allowed formats: MP4, 3GP'}
        </p>
        </>
    ):(type == 'document' || type == 'DOCUMENT' || type == 'Document') && (
        <>
        <p style={{...textStyle}}>
            {'Upload Document here'}<br/>
            {'Max size: 100MB'}<br/>
            {'Allowed formats: CSV, DOC, DOCX, PDF, PPT, PPTX, XLS, XLSX'}
        </p>
        </>
    )}
    </>
  )
}

export default UploaderText