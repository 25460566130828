/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import './sass/main.scss';
import App from './App';
import { Provider } from 'react-redux';

const { store } = require('./Redux/store');
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const { PersistGate } = require('redux-persist/integration/react');
// import { persistStore } from 'redux-persist';
import { BrowserRouter } from 'react-router-dom';

const { persistor } = require('./Redux/store');
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://03474b50b6938c293c65fdcdebc781b5@o4505962094198784.ingest.sentry.io/4505962095837184',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['https://app.cheerio.in', 'https://dev.app.cheerio.in'],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactGA.initialize('G-SJH1WVX2W1');
ReactGA.send('pageview');

// let persistor = persistStore(store)
const root = ReactDOM.createRoot(document.getElementById('root')!);
const queryClient = new QueryClient();
root.render(
  <GoogleOAuthProvider clientId="926697348641-fkds5ktkf615bgtjvuddpiclcs3lkifc.apps.googleusercontent.com">
    {/* <GoogleOAuthProvider clientId='AIzaSyB1if3Nly8iNRr9LhfuZmoHGnT3uqXE6nAapps.googleusercontent.com'> */}
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HelmetProvider>
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                <App />
              </QueryClientProvider>
            </BrowserRouter>
          </HelmetProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
