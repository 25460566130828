import React from 'react';
import { useNavigate } from 'react-router-dom';
import images from '../../../Utils/images';

const PremiumFeatureModal = ({ onClose }) => {
    const navigate = useNavigate();
    const handleClose = () => {
        onClose();
        navigate('/');
    };
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(5px)',
            zIndex: 1000
        }}>
            <div style={{
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '20px',
                width: 408,
                height: 450,
                textAlign: 'center',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                position: 'relative'
            }}>
                <button
                    onClick={handleClose}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        background: 'none',
                        border: 'none',
                        fontSize: '20px',
                        cursor: 'pointer'
                    }}
                >
                    ×
                </button>

                <div style={{ marginBottom: '20px' }}>
                    <img src={images.PremiumIcon} style={{ width: 208, height: 208 }} />
                </div>

                <h2 style={{ margin: '0 0 10px', color: '#333' }}>Premium Feature</h2>

                <p style={{ margin: '0 0 20px', color: '#666' }}>
                    This feature is not included in your current active plan. Please upgrade to unlock
                </p>

                <button style={{
                    backgroundColor: '#8A2BE2',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding: '10px 20px',
                    fontSize: '16px',
                    cursor: 'pointer',
                    width: '100%'
                }}>
                    Upgrade
                </button>

                <p style={{ margin: '10px 0 0', fontSize: '14px' }}>
                    Have doubts? <a href="#" style={{ color: '#8A2BE2', textDecoration: 'none' }}>Contact us</a>
                </p>
            </div>
        </div>
    );
};

export default PremiumFeatureModal;