import React from 'react';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import CheerioButton from '../CheerioButton';
import { useNavigate } from 'react-router-dom';

const WATemplate = ({ setOpenWATemplate, proceedtoTemplate, goToTemplate }) => {
  const navigate = useNavigate();

  const gotolinkaction = () => {
    window.open('http://business.facebook.com/wa/manage/message-templates', '_blank');
  };

  return (
    <>
      <div
        style={{ ...Styles.DarkBg }}
        onClick={() => {
          setOpenWATemplate(false);
        }}
      >
        <div className="" style={{ ...Styles.Center }}>
          <div
            className="d-flex flex-column justify-content-between align-items-center"
            style={{
              width: 500,
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 0,
              border: `1px solid ${colors.borderwhite}`,
              paddingInline: 30,
              paddingBlock: 30,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h2
              style={{ padding: 0, margin: 0, fontSize: 18, fontWeight: 700, color: colors.black }}
            >
              {'WhatsApp Integration Status'}
            </h2>
            <div
              className="d-flex flex-row justify-content-between align-items-start w-75"
              style={{ marginBlockStart: 30, position: 'relative' }}
            >
              <div
                className="mx-0"
                style={{
                  height: 2,
                  width: '100%',
                  backgroundColor: colors.primary03,
                  position: 'absolute',
                  top: 12,
                  zIndex: 1,
                }}
              ></div>
              <img
                src={images.CircleCheck}
                style={{ height: 24, width: 24, objectFit: 'contain', zIndex: 2 }}
              ></img>
              <img
                src={images.CircleCheck}
                style={{ height: 24, width: 24, objectFit: 'contain', zIndex: 2 }}
              ></img>

              <img
                src={images.CircleUncheck}
                style={{
                  height: 24,
                  width: 24,
                  objectFit: 'contain',
                  zIndex: 2,
                  backgroundColor: colors.white01,
                }}
              ></img>
              <img
                src={images.CircleUncheck}
                style={{
                  height: 24,
                  width: 24,
                  objectFit: 'contain',
                  zIndex: 2,
                  backgroundColor: colors.white01,
                }}
              ></img>
            </div>
            <div
              className="d-flex flex-row justify-content-between align-items-center"
              style={{
                marginBlockEnd: 30,
                marginBlockStart: 8,
                width: '80%',
                paddingInlineStart: 8,
              }}
            >
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.black,
                }}
              >
                {'Setup'}
              </p>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.black,
                }}
              >
                {'Template Request'}
              </p>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.black,
                }}
              >
                {'Setup webhooks'}
              </p>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.black,
                }}
              >
                {'Approval'}
              </p>
            </div>
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                color: colors.black,
                width: '100%',
                padding: 0,
                margin: 0,
                marginBlock: 16,
              }}
            >
              {'Cheerio auto submits a few essential templates to Whatsapp such as '}
              <span style={{ fontWeight: 600 }}>
                {'Sell a course, Sell a Product and Share an offer'}
              </span>
              {
                '. These templates are currently under review for approval. You can view the templates at : '
              }
              <span
                style={{
                  color: colors.linkblue,
                  textDecorationLine: 'underline',
                  wordBreak: 'break-all',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  gotolinkaction();
                }}
              >
                {'http://business.facebook.com/wa/manage/message-templates'}
              </span>
            </p>
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                color: colors.black,
                width: '100%',
                padding: 0,
                margin: 0,
                marginBlockStart: 16,
                marginBlockEnd: 32,
              }}
            >
              {
                "You can test announcements using Cheerio's WhatsApp number in the meantime. In case of any queries please contact the support team."
              }
            </p>
            <div
              className="d-flex flex-row justify-content-between align-items-center w-100"
              style={{}}
            >
              {proceedtoTemplate ? (
                <>
                  <CheerioButton
                    borderStyle={{
                      borderColor: colors.primary03,
                      width: '100%',
                      marginInlineEnd: 16,
                    }}
                    textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                    btnText={'Next'}
                    backColor={colors.primary03}
                    onclick={() => {
                      // navigate('/creator')
                      // setOpenWATemplate(false)
                      goToTemplate();
                    }}
                  />
                </>
              ) : (
                <>
                  <CheerioButton
                    borderStyle={{
                      borderColor: colors.primary03,
                      width: '45%',
                      marginInlineEnd: 16,
                    }}
                    textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                    btnText={'Back to home'}
                    backColor={colors.primary03}
                    onclick={() => {
                      navigate('/creator');
                      setOpenWATemplate(false);
                    }}
                  />
                  <CheerioButton
                    borderStyle={{
                      width: '45%',
                      marginInlineStart: 16,
                      border: `1px solid ${colors.primary03}`,
                    }}
                    textStyle={{ fontSize: 14, fontWeight: 600, color: colors.primary03 }}
                    btnText={'Contact support'}
                    backColor={colors.white}
                    onclick={() => {
                      window.open(
                        'mailto:support@cheerio.freshdesk.com?subject=WhatsApp integration ticket'
                      );
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WATemplate;

const Styles = {
  DarkBg: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    width: '100vw',
    height: '100vh',
    // flex:1,
    zIndex: 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'fixed',
  },
  Center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
};
