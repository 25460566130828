import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FreeTrialModal from '../../Components/Modal/FreeTrialModal';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
// import "./index.css";
import css from './index.css';
import ReactGA from 'react-ga4';
import { eventsend } from '../../Config/analyticsFunctions';
import CheerioButton from '../../Components/CheerioButton';

const Subscription = () => {
  useEffect(() => {
    ReactGA.event('subscription_premium_pageview');
    eventsend('subscription_premium_pageview');
  }, []);

  const [isFTMOpen, setIsFTMOpen] = useState(false);
  const navigate = useNavigate();
  const localdata = useSelector((state) => state.main.localdata);

  const isPremiumUser = localdata?.premium || localdata?.free_trail?.status;

  const SubscriptionCard = ({
    plantitle,
    planprice,
    CRMtools,
    Ecredits,
    Scredits,
    Wcredits,
    Pfees,
    onclick,
    offer,
    planpricefull,
  }) => {
    return (
      <>
        <div
          className="px-4 py-4 d-flex flex-column justify-content-between align-items-center zoom"
          style={{
            height: '90%',
            width: '30%',
            backgroundColor: '#F5F5F5',
            marginInline: 35,
            ...Styles.itemborder,
          }}
        >
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <h2 style={{ ...Styles.TxtItemBig, padding: 0, margin: 0 }}>{plantitle}</h2>
            <h2 style={{ ...Styles.TxtItemBig, padding: 0, margin: 0 }}>{`\u20B9${planprice}`}</h2>
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <h2 style={{ ...Styles.TxtItemBig2 }}>{'Subscription'}</h2>
            <div className="d-flex flex-row justify-content-center align-items-center">
              {offer && (
                <span
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 12,
                    fontWeight: 400,
                    color: colors.white,
                    paddingInline: 10,
                    paddingBlock: 2,
                    backgroundColor: colors.green,
                    borderRadius: 6,
                    marginInlineEnd: 6,
                  }}
                >
                  {`${offer} OFF`}
                </span>
              )}
              {planpricefull && (
                <h2
                  style={{
                    ...Styles.TxtItemBig2,
                    color: colors.greys04,
                    textDecorationLine: 'line-through',
                  }}
                >
                  {`\u20B9${planpricefull}`}
                </h2>
              )}
            </div>
          </div>
          <div style={{ borderTop: '2px solid #E6E6E6', width: '100%' }}></div>
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <p style={{ ...Styles.TxtItemSmall }}>{'Premium Access'}</p>
            <p style={{ ...Styles.TxtItemSmall, color: colors.primary03 }}>{CRMtools}</p>
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <p style={{ ...Styles.TxtItemSmall }}>{'Emails Credits'}</p>
            <p style={{ ...Styles.TxtItemSmall, color: colors.primary03 }}>{Ecredits}</p>
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <p style={{ ...Styles.TxtItemSmall }}>{'SMS Credits'}</p>
            <p style={{ ...Styles.TxtItemSmall, color: colors.primary03 }}>{Scredits}</p>
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <p style={{ ...Styles.TxtItemSmall }}>{'WhatsApp Credits'}</p>
            <p style={{ ...Styles.TxtItemSmall, color: colors.primary03 }}>{Wcredits}</p>
          </div>
          {/* <div className='d-flex flex-row justify-content-between align-items-center w-100'>
            <p style={{...Styles.TxtItemSmall}}>{'Payment Gateway'}</p>
            <p style={{...Styles.TxtItemSmall,color:colors.primary03}}>{Pfees}</p>
          </div> */}
          <Button
            variant="primary"
            className="mx-2 px-3 btncustom"
            style={{
              width: '100%',
              backgroundColor: colors.primary03,
              borderWidth: 0,
              fontSize: 16,
              fontWeight: 600,
              color: colors.white,
            }}
            onClick={() => {
              onclick();
            }}
          >
            {'Choose plan'}
          </Button>
        </div>
      </>
    );
  };

  const SubscriptionCardNew = ({
    index,
    mid,
    planName,
    planPrice,
    wCredits,
    eCredits,
    sCredits,
    onclickAction,
  }) => {
    return (
      <>
        <div
          className="d-flex flex-column justify-content-start align-items-center"
          style={{
            width: '25%',
            // height: '100%',
            paddingBlock: 20,
            paddingInline: 20,
            marginRight: '20px',
            borderRadius: '14px',
            backgroundColor: 'white',
            border: '1px solid #CCCCCC',
          }}
        >
          <h2
            style={{
              fontSize: 20,
              fontWeight: 700,
              marginBottom: '1%',
              color: colors.black,
              textAlign: 'center',
            }}
          >
            {planName}
          </h2>
          <h2
            style={{
              fontSize: 24,
              fontWeight: 700,
              marginBottom: '1%',
              color: colors.black,
              textAlign: 'center',
              marginBlockStart: 20,
            }}
          >
            {/* {planPrice ? `\u20B9${planPrice}` : ''} */}
            {planName === 'Enterprise Plan' || planPrice ? `Talk to us` : ''}
          </h2>
          <p
            style={{
              fontSize: 16,
              fontWeight: 400,
              marginBottom: '1%',
              color: colors.greys04,
              textAlign: 'center',
            }}
          >
            {planName === 'Enterprise Plan' || planPrice ? '(No-Setup charges)' : ''}
          </p>
          <CheerioButton
            borderStyle={{
              width: '80%',
              border: 0,
              marginBottom: planName === 'Enterprise Plan' || planPrice ? '0' : '40px',
              marginTop: planName === 'Enterprise Plan' || planPrice ? '20px' : '10px',
            }}
            textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
            btnText={'Contact Us'}
            backColor={colors.primary03}
            onclick={() => {
              onclickAction();
            }}
          />
          <p
            style={{
              fontSize: 16,
              fontWeight: 400,
              color: colors.greys04,
              textAlign: 'center',
              marginBlock: 16,
            }}
          >
            {planName === 'Enterprise Plan' || planPrice
              ? '1000 Free User Initiated Conversations/month'
              : ''}
          </p>
          <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{ margin: 0, padding: 0, fontSize: 16, fontWeight: 400, color: colors.black }}
            >
              {'Manage Unlimited Contacts'}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{ margin: 0, padding: 0, fontSize: 16, fontWeight: 400, color: colors.black }}
            >
              {`Free ${wCredits} WhatsApp Credits`}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{ margin: 0, padding: 0, fontSize: 16, fontWeight: 400, color: colors.black }}
            >
              {`Free ${eCredits} Email Credits`}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{ margin: 0, padding: 0, fontSize: 16, fontWeight: 400, color: colors.black }}
            >
              {`Free ${sCredits} SMS Credits`}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{ margin: 0, padding: 0, fontSize: 16, fontWeight: 400, color: colors.black }}
            >
              {'Official WABA Cloud API Setup'}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{ margin: 0, padding: 0, fontSize: 16, fontWeight: 400, color: colors.black }}
            >
              {'Custom Email Integration'}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{ margin: 0, padding: 0, fontSize: 16, fontWeight: 400, color: colors.black }}
            >
              {'Drag & Drop Email Builder'}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{ margin: 0, padding: 0, fontSize: 16, fontWeight: 400, color: colors.black }}
            >
              {index === 1 ? 'Basic Analytics & Reports' : 'Advanced Analytics & Reports'}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{ margin: 0, padding: 0, fontSize: 16, fontWeight: 400, color: colors.black }}
            >
              {'Pre-Approved Template Library'}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{ margin: 0, padding: 0, fontSize: 16, fontWeight: 400, color: colors.black }}
            >
              {index === 1
                ? '24x7 Email Support'
                : index === 2
                  ? '24x7 Email + Chat Support'
                  : (index === 3 && '24x7 On-Call Priority Support') ||
                    (index === 4 && '24x7 On-Call Priority Support')}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{ margin: 0, padding: 0, fontSize: 16, fontWeight: 400, color: colors.black }}
            >
              {'Mobile App Access'}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{ margin: 0, padding: 0, fontSize: 16, fontWeight: 400, color: colors.black }}
            >
              {'WhatsApp Inbox & Green Tick Apply'}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={index === 1 ? images.UNSUCESS : images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{
                margin: 0,
                padding: 0,
                fontSize: 16,
                fontWeight: 400,
                color: index === 1 ? colors.greys04 : colors.black,
              }}
            >
              {'WhatsApp Chatbot Builder'}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={index === 1 ? images.UNSUCESS : images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{
                margin: 0,
                padding: 0,
                fontSize: 16,
                fontWeight: 400,
                color: index === 1 ? colors.greys04 : colors.black,
              }}
            >
              {'Dynamic Workflow Automation'}
            </p>
          </div>
          {/* <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={index === 1 || index === 2 ? images.UNSUCESS : images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{
                margin: 0,
                padding: 0,
                fontSize: 16,
                fontWeight: 400,
                color: index === 1 || index == 2 ? colors.greys04 : colors.black,
              }}
            >
              {'Dedicated Account Manager'}
            </p>
          </div> */}
          {/* <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={index === 1 || index === 2 ? images.UNSUCESS : images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{
                margin: 0,
                padding: 0,
                fontSize: 16,
                fontWeight: 400,
                color: index === 1 || index == 2 ? colors.greys04 : colors.black,
              }}
            >
              {'Mobile App Access'}
            </p>
          </div> */}
          {index !== 4 && (
            <div
              className="d-flex flex-row justify-content-start align-items-center my-1"
              style={{ width: '90%' }}
            >
              <img
                src={index === 1 || index === 2 ? images.UNSUCESS : images.SUCCESSFULL}
                style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
              ></img>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 16,
                  fontWeight: 400,
                  color: index === 1 || index == 2 ? colors.greys04 : colors.black,
                }}
              >
                {'Integration with Shopify, WooCommerce'}
              </p>
            </div>
          )}

          <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={index === 1 || index === 2 ? images.UNSUCESS : images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{
                margin: 0,
                padding: 0,
                fontSize: 16,
                fontWeight: 400,
                color: index === 1 || index == 2 ? colors.greys04 : colors.black,
              }}
            >
              {'Developer API Library'}
            </p>
          </div>

          <div
            className="d-flex flex-row justify-content-start align-items-center my-1"
            style={{ width: '90%' }}
          >
            <img
              src={index === 1 || index === 2 ? images.UNSUCESS : images.SUCCESSFULL}
              style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
            ></img>
            <p
              style={{
                margin: 0,
                padding: 0,
                fontSize: 16,
                fontWeight: 400,
                color: index === 1 || index == 2 ? colors.greys04 : colors.black,
              }}
            >
              {'Multi-Agent Login Support'}
            </p>
          </div>
          {index === 4 && (
            <div
              className="d-flex flex-row justify-content-start align-items-center my-1"
              style={{ width: '90%' }}
            >
              <img
                src={index === 1 || index === 2 ? images.UNSUCESS : images.SUCCESSFULL}
                style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
              ></img>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 16,
                  fontWeight: 400,
                  color: index === 1 || index == 2 ? colors.greys04 : colors.black,
                }}
              >
                {'Custom Integration'}
              </p>
            </div>
          )}
          {index === 4 && (
            <div
              className="d-flex flex-row justify-content-start align-items-center my-1"
              style={{ width: '90%' }}
            >
              <img
                src={index === 1 || index === 2 ? images.UNSUCESS : images.SUCCESSFULL}
                style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
              ></img>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 16,
                  fontWeight: 400,
                  color: index === 1 || index == 2 ? colors.greys04 : colors.black,
                }}
              >
                {'Business Account Manager'}
              </p>
            </div>
          )}
          {index === 4 && (
            <div
              className="d-flex flex-row justify-content-start align-items-center my-1"
              style={{ width: '90%' }}
            >
              <img
                src={index === 1 || index === 2 ? images.UNSUCESS : images.SUCCESSFULL}
                style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 6 }}
              ></img>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 16,
                  fontWeight: 400,
                  color: index === 1 || index == 2 ? colors.greys04 : colors.black,
                }}
              >
                {'Top tier API rate limit'}
              </p>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{
          backgroundColor: '#F5F5F5',
          // height: '100vh',
          width: '100%',
          paddingBlock: 30,
          paddingInline: 40,
        }}
      >
        <div className="d-flex flex-column justify-content-start align-items-center w-100 h-100">
          {isFTMOpen && (
            <FreeTrialModal
              setIsOpen={setIsFTMOpen}
              centerstyle={{ transform: 'translate(-50%,-50%)' }}
            />
          )}
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <div
              className="d-flex flex-row justify-content-start align-items-center"
              style={{ flex: 1 }}
            >
              <img
                src={images.BackArrowBlack}
                style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                onClick={() => {
                  navigate('/creator');
                }}
              />
            </div>
            <div
              className="d-flex flex-row justify-content-center align-items-center"
              style={{ flex: 2 }}
            >
              <h1
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 28,
                  fontWeight: 700,
                  color: colors.black,
                }}
              >
                {'Cheerio Subscription'}
              </h1>
            </div>
            <div
              className="d-flex flex-row justify-content-end align-items-center"
              style={{ flex: 1 }}
            >
              <CheerioButton
                borderStyle={{ marginBlock: 4 }}
                textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
                btnText={'Free Trial'}
                backColor={colors.black}
                disabled={isPremiumUser ? true : false}
                onclick={() => {
                  setIsFTMOpen(true);
                }}
              />
            </div>
          </div>

          <div
            className="d-flex flex-column justify-content-between align-items-center"
            style={{ marginBlock: 16 }}
          >
            <p
              style={{ fontSize: 20, fontWeight: 400, color: colors.greys04, textAlign: 'center' }}
            >
              {'Choose a plan that fits you the best!'}
              <br />
              {'No credit/cancellation required'}
            </p>
          </div>

          <div
            className="w-100 verythinscrollnewzero d-flex flex-row justify-content-center "
            style={{ flex: 1, border: '0px solid red', paddingTop: '20px' }}
          >
            <SubscriptionCardNew
              index={1}
              planName={'Quarterly Starter Plan'}
              planPrice={'2,000'}
              wCredits={'500'}
              eCredits={'500'}
              sCredits={'500'}
              onclickAction={() => {
                ReactGA.event('subscription_page_3Month_clicked');
                eventsend('subscription_page_3Month_clicked');
                window.open('mailto:avinash@cheerio.in?subject=Quarterly Starter Plan');
                window.open('https://pages.razorpay.com/3months-starter-plan', '_blank');
              }}
            />
            <SubscriptionCardNew
              index={2}
              mid={true}
              planName={'Half Yearly Growth Plan'}
              planPrice={'10,000'}
              wCredits={'1000'}
              eCredits={'1000'}
              sCredits={'1000'}
              onclickAction={() => {
                ReactGA.event('subscription_page_6Months_clicked');
                eventsend('subscription_page_6Months_clicked');
                window.open('mailto:avinash@cheerio.in?subject=Half Yearly Growth Plan');
                window.open('https://pages.razorpay.com/6month-growthplan', '_blank');
              }}
            />
            <SubscriptionCardNew
              index={3}
              planName={'Annual Pro Plan'}
              planPrice={'15,000'}
              wCredits={'3000'}
              eCredits={'3000'}
              sCredits={'3000'}
              onclickAction={() => {
                ReactGA.event('subscription_page_12Months_clicked');
                eventsend('subscription_page_12Months_clicked');
                window.open('mailto:avinash@cheerio.in?subject=Annual Pro Plan');
                window.open('https://pages.razorpay.com/1year-proplan', '_blank');
              }}
            />
            <SubscriptionCardNew
              index={4}
              planName={'Enterprise Plan'}
              wCredits={'10,000'}
              eCredits={'100,000'}
              sCredits={'10,000'}
              onclickAction={() => {
                window.open('https://www.cheerio.in/pricing', '_blank');
              }}
            />
          </div>
        </div>

        {/* <div className='css.column w-100 h-100'>
        <img src={images.BackArrowBlack} 
          style={{height:50,width:50,objectFit:'contain',position:'absolute',top:30,left:60,cursor:'pointer'}} 
          onClick={()=>{navigate('/creator')}}
        />
        {isFTMOpen && 
        <FreeTrialModal
          setIsOpen={setIsFTMOpen}
          
          centerstyle={{transform:'translate(-50%,-50%)'}}
        />
        }
        <div className='d-flex flex-column justify-content-evenly align-items-center w-100' style={{height:'20%'}}>
          <text style={{...Styles.TxtHeader,textAlign:'center'}}>
            {'Cheerio Subscription'}
          </text>
          <text style={{...Styles.TxtBody,textAlign:'center',fontWeight:600}}>
            {'Pick a plan which is best for you!'}
          </text>
        </div>
        <div className='d-flex flex-row justify-content-evenly align-items-center w-100' style={{height:'60%'}}>
          
          <SubscriptionCard 
            plantitle={'1 Month'}
            planprice={'3,000'}
            // planpricefull={'4,000'}
            CRMtools={'All'}
            Ecredits={'5,000'}
            Scredits={'1,500'}
            Wcredits={'500'}
            Pfees={'2%'}
            onclick={()=>{
              ReactGA.event('subscription_page_1Month_clicked');
              eventsend('subscription_page_1Month_clicked');
              window.open('https://rzp.io/i/NrhIkNyB2','_blank');
            }}
            // offer={'25%'}
          />
          
          <SubscriptionCard 
            plantitle={'6 Months'}
            planprice={'15,000'}
            planpricefull={'18,000'}
            CRMtools={'All'}
            Ecredits={'30,000'}
            Scredits={'10,000'}
            Wcredits={'3,000'}
            Pfees={'2%'}
            onclick={()=>{
              ReactGA.event('subscription_page_6Months_clicked');
              eventsend('subscription_page_6Months_clicked');
              window.open('https://rzp.io/i/QebiujK','_blank');
            }}
            offer={'16%'}
          />
          
          <SubscriptionCard 
            plantitle={'12 Months'}
            planprice={'24,000'}
            planpricefull={'36,000'}
            CRMtools={'All'}
            Ecredits={'60,000'}
            Scredits={'15,000'}
            Wcredits={'6,000'}
            Pfees={'2%'}
            onclick={()=>{
              ReactGA.event('subscription_page_12Months_clicked');
              eventsend('subscription_page_12Months_clicked');
              window.open('https://rzp.io/i/ynGMeke8W','_blank');
            }}
            offer={'33%'}
          />
          
        </div>
        
        <div className='d-flex flex-column justify-content-evenly align-items-center w-100' style={{height:'20%'}}>
          <Button variant="outline-primary" className='mx-2 px-4 py-2 trialbtn btncustom' 
          style={{width:'auto',color:colors.primary03,borderColor:colors.primary03,fontSize:26,fontWeight:600}}
          onClick={()=>{setIsFTMOpen(true)}}
          disabled={isPremiumUser ? true : false}
          >
            {'Take a free trial'}
          </Button>
          <text style={{...Styles.TxtBody,fontSize:20,textAlign:'center'}}>
            {'No cancellation needed, no charges applicable.'}
          </text>
        </div>
        
      </div> */}
      </div>
    </>
  );
};

export default Subscription;

const Styles = {
  TxtHeader: {
    fontSize: 48,
    fontWeight: 900,
    color: colors.primary03,
  },
  TxtBody: {
    fontSize: 24,
    fontWeight: 400,
    color: colors.black,
  },
  TxtItemBig: {
    fontSize: 24,
    fontWeight: 700,
    color: colors.black,
  },
  TxtItemBig2: {
    fontSize: 22,
    fontWeight: 700,
    color: colors.black,
  },
  TxtItemSmall: {
    fontSize: 18,
    fontWeight: 600,
    color: colors.black,
  },
  itemborder: {
    // backgroundColor:colors.white,
    borderRadius: 16,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.15),-2px -2px 10px 0 rgba(0, 0, 0, 0.15)',
  },
};
