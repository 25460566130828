import React, { useEffect, useState } from 'react'
import colors from '../../../Utils/colors'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useSelector } from 'react-redux';
import { V2GetFlowsList } from '../../../Services';

const InvokeNewFlowModal = ({ data, setData }) => {

  const [selectedFlow, setSelectedFlow] = useState();
  const [flowList, setFlowList] = useState([]);

  const authtoken = useSelector((state) => state.main.auth_token);

  useEffect(()=>{
    getFlowListAPICall();
  },[])

  useEffect(()=>{
    if(data){
      setSelectedFlow(data?.flowID);
    }
  },[])

  useEffect(()=>{
    setData({...data,
      flowName: flowList.filter((e)=>e._id === selectedFlow)[0]?.name,
      flowID: selectedFlow
    })
  },[selectedFlow])

  const getFlowListAPICall = () => {
    let token = authtoken;
    V2GetFlowsList(token).then((res)=>{
      if(res){
        console.log("success response ===> ",res);
        setFlowList(res?.data);
      }else{
        console.log("error response ===> ",res);
      }
    })
  }

  return (
    <>
    <div className='w-100 h-100 flex-column justify-content-start align-items-start'>
      <InputGroup className="mb-3" style={{width:'100%',marginBlock:16}}>
        <Form.Label style={{fontSize:14,fontWeight:600,color:colors.black}}>{'Select flow'}</Form.Label>
        <Form.Select type='text' placeholder="select" className='w-100 btncustom dropdown'
          style={{borderRadius:8,fontSize:16,fontWeight:400,color:colors.greys01}}
          value={selectedFlow}
          onChange={(e)=>{setSelectedFlow(e.target.value)}}
        >
          <option style={{width:'100%',marginTop:4,marginBottom:4,borderRadius:8}} key="blankchoice" hidden value>
            {'Choose flow'}
          </option>
          {flowList && flowList.map((item,index)=>{
            return(
              <option style={{width:'100%',marginTop:4,marginBottom:4,borderRadius:8}} value={item._id} key={`EB${item?._id}`}>
                {item?.name}
              </option>
            )
          })}
        </Form.Select> 
      </InputGroup>
    </div>
    </>
  )
}

export default InvokeNewFlowModal