import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getClientAttributes } from '../../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CheerioButton from '../../../Components/CheerioButton';
import images from '../../../Utils/images';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import colors from '../../../Utils/colors';

const UpdateShopifyAttributesModal = ({ data, setData, prevNode }) => {
  const authtoken = useSelector((state) => state.main.auth_token);

  const [isLoading, setIsLoading] = useState(false);
  const [attributesList, setAttributesList] = useState(['name', 'email', 'phone']);
  const [variableList, setVariableList] = useState([]);
  const [attributesInput, setAttributesInput] = useState([
    { ShopifyAttribute: '', CheerioAttribute: '' },
  ]);
  const [draftPropertiesInput, setDraftPropertiesInput] = useState([
    { ShopifyNoteAttribute: '', ShopifyNoteValue: '' },
  ]);
  const ShopifyAttribute = [
    'email',
    'first_name',
    'address1',
    'phone',
    'city',
    'zip',
    'province',
    'country',
    'last_name',
    'address2',
    'company',
    'latitude',
    'longitude',
    'name',
    'country_code',
    'province_code',
  ];
  const ShopifyDraftProperties = [
    'Receiver Name',
    'Receiver Email',
    'Receiver Number',
    'Buyer Number',
    'Buyer Email',
    'Buyer Name',
    'Occasion',
    'VoucherCode',
  ];
  const [updateActionType, setUpdateActionType] = useState('updateAttribute')

  useEffect(() => {
    setIsLoading(true);
    getUserAttributesAPICall();
    if (prevNode?.data?.variable) {
      setVariableList((prev) => {
        return [prevNode?.data?.variable];
      });
      setAttributesList((prev) => {
        return [...prev, prevNode?.data?.variable];
      });
    }
    if(data?.action){
      setUpdateActionType(data?.action)
    }
    if (data?.attributes) {
      setAttributesInput(data?.attributes);
    }
    if (data?.draftOrderProperties){
      setDraftPropertiesInput(data?.draftOrderProperties)
    }
  }, []);

  useEffect(() => {
    setData({ ...data, attributes: attributesInput });
  }, [attributesInput]);

  useEffect(()=>{
    setData({...data, draftOrderProperties: draftPropertiesInput })
  },[draftPropertiesInput])

  useEffect(()=>{
    setData({...data, action: updateActionType })
  },[updateActionType])

  const getUserAttributesAPICall = () => {
    let token = authtoken;
    getClientAttributes(token).then((res) => {
      if (res?.flag) {
        setAttributesList((prev) => {
          const arr = res?.data.map((item) => item.attribute);
          return [...prev, ...arr];
        });
        // console.log("user attributes success res ---> ",res);
      } else {
        // console.log("user attributes failed res ---> ",res);
      }
    });
  };

  return (
    <>
      <div className="w-100 h-100 py-3 px-3 d-flex flex-column justify-content-start align-items-start">

        <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 16 }}>
          <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>{'Select Update Action'}</Form.Label>
          <Form.Select type='text' placeholder="select" className='w-100 btncustom dropdown'
            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
            value={updateActionType}
            onChange={(e) => { setUpdateActionType(e.target.value) }}
          >
            <option style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }} value={'updateAttribute'} key={'updateAttribute'}>
              {'Update Shopify attributes'}
            </option>
            <option style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }} value={'draftOrderProperties'} key={'draftOrderProperties'}>
              {'Update Shopify draft properties'}
            </option>
          </Form.Select>
        </InputGroup>

        {updateActionType === 'updateAttribute' ? (
          <>
          {attributesInput.length > 0 &&
          attributesInput.map((item, index) => {
            return (
              <div className="w-100 h-100 d-flex justify-content-between mb-2">
                <div className="w-100 h-100" style={{}}>
                  <h4 className="" style={{ marginBottom: '10px', fontWeight: '600' }}>
                    {'Shopify attribute'}
                  </h4>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    style={{
                      marginBottom: '5px',
                      width: '95%',
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        name="variable"
                        // value={item.variable}
                        // onChange={(e) => {
                        //   console.log('trigger', e.target.value);
                        //   handleChange(index, e);
                        // }}
                        size={'small'}
                        placeholder={'Type/Select an attribute'}
                        className={'restylingLabels'}
                        InputProps={{
                          notched: false,
                          style: {
                            borderRadius: 4,
                          },
                        }}
                        {...params}
                      />
                    )}
                    onInputChange={(e, newValue) => {
                      setAttributesInput((prev) => {
                        const newAttributesInput = [...prev];
                        newAttributesInput[index] = {
                          ...newAttributesInput[index],
                          ShopifyAttribute: newValue ?? '',
                        };
                        return newAttributesInput;
                      });
                    }}
                    onChange={(e, newValue) => {
                      setAttributesInput((prev) => {
                        const newAttributesInput = [...prev];
                        newAttributesInput[index] = {
                          ...newAttributesInput[index],
                          ShopifyAttribute: newValue ?? '',
                        };
                        return newAttributesInput;
                      });
                    }}
                    freeSolo={true}
                    value={item?.ShopifyAttribute}
                    options={ShopifyAttribute.map((val) => `${val}`)}
                  />
                </div>
                <div
                  className=""
                  style={{
                    alignSelf: 'center',
                    width: '5%',
                    marginTop: '12px',
                    marginInlineEnd: '10px',
                  }}
                >
                  <HiOutlineArrowNarrowRight
                    size={28}
                    style={{ transform: 'scaleX(1)', color: '#666666' }}
                  />
                </div>
                <div className="w-100 h-100">
                  <h4 className="" style={{ fontWeight: '600' }}>
                    {'Cheerio Attribute'}
                  </h4>
                  <div className="d-flex w-100 h-100">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      style={{
                        marginBlock: '10px',
                        width: '95%',
                      }}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          name="variable"
                          // value={item.variable}
                          // onChange={(e) => {
                          //   console.log('trigger', e.target.value);
                          //   handleChange(index, e);
                          // }}
                          size={'small'}
                          placeholder={'Type/Select'}
                          className={'restylingLabels'}
                          InputProps={{
                            notched: false,
                            style: {
                              borderRadius: 4,
                            },
                          }}
                          {...params}
                        />
                      )}
                      value={item?.CheerioAttribute}
                      onInputChange={(e, newValue) => {
                        setAttributesInput((prev) => {
                          const newAttributesInput = [...prev];
                          newAttributesInput[index] = {
                            ...newAttributesInput[index],
                            CheerioAttribute: newValue ?? '',
                          };
                          return newAttributesInput;
                        });
                      }}
                      onChange={(e, newValue) => {
                        setAttributesInput((prev) => {
                          const newAttributesInput = [...prev];
                          newAttributesInput[index] = {
                            ...newAttributesInput[index],
                            CheerioAttribute: newValue ? `%${newValue}%` : '',
                          };
                          return newAttributesInput;
                        });
                      }}
                      freeSolo={true}
                      options={attributesList.map((val) => `${val}`)}
                    />
                    <img
                      src={images.DeleteBlack}
                      style={{
                        height: 24,
                        width: 22,
                        objectFit: 'contain',
                        marginInlineStart: 8,
                        cursor: 'pointer',
                        alignSelf: 'center',
                      }}
                      onClick={() => {
                        if (attributesInput.length === 1) {
                          toast.error(
                            'Cannot delete first attribute row, at least one attribute row is mandatory',
                            { autoClose: true }
                          );
                        } else {
                          setAttributesInput((prev) => prev.filter((_, i) => i !== index));
                          toast.success('Successfully deleted attribute row!', {
                            autoClose: true,
                          });
                        }
                      }}
                    ></img>
                  </div>
                </div>
              </div>
            );
          })}

          <div className="w-100" style={{ paddingBlockEnd: '5px' }}>
          {' '}
          <CheerioButton
            borderStyle={{ borderColor: '#666666' }}
            textStyle={{
              color: 'black',
              fontWeight: '600',
            }}
            btnText={'Add Attribute'}
            icon={images.IconAdd}
            backColor={''}
            onclick={() => {
              // if (attributesInput.length < 5) {
              setAttributesInput((prev) => {
                return [...prev, { ShopifyAttribute: '', CheerioAttribute: '' }];
              });
              toast.success('Added a field!', { autoClose: true });
              // } else {
              //   toast.error('You can only set up to 5 conditions', { autoClose: true });
              // }
            }}
          />
        </div>
          </>
        ):(
          <>
          {/* draft properties update */}
        {draftPropertiesInput.length > 0 &&
          draftPropertiesInput.map((item, index) => {
            return (
              <div className="w-100 h-100 d-flex justify-content-between mb-2">
                <div className="w-100 h-100" style={{}}>
                  <h4 className="" style={{ marginBottom: '10px', fontWeight: '600' }}>
                    {'Shopify note property'}
                  </h4>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    style={{
                      marginBottom: '5px',
                      width: '95%',
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        name="variable"
                        // value={item.variable}
                        // onChange={(e) => {
                        //   console.log('trigger', e.target.value);
                        //   handleChange(index, e);
                        // }}
                        size={'small'}
                        placeholder={'Type/Select an attribute'}
                        className={'restylingLabels'}
                        InputProps={{
                          notched: false,
                          style: {
                            borderRadius: 4,
                          },
                        }}
                        {...params}
                      />
                    )}
                    onInputChange={(e, newValue) => {
                      setDraftPropertiesInput((prev) => {
                        const newDraftInput = [...prev];
                        newDraftInput[index] = {
                          ...newDraftInput[index],
                          ShopifyNoteAttribute: newValue ?? '',
                        };
                        return newDraftInput;
                      });
                    }}
                    onChange={(e, newValue) => {
                      setDraftPropertiesInput((prev) => {
                        const newDraftInput = [...prev];
                        newDraftInput[index] = {
                          ...newDraftInput[index],
                          ShopifyNoteAttribute: newValue ?? '',
                        };
                        return newDraftInput;
                      });
                    }}
                    freeSolo={true}
                    value={item?.ShopifyNoteAttribute}
                    options={ShopifyDraftProperties.map((val) => `${val}`)}
                  />
                </div>
                <div
                  className=""
                  style={{
                    alignSelf: 'center',
                    width: '5%',
                    marginTop: '12px',
                    marginInlineEnd: '10px',
                  }}
                >
                  <HiOutlineArrowNarrowRight
                    size={28}
                    style={{ transform: 'scaleX(1)', color: '#666666' }}
                  />
                </div>
                <div className="w-100 h-100">
                  <h4 className="" style={{ fontWeight: '600' }}>
                    {'Cheerio Attribute'}
                  </h4>
                  <div className="d-flex w-100 h-100">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      style={{
                        marginBlock: '10px',
                        width: '95%',
                      }}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          name="variable"
                          // value={item.variable}
                          // onChange={(e) => {
                          //   console.log('trigger', e.target.value);
                          //   handleChange(index, e);
                          // }}
                          size={'small'}
                          placeholder={'Type/Select'}
                          className={'restylingLabels'}
                          InputProps={{
                            notched: false,
                            style: {
                              borderRadius: 4,
                            },
                          }}
                          {...params}
                        />
                      )}
                      value={item?.ShopifyNoteValue}
                      onInputChange={(e, newValue) => {
                        setDraftPropertiesInput((prev) => {
                          const newDraftInput = [...prev];
                          newDraftInput[index] = {
                            ...newDraftInput[index],
                            ShopifyNoteValue: newValue ?? '',
                          };
                          return newDraftInput;
                        });
                      }}
                      onChange={(e, newValue) => {
                        setDraftPropertiesInput((prev) => {
                          const newDraftInput = [...prev];
                          newDraftInput[index] = {
                            ...newDraftInput[index],
                            ShopifyNoteValue: newValue ? `%${newValue}%` : '',
                          };
                          return newDraftInput;
                        });
                      }}
                      freeSolo={true}
                      options={attributesList.map((val) => `${val}`)}
                    />
                    <img
                      src={images.DeleteBlack}
                      style={{
                        height: 24,
                        width: 22,
                        objectFit: 'contain',
                        marginInlineStart: 8,
                        cursor: 'pointer',
                        alignSelf: 'center',
                      }}
                      onClick={() => {
                        if (draftPropertiesInput.length === 1) {
                          toast.error(
                            'Cannot delete first draft property row, at least one draft property row is mandatory',
                            { autoClose: true }
                          );
                        } else {
                          setDraftPropertiesInput((prev) => prev.filter((_, i) => i !== index));
                          toast.success('Successfully deleted note property row!', {
                            autoClose: true,
                          });
                        }
                      }}
                    ></img>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="w-100" style={{ paddingBlockEnd: '5px' }}>
          {' '}
          <CheerioButton
            borderStyle={{ borderColor: '#666666' }}
            textStyle={{
              color: 'black',
              fontWeight: '600',
            }}
            btnText={'Add Note Property'}
            icon={images.IconAdd}
            backColor={''}
            onclick={() => {
              // if (attributesInput.length < 5) {
              setDraftPropertiesInput((prev) => {
                return [...prev, { ShopifyNoteAttribute: '', ShopifyNoteValue: '' }];
              });
              toast.success('Added a field!', { autoClose: true });
              // } else {
              //   toast.error('You can only set up to 5 conditions', { autoClose: true });
              // }
            }}
          />
        </div>
          </>
        )}
        

        


        

        
      </div>
    </>
  );
};

export default UpdateShopifyAttributesModal;
