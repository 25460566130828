import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import Button from 'react-bootstrap/Button';
import '../../../../src/Pages/Templates/styles.css';
import { toast } from 'react-toastify';
import { cancelSubscription } from '../../../Services';

const CancelSubscriptionModal = ({ setIsCancelModal, isCancelModal, plan }) => {
  const handleClose = () => {
    setIsCancelModal(false);
  };
  const data = {
    plan: '',
  };

  if (plan === 'Addons') {
    data.plan = 'Addons';
  } else if (plan === 'Email') {
    data.plan = 'Email';
  } else if (plan === 'walletSubscription') {
    data.plan = 'walletSubscription';
  }

  console.log(data);

  const navigate = useNavigate();
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const [tier, setTier] = useState(null);
  const getLastNumber = (plan_id) => {
    if (!plan_id) return null;
    const match = plan_id.match(/\d+$/);
    return match ? Number(match[0]) : null;
  };
  useEffect(() => {
    if (localdata?.premium_details) {
      const tierNumber = getLastNumber(localdata.premium_details.appsumo?.plan_id);
      setTier(tierNumber);
    }
  }, [localdata]);
  const [workshops, setWorkshops] = useState(0);
  const [emailCredits, setEmailCredits] = useState(0);
  const [members, setMembers] = useState(0);
  const [workflowConnectors, setWorkflowConnectors] = useState(0);
  useEffect(() => {
    if (tier === 1) {
      setWorkshops(1);
      setMembers(1);
      setEmailCredits(20000);
      setWorkflowConnectors(50);
    } else if (tier === 2) {
      setWorkshops(2);
      setMembers(2);
      setEmailCredits(50000);
      setWorkflowConnectors(50);
    } else if (tier === 3) {
      setWorkshops(4);
      setMembers(3);
      setEmailCredits(100000);
      setWorkflowConnectors(50);
    } else if (tier === 4) {
      setWorkshops(6);
      setMembers(4);
      setEmailCredits(200000);
      setWorkflowConnectors(50);
    } else if (tier === 5) {
      setWorkshops(10);
      setMembers(5);
      setEmailCredits(40000);
      setWorkflowConnectors(50);
    }
  }, [tier]);
  useEffect(() => {
    if (localdata?.addOnSubscriptionActive) {
      setWorkflowConnectors((prev) => prev + 200);
      setMembers((prev) => prev + 10);
      setWorkshops((prev) => prev + 5);
    }
  }, [localdata?.addOnSubscriptionActive, tier]);
  useEffect(() => {
    if (localdata?.emailSubscriptionActive) {
      setEmailCredits((prev) => prev + 100000);
    }
  }, [localdata?.emailSubscriptionActive, tier]);

  return (
    <div>
      <Modal show={isCancelModal} onHide={handleClose} className="custom-modal">
        <Modal.Body
          style={{
            padding: '10%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            style={{
              width: '185px',
              height: '185px',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}
            src={images.alertIcon}
          ></img>
          <p
            style={{
              fontWeight: 600,
              fontSize: '20px',
              display: 'flex',
              textAlign: 'center',
            }}
          >
            Are you sure you want to cancel add-ons bundle?
          </p>
          <p
            style={{
              paddingTop: '1%',
              paddingBottom: '10%',
              display: 'flex',
              textAlign: 'center',
            }}
          >
            {`You are currently using ${workshops} workspaces, ${members} agents, ${workflowConnectors} workflow connectors. Your flows & workspaces will stop working by the end of subscription cycle`}
          </p>

          <CheerioButton
            borderStyle={{ borderColor: colors.primary03, width: '100%' }}
            textStyle={{ color: colors.white01, fontWeight: 600 }}
            btnText={'No, I want to continue using'}
            backColor={colors.primary03}
            onclick={() => {
              setIsCancelModal(false);
            }}
          />
          <br />
          <CheerioButton
            borderStyle={{ borderColor: colors.white01, width: '100%' }}
            textStyle={{ color: colors.black02, fontWeight: 600 }}
            btnText={'Yes, Cancel Subscription'}
            backColor={colors.white01}
            onclick={() => {
              cancelSubscription(data, authtoken);
              setIsCancelModal(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CancelSubscriptionModal;
