import images from '../../../Utils/images';
import Form from 'react-bootstrap/Form';
import { ListGroup } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import colors from '../../../Utils/colors';
import { Checkbox } from '@mui/material';
import { getCatalogProducts } from '../../../Services';
import { useSelector } from 'react-redux';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import PreviewCustomTemplate from '../../CustomTemplate/Preview';
import CheerioButton from '../../../Components/CheerioButton';
import { RiDeleteBin6Line } from "react-icons/ri";
const SendWACatalogModal = ({ data, setData, setBtnDisabled }) => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const [products, setProducts] = useState(''); // Default to an empty state initially
    const [agentData, setAgentData] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [userAssignPopup, setShowUserAssignPopup] = useState(true);
    const [bodyText, setBodyText] = useState('');
    const [footerText, setFooterText] = useState('');
    const localdata = useSelector((state) => state.main.localdata);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const catalogId = localdata?.whatsapp_credentials?.activeCatalogId;
    const ai_sensy_token = localdata?.whatsapp_credentials?.ai_sensy_token;
    const [searchTerm, setSearchTerm] = useState('');
    const [showList, setShowList] = useState(false);
    const [searchProducts, setSearchProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [sections, setSections] = useState([]);
    const [sectionList, setSectionList] = useState([]);
    const [isSectionCreated, setIsSectionCreated] = useState(false);
    const [selectedSelections, setSelectedSections] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);


    const getProducts = async (catalogId, beforeParam = '', afterParam = '') => {
        const url = new URL('https://backend.aisensy.com/direct-apis/t1/product');
        const params = {
            catalogId: catalogId,
            before: beforeParam,
            after: afterParam
        };
        url.search = new URLSearchParams(params).toString();

        const options = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${ai_sensy_token}`
            }
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setFilteredProducts(data?.products);
            const uniqueProducts = data?.products.reduce((acc, product) => {
                if (!acc.find(p => p.id === product.id)) {
                    acc.push({ ...product, selected: false });
                }
                return acc;
            }, []);

            setSectionList(uniqueProducts || []);
            console.log(data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    /*     const getProducts = async (catalogId) => {
            let token = authtoken;
            const data = { catalogId: catalogId }
            getCatalogProducts(token, data).then((res) => {
                if (res?.flag) {
                    setFilteredProducts(res.data)
                } else {
                    console.error('Error fetching products:', error);
                }
            }); url.search = new URLSearchParams(params).toString();
    
            const options = {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${ai_sensy_token}`
                }
            };
    
            try {
                const response = await fetch(url, options);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setFilteredProducts(data?.products);
                const uniqueProducts = data?.products.reduce((acc, product) => {
                    if (!acc.find(p => p.id === product.id)) {
                        acc.push({ ...product, selected: false });
                    }
                    return acc;
                }, []);
    
                setSectionList(uniqueProducts || []);
                console.log(data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        }; */

    useEffect(() => {
        getProducts(catalogId);
    }, []);

    useEffect(() => {
        if (data?.completeCatalogSelected) {
            setProducts("allProducts");
        }
        else if (data?.sections?.length > 0) {
            setSections(data?.sections);
            setProducts("multiProduct");
        } else {
            setProducts("singleProduct");
            setSelectedProduct(data?.product);
        }
        if (data?.message) {
            setBodyText(data?.message);
        }
        if (data?.footerText) {
            setFooterText(data?.footerText);
        }
    }, [])

    useEffect(() => {
        if (products === "allProducts") {
            setData({ completeCatalogSelected: true, message: bodyText, footerText: footerText })
            if (bodyText) {
                setBtnDisabled(false);
            } else {
                setBtnDisabled(true);
            }
        } else if (products === "multiProduct") {
            setData({ completeCatalogSelected: false, message: bodyText, footerText: footerText, sections: sections });
            if (bodyText && sections?.length > 0) {
                setBtnDisabled(false);
            } else {
                setBtnDisabled(true);
            }
        } else {
            setData({ completeCatalogSelected: false, message: bodyText, footerText: footerText, product: selectedProduct });
            if (bodyText && selectedProduct) {
                setBtnDisabled(false);
            } else {
                setBtnDisabled(true);
            }
        }
    }, [products, selectedProduct, bodyText, footerText, sections]);


    const handleCheckboxChange = (sectionId, product) => {
        setSections((prevSections) =>
            prevSections.map(section => {
                if (section.id === sectionId) {
                    const isSelected = section.selectedProducts.some(p => p.id === product.id);
                    const selectedProducts = isSelected
                        ? section.selectedProducts.filter(p => p.id !== product.id)
                        : [...section.selectedProducts, { ...product }];
                    return { ...section, selectedProducts };
                }
                return section;
            })
        );
    };

    useEffect(() => {
        console.log(sections);
    }, [sections]);

    const createNewSection = () => {
        const newSection = {
            id: sections.length + 1,
            products: sectionList.map(product => ({ ...product, selected: false })),
            selectedProducts: []
        };
        setSections([...sections, newSection]);
    };

    console.log(filteredProducts);
    console.log(searchProducts);
    console.log(sections);

    const handleModeChange = (e) => {
        setProducts(e.target.value);
    };
    const handleBodyText = (e) => {
        setBodyText(e.target.value);
    };
    const handleFooterText = (e) => {
        setFooterText(e.target.value);
    };
    const handleFocus = () => {
        setSearchProducts(filteredProducts);
        setShowList(true);
    };
    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        if (value === '') {
            setSearchProducts(filteredProducts);
        } else {
            const filtered = searchProducts.filter(product =>
                product.name.toLowerCase().includes(value.toLowerCase())
            );
            setSearchProducts(filtered);
        }
    };

    const handleItemClick = (product) => {
        setSelectedProduct(product);
        console.log('product', product);
        console.log('selected', selectedProduct);
        setShowList(false);
    };

    const deleteSection = (sectionId) => {
        setSections(sections.filter(section => section.id !== sectionId));
    };

    console.log(selectedProduct);

    const sectionStyle = {
        border: '1px solid #ddd',
        padding: '20px',
        marginBottom: '20px'
    };
    const sectionHeaderStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    };

    const productItemStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '10px 0',
        borderBottom: '1px solid #ddd'
    };

    const productImageStyle = {
        width: '40px',
        height: '40px',
        marginRight: '10px',
        backgroundColor: '#f0f0f0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };

    const productDetailsStyle = {
        display: 'flex',
        flexDirection: 'column'
    };

    const buttonStyle = {
        display: 'block',
        margin: '20px auto',
        padding: '10px 20px',
        backgroundColor: 'white',
        color: 'black',
        border: '1px solid black',
        borderRadius: '5px',
        cursor: 'pointer'
    };
    const deleteIconStyle = {
        cursor: 'pointer'
    };
    return (
        <div>
            <Form.Group className="mb-3" style={{ width: '100%', marginBlock: 16 }}>
                <Form.Label htmlFor="dropdown" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                    Select type of product list
                </Form.Label>
                <InputGroup>
                    <Form.Select
                        id="dropdown"
                        className='w-100 btncustom dropdown'
                        style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                        onChange={handleModeChange}
                        value={products}
                    >
                        <option disabled value="">
                            Select
                        </option>
                        <option value={'allProducts'} key={'All products/Whole catalog'}>
                            {'All products/Whole catalog'}
                        </option>
                        <option value={'singleProduct'} key={'Single product'}>
                            {'Single product'}
                        </option>
                        <option value={'multiProduct'} key={'Multi product'}>
                            {'Multi product'}
                        </option>
                    </Form.Select>
                </InputGroup>
            </Form.Group>
            {products === 'allProducts' && <div>
                <Form.Group className="mb-3" style={{ width: '100%', marginBlock: 16 }}>
                    <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                        Body Text:
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            id="textInput"
                            type="text"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                            onChange={handleBodyText}
                            value={bodyText}
                            placeholder="Enter Body Text Here..."
                        />
                    </InputGroup>
                    <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                        Footer Text:
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            id="textInput"
                            type="text"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                            onChange={handleFooterText}
                            placeholder="Enter Footer Text Here..."
                            value={footerText}
                        />
                    </InputGroup>
                    <div
                        className="w-100 d-flex flex-column justify-content-start align-items-center WAPreviewScroll"
                        style={{
                            flex: 1,
                            border: `1px solid ${colors.borderwhite}`,
                            marginBlockEnd: 16,
                            marginBlockStart: 8,
                            borderRadius: 16,
                            overflowY: 'auto',
                        }}
                    >
                        <h4 style={{ fontSize: 16, fontWeight: 700, color: colors.black, paddingTop: '3%' }}>
                            {'Message Preview'}
                        </h4>
                        <div

                        >
                            <PreviewCustomTemplate
                                body={{
                                    type: "BODY",
                                    text: `${bodyText}` || "Now shop for your favourite products right here on whatsapp.",
                                    example: {
                                        body_text: [],
                                    },
                                }}
                                header={{
                                    type: "HEADER",
                                    format: "IMAGE",
                                    example: {
                                        header_text: [],
                                        header_handle: [],
                                    },
                                }}
                                footerData={{
                                    type: "FOOTER",
                                    text: `${footerText}` || "Now shop for your favourite products right here on whatsapp.",
                                }}
                                buttonData={{
                                    type: "BUTTONS",
                                    buttons: [{ type: "QUICK_REPLY", text: "View Catalog" }],
                                }}
                            />
                        </div>
                    </div>
                </Form.Group>
            </div>}
            {products === 'singleProduct' && <div>
                <Form.Group className="mb-3" style={{ width: '100%', marginBlock: 16 }}>
                    <Form.Label htmlFor="productSearch" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                        Select product
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            id="productSearch"
                            type="text"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400 }}
                            onChange={handleInputChange}
                            onFocus={handleFocus}
                            // onBlur={handleBlur}
                            value={searchTerm}
                            placeholder="Type to search..."
                        />
                    </InputGroup>
                    <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                        Body Text:
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            id="textInput"
                            type="text"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                            onChange={handleBodyText}
                            value={bodyText}
                            placeholder="Enter Body Text Here..."
                            maxLength={1024}
                        />
                    </InputGroup>
                    <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                        Footer Text (Optional):
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            id="textInput"
                            type="text"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                            onChange={handleFooterText}
                            value={footerText}
                            placeholder="Enter Footer Text Here..."
                        />
                    </InputGroup>
                    {showList && (
                        <ListGroup style={{ maxHeight: 200, overflowY: 'auto', marginTop: 8 }}>
                            {searchProducts.map(product => (
                                <ListGroup.Item key={product.id} >
                                    <div style={{ display: 'flex', alignItems: 'center' }} onMouseDown={() => handleItemClick(product)}>
                                        {product.image_url && (
                                            <img
                                                src={product.image_url}
                                                alt={product.name}
                                                style={{ width: 40, height: 40, marginRight: 10 }}
                                            />
                                        )}
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                            <div style={{ marginBottom: 4 }}>
                                                {product.name}
                                            </div>
                                            <div style={{ fontSize: 12, color: '#666666' }}>
                                                {product.retailer_id}
                                            </div>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    )}
                    <div
                        className="w-100 d-flex flex-column justify-content-start align-items-center WAPreviewScroll"
                        style={{
                            flex: 1,
                            border: `1px solid ${colors.borderwhite}`,
                            marginBlockEnd: 16,
                            marginBlockStart: 8,
                            borderRadius: 16,
                            overflowY: 'auto',
                        }}
                    >
                        <h4 style={{ fontSize: 16, fontWeight: 700, color: colors.black, paddingTop: '3%' }}>
                            {'Message Preview'}
                        </h4>
                        <div>
                            <PreviewCustomTemplate
                                body={{
                                    type: "BODY",
                                    text: `${bodyText}` || "Now shop for your favourite products right here on whatsapp.",
                                    example: {
                                        body_text: [],
                                    },
                                }}
                                header={{
                                    type: "HEADER",
                                    format: "IMAGE",
                                    image_url: selectedProduct?.image_url || images.Dummy,
                                    example: {
                                        header_text: [],
                                        header_handle: [],
                                    },
                                }}
                                footerData={{
                                    type: "FOOTER",
                                    text: `${footerText}` || "Now shop for your favourite products right here on whatsapp.",
                                }}
                                buttonData={{
                                    type: "BUTTONS",
                                    buttons: [{ type: "QUICK_REPLY", text: "View" }],
                                }}
                            />
                        </div>
                    </div>
                </Form.Group>
            </div>}
            {products === 'multiProduct' && <div>
                <Form.Group className="mb-3" style={{ width: '100%', marginBlock: 16 }}>
                    <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                        Body Text:
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            id="textInput"
                            type="text"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                            onChange={handleBodyText}
                            value={bodyText}
                            placeholder="Enter Body Text Here..."
                        />
                    </InputGroup>
                    <Form.Label htmlFor="textInput" style={{ fontWeight: 'bold', marginBottom: 8, display: 'block' }}>
                        Footer Text(Optional)
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            id="textInput"
                            type="text"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                            onChange={handleFooterText}
                            placeholder="Enter Footer Text Here..."
                            value={footerText}
                        />
                    </InputGroup>
                    <div
                    >
                    </div>
                    <div>
                        <div>
                            {/* <button onClick={createNewSection}>Create New Section</button> */}
                            {sections.map(section => (
                                <div key={section.id} style={{ paddingTop: '2%' }} className="section">
                                    <div style={sectionHeaderStyle}>
                                        <h4>Section {section.id}</h4>
                                        <RiDeleteBin6Line
                                            style={deleteIconStyle}
                                            onClick={() => deleteSection(section.id)}
                                        />
                                    </div>
                                    {section.products.map(product => (
                                        <div key={product?.id} style={productItemStyle} className="product-item">
                                            <input
                                                type="checkbox"
                                                checked={section.selectedProducts.some(p => p.id === product?.id)}
                                                onChange={() => handleCheckboxChange(section.id, product)}
                                            />
                                            {product?.image_url && (
                                                <img
                                                    src={product?.image_url}
                                                    alt={product?.name}
                                                    style={{ width: 40, height: 40, marginRight: 10 }}
                                                />
                                            )}
                                            <div style={{ ...productDetailsStyle, flexGrow: 1 }}>
                                                <span>{product?.name}</span>
                                                <span>{product?.retailer_id}</span>
                                            </div>
                                            <div>
                                                <span>{product?.price}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    <CheerioButton
                        borderStyle={{ borderColor: colors.black, width: '98%' }}
                        textStyle={{ color: colors.black, fontWeight: 600 }}
                        btnText={sections.length > 0 ? 'Create another Section' : 'Create Section'}
                        backColor={colors.white}
                        onclick={createNewSection}
                    />
                    <div
                        className="w-100 d-flex flex-column justify-content-start align-items-center WAPreviewScroll"
                        style={{
                            flex: 1,
                            border: `1px solid ${colors.borderwhite}`,
                            marginBlockEnd: 16,
                            marginBlockStart: 8,
                            borderRadius: 16,
                            overflowY: 'auto',
                        }}
                    >
                        <h4 style={{ fontSize: 16, fontWeight: 700, color: colors.black, paddingTop: '3%' }}>
                            {'Message Preview'}
                        </h4>
                        <div

                        >
                            <PreviewCustomTemplate
                                body={{
                                    type: "BODY",
                                    text: `${bodyText}` || "Now shop for your favourite products right here on whatsapp.",
                                    example: {
                                        body_text: [], // An empty array since 'body_text' is defined as never[]
                                    },
                                }}
                                header={{
                                    type: "HEADER",
                                    format: "IMAGE",
                                    // text: "Welcome to the Header Section",
                                    example: {
                                        header_text: [], // An empty array since 'header_text' is defined as never[]
                                        header_handle: [], // An empty array since 'header_handle' is defined as never[]
                                    },
                                }}
                                footerData={{
                                    type: "FOOTER",
                                    text: `${footerText}` || "Now shop for your favourite products right here on whatsapp.",
                                }}
                                buttonData={{
                                    type: "BUTTONS",
                                    buttons: [{ type: "QUICK_REPLY", text: "View Catalog" }], // An empty array since 'buttons' is defined as never[]
                                }}
                            />
                        </div>
                    </div>
                </Form.Group>
            </div>}
        </div>
    );
};

export default SendWACatalogModal;
