import React, { useRef, useState } from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images'
import CheerioButton from '../../../Components/CheerioButton';
import { toast } from 'react-toastify';
import { TextField } from '@mui/material';

const ShareModal = ({ setModalClose, _id = '123456' }) => {

    const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
    const linkRef = useRef(null);

    const [shareType, setShareType] = useState('url');
    const [height, setHeight] = useState(761)
    const [width, setWidth] = useState(398)
    const [timeDelay, setTimeDelay] = useState(2000)

    return (
        <>
            <div className='DarkBg'>
                <div className='Center'>
                    <div className='d-flex flex-column justify-content-start align-items-center' style={{
                        backgroundColor: colors.white01,
                        borderRadius: 12,
                        width: 600,
                        height: 'auto',
                        paddingBlock: 20,
                        // paddingInline:30,
                    }}
                        onClick={(e) => { e.stopPropagation() }}
                    >
                        <div className='d-flex flex-row justify-content-between align-items-center w-100' style={{
                            paddingBlockEnd: 16,
                            borderBottom: `1px solid ${colors.borderwhite}`,
                            paddingInline: 30,
                        }}>
                            <h3>
                                {'Share Form'}
                            </h3>
                            <img src={images.CMClose} style={{
                                height: 20,
                                width: 20,
                                objectFit: 'contain',
                                cursor: 'pointer',
                            }}
                                onClick={() => {
                                    setModalClose();
                                }}
                            >
                            </img>
                        </div>
                        {/* <div className='d-flex flex-row justify-content-start align-items-center w-100' style={{
                    paddingBlock:16,
                }}>
                    <h4>
                        {'Share via'}
                    </h4>

                </div> */}
                        <div className='d-flex flex-row justify-content-start align-items-center w-100 mt-2 h-auto' style={{
                            borderBottom: `1px solid ${colors.borderwhite}`,
                            paddingInline: 30,
                        }}>
                            <div className='d-flex justify-content-center align-items-center px-2 mx-2 h-100 '>
                                <h4 className='py-2'>
                                    {'Share via'}
                                </h4>
                            </div>

                            <div className='d-flex justify-content-center align-items-center px-4 h-100 py-2' style={{
                                borderBottom: shareType == 'url' ? `2px solid ${colors.primary03}` : '',
                            }}>
                                <img
                                    src={shareType == 'url' ? images.LinkSel : images.LinkUnsel}
                                    style={{
                                        height: 24,
                                        width: 24,
                                        objectFit: 'contain',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => { setShareType('url') }}
                                />
                            </div>
                            <div className='d-flex justify-content-center align-items-center px-4 h-100 py-2' style={{
                                borderBottom: shareType == 'code' ? `2px solid ${colors.primary03}` : '',
                            }}>
                                <img
                                    src={shareType == 'code' ? images.CodeSel : images.CodeUnsel}
                                    style={{
                                        height: 24,
                                        width: 24,
                                        objectFit: 'contain',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => { setShareType('code') }}
                                />
                            </div>
                            <div className='d-flex justify-content-center align-items-center px-4 h-100 py-2' style={{
                                borderBottom: shareType == 'popup' ? `2px solid ${colors.primary03}` : '',
                            }}>
                                <img
                                    src={shareType == 'popup' ? images.PopupSel : images.PopupUnsel}
                                    style={{
                                        height: 24,
                                        width: 24,
                                        objectFit: 'contain',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => { setShareType('popup') }}
                                />
                            </div>

                        </div>

                        <div className='d-flex flex-column justify-content-start align-items-center w-100' style={{
                            borderRadius: 12,
                            marginBlock: 16,
                            paddingInline: 30,
                        }}>
                            <h4 className='my-2 w-100'>
                                {shareType == 'url' ? 'Link' : shareType == 'code' ? 'Embeddable form code' : shareType == 'popup' && 'Embeddable Pop-up form code'}
                            </h4>
                            <div className='d-flex flex-column justify-content-start align-items-start w-100 px-3 py-2' style={{
                                borderRadius: 12,
                                marginBlock: 16,
                                backgroundColor: colors.white03,
                            }}>
                                {shareType == 'code' &&
                                    <>
                                        <p className='w-100' style={{
                                            color: colors.greys04
                                        }}>
                                            {"<!-- To embed the form anywhere on your website, please create a new `<div>` with id='form-script' -->"}
                                        </p>
                                    </>
                                }
                                <p ref={linkRef} className='my-2 w-100' style={{
                                    textDecorationLine: shareType == 'url' ? 'underline' : 'none',
                                    color: colors.linkblue,
                                    cursor: shareType == 'url' ? 'pointer' : '',
                                    userSelect: 'text',
                                }}
                                    onClick={() => {
                                        if (shareType == 'url') {
                                            window.open(`${baseUrl}/form/${_id}`)
                                        }
                                    }}
                                >
                                    {shareType == 'url' ? `${baseUrl}/form/${_id}` :
                                        shareType == 'code' ? `<script src="https://ddy5h2g430ghl.cloudfront.net/formScript.js" id="form-script" form-id="${_id}" height="${height}px" width="${width}px"></script>` :
                                            shareType == 'popup' && `<script src="https://ddy5h2g430ghl.cloudfront.net/formScript.js" id="form-script" form-id="${_id}" height="${height}px" width="${width}px" delayTime="${timeDelay}"></script>`
                                    }
                                </p>
                            </div>

                            {shareType == 'code' &&
                                <>
                                    <div className='d-flex flex-row justify-content-start align-items-center w-100 my-2'>
                                        <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                                            <p style={{ fontWeight: 500 }}>
                                                {'Form Height (in px)'}
                                            </p>
                                            <p style={{ fontSize: 12, fontWeight: 400, color: colors.greys04 }}>
                                                {'Recommended height: 761'}
                                            </p>
                                            <TextField
                                                name="formHeight"
                                                value={height}
                                                onChange={(e) => { setHeight(e.target.value) }}
                                                type='number'
                                                size='small'
                                                style={{ marginBlock: 8, width: '100%' }}
                                                className='btncustom'
                                            // InputProps={{ inputProps: { max: 761 } }}
                                            />
                                        </div>
                                        <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                                            <p style={{ fontWeight: 500 }}>
                                                {'Form Width (in px)'}
                                            </p>
                                            <p style={{ fontSize: 12, fontWeight: 400, color: colors.greys04 }}>
                                                {'Recommended width: 398'}
                                            </p>
                                            <TextField
                                                name="formWidth"
                                                value={width}
                                                onChange={(e) => { setWidth(e.target.value) }}
                                                type='number'
                                                size='small'
                                                style={{ marginBlock: 8, width: '100%' }}
                                                className='btncustom'
                                            // InputProps={{ inputProps: { max: 398 } }}
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                            {shareType == 'popup' &&
                                <>
                                    <div className='d-flex flex-row justify-content-start align-items-center w-100 my-2'>
                                        <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                                            <p style={{ fontWeight: 500 }}>
                                                {'Form Height (px)'}
                                            </p>
                                            <p style={{ fontSize: 12, fontWeight: 400, color: colors.greys04 }}>
                                                {'Recommended height: 761'}
                                            </p>
                                            <TextField
                                                name="formHeight"
                                                value={height}
                                                onChange={(e) => { setHeight(e.target.value) }}
                                                type='number'
                                                size='small'
                                                style={{ marginBlock: 8, width: '100%' }}
                                                className='btncustom'
                                            // InputProps={{ inputProps: { max: 761 } }}
                                            />
                                        </div>
                                        <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                                            <p style={{ fontWeight: 500 }}>
                                                {'Form Width (px)'}
                                            </p>
                                            <p style={{ fontSize: 12, fontWeight: 400, color: colors.greys04 }}>
                                                {'Recommended width: 398'}
                                            </p>
                                            <TextField
                                                name="formWidth"
                                                value={width}
                                                onChange={(e) => { setWidth(e.target.value) }}
                                                type='number'
                                                size='small'
                                                style={{ marginBlock: 8, width: '100%' }}
                                                className='btncustom'
                                            // InputProps={{ inputProps: { max: 398 } }}
                                            />
                                        </div>
                                        <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                                            <p style={{ fontWeight: 500 }}>
                                                {'Appears after (ms)'}
                                            </p>
                                            <p style={{ fontSize: 12, fontWeight: 400, color: colors.greys04 }}>
                                                {'Recommended delay: 2000'}
                                            </p>
                                            <TextField
                                                name="formDelay"
                                                value={timeDelay}
                                                onChange={(e) => { setTimeDelay(e.target.value) }}
                                                type='number'
                                                size='small'
                                                style={{ marginBlock: 8, width: '100%' }}
                                                className='btncustom'
                                            // InputProps={{ inputProps: { max: 398 } }}
                                            />
                                        </div>
                                    </div>
                                </>
                            }



                        </div>

                        <div className='d-flex flex-row justify-content-end align-items-center w-100' style={{
                            paddingBlockStart: 16,
                            borderTop: `1px solid ${colors.borderwhite}`,
                            paddingInline: 30,
                        }}>
                            <CheerioButton
                                borderStyle={{ border: `1px solid ${colors.black}`, marginInline: 8 }}
                                backColor={colors.white}
                                textStyle={{ color: colors.black }}
                                btnText={'Cancel'}
                                onclick={() => {
                                    setModalClose()
                                    // navigate('/creator/embeddableForms/create');
                                }}
                            />
                            <CheerioButton
                                borderStyle={{ border: `1px solid ${colors.primary03}`, marginInline: 8 }}
                                backColor={colors.primary03}
                                textStyle={{ color: colors.white }}
                                btnText={shareType == 'url' ? 'Copy Link' : 'Copy Code'}
                                // disabled={formID ? false : true}
                                onclick={() => {
                                    if (linkRef.current) {
                                        const textToCopy = linkRef.current.innerText;
                                        navigator.clipboard.writeText(textToCopy)
                                            .then(() => {
                                                toast.success('Text copied to clipboard')
                                                // console.log('Text copied to clipboard');
                                            })
                                            .catch(err => {
                                                toast.error('Failed to copy text')
                                                // console.error('Failed to copy text: ', err);
                                            });
                                    }
                                }}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ShareModal
