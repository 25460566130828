import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import images from '../../../Utils/images';
import SelectPageModal from './selectPageModal';
import { saveInstagramToken, savePixelConfiguration, saveFbToken, listInstagramPages, delinkInstagram, delinkFbToken, delinkPixel, getUserApi, getLeadPages, getPixelPages, savePixelPageId, saveLeadPageId, saveInstagramPageDetails } from '../../../Services';

export const Pixel = () => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const localdata = useSelector((state) => state.main.localdata);
    const [igOpen, setIgOpen] = useState(false);
    const [pixelOpen, setPixelOpen] = useState(false);
    const [leadOpen, setLeadOpen] = useState(false);
    const [pixelPages, setPixelPages] = useState([]);
    const [leadPages, setLeadPages] = useState([]);
    const [instagramPages, setInstagramPages] = useState([]);
    const [igPageId, setIgPageId] = useState("");
    const [leadPageId, setLeadPageId] = useState("");
    const [pixelPageId, setPixelPageId] = useState("");

    const [permissions, setPermissions] = useState({
        adAccounts: false,
        leads: false,
        messenger: false,
    });

    useEffect( () => {
        if(igPageId){
            saveInstagramPage();
        }

        if(leadPageId){
            saveLeadPage();
        }

        if(pixelPageId){
            savePixelPage();
        }
    }, [igPageId, pixelPageId, leadPageId])

    const getInstagramPagesListApiCall = () => {
        let token = authtoken;
        console.log("getting list of all pages api");
        listInstagramPages(token).then((res) => {
          try {
            if (res?.flag) {
              setInstagramPages(res?.data);
              setIgOpen(true);
            } else {
              toast.error(res?.message);
            }
          } catch (err) {
            toast.error(err?.message);
          }
        });
    
    };

    const saveInstagramPage = () => {
        const token = authtoken;
        const data = {
            pageId: igPageId,
        }
        saveInstagramPageDetails(token, data).then((res) => {
            try {
              if (res?.flag) {
                setIgPageId("");
                setIgOpen(false);
                getuserapicall();
                setPermissions(prev => ({ ...prev, messenger: true }));
                toast.success("Successfully connected to Instagram/Messenger", { position: 'top-center' });
              } else {
                toast.error(res?.message);
              }
            } catch (err) {
              toast.error(err?.message);
            }
          });
    }

    const getLeadsPagesListApiCall = () => {
        let token = authtoken;
        console.log("getting list of all pages api");
        getLeadPages(token).then((res) => {
          try {
            if (res?.flag) {
              setLeadPages(res?.data?.data);
              setLeadOpen(true);
            } else {
              toast.error(res?.message);
            }
          } catch (err) {
            toast.error(err?.message);
          }
        });
    };

    const saveLeadPage = () => {
        const token = authtoken;
        const data = {
            pageId: leadPageId,
        }
        saveLeadPageId(token, data).then((res) => {
            try {
              if (res?.flag) {
                setLeadPageId("");
                getuserapicall();
                setLeadOpen(false);
                setPermissions(prev => ({ ...prev, leads: true }));
                toast.success("Successfully connected to Facebook Leads", { position: 'top-center' });
              } else {
                toast.error(res?.message);
              }
            } catch (err) {
              toast.error(err?.message);
            }
          });
    }

    const getPixelPagesListApiCall = () => {
        let token = authtoken;
        console.log("getting list of all pages api");
        getPixelPages(token).then((res) => {
          try {
            if (res?.flag) {
              setPixelPages(res?.data?.data);
              setPixelOpen(true);
            } else {
              toast.error(res?.message);
            }
          } catch (err) {
            toast.error(err?.message);
          }
        });
    };

    const savePixelPage = () => {
        const token = authtoken;
        const data = {
            pageId: pixelPageId,
        }
        savePixelPageId(token, data).then((res) => {
            try {
              if (res?.flag) {
                setPixelPageId("");
                getuserapicall();
                setPermissions(prev => ({ ...prev, adAccounts: true }));
                toast.success("Successfully connected to Pixels", { position: 'top-center' });
                setPixelOpen(false);
              } else {
                toast.error(res?.message);
              }
            } catch (err) {
              toast.error(err?.message);
            }
        });
    }

    const isTokenExpired = (createdAt) => {
        if (!createdAt) return true;
        const createdDate = new Date(createdAt);
        const currentDate = new Date();
        const daysSinceCreation = (currentDate - createdDate) / (1000 * 60 * 60 * 24);
        return daysSinceCreation >= 60;
    };
    useEffect(() => {
        setPermissions({
            adAccounts: !!localdata?.metaConfig?.pixelPageId && !!localdata?.metaConfig?.pixelTokenCreatedAt && !isTokenExpired(localdata?.metaConfig?.pixelTokenCreatedAt),
            leads: !!localdata?.metaConfig?.leadsPageId && !!localdata?.metaConfig?.leadsTokenCreatedAt && !isTokenExpired(localdata?.metaConfig?.leadsTokenCreatedAt),
            messenger: !!localdata?.metaConfig?.instagramPageName,
        });
    }, [localdata]);


    const getuserapicall = () => {
        let token = authtoken;
        getUserApi(token).then((res) => {
            if (res?.flag) {
                dispatch({ type: 'addLocaldata', payload: res?.data });
            } else {
                navigate('/');
                toast.error(res?.message);
            }
        });
    };


    const permissionDetails = {
        adAccounts: {
            title: 'Meta Pixels',
            description: 'Track and optimize your ad performance seamlessly.',
        },
        leads: {
            title: 'Facebook Leads/Contacts',
            description: 'Manage and interact with your leads directly from our platform.',
        },
        messenger: {
            title: 'Instagram/Messenger Chats (End-to-End Encrypted)',
            description: 'Send and receive messages securely through Messenger and Instagram.',
        },
    };

    const handleConnectAdAccounts = async () => {
        try {
            window.FB.login(
                (response) => {
                    if (response.authResponse) {
                        const fbToken = response.authResponse.accessToken;
                        const data = { token: fbToken };
                        savePixelConfiguration(authtoken, data)
                            .then((res) => {
                                if (res?.flag) {
                                    getPixelPagesListApiCall();
                                } else {
                                    toast.error(res?.message, { position: 'top-center' });
                                }
                            })
                            .catch((e) => {
                                console.error("Error saving pixel configuration:", e);
                                toast.error("Failed to save pixel configuration", { position: 'top-center' });
                            });
                    } else {
                        console.log('User cancelled login or did not fully authorize.');
                        toast.warn("Login cancelled or not fully authorized", { position: 'top-center' });
                    }
                },
                {
                    scope: 'ads_read,ads_management,public_profile,pages_manage_ads,pages_read_engagement,business_management,pages_manage_metadata,pages_show_list,leads_retrieval',
                }
            );
        } catch (error) {
            console.error('Error connecting Ad Accounts:', error);
            toast.error("Failed to connect Ad Accounts", { position: 'top-center' });
        }
    };

    const handleConnectLeads = async () => {
        try {
            window.FB.login(
                (response) => {
                    if (response.authResponse) {
                        const fbToken = response.authResponse.accessToken;
                        saveFbToken({ token: fbToken }, authtoken)
                            .then((res) => {
                                if (res?.flag) {
                                    getLeadsPagesListApiCall();
                                } else {
                                    toast.error(res?.message, { position: 'top-center' });
                                }
                            })
                            .catch((e) => {
                                console.error("Error saving Facebook token:", e);
                                toast.error("Failed to save Facebook token", { position: 'top-center' });
                            });
                    } else {
                        console.log('User cancelled login or did not fully authorize.');
                        toast.warn("Login cancelled or not fully authorized", { position: 'top-center' });
                    }
                },
                {
                    scope: 'pages_show_list,ads_management,leads_retrieval,pages_read_engagement',
                }
            );
        } catch (error) {
            console.error('Error connecting Leads:', error);
            toast.error("Failed to connect Facebook Leads", { position: 'top-center' });
        }
    };

    const handleConnectMessenger = async () => {
        try {
            window.FB.login(
                (response) => {
                    if (response.authResponse) {
                        const fbToken = response.authResponse.accessToken;
                        saveInstagramToken(authtoken, { token: fbToken })
                            .then((res) => {
                                if (res?.flag) {
                                    getInstagramPagesListApiCall();
                                } else {
                                    toast.error(res?.message, { position: 'top-center' });
                                }
                            })
                            .catch((e) => {
                                console.error("Error saving Instagram token:", e);
                                toast.error("Failed to save Instagram token", { position: 'top-center' });
                            });
                    } else {
                        console.log('User cancelled login or did not fully authorize.');
                        toast.warn("Login cancelled or not fully authorized", { position: 'top-center' });
                    }
                },
                {
                    scope: 'pages_show_list,business_management,pages_messaging,instagram_basic,instagram_manage_messages,pages_manage_metadata,pages_read_engagement',
                }
            );
        } catch (error) {
            console.error('Error connecting Messenger:', error);
            toast.error("Failed to connect to Messenger", { position: 'top-center' });
        }
    };


    const handleDisconnect = (id) => {
        if (id === 'messenger') {
            delinkInstagram(authtoken)
                .then((res) => {
                    if (res?.flag) {
                        getuserapicall();

                        setPermissions(prev => ({ ...prev, [id]: false }));
                        toast.success("Successfully disconnected from Instagram/Messenger", { position: 'top-center' });
                    } else {
                        toast.error(res?.message || "Failed to disconnect from Instagram/Messenger", { position: 'top-center' });
                    }
                })
                .catch((error) => {
                    console.error("Error disconnecting from Instagram/Messenger:", error);
                    toast.error("Failed to disconnect from Instagram/Messenger", { position: 'top-center' });
                });
        } else if (id === 'adAccounts') {
            delinkPixel(authtoken)
                .then((res) => {
                    if (res?.flag) {
                        getuserapicall();

                        setPermissions(prev => ({ ...prev, [id]: false }));
                        toast.success("Successfully delinked Meta Pixels", { position: 'top-center' });
                    } else {
                        toast.error(res?.message || "Failed to delink Meta Pixels", { position: 'top-center' });
                    }
                })
                .catch((error) => {
                    console.error("Error delinking Meta Pixels:", error);
                    toast.error("Failed to delink Meta Pixels", { position: 'top-center' });
                });
        } else if (id === 'leads') {
            delinkFbToken(authtoken)
                .then((res) => {
                    if (res?.flag) {
                        getuserapicall();

                        setPermissions(prev => ({ ...prev, [id]: false }));
                        toast.success("Successfully delinked Facebook Leads", { position: 'top-center' });
                    } else {
                        toast.error(res?.message || "Failed to delink Facebook Leads", { position: 'top-center' });
                    }
                })
                .catch((error) => {
                    console.error("Error delinking Facebook Leads:", error);
                    toast.error("Failed to delink Facebook Leads", { position: 'top-center' });
                });
        } else {
            console.error('Invalid permission id');
        }
    };

    const handleConnect = (id) => {
        if (!permissions[id]) {
            switch (id) {
                case 'adAccounts':
                    handleConnectAdAccounts();
                    break;
                case 'leads':
                    handleConnectLeads();
                    break;
                case 'messenger':
                    handleConnectMessenger();
                    break;
                default:
                    console.error('Invalid permission id');
            }
        } else {
            handleDisconnect(id);
        }
    };


    const styles = {
        container: {
            maxWidth: '800px',
            padding: '20px',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
        },
        title: {
            fontWeight: 700,
            fontSize: 20,
            paddingTop: '2%',
        },
        expirationMessage: {
            color: 'red',
            fontSize: '12px',
            marginTop: '5px',
        },
        subtitle: {
            fontWeight: 400,
            fontSize: 16,
            color: '#666',
        },
        icon: {
            width: '44px',
            height: '44px',
            marginRight: '10px',
        },
        permissionItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '20px',
            padding: '15px',
            border: '1px solid #E6E6E6',
            borderRadius: '8px',
            backgroundColor: 'white',
            width: '100%',
            maxWidth: '800px'
        },
        permissionInfo: {
            flex: 1,
        },
        permissionTitle: {
            margin: '0 0 5px 0',
            fontWeight: 600,
            fontSize: '16px',
        },
        permissionDescription: {
            margin: '0',
            fontSize: '14px',
            color: '#666',
        },
        connectButton: {
            backgroundColor: '#8E53F7',
            color: 'white',
            border: 'none',
            padding: '8px 20px',
            borderRadius: '8px',
            cursor: 'pointer',
        },
        connectedButton: {
            backgroundColor: 'white',
            color: '#8E53F7',
            border: '1px solid #8E53F7',
            cursor: 'default',
        },
        assistance: {
            fontWeight: 700,
            fontSize: 16,
            color: 'black'
        },
        link: {
            color: '#8E53F7',
            textDecoration: 'underline',
            fontWeight: 400,
            fontSize: 16,
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <img src={images.FacebookLogo} alt="Facebook logo" style={styles.icon} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <h2 style={styles.title}>Facebook Manager Integration</h2>
                    <p style={styles.subtitle}>Sync your leads, customers from all your Facebook accounts (Ads, Marketing, Business)</p>
                </div>
            </div>
    
            {igOpen ? (
                <SelectPageModal setListPageModalOpen={setIgOpen} setSelectedPageId={setIgPageId} pagesList={instagramPages} setPagesList={setInstagramPages} type={"instagram"} />
            ) : pixelOpen ? (
                <SelectPageModal setListPageModalOpen={setPixelOpen} setSelectedPageId={setPixelPageId} pagesList={pixelPages} setPagesList={setPixelPages} type={"pixel"} />
            ) : leadOpen ? (
                <SelectPageModal setListPageModalOpen={setLeadOpen} setSelectedPageId={setLeadPageId} pagesList={leadPages} setPagesList={setLeadPages} type={"leads"} />
            ) : null}
    
            {Object.entries(permissionDetails).map(([id, details]) => (
                <div key={id} style={styles.permissionItem}>
                    <div style={styles.permissionInfo}>
                        <h3 style={styles.permissionTitle}>{details.title}</h3>
                        <p style={styles.permissionDescription}>{details.description}</p>
                        {id === 'leads' &&
                            localdata?.metaConfig?.leadsPageId &&
                            localdata?.metaConfig?.leadsTokenCreatedAt &&
                            isTokenExpired(localdata.metaConfig.leadsTokenCreatedAt) &&
                            !permissions[id] && (
                                <p style={styles.expirationMessage}>Token Expired</p>
                            )}
                        {id === 'adAccounts' &&
                            localdata?.metaConfig?.pixelPageId &&
                            localdata?.metaConfig?.pixelTokenCreatedAt &&
                            isTokenExpired(localdata.metaConfig.pixelTokenCreatedAt) &&
                            !permissions[id] && (
                                <p style={styles.expirationMessage}>Token Expired</p>
                            )}
                    </div>
                    <button
                        style={{
                            ...styles.connectButton,
                            ...(permissions[id] ? styles.connectedButton : {})
                        }}
                        onClick={() => handleConnect(id)}
                    >
                        {permissions[id] ? 'Disconnect' : 'Connect'}
                    </button>
                </div>
            ))}
    
            <p style={styles.assistance}>
                Need assistance? <a href="#" style={styles.link}>Schedule a demo.</a>
            </p>
        </div>
    );
};