import React, { useEffect, useRef, useState } from 'react';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';
import images from '../../../Utils/images';
import {
  V2WorkflowS3Link,
  getAllRespones,
  getClientAttributes,
  saveResponse,
  setupKeywordAction,
  updateKeywordAction,
} from '../../../Services';
import UploadValidator from '../../../Components/UploadValidator';
import { getImagePath } from '../../../Utils';
import { BsFileEarmark } from 'react-icons/bs';
import UserAttributesModal from '../../WhatsAppReplies/components/UserAttributesModal';
import Helper from '../../../Utils/Helper';

const SavedResponses = ({ object, options, dataObject, setDataObject, saveSuccessAction }) => {
  const [nickName, setNickName] = useState('');
  const [response, setResponse] = useState('');
  const [mediaFile, setMediaFile] = useState();

  const authtoken = useSelector((state) => state.main.auth_token);
  const upDocRef = useRef();
  const upMediaRef = useRef();
  const [upFile, setUpFile] = useState();
  const [upFile64, setUpFile64] = useState('');
  const [userAttributes, setUserAttributes] = useState();
  const [UAModalOpen, setUAModalOpen] = useState(false);
  const [UAFilterValue, setUAFilterValue] = useState('');
  const [keyPressed, setKeyPressed] = useState('none');
  const [values, setValues] = useState([]);
  const [selectedCResponse, setSelectedCResponse] = useState();
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;

  useEffect(() => {
    getUserAttributesAPICall();
    getAllResponsesApiCall();
  }, []);

  useEffect(() => {
    console.log('values exists');
    if (values?.length > 0 && dataObject?._id) {
      setSelectedCResponse(values.filter((item) => item?._id == dataObject?._id)[0] ?? null);
    }
  }, [values]);

  const getAllResponsesApiCall = () => {
    getAllRespones(authtoken).then((res) => {
      if (res?.flag) {
        // setData(true);
        setValues(res?.data);
      } else {
        toast.error(res);
      }
    });
  };

  const getUserAttributesAPICall = () => {
    let token = authtoken;
    getClientAttributes(token).then((res) => {
      if (res?.flag) {
        setUserAttributes(res?.data);
        // console.log("user attributes success res ---> ",res);
      } else {
        // console.log("user attributes failed res ---> ",res);
      }
    });
  };

  const selectedAttributeAction = (value) => {
    // console.log("selected ---> ",value)
    let currentWord = getWordAtCursor();
    setResponse((prev) => {
      let before = prev.substring(0, currentWord[1]);
      let after = prev.substring(currentWord[2] + 1);
      let editedText = before + `%${value}%` + after;
      return editedText;
    });
    document.getElementById('RespInputAttr').focus();
    setUAModalOpen(false);
  };

  function getWordAtCursor() {
    const input = document.getElementById('RespInputAttr');
    const cursorPosition = input.selectionStart;
    // Get the text content of the input field
    const text = input.value;
    // Find the start and end indices of the current word
    let startIndex = cursorPosition;
    let endIndex = cursorPosition;
    // Move startIndex to the beginning of the word
    while (startIndex > 0 && !isWordBoundary(text[startIndex - 1])) {
      startIndex--;
    }
    // Move endIndex to the end of the word
    while (endIndex < text.length && !isWordBoundary(text[endIndex])) {
      endIndex++;
    }
    // Extract the current word
    const currentWord = text.substring(startIndex, endIndex);
    // console.log("Current word:", currentWord);
    return [currentWord, startIndex, endIndex];
  }

  function isWordBoundary(char) {
    // Check if the character is a space or newline
    return char === ' ' || char === '\n';
  }

  useEffect(() => {
    if (response) {
      let currentWordArray = getWordAtCursor();
      let currentWord = currentWordArray[0];
      if (currentWord[0] === '%' && currentWord?.length > 1) {
        setUAFilterValue(currentWord?.substring(1));
        // console.log("ccccccc ",currentWord?.substring(1));
      } else {
        setUAFilterValue('');
      }
    }
  }, [response]);

  const handlefileupload = async (e, type = 'media') => {
    const file = e.target.files[0];
    if (file) {
      if (UploadValidator(file, type === 'document' ? 'document' : file?.type?.split('/')[0])) {
        setUpFile(file);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (upFile64 && upFile) {
      getS3Link();
    }
  }, [upFile64]);

  const getS3Link = () => {
    const loadingToast = toast.loading('Uploading file. Please wait.');
    let data = {
      image: upFile64,
      type: upFile?.type?.split('/')[0],
      format: upFile?.type?.split('/')[1],
    };
    let token = authtoken;
    V2WorkflowS3Link(data, token).then((res) => {
      if (res) {
        toast.update(loadingToast, {
          render: 'File uploaded.',
          type: 'success',
          autoClose: true,
          isLoading: false,

        });
        console.log('resp s3 ---> ', res);
        setMediaFile({
          format: upFile?.type?.split('/')[1],
          s3URL: res?.data?.url,
          type: upFile?.type?.split('/')[0],
          fileName: upFile?.name ? upFile?.name : res?.data?.fileName,
        });
      } else {
        toast.update(loadingToast, {
          render: 'Error occurred while uploading file, please try again later',
          type: 'error',
          autoClose: true,
          isLoading: false,

        });
        // toast.error("Error occurred while uploading media file, please try again later",{autoClose:true});
      }
    });
  };

  useEffect(() => {
    if (selectedCResponse?._id) {
      // console.log("8888888888888 ",selectedCResponse)
      setMediaFile({
        format: selectedCResponse?.file?.format,
        s3URL: selectedCResponse?.file?.s3URL,
        type: selectedCResponse?.file?.fileType,
        fileName: selectedCResponse?.file?.fileName,
      });
      setNickName(selectedCResponse?.nickName);
      setResponse(selectedCResponse?.response);
    }
  }, [selectedCResponse]);

  const saveResponseApiCall = () => {
    // if(selectedCResponse?._id){
    //   if(dataObject?._id){
    //     updateKAAPICall(selectedCResponse)
    //   }else{
    //     saveKeywordAction(selectedCResponse);
    //   }
    // }else{
    const data = {
      nickName: nickName,
      response: response,
      file: mediaFile,
    };
    saveResponse(authtoken, data).then((res) => {
      if (res?.flag) {
        console.log('saved canned response ===> ', res);
        setDataObject({
          header: {
            mediaType: res?.data?.file?.fileType ?? '',
            mediaName: res?.data?.file?.fileName ?? '',
            mediaFile: res?.data?.file?.s3URL ?? '',
          },
          body: {
            message: res?.data?.response ?? '',
          },
          options: [],
        });
        // setAddNew(false);
        // toast.success('Response saved', { position: 'top-center' });
        saveKeywordAction(res?.data);
      } else {
        toast.error(res);
      }
    });
    // }
  };

  const saveKeywordAction = (resdata) => {
    let apidata = {
      action: 'responseMessage',
      keywords: options[0]?.keywords,
      keywordCriteria: options[0]?.criteria,
      data: {
        key: 'Saved Responses',
        _id: resdata?._id,
        header: {
          mediaType: resdata?.file?.fileType ?? '',
          mediaName: resdata?.file?.fileName ?? '',
          mediaFile: resdata?.file?.s3URL ?? '',
        },
        body: {
          message: resdata?.response ?? '',
        },
        options: [],
      },
    };

    if (
      object &&
      'name' in object &&
      'startTime' in object &&
      'endTime' in object &&
      object?.days.length > 0
    ) {
      apidata = {
        ...apidata,
        timeFrame: {
          name: object?.name,
          startTime: object?.startTime,
          endTime: object?.endTime,
          days: object?.days,
        },
      };
    }

    if (dataObject?._id || Helper.HelperEditKeywordAction) {
      updateKeywordAction(apidata, authtoken, Helper.HelperKeywordActionID).then((res) => {
        if (res?.flag) {
          toast.success('keyword action updated');
          saveSuccessAction();
        } else {
          toast.error(res?.message);
        }
      });
    } else {
      setupKeywordAction(apidata, authtoken).then((res) => {
        if (res?.flag) {
          toast.success('keyword action saved');
          saveSuccessAction();
        } else {
          toast.error(res?.message);
        }
      });
    }
  };

  return (
    <>
      <div className="saved_responses_container">
        <div className="saved_responses_container__header_row">
          <h3>{'Saved Responses'}</h3>
          <CheerioButton
            borderStyle={{
              borderWidth: 0,
            }}
            textStyle={{
              fontWeight: 600,
              color: colors.white01,
            }}
            btnText={'Save'}
            backColor={colors.primary03}
            disabled={options.length > 0 ? false : true}
            onclick={() => {
              console.log('next clicked');
              saveResponseApiCall();
              // nextClickAction();
            }}
          />
        </div>
        <div className="saved_responses_container__response">
          <input
            // class="form-control shadow-none"
            className="form-control shadow-none my-2"
            placeholder="Add Canned Response"
            onChange={(e) => setNickName(e.target.value)}
            value={nickName}
            required
          />
          {UAModalOpen && (
            <UserAttributesModal
              setUserAttributesOpen={setUAModalOpen}
              userAttributesData={userAttributes}
              selectedUserAttributeAction={selectedAttributeAction}
              filterValue={UAFilterValue}
              keyPressed={keyPressed}
              setKeyPressed={setKeyPressed}
              positionStyle={{ left: '50%', top: 100 }}
            />
          )}
          {mediaFile?.s3URL && (
            <>
              <div className="d-flex flex-row justify-content-between align-items-center w-100 my-1">
                {mediaFile?.type === 'image' ? (
                  <img
                    src={getImagePath(mediaFile?.s3URL)}
                    style={{ height: 32, width: 32, objectFit: 'contain', marginInlineEnd: 6 }}
                  ></img>
                ) : mediaFile?.type === 'video' ? (
                  <video
                    src={getImagePath(mediaFile?.s3URL)}
                    style={{ height: 32, width: 40, objectFit: 'contain', marginInlineEnd: 6 }}
                  ></video>
                ) : (
                  <BsFileEarmark size={24} />
                )}

                <p
                  style={{
                    flex: 1,
                    fontSize: 14,
                    fontWeight: 400,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    wordBreak: 'break-all',
                  }}
                >
                  {mediaFile?.fileName}
                </p>
                <img
                  src={images.CMClose}
                  style={{
                    height: 20,
                    width: 20,
                    objectFit: 'contain',
                    marginInlineStart: 6,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setUpFile(null);
                    setUpFile64(null);
                    setMediaFile(null);
                  }}
                ></img>
              </div>
            </>
          )}
          <textarea
            id="RespInputAttr"
            rows="5"
            class="form-control shadow-none my-2"
            placeholder="Add response"
            style={{ resize: 'none' }}
            value={response}
            onChange={(e) => setResponse(e.target.value)}
            required
            onKeyDown={(event) => {
              if (event.key === '%' && event.shiftKey) {
                setUAModalOpen(true);
              } else if (
                event.key === 'Backspace' &&
                response.charAt(event.target.selectionStart - 1) === '%'
              ) {
                setUAModalOpen(false);
              } else if (event.key === 'ArrowUp' && UAModalOpen) {
                event.preventDefault();
                setKeyPressed('Up');
              } else if (event.key === 'ArrowDown' && UAModalOpen) {
                event.preventDefault();
                setKeyPressed('Down');
              } else if (event.key === 'Tab' && UAModalOpen) {
                event.preventDefault();
                setKeyPressed('Tab');
              } else if (event.code === 'Space') {
                setUAModalOpen(false);
              }
            }}
          />
          <div className="d-flex flex-row justify-content-end align-items-center w-100 my-2">
            <Tooltip title="Upload image/video">
              <img
                src={images.CRphotoSelect}
                style={{
                  height: 24,
                  width: 24,
                  objectFit: 'contain',
                  marginInline: 4,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (!upFile64) {
                    upMediaRef.current.click();
                  }
                }}
              ></img>
            </Tooltip>
            <Tooltip title="Upload document">
              <img
                src={images.CRdocumentSelect}
                style={{
                  height: 24,
                  width: 24,
                  objectFit: 'contain',
                  marginInline: 4,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (!upFile64) {
                    upDocRef.current.click();
                  }
                }}
              ></img>
            </Tooltip>
            <Tooltip title="File upload formats and sizes info">
              <img
                src={images.InfoCircleGrey}
                style={{
                  height: 24,
                  width: 24,
                  objectFit: 'contain',
                  marginInline: 4,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  window.open(
                    'https://developers.facebook.com/docs/whatsapp/on-premises/reference/media/',
                    '_blank'
                  );
                }}
              ></img>
            </Tooltip>
          </div>
          <input
            type="file"
            id="DocumentUpload"
            style={{ display: 'none' }}
            ref={upDocRef}
            accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,.pdf,.pdfx,.csv"
            onChange={(e) => {
              handlefileupload(e, 'document');
            }}
          />
          <input
            type="file"
            id="MediaUpload"
            style={{ display: 'none' }}
            ref={upMediaRef}
            accept="image/*,video/*"
            onChange={(e) => {
              handlefileupload(e);
            }}
          />
        </div>
        <div
          className="w-100 d-flex flex-row justify-content-start align-items-start"
          style={{ flex: 1, overflowY: 'scroll', flexWrap: 'wrap' }}
        >
          {values.length > 0 &&
            values
              .filter((item, index) => !item?.templateData?.templateID)
              .map((item, index) => {
                return (
                  <>
                    <div
                      className="py-1 px-1"
                      style={{
                        width: '50%',
                        height: 125,
                        cursor: 'pointer',
                      }}
                      key={item?._id}
                      onClick={() => {
                        setSelectedCResponse(item);
                        console.log('selected response ===> ', item);
                      }}
                    >
                      <div
                        className="d-flex flex-column justify-content-start align-items-center w-100 px-3 py-2 h-100 crhover"
                        style={{
                          // border:`1px solid ${colors.borderwhite}`,
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderColor:
                            selectedCResponse?._id === item?._id
                              ? colors.primary03
                              : colors.borderwhite,
                          borderRadius: 8,
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 600,
                            width: '100%',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            wordBreak: 'break-all',
                          }}
                        >
                          {item?.nickName}
                        </p>
                        <div style={{ width: '100%', flex: 1, overflow: 'hidden' }}>
                          <p
                            style={{
                              // width: '100%',
                              textOverflow: 'ellipsis',
                              // whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              wordBreak: 'break-all',
                            }}
                          >
                            {item?.response}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
        </div>
      </div>
    </>
  );
};

export default SavedResponses;
