import React from 'react'
import images from '../../../Utils/images';
import colors from '../../../Utils/colors';

const UnsubscribeModal = ({ setIsUnsubModalOpen }) => {
  return (
    <>
    <div className='DarkBg' onClick={()=>{}}>
        <div className='Center'>
            <div className='unsub_modal_container' onClick={(e)=>{
                e.stopPropagation();
            }}>
                <div className='unsub_modal_container__header'>
                    <h3>
                        {'Unsubscribe Information'}
                    </h3>
                    <img 
                        src={images.CMClose} 
                        style={{
                            height:24,
                            width:24,
                            objectFit:'contain',
                            cursor:'pointer',
                        }} 
                        onClick={()=>{
                            setIsUnsubModalOpen(false);
                        }}
                    />
                </div>
                <div className='unsub_modal_container__body'>
                    <p className='my-2'>
                        {'It is required for all email senders to provide recipients with an option to unsubscribe.'}
                    </p>
                    <p className='mt-3 mb-2' style={{fontWeight:700}}>
                        {'Here are the steps to follow:'}
                    </p>
                    <p className='my-2'>
                        {'1. To add unsubscribe text in your email you have to drag HTML content in your email where you want to add your unsubscribe text.'}
                    </p>
                    <img 
                        src={images.Unsub01} 
                        className='unsub_img'
                    />
                    <p className='my-2'>
                        {'2. Delete '}
                        <span style={{fontWeight:700}}>
                            {'<strong>Hello, world!</strong>'}
                        </span>
                        {' this text'}
                    </p>
                    <img 
                        src={images.Unsub02} 
                        className='unsub_img'
                    />
                    <p className='my-2'>
                        {'3. Enter your unsubscribe text in HTML section in left menu '}
                        <span style={{fontWeight:700}}>
                            {'(For example: If you wish to unsubscribe click here)'}
                        </span>
                    </p>
                    <img 
                        src={images.Unsub03} 
                        className='unsub_img'
                    />
                    <p className='my-2'>
                        {'4. To convert a text into a link that let customers unsubscribe. You have to add the text shown in the syntax below. You can copy and paste this syntax in HTML section'}
                    </p>
                    <div className='code_container_unsub'>
                        <p style={{textAlign:'center'}}>
                            {'<span id="cheerio_unsubscribe_link"> add your text here </span>'}
                        </p>
                    </div>
                    <img 
                        src={images.Unsub05} 
                        className='unsub_img'
                    />
                    <p className='my-2'>
                        {'5. Add text within the syntax in which you want link to be present. In this case the link will be added to unsubscribe and Whenever customer will click on unsubscribe they will be removed from the list'}<br/><br/>
                        {'Note: The text will automatically change to  underlined and blue in color in your email'}
                    </p>
                    <img 
                        src={images.Unsub05} 
                        className='unsub_img'
                    />
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default UnsubscribeModal