import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import Button from 'react-bootstrap/Button';
import SuccessModal from './SuccessModal';
import '../../../../src/Pages/Templates/styles.css';
import { getPaymentByUserId } from '../../../Services';

const HistoryModal = ({ setIsHistoryModalOpen, isHistoryModalOpen }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);

  const handleClose = () => {
    setIsHistoryModalOpen(false);
  };
  const walletCurrency = localdata?.walletCurrency ?? 'USD';

  const [paymentHistory, setPaymentHistory] = useState([]);

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      try {
        const data = await getPaymentByUserId(authtoken);
        if (data?.flag) {
          setPaymentHistory(data?.response?.data);
        }
      } catch (error) {
        console.error('Error fetching payment history:', error);
      }
    };

    fetchPaymentHistory();
  }, [authtoken]);

  function formatDateTime(timestamp) {
    const date = new Date(timestamp);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.error('Invalid date value:', timestamp);
      return 'Invalid date';
    }

    // Extract date components
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();

    // Create the swapped date format
    const swappedDate = `${day}-${month}-${year}`;

    // Extract time components
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    const formattedTime = `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;

    // Concatenate date and time with the desired format
    return `${swappedDate}, ${formattedTime}`;
  }

  return (
    <div>
      <Modal show={isHistoryModalOpen} onHide={handleClose} className="custom-modal">
        <Modal.Body
          style={{
            padding: '4%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '2%',
            }}
          >
            <p
              style={{
                fontWeight: 700,
                fontSize: '24px',
                lineHeight: '30px',
              }}
            >
              Wallet Transaction History
            </p>
            <img
              src={images.CMClose}
              style={{
                cursor: 'pointer',
                height: 22,
                width: 22,
                marginLeft: '28%',
              }}
              onClick={handleClose}
            ></img>
          </div>
          <div style={{ border: '1px solid #E6E6E6' }}></div>
          <div>
            {paymentHistory
              ?.filter(
                (payment) =>
                  payment?.description === 'Order' || payment?.description === 'Subscription'
              )
              ?.map((payment) => (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: '2%',
                      paddingTop: '2%',
                      paddingBottom: '2%',
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontWeight: 600,
                          fontSize: '16px',
                          lineHeight: '20px',
                        }}
                      >
                        {payment?.description === 'Order' ? 'Manual Top Up' : 'Automatic Top Up'}
                      </p>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#666666',
                          paddingTop: '5%',
                        }}
                      >
                        {formatDateTime(payment?.createdAt)}
                      </p>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#666666',
                          paddingTop: '2%',
                        }}
                      >
                        Payment Id: {payment?.paymentId}
                      </p>
                    </div>
                    <div>
                      <p
                        style={{
                          fontWeight: 600,
                          fontSize: '16px',
                          lineHeight: '20px',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end',
                        }}
                      >
                        {walletCurrency === 'INR' ? '₹' : '$'} {payment?.amount}
                      </p>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#666666',
                          paddingTop: '15%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {payment?.cardDetails
                          ? payment?.cardDetails?.type.charAt(0).toUpperCase() +
                            payment?.cardDetails?.type.slice(1)
                          : payment?.paymentMode.charAt(0).toUpperCase() +
                            payment?.paymentMode.slice(1)}
                      </p>
                    </div>
                  </div>
                  <div style={{ border: '1px solid #E6E6E6' }}></div>
                </div>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HistoryModal;
