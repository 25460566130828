import React from 'react';
import colors from '../../Utils/colors';
import images from '../../Utils/images';

const WADocs = () => {
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center w-75">
        <img
          src={images.CreatorWA}
          style={{ height: 64, width: 64, objectFit: 'contain', marginBlock: 16 }}
        />
        <text style={{ ...Styles.TxtHeading, marginBlock: 8 }}>
          {'WhatsApp registration for new business'}
        </text>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-start w-75">
        <text style={{ ...Styles.TxtBody, marginBlock: 8, flexWrap: 'wrap' }}>
          {'PreRequisites'}
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 4, flexWrap: 'wrap' }}>
          {'1. Businesses must have a Facebook page.'}
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 4, flexWrap: 'wrap' }}>
          {'2. Facebook Business page should be verified.'}
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 4, flexWrap: 'wrap' }}>
          {'3. Businesses should have a new number for WhatsApp messaging.'}
        </text>
      </div>
      <div
        className="d-flex flex-column justify-content-center align-items-start w-75"
        style={{ marginBottom: 30 }}
      >
        <text style={{ ...Styles.TxtBody, marginBlock: 8, flexWrap: 'wrap' }}>
          {'Step to follow'}
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 4, flexWrap: 'wrap' }}>
          {'1. Visit developer home page for Meta.'}
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 4, flexWrap: 'wrap' }}>
          {'2. Create New App (type - Business).'}
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 4, flexWrap: 'wrap' }}>
          {'3. Add the appropriate app name.'}
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 4, flexWrap: 'wrap' }}>
          {'4. Add contact email.'}
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 4, flexWrap: 'wrap' }}>
          {'5. Select the business account.'}
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 4, flexWrap: 'wrap' }}>
          {'6. Once an app is created, go to the developer home and select the app.'}
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 4, flexWrap: 'wrap' }}>
          {'7. Now Select -> Setup WhatsApp.'}
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 4, flexWrap: 'wrap' }}>
          {'8. Follow the steps and complete the WhatsApp setup.'}
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 4, flexWrap: 'wrap' }}>
          {'9. '}
          <text
            style={{ color: colors.linkblue, textDecorationLine: 'underline', cursor: 'pointer' }}
            onClick={() => {
              window.open(
                'https://developers.facebook.com/docs/whatsapp/',
                '_blank',
                'noopener,noreferrer'
              );
            }}
          >
            {'https://developers.facebook.com/docs/whatsapp/'}
          </text>
        </text>
        <text style={{ ...Styles.TxtBody2, marginBlock: 4, flexWrap: 'wrap' }}>
          {
            '10. Once the setup is done, please provide us the access token by following these steps - '
          }
          <text
            style={{
              color: colors.linkblue,
              textDecorationLine: 'underline',
              wordBreak: 'break-all',
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open(
                'https://developers.facebook.com/docs/whatsapp/business-management-api/get-started#1--acquire-an-access-token-using-a-system-user-or-facebook-login',
                '_blank',
                'noopener,noreferrer'
              );
            }}
          >
            {
              'https://developers.facebook.com/docs/whatsapp/business-management-api/get-started#1--acquire-an-access-token-using-a-system-user-or-facebook-login'
            }
          </text>
        </text>
      </div>
    </>
  );
};

export default WADocs;

const Styles = {
  TxtHeading: {
    fontSize: 24,
    fontWeight: 700,
    color: colors.black,
  },
  TxtBody: {
    fontSize: 18,
    fontWeight: 700,
    color: colors.black,
  },
  TxtBody2: {
    fontSize: 18,
    fontWeight: 600,
    color: colors.black04,
  },
};
