import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CheerioButton from '../../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import {
  ShopifyRemoveApi,
  ShopifyStoreIntegrationApi,
  getShopifyDetailsApi,
  shopifyAppURL,
  uninstallShopifyApp,
  updateShopifyAppURL,
  getUserApi
} from '../../../Services';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { eventsend } from '../../../Config/analyticsFunctions';

const Setup = ({ setSelectedMenuItem = false }) => {
  const [shopURL, setShopURL] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [shopifyDetails, setShopifyDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const localdata = useSelector((state) => state.main.localdata);
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [updateShop, setUpdateShop] = useState('');
  const [updateVisible, setUpdateVisible] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    eventsend("Shopify_card_Discover_page_clicked");
    getShopifyDetailsAPIcall();
  }, []);

  const getuserapicall = () => {
    let token = authtoken;
    getUserApi(token).then((res) => {
      // console.log('get user api response ==> ', res);
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
      } else {
        navigate('/');
        toast.error(res?.message);
      }
    });
  };

  const getShopifyDetailsAPIcall = () => {
    let token = authtoken;
    getShopifyDetailsApi(token).then((res) => {
      if (res?.flag) {
        setShopifyDetails(res?.data?.result);
        console.log('get Details success response ===> ', res);
      } else {
        setIsLoading(false);
        //toast.error('Shopify config or details does not exist',{position:'top-center'});
      }
    });
  };

  useEffect(() => {
    if (shopifyDetails?._id) {
      setSuccess(true);
      setIsLoading(false);
    } else if (shopifyDetails) {
      setIsLoading(false);
    }
  }, [shopifyDetails]);

  const ShopifyIntegrationAPIcall = () => {
    setIsLoading(true);
    let data = {
      user_id: localdata?._id,
      store_name: shopURL,
      access_token: accessToken,
      api_secret_key: apiKey,
    };
    let token = authtoken;
    ShopifyStoreIntegrationApi(data, token).then((res) => {
      if (res.flag) {
        setSuccess(true);
        getShopifyDetailsAPIcall();
        console.log('success response ===> ', res);
      } else {
        toast.error(
          'Incorrect credentials or Required permissions are missing!! Please read the Help Documentation below.',
          { position: 'top-center' }
        );
      }
      setIsLoading(false);
    });
  };

  const shopifyRemoveAPIcall = () => {
    setIsLoading(true);
    let data = {
      user_id: localdata?._id,
    };
    setShopURL('');
    setAccessToken('');
    setApiKey('');
    let token = authtoken;
    ShopifyRemoveApi(data, token).then((res) => {
      if (res.flag) {
        setSuccess(false);
        getuserapicall()
        console.log('remove response ===> ', res);
      } else {
        toast.error(res?.message);
      }
      setIsLoading(false);
    });
  };

  const shopifyUnlinkAPIcall = () => {
    setIsLoading(true);
    const token = authtoken
    uninstallShopifyApp(token).then((res) => {
      if (res.flag) {
        getuserapicall()
        setSuccess(false)
        toast.success(res.message)
        getShopifyDetailsAPIcall()
      }
      else {
        setIsLoading(true);
        setSuccess(false)
        toast.error(res.message)
      }
    })
  }

  useEffect(() => {
    const updateShopify = () => {
      const token = authtoken
      const localShopURL = localStorage.getItem('shopifyURL')
      if (localShopURL) {
        updateShopifyAppURL(token, localShopURL).then((res) => {
          if (res.flag) {
            localStorage.removeItem('shopifyURL')
            getuserapicall()
            toast.success('Shop URL updated successfully');
            // setUpdateShop('');
            getShopifyDetailsAPIcall();
          } else {
            toast.error(res.message);
          }
        });
      }
      else {
        setUpdateVisible(false)
      }
    }
    updateShopify()
  }, [])
  return (
    <>
      <div
        className="h-100 w-100 verythinscrollnew"
        style={{
          overflowY: 'auto',
          backgroundColor: colors.white02,
          border: `1px solid ${colors.borderwhite}`,
        }}
      >
        <div
          className="d-flex flex-column justify-content-start align-items-center w-100 "
          style={{ flex: 1 }}
        >
          {isLoading ? (
            <>
              <div
                className="d-flex flex-column justify-content-center align-items-center w-100"
                style={{ height: '80vh' }}
              >
                <SpinnerLoader />
              </div>
            </>
          ) : (
            <>
              <div
                className="d-flex flex-row justify-content-start align-items-center w-100"
                style={{ paddingInline: 30, marginBlockStart: 30, marginBlockEnd: 16 }}
              >
                <img
                  src={images.ShopifyLogo}
                  style={{ height: 48, width: 42, objectFit: 'contain' }}
                ></img>
                <div
                  className="d-flex flex-column justify-content-between align-items-start"
                  style={{ flex: 1, paddingInlineStart: 16 }}
                >
                  <h3
                    style={{
                      // padding: 0,
                      // margin: 0,
                      // fontSize: 20,
                      // fontWeight: 700,
                      color: colors.black,
                    }}
                  >
                    {'Shopify Store Integration'}
                  </h3>
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.greys04,
                    }}
                  >
                    {'Easily configure Shopify store to automate your Shopify messages'}
                  </p>
                </div>
              </div>
              {/* <p style={{
                marginLeft: 30,
                marginRight: 30,
                fontSize: 16,
                padding: 16,
                backgroundColor: colors.primary01,
                borderRadius: 10,
              }}>
                Note: As of May 2024 Shopify has changed its basic plan PII data access on api and moved it to Shopify plan for small teams. You must be on this plan to use Shopify integration
              </p> */}
              {success ? (
                <>
                  <h4
                    style={{
                      padding: 0,
                      margin: 0,
                      paddingInline: 30,
                      marginBlock: 16,
                      fontSize: 16,
                      fontWeight: 600,
                      color: colors.black,
                      width: '100%',
                    }}
                  >
                    {'Shop URL'}
                  </h4>
                  <div
                    className="d-flex flex-row justify-content-between align-items-center"
                    style={{ width: 'calc( 100% - 60px )' }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 16,
                        fontWeight: 400,
                        color: colors.black,
                      }}
                    >
                      {shopifyDetails?.shop_url}
                    </p>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <img
                        src={images.GreenCheck}
                        style={{ height: 24, width: 24, objectFit: 'contain' }}
                      ></img>
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          paddingInlineStart: 8,
                          fontSize: 16,
                          fontWeight: 600,
                          color: colors.success03,
                        }}
                      >
                        {'Store Integration Successful'}
                      </p>
                    </div>
                  </div>
                  <CheerioButton
                    borderStyle={{
                      paddingBlock: 8,
                      marginBlock: 30,
                      paddingInline: 32,
                      marginInlineEnd: 'auto',
                      marginInlineStart: 30,
                      border: `1px solid ${colors.black}`,
                    }}
                    textStyle={{ fontSize: 12, fontWeight: 600, color: colors.black }}
                    btnText={'Delink'}
                    backColor={colors.white02}
                    onclick={() => {
                      console.log('delink button clicked...');
                      eventsend("Delink_store__Shopify_integration_page_clicked");
                      if (localdata?.email === 'priam@cheerio.in')
                        shopifyUnlinkAPIcall()
                      else
                        shopifyRemoveAPIcall();
                    }}
                  />
                  {setSelectedMenuItem && (
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        paddingInline: 30,
                        marginBlockEnd: 16,
                        fontSize: 16,
                        fontWeight: 400,
                        color: colors.black,
                        width: '100%',
                      }}
                    >
                      {'Customers data synced successfully!'}
                      <span
                        style={{
                          color: colors.linkblue,
                          paddingInlineStart: 10,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSelectedMenuItem('Customers data');
                        }}
                      >
                        {'View data'}
                      </span>
                    </p>
                  )}
                </>
              ) : (
                <>
                  <Form className="w-75 me-auto" style={{ paddingInline: 30, paddingBlock: 16 }}>
                    <InputGroup className="" style={{ marginBottom: 16 }}>
                      <Form.Label style={{ fontSize: 16, fontWeight: 700, color: colors.black }}>
                        {'Store Name'}
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Copy Shopify Store Name"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={shopURL}
                        onChange={(e) => {
                          setShopURL(e.target.value);
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="" style={{ marginBottom: 16 }}>
                      <Form.Label style={{ fontSize: 16, fontWeight: 700, color: colors.black }}>
                        {'Access Token'}
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Copy token from Shopify Store"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={accessToken}
                        onChange={(e) => {
                          setAccessToken(e.target.value);
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="" style={{ marginBottom: 16 }}>
                      <Form.Label style={{ fontSize: 16, fontWeight: 700, color: colors.black }}>
                        {'API Secret Key'}
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Copy key from Shopify Store"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={apiKey}
                        onChange={(e) => {
                          setApiKey(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </Form>
                  <CheerioButton
                    borderStyle={{
                      paddingBlock: 10,
                      marginBlock: 30,
                      paddingInline: 40,
                      marginInlineEnd: 'auto',
                      marginInlineStart: 30,
                    }}
                    textStyle={{ fontSize: 12, fontWeight: 600, color: colors.white }}
                    btnText={'Submit'}
                    backColor={colors.black}
                    onclick={() => {
                      eventsend("Connect_store_Shopify_integration_page_clicked");
                      console.log('Submit button clicked...');
                      ShopifyIntegrationAPIcall();
                    }}
                    disabled={shopURL && accessToken && apiKey ? false : true}
                  />
                  {localdata?.email === 'priam@cheerio.in' &&
                    <>
                      <InputGroup className="" style={{ paddingInline: 30, paddingBlock: 16, display: "flex", flexDirection: "column" }}>
                        <Form.Label style={{ fontSize: 16, fontWeight: 700, color: colors.black }}>
                          {'Shop URL'}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Copy Shop URL from Shopify Store"
                          className="w-50 btncustom"
                          style={{ borderRadius: 8 }}
                          value={shopURL}
                          onChange={(e) => {
                            setShopURL(e.target.value);
                          }}
                        />
                      </InputGroup>
                      <CheerioButton
                        borderStyle={{
                          paddingBlock: 10,
                          marginBlock: 30,
                          paddingInline: 40,
                          marginInlineEnd: 'auto',
                          marginInlineStart: 30,
                        }}
                        textStyle={{ fontSize: 12, fontWeight: 600, color: colors.white }}
                        btnText={'Submit'}
                        backColor={colors.black}
                        onclick={async () => {
                          console.log('Submit button clicked...');
                          const res = await shopifyAppURL(authtoken, shopURL)
                          // localStorage.setItem('shopifyURL', shopURL)
                          console.log('res', res?.data)
                          if (res)
                            window.open(res?.data, '_self');
                        }}
                        disabled={!shopURL ? true : false}
                      />
                    </>}
                  {/* {updateVisible && <>
                    <InputGroup className="" style={{ paddingInline: 30, paddingBlock: 16 }}>
                      <Form.Label style={{ fontSize: 16, fontWeight: 700, color: colors.black }}>
                        {'Update Shopify Profile'}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Copy Shop URL from Shopify Store"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={updateShop}
                        onChange={(e) => {
                          setUpdateShop(e.target.value);
                        }}
                      />
                    </InputGroup>
                    <CheerioButton
                      borderStyle={{
                        paddingBlock: 10,
                        marginBlock: 30,
                        paddingInline: 40,
                        marginInlineEnd: 'auto',
                        marginInlineStart: 30,
                      }}
                      textStyle={{ fontSize: 12, fontWeight: 600, color: colors.white }}
                      btnText={'Update'}
                      backColor={colors.black}
                      onclick={() => {
                        console.log('Submit button clicked...');
                        updateShopifyAppURL(authtoken, updateShop).then((res) => {
                          if (res.flag) {
                            toast.success('Shop URL updated successfully');
                            setUpdateShop('');
                            getShopifyDetailsAPIcall();
                          } else {
                            toast.error(res.message);
                          }
                        });
                      }}
                      disabled={!updateShop ? true : false}
                    />
                  </>} */}


                </>
              )}

              <div
                className="d-flex flex-column justify-content-between align-items-start"
                style={{
                  width: 'calc( 100% - 60px )',
                  marginBlock: 16,
                  border: `1px solid ${colors.borderwhite}`,
                  borderRadius: 16,
                  paddingBlock: 20,
                  paddingInline: 20,
                }}
              >
                <h4
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 16,
                    fontWeight: 700,
                    color: colors.black,
                  }}
                >
                  {'Help Documentation'}
                </h4>
                <div
                  className="d-flex flex-row justify-content-start align-items-center w-100"
                  style={{ cursor: 'pointer', marginBlockStart: 16, marginBlockEnd: 8 }}
                  onClick={() => {
                    window.open(
                      // 'https://docs.google.com/document/d/1m_ng_gtPWdrtflQlWqUuZyC4kg4qtutc3iepQ12t0Js/edit?usp=sharing',
                      'https://docs.google.com/document/d/1Nxl_Znjo4AKLjpowcZ9HyYQyEPBteZg6Q3Y_jPbIueY/edit?usp=sharing',
                      '_blank',
                      'noopener,noreferrer'
                    );
                  }}
                >
                  <img
                    src={images.HelpIcon}
                    style={{ height: 24, width: 24, objectFit: 'contain' }}
                  ></img>
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      paddingInlineStart: 8,
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.linkblue,
                    }}
                  >
                    {'How to get consumer key & consumer secret'}
                  </p>
                </div>
                {/* <div className='d-flex flex-row justify-content-start align-items-center w-100' style={{cursor:'pointer',marginBlock:8}}>
                        <img src={images.HelpIcon} style={{height:24,width:24,objectFit:'contain'}}></img>
                        <p style={{padding:0,margin:0,paddingInlineStart:8,fontSize:16,fontWeight:400,color:colors.linkblue}}>
                            {'How to setup automated message templates for your Shopify store?'}
                        </p>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100' style={{cursor:'pointer',marginBlockStart:8}}>
                        <img src={images.HelpIcon} style={{height:24,width:24,objectFit:'contain'}}></img>
                        <p style={{padding:0,margin:0,paddingInlineStart:8,fontSize:16,fontWeight:400,color:colors.linkblue}}>
                            {'How to setup automated messages Flows for your Shopify store?'}
                        </p>
                    </div> */}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Setup;
