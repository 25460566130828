import React, { useEffect, useState } from 'react'
import colors from '../../../Utils/colors'
import { FaAngleDown } from "react-icons/fa6"
import { Autocomplete, TextField } from '@mui/material'
import { useSelector } from 'react-redux'

const NumberOptions = [
    '{{buyerNumber}}',
    '{{vendorContactNo}}',
    '{{receiverNumber}}'
]
const EmailOptions = [
    '{{buyerEmail}}',
    '{{vendorEmail}}',
    '{{receiverEmail}}'
]

const AdvancedOptions = ({ sendTo, setSendTo, type }) => {

    const localdata = useSelector((state) => state.main.localdata);
    const VendorListException = localdata?._id == '6489675b4f598e055ed74e34' || localdata?._id === "66f2929e5c505ea5fa000674";

    const [showOptions, setShowOptions] = useState(false);
    useEffect(()=>{
        if(!sendTo){
            setSendTo(type === 'Number' ? NumberOptions[0] : type === 'Email' && EmailOptions[0])
        }
    },[])

  return (
    <>
    {VendorListException && 
        <>
        <div className='d-flex flex-column justify-content-start align-items-center w-100 my-3'>
            <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                <p style={{fontSize:16,fontWeight:600,color:colors.black}}>
                    {'Advanced Options'}
                </p>
                <FaAngleDown size={18} style={{
                    cursor:'pointer',
                    transform: showOptions ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                }} onClick={()=>{
                    setShowOptions(!showOptions);
                }}/>
            </div>
            {showOptions && (
                <>
                <div className='d-flex flex-column justify-content-start align-items-center w-100 mt-2'>
                    <p style={{fontSize:14,fontWeight:600,color:colors.black,width:'100%'}}>
                        {'Send To'}
                    </p>
                    <Autocomplete
                        disablePortal
                        style={{
                            width:'100%',
                            marginBottom: '8px',
                        }}
                        size="small"
                        renderInput={(params) => (
                            <TextField
                                name="variable"
                                size={'small'}
                                placeholder={'Enter here'}
                                className={'restylingLabels'}
                                InputProps={{
                                    notched: false,
                                    style: {
                                        borderRadius: 4,
                                    },
                                }}
                                {...params}
                            />
                        )}
                        value={sendTo}
                        onInputChange={(e, newValue) => {
                            setSendTo(newValue);
                        }}
                        onChange={(e, newValue) => {
                            setSendTo(newValue);
                        }}
                        freeSolo={true}
                        options={type === 'Number' ? NumberOptions : type === 'Email' && EmailOptions}
                    />
                </div>
                </>
            )}
        </div>
        </>
    }
    </>
  )
}

export default AdvancedOptions