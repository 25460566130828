import React, { useEffect, useState } from 'react';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import { Card } from 'react-bootstrap';

export default function PremiumPlans() {
  const [planType, setPlanType] = useState('Monthly');
  useEffect(() => {}, []);
  const [selectedPlan, setSelectedPlan] = useState('Pro');

  const GroupBtn = ({ title, subTitle }) => {
    const status = title === planType;
    return (
      <button
        onClick={() => setPlanType(title)}
        type="button"
        style={{
          backgroundColor: status ? colors.themeBlue : colors.white,
          borderWidth: 0,
          height: 40,
          width: `${100 / 3}%`,
          //   borderRadius: title == "Quarterly" ? 0 : 4,
          borderLeftWidth: title === 'Quarterly' ? 1 : 0,
          borderRightWidth: title === 'Quarterly' ? 1 : 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <text
            style={{
              fontSize: 14,
              color: status ? colors.white : colors.black,
              fontWeight: 'bold',
            }}
          >
            {title}
          </text>
          <text
            style={{
              fontSize: 10,
              color: status ? colors.white : colors.themeBlue,
              fontWeight: 'lighter',
            }}
          >
            {subTitle}
          </text>
        </div>
      </button>
    );
  };

  const TextWithCheck = ({ txt, count, status }) => {
    return (
      <div
        style={{
          marginTop: 8,
          alignItems: 'flex-start',
          width: '70%',
          marginLeft: '10%',
        }}
      >
        <img
          src={status ? images.activeTick : images.disableTick}
          style={{ height: 8, width: 10, marginRight: 4 }}
        />
        <text
          style={{
            fontSize: 13,
            color: colors.greys04,
            fontWeight: 'normal',
            marginLeft: 5,
            marginRight: 5,
          }}
        >
          {txt}
        </text>
        {count ? (
          <text
            style={{
              fontSize: 14,
              color: colors.black,
              fontWeight: 'bold',
            }}
          >
            {count}
          </text>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <text style={styles.boldTxt}>Choose a plan that’s best for you</text>
      <div style={styles.gBtnContainer}>
        <GroupBtn title={'Monthly'} subTitle={''} />
        <GroupBtn title={'Quarterly'} subTitle={'EXTRA 10% OFF'} />
        <GroupBtn title={'Yearly'} subTitle={'EXTRA 20% OFF'} />
      </div>
      <div
        className="d-flex"
        style={{
          width: '96%',
          border: `1px solid ${colors.greys03}`,
          borderRadius: 5,
          marginTop: 40,
        }}
      >
        {/* FreeTrial */}
        <div
          onClick={() => setSelectedPlan('Free')}
          className={`flex-fill ${selectedPlan === 'Free' ? `border border-success` : ''}`}
          style={{ ...styles.body, borderWidth: 1, borderColor: colors.green }}
        >
          <text style={{ ...styles.boldTxt, marginTop: 10 }}>Free Trial</text>
          <text
            style={{
              ...styles.boldTxt,
              fontWeight: 'normal',
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            ₹0
          </text>
          <div
            style={{
              backgroundColor: colors.white04,
              padding: 16,
              paddingTop: 5,
              paddingBottom: 5,
              borderRadius: 2,
            }}
          >
            <text style={{ ...styles.txt }}>Additional setup charges</text>
          </div>

          <div
            style={{
              width: '90%',
              backgroundColor: colors.greys03,
              height: 1,
              marginTop: 12,
              marginBottom: 12,
            }}
          />
          <TextWithCheck status={true} txt={'Email Credits'} count={'(100)'} />
          <TextWithCheck status={true} txt={'SMS Credits'} count={'(100)'} />
          <TextWithCheck status={true} txt={'WhatsApp Credits'} count={'(100)'} />
          <TextWithCheck status={true} txt={'Support timeline'} count={'(5 days)'} />
          <TextWithCheck status={true} txt={'Devices login'} count={'(1)'} />
          <TextWithCheck status={true} txt={'Analytics'} count={'(Basic)'} />
          <TextWithCheck status={false} txt={'Payment Links'} />
          <TextWithCheck status={false} txt={'Custom WhatsApp business account integration'} />
          <TextWithCheck status={false} txt={'Custom Email integration'} />
          <TextWithCheck status={false} txt={'Download reports'} />
          <button
            type="button"
            className="btn btn-dark"
            disabled={selectedPlan !== 'Free'}
            style={{ width: '50%', marginTop: '10%', marginBottom: '5%' }}
          >
            Buy Now
          </button>
        </div>
        {/* Growth */}
        <div
          onClick={() => setSelectedPlan('Growth')}
          className={`flex-fill ${selectedPlan === 'Growth' ? `border border-success` : ''}`}
          style={styles.body}
        >
          <text style={{ ...styles.boldTxt, marginTop: 10 }}>Growth</text>
          <text
            style={{
              ...styles.boldTxt,
              fontWeight: 'normal',
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            ₹1200
          </text>
          <div
            style={{
              backgroundColor: colors.white04,
              padding: 16,
              paddingTop: 5,
              paddingBottom: 5,
              borderRadius: 2,
            }}
          >
            <text style={{ ...styles.txt }}>Additional setup charges</text>
          </div>
          <div
            style={{
              width: '90%',
              backgroundColor: colors.greys03,
              height: 1,
              marginTop: 12,
              marginBottom: 12,
            }}
          />
          <TextWithCheck status={true} txt={'Email Credits'} count={'(1500)'} />
          <TextWithCheck status={true} txt={'SMS Credits'} count={'(800)'} />
          <TextWithCheck status={true} txt={'WhatsApp Credits'} count={'(200)'} />
          <TextWithCheck status={true} txt={'Support timeline'} count={'(3 days)'} />
          <TextWithCheck status={true} txt={'Devices login'} count={'(Upto 2)'} />
          <TextWithCheck status={true} txt={'Analytics'} count={'(Basic)'} />
          <TextWithCheck status={true} txt={'Payment Links'} />
          <TextWithCheck status={true} txt={'Custom WhatsApp business account integration'} />
          <TextWithCheck status={false} txt={'Custom Email integration'} />
          <TextWithCheck status={false} txt={'Download reports'} />
          <button
            type="button"
            className="btn btn-dark"
            disabled={selectedPlan !== 'Growth'}
            style={{ width: '50%', marginTop: '10%', marginBottom: '5%' }}
          >
            Buy Now
          </button>
        </div>
        {/* Pro */}
        <div
          onClick={() => setSelectedPlan('Pro')}
          className={`flex-fill ${selectedPlan === 'Pro' ? `border border-success` : ''}`}
          style={styles.body}
        >
          <text style={{ ...styles.boldTxt, marginTop: 10 }}>Pro</text>
          <text
            style={{
              ...styles.boldTxt,
              fontWeight: 'normal',
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            ₹1800
          </text>
          <div
            style={{
              backgroundColor: colors.green,
              padding: 16,
              paddingTop: 5,
              paddingBottom: 5,
              borderRadius: 2,
            }}
          >
            <text style={{ ...styles.txt, color: colors.white }}>No setup charges</text>
          </div>
          <div
            style={{
              width: '90%',
              backgroundColor: colors.greys03,
              height: 1,
              marginTop: 12,
              marginBottom: 12,
            }}
          />
          <TextWithCheck status={true} txt={'Email Credits'} count={'(2500)'} />
          <TextWithCheck status={true} txt={'SMS Credits'} count={'(1250)'} />
          <TextWithCheck status={true} txt={'WhatsApp Credits'} count={'(300)'} />
          <TextWithCheck status={true} txt={'Support timeline'} count={'(1 day)'} />
          <TextWithCheck status={true} txt={'Devices login'} count={'(Upto 5)'} />
          <TextWithCheck status={true} txt={'Analytics'} count={'(Advanced)'} />
          <TextWithCheck status={true} txt={'Payment Links'} />
          <TextWithCheck status={true} txt={'Custom WhatsApp business account integration'} />
          <TextWithCheck status={true} txt={'Custom Email integration'} />
          <TextWithCheck status={false} txt={'Download reports'} />
          <button
            type="button"
            className={`btn btn-dark`}
            disabled={selectedPlan !== 'Pro'}
            style={{ width: '50%', marginTop: '10%', marginBottom: '5%' }}
          >
            Buy Now
          </button>
        </div>
        {/* Enterprise */}
        <div
          onClick={() => setSelectedPlan('Enterprise')}
          className={`flex-fill ${selectedPlan === 'Enterprise' ? `border border-success` : ''}`}
          style={styles.body}
        >
          <text style={{ ...styles.boldTxt, marginTop: 10 }}>Enterprise</text>
          <text
            style={{
              ...styles.boldTxt,
              fontWeight: 'normal',
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            ₹2400
          </text>
          <div
            style={{
              backgroundColor: colors.green,
              padding: 16,
              paddingTop: 5,
              paddingBottom: 5,
              borderRadius: 2,
            }}
          >
            <text style={{ ...styles.txt, color: colors.white }}>No setup charges</text>
          </div>
          <div
            style={{
              width: '90%',
              backgroundColor: colors.greys03,
              height: 1,
              marginTop: 12,
              marginBottom: 12,
            }}
          />
          <TextWithCheck status={true} txt={'Email Credits'} count={'(3500)'} />
          <TextWithCheck status={true} txt={'SMS Credits'} count={'(1600)'} />
          <TextWithCheck status={true} txt={'WhatsApp Credits'} count={'(500)'} />
          <TextWithCheck status={true} txt={'Support timeline'} count={'(4 hours)'} />
          <TextWithCheck status={true} txt={'Devices login'} count={'(Upto 10)'} />
          <TextWithCheck status={true} txt={'Analytics'} count={'(Advanced)'} />
          <TextWithCheck status={true} txt={'Payment Links'} />
          <TextWithCheck status={true} txt={'Custom WhatsApp business account integration'} />
          <TextWithCheck status={true} txt={'Custom Email integration'} />
          <TextWithCheck status={true} txt={'Download reports'} />

          <button
            type="button"
            className={`btn btn-dark`}
            disabled={selectedPlan !== 'Enterprise'}
            style={{ width: '50%', marginTop: '10%', marginBottom: '5%' }}
          >
            Buy Now
          </button>
        </div>
      </div>
      <Card style={{ padding: 20, marginTop: 30, width: '96%', marginBottom: 30 }}>
        <text style={{ ...styles.boldTxt, marginTop: 4, fontSize: 18 }}>NOTE</text>
        <div className="d-flex bd-highlight">
          <div className="p-2 w-100 bd-highlight">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <p style={{ margin: 0, marginTop: 12 }}>
                &#9642; Support will be available on working business days only.
              </p>
              <p style={{ margin: 0, marginTop: 12 }}>&#9642; We do not shy away from challenges</p>
              <p style={{ margin: 0, marginTop: 12 }}>&#9642; All pricing is exclusive of GST.</p>
            </div>
          </div>
          <div
            className="p-2 w-100 flex-shrink-1 bd-highlight"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <button
              type="button"
              className="btn btn-outline-dark"
              style={{
                marginLeft: 'auto',
                paddingLeft: 40,
                paddingRight: 40,
                fontSize: 15,
              }}
            >
              View top up plans
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}

const styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  boldTxt: {
    fontWeight: 'bold',
    fontSize: 22,
    color: colors.black,
    marginTop: 20,
  },
  gBtnContainer: {
    display: 'flex',
    width: '60%',
    alignItems: 'center',
    border: `1px solid ${colors.greys03}`,
    borderRadius: 5,
    overflow: 'hidden',
    marginTop: 30,
  },
  body: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  txt: {
    fontSize: 14,
    color: colors.greys01,
  },
};
