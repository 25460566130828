import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import images from '../../Utils/images';
import SMSDocs from './SMSDocs';
import WADocs from './WADocs';

const Documentation = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="container-fluid px-3 py-3"
        style={{ backgroundColor: '#F5F5F5', height: '100dvh', width: '100%', overflowY: 'scroll' }}
      >
        <div
          className="d-flex flex-column justify-content-between align-items-center w-100 h-100"
          style={{ marginBlockEnd: 30 }}
        >
          <img
            src={images.BackArrowBlack}
            style={{
              height: 24,
              width: 24,
              objectFit: 'contain',
              position: 'absolute',
              top: 30,
              left: 60,
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/creator');
            }}
          />
          <Routes>
            <Route path="whatsapp" element={<WADocs />} />
            <Route path="sms" element={<SMSDocs />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default Documentation;
