import React from 'react';
import SpinnerLoader from './SpinnerLoader';
import colors from '../Utils/colors';

const SpinnerDarkModal = ({ setOpenDarkSpinner }) => {
  return (
    <>
      <div
        style={{ ...Styles.DarkBg }}
        onClick={() => {
          setOpenDarkSpinner(false);
        }}
      >
        <div className="" style={{ ...Styles.Center }}>
          <SpinnerLoader color={colors.white} />
        </div>
      </div>
    </>
  );
};

export default SpinnerDarkModal;

const Styles = {
  DarkBg: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    width: '100vw',
    height: '100vh',
    // flex:1,
    zIndex: 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'fixed',
  },
  Center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
};
