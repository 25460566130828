import React, { useEffect, useState } from 'react'
import images from '../../../Utils/images'
import CheerioButton from '../../../Components/CheerioButton'
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import colors from '../../../Utils/colors';
import { PartnerModifyCredits } from '../../../Services';
import { toast } from 'react-toastify';

const UpdateBalanceModal = ({ setIsModalOpen, token, clientData, partnerData, closerefreshaction }) => {

    const [emailCredit, setEmailCredit] = useState('');
    const [walletBalance, setWalletBalance] = useState('');

    const updateBalanceAPICall = () => {
        // let token = token;
        let data = {
            userId: clientData?.user?._id,
            walletBalance: walletBalance,
            emailCredits: emailCredit,
        };
        PartnerModifyCredits(token, data).then((res) => {
            if (res?.flag) {
                toast.success('Balance successfully updated!', { position: 'top-right' })
                // console.log("success response ",res)
            } else {
                toast.error(res?.message ?? 'Unexpected error occurred, please try again later', { position: 'top-right' })
                // console.log("error response ==> ",res)
            }
            closerefreshaction()
        })
    }

    return (
        <>
            <div className='DarkBg' onClick={() => { setIsModalOpen(false) }}>
                <div className='Center'>
                    <div className='ANA_modal_container' onClick={(e) => { e.stopPropagation() }}>
                        <div className='ANA_modal_container__header'>
                            <h3>
                                {'Update Balance'}
                            </h3>
                            <img src={images.CMClose}
                                style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}
                                onClick={() => { setIsModalOpen(false) }}
                            ></img>
                        </div>
                        <div className='ANA_modal_container__body'>
                            <InputGroup className="mt-3">
                                <Form.Label className='form_heading_style'>
                                    {'Email Credits'}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    min={0}
                                    placeholder="Add to email balance"
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={emailCredit}
                                    onChange={(e) => {
                                        setEmailCredit(e.target.value);
                                    }}
                                />
                            </InputGroup>
                            <p>
                                {`Currently available: ${clientData?.emailCredits}`}
                            </p>
                            <InputGroup className="mt-3">
                                <Form.Label className='form_heading_style'>
                                    {'Wallet Balance'}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    min={0}
                                    placeholder="Add to Wallet balance"
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={walletBalance}
                                    onChange={(e) => {
                                        setWalletBalance(e.target.value);
                                    }}
                                />
                            </InputGroup>
                            <p>
                                {`Currently available: $${clientData?.walletBalance}`}
                            </p>
                            <InputGroup className="mt-4">
                                <Form.Label className='form_heading_style'>
                                    {'Centralized balance'}
                                </Form.Label>
                                {/* <Form.Control
                            type="text"
                            placeholder="Add count"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={walletBalance}
                            onChange={(e) => {
                                walletBalance(e.target.value);
                            }}
                        /> */}
                            </InputGroup>
                            <p>
                                {`Email credits: ${partnerData?.partnerUserDetails?.emailCredits}`}
                            </p>
                            <p>
                                {`Wallet balance: $${partnerData?.partnerUserDetails?.walletBalance}`}
                            </p>
                        </div>
                        <CheerioButton
                            borderStyle={{
                                borderColor: colors.black,
                                width: '100%',
                                paddingBlock: 10,
                            }}
                            textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white01 }}
                            btnText={'Update Balance'}
                            backColor={colors.black}
                            disabled={(((emailCredit && emailCredit >= 0) || !emailCredit) && ((walletBalance && walletBalance >= 0) || !walletBalance)) ? false : true}
                            onclick={() => {
                                console.log("add account clicked")
                                updateBalanceAPICall();
                            }}
                        />
                        <p style={{ fontSize: 14, fontWeight: 400, color: colors.greys04 }}>
                            {'(Will be deducted from your partner balance)'}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateBalanceModal