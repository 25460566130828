import React, { useEffect, useState } from 'react';
import images from '../../../Utils/images';
import Form from 'react-bootstrap/Form';
import colors from '../../../Utils/colors';
import InputGroup from 'react-bootstrap/InputGroup';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import CheerioButton from '../../../Components/CheerioButton';
import { Alert } from 'react-bootstrap';

const AddKeywords = ({ index = 0, SetOptions, Options, nextClickAction }) => {
  const [InputValue, setInputValue] = useState('');
  const [KeywordArr, setKeywordArr] = useState([]);
  const [disableInput, setDisableInput] = useState(false);
  const [criteriaExact, setCriteriaExact] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const disallowedKeywords = ['prev', 'back', 'exit', 'menu'];

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addKeyword(InputValue);
    }
  };

  const addKeyword = (keyword) => {
    const trimmedKeyword = keyword.trim();
    if (disallowedKeywords.includes(trimmedKeyword.toLowerCase())) {
      setAlertMessage(`The following keywords are not allowed: ${disallowedKeywords.join(', ')}`);
    } else {
      if (trimmedKeyword !== '' && !KeywordArr.includes(trimmedKeyword)) {
        const newKeywordArr = [...KeywordArr];
        newKeywordArr.push(trimmedKeyword);
        setKeywordArr(newKeywordArr);
        setInputValue('');
      }
    }
  };

  const setKeywordArrAction = () => {
    const tempOpt = [...Options];
    tempOpt.splice(index, 1);
    setKeywordArr(tempOpt[index]?.keywords);
  };

  useEffect(() => {
    if (alertMessage) {
      const timer = setTimeout(() => {
        setAlertMessage('');
      }, 2000); // 2 seconds
      return () => clearTimeout(timer);
    }
  }, [alertMessage]);

  useEffect(() => {
    if (Options[index]?.keywords?.length > 0) {
      setKeywordArr(Options[index].keywords);
    }
    if (Options[index]?.criteria === 'exact match') {
      setCriteriaExact(true);
    } else if (Options[index]?.criteria === 'approx. match') {
      setCriteriaExact(false);
    }
  }, []);

  useEffect(() => {
    if (KeywordArr?.length === 10) {
      setDisableInput(true);
    }
    if (KeywordArr) {
      SetOptions((options) => {
        const tempOptions = [...options];
        tempOptions[index] = {
          keywords: KeywordArr,
          criteria: criteriaExact ? 'exact match' : 'approx. match',
        };
        return tempOptions;
      });
    }
  }, [KeywordArr, criteriaExact]);

  return (
    <>
      <div className="addkeywords_container">
        <div className="addkeywords_container__header_row">
          <h4>{'Matching criteria'}</h4>
          <img className="image24" src={images.InfoCircleGrey}></img>
        </div>
        <div className="addkeywords_container__match_row">
          <Form.Check // prettier-ignore
            inline
            type={'radio'}
            id={`radio-exact`}
            label={`Exact Match`}
            style={{ marginInlineEnd: 16, fontSize: 14, fontWeight: 400, color: colors.black }}
            checked={criteriaExact}
            onChange={() => setCriteriaExact(true)}
          />
          <Form.Check // prettier-ignore
            inline
            type={'radio'}
            id={`radio-approx`}
            label={`Approx. Match`}
            style={{ marginInlineEnd: 16, fontSize: 14, fontWeight: 400, color: colors.black }}
            checked={!criteriaExact}
            onChange={() => setCriteriaExact(false)}
          />
        </div>
        <div
          className="d-flex flex-row justify-content-start align-items-center w-100 mb-2"
          style={{ flexWrap: 'wrap' }}
        >
          {KeywordArr &&
            KeywordArr.map((item) => {
              return (
                <>
                  <div
                    className="d-flex flex-row justify-content-center align-items-center px-3 py-1 my-1 me-2"
                    style={{ backgroundColor: colors.white03, borderRadius: 10 }}
                  >
                    <p className="me-2">{item}</p>
                    <IoIosCloseCircleOutline
                      onClick={() => {
                        setKeywordArr((prev) => {
                          const tempArr = [...prev];
                          const index = tempArr.indexOf(item);
                          tempArr.splice(index, 1);
                          return tempArr;
                        });
                      }}
                    />
                  </div>
                </>
              );
            })}
        </div>
        {alertMessage && <Alert variant="danger">{alertMessage}</Alert>}
        <InputGroup>
          <Form.Control
            as="textarea"
            aria-label="With textarea"
            value={InputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onKeyDown={handleKeyPress}
            disabled={disableInput}
            style={{ resize: 'none' }}
            rows={4}
          />
        </InputGroup>
        <div
          style={{
            color: 'gray',
            fontSize: '0.875rem',
            marginTop: '0.5rem',
            justifySelf: 'flex-start',
            marginInlineStart: 0,
            marginInlineEnd: 'auto',
          }}
        >
          Press Enter to submit
        </div>
        <CheerioButton
          borderStyle={{
            borderWidth: 0,
            justifySelf: 'flex-start',
            marginBlock: 24,
            marginInlineStart: 0,
            marginInlineEnd: 'auto',
          }}
          textStyle={{
            fontWeight: 600,
            color: colors.white01,
          }}
          btnText={'Next'}
          backColor={colors.primary03}
          onclick={async () => {
            if (InputValue.trim()) {
              await addKeyword(InputValue);
            }
            console.log('next clicked');
            nextClickAction();
          }}
        />
        {/* <div style={{justifySelf:'flex-start',marginBlock:24,}}></div> */}
      </div>
    </>
  );
};

export default AddKeywords;
