import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import colors from '../../Utils/colors';
import { BASE_URL } from '../../Config/axiosConfig';

const ShortURL = () => {
  const [Code6, setCode6] = useState(false);
  const [loading, setLoading] = useState(false);
  const code = useParams();
  const [arr, setArr] = useState([]);

  useEffect(() => {
    setLoading(true);
    annexistsfunction();
  }, []);

  const annexistsfunction = () => {
    const andexists = code.code.includes('&id=');
    if (andexists) {
      const arraysplit = code.code.split('&id=');
      setArr(arraysplit);
    }else{
      setCode6(true);
    }
  };

  useEffect(() => {
    if(arr[1]){
      shorturlapicallID();
    }else if(Code6){
      shorturlapicall()
    }
  }, [Code6,arr]);

  const shorturlapicall = () => {
    setLoading(false);
    window.open(`${BASE_URL}`+`api/v2/analytics/openLink?code=`+`${code.code}`, '_self');
  };
  
  const shorturlapicallID = () => {
    setLoading(false);
    window.open(`${BASE_URL}`+`api/v2/analytics/openLink?code=`+`${arr[0]}`+`&id=`+`${arr[1]}`, '_self');
  };

  return (
    <>
      <div
        className="container-fluid d-flex flex-column justify-content-center align-items-center"
        style={{ height: '100vh', width: '100%' }}
      >
        {loading && <Spinner animation="border" style={{ color: colors.primary03 }} />}
      </div>
    </>
  );
};

export default ShortURL;
