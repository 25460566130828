import React from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { getImagePath } from '../../../Utils';

const WAPreview = ({ FormData, upimage, templateType }) => {
  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center w-100"
        style={{ flex: 1 }}
      >
        <div
          className="d-flex flex-column justify-content-start align-items-center"
          style={{
            width: '70%',
            borderRadius: 10,
            padding: 16,
            backgroundColor: '#1F2C34',
            position: 'relative',
          }}
        >
          <img
            src={images.CreatorWA}
            style={{
              height: 32,
              width: 32,
              objectFit: 'contain',
              position: 'absolute',
              top: -14,
              left: -14,
            }}
          ></img>
          <div
            className=""
            style={{
              minHeight: '100px',
              width: '100%',
              backgroundColor: colors.borderwhite,
              borderRadius: 8,
            }}
          >
            {upimage && (
              <img
                src={getImagePath(upimage)}
                style={{ height: '100%', width: '100%', borderRadius: 8, objectFit: 'cover' }}
              />
            )}
          </div>
          {templateType === 'renewal_remainder' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'Hello '}
                {FormData[0] ? (
                  `${FormData[0]},`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"customer name",`}</span>
                )}
                <br />
                <br />
                {'We would like to inform you that '}
                {FormData[1] ? (
                  `${FormData[1]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"reminder description."`}</span>
                )}
                {' Please contact us at '}
                {FormData[2] ? (
                  FormData[2]
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"callback number"`}</span>
                )}
                {' for more information/questions.'}
                <br />
                <br />
                {FormData[3] ? (
                  FormData[3]
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"company name"`}</span>
                )}
              </p>
            </>
          )}
          {templateType === 'maintainance_update' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'Hello '}
                {FormData[0] ? (
                  `${FormData[0]},`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"customer name",`}</span>
                )}
                <br />
                <br />
                {'We are '}
                {FormData[1] ? (
                  `${FormData[1]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Maintenance message"`}</span>
                )}
                <br />
                <br />
                {'For more information feel free to reach to us.'}
                <br />
                <br />
                {'Thanks'}
                <br />
                {FormData[2] ? (
                  FormData[2]
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Business name"`}</span>
                )}
              </p>
            </>
          )}
          {templateType === 'new_product_service_marathi' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'नमस्ते '}
                {FormData[0] ? (
                  `${FormData[0]},`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"customer name",`}</span>
                )}
                <br />
                <br />
                {'आम्ही '}
                {FormData[1] ? (
                  `${FormData[1]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Message"`}</span>
                )}
                <br />
                <br />
                {
                  'अधिक माहिती साठी आपण खाली दिलेल्या क्रमांकावर संपर्क करू शकतात अथवा या मेसेजला रिप्लाय देऊन माहिती मिळवू शकतात.'
                }
                <br />
                <br />
                {'धन्यवाद'}
                <br />
              </p>
            </>
          )}
          {templateType === 'do_you_know' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'Hi '}
                {FormData[0] ? (
                  `${FormData[0]},`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"customer name",`}</span>
                )}
                <br />
                <br />
                {'Do you know that '}
                {FormData[1] ? (
                  `${FormData[1]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Message"`}</span>
                )}
                <br />
                <br />
                {FormData[2] ? (
                  `${FormData[2]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Feature 1"`}</span>
                )}
                <br />
                {FormData[3] ? (
                  `${FormData[3]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Feature 2"`}</span>
                )}
                <br />
                {FormData[4] ? (
                  `${FormData[4]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Feature 3"`}</span>
                )}
                <br />
                {FormData[5] ? (
                  `${FormData[5]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Feature 4"`}</span>
                )}
                <br />
                <br />
                {'You can '}
                {FormData[6] ? (
                  `${FormData[6]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Info message"`}</span>
                )}
                <br />
                <br />
                {'You can also call us at '}
                {FormData[7] ? (
                  `${FormData[7]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Contact details"`}</span>
                )}
                {' for any further information'}
                <br />
              </p>
            </>
          )}
          {templateType === 'discount_all_products_new' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'Hi '}
                {FormData[0] ? (
                  `${FormData[0]} 😊,`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"customer name" 😊,`}</span>
                )}
                <br />
                <br />
                {'Thankyou for recently buying '}
                {FormData[1] ? (
                  `${FormData[1]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Business name"`}</span>
                )}
                {' Products.😍'}
                <br />
                <br />
                {`To show our appreciation🥳, we've got a special offer for you FLAT `}
                {FormData[2] ? (
                  `${FormData[2]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Discount"`}</span>
                )}
                {' discount on all products.'}
                <br />
                <br />
                {`Discount Code : `}
                {FormData[3] ? (
                  `${FormData[3]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Discount code"`}</span>
                )}
                <br />
                <br />
                {'Shop Now.'}
                <br />
              </p>
            </>
          )}
          {templateType === 'loyal_customers_new' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'Hey '}
                {FormData[0] ? (
                  `${FormData[0]} 🤙,`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"customer name" 🤙,`}</span>
                )}
                <br />
                <br />
                {'With more than '}
                {FormData[1] ? (
                  `${FormData[1]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Quantity info"`}</span>
                )}
                {' units sold 🥳, our product '}
                {FormData[2] ? (
                  `${FormData[2]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Product name"`}</span>
                )}
                {' is one of our best-seller🥇 and an ABSOLUTE favorite among our customers!'}
                <br />
                <br />
                {`And that's why we want to give a chance to everyone to experience it. This month, we are giving `}
                {FormData[3] ? (
                  `${FormData[3]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Discount (in %)"`}</span>
                )}
                {`% off on "product name".`}
                <br />
                {`Apply the code `}
                {FormData[4] ? (
                  `${FormData[4]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Discount code"`}</span>
                )}
                {' on checkout.'}
                <br />
                <br />
                {'✅ No minimum order required.'}
                <br />
                {'✅ Cash on delivery available.'}
                <br />
                <br />
                {'Call us at '}
                {FormData[5] ? (
                  `${FormData[5]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Contact details"`}</span>
                )}
                <br />
              </p>
            </>
          )}
          {templateType === 'welcome_insta_message_2' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'Hello '}
                {FormData[0] ? (
                  `${FormData[0]},`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"customer name",`}</span>
                )}
                <br />
                <br />
                {'Welcome to '}
                {FormData[1] ? (
                  `${FormData[1]}. `
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Business name". `}</span>
                )}
                {'We are glad you chose us for '}
                {FormData[2] ? (
                  `${FormData[2]}. `
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Product name".`}</span>
                )}
                <br />
                <br />
                {`Let's connect on Instagram for some fun`}
                <br />
                {FormData[3] ? (
                  `${FormData[3]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Instagram handle"`}</span>
                )}
                <br />
                <br />
                {'If you need anything, write to us at '}
                {FormData[4] ? (
                  `${FormData[4]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Support Email"`}</span>
                )}
                <br />
                <br />
                {`Here's your `}
                {FormData[5] ? (
                  `${FormData[5]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Discount"`}</span>
                )}
                {' discount code to shop any item on the website --'}
                <br />
                {FormData[6] ? (
                  `${FormData[6]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Link"`}</span>
                )}
                <br />
                <br />
                {'⚡️ No minimum purchase is required.'}
                <br />
                {'⚡️ This discount code is valid for the next '}
                {FormData[7] ? (
                  `${FormData[7]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Duration"`}</span>
                )}
                {' months.'}
                <br />
                <br />
                {'Call us at '}
                {FormData[8] ? (
                  `${FormData[8]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Contact details"`}</span>
                )}
                <br />
              </p>
            </>
          )}
          {templateType === 'business_info_newsletter' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'Hi '}
                {FormData[0] ? (
                  `${FormData[0]},`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"customer name",`}</span>
                )}
                <br />
                <br />
                {'Want to switch to '}
                {FormData[1] ? (
                  `${FormData[1]}? `
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Product name"?`}</span>
                )}
                {'So what is stopping you?'}
                <br />
                <br />
                {FormData[2] ? (
                  `${FormData[2]}. `
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Not working feature 1"`}</span>
                )}
                <br />
                {FormData[3] ? (
                  `${FormData[3]}. `
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Not working feature 2"`}</span>
                )}
                <br />
                {FormData[4] ? (
                  `${FormData[4]}. `
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Not working feature 3"`}</span>
                )}
                <br />
                <br />
                {`Welcome to `}
                {FormData[5] ? (
                  `${FormData[5]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Business name"`}</span>
                )}
                {' where we have a solution to all your obstacles:'}
                <br />
                <br />
                {FormData[6] ? (
                  `${FormData[6]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Feature 1"`}</span>
                )}
                <br />
                {FormData[7] ? (
                  `${FormData[7]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Feature 2"`}</span>
                )}
                <br />
                {FormData[8] ? (
                  `${FormData[8]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Feature 3"`}</span>
                )}
                <br />
                <br />
                {`You can place your order online on `}
                {FormData[9] ? (
                  `${FormData[9]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Link"`}</span>
                )}
                <br />
                <br />
                {'To know more, you can call us on '}
                {FormData[10] ? (
                  `${FormData[10]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Contact detail"`}</span>
                )}
                <br />
              </p>
            </>
          )}
          {templateType === 'repeatcustomer_offer' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'Hi '}
                {FormData[0] ? (
                  `${FormData[0]},`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"customer name",`}</span>
                )}
                <br />
                <br />
                {'Welcome to the '}
                {FormData[1] ? (
                  `${FormData[1]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Business name".`}</span>
                )}
                {' Thank you for '}
                {FormData[2] ? (
                  `${FormData[2]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Thanking action".`}</span>
                )}
                {` It's time to fill those carts again with `}
                {FormData[3] ? (
                  `${FormData[3]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Feature".`}</span>
                )}
                <br />
                <br />
                {'On the Special occasion of '}
                {FormData[4] ? (
                  `${FormData[4]}, `
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Occasion", `}</span>
                )}
                {'We are offering you a special discount of '}
                {FormData[5] ? (
                  `${FormData[5]}. `
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Promotion text".`}</span>
                )}
                <br />
                <br />
                {'You can place your orders online on '}
                {FormData[6] ? (
                  `${FormData[6]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Link"`}</span>
                )}
                <br />
                <br />
                {'Stock up on your essential '}
                {FormData[7] ? (
                  `${FormData[7]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Feature 2".`}</span>
                )}
                {' Call us at '}
                {FormData[8] ? (
                  `${FormData[8]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Contact detail".`}</span>
                )}
                <br />
              </p>
            </>
          )}
          {templateType === 'customer_win_back' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'Congratulations '}
                {FormData[0] ? (
                  `${FormData[0]},`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"customer name",`}</span>
                )}
                <br />
                <br />
                {'We have a '}
                {FormData[1] ? (
                  `${FormData[1]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Promotion text"`}</span>
                )}
                {' in store for you!'}
                <br />
                <br />
                {'We have been missing you on '}
                {FormData[2] ? (
                  `${FormData[2]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Business name".`}</span>
                )}
                <br />
                <br />
                {`Why don't you check out the `}
                {FormData[3] ? (
                  `${FormData[3]}!`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Feature"!`}</span>
                )}
                <br />
                <br />
                {`In fact, here's a present for you, use the code `}
                {FormData[4] ? (
                  `${FormData[4]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Discount Code".`}</span>
                )}
                <br />
                <br />
                {`We will be waiting for you!`}
                <br />
                <br />
                {`Hurry now, limited time offer!`}
                <br />
              </p>
            </>
          )}
          {templateType === 'additional_discount_offer' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'Dearest '}
                {FormData[0] ? (
                  `${FormData[0]},`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Greeting",`}</span>
                )}
                <br />
                <br />
                {'These are Special times and they call for Special needs.'}
                <br />
                <br />
                {'At '}
                {FormData[1] ? (
                  `${FormData[1]},`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Business name",`}</span>
                )}
                {' we would like you to pamper yourself this '}
                {FormData[2] ? (
                  `${FormData[2]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Occasion".`}</span>
                )}
                <br />
                <br />
                {'Shop now and get additional '}
                {FormData[3] ? (
                  `${FormData[3]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Promotion text".`}</span>
                )}
                <br />
                <br />
                {' You can be rest assured that '}
                {FormData[4] ? (
                  `${FormData[4]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Feature".`}</span>
                )}
                <br />
                <br />
              </p>
            </>
          )}
          {templateType === 'share_info' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'Hey '}
                {FormData[0] ? (
                  `${FormData[0]},`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"customer name",`}</span>
                )}
                <br />
                <br />
                {'Wishing you a very '}
                {FormData[1] ? (
                  `${FormData[1]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Greeting"`}</span>
                )}
                <br />
                <br />
                {`Here's a `}
                {FormData[2] ? (
                  `${FormData[2]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Type of message"`}</span>
                )}
                {' of the day we wanted to share with you.'}
                <br />
                <br />
                {FormData[3] ? (
                  `${FormData[3]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Add message"`}</span>
                )}
                <br />
                <br />
                {`In your journey to be an avid `}
                {FormData[4] ? (
                  `${FormData[4]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Supporting message"`}</span>
                )}
                {' we are always there for you.'}
                <br />
                <br />
                {`If you are interested in knowing more about how we can help, click the link below.`}
                <br />
              </p>
            </>
          )}
          {templateType === 'thank_you_customer' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'Hello '}
                {FormData[0] ? (
                  `${FormData[0]},`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"customer name",`}</span>
                )}
                <br />
                <br />
                {'Thank you for '}
                {FormData[1] ? (
                  `${FormData[1]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Action"`}</span>
                )}
                <br />
                <br />
                {'Please find below '}
                {FormData[2] ? (
                  `${FormData[2]} -`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Supporting text" -`}</span>
                )}
                <br />
                {FormData[3] ? (
                  `${FormData[3]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Link"`}</span>
                )}
                <br />
                <br />
                {'Below are some of the key features:'}
                <br />
                <br />
                {FormData[4] ? (
                  `${FormData[4]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Feature 1"`}</span>
                )}
                <br />
                {FormData[5] ? (
                  `${FormData[5]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Feature 2"`}</span>
                )}
                <br />
                {FormData[6] ? (
                  `${FormData[6]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Feature 3"`}</span>
                )}
                <br />
                {FormData[7] ? (
                  `${FormData[7]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Feature 4"`}</span>
                )}
                <br />
                <br />
                {'If you have any questions, please feel free to react out to us.'}
                <br />
                <br />
                {'Best Regards'}
                <br />
                {FormData[8] ? (
                  `${FormData[8]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Sender name"`}</span>
                )}
                <br />
                {FormData[9] ? (
                  `${FormData[9]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Company name"`}</span>
                )}
                <br />
              </p>
            </>
          )}
          {templateType === 'customer_enquiry' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'Hello '}
                {FormData[0] ? (
                  `${FormData[0]},`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"customer name",`}</span>
                )}
                <br />
                <br />
                {'I am '}
                {FormData[1] ? (
                  `${FormData[1]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Seller name"`}</span>
                )}
                {' from '}
                {FormData[2] ? (
                  `${FormData[2]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Company name".`}</span>
                )}
                {
                  ' You had enquired about our services in the recent past. I just wanted to touch base with you once again to check if you are still interested in availing any of our packages. We are running a '
                }
                {FormData[3] ? (
                  `${FormData[3]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Discount (in %)"`}</span>
                )}
                {' promotional offer till '}
                {FormData[4] ? (
                  `${FormData[4]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Offer valid till".`}</span>
                )}
                <br />
                <br />
                {'Just reply to this message and I will help you out at the earliest.'}
                <br />
                <br />
                {'Regards,'}
                <br />
                {FormData[2] ? (
                  `${FormData[2]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Company name"`}</span>
                )}
                <br />
              </p>
            </>
          )}
          {templateType === 'new_course_media_button' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'Hey '}
                {FormData[0] ? (
                  `${FormData[0]},`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"customer name",`}</span>
                )}
                <br />
                <br />
                {FormData[1] ? (
                  `${FormData[1]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Seller name"`}</span>
                )}
                {' has announced a new course on '}
                {FormData[2] ? (
                  `${FormData[2]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Course name".`}</span>
                )}
                <br />
                <br />
                {'Register now'}
                <br />
              </p>
            </>
          )}
          {templateType === 'new_product_media_button' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'Hey '}
                {FormData[0] ? (
                  `${FormData[0]},`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"customer name",`}</span>
                )}
                <br />
                <br />
                {FormData[1] ? (
                  `${FormData[1]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Seller name"`}</span>
                )}
                {' has launched a new product '}
                {FormData[2] ? (
                  `${FormData[2]}.`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Product name".`}</span>
                )}
                <br />
                <br />
                {'Buy now'}
                <br />
              </p>
            </>
          )}
          {templateType === 'share_an_offer_media_button' && (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.white,
                  width: '100%',
                }}
              >
                {'Hey '}
                {FormData[0] ? (
                  `${FormData[0]},`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"customer name",`}</span>
                )}
                <br />
                <br />
                {FormData[1] ? (
                  `${FormData[1]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Seller name"`}</span>
                )}
                {' has an exclusive offer for you on '}
                {FormData[2] ? (
                  `${FormData[2]}. `
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Product name". `}</span>
                )}
                {FormData[3] ? (
                  `${FormData[3]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"Discount (in %)"`}</span>
                )}
                {'% OFF for the next '}
                {FormData[4] ? (
                  `${FormData[4]}`
                ) : (
                  <span style={{ fontWeight: 700 }}>{`"No. of days offer available"`}</span>
                )}
                {' days is available for you.'}
                <br />
                <br />
                {'Claim now'}
                <br />
              </p>
            </>
          )}
        </div>
        <div
          className="d-flex flex-column justify-content-start align-items-center"
          style={{
            width: '70%',
            borderRadius: 10,
            paddingBlock: 6,
            marginBlock: 8,
            backgroundColor: '#1F2C34',
          }}
        >
          <p style={{ padding: 0, margin: 0, fontSize: 14, fontWeight: 600, color: colors.white }}>
            {'Visit Website'}
          </p>
        </div>
      </div>
    </>
  );
};

export default WAPreview;
