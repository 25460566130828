import React, { useEffect, useState } from 'react';
import '../styles.css';
import images from '../../../Utils/images';
import {
  fetchAllWorkflows,
  getUserApi,
  manualWorkflowTrigger,
  toggleWorkflowService,
} from '../../../Services';
import { useDispatch, useSelector } from 'react-redux';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { toast } from 'react-toastify';
import { eventsend } from '../../../Config/analyticsFunctions';

export const TriggerWorkflow = ({ setShowTriggerWorkflow, chatData, resumeWorkflow, positionStyling }) => {
  const [workFlowList, setWorkflowList] = useState([]);
  const authtoken = useSelector((state) => state.main.auth_token);
  const workflowStatus = useSelector(
    (state) => state.main?.localdata?.whatsapp_credentials?.workflow
  );
  const [isCheck, setIsCheck] = useState(workflowStatus);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   getuserapicall();
  // }, []);

  useEffect(() => {
    if (workflowStatus) {
      getWorkflowListAPICall();
    }
  }, [workflowStatus]);

  const getuserapicall = () => {
    setIsLoading(true);
    let token = authtoken;
    getUserApi(token).then((res) => {
      console.log('get user api response ==> ', res);
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
        setIsLoading(false);
      } else {
        console.log('error get user api ===> ', res);
        setIsLoading(false);
      }
    });
  };

  const toggleWorkflowStatus = () => {
    setIsLoading(true);
    let token = authtoken;
    toggleWorkflowService(token).then((res) => {
      if (res.data) {
        console.log('res.data.workflow', res?.data?.workflow);
        setIsCheck(res?.data?.workflow);
        setIsLoading(false);
        getuserapicall();
        console.log('success response ===> ', res);
        toast.success(`Succesfully ${res?.data?.workflow ? 'Enabled' : 'Disabled'} Workflow`, {
          position: 'top-center',

          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        console.log('error response ===> ', res);
        setIsLoading(false);

        toast(`Error occured while saving settings. Please try again later`, {
          position: 'top-center',
        });
      }
    });
  };

  const getWorkflowListAPICall = () => {
    setIsLoading(true);

    fetchAllWorkflows(authtoken).then((res) => {
      const arr = res?.data;
      const filteredArray = arr.filter(
        (obj) => obj.nodes.length > 0 && obj.nodes[0].type === 'incomingWA'
      );
      setWorkflowList(filteredArray);
      setIsLoading(false);
    });
  };

  const manualTriggerWorkflowApiCall = (id) => {
    // resumeWorkflow();
    const data = {
      phoneNumber: chatData?.user_details?.phone,
      workflowId: id?.toString(),
      userName: chatData?.user_details?.name,
      convId: chatData?._id,
    };
    setShowTriggerWorkflow(false);
    manualWorkflowTrigger(data, authtoken).then((res) => {
      toast.loading('Triggering flow', { position: 'top-center', toastId: 1 });
      if (res?.flag) {
        toast.dismiss(1);
        toast.success('Workflow Triggered!', { position: 'top-center' });
      } else {
        toast.dismiss(1);
        toast.error(res?.message, { position: 'top-center' });
      }
    });
  };

  console.log('workflow list', workFlowList);

  return (
    <ClickAwayListener onClickAway={() => setShowTriggerWorkflow(false)}>
      <div className="triggerFlows" style={{ ...positionStyling }}>
        <div
          className="d-flex justify-content-between"
          style={{ alignItems: 'center', paddingInline: '10px', paddingBlock: '10px' }}
        >
          <p style={{ fontSize: '16px', fontWeight: '700' }}>Trigger Chatbot</p>
          <img
            src={images.CMClose}
            onClick={() => {
              eventsend("Trigger_chatbot_Inbox_page_clicked");
              setShowTriggerWorkflow(false);
            }}
            style={{
              cursor: 'pointer',
              height: 18,
              width: 18,
              marginInlineEnd: 8,
            }}
          ></img>
        </div>
        <hr style={{ marginBlock: '0px' }}></hr>
        {isLoading ? (
          <div
            className="d-flex align-items-center flex-column"
            style={{ width: '100%', paddingTop: '35%' }}
          >
            Loading...
          </div>
        ) : isCheck ? (
          <div
            style={{
              overflowY: 'auto',
              scrollbarWidth: 'thin',
              height: 'calc(100% - 56px)',
            }}
          >
            {workFlowList.length > 0 ? (
              workFlowList.map((item) => {
                return (
                  <div
                    key={item?._id}
                    className="workflow-item"
                    onClick={() => {
                      manualTriggerWorkflowApiCall(item?._id);
                    }}
                  >
                    {item?.name}
                  </div>
                );
              })
            ) : (
              <div
                className="d-flex align-items-center flex-column"
                style={{ width: '100%', paddingTop: '35%', color: 'grey' }}
              >
                Sorry, No workflow available!
              </div>
            )}
          </div>
        ) : (
          <div
            className="d-flex align-items-start flex-column"
            style={{
              paddingTop: '25%',
              color: 'grey',
              paddingInline: '20px',
              fontSize: '16px',
            }}
          >
            <div className="form-check form-switch" style={{}}>
              <input
                className="form-check-input shadow-none"
                type="checkbox"
                role="switch"
                checked={isCheck}
                id="flexSwitchCheckDefault"
                onChange={(e) =>
                  e.target.checked ? toggleWorkflowStatus() : toggleWorkflowStatus()
                }
              />
            </div>
            <p style={{ width: '80%' }}>
              Your workflow toggle is off currently. Please turn ON to trigger.
            </p>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
