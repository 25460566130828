import React from 'react'
import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';
import SendWATemplateModal from '../../WorkFlow/Modals/SendWATemplateModal';

const RASendWAModal = ({
    setIsOpenTemplateModal,
    editedData,
    setEditedData,
    setBtnDisabled,
    btnDisabled,
    kaID,
    onSubmitAction,
}) => {
  return (
    <>
    <div
        className="DarkBg"
        style={{}}
        onClick={(e) => {
          setIsOpenTemplateModal(false);
          e.stopPropagation();
        }}
      >
        <div className="Center">
          <div
            className="d-flex flex-column justify-content-between align-items-center"
            style={{
              width:800,
              maxHeight: '80vh',
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 0,
              border: `1px solid ${colors.borderwhite}`,
              paddingBlock: 16,
              paddingInline: 20,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {/* Header */}
            <div
              className="d-flex flex-row justify-content-between align-items-center w-100"
              style={{ paddingBlockEnd: 8, borderBottom: `1px solid ${colors.borderwhite}` }}
            >
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 20,
                  fontWeight: 700,
                  color: colors.black,
                }}
              >
                {'Send WA Template'}
              </p>
            </div>
            {/* ------------------- */}

            {/* Body */}
            <div
              className="w-100 verythinscrollnew"
              style={{ flex: 1, overflowY: 'scroll', paddingInline: 8 }}
            >
                <SendWATemplateModal
                  data={editedData}
                  setData={setEditedData}
                  btnDisabled={btnDisabled}
                  setBtnDisabled={setBtnDisabled}
                  keywordsAction={true}
                  kaID={kaID}
                />
            </div>
            {/* ------------------- */}

            {/* Footer */}
            <div
              className="d-flex flex-row justify-content-evenly align-items-center w-100"
              style={{ paddingBlockStart: '10px' }}
            >
              <CheerioButton
                borderStyle={{ width: '45%' }}
                textStyle={{
                  fontSize: 16,
                  padding: 0,
                  margin: 0,
                  fontWeight: 600,
                  color: colors.white,
                }}
                btnText={'Save'}
                backColor={colors.black}
                disabled={btnDisabled}
                onclick={() => {           
                //   onSubmit(editedData);
                    onSubmitAction();
                }}
              />
            </div>

            {/* ------------------- */}
          </div>
        </div>
      </div>
    </>
  )
}

export default RASendWAModal