import React from 'react';
import styles from './../styles.module.css';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';

const DeploymentBanner = () => {
  const getTime = () => {
    const time = new Date();
    const TimeAMPM = time.toLocaleString('en-US', { hour: 'numeric', hour12: true });
    return TimeAMPM;
  };
  const getEndTime = () => {
    const endTime = new Date(Date.now() + 1 * (60 * 60 * 1000));
    const endTimeAMPM = endTime.toLocaleString('en-US', { hour: 'numeric', hour12: true });
    return endTimeAMPM;
  };

  return (
    <>
      <div
        className={`${styles['Row']} ${styles['BannerContainer']}`}
        style={{ backgroundColor: colors.yellow03, width: 'calc( 100% - 32px )' }}
      >
        <img src={images.InfoCircle} style={{ height: 24, width: 24, objectFit: 'contain' }}></img>
        {/* <p
          style={{
            flex: 1,
            margin: 0,
            paddingBlock: 0,
            fontSize: 16,
            fontWeight: 400,
            color: colors.black,
            paddingInline: 16,
            textAlign: 'justify',
          }}
        >
          {`Meta is experiencing major disruptions in WhatsApp Business API since 4th Aug 7 am IST. You may observe lower deliverability and issues in live chat until this is fixed. We will update you as soon Meta team resolves the issue. Till then avoid sending campaigns.`}
        </p> */}

         <p style={{flex:1,margin:0,paddingBlock:0,fontSize:16,fontWeight:400,color:colors.black,paddingInline:16,textAlign:'justify'}}>
            {`We have planned for a deployment between ${getTime()} to ${getEndTime()} today. Please refrain from sending campaigns during this time to avoid failures. Inconvenience is regretted.`}
        </p>
      </div>
    </>
  );
};

export default DeploymentBanner;
