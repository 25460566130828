import colors from '../../../Utils/colors';
import { Position } from 'reactflow';
import { Form, InputGroup } from 'react-bootstrap';
import CustomHandle from './CustomHandle';
import images from '../../../Utils/images';

const handleStyle = { backgroundColor: '#1CCFEE', height: 10, width: 10, borderRadius: 5 };

const LlamaNode = ({ data, isConnectable }) => {
    return (
        <>
            <div className='d-flex flex-column justify-content-between align-items-center' style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 16, backgroundColor: colors.white01, width: 230 }}>
                <CustomHandle
                    type="target"
                    position={Position.Left}
                    isConnectable={isConnectable}
                    style={handleStyle}
                />
                <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{ paddingInline: 16, paddingBlock: 8, backgroundColor: '#1CCFEE', borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
                    <div style={{ height: 4, width: '20%', borderRadius: 2, backgroundColor: colors.white, marginBlockEnd: 4 }}></div>
                    <div className='w-100 d-flex flex-row justify-content-between align-items-center' style={{}}>
                        <p style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.white }}>
                            {'Assign Llama'}
                        </p>
                        <img src={images.Edit} style={{ height: 20, width: 20, objectFit: 'contain' }}></img>
                    </div>
                </div>
                <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{ paddingInline: 16, paddingBlockStart: 8, paddingBlockEnd: 16, backgroundColor: colors.white01, borderBottomLeftRadius: 16, borderBottomRightRadius: 16 }}>
                    <p style={{ padding: 0, margin: 0, marginBlock: 4, fontSize: 12, fontWeight: 400, color: colors.black, width: '100%' }}>
                        {'Chat will be assigned to Llama'}
                    </p>
                </div>
                <div
                    className="w-100 d-flex flex-column justify-content-start align-items-center"
                    style={{ paddingInline: 16, paddingBlockStart: 8, paddingBlockEnd: 16 }}
                >
                    <p
                        style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 4,
                            fontSize: 10,
                            fontWeight: 600,
                            color: colors.black,
                            width: '100%',
                        }}
                    >
                        {'Assistant'}
                    </p>
                    <InputGroup className="my-1">
                        <Form.Control
                            type="text"
                            placeholder={'Select Assistant'}
                            className="w-100 btncustom"
                            style={{ borderRadius: 8, fontSize: 10, fontWeight: 400, color: colors.black }}
                            maxLength={640}
                            value={data?.assistant?.name ? data?.assistant?.name : ''}
                        />
                    </InputGroup>
                </div>
                <div className="d-flex flex-column justify-content-start align-items-center w-100 py-2 px-2">
                    <div
                        className="d-flex flex-row justify-content-start align-items-center w-100"
                        style={{
                            borderRadius: 8,
                            backgroundColor: colors.white03,
                            marginBlock: 8,
                            paddingBlock: 6,
                            paddingInline: 16,
                        }}
                    >
                    </div>
                    <CustomHandle
                        type="source"
                        position={Position.Right}
                        style={handleStyle}
                        isConnectable={1}
                    />
                </div>
            </div >
        </>
    )
}

export default LlamaNode