import React, { useEffect, useRef, useState } from 'react'
import CheerioButton from '../../../Components/CheerioButton'
import colors from '../../../Utils/colors'
import { Checkbox } from '@mui/material'
import SignatureCanvas from 'react-signature-canvas'
import { toast } from 'react-toastify'
import images from '../../../Utils/images'
import { useSelector } from 'react-redux'
import { UploadNDAAPI } from '../../../Services'
import { getImagePath } from '../../../Utils'

const NDAModal = ({ setIsNDAModalOpen, updateLocalDataAction }) => {

    const localdata = useSelector((state) => state.main.localdata);
    const auth_token = useSelector((state) => state.main?.auth_token);

    const [agreeCheck, setAgreeCheck] = useState(false);
    const [base64Sign, setBase64Sign] = useState();
    const [successScreen, setSuccessScreen] = useState(false);
    const signcanvasref = useRef();
    const toastref = useRef();

    const clearSignAction = () => {
        signcanvasref.current.clear();
    }

    const saveAction = () => {
        if (signcanvasref.current.isEmpty()) {
            toast.error('Please sign in the box above')
        } else {
            toastref.current = toast.loading("Uploading signature. Please wait.");
            setBase64Sign(signcanvasref.current.getTrimmedCanvas().toDataURL('image/png'))
        }
    }

    useEffect(() => {
        if (base64Sign) {
            toast.update(toastref.current, { render: "signature uploaded.", type: "success", isLoading: false });
            uploadNDAApiCall();
        }
    }, [base64Sign])

    const uploadNDAApiCall = () => {
        let token = auth_token;
        let data = {
            image: base64Sign,
            fileName: "signature.png",
        };
        UploadNDAAPI(data, token).then((res) => {
            if (res) {
                toast.success("NDA uploaded successfully", {});
                updateLocalDataAction();
                setSuccessScreen(true);
            } else {
                toast.error("NDA upload failed", {});
            }
        })
    }

    const getCurrentDate = () => {
        const date = new Date();
        const mm = date.toLocaleString('en-EN', { month: 'short' });
        const dd = date.getDate();
        const yy = date.getFullYear();
        return dd + '-' + mm + '-' + yy;
    };

    return (
        <>
            <div className='DarkBg' onClick={() => { setIsNDAModalOpen(false) }}>
                <div className='Center'>
                    <div className='nda_modal_container' onClick={(e) => e.stopPropagation()}>
                        <div className='nda_modal_container__header'>
                            <h3>
                                {'Non Disclosure Agreement'}
                            </h3>
                            <img style={{ height: '18px', width: '18px' }} src={images.CMClose} onClick={() => { setIsNDAModalOpen(false) }} />
                        </div>
                        {successScreen ? (
                            <>
                                <div className='d-flex flex-column justify-content-center align-items-center w-100'
                                    style={{ flex: 1 }}>
                                    <img src={images.CircleCheckGreen} style={{ height: 160, width: 160, objectFit: 'contain' }}></img>
                                    <h3 className='mt-3 mb-5'>
                                        {'NDA signed successfully!'}
                                    </h3>
                                    <CheerioButton
                                        borderStyle={{ borderColor: colors.primary03, marginInline: 8 }}
                                        textStyle={{ color: colors.white }}
                                        btnText={'Close'}
                                        backColor={colors.primary03}
                                        // icon={images.WhatsAppWhite}
                                        onclick={() => {
                                            setIsNDAModalOpen(false);
                                        }}
                                    // disabled={agreeCheck ? false : true}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='nda_modal_container__body verythinscrollnew'>
                                    <p>
                                        {`This Non-Disclosure Agreement (the "Agreement") is made and entered into as of ${getCurrentDate()} (the "Effective Date") by and between Cheerio Technologies Private Limited, a company organized and existing under the laws of Bengaluru, Karnataka, India, with its principal place of business at No. 44/2, 14th 'B 'cross, Sarakki, JP Nagar 1st Phase, Near JP Nagar Metro Station, Bangalore, Karnataka - 560078 (hereinafter referred to as "Disclosing Party"), and [Customer/Client Name], a company organized and existing under the laws of India, with its principal place of business at India (hereinafter referred to as "Receiving Party").`}<br /><br />
                                        {`Definition of Confidential Information: For the purposes of this Agreement, "Confidential Information" shall include all data, information, materials, products, technology, trade secrets, business plans, financial information, or other proprietary information disclosed by the Disclosing Party to the Receiving Party, whether orally or in writing, and whether marked, designated, or otherwise identified as "confidential" or "proprietary" at the time of disclosure.`}<br /><br />
                                        {`Non-Disclosure and Non-Use Obligations: The Receiving Party agrees that it will not disclose, divulge, or communicate to any third party any Confidential Information without the prior written consent of the Disclosing Party. The Receiving Party further agrees that it will not use any Confidential Information for any purpose other than the purpose for which it was disclosed by the Disclosing Party. Data uploaded by you will not be shared by anyone ever unless you consent`}<br /><br />
                                        {`Duty of Care: The Receiving Party agrees to protect the Confidential Information with the same degree of care that it uses to protect its own confidential information of a similar nature, but in no event less than a reasonable standard of care.`}<br /><br />
                                        {`Exceptions: The obligations set forth in this Agreement shall not apply to any information that (a) is or becomes generally known to the public without breach of any obligation owed to the Disclosing Party, (b) was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party, (c) was independently developed by the Receiving Party without reference to the Confidential Information, or (d) is rightfully received from a third party without restriction on disclosure.`}<br /><br />
                                        {`Term and Termination: This Agreement shall remain in effect for a period of 3 years from the Effective Date unless earlier terminated by either party upon 30 days written notice. Upon termination or expiration of this Agreement, the Receiving Party shall promptly return or destroy all Confidential Information and certify in writing to the Disclosing Party that such Confidential Information has been returned or destroyed.`}<br /><br />
                                        {`Governing Law: This Agreement shall be governed by and construed in accordance with the laws of state of Karnataka, India, without giving effect to any principles of conflicts of law.`}<br /><br />
                                        {`IN WITNESS WHEREOF, the parties hereto have executed this Agreement as of the Effective Date.`}<br /><br />
                                        {`Cheerio Technologies Pvt Ltd`}<br /><br />
                                    </p>
                                    <div className='d-flex flex-row justify-content-between align-items-end w-100 my-2'>
                                        <p style={{ width: '50%' }}>
                                            {`By: `}<img src={images.AvinashSign} style={{ height: 70, width: 130, objectFit: 'contain' }}></img><br />
                                            {`Name: Avinash Upadhyay`}<br />
                                            {`Title: Director `}<br />
                                            {`Date: ${getCurrentDate()} `}
                                        </p>
                                        {localdata?.isNDASigned &&
                                            (
                                                <>
                                                    <img
                                                        src={getImagePath(localdata?.NDAImageLink)}
                                                        style={{ height: 70, width: 130, objectFit: 'contain', marginInlineEnd: 24, marginBlockEnd: 32 }}
                                                    />
                                                </>
                                            )}
                                    </div>
                                    {!localdata?.isNDASigned &&
                                        <>
                                            <div className='d-flex flex-row justify-content-start align-items-center w-100 my-2'>
                                                <Checkbox
                                                    checked={agreeCheck}
                                                    onChange={() => { setAgreeCheck(!agreeCheck) }}
                                                />
                                                <p>
                                                    {'I have read all the point & agree to the terms & conditions'}
                                                </p>
                                            </div>
                                            <div className='w-100 mb-2 d-flex justify-content-center align-items-center' style={{ height: 300, border: `1px solid ${colors.borderwhite}`, backgroundColor: colors.white04, borderRadius: 16 }}>
                                                <SignatureCanvas
                                                    canvasProps={{ width: 700, height: 280, className: 'sigCanvas' }}
                                                    ref={signcanvasref}
                                                />
                                            </div>
                                            <p style={{ width: '100%', textAlign: 'center' }}>{'Sign within the box to continue'}</p>
                                        </>
                                    }
                                </div>
                                {!localdata?.isNDASigned &&
                                    <>
                                        <div className='d-flex flex-row justify-content-center align-items-center w-100 my-2'>
                                            <CheerioButton
                                                borderStyle={{ borderColor: colors.primary03, marginInline: 8 }}
                                                textStyle={{ color: colors.primary03 }}
                                                btnText={'Clear'}
                                                backColor={colors.white01}
                                                // icon={images.WhatsAppWhite}
                                                onclick={() => {
                                                    clearSignAction();
                                                }}
                                            // disabled={true}
                                            />
                                            <CheerioButton
                                                borderStyle={{ borderColor: colors.primary03, marginInline: 8 }}
                                                textStyle={{ color: colors.white }}
                                                btnText={'Save & Continue'}
                                                backColor={colors.primary03}
                                                // icon={images.WhatsAppWhite}
                                                onclick={() => {
                                                    saveAction();
                                                }}
                                                disabled={agreeCheck ? false : true}
                                            />
                                        </div>
                                    </>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default NDAModal