import React from 'react';
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import images from '../../../Utils/images';
const handleStyle = { backgroundColor: '#FF7C32', height: 10, width: 10, borderRadius: 5 };

const AssignAgentNode = ({ data, isConnectable }) => {


    const option = data?.option || 'Automatic';
    const agents = data?.agents || [];

    if (!data.option) data.option = option;
    if (!data.agents) data.agents = agents;

    return (
        <>
            <div className='d-flex flex-column justify-content-between align-items-center' style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 16, backgroundColor: colors.white01, width: 230 }}>
                <Handle type='target' position={Position.Left} isConnectable={isConnectable} style={handleStyle} />
                <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{ paddingInline: 16, paddingBlock: 8, backgroundColor: '#FF7C32', borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
                    <div style={{ height: 4, width: '20%', borderRadius: 2, backgroundColor: colors.white, marginBlockEnd: 4 }}></div>
                    <div className='w-100 d-flex flex-row justify-content-between align-items-center' style={{}}>
                        <p style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.white }}>
                            {'Assign Agents'}
                        </p>
                        <img src={images.Edit} style={{ height: 20, width: 20, objectFit: 'contain' }}></img>
                    </div>
                </div>
                {/* <div className='w-100 d-flex flex-row justify-content-between align-items-center' style={{paddingInline:16,paddingBlock:8,backgroundColor:colors.yellow04,borderTopLeftRadius:16,borderTopRightRadius:16}}>
            <p style={{padding:0,margin:0,fontSize:16,fontWeight:700,color:colors.white}}>
                {'Assign to chatbot'}
            </p>
        </div> */}
                <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{ paddingInline: 16, paddingBlockStart: 8, paddingBlockEnd: 16, backgroundColor: colors.white01, borderBottomLeftRadius: 16, borderBottomRightRadius: 16 }}>
                    <p style={{ padding: 0, margin: 0, marginBlock: 4, fontSize: 12, fontWeight: 400, color: colors.black, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        {data?.option === 'Automatic' ? "All Agents" : "Selective Agents"}
                        <img src={images.DownArrowTip} style={{
                            width: 24,
                            height: 24,
                        }} />
                    </p>
                </div>
            </div>
        </>
    )
}

export default AssignAgentNode