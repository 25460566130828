import React, { useEffect, useState } from 'react'
import CheerioButton from '../../Components/CheerioButton'
import colors from '../../Utils/colors'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import SpinnerLoader from '../../Components/SpinnerLoader';
import images from '../../Utils/images';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { ContactMapping } from '../ContactModule/ContactMapping/ContactMapping';
import { useSelector } from 'react-redux';
import { deleteVendorsByID, getVendorsListContacts } from '../../Services';
import { Tooltip } from '@mui/material';
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from 'react-toastify';

const VendorList = () => {

  const authtoken = useSelector((state) => state.main.auth_token);
  const navigate = useNavigate();
  const location = useLocation();
  const [vendorListData, setVendorListData] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const retrigger = location?.state?.retrigger ?? false;

  useEffect(()=>{
    setIsLoading(true)
    getVendorsListAPICall();
  },[])

  useEffect(()=>{
    if(retrigger){
        getVendorsListAPICall();
    }
},[retrigger])

  const getVendorsListAPICall = () => {
    let token = authtoken;
    getVendorsListContacts(token).then((res)=>{
      if(res?.flag){
        setVendorListData(res?.data);
      }else{
        toast.error(res?.message,{position:'top-right',autoClose:true})
      }
    })
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const columns = [
    { id: 'vendorName', label: 'Vendor Name', width: '30%', align: 'start', minWidth:'300px' },
    { id: 'vendorEmail', label: 'Vendor Email', width: '30%', align: 'center', minWidth:'300px' },
    { id: 'vendorPhoneNo', label: 'Phone No', width: '25%', align: 'center', minWidth:'250px' },
    { id: 'vendorCompany', label: 'Vendor Company', width: '30%', align: 'center', minWidth:'300px' },
    { id: 'productName', label: 'Experience Name', width: '30%', align: 'center', minWidth:'300px' },
    { id: 'updatedOn', label: 'Updated On', width: '30%', align: 'center' , minWidth:'300px'},
    { id: 'action', label: 'Action', width: '25%', align: 'center', minWidth:'250px' },
  ];

  const addContactAction = () => {
    navigate('/creator/vendorsList/upload')
  }

  const uploadCSVAction = () => {
    navigate('/creator/vendorsList/upload')
  }

  const deleteAction = (data) => {
    let token = authtoken;
    deleteVendorsByID(token, data).then((res)=>{
      if(res?.flag){
        toast.success('Vendor successfully deleted',{autoClose:true, position:'top-right'})
      }else{
        toast.error("Vendor deletion failed, please try again later",{autoClose:true, position:'top-right'})
      }
    })
    getVendorsListAPICall();
  }

  return (
    <>
    {location.pathname === '/creator/vendorsList' && 
    <>
    <div className='w-100 h-100 Column justify-content-start' style={{
      paddingBlock:32,
      paddingInline:40,
      overflowY:'hidden',
      overflowX:'auto',
    }}>
      <div className='Row w-100'>
        <h3>
          {'Vendors List'}
        </h3>
        <CheerioButton 
          borderStyle={{borderColor: colors.primary03}}
          backColor={colors.primary03}
          textStyle={{fontSize:16,fontWeight:600,color:colors.white01}}
          btnText={'Add contact'}
          onclick={()=>{
            addContactAction()
          }}
        />
      </div>
        <TableContainer sx={{flex: 1,marginBlock: '20px',overflowX: 'auto',whiteSpace: 'nowrap'}}>
          {isLoading ? (
            <>
            <div className='d-flex flex-column justify-content-center align-items-center w-100 h-100'>
              <SpinnerLoader />
            </div>
            </>
          ): (
            <>
            {vendorListData?.length === 0 ? (
              <>
              <div className='Column justify-content-center w-100 h-100'>
                <img 
                  src={images.NoVendorsPlaceholder} 
                  style={{
                    height:300,
                    width:300,
                    objectFit:'contain',
                    marginBlock:20,
                  }}
                />
                <p style={{fontSize:16,fontWeight:600,color:colors.black,marginBlock:16}}>
                  {'Add your contacts here by uploading the csv file'}
                </p>
                <CheerioButton 
                  borderStyle={{borderColor: colors.primary03}}
                  backColor={colors.primary03}
                  textStyle={{fontSize:16,fontWeight:600,color:colors.white01}}
                  btnText={'Upload csv file'}
                  onclick={()=>{
                    uploadCSVAction()
                  }}
                />
              </div>
              </>
            ):(
              <>
                <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => {
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            width: column.minWidth,
                            // minWidth: column.minWidth,
                            fontSize: 16,
                            fontWeight: 500,
                            color: colors.black,
                            backgroundColor: colors.white04,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vendorListData?.length > 0 && vendorListData.map((row) => {
                    return (
                      <>
                      <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                        {columns.map((column) => {
                          let value = '';
                          if (column.id == 'vendorName') {
                            // value = row?.isUploading ? `${row?.listName} ${<CircularIndeterminate text={'Uploading...'} />}` : `${row?.listName}`
                            value = row?.vendorsName
                          } else if (column.id == 'vendorEmail') {
                            value = row?.vendorEmail
                          } else if (column.id == 'vendorPhoneNo') {
                            value = row?.vendorContactNo
                          } else if (column.id == 'vendorCompany') {
                            value = row?.vendorCompanyName
                          } else if (column.id == 'productName') {
                            value = row?.productName
                          } else if (column.id == 'updatedOn') {
                            value = formatDate(row?.createdAt) ?? 'NA'
                          }
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id == 'action' ? (
                                <>
                                  <div className="d-flex flex-row justify-content-center align-items-center">
                                    {/* <Tooltip title={'Edit'}>
                                      <FiEdit 
                                        size={18} 
                                        style={{marginInline:4}}
                                        color={colors.linkblue} 
                                        onClick={()=>{
                                          console.log("1111111111")
                                        }}
                                      />
                                    </Tooltip> */}
                                    <Tooltip title={'Delete'}>
                                      <RiDeleteBinLine 
                                        size={18} 
                                        style={{marginInline:4}}
                                        color={colors.linkblue} 
                                        onClick={()=>{
                                          deleteAction(row)
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                </>
                              ) : (
                                <p style={{ fontSize: 14, color: colors.black }}>
                                  {value}
                                </p>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
              </>
            )}
            </>
          )}
        </TableContainer>
    </div>
    </>
    }
    <Routes>
      <Route path='/upload' element={<ContactMapping vendorsList={true} />} />
    </Routes>
    </>
  )
}

export default VendorList