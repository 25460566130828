import React, { useEffect, useState } from 'react';
import { VariableBody } from './Body';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import CheerioButton from '../../Components/CheerioButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Modal, Box } from '@mui/material';

import { useSelector } from 'react-redux';
import '../AnnouncementsNew/styles.css';
import PreviewCustomTemplate from './Preview';
import '../CustomTemplate/styles.css';
import { CreateDraftTemplate, CustomTemplateAPI, EditDraftTemplate } from '../../Services';
import { VariableHeader } from './Header';
import { VariableButtons } from './Buttons';
import { VariableFooter } from './Footer';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import MessageCard from './MessageCard';
import AITemplateModal from '../Templates/Components/AITemplateModal';
import { languageList } from './CustomTemplate';
import { eventsend } from '../../Config/analyticsFunctions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '700px',
  bgcolor: 'background.paper',
  borderRadius: 5,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const FirstTemplate = ({
  templateCategory,
  templateName,
  lang,
  setFirstTemplate,
  ReceivedTemplateData,
  DraftData,
  templateType,
  setTemplateName,
  setTemplateCategory,
  setLang,
}) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state?.main?.localdata);
  const navigate = useNavigate();
  const [headerData, setHeaderData] = useState({
    type: 'HEADER',
    format: 'NONE', // NONE, TEXT, IMAGE, VIDEO, DOCUMENT, LOCATION
    text: '',
    example: {
      header_text: [], // Array of string with length 1
      header_handle: [], // {file_name, base64}[]
    },
  });

  const [cardsData, setCardsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [isAITemplateModal, setIsAITemplateModal] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleNavigate = () => {
    setOpen(false);
    if (DraftData) {
      navigate('/creator/templates/myDrafts');
    } else if (ReceivedTemplateData) {
      navigate('/creator/templates/whatsappTemplates');
    } else {
      setFirstTemplate(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log('changed card data ---> ', cardsData);
  }, [cardsData]);

  useEffect(() => {
    if (templateType === 'Carousel') {
      setCardsData([
        {
          components: [],
        },
      ]);
    }
  }, []);

  // Body Components
  const [bodyData, setBodyData] = useState({
    type: 'BODY',
    text: '',
    example: {
      body_text: [], // Array of string with length 1
    },
  });

  const [buttonData, setButtonData] = useState({
    type: 'BUTTONS',
    buttons: [],
  });

  const [footerData, setFooterData] = useState({
    type: 'FOOTER',
    text: '',
  });

  const [receivedButton, setReceivedButton] = useState([]);
  const [draftButton, setDraftButton] = useState([]);
  const [draftCards, setDraftCards] = useState([]);

  useEffect(() => {
    if (ReceivedTemplateData) {
      console.log('receieved data 00000 ', ReceivedTemplateData);
      if (ReceivedTemplateData?.header) {
        setHeaderData({
          ...headerData,
          format: ReceivedTemplateData.header.toUpperCase(),
        });
      }
      if (ReceivedTemplateData?.body) {
        setBodyData({
          ...bodyData,
          text: ReceivedTemplateData.body,
        });
      }
      if (ReceivedTemplateData?.button) {
        ReceivedTemplateData?.button?.forEach((item, index) => {
          setReceivedButton((prev) => {
            let temparr = [...prev];
            temparr[index] = {
              type: item.buttonType,
              text: item.buttonText,
              phone_number: '',
              url: item.buttonType === 'URL' ? 'https://web.cheerio.in/ss/{{1}}' : '',
              example: [localdata?.company_name ?? 'ESMY'],
              uuid: new Date().getMilliseconds(),
            };
            return temparr;
          });
        });
      }
    }
    if (DraftData) {
      if (DraftData?.data?.components?.some((component) => component.type === 'HEADER')) {
        const DraftHeader = DraftData?.data?.components?.find(
          (component) => component.type === 'HEADER'
        );
        setHeaderData({
          ...headerData,
          ...DraftHeader,
        });
      }
      if (DraftData?.data?.components?.some((component) => component.type === 'BODY')) {
        const DraftBody = DraftData?.data?.components?.find(
          (component) => component.type === 'BODY'
        );
        if (DraftBody?.example?.body_text?.length > 0) {
          setBodyData({
            ...bodyData,
            ...DraftBody,
            example: {
              ...DraftBody.example,
              body_text: DraftBody?.example?.body_text[0],
            },
          });
        } else {
          setBodyData({
            ...bodyData,
            ...DraftBody,
          });
        }
      }
      if (DraftData?.data?.components?.some((component) => component.type === 'FOOTER')) {
        const DraftFooter = DraftData?.data?.components?.find(
          (component) => component.type === 'FOOTER'
        );
        setFooterData({
          ...footerData,
          ...DraftFooter,
        });
      }
      if (DraftData?.data?.components?.some((component) => component.type === 'BUTTONS')) {
        const DraftButtons = DraftData?.data?.components?.find(
          (component) => component.type === 'BUTTONS'
        );
        setDraftButton([...DraftButtons?.buttons]);
      }
      if (DraftData?.data?.components?.some((component) => component.type === 'CAROUSEL')) {
        const DraftCarousel = DraftData?.data?.components?.find(
          (component) => component.type === 'CAROUSEL'
        );
        setDraftCards([...DraftCarousel?.cards]);
      }
    }
  }, []);

  useEffect(() => {
    if (draftButton) {
      setButtonData({
        ...buttonData,
        buttons: draftButton,
      });
    }
  }, [draftButton]);

  useEffect(() => {
    if (draftCards) {
      setCardsData([...draftCards]);
    }
  }, [draftCards]);

  useEffect(() => {
    if (receivedButton) {
      setButtonData({
        ...buttonData,
        buttons: receivedButton,
      });
    }
  }, [receivedButton]);
  // handleHeaderTextChange
  // const textCount = bodyValue.length;

  // const languageList = ['English', 'Hindi', 'Marathi', 'Gujarati', 'Tamil'];
  const cateogaryList = ['UTILITY', 'AUTHENTICATION', 'MARKETING'];

  const CustomTemplateAPICall = () => {
    const tempHeaderData = { ...headerData };
    const components = [];
    if (tempHeaderData.format !== 'NONE') {
      if (tempHeaderData.format === 'TEXT') {
        delete tempHeaderData.example.header_handle;
        if (tempHeaderData.text !== '') {
          eventsend('Header_dropdown_template_builder_selected');
          if (!tempHeaderData.text.match(/\{\{(\d+)\}\}/g)) {
            delete tempHeaderData.example;
            components.push({ ...headerData, example: null });
          } else {
            if (
              tempHeaderData.example.header_text.length !==
                tempHeaderData.text.match(/\{\{(\d+)\}\}/g).length ||
              tempHeaderData.example.header_text.includes('')
            ) {
              toast.error('Please enter all the header variable examples ');
              return;
            } else {
              components.push({ ...headerData });
            }
          }
        } else {
          toast.error('Please enter header text');
          return;
        }
      } else {
        // console.log('tempHeaderData', tempHeaderData);
        delete tempHeaderData.example.header_text;
        components.push(tempHeaderData);
      }
    }

    if (bodyData?.text !== '') {
      if (!bodyData.text.match(/\{\{(\d+)\}\}/g)) {
        components.push({ ...bodyData, example: null });
      } else if (
        bodyData.example.body_text.length ===
          (bodyData.text.match(/\{\{(\d+)\}\}/g)?.length || 0) &&
        !bodyData.example.body_text.includes('')
      ) {
        components.push({ ...bodyData, example: { body_text: [bodyData.example.body_text] } });
      } else {
        toast.error('Please enter all the body variable examples ');
        return;
      }
    } else {
      toast.error('Please enter body text');
      return;
    }

    if (footerData.text !== '') {
      eventsend('Footer_text_Template_builder_added');
      components.push(footerData);
    }

    if (buttonData.buttons.length > 0) {
      const tempButtonData = { ...buttonData };
      if (tempButtonData.buttons.map((e) => e.text).includes('')) {
        toast.error('Please enter all the button text');
        return;
      }
      if (
        tempButtonData.buttons
          .filter((e) => e.type === 'URL')
          .map((e) => e.url)
          .includes('')
      ) {
        toast.error('Please enter all the button url');
        return;
      } else if (
        tempButtonData.buttons
          .filter((e) => e.type === 'URL')
          .map((e) => e.example)
          .flat()
          .includes('')
      ) {
        toast.error('Please enter all the button url examples');
        return;
      }

      if (
        tempButtonData.buttons
          .filter((e) => e.type === 'PHONE_NUMBER')
          .map((e) => e.phone_number)
          .includes('')
      ) {
        toast.error('Please enter all the button phone number');
        return;
      }
      tempButtonData.buttons = tempButtonData.buttons.map((e) => {
        delete e.uuid;
        return e;
      });
      components.push(tempButtonData);
    }

    if (templateType === 'Carousel' && cardsData?.length > 0) {
      components.push({
        type: 'CAROUSEL',
        cards: cardsData,
      });
    }

    // console.log('components', components);
    let token = authtoken;
    const toastId = toast.loading('Creating Template. This may take a while');
    CustomTemplateAPI(
      {
        name: templateName,
        category:
          templateType === 'Carousel' && templateCategory === 'AUTHENTICATION'
            ? 'MARKETING'
            : templateCategory,
        language: lang,
        allow_category_change: true,
        components: components,
      },
      token
    )
      .then((res) => {
        toast.update(toastId, {
          render: 'Template Created Successfully',
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          autoClose: true,
        });
        navigate('/creator/templates/myTemplates');
      })
      .catch((err) => {
        toast.update(toastId, {
          render: err?.response?.data?.message || 'Error Occured while creating template',
          type: toast.TYPE.ERROR,
          isLoading: false,
          autoClose:true
        });
      });
  };

  const saveDraftAPICall = () => {
    let token = authtoken;
    let data = {
      name: templateName,
      data: {
        name: templateName,
        category:
          templateType === 'Carousel' && templateCategory === 'AUTHENTICATION'
            ? 'MARKETING'
            : templateCategory,
        language: lang,
        allow_category_change: true,
      },
    };
    // const toastId = toast.loading('Creating draft. This may take a while');
    CreateDraftTemplate(data, token).then((res) => {
      if (res?.flag) {
        // toast.update(toastId, {
        //   render: 'Draft created Successfully',
        //   type: toast.TYPE.SUCCESS,
        //   isLoading: false,
        //
        // });
        editDraftAPICall(res?.data?._id);
      } else {
        // toast.update(toastId, {
        //   render: res?.message || 'Error Occured while creating draft',
        //   type: toast.TYPE.ERROR,
        //   isLoading: false,
        //
        // });
        toast.error(res?.message ?? 'Error Occured while creating draft', {
          position: 'top-right',
        });
      }
      // console.log("draft res ---> ",res)
      // toast.update(toastId, {
      //   render: 'Draft Saved Successfully',
      //   type: toast.TYPE.SUCCESS,
      //   isLoading: false,
      //
      // });
      // navigate('/creator/templates/myTemplates');
    });
    // .catch((err) => {
    //   toast.update(toastId, {
    //     render: err?.response?.data?.message || 'Error Occured while creating template',
    //     type: toast.TYPE.ERROR,
    //     isLoading: false,
    //
    //   });
    // });
  };

  const editDraftAPICall = (_id) => {
    console.log('EDIT 999999999');
    const tempHeaderData = { ...headerData };
    const components = [];
    if (tempHeaderData.format !== 'NONE') {
      if (tempHeaderData.format === 'TEXT') {
        delete tempHeaderData.example.header_handle;
        if (tempHeaderData.text !== '') {
          eventsend('Header_dropdown_template_builder_selected');
          if (!tempHeaderData.text.match(/\{\{(\d+)\}\}/g)) {
            delete tempHeaderData.example;
            components.push({ ...headerData, example: null });
          } else {
            if (
              tempHeaderData.example.header_text.length !==
                tempHeaderData.text.match(/\{\{(\d+)\}\}/g).length ||
              tempHeaderData.example.header_text.includes('')
            ) {
              toast.error('Please enter all the header variable examples ');
              return;
            } else {
              components.push({ ...headerData });
            }
          }
        } else {
          toast.error('Please enter header text');
          return;
        }
      } else {
        // console.log('tempHeaderData', tempHeaderData);
        delete tempHeaderData.example.header_text;
        components.push(tempHeaderData);
      }
    }

    if (bodyData?.text !== '') {
      if (!bodyData.text.match(/\{\{(\d+)\}\}/g)) {
        components.push({ ...bodyData, example: null });
      } else if (
        bodyData.example.body_text.length ===
          (bodyData.text.match(/\{\{(\d+)\}\}/g)?.length || 0) &&
        !bodyData.example.body_text.includes('')
      ) {
        components.push({ ...bodyData, example: { body_text: [bodyData.example.body_text] } });
      } else {
        toast.error('Please enter all the body variable examples ');
        return;
      }
    } else {
      toast.error('Please enter body text');
      return;
    }

    if (footerData.text !== '') {
      eventsend('Footer_text_Template_builder_added');
      components.push(footerData);
    }

    if (buttonData.buttons.length > 0) {
      const tempButtonData = { ...buttonData };
      if (tempButtonData.buttons.map((e) => e.text).includes('')) {
        toast.error('Please enter all the button text');
        return;
      }
      if (
        tempButtonData.buttons
          .filter((e) => e.type === 'URL')
          .map((e) => e.url)
          .includes('')
      ) {
        toast.error('Please enter all the button url');
        return;
      } else if (
        tempButtonData.buttons
          .filter((e) => e.type === 'URL')
          .map((e) => e.example)
          .flat()
          .includes('')
      ) {
        toast.error('Please enter all the button url examples');
        return;
      }

      if (
        tempButtonData.buttons
          .filter((e) => e.type === 'PHONE_NUMBER')
          .map((e) => e.phone_number)
          .includes('')
      ) {
        toast.error('Please enter all the button phone number');
        return;
      }
      tempButtonData.buttons = tempButtonData.buttons.map((e) => {
        delete e.uuid;
        return e;
      });
      components.push(tempButtonData);
    }

    if (templateType === 'Carousel' && cardsData?.length > 0) {
      components.push({
        type: 'CAROUSEL',
        cards: cardsData,
      });
    }

    let token = authtoken;
    const toastId = toast.loading('Saving draft. This may take a while');
    EditDraftTemplate(
      {
        name: templateName,
        data: {
          name: templateName,
          category:
            templateType === 'Carousel' && templateCategory === 'AUTHENTICATION'
              ? 'MARKETING'
              : templateCategory,
          language: lang,
          allow_category_change: true,
          components: components,
        },
      },
      token,
      _id
    ).then((res) => {
      if (res) {
        console.log('success edit draft ', res);
        toast.update(toastId, {
          render: 'Draft saved successfully',
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          autoClose: true,
        });
      } else {
        toast.update(toastId, {
          render: res?.message || 'Error Occured while creating template',
          type: toast.TYPE.ERROR,
          isLoading: false,
          autoClose:true
        });
      }
      // toast.update(toastId, {
      //   render: 'Template Created Successfully',
      //   type: toast.TYPE.SUCCESS,
      //   isLoading: false,
      //
      // });
      // navigate('/creator/templates/myTemplates');
    });
    // .catch((err) => {
    //   toast.update(toastId, {
    //     render: err?.response?.data?.message || 'Error Occured while creating template',
    //     type: toast.TYPE.ERROR,
    //     isLoading: false,
    //
    //   });
    // });
  };

  return (
    <>
      {isAITemplateModal && (
        <AITemplateModal
          setIsAITemplateModal={setIsAITemplateModal}
          isAITemplateModal={isAITemplateModal}
        />
      )}
      {/* <div style={{width:"100%",display:"flex",position:"relative",top:"140px"}}> */}
      {/* <div className='d-flex flex-column justify-content-between align-items-center h-100 w-100' style={{paddingInline:40,paddingBlock:20}}> */}
      {/* Border Changes */}
      {/* <div className='d-flex flex-column h-100 w-100' style={{border:"2px solid black"}}> */}
      <div className="d-flex flex-column" style={{ height: '100vh', position: 'relative' }}>
        {/* This is the header */}
        {/* Border Changes */}

        {/* <div className='w-100'style={{border:"2px solid red",height:"60px",display:"flex"}}> */}
        <div
          className="d-flex flex-row justify-content-between align-items-center"
          style={{
            height: '60px',
            width: '100%',
            paddingInline: 20,
            // display: 'flex',
            // marginBottom: '10px'
          }}
        >
          {/* <div style={{borderBottom:"1px solid green",height:"100px",display:"flex",width:"141%"}}> */}
          <div
            className="d-flex flex-row justify-content-start align-items-center"
            style={{ width: '40%' }}
          >
            <img
              alt="back_btn"
              src={images.BackArrowBlack}
              style={{
                // position: 'absolute',
                // marginLeft: '19px',
                // marginTop: '18px',
                height: '24px',
                width: '24px',
                cursor: 'pointer',
                marginInlineEnd: 10,
              }}
              onClick={handleOpen}
            ></img>
            <InputGroup className="" style={{ width: 'auto' }}>
              <Form.Control
                type="text"
                placeholder={'Enter flow name here'}
                className="w-100 btncustom"
                style={{ borderRadius: 8, fontSize: 20, fontWeight: 600, color: colors.black }}
                // isInvalid={(renewalText.length >= 640)}
                // maxLength={640}
                value={templateName}
                onChange={(e) => {
                  const val = e.target.value.replace(/[^a-z0-9_]/g, '');
                    setTemplateName(val);
                }}
              />
            </InputGroup>
            {/* <span
              style={{
                // position: 'absolute',
                fontSize: '20px',
                fontWeight: '700',
                marginInlineStart: 10,
                // marginLeft: '65px',
                // marginTop: '15px',
              }}
            >
              {templateName}
            </span> */}
          </div>
          {/* <div style={{display:"flex",position:"relative",left:"320px",top:"20px"}}> */}
          {/* Border Changes */}
          {/* <div style={{display:"flex",border:"2px solid green",position:"absolute",marginLeft:"400px"}}> */}
          <div
            className="d-flex flex-row justify-content-end align-items-center"
            style={{
              // position: 'relative',
              // width: '38%',
              maxWidth: '60%',
              width: 'auto',
              // flexDirection: 'row',
              // display: 'flex',
              // backgroundColor:'#f44',
            }}
          >
            <div
              className="btncustom mx-3"
              style={{
                // width: '25%',
                // height: '15%',
                // marginTop: '22px',
                // position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // marginRight: '10px', // Align text vertically
              }}
            >
              <span
                style={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: colors.black,
                  // cursor: 'default',
                  // backgroundColor: 'white',
                }}
              >
                {'Category: '}
              </span>
              <Form.Select
                type="text"
                placeholder="category"
                className="btncustom dropdown"
                style={{
                  borderRadius: 8,
                  fontSize: 12,
                  fontWeight: 400,
                  color: colors.greys01,
                  marginInline: 16,
                  width: 'auto',
                }}
                value={templateCategory}
                onChange={(e) => {
                  setTemplateCategory(e.target.value);
                  eventsend('Template_type_dropdown_selected');
                  if (e.target.value === 'MARKETING') {
                    eventsend('Marketing_clicked_template_builder_clicked');
                  } else if (e.target.value === 'UTILITY') {
                    eventsend('Utility_clicked_template_builder_clicked');
                  } else if (e.target.value === 'AUTHENTICATION') {
                    eventsend('Authentication_clicked_template_builder_clicked');
                  }
                }}
              >
                <option
                  style={{ marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                  key="blankchoice"
                  hidden
                  value
                >
                  {'Select'}
                </option>
                <option
                  style={{ marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                  value={'MARKETING'}
                  key={'MARKETING'}
                >
                  {'MARKETING'}
                </option>
                <option
                  style={{ marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                  value={'UTILITY'}
                  key={'UTILITY'}
                >
                  {'UTILITY'}
                </option>
                <option
                  style={{ marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                  value={'AUTHENTICATION'}
                  key={'AUTHENTICATION'}
                >
                  {'AUTHENTICATION'}
                </option>
              </Form.Select>
              {/* <span
                style={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: colors.greys04,
                  marginLeft: '8px', // Adjust spacing
                }}
              >
                {templateType === 'Carousel' && templateCategory === 'AUTHENTICATION'
                  ? 'MARKETING'
                  : templateCategory}
              </span> */}
            </div>
            <div
              className="btncustom mx-3"
              style={{
                // width: '25%',
                // height: '15%',
                // marginTop: '22px',
                // position: 'relative',
                display: 'flex',
                justifyContent: 'center', // Align text vertically
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: colors.black,
                  // cursor: 'default',
                  // backgroundColor: 'white',
                }}
              >
                {'Language: '}
              </span>
              <Form.Select
                type="text"
                placeholder="language"
                className="btncustom dropdown"
                style={{
                  borderRadius: 8,
                  fontSize: 12,
                  fontWeight: 400,
                  color: colors.greys01,
                  marginInline: 16,
                  width: 'auto',
                }}
                value={lang}
                onChange={(e) => {
                  setLang(e.target.value);
                }}
              >
                {languageList &&
                  languageList.map((e, index) => {
                    return (
                      <option
                        style={{ marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                        value={e.key}
                        key={e.key}
                      >
                        {e.value}
                      </option>
                    );
                  })}
              </Form.Select>
              {/* <span
                style={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: colors.greys04,
                  marginLeft: '8px', // Adjust spacing
                }}
              >
                {lang === 'en' ? 'ENGLISH' : lang}
              </span> */}
            </div>
            <div
              className="d-flex flex-row justify-content-center align-items-center mx-3"
              // style={{width:'25%'}}
            >
              <CheerioButton
                borderStyle={{
                  borderColor: colors.primary03,
                  // borderRadius: 8,
                  // width: '170%',
                  // height: '6%',
                  paddingInline: 20,
                  paddingBlock: 8,
                  // marginLeft: '64px',
                  // marginBottom: '7px',
                  // marginTop: '-2%'
                }}
                textStyle={{ fontSize: 14, fontWeight: 600, color: colors.primary03 }}
                btnText={'Save Draft'}
                backColor={colors.white02}
                // icon={images.WhatsAppWhite}
                onclick={() => {
                  console.log('save draft clicked');
                  if (DraftData?._id) {
                    editDraftAPICall(DraftData?._id);
                  } else {
                    saveDraftAPICall();
                  }
                  // CustomTemplateAPICall(e);
                }}
                // disabled={enableSubmit ? false : true}
              />
            </div>
            <div
              className="d-flex flex-row justify-content-center align-items-center mx-3"
              // style={{width:'25%'}}
            >
              <CheerioButton
                borderStyle={{
                  borderColor: colors.black,
                  // borderRadius: 8,
                  // width: '170%',
                  // height: '6%',
                  paddingInline: 20,
                  paddingBlock: 8,
                  // marginLeft: '64px',
                  // marginBottom: '7px',
                  // marginTop: '-2%'
                }}
                textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                btnText={'Submit Template'}
                backColor={colors.black}
                // icon={images.WhatsAppWhite}
                onclick={(e) => {
                  eventsend('Next_Template_builder_clicked');
                  eventsend('Submit_Template_builder_Clicked');
                  CustomTemplateAPICall(e);
                }}
                // disabled={enableSubmit ? false : true}
              />
            </div>
          </div>
        </div>

        {/* <---- Hiding Boder Colour -------------> */}
        {/* <div className='w-100' style={{display:"flex",border:"1px solid purple",height:"607px"}}>       */}
        <div
          className=""
          style={{
            display: 'flex',
            borderTop: '0.5px solid #D3D3D3',
            // height: '607px',
            height: 'calc( 100% - 60px )',
          }}
        >
          {/*  This is the Main part */}
          {/* Border Changes  */}
          {/* <div className='flex-column w-50' style={{border:"2px solid pink", overflowY:'scroll',width: "79%",marginTop:"8px", marginLeft:"10px", borderRadius:"10px"}}> */}

          <div style={{ width: '100%', height: '100%', overflowY: 'auto', scrollbarWidth: 'thin' }}>
            {templateType === 'Carousel' ? (
              <>
                <div style={{ width: '100%', marginTop: '10px' }}>
                  <VariableBody
                    data={bodyData}
                    setData={setBodyData}
                    Title={'Message Bubble'}
                    Carousel={true}
                    Bubble={true}
                  />
                </div>
                {cardsData?.length > 0 &&
                  cardsData.map((item, index) => {
                    return (
                      <>
                        <div
                          className="w-100"
                          style={{ height: 2, backgroundColor: colors.greys04 }}
                        ></div>
                        <div className="w-100 px-3 py-3 d-flex flex-row justify-content-between align-items-center">
                          <h3>{`Message Card ${index + 1}`}</h3>
                          <img
                            src={images.DeleteBlack}
                            style={{
                              height: 24,
                              width: 24,
                              objectFit: 'contain',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setCardsData((data) => {
                                const tempArr = [...data];
                                tempArr.splice(index, 1);
                                return tempArr;
                              });
                            }}
                          ></img>
                        </div>

                        <MessageCard
                          setCardData={setCardsData}
                          index={index}
                          cardData={cardsData}
                        />
                      </>
                    );
                  })}
                <CheerioButton
                  borderStyle={{
                    borderColor: colors.black,
                    width: '70%',
                    // height: '6%',
                    // marginLeft: '64px',
                    marginInline: 'auto',
                    marginBlock: 20,
                  }}
                  textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                  btnText={'Add new message card'}
                  backColor={colors.black}
                  // icon={images.WhatsAppWhite}
                  onclick={() => {
                    setCardsData((cards) => {
                      let tempArr = [...cards];
                      tempArr.push({
                        components: [],
                      });
                      return tempArr;
                    });
                  }}
                  disabled={cardsData?.length < 10 ? false : true}
                />
              </>
            ) : (
              <>
                <VariableHeader data={headerData} setData={setHeaderData} />

                <div style={{ width: '100%', marginTop: '10px' }}>
                  <VariableBody data={bodyData} setData={setBodyData} />
                </div>
                <div style={{ width: '100%', marginTop: '10px' }}>
                  <VariableFooter
                    data={footerData.text}
                    setData={(value) => {
                      setFooterData({
                        ...footerData,
                        text: value,
                      });
                    }}
                  />
                </div>

                <div style={{ width: '100%', marginTop: '10px', marginBottom: '20px' }}>
                  <VariableButtons
                    data={buttonData.buttons}
                    setData={(data) => {
                      setButtonData({
                        ...buttonData,
                        buttons: data,
                      });
                    }}
                  />
                </div>
              </>
            )}
          </div>

          {/* This is the preview */}

          <div
            className="d-flex flex-column"
            style={{ overflowY: 'scroll', width: '40%', paddingInline: 16 }}
          >
            <div
              className="w-100 d-flex flex-column justify-content-start align-items-center WAPreviewScroll"
              style={{
                flex: 1,
                border: `1px solid ${colors.borderwhite}`,
                marginBlockEnd: 16,
                marginBlockStart: 8,
                borderRadius: 16,
                paddingBlock: 10,
                overflowY: 'auto',
              }}
            >
              <h4 style={{ fontSize: 16, fontWeight: 700, color: colors.black }}>
                {'Message Preview'}
              </h4>
              <PreviewCustomTemplate
                body={bodyData}
                header={headerData}
                footerData={footerData}
                buttonData={buttonData}
                CarouselData={cardsData}
              />
            </div>
            {/* <CheerioButton
              borderStyle={{
                borderColor: 'transparent',
                borderRadius: 20,
                width: '70%',
                height: '6%',
                marginLeft: '64px',
                marginBottom: '8px',
                background: 'linear-gradient(90deg, #B974FF, #26DBD0)'
              }}
              textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
              btnText={'Generate with AI'}
              backColor={colors.black}
              icon={images.AIBall}
              iconStyle={{
                width: 29.62,
                height: 29.62
              }}
              // icon={images.WhatsAppWhite}
              onclick={() => setIsAITemplateModal(true)}
            // disabled={enableSubmit ? false : true}
            /> */}
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 550 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '10px',
            }}
          >
            <h2 id="child-modal-title">Discard Content</h2>
            <img
              src={images?.CMClose}
              style={{ width: '25px', cursor: 'pointer' }}
              onClick={handleClose}
            ></img>
          </div>
          <p id="child-modal-description" style={{ color: colors?.darkgrey }}>
            Any content which you've entered will be deleted if you discard.
          </p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '10px',
              gap: '40px',
            }}
          >
            <CheerioButton
              borderStyle={{
                borderColor: colors.black,
                width: '100%',
                height: '6%',
                marginBottom: '5px',
              }}
              textStyle={{ fontSize: 14, fontWeight: 600, color: colors.black }}
              btnText={'Exit & go back'}
              backColor={'transparent'}
              onclick={handleNavigate}
            />
            <CheerioButton
              borderStyle={{
                borderColor: colors.black,
                width: '100%',
                height: '6%',
                marginBottom: '5px',
              }}
              textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
              btnText={'Continue Editing'}
              backColor={colors.black}
              onclick={handleClose}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default FirstTemplate;
const Styles = {
  DarkBg: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    width: '100vw',
    height: '100vh',
    // flex:1,
    zIndex: 1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'fixed',
  },
  Center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  TxtHeading: {
    fontSize: 24,
    fontWeight: 700,
    color: colors.black,
  },
  TxtHeading2: {
    fontSize: 16,
    fontWeight: 700,
    color: colors.black,
  },
  TxtBody2: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.greys04,
  },
  TxtBody1: {
    fontSize: 16,
    fontWeight: 400,
    color: colors.primary03,
  },
  borderstyle: {
    backgroundColor: colors.white,
    borderRadius: 16,
    boxShadow: '0px 0px 10px 0 rgba(0, 0, 0, 0.15)',
  },
  borderDashed: {
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: colors.primary04,
    borderRadius: 40,
  },
  TxtBody: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.black04,
  },
};
