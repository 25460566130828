import { useLocation, useNavigate } from 'react-router-dom';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';

const AutotopupSuccesModel = ({ setPSModalOpen, fetchautorechargedetails }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    setPSModalOpen(false);
    fetchautorechargedetails();
  };

  return (
    <div className='DarkBg'>
      <div className='Center'>
        <div
          className='d-flex flex-column justify-content-start align-items-center position-relative'
          style={{
            maxHeight: '100vh',
            width: 510,
            height: 570,
            backgroundColor: colors.white01,
            border: `1px solid ${colors.borderwhite}`,
            borderRadius: 16,
            paddingBlock: 20,
            paddingInline: 30,
          }}
        >
          <img
            src={images.CMClose}
            alt="Close"
            style={{
              cursor: 'pointer',
              height: 26,
              width: 26,
              position: 'absolute',
              top: 10,
              right: 10,
            }}
            onClick={handleClose}
          />


          <img
            src={images.Success}
            alt="Success"
            style={{
              height: 200,
              width: 200,
              objectFit: 'contain',
              marginBlock: 32,
            }}
          />

          <h3
            className='my-2'
            style={{
              fontSize: 20,
              fontWeight: 700,
              color: colors.black,
              textAlign: 'center',
            }}
          >
            {'Congratulations'}
            <br />
            {'Wallet auto top up setup is completed'}
          </h3>
          <p
            className='my-2'
            style={{
              fontWeight: 400,
              fontSize: '16px',
              color: '#666666',
              textAlign: 'center',
              marginLeft: '8px'
            }}>
            You can manage your wallet top up and other setting by clicking on manage
          </p>
          <div
            style={{
              width: '95%',
              position: 'absolute',
              bottom: 20, 
              justifyContent: 'center',
            }}
          >
            <CheerioButton
              borderStyle={{ borderColor: colors.primary03, width: '95%' }}  
              textStyle={{ color: '#fff' }}
              btnText={'Manage'}
              backColor={colors.primary03}
              onclick={() => navigate('/manageprofile/paymentDetails')}  
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutotopupSuccesModel;
