import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import images from '../../../Utils/images';
import { AiOutlineSearch } from 'react-icons/ai';
import '../styles.css';
import CheerioButton from '../../../Components/CheerioButton';
import { distributeLeads, getUserApi, toggleAutoReassign } from '../../../Services';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export const DistributedLeads = ({
  show,
  setShow,
  agents,
  totalChats,
  getInboxListing,
  setInboxList,
}) => {
  const [selectedAgents, setSelectedAgents] = useState([]);
  const dispatch = useDispatch();
  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);
  console.log('s', selectedAgents);

  const getuserapicall = () => {
    let token = authtoken;
    getUserApi(token).then((res) => {
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
      } else {
        console.log('error get user api ===> ', res);
      }
    });
  };

  const handleReassignToggle = (e) => {
    const data = {};
    if (e.target.checked) {
      data.value = true;
    } else {
      data.value = false;
    }
    toggleAutoReassign(authtoken, data).then((res) => {
      if (res?.flag) {
        toast.success(res?.data,);
        getuserapicall();
      } else {
        toast.error(res?.message,);
      }
    });
  };

  const handleCheckBoxChange = (e) => {
    const value = Object.values(agents).filter((item) => item?._id === e.target.value);
    if (e.target.checked) {
      setSelectedAgents((prev) => {
        return [...prev, value[0]];
      });
    } else {
      const newArr = selectedAgents.filter((item) => item?._id !== value._id);
      setSelectedAgents(newArr);
    }
  };

  const distributeLeadsApiCall = () => {
    const data = {
      agents: selectedAgents,
    };

    distributeLeads(authtoken, data).then((res) => {
      if (res?.flag) {
        toast.success(res?.message, { position: 'top-center' });
        window.location.reload();
        // setInboxList([]);
        // getInboxListing(1);
        //setShow(false);
      } else {
        toast.error(res?.message, { position: 'top-center' });
      }
    });
  };
  return (
    <div>
      <Modal
        show={show}
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="my-modal"
        contentClassName=""
      >
        <Modal.Body
          style={{ padding: '30px', width: '100%', overflowY: 'auto', scrollbarWidth: 'thin' }}
        >
          <div>
            <div className="d-flex justify-content-between">
              <div>
                {' '}
                <p style={{ fontSize: '20px', fontWeight: '700' }}>Distribute Leads</p>
                <p style={{ marginTop: '' }}>
                  You have {totalChats} unassigned chats and{' '}
                  {agents ? Object.values(agents).length : '0'} agents how you want me to distribute
                  them:-
                </p>
              </div>

              <div className="d-flex flex-row">
                <img
                  id="CloseModal"
                  src={images.CMClose}
                  onClick={() => {
                    setShow(false);
                  }}
                  style={{
                    cursor: 'pointer',
                    height: 22,
                    width: 22,
                    marginInlineEnd: 8,
                  }}
                ></img>
              </div>
            </div>
            {/* <div className={'loggedInput'} style={{ marginBottom: '20px' }}>
              <AiOutlineSearch size={18} />
              <input
                style={{ width: '100%', border: 'none', outline: 'none' }}
                className="inputAgent"
                type={'text'}
                placeholder={`Search for agents`}
                onChange={() => {}}
              />
            </div> */}

            {agents &&
              Object.values(agents).map((item, index) => {
                return (
                  <div className="d-flex" style={{ paddingTop: '10px' }}>
                    <input
                      type="checkbox"
                      value={item?._id}
                      style={{ height: '30px', width: '18px' }}
                      onChange={(e) => {
                        handleCheckBoxChange(e);
                      }}
                    />
                    <p
                      style={{
                        margin: 0,
                        fontWeight: 'normal',
                        fontSize: 12,
                        color: 'white',
                        marginLeft: '10px',
                        backgroundColor: '#5FC5FF',
                        height: 30,
                        borderRadius: 50,
                        textAlign: 'center',
                        paddingTop: 6,
                        paddingBottom: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      {item?.name[0] + ' '}
                    </p>
                    <div style={{ marginLeft: '10px' }}>
                      <p style={{ marginTop: '', fontSize: '16px' }}> {item?.name}</p>
                      <p style={{ marginTop: '-5px', fontSize: '12px', color: '#666666' }}>
                        {item?.role}
                      </p>
                    </div>
                  </div>
                );
              })}

            <div className="d-flex justify-content-between">
              <div className="d-flex" style={{ marginTop: '20px' }}>
                <input
                  id="AutomaticCheckbox"
                  type="checkbox"
                  checked={localdata?.autoReassign}
                  style={{ height: '16px', marginTop: '5px' }}
                  onChange={(e) => {
                    handleReassignToggle(e);
                  }}
                />
                <p style={{ marginLeft: '10px', fontSize: '16px' }}>
                  Reassign new leads automatically
                </p>
              </div>
              <CheerioButton
                id="DistributeButton"
                btnText={'Distribute'}
                textStyle={{ color: 'white' }}
                backColor={'black'}
                borderStyle={{ width: '25%', height: '40px' }}
                disabled={totalChats < 1 ? true : false}
                onclick={() => {
                  //getInboxListing();
                  if (selectedAgents.length > 0) {
                    distributeLeadsApiCall();
                  } else {
                    toast.error('Please select an agent to distribute', {
                      position: 'top-center',

                    });
                  }
                }}
              />
            </div>
            <div style={{ marginTop: '10px', fontSize: '10px' }}>
              <i>Note:-You need to reload the page to see the changes after distribution.</i>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
