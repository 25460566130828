import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import images from '../../Utils/images';
import Plans from './Plans';

const TopUp = () => {
  const navigate = useNavigate();

  return (
    <>
      <img
        src={images.CMClose}
        style={{
          height: 24,
          width: 24,
          position: 'absolute',
          right: '80px',
          top: '25px',
          objectFit: 'contain',
          marginInlineEnd: 8,
          cursor: 'pointer',
        }}
        onClick={() => {
          navigate('/creator');
        }}
      ></img>
      <Routes>
        <Route path="plans" element={<Plans />} />
      </Routes>
    </>
  );
};

export default TopUp;
