import { Form, InputGroup } from 'react-bootstrap';
import { Styles } from './Styles';
export const VariableFooter = ({
  data,
  setData,
}: {
  data: string;
  setData: (val: string) => void;
}) => {
  return (
    <InputGroup
      className="mb-2 py-2 px-3"
      style={{
        borderBottom: '1px solid gray',
        marginBottom: '10px',
        position: 'relative',
        paddingBottom: '35px',
        left: '1',
      }}
    >
      <div style={{ width: '100%', marginBottom: '20px' }}>
        <Form.Label style={{ ...Styles.TxtHeading2 }}>
          Footer
          <span
            style={{
              color: 'grey',
              fontSize: '14px',
              marginLeft: '10px',
              fontWeight: '600',
            }}
          >
            Optional
          </span>
        </Form.Label>
        <div
          style={{
            fontSize: '14px',
            color: 'grey',
            fontWeight: '400',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p>Add a short line of text at the bottom of your message template</p>
          <span
            style={{
              color: 'grey',
              textAlign: 'end',
              justifyContent: 'right',
              marginLeft: '10px',
            }}
          >
            {data.length}/60
          </span>
        </div>
        <Form.Control
          type="text"
          placeholder="Enter text"
          className="w-100 btncustom"
          style={{ borderRadius: 8, marginTop: '10px' }}
          value={data}
          onChange={(e) => {
            setData(e.target.value);
          }}
          maxLength={60}
        />
      </div>
    </InputGroup>
  );
};
