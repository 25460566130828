import React from 'react';
import CheerioButton from '../../Components/CheerioButton';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { paymentOrder } from '../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getUserApi } from '../../Services';


const Addons = () => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const localdata = useSelector((state) => state.main.localdata);


    const getuserapicall = () => {
        let token = authtoken;
        getUserApi(token).then((res) => {
            // console.log('get user api response ==> ', res);
            if (res?.flag) {
                dispatch({ type: 'addLocaldata', payload: res?.data });
            } else {
                navigate('/');
                toast.error(res?.message);
            }
        });
    };

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }

    async function displayRazorpay(planName) {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?')
            return
        }
        const jsonPlan = {
            "plan": "",
            "planId": ""
        }
        if (planName === 'Addons') {
            jsonPlan.plan = "Addons";
            jsonPlan.planId = 'plan_O2xRfniwXuDVMS';
        }
        else if (planName === 'Email') {
            jsonPlan.plan = "Email";
            jsonPlan.planId = 'plan_O2xTVRZcbAi9ur'
        }
        else if (planName === 'Whatsapp') {
            jsonPlan.plan = "Whatsapp";
        }
        else if (planName === 'SMS') {
            jsonPlan.plan = "SMS";
        }
        const data = await paymentOrder(jsonPlan, authtoken);


        console.log(data)

        const options = {
            key: 'rzp_live_1IEjlHGI3q0yMR',
            subscription_id: data?.response?.subscriptionId,
            name: 'Subscription',
            description: planName === 'Email' ? 'Email' : 'Addons',
            handler: function (response) {
                toast.success(planName === 'Email' ? 'You have subscribed to Email Credits successfully!' : 'You have subscribed to Addons Bundle successfully!', { position: 'top-center' })
                getuserapicall();
            },
        }
        const paymentObject = new window.Razorpay(options)
        paymentObject.open()
    }

    const navigate = useNavigate();
    return (<>
        <div style={{ width: '100%', height: '100vh', padding: '2%', backgroundColor: '#FAFAFA' }}>
            <div className={'SidemenuHeader Row'}
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}
            >
                <img
                    src={images.BackArrowBlack}
                    style={{
                        width: '24px',
                        height: '24px',
                        cursor: 'pointer'
                    }}
                    className={'IconStyle'}
                    onClick={() => {
                        navigate('/manageprofile/planDetails');
                    }}
                ></img>
                <p style={{ fontWeight: 800, fontSize: '24px', lineHeight: '30px', color: '#8E53F7', marginLeft: '2%' }}>
                    Cheerio Platform <span style={{ color: 'black' }}>Addons</span>
                </p>
            </div>
            <div>
                <p style={{ fontSize: '16px', marginTop: '5px', color: 'black', marginLeft: '4%', marginBottom: '2%' }}>
                    You can upgrade your Cheerio AI platform using custom add ons as per your requirements. Please note each addon is monthly recurring subscription cycle but you can cancel your subscription anytime.
                </p>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    paddingLeft: '2%',
                    paddingRight: '2%'
                }}
            >
                <div className="w-50 px-2 py-2" style={{}}>
                    <div
                        className=" align-items-center w-100 h-100"
                        style={{
                            borderRadius: 16,
                            border: `1px solid ${colors.borderwhite}`,
                            backgroundColor: colors.white01,
                        }}
                    >
                        <div
                            className="d-flex flex-column justify-content-start align-items-left h-100"
                            style={{ paddingBlock: 12, paddingInline: 16 }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <img style={{ width: '62px', height: '62px' }} src={images.addonsLogo}></img>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        paddingLeft: '3%'
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: 'Segoe UI',
                                            fontWeight: 700,
                                            fontSize: '20px',
                                            lineHeight: '25px'
                                        }}
                                    >
                                        Add-ons Bundle
                                        <div
                                            style={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                lineHeight: '20px',
                                                color: '#666666',
                                                marginTop: '2%'
                                            }}
                                        >
                                            Upgrade for existing Cheerio features
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            fontFamily: 'Segoe UI',
                                            fontWeight: 700,
                                            fontSize: '20px',
                                            lineHeight: '25px'
                                        }}
                                    >
                                        $500
                                    </div>
                                </div>
                            </div>
                            <p
                                style={{ paddingInline: 18, margin: '2%', fontFamily: 'Segoe UI', fontWeight: '400', fontSize: '16px', color: '#666666' }}
                            >
                                <ul
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        lineHeight: '20px'
                                    }}
                                >
                                    <li>
                                        Get extra 200 workflow connectors for complex workflow & journeys
                                    </li>
                                    <li>
                                        Get monthly API Calls upto 10000
                                    </li>
                                    <li>
                                        Get 10 extra agents for support
                                    </li>
                                    <li>
                                        Get 5 workspace for WABA account (manage 5 WABA account)

                                    </li>
                                </ul>
                            </p>
                            <CheerioButton
                                borderStyle={{
                                    width: '90%',
                                    border: 0,
                                    marginBottom: '4%',
                                    marginTop: '4%',
                                    marginLeft: '4.5%'
                                }}
                                textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
                                btnText={localdata?.addOnSubscriptionActive ? 'Subscribed' : 'Subscribe Addon'}
                                backColor={localdata?.addOnSubscriptionActive ? colors.success03 : colors.primary03}
                                disabled={localdata?.addOnSubscriptionActive ? true : false}
                                onclick={() => {
                                    displayRazorpay('Addons');
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="w-50 px-2 py-2" style={{}}>
                    <div
                        className=" align-items-center w-100 h-100"
                        style={{
                            borderRadius: 16,
                            border: `1px solid ${colors.borderwhite}`,
                            backgroundColor: colors.white01,
                        }}
                    >
                        <div
                            className="d-flex flex-column justify-content-start align-items-left h-100"
                            style={{ paddingBlock: 12, paddingInline: 16 }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <img style={{ width: '62px', height: '62px' }} src={images.emailLogo}></img>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        paddingLeft: '3%'
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: 'Segoe UI',
                                            fontWeight: 700,
                                            fontSize: '20px',
                                            lineHeight: '25px'
                                        }}
                                    >
                                        Email Credits
                                        <div
                                            style={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                lineHeight: '20px',
                                                color: '#666666',
                                                marginTop: '2%'
                                            }}
                                        >
                                            Upgrade for existing Cheerio features
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            fontFamily: 'Segoe UI',
                                            fontWeight: 700,
                                            fontSize: '20px',
                                            lineHeight: '25px'
                                        }}
                                    >
                                        $120
                                    </div>
                                </div>
                            </div>
                            <p
                                style={{ paddingInline: 18, margin: '2%', fontFamily: 'Segoe UI', fontWeight: '400', fontSize: '16px', color: '#666666' }}
                            >
                                <ul
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        lineHeight: '20px'
                                    }}
                                >
                                    <li>
                                        You will get extra 100,000 credits per month for your email campaigns.
                                    </li>
                                    <li>
                                        You have to pay $120 of recurring amount every month.
                                    </li>
                                    <li>
                                        You can cancel this subscription anytime you want.
                                    </li>
                                    <li>
                                        Services will be stopped right away in terms of no-payments or delayed payments
                                    </li>
                                </ul>
                            </p>
                            <CheerioButton
                                borderStyle={{
                                    width: '90%',
                                    border: 0,
                                    marginBottom: '0%',
                                    marginTop: '0.35%',
                                    marginLeft: '4.5%'
                                }}
                                textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
                                btnText={localdata?.emailSubscriptionActive ? 'Subscribed' : 'Subscribe Addon'}
                                backColor={localdata?.emailSubscriptionActive ? colors.success03 : colors.primary03}
                                disabled={localdata?.emailSubscriptionActive ? true : false}
                                onclick={() => {
                                    displayRazorpay('Email');
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>
    )
}

export default Addons;