import React, { useEffect, useState } from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CheerioButton from '../../../Components/CheerioButton';
import { QRCode } from 'react-qrcode-logo';
import { getQRLinkClicks } from '../../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const QRModal = ({ setIsQRModalOpen }) => {

    const authtoken = useSelector((state) => state.main.auth_token);

    const [QRLink, setQRLink] = useState('');
    const [ResultExists, setResultExists] = useState(false);
    const [count, setCount] = useState(null);

    const getlinkclicksapicall = () => {
        const regex = /\/([^\/]+)$/;
        if (!QRLink) {
            toast.error(`URL is empty`);
            return;
        }

        const code = QRLink.match(regex);
        if (!code) {
            toast.error(`URL is invalid`);
            return;
        }

        let data = {
            code: code ? code[1] : null
        };
        // let data = {
        //     code: QRLink.split('/')[1],
        // };
        let token = authtoken;
        console.log("data being sent ===> ", data);
        getQRLinkClicks(data, token).then((res) => {
            if (res?.flag) {
                setResultExists(true);
                setCount(res?.count);
            } else {
                toast.error('Unexpected error occurred, please try again later', { position: 'top-center' });
            }
        })
    }

    const ResultItems = ({ title, value }) => {
        return (
            <>
                <div className='qr_result_item_container'>
                    <p className='value_text'>
                        {value}
                    </p>
                    <p className='title_text'>
                        {title}
                    </p>
                </div>
            </>
        )
    }

    return (
        <>
            <div className='DarkBg' onClick={() => { }}>
                <div className='Center'>
                    <div className='qr_modal_container'
                        // className='d-flex flex-column justify-content-start align-items-center'
                        // style={{
                        //     width: 500,
                        //     maxHeight: '70vh',
                        //     backgroundColor: colors.white01,
                        //     borderRadius:16,
                        //     zIndex:1,
                        //     border:`1px solid ${colors.borderwhite}`,
                        //     paddingBlock:20,
                        //     paddingInline:30,
                        // }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <div className='qr_modal_container__header'
                        // className='d-flex flex-row justify-content-between align-items-center w-100' 
                        // style={{paddingBlockEnd:16,borderBottom:`1px solid ${colors.borderwhite}`}}
                        >
                            <h2
                            // style={{
                            //     padding:0,
                            //     margin:0,
                            //     fontSize:20,
                            //     fontWeight:700,
                            //     color: colors.black,
                            // }}
                            >
                                {'Track QR code'}
                            </h2>
                            <img
                                src={images.CMClose}
                                // style={{height:24,width:24,objectFit:'contain',cursor:'pointer'}}
                                onClick={() => { setIsQRModalOpen(false) }}
                            />
                        </div>

                        <InputGroup className="" style={{ width: '90%', marginBlock: 20 }}>
                            <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black, marginBlock: 4 }}>
                                {'Enter your WhatsApp QR link here'}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="wa.cheerio.in/xyzabc"
                                className="w-100 btncustom"
                                style={{ borderRadius: 8 }}
                                value={QRLink}
                                onChange={(e) => {
                                    setQRLink(e.target.value);
                                }}
                            />
                        </InputGroup>

                        {(ResultExists) &&
                            <div className='qr_modal_container__result'
                            // className='d-flex flex-column justify-content-between align-items-center' 
                            // style={{marginBlock:16,width:'90%'}}
                            >
                                <h2
                                // style={{
                                //     padding:0,
                                //     margin:0,
                                //     fontSize:20,
                                //     fontWeight:700,
                                //     color: colors.black,
                                //     width:'100%',
                                // }}
                                >
                                    {'Results'}
                                </h2>
                                <div className='results_row'
                                // className='d-flex flex-row justify-content-start align-items-center w-100' style={{marginBlock:16}}
                                >
                                    {/* <ResultItems 
                            title={'Link Clicks'}
                            value={'2400456'}
                        /> */}
                                    <ResultItems
                                        title={'QR Scans'}
                                        value={count}
                                    />
                                </div>
                            </div>
                        }



                        <CheerioButton
                            borderStyle={{ borderWidth: 0, marginBlockStart: 16, paddingInline: 30, paddingBlock: 8 }}
                            textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
                            btnText={(ResultExists) ? 'Track another' : 'Track'}
                            backColor={colors.primary03}
                            // icon={images.downloadWhite}
                            onclick={() => {
                                if (ResultExists) {
                                    setResultExists(false);
                                    setCount(null);
                                    setQRLink('');
                                } else {
                                    getlinkclicksapicall()
                                }
                            }}
                            disabled={QRLink ? false : true}
                        />


                    </div>
                </div>
            </div>
        </>
    )
}

export default QRModal