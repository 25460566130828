import React, { useEffect, useState } from 'react';
import './../styles.css';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { ShopifyCreateContactApi, ShopifyDownloadContactApi } from '../../../Services';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NewLoader } from '../../../Components/SpinnerLoader';

const CreateContactModal = ({ setOpenCCModal }) => {
  const [labelName, setLabelName] = useState('');
  const [days, setDays] = useState('');
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const localdata = useSelector((state) => state.main.localdata);
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);

  useEffect(() => {
    if (days) {
      console.log('days value ===> ', days);
    }
  }, [days]);
  useEffect(() => {
    if (labelName) {
      console.log('labelName value ===> ', labelName);
    }
  }, [labelName]);

  const OKtoProceed = labelName && days;

  useEffect(() => {
    if (OKtoProceed) {
      console.log('It is OK to Proceed');
      console.log('Final labelName ===> ', labelName);
      console.log('Final days ===> ', days);
    }
  }, [OKtoProceed]);

  const ShopifyCreateContactsAPIcall = () => {
    let data = {
      user_id: localdata?._id,
      days: days,
      label: labelName,
    };
    let token = authtoken;
    setIsLoading(true);
    ShopifyCreateContactApi(data, token).then((res) => {
      if (res.flag) {
        console.log('successfully created contact list ===> ', res);
        setSuccess(true);
      } else {
        toast.error('error create contact list');
        setFailure(true);
      }
    });
    setIsLoading(false);
  };

  const ShopifyDownloadContactsAPIcall = () => {
    let token = authtoken;
    setIsLoading(true);
    ShopifyDownloadContactApi(token).then((res) => {
      if (res.flag) {
        console.log('successfully Downloaded contact list ===> ', res);
        setSuccess(true);
      } else {
        toast.error('error Download contact list');
        setFailure(true);
      }
    });
    setIsLoading(false);
  };

  return (
    <>
      <div className="DarkBg" onClick={() => { }}>
        {isLoading ?
          <div
            className="d-flex flex-column align-items-center"
            style={{ marginTop: '30%', height: '100%', width: '100%', overflowY: 'hidden' }}
          >
            <NewLoader></NewLoader>
            <p style={{ fontWeight: '700', fontSize: '18px' }}>Processing your request</p>
            <p style={{ fontWeight: '500', fontSize: '16px' }}>
              This can take few minutes. If your contact list is larger than 1000. Sit back
              and relax, we will notify you once done.
            </p>
            <CheerioButton
              borderStyle={{
                marginBlock: 8,
                borderColor: colors.primary03,
                width: '25%',
                paddingBlock: 10,
                marginInlineEnd: 16,
              }}
              textStyle={{
                fontSize: 14,
                fontWeight: 600,
                color: colors.white,
              }}
              btnText={'Back to request'}
              backColor={colors.primary03}
              onclick={() => {
                // navigate('/creator/contacts/labels');
              }}
            />
          </div> :
          <div className="Center">
            <div
              className="d-flex flex-column justify-content-between align-items-center"
              style={{
                width: 600,
                height: 570,
                backgroundColor: colors.white01,
                borderRadius: 16,
                zIndex: 0,
                border: `1px solid ${colors.borderwhite}`,
                paddingBlock: 30,
                paddingInline: 30,
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {success ? (
                <>
                  <div></div>
                  <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <img
                      src={images.CATSuccess}
                      style={{ height: 160, width: 160, objectFit: 'contain' }}
                    ></img>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 24,
                        fontWeight: 600,
                        color: colors.black,
                        marginBlock: 16,
                        textAlign: 'center',
                      }}
                    >
                      {'Contact list saved from shopify customers successfully'}
                    </p>
                  </div>
                  <div className="d-flex flex-row justify-content-center align-items-center w-100">
                    <CheerioButton
                      borderStyle={{
                        marginBlock: 8,
                        borderColor: colors.primary03,
                        width: '40%',
                        paddingBlock: 10,
                        marginInlineEnd: 16,
                      }}
                      textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                      btnText={'See contacts list'}
                      backColor={colors.primary03}
                      onclick={() => {
                        console.log('see contacts clicked . . .');
                        navigate('/creator/importcontacts');
                      }}
                    />
                    <CheerioButton
                      borderStyle={{
                        marginBlock: 8,
                        width: '40%',
                        paddingBlock: 10,
                        marginInlineStart: 16,
                        border: `1px solid ${colors.primary03}`,
                      }}
                      textStyle={{ fontSize: 14, fontWeight: 600, color: colors.primary03 }}
                      btnText={'Back to home'}
                      backColor={colors.white}
                      onclick={() => {
                        console.log('back to home clicked . . .');
                        navigate('/creator');
                      }}
                    />
                  </div>
                </>
              ) : failure ? (
                <>
                  <div></div>
                  <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <img
                      src={images.CATFail}
                      style={{ height: 160, width: 160, objectFit: 'contain' }}
                    ></img>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 24,
                        fontWeight: 600,
                        color: colors.black,
                        marginBlock: 16,
                        textAlign: 'center',
                      }}
                    >
                      {
                        'Failed to save contact list from shopify customers at this time. Please try again later.'
                      }
                    </p>
                  </div>
                  <CheerioButton
                    borderStyle={{
                      marginBlock: 8,
                      width: '40%',
                      paddingBlock: 10,
                      marginInlineStart: 16,
                      border: `1px solid ${colors.primary03}`,
                    }}
                    textStyle={{ fontSize: 14, fontWeight: 600, color: colors.primary03 }}
                    btnText={'Back to Customers'}
                    backColor={colors.white}
                    onclick={() => {
                      console.log('back to home clicked . . .');
                      setOpenCCModal(false);
                    }}
                  />
                </>
              ) : (
                <>
                  <div className="d-flex flex-column justify-content-center align-items-start w-100">
                    <div className="d-flex flex-row justify-content-between align-items-center w-100">
                      <h2
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: 20,
                          fontWeight: 700,
                          color: colors.black,
                        }}
                      >
                        {'Create a contact list from your Shopify customers'}
                      </h2>
                      <img
                        src={images.CMClose}
                        style={{ height: 32, width: 32, objectFit: 'contain', cursor: 'pointer' }}
                        onClick={() => {
                          setOpenCCModal(false);
                        }}
                      ></img>
                    </div>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        marginBlock: 16,
                        fontSize: 16,
                        fontWeight: 400,
                        color: colors.greys04,
                      }}
                    >
                      {
                        'You can create contact list from your shopify customer data & send them manual campaigns'
                      }
                    </p>
                  </div>

                  <div
                    className="d-flex flex-column justify-content-center align-items-start w-100"
                    style={{ marginBlock: 32 }}
                  >
                    <h4
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 16,
                        fontWeight: 600,
                        color: colors.black,
                      }}
                    >
                      {'You have selected below filter'}
                    </h4>
                    <InputGroup className="btncustom" style={{ width: '50%', marginBlockStart: 32 }}>
                      <InputGroup.Text
                        id=""
                        style={{ fontSize: 12, fontWeight: 600, color: colors.black }}
                      >
                        <img
                          src={images.ANCalendar}
                          style={{ height: 18, width: 18, objectFit: 'contain' }}
                        ></img>
                      </InputGroup.Text>
                      <Form.Select
                        className="btncustom"
                        aria-label="Select duration"
                        style={{ fontSize: 12, fontWeight: 600, color: colors.greys04 }}
                        value={days}
                        onChange={(e) => {
                          setDays(e.target.value);
                        }}
                      >
                        <option>Select duration</option>
                        <option value="1">Today</option>
                        <option value="2">Yesterday</option>
                        <option value="7">Last 7 days</option>
                        <option value="15">Last 15 days</option>
                        <option value="30">Last month</option>
                        <option value="181">Last 6 months</option>
                        <option value="365">Last year</option>
                      </Form.Select>
                    </InputGroup>
                  </div>

                  <div
                    className="d-flex flex-column justify-content-center align-items-start w-100"
                    style={{ marginBlock: 32 }}
                  >
                    <h4
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 16,
                        fontWeight: 600,
                        color: colors.black,
                      }}
                    >
                      {'Label your contact list'}
                    </h4>
                    <InputGroup className="" style={{ marginBlockStart: 16 }}>
                      <Form.Control
                        type="text"
                        placeholder="Enter here"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={labelName}
                        onChange={(e) => {
                          setLabelName(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </div>
                  <div className="d-flex">
                    <CheerioButton
                      borderStyle={{ paddingBlock: 10 }}
                      textStyle={{ fontSize: 12, fontWeight: 600, color: colors.white }}
                      btnText={'Save contact list'}
                      backColor={colors.black}
                      onclick={() => {
                        console.log('save clicked...');
                        ShopifyCreateContactsAPIcall();
                      }}
                      disabled={OKtoProceed ? false : true}
                    />
                    <CheerioButton
                      borderStyle={{ paddingBlock: 10, marginLeft: 20 }}
                      textStyle={{ fontSize: 12, fontWeight: 600, color: colors.white }}
                      btnText={'Download contact csv'}
                      backColor={colors.black}
                      onclick={() => {
                        console.log('download clicked...');
                        ShopifyDownloadContactsAPIcall();
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>}
      </div>
    </>
  );
};

export default CreateContactModal;
