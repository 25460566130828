import React, { useEffect, useRef, useState } from 'react';
import '../styles.css';
import images from '../../../Utils/images';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useSelector } from 'react-redux';
import { deleteResponse, getAllRespones, saveResponse, updateResponse } from '../../../Services';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';

const AddNewComponent = ({
  nick,
  response,
  setNickName,
  setResponse,
  setAddNew,
  saveResponseApi,
}) => {
  return (
    <>
      <div className="d-flex justify-content-between" style={{ padding: '10px', height: '10%' }}>
        <div className="d-flex">
          <img
            id="AddNewResponse"
            style={{ height: '20px', marginRight: '8px', marginTop: '2px', cursor: 'pointer' }}
            src={images.BackArrowBlack}
            onClick={() => setAddNew(false)}
          ></img>
          <p style={{ fontSize: '16px', fontWeight: '600' }}> Add new response</p>
        </div>
        <img
          id="CheckNewResponse"
          style={{ height: '22px', cursor: 'pointer', marginTop: '2px' }}
          src={images.IconCheck}
          onClick={() => {
            if (nick !== '' && response !== '') {
              saveResponseApi();
            } else {
              toast.error('Required field missing', { position: 'top-center' });
            }
          }}
        ></img>
      </div>
      <hr></hr>

      <div style={{ padding: '10px', marginTop: '-18px' }}>
        <div>
          <div className="d-flex justify-content-between">
            <label
              for="exampleFormControlInput1"
              style={{ fontWeight: '600', fontSize: '14px', color: 'black' }}
            >
              Canned Response
            </label>
            <Tooltip title="Give a nickname to your saved responses for your own reference.">
              <img height="20px" src={images.InfoCircle}></img>
            </Tooltip>
          </div>
          <input
            class="form-control shadow-none"
            placeholder="Add Canned Response"
            onChange={(e) => setNickName(e.target.value)}
            value={nick}
            required
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '600', fontSize: '14px', color: 'black' }}
          >
            Response
          </label>
          <textarea
            rows="5"
            class="form-control shadow-none"
            placeholder="Add response"
            value={response}
            onChange={(e) => setResponse(e.target.value)}
            required
          />
        </div>
      </div>
    </>
  );
};

const EditComponent = ({
  nick,
  response,
  setNickName,
  setResponse,
  setEdit,
  updateResponseApi,
  deleteResponseApi,
}) => {
  return (
    <>
      <div className="d-flex justify-content-between" style={{ padding: '10px', height: '10%' }}>
        <div className="d-flex">
          <img
            style={{ height: '20px', marginRight: '8px', marginTop: '2px', cursor: 'pointer' }}
            src={images.BackArrowBlack}
            onClick={() => setEdit(false)}
          ></img>
          <p style={{ fontSize: '16px', fontWeight: '600' }}> Edit response</p>
        </div>
        <div>
          <img
            style={{ height: '22px', cursor: 'pointer', marginTop: '2px', marginRight: '5px' }}
            src={images.DeleteBlack}
            onClick={() => {
              deleteResponseApi();
            }}
          ></img>
          <img
            style={{ height: '22px', cursor: 'pointer', marginTop: '2px' }}
            src={images.IconCheck}
            onClick={() => {
              if (nick !== '' && response !== '') {
                updateResponseApi();
              } else {
                toast.error('Required field missing', { position: 'top-center' });
              }
            }}
          ></img>
        </div>
      </div>
      <hr></hr>

      <div style={{ padding: '10px', marginTop: '-18px' }}>
        <div>
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '600', fontSize: '14px', color: 'black' }}
          >
            Nickname
          </label>
          <input
            class="form-control shadow-none"
            placeholder="Add Nickname"
            onChange={(e) => setNickName(e.target.value)}
            value={nick}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '600', fontSize: '14px', color: 'black' }}
          >
            Response
          </label>
          <textarea
            rows="5"
            class="form-control shadow-none"
            placeholder="Add response"
            onChange={(e) => setResponse(e.target.value)}
            value={response}
          />
        </div>
      </div>
    </>
  );
};

export const SavedResponses = ({ setText, setShow }) => {
  const [addNew, setAddNew] = useState(false);
  const [data, setData] = useState(true);
  const [edit, setEdit] = useState(false);
  const token = useSelector((state) => state.main.auth_token);
  const [nickName, setNickName] = useState('');
  const [response, setResponse] = useState('');
  const [values, setValues] = useState([]);
  const [responseId, setResponseId] = useState('');

  const getAllResponsesApiCall = () => {
    getAllRespones(token).then((res) => {
      if (res?.flag) {
        setData(true);
        setValues(res?.data);
      } else {
        toast.error(res);
      }
    });
  };

  const saveResponseApiCall = () => {
    const data = {
      nickName: nickName,
      response: response,
    };
    saveResponse(token, data).then((res) => {
      if (res?.flag) {
        setAddNew(false);
        toast.success('Response saved', { position: 'top-center' });
      } else {
        toast.error(res);
      }
    });
  };

  const updateResponseApiCall = () => {
    const data = {
      nickName: nickName,
      response: response,
    };
    updateResponse(token, responseId, data).then((res) => {
      if (res?.flag) {
        setEdit(false);
        toast.success('Message updated', { position: 'top-center' });
      } else {
        toast.error(res);
      }
    });
  };

  const deleteResponseApiCall = () => {
    deleteResponse(token, responseId).then((res) => {
      if (res?.flag) {
        setEdit(false);
        toast.success('Deleted Successfully', { position: 'top-center' });
      } else {
        toast.error(res);
      }
    });
  };

  useEffect(() => {
    if (addNew) {
      setNickName('');
      setResponse('');
    }
  }, [addNew]);

  useEffect(() => {
    getAllResponsesApiCall();
  }, [addNew, edit]);

  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <div class="responses">
        {data ? (
          addNew ? (
            <AddNewComponent
              nick={nickName}
              response={response}
              setNickName={setNickName}
              setResponse={setResponse}
              setAddNew={setAddNew}
              saveResponseApi={saveResponseApiCall}
            ></AddNewComponent>
          ) : edit ? (
            <EditComponent
              nick={nickName}
              response={response}
              setNickName={setNickName}
              setResponse={setResponse}
              setEdit={setEdit}
              updateResponseApi={updateResponseApiCall}
              deleteResponseApi={deleteResponseApiCall}
            ></EditComponent>
          ) : (
            <>
              <div
                className="d-flex justify-content-between"
                style={{ padding: '10px', height: '10%' }}
              >
                <p style={{ fontSize: '16px', fontWeight: '600' }}>Saved Replies</p>
                <img
                  id="AddNewResponse"
                  style={{ height: '22px', cursor: 'pointer' }}
                  src={images.IconAdd}
                  onClick={() => {
                    setAddNew(true);
                  }}
                ></img>
              </div>
              <hr></hr>
              <div
                class="response-content"
                style={{
                  padding: '10px',
                  marginTop: '-17px',
                  fontSize: '14px',
                  height: '82%',
                  overflowY: 'auto',
                  scrollbarWidth: 'thin',
                  scrollbarColor: 'white',
                }}
              >
                {values.map((value) => (
                  <div
                    id={value?._id}
                    onClick={(e) => {
                      e.stopPropagation();
                      setText(value?.response);
                      setShow(false);
                    }}
                    style={{ marginBottom: '10px', padding: '10px' }}
                  >
                    <div className="d-flex justify-content-between">
                      <div>
                        <p style={{ fontWeight: '600', height: '10px', marginBottom: '10px' }}>
                          {value.nickName}
                        </p>
                        <p style={{ color: 'grey' }}>{value.response}</p>
                      </div>
                      <div>
                        <img
                          style={{ height: '20px', cursor: 'pointer' }}
                          src={images.EditSender}
                          onClick={(e) => {
                            e.stopPropagation();
                            setResponse(value.response);
                            setNickName(value.nickName);
                            setResponseId(value._id);
                            setEdit(true);
                          }}
                        ></img>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )
        ) : addNew ? (
          <AddNewComponent
            nick={nickName}
            response={response}
            setNickName={setNickName}
            setResponse={setResponse}
            setAddNew={setAddNew}
            saveResponseApi={saveResponseApiCall}
          ></AddNewComponent>
        ) : (
          <>
            <div
              className="d-flex justify-content-between"
              style={{ padding: '10px', height: '10%' }}
            >
              <p style={{ fontSize: '16px', fontWeight: '600' }}>Saved Replies</p>
              <img
                style={{ height: '22px', cursor: 'pointer' }}
                src={images.IconAdd}
                onClick={() => {
                  setAddNew(true);
                }}
              ></img>
            </div>
            <hr></hr>

            <div
              className="d-flex align-items-center flex-column"
              style={{ fontSize: '14px', paddingTop: '15%' }}
            >
              <img style={{ height: '15%', width: '15%' }} src={images.IconReply}></img>
              <p style={{ textAlign: 'center', width: '70%' }}>
                Save responses for frequently asked questions to reply quickly
              </p>
              <p
                style={{ fontWeight: '600', color: 'blue', cursor: 'pointer' }}
                onClick={() => {
                  setAddNew(true);
                }}
              >
                Add New Response
              </p>
            </div>
          </>
        )}
      </div>
    </ClickAwayListener>
  );
};

export const MatchResponses = ({ text, values, setText, event }) => {
  const [selected, setSelected] = useState('');
  const [arr, setArr] = useState(values);

  let data = values.filter((value) => {
    if (text === '/') {
      return true;
    }

    if (value?.response.includes(text) || value?.nickName.includes(text)) {
      return value;
    } else {
      return false;
    }
  });

  useEffect(() => {
    if (selected === '' && text !== '') {
      setSelected(data[0]);
    } else if (data.length !== arr.length) {
      setArr(data);
      setSelected(data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (arr) {
      const newVal = arr.indexOf(selected);
      if (event.code === 'Tab') {
        event.preventDefault();
        setText(selected?.response);
      } else if (event.code === 'ArrowDown') {
        event.preventDefault();

        if (arr[newVal + 1]) {
          setSelected(arr[newVal + 1]);
          document
            .getElementById('cr' + arr[newVal + 1]?._id)
            ?.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (event.code === 'ArrowUp') {
        event.preventDefault();

        if (arr[newVal - 1]) {
          setSelected(arr[newVal - 1]);
          document
            .getElementById('cr' + arr[newVal - 1]?._id)
            ?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }, [event]);

  if (!data.length) {
    return null;
  }

  return (
    <div class="responses-matched">
      <div className="d-flex justify-content-between" style={{ padding: '10px', height: '10%' }}>
        <p style={{ fontSize: '16px', fontWeight: '600' }}>Saved Replies</p>
        {/* <img
          style={{ height: '22px', cursor: 'pointer' }}
          src={images.IconAdd}
          onClick={() => {
            setAddNew(true);
          }}
        ></img> */}
      </div>
      <p style={{ color: 'grey', fontSize: '12px', marginLeft: '10px' }}>
        Press tab to select message
      </p>
      <hr></hr>
      <div
        class="response-content"
        style={{
          padding: '10px',
          marginTop: '-17px',
          fontSize: '14px',
          height: '82%',
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: 'white',
        }}
      >
        {data.length &&
          data.map((value) => {
            return (
              <div
                id={'cr' + value?._id}
                key={value?._id}
                onClick={() => {
                  setText(value?.response);
                  data = [];
                }}
                style={{
                  borderRadius: '12px',
                  marginBottom: '2px',
                  backgroundColor: selected == value ? '#f1f1f1' : '',
                  paddingLeft: '10px',
                  paddingTop: '5px',
                }}
              >
                <div className="d-flex justify-content-between" style={{}}>
                  <div>
                    <p style={{ fontWeight: '600', height: '10px', marginBottom: '10px' }}>
                      {value.nickName}
                    </p>
                    <p style={{ color: 'grey' }}>{value.response}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
