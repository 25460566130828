import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { contactlabellistWithoutCountAPI } from '../../../Services';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material';
import SearchWithDropdown from './SearchWithDropdown';

const Events = ['addToLabel'];

const AddToLabelModal = ({ data, setData }) => {
    const [allLabel, setAllLabel] = useState([]);
    const [eventType, setEventType] = useState('');
    const [labelName, setLabelName] = useState('');
    const [saveContact, setSaveContact] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [displayedLabels, setDisplayedLabels] = useState([]);
    const [loading, setLoading] = useState(false);
    const authtoken = useSelector((state) => state.main.auth_token);

    useEffect(() => {
        getLabelList();

        if (data?.eventType) {
            setEventType(data.eventType);
        } else {
            setData({
                ...data,
                eventType: Events[0],
            });
        }

        if (data?.labelName) {
            setLabelName(data.labelName);
        }

        if (data?.saveContact) {
            setSaveContact(data.saveContact);
        }
    }, []);

    const getLabelList = () => {
        contactlabellistWithoutCountAPI(authtoken).then((res) => {
            if (res?.flag) {
                setAllLabel(res.data.v1.map((l) => l.label_name));
                setDisplayedLabels(res.data.v1.map((l) => l.label_name).slice(0, 25));
            } else {
                console.error('error', res);
            }
        });
    };

    const loadMore = () => {
        if (searchTerm) return;
        setLoading(true);
        setTimeout(() => {
            const nextBatch = allLabel.slice(displayedLabels.length, displayedLabels.length + 25);
            setDisplayedLabels([...displayedLabels, ...nextBatch]);
            setLoading(false);
        }, 1000);
    };

    const filteredLabels = allLabel.filter(label =>
        label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const labelsToDisplay = searchTerm ? filteredLabels : displayedLabels;

    return (
        <div className="w-100 flex-column justify-content-start align-items-start" style={{ height: isOpen ? 400 : 100, overflowY: 'hidden' }}>
            <h4 className="" style={{ marginBottom: '10px', fontWeight: '600', display: 'flex', gap: 8, paddingTop: '2%' }}>
                Select a label
            </h4>

            <div style={{ position: 'relative', width: '100%' }}>
                <StyledTextField
                    fullWidth
                    size="small"
                    placeholder="Select"
                    value={labelName}
                    onClick={() => setIsOpen(!isOpen)}
                    InputProps={{
                        readOnly: true,
                    }}
                    style={{
                        paddingBottom: '2%'
                    }}
                />
                {isOpen && (
                    <SearchWithDropdown
                        items={labelsToDisplay}
                        searchPlaceholder="Search Label"
                        onSelect={(label) => {
                            setLabelName(label);
                            setData({ ...data, labelName: label });
                            setIsOpen(false);
                        }}
                        loading={loading}
                        onScroll={loadMore}
                        searchTerm={searchTerm}
                        onSearchChange={setSearchTerm}
                    />
                )}
            </div>
        </div>
    );
};

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#CCC',
            borderRadius: '8px',
        },
        '&:hover fieldset': {
            borderColor: '#999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#666',
        },
    },
});

export default AddToLabelModal;
