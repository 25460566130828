import { CancelAutotopup } from '../../../Services';
import { useState, useEffect } from 'react';
import images from '../../../Utils/images';
import colors from "../../../Utils/colors";
import CheerioButton from '../../../Components/CheerioButton';
import { Modal, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';


const AutomandateCancel = ({ formatDate, fetchautorechargedetailsapicall }) => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        console.log("open the model")
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        fetchautorechargedetails()
        fetchautorechargedetailsapicall()
        setOpen(false);
        toast.success('Your Automandate is Deactivated.', { position: 'top-center' });
    };

    const fetchautorechargedetails = async () => {
        try {
            const res = await CancelAutotopup(authtoken);
            if (res?.status) {
                console.log("status", res.data.status)
            }

        } catch (err) {
            console.log('Error fetching notifications', err);
        }
    };
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '900px',
        bgcolor: 'background.paper',
        borderRadius: 5,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '70vw',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        // justifyContent: 'space-between',
                        // width: '70vw',

                    }}
                >
                    <img
                        style={{ width: '80px', height: '80px', marginRight: 8 }}
                        src={images.walletLogo}
                    ></img>
                    <div>
                        <h4
                            style={{
                                marginTop: '24px',

                            }}>
                            Cheerio Wallet
                        </h4>
                        <p
                            style={{
                                // marginTop:'-4px',
                                fontWeight: 400,
                                display: 'flex',
                                fontSize: 14,
                                color: '#666666',
                                paddingLeft: '1%',
                                width: '101%',

                            }}
                        >
                            {formatDate}
                        </p>
                    </div>

                </div>

                <button
                    style={{
                        height: '41px',
                        padding: '10px',
                        gap: '8px',
                        borderRadius: '8px',
                        // border: '1px 0px 0px 0px',
                        opacity: '0px',
                        // backgroundColor: '#FFFFFF',
                        backgroundColor: 'transparent',
                        border: '1px solid var(--Responses-Failure, #FF0000)',
                        width: '175px',
                        marginTop: '24px',
                        borderColor: '#FF0000',
                        fontFamily: 'Segoe UI',
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '21.28px',
                        textAlign: 'center',
                        color: '#FF0000',
                    }}
                    onClick={handleOpen}
                >
                    Cancel AutoMandate

                </button>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, width: 700, height: 160, }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: '10px',
                            marginTop: '16px'
                        }}
                    >
                        <h3 id="child-modal-title"> Are you sure to Cancel Automandate?</h3>
                        <img
                            src={images?.CMClose}
                            style={{ width: '25px', cursor: 'pointer' }}
                            onClick={handleClose}
                        ></img>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: '30px',
                            gap: '40px',
                        }}
                    >
                        <CheerioButton
                            borderStyle={{
                                borderColor: colors.black,
                                width: '100%',
                                height: '6%',
                                marginBottom: '5px',
                            }}
                            textStyle={{ fontSize: 14, fontWeight: 600, color: colors.black }}
                            btnText={'Close'}
                            backColor={'transparent'}
                            onclick={handleClose}
                        />
                        <CheerioButton
                            borderStyle={{
                                borderColor: 'transparent',
                                width: '100%',
                                height: '6%',
                                marginBottom: '5px',
                            }}
                            textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                            btnText={'Delete'}
                            backColor={colors.red49}
                            onclick={() => {

                                handleDelete()
                            }}
                        />
                    </div>
                </Box>
            </Modal>



        </>
    )
}
export default AutomandateCancel;