import { useState } from 'react';

import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import MediaResponse from '../../Keywords/Components/MediaResponse';
import TemplateAction from '../../Keywords/Components/TemplateAction';
import WorkflowAction from '../../Keywords/Components/WorkflowAction';
import SavedResponses from '../../Keywords/Components/SavedResponses';
import AssignAgentAction from '../../Keywords/Components/AssignAgentAction';

const ReplyActionsComponent = ({ object, setStateView }) => {
  const [selectedItem, setSelectedItem] = useState('Saved Responses');
  const [dataObject, setDataObject] = useState(object.data ?? {});
  const [options] = useState([
    {
      keywords: object.keywords,
      criteria: object.criteria,
    },
  ]);

  const MediaValidItems = ['Image', 'Video', 'Document', 'image', 'video', 'document'];
  const saveSuccessAction = () => {
    setStateView('list');
  };

  return (
    <>
      <div className="replyactions_container">
        <div className="replyactions_container__sidebar">
          <SidebarItem
            imageSrc={selectedItem === 'Saved Responses' ? images.KWCannedSel : images.KWCanned}
            itemText={'Saved Responses'}
            onclickAction={() => setSelectedItem('Saved Responses')}
            selectedItem={selectedItem}
          />
          <SidebarItem
            imageSrc={
              selectedItem === 'Image' || selectedItem === 'image'
                ? images.KWImageSel
                : images.KWImage
            }
            itemText={'Image'}
            onclickAction={() => setSelectedItem('Image')}
            selectedItem={selectedItem}
          />
          <SidebarItem
            imageSrc={
              selectedItem === 'Video' || selectedItem === 'video'
                ? images.KWVideoSel
                : images.KWVideo
            }
            itemText={'Video'}
            onclickAction={() => setSelectedItem('Video')}
            selectedItem={selectedItem}
          />
          <SidebarItem
            imageSrc={
              selectedItem === 'Document' || selectedItem === 'document'
                ? images.KWDocumentSel
                : images.KWDocument
            }
            itemText={'Document'}
            onclickAction={() => setSelectedItem('Document')}
            selectedItem={selectedItem}
          />
          <SidebarItem
            imageSrc={selectedItem === 'Template' ? images.KWTemplateSel : images.KWTemplate}
            itemText={'Template'}
            onclickAction={() => setSelectedItem('Template')}
            selectedItem={selectedItem}
          />
          <SidebarItem
            imageSrc={selectedItem === 'Workflow' ? images.KWWorkflowSel : images.KWWorkflow}
            itemText={'Workflow'}
            onclickAction={() => setSelectedItem('Workflow')}
            selectedItem={selectedItem}
          />
          <SidebarItem
            imageSrc={selectedItem === 'Assign chat' ? images.KWAgentSel : images.KWAgent}
            itemText={'Assign chat'}
            onclickAction={() => setSelectedItem('Assign chat')}
            selectedItem={selectedItem}
          />
        </div>
        <div className="replyactions_container__content">
          {selectedItem === 'Saved Responses' ? (
            <>
              <SavedResponses
                object={object}
                options={options}
                dataObject={dataObject}
                setDataObject={setDataObject}
                saveSuccessAction={saveSuccessAction}
              />
            </>
          ) : MediaValidItems.includes(selectedItem) ? (
            <>
              <MediaResponse
                object={object}
                options={options}
                dataObject={dataObject}
                setDataObject={setDataObject}
                selectedItem={selectedItem}
                saveSuccessAction={saveSuccessAction}
              />
            </>
          ) : selectedItem === 'Workflow' ? (
            <>
              <WorkflowAction
                object={object}
                options={options}
                dataObject={dataObject}
                setDataObject={setDataObject}
                saveSuccessAction={saveSuccessAction}
              />
            </>
          ) : selectedItem === 'Assign chat' ? (
            <>
              <AssignAgentAction
                object={object}
                options={options}
                dataObject={dataObject}
                setDataObject={setDataObject}
                saveSuccessAction={saveSuccessAction}
              />
            </>
          ) : selectedItem === 'Template' ? (
            <>
              <TemplateAction
                object={object}
                options={options}
                dataObject={dataObject}
                setDataObject={setDataObject}
                saveSuccessAction={saveSuccessAction}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default ReplyActionsComponent;

const SidebarItem = ({ imageSrc, itemText, onclickAction, selectedItem }) => {
  return (
    <>
      <div
        className="ra_sidebar_item rahover"
        onClick={onclickAction}
        style={{
          backgroundColor:
            (selectedItem === itemText || selectedItem === itemText.toLowerCase()) && '#F5F0FF',
        }}
      >
        <img className="image24" src={imageSrc}></img>
        <h4
          style={{
            color:
              selectedItem === itemText || selectedItem === itemText.toLowerCase()
                ? colors.primary03
                : colors.black,
          }}
        >
          {itemText}
        </h4>
      </div>
    </>
  );
};
