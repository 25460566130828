import React, { useEffect, useRef, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import Alert from 'react-bootstrap/Alert';
import { useDispatch, useSelector } from 'react-redux';
import {
  contactlabellistV2API,
  getContacsTagsList,
  importContactsCSVApi,
  importContactsCSVV2Api,
  saveFbToken,
} from '../../../Services';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import FacebookLogin from 'react-facebook-login';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import './index.scss';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { NewLoader } from '../../../Components/SpinnerLoader';
import { triggerReRender } from '../../ContactModule/Labels/redux';
import { eventsend } from '../../../Config/analyticsFunctions';
import { Tooltip } from '@mui/material';

export function ValidateSpecialCharsOnly(name) {
  if (/^([A-Za-z0-9 _]+)$/.test(name)) {
    return true;
  }
  toast.error('Please remove special characters!', { position: 'top-center' });

  //setIncorrectEmail(true);
  return false;
}

const ICNewModal = ({ setOpenICModal, campaignFlow = false, setLabelName, setAttributeTour }) => {
  const navigate = useNavigate();
  const savebtnscroll = useRef();
  const localdata = useSelector((state) => state.main.localdata);
  const agentdata = useSelector((state) => state.main.agentdata);

  const [showAlert, setShowAlert] = useState(false);
  const [showAPIAlert, setShowAPIAlert] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [addLabel, setAddLabel] = useState(false);
  const [CSVfile, setCSVfile] = useState();
  const [label, setLabel] = useState('');
  const [resmessage, setresmessage] = useState('');
  const csvref = useRef();

  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);

  const [countOfProgress, setCountOfProgress] = useState(0);
  const [dragActive, setDragActive] = useState(false);
  const [showlabel, setshowlabel] = useState(false);
  const [labelList, setLabelList] = useState([]);
  const [success, setSuccess] = useState(false);
  const [scrollSave, setScrollSave] = useState(false);
  const [tags, setTags] = useState('');
  const [tagArr, setTagArr] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [selectedTagsList, setSelectedTagsList] = useState([]);
  const [finalArr, setFinalArr] = useState([]);
  const [labelSet, setLabelSet] = useState(new Set());
  const [inputLabel, setInputLabel] = useState(null);
  // const suggestedTags = ["Premium users" , "High end users" , "Platinum users" , "New users"];

  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [flag, setFlag] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked(true);
  };

  useEffect(() => {
    eventsend('import_contacts_modal_opened_web');
    getlabellistapicall();
    getTagsListAPIcall();
  }, []);

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    matchFrom: 'any',
    stringify: (option) => option.label || option,
  });

  const getTagsListAPIcall = () => {
    let data = {
      _id: localdata?._id,
    };
    let token = authtoken;
    getContacsTagsList(data, token).then((res) => {
      if (res?.flag) {
        setTagsList(res?.data?.getTags);
      } else {
        console.log('errr ===> ', res);
      }
    });
  };

  const onclickaddselected = (item) => {
    const tempArr = tagsList;
    const modifiedArr = tempArr.filter((e) => e?._id !== item._id);
    setSelectedTagsList([...selectedTagsList, item]);
    setTagsList(modifiedArr);
  };

  const onclickremoveselected = (item) => {
    const tempArr = selectedTagsList;
    const modifiedArr = tempArr.filter((e) => e?._id !== item._id);
    setTagsList([...tagsList, item]);
    setSelectedTagsList(modifiedArr);
  };

  const onclickremoveSelfselected = (item) => {
    const tempArr = tagArr;
    const modifiedArr = tempArr.filter((e) => e !== item);
    setTagArr(modifiedArr);
  };

  const getlabellistapicall = () => {
    // let data = {
    //     user_id : _id,
    // };
    let token = authtoken;
    contactlabellistV2API(token).then((res) => {
      if (res.flag) {
        let allLabels = [
          ...res.data.v2.map((vls) => {
            return { label: vls.label_name };
          }),
        ];
        setLabelList(allLabels);
      } else {
        console.log('err===>', res);
      }
    });
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (
      e.dataTransfer.files &&
      e.dataTransfer.files[0] &&
      e.dataTransfer.files[0].type == 'text/csv'
    ) {
      setCSVfile(e.dataTransfer.files[0]);
    } else {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  };

  const importcontactsapiAction = () => {
    importContactsapicall();
  };

  useEffect(() => {
    const tempArr = [...tagArr, ...selectedTagsList.map((e) => e.tag_name)];
    setFinalArr(tempArr);
  }, [tagArr, selectedTagsList]);

  const importContactsapicall = () => {
    let form = new FormData();
    form.append('user_id', localdata?._id);
    form.append('file', CSVfile);
    form.append('privateLabel', isChecked);
    form.append('uploadedUser', agentdata?._id ?? localdata?._id);
    form.append('label', inputLabel?.toLowerCase().trim());
    form.append('tags', JSON.stringify([...tagss]));
    let token = authtoken;
    // importContactsCSVApi(form, token).then((res) => {
    importContactsCSVV2Api(form, token)
      .then((res) => {
        if (res?.flag) {
          eventsend('import_contacts_successfully_saved_web');
          setTimeout(() => {
            setSuccess(true);
            // setTimeout(() => {
            setAddLabel(false);
            setFileUpload(false);
            // navigate('/creator');
            // }, 2000);
            setIsLoading(false);
          }, 1000);

          // setresmessage(res?.message);

          if (campaignFlow) {
            setLabelName(inputLabel?.toLowerCase());
          }
          dispatch(triggerReRender());
          // setOpenICModal(false);

          // window.location.reload();
        } else {
          eventsend('import_contacts_error_web', { error: res?.message });
          setShowAPIAlert(res?.message);
          setTimeout(() => {
            setUploadError(true);
            setIsLoading(false);
          }, 1000);

          //setError(res?.message);
        }
        setLabel('');
      })
      .catch(() => {
        setIsLoading(false);
        setUploadError(true);
      });
  };

  useEffect(() => {
    if (resmessage?.length > 0) {
      setSuccess(true);
      setTimeout(() => {
        setAddLabel(false);
        setFileUpload(false);
        // navigate('/creator');
      }, 2000);
    }
  }, [resmessage]);

  useEffect(() => {
    if (showAPIAlert) {
      setCSVfile(null);
      setAddLabel(false);
      setFileUpload(false);
      setTimeout(() => {
        setShowAPIAlert(false);
      }, 3000);
    }
  }, [showAPIAlert]);

  useEffect(() => {
    if (CSVfile) {
      setFileUpload(!fileUpload);
    }
  }, [CSVfile]);

  useEffect(() => {
    if (CSVfile) {
      const timer = setInterval(() => {
        setCountOfProgress((oldProgress) => {
          if (oldProgress == 100) clearInterval(timer);
          return Math.min(oldProgress + Math.random() * 10, 100);
        });
      }, 200);
    }
  }, [CSVfile]);

  const addnewtags = () => {
    const tagsArray = tags
      .split(',')
      .map((item) => {
        return item.trim();
      })
      .filter((item) => {
        return item !== '';
      });
    setTagArr([...tagArr, ...tagsArray]);
    setTags('');
  };

  const scrolltoSave = () => {
    const element = document.getElementById('scrollsave');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    if (scrollSave) {
      scrolltoSave();
    }
  }, [scrollSave]);

  const responseFacebook = (response) => {
    console.log(response);
  };

  const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    {
      code: 'AE',
      label: 'United Arab Emirates',
      phone: '971',
    },
  ];
  const [currentTag, setCurrentTag] = useState('');
  const [tagss, setTagss] = useState(new Set());
  const handleInputChange = (e) => {
    setCurrentTag(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === ',') {
      e.preventDefault();
      const trimmedTag = currentTag.trim();
      if (trimmedTag !== '' && !tagss.has(trimmedTag)) {
        const newTags = new Set(tagss);
        newTags.add(trimmedTag);
        setTagss(newTags);
        setCurrentTag('');
      }
    }
  };

  const handleFuncClick = () => {
    const trimmedTag = currentTag.trim();
    if (trimmedTag !== '' && !tagss.has(trimmedTag)) {
      const newTags = new Set(tagss);
      newTags.add(trimmedTag);
      setTagss(newTags);
      setCurrentTag('');
    }
  };

  return (
    <>
      <div className="ModalDarkBg" onClick={() => { }}>
        <div className="Center">
          {/* <div style={{ ...Styles.DarkBg }} onClick={() => { }}>
        <div className="" style={{ ...Styles.Center }}> */}
          <div
            className="ic_new_modal_container"
            // className="d-flex flex-column justify-content-between align-items-center"
            // style={{
            //   height: '80vh',
            //   width: 1012,
            //   backgroundColor: colors.white01,
            //   borderRadius: 16,
            //   zIndex: 0,
            //   border: `1px solid ${colors.borderwhite}`,
            //   paddingInline: 24,
            // }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {showAlert && (
              <>
                <Alert
                  variant="danger"
                  onClose={() => {
                    setShowAlert(false);
                  }}
                  dismissible
                  style={{ position: 'absolute', top: 20, zIndex: 1 }}
                >
                  <Alert.Heading>Wrong file format uploaded</Alert.Heading>
                  <p>
                    Please upload only .csv files. Download the sample contacts file to know more.
                  </p>
                </Alert>
              </>
            )}
            {showAPIAlert && (
              <>
                <Alert
                  variant="danger"
                  onClose={() => {
                    setShowAPIAlert(false);
                  }}
                  dismissible
                  style={{ position: 'absolute', top: 20, zIndex: 1 }}
                >
                  <Alert.Heading>{showAPIAlert}</Alert.Heading>
                  {/* <p> */}
                  {/* {showAPIAlert} */}
                  {/* </p> */}
                </Alert>
              </>
            )}

            <div
              className="ic_new_modal_container__header"
            // className="d-flex flex-row justify-content-between align-items-center w-100"
            // style={{
            //   paddingBlock: 16,
            //   borderBottom: `1px solid ${colors.borderwhite}`,
            // }}
            >
              <h2
              // style={{
              //   padding: 0,
              //   margin: 0,
              //   fontSize: 24,
              //   fontWeight: 700,
              //   color: colors.black,
              // }}
              >
                {'Upload Contacts'}
              </h2>
              <div className="d-flex flex-row justify-content-center align-items-center">
                {/* <Link className='d-flex flex-row justify-content-center align-items-center' style={{marginInlineEnd:50,textDecoration:'none'}} to='/sample-contacts-list.csv' target='_blank' download>
                            <img src={images.CSampleDownload} style={{height:24,width:24,objectFit:'contain',marginInlineEnd:8}}></img>
                            <p style={{padding:0,margin:0,fontSize:16,fontWeight:600,color:colors.linkblue}}>
                                {'Download sample file'}
                            </p>
                        </Link> */}
                <img
                  src={images.CMClose}
                  // style={{
                  //   height: 32,
                  //   width: 32,
                  //   objectFit: 'contain',
                  //   cursor: 'pointer',
                  // }}
                  onClick={() => {
                    setOpenICModal(false);
                  }}
                />
              </div>
            </div>
            <div
              className="ic_new_modal_container__body"
            // className="d-flex flex-row justify-content-between align-items-center"
            // style={{
            //   width: '100%',
            //   flex: 1,
            //   overflowY: 'scroll',
            //   marginBlock: 16,
            // }}
            >
              {success ? (
                <>
                  <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
                    <img
                      src={images.CATSuccess}
                      style={{
                        height: 200,
                        width: 200,
                        objectFit: 'contain',
                        marginBlock: 8,
                      }}
                    ></img>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        marginBlockEnd: 24,
                        marginBlockStart: 8,
                        fontSize: 24,
                        fontWeight: 700,
                        color: colors.black,
                      }}
                    >
                      {'Contacts upload successful'}
                    </p>
                    <div
                      className="d-flex flex-row justify-content-center align-items-center w-100"
                      style={{ marginBlock: 20 }}
                    >
                      <CheerioButton
                        borderStyle={{
                          marginBlock: 8,
                          borderColor: colors.primary03,
                          width: '25%',
                          paddingBlock: 10,
                          marginInlineEnd: 16,
                        }}
                        textStyle={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: colors.white,
                        }}
                        btnText={'See contacts'}
                        backColor={colors.primary03}
                        onclick={() => {
                          navigate('/creator/contacts/labels');
                          window.location.reload();
                          setOpenICModal(false);
                        }}
                      />
                      {campaignFlow ? (
                        <CheerioButton
                          borderStyle={{
                            marginBlock: 8,
                            width: '25%',
                            paddingBlock: 10,
                            marginInlineStart: 16,
                            border: `1px solid ${colors.primary03}`,
                          }}
                          textStyle={{
                            fontSize: 14,
                            fontWeight: 600,
                            color: colors.primary03,
                          }}
                          btnText={'Back to campaign'}
                          backColor={colors.white}
                          onclick={() => {
                            setOpenICModal(false);
                            const seenTour = localStorage.getItem('seenTour');
                            if (!seenTour) {
                              setAttributeTour(true);
                              localStorage.setItem('seenTour', 'true');
                            }
                          }}
                        />
                      ) : (
                        <CheerioButton
                          borderStyle={{
                            marginBlock: 8,
                            width: '25%',
                            paddingBlock: 10,
                            marginInlineStart: 16,
                            border: `1px solid ${colors.primary03}`,
                          }}
                          textStyle={{
                            fontSize: 14,
                            fontWeight: 600,
                            color: colors.primary03,
                          }}
                          btnText={'Back to home'}
                          backColor={colors.white}
                          onclick={() => {
                            navigate('/creator');
                            setOpenICModal(false);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </>
              ) : isLoading ? (
                <div
                  className="d-flex flex-column align-items-center"
                  style={{ marginTop: '30%', height: '100%', width: '100%', overflowY: 'hidden' }}
                >
                  <NewLoader></NewLoader>

                  <p style={{ fontWeight: '700', fontSize: '18px' }}>Processing your contacts</p>
                  <p style={{ fontWeight: '500', fontSize: '16px' }}>
                    This can take few minutes. If your contact list is larger than 1000. Sit back
                    and relax, we will send an email to you once done.
                  </p>
                  <CheerioButton
                    borderStyle={{
                      marginBlock: 8,
                      borderColor: colors.primary03,
                      width: '25%',
                      paddingBlock: 10,
                      marginInlineEnd: 16,
                    }}
                    textStyle={{
                      fontSize: 14,
                      fontWeight: 600,
                      color: colors.white,
                    }}
                    btnText={'Back to contacts'}
                    backColor={colors.primary03}
                    onclick={() => {
                      navigate('/creator/contacts/labels');
                      setOpenICModal(false);
                    }}
                  />
                </div>
              ) : uploadError ? (
                <div
                  className="d-flex flex-column align-items-center"
                  style={{ marginTop: '20%', height: '100%', width: '100%', overflowY: 'hidden' }}
                >
                  <img style={{ height: '40%' }} src={images.ErrorImg}></img>

                  <p style={{ fontWeight: '700', fontSize: '18px' }}>Upload Failed</p>
                  <p style={{ fontWeight: '500', fontSize: '16px' }}>
                    Error while uploading contacts please retry or upload after sometime.
                  </p>
                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: '15px', width: '30%' }}
                  >
                    <CheerioButton
                      borderStyle={{
                        borderColor: colors.primary03,
                        width: '40%',
                        borderRadius: '8px',
                      }}
                      textStyle={{
                        fontSize: 14,
                        fontWeight: 600,
                        color: colors.white,
                      }}
                      btnText={'Retry'}
                      backColor={colors.primary03}
                      onclick={() => {
                        // navigate('/creator/contacts/labels');
                        // setOpenICModal(false);
                        importcontactsapiAction();
                      }}
                    />
                    <CheerioButton
                      borderStyle={{
                        borderColor: colors.black,
                        borderRadius: '8px',
                      }}
                      textStyle={{
                        fontSize: 14,
                        fontWeight: 600,
                        color: colors.black03,
                      }}
                      btnText={'Back to contacts'}
                      backColor={colors.white}
                      onclick={() => {
                        navigate('/creator/contacts/labels');
                        setOpenICModal(false);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="info_50"
                  // className="d-flex flex-column justify-content-start align-items-center w-50 my-2"
                  // style={{ height: '100%' }}
                  >
                    {/* <div className='w-100'></div> */}
                    <h4
                      style={{
                        padding: 0,
                        margin: 0,
                        marginBlock: 8,
                        fontSize: 18,
                        fontWeight: 700,
                        color: colors.black,
                        width: '100%',
                      }}
                    >
                      {'How to manage contacts on Cheerio?'}
                    </h4>
                    <div
                      className="d-flex flex-row justify-content-between align-items-start w-100"
                      style={{ marginBlock: 16 }}
                    >
                      <img
                        src={images.HNProfileSetup}
                        style={{
                          height: 24,
                          width: 24,
                          objectFit: 'contain',
                          marginInlineEnd: 8,
                        }}
                      ></img>
                      <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ marginInline: 8, flex: 1 }}
                      >
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                            flexWrap: 'wrap',
                            width: '100%',
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {'Upload your contact list on Cheerio'}
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: colors.black04,
                            flexWrap: 'wrap',
                            width: '100%',
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {'Download sample file in CSV format, add contacts to it and upload.'}
                        </p>
                      </div>
                    </div>
                    <img
                      src={images.CImage0}
                      style={{
                        height: 'auto',
                        width: '100%',
                        objectFit: 'contain',
                        marginBlock: 16,
                      }}
                    ></img>
                    <img
                      src={images.CImage1}
                      style={{
                        height: 200,
                        width: 200,
                        objectFit: 'contain',
                        marginBlock: 16,
                      }}
                    ></img>
                    <div
                      className="d-flex flex-row justify-content-between align-items-start w-100"
                      style={{ marginBlock: 16 }}
                    >
                      <img
                        src={images.HNProfileSetup}
                        style={{
                          height: 24,
                          width: 24,
                          objectFit: 'contain',
                          marginInlineEnd: 8,
                        }}
                      ></img>
                      <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ marginInline: 8, flex: 1 }}
                      >
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                            flexWrap: 'wrap',
                            width: '100%',
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {'Label the file to segment customers for precise targeting'}
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: colors.black04,
                            flexWrap: 'wrap',
                            width: '100%',
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {
                            'For example: New customers, Regular customers, Dropped customers, April customers.'
                          }
                        </p>
                      </div>
                    </div>
                    <img
                      src={images.CImage2}
                      style={{
                        height: 200,
                        width: 200,
                        objectFit: 'contain',
                        marginBlock: 16,
                      }}
                    ></img>
                    <div
                      className="d-flex flex-row justify-content-between align-items-start w-100"
                      style={{ marginBlock: 16 }}
                    >
                      <img
                        src={images.HNProfileSetup}
                        style={{
                          height: 24,
                          width: 24,
                          objectFit: 'contain',
                          marginInlineEnd: 8,
                        }}
                      ></img>
                      <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ marginInline: 8, flex: 1 }}
                      >
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                            flexWrap: 'wrap',
                            width: '100%',
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {'You can add, edit, and delete contacts from the label later'}
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: colors.black04,
                            flexWrap: 'wrap',
                            width: '100%',
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {
                            'Go to the Contacts section from the side menu to manage your contacts and labels.'
                          }
                        </p>
                      </div>
                    </div>
                    <img
                      src={images.CImage3}
                      style={{
                        height: 200,
                        width: 200,
                        objectFit: 'contain',
                        marginBlock: 16,
                      }}
                    ></img>
                  </div>
                  <div
                    className="upload_50"
                  // className="d-flex flex-column jusitfy-content-start align-items-center w-50 my-2"
                  // style={{ height: '100%', paddingInline: 16 }}
                  >
                    {/* Upload drag and drop zone */}
                    {!fileUpload && (
                      <div
                        className="d-flex flex-column justify-content-start align-items-center"
                        style={{
                          width: '100%',
                          backgroundColor: colors.white02,
                          border: `1px dashed ${colors.greys03}`,
                          borderRadius: 16,
                        }}
                        onDragEnter={handleDrag}
                        onDragOver={handleDrag}
                        onDragExit={handleDrag}
                        onDrop={handleDrop}
                        onClick={() => {
                          if (!CSVfile) {
                            csvref.current.click();
                          }
                        }}
                      >
                        <input
                          type="file"
                          style={{ display: 'none' }}
                          ref={csvref}
                          accept=".csv"
                          value=""
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              setCSVfile(file);
                            } else {
                              setCSVfile(null);
                            }
                          }}
                        />
                        <img
                          src={images.ImgUpIcon}
                          style={{
                            width: 140,
                            height: 100,
                            objectFit: 'contain',
                            marginBlock: 24,
                          }}
                        ></img>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            fontSize: 12,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Drag & drop the file here'}
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            fontSize: 12,
                            fontWeight: 600,
                            color: colors.black,
                            marginBlock: 8,
                          }}
                        >
                          {'OR'}
                        </p>
                        <CheerioButton
                          borderStyle={{ paddingBlock: 8, marginBlockEnd: 24 }}
                          textStyle={{
                            fontSize: 12,
                            fontWeight: 600,
                            color: colors.white,
                          }}
                          btnText={'Browse Files'}
                          backColor={colors.black}
                          onclick={() => { }}
                        />
                      </div>
                    )}
                    {/* ---------------------- */}

                    {!fileUpload && (
                      <>
                        <div className="d-flex flex-row justify-content-center align-items-center">
                          <Link
                            className="d-flex flex-row justify-content-center align-items-center"
                            style={{ marginBlock: 16, textDecoration: 'none' }}
                            to="/sample-contacts-list.csv"
                            target="_blank"
                            download
                          >
                            <img
                              src={images.CSampleDownload}
                              style={{
                                height: 24,
                                width: 24,
                                objectFit: 'contain',
                                marginInlineEnd: 8,
                              }}
                            ></img>
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 16,
                                fontWeight: 600,
                                color: colors.linkblue,
                              }}
                            >
                              {'Download sample file'}
                            </p>
                          </Link>
                        </div>
                      </>
                    )}

                    {/* <>
                      <div>
                        <CheerioButton
                          borderStyle={{
                            borderColor: '#1877f2',
                            height: 40,
                            borderRadius: 4,
                            paddingInline: 24,
                          }}
                          textStyle={{ fontSize: 16, fontWeight: 500, color: colors.white }}
                          btnText={'Sync Contacts from Facebook'}
                          backColor={'#1877f2'}
                          // icon={images.WhatsAppWhite}
                          onclick={() => {
                            window.FB.login(
                              function (response) {
                                console.log({
                                  response,
                                });
                                if (response.authResponse) {
                                  let token = authtoken;
                                  //const code = response.authResponse.code;
                                  const fbToken = response.authResponse.accessToken;
                                  saveFbToken({ token: fbToken }, token)
                                    .then((res) => {
                                      if (res?.flag) {
                                        if (!toast.isActive(toastId.current)) {
                                          toast('Saved Token & Contacts Synced', {
                                            position: 'top-center',
                                            toastId: 1,
                                            
                                          });
                                        }
                                      } else {
                                        console.log("Can't save token: ", res);
                                        if (!toast.isActive(toastId.current)) {
                                          toast.error(res?.message, {
                                            position: 'top-center',
                                            toastId: 1,
                                            
                                          });
                                        }
                                      }
                                    })
                                    .catch((e) => {
                                      console.log(e.message);
                                      if (!toast.isActive(toastId.current)) {
                                        toast(e, {
                                          position: 'top-center',
                                          toastId: 1,
                                          
                                        });
                                      }
                                    });
                                  // The returned code must be transmitted to your backend,
                                  // which will perform a server-to-server call from there to our servers for an access token
                                } else {
                                  console.log('User cancelled login or did not fully authorize.');
                                }
                              },
                              {
                                scope:
                                  'pages_show_list,ads_management,leads_retrieval,pages_read_engagement',
                              }
                            );
                          }}
                        />
                      </div>
                    </> */}

                    {/* Uploaded file progress */}
                    {fileUpload && (
                      <div
                        className="d-flex flex-column justify-content-start align-items-center"
                        style={{
                          width: '100%',
                          marginBlock: 24,
                          backgroundColor: colors.white02,
                          border: `1px solid ${colors.greys03}`,
                          borderRadius: 16,
                          padding: 20,
                        }}
                      >
                        <div
                          className="d-flex flex-row justify-content-between align-items-center w-100"
                          style={{ marginBlock: 8 }}
                        >
                          <div
                            className="d-flex flex-row justify-content-start align-items-center"
                            style={{ flex: 1 }}
                          >
                            <img
                              src={images.CFile}
                              style={{
                                height: 24,
                                width: 24,
                                objectFit: 'contain',
                                marginInlineEnd: 8,
                              }}
                            ></img>
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 16,
                                fontWeight: 600,
                                color: colors.black,
                                overflowWrap: 'break-word',
                              }}
                            >
                              {`${CSVfile.name}`}
                            </p>
                          </div>
                          <img
                            src={images.CDelete}
                            style={{
                              height: 24,
                              width: 24,
                              objectFit: 'contain',
                              marginInlineStart: 8,
                            }}
                            onClick={() => {
                              setCSVfile(null);
                              setCountOfProgress(0);
                              setFileUpload(!fileUpload);
                            }}
                          />
                        </div>
                        <ProgressBar
                          variant="customcolor"
                          style={{
                            marginBlock: 4,
                            height: 8,
                            width: 'calc( 100% - 30px)',
                            borderRadius: 4,
                            alignSelf: 'flex-start',
                          }}
                          now={countOfProgress}
                        />
                        {/* <div style={{marginBlock:4,height:4,width:'calc( 100% - 30px)',backgroundColor:colors.primary03,borderRadius:2,alignSelf:'flex-start'}}></div> */}
                        {countOfProgress == 100 && (
                          <>
                            <div
                              className="d-flex flex-row justify-content-start align-items-center w-100"
                              style={{
                                marginBlockStart: 4,
                                marginBlockEnd: 24,
                              }}
                            >
                              <img
                                src={images.HNProfileSetup}
                                style={{
                                  height: 20,
                                  width: 20,
                                  objectFit: 'contain',
                                  marginInlineEnd: 8,
                                }}
                              ></img>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black04,
                                }}
                              >
                                {'Upload completed, add label to continue.'}
                              </p>
                            </div>
                            <div style={{ width: '100%', marginBlock: 16 }}>
                              <h4
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: colors.black,
                                  marginBlock: 4,
                                }}
                              >
                                {'Label Contact list'}
                              </h4>
                              {labelList?.length >= 0 && (
                                <Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  style={{
                                    marginTop: '5px',
                                  }}
                                  filterOptions={filterOptions}
                                  renderInput={(params) => (
                                    <TextField
                                      size={'small'}
                                      placeholder={
                                        'Label this contact list or choose to add to existing label'
                                      }
                                      className={'restylingLabels'}
                                      InputProps={{
                                        notched: false,
                                        style: {
                                          borderRadius: 4,
                                        },
                                      }}
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        // if (!newValue.match(/[%<>\\$'"\[\]]/)) {
                                        // if (
                                        //   !newValue.match(
                                        //     /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\-]/g
                                        //     ///^([A-Za-z0-9 _]+)$/
                                        //   )
                                        if (/^([A-Za-z0-9 _]+)$/.test(newValue)) {
                                          setError('');
                                        } else {
                                          setError('Special characters are not allowed');
                                        }
                                        setInputLabel(newValue);
                                        setFlag(true);
                                      }}
                                      helperText={error}
                                      error={!!error}
                                      {...params}
                                    />
                                  )}
                                  freeSolo={true}
                                  options={labelList}
                                  onInputChange={(event, newInputValue) => {
                                    const trimmedValue = (newInputValue || '').trim();
                                    setInputLabel(trimmedValue);
                                  }}
                                  onChange={(event, newValue) => {
                                    if (newValue?.label === undefined) {
                                      const trimmedValue = (newValue || '').trim();
                                      if (trimmedValue) {
                                        setInputLabel(trimmedValue);
                                        setFlag(true);
                                      }
                                    } else {
                                      setInputLabel(newValue.label);
                                      setFlag(false);
                                      setIsChecked(false);
                                    }
                                  }}
                                />
                              )}
                            </div>
                            {(flag || !inputLabel) && (
                              <div
                                style={{
                                  width: '100%',
                                  marginBlock: 16,
                                  display: 'flex',
                                  marginTop: '-1%',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div className="d-flex flex-row" style={{ width: '100%' }}>
                                  <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleChange}
                                    style={{
                                      width: '16px',
                                      height: '16px',
                                    }}
                                  />
                                  <p
                                    style={{
                                      marginTop: '-1%',
                                      paddingLeft: '2%',
                                    }}
                                  >
                                    Make label private
                                  </p>
                                </div>
                                <div
                                  style={{
                                    marginTop: '-1%',
                                    paddingLeft: '2%',
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      'Private labels are only visible to the uploader. No one can access these contacts & neither they will be visible in all contacts'
                                    }
                                  >
                                    <img
                                      src={images.InfoCircle}
                                      style={{
                                        height: 24,
                                        width: 24,
                                        objectFit: 'contain',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            )}

                            {!campaignFlow && (
                              <>
                                {/* <div style={{ width: '100%', marginBlock: 16 }}>
                                  <h4
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      fontSize: 16,
                                      fontWeight: 600,
                                      color: colors.black,
                                      marginBlock: 4,
                                    }}
                                  >
                                    {'Enter Tags'}
                                  </h4>
                                  <div
                                    className="d-flex flex-column justify-content-start align-items-center w-100 px-2"
                                    style={{
                                      border: `1px solid ${colors.greys03}`,
                                      borderRadius: 8,
                                    }}
                                  >
                                    <div
                                      className={'label-lister'}
                                      style={{
                                        marginTop: '10px',
                                        width: '100%',
                                      }}
                                    >
                                      {[...tagss].map((vls) => {
                                        return (
                                          <span>
                                            {vls}{' '}
                                            <IoIosCloseCircleOutline
                                              onClick={() => {
                                                setTagss((vlss) => {
                                                  let newSet = new Set(vlss);
                                                  newSet.delete(vls);
                                                  return newSet;
                                                });
                                              }}
                                            />
                                          </span>
                                        );
                                      })}
                                    </div>
                                    <InputGroup className="my-2">
                                      <Form.Control
                                        type="text"
                                        placeholder={`Use comma(,) to seperate tags`}
                                        className="btncustom"
                                        style={{ borderRadius: 8 }}
                                        value={currentTag}
                                        onChange={handleInputChange}
                                        onKeyPress={handleKeyPress}
                                      />
                                      <Button
                                        variant="outline-secondary"
                                        className="btncustom tickcustom"
                                        style={{
                                          backgroundColor: colors.primary03,
                                        }}
                                        onClick={handleFuncClick}
                                      >
                                        <img
                                          src={images.CircleCheck}
                                          style={{
                                            height: 20,
                                            width: 20,
                                            objectFit: 'contain',
                                          }}
                                        ></img>
                                      </Button>
                                    </InputGroup>
                                  </div>
                                </div>

                                <p
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: colors.greys04,
                                    width: '100%',
                                    marginBlock: 8,
                                  }}
                                >
                                  {'Suggested Tags'}
                                </p>

                                <div
                                  className="d-flex flex-row justify-content-start align-items-center w-100"
                                  style={{ flexWrap: 'wrap' }}
                                >
                                  {tagsList &&
                                    tagsList.map((item) => {
                                      return (
                                        <>
                                          <div
                                            key={item._id}
                                            className="d-flex justify-content-center align-items-center"
                                            style={{
                                              paddingInline: 16,
                                              paddingBlock: 6,
                                              borderRadius: 4,
                                              backgroundColor: colors.borderwhite,
                                              marginInlineEnd: 16,
                                              marginBlock: 6,
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                              //onclickaddselected(item);
                                              setTagss((vls) => {
                                                let newSet = new Set(vls);
                                                newSet.add(item.tag_name);
                                                return newSet;
                                              });
                                            }}
                                          >
                                            <p
                                              style={{
                                                padding: 0,
                                                margin: 0,
                                                fontSize: 12,
                                                fontWeight: 400,
                                                color: colors.greys04,
                                              }}
                                            >
                                              {item?.tag_name}
                                            </p>
                                          </div>
                                        </>
                                      );
                                    })}
                                </div> */}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    )}
                    {/* ---------x----------x----------- */}

                    {/* label popup */}
                    {showlabel && (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between align-items-center"
                          style={{
                            position: 'fixed',
                            zIndex: 4,
                            width: '30%',
                            height: 320,
                            backgroundColor: colors.white01,
                            top: 150,
                            borderRadius: 16,
                            border: `1px solid ${colors.borderwhite}`,
                            paddingBlock: 15,
                            paddingInline: 30,
                          }}
                        >
                          <div
                            className="d-flex flex-row justify-content-between align-items-center w-100"
                            style={{ marginBlock: 8 }}
                          >
                            <h4
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 16,
                                fontWeight: 700,
                                color: colors.black,
                              }}
                            >
                              {'Labels'}
                            </h4>
                            <img
                              src={images.CreatorClose}
                              style={{
                                height: 20,
                                width: 20,
                                objectFit: 'contain',
                              }}
                              onClick={() => {
                                setshowlabel(false);
                              }}
                            ></img>
                          </div>

                          <div
                            className="d-flex flex-column justify-content-start align-items-start w-100"
                            style={{
                              flex: 1,
                              marginBlock: 8,
                              overflowY: 'scroll',
                            }}
                          >
                            {labelList &&
                              labelList.map((item, index) => {
                                const randomcolor = ['#FEAF52', '#E50000', '#039600', '#8E53F7'];
                                const numberindex = Math.round((index + 1) % 4);
                                return (
                                  <>
                                    <div
                                      key={`key${item?._id}`}
                                      onClick={() => {
                                        setLabel(item?.label);
                                        setTimeout(() => {
                                          setshowlabel(false);
                                          setScrollSave(true);
                                        }, 500);
                                      }}
                                      className="d-flex flex-row justify-content-center align-items-center"
                                      style={{
                                        marginBlock: 8,
                                        paddingBlock: 4,
                                        paddingInline: 16,
                                        border: `1px solid ${randomcolor[numberindex]}`,
                                        borderRadius: 8,
                                        backgroundColor:
                                          label == item?.label
                                            ? randomcolor[numberindex]
                                            : colors.white,
                                        maxWidth: '90%',
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                          color:
                                            label == item?.label
                                              ? colors.white
                                              : randomcolor[numberindex],
                                          margin: 0,
                                          padding: 0,
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          wordBreak: 'break-all',
                                        }}
                                      >
                                        {item?.label}
                                      </p>
                                    </div>
                                  </>
                                );
                              })}
                          </div>

                          <InputGroup className="" style={{ marginBlock: 8 }}>
                            <Form.Control
                              type="text"
                              placeholder="Add new label"
                              className="btncustom"
                              style={{}}
                              value={label}
                              onChange={(e) => {
                                setLabel(e.target.value);
                              }}
                              onKeyDown={(event) => {
                                if (event.key == 'Enter') {
                                  setshowlabel(false);
                                  setScrollSave(true);
                                }
                              }}
                            />
                            <Button
                              variant="outline-secondary"
                              className="btncustom tickcustom"
                              style={{ backgroundColor: colors.primary03 }}
                              onClick={() => {
                                setshowlabel(false);
                                setScrollSave(true);
                              }}
                            >
                              <img
                                src={images.CircleCheck}
                                style={{
                                  height: 20,
                                  width: 20,
                                  objectFit: 'contain',
                                }}
                              ></img>
                            </Button>
                          </InputGroup>
                        </div>
                      </>
                    )}
                    {/* -------x---------x----------- */}
                    {CSVfile && inputLabel && inputLabel !== '' && (
                      <CheerioButton
                        borderStyle={{
                          marginBlock: 8,
                          borderWidth: 0,
                          width: '100%',
                          paddingBlock: 10,
                        }}
                        textStyle={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: colors.white,
                        }}
                        btnText={'Save'}
                        id={'scrollsave'}
                        backColor={colors.primary03}
                        onclick={() => {
                          if (error === '') {
                            eventsend('import_contacts_save_button_clicked_web');
                            setIsLoading(true);
                            importcontactsapiAction();
                          }
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                width: '100%',
                height: 1,
                backgroundColor: colors.darkgrey,
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ICNewModal;

const Styles = {
  DarkBg: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    width: '100vw',
    height: '100vh',
    // flex:1,
    zIndex: 1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'fixed',
  },
  Center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
};
