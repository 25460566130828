import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, CardImg, Card, Dropdown } from 'react-bootstrap';
import images from '../../../Utils/images';
import colors from '../../../Utils/colors';
import { getImagePath } from '../../../Utils';
import Helper from '../../../Utils/Helper';
import { V2AgentGet, downloadRepliesCsv, toggleStatus } from '../../../Services';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import CheerioButton from '../../../Components/CheerioButton';
import { MenuItem, Select, Tooltip } from '@mui/material';
import '../styles.css';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import DelinkModal from './DelinkModal';
const BANNER = 'https://i.imgur.com/CaKdFMq.jpg';

export function TimeToIst(time) {
  let formattedTime = new Date(time).toLocaleString('en-GB', {
    timeZone: 'Asia/Kolkata',
    hour: '2-digit',
    minute: 'numeric',
    hour12: true,
  });
  if (formattedTime.split(':')[0] === '00') {
    formattedTime = formattedTime.replace('00', '12');
  }

  return formattedTime;
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    id="DropdownToggle"
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();

      onClick(e);
    }}
  >
    {children}
    <Tooltip title={'Menu'}>
      <img src={images.ThreeDot} style={{ height: 30, width: 30 }} />
    </Tooltip>
  </a>
));

function InstagramInbox({
  list,
  setSelectedIndex,
  selectedItem,
  setSelectedItem,
  waData,
  profileImg,
  isChatLoad,
  hasNext,
  page,
  setPage,
  filters,
  setFilters,
  setInboxList,
  getInboxListing,
  agentData,
  totalChats,
  mobileChangeScreen,
  setChatData,
  setSearchInbox,
  removeFilterAction,
}) {
  const [showFilters, setShowFilters] = useState(false);
  const [showDistributeLeads, setShowDistributeLeads] = useState(false);
  //const [agentData, setAgentData] = useState();
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const [showMailCSV, setShowMailCSV] = useState(false);
  const [email, setEmail] = useState();
  const hasSeenPopup = localStorage.getItem('hasSeenDistributionPopup');
  const [showDistributePopUp, setShowDistirbutePopUp] = useState(!hasSeenPopup);
  const [delinkModal, setDelinkModal] = useState(false);

  const handleClose = () => {
    localStorage.setItem('hasSeenDistributionPopup', 'true');
    setShowDistirbutePopUp(false);
  };

  const downloadCsvApiCall = () => {
    const data = {
      showCTWA: false,
      email: email,
      filters,
    };
    downloadRepliesCsv(authtoken, data).then((res) => {
      // if (res.status == '200') {
      //   // console.log('return policy',res);

      //   const url = window.URL.createObjectURL(new Blob([res.data]));
      //   const link = document.createElement('a');
      //   link.href = url;
      //   link.setAttribute('download', `exported-replies.csv`);
      //   document.body.appendChild(link);
      //   link.click();
      //   toast.success('File downloaded', { position: 'top-center' });
      // }
      if (res?.flag) {
        toast.success(res?.result);
      } else {
        toast.error('error downloading csv', res);
      }
    });
  };
  // const handleChange = (e, convId) => {
  //   const data = {
  //     convId: convId,
  //     status: e.target.value,
  //   };
  //   toggleStatus(authtoken, data).then((res) => {
  //     if (res?.flag) {
  //       setInboxList([]);
  //       getInboxListing(1);
  //     } else {
  //       toast.error(res?.message, { position: 'top-center' });
  //     }
  //   });
  // };

  // useEffect(() => {
  //   document
  //     .getElementById(selectedItem?._id)
  //     ?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  // }, [list]);

  let check = false;
  if (
    filters?.general?.length > 0 ||
    filters?.status?.length > 0 ||
    filters?.agents?.length > 0 ||
    filters?.showCtwa ||
    Object.values(filters?.day).every((x) => x !== null)
  ) {
    check = true;
  }
  //console.log('chat list res  ', check);
  // const inboxList = useMemo(()=>list,[list]);
  return (
    <div style={{ backgroundColor: colors.white, height: '92%' }}>
      {!isChatLoad ? (
        <div
          style={{
            flex: 1,
            display: 'flex',
            height: `100%`,
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <p
            style={{ margin: 0, fontSize: 14, color: colors.greys04 }}
          >{`Your chat will\nappear here`}</p>
        </div>
      ) : (
        <>
          <div style={{}}>
            <div
              className="d-flex justify-content-between"
              style={{
                padding: '8px',
                position: 'relative',
                // borderBottom: `1px solid ${colors.borderwhite}`
              }}
            >
              <div style={styles.row}>
                {/* <CardImg
                  src={he.encode(getImagePath(profileImg))}
                  style={{ height: 34, width: 34, borderRadius: 17 }}
                /> */}
                <h3
                  style={{
                    color: colors.black,
                    padding: '10px',
                    fontWeight: '700',
                    fontSize: '24px',
                  }}
                >
                  {localdata?.metaConfig?.instagramPageName}
                  {/* WhatsApp */}
                </h3>
                <Tooltip title="Copy Username">
                  <img
                    src={images.Copy}
                    height={22}
                    width={22}
                    style={{
                      cursor: 'pointer',
                      alignSelf: 'flex-start',
                      marginTop: '12px',
                      paddingLeft: 0,
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(localdata?.metaConfig?.instagramPageName);
                      toast.success('Copied to clipboard');
                    }}
                  ></img>
                </Tooltip>
                <br />
                {/* <p style={styles.numbertitle}>{waData?.phone_number}</p> */}
              </div>
              <div>
                <span
                  style={{
                    color: colors.darkgrey,
                    fontSize: '14px',
                    fontWeight: '600',
                    position: 'absolute',
                    left: '5%',
                    top: '75%',
                  }}
                >
                  Instagram Username
                </span>
              </div>

              <div style={{ padding: '10px' }}>
                {/* <CardImg
                  id="FilterMenu"
                  src={showFilters || check ? images.fillFilter : images.filter}
                  onClick={() => setShowFilters(!showFilters)}
                  style={{ height: 20, width: 20, marginRight: '10px', cursor: 'pointer' }}
                /> */}
                {/* <Tooltip title={'Distribute Leads'}>
                  <CardImg
                    src={images.distribute}
                    onClick={() => setShowDistributeLeads(true)}
                    style={{ height: 20, width: 20, marginRight: '10px', cursor: 'pointer' }}
                  />
                </Tooltip>

                <CardImg
                  src={images.download}
                  onClick={() => setShowMailCSV(true)}
                  style={{ height: 18, width: 18, marginRight: '10px', cursor: 'pointer' }}
                /> */}
                <Dropdown>
                  <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setDelinkModal(true)}>
                      Delink Account
                    </Dropdown.Item>
                  </Dropdown.Menu>
                  {/* <Dropdown.Menu> */}
                  {/* <Dropdown.Header
                      style={{ color: 'black', fontWeight: '600', fontSize: '16px' }}
                    ></Dropdown.Header> */}
                  {/* <Dropdown.Item
                      onClick={() => {
                        setShowDistributeLeads(true);
                      }}
                      className="d-flex"
                    > */}{' '}
                  {/* <img
                        height={18}
                        width={18}
                        style={{ marginRight: '5px' }}
                        src={images.distribute}
                      ></img> */}
                  {/* Distribute Leads
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setShowMailCSV(true);
                      }}
                    > */}
                  {/* <img height={15} src={images.download} style={{ marginRight: '5px' }}></img>{' '}  */}
                  {/* Export Report */}
                  {/* </Dropdown.Item> */}
                  {/* </Dropdown.Menu> */}
                </Dropdown>
              </div>
            </div>
            {/* {showDistributePopUp && list.length > 0 && !check && (
              <div
                className="d-flex justify-content-between"
                style={{
                  marginTop: '-11px',
                  position: 'absolute',
                  width: '20vw',
                  backgroundColor: '#333',
                  color: '#fff',
                  left: '37%',
                  borderRadius: '0px 8px 8px 8px',
                  padding: '10px',
                }}
              >
                <p style={{ fontSize: '12px' }}>
                  {' '}
                  Now you can distribute all your chats between agent by clicking on above button.
                </p>
                <IoMdCloseCircleOutline
                  style={{ width: '20px', cursor: 'pointer' }}
                  onClick={handleClose}
                />
              </div>
            )} */}
            {/* {showMailCSV && (
              <MailRepliesModal
                show={showMailCSV}
                setShow={setShowMailCSV}
                mailApiCall={downloadCsvApiCall}
                email={email}
                setEmail={setEmail}
              ></MailRepliesModal>
            )} */}
            {/* {showFilters && (
              <Filters
                setShowFilters={setShowFilters}
                filters={filters}
                setFilters={setFilters}
                agents={agentData}
                setInboxList={setInboxList}
                // getInboxListing={getInboxListing}
                setPage={setPage}
                setChatData={setChatData}
                removeFilterAction={removeFilterAction}
              ></Filters>
            )} */}
          </div>
          <div
            style={{
              backgroundColor: colors.white,
              overflowY: 'auto',
              scrollbarWidth: 'thin',
              height: '100%',
            }}
          >
            <div style={{ backgroundColor: colors.white }}>
              <div
                style={{
                  display: 'flex',
                  backgroundColor: colors.white,
                  flexDirection: 'column',
                  padding: '12px',
                  marginBottom: '10px',
                }}
              >
                {/* <InputGroup className="my-2">
                  <InputGroup.Text
                    style={{
                      borderRightWidth: 0,
                      backgroundColor: 'transparent',
                      paddingInline: 8,
                    }}
                  >
                    <IoIosSearch size={16} />
                  </InputGroup.Text>
                </InputGroup> */}
                {list?.length > 0 &&
                  list.map((_item, _i) => {
                    const isSelected = selectedItem?._id == _item?._id;
                    //console.log('i', _item?.agent[0]?.name);

                    return (
                      <div
                        id={_item?._id}
                        className="conversation"
                        onClick={() => {
                          setSelectedItem(_item);
                          mobileChangeScreen('chatview');
                        }}
                        style={{
                          ...styles.rowItem,
                          backgroundColor: isSelected ? colors.grey2 : colors.white,

                          borderRadius: '8px',
                          marginBottom: '10px',

                          // borderBottom: '1px solid #B3B3B3',
                        }}
                        key={_item?._id}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            marginLeft: '',
                          }}
                        >
                          <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div
                              className="d-flex justify-content-between"
                              style={{ width: '100%' }}
                            >
                              {' '}
                              <div className="d-flex" style={{ minWidth: '40%' }}>
                                <p
                                  style={{
                                    // margin: 0,
                                    fontWeight: '600',
                                    fontSize: 16,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '100%',
                                  }}
                                >
                                  {_item?.clientName.substring(0, 18)}
                                </p>
                                {_item?.waReferral?.source_id && (
                                  <p
                                    style={{
                                      border: '1px solid black',
                                      height: '100%',
                                      fontSize: '10px',
                                      width: '40%',
                                      borderRadius: '12px',
                                      textAlign: 'center',
                                      paddingTop: '4px',
                                      marginLeft: '10px',
                                    }}
                                  >
                                    {'CTWA'}
                                  </p>
                                )}
                              </div>
                              <div
                                className={'d-flex justify-content-end'}
                                style={{ width: 'fit-content' }}
                              >
                                {_item?.unreadCount && !isSelected ? (
                                  <p
                                    style={{
                                      fontWeight: 'normal',
                                      fontSize: 11,
                                      color: colors.white,
                                      backgroundColor: '#9357FF',
                                      height: 25,
                                      marginRight: '4px',
                                      borderRadius: 50,
                                      textAlign: 'center',
                                      paddingTop: 4,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                    }}
                                  >
                                    {_item?.unreadCount}
                                  </p>
                                ) : (
                                  <></>
                                )}
                                {_item?.updatedTime && (
                                  <p
                                    style={{
                                      marginTop: 3,
                                      marginLeft: _item?.unread_count ? '5px' : '',
                                      fontWeight: 'normal',
                                      fontSize: 12,
                                      color: '#666666',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {TimeToIst(_item?.updatedTime).toUpperCase()}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div style={{ padding: '0px' }}>
                              {/* <Select
                                value={_item?.status ?? 'Unresolved'}
                                onChange={(e) => {
                                  handleChange(e, _item?._id);
                                }}
                                sx={
                                  (_item?.status ? _item?.status : 'Unresolved') == 'Unresolved'
                                    ? {
                                        borderRadius: '47px',
                                        height: '22px',
                                        width: '100%',
                                        color: 'white',
                                        fontSize: '10px',
                                        backgroundColor: '#FF0000',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          border: 'none',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                          border: 'none',
                                        },
                                        '& .MuiSvgIcon-root': {
                                          color: 'white',
                                        },
                                      }
                                    : {
                                        height: '22px',
                                        color: 'white',
                                        width: '100%',
                                        borderRadius: '47px',
                                        fontSize: '10px',
                                        backgroundColor: '#04B100',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          border: 'none',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                          border: 'none',
                                        },
                                        '& .MuiSvgIcon-root': {
                                          color: 'white',
                                        },
                                      }
                                }
                              >
                                <MenuItem value="Unresolved"> Unresolved</MenuItem>
                                <MenuItem value="Archived"> Resolved</MenuItem>
                              </Select> */}
                            </div>
                          </div>
                          {/* <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              border:"2px solid red"
                            }}
                          >
                            {_item?.image ? (
                              <CardImg
                                src={images.photoSelect}
                                style={{ height: 10, width: 10, marginRight: 5 }}
                              />
                            ) : (
                              <></>
                            )}
                            <p
                              style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: 200,
                                color: '#666666',

                                overflow: 'hidden',
                              }}
                            >
                              {_item?.text ? _item?.text : _item?.image ? 'image' : ''}
                            </p>
                          </div> */}

                          <div
                            className="d-flex justify-content-between"
                            style={{ marginTop: '4px' }}
                          >
                            {/* {_item?.time && (
                              <p
                                style={{
                                  marginTop: 0,
                                  fontWeight: 'normal',
                                  fontSize: 13,
                                  color: '#666666',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  width: 200,
                                  overflow: 'hidden',
                                }}
                              >
                                {TimeToIst(_item?.time)}
                              </p>
                            )} */}
                            <p
                              style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: 200,
                                color: '#666666',
                                overflow: 'hidden',
                              }}
                            >
                              {_item?.lastText ? _item?.lastText : _item?.image ? 'image' : ''}
                            </p>
                            {
                              <p
                                style={{
                                  marginTop: 0,
                                  fontWeight: 'normal',
                                  fontSize: 12,
                                  color: '#666666',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  alignSelf: 'flex-end',
                                }}
                              >
                                {_item?.agent?.name ?? 'Unassigned'}
                              </p>
                            }
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {hasNext ? (
                  <div className="d-flex justify-content-center" style={{ marginBottom: '20px' }}>
                    <CheerioButton
                      btnText={'Load More'}
                      textStyle={{ color: colors.black }}
                      backColor={colors.white}
                      borderStyle={{ width: '70%' }}
                      onclick={() => {
                        setPage(page + 1);

                        //getInboxListing();
                      }}
                    />
                  </div>
                ) : (
                  <div className="d-flex justify-content-center" style={{ marginTop: '5px' }}>
                    No more chats found
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {/* <DistributedLeads
        show={showDistributeLeads}
        setShow={setShowDistributeLeads}
        agents={agentData}
        totalChats={totalChats}
        getInboxListing={getInboxListing}
        setInboxList={setInboxList}
      ></DistributedLeads> */}
      <DelinkModal delinkModal={delinkModal} setDelinkModal={setDelinkModal} />
    </div>
  );
}

InstagramInbox.propTypes = {};

export default InstagramInbox;

const styles = {
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 50,
    paddingLeft: 16,
    backgroundColor: colors.white,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  numbertitle: { marginLeft: 12, color: colors.black, fontWeight: 'bolder' },
  searchBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: 12,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'auto',
    height: 36,
    paddingLeft: 10,
    paddingRight: 10,
  },
  rowItem: {
    padding: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
};
