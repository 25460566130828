import React, { useCallback, useEffect, useState } from 'react'
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import images from '../../../Utils/images';
import { getImagePath } from '../../../Utils';
import CustomHandle from './CustomHandle';

const handleStyle = {backgroundColor: '#C10808',height:8,width:8,borderRadius:4};

const EndFlowNode = ({data, isConnectable}) => {
  return (
    <>
    <div className='d-flex flex-column justify-content-between align-items-center' style={{border:`1px solid ${colors.borderwhite}`,borderRadius:16,backgroundColor:colors.white01,width:250}}>
        <CustomHandle type='target' position={Position.Left} isConnectable={isConnectable} style={handleStyle} />
        <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{paddingInline:16,paddingBlock:8,backgroundColor:'#C10808',borderTopLeftRadius:16,borderTopRightRadius:16}}>
            <div style={{height:4,width:'20%',borderRadius:2,backgroundColor:colors.white,marginBlockEnd:4}}></div>
            <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                <p style={{padding:0,margin:0,fontSize:16,fontWeight:700,color:colors.white}}>
                    {'End Flow'}
                </p>
                <img src={images.Edit} style={{height:20,width:20,objectFit:'contain',cursor:'pointer'}}></img>
            </div> 
        </div>
        <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{paddingInline:16,paddingBlockStart:8,paddingBlockEnd:16}}>
            {(!data?.header || data?.header?.mediaFile) && 
            <>
            <div className='d-flex flex-row justify-content-start align-items-center w-100'>
                <p style={{padding:0,margin:0,marginBlock:4,fontSize:10,fontWeight:600,color:colors.black}}>
                    {data?.header?.mediaType ? data?.header?.mediaType : 'Image'}
                </p> 
                <img src={images.DownArrowTip} style={{height:14,width:14,objectFit:'contain',marginInlineStart:8}}></img>
            </div>
            <div className='my-1 d-flex flex-column justify-content-center align-items-center w-100' style={{height:80,border:`2px dashed #dde1e5`,borderRadius:8}}>
                {data?.header?.mediaFile ? (
                    <>
                    {data?.header?.mediaType === 'Image' ? (
                        <>
                        <img src={getImagePath(data?.header?.mediaFile)} style={{height:'100%',width:'100%',objectFit:'contain'}}></img>
                        </>
                    ): data?.header?.mediaType === 'Video' && (
                        <>
                        <video src={getImagePath(data?.header?.mediaFile)} style={{height:'100%',width:'80%',objectFit:'contain'}}></video>
                        </>
                    )}
                    </>
                ):(
                    <>
                    <img src={images.Upload} style={{height:30,width:40,objectFit:'contain'}}></img>
                    <p style={{padding:0,margin:0,fontSize:10,fontWeight:400,color:colors.greys04}}>
                        {'Upload Image'}
                    </p>
                    </>
                )}
                
            </div>
            </>
            }
            

            <p style={{padding:0,margin:0,marginBlock:4,fontSize:10,fontWeight:600,color:colors.black,width:'100%'}}>
                {'Add body text'}
            </p>

            <InputGroup className="my-1">
                <Form.Control as="textarea" rows={4} placeholder={'Enter here'} className='w-100 btncustom'
                style={{borderRadius:8,fontSize:10,fontWeight:400,color:colors.black}}
                // isInvalid={(renewalText.length >= 640)}
                maxLength={640}
                value={data?.body?.message ? data?.body?.message : ''}
                // onChange={onChange}
                />
            </InputGroup>
        </div>
    </div>
    </>
  )
}

export default EndFlowNode