import { useNavigate } from 'react-router-dom';
import { styledSx } from '../ContactModule/Tags/variableDependencies';
import { DataGrid } from '@mui/x-data-grid';
import CheerioButton from '../../Components/CheerioButton';
import colors from '../../Utils/colors';
import { useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import images from '../../Utils/images';
import { getKeywordsTimeFrameList, deleteKeywordsAction } from '../../Services';
import { useSelector } from 'react-redux';
import { FiTrash2, FiEdit2 } from 'react-icons/fi';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import Helper from '../../Utils/Helper';
import TimeTriggerSetup from '../TimeTriggerSetup';

const daysOfWeek = [
  { label: 'M', value: 'mon', },
  { label: 'T', value: 'tue', },
  { label: 'W', value: 'wed', },
  { label: 'T', value: 'thu', },
  { label: 'F', value: 'fri', },
  { label: 'S', value: 'sat', },
  { label: 'S', value: 'sun', },
];

const TimeTriggers = () => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const navigate = useNavigate();
  const MobileWidth = window.innerWidth < 700 ? true : false;
  const [isLoading, setIsLoading] = useState(false);
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;
  const [stateView, setStateView] = useState('list');
  const [currentRow, setCurrentRow] = useState(null);
  const [search, setSearch] = useState('');
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);

  const getKeywordsListAPICall2 = () => {
    setIsLoading(true);
    getKeywordsTimeFrameList(authtoken)
      .then((res) => {
        if (res?.flag) {
          setRows(res?.data?.filter((item) => {
            if (item?.timeFrame?.name && item?.timeFrame?.startTime && item?.timeFrame?.endTime) {
              return item;
            }
          }) ?? []);
          console.log('Time Trigger 2', res?.data);
        } else {
          toast.error(res, { position: 'top-right' });
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (search)
      setRows(
        allRows.filter((row) => row?.timeFrame?.name?.toLowerCase().includes(search?.toLowerCase()))
      );
    else setRows(allRows);
  }, [search]);

  useEffect(() => {
    if (!isPremiumUser) {
      navigate('/creator');
    } else {
      getKeywordsListAPICall2();
    }
  }, [stateView]);

  const onClickAction = (i, row) => {
    if (i == 0) {
      handleEditClick(row);
    } else if (i == 1) {
      handleDeleteClick(row);
    }
  };

  const handleEditClick = (row) => {
    Helper.HelperKeywordActionID = row?._id;
    Helper.HelperEditKeywordAction = true;
    setCurrentRow(row);
    setStateView('edit');
  };

  const handleDeleteClick = (row) => {
    let data = {
      _id: row?._id,
    };
    deleteKeywordsAction(authtoken, data).then((res) => {
      if (res?.flag) {
        toast.success('Keyword action deleted successfully', {

          position: 'top-right',
        });
        getKeywordsListAPICall2();
      } else {
        toast.error('Failed to delete keyword action, please try again later', {

          position: 'top-right',
        });
      }
    });
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 260,
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginLeft: 16 }}>{params?.row?.timeFrame?.name}</div>
          </div>
        );
      },
    },
    {
      field: 'time_frame',
      headerName: 'Time Frame',
      width: 240,
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginLeft: 16 }}>
              {convertToAmPm(params.row?.timeFrame?.startTime) + ' - '}
              {convertToAmPm(params.row?.timeFrame?.endTime)}
            </div>
          </div>
        );
      },
    },
    {
      field: 'days',
      headerName: 'Days',
      flex: 1,
      renderCell: (params) => {
        const selectedDays = params.row?.timeFrame?.days?.length > 0 ? params.row.timeFrame.days : daysOfWeek.map(day => day.value)
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginLeft: 20, display: "flex", alignItems: "center", justifyItems: "space-between", gap: 20 }}>
              {daysOfWeek.map((day, i) => {
                return (
                  <div style={{
                    background: selectedDays?.includes(day.value) ? "#ECE1FF" : "#F5F5F5",
                    borderRadius: "50%",
                    fontWeight: 600,
                    fontSize: 15,
                    color: selectedDays?.includes(day.value) ? "#8E53F7" : "#999999",
                    padding: "5px 10px",
                  }}>
                    {day.label}
                  </div>
                );
              })}
            </div>
          </div >
        );
      },
    },
    {
      field: 'reply_actions',
      headerName: 'Reply Actions',
      width: 320,
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
            <span className="replyAction">{params.row?.action}</span>
          </div>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => {
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              padding: '0 25px',
            }}
          >
            {[<FiEdit2 />, <FiTrash2 />].map((btn, i) => (
              <Tooltip title={i == 0 ? 'Edit' : 'Delete'} key={i}>
                <div style={{ cursor: 'pointer' }} onClick={() => onClickAction(i, params.row)}>
                  {btn}
                </div>
              </Tooltip>
            ))}
          </div>
        );
      },
    },
  ];

  return (
    <div className="KeywordsDiv">
      {stateView === 'list' ? (
        <>
          <div className="flex">
            <div style={{ marginBottom: '12px' }}>
              <p style={{ fontWeight: '700', fontSize: '20px' }}>Time Triggers</p>
              <p
                style={{ color: '#666666', fontSize: '16px', marginTop: '5px', textWrap: 'nowrap' }}
              >
                Note: Time Triggers are used to send messages at a specific time.
              </p>
            </div>
            <div className="search_container">
              <div className="search_upload_row me-3">
                <div className="logged_input">
                  <AiOutlineSearch size={16} />
                  <input
                    className="search_input"
                    type="text"
                    placeholder="Search in Time Triggers"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="search_upload_row">
                <CheerioButton
                  borderStyle={{
                    marginInlineStart: 6,
                    paddingInline: 10,
                    paddingBlock: 7,
                    borderRadius: 8,
                    border: "none"
                  }}
                  textStyle={{
                    fontSize: MobileWidth ? 12 : 14,
                    fontWeight: 600,
                    color: colors.white01,
                  }}
                  btnText={'Add Triggers'}
                  icon={images.IPlusWhite}
                  backColor={colors.darkPurple}
                  onclick={() => {
                    setStateView('create');
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              getRowId={(row) => row._id}
              loading={isLoading}
              rows={rows}
              autoHeight
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              sx={styledSx}
            />
          </div>
        </>
      ) : (
        <div className="flex">
          <TimeTriggerSetup setStateView={setStateView} currentRow={currentRow} />
        </div>
      )}
    </div>
  );
};

export default TimeTriggers;

const convertToAmPm = (timeString) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  const amPm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${amPm}`;
};
