import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import SetupBot from './Setupbot';
import { ToggleButton } from 'react-bootstrap';
import CheerioButton from '../../Components/CheerioButton';
import { Dropdown } from 'react-bootstrap';
import './styles.css';
import images from '../../Utils/images';
import DropzoneCustom from './Dropzone';
import Sidebar from './Sidebar';
import DefaultReplies from './DefaultReplies';
import WorkflowTabels from '../WorkflowTabels';
import { useSelector } from 'react-redux';
import { PremiumOptionModal } from '../../Components/Modal/PremiumOptionModal';
import { eventsend } from '../../Config/analyticsFunctions';
// import { KeywordTriggers } from './KeywordTriggers';
// import  AssignChat  from './AssignChat';
// import ChatFlows  from './ChatFlows';
const ChatBot = () => {
  const location = useLocation();
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;
  const [isFTUser, setIsFTUser] = useState(false);

  const getRemainingDays = (e) => {
    const d = new Date(e);
    const date = new Date();
    let dd = d.toDateString();
    let cd = date.toDateString();
    let diff = Math.floor((Date.parse(dd) - Date.parse(cd)) / 86400000);
    return diff;
  };

  const remainingDays = getRemainingDays(localdata?.free_trail?.end_date);
  useEffect(() => {
    if (remainingDays > 0) {
      setIsFTUser(true);
    }
  }, [localdata]);

  useEffect(() => {
    eventsend('chatbot_pageview_web');
    if (location?.state?.selected) {
      setSelected(location.state.selected);
    }
  }, []);

  const [selected, setSelected] = useState('SetupChatBot');
  const [switchPage, setSwitchPage] = useState(true);

  useEffect(() => {
    console.log('selected changed ---> ', selected);
  }, [selected]);
  return (
    <>
      {!isFTUser && !isPremiumUser && (
        <PremiumOptionModal
          option={'ChatBot'}
          openPremiumModal={true}
          setOpenPremiumModal={() => {}}
        ></PremiumOptionModal>
      )}
      <div
        className="container d-flex flex-row justify-content-between align-items-start h-100"
        style={{ marginLeft: '0', marginRight: '0px', padding: '0px', overflowX: 'hidden' }}
      >
        {/* <Sidebar selected={selected} setSelected={setSelected}></Sidebar> */}
        {selected === 'SetupChatBot' && <SetupBot></SetupBot>}
        {selected === 'SetupWorkflow' && <WorkflowTabels />}
        {/* {selected === "DefaultReplies" && switchPage && <DefaultReplies></DefaultReplies>} */}

        {/* {selected === "KeywordTriggers" &&<KeywordTriggers></KeywordTriggers>}
    {selected === "AssignChat" && <AssignChat></AssignChat>} 
    
    {selected === "ChatFlows" &&<ChatFlows></ChatFlows>}
     */}
      </div>
    </>
  );
};

export default ChatBot;
