import React, { useEffect, useState } from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images'
import CheerioButton from '../../../Components/CheerioButton'
import { Checkbox } from '@mui/material'
import { eventsend } from '../../../Config/analyticsFunctions'

const PreReqWASetup = ({ setIsModalOpen, proceedToSetup }) => {

    const [checklist, setChecklist] = useState([
        { id: 1, status: false, text: 'I have a new SIM card not being used on WhatsApp currently and it can receive Facebook OTP right now' },
        { id: 2, status: false, text: 'I have a Facebook page' },
        { id: 3, status: false, text: 'I’m the admin of my Facebook business manager' },
        { id: 4, status: false, text: 'I’ve a live website with privacy policy' },
        { id: 5, status: false, text: 'My business follows accepted use of policy of WhatsApp (Check here)' },
        { id: 6, status: false, text: 'My business is verified on Facebook' },
        { id: 7, status: false, text: 'I need help verifying my business' },
        { id: 8, status: false, text: 'I ‘ve the necessary docs for verifying my business on Facebook' }
    ])
    const [count, setCount] = useState(0)

    useEffect(() => {
        setCount(checklist.filter(item => item.status).length)
    }, [checklist])

    return (
        <>
            <div className='DarkBg' onClick={() => { }}>
                <div className='Center'>
                    <div className='d-flex flex-column justify-content-between align-items-start'
                        style={{ width: 500, maxHeight: '80vh', backgroundColor: colors.white01, borderRadius: 12, border: `1px solid ${colors.borderwhite}`, paddingInline: 32, paddingBlock: 20, overflowY: 'auto' }}
                        onClick={(e) => { e.stopPropagation() }}
                    >
                        <div className='d-flex flex-row justify-content-between align-items-center w-100 pb-3 mb-2' style={{ borderBottom: `1px solid ${colors.borderwhite}` }}>
                            <h3>
                                {'Pre-requisites for WhatsApp setup'}
                            </h3>
                            <img src={images.CMClose} style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }} onClick={() => { setIsModalOpen(false) }}></img>
                        </div>
                        <p>
                            {'Please check all the requirements before setup'}
                        </p>
                        {checklist.map((item, index) => {
                            return (
                                <>
                                    <div className='d-flex flex-row justify-content-start align-items-center my-1 w-100'>
                                        <Checkbox
                                            checked={checklist[index].status}
                                            onChange={() => {
                                                setChecklist((checklist) => {
                                                    let temp = [...checklist]
                                                    temp[index].status = !temp[index].status;
                                                    return temp
                                                })
                                            }}
                                        />
                                        <p>
                                            {item?.text}
                                        </p>
                                    </div>
                                </>
                            )
                        })}

                        {count < 8 &&
                            <p className='my-2'>
                                {'As you don’t have all the pre-requisites we strongly recommend you to Schedule an onboarding call with Cheerio Team. OR you can proceed if you want? '}
                                <span style={{ color: colors.linkblue, cursor: 'pointer' }} onClick={() => { proceedToSetup(); }}>
                                    {'Proceed to setup'}
                                </span>
                            </p>
                        }

                        <CheerioButton
                            borderStyle={{ width: '100%', marginBlockStart: 16 }}
                            textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white01 }}
                            btnText={count < 8 ? 'Schedule an onboarding call' : 'Next'}
                            backColor={colors.black}
                            // icon={images.Calendar}
                            onclick={() => {
                                if (count < 8) {
                                    eventsend("Schedule_onboarding_call_WhatsApp_setup_pop_up_Clicked");
                                    window.open('https://www.cheerio.in/post/how-to-get-onboarding-support-for-your-cheerio-premium-account', '_blank')
                                    // console.log("schedule onboarding call ...")
                                } else {
                                    eventsend("Next_WhatsApp_setup_pop_up_Clicked");
                                    proceedToSetup();
                                }
                            }}
                        />

                    </div>

                </div>
            </div>
        </>
    )
}

export default PreReqWASetup