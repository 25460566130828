import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getCampaignListAPI, getClientAttributes } from '../../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { options } from 'linkifyjs';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import Form from 'react-bootstrap/Form';

const WebhookTriggerModal = ({ data, setData, prevNode, btnDisabled, setBtnDisabled }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);

  const [isLoading, setIsLoading] = useState(false);
  const [attributesList, setAttributesList] = useState(['name', 'email', 'mobile']);
  const [variableList, setVariableList] = useState([]);
  const [attribute, setAttribute] = useState('');
  const [attributeNewValue, setAttributeNewValue] = useState('');
  const [values, setValues] = useState(data?.values ? data?.values : ['']);
  const [url, setUrl] = useState(data?.url ?? '');
  const [header, setHeader] = useState(
    data?.header ?? {
      key: '',
      value: '',
    }
  );
  const [saveVariables, setSaveVariables] = useState(data?.saveVariables ?? false);

  useEffect(() => {
    setIsLoading(true);
    setBtnDisabled(true);
    getUserAttributesAPICall();
    if (prevNode?.data?.variable) {
      setVariableList((prev) => {
        return [prevNode?.data?.variable];
      });
    }
  }, []);
  useEffect(() => {
    // console.log("data changed ===> ",data);
  }, [data]);

  useEffect(() => {
    setData({ ...data, url: url, values: values, header: header, saveVariables: saveVariables });
    if (url === '' || values?.includes('') || values?.includes(null)) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [url, values, header, saveVariables]);

  const getUserAttributesAPICall = () => {
    let token = authtoken;
    getClientAttributes(token).then((res) => {
      if (res?.flag) {
        setAttributesList((prev) => {
          const arr = res?.data.map((item) => item.attribute);
          return [...prev, ...arr];
        });
        // console.log("user attributes success res ---> ",res);
      } else {
        // console.log("user attributes failed res ---> ",res);
      }
    });
  };

  const handleAutocompleteChange = (index, newValue) => {
    setValues((prev) => {
      const newValues = [...prev];
      newValues[index] = newValue;
      return newValues;
    });
  };

  return (
    <>
      <div className="w-100 h-100 py-3 d-flex flex-column justify-content-start align-items-start">
        <div class="form-group" style={{ width: '100%', marginBlockEnd: '15px' }}>
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '700', fontSize: '14px', color: 'black', marginBlockEnd: '5px' }}
          >
            URL
          </label>

          <input
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            class="form-control shadow-none"
            placeholder="Enter url"
          />
        </div>
        <div class="form-group" style={{ width: '100%', marginBlockEnd: '15px' }}>
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '700', fontSize: '14px', color: 'black', marginBlockEnd: '5px' }}
          >
            Header
          </label>
          <div className="d-flex justify-content-between">
            <input
              value={header?.key}
              onChange={(e) =>
                setHeader((prev) => {
                  return { ...prev, key: e.target.value };
                })
              }
              class="form-control shadow-none"
              placeholder="Enter Key"
              style={{ width: '45%' }}
            />
            <input
              value={header?.value}
              onChange={(e) =>
                setHeader((prev) => {
                  return { ...prev, value: e.target.value };
                })
              }
              class="form-control shadow-none"
              placeholder="Enter Value"
              style={{ width: '45%' }}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#ECE1FF',
            marginBlockEnd: '5px',
            borderRadius: '8px',
            alignItems: 'center', // Align items vertically center
            paddingBlock: '10px',
            paddingInline: '15px',
          }}
        >
          <p
            style={{
              fontWeight: 400,
              fontSize: 14,
              width: '85%',
              color: '#8E53F7',
            }}
          >
            NOTE:-If you dont see your variable name in list just type your variable name with "@".
          </p>
        </div>
        <h4 className="" style={{ marginBottom: '5px', fontWeight: '600' }}>
          {'Choose attributes/variables'}
        </h4>
        {values.map((val, index) => {
          return (
            <div className="w-100 my-2      ">
              <p style={{ fontWeight: '600' }}>Input {index + 1}</p>

              <div className="d-flex w-100 align-items-center">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  style={{
                    width: '100%',
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      name="variable"
                      // value={item.variable}
                      // onChange={(e) => {
                      //   console.log('trigger', e.target.value);
                      //   handleChange(index, e);
                      // }}
                      size={'small'}
                      placeholder={'Type/Select'}
                      className={'restylingLabels'}
                      InputProps={{
                        notched: false,
                        style: {
                          borderRadius: 4,
                        },
                      }}
                      {...params}
                    />
                  )}
                  value={val}
                  onInputChange={(e, newValue) => handleAutocompleteChange(index, newValue)}
                  onChange={(e, newValue) => handleAutocompleteChange(index, newValue)}
                  options={[...variableList, ...attributesList].map((val) => `${val}`)}
                />
                <img
                  src={images.DeleteBlack}
                  style={{
                    height: 24,
                    width: 20,
                    objectFit: 'contain',
                    marginInlineStart: 8,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (index == 0) {
                      toast.error(
                        'Cannot delete first condition, atleast one condition is mandatory',
                        {}
                      );
                    } else {
                      setValues((prev) => {
                        let temp = [...prev];
                        temp.splice(index, 1);
                        console.log('temp --- ', temp);
                        return temp;
                      });
                    }
                  }}
                ></img>
              </div>
            </div>
          );
        })}
        <div className="d-flex justify-content-center w-100">
          <CheerioButton
            borderStyle={{ marginBlockStart: 16, width: '100%' }}
            textStyle={{
              color: colors.black,
              fontWeight: '600',
            }}
            btnText={'Add More'}
            icon={images.IconAdd}
            backColor={colors.white}
            onclick={() => {
              console.log('node delete clicked');
              setValues((prev) => {
                let temp = [...prev];
                temp.push('');
                return temp;
              });
            }}
          />
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center w-100 mb-4 mt-2">
        <Form.Check
          // disabled={disabled}
          type="checkbox"
          className="btncustom"
          style={{ outline: 'none', boxShadow: 'none' }}
          // value={skipReply ? true : false}
          checked={saveVariables}
          label="Save response in variable"
          onChange={() => {
            setSaveVariables(!saveVariables);
          }}
        />
      </div>
    </>
  );
};

export default WebhookTriggerModal;
