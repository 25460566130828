import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import { getImagePath } from '../../Utils';
import { getUserCategoriesData } from '../../Services';
import SpinnerLoader from '../../Components/SpinnerLoader';
import { useNavigate } from 'react-router-dom';
import { eventsend } from '../../Config/analyticsFunctions';
import { persistor } from '../../Redux/store';
import ReactGA from 'react-ga4';

const MyProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profilepic = useSelector((state) => state.main.user_pic);
  const username = useSelector((state) => state.main.username);
  const gender = useSelector((state) => state.main.gender);
  const age = useSelector((state) => state.main.age);
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);

  const [loading, setLoading] = useState(false);
  const [userCatData, setUserCatData] = useState([]);

  useEffect(() => {
    setLoading(true);
    ReactGA.send({ hitType: 'pageview', page: '/myprofile' });
    getUserCategoriesCall();
  }, []);

  const getUserCategoriesCall = () => {
    let id = _id;
    let token = authtoken;
    // console.log("11111111111",id)
    // console.log("22222222222",token)
    getUserCategoriesData(id, token).then((res) => {
      if (res.flag) {
        // console.log(res.data.list)
        setUserCatData(res.data.list);
      } else {
        console.log(res);
      }
    });
  };

  useEffect(() => {
    if (userCatData.length > 0) {
      setLoading(false);
      ReactGA.event('myprofile_page_view_web', { UserId: _id });
      eventsend('myprofile_page_view_web', { UserId: _id });
    } else {
      setTimeout(() => {
        setLoading(false);
        ReactGA.event('myprofile_nointerests_page_view_web', { UserId: _id });
        eventsend('myprofile_nointerests_page_view_web', { UserId: _id });
      }, 1000);
    }
  }, [userCatData]);

  const onclickLogout = () => {
    ReactGA.event('click_logout_web', { UserId: _id });
    eventsend('click_logout_web', { UserId: _id });
    dispatch({ type: 'redirectToLogin', payload: true });
    dispatch({ type: 'logout' });
    navigate('/login');
  };

  const termsofuuse = () => {
    ReactGA.event('click_terms_of_use_web', { UserId: _id });
    eventsend('click_terms_of_use_web', { UserId: _id });
    window.open('https://www.cheerio.in/terms', '_blank', 'noopener,noreferrer');
  };
  const privacypolicy = () => {
    ReactGA.event('click_privacy_policy_web', { UserId: _id });
    eventsend('click_privacy_policy_web', { UserId: _id });
    window.open('https://www.cheeriosocial.com/privacy', '_blank', 'noopener,noreferrer');
  };
  const aboutus = () => {
    ReactGA.event('click_about_us_web', { UserId: _id });
    eventsend('click_about_us_web', { UserId: _id });
    window.open('https://www.cheeriosocial.com/', '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <div className="container-fluid px-0">
        <div
          className="row col-12 col-md-6 col-lg-4 mx-auto fixed-top"
          style={{ backgroundColor: colors.themeBlue, height: '8vh', ...styles.shadow }}
        >
          <div className="d-flex flex-row justify-content-start h-100 w-100 align-items-center">
            <img
              className="col-1"
              src={images.Back}
              style={{ height: '2vh', width: 'auto', objectFit: 'contain' }}
              onClick={() => {
                ReactGA.event('back_to_explore_clicked_web');
                eventsend('back_to_explore_clicked_web');
                navigate('/explore/clubs');
              }}
            />
            <div
              className="col-10 mx-auto text-center"
              style={{ fontSize: 26, fontWeight: 600, color: colors.white }}
            >
              My Profile
            </div>
            <img
              className="col-1"
              src={images.Edit}
              style={{ height: '2vh', width: 'auto', objectFit: 'contain' }}
              onClick={() => {
                ReactGA.event('edit_profile_clicked_web', { UserId: _id });
                eventsend('edit_profile_clicked_web', { UserId: _id });
                navigate('/profile/basic');
              }}
            />
          </div>
        </div>
        <div
          className="row col-12 col-md-6 col-lg-4 mx-auto"
          style={{ minHeight: '92vh', marginTop: '8vh', ...styles.shadow }}
        >
          {loading ? (
            <>
              <div className="d-flex flex-column justify-content-center align-items-center align-self-center">
                <SpinnerLoader style={{ marginTop: 60 }} />
              </div>
            </>
          ) : (
            <>
              <div className="d-flex flex-column justify-content-start px-3 my-3">
                <img
                  src={getImagePath(profilepic)}
                  className="align-self-center text-center mb-2"
                  style={{ height: 160, width: 160, borderRadius: 80, objectFit: 'cover' }}
                />
                {/* <h5 style={{backgroundColor:"#A470FF",color:"#fff",padding:5,paddingLeft:10,borderRadius:4}} className='align-self-center text-start w-100 mb-2 mt-2'>Name</h5> */}
                <div className="mt-2 mb-3 mx-auto" style={{ ...styles.nameTxt }}>
                  {' '}
                  {username}{' '}
                </div>
                <h5
                  style={{
                    backgroundColor: '#A470FF',
                    color: '#fff',
                    padding: 5,
                    paddingLeft: 10,
                    borderRadius: 4,
                  }}
                  className="align-self-center text-start w-100 mb-2"
                >
                  Gender
                </h5>
                <div className="my-2" style={{ ...styles.bodyTxt, paddingLeft: 10 }}>
                  {' '}
                  {gender.charAt(0).toUpperCase() + gender.slice(1)}{' '}
                </div>
                <h5
                  style={{
                    backgroundColor: '#A470FF',
                    color: '#fff',
                    padding: 5,
                    paddingLeft: 10,
                    borderRadius: 4,
                  }}
                  className="align-self-center text-start w-100 mb-2"
                >
                  Age
                </h5>
                <div className="my-2" style={{ ...styles.bodyTxt, paddingLeft: 10 }}>
                  {' '}
                  {age}{' '}
                </div>
                <h5
                  style={{ backgroundColor: '#A470FF', color: '#fff', padding: 5, borderRadius: 4 }}
                  className="align-self-center text-start w-100 my-2 px-3"
                >
                  Interests
                </h5>
                <div
                  className="align-self-center text-start w-100 my-2 "
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    flexWrap: 'wrap',
                  }}
                >
                  {userCatData.map((item) => (
                    <div
                      key={item._id}
                      className="px-3 mx-2 my-2 align-items-center"
                      style={styles.notSelectedBody}
                    >
                      <img
                        className="text-center align-self-center me-2"
                        src={getImagePath(item.picture)}
                        style={{ height: 14, width: 14, objectFit: 'cover' }}
                      />
                      <div className="text-center align-self-center" style={styles.notSelectedText}>
                        {' '}
                        {item.name}
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className="align-self-center text-center mt-4 mb-1"
                  style={styles.botLinksTxt}
                  onClick={() => termsofuuse()}
                >
                  Terms of Use
                </div>
                <div
                  className="align-self-center text-center my-1"
                  style={styles.botLinksTxt}
                  onClick={() => privacypolicy()}
                >
                  Privacy Policy
                </div>
                <div
                  className="align-self-center text-center my-1"
                  style={styles.botLinksTxt}
                  onClick={() => aboutus()}
                >
                  About Us
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center mt-1 mb-3">
                  <img
                    src={images.LogOut}
                    style={{ height: 14, width: 'auto', objectFit: 'contain' }}
                  />
                  <div
                    className="ms-2"
                    style={styles.botLinksTxt}
                    onClick={() => {
                      onclickLogout();
                    }}
                  >
                    Logout
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MyProfile;

const styles = {
  bodyTxt: {
    fontSize: 16,
    fontWeight: 400,
    color: colors.black,
  },
  botLinksTxt: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.textChrome,
  },
  nameTxt: {
    fontSize: 26,
    fontWeight: 600,
    color: colors.black,
  },
  notSelectedBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'start',
    borderColor: colors.themeBlue,
    borderStyle: 'solid',
    borderWidth: 1,
    height: 30,
    borderRadius: 15,
    backgroundColor: colors.grey,
  },
  notSelectedText: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.black,
    alignSelf: 'center',
  },
  shadow: {
    boxShadow: '0px 0px 9px #B2BEB5',
  },
};
