import React, { useRef, useState } from 'react';
import images from '../../../Utils/images';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';
import { setupSms } from '../../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import PhoneInput from 'react-phone-input-2';
import { eventsend } from '../../../Config/analyticsFunctions';

const SMSDLTModal = ({ setIsOpenSMSDLTModal }) => {
  const [registrationID, setRegistrationID] = useState('');
  const [headerID, setHeaderID] = useState('');
  const [SMSHeader, setSMSHeader] = useState('');
  const [SMSID, setSMSID] = useState('');
  const [SMSValue, setSMSValue] = useState('');
  const [DLTdump, setDLTdump] = useState();
  const [businessName, setBusinessName] = useState('');
  const [businessRegID, setBusinessRegID] = useState('');
  const [planSelected, setPlanSelected] = useState('Low-volume standard');
  const [phoneNo, setPhoneNo] = useState('');
  const [emailID, setEmailID] = useState('');
  const [reason, setReason] = useState('');
  const [success, setSuccess] = useState(false);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [country, setCountry] = useState('India');

  const [currentScreen, setCurrentScreen] = useState('Start');

  const fileInputRef = useRef();

  const resetDataAction = () => {
    setRegistrationID('');
    setHeaderID('');
    setSMSHeader('');
    setDLTdump(null);
    setBusinessName('');
    setBusinessRegID('');
    setPhoneNo('');
    setEmailID('');
    setReason('');
  }

  const setupSmsApiCall = () => {
    let form = new FormData();
    form.append('registrationId', registrationID);
    form.append('urnId', headerID);
    form.append('smsHeader', SMSHeader);
    form.append('file', DLTdump);
    form.append('businessName', businessName);
    form.append('businessRegistrationID', businessRegID);
    form.append('phoneNumber', phoneNo);
    form.append('emailID', emailID);
    form.append('reason', reason);
    form.append('selectedPlan', planSelected);
    form.append('country', country)

    if (country !== 'USA') {
      form.delete('selectedPlan');
    }
    setupSms(authtoken, form).then((res) => {
      if (res.flag) {
        setSuccess(true);
      } else {
        toast.error(res?.message, { position: 'top-center' });
      }
    });
  };
  const onChangeRId = (e) => {
    const value = e.target.value;
    const regexp = new RegExp('^[0-9]+$');
    // if value is not blank, then test the regex
    if (value === '' || regexp.test(value)) {
      setRegistrationID(value);
    }
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value)
  }

  const ListingItem = ({ MainText, SubText, Pricing }) => {
    return (
      <>
        <div className='w-100 py-2 d-flex flex-column justify-content-start align-items-start mb-2'>
          <div className='d-flex flex-row justify-content-between align-items-center w-100'>
            <p style={{ fontWeight: 600, color: colors.black, marginBlock: 4 }}>
              {MainText ?? ''}
            </p>
            <p style={{ fontWeight: 600, color: colors.primary03, marginBlock: 4, textAlign: 'end' }}>
              {Pricing ?? ''}
            </p>
          </div>
          <p style={{ marginBlock: 0 }}>
            {SubText ?? ''}
          </p>
        </div>
      </>
    )
  }

  const onchangePhone = (e, d, rv, fv) => {
    // console.log('no  ', e);
    setPhoneNo(e);
  };

  const handlefileupload = async (e) => {
    const file = e.target.files[0];
    console.log("file ---> ", file)
    if (file && file?.type === 'text/csv') {
      setDLTdump(file);
    } else {
      toast.error('Wrong file format uploaded, only CSV format is supported')
    }
    e.target.value = null;
  }

  return (
    <>
      <div className="DarkBg" onClick={() => { }}>
        <div className="Center">
          {success ? (
            <div className="sms_dlt_modal_container">
              <div className="d-flex align-items-center flex-column" style={{ marginTop: '50px' }}>
                <img
                  src={images.CircleCheckGreen}
                  style={{
                    alignItems: 'center',
                    marginInlineEnd: 8,
                  }}
                ></img>
                <div style={{ textAlign: 'center', width: '90%' }}>
                  <p
                    style={{
                      fontWeight: '700',
                      fontSize: '18px',
                      marginTop: '20px',
                      color: 'black',
                      height: '25px',
                    }}
                  >
                    Submitted successfully
                  </p>
                  <p style={{ fontSize: '14px', color: 'black' }}>
                    Your request for SMS Channel setup is under process. We’ll inform you once it is
                    ready to use
                  </p>
                </div>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ marginTop: '23%', width: '100%' }}
              >
                <CheerioButton
                  className="button"
                  borderStyle={{
                    height: '36px',
                    borderRadius: '9px',
                    width: '50%',
                    border: 'none',
                  }}
                  textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
                  btnText={'Close'}
                  backColor={'#8E53F7'}
                  onclick={() => {
                    setIsOpenSMSDLTModal(false);
                  }}
                />
              </div>
            </div>
          ) : (
            <div
              className="sms_dlt_modal_container"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="sms_dlt_modal_container__header">
                <div className='d-flex flex-row justify-content-start align-items-center'>
                  {currentScreen !== 'Start' &&
                    <img src={images.BackArrowBlack}
                      style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer', marginInlineEnd: 8 }}
                      onClick={() => {
                        resetDataAction();
                        if (currentScreen === 'USA_details_2') {
                          setCurrentScreen('USA_details')
                          eventsend("Proceed_SMS_International_with_US_setup_pop_up_Clicked");
                        } else {
                          eventsend('Submit_SMS_International_wo_US_setup_pop_up_Clicked');
                          setCurrentScreen('Start')
                        }
                      }}
                    >
                    </img>
                  }
                  <h2>{'Setup SMS'}</h2>
                </div>
                <img
                  src={images.CMClose}
                  style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                  onClick={() => {
                    setIsOpenSMSDLTModal(false);
                  }}
                />
              </div>
              {currentScreen == 'Start' ? (
                <>
                  <p>
                    {'Select the messaging region you want to proceed with. Please note that pricing will vary based on the region.'}
                  </p>
                  <FormControl style={{ width: '100%' }}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={country}
                      onChange={handleCountryChange}
                      style={{ width: '100%', alignContent: 'center' }}
                    >
                      <FormControlLabel
                        value="India"
                        control={<Radio
                          sx={{
                            color: colors.greys04,
                            '&.Mui-checked': {
                              color: colors.primary03,
                            },
                          }}
                        />}
                        label="Messaging within INDIA"
                        style={{
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderColor: country === 'India' ? colors.primary03 : colors.borderwhite,
                          paddingBlock: 12,
                          paddingInline: 20,
                          borderRadius: 12,
                          width: '80%',
                          marginBlockEnd: 10,
                        }}
                      />
                      <FormControlLabel
                        value="International"
                        control={<Radio
                          sx={{
                            color: colors.greys04,
                            '&.Mui-checked': {
                              color: colors.primary03,
                            },
                          }}
                        />}
                        label="International Messaging excluding US"
                        style={{
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderColor: country === 'International' ? colors.primary03 : colors.borderwhite,
                          paddingBlock: 12,
                          paddingInline: 20,
                          borderRadius: 12,
                          width: '80%',
                          marginBlockEnd: 10,
                        }}
                      />
                      <FormControlLabel
                        value="USA"
                        control={<Radio
                          sx={{
                            color: colors.greys04,
                            '&.Mui-checked': {
                              color: colors.primary03,
                            },
                          }}
                        />}
                        label="International Messaging including US"
                        style={{
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderColor: country === 'USA' ? colors.primary03 : colors.borderwhite,
                          paddingBlock: 12,
                          paddingInline: 20,
                          borderRadius: 12,
                          width: '80%',
                          marginBlockEnd: 10,
                        }}
                      />
                    </RadioGroup>
                  </FormControl>
                  <CheerioButton
                    borderStyle={{ width: '100%', border: `1px solid ${colors.primary03}`, marginBlockStart: 16 }}
                    backColor={colors.primary03}
                    textStyle={{ color: colors.white01 }}
                    btnText={'Proceed'}
                    onclick={() => {
                      eventsend("Proceed_SMS_setup_pop_up_Clicked");
                      if (country === 'India') {
                        setCurrentScreen('India_details');
                      } else if (country === 'International') {
                        setCurrentScreen('International_details')
                      } else if (country === 'USA') {
                        eventsend("Proceed_SMS_International_with_US_setup_pop_up_Clicked");
                        setCurrentScreen('USA_details')
                      }
                    }}
                  />
                </>
              ) : currentScreen === 'India_details' ? (
                <>
                  <p>
                    {'Please fill out following details to setup SMS Channel. All the details will be available at your DLT portal'}
                  </p>
                  <p style={{ color: colors.black }}>
                    {'Need help? '}
                    <span style={{ cursor: 'pointer', color: colors.linkblue }}
                      onClick={() => { window.open('https://cheerio.freshdesk.com/support/tickets/new', '_blank'); }}
                    >
                      {'Contact Us'}
                    </span>
                  </p>
                  <Form
                    className="py-2 verythinscrollnewinv w-100"
                    style={{
                      overflowY: 'auto',
                      // scrollbarWidth: 'thin',
                      padding: '8px',
                    }}
                  >
                    <InputGroup className="my-2">
                      <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                        {'Registration ID'}
                      </Form.Label>
                      <Form.Control
                        maxLength={19}
                        placeholder="19 digit Registrartion Id"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={registrationID}
                        onChange={(e) => {
                          onChangeRId(e);
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="my-2">
                      <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                        {'URN/Header ID'}
                      </Form.Label>
                      <Form.Control
                        maxLength={15}
                        placeholder="15 digit URN/Registration Id"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={headerID}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regexp = new RegExp('^[0-9]+$');
                          if (value === '' || regexp.test(value)) {
                            setHeaderID(value);
                          }
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="my-2">
                      <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                        {'SMS Header'}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter here"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={SMSHeader}
                        onChange={(e) => {
                          setSMSHeader(e.target.value);
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="my-2">
                      <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                        {'Upload DLT Template dump'}
                      </Form.Label>
                      <div className='d-flex flex-column justify-content-center align-items-center w-100 py-2 px-2 my-2'
                        style={{ border: `1px dashed ${colors.borderwhite}`, height: 210, backgroundColor: colors.white02, borderRadius: 12, cursor: 'pointer' }}
                        onClick={() => {
                          if (!DLTdump) {
                            fileInputRef.current.click();
                          }
                        }}
                      >
                        {DLTdump ? (
                          <>
                            <img src={images.DocIcon} style={{
                              height: 50, width: 70, objectFit: 'contain'
                            }}></img>
                            <div className='d-flex flex-row justify-content-center align-items-center' style={{ width: '80%' }}>
                              <p style={{ fontWeight: 500, color: colors.black, marginBlock: 8, textAlign: 'center', fontSize: 14, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', wordBreak: 'break-all' }}>
                                {DLTdump?.name ?? 'File uploaded successfully'}
                              </p>
                              <img src={images.Cross} style={{ height: 18, width: 18, marginInlineStart: 10, cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDLTdump(null);
                                }}
                              ></img>
                            </div>
                          </>
                        ) : (
                          <>
                            <img src={images.ImgUpIcon} style={{ height: 50, width: 70, objectFit: 'contain' }}></img>
                            <p style={{ fontWeight: 500, color: colors.black, marginBlock: 8, textAlign: 'center', fontSize: 14 }}>
                              {'Upload file here'}
                            </p>
                          </>
                        )}

                      </div>
                    </InputGroup>
                    <input
                      type='file'
                      id='fileInput'
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      accept='.csv, text/csv'
                      onChange={(e) => {
                        handlefileupload(e);
                      }}
                    />
                  </Form>
                  <CheerioButton
                    borderStyle={{ width: '100%', border: `1px solid ${colors.primary03}`, marginBlockStart: 16 }}
                    backColor={colors.primary03}
                    textStyle={{ color: colors.white01 }}
                    btnText={'Submit'}
                    onclick={() => {
                      eventsend("Submit_SMS_Indian_setup_pop_up_Clicked");
                      setupSmsApiCall();
                      // setSuccess(true);
                    }}
                    disabled={(registrationID && headerID && SMSHeader && DLTdump) ? false : true}
                  />
                </>
              ) : currentScreen === 'International_details' ? (
                <>
                  <p>
                    {'Please read all the necessary requirements & fee structure for international messaging excluding US'}
                  </p>
                  <ListingItem
                    MainText={'Phone Number Charges'}
                    Pricing={'$2.30'}
                    SubText={'Monthly'}
                  />
                  <ListingItem
                    MainText={'Per SMS Cost'}
                    Pricing={'$0.012'}
                    SubText={'Outbound'}
                  />
                  <Form
                    className="py-2 verythinscrollnewinv w-100"
                    style={{
                      overflowY: 'auto',
                      scrollbarWidth: 'thin',
                      padding: '8px',
                    }}
                  >
                    <InputGroup className="my-2">
                      <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                        {'Business Name'}
                      </Form.Label>
                      <Form.Control
                        // maxLength={19}
                        placeholder="Registered business name"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={businessName}
                        onChange={(e) => {
                          setBusinessName(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </Form>
                  <CheerioButton
                    borderStyle={{ width: '100%', border: `1px solid ${colors.primary03}`, marginBlockStart: 16 }}
                    backColor={colors.primary03}
                    textStyle={{ color: colors.white01 }}
                    btnText={'Submit'}
                    onclick={() => {
                      eventsend("Submit_SMS_International_wo_US_setup_pop_up_Clicked");
                      setupSmsApiCall();
                      // setSuccess(true);
                    }}
                    disabled={businessName ? false : true}
                  />
                </>
              ) : currentScreen === 'USA_details' ? (
                <>
                  <div className='d-flex flex-column justify-content-start align-items-center w-100 verythinscrollnewinv'
                    style={{ overflowY: 'auto' }}
                  >
                    <p>
                      {'Please read all the necessary requirements & fee structure for international messaging including US'}
                    </p>
                    <div className='my-2 w-100 d-flex flex-column justify-content-start align-items-start'
                      style={{ backgroundColor: colors.lightPurple, borderRadius: 10, padding: 10 }}
                    >
                      <div className='d-flex flex-row justify-content-start align-items-center w-100'>
                        <img src={images.InfoCircle} style={{ height: 16, width: 16, objectFit: 'contain', marginInlineEnd: 6 }}></img>
                        <p style={{ color: colors.primary04, marginBlock: 2, fontWeight: 600, fontSize: 14 }}>
                          {'A2P 10DLC Announcements'}
                        </p>
                      </div>
                      <p style={{ color: colors.primary04, marginBlock: 2, fontSize: 14 }}>
                        {'In order to send SMS messages to the U.S. using 10DLC phone numbers, all messages must be sent via an approved application-to-person (A2P) Campaign. Any unregistered U.S.-bound messages will be blocked. '}
                      </p>
                    </div>
                    <Form
                      className="py-2 w-100"
                      style={{
                        // overflowY: 'auto',
                        // scrollbarWidth: 'thin',
                        padding: '8px',
                      }}
                    >
                      <InputGroup className="my-2">
                        <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black, width: '100%' }}>
                          {'Select plan'}
                        </Form.Label>
                        <Form.Select
                          type="text"
                          placeholder="select plan"
                          className="btncustom dropdown"
                          style={{
                            borderRadius: 8,
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys01,
                            // marginInline: 16,
                            width: '100%',
                            paddingBlock: 10,
                          }}
                          value={planSelected}
                          onChange={(e) => {
                            setPlanSelected(e.target.value);
                            if (e.target.value === "Low-volume standard") {
                              eventsend("Proceed_SMS_International_with_US_setup_pop_up_Clicked_low_volume");
                            } else {
                              eventsend("Proceed_SMS_International_with_US_setup_pop_up_Clicked_sole_propreitor");
                            }
                          }}
                        >
                          <option
                            style={{ marginTop: 4, marginBottom: 4, borderRadius: 8, padding: 10 }}
                            value={'Low-volume standard'}
                            key={'Low-volume standard'}
                          >
                            {'Low-volume standard'}
                          </option>
                          <option
                            style={{ marginTop: 4, marginBottom: 4, borderRadius: 8, padding: 10 }}
                            value={'Sole-Proprietor'}
                            key={'Sole-Proprietor'}
                          >
                            {'Sole-Proprietor'}
                          </option>
                        </Form.Select>
                      </InputGroup>
                    </Form>
                    <div className='d-flex flex-column justify-content-start align-items-start w-100 my-2'>
                      <p style={{ fontWeight: 600, color: colors.black, marginBlock: 2 }}>
                        {'Pre-requisites'}
                      </p>
                      <p style={{ marginBlock: 2 }}>
                        {'Business information'}<br />
                        {'Business registration ID such as US EIN'}<br />
                        {'Contact information'}<br />
                        {'Business reason to use messaging and sample messages'}
                      </p>
                    </div>
                    {planSelected === 'Low-volume standard' ? (
                      <>
                        <div className='d-flex flex-column justify-content-start align-items-start w-100 my-2'>
                          <p style={{ fontWeight: 600, color: colors.black, marginBlock: 2 }}>
                            {'You will get'}
                          </p>
                          <p style={{ marginBlock: 2 }}>
                            {'<6000 messages per day'}<br />
                            {'Standard delivery rate'}
                          </p>
                        </div>
                        <ListingItem
                          MainText={'Brand Registration'}
                          Pricing={'$4'}
                          SubText={'One-time Fee'}
                        />
                        <ListingItem
                          MainText={'Campaign Vetting Fee'}
                          Pricing={'$15'}
                          SubText={'One-time (Additional if re-submitted)'}
                        />
                        <ListingItem
                          MainText={'Campaign Fee'}
                          Pricing={'$2 - $10'}
                          SubText={'Monthly'}
                        />
                        <ListingItem
                          MainText={'Phone Number Charges'}
                          Pricing={'$2.30'}
                          SubText={'Monthly'}
                        />
                        <ListingItem
                          MainText={'Per SMS Cost'}
                          Pricing={'$0.012'}
                          SubText={'Outbound'}
                        />
                      </>
                    ) : planSelected === 'Sole-Proprietor' && (
                      <>
                        <div className='d-flex flex-column justify-content-start align-items-start w-100 my-2'>
                          <p style={{ fontWeight: 600, color: colors.black, marginBlock: 2 }}>
                            {'You will get'}
                          </p>
                          <p style={{ marginBlock: 2 }}>
                            {'<3000 messages per day'}<br />
                            {'Delivery at 1 message per second'}
                          </p>
                        </div>
                        <ListingItem
                          MainText={'Brand Registration'}
                          Pricing={'$4'}
                          SubText={'One-time Fee'}
                        />
                        <ListingItem
                          MainText={'Campaign Vetting Fee'}
                          Pricing={'$15'}
                          SubText={'One-time (Additional if re-submitted)'}
                        />
                        <ListingItem
                          MainText={'Campaign Fee'}
                          Pricing={'$2'}
                          SubText={'Monthly'}
                        />
                        <ListingItem
                          MainText={'Phone Number Charges'}
                          Pricing={'$2.30'}
                          SubText={'Monthly'}
                        />
                        <ListingItem
                          MainText={'Per SMS Cost'}
                          Pricing={'$0.012'}
                          SubText={'Outbound'}
                        />
                      </>
                    )}
                  </div>
                  <CheerioButton
                    borderStyle={{ width: '100%', border: `1px solid ${colors.primary03}`, marginBlockStart: 16 }}
                    backColor={colors.primary03}
                    textStyle={{ color: colors.white01 }}
                    btnText={'I understand, Proceed'}
                    onclick={() => {
                      setCurrentScreen('USA_details_2');
                    }}
                  />
                </>
              ) : currentScreen === 'USA_details_2' && (
                <>
                  <p>
                    {'Please fill out following details for your SMS Registration process'}
                  </p>
                  <div className='my-2 w-100 d-flex flex-column justify-content-start align-items-center verythinscrollnewinv'
                    style={{ overflowY: 'auto', flex: 1 }}
                  >
                    <Form
                      className="py-2 w-100"
                      style={{
                        overflowY: 'auto',
                        padding: '8px',
                      }}
                    >
                      <InputGroup className="my-2">
                        <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                          {'Business Name'}
                        </Form.Label>
                        <Form.Control
                          // maxLength={19}
                          placeholder="Registered business name"
                          className="w-100 btncustom"
                          style={{ borderRadius: 8 }}
                          value={businessName}
                          onChange={(e) => {
                            setBusinessName(e.target.value);
                          }}
                        />
                      </InputGroup>
                      <InputGroup className="my-2">
                        <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                          {'Business Registration ID'}
                        </Form.Label>
                        <Form.Control
                          placeholder="Registration ID"
                          className="w-100 btncustom"
                          style={{ borderRadius: 8 }}
                          value={businessRegID}
                          onChange={(e) => {
                            setBusinessRegID(e.target.value)
                          }}
                        />
                      </InputGroup>
                      <InputGroup className="my-2">
                        <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                          {'Phone no.'}
                        </Form.Label>
                        <PhoneInput
                          inputStyle={{ width: '100%' }}
                          country={'us'}
                          countryCodeEditable={false}
                          value={phoneNo}
                          onChange={onchangePhone}
                        />
                      </InputGroup>
                      <InputGroup className="my-2">
                        <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                          {'Email ID'}
                        </Form.Label>
                        <Form.Control
                          // maxLength={19}
                          placeholder="john@example.com"
                          className="w-100 btncustom"
                          style={{ borderRadius: 8 }}
                          value={emailID}
                          onChange={(e) => {
                            setEmailID(e.target.value);
                          }}
                        />
                      </InputGroup>
                      <InputGroup className="my-2">
                        <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                          {'Reason'}
                        </Form.Label>
                        <Form.Control
                          // maxLength={19}
                          as="textarea"
                          rows={3}
                          placeholder="Specify why you want to use messaging in US"
                          className="w-100 btncustom"
                          style={{ borderRadius: 8 }}
                          value={reason}
                          onChange={(e) => {
                            setReason(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </Form>
                  </div>
                  <CheerioButton
                    borderStyle={{ width: '100%', border: `1px solid ${colors.primary03}`, marginBlockStart: 16 }}
                    backColor={colors.primary03}
                    textStyle={{ color: colors.white01 }}
                    btnText={'Submit'}
                    onclick={() => {
                      eventsend("Submit_SMS_International_with_US_setup_pop_up_Clicked");
                      setupSmsApiCall();
                      // setCurrentScreen('Success');
                    }}
                    disabled={(businessName && businessRegID && phoneNo && emailID && reason) ? false : true}
                  />
                </>
              )}

              {/* <Form
                className="py-2 verythinscrollnewinv"
                style={{
                  overflowY: 'auto',
                  scrollbarWidth: 'thin',
                  padding: '10px',
                }}
              >
                <InputGroup className="my-2">
                  <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                    {'Registration ID'}
                  </Form.Label>
                  <Form.Control
                    maxLength={19}
                    placeholder="19 digit Registrartion Id"
                    className="w-100 btncustom"
                    style={{ borderRadius: 8 }}
                    value={registrationID}
                    onChange={(e) => {
                      onChangeRId(e);
                    }}
                  />
                </InputGroup>
                <InputGroup className="my-2">
                  <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                    {'URN/Header ID'}
                  </Form.Label>
                  <Form.Control
                    maxLength={15}
                    placeholder="15 digit URN/Registration Id"
                    className="w-100 btncustom"
                    style={{ borderRadius: 8 }}
                    value={headerID}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regexp = new RegExp('^[0-9]+$');
                      if (value === '' || regexp.test(value)) {
                        setHeaderID(value);
                      }
                    }}
                  />
                </InputGroup>
                <InputGroup className="my-2">
                  <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                    {'SMS Header'}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter here"
                    className="w-100 btncustom"
                    style={{ borderRadius: 8 }}
                    value={SMSHeader}
                    onChange={(e) => {
                      setSMSHeader(e.target.value);
                    }}
                  />
                </InputGroup>
                <p>
                  {
                    'These template Id & template value are for testing purposes please make sure to add an original template values here.'
                  }
                </p>
                <InputGroup className="my-2">
                  <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                    {'SMS/Template Id'}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter here"
                    className="w-100 btncustom"
                    style={{ borderRadius: 8 }}
                    value={SMSID}
                    onChange={(e) => {
                      setSMSID(e.target.value);
                    }}
                  />
                </InputGroup>
                <InputGroup className="my-2">
                  <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                    {'SMS/Template Value'}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter here"
                    className="w-100 btncustom"
                    style={{ borderRadius: 8 }}
                    value={SMSValue}
                    onChange={(e) => {
                      setSMSValue(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form>
              <CheerioButton
                borderStyle={{
                  marginBlockStart: 20,
                  width: '100%',
                  borderColor: colors.primary03,
                }}
                textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                btnText={'Submit'}
                backColor={colors.primary03}
                onclick={() => {
                  if (SMSHeader && SMSID && SMSValue && registrationID && headerID) {
                    if (registrationID.length === 19 && headerID.length === 15) {
                      setupSmsApiCall();
                    } else {
                      if (registrationID.length !== 19) {
                        toast.error('RegistrationId should contain 19 digits.', {
                          position: 'top-center',
                        });
                      } else if (headerID.length !== 15) {
                        toast.error('URN Id should contain 15 digits.', {
                          position: 'top-center',
                        });
                      }
                    }
                  } else {
                    toast.error('Please fill all the fields', { position: 'top-center' });
                  }
                }}
              /> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SMSDLTModal;
