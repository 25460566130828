import { getPaymentByUserId } from '../../../Services';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import CheerioButton from '../../../Components/CheerioButton';



const PremiumSubscriptions = ({ selectedItem, setSelectedItem }) => {
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [isCancel, setIsCancel] = useState(false);
    const [isCancelModal, setIsCancelModal] = useState(false);
    const localdata = useSelector((state) => state.main.localdata);

    const [plan, setPlan] = useState('');
    const authtoken = useSelector((state) => state.main.auth_token);
    function formatDate(createdAt) {
        if (!createdAt) {
            throw new RangeError('Invalid time value: Date is undefined or null');
        }

        const date = new Date(createdAt);

        if (isNaN(date.getTime())) {
            throw new RangeError('Invalid time value: Date parsing failed');
        }

        const options = { year: 'numeric', month: 'short', day: '2-digit' };

        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

        let [month, day, year] = formattedDate.split(" ");

        day = day.padStart(2, '0');

        return `Active since ${month}, ${day} ${year}`;
    }
    const navigate = useNavigate();
    const getPlanDurationText = (duration) => {
        if (duration === 0) return "Test Payment";
        if (duration === 90) return "Quarterly";
        if (duration === 180) return "Half Yearly";
        if (duration === 365) return "Yearly";
        return "";
    };

    useEffect(() => {
        const fetchPaymentHistory = async () => {
            try {
                const data = await getPaymentByUserId(authtoken);
                if (data?.flag) {
                    setPaymentHistory(data?.response?.data);
                }
            } catch (error) {
                console.error("Error fetching payment history:", error);
            }
        };

        fetchPaymentHistory();
    }, [authtoken]);

    const PremiumSubscriptionPayment = paymentHistory.filter((payment) => payment?.description === 'Premium')

    return (

        <div
            style={{
                paddingTop: '1%'
            }}
        >
            {isCancelModal && (
                <CancelSubscriptionModal
                    setIsCancelModal={setIsCancelModal}
                    isCancelModal={isCancelModal}
                    plan={plan}
                />
            )}
            {localdata?.premiumPlanName && localdata?.premiumSubscriptionId && <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '70vw'
                }}
            >
                <div
                    style={{
                        display: 'flex'
                    }}
                >
                    <img style={{ width: '60px', height: '60px', marginRight: 1 }} src={images.planLogo}></img>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: 16,
                                lineHeight: 1,
                                paddingLeft: '1%',
                                paddingTop: '5%'

                            }}
                        >
                            {localdata?.premiumPlanName} Plan ({getPlanDurationText(localdata?.premium_details?.plan_duration)})
                        </div>
                        <p
                            style={{
                                fontWeight: 400,
                                fontSize: 14,
                                color: '#666666',
                                paddingLeft: '1%',
                                width: '101%'
                            }}
                        >
                            {PremiumSubscriptionPayment[0]?.createdAt ? formatDate(PremiumSubscriptionPayment[0]?.createdAt) : 'No such payment done'}
                        </p>
                    </div>
                    <div

                        style={{
                            position: 'absolute',
                            right: '0%',
                            top: '20%'
                        }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                right: '0%',
                                paddingTop: '16%',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 10
                            }}
                        >
                            <CheerioButton
                                borderStyle={{ borderColor: colors.darkPurple, borderRadius: '8px', paddingBlock: 0, paddingInline: 35 }}
                                textStyle={{ color: colors.white01, fontWeight: 600 }}
                                btnText={'Upgrade'}
                                backColor={colors.darkPurple}
                                onclick={() => {
                                    navigate('/premiumpricing')
                                }}
                            />
                            <CheerioButton
                                borderStyle={{ borderColor: '#FF0000', borderRadius: '8px', paddingBlock: 0, paddingInline: 10 }}
                                textStyle={{ color: '#FF0000', fontWeight: 400 }}
                                btnText={'Cancel Subscription'}
                                backColor={colors.white01}
                                onclick={() => {
                                    setPlan('Addons');
                                    setIsCancelModal(true);
                                }}
                                disabled={localdata?.premiumSubscriptionId === ""}
                            />
                        </div>
                    </div>
                </div>

            </div>}
        </div >
    )
}

export default PremiumSubscriptions