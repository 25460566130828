import images from '../../../Utils/images';

export const emailTemplateData = [
  {
    templateName: 'Product Offer',
    image: images.Temp1,
    industry: 'Ecommerce',
    type: 'MARKETING',
  },
  {
    templateName: 'Online workshop',
    image: images.Temp2,
    industry: 'Ecommerce',
    type: 'MARKETING',
  },
  {
    templateName: 'Welcome/onboarding',
    image: images.Temp3,
    industry: 'Ecommerce',
    type: 'MARKETING',
  },
  {
    templateName: 'The Black Friday Sale',
    image: images.Temp4,
    industry: 'Ecommerce',
    type: 'MARKETING',
  },
  {
    templateName: 'Deals for the week',
    image: images.Temp5,
    industry: 'Ecommerce',
    type: 'MARKETING',
  },
  {
    templateName: 'Abandoned cart',
    image: images.Temp6,
    industry: 'Ecommerce',
    type: 'MARKETING',
  },
  {
    templateName: 'Offer on new arrivals',
    image: images.Temp7,
    industry: 'Ecommerce',
    type: 'MARKETING',
  },
  {
    templateName: 'Abandoned Cart 2',
    image: images.Temp8,
    industry: 'Ecommerce',
    type: 'MARKETING',
  },
  {
    templateName: 'Offer packages',
    image: images.Temp9,
    industry: 'Ecommerce',
    type: 'MARKETING',
  },
  {
    templateName: 'Sale Offer',
    image: images.Temp10,
    industry: 'Ecommerce',
    type: 'MARKETING',
  },
];
