import React from 'react';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import { useSelector } from 'react-redux';
import { toggleInterveneStatus } from '../../../Services';
import { eventsend } from '../../../Config/analyticsFunctions';
const MobileWidth = window.innerWidth < 900 ? true : false;

export const InterveneChat = ({ setActiveWorkflow, chatData, setResumeWorkflow }) => {
  const authtoken = useSelector((state) => state.main.auth_token);

  const toggleStatusApiCall = () => {
    const data = {
      phoneNumber: chatData?.user_details?.phone,
      convId: chatData?._id,
      value: true,
    };
    toggleInterveneStatus(data, authtoken).then((res) => {
      console.log('res====> ', res);
      if (res?.data) {
        setActiveWorkflow(false);
        setResumeWorkflow(true);
      } else {
        setActiveWorkflow(true);
        setResumeWorkflow(false);
      }
    });
  };
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.white,
        padding: '16px 10px',
        flexDirection: MobileWidth ? 'column' : 'row',
        // '@media (max-width: 600px)': {
        //   flexDirection: 'column',
        // },
      }}
    >
      <div
        style={{
          maxWidth: MobileWidth ? '100%' : '70%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          // border: '1px solid #9357FF',
        }}
      >
        <p
          style={{
            fontSize: 16,
            color: colors.black,
          }}
        >
          This chat has an active workflow. To reply, please stop the workflow and intervene by
          clicking the "Intervene Chat" button.
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: MobileWidth ? '100%' : '',
          // border: '1px solid #9357FF',
        }}
      >
        <CheerioButton
          id="SendTemplateButton"
          btnText="Intervene Chat"
          textStyle={{
            color: colors.white,
            fontSize: '12px',
            fontWeight: '600',
            textWrap: 'nowrap',
          }}
          backColor={colors.black}
          borderStyle={{ height: '32px', borderRadius: '9px', width: '100%' }}
          onclick={() => {
            eventsend("Intervene_chat_Inbox_page_clicked");
            toggleStatusApiCall();
          }}
        />
      </div>
    </div>
  );
};
