import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import colors from '../../../Utils/colors';
import { useSelector } from 'react-redux';
import { ShopifyCustomersApi, getShopifyDetailsApi } from '../../../Services';
import images from '../../../Utils/images';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import CheerioButton from '../../../Components/CheerioButton';
import CreateContactModal from './CreateContactModal';
import { toast } from 'react-toastify';

const CustomersData = () => {
  const [labelName, setLabelName] = useState('');
  const [shopifyDetails, setShopifyDetails] = useState();
  const [customerData, setCustomerData] = useState([]);
  const [IntegrationStatus, setIntegrationStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openCCModal, setOpenCCModal] = useState(false);

  const localdata = useSelector((state) => state.main.localdata);
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);

  useEffect(() => {
    setIsLoading(true);
    getShopifyDetailsAPIcall();
  }, []);

  const getShopifyDetailsAPIcall = () => {
    let token = authtoken;
    getShopifyDetailsApi(token).then((res) => {
      if (res?.flag) {
        setShopifyDetails(res?.data?.result);
        setIntegrationStatus(true);
      } else {
        setIntegrationStatus(false);
        //toast.error('Shopify config or details does not exist',{position:'top-center'});
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (shopifyDetails?._id) {
      console.log('shopifyDetials ===> ', shopifyDetails);
      shopifyCustomersAPIcall();
    }
  }, [shopifyDetails]);

  const shopifyCustomersAPIcall = () => {
    setIsLoading(true);
    let data = {
      storeName: shopifyDetails?.storeName,
      access_token: shopifyDetails?.access_token,
    };
    let token = authtoken;
    console.log('Data sent ===> ', data);
    ShopifyCustomersApi(data, token).then((res) => {
      if (res?.flag) {
        setCustomerData(res?.data?.customers);
        console.log('success res customer list ===> ', res);
      } else {
      toast.error(res?.message);
      }
      setIsLoading(false);
    });
  };

  return (
    <>
      <div
        className="h-100 w-100 verythinscrollnew"
        style={{
          overflowY: 'auto',
          backgroundColor: colors.white02,
          border: `1px solid ${colors.borderwhite}`,
        }}
      >
        <div
          className="d-flex flex-column justify-content-start align-items-center w-100 "
          style={{ flex: 1 }}
        >
          {openCCModal && <CreateContactModal setOpenCCModal={setOpenCCModal} />}
          {isLoading ? (
            <>
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ height: '70vh' }}
              >
                <SpinnerLoader />
              </div>
            </>
          ) : (
            <>
              <div
                className="d-flex flex-row justify-content-between align-items-center w-100"
                style={{ marginBlockStart: 30, marginBlockEnd: 16, paddingInline: 30 }}
              >
                <h3
                  style={{
                    // padding: 0,
                    // margin: 0,
                    // fontSize: 20,
                    // fontWeight: 700,
                    color: colors.black,
                  }}
                >
                  {'Customers'}
                </h3>
                {IntegrationStatus && (
                  <CheerioButton
                    borderStyle={{}}
                    textStyle={{color: colors.white }}
                    btnText={'Save as contact list'}
                    backColor={colors.black}
                    onclick={() => {
                      setOpenCCModal(true);
                    }}
                    icon={images.IPlusWhite}
                  />
                )}
              </div>

              {IntegrationStatus ? (
                <>
                  <div
                    className="d-flex flex-row justify-content-start align-items-center"
                    style={{
                      width: 'calc( 100% - 60px )',
                      height: 40,
                      backgroundColor: '#F0F2F2',
                      marginBlockStart: 16,
                    }}
                  >
                    <div
                      className="h-100"
                      style={{
                        width: '20%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRight: '1px solid #B3B3B3',
                      }}
                    >
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 14,
                          fontWeight: 600,
                          color: colors.black04,
                        }}
                      >
                        {'Name'}
                      </p>
                    </div>
                    <div
                      className="h-100"
                      style={{
                        width: '20%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRight: '1px solid #B3B3B3',
                      }}
                    >
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 14,
                          fontWeight: 600,
                          color: colors.black04,
                        }}
                      >
                        {'Phone no.'}
                      </p>
                    </div>
                    <div
                      className="h-100"
                      style={{
                        width: '20%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRight: '1px solid #B3B3B3',
                      }}
                    >
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 14,
                          fontWeight: 600,
                          color: colors.black04,
                        }}
                      >
                        {'Location'}
                      </p>
                    </div>

                    <div
                      className="h-100"
                      style={{
                        width: '25%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRight: '1px solid #B3B3B3',
                      }}
                    >
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 14,
                          fontWeight: 600,
                          color: colors.black04,
                        }}
                      >
                        {'Email ID'}
                      </p>
                    </div>

                    <div
                      className="h-100"
                      style={{
                        width: '15%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 14,
                          fontWeight: 600,
                          color: colors.black04,
                        }}
                      >
                        {'Orders Count'}
                      </p>
                    </div>
                  </div>

                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{ width: 'calc( 100% - 60px )' }}
                  >
                    {customerData &&
                      customerData.map((item, index) => {
                        return (
                          <>
                            <div
                              key={item?.id}
                              className="d-flex flex-row justify-content-center align-items-center w-100"
                              style={{}}
                            >
                              <div
                                className="d-flex flex-row justify-content-start align-items-center"
                                style={{
                                  width: '20%',
                                  paddingBlock: 14,
                                  paddingInline: 10,
                                  borderRight: '1px solid #B3B3B3',
                                }}
                              >
                                <p
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: colors.black04,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    wordBreak: 'break-all',
                                  }}
                                >
                                  {`${item?.first_name} ${item?.last_name}`}
                                </p>
                              </div>
                              <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{
                                  width: '20%',
                                  paddingBlock: 14,
                                  paddingInline: 10,
                                  borderRight: '1px solid #B3B3B3',
                                }}
                              >
                                <p
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: colors.black04,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    wordBreak: 'break-all',
                                  }}
                                >
                                  {item?.phone ? item?.phone : 'NA'}
                                </p>
                              </div>
                              <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{
                                  width: '20%',
                                  paddingBlock: 14,
                                  paddingInline: 10,
                                  borderRight: '1px solid #B3B3B3',
                                }}
                              >
                                <p
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: colors.black04,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    wordBreak: 'break-all',
                                  }}
                                >
                                  {item?.default_address?.province
                                    ? item?.default_address?.province
                                    : 'NA'}
                                </p>
                              </div>
                              <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{
                                  width: '25%',
                                  paddingBlock: 14,
                                  paddingInline: 10,
                                  borderRight: '1px solid #B3B3B3',
                                }}
                              >
                                <p
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: colors.black04,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    wordBreak: 'break-all',
                                  }}
                                >
                                  {item?.email ? item?.email : 'NA'}
                                </p>
                              </div>
                              <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{
                                  width: '15%',
                                  paddingBlock: 14,
                                  paddingInline: 10,
                                  cursor: 'pointer',
                                }}
                              >
                                <p
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: colors.black04,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    wordBreak: 'break-all',
                                  }}
                                >
                                  {item?.orders_count}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ height: '70vh' }}
                  >
                    <p style={{ fontSize: 16, fontWeight: 400, color: colors.greys04 }}>
                      {'Integrate Shopify Store to continue'}
                    </p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomersData;
