import images from '../../../Utils/images';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import colors from '../../../Utils/colors';
import { Checkbox } from '@mui/material';
import { V2AgentGet } from '../../../Services';
import { useSelector } from 'react-redux';
import { IoMdCloseCircleOutline } from 'react-icons/io';

const AssignAgentsModal = ({ data, setData }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const [agentMode, setAgentMode] = useState('Automatic');
  const [agentData, setAgentData] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [userAssignPopup, setShowUserAssignPopup] = useState(true);
  const GetAgentsV2APICall = () => {
    let token = authtoken;
    V2AgentGet(token).then((res) => {
      if (res) {
        setAgentData(Object.values(res?.data));
      } else {
      }
    });
  };
  useEffect(() => {
    GetAgentsV2APICall();
  }, []);

  useEffect(() => {
    if (data) {
      setAgentMode(data?.option || 'Automatic');
      setAssignments(data?.agents);
    }
  }, []);

  useEffect(() => {
    setData({
      ...data,
      option: agentMode,
      agents: agentMode === 'Manual' ? assignments : [],
      // seconds: seconds,
    });
  }, [agentMode, assignments]);

  const handleCheckboxChange = (id) => {
    setAssignments((prev = []) => {
      if (prev.includes(id)) {
        return prev.filter((agentId) => agentId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <img
          src={images.InfoCircle}
          style={{ height: 18, width: 18, objectFit: 'contain', marginTop: '4%' }}
        />
        <p
          style={{
            fontWeight: 400,
            fontSize: 14,
            paddingTop: '3.5%',
            color: '#666666',
            paddingLeft: '2%',
            textAlign: 'left',
          }}
        >
          Your support/inbox chats will be assigned to agents in a round robin method. You can
          toggle agent assignment from below
        </p>
      </div>
      {userAssignPopup && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#ECE1FF',
            borderRadius: '8px',
            alignItems: 'center', // Align items vertically center
            paddingBlock: '10px',
            paddingInline: '15px',
            marginTop: '2px',
          }}
        >
          <p
            style={{
              fontWeight: 400,
              fontSize: 14,
              width: '85%',
              color: '#8E53F7',
            }}
          >
            NOTE:-Please make sure to uncheck auto reassign agents from distribute chats in Inbox.
          </p>
          <IoMdCloseCircleOutline
            size={22}
            color="#8E53F7"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowUserAssignPopup(false)}
          />
        </div>
      )}

      <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 16 }}>
        <Form.Select
          type="text"
          placeholder="select"
          className="w-100 btncustom dropdown"
          style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
          value={agentMode}
          onChange={(e) => {
            setAgentMode(e.target.value);
          }}
        >
          <option
            style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
            value={'Automatic'}
            key={'Automatic'}
          >
            {'Assign automatically to all agents'}
          </option>
          <option
            style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
            value={'Manual'}
            key={'Manual'}
          >
            {'Assign automatically to selective agents'}
          </option>
        </Form.Select>
      </InputGroup>

      {agentMode === 'Manual' &&
        agentData &&
        agentData?.length > 0 &&
        agentData?.map((agent, index) => (
          <div key={agent?._id}>
            <Checkbox
              checked={assignments?.includes(agent?._id)}
              onChange={() => handleCheckboxChange(agent?._id)}
            />
            {agent?.name}
          </div>
        ))}
    </div>
  );
};

export default AssignAgentsModal;
