import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { freeTrialApi } from '../../../Services';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import '../../../Components/Modal/Modal.css';
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { eventsend } from '../../../Config/analyticsFunctions';

export default function ForgotPass({
    newPasswordValueOne,
    newPasswordValueTwo,
    setNewPasswordValueOne,
    setNewPasswordValueTwo,
    submitPasswordAction,
    closeOtpAction,
}) {

    const isButtonDisabled = !(newPasswordValueOne === newPasswordValueTwo && newPasswordValueOne.length > 7 && newPasswordValueTwo.length > 7)

    useEffect(() => {
        eventsend("Forgot password")
    }, []);

    const [showPasswordOne, setShowPasswordOne] = useState(false);
    const [showPasswordTwo, setShowPasswordTwo] = useState(false);

    const toggleOne = () => {
        setShowPasswordOne(!showPasswordOne);
    };

    const toggleTwo = () => {
        setShowPasswordTwo(!showPasswordTwo);
    };

    return (
        <>
            <div className='ModalDarkBg'>
                <div className="Center">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            submitPasswordAction();
                            eventsend("Save_password_forget_pass_pop_up_clicked");
                        }}
                    >
                        <div className='otp_verify_modal'>
                            <div className='otp_verify_modal__header'>
                                <img src={images.CMClose} onClick={closeOtpAction}></img>
                            </div>
                            <div className="Row">
                                <p
                                    style={Styles.freeTrial}
                                >
                                    Forgot Password
                                </p>
                            </div>

                            <div className='otp_verify_modal__body'>
                                <h6 className='enter_otp_text'>
                                    Password
                                </h6>
                                <div className="" style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    // marginTop: "auto",
                                }}
                                >
                                    <input
                                        type={
                                            showPasswordOne ? "text" : "password"
                                        }
                                        className="form-control"
                                        id="UserPassword"
                                        placeholder="x-x-x-x-x-x"
                                        value={newPasswordValueOne}
                                        onChange={(e) => setNewPasswordValueOne(e.target.value)}
                                        minLength={8}
                                    />
                                    <span className="icon" style={{ marginLeft: '-10%', marginTop: '1%' }} onClick={toggleOne}>
                                        {showPasswordOne ? <AiFillEye /> : <AiFillEyeInvisible />}
                                    </span>
                                </div>
                                <h6 className='enter_otp_text' style={{ marginTop: '10px' }}>
                                    Re-enter Password
                                </h6>
                                <div className="" style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    // marginTop: "auto",
                                }}
                                >
                                    <input
                                        type={
                                            showPasswordTwo ? "text" : "password"
                                        }
                                        className="form-control"
                                        id="exampleFormControlInput2"
                                        placeholder="x-x-x-x-x-x"
                                        value={newPasswordValueTwo}
                                        onChange={(e) => setNewPasswordValueTwo(e.target.value)}
                                    />
                                    <span className="icon" style={{ marginLeft: '-10%', marginTop: '1%' }} onClick={toggleTwo}>
                                        {showPasswordTwo ? <AiFillEye /> : <AiFillEyeInvisible />}
                                    </span>

                                </div>
                                <h4 className='otp_sent_text' style={{ padding: 10, justifyContent: 'center', alignItems: 'center', color: colors.linkblue, marginTop: '2%', fontWeight: '600' }}>
                                    Save button will be active when both passwords match and password length is 8 or more
                                </h4>
                            </div>

                            <div style={{ width: '100%', marginTop: 'auto', marginBottom: '5%' }}>
                                <button
                                    style={{ width: '100%' }}
                                    type="submit"
                                    className="btn btn-dark"
                                    disabled={isButtonDisabled}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </form >
                </div >
            </div >
        </>
    );
};

const Styles = {
    DarkBg: {
        backgroundColor: 'rgba(0,0,0,0.4)',
        width: '100vw',
        height: '100vh',
        // flex:1,
        zIndex: 0,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        position: 'fixed',
        zIndex: '1231423',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
    },
    freeTrial: {
        margin: 0,
        fontSize: 24,
        fontWeight: 700,
        color: colors.black,
        fontWeight: 'bold',
    },
    Center: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    },
    TxtHeading: {
        fontSize: 42,
        fontWeight: 900,
        color: colors.primary03,
    },
    TxtBody: {
        fontSize: 20,
        fontWeight: 600,
        color: colors.black03,
    },
    TxtBody2: {
        fontSize: 16,
        fontWeight: 600,
        color: colors.black03,
    },
    itemborder: {
        backgroundColor: colors.white,
        borderRadius: 16,
        boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.15),-2px -2px 10px 0 rgba(0, 0, 0, 0.15)',
    },
};
