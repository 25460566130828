import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';

export const EnableFlowModal = ({ show, setShow, apiCall, apiValues }) => {
  return (
    <div>
      <Modal show={show}>
        <ModalBody>
          <div className="d-flex justify-content-between ">
            <p style={{ fontWeight: '700', fontSize: '18px' }}>Enable Flow</p>

            <div className="d-flex flex-row">
              <img
                src={images.CMClose}
                onClick={() => {
                  //setOrder(false);
                  setShow(false);
                }}
                style={{
                  cursor: 'pointer',
                  height: 22,
                  width: 22,
                  marginInlineEnd: 8,
                }}
              ></img>
            </div>
          </div>
          <p style={{ fontSize: '14px', color: 'grey', marginTop: '10px' }}>
            Enabling this WooCommerce flow will stop your existing default flows for this event. Are you
            sure you want to continue?
          </p>
          <p style={{ fontSize: '14px', color: 'grey', marginTop: '5px' }}>
            You can switch back to original flow from WooCommerce tab anytime.
          </p>
          <p style={{ fontSize: '14px', color: 'grey', marginTop: '10px', fontWeight: '600' }}>
            Need help?
          </p>
          <div className="d-flex justify-content-between">
            <CheerioButton
              className="button"
              borderStyle={{
                height: '30px',
                borderRadius: '9px',
                width: '42%',
                marginBlockStart: 12,
                marginBlockEnd: 8,
                marginBottom: '20px',
                border: '1px solid black',
              }}
              textStyle={{ color: 'black', fontSize: 16, fontWeight: 600 }}
              btnText={'Cancel'}
              backColor={'white'}
              onclick={() => {
                // setOrder(false);
                setShow(false);
              }}
            />
            <CheerioButton
              className="button"
              borderStyle={{
                height: '30px',
                borderRadius: '9px',
                width: '42%',
                marginBlockStart: 12,
                marginBlockEnd: 8,
                marginBottom: '20px',
                border: 'none',
              }}
              textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
              btnText={'Enable Flow'}
              backColor={'#8E53F7'}
              onclick={() => {
                apiCall(apiValues?.rowType, apiValues?.rowBool);
                setShow(false);
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
