import React from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images'

const EFBanner = ({ closeBtnAction }) => {
  return (
    <>
    <div className='d-flex flex-row justify-content-between align-items-center my-2' style={{
        width:'100%',
        paddingBlock:16,
        paddingInline:24,
        borderRadius:12,
        backgroundColor: colors.primary01,
    }}>
        <img src={images.InfoCirclePurple} style={{
            height:20,
            width:20,
            objectFit:'contain',
            marginInlineEnd:8,
        }}></img>
        <div style={{flex:1}}>
            <p className='w-100' style={{color:colors.themeDarkBlue}}>
                {'Create, edit, embed, and automate workflows actions based on responses records in Cheerio forms. You can share a link or add a snippet to your website to integrate forms'}
            </p>
        </div>
        <img src={images.CrossPurple} style={{
            height:20,
            width:20,
            objectFit:'contain',
            marginInlineStart:8,
            cursor:'pointer',
        }} onClick={()=>{
            closeBtnAction();
        }}></img>
    </div>
    </>
  )
}

export default EFBanner