import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';
import images from '../../../Utils/images';
import PreviewModal from './PreviewModal';
import { useSelector } from 'react-redux';
import {
  AutoSubmitTemplates,
  ShopifyAutoSubmitTemplates,
  ShopifyTemplatesStatus,
  getShopifyDetailsApi,
  getWooCommerceDetailsApi,
} from '../../../Services';
import Helper from '../../../Utils/Helper';
import { axiosInstance } from '../../../Config/axiosConfig';
import { useQuery } from '@tanstack/react-query';

const MessageTemplate = () => {
  const localdata = useSelector((state) => state.main.localdata);
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);

  const [IntegrationStatus, setIntegrationStatus] = useState(true);

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [previewType, setPreviewType] = useState('');
  const [templateStatus, setTemplateStatus] = useState();

  const [SAC, setSAC] = useState('Not Submitted');
  const [SOF, setSOF] = useState('Not Submitted');
  const [SCF, setSCF] = useState('Not Submitted');
  const [SOCO, setSOCO] = useState('Not Submitted');
  const [SOCA, setSOCA] = useState('Not Submitted');

  const dataCall = async () => {
    return axiosInstance
      .get('woocommerce/addKeys' + `?userId=${localdata?._id}`, {
        headers: {
          'x-access-token': authtoken,
        },
      })
      .then((response) => response.data);
  };

  const { data, isLoading: loading, isError } = useQuery(['ServeDataFtch'], () => dataCall());

  if (loading) {
    return <p>Loading...</p>;
  }

  useEffect(() => {
    getWooCommerceDetailsAPIcall();
  }, []);
  //
  const getWooCommerceDetailsAPIcall = () => {
    // let data = {
    //   _id: localdata?._id,
    // };
    let token = authtoken;
    getWooCommerceDetailsApi(token).then((res) => {
      if (res.flag) {
        setIntegrationStatus(true);
        // setShopifyDetails(res?.data?.result);
      } else {
        setIntegrationStatus(false);
        // setIsLoading(false);
        // console.log("no shopify details exist ===> ",res)
      }
    });
  };

  useEffect(() => {
    if (IntegrationStatus) {
      console.log('helper.submit templates ===> ', Helper.autoSubmitShopifyTemplates);
      if (Helper.autoSubmitShopifyTemplates) {
        submitAllTemplates();
      } else {
        getShopifyTemplateStatus();
      }
    }
  }, [IntegrationStatus]);
  //
  const getShopifyTemplateStatus = () => {
    let data = {
      _id: localdata?._id,
    };
    let token = authtoken;
    console.log('data sent ==> ', data);
    ShopifyTemplatesStatus(data, token).then((res) => {
      if (res.flag) {
        console.log('shopify status response: ', res);
        setTemplateStatus(res?.data?.result);
      } else {
        console.log('shopify status ERROR: ', res);
      }
    });
  };

  const submitAllTemplates = () => {
    console.log('auto submit all templates called . . .');
    let data = {
      user_id: localdata?._id,
    };
    let token = authtoken;
    ShopifyAutoSubmitTemplates(data, token).then((res) => {
      Helper.autoSubmitShopifyTemplates = false;
      if (res?.flag) {
        console.log('auto submit response ===> ', res);
      } else {
        console.log('auto submit ERROR response ===> ', res);
      }
      getShopifyTemplateStatus();
    });
  };

  useEffect(() => {
    if (templateStatus) {
      setTemplateStatusFunction();
    }
  }, [templateStatus]);

  const setTemplateStatusFunction = () => {
    templateStatus.map((item) => {
      console.log('item name:', item?.name);
      if (item?.name === 'shopify_order_feedback') {
        setSCF(item?.status);
      } else if (item?.name === 'shopify_order_fulfill') {
        setSOF(item?.status);
      } else if (item?.name === 'shopify_order_confirm') {
        setSOCO(item?.status);
      } else if (item?.name === 'shopify_order_cancel') {
        setSOCA(item?.status);
      } else if (item?.name === 'shopify_abandon_cart') {
        setSAC(item?.status);
      }
    });
  };

  const TemplateCard = ({
    cardImage,
    cardTitle,
    cardDescription,
    response = false,
    left = true,
    status,
  }) => {
    return (
      <>
        <div
          className="d-flex flex-row justify-content-between align-items-center"
          style={{
            height: '100%',
            width: '50%',
            paddingBlock: 20,
            paddingInline: 20,
            backgroundColor: colors.white01,
            border: `1px solid ${colors.borderwhite}`,
            borderRadius: 16,
            marginInlineEnd: left ? 8 : 0,
            marginInlineStart: left ? 0 : 8,
          }}
        >
          <img src={cardImage} style={{ height: 105, width: 105, objectFit: 'contain' }}></img>
          <div
            className="d-flex flex-column justify-content-between align-items-start h-100"
            style={{ flex: 1, paddingInlineStart: 16 }}
          >
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 14,
                  fontWeight: 700,
                  color: colors.black,
                }}
              >
                {cardTitle}
              </p>
              <div className="d-flex flex-row justify-content-between align-items-center">
                {response && (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      paddingBlock: 4,
                      paddingInline: 8,
                      borderRadius: 6,
                      backgroundColor: colors.primary01,
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 10,
                        fontWeight: 600,
                        color: colors.primary04,
                      }}
                    >
                      {'Response'}
                    </p>
                  </div>
                )}
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    paddingBlock: 4,
                    paddingInline: 8,
                    borderRadius: 6,
                    backgroundColor: colors.primary01,
                    marginInlineStart: 6,
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 10,
                      fontWeight: 600,
                      color: colors.primary04,
                    }}
                  >
                    {'Text'}
                  </p>
                </div>
              </div>
            </div>
            <p
              style={{
                padding: 0,
                margin: 0,
                fontSize: 14,
                fontWeight: 400,
                color: colors.black,
                width: '100%',
                marginBlock: 10,
                wordBreak: 'break-all',
              }}
            >
              {cardDescription}
            </p>
            <div className="d-flex flex-row justify-content-end align-items-center w-100">
              <CheerioButton
                borderStyle={{
                  border: `1px solid ${colors.black}`,
                  paddingBlock: 4,
                  paddingInline: 16,
                  borderRadius: 8,
                  marginInlineEnd: 16,
                }}
                textStyle={{ fontSize: 12, fontWeight: 600, color: colors.black }}
                btnText={'View'}
                backColor={colors.white01}
                onclick={() => {
                  setPreviewType(cardTitle);
                  setOpenPreviewModal(true);
                }}
                icon={images.CANor}
              />
              <CheerioButton
                borderStyle={{
                  border: `1px solid ${status === 'Not Submitted'
                    ? colors.greys04
                    : status === 'PENDING'
                      ? colors.textChrome
                      : status === 'APPROVED'
                        ? colors.success03
                        : status === 'REJECTED' && colors.warning04
                    }`,
                  paddingBlock: 4,
                  paddingInline: 16,
                  borderRadius: 8,
                }}
                textStyle={{ fontSize: 12, fontWeight: 600, color: colors.white }}
                btnText={status}
                backColor={
                  status === 'Not Submitted'
                    ? colors.greys04
                    : status === 'PENDING'
                      ? colors.textChrome
                      : status === 'APPROVED'
                        ? colors.success03
                        : status === 'REJECTED' && colors.warning04
                }
                onclick={() => {
                  console.log('support button clicked...');
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="h-100 w-100 verythinscrollnew"
        style={{
          overflowY: 'auto',
          backgroundColor: colors.white02,
          border: `1px solid ${colors.borderwhite}`,
        }}
      >
        {openPreviewModal && (
          <PreviewModal setOpenPreviewModal={setOpenPreviewModal} previewType={previewType} />
        )}

        <div
          className="d-flex flex-column justify-content-start align-items-center w-100 "
          style={{
            flex: 1,
            filter: data.exists ? '' : 'grayscale(1)',
            pointerEvents: data.exists ? '' : 'none',
          }}
        >
          {!data.exists && (
            <p
              style={{
                padding: 0,
                margin: 0,
                marginBlockStart: 20,
                fontSize: 16,
                fontWeight: 400,
                color: colors.greys04,
              }}
            >
              {'Integrate WooCommerce Store to continue'}
            </p>
          )}

          {data.exists && (
            <div
              className="d-flex flex-row justify-content-between align-items-center"
              style={{
                backgroundColor: colors.primary01,
                borderRadius: 16,
                paddingBlock: 20,
                marginBlockStart: 30,
                marginBlockEnd: 16,
                width: 'calc( 100% - 60px )',
                paddingInline: 20,
              }}
            >
              <img
                src={images.InfoCircle}
                style={{ height: 24, width: 24, objectFit: 'contain' }}
              ></img>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingInlineStart: 16,
                  fontSize: 14,
                  fontWeight: 600,
                  color: colors.black,
                }}
              >
                {
                  'We have auto-submitted templates on your behalf which are currently under review. You can see truncated details by clicking on "View" button.'
                }
              </p>
            </div>
          )}

          <div>
            <div className="d-flex flex-row justify-content-between align-items-center w-100"
              style={{ marginBlock: 16, paddingInline: 30 }}
            >
              <h2
                style={{ padding: 0, margin: 0, fontSize: 20, fontWeight: 700, color: colors.black }}
              >
                {'Explore message templates'}
              </h2>
              <div className="d-flex flex-row justify-content-center align-items-center">
                {/* <CheerioButton
              borderStyle={{border:`1px solid ${colors.black}`,marginInlineEnd:16}}
              textStyle={{fontSize:12,fontWeight:600,color:colors.white}}
              btnText={'Submit all'}
              backColor={colors.black}
              onclick={()=>{console.log("submit all clicked");submitAllTemplates();}}
            /> */}
                <CheerioButton
                  borderStyle={{ border: `1px solid ${colors.black}` }}
                  textStyle={{ fontSize: 12, fontWeight: 600, color: colors.black }}
                  btnText={'Contact support'}
                  backColor={colors.white02}
                  onclick={() => {
                    window.open(
                      'mailto:support@cheerio.freshdesk.com?subject=Shopify template support.'
                    );
                  }}
                />
              </div>
            </div>
            <p
              style={{
                padding: 0,
                margin: 0,
                paddingInline: 30,
                marginBlockEnd: 16,
                fontSize: 16,
                fontWeight: 400,
                color: colors.greys04,
              }}
            >
              {
                'A collection of messages best suited for your requirements. You can choose to add the messages together or to review each one before setting up the message'
              }
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-between align-items-center w-100"
            style={{ height: 200, marginBlock: 8, paddingInline: 30 }}
          >
            <TemplateCard
              cardTitle={'Abandoned Cart'}
              cardImage={images.STAbandonedCart1}
              cardDescription={
                'Hi *[Mohit]*! 👋Thank you for your order.Your payment of *[Rs. 3590]* for an order created from the *SHOPIFY_STORE_NAME*...'
              }
              status={SAC}
            />
            {/* <TemplateCard
            cardTitle={'Order Fulfilled'}
            cardImage={images.STOrderFulfilled}
            cardDescription={'Hey *[Mohit]*Your order is on its way. You can check your shipment details or track your order by clicking on the button below. 📦'}
          /> */}
            <TemplateCard
              cardTitle={'Customer Feedback'}
              cardImage={images.STCustomerFeedback}
              cardDescription={
                'Hello *[Ankit]*, 👋😇We hope you liked the *[Product]* you purchased on *[Date]*.✨Can you share your experience with us?...'
              }
              response={true}
              left={false}
              status={SCF}
            />
          </div>
          <div
            className="d-flex flex-row justify-content-between align-items-center w-100"
            style={{ height: 200, marginBlock: 8, paddingInline: 30 }}
          >
            {/* <TemplateCard
            cardTitle={'Abandoned Cart'}
            cardImage={images.STAbandonedCart1}
            cardDescription={'Hi *[Mohit]*! 👋Thank you for your order.Your payment of *[Rs. 3590]* for an order created from the *SHOPIFY_STORE_NAME*...'}
          /> */}
            <TemplateCard
              cardTitle={'Order Confirmed'}
              cardImage={images.STOrderConfirmed}
              cardDescription={
                'Hi *[Mohit]* - Your order is confirmed 🎉Great news! We have received your order *[#FGH456]* amounting to *[Rs.3000]*.'
              }
              status={SOCO}
            />
            <TemplateCard
              cardTitle={'Order Cancelled'}
              cardImage={images.STOrderCancelled}
              cardDescription={
                'Customer FeedbackResponseTextHello *[Ankit]*, 👋😇We hope you liked the *[Product]* you purchased on *[Date]*....'
              }
              left={false}
              status={SOCA}
            />
          </div>
          <div
            className="d-flex flex-row justify-content-between align-items-center w-100"
            style={{ height: 200, marginBlock: 8, paddingInline: 30 }}
          >
            <TemplateCard
              cardTitle={'Order Fulfilled'}
              cardImage={images.STOrderFulfilled}
              cardDescription={
                'Hey *[Mohit]*Your order is on its way. You can check your shipment details or track your order by clicking on the button below. 📦'
              }
              status={SOF}
            />
            {/* <TemplateCard
            cardTitle={'Order Confirmed'}
            cardImage={images.STOrderConfirmed}
            cardDescription={'Hi *[Mohit]* - Your order is confirmed 🎉Great news! We have received your order *[#FGH456]* amounting to *[Rs.3000]*.'}
          /> */}
            {/* <TemplateCard
            cardTitle={'Abandoned Cart 2'}
            cardImage={images.STAbandonedCart2}
            cardDescription={'Hi *[Ankit]*!We noticed that the item(s) is still in your Cart. We have an amazing offer for you. 😍Use Code - *[CODE]* ....'}
            left={false}
          /> */}
          </div>
          {/* <div className='d-flex flex-row justify-content-between align-items-center w-100' style={{height:200,marginBlock:8,paddingInline:30}}>
          <TemplateCard
            cardTitle={'Reorder'}
            cardImage={images.STReorder}
            cardDescription={"Hi *[Ankit]*,Thanks for making your last purchase of *[Product Name]*.*Here's a reminder to place an order of your favorite items ..."}
          />
          <TemplateCard
            cardTitle={'Abandoned cart 3'}
            cardImage={images.STAbandonedCart3}
            cardDescription={'Hey *[Mohit]*Your order is on its way. You can check your shipment details or track your order by clicking on the button below. 📦'}
            left={false}
          />
        </div>
        <div className='d-flex flex-row justify-content-between align-items-center w-100' style={{height:200,marginBlock:8,paddingInline:30}}>
          <TemplateCard
            cardTitle={'COD Order Confirmation'}
            cardImage={images.STCODConfirmation}
            // cardDescription={"Hey *[Mohit]*Your order is on its way. You can check your shipment details or track your order by clicking on the button below. 📦"}
            cardDescription={"Hello *Mohit* Thank you for purchasing from *SHOPIFY_STORE_NAME*. Your order has been created successfully. Since you’ve chosen Cash on Delivery..."}
          />
          <TemplateCard
            cardTitle={'COD Order Confirm Status'}
            cardImage={images.STCODConfirmStatus}
            // cardDescription={'Hey *[Mohit]*Your order is on its way. You can check your shipment details or track your order by clicking on the button below. 📦'}
            cardDescription={'Thank you for your confirmation. Your package is now being processed for shipping. Have a great day! 😀'}
            left={false}
          />
        </div>
        <div className='d-flex flex-row justify-content-between align-items-center w-100' style={{height:200,marginBlock:8,paddingInline:30}}>
          <TemplateCard
            cardTitle={'COD Order Follow up'}
            cardImage={images.STCODConfirmFollowup}
            // cardDescription={"Hey *[Mohit]*Your order is on its way. You can check your shipment details or track your order by clicking on the button below. 📦"}
            cardDescription={"Hello *Ankit* Thank you for purchasing from *SHOPIFY_STORE_NAME*. Your order has been created successfully. We noticed you haven't..."}
          />
        </div> */}
        </div>
      </div >
    </>
  );
};

export default MessageTemplate;
