import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import './../styles.css';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import {
  ShopifyEnableWebhookAPI,
  ShopifyToggleWebhookAPI,
  getShopifyDetailsApi,
} from '../../../Services';
import { Route, Routes, useNavigate } from 'react-router-dom';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { toast } from 'react-toastify';
import { EnableFlowModal } from './EnableFlowModal';
import { GoPencil } from 'react-icons/go';
import { BiBarChartAlt2 } from 'react-icons/bi';
import { CodPrepaidFlow } from './CodPrepaidFlow';
import { eventsend } from '../../../Config/analyticsFunctions';

const Flows = ({ selectedMenuItem, setSelectedCampaignFlow, setSelectedMenuItem }) => {
  const localdata = useSelector((state) => state.main.localdata);
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);
  const existingWorkFlow = localdata?.whatsapp_credentials?.shopifyWorflowId;
  const navigate = useNavigate();

  const [label, setLabel] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [ShopifyDetails, setShopifyDetails] = useState();
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [orderFulfilled, setOrderFulfilled] = useState(false);
  const [orderCancelled, setOrderCancelled] = useState(false);
  const [orderFeedback, setOrderFeedback] = useState(false);
  const [abandonedCart, setAbandonedCart] = useState(false);
  const [codConfirmation, setCodConfirmation] = useState(false);
  const [IntegrationStatus, setIntegrationStatus] = useState(false);
  const [enableFlow, setEnableFlow] = useState(false);
  const [codToPrepaid, setCodToPrepaid] = useState(false);

  const [apiValues, setApiValues] = useState({ hook: '', codVal: '', status: '' });

  const [codCheck, setCodCheck] = useState({
    orderCreateCOD: false,
    orderCancelCOD: false,
    orderFeedbackCOD: false,
    orderFullfillCOD: false,
  });
  useEffect(() => {
    setIsLoading(true);
    console.log('selectedMenuItem ===> ', selectedMenuItem);
    eventsend('Flow_toggles__Shopify_integration_page_clicked');
    getShopifyDetailsAPIcall();
  }, []);

  const getShopifyDetailsAPIcall = () => {
    let token = authtoken;
    getShopifyDetailsApi(token).then((res) => {
      if (res?.flag) {
        setShopifyDetails(res?.data?.result);
        setIntegrationStatus(true);
        // setShopifyDetails(res?.data?.result);
      } else {
        // toast.error('Shopify config or details does not exist',{position:'top-center'});
        setIntegrationStatus(false);
        setIsLoading(false);
        // console.log("no shopify details exist ===> ",res)
      }
    });
  };

  useEffect(() => {
    if (ShopifyDetails?._id) {
      setOrderPlaced(ShopifyDetails?.orderCreateWebhook);
      setOrderFulfilled(ShopifyDetails?.orderFullfillWebhook);
      setOrderCancelled(ShopifyDetails?.orderCancelWebhook);
      setOrderFeedback(ShopifyDetails?.orderFeedbackWebhook);
      setAbandonedCart(ShopifyDetails?.abandonCartWebhook);
      setCodConfirmation(ShopifyDetails?.codWebhook);
      setCodCheck({
        orderCreateCOD: ShopifyDetails?.orderCreateCOD,
        orderCancelCOD: ShopifyDetails?.orderCancelCOD,
        orderFeedbackCOD: ShopifyDetails?.orderFeedbackCOD,
        orderFullfillCOD: ShopifyDetails?.orderFullfillCOD,
      });
      setCodToPrepaid(ShopifyDetails?.CODtoPrepaid ?? false);
      setIsLoading(false);
    }
  }, [ShopifyDetails]);

  // useEffect(()=>{
  //     if(orderPlaced){
  //         console.log("order placed selected ===> ",orderPlaced);
  //         EnableWebhookAPIcall('orders-placed')
  //     }
  // },[orderPlaced])

  // const EnableWebhookAPIcall = ( hookloc ) => {
  //     let data = {
  //         user_id: localdata?._id,
  //         hookloc: hookloc,
  //     };
  //     let token = authtoken;
  //     ShopifyEnableWebhookAPI(data,token).then((res)=>{
  //         if(res.flag){
  //             console.log("enable webhook success response ===> ",res);
  //         }else{
  //             console.log("enable webhook failure response ===> ",res);
  //         }
  //     })
  // }

  const ToggleWebhookAPIcall = (hookloc, codVal, status) => {
    eventsend('Flow_check_box_Shopify_integration_page_clicked');
    console.log('status', status);
    let data = {
      user_id: localdata?._id,
      hookloc: hookloc,
      codStatus: codVal ?? false,
      status: status,
    };
    let token = authtoken;
    ShopifyToggleWebhookAPI(data, token).then((res) => {
      if (res?.flag) {
        console.log(' successfully data toggled webhook ===> ', data);
        console.log(' successfully toggled webhook ===> ', res);
        getShopifyDetailsAPIcall();
      } else {
        console.log(' failed to toggled webhook ===> ', res);
        getShopifyDetailsAPIcall();
      }
    });
  };

  const AbandonedData = [
    { _id: 1, flow: 'Abandoned cart 1' },
    { _id: 2, flow: 'Abandoned cart 2' },
    { _id: 3, flow: 'Abandoned cart 3' },
  ];
  const OrderConfirmationData = [
    { _id: 1, flow: 'Order confirmation' },
    { _id: 2, flow: 'Order follow up status' },
    { _id: 3, flow: 'Order cancel status' },
    { _id: 4, flow: 'Order delivery status' },
  ];
  const OrderPlacementData = [
    { _id: 1, flow: 'Order received' },
    { _id: 2, flow: 'Order confirmed' },
    { _id: 3, flow: 'Order cancelled' },
    { _id: 4, flow: 'Order fulfilled' },
    { _id: 5, flow: 'Reorder' },
    { _id: 6, flow: 'Order Feedback' },
  ];

  const displayData =
    selectedMenuItem !== 'Abandoned cart' && selectedMenuItem !== 'COD Confirmation'
      ? AbandonedData
      : selectedMenuItem == 'Order placement'
      ? OrderPlacementData
      : selectedMenuItem == 'Order confirmation' && OrderConfirmationData;

  const RowItem = ({ value, checked, handleChange, hookloc, codCheck, setOrder }) => {
    const navigate = useNavigate();
    return (
      <>
        <div
          className="d-flex flex-row justify-content-center align-items-center w-100"
          style={{ height: 60 }}
        >
          <div
            className="d-flex flex-row justify-content-start align-items-center h-100"
            style={{
              width: '45%',
              paddingBlock: 14,
              paddingInline: 10,
              paddingLeft: '20px',
              borderRight: '1px solid #B3B3B3',
            }}
          >
            <div
              style={{
                marginTop:
                  value !== 'Abandoned cart' && value !== 'COD Confirmation' ? '20px' : '0px',
                marginLeft: value !== 'Abandoned cart' && value !== 'COD Confirmation' ? '0px' : '',
              }}
            >
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 16,
                  fontWeight: 600,
                  color: colors.black04,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  wordBreak: 'break-all',
                }}
              >
                {value}
              </p>
              {value !== 'Abandoned cart' &&
                value !== 'COD Confirmation' &&
                value !== 'COD to Prepaid' && (
                  <div className="d-flex">
                    <p style={{ fontSize: '14px', color: 'grey' }}>Enable for COD flow also</p>
                    <input
                      type="checkbox"
                      checked={codCheck}
                      disabled={!checked}
                      style={{ border: '2px solid red', marginLeft: '8px', marginTop: '-12px' }}
                      onChange={() => handleChange(!codCheck, checked)}
                    />
                  </div>
                )}
            </div>
          </div>

          <div
            className="d-flex flex-row justify-content-center align-items-center h-100"
            style={{
              width: '35%',
              paddingBlock: 14,
              paddingInline: 10,
              borderRight: '1px solid #B3B3B3',
            }}
          >
            <Form.Check
              type="switch"
              checked={checked}
              onChange={() => {
                setOrder(!checked);

                handleChange(codCheck, !checked);
              }}
            />
          </div>

          <div
            className="d-flex flex-row justify-content-center align-items-center h-100"
            style={{ width: '20%', paddingBlock: 14, paddingInline: 10 }}
          >
            {value === 'COD to Prepaid' && (
              <GoPencil
                onClick={() => {
                  navigate('/creator/codPrepaid');
                }}
                style={{ cursor: 'pointer' }}
              />
            )}
            <img
              style={{ height: '22px', marginInlineStart: '10px', cursor: 'pointer' }}
              src={images.SNAnalytics}
              onClick={() => {
                setSelectedCampaignFlow(hookloc);
                setSelectedMenuItem('Analytics');
                navigate(`/creator/manageIntegrations/shopify/Analytics`);
              }}
            ></img>{' '}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="h-100 w-100 verythinscrollnew"
        style={{
          overflowY: 'auto',
          backgroundColor: colors.white02,
          border: `1px solid ${colors.borderwhite}`,
        }}
      >
        {isLoading ? (
          <>
            <div
              className="d-flex flex-column justify-content-center align-items-center w-100"
              style={{ flex: 1 }}
            >
              <SpinnerLoader />
            </div>
          </>
        ) : IntegrationStatus ? (
          <>
            <div
              className="d-flex flex-column justify-content-start align-items-center w-100"
              style={{ flex: 1 }}
            >
              <h3
                style={{
                  marginBlockStart: 30,
                  marginBlockEnd: 16,
                  paddingInline: 30,
                  width: '100%',
                  color: colors.black,
                }}
              >
                {selectedMenuItem}
              </h3>
              <div
                className="d-flex flex-row justify-content-between align-items-center"
                style={{
                  width: 'calc( 100% - 60px )',
                  height: 40,
                  backgroundColor: '#F0F2F2',
                  marginBlockStart: 16,
                }}
              >
                <div
                  className="h-100"
                  style={{
                    width: '45%',
                    display: 'flex',
                    paddingLeft: '20px',
                    alignItems: 'center',
                    borderRight: '1px solid #B3B3B3',
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 14,
                      fontWeight: 600,
                      color: colors.black04,
                    }}
                  >
                    {'Flow'}
                  </p>
                </div>
                <div
                  className="h-100"
                  style={{
                    width: '35%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRight: '1px solid #B3B3B3',
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 14,
                      fontWeight: 600,
                      color: colors.black04,
                    }}
                  >
                    {'Status'}
                  </p>
                </div>
                <div
                  className="h-100"
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 14,
                      fontWeight: 600,
                      color: colors.black04,
                    }}
                  >
                    {'Action'}
                  </p>
                </div>
              </div>
              {enableFlow && (
                <EnableFlowModal
                  show={enableFlow}
                  setShow={setEnableFlow}
                  apiCall={ToggleWebhookAPIcall}
                  apiValues={apiValues}
                  // setOrder={setOrderPlaced}
                ></EnableFlowModal>
              )}
              <div
                className="d-flex flex-column justify-content-start "
                style={{ width: 'calc( 100% - 60px )' }}
              >
                {/* {displayData && displayData.map((item,index)=>{ */}
                {/* return( */}
                {/* <> */}
                <RowItem
                  value={`Orders placed`}
                  checked={orderPlaced}
                  codCheck={codCheck.orderCreateCOD}
                  setOrder={setOrderPlaced}
                  handleChange={(val, status) => {
                    if (
                      (existingWorkFlow?.orderCreatePrepaid?.status ||
                        existingWorkFlow?.orderCreateCOD?.status) &&
                      !orderPlaced
                    ) {
                      setEnableFlow(true);
                      setOrderPlaced(false);
                      if (val !== codCheck.orderCreateCOD) {
                        setCodCheck((prev) => {
                          return { ...prev, orderCreateCOD: val };
                        });
                      }
                      const obj = {
                        hook: 'ordercreate',
                        val: val,
                        status: status,
                      };
                      setApiValues(obj);
                    } else {
                      if (val !== codCheck.orderCreateCOD) {
                        setCodCheck((prev) => {
                          return { ...prev, orderCreateCOD: val };
                        });
                      }
                      ToggleWebhookAPIcall('ordercreate', val, status);
                    }
                  }}
                  hookloc={'orders-placed'}
                />
                <RowItem
                  value={`Orders fulfilled`}
                  checked={orderFulfilled}
                  codCheck={codCheck.orderFullfillCOD}
                  setOrder={setOrderFulfilled}
                  handleChange={(val, status) => {
                    if (existingWorkFlow?.orderFulfilled?.status && !orderFulfilled) {
                      setEnableFlow(true);
                      setOrderFulfilled(false);
                      if (val !== codCheck.orderFullfillCOD) {
                        setCodCheck((prev) => {
                          return { ...prev, orderFullfillCOD: val };
                        });
                      }
                      const obj = {
                        hook: 'orderfulfilled',
                        val: val,
                        status: status,
                      };
                      setApiValues(obj);
                    } else {
                      if (val !== codCheck.orderFullfillCOD) {
                        setCodCheck((prev) => {
                          return { ...prev, orderFullfillCOD: val };
                        });
                      }
                      ToggleWebhookAPIcall('orderfulfilled', val, status);
                    }
                  }}
                  hookloc={'orders-fulfilled'}
                />
                <RowItem
                  value={`Orders cancelled`}
                  checked={orderCancelled}
                  codCheck={codCheck.orderCancelCOD}
                  setOrder={setOrderCancelled}
                  handleChange={(val, status) => {
                    if (existingWorkFlow?.orderCancel?.status && !orderCancelled) {
                      setEnableFlow(true);
                      setOrderCancelled(false);
                      if (val !== codCheck.orderCancelCOD) {
                        setCodCheck((prev) => {
                          return { ...prev, orderCancelCOD: val };
                        });
                      }
                      const obj = {
                        hook: 'ordercancelled',
                        val: val,
                        status: status,
                      };
                      setApiValues(obj);
                    } else {
                      if (val !== codCheck.orderCancelCOD) {
                        setCodCheck((prev) => {
                          return { ...prev, orderCancelCOD: val };
                        });
                      }
                      ToggleWebhookAPIcall('ordercancelled', val, status);
                    }
                  }}
                  hookloc={'order_cancelled'}
                />
                <RowItem
                  value={`Orders feedback`}
                  checked={orderFeedback}
                  codCheck={codCheck.orderFeedbackCOD}
                  setOrder={setOrderFeedback}
                  handleChange={(val, status) => {
                    if (existingWorkFlow?.orderFeedback?.status && !orderFeedback) {
                      setOrderFeedback(false);
                      setEnableFlow(true);
                      if (val !== codCheck.orderFeedbackCOD) {
                        setCodCheck((prev) => {
                          return { ...prev, orderFeedbackCODrd: val };
                        });
                      }
                      const obj = {
                        hook: 'orderfeedback',
                        val: val,
                        status: status,
                      };
                      setApiValues(obj);
                    } else {
                      if (val !== codCheck.orderFeedbackCOD) {
                        setCodCheck((prev) => {
                          return { ...prev, orderFeedbackCOD: val };
                        });
                      }
                      ToggleWebhookAPIcall('orderfeedback', val, status);
                    }
                  }}
                  hookloc={'order_feedback'}
                />
                <RowItem
                  value={`Abandoned cart`}
                  checked={abandonedCart}
                  setOrder={setAbandonedCart}
                  handleChange={() => {
                    if (existingWorkFlow?.abandonedCart?.status && !abandonedCart) {
                      setEnableFlow(true);
                      setAbandonedCart(false);
                      const obj = {
                        hook: 'abandonedcart',
                        val: false,
                        status: undefined,
                      };
                      setApiValues(obj);
                    } else {
                      ToggleWebhookAPIcall('abandonedcart');
                    }
                  }}
                  hookloc={'abandoned_cart'}
                />
                <RowItem
                  value={`COD to Prepaid`}
                  checked={codToPrepaid}
                  setOrder={setCodToPrepaid}
                  handleChange={() => {
                    console.log('cod', codToPrepaid);
                    ToggleWebhookAPIcall('CODtoPrepaid', undefined, !codToPrepaid);
                  }}
                  hookloc={'CODtoPrepaid'}
                />
                {/* </> */}
                {/* ) */}
                {/* })} */}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ height: '70vh' }}
            >
              <p style={{ fontSize: 16, fontWeight: 400, color: colors.greys04 }}>
                {'Integrate Shopify Store to continue'}
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Flows;
