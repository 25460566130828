import { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useSelector } from 'react-redux';
import { contactlabellistWithoutCountAPI } from '../../../Services';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material';
import CheerioButton from '../../../Components/CheerioButton';
import images from '../../../Utils/images';

const Events = ['Pabbly'];

const PabblyEventModal = ({ data, setData }) => {
  const [allLabel, setAllLabel] = useState([]);
  const [eventType, setEventType] = useState('');
  const [labelName, setLabelName] = useState('');
  const [saveContact, setSaveContact] = useState(false);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [customKeys, setCustomKeys] = useState([]);

  useEffect(() => {
    getLabelList();

    if (data?.eventType) {
      setEventType(data.eventType);
    } else {
      setData({
        ...data,
        eventType: Events[0],
      });
    }

    if (data?.labelName) {
      setLabelName(data.labelName);
    }

    if (data?.saveContact) {
      setSaveContact(data.saveContact);
    }

    if (data?.customKeys) {
      setCustomKeys(data.customKeys);
    }
  }, []);

  const getLabelList = () => {
    contactlabellistWithoutCountAPI(authtoken).then((res) => {
      if (res?.flag) {
        setAllLabel(res.data.v1.map((l) => l.label_name));
      } else {
        console.error('error', res);
      }
    });
  };

  const addCustomAttribute = () => {
    if (customKeys.length < 5) {
      const newcustomKeys = [...customKeys, ""];
      setCustomKeys(newcustomKeys);
      setData({
        ...data,
        customKeys: newcustomKeys,
      });
    }
  };

  const updateCustomAttribute = (index, value) => {
    const newCustomKeys = customKeys.map((attr, i) =>
      i === index ? value : attr
    );
    setCustomKeys(newCustomKeys);
    setData({
      ...data,
      customKeys: newCustomKeys,
    });
  };

  const deleteCustomAttribute = (index) => {
    const newcustomKeys = customKeys.filter((_, i) => i !== index);
    setCustomKeys(newcustomKeys);
    setData({
      ...data,
      customKeys: newcustomKeys,
    });
  };

  return (
    <div className="w-100 h-100 flex-column justify-content-start align-items-start">
      <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 16 }}>
        <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
          Select event
        </Form.Label>
        <Form.Select
          type="text"
          placeholder="select event"
          className="w-100 btncustom dropdown"
          style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
          value={eventType}
          onChange={(e) => {
            setEventType(e.target.value);
            setData({
              ...data,
              eventType: e.target.value,
            });
          }}
        >
          {Events.map((cso) => (
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={cso}
              key={cso}
            >
              {cso}
            </option>
          ))}
        </Form.Select>
      </InputGroup>
      <hr />
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <p style={{ fontWeight: 700 }}>{"Custom Keys"}</p>
        <p>{`${customKeys?.length}/5`}</p>
      </div>
      <p style={{
        color: "#666666",
        marginTop: 5,
        fontSize: 14,
        marginBottom: 5
      }}>{"Add custom keys like 'state', 'age', 'Pincode', etc., here and make sure to set the values for these keys from the Pabbly Workflow Dashboard."}</p>

      {customKeys.map((attr, index) => (
        <InputGroup key={index} className="mb-3" style={{ width: '100%' }}>
          <Form.Label style={{
            fontWeight: 700,
          }}>{`Custom key ${index + 1}`}</Form.Label>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 8,
            width: '100%',
          }}>
            <Form.Control
              type="text"
              placeholder="Type here"
              value={attr}
              className="w-100 btncustom"
              style={{ borderRadius: 8 }}
              onChange={(e) => {
                const val = e.target.value.replace(/\s+/g, '');
                updateCustomAttribute(index, val);
              }}
            />
            <img src={images.DeleteBlack} alt="" height={20} style={{ cursor: "pointer" }} onClick={() => deleteCustomAttribute(index)} />
          </div>
        </InputGroup>
      ))}

      {customKeys.length < 5 &&
        <div style={{
          paddingInline: 10,
          marginBottom: 10,
        }}>
          <CheerioButton
            btnText={"Add Custom Key"}
            textStyle={{ fontWeight: 600 }}
            icon={images.ADDICONBLACK}
            onclick={addCustomAttribute}
          />
        </div>
      }

      <InputGroup className="mb-3" style={{ width: '100%' }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <input
            checked={saveContact}
            onChange={(e) => {
              setSaveContact(e.target.checked);
              setLabelName('');
              setData({
                ...data,
                saveContact: e.target.checked,
                labelName: '',
              });
            }}
            id="cb1_pabbly"
            type="checkbox"
            style={{ width: '1rem' }}
          />
          <label for="cb1_pabbly">Save contact in a label</label>
        </div>
      </InputGroup>

      {
        saveContact ? (
          <StyledAutocomplete
            disablePortal
            id="combo-box-for-labels"
            renderInput={(params) => (
              <TextField
                size="small"
                placeholder="Choose label"
                className="restylingLabels"
                InputProps={{
                  notched: false,
                  style: {
                    borderRadius: 4,
                  },
                }}
                {...params}
              />
            )}
            value={labelName}
            freeSolo={true}
            options={allLabel}
            onChange={(_, newValue) => {
              setLabelName(newValue);
              setData({
                ...data,
                labelName: newValue,
              });
            }}
          />
        ) : null
      }
    </div >
  );
};

const StyledAutocomplete = styled(Autocomplete)({
  '&.Mui-focused .MuiInputLabel-outlined': {
    color: 'grey',
  },
  '& .MuiAutocomplete-inputRoot': {
    color: 'grey',
    height: '40px',
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type': {
      height: '10px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      border: '1px solid var(--border-color-border-medium, #CCC)',
    },
  },
});

export default PabblyEventModal;
