import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { useSelector } from 'react-redux';
import {
  CreateDraftTemplate,
  GetTemplatesV2API,
  deleteWATemplateApi,
  fetechTemplatesApi,
} from '../../../Services';
import { Form, InputGroup } from 'react-bootstrap';
import CustomTemplateStart from '../../CustomTemplate/CustomTemplate';
import { useNavigate } from 'react-router-dom';
import {
  Pagination,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Tooltip,
  Select,
  MenuItem,
} from '@mui/material';
import '../styles.css';
import images from '../../../Utils/images';
import TemplatePreviewModal from '../../../Components/Modal/TemplatePreviewModal';
import { languageList } from '../../CustomTemplate/CustomTemplate';
import { RxCross1 } from 'react-icons/rx';
import { IoIosSearch } from 'react-icons/io';
import { AiOutlineEye } from 'react-icons/ai';
import { IoEyeOutline } from 'react-icons/io5';
import { FiTrash, FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';
const MyTemplates = ({
  setSelectedItem,
  createTemplateAction,
  searchData,
  setSearchData,
  customTemplateCateogary,
  updateCustomTemplateCateogary,
  filterItem,
  setFilterItem,
}) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);

  const [isLoading, setIsLoading] = useState(false);
  const [myTempDataExists, setMyTempDataExists] = useState(false);
  const [myTempData, setMyTempData] = useState(null);
  // const [filterItem, setFilterItem] = useState('');
  const navigate = useNavigate();
  const [before, setBefore] = useState('');
  const [after, setAfter] = useState('');
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [prevItem, setPrevItem] = useState();
  const [statusFilter, setStatusFilter] = useState('');
  const [languageFilter, setLanguageFilter] = useState('');
  const [limitValue, setLimitValue] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  useEffect(() => {
    getMyTemplatesAPICall();
  }, []);

  // useEffect(() => {
  //   fetechTemplatesApiCall();
  // }, [limitValue]);

  const customMenuProps = {
    PaperProps: {
      sx: {
        borderRadius: '10px',
        maxHeight: 300,
      },
    },
  };

  const getMyTemplatesAPICall = () => {
    setIsLoading(true);
    let token = authtoken;
    const data = {
      limit: limitValue,
    };
    // console.log("local",localdata?.whatsapp_credentials?.is_ai_sensy_user);
    GetTemplatesV2API(data, token).then((res) => {
      if (res?.data.length) {
        // console.log('success response: ', res);
        setMyTempDataExists(true);
        setIsLoading(false);
        setMyTempData(res?.data);
        if (res?.paginate) {
          setBefore(res?.paginate?.before);
          setAfter(res?.paginate?.after);
        }
      } else {
        // console.log('error response: ', res);
        setIsLoading(false);
      }
    });
  };

  const fetechTemplatesApiCall = (limit, af, bef) => {
    setIsLoading(true);
    const data = {
      before: bef,
      after: af,
      limit: limit ?? '50',
    };
    fetechTemplatesApi(data, authtoken).then((res) => {
      if (res?.data.length) {
        // console.log('success response: ', res);
        setMyTempDataExists(true);
        setIsLoading(false);
        setMyTempData(res?.data);
        if (res?.paginate) {
          setBefore(res?.paginate?.before);
          setAfter(res?.paginate?.after);
        }
      } else if (res?.data.length === 0) {
        setMyTempDataExists(true);
        setIsLoading(false);
        setMyTempData(res?.data);
        // if(data.after == ""){
        //   setAfter("");
        // }else{
        //   setBefore("");
        // }
      } else {
        // console.log('error response: ', res);
        setIsLoading(false);
      }
    });
  };

  const deleteWATemplateApiCall = (item) => {
    setIsLoading(true);
    const data = {
      template_name: item.name,
    };
    const toastId = toast.loading('Deleting the template. This may take a while');
    deleteWATemplateApi(data, authtoken).then((res) => {
      if (res?.status) {
        setIsLoading(false);
        fetechTemplatesApiCall();
        toast.update(toastId, {
          render: 'Successfully deleted the template.',
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          autoClose:true,
        });
      } else {
        console.log('error response: ', res);
        toast.update(toastId, {
          render: res?.message || 'Error occured while deleting template',
          type: toast.TYPE.ERROR,
          isLoading: false,
          autoClose:true
          
        });
        setIsLoading(false);
      }
    });
  };

  const DuplicateTemplateAPICall = (item) => {
    let token = authtoken;
    let data = {
      name: `${item.name}_copy`,
      data: {
        name: `${item.name}_copy`,
        category: item.category,
        language: item.language,
        components: item.components,
        allow_category_change: true,
      },
    };
    const toastId = toast.loading('Duplicating template. This may take a while');
    CreateDraftTemplate(data, token).then((res) => {
      if (res?.flag) {
        toast.update(toastId, {
          render: 'Template copied to Drafts',
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          autoClose:true
          
        });
      } else {
        toast.update(toastId, {
          render: res?.message || 'Error Occured while creating draft',
          type: toast.TYPE.ERROR,
          isLoading: false,
          autoClose:true
          
        });
      }
    });
  };

  const getDateUpdated = (e) => {
    let date = new Date(e * 1000);
    let dd = date.toLocaleDateString();
    return dd;
  };

  return (
    <>
      {!customTemplateCateogary ? (
        <div
          className="d-flex flex-column justify-content-start align-items-center h-100 w-100"
          style={{}}
        >
          {previewModalOpen && (
            <TemplatePreviewModal setPreviewModalOpen={setPreviewModalOpen} item={prevItem} />
          )}
          {/* <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <h3
              style={{ 
                color: colors.black 
              }}
            >
              {'My Templates'}
            </h3>
            <div
              className='d-flex flex-row justify-content-between align-items-center'
              style={{
                width: '40%',
              }}
            >
                <CheerioButton
                  borderStyle={{}}
                  textStyle={{color: colors.white }}
                  btnText={'Create template'}
                  backColor={colors.black}
                  onclick={() => {
                    navigate('/creator/templates/create');
                  }}
                />
             
              <InputGroup
                className="btncustom"
                style={{
                  width: '60%',
                  height: '100%',
                }}
              >
                <InputGroup.Text
                  id="basic-addon1"
                  style={{ fontSize: 12, fontWeight: 600, color: colors.black, cursor: 'default' }}
                >
                  {'FILTER'}
                </InputGroup.Text>
                <Form.Select
                  className="btncustom"
                  aria-label="select campaign"
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    color: colors.greys04,
                    cursor: 'pointer',
                  }}
                  value={filterItem}
                  onChange={(e) => {
                    setFilterItem(e.target.value);
                  }}
                >
                  <option value={''} selected>
                    {'All'}
                  </option>
                  <option value={'MARKETING'} key={'1'}>
                    Marketing
                  </option>
                  <option value={'AUTHENTICATION'} key={'2'}>
                    {' '}
                    Authentication
                  </option>
                  <option value={'UTILITY'} key={'3'}>
                    {' '}
                    Utility
                  </option>
                </Form.Select>
              </InputGroup>
            </div>
          </div> */}
          <div
            className="d-flex flex-column justify-content-start align-items-center w-100 verythinscrollnew"
            style={{ flex: 1, overflowY: 'scroll' }}
          >
            {isLoading ? (
              <>
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <SpinnerLoader />
                </div>
              </>
            ) : (
              <>
                {myTempDataExists ? (
                  <>
                    <div
                      className="d-flex flex-column justify-content-start align-items-center"
                      style={{
                        width: '100%',
                      }}
                    >
                      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={myTempData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        class='paginate'
      /> */}
                      {/* {localdata?.whatsapp_credentials?.is_ai_sensy_user && ( */}
                      {/* <div
                        className="d-flex justify-content-end mb-2"
                        style={{ height: '25px', width: '100%' }}
                      >
                        <img
                          src={images.Left}
                          style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                          onClick={() => {
                            const af = '';
                            const bef = before;
                            fetechTemplatesApiCall(af, bef);
                          }}
                        ></img>
                         <p style={{marginLeft:"5px",marginRight:"5px"}}>{"<-->"}</p> 
                        <img
                          src={images.Right}
                          style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                          onClick={() => {
                            const af = after;
                            const bef = '';

                            fetechTemplatesApiCall(af, bef);
                          }}
                        ></img>
                      </div> */}
                      {/* )} */}
                      <div className="announcement_container">
                        <div className="campaign_table_container" style={{ width: '100%' }}>
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              flexDirection: 'row',
                              gap: '10px',
                              marginBlock: 10,
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'row',
                                gap: '10px',
                                marginBlock: 10,
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'flex-row',
                                  backgroundColor: colors.white,
                                  marginBottom: '1%',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    backgroundColor: colors.white,
                                    flexDirection: 'column',
                                    width: '100%',
                                    paddingRight: '8px',
                                  }}
                                >
                                  <InputGroup style={{ width: '100%' }}>
                                    <InputGroup.Text
                                      style={{
                                        borderRightWidth: 0,
                                        backgroundColor: 'transparent',
                                        borderColor: '#CCCCCC',
                                        paddingInline: '8px',
                                      }}
                                    >
                                      <IoIosSearch size={22} />
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="text"
                                      placeholder="Search for template"
                                      style={{
                                        borderTopRightRadius: searchData ? '0' : 8,
                                        borderBottomRightRadius: searchData ? '0' : 8,
                                        borderColor: '#CCCCCC',
                                        borderLeftWidth: 0,
                                        paddingInlineStart: 0,
                                        zIndex: 1,
                                        outline: 'none',
                                        boxShadow: 'none',
                                        borderRightWidth: searchData ? '0' : '',
                                      }}
                                      onFocus={(e) => {
                                        e.target.style.outline = 'none';
                                        e.target.style.borderColor = '#B3B3B3';
                                      }}
                                      value={searchData}
                                      onChange={(e) => {
                                        setSearchData(e.target.value);
                                      }}
                                    />
                                    {searchData && (
                                      <InputGroup.Text
                                        onClick={() => {
                                          setSearchData('');
                                        }}
                                        style={{
                                          borderLeftWidth: 0,
                                          backgroundColor: 'transparent',
                                          borderColor: '#B3B3B3',
                                          paddingInline: '6px',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <RxCross1 size={16} />
                                      </InputGroup.Text>
                                    )}
                                  </InputGroup>
                                </div>
                              </div>
                              <div style={{ height: '40px' }}>
                                <Select
                                  value={filterItem}
                                  displayEmpty
                                  onChange={(e) => {
                                    setFilterItem(e.target.value);
                                  }}
                                  MenuProps={customMenuProps}
                                  sx={{
                                    height: '40px',
                                    color: 'black',
                                    width: 'auto',
                                    borderRadius: '8px',
                                    borderColor: '#CCCCCC',
                                    '& .MuiSvgIcon-root': {
                                      color: 'black',
                                    },
                                  }}
                                >
                                  <MenuItem
                                    value={''}
                                    sx={{
                                      height: '32px',
                                      color: 'black',
                                      width: 'auto',
                                      borderRadius: '8px',
                                      border: 0,
                                      margin: '10px',
                                      '& .MuiSvgIcon-root': {
                                        color: 'black',
                                      },
                                    }}
                                  >
                                    {'All Categories'}
                                  </MenuItem>
                                  <MenuItem
                                    value={'MARKETING'}
                                    sx={{
                                      height: '32px',
                                      color: 'black',
                                      width: 'auto',
                                      borderRadius: '8px',
                                      border: 0,
                                      margin: '10px',
                                      '& .MuiSvgIcon-root': {
                                        color: 'black',
                                      },
                                    }}
                                  >
                                    {'Marketing'}
                                  </MenuItem>
                                  <MenuItem
                                    value={'AUTHENTICATION'}
                                    sx={{
                                      height: '32px',
                                      color: 'black',
                                      width: 'auto',
                                      borderRadius: '8px',
                                      border: 0,
                                      margin: '10px',
                                      '& .MuiSvgIcon-root': {
                                        color: 'black',
                                      },
                                    }}
                                  >
                                    {'Authentication'}
                                  </MenuItem>
                                  <MenuItem
                                    value={'UTILITY'}
                                    sx={{
                                      height: '32px',
                                      color: 'black',
                                      width: 'auto',
                                      borderRadius: '8px',
                                      border: 0,
                                      margin: '10px',
                                      '& .MuiSvgIcon-root': {
                                        color: 'black',
                                      },
                                    }}
                                  >
                                    {'Utility'}
                                  </MenuItem>
                                </Select>
                              </div>
                              <div style={{ height: '40px' }}>
                                <Select
                                  value={statusFilter}
                                  displayEmpty
                                  onChange={(e) => {
                                    setStatusFilter(e.target.value);
                                  }}
                                  MenuProps={customMenuProps}
                                  sx={{
                                    height: '40px',
                                    color: 'black',
                                    width: 'auto',
                                    borderRadius: '8px',
                                    borderColor: '#CCCCCC',
                                    '& .MuiSvgIcon-root': {
                                      color: 'black',
                                    },
                                  }}
                                >
                                  <MenuItem
                                    value=""
                                    hidden
                                    sx={{
                                      height: '32px',
                                      color: 'grey',
                                      width: 'auto',
                                      borderRadius: '8px',
                                      border: 0,
                                      margin: '10px',
                                    }}
                                  >
                                    {'Status'}
                                  </MenuItem>
                                  <MenuItem
                                    value={'APPROVED'}
                                    sx={{
                                      height: '32px',
                                      color: 'black',
                                      width: 'auto',
                                      borderRadius: '8px',
                                      border: 0,
                                      margin: '10px',
                                      '& .MuiSvgIcon-root': {
                                        color: 'black',
                                      },
                                    }}
                                  >
                                    {'Approved'}
                                  </MenuItem>
                                  <MenuItem
                                    value={'REJECTED'}
                                    sx={{
                                      height: '32px',
                                      color: 'black',
                                      width: 'auto',
                                      borderRadius: '8px',
                                      border: 0,
                                      margin: '10px',
                                      '& .MuiSvgIcon-root': {
                                        color: 'black',
                                      },
                                    }}
                                  >
                                    {'Rejected'}
                                  </MenuItem>
                                  <MenuItem
                                    value={'PENDING'}
                                    sx={{
                                      height: '32px',
                                      color: 'black',
                                      width: 'auto',
                                      borderRadius: '8px',
                                      border: 0,
                                      margin: '10px',
                                      '& .MuiSvgIcon-root': {
                                        color: 'black',
                                      },
                                    }}
                                  >
                                    {'Pending'}
                                  </MenuItem>
                                </Select>
                              </div>
                              <div style={{ height: '40px' }}>
                                <Select
                                  value={languageFilter}
                                  displayEmpty
                                  onChange={(e) => {
                                    setLanguageFilter(e.target.value);
                                  }}
                                  MenuProps={customMenuProps}
                                  sx={{
                                    height: '40px',
                                    color: 'black',
                                    width: 'auto',
                                    borderRadius: '8px',
                                    borderColor: '#CCCCCC',
                                    '& .MuiSvgIcon-root': {
                                      color: 'black',
                                    },
                                  }}
                                >
                                  <MenuItem value="" hidden>
                                    {'Language '}
                                  </MenuItem>
                                  {languageList?.length > 0 &&
                                    languageList.map((item) => {
                                      return (
                                        <MenuItem
                                          value={item?.key}
                                          sx={{
                                            height: '32px',
                                            color: 'black',
                                            width: 'auto',
                                            borderRadius: '8px',
                                            border: 0,
                                            margin: '10px',
                                            '& .MuiSvgIcon-root': {
                                              color: 'black',
                                            },
                                          }}
                                        >
                                          {item?.value}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                            <div
                              className="d-flex flex-row justify-content-end mb-2"
                              style={{ height: '25px', width: '100%', gap: 10, marginTop: 20 }}
                            >
                              <div className="d-flex flex-row" style={{ gap: 10 }}>
                                <p style={{ fontSize: 16, fontWeight: 400 }}>{'Rows per page:'}</p>
                                <Select
                                  value={limitValue}
                                  onChange={(e) => {
                                    setLimitValue(e.target.value);
                                    fetechTemplatesApiCall(e.target.value);
                                  }}
                                  sx={{
                                    color: 'black',
                                    width: 'auto',
                                    border: 'none',
                                    borderColor: '#CCCCCC',
                                    '& .MuiSvgIcon-root': {
                                      color: 'black',
                                    },
                                  }}
                                >
                                  <MenuItem value="25">25</MenuItem>
                                  <MenuItem value="50">50</MenuItem>
                                  <MenuItem value="75">75</MenuItem>
                                  <MenuItem value="100">100</MenuItem>
                                  <MenuItem value="200">200</MenuItem>
                                </Select>
                              </div>
                              <div className="d-flex flex-row" style={{ height: '25px', gap: 20 }}>
                                <img
                                  src={images.Left}
                                  style={{
                                    height: 24,
                                    width: 24,
                                    objectFit: 'contain',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    const af = '';
                                    const bef = before;
                                    fetechTemplatesApiCall(limitValue, af, bef);
                                  }}
                                ></img>
                                <img
                                  src={images.Right}
                                  style={{
                                    height: 24,
                                    width: 24,
                                    objectFit: 'contain',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    const af = after;
                                    const bef = '';

                                    fetechTemplatesApiCall(limitValue, af, bef);
                                  }}
                                ></img>
                              </div>
                            </div>
                          </div>
                          {myTempData.filter((item) => {
                            const matchesCategory =
                              filterItem === '' || item.category === filterItem;
                            const matchesSearch =
                              searchData === '' ||
                              item.name.toLowerCase().includes(searchData.toLowerCase());
                            const matchStatus = statusFilter === '' || item.status === statusFilter;
                            const matchLanguage =
                              languageFilter === '' || item.language === languageFilter;

                            return matchesCategory && matchesSearch && matchStatus && matchLanguage;
                          }).length > 0 ? (
                            <>
                              <TableContainer sx={{ flex: 1 }}>
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          width: '25%',
                                          fontSize: 16,
                                          fontWeight: 600,
                                          color: colors.black,
                                          backgroundColor: colors.white04,
                                        }}
                                      >
                                        {'Template Name'}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: '15%',
                                          fontSize: 16,
                                          fontWeight: 600,
                                          color: colors.black,
                                          textAlign: 'center',
                                          backgroundColor: colors.white04,
                                        }}
                                      >
                                        {'Language'}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: '15%',
                                          fontSize: 16,
                                          fontWeight: 600,
                                          color: colors.black,
                                          textAlign: 'center',
                                          backgroundColor: colors.white04,
                                        }}
                                      >
                                        {'Status'}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: '25%',
                                          fontSize: 16,
                                          fontWeight: 600,
                                          color: colors.black,
                                          backgroundColor: colors.white04,
                                        }}
                                      >
                                        {'Rejected reason'}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: '20%',
                                          fontSize: 16,
                                          fontWeight: 600,
                                          color: colors.black,
                                          textAlign: 'center',
                                          backgroundColor: colors.white04,
                                        }}
                                      >
                                        {'Actions'}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {myTempData &&
                                      myTempData
                                        .filter((item) => {
                                          const matchesCategory =
                                            filterItem === '' || item.category === filterItem;
                                          const matchesSearch =
                                            searchData === '' ||
                                            item.name
                                              .toLowerCase()
                                              .includes(searchData.toLowerCase());
                                          const matchStatus =
                                            statusFilter === '' || item.status === statusFilter;
                                          const matchLanguage =
                                            languageFilter === '' ||
                                            item.language === languageFilter;

                                          return (
                                            matchesCategory &&
                                            matchesSearch &&
                                            matchStatus &&
                                            matchLanguage
                                          );
                                        })
                                        .sort((a, b) => {
                                          const order = { PENDING: 1, APPROVED: 2, REJECTED: 3 };
                                          return order[a.status] - order[b.status];
                                        })
                                        .map((item) => {
                                          return (
                                            <>
                                              <TableRow hover tabIndex={-1} key={item.id}>
                                                <TableCell>
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      flexDirection: 'column',
                                                      gap: '6px',
                                                    }}
                                                  >
                                                    <p style={{ fontSize: 14, fontWeight: 400 }}>
                                                      {item?.name === 'loyal_customers_new'
                                                        ? 'X% off with discount code'
                                                        : item?.name === 'discount_all_products_new'
                                                        ? 'Thank you message'
                                                        : item?.name === 'repeatcustomer_offer'
                                                        ? 'Repeat customer offer'
                                                        : item?.name === 'repeatcustomer_offer2'
                                                        ? 'Repeat customer offer'
                                                        : item?.name === 'do_you_know'
                                                        ? 'Do you know'
                                                        : item?.name === 'welcome_insta_message_2'
                                                        ? 'Welcome Instagram'
                                                        : item?.name === 'customer_enquiry'
                                                        ? 'Follow up with discount'
                                                        : item?.name === 'renewal_remainder'
                                                        ? 'Send a Reminder'
                                                        : item?.name === 'share_info'
                                                        ? 'Share info/update/quote'
                                                        : item?.name === 'thank_you_customer'
                                                        ? 'Thank your customer & share info'
                                                        : item?.name === 'customer_win_back'
                                                        ? 'Customer win back'
                                                        : item?.name === 'additional_discount_offer'
                                                        ? 'Additional discount offer'
                                                        : item?.name === 'business_info_newsletter'
                                                        ? 'Business info newsletter'
                                                        : item?.name === 'maintainance_update'
                                                        ? 'Maintenance update'
                                                        : item?.name ===
                                                          'new_product_service_marathi'
                                                        ? 'New product service Marathi'
                                                        : item?.name === 'Offer'
                                                        ? 'Share an Offer'
                                                        : item?.name ===
                                                          'share_an_offer_media_button'
                                                        ? 'Share an Offer'
                                                        : item?.name === 'Course'
                                                        ? 'Sell a Course'
                                                        : item?.name === 'new_course_media_button'
                                                        ? 'Sell a Course'
                                                        : item?.name === 'Product'
                                                        ? 'Sell a Product'
                                                        : item?.name === 'new_product_media_button'
                                                        ? 'Sell a Product'
                                                        : item?.name}
                                                    </p>
                                                    <p
                                                      style={{
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        color: '#666666',
                                                      }}
                                                    >
                                                      {item?.category}
                                                    </p>
                                                  </div>
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>
                                                  {
                                                    languageList.find(
                                                      (lang) => lang.key === item?.language
                                                    )?.value
                                                  }
                                                </TableCell>
                                                <TableCell>
                                                  <div
                                                    className="d-flex flex-row justify-content-center align-items-center w-100"
                                                    style={{
                                                      paddingInline: 10,
                                                      paddingBlock: 10,
                                                      textAlign: 'center',
                                                      backgroundColor:
                                                        item?.status === 'APPROVED'
                                                          ? '#CFFFCE'
                                                          : item?.status === 'REJECTED'
                                                          ? '#FFDBDA'
                                                          : item?.status === 'PENDING' && '#FFDEB8',
                                                      borderRadius: 8,
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        padding: 0,
                                                        margin: 0,
                                                        fontSize: 16,
                                                        fontWeight: 400,
                                                        color:
                                                          item?.status === 'APPROVED'
                                                            ? colors.success03
                                                            : item?.status === 'REJECTED'
                                                            ? colors.error03
                                                            : item?.status === 'PENDING' &&
                                                              '#D77400',
                                                      }}
                                                    >
                                                      {item?.status}
                                                    </p>
                                                  </div>
                                                </TableCell>
                                                <TableCell>{item?.rejected_reason}</TableCell>
                                                <TableCell>
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      flexDirection: 'row',
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                      gap: 10,
                                                    }}
                                                  >
                                                    <Tooltip
                                                      title="View template"
                                                      style={{ cursor: 'pointer' }}
                                                    >
                                                      <div>
                                                        <IoEyeOutline
                                                          size={20}
                                                          onClick={() => {
                                                            setPrevItem(item);
                                                            setPreviewModalOpen(true);
                                                          }}
                                                        />
                                                      </div>
                                                    </Tooltip>
                                                    <Tooltip
                                                      title="Delete template"
                                                      style={{ cursor: 'pointer' }}
                                                    >
                                                      <div>
                                                        <FiTrash2
                                                          size={20}
                                                          onClick={() => {
                                                            deleteWATemplateApiCall(item);
                                                          }}
                                                        />
                                                      </div>
                                                    </Tooltip>
                                                    <Tooltip
                                                      title="Duplicate template"
                                                      style={{ cursor: 'pointer' }}
                                                    >
                                                      <img
                                                        src={images.Copy}
                                                        height={22}
                                                        width={22}
                                                        onClick={() => {
                                                          DuplicateTemplateAPICall(item);
                                                        }}
                                                      />
                                                    </Tooltip>
                                                  </div>
                                                </TableCell>
                                              </TableRow>
                                            </>
                                          );
                                        })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                          ) : (
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              No more data found
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
                      <p style={{ fontSize: 16, fontWeight: 400, color: colors.greys04 }}>
                        {
                          'All your submitted templates will appear here. Start adding your templates.'
                        }
                      </p>
                      <CheerioButton
                        borderStyle={{ borderColor: colors.black, paddingInline: 30 }}
                        textStyle={{ fontSize: 12, fontWeight: 400, color: colors.white }}
                        btnText={'Explore templates'}
                        backColor={colors.black}
                        // icon={images.WhatsAppWhite}
                        onclick={() => {
                          setSelectedItem('Explore Templates');
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          {/* <Plans/> */}
          {/* <Plans></Plans> */}
          <CustomTemplateStart
            customTemplateCateogary={customTemplateCateogary}
            updateCustomTemplateCateogary={updateCustomTemplateCateogary}
          />
        </>
      )}
    </>
  );
};

export default MyTemplates;
