import React, { useState } from 'react';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import CheerioButton from '../CheerioButton';
import { useSelector } from 'react-redux';
import { UpdateWATokenAPI } from '../../Services';

const WARefreshModal = ({ setIsWARefreshOpen }) => {
  const [WARefreshToken, setWARefreshToken] = useState('');
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);

  const UpdateTokenAPIcall = () => {
    let data = {
      user_id: localdata?._id,
      auth_token: WARefreshToken,
    };
    let token = authtoken;
    UpdateWATokenAPI(data, token).then((res) => {
      if (res?.flag) {
        console.log('success updating token ===> ', res);
        setTimeout(() => {
          setIsWARefreshOpen(false);
        }, 500);
      } else {
        console.log('error updating token ===> ', res);
      }
    });
  };

  return (
    <>
      <div
        className="DarkBg"
        onClick={() => {
          setIsWARefreshOpen(false);
        }}
      >
        <div className="Center">
          <div
            className="d-flex flex-column justify-content-between align-items-center"
            style={{
              width: 500,
              height: 300,
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 0,
              border: `1px solid ${colors.borderwhite}`,
              paddingBlock: 24,
              paddingInline: 30,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <h2
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 18,
                  fontWeight: 700,
                  color: colors.black,
                }}
              >
                {'Enter WhatsApp Authentication Token'}
              </h2>
              <img
                src={images.CMClose}
                style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                onClick={() => {
                  setIsWARefreshOpen(false);
                }}
              ></img>
            </div>
            <InputGroup className="my-2">
              <Form.Label style={{ fontSize: 14, fontWeight: 700, color: colors.black }}>
                {'Enter Token'}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter here"
                className="w-100 btncustom"
                style={{ borderRadius: 8 }}
                value={WARefreshToken}
                onChange={(e) => {
                  setWARefreshToken(e.target.value);
                }}
              />
            </InputGroup>
            <CheerioButton
              borderStyle={{
                marginBlock: 8,
                borderColor: colors.primary03,
                paddingInline: 40,
                paddingBlock: 10,
              }}
              textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
              btnText={'Save'}
              backColor={colors.primary03}
              disabled={WARefreshToken ? false : true}
              onclick={() => {
                console.log('next clicked . . .');
                UpdateTokenAPIcall();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WARefreshModal;
