import React, { useEffect, useState } from 'react'
import images from '../../../Utils/images'
import colors from '../../../Utils/colors'
import AddKeywords from './AddKeywords'
import ReplyAction from './ReplyAction'
import { eventsend } from '../../../Config/analyticsFunctions'

const KeywordsSidebarItem = ({ status = 'pending', itemText, onclickAction, containerStyle }) => {
    return (
        <>
            <div className='keywords_sidebar_item kwonhover'
                onClick={onclickAction}
                style={{ ...containerStyle }}
            >
                <img
                    className='check_image'
                    src={status === 'success' ? images.CircleCheck : images.CircleUncheckBlack}
                ></img>
                <p style={{
                    fontWeight: status == 'success' ? 500 : 400,
                }}>
                    {itemText}
                </p>
            </div>
        </>
    )
}

const AddKeywordAction = ({
    setAddKeywordAction,
    saveSuccessAction,
    existingDataKA,
}) => {

    const [addKeywords, setAddKeywords] = useState(true);
    const [addStatus, setAddStatus] = useState('pending');
    const [actionStatus, setActionStatus] = useState('pending');
    const [options, setOptions] = useState([
        {
            keywords: existingDataKA?.keywords ?? [],
            criteria: existingDataKA?.keywordCriteria ?? ''
        }
    ]);
    const [dataObject, setDataObject] = useState(existingDataKA?.data ?? {});

    useEffect(() => {
        if (existingDataKA?._id) {
            console.log("existingData ---> ", existingDataKA)
        }
    }, [existingDataKA])



    return (
        <>
            <div className='keywords_action_container'>
                <div className='keywords_action_container__sidebar'>
                    <div className='header_row'>
                        <img
                            className='image24'
                            src={images.BackArrowBlack}
                            onClick={() => { setAddKeywordAction(false) }}
                        ></img>
                        <h3>
                            {'Add keyword'}
                        </h3>
                    </div>
                    <KeywordsSidebarItem
                        itemText={'Add keyword'}
                        status={addStatus}
                        onclickAction={() => { setAddKeywords(true); eventsend("Add_keyword_Keywords_page_clicked"); }}
                        containerStyle={{ backgroundColor: addKeywords && '#F5F0FF' }}
                    />
                    <KeywordsSidebarItem
                        itemText={'Reply Action'}
                        status={actionStatus}
                        onclickAction={() => { setAddKeywords(false) }}
                        containerStyle={{ backgroundColor: !addKeywords && '#F5F0FF' }}
                    />
                </div>
                <div className='keywords_action_container__content'>
                    <div className='keywords_action_container__content__content_container'>
                        {addKeywords ? (
                            <>
                                <AddKeywords
                                    Options={options}
                                    SetOptions={setOptions}
                                    nextClickAction={() => {
                                        console.log("options data saved ---> ", options);
                                        setAddStatus('success')
                                        setAddKeywords(false)
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <ReplyAction
                                    options={options}
                                    dataObject={dataObject}
                                    setDataObject={setDataObject}
                                    saveSuccessAction={() => {
                                        setActionStatus('success');
                                        saveSuccessAction();
                                    }}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddKeywordAction