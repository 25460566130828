import React, { useEffect, useState, useRef } from 'react';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { Dropdown, Form, InputGroup } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { getClientAttributes, uploadExcelContactNew, uploadVendorsListNew } from '../../../Services';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { addCustomAttribute } from '../../../Services';
import { ToastContainer, toast } from 'react-toastify';
import { uploadContactNew } from '../../../Services';

const Mapping = ({ setStep, fetchedCSV, CSVfile, labelName, excelLabel, vendorsList }) => {
  const [options, setOptions] = useState(
    vendorsList ?
      [
        { label: 'Vendor Name', group: 'Reserved Fields' },
        { label: 'Vendor Email', group: 'Reserved Fields' },
        { label: 'Vendor Phone', group: 'Reserved Fields' },
        { label: 'Company Name', group: 'Reserved Fields' },
        { label: 'Product Name', group: 'Reserved Fields' }
      ] :
      [
        { label: 'Name', group: 'Reserved Fields' },
        { label: 'Email', group: 'Reserved Fields' },
        { label: 'Country Code', group: 'Reserved Fields' },
        { label: 'Phone', group: 'Reserved Fields' },
        { label: 'State', group: 'Reserved Fields' },
        { label: 'City', group: 'Reserved Fields' },
        { label: 'Country', group: 'Reserved Fields' },
        { label: 'Alternate Email', group: 'Reserved Fields' },
      ]
  );
  const [selectedOption, setSelectedOption] = useState(null);
  const [mappingData, setMappingData] = useState();
  const authtoken = useSelector((state) => state.main.auth_token);
  const [open, setOpen] = useState(false);
  const [unmappedCount, setUnmappedCount] = useState(0);
  const [optionMapping, setOptionMapping] = useState({});
  const [skipUnmapped, setSkipUnmapped] = useState(false);
  const [columnsCount, setColumnsCount] = useState(0);
  const [mappedData, setMappedData] = useState({});
  const [rowsCount, setRowsCount] = useState(0);
  const autocompleteRefs = useRef({});
  const [activeAutocomplete, setActiveAutocomplete] = useState(null);


  const indAttributesAPIcall = async () => {
    let token = authtoken;
    const response = await getClientAttributes(token);
    if (response?.flag) {
      const attributes = response?.data
        ?.map((item) => item?.attribute ? { label: item.attribute, group: 'Custom Attributes' } : null)
        ?.filter(Boolean);
      setOptions((prevOptions) => {
        const existingLabels = new Set(prevOptions.map((option) => option.label));
        const newAttributes = attributes.filter((attr) => !existingLabels.has(attr.label));
        return [...prevOptions, ...newAttributes];
      });
    } else {
    }
  };

  const findMatchingOption = (header) => {
    return options?.find(option =>
      option?.label?.toLowerCase() === header.toLowerCase()
    );
  };
  useEffect(() => {
    if (!vendorsList) {
      indAttributesAPIcall();
    }
  }, []);


  useEffect(() => {
    const mappingHelper = () => {
      const contacts = excelLabel ? fetchedCSV?.firstFiveRows : fetchedCSV?.contacts;
      const keys = Object?.keys(contacts[0]);
      const finalData = keys?.map((key) => ({
        key,
        value: contacts.map((contact) => contact[key] || ''),
      }));
      setMappingData(finalData);

      const initialMappings = {};
      const initialMappedData = {};
      keys?.forEach(key => {
        const matchingOption = findMatchingOption(key);
        if (matchingOption) {
          initialMappings[key] = matchingOption;
          initialMappedData[key] = matchingOption.label;
        }
      });
      setOptionMapping(initialMappings);
      setMappedData(initialMappedData);

      setUnmappedCount(finalData?.length - Object.keys(initialMappings).length);
      setColumnsCount(keys?.length);
    };
    mappingHelper();
  }, [fetchedCSV, options]);

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option?.label?.toLowerCase() || '',
  });

  const handleAddCustomField = () => {
    if (activeAutocomplete && autocompleteRefs.current[activeAutocomplete]) {
      autocompleteRefs.current[activeAutocomplete].blur();
    }
    setOpen(true);
  };

  const handleOptionChange = (newValue, key) => {
    setOptionMapping((prevMapping) => {
      const updatedMapping = { ...prevMapping, [key]: newValue };
      const unmappedTables = mappingData?.length - Object.keys(updatedMapping).length;
      setUnmappedCount(unmappedTables);
      return updatedMapping;
    });
    setMappedData((prevMappedData) => ({
      ...prevMappedData,
      [key]: newValue?.label
    }));

  };

  const handleUploadCSV = async () => {
    let form = new FormData();
    form.append('label', fetchedCSV?.fields?.label)
    form.append('files', CSVfile);
    form.append('mappedData', mappedData);
    form.append('foreignKey', 'loid')
    let data = {
      file: CSVfile,
      label: labelName,
      mappedData: mappedData,
    };
    let token = authtoken;
    // importContactsCSVApi(data, token).then((res) => {
    if (excelLabel) {
      const response = await uploadExcelContactNew(form, token);
    } else if (vendorsList) {
      const response = await uploadVendorsListNew(data, token);
    } else {
      const response = await uploadContactNew(data, token);
    }
  };

  const handleClearOption = (key) => {
    setOptionMapping((prevMapping) => {
      const updatedMapping = { ...prevMapping };
      delete updatedMapping[key];

      const updatedMappedData = { ...mappedData };
      delete updatedMappedData[key];
      setMappedData(updatedMappedData);
      const unmappedTables = mappingData?.length - Object.keys(updatedMapping).length;
      setUnmappedCount(unmappedTables);
      return updatedMapping;
    });
  };
  const emailFields = ['email', 'mailid', 'emailid'];
  const phoneFields = ['phone', 'mobile', 'phonenumber', 'number'];

  const mappedValues = Object.values(mappedData).map((value) => value?.toLowerCase());

  const hasEmailField = emailFields.some((field) => mappedValues.includes(field));
  const hasPhoneField = phoneFields.some((field) => mappedValues.includes(field));


  return (
    <div style={{ padding: '1% 2%', width: '82%', overflowY: 'auto' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2%',
        }}
      >
        <div>{`Let’s Map the columns in your uploaded ${excelLabel ? 'excel' : 'csv'} to your desired fields`}</div>
        <CheerioButton
          borderStyle={{ border: 'none', width: '10%' }}
          btnText={'Next'}
          backColor={colors.darkPurple}
          textStyle={{ color: colors.white }}
          onclick={() => {
            if (Object.keys(mappedData).length === 0) {
              toast.error('Please map atleast one field');
            } else if (!hasEmailField && !hasPhoneField && !vendorsList) {
              toast.error('Email or Phone number are required in mapping');
            } else {
              handleUploadCSV();
              setStep(3);
            }
          }}
          disabled={unmappedCount > 0 && !skipUnmapped}
        />
      </div>
      {excelLabel &&
        <>
          <div className='Row w-100 justify-content-start my-2'>
            <h4>
              {`Sheet 1 - ${fetchedCSV?.sheetNames[0]}`}
            </h4>
          </div>
        </>
      }
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}
          >
            <img src={images.InfoCircleGrey} alt="" height={18} />
            <p style={{ color: '#666666' }}>{`${columnsCount} Columns fetched`}</p>
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}
          >
            <Form.Group className="" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Skip unmapped columns"
                value={skipUnmapped}
                onChange={(e) => setSkipUnmapped(e.target.checked)}
              />
            </Form.Group>
            <p style={{ fontWeight: 700 }}>{`${unmappedCount} unmapped`}</p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          marginTop: '2%',
        }}
      >
        {mappingData?.map((contactData, index) => (
          <div
            key={index}
            style={{
              display: 'inline-block',
              minWidth: '400px',
              marginRight: '10px',
              minHeight: '400px',
            }}
          >
            <div style={{ ...styles.table }}>
              <div
                style={{
                  ...styles.tableHead,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingInline: 20,
                }}
              >
                <p style={{ fontWeight: 600 }}>{contactData?.key}</p>
                <div>
                  <img
                    src={!optionMapping[contactData.key] ? images.Danger : images?.SuccessDone}
                    alt=""
                    height={15}
                  />
                  <span
                    style={{
                      color: optionMapping[contactData.key] ? '#039600' : '#E50000',
                      marginLeft: 5,
                    }}
                  >
                    {optionMapping[contactData.key] ? 'Mapped' : 'Unmapped'}
                  </span>
                </div>
              </div>
              <div style={{ background: 'white', minHeight: '400px' }}>
                <div
                  style={{
                    padding: 10,
                    paddingInline: 20,
                    zIndex: 0,
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id={`combo-box-${index}`}
                    options={options}
                    value={optionMapping[contactData.key] || null}
                    onFocus={() => setOpen(false)}
                    groupBy={(option) => option.group}
                    getOptionLabel={(option) => option.label || ''}
                    filterOptions={(options, state) => {
                      // Always return all filtered options
                      return filterOptions(options, state);
                    }}
                    style={{ marginBottom: '5px', zIndex: 0 }}
                    className="w-100 btncustom"
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Field to be mapped"
                        inputRef={(ref) => {
                          autocompleteRefs.current[index] = ref;
                        }}
                        style={{ zIndex: 0 }}
                        InputLabelProps={{
                          ...params.InputLabelProps,
                          shrink: optionMapping[contactData.key] !== undefined,
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue === null) {
                        handleClearOption(contactData.key);
                      } else {
                        handleOptionChange(newValue, contactData.key);
                      }
                    }}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        key={option.label}
                        style={{
                          color: optionMapping[contactData.key] === option ? 'gray' : 'black',
                          zIndex: 0,
                        }}
                      >
                        {option.label}
                      </li>
                    )}
                    renderGroup={(params) => {
                      const isReservedGroup = params.group === 'Reserved Fields';
                      return [
                        <li key={params.key} {...params}>
                          <div
                            style={{
                              fontWeight: 'bold',
                              borderBottom: '1px solid #ddd',
                              paddingBottom: '4px',
                              paddingInline: '2%',
                            }}
                          >
                            {params.group}
                          </div>
                          <ul>{params.children}</ul>
                        </li>,
                        isReservedGroup && (
                          <li key="add-button">
                            <button
                              style={{
                                color: colors.linkblue,
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                padding: 10,
                              }}
                              onClick={() => {
                                handleAddCustomField();
                                autocompleteRefs.current[index].blur();
                              }}
                            >
                              Add Custom Field
                            </button>
                          </li>
                        ),
                      ];
                    }}
                  />
                </div>
                {contactData?.value?.map((val, valIndex) => (
                  <div key={valIndex} style={{ padding: '20px 20px' }}>
                    {val}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      {excelLabel &&
        <>
          <SecondaryContacts
            fetchedCSV={fetchedCSV}
          />
        </>
      }
      {open && (
        <div className="sidebar" style={open ? sidebarOpenStyle : sidebarStyle}>
          <AddNewAttribute setSelected={setOpen} indAttributesAPIcall={indAttributesAPIcall} />
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

const SecondaryContacts = ({ fetchedCSV }) => {

  const columnsCount = Object.keys(fetchedCSV?.secondFiveRows[0]).length;
  const mappingData = Object?.keys(fetchedCSV?.secondFiveRows[0])?.map((key) => ({
    key,
    value: fetchedCSV?.secondFiveRows?.map((contact) => contact[key] || '')
  }))

  return (
    <>
      <div className='Row w-100 justify-content-start mt-4 mb-2'>
        <h4>
          {`Sheet 2 - ${fetchedCSV?.sheetNames[1]}`}
        </h4>
      </div>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}
          >
            <img src={images.InfoCircleGrey} alt="" height={18} />
            <p style={{ color: '#666666' }}>{`${columnsCount} Columns fetched`}</p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          marginTop: '2%',
        }}
      >
        {mappingData?.map((contactData, index) => (
          <div
            key={index}
            style={{
              display: 'inline-block',
              minWidth: '400px',
              marginRight: '10px',
              minHeight: '400px',
            }}
          >
            <div style={{ ...styles.table }}>
              <div
                style={{
                  ...styles.tableHead,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingInline: 20,
                }}
              >
                <p style={{ fontWeight: 600 }}>{contactData?.key}</p>
                {/* <div>
                  <img
                    src={!optionMapping[contactData.key] ? images.Danger : images?.SuccessDone}
                    alt=""
                    height={15}
                  />
                  <span
                    style={{
                      color: optionMapping[contactData.key] ? '#039600' : '#E50000',
                      marginLeft: 5,
                    }}
                  >
                    {optionMapping[contactData.key] ? 'Mapped' : 'Unmapped'}
                  </span>
                </div> */}
              </div>
              <div style={{ background: 'white', minHeight: '400px' }}>
                {/* <div
                  style={{
                    padding: 10,
                    paddingInline: 20,
                    zIndex: 0,
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id={`combo-box-${index}`}
                    options={options}
                    value={optionMapping[contactData.key] || null}
                    onFocus={() => setOpen(false)}
                    groupBy={(option) => option.group}
                    getOptionLabel={(option) => option.label || ''}
                    filterOptions={(options, state) => {
                      // Always return all filtered options
                      return filterOptions(options, state);
                    }}
                    style={{ marginBottom: '5px', zIndex: 0 }}
                    className="w-100 btncustom"
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Field to be mapped"
                        inputRef={(ref) => {
                          autocompleteRefs.current[index] = ref;
                        }}
                        style={{ zIndex: 0 }}
                        InputLabelProps={{
                          ...params.InputLabelProps,
                          shrink: optionMapping[contactData.key] !== undefined,
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue === null) {
                        handleClearOption(contactData.key);
                      } else {
                        handleOptionChange(newValue, contactData.key);
                      }
                    }}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        key={option.label}
                        style={{
                          color: optionMapping[contactData.key] === option ? 'gray' : 'black',
                          zIndex: 0,
                        }}
                      >
                        {option.label}
                      </li>
                    )}
                    renderGroup={(params) => {
                      const isReservedGroup = params.group === 'Reserved Fields';
                      return [
                        <li key={params.key} {...params}>
                          <div
                            style={{
                              fontWeight: 'bold',
                              borderBottom: '1px solid #ddd',
                              paddingBottom: '4px',
                              paddingInline: '2%',
                            }}
                          >
                            {params.group}
                          </div>
                          <ul>{params.children}</ul>
                        </li>,
                        isReservedGroup && (
                          <li key="add-button">
                            <button
                              style={{
                                color: colors.linkblue,
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                padding: 10,
                              }}
                              onClick={() => {
                                handleAddCustomField();
                                autocompleteRefs.current[index].blur();
                              }}
                            >
                              Add Custom Field
                            </button>
                          </li>
                        ),
                      ];
                    }}
                  />
                </div> */}
                {contactData?.value?.map((val, valIndex) => (
                  <div key={valIndex} style={{ padding: '20px 20px' }}>
                    {val}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

const AddNewAttribute = ({ setSelected, indAttributesAPIcall }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const [AttrField, setAttrField] = useState('');
  const [AttrValue, setAttrValue] = useState('');

  const handleAddAttribute = async () => {
    try {
      let token = authtoken;
      const response = await addCustomAttribute(AttrValue, token);
      if (response?.flag) {
        setSelected(false);
        toast.success(response?.message);
        indAttributesAPIcall();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <div
        className="h-100 w-100 d-flex flex-column justify-content-start align-items-center"
        style={{ overflowY: 'auto', paddingInline: 24, paddingBlock: 20 }}
      >
        <div className="d-flex flex-row justify-content-start align-items-center w-100">
          <img
            src={images.CMClose}
            style={{
              height: 24,
              width: 24,
              objectFit: 'contain',
              cursor: 'pointer',
              marginInlineEnd: 16,
            }}
            onClick={() => setSelected(false)}
          ></img>
          <p style={{ fontSize: 16, fontWeight: 700 }}>{'Add new custom attribute'}</p>
        </div>
        <div className="py-4 w-100">
          <p style={{ fontSize: 14, fontWeight: 400, color: colors.greys04 }}>
            {
              'Only alphanumeric characters (letter A-Z, numbers 0-9) and underscores are allowed. You cannot use reserved field names.'
            }
          </p>
        </div>
        <InputGroup className="mb-3 w-100">
          <Form.Label style={{ fontSize: 16, fontWeight: 600 }}>
            {'Enter Attribute Name'}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter attribute name"
            className="w-100 btncustom"
            style={{ borderRadius: 8 }}
            value={AttrValue}
            onChange={(e) => {
              setAttrValue(e.target.value);
            }}
          />
        </InputGroup>
        <div className="d-flex flex-row justify-content-around align-items-center w-100 mt-4">
          <CheerioButton
            borderStyle={{ border: `1px solid ${colors.black}`, width: '40%' }}
            textStyle={{}}
            btnText={'Cancel'}
            backColor={colors.white}
            onclick={() => setSelected(false)}
          />
          <CheerioButton
            borderStyle={{ border: `1px solid ${colors.primary03}`, width: '40%' }}
            textStyle={{ color: colors.white }}
            btnText={'Add attribute'}
            backColor={colors.primary03}
            disabled={AttrValue?.length > 0 ? false : true}
            onclick={handleAddAttribute}
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

const sidebarStyle = {
  position: 'fixed',
  top: 0,
  right: 0,
  width: '450px',
  height: '100%',
  backgroundColor: 'white',
  boxShadow: '-2px 0 5px rgba(0,0,0,0.5)',
  transform: 'translateX(100%)',
  transition: 'transform 0.3s ease',
  zIndex: '20 !important',
};

const sidebarOpenStyle = {
  ...sidebarStyle,
  transform: 'translateX(0)',
};

const styles = {
  tableHead: {
    backgroundColor: '#F0F2F2',
    padding: '10px',
    textAlign: 'left',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableRow: {
    borderBottom: '1px solid #ddd',
  },
  tableCell: {
    padding: '8px',
    textAlign: 'left',
  },
};

export default Mapping;
