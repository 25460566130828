import React from 'react';
import colors from '../../../Utils/colors';
import { IoIosArrowForward } from 'react-icons/io';
import images from '../../../Utils/images';
import { CiClock2 } from 'react-icons/ci';

const AccordianItems = ({
  ItemImage,
  ItemHeader,
  ItemText,
  ActionText,
  onclickAction,
  completed = false,
}) => {
  const MobileWidth = window.innerWidth < 700 ? true : false;

  return (
    <>
      <div
        className="hoverShadow me-3 d-flex flex-row justify-content-between align-items-stretch"
        style={{
          width: 250,
          border: `1px solid ${colors.borderwhite}`,
          borderRadius: 10,
          backgroundColor: colors.white01,
          paddingInline: 12,
          paddingBlock: 12,
          // marginBlockEnd: MobileWidth ? 12 : 0,
          marginBlockEnd: MobileWidth ? 12 : 8,
        }}
      >
        <img
          className="me-2"
          src={ItemImage}
          style={{ height: 30, width: 30, objectFit: 'contain' }}
        ></img>
        <div
          className="d-flex flex-column justify-content-start align-items-start"
          style={{ flex: 1 }}
        >
          <h4 style={{ color: colors.black }}>{ItemHeader}</h4>
          <p className="mt-1 mb-2" style={{ color: colors.greys04 }}>
            {ItemText}
          </p>
          <div
            className="mt-auto d-flex flex-row justify-content-between align-items-center w-100 mb-0"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (!completed) {
                onclickAction();
              }
            }}
          >
            {ItemHeader === 'SMS' ? (
              completed === 'pending' ? (
                <div className="d-flex" style={{}}>
                  <CiClock2
                    style={{
                      height: 20,
                      width: 20,
                      objectFit: 'contain',
                      color: '#FEAF52',
                      marginRight: '5px',
                      marginTop: '2px',
                    }}
                  />
                  <p style={{ color: '#FEAF52' }}>{'Request processing'}</p>
                </div>
              ) : completed === 'completed' ? (
                <>
                  <p style={{ color: colors.success03 }}>{'Completed'}</p>
                  <img
                    src={images.SUCCESSFULL}
                    style={{ height: 20, width: 20, objectFit: 'contain' }}
                  ></img>
                </>
              ) : (
                <>
                  <p style={{ color: colors.themeDarkBlue }}>{ActionText}</p>
                  <IoIosArrowForward color={colors.themeDarkBlue} />
                </>
              )
            ) : completed ? (
              <>
                <p style={{ color: colors.success03 }}>{'Completed'}</p>
                <img
                  src={images.SUCCESSFULL}
                  style={{ height: 20, width: 20, objectFit: 'contain' }}
                ></img>
              </>
            ) : (
              <>
                <p style={{ color: colors.themeDarkBlue }}>{ActionText}</p>
                <IoIosArrowForward color={colors.themeDarkBlue} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccordianItems;
