import React, { useEffect, useRef, useState } from 'react';
import '../styles.css';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { useSelector } from 'react-redux';
import HEImages from './HEImages';
import { getImagePath } from '../../../Utils';
import { aiConversation, aiHistory, aiImage } from '../../../Services';
import { toast } from 'react-toastify';
import { CardImg } from 'react-bootstrap';
import { Tooltip } from '@mui/material';
import SpinnerLoader, { NewLoader } from '../../../Components/SpinnerLoader';
import { FaRegCopy } from 'react-icons/fa';
import { eventsend } from '../../../Config/analyticsFunctions';
export const GptComponent = ({ setShow }) => {
  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);
  const agentdata = useSelector((state) => state.main.agentdata);
  const [generateBtn, setGenerateBtn] = useState(false);
  const [step1, setStep1] = useState(false);
  const user = agentdata ?? localdata;
  const messagesEndRef = useRef();
  const [toggleCheck, setToggleCheck] = useState(false);
  const [loader, setLoader] = useState(true);
  const [chat, setChat] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    aiHistoryApiCall();
    eventsend("Cheerio_GPT_Home_page_clicked");
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
  }, [chat]);

  const aiHistoryApiCall = () => {
    aiHistory(authtoken).then((res) => {
      if (res?.flag) {
        setChat(res?.data);
        if (res?.data.length > 0) {
          setStep1(true);
        }
        setLoader(false);
      } else {
        toast.error(res?.message, { position: 'top-center' });
        setLoader(false);
      }
    });
  };

  const aiConversationApiCall = () => {
    const data = {
      query: query,
    };
    aiConversation(authtoken, data).then((res) => {
      if (res?.flag) {
        setChat((prev) => {
          const updatedChat = [...prev];
          const lastIndex = updatedChat.length - 1;
          if (lastIndex >= 0) {
            updatedChat[lastIndex] = {
              ...updatedChat[lastIndex],
              content: res?.data,
              role: 'assistant',
              isError: false,
            };
          }
          return updatedChat;
        });
        //messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
        setGenerateBtn(false);
      } else {
        setChat((prev) => {
          const updatedChat = [...prev];
          const lastIndex = updatedChat.length - 1;
          if (lastIndex >= 0) {
            updatedChat[lastIndex] = {
              ...updatedChat[lastIndex],
              content:
                'Due to high demand, we were not able to resolve your request, kindly try after sometime. If the issues still persists let your account manager from Cheerio know.',
              role: 'assistant',
              isError: true,
            };
          }
          return updatedChat;
        });
        // messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
        setGenerateBtn(false);
      }
    });
  };

  const aiImageApiCall = () => {
    const data = {
      query: query,
    };
    aiImage(authtoken, data).then((res) => {
      if (res?.flag) {
        setChat((prev) => {
          const updatedChat = [...prev];
          const lastIndex = updatedChat.length - 1;
          if (lastIndex >= 0) {
            updatedChat[lastIndex] = {
              ...updatedChat[lastIndex],
              imgData: res?.data,
              role: 'assistant',
              isError: false,
              msgType: 'image',
            };
          }
          return updatedChat;
        });
        setGenerateBtn(false);

        //messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
      } else {
        setChat((prev) => {
          const updatedChat = [...prev];
          const lastIndex = updatedChat.length - 1;
          if (lastIndex >= 0) {
            updatedChat[lastIndex] = {
              ...updatedChat[lastIndex],
              content:
                'Due to high demand, we were not able to resolve your request, kindly try after sometime. If the issues still persists let your account manager from Cheerio know.',
              role: 'assistant',
              isError: true,
            };
          }
          return updatedChat;
        });
        setGenerateBtn(false);
      }
    });
  };

  return (
    <div
      className="gptComponent"
      style={{ overflowY: 'auto', overflowX: 'hidden', scrollbarWidth: 'thin' }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="d-flex justify-content-between" style={{}}>
        <div className="d-flex">
          <img
            src={images.CMClose}
            onClick={() => {
              setShow(false);
            }}
            style={{
              cursor: 'pointer',
              height: 22,
              width: 22,
              marginTop: '7px',
              marginRight: '5px',
            }}
          ></img>
          <p
            style={{
              fontWeight: '700',
              fontSize: '20px',
              marginTop: '2px',
              verticalAlign: 'baseline',
            }}
          >
            Cheerio AI
          </p>
        </div>
        <div
          className="d-flex align-items-center"
          style={{
            backgroundColor: '#F0F0F0',
            padding: '4px 10px 4px 10px',
            //paddingLeft: '10px',
            //paddingRight: '10px',

            borderRadius: '10px',
          }}
        >
          <p style={{ fontWeight: '600', marginRight: '7px' }}>Text</p>
          <div class="form-check form-switch" style={{ paddingTop: '2px' }}>
            <input
              class="form-check-input shadow-none"
              // style={{ backgroundColor: '#8E53F7' }}
              id="gptToggle"
              type="checkbox"
              role="switch"
              checked={toggleCheck}
              onChange={() => {
                setToggleCheck(!toggleCheck);
              }}
            ></input>
          </div>
          <p style={{ fontWeight: '600' }}>Image</p>
        </div>
      </div>
      <hr></hr>
      {loader ? (
        <div
          className="d-flex flex-column align-items-center"
          style={{ marginTop: '40%', width: '100%', overflowY: 'hidden' }}
        >
          <NewLoader></NewLoader>
        </div>
      ) : !step1 ? (
        <div className="d-flex align-items-center flex-column" style={{ marginTop: '20%' }}>
          <img
            style={{
              position: 'relative',
              height: '60px',
              zIndex: 1,
            }}
            src={images.GPTNEW}
          ></img>
          {/* <img
            style={{
              position: 'sticky',
              height: '45px',
              marginTop: '-50px',
              zIndex: 1,
            }}
            src={images.GPTGIF}
          ></img> */}
          <p style={{ fontWeight: '700', fontSize: '20px', marginTop: '8px' }}>
            Hey, how can I help?
          </p>
          <p style={{ fontWeight: '400', fontSize: '14px', color: '#808080' }}>
            Limited edition feature
          </p>
        </div>
      ) : (
        <div
          style={{
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            height: '72%',
            marginTop: '10px',
          }}
        >
          {chat.length > 0 &&
            chat.map((item) => {
              return (
                <div
                  ref={messagesEndRef}
                  className={`d-flex flex-row  ${item?.role === 'user' ? `justify-content-end` : 'justify-content-start'
                    }  py-2 mx-auto`}
                  style={{ marginTop: '20px' }}
                >
                  <div style={{ maxWidth: '78%' }}>
                    {item?.role === 'user' ? (
                      <div className="" style={{ border: '' }}>
                        <div
                          className={`d-flex justify-content-end
                       `}
                        >
                          <HEImages
                            style={{ height: '20px', marginTop: '2px', marginRight: '5px' }}
                            userGeneratedSrc={
                              agentdata?.profileImg
                                ? agentdata?.profileImg
                                : getImagePath(localdata?.picture)
                            }
                          />
                          <p style={{ marginRight: '10px', fontWeight: '700' }}>You</p>
                        </div>
                        <p
                          style={{
                            width: '100%',
                            border: '',
                            display: 'block',
                            padding: '0px',
                          }}
                        >
                          {item?.content}
                        </p>
                      </div>
                    ) : (
                      <div
                        className={`d-flex flex-row justify-content-start
                       `}
                        style={{ border: '' }}
                      >
                        <img
                          style={{
                            position: '',
                            height: '24px',
                            zIndex: 1,
                          }}
                          src={images.GPTNEW}
                        ></img>
                        {/* <img
                          style={{
                            position: 'sticky',
                            height: '18px',
                            marginTop: '2px',
                            zIndex: 1,
                            marginLeft: '-21px',
                            opacity: '1',
                          }}
                          src={images.GPTGIF}
                        ></img> */}
                        <div>
                          <p style={{ marginLeft: '10px', fontWeight: '700' }}>Cheerio AI</p>
                          {item?.msgType === 'image' ? (
                            <div className="container">
                              <div className="row">
                                {item?.imgData.map((image, index) => (
                                  <React.Fragment key={index}>
                                    <div className="col" style={{ padding: '0px' }}>
                                      <img
                                        src={image?.url}
                                        onClick={() => {
                                          window.open(image?.url, '_blank');
                                        }}
                                        className="img-fluid"
                                        style={{
                                          width: '200px',
                                          height: '200px',
                                          objectFit: 'cover',
                                          cursor: 'pointer',
                                          borderRadius: '8px',
                                        }}
                                      />
                                      {/* <Tooltip title="Download">
                                        <img
                                          onClick={async () => {
                                            const blob = await fetch(image?.url, {
                                              method: 'GET',
                                              headers: {
                                                'Content-Type': 'application/json',
                                              },
                                            }).then((res) => {
                                              console.log('Response:', res);
                                              return res.blob();
                                            });

                                            const url = window.URL.createObjectURL(blob);
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', 'Downloaded.png');
                                            document.body.appendChild(link);
                                            link.click();
                                            toast.success('File downloaded', {
                                              position: 'top-center',
                                            });
                                          }}
                                          src={images.Download_CSV}
                                          style={{
                                            cursor: 'pointer',
                                            height: '28px',
                                            width: '28px',
                                            marginRight: '10px',
                                            marginTop: '2px',
                                          }}
                                        ></img>
                                      </Tooltip> */}
                                    </div>
                                    {(index + 1) % 2 === 0 && (
                                      <div className="w-100" style={{ margin: '2px' }}></div>
                                    )}
                                  </React.Fragment>
                                ))}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <p
                                style={{
                                  marginBottom: '2px',
                                  marginLeft: '10px',
                                  color: item?.isError ? 'red' : 'black',
                                }}
                              >
                                {item?.content}
                              </p>
                              <Tooltip title="Copy">
                                {''}
                                <FaRegCopy
                                  style={{
                                    height: 25,
                                    width: 25,
                                    objectFit: 'contain',
                                    marginInline: 8,
                                    cursor: 'pointer',
                                    color: '#8E53F7',
                                    marginTop: '2px',
                                  }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(item?.content);
                                    toast.success('Copied to clipboard');
                                  }}
                                ></FaRegCopy>
                              </Tooltip>
                            </div>
                          )}
                          {/* {item?.type ==='Image' ? <CardImg
                              src={
                                _ ? getImagePath(_msg.image) : getImagePath(_msg.fileURL)
                              }
                              style={{
                                maxHeight: 200,
                                objectFit: 'cover',
                                borderRadius: 8,
                                marginBottom: 8,
                              }}
                            />:<p style={{ marginLeft: '10px', color: item?.isError ? 'red' : 'black' }}>
                            {item?.msg}
                          </p>} */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}
      <div
        style={{
          bottom: '10px',
          width: '95%',
          position: 'absolute',
        }}
      >
        {!step1 && !loader && (
          <div style={{ marginBottom: '10px' }}>
            <div className="d-flex">
              <img
                style={{
                  position: '',
                  height: '25px',
                  zIndex: 1,
                }}
                src={images.GPTNEW}
              ></img>
              {/* <img
                style={{
                  position: 'absolute',
                  height: '23px',
                  left: '2px',
                  top: '2px',
                  zIndex: 1,
                  opacity: '1',
                }}
                src={images.GPTGIF}
              ></img> */}
              <p style={{ marginLeft: '10px', color: '#666666', fontSize: '13px' }}>
                Hello {user?.name}, I can help you resolve your queries, plan campaigns, generate
                templates & media creatives.
              </p>
            </div>
          </div>
        )}

        {!loader && (
          <div className="generate-div">
            <textarea
              class="generate-field"
              placeholder="Ask Cheerio AI"
              value={query}
              onClick={(e) => { }}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              onSelect={() => {
                // commentBoxRef.current.scrollIntoView({ behaviour: 'smooth' });
              }}
            ></textarea>
            <CheerioButton
              borderStyle={{
                borderColor: colors.primary03,
                paddingInline: 10,
                paddingBlock: 4,
                alignSelf: 'flex-end',
              }}
              textStyle={{ color: colors.white }}
              btnText={'Generate'}
              backColor={colors.primary03}
              disabled={generateBtn}
              onclick={() => {
                // e.preventDefault();
                eventsend("Prompt_sent_Clicked");
                if (query) {
                  //postCommentApiCall();
                  setGenerateBtn(true);
                  setChat((prev) => {
                    const obj = {
                      content: query,
                      role: 'user',
                    };
                    const obj2 = {
                      content: 'Generating...',
                      role: 'assistant',
                    };
                    return [...prev, obj, obj2];
                  });
                  if (toggleCheck) {
                    aiImageApiCall();
                  } else {
                    aiConversationApiCall();
                  }
                  setQuery('');
                  setStep1(true);
                } else {
                  toast.error('Please enter a query', { position: 'top-center' });
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
