import colors from '../../Utils/colors';

export const Styles = {
  DarkBg: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    width: '100vw',
    height: '100vh',
    // flex:1,
    zIndex: 1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'fixed',
  },
  Center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  TxtHeading: {
    fontSize: 24,
    fontWeight: 700,
    color: colors.black,
  },
  TxtHeading2: {
    fontSize: 16,
    fontWeight: 700,
    color: colors.black,
  },
  TxtBody2: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.greys04,
  },
  TxtBody1: {
    fontSize: 16,
    fontWeight: 400,
    color: colors.primary03,
  },
  borderstyle: {
    backgroundColor: colors.white,
    borderRadius: 16,
    boxShadow: '0px 0px 10px 0 rgba(0, 0, 0, 0.15)',
  },
  borderDashed: {
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: colors.primary04,
    borderRadius: 40,
  },
  TxtBody: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.black04,
  },
};
