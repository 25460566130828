import { BASE_CHAT_IMAGE_URL, BASE_IMAGE_URL, BASE_VIDEO_URL } from '../Config/axiosConfig';

export const getImagePath = (data, isChat = false) => {
  // let baseURL = "http://192.168.1.8:3000/";
  // let	baseURL = "http://3.137.121.138:3000/";
  if (data) {
    if (data.toString().slice(0, 4) === 'data') {
      return data;
    } else if (data.toString().slice(0, 4) === 'http') {
      return data;
    } else {
      return `${(isChat ? BASE_CHAT_IMAGE_URL : BASE_IMAGE_URL) + data}`;
    }
  } else {
    return ``;
  }
};

export const getVideoPath = (data) => {
  // let baseURL = "http://192.168.1.8:3000/";
  // let	baseURL = "http://3.137.121.138:3000/";
  if (data) {
    return { uri: `${BASE_VIDEO_URL + data}` };
  } else {
    return { uri: `` };
  }
};
