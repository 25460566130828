import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {
  GetTemplatesV2API,
  saveReinitiateTemplate,
  saveResponse,
  sendWhatsAppTemplate,
  updateResponse,
} from '../../../Services';
import { useSelector } from 'react-redux';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { Modal } from 'react-bootstrap';
import AnnDetailsWF from '../../WorkFlow/Modals/AnnDetailsWF';
import '../styles.css';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { eventsend } from '../../../Config/analyticsFunctions';

const ShortcutModal = ({
  show,
  setShow,
  shortcutName,
  setShortcutName,
  saveResponseApiCall,
  setApiLoading,
}) => {
  return (
    <div>
      <Modal show={show} dialogClassName="shortcutModal">
        <Modal.Body style={{ padding: '0px' }}>
          <div
            className="d-flex flex-row justify-content-end px-3"
            style={{ alignItems: 'center', paddingBlockStart: '10px' }}
          >
            <img
              src={images.CMClose}
              onClick={() => {
                setShow(false);
              }}
              style={{
                cursor: 'pointer',
                height: 18,
                width: 18,
              }}
            ></img>
          </div>
          <div className="px-3">
            <label
              for="exampleFormControlInput1"
              style={{
                fontWeight: '700',
                fontSize: '14px',
                color: 'black',
                marginBlockStart: '0px',
              }}
            >
              Shortcut{' '}
            </label>
            <input
              class="form-control shadow-none"
              placeholder="Type shortcut for template"
              value={shortcutName}
              onChange={(e) => {
                setShortcutName(e.target.value);
              }}
            />
          </div>
          <div className="d-flex justify-content-between px-3 my-3">
            <CheerioButton
              btnText={'Save'}
              textStyle={{ color: colors.white, fontWeight: '600' }}
              backColor={colors.black}
              borderStyle={{
                borderWidth: 0,
                width: '48%',
                borderRadius: '8px',
              }}
              onclick={() => {
                if (shortcutName) {
                  setApiLoading(true);
                  setShow(false);

                  saveResponseApiCall();
                } else {
                  toast.error('Please enter a shortcut name!');
                }
              }}
            />
            <CheerioButton
              btnText={'Discard'}
              textStyle={{ color: colors.black, fontWeight: '600' }}
              backColor={colors.white}
              borderStyle={{
                border: '1px solid black',
                width: '48%',
                borderRadius: '8px',
              }}
              onclick={() => {
                setShow(false);
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const SendTemplateModal = ({
  data,
  setData,
  setBtnDisabled,
  prevNode,
  show,
  setShow,
  saveTemplate = false,
  attributesList,
  existingData,
  responseId,
  matchResponseShow = false,
  reinitiate = false,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [templateData, setTemplateData] = useState([]);

  const [ItemV2, setItemV2] = useState([]);
  // const [mediaIDV2, setMediaIDV2] = useState('');
  const [headerTypeV2, setHeaderTypeV2] = useState('');
  const [headerFileNameV2, setHeaderFileNameV2] = useState('');
  // const [URLValueV2, setURLValueV2] = useState('');
  const [BodyParV2, setBodyParV2] = useState([]);
  // const [ImageV2, setImageV2] = useState('');
  // const [mpm, setMpm] = useState(false);
  const [s3Link, setS3Link] = useState('');

  const [actionList, setActionList] = useState({});
  const [headerInputText, setHeaderInputText] = useState('');
  // const [headerInputExists, setHeaderInputExists] = useState(false);

  // const [posURL, setPosURL] = useState(null);
  const [annv2CTA, setAnnv2CTA] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [carouselCard, setCarouselCard] = useState([]);

  // const [existingData, setExistingData] = useState();
  const [quickReplyBtn, setQuickReplyBtn] = useState([]);
  const [openShortcutModal, setOpenShortcutModal] = useState(false);
  const [shortcutName, setShortcutName] = useState('');
  const [apiLoading, setApiLoading] = useState(false);
  const authtoken = useSelector((state) => state.main.auth_token);
  const mobileWidth = window.innerWidth < 900 ? true : false;
  const [selectedOption, setSelectedOption] = useState(null);
  const [headerOption, setHeaderOption] = useState(data?.headerOption ?? 'uploadShopify');

  const localdata = useSelector((state) => state.main.localdata);
  const agentdetails = useSelector((state) => state.main.agentdata);

  useEffect(() => {
    // setExistingData(data);
    eventsend("Send_template_Inbox_page_clicked");
    GetTemplatesV2APIcall();
  }, []);

  useEffect(() => {
    if (templateData) {
      // console.log("useeffect called......",existingData?.templateID)
      if (existingData?.templateID) {
        setSelectedOption(existingData?.templateName);
        setSelectedTemplate(existingData?.templateID);
      }
    }
  }, [templateData]);

  useEffect(() => {
    if (ItemV2) {
      // setTimeout(() => {
      //     loadExistingData();
      // }, 2000);
      // setTimeout(() => {
      loadExistingData();
      // }, 2000);
    }
  }, [ItemV2]);

  const loadExistingData = () => {
    if (existingData) {
      setShortcutName(existingData?.nickName);
      setHeaderTypeV2(existingData?.header?.type);
      setHeaderFileNameV2(existingData?.header?.fileName);
      setBodyParV2(existingData?.bodyParameters);
      setActionList(existingData?.mpm);
      setHeaderInputText(existingData?.header?.text);
      // console.log("culprit 1....")
      setAnnv2CTA(existingData?.cta);
      setS3Link(existingData?.header?.imgeURL);
      setCarouselCard(existingData?.carouselCards);
    }
  };

  // useEffect(()=>{
  //     if(data){
  //         setSelectedTemplate(data?.templateID);
  //         setMediaIDV2(data?.header?.mediaId);
  //         setHeaderTypeV2(data?.header?.type);
  //         setHeaderFileNameV2(data?.header?.fileName);
  //         setURLValueV2(data?.cta?.value);
  //         setBodyParV2(data?.bodyParameters);
  //         setActionList(data?.mpm);
  //         setHeaderInputText(data?.header?.text)
  //         setPosURL(data?.cta?.index)
  //         setAnnv2CTA(data?.cta)
  //         setS3Link(data?.header?.imgeURL)
  //     }
  // },[])

  // useEffect(() => {
  //   // console.log("data changed ===> ",data);
  // }, [data]);
  //console.log('dddd', prevNode);
  useEffect(() => {
    // setData({
    //   ...data,
    //   templateName: ItemV2[0]?.name,
    //   templateID: selectedTemplate,
    //   language_code: ItemV2[0]?.language,

    //   cta: annv2CTA,
    //   header: {
    //     type: headerTypeV2,
    //     text: headerInputText,
    //     imgeURL: s3Link,
    //     fileName: headerFileNameV2 ? headerFileNameV2 : null,
    //   },
    //   bodyParameters: BodyParV2,
    //   quickReplyButtons: quickReplyBtn,
    //   mpm: actionList,
    // });
    checkValidation();
  }, [
    selectedTemplate,
    headerTypeV2,
    headerFileNameV2,
    BodyParV2,
    actionList,
    headerInputText,
    ItemV2,
    annv2CTA,
    s3Link,
    quickReplyBtn,
    carouselCard,
  ]);

  const checkValidation = () => {
    let ctaValid = annv2CTA?.filter((item, index) => {
      return item.value === '';
    });
    let bodyParValid = BodyParV2?.filter((item, index) => {
      return item === '';
    });
    const header = {
      type: headerTypeV2,
      text: headerInputText,
      imgeURL: s3Link,
      fileName: headerFileNameV2 ? headerFileNameV2 : null,
    };
    let headerValid =
      (headerTypeV2 && s3Link && headerFileNameV2) ||
      (headerTypeV2 === 'text' && headerInputText) ||
      (headerTypeV2 === 'text' && !headerInputText) ||
      data?.headerOption === 'fetchShopify' || data?.headerOption === 'fetchWoo' ||
      headerTypeV2 === 'NONE';
    let mpmValid =
      actionList?.index &&
      actionList?.thumbnail_product_retailer_id &&
      actionList?.sections?.length !== 0 &&
      actionList?.sections?.filter((item, index) => {
        return !item?.title || item?.product_items?.length === 0;
      }).length !== 0;
    let carouselValid =
      carouselCard?.length === 0 ||
      carouselCard?.filter((item, index) => {
        return (
          item?.header?.link === '' ||
          !item?.bodyParameters ||
          item?.bodyParameters?.includes('', 0)
        );
      })?.length === 0;

    if (
      ItemV2[0]?.name &&
      selectedTemplate &&
      ItemV2[0]?.language &&
      ((annv2CTA && ctaValid?.length === 0) || !annv2CTA?.cta) &&
      header &&
      headerValid &&
      ((BodyParV2 && bodyParValid?.length === 0) || !BodyParV2) &&
      ((actionList && mpmValid) || actionList?.index === null || !actionList) &&
      carouselCard &&
      carouselValid
    ) {
      setApiLoading(false);
    } else {
      setApiLoading(true);
    }
  };

  useEffect(() => {
    if (selectedTemplate && templateData) {
      // console.log("set item useeffect called.....")
      setIsLoading(true);
      setItemV2(templateData.filter((e) => e.id === selectedTemplate));
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, [selectedTemplate, templateData]);

  const GetTemplatesV2APIcall = () => {
    setIsLoading(true);
    let token = authtoken;
    let data = {
      limit: 500,
    };
    GetTemplatesV2API(data, token).then((res) => {
      if (res) {
        console.log('TemplatesV2API response: ' + res);
        setTemplateData(res?.data);
        //if (!existingData?.templateID) {
        const filteredTemplates = res?.data.filter((item, index) => {
          return item?.status === 'APPROVED';
        });
        setIsLoading(false);
        // setSelectedTemplate(filteredTemplates[0]?.id);
        //}
      } else {
        console.log('errror: ' + res);
        setIsLoading(false);
      }
    });
  };

  const saveResponseApiCall = () => {
    toast.loading('Saving Template', { toastId: 3 });

    const data = {
      nickName: shortcutName,
      response: 'Template reply',
      templateResponse: true,
      templateData: {
        templateID: ItemV2[0].id,
        cta: annv2CTA,
        header: {
          type: headerTypeV2,
          text: headerInputText,
          mediaId: s3Link,
          imgeURL: s3Link,
          fileName: headerFileNameV2 ? headerFileNameV2 : null,
        },
        bodyParameters: BodyParV2,
        quickReplyButtons: quickReplyBtn,
        mpm: actionList,
        language_code: ItemV2[0]?.language,
        templateName: ItemV2[0]?.name,
        carouselCards: carouselCard,
      },
    };
    saveResponse(authtoken, data).then((res) => {
      if (res?.flag) {
        setApiLoading(false);
        toast.dismiss(3);
        toast.success('Template saved', { position: 'top-center' });
        if (matchResponseShow) {
          matchResponseShow(false);
        }
        if (saveTemplate) {
          setShow(false);
        }
      } else {
        setApiLoading(false);
        toast.dismiss(3);
        toast.error(res);
      }
    });
  };

  const saveReinititateTemplateApiCall = (data) => {
    saveReinitiateTemplate(data?.component, authtoken).then((res) => {
      if (res?.flag) {
        setApiLoading(false);
        toast.success('Reinitiate Template saved', { position: 'top-center' });

        setShow(false);
      } else {
        setApiLoading(false);
        toast.error(res);
      }
    });
  };

  const updateResponseApiCall = () => {
    toast.loading('Updating Template', { toastId: 1 });
    const data = {
      nickName: shortcutName,
      response: 'Template reply',
      templateResponse: true,
      templateData: {
        templateID: ItemV2[0].id,
        cta: annv2CTA,
        header: {
          type: headerTypeV2,
          text: headerInputText,
          mediaId: s3Link,
          imgeURL: s3Link,
          fileName: headerFileNameV2 ? headerFileNameV2 : null,
        },
        bodyParameters: BodyParV2,
        quickReplyButtons: quickReplyBtn,
        mpm: actionList,
        language_code: ItemV2[0]?.language,
        templateName: ItemV2[0]?.name,
        carouselCards: carouselCard,
      },
    };
    updateResponse(authtoken, responseId, data).then((res) => {
      if (res?.flag) {
        toast.success('Template updated', { position: 'top-center' });
        setApiLoading(false);
        toast.dismiss(1);
        if (matchResponseShow) {
          matchResponseShow(false);
        }
        setShow(false);
      } else {
        toast.dismiss(1);
        setApiLoading(false);
        toast.error(res);
      }
    });
  };

  useEffect(() => {
    if (templateData) {
      // setIsLoading(false);
    }
  }, [templateData]);

  const replaceAttributesWithValues = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => {
        if (item.startsWith('%') && item.endsWith('%') && item.length > 2) {
          const TrimWord = item.substring(1, item.length - 1);
          return attributesList[TrimWord] ?? item;
        } else {
          return item;
        }
      });
    } else {
      return arr;
    }
  };

  const sendTemplateApiCall = () => {
    toast.loading('Sending Template', { toastId: 2 });

    const data = {
      to: attributesList?.phone,
      component: {
        cta: annv2CTA.map((item) => {
          if (item.value.startsWith('%') && item.value.endsWith('%') && item.value.length > 2) {
            const TrimWord = item.value.substring(1, item.value.length - 1);
            return { ...item, value: attributesList[TrimWord] ?? item.value };
          } else {
            return item;
          }
        }),
        header: {
          type: headerTypeV2,
          text: headerInputText,
          mediaId: s3Link,
          fileName: headerFileNameV2 ? headerFileNameV2 : null,
        },
        bodyParameters: replaceAttributesWithValues(BodyParV2),
        quickReplyButtons: quickReplyBtn,
        mpm: actionList,
        language_code: ItemV2[0]?.language,
        templateName: ItemV2[0]?.name,
        carouselCards: carouselCard,
      },
      repliedBy: agentdetails ? agentdetails?.name : localdata?.name,
    };
    if (annv2CTA.length === 0) {
      delete data.component.cta;
    }
    // if (URLValueV2 === '') {
    //   delete data.cta;
    // }
    if (headerTypeV2 === '') {
      delete data.component.header;
    }
    if (!actionList.index) {
      delete data.component.mpm;
    }
    // if (headerInputExists) {
    //   delete data.header.mediaId;
    //   delete data.header.fileName;
    // }
    // if (headerTypeV2 === 'text' && !headerInputExists) {
    //   delete data.header;
    // }
    sendWhatsAppTemplate(authtoken, data).then((res) => {
      if (res?.flag) {
        // console.log('return policy',res);
        toast.dismiss(2);
        setApiLoading(false);

        toast.success(res?.message, { position: 'top-center' });
        if (reinitiate) {
          saveReinititateTemplateApiCall(data);
        }
        if (matchResponseShow) {
          matchResponseShow(false);
        }
        if (!reinitiate) {
          setShow(false);
        }
      } else {
        toast.dismiss(2);
        setApiLoading(false);
        toast.error(res?.message);
      }
    });
  };

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="sendTemplateModal"
        contentClassName="sendTemplateModal"
        className={openShortcutModal ? 'background-modal' : ''}
      >
        <Modal.Body
          style={{
            width: '100%',
            overflowY: 'auto',
            padding: '0px',
            borderRadius: '22px',
          }}
        >
          <div
            className="d-flex justify-content-between px-3 py-3"
            style={{ alignItems: 'center', borderBottom: '1px solid #CCCCCC' }}
          >
            <p style={{ fontWeight: '700', fontSize: '20px' }}>
              {saveTemplate ? 'Save Template' : 'Send a Template'}
            </p>

            <div
              className={
                saveTemplate
                  ? 'd-flex flex-row justify-content-end'
                  : 'd-flex flex-row justify-content-between'
              }
              style={{ width: '38%', alignItems: 'center' }}
            >
              {!saveTemplate && (
                <CheerioButton
                  btnText={mobileWidth ? 'Save' : 'Save as canned response'}
                  textStyle={{ color: colors.black, fontWeight: '600' }}
                  backColor={colors.white}
                  borderStyle={{
                    border: '1px solid black',
                    width: mobileWidth ? '90%' : '90%',
                    borderRadius: '8px',
                  }}
                  onclick={() => {
                    setOpenShortcutModal(true);
                  }}
                />
              )}
              <img
                src={images.CMClose}
                onClick={() => {
                  setShow(false);
                }}
                style={{
                  cursor: 'pointer',
                  height: 22,
                  width: 22,
                  marginInlineStart: 8,
                }}
              ></img>
            </div>
          </div>
          <div
            className="px-3"
            style={{
              maxHeight: '80vh',
              minHeight: '30vh',
              overflowY: 'auto',
              scrollbarWidth: 'thin',
            }}
          >
            <div className={mobileWidth ? 'd-flex flex-column' : 'd-flex flex-column'}>
              <InputGroup
                className="mx-2"
                style={{
                  width: mobileWidth ? '95%' : '98%',
                  marginBlockStart: 20,
                  marginBlockEnd: saveTemplate ? '0' : 20,
                }}
              >
                <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                  {'Select Template'}
                </Form.Label>
                <Typeahead
                  id="labelNameTypeahead"
                  labelKey="name"
                  options={
                    templateData
                      ? templateData.filter((item, index) => {
                        return item?.status === 'APPROVED';
                      })
                      : []
                  }
                  placeholder="Choose Template"
                  className="w-100 btncustom dropdown"
                  style={{
                    borderRadius: 8,
                    fontSize: 16,
                    fontWeight: 400,
                    color: '#6c757d',
                  }}
                  selected={selectedOption ? [selectedOption] : []}
                  onChange={(selected) => {
                    if (selected?.length > 0) {
                      setSelectedOption(selected[0]);
                      setSelectedTemplate(selected[0]?.id);
                    } else {
                      setSelectedOption(null);
                    }
                  }}
                />
              </InputGroup>
              {saveTemplate && (
                <InputGroup
                  className="mx-2"
                  style={{
                    width: mobileWidth ? '95%' : '98%',
                    marginBlockEnd: 20,
                    marginBlockStart: '10px',
                  }}
                >
                  <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                    {'Shortcut'}
                  </Form.Label>
                  <Form.Control
                    value={shortcutName}
                    type="text"
                    placeholder="Type Shortcut for templates"
                    className="w-100 btncustom"
                    style={{ borderRadius: 8 }}
                    onChange={(e) => {
                      setShortcutName(e.target.value);
                    }}
                  />
                </InputGroup>
              )}
            </div>

            {isLoading ? (
              <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100 my-3">
                <SpinnerLoader />
              </div>
            ) : (
              <>
                {ItemV2[0] && (
                  <AnnDetailsWF
                    item={ItemV2[0]}
                    setHeaderType={setHeaderTypeV2}
                    headerType={headerTypeV2}
                    headerFileName={headerFileNameV2}
                    setHeaderFileName={setHeaderFileNameV2}
                    bodyPar={BodyParV2}
                    setBodyPar={setBodyParV2}
                    actionList={actionList}
                    setActionList={setActionList}
                    headerInputText={headerInputText}
                    setHeaderInputText={setHeaderInputText}
                    setAnnv2CTA={setAnnv2CTA}
                    s3Link={s3Link}
                    setS3Link={setS3Link}
                    existingData={existingData}
                    quickReplyBtn={quickReplyBtn}
                    setQuickReplyBtn={setQuickReplyBtn}
                    inputFieldDropDown={false}
                    attributesList={attributesList}
                    setCarouselCardAPI={setCarouselCard}
                    setHeaderOption={setHeaderOption}
                    headerOption={headerOption}
                  />
                )}
              </>
            )}
          </div>
          {!isLoading && (
            <div className="d-flex justify-content-end px-3" style={{ marginBlockEnd: '2%' }}>
              <CheerioButton
                btnText={saveTemplate ? 'Save Template' : 'Send Template'}
                textStyle={{ color: colors.white }}
                backColor={colors.black}
                borderStyle={{
                  borderWidth: 0,
                  width: mobileWidth ? '100%' : '42%',
                  borderRadius: '8px',
                }}
                disabled={apiLoading}
                onclick={() => {
                  if (saveTemplate) {
                    if (shortcutName) {
                      if (existingData) {
                        setApiLoading(true);
                        updateResponseApiCall();
                      } else {
                        setApiLoading(true);
                        saveResponseApiCall();
                      }
                    } else {
                      toast.error('Please enter shortcut name!');
                    }
                  } else {
                    setApiLoading(true);
                    sendTemplateApiCall();
                  }
                }}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
      {openShortcutModal && (
        <ShortcutModal
          show={openShortcutModal}
          setShow={setOpenShortcutModal}
          shortcutName={shortcutName}
          setShortcutName={setShortcutName}
          saveResponseApiCall={saveResponseApiCall}
          setApiLoading={setApiLoading}
        ></ShortcutModal>
      )}
    </>
  );
};

export default SendTemplateModal;
