import { useState } from "react";
import { useNavigate } from "react-router-dom";
import colors from "../../../Utils/colors";
import PaymentHistory from "./PaymentHistory";
import Subscriptions from "./Subscriptions";
import PremiumSubscriptions from "./PremiumSubscriptions";
import PremiumPaymentHistory from "./PremiumPaymentHistory";
const SubscriptionDetails = () => {
    const [selectedItem, setSelectedItem] = useState('Subscriptions');
    const navigate = useNavigate();
    return (
        <>
            <div className={'PeopleHeader Row'}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    paddingBottom: '130px',
                }}
            >
                <div className={'PeopleHeaderText'}
                    style={{
                        paddingTop: '2%'
                    }}
                >Payments & Subscriptions</div>
                <div
                    style={{
                        display: 'flex',
                        gap: 20,
                    }}
                >
                    {<div className="d-flex justify-content-center align-items-center px-4"
                        style={{
                            borderBottom: selectedItem === 'Subscriptions' ? `3px solid ${colors.primary03}` : '', cursor: 'pointer',
                            paddingTop: '2.5rem', paddingBottom: '0.8rem', position: 'relative'
                        }}
                        onClick={() => { setSelectedItem('Subscriptions'); }}>
                        <h4>{'Subscriptions'}</h4>
                    </div>}
                    {<div className="d-flex justify-content-center align-items-center px-4"
                        style={{
                            borderBottom: selectedItem === 'Payment History' ? `3px solid ${colors.primary03}` : '', cursor: 'pointer',
                            paddingTop: '2.5rem', paddingBottom: '0.8rem'
                        }}
                        onClick={() => { setSelectedItem('Payment History'); }}>
                        <h4>{'Payment History'}</h4>
                    </div>}
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: 3.28,
                    marginRight: '5%',
                    marginLeft: 300,
                    marginTop: '1.5%',
                    left: 0,
                    top: '15%',
                    position: 'absolute'
                }}
            >
                {selectedItem === 'Subscriptions' && <p> Manage your Cheerio Subscription Add ons</p>}
                {selectedItem === 'Subscriptions' && <PremiumSubscriptions selectedItem={selectedItem} setSelectedItem={setSelectedItem} />}
                {selectedItem === 'Payment History' && (
                    <div style={{ flex: 1, overflow: 'auto' }}>
                        <PremiumPaymentHistory />
                    </div>
                )}
            </div>
        </>
    )
}

export default SubscriptionDetails