import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { PremiumUpgrade, freeTrialApi } from '../../../Services';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import '../../../Components/Modal/Modal.css';
import { eventsend } from '../../../Config/analyticsFunctions';
import CheerioButton from '../../../Components/CheerioButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const FreeTrialPlans = ({ setIsOpen, centerstyle, onsuccess, isSuccess = false }) => {
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);
  const navigate = useNavigate();
  const localdata = useSelector((state) => state.main.localdata);

  const [FTsuccess, setFTsuccess] = useState(isSuccess);
  const [FTendDate, setFTendDate] = useState();
  const [planType, setPlanType] = useState('Monthly');
  const [planName, setPlanName] = useState('Pro');
  const [planData, setPlanData] = useState();
  const [planPrice, setPlanPrice] = useState('');
  const [planPriceR, setPlanPriceR] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [CVV, setCVV] = useState('');

  const OkProceed = cardNumber && expiry && CVV;

  const onclickfreetrial = () => {
    let data = {
      user_id: localdata?._id ? localdata?._id : _id,
    };
    let token = authtoken;
    ReactGA.event('free_trial_modal_button_click', { data: data });
    eventsend('free_trial_modal_button_click', { data: data });
    freeTrialApi(data, token).then((res) => {
      if (res.flag) {
        console.log('free trial success', res);
        setFTendDate(res?.data?.saveFreeTrial?.end_date);
        // setFTsuccess(true);
        onsuccess();
      } else {
        console.log('free trial error', res);
      }
    });
  };

  useEffect(() => {
    if (FTendDate?.length > 0) {
      setFTsuccess(true);
    }
  }, [FTendDate]);

  const PremiumUpgradeAPIcall = () => {
    let data = {
      user_id: localdata?._id,
      plan_type:
        planType === 'Monthly'
          ? '1_month'
          : planType === 'Quarterly'
            ? '3_months'
            : planType === 'Yearly' && '12_months',
      // plan_category: planName,
      plan_category: 'free',
      description: `Enjoy ${
        planType === 'Monthly'
          ? '1 month'
          : planType === 'Quarterly'
            ? '3 months'
            : planType === 'Yearly' && '12 months'
      } of Cheerio Premium Exclusive`,
      country_code: '',
      localized_price: planPriceR,
      price: planPrice,
      currency: 'USD',
      creditCardNumber: cardNumber,
      creditCardExpiry: expiry,
      creditCardCVV: CVV,
    };
    let token = authtoken;
    console.log('data sent to PremiumUpgrade : ', data);
    PremiumUpgrade(data, token).then((res) => {
      if (res?.flag) {
        console.log('premium upgrade details success : ', res);
        onclickfreetrial();
      } else {
        console.log('premium upgrade details fail : ', res);
      }
    });
  };

  const getRemainingDays = (e) => {
    const d = new Date(e);
    const date = new Date();
    let dd = d.toDateString();
    let cd = date.toDateString();
    let diff = Math.floor((Date.parse(dd) - Date.parse(cd)) / 86400000);
    return diff;
  };

  const GroupBtn = ({ title, subTitle }) => {
    const status = title == planType;
    return (
      <button
        onClick={() => setPlanType(title)}
        type="button"
        style={{
          backgroundColor: status ? colors.themeBlue : colors.white,
          borderWidth: 0,
          height: 40,
          width: `${100 / 3}%`,
          borderRadius: title == 'Quarterly' ? 0 : 4,
          borderLeftWidth: title == 'Quarterly' ? 1 : 0,
          borderRightWidth: title == 'Quarterly' ? 1 : 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <text
            style={{
              fontSize: 14,
              color: status ? colors.white : colors.black,
              fontWeight: 'bold',
            }}
          >
            {title}
          </text>
          <text
            style={{
              fontSize: 10,
              color: status ? colors.white : colors.themeBlue,
              fontWeight: 'lighter',
            }}
          >
            {subTitle}
          </text>
        </div>
      </button>
    );
  };

  const planDataArray = [
    [
      {
        email: '1500',
        sms: '800',
        WA: '200',
        support: '3 days',
        devices: 'Upto 2',
        analytics: 'Basic',
        priceR: '₹ 1200',
        price: '15',
      },
      {
        email: '2500',
        sms: '1250',
        WA: '300',
        support: '1 day',
        devices: 'Upto 5',
        analytics: 'Advance',
        priceR: '₹ 1800',
        price: '22',
      },
      {
        email: '3500',
        sms: '1600',
        WA: '500',
        support: '4 hours',
        devices: 'Upto 10',
        analytics: 'Advance',
        priceR: '₹ 2400',
        price: '30',
      },
    ],
    [
      {
        email: '5000',
        sms: '2500',
        WA: '625',
        support: '3 days',
        devices: 'Upto 2',
        analytics: 'Basic',
        priceR: '₹ 3000',
        price: '37',
      },
      {
        email: '7500',
        sms: '3750',
        WA: '900',
        support: '1 day',
        devices: 'Upto 5',
        analytics: 'Advance',
        priceR: '₹ 4950',
        price: '60',
      },
      {
        email: '10,000',
        sms: '5000',
        WA: '1250',
        support: '4 hours',
        devices: 'Upto 10',
        analytics: 'Advance',
        priceR: '₹ 6600',
        price: '80',
      },
    ],
    [
      {
        email: '20,000',
        sms: '10,000',
        WA: '2500',
        support: '3 days',
        devices: 'Upto 2',
        analytics: 'Basic',
        priceR: '₹ 12000',
        price: '146',
      },
      {
        email: '30,000',
        sms: '15,000',
        WA: '3750',
        support: '1 day',
        devices: 'Upto 5',
        analytics: 'Advance',
        priceR: '₹ 18000',
        price: '218',
      },
      {
        email: '40,000',
        sms: '20,000',
        WA: '5000',
        support: '4 hours',
        devices: 'Upto 10',
        analytics: 'Advance',
        priceR: '₹ 24000',
        price: '291',
      },
    ],
  ];

  useEffect(() => {
    if (planType === 'Monthly') {
      setPlanData(planDataArray[0]);
    } else if (planType === 'Quarterly') {
      setPlanData(planDataArray[1]);
    } else if (planType === 'Yearly') {
      setPlanData(planDataArray[2]);
    }
  }, [planType]);

  useEffect(() => {
    if (planData) {
      if (planName === 'Growth') {
        setPlanPrice(planData[0].price);
        setPlanPriceR(planData[0].priceR);
      } else if (planName === 'Pro') {
        setPlanPrice(planData[1].price);
        setPlanPriceR(planData[1].priceR);
      } else if (planName === 'Enterprise') {
        setPlanPrice(planData[2].price);
        setPlanPriceR(planData[2].priceR);
      }
    }
  }, [planData, planName]);

  useEffect(() => {
    if (planPrice) {
      console.log('**************************');
      console.log('planprice : ', planPrice);
      console.log('**************************');
    }
  }, [planPrice]);

  const getDate = (e) => {
    const d = new Date(e);
    let dd = d.getDate();
    let mm = d.toLocaleString('en-EN', { month: 'short' });
    let yy = d.getFullYear();
    return dd + '-' + mm + '-' + yy;
  };

  const PlanCard = ({ PlanName }) => {
    return (
      <>
        <div
          className="d-flex flex-column justify-content-start align-items-center my-auto"
          style={{ width: '80%', border: `1px solid ${colors.borderwhite}`, borderRadius: 8 }}
        >
          <div
            className="d-flex flex-row justify-content-between align-items-center w-100"
            style={{ paddingInline: 20, paddingBlock: 16 }}
          >
            <h3
              style={{ padding: 0, margin: 0, fontSize: 32, fontWeight: 700, color: colors.black }}
            >
              {PlanName}
            </h3>
            <h4
              style={{ padding: 0, margin: 0, fontSize: 24, fontWeight: 600, color: colors.black }}
            >
              {`$ ${planPrice}`}
            </h4>
          </div>
          <p
            style={{
              fontSize: 16,
              fontWeight: 400,
              color: PlanName !== 'Growth' ? colors.white : colors.greys04,
              backgroundColor: PlanName !== 'Growth' ? colors.success01 : colors.white04,
              paddingBlock: 6,
              paddingInline: 16,
              borderRadius: 8,
            }}
          >
            {PlanName !== 'Growth' ? 'No setup charges' : 'Additional setup changes'}
          </p>
          <div style={{ height: 1, width: '100%', backgroundColor: colors.borderwhite }}></div>
          <div
            className="d-flex flex-row justify-content-start align-items-center w-100"
            style={{ paddingInline: 20, paddingBlock: 8 }}
          >
            <img
              src={images.GreenCheck}
              style={{ height: 24, width: 24, objectFit: 'contain' }}
            ></img>
            <p
              style={{
                margin: 0,
                padding: 0,
                marginInlineStart: 4,
                fontSize: 16,
                fontWeight: 400,
                color: colors.black04,
              }}
            >
              {'Email Credits'}
              {planData && (
                <span style={{ fontWeight: 600 }}>
                  {PlanName === 'Growth'
                    ? ` (${planData[0].email})`
                    : PlanName === 'Pro'
                      ? ` (${planData[1].email})`
                      : PlanName === 'Enterprise' && ` (${planData[2].email})`}
                </span>
              )}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center w-100"
            style={{ paddingInline: 20, paddingBlock: 8 }}
          >
            <img
              src={images.GreenCheck}
              style={{ height: 24, width: 24, objectFit: 'contain' }}
            ></img>
            <p
              style={{
                margin: 0,
                padding: 0,
                marginInlineStart: 4,
                fontSize: 16,
                fontWeight: 400,
                color: colors.black04,
              }}
            >
              {'SMS Credits'}
              {planData && (
                <span style={{ fontWeight: 600 }}>
                  {PlanName === 'Growth'
                    ? ` (${planData[0]?.sms})`
                    : PlanName === 'Pro'
                      ? ` (${planData[1]?.sms})`
                      : PlanName === 'Enterprise' && ` (${planData[2]?.sms})`}
                </span>
              )}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center w-100"
            style={{ paddingInline: 20, paddingBlock: 8 }}
          >
            <img
              src={images.GreenCheck}
              style={{ height: 24, width: 24, objectFit: 'contain' }}
            ></img>
            <p
              style={{
                margin: 0,
                padding: 0,
                marginInlineStart: 4,
                fontSize: 16,
                fontWeight: 400,
                color: colors.black04,
              }}
            >
              {'WhatsApp Credits'}
              {planData && (
                <span style={{ fontWeight: 600 }}>
                  {PlanName === 'Growth'
                    ? ` (${planData[0]?.WA})`
                    : PlanName === 'Pro'
                      ? ` (${planData[1]?.WA})`
                      : PlanName === 'Enterprise' && ` (${planData[2]?.WA})`}
                </span>
              )}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center w-100"
            style={{ paddingInline: 20, paddingBlock: 8 }}
          >
            <img
              src={images.GreenCheck}
              style={{ height: 24, width: 24, objectFit: 'contain' }}
            ></img>
            <p
              style={{
                margin: 0,
                padding: 0,
                marginInlineStart: 4,
                fontSize: 16,
                fontWeight: 400,
                color: colors.black04,
              }}
            >
              {'Support timeline'}
              {planData && (
                <span style={{ fontWeight: 600 }}>
                  {PlanName === 'Growth'
                    ? ` (${planData[0]?.support})`
                    : PlanName === 'Pro'
                      ? ` (${planData[1]?.support})`
                      : PlanName === 'Enterprise' && ` (${planData[2]?.support})`}
                </span>
              )}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center w-100"
            style={{ paddingInline: 20, paddingBlock: 8 }}
          >
            <img
              src={images.GreenCheck}
              style={{ height: 24, width: 24, objectFit: 'contain' }}
            ></img>
            <p
              style={{
                margin: 0,
                padding: 0,
                marginInlineStart: 4,
                fontSize: 16,
                fontWeight: 400,
                color: colors.black04,
              }}
            >
              {'Devices login'}
              {planData && (
                <span style={{ fontWeight: 600 }}>
                  {PlanName === 'Growth'
                    ? ` (${planData[0]?.devices})`
                    : PlanName === 'Pro'
                      ? ` (${planData[1]?.devices})`
                      : PlanName === 'Enterprise' && ` (${planData[2]?.devices})`}
                </span>
              )}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center w-100"
            style={{ paddingInline: 20, paddingBlock: 8 }}
          >
            <img
              src={images.GreenCheck}
              style={{ height: 24, width: 24, objectFit: 'contain' }}
            ></img>
            <p
              style={{
                margin: 0,
                padding: 0,
                marginInlineStart: 4,
                fontSize: 16,
                fontWeight: 400,
                color: colors.black04,
              }}
            >
              {'Analytics'}
              {planData && (
                <span style={{ fontWeight: 600 }}>
                  {PlanName === 'Growth'
                    ? ` (${planData[0]?.analytics})`
                    : PlanName === 'Pro'
                      ? ` (${planData[1]?.analytics})`
                      : PlanName === 'Enterprise' && ` (${planData[2]?.analytics})`}
                </span>
              )}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center w-100"
            style={{ paddingInline: 20, paddingBlock: 8 }}
          >
            <img
              src={images.GreenCheck}
              style={{ height: 24, width: 24, objectFit: 'contain' }}
            ></img>
            <p
              style={{
                margin: 0,
                padding: 0,
                marginInlineStart: 4,
                fontSize: 16,
                fontWeight: 400,
                color: colors.black04,
              }}
            >
              {'Payment Links'}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center w-100"
            style={{ paddingInline: 20, paddingBlock: 8 }}
          >
            <img
              src={images.GreenCheck}
              style={{ height: 24, width: 24, objectFit: 'contain' }}
            ></img>
            <p
              style={{
                margin: 0,
                padding: 0,
                marginInlineStart: 4,
                fontSize: 16,
                fontWeight: 400,
                color: colors.black04,
              }}
            >
              {'Custom WhatsApp business account integration'}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center w-100"
            style={{
              paddingInline: 20,
              paddingBlock: 8,
              filter: PlanName === 'Growth' ? 'opacity(40%)' : '',
            }}
          >
            <img
              src={images.GreenCheck}
              style={{ height: 24, width: 24, objectFit: 'contain' }}
            ></img>
            <p
              style={{
                margin: 0,
                padding: 0,
                marginInlineStart: 4,
                fontSize: 16,
                fontWeight: 400,
                color: colors.black04,
              }}
            >
              {'Custom Email integration'}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-center w-100"
            style={{
              paddingInline: 20,
              paddingBlock: 8,
              filter: PlanName !== 'Enterprise' ? 'opacity(40%)' : '',
            }}
          >
            <img
              src={images.GreenCheck}
              style={{ height: 24, width: 24, objectFit: 'contain' }}
            ></img>
            <p
              style={{
                margin: 0,
                padding: 0,
                marginInlineStart: 4,
                fontSize: 16,
                fontWeight: 400,
                color: colors.black04,
              }}
            >
              {'Download reports'}
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div style={Styles.DarkBg}>
        <div className="" style={{ ...Styles.Center, ...centerstyle }}>
          {FTsuccess ? (
            <>
              <div
                className="d-flex flex-column justify-content-between align-items-center"
                style={{
                  backgroundColor: colors.white,
                  borderRadius: 16,
                  width: 480,
                  height: 540,
                  zIndex: 0,
                  padding: 24,
                }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                  <p>Here is your</p>
                  <h1>FREE Welcome Credits</h1>
                  <div
                    style={{
                      display: 'flex',
                      position: 'relative',
                      marginTop: '10%',
                      marginBottom: '-10%',
                    }}
                  >
                    <img
                      src={images.WAFrame}
                      style={{
                        height: '80%',
                        width: '80%',
                        marginTop: '10%',
                        zIndex: 2,
                      }}
                    />
                    <img
                      src={images.EmailFrame}
                      style={{ height: '80%', position: 'absolute', marginLeft: '30%', zIndex: 1 }}
                    />
                    <img
                      src={images.SMSFrame}
                      style={{
                        height: '80%',
                        width: '80%',
                        marginTop: '10%',
                        marginLeft: '-20%',
                        zIndex: 0,
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-evenly align-items-center w-100">
                  <CheerioButton
                    borderStyle={{
                      border: `1px solid ${colors.primary03}`,
                      width: '60%',
                      borderRadius: 8,
                      marginBlock: 16,
                    }}
                    textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
                    btnText={'Claim Credits'}
                    backColor={colors.primary03}
                    onclick={() => {
                      navigate('/creator');
                      setIsOpen(false);
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="d-flex flex-row justify-content-between align-items-center"
                style={{
                  backgroundColor: colors.white,
                  borderRadius: 16,
                  width: 1020,
                  zIndex: 0,
                  height: '90vh',
                }}
              >
                <div
                  className="d-flex flex-column justify-content-between align-items-center"
                  style={{ height: '100%', width: '50%', paddingBlock: 30, paddingInline: 40 }}
                >
                  <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <h2
                      style={{ fontSize: 24, fontWeight: 700, color: colors.black, width: '100%' }}
                    >
                      {'Cheerio Free Trial'}
                    </h2>
                    <p
                      style={{ fontSize: 16, fontWeight: 400, color: colors.black, width: '100%' }}
                    >
                      {'To activate Free trial add credit card details'}
                    </p>
                  </div>

                  <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <InputGroup className="my-2">
                      <Form.Label style={{ fontSize: 14, fontWeight: 700, color: colors.black }}>
                        {'Card Number'}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="x x x x - x x x x - x x x x - x x x x"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={cardNumber}
                        onChange={(e) => {
                          setCardNumber(e.target.value);
                        }}
                      />
                    </InputGroup>
                    <div className="d-flex flex-row justify-content-between align-items-center w-100">
                      <InputGroup className="mb-2" style={{ width: '30%' }}>
                        <Form.Label style={{ fontSize: 14, fontWeight: 700, color: colors.black }}>
                          {'Expiry'}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="MM-YY"
                          className="w-100 btncustom"
                          style={{ borderRadius: 8 }}
                          value={expiry}
                          onChange={(e) => {
                            setExpiry(e.target.value);
                          }}
                        />
                      </InputGroup>
                      <InputGroup className="mb-2" style={{ width: '30%' }}>
                        <Form.Label style={{ fontSize: 14, fontWeight: 700, color: colors.black }}>
                          {'CVV'}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="x-x-x"
                          className="w-100 btncustom"
                          style={{ borderRadius: 8 }}
                          value={CVV}
                          onChange={(e) => {
                            setCVV(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <ul style={{ width: '100%' }}>
                      <li
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.greys04,
                          width: '100%',
                        }}
                      >
                        {'Cancel your membership anytime from dashboard'}
                      </li>
                      <li
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.greys04,
                          width: '100%',
                        }}
                      >
                        {`You won’t be charged if you cancel before 26-Apr-2023`}
                      </li>
                      <li
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.greys04,
                          width: '100%',
                        }}
                      >
                        {'We will remind you 2 days before your trial ends'}
                      </li>
                      <li
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.greys04,
                          width: '100%',
                        }}
                      >
                        {
                          'You will be charged automatically after 7 days according to the plan you will choose'
                        }
                      </li>
                    </ul>
                  </div>

                  <div className="d-flex flex-column justify-content-center align-items-start w-100">
                    <div
                      className="d-flex flex-row justify-content-start align-items-center"
                      style={{ height: 36, border: `1px solid ${colors.black}`, borderRadius: 8 }}
                    >
                      <div
                        className="h-100 d-flex flex-column justify-content-center align-items-center"
                        style={{ paddingInline: 16, borderRight: `1px solid ${colors.black}` }}
                      >
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'CURRENT PLAN'}
                        </p>
                      </div>
                      <div
                        className="h-100 d-flex flex-column justify-content-center align-items-center"
                        style={{ paddingInline: 16 }}
                      >
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {`${planName} Plan- $${planPrice}/ ${
                            planType === 'Monthly'
                              ? 'month'
                              : planType === 'Quarterly'
                                ? 'quarter'
                                : planType === 'Yearly' && 'year'
                          }`}
                        </p>
                      </div>
                    </div>
                    <CheerioButton
                      borderStyle={{
                        border: `1px solid ${colors.black}`,
                        paddingInline: 16,
                        paddingBlock: 4,
                        borderRadius: 8,
                        marginBlock: 16,
                      }}
                      textStyle={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                      btnText={'View all plans'}
                      backColor={colors.white}
                      // onclick={()=>{setOpenICModal(true)}}
                      // icon={images.UploadWhite}
                    />
                  </div>

                  <CheerioButton
                    borderStyle={{ border: `1px solid ${colors.primary04}`, width: '100%' }}
                    textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
                    btnText={'Start a Trial'}
                    backColor={colors.primary04}
                    disabled={OkProceed ? false : true}
                    onclick={() => {
                      // PremiumUpgradeAPIcall();
                      onclickfreetrial();
                    }}
                    // icon={images.UploadWhite}
                  />
                </div>

                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ height: '90%', width: '50%', paddingBlock: 30, paddingInline: 40 }}
                >
                  <div
                    className="d-flex flex-row justify-content-evenly align-items-center"
                    style={{
                      height: 45,
                      border: `1px solid ${colors.greys04}`,
                      borderRadius: 8,
                      width: '80%',
                    }}
                  >
                    <div
                      className="d-flex flex-column justify-content-center align-items-center h-100"
                      style={{
                        borderRight: `1px solid ${colors.greys04}`,
                        flex: 1,
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        backgroundColor: planType == 'Monthly' ? colors.primary01 : colors.white,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setPlanType('Monthly');
                      }}
                    >
                      <h4
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 14,
                          fontWeight: 700,
                          color: planType == 'Monthly' ? colors.primary04 : colors.black,
                        }}
                      >
                        {'Monthly'}
                      </h4>
                    </div>
                    <div
                      className="d-flex flex-column justify-content-center align-items-center h-100"
                      style={{
                        borderRight: `1px solid ${colors.greys04}`,
                        flex: 1,
                        backgroundColor: planType == 'Quarterly' ? colors.primary01 : colors.white,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setPlanType('Quarterly');
                      }}
                    >
                      <h4
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 14,
                          fontWeight: 700,
                          color: planType == 'Quarterly' ? colors.primary04 : colors.black,
                        }}
                      >
                        {'Quarterly'}
                      </h4>
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 10,
                          fontWeight: 400,
                          color: colors.primary04,
                        }}
                      >
                        {'EXTRA 10% OFF'}
                      </p>
                    </div>
                    <div
                      className="d-flex flex-column justify-content-center align-items-center h-100"
                      style={{
                        flex: 1,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        cursor: 'pointer',
                        backgroundColor: planType == 'Yearly' ? colors.primary01 : colors.white,
                      }}
                      onClick={() => {
                        setPlanType('Yearly');
                      }}
                    >
                      <h4
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 14,
                          fontWeight: 700,
                          color: planType == 'Yearly' ? colors.primary04 : colors.black,
                        }}
                      >
                        {'Yearly'}
                      </h4>
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 10,
                          fontWeight: 400,
                          color: colors.primary04,
                        }}
                      >
                        {'EXTRA 20% OFF'}
                      </p>
                    </div>
                  </div>

                  <div
                    className="w-100 verythinscrollnew"
                    style={{ flex: 1, marginBlock: 8, paddingBlock: 8, overflowY: 'scroll' }}
                  >
                    {/* <div className="d-flex flex-column justify-content-start align-items-center w-100 h-100"> */}
                    <div className="d-flex flex-row justify-content-between align-items-center w-100 h-100">
                      <img
                        src={images.DownArrowTip}
                        style={{
                          height: 24,
                          width: 24,
                          objectFit: 'contain',
                          transform: 'rotate(90deg)',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (planName === 'Growth') {
                            setPlanName('Enterprise');
                          } else if (planName === 'Pro') {
                            setPlanName('Growth');
                          } else if (planName === 'Enterprise') {
                            setPlanName('Pro');
                          }
                        }}
                      ></img>
                      <PlanCard PlanName={planName} />
                      <img
                        src={images.DownArrowTip}
                        style={{
                          height: 24,
                          width: 24,
                          objectFit: 'contain',
                          transform: 'rotate(270deg)',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (planName === 'Growth') {
                            setPlanName('Pro');
                          } else if (planName === 'Pro') {
                            setPlanName('Enterprise');
                          } else if (planName === 'Enterprise') {
                            setPlanName('Growth');
                          }
                        }}
                      ></img>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FreeTrialPlans;

const Styles = {
  DarkBg: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    width: '100vw',
    height: '100vh',
    // flex:1,
    zIndex: 1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'fixed',
  },
  Center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  TxtHeading: {
    fontSize: 42,
    fontWeight: 900,
    color: colors.primary03,
  },
  TxtBody: {
    fontSize: 20,
    fontWeight: 600,
    color: colors.black03,
  },
  TxtBody2: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.black03,
  },
  itemborder: {
    backgroundColor: colors.white,
    borderRadius: 16,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.15),-2px -2px 10px 0 rgba(0, 0, 0, 0.15)',
  },
};
