import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';
import { useEffect } from 'react';
import { eventsend } from '../../../Config/analyticsFunctions';

export default function SendTemplateBar({ setShowSendTemplateModal }) {
  // TODO: bug
  // {"message":"(#132009) Required component in the template is missing","code":132009,"error_subcode":2494073,"type":"OAuthException","error_data":{"messaging_product":"whatsapp","details":"Card header missing (for card_index=0)"}}
  useEffect(() => {
    eventsend("Send_template_Inbox_page_clicked");
  }, [])
  return (
    <div
      className="d-flex justify-content-between"
      style={{
        width: '100%',
        alignItems: 'center',
        backgroundColor: colors.white,
        padding: 20,
      }}
    >
      <p
        style={{
          color: colors.black,
          fontSize: 14,
          width: '70%',
        }}
      >
        Send a template message to initiate a conversation
      </p>
      <div className="d-flex">
        <div style={{ marginRight: '10px' }}>
          <CheerioButton
            id="SendTemplateButton"
            btnText={'Initiate Conversation'}
            textStyle={{ color: colors.white, fontSize: '12px' }}
            backColor={colors.black}
            borderStyle={{ height: '32px', borderRadius: '8px' }}
            onclick={() => {
              setShowSendTemplateModal(true);
            }}
          />
        </div>
      </div>
    </div>
  );
}
