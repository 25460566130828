import React, { useEffect, useState } from 'react'
import colors from '../../../Utils/colors'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import KeywordsItem from '../Components/KeywordsItem';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import CheerioButton from '../../../Components/CheerioButton';
import { AiOutlineInfoCircle } from "react-icons/ai";
import images from '../../../Utils/images';
import { useSelector } from 'react-redux';


const IncominWAModal = ({ data, setData, saveKeywords, onSubmit, setSaveKeywords }) => {

    const localdata = useSelector((state) => state.main.localdata);
    const IncreasedKeywordsLimit = localdata?._id === '65e1cfedd0e23c3ae533f4b9'

    const keywordsLimit = IncreasedKeywordsLimit ? 10 : 5;

    const [keyWsaved, setKeyWsaved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [infoScreen, setInfoScreen] = useState(false);
    const [options, setOptions] = useState([
        {
            keywords: [],
            criteria: ''
        }
    ]);
    const defaultOption = {
        keywords: 'Default',
        criteria: 'Default Match'
    }
    const [NKey, setNkey] = useState(1);
    const [noChangeProceed, setNoChangeProceed] = useState(false);

    useEffect(() => {
        setKeyWsaved(false);
        if (data?.options?.length > 1) {
            setIsLoading(true);
            const nonDefaultOptions = [...data?.options];
            nonDefaultOptions.pop();
            setOptions(nonDefaultOptions);
        }
    }, [])

    useEffect(() => {
        if (data?.options?.length === options.length + 1) {
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
        if (options[0]?.keywords?.length !== 0) {
            setData({
                ...data,
                options: [...options, defaultOption],
            })
            if (saveKeywords) {
                setKeyWsaved(true);
            }
        } else if (options?.length === 0) {
            setData({
                ...data,
                options: [defaultOption],
            });
            if (saveKeywords) {
                setKeyWsaved(true);
            }
        }
    }, [options])

    useEffect(() => {
        if (keyWsaved && saveKeywords) {
            setSaveKeywords(false);
            onSubmit();
        } else if (!keyWsaved && saveKeywords) {
            if (noChangeProceed) {
                onSubmit();
            }
        }
    }, [keyWsaved, saveKeywords, noChangeProceed])

    return (
        <>
            <div className='w-100 h-100 d-flex flex-column justify-content-start align-items-start'>

                {/* {infoScreen ? (
                    <>
                        <div className='d-flex flex-row justify-content-start align-items-center w-100 my-2'>
                            <img src={images.BackArrowBlack} style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}
                                onClick={() => { setInfoScreen(false) }}></img>
                            <h4 className='my-2 ms-2' style={{}}>
                                {'Match Keywords'}
                            </h4>
                        </div>
                        <p className='mt-2 mb-3 w-100' style={{ color: colors.greys04 }}>
                            {'This feature allows you to trigger messages, flows based on the keywords and message sent by sender. If a message matches your set of keywords you can determine an action based on it'}<br /><br />
                            {'1. Create a set of matching keywords like (Hi, Hello, Hey)'}<br />
                            {'2. Each set can contain total of 10 keywords'}<br />
                            {'3. A keyword can be a word or a sentence like (hi, Hi good morning)'}<br />
                            {'4. You can create maximum of 5 Set in a node'}<br />
                        </p>
                        <img src={images.WFIKT} style={{ width: '100%', height: 'auto', objectFit: 'contain' }}></img>
                        <p className='mt-2 mb-3 w-100' style={{ color: colors.greys04 }}>
                            {'You can add Keywords on Incoming WhatsApp message only'}
                        </p>
                    </>
                ) : (
                    <>
                        <div className='d-flex flex-row justify-content-start align-items-center w-100 my-2'>
                            <h4 className='my-2 me-2' style={{}}>
                                {'Match Keywords'}
                            </h4>
                            <AiOutlineInfoCircle
                                size={18}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setInfoScreen(true);
                                }}
                            />
                        </div>
                        <p className='my-2' style={{ color: colors.greys04 }}>
                            {'You can create a set of multiple keywords. A set can store words, sentences. Use comma to separate keywords'}
                        </p>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 8,
                            padding: 16,
                            backgroundColor: colors.primary01,
                            borderRadius: 10,
                        }}>
                            <AiOutlineInfoCircle
                                size={50}
                            />
                            <p>{"In order make default commands like prev, back, menu and exit work it's recommended to connect the default node or use keyword triggers."}</p>
                        </div>
                        {isLoading ? (
                            <>
                                <div className='d-flex flex-row justify-content-center align-items-center w-100'>
                                    <SpinnerLoader />
                                </div>
                            </>
                        ) : (
                            <>
                                {options.length > 0 && options.map((item, index) => {
                                    return (
                                        <>
                                            <KeywordsItem
                                                SetOptions={setOptions}
                                                index={index}
                                                Options={options}
                                                saveKeywords={saveKeywords}
                                                setNoChangeProceed={setNoChangeProceed}
                                            />
                                        </>
                                    )
                                })}
                            </>
                        )}

                        <CheerioButton
                            borderStyle={{ marginBlockStart: 16, marginBlockEnd: 24 }}
                            textStyle={{ color: colors.white }}
                            btnText={'Add new set'}
                            backColor={colors.black}
                            disabled={options?.length >= keywordsLimit ? true : false}
                            onclick={() => {
                                setOptions((options) => {
                                    const tempOpt = [...options];
                                    tempOpt.push(
                                        {
                                            keywords: [],
                                            criteria: ''
                                        }
                                    )
                                    return tempOpt;
                                })
                            }}
                        />
                    </>
                )} */}

                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 8,
                    padding: 16,
                    backgroundColor: colors.primary01,
                    borderRadius: 10,
                    marginBlock:16,
                }}>
                    <AiOutlineInfoCircle
                        size={50}
                    />
                    <p>{"Match keywords feature in Incoming WhatsApp node has been deprecated, switch to using Keyword Triggers instead"}</p>
                </div>

            </div>
        </>
    )
}

export default IncominWAModal