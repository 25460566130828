import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import colors from '../../Utils/colors';
import { io } from 'socket.io-client';
import {
  V2AgentGet,
  getInstagramConversationByIDAPI,
  getInstagramInboxListingAPI,
} from '../../Services';
import { eventsend } from '../../Config/analyticsFunctions';
import { SOCKET_URL } from '../../Config/axiosConfig';
import SpinnerLoader from '../../Components/SpinnerLoader';
import { PremiumOptionModal } from '../../Components/Modal/PremiumOptionModal';
import InstagramInbox from './components/InstagramInbox';
import InstagramChatView from './components/InstagramChatView';

//let chatTemp = {};
//let tempList = [];
// let selectedIndexTemp = 0;
const socket = io(SOCKET_URL, { maxHttpBufferSize: 1e7,transports: ['websocket'] });

const InstagramReplies = () => {
  const localdata = useSelector((state) => state.main.localdata);
  const [inboxList, setInboxList] = useState([]);
  const [chatData, setChatData] = useState();
  //const [isChatLoad, setIsChatLoad] = useState(false);
  const [connection, setConnection] = useState(false);
  const firstRender = React.useRef(false);
  const [loading, setLoading] = useState(false);
  // const [selectedIndex, setSelectedIndex] = useState(-1);
  const [page, setPage] = useState(1);
  // const [searchInbox, setSearchInbox] = useState('');
  const [next, setNext] = useState(true);
  const [updateMsg, setUpdateMsg] = useState();
  const [showScreen, setShowScreen] = useState('inbox');
  const agentUserData = useSelector((state) => state.main.agentdata);

  //const showCTWA = useSelector((state) => state.main.showCTWA);
  const isChatLoad = localdata?.metaConfig?.instagramPageName ?? false;
  const isPremiumUser = localdata?.premium;
  const [filters, setFilters] = useState({
    general: [],
    status: [],
    day: {
      selectedNumberOfDate: null,
      startDate: null,
      endDate: null,
    },
    agents: agentUserData?.role === 'agent' ? [agentUserData?._id] : [],
    showCtwa: false,
  });
  const [agentData, setAgentData] = useState();
  const authtoken = useSelector((state) => state.main.auth_token);
  const [totalChats, setTotalChats] = useState(0);
  const [loadingChatScreen, setLoadingChatScreen] = useState(false);
  const [messages, setMessages] = useState([{ messageText: 'some text' }]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [loadingInbox, setLoadingInbox] = useState(false);
  const [updateConversations, setUpdateConversations] = useState(false);

  const MobileWidth = window.innerWidth < 900 ? true : false;

  function afterConnectionSetup(newMessage) {
    // if (socket.connected && !connection) {
    setConnection(true);
    setUpdateConversations(true);
    //   // console.log("triggere");
    //   // setInboxList([]);
    // }
    setLoadingChatScreen(false);
  }

  const onConnect = () => {
    console.log('connected', socket.connected);
    if (socket.connected) {
      try {
        socket.emit('addPersonInsta', localdata?._id);
      } catch (err) {
        alert("Can't connect to server");
      }
    }
  };

  const loadChats = (eventdata) => {
    console.log('data', eventdata);
    console.log('socket', socket);
    setInboxList((prev) => {
      const newArr = [...prev];

      for (let i = 0; i < eventdata?.docs?.length; i++) {
        let index = newArr.findIndex((item) => item._id === eventdata?.docs[i]?._id);
        if (index > -1) {
          newArr[index] = eventdata?.docs[i];
        } else {
          newArr.push(eventdata?.docs[i]);
        }
      }
      console.log('newArr', newArr);

      return newArr;
    });
    setUpdateConversations(false);
    setLoadingInbox(false);
    setNext(eventdata?.hasNextPage);
    setTotalChats(eventdata?.totalDocs);
  };

  const updateChatList = (eventdata) => {
    console.log('//////////////chat list chat data//////////////', eventdata);
    setMessages(eventdata ?? []);
    setLoadingMessages(false);
  };

  const getInboxListing = useCallback(
    (pg) => {
      console.log('getting inbox listing');
      getInboxListAPIcall(page);
    },
    [page, filters]
  );

  // const useDebouncedEffect = (effect, deps, delay) => {
  //   useEffect(() => {
  //     const handler = setTimeout(() => effect(), delay);

  //     return () => clearTimeout(handler);
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [...(deps || []), delay]);
  // };

  // useDebouncedEffect(() => getInboxListAPIcall(), 500);

  const updateConversation = (eventdata) => {
    setInboxList((prev) => {
      let newArr = [...prev];
      const index = newArr.findIndex((item) => item._id === eventdata._id);
      if (index > -1) {
        // change index to 0 and replace the item
        newArr = newArr.filter((item) => item._id !== eventdata._id);
        newArr = [eventdata, ...newArr];
      } else {
        newArr = [eventdata, ...newArr];
      }
      return newArr;
    });
  };

  useEffect(() => {
    socket.on('newInstaList', (eventdata) => {
      console.log('newInsta', eventdata);
      if (eventdata?.deleted && eventdata?._doc.conversationId === chatData?._id) {
        setMessages((prev) => {
          return prev.map((message) => {
            if (message?.messageId === eventdata?._doc?.messageId) {
              return eventdata?._doc;
            }
            return message;
          });
        });
      } else {
        if (eventdata?.conversationId === chatData?._id) {
          setMessages((prev) => {
            const newArr = [...prev, eventdata];
            return newArr;
          });
        }
      }
    });

    return () => {
      socket.off('newInstaList');
    };
  }, [chatData]);

  useEffect(() => {
    console.log('instagram_replies_pageview_web');
    eventsend('instagram_replies_pageview_web');
    getInboxListAPIcall();
    // socket.on('connect', onConnect);
    if (!firstRender.current) {
      firstRender.current = true;
      onConnect();
    }

    // Socket Connected and User Added
    socket.on('getPersonsInsta', afterConnectionSetup);

    socket.on('InstagramConversationInfo', updateConversation);
    // socket.on('getMassage', newMessage);
    return () => {
      socket.off('getPersonsInsta', afterConnectionSetup);
      socket.off('InstagramConversationInfo', updateConversation);
    };
  }, []);

  const getInboxListAPIcall = (page = 1) => {
    let token = authtoken;
    let data = {
      page: page,
      limit: 20,
    };
    getInstagramInboxListingAPI(token, data).then((res) => {
      if (res?.flag) {
        loadChats(res?.data);
        console.log('success api --------------> ', res);
      } else {
        console.log('error api --------------> ', res);
      }
    });
  };

  const getConversationsByIDAPIcall = (id) => {
    let token = authtoken;
    let data = {
      _id: id,
    };
    getInstagramConversationByIDAPI(token, data).then((res) => {
      if (res?.flag) {
        updateChatList(res?.data);
        console.log('success api --------------> ', res);
      } else {
        console.log('error api --------------> ', res);
      }
    });
  };

  useEffect(() => {
    console.log('chatData', chatData);
    if (chatData) {
      console.log('getting messages');
      setLoadingMessages(true);
      getConversationsByIDAPIcall(chatData?._id);
    }
  }, [chatData]);

  useEffect(() => {
    console.log('getting updateConversations', updateConversations);
    if (connection && updateConversations) {
      console.log('getting page', page);
      if (page == 1) {
        setLoadingInbox(true);
      }
      // getInboxListing();
      getInboxListAPIcall(page);
    }
  }, [updateConversations]);

  const GetAgentsV2APICall = () => {
    let token = authtoken;
    V2AgentGet(token).then((res) => {
      if (res) {
        setAgentData(res?.data);
      } else {
      }
    });
  };
  useEffect(() => {
    GetAgentsV2APICall();
  }, []);

  const mobileChangeScreenAction = (e) => {
    setTimeout(() => {
      setShowScreen(e);
    }, 500);
  };

  // console.log('inbox', isChatLoad);
  return (
    <>
      {!isPremiumUser && (
        <PremiumOptionModal
          option={'WhatsApp Inbox'}
          openPremiumModal={true}
          setOpenPremiumModal={() => { }}
        ></PremiumOptionModal>
      )}

      {loading ? (
        <div className="spinner_loader">
          <SpinnerLoader />
        </div>
      ) : (
        <div className="whatsapp_replies">
          <div
            className="whatsapp_replies__wainbox_container"
            style={{ display: MobileWidth ? (showScreen === 'inbox' ? '' : 'none') : '' }}
          >
            {loadingInbox ? (
              <div className="spinner_loader">
                <SpinnerLoader />
              </div>
            ) : (
              <InstagramInbox
                isChatLoad={isChatLoad}
                list={inboxList}
                // setSelectedIndex={setSelectedIndex}
                selectedItem={chatData}
                setSelectedItem={(data) => {
                  setChatData(data);
                  setInboxList((prev) => {
                    const newArr = [...prev];
                    const index = newArr.findIndex((item) => item._id === data._id);
                    if (index > -1) {
                      newArr[index] = { ...data, unread_count: 0 };
                    }
                    return newArr;
                  });
                }}
                waData={localdata?.whatsapp_credentials}
                profileImg={localdata?.picture}
                page={page}
                setPage={(pg) => {
                  setPage(pg);
                  setUpdateConversations(true);
                }}
                hasNext={next}
                filters={filters}
                setFilters={setFilters}
                setInboxList={setInboxList}
                getInboxListing={getInboxListing}
                agentData={agentData}
                setAgentData={setAgentData}
                totalChats={totalChats}
                mobileChangeScreen={mobileChangeScreenAction}
                setChatData={setChatData}
                //setSearchInbox={setSearchInbox}
                removeFilterAction={() => {
                  setFilters({
                    general: [],
                    status: [],
                    day: {
                      selectedNumberOfDate: null,
                      startDate: null,
                      endDate: null,
                    },
                    agents: agentUserData?.role === 'agent' ? [agentUserData?._id] : [],
                    showCtwa: false,
                  });
                }}
              />
            )}
          </div>

          {loadingMessages ? (
            <div className="spinner_loader">
              <SpinnerLoader />
            </div>
          ) : !loadingMessages ? (
            <div
              className="whatsapp_replies__wachatview_container"
              style={{ display: MobileWidth ? (showScreen === 'chatview' ? '' : 'none') : '' }}
            >
              <InstagramChatView
                chatData={chatData}
                isChatLoad={isChatLoad}
                updateNewMessage={setUpdateMsg}
                agentData={agentData}
                setAgentData={setAgentData}
                setInboxList={setInboxList}
                getInboxListing={getInboxListing}
                mobileChangeScreen={mobileChangeScreenAction}
                setLoadingChatScreen={setLoadingChatScreen}
                loadingChatScreen={false}
                socket={socket}
                messages={messages}
                setChatStatus={(status) => {
                  setInboxList((prev) => {
                    const newArr = [...prev];
                    const index = newArr.findIndex((item) => item._id === chatData._id);
                    if (index > -1) {
                      newArr[index] = { ...chatData, status: status };
                    }
                    return newArr;
                  });
                }}
                setAgent={(agent) => {
                  setInboxList((prev) => {
                    const newArr = [...prev];
                    const index = newArr.findIndex((item) => item._id === chatData._id);
                    if (index > -1) {
                      newArr[index] = { ...chatData, agent: agent };
                    }
                    return newArr;
                  });
                }}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-center" style={{ width: '70%' }}>
              <p style={{ margin: 0, fontSize: 16, color: colors.darkgrey }}>No data available </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default InstagramReplies;
