import React from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';

const SuccessFailureModal = ({ setIsSFModalOpen, isSuccess = true, onButtonClickAction }) => {
  return (
    <>
      <div className="DarkBg" onClick={() => {}}>
        <div className="Center">
          <div
            className="d-flex flex-column justify-content-between align-items-center"
            style={{
              height: 500,
              width: 470,
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 1,
              border: `1px solid ${colors.borderwhite}`,
              paddingInline: 30,
              paddingBlock: 40,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {isSuccess ? (
              <>
                <div className="d-flex flex-row justify-content-end align-items-center w-100">
                  <img
                    src={images.CMClose}
                    style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                    onClick={() => {
                      setIsSFModalOpen(false);
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="d-flex flex-row justify-content-start align-items-center w-100">
                  <img
                    src={images.BackArrowBlack}
                    style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                    onClick={() => {
                      setIsSFModalOpen(false);
                    }}
                  />
                </div>
              </>
            )}

            <div className="d-flex flex-column justify-content-center align-items-center my-2">
              <img
                src={isSuccess ? images.CATSuccess : images.CATFail}
                style={{ height: 200, width: 200, objectFit: 'contain' }}
              />
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  marginBlock: 6,
                  fontSize: 16,
                  fontWeight: 700,
                  color: colors.black,
                }}
              >
                {isSuccess ? 'Template submit successfully' : 'Error while submitting template'}
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  marginBlock: 6,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.greys04,
                  textAlign: 'center',
                  width: '80%',
                }}
              >
                {isSuccess
                  ? 'Your template is being submitted to meta for approval. You can check template status in my templates.'
                  : 'Please try submitting again. If error still occurs please try again after some time.'}
              </p>
            </div>
            <CheerioButton
              borderStyle={{ borderColor: colors.black, width: '100%' }}
              textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
              btnText={isSuccess ? 'Close' : 'Retry'}
              backColor={colors.black}
              // icon={images.WhatsAppWhite}
              onclick={() => {
                console.log('close clicked');
                onButtonClickAction();
              }}
              // disabled={enableSubmit ? false : true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessFailureModal;
