import React, { useEffect, useRef, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useSelector } from 'react-redux';
import WAPreview from './WAPreview';
import CheerioButton from '../../../Components/CheerioButton';
import { getImagePath } from '../../../Utils';
import {
  CompanyNameInput,
  CustomerNameInput,
  DaysInput,
  DaysTillInput,
  DiscountCodeInput,
  DiscountInput,
  DiscountTextInput,
  DurationInput,
  Feature1Input,
  Feature2Input,
  Feature3Input,
  Feature4Input,
  GreetingInput,
  InfoInput,
  InfoTypeInput,
  InstaHandleInput,
  LinkDescriptionInput,
  LinkInput,
  NFeature1Input,
  NFeature2Input,
  NFeature3Input,
  OccasionInput,
  OfferNameInput,
  ProductLinkInput,
  ProductNameInput,
  ProfessionInput,
  PromotionTextInput,
  RNumberInput,
  RenewalTextInput,
  SellerNameInput,
  SupportEmailInput,
  ThankYouInput,
} from './InputComponents';
import TemplateItem from './TemplateItem';
import { AISensySubmitTemplates } from '../../../Services';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { eventsend } from '../../../Config/analyticsFunctions';

const SubmitModal = ({ setIsSubmitModalOpen, templateType, onSuccessAction, onFailureAction }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);

  const upimgref = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const [upimgdone, setupimgdone] = useState(false);
  const [upimg64, setupimg64] = useState('');
  const [upimgname, setupimgname] = useState('');

  const [CustomerName, setCustomerName] = useState('');
  const [sellerName, setSellerName] = useState('');
  const [companyName, setCompanyName] = useState(localdata?.company_name);
  const [renewalText, setRenewalText] = useState('');
  const [Rnumber, setRNumber] = useState(localdata?.mobile);
  const [productName, setProductName] = useState('');
  const [offerName, setOfferName] = useState('');
  const [discount, setDiscount] = useState('');
  const [days, setDays] = useState('');
  const [daystill, setDaystill] = useState('');
  const [productLink, setProductLink] = useState('');
  const [ThankYou, setThankYou] = useState('');
  const [LinkDesc, setLinkDesc] = useState('');
  const [Link, setLink] = useState('');
  const [Feature1, setFeature1] = useState('');
  const [Feature2, setFeature2] = useState('');
  const [Feature3, setFeature3] = useState('');
  const [NFeature1, setNFeature1] = useState('');
  const [NFeature2, setNFeature2] = useState('');
  const [NFeature3, setNFeature3] = useState('');
  const [Feature4, setFeature4] = useState('');
  const [Greeting, setGreeting] = useState('');
  const [InfoType, setInfoType] = useState('');
  const [Info, setInfo] = useState('');
  const [Profession, setProfession] = useState('');
  const [promotionText, setPromotionText] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [occasion, setOccasion] = useState('');
  const [instaHandle, setInstaHandle] = useState('');
  const [supportEmail, setSupportEmail] = useState('');
  const [duration, setDuration] = useState('');
  const [discountText, setDiscountText] = useState('');

  const [dragActive, setDragActive] = useState(false);

  const enableSubmit =
    templateType === 'renewal_remainder'
      ? upimg64 && CustomerName && renewalText && Rnumber && companyName
      : templateType === 'maintainance_update'
        ? upimg64 && CustomerName && Info && companyName
        : templateType === 'new_product_service_marathi'
          ? upimg64 && CustomerName && productName
          : templateType === 'do_you_know'
            ? upimg64 &&
            CustomerName &&
            productName &&
            Feature1 &&
            Feature2 &&
            Feature3 &&
            Feature4 &&
            Info &&
            Rnumber
            : templateType === 'discount_all_products_new'
              ? upimg64 && CustomerName && companyName && discountText && discountCode
              : templateType === 'loyal_customers_new'
                ? upimg64 && CustomerName && Info && productName && discount && discountCode && Rnumber
                : templateType === 'welcome_insta_message_2'
                  ? upimg64 &&
                  CustomerName &&
                  companyName &&
                  productName &&
                  instaHandle &&
                  supportEmail &&
                  discountText &&
                  Link &&
                  duration &&
                  Rnumber
                  : templateType === 'business_info_newsletter'
                    ? upimg64 &&
                    CustomerName &&
                    productName &&
                    NFeature1 &&
                    NFeature2 &&
                    NFeature3 &&
                    companyName &&
                    Feature1 &&
                    Feature2 &&
                    Feature3 &&
                    Link &&
                    Rnumber
                    : templateType === 'repeatcustomer_offer'
                      ? upimg64 &&
                      CustomerName &&
                      companyName &&
                      ThankYou &&
                      Feature1 &&
                      occasion &&
                      promotionText &&
                      Link &&
                      Feature2 &&
                      Rnumber
                      : templateType === 'customer_win_back'
                        ? upimg64 && CustomerName && promotionText && companyName && Feature1 && discountCode
                        : templateType === 'additional_discount_offer'
                          ? upimg64 && Greeting && companyName && occasion && promotionText && Feature1
                          : templateType === 'share_info'
                            ? upimg64 && CustomerName && Greeting && InfoType && Info && Profession
                            : templateType === 'thank_you_customer'
                              ? upimg64 &&
                              CustomerName &&
                              ThankYou &&
                              LinkDesc &&
                              Link &&
                              Feature1 &&
                              Feature2 &&
                              Feature3 &&
                              Feature4 &&
                              sellerName &&
                              companyName
                              : templateType === 'customer_enquiry'
                                ? upimg64 && CustomerName && sellerName && companyName && discount && daystill
                                : templateType === 'new_course_media_button'
                                  ? upimg64 && CustomerName && sellerName && productName
                                  : templateType === 'new_product_media_button'
                                    ? upimg64 && CustomerName && sellerName && productName
                                    : templateType === 'share_an_offer_media_button' &&
                                    upimg64 &&
                                    CustomerName &&
                                    sellerName &&
                                    productName &&
                                    discount &&
                                    days;

  const formdata =
    templateType === 'renewal_remainder'
      ? [CustomerName, renewalText, Rnumber, companyName]
      : templateType === 'maintainance_update'
        ? [CustomerName, Info, companyName]
        : templateType === 'new_product_service_marathi'
          ? [CustomerName, productName]
          : templateType === 'do_you_know'
            ? [CustomerName, productName, Feature1, Feature2, Feature3, Feature4, Info, Rnumber]
            : templateType === 'discount_all_products_new'
              ? [CustomerName, companyName, discountText, discountCode]
              : templateType === 'loyal_customers_new'
                ? [CustomerName, Info, productName, discount, discountCode, Rnumber]
                : templateType === 'welcome_insta_message_2'
                  ? [
                    CustomerName,
                    companyName,
                    productName,
                    instaHandle,
                    supportEmail,
                    discount,
                    Link,
                    duration,
                    Rnumber,
                  ]
                  : templateType === 'business_info_newsletter'
                    ? [
                      CustomerName,
                      productName,
                      NFeature1,
                      NFeature2,
                      NFeature3,
                      companyName,
                      Feature1,
                      Feature2,
                      Feature3,
                      Link,
                      Rnumber,
                    ]
                    : templateType === 'repeatcustomer_offer'
                      ? [
                        CustomerName,
                        companyName,
                        ThankYou,
                        Feature1,
                        occasion,
                        promotionText,
                        Link,
                        Feature2,
                        Rnumber,
                      ]
                      : templateType === 'customer_win_back'
                        ? [CustomerName, promotionText, companyName, Feature1, discountCode]
                        : templateType === 'additional_discount_offer'
                          ? [Greeting, companyName, occasion, promotionText, Feature1]
                          : templateType === 'share_info'
                            ? [CustomerName, Greeting, InfoType, Info, Profession]
                            : templateType === 'thank_you_customer'
                              ? [
                                CustomerName,
                                ThankYou,
                                LinkDesc,
                                Link,
                                Feature1,
                                Feature2,
                                Feature3,
                                Feature4,
                                sellerName,
                                companyName,
                              ]
                              : templateType === 'customer_enquiry'
                                ? [CustomerName, sellerName, companyName, discount, daystill]
                                : templateType === 'new_course_media_button'
                                  ? [CustomerName, sellerName, productName]
                                  : templateType === 'new_product_media_button'
                                    ? [CustomerName, sellerName, productName]
                                    : templateType === 'share_an_offer_media_button' && [CustomerName, sellerName, productName, discount, days];

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (
      e.dataTransfer.files &&
      e.dataTransfer.files[0] &&
      (e.dataTransfer.files[0].type === 'image/jpg' || e.dataTransfer.files[0].type === 'image/png')
    ) {
      handleUploadImage(e.dataTransfer.files[0]);
    } else {
      alert(
        'Wrong file format. Please ensure that you have uploaded image is of .jpg or .png format'
      );
    }
  };

  const handleUploadImage = async (e) => {
    const file = e;
    if (file.size < 5242880) {
      setupimgname(file.name);
      const base64 = await convertToBase64(file);
      setupimg64(base64);
    } else {
      alert('File size limit exceeded. Please check the size of the file to be uploaded.');
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const submitTemplateAction = () => {
    setIsLoading(true);
    let form = new FormData();
    form.append('image', upimg64);
    form.append('template_name', templateType);
    form.append('body_text_arr', JSON.stringify(formdata));
    form.append('user_id', localdata?._id);
    AISensySubmitTemplates(form).then((res) => {
      setIsLoading(false);
      if (res?.flag) {
        console.log('success response: ', res);
        onSuccessAction();
      } else {
        console.log('error response: ', res);
        onFailureAction();
      }
    });
  };

  const handleimgupload = async (e) => {
    const file = e.target.files[0];
    if (file.size < 5242880) {
      setupimgname(file.name);
      const base64 = await convertToBase64(file);
      setupimg64(base64);
    } else {
      alert('File size limit exceeded. Please check the size of the file to be uploaded.');
    }
  };

  const UploadImageComponent = () => {
    return (
      <>
        <InputGroup className="mb-2">
          <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
            {'Upload default image'}
          </Form.Label>
          <div
            className="d-flex flex-column justify-content-around align-items-center"
            style={{
              width: '100%',
              border: `1px dotted ${colors.greys03}`,
              borderRadius: 16,
              cursor: 'pointer',
              height: 230,
              backgroundColor: colors.white02,
            }}
            onClick={() => {
              upimgref.current.click();
            }}
            onDragEnter={handleDrag}
            onDragOver={handleDrag}
            onDragExit={handleDrag}
            onDrop={handleDrop}
          // onClick={()=>{
          //   if(upimg64){
          //     setupimg64(null)
          //   }
          // }}
          >
            <img
              src={upimg64 ? getImagePath(upimg64) : images.ImgUpIcon}
              style={{ width: 140, height: 90, objectFit: 'contain', marginTop: 20 }}
            ></img>
            <p
              style={{
                fontSize: 12,
                fontWeight: 600,
                color: colors.black,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                wordBreak: 'break-all',
              }}
            >
              {upimgname ? upimgname : 'Drag & drop file here'}
              {upimgname && (
                <img
                  src={images.Cross}
                  style={{ height: 18, width: 18, marginInlineStart: 24, cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setupimg64(null);
                    setupimgname('');
                  }}
                />
              )}
            </p>
            <p style={{ fontSize: 12, fontWeight: 600, color: colors.black, textAlign: 'center' }}>
              {'(350px * 350px) to (1500px * 1500px)'}
              <br />
              {'Max file size: 5MB'}
            </p>
          </div>
          <p
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: colors.greys04,
              width: '100%',
              marginBlock: 8,
            }}
          >
            {
              'You will be asked to upload image every time you launch a campaign. If you don’t upload any image, By default this image will be shared'
            }
          </p>
        </InputGroup>
      </>
    );
  };

  return (
    <>
      <div className="DarkBg" onClick={() => { }}>
        <div className="Center">
          <div
            className="d-flex flex-column justify-content-start align-items-center"
            style={{
              width: 1000,
              height: '80vh',
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 1,
              border: `1px solid ${colors.borderwhite}`,
              paddingBlock: 20,
              paddingInline: 30,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {isLoading ? (
              <>
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <SpinnerLoader />
                </div>
              </>
            ) : (
              <>
                <div
                  className="d-flex flex-row justify-content-between align-items-center w-100"
                  style={{ marginBlockEnd: 16 }}
                >
                  <h2
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: 20,
                      fontWeight: 700,
                      color: colors.black,
                    }}
                  >
                    {templateType === 'loyal_customers_new'
                      ? 'X% off with discount code'
                      : templateType === 'discount_all_products_new'
                        ? 'Thank you message'
                        : templateType === 'repeatcustomer_offer'
                          ? 'Repeat customer offer'
                          : templateType === 'repeatcustomer_offer2'
                            ? 'Repeat customer offer'
                            : templateType === 'do_you_know'
                              ? 'Do you know'
                              : templateType === 'welcome_insta_message_2'
                                ? 'Welcome Instagram'
                                : templateType === 'customer_enquiry'
                                  ? 'Follow up with discount'
                                  : templateType === 'renewal_remainder'
                                    ? 'Send a Reminder'
                                    : templateType === 'share_info'
                                      ? 'Share info/update/quote'
                                      : templateType === 'thank_you_customer'
                                        ? 'Thank your customer & share info'
                                        : templateType === 'customer_win_back'
                                          ? 'Customer win back'
                                          : templateType === 'additional_discount_offer'
                                            ? 'Additional discount offer'
                                            : templateType === 'business_info_newsletter'
                                              ? 'Business info newsletter'
                                              : templateType === 'maintainance_update'
                                                ? 'Maintenance update'
                                                : templateType === 'new_product_service_marathi'
                                                  ? 'New product service Marathi'
                                                  : templateType === 'share_an_offer_media_button'
                                                    ? 'Share an Offer'
                                                    : templateType === 'new_course_media_button'
                                                      ? 'Sell a Course'
                                                      : templateType === 'new_product_media_button' && 'Sell a Product'}
                  </h2>
                  <img
                    src={images.CMClose}
                    style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                    onClick={() => {
                      setIsSubmitModalOpen(false);
                    }}
                  ></img>
                </div>
                <div
                  className="w-100 d-flex flex-row justify-content-between align-items-center"
                  style={{ height: 'calc( 100% - 45px )' }}
                >
                  {/* Enter details Col */}
                  <div
                    className="h-100 d-flex flex-column justify-content-start align-items-center submitTemplateScroll"
                    style={{ width: '60%', overflowY: 'scroll', paddingInline: 8 }}
                  >
                    <UploadImageComponent />
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      ref={upimgref}
                      accept=".jpeg, .png, .jpg"
                      onChange={(e) => {
                        handleimgupload(e);
                      }}
                    />
                    {templateType === 'renewal_remainder' && (
                      <>
                        <CustomerNameInput
                          CustomerName={CustomerName}
                          setCustomerName={setCustomerName}
                          heading={'Enter customer name'}
                          placeholder={'ex: John'}
                        />
                        <RenewalTextInput
                          renewalText={renewalText}
                          setRenewalText={setRenewalText}
                          heading={'Enter reminder description'}
                          placeholder={'ex: your membership is up for renewal by 27th April'}
                        />
                        <RNumberInput
                          Rnumber={Rnumber}
                          setRNumber={setRNumber}
                          heading={'Enter callback number'}
                          placeholder={'Enter here'}
                        />
                        <CompanyNameInput
                          companyName={companyName}
                          setCompanyName={setCompanyName}
                          heading={'Enter company/sender name'}
                          placeholder={'Enter here'}
                        />
                      </>
                    )}
                    {templateType === 'maintainance_update' && (
                      <>
                        <CustomerNameInput
                          CustomerName={CustomerName}
                          setCustomerName={setCustomerName}
                          heading={'Enter customer name'}
                          placeholder={'ex: John'}
                        />
                        <InfoInput
                          Info={Info}
                          setInfo={setInfo}
                          heading={'Maintenance message'}
                          placeholder={
                            'ex: looking into the issue that came to our server and will try to fix it as soon as possible.'
                          }
                        />
                        <CompanyNameInput
                          companyName={companyName}
                          setCompanyName={setCompanyName}
                          heading={'Business name'}
                          placeholder={'ex: Team Cheerio'}
                        />
                      </>
                    )}
                    {templateType === 'new_product_service_marathi' && (
                      <>
                        <CustomerNameInput
                          CustomerName={CustomerName}
                          setCustomerName={setCustomerName}
                          heading={'Enter customer name'}
                          placeholder={'ex: John'}
                        />
                        <ProductNameInput
                          productName={productName}
                          setProductName={setProductName}
                          heading={'Message'}
                          placeholder={
                            'ex: आपल्यासाठी घेऊन आलो आहोत एक नवीन उत्पादन - रबर मॅट्स. ज्या आपण आपल्या रोजच्या वापरासाठी उपयोगात आणू शकतात.'
                          }
                        />
                      </>
                    )}
                    {templateType === 'do_you_know' && (
                      <>
                        <CustomerNameInput
                          CustomerName={CustomerName}
                          setCustomerName={setCustomerName}
                          heading={'Enter customer name'}
                          placeholder={'ex: John'}
                        />
                        <ProductNameInput
                          productName={productName}
                          setProductName={setProductName}
                          heading={'Message'}
                          placeholder={'ex: knowing your Prakriti helps you in the following ways:'}
                        />
                        <Feature1Input
                          Feature1={Feature1}
                          setFeature1={setFeature1}
                          heading={'Feature 1'}
                          placeholder={
                            'ex: 1. Get a Personalized treatment plan for your health issues'
                          }
                        />
                        <Feature2Input
                          Feature2={Feature2}
                          setFeature2={setFeature2}
                          heading={'Feature 2'}
                          placeholder={'ex: 2. Prevent any disease from occuring'}
                        />
                        <Feature3Input
                          Feature3={Feature3}
                          setFeature3={setFeature3}
                          heading={'Feature 3'}
                          placeholder={'ex: 3. Improved quality of life'}
                        />
                        <Feature4Input
                          Feature4={Feature4}
                          setFeature4={setFeature4}
                          heading={'Feature 4'}
                          placeholder={'ex: 4. Better management of chronic conditions'}
                        />
                        <InfoInput
                          Info={Info}
                          setInfo={setInfo}
                          heading={'Info message'}
                          placeholder={
                            'ex: read about the 3 types of Prakriti on our Blog to know what they are and ...'
                          }
                        />
                        <RNumberInput
                          Rnumber={Rnumber}
                          setRNumber={setRNumber}
                          heading={'Contact details'}
                          placeholder={'Enter here'}
                        />
                      </>
                    )}
                    {templateType === 'discount_all_products_new' && (
                      <>
                        <CustomerNameInput
                          CustomerName={CustomerName}
                          setCustomerName={setCustomerName}
                          heading={'Enter customer name'}
                          placeholder={'ex: John'}
                        />
                        <CompanyNameInput
                          companyName={companyName}
                          setCompanyName={setCompanyName}
                          heading={'Business name'}
                          placeholder={'ex: Team Cheerio'}
                        />
                        <DiscountTextInput
                          discountText={discountText}
                          setDiscountText={setDiscountText}
                          heading={'Discount'}
                          placeholder={'ex: 20%'}
                        />
                        <DiscountCodeInput
                          discountCode={discountCode}
                          setDiscountCode={setDiscountCode}
                          heading={'Discount code'}
                          placeholder={'ex: CHEER20'}
                        />
                      </>
                    )}
                    {templateType === 'loyal_customers_new' && (
                      <>
                        <CustomerNameInput
                          CustomerName={CustomerName}
                          setCustomerName={setCustomerName}
                          heading={'Enter customer name'}
                          placeholder={'ex: John'}
                        />
                        <InfoInput
                          Info={Info}
                          setInfo={setInfo}
                          heading={'Quantity info'}
                          placeholder={'ex: 500'}
                        />
                        <ProductNameInput
                          productName={productName}
                          setProductName={setProductName}
                          heading={'Product name'}
                          placeholder={'ex: Cheerio WA'}
                        />
                        <DiscountInput
                          discount={discount}
                          setDiscount={setDiscount}
                          heading={'Discount (in %)'}
                          placeholder={'ex: 20'}
                        />
                        <DiscountCodeInput
                          discountCode={discountCode}
                          setDiscountCode={setDiscountCode}
                          heading={'Discount code'}
                          placeholder={'ex: CHEER20'}
                        />
                        <RNumberInput
                          Rnumber={Rnumber}
                          setRNumber={setRNumber}
                          heading={'Contact details'}
                          placeholder={'Enter here'}
                        />
                      </>
                    )}
                    {templateType === 'welcome_insta_message_2' && (
                      <>
                        <CustomerNameInput
                          CustomerName={CustomerName}
                          setCustomerName={setCustomerName}
                          heading={'Enter customer name'}
                          placeholder={'ex: John'}
                        />
                        <CompanyNameInput
                          companyName={companyName}
                          setCompanyName={setCompanyName}
                          heading={'Business name'}
                          placeholder={'ex: Cheerio'}
                        />
                        <ProductNameInput
                          productName={productName}
                          setProductName={setProductName}
                          heading={'Product name'}
                          placeholder={'ex: CRM Marketing'}
                        />
                        <InstaHandleInput
                          instaHandle={instaHandle}
                          setInstaHandle={setInstaHandle}
                          heading={'Instagram handle'}
                          placeholder={'ex: https://www.instagram.com/cheeriosocial/'}
                        />
                        <SupportEmailInput
                          supportEmail={supportEmail}
                          setSupportEmail={setSupportEmail}
                          heading={'Support Email'}
                          placeholder={'ex: admin@cheeerio.in'}
                        />
                        <DiscountInput
                          discount={discount}
                          setDiscount={setDiscount}
                          heading={'Discount'}
                          placeholder={'ex: 20%'}
                        />
                        <LinkInput
                          Link={Link}
                          setLink={setLink}
                          heading={'Link'}
                          placeholder={'ex: https://www.cheerio.in'}
                        />
                        <DurationInput
                          duration={duration}
                          setDuration={setDuration}
                          heading={'Duration'}
                          placeholder={'ex: 2'}
                        />
                        <RNumberInput
                          Rnumber={Rnumber}
                          setRNumber={setRNumber}
                          heading={'Contact details'}
                          placeholder={'Enter here'}
                        />
                      </>
                    )}
                    {templateType === 'business_info_newsletter' && (
                      <>
                        <CustomerNameInput
                          CustomerName={CustomerName}
                          setCustomerName={setCustomerName}
                          heading={'Enter customer name'}
                          placeholder={'ex: John'}
                        />
                        <ProductNameInput
                          productName={productName}
                          setProductName={setProductName}
                          heading={'Product name'}
                          placeholder={'ex: hassle free CRM'}
                        />
                        <NFeature1Input
                          NFeature1={NFeature1}
                          setNFeature1={setNFeature1}
                          heading={'Not working feature 1'}
                          placeholder={'ex: Not getting a reliable support'}
                        />
                        <NFeature2Input
                          NFeature2={NFeature2}
                          setNFeature2={setNFeature2}
                          heading={'Not working feature 2'}
                          placeholder={'ex: Too expensive'}
                        />
                        <NFeature3Input
                          NFeature3={NFeature3}
                          setNFeature3={setNFeature3}
                          heading={'Not working feature 3'}
                          placeholder={"ex: Don't have the time to compare alternatives"}
                        />
                        <CompanyNameInput
                          companyName={companyName}
                          setCompanyName={setCompanyName}
                          heading={'Business name'}
                          placeholder={'ex: Cheerio'}
                        />
                        <Feature1Input
                          Feature1={Feature1}
                          setFeature1={setFeature1}
                          heading={'Feature 1'}
                          placeholder={'ex: We have over 24 hours support'}
                        />
                        <Feature2Input
                          Feature2={Feature2}
                          setFeature2={setFeature2}
                          heading={'Feature 2'}
                          placeholder={'ex: We have a special 10% discount for you'}
                        />
                        <Feature3Input
                          Feature3={Feature3}
                          setFeature3={setFeature3}
                          heading={'Feature 3'}
                          placeholder={'ex: We give feature by feature parity matrix'}
                        />
                        <LinkInput
                          Link={Link}
                          setLink={setLink}
                          heading={'Link'}
                          placeholder={'ex: cheerio.in'}
                        />
                        <RNumberInput
                          Rnumber={Rnumber}
                          setRNumber={setRNumber}
                          heading={'Contact details'}
                          placeholder={'Enter here'}
                        />
                      </>
                    )}
                    {templateType === 'repeatcustomer_offer' && (
                      <>
                        <CustomerNameInput
                          CustomerName={CustomerName}
                          setCustomerName={setCustomerName}
                          heading={'Enter customer name'}
                          placeholder={'ex: John'}
                        />
                        <CompanyNameInput
                          companyName={companyName}
                          setCompanyName={setCompanyName}
                          heading={'Business name'}
                          placeholder={'ex: Cheerio family'}
                        />
                        <ThankYouInput
                          ThankYou={ThankYou}
                          setThankYou={setThankYou}
                          heading={'Thanking action'}
                          placeholder={'ex: signing up for our free trial'}
                        />
                        <Feature1Input
                          Feature1={Feature1}
                          setFeature1={setFeature1}
                          heading={'Feature'}
                          placeholder={'ex: our discounted licenses on messaging'}
                        />
                        <OccasionInput
                          occasion={occasion}
                          setOccasion={setOccasion}
                          heading={'Occasion'}
                          placeholder={"ex: Mother's day"}
                        />
                        <PromotionTextInput
                          promotionText={promotionText}
                          setPromotionText={setPromotionText}
                          heading={'Promotion text'}
                          placeholder={'ex: 25%'}
                        />
                        <LinkInput
                          Link={Link}
                          setLink={setLink}
                          heading={'Link'}
                          placeholder={'ex: cheerio.in'}
                        />
                        <Feature2Input
                          Feature2={Feature2}
                          setFeature2={setFeature2}
                          heading={'Feature 2'}
                          placeholder={'ex: requirements for web or app messaging.'}
                        />
                        <RNumberInput
                          Rnumber={Rnumber}
                          setRNumber={setRNumber}
                          heading={'Contact details'}
                          placeholder={'Enter here'}
                        />
                      </>
                    )}
                    {templateType === 'customer_win_back' && (
                      <>
                        <CustomerNameInput
                          CustomerName={CustomerName}
                          setCustomerName={setCustomerName}
                          heading={'Enter customer name'}
                          placeholder={'ex: John'}
                        />
                        <PromotionTextInput
                          promotionText={promotionText}
                          setPromotionText={setPromotionText}
                          heading={'Promotion text'}
                          placeholder={'ex: surprise'}
                        />
                        <CompanyNameInput
                          companyName={companyName}
                          setCompanyName={setCompanyName}
                          heading={'Business name'}
                          placeholder={'ex: Cheerio web app'}
                        />
                        <Feature1Input
                          Feature1={Feature1}
                          setFeature1={setFeature1}
                          heading={'Feature'}
                          placeholder={'ex: chatbot we just released'}
                        />
                        <DiscountCodeInput
                          discountCode={discountCode}
                          setDiscountCode={setDiscountCode}
                          heading={'Discount code'}
                          placeholder={'ex: CH500'}
                        />
                      </>
                    )}
                    {templateType === 'additional_discount_offer' && (
                      <>
                        <GreetingInput
                          Greeting={Greeting}
                          setGreeting={setGreeting}
                          heading={'Greeting'}
                          placeholder={'ex: coding superstarts'}
                        />
                        <CompanyNameInput
                          companyName={companyName}
                          setCompanyName={setCompanyName}
                          heading={'Business name'}
                          placeholder={'ex: Cheerio'}
                        />
                        <OccasionInput
                          occasion={occasion}
                          setOccasion={setOccasion}
                          heading={'Occasion'}
                          placeholder={'ex: Diwali'}
                        />
                        <PromotionTextInput
                          promotionText={promotionText}
                          setPromotionText={setPromotionText}
                          heading={'Promotion text'}
                          placeholder={'ex: 5% off'}
                        />
                        <Feature1Input
                          Feature1={Feature1}
                          setFeature1={setFeature1}
                          heading={'Feature'}
                          placeholder={'ex: our documentation and support is unmatched'}
                        />
                      </>
                    )}
                    {templateType === 'share_info' && (
                      <>
                        <CustomerNameInput
                          CustomerName={CustomerName}
                          setCustomerName={setCustomerName}
                          heading={'Enter customer name'}
                          placeholder={'ex: John'}
                        />
                        <GreetingInput
                          Greeting={Greeting}
                          setGreeting={setGreeting}
                          heading={'Greeting'}
                          placeholder={'ex: Good Morning.'}
                        />
                        <InfoTypeInput
                          InfoType={InfoType}
                          setInfoType={setInfoType}
                          heading={'Type of message'}
                          placeholder={'ex: tip, headline, thought, etc '}
                        />
                        <InfoInput
                          Info={Info}
                          setInfo={setInfo}
                          heading={'Add message'}
                          placeholder={
                            'ex: When sending emails, make sure to add a prominent unsubscribe button ...'
                          }
                        />
                        <ProfessionInput
                          Profession={Profession}
                          setProfession={setProfession}
                          heading={'Supporting message'}
                          placeholder={'ex: marketer'}
                        />
                      </>
                    )}
                    {templateType === 'thank_you_customer' && (
                      <>
                        <CustomerNameInput
                          CustomerName={CustomerName}
                          setCustomerName={setCustomerName}
                          heading={'Enter customer name'}
                          placeholder={'ex: John'}
                        />
                        <ThankYouInput
                          ThankYou={ThankYou}
                          setThankYou={setThankYou}
                          heading={'Action'}
                          placeholder={'ex: installing our app.'}
                        />
                        <LinkDescriptionInput
                          LinkDesc={LinkDesc}
                          setLinkDesc={setLinkDesc}
                          heading={'Supporting text'}
                          placeholder={'ex: the youtube link for the demo video.'}
                        />
                        <LinkInput
                          Link={Link}
                          setLink={setLink}
                          heading={'Link'}
                          placeholder={'ex: https://youtu.be/OqfnppSZdwA'}
                        />
                        <Feature1Input
                          Feature1={Feature1}
                          setFeature1={setFeature1}
                          heading={'Feature 1'}
                          placeholder={'ex: WhatsApp Integration'}
                        />
                        <Feature2Input
                          Feature2={Feature2}
                          setFeature2={setFeature2}
                          heading={'Feature 2'}
                          placeholder={'ex: Email Integration'}
                        />
                        <Feature3Input
                          Feature3={Feature3}
                          setFeature3={setFeature3}
                          heading={'Feature 3'}
                          placeholder={'ex: Advanced analytics'}
                        />
                        <Feature4Input
                          Feature4={Feature4}
                          setFeature4={setFeature4}
                          heading={'Feature 4'}
                          placeholder={'ex: Link CTR tracking'}
                        />
                        <SellerNameInput
                          sellerName={sellerName}
                          setSellerName={setSellerName}
                          heading={'Sender name'}
                          placeholder={'Enter sender name'}
                        />
                        <CompanyNameInput
                          companyName={companyName}
                          setCompanyName={setCompanyName}
                          heading={'Company name'}
                          placeholder={'ex: Enter company name'}
                        />
                      </>
                    )}
                    {templateType === 'customer_enquiry' && (
                      <>
                        <CustomerNameInput
                          CustomerName={CustomerName}
                          setCustomerName={setCustomerName}
                          heading={'Enter customer name'}
                          placeholder={'ex: John'}
                        />
                        <SellerNameInput
                          sellerName={sellerName}
                          setSellerName={setSellerName}
                          heading={'Seller name'}
                          placeholder={'Enter seller name'}
                        />
                        <CompanyNameInput
                          companyName={companyName}
                          setCompanyName={setCompanyName}
                          heading={'Company name'}
                          placeholder={'ex: Cheerio'}
                        />
                        <DiscountInput
                          discount={discount}
                          setDiscount={setDiscount}
                          heading={'Discount (in %)'}
                          placeholder={'ex: 20'}
                        />
                        <DaysTillInput
                          daystill={daystill}
                          setDaystill={setDaystill}
                          heading={'Offer valid till'}
                          placeholder={'ex: 24th April'}
                        />
                      </>
                    )}
                    {templateType === 'new_course_media_button' && (
                      <>
                        <CustomerNameInput
                          CustomerName={CustomerName}
                          setCustomerName={setCustomerName}
                          heading={'Enter customer name'}
                          placeholder={'ex: John'}
                        />
                        <SellerNameInput
                          sellerName={sellerName}
                          setSellerName={setSellerName}
                          heading={'Seller name'}
                          placeholder={'Enter seller name'}
                        />
                        <ProductNameInput
                          productName={productName}
                          setProductName={setProductName}
                          heading={'Course name'}
                          placeholder={'Enter course name'}
                        />
                      </>
                    )}
                    {templateType === 'new_product_media_button' && (
                      <>
                        <CustomerNameInput
                          CustomerName={CustomerName}
                          setCustomerName={setCustomerName}
                          heading={'Enter customer name'}
                          placeholder={'ex: John'}
                        />
                        <SellerNameInput
                          sellerName={sellerName}
                          setSellerName={setSellerName}
                          heading={'Seller name'}
                          placeholder={'Enter seller name'}
                        />
                        <ProductNameInput
                          productName={productName}
                          setProductName={setProductName}
                          heading={'Product name'}
                          placeholder={'Enter product name'}
                        />
                      </>
                    )}
                    {templateType === 'share_an_offer_media_button' && (
                      <>
                        <CustomerNameInput
                          CustomerName={CustomerName}
                          setCustomerName={setCustomerName}
                          heading={'Enter customer name'}
                          placeholder={'ex: John'}
                        />
                        <SellerNameInput
                          sellerName={sellerName}
                          setSellerName={setSellerName}
                          heading={'Seller name'}
                          placeholder={'Enter seller name'}
                        />
                        <ProductNameInput
                          productName={productName}
                          setProductName={setProductName}
                          heading={'Product name'}
                          placeholder={'Enter product name'}
                        />
                        <DiscountInput
                          discount={discount}
                          setDiscount={setDiscount}
                          heading={'Discount (in %)'}
                          placeholder={'Enter discount'}
                        />
                        <DaysInput
                          days={days}
                          setDays={setDays}
                          heading={'No. of days offer available'}
                          placeholder={'Enter no. of days'}
                        />
                      </>
                    )}
                  </div>
                  <div
                    className="h-100 d-flex flex-column justify-content-between align-items-center"
                    style={{ width: '40%', paddingInline: 16 }}
                  >
                    <div
                      className="w-100 d-flex flex-column justify-content-start align-items-center WAPreviewScroll"
                      style={{
                        flex: 1,
                        border: `1px solid ${colors.borderwhite}`,
                        marginBlockEnd: 16,
                        marginBlockStart: 8,
                        borderRadius: 16,
                        paddingBlock: 10,
                        overflowY: 'auto',
                      }}
                    >
                      <h4 style={{ fontSize: 16, fontWeight: 700, color: colors.black }}>
                        {'Message Preview'}
                      </h4>
                      <WAPreview
                        FormData={formdata}
                        upimage={upimg64}
                        templateType={templateType}
                      />
                    </div>
                    <CheerioButton
                      borderStyle={{ borderColor: colors.black, width: '100%' }}
                      textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                      btnText={'Submit Template'}
                      backColor={colors.black}
                      // icon={images.WhatsAppWhite}
                      onclick={() => {
                        console.log('submit template clicked');
                        eventsend("Submit_Template_builder_Clicked");
                        submitTemplateAction();
                      }}
                      disabled={enableSubmit ? false : true}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmitModal;
