import React from 'react'

const TicketComponent = ({ count, label }) => {
  return (
    <>
    <div className='ticket_component_container shadow_small'>
        <p className='count_style'>
            {count}
        </p>
        <p className='label_style'>
            {label}
        </p>
    </div>
    </>
  )
}

export default TicketComponent