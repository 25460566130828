import React from 'react'
import images from '../../Utils/images'
import CheerioButton from '../../Components/CheerioButton'
import { useNavigate } from 'react-router-dom'

const NoIntegrations = () => {
    const navigate = useNavigate()
    return (
        <>
            <div
                className="d-flex justify-content-between"
                style={{
                    borderBottom: '1px solid #CDCDCD',
                    padding: '1%',
                    width: '100%',
                    backgroundColor: 'white',
                    alignItems: 'center',
                }}
            >
                <p style={{ fontWeight: '700', fontSize: '20px' }}>Manage Integrations</p>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 10,
                marginTop: "10%"

            }}>
                <img src={images?.NOINTEGRATIONLOGO} alt="" height={"250px"} />
                <div style={{ fontWeight: "bold", textAlign: "center" }}>
                    Looks like you’ve not integrated any tool yet <br />
                    <span style={{ color: "#666666", fontWeight: 400 }}>unleash the power of integrations</span>
                </div>
                <CheerioButton
                    className="button"
                    borderStyle={{
                        height: '42px',
                        borderColor: "white"
                    }}
                    textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
                    btnText={'Discover Integrations'}
                    onclick={() => {
                        navigate('/creator/discoverPlatforms');
                    }}
                    backColor={'#8E53F7'}
                />

            </div>
        </>

    )
}

export default NoIntegrations