import React from 'react';
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import images from '../../../Utils/images';
import CustomHandle from './CustomHandle';

const handleStyle = { height: 8, width: 8, borderRadius: 4, backgroundColor: '#0176FF' };

const KylasUpdateNode = ({ data, isConnectable }) => {
  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          border: `1px solid ${colors.borderwhite}`,
          borderRadius: 16,
          backgroundColor: colors.white01,
          width: 250,
        }}
      >
        <div
          className="w-100 d-flex flex-column justify-content-start align-items-center"
          style={{
            paddingInline: 16,
            paddingBlock: 8,
            backgroundColor: '#0176FF',
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          }}
        >
          <div
            style={{
              height: 4,
              width: '20%',
              borderRadius: 2,
              backgroundColor: colors.white,
              marginBlockEnd: 4,
            }}
          ></div>
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <p
              style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.white }}
            >
              {'Kylas Event Update'}
            </p>
            <img
              src={images.Edit}
              style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}
            ></img>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-start align-items-center w-100 py-2 px-2">
          {data?.options ? (
            <>
              {data?.options?.map((item, index) => {
                return (
                  <>
                    <div
                      key={`key=${index + 1}`}
                      className="d-flex flex-row justify-content-start align-items-center w-100"
                      style={{
                        borderRadius: 8,
                        backgroundColor: colors.white03,
                        marginBlock: 8,
                        paddingBlock: 6,
                        paddingInline: 16,
                      }}
                    >
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 12,
                          fontWeight: 600,
                          color: colors.black,
                        }}
                      >
                        {`Condition node ${index + 1}`}
                      </p>
                      <CustomHandle
                        type="source"
                        // position={Position.Right}
                        position={Position.Right}
                        id={`child_node_${index}`}
                        style={{ ...handleStyle, top: 'auto', bottom: `calc( ${index}*16 )` }}
                        isConnectable={1}
                      />
                    </div>
                  </>
                );
              })}
            </>
          ) : (
            <>
              <p
                style={{
                  marginBlock: 8,
                  fontSize: 12,
                  color: colors.black,
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {'Add a condition'}
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default KylasUpdateNode;
