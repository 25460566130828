import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import '../PremiumPricing/styles.scss'
import images from '../../Utils/images';
import data from '../PremiumPricing/Components/TableData.json';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PremiumModal from '../CreatorNew/Components/PremiumModal';
import { useNavigate } from 'react-router-dom';

export const FeatureItem = ({ item, currency, planDuration, seeDetails = false, seeDetailsAction, setShowPremiumModal, setSelectedPlan, }) => {

    const localdata = useSelector((state) => state.main.localdata);

    const buttonText = localdata?.premiumPlanName && (item?.name === localdata?.premiumPlanName && planDuration === localdata?.premium_details?.plan_type && currency === localdata?.premium_details?.currency) ? 'Current Active Plan' : (seeDetails ? 'See Details' : 'Purchase plan');

    const itemColor =
        item.name == 'Basic' ? '#EC596B' :
            item.name == 'Startup' ? '#FF9C54' :
                item.name == 'Growth' ? '#00B39E' :
                    item.name == 'Pro' ? '#0B7F63' :
                        item.name == 'Enterprise' && '#0073C6';

    const itemPrice =
        currency == 'INR' && planDuration == 'Quarterly' ? item.priceINR[0] :
            currency == 'INR' && planDuration == 'Half-Yearly' ? item.priceINR[1] :
                currency == 'INR' && planDuration == 'Yearly' ? item.priceINR[2] :
                    currency == 'USD' && planDuration == 'Quarterly' ? item.priceUSD[0] :
                        currency == 'USD' && planDuration == 'Half-Yearly' ? item.priceUSD[1] :
                            currency == 'USD' && planDuration == 'Yearly' && item.priceUSD[2];

    const purchasePlansAction = () => {
        setSelectedPlan({
            planName: item.name,
            planDuration: planDuration,
            currency: currency
        });
        setShowPremiumModal(true);
    }



    return (
        <>
            <div className='feature_item_container'>
                <div className='feature_item' style={{ backgroundColor: itemColor }}>
                    <p className='font16 white01' style={{ fontWeight: 700 }}>
                        {item.name}
                    </p>
                    <p className='font20 white01' style={{ marginBlockEnd: 16, marginBlockStart: 8, fontWeight: 700 }}>
                        {`${currency === 'INR' ? '₹' : '$'}${itemPrice}`}
                        <span className='font14 white01' style={{ fontSize: 12 }}>
                            {`/${planDuration} ${currency === 'INR' ? '(+18% GST)' : ''}`}
                        </span>
                    </p>
                    <p className='font14 white01' style={{ width: '100%', textAlign: 'center', fontSize: 12 }}>
                        {'1000 free service conversations'}
                    </p>
                    <div className='custom_button_white' style={{ width: '100%', marginBlockStart: 16, borderColor: 'white', marginInline: 0 }} onClick={() => {
                        if (seeDetails) {
                            seeDetailsAction();
                        } else if (localdata?.premium === false || (item?.name !== localdata?.premiumPlanName || planDuration !== localdata?.premium_details?.plan_type || currency !== localdata?.premium_details?.currency)) {
                            purchasePlansAction();
                        }
                    }}>
                        <p className='font14' style={{ color: itemColor }}>
                            {buttonText}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}


export const PlanDetails = [
    {
        name: "Basic",
        priceINR: ['4,500', '7,500', '12,000'],
        priceUSD: ['56', '94', '150'],
        INRID: ['plan_OyCNaABf59WSMO', 'plan_OyCTRCXBKTIvg0', 'plan_OyCXxb0OQKcuF2'],
        USDID: ['plan_OSAQhGj7slsjPf', 'plan_OTJfFLMqpOMMQW', 'plan_OTK3czSgCcIbtT'],
    },
    {
        name: "Startup",
        priceINR: ['9,000', '15,000', '24,000'],
        priceUSD: ['113', '188', '300'],
        INRID: ['plan_OyCPF8T7TiRc6A', 'plan_OyCU80cFjVWIiL', 'plan_OyCa7f7nLYgGpZ'],
        USDID: ['plan_OSASamBeIYDFtB', 'plan_OTJigtGioONAId', 'plan_OTK4xBePPvCsgZ'],
    },
    {
        name: "Growth",
        priceINR: ['15,000', '21,000', '36,000'],
        priceUSD: ['188', '263', '450'],
        INRID: ['plan_OyCQApFJFdJTqI', 'plan_OyCV8j9XXqFB5z', 'plan_OyCac2ehZK1zsZ'],
        USDID: ['plan_OSATjFb7Apvowu', 'plan_OTJlRB9Y4Cx8g6', 'plan_OTK7MlmvW0JCRR'],
    },
    {
        name: "Pro",
        priceINR: ['20,000', '27,000', '48,000'],
        priceUSD: ['250', '338', '600'],
        INRID: ['plan_OyCR3y5LHeMQKL', 'plan_OyCW5dwoi4RNyw', 'plan_OyCbEeFG2gayDo'],
        USDID: ['plan_OSAUdRUvleKmf6', 'plan_OTJnFdKl9FkqTY', 'plan_OTK9hXOmFvYmoo'],
    },
    {
        name: "Enterprise",
        duration: "Quarterly",
        priceINR: ['70,000', '120,000', '200,000'],
        priceUSD: ['875', '1,500', '2,500'],
        INRID: ['plan_OyCRqbjVLaR3QG', 'plan_OyCWsK02DtUGGj', 'plan_OyCc4AbjOyMslf'],
        USDID: ['plan_OSAVJLbMULJTU4', 'plan_OTJvKy4q6aMBqm', 'plan_OTKBhayTDmzmth'],
    }
]

const PremiumPricing = () => {
    const [showPremiumModal, setShowPremiumModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [planDuration, setPlanDuration] = useState('Quarterly');
    const [currency, setCurrency] = useState('INR');
    const navigate = useNavigate();

    return (

        <>
            {showPremiumModal && selectedPlan && (
                <PremiumModal
                    setShowPremiumModal={setShowPremiumModal}
                    showPremiumModal={showPremiumModal}
                    planName={selectedPlan.planName}
                    planDuration={selectedPlan.planDuration}
                    currency={selectedPlan.currency}
                />
            )}
            <div className="premium_navbar" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 20px',
                // borderBottom: '1px solid #E0E0E0',
                backgroundColor: '#FFFFFF'
            }}>
                <div className="nav_left" style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={images.BackArrowBlack}
                        alt="back"
                        style={{ marginRight: 10, cursor: 'pointer', width: '32px', height: '32px' }}
                        onClick={() => {
                            navigate('/creator')
                        }}
                    />
                    <p style={{ fontSize: 20, fontWeight: 700 }}>Upgrade to Cheerio Premium</p>
                </div>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '6%',
                    alignItems: 'center',
                    textAlign: 'center',
                    flexDirection: 'column',
                    gap: 40
                }}
            >
                <h2

                >
                    Best AI-Based Omnichannel Platform with affordable plans & service
                </h2>
                <p>
                    Market on multiple platforms, Automate customer support, & sell over WhatsApp or Email
                </p>
            </div>
            {/* Selectors */}
            <div className='pricing_selector_container'>

                {/* Duration */}
                <div className='Row' style={{ justifyContent: 'center', marginInline: 16 }}>
                    <div
                        className={`pricing_button ${planDuration === 'Quarterly' ? 'pb_selected' : 'pb_unselected'} pb_left`}
                        onClick={() => {
                            setPlanDuration('Quarterly')
                        }}
                    >
                        <p className={`font16 ${planDuration === 'Quarterly' ? 'white01' : 'black01'}`}>
                            {'Quarterly'}
                        </p>
                    </div>
                    <div
                        className={`pricing_button ${planDuration === 'Half-Yearly' ? 'pb_selected' : 'pb_unselected'}`}
                        onClick={() => {
                            setPlanDuration('Half-Yearly')
                        }}
                    >
                        <div className='Row' style={{ justifyContent: 'center' }}>
                            <p className={`font16 ${planDuration === 'Half-Yearly' ? 'white01' : 'black01'}`}>
                                {'Half-Yearly '}
                            </p>
                            <span className='off_span'>
                                {'8% off'}
                            </span>
                        </div>
                    </div>
                    <div
                        className={`pricing_button ${planDuration === 'Yearly' ? 'pb_selected' : 'pb_unselected'} pb_right`}
                        onClick={() => {
                            setPlanDuration('Yearly')
                        }}
                    >
                        <div className='Row' style={{ justifyContent: 'center' }}>
                            <p className={`font16 ${planDuration === 'Yearly' ? 'white01' : 'black01'}`}>
                                {'Yearly'}
                            </p>
                            <span className='off_span'>
                                {'20% off'}
                            </span>
                        </div>
                    </div>
                </div>

                {/* Currency */}
                <div className='Row' style={{ justifyContent: 'center', marginInline: 16 }}>
                    <div
                        className={`pricing_button ${currency === 'INR' ? 'pb_selected' : 'pb_unselected'} pb_left`}
                        onClick={() => {
                            setCurrency('INR')
                        }}
                    >
                        <div className='Row' style={{ justifyContent: 'center' }}>
                            <p className={`font16 ${currency === 'INR' ? 'white01' : 'black01'}`}>
                                {'INR'}
                            </p>
                            <img src={images.FlagIndia} style={{
                                height: 12, width: 12, objectFit: 'contain', marginInlineStart: 6
                            }}></img>
                        </div>
                    </div>
                    <div
                        className={`pricing_button ${currency === 'USD' ? 'pb_selected' : 'pb_unselected'} pb_right`}
                        onClick={() => {
                            setCurrency('USD')
                        }}
                    >
                        <div className='Row' style={{ justifyContent: 'center' }}>
                            <p className={`font16 ${currency === 'USD' ? 'white01' : 'black01'}`}>
                                {'USA'}
                            </p>
                            <img src={images.FlagUSA} style={{
                                height: 12, width: 12, objectFit: 'contain', marginInlineStart: 6
                            }}></img>
                        </div>
                    </div>
                </div>
            </div>

            {/* Features Strip */}
            <div className='feature_strip_container'>
                <div className='feature_item_container'>
                    <div className='feature_item'>
                        <p className='font18 black01'>
                            {'Features'}
                        </p>
                    </div>
                </div>
                {PlanDetails.map((item, index) => {
                    return (
                        <FeatureItem item={item} currency={currency} planDuration={planDuration} seeDetailsAction={() => { }} setShowPremiumModal={setShowPremiumModal}
                            setSelectedPlan={setSelectedPlan} />
                    )
                })}
            </div>


            {/* Table */}
            <Table style={{ marginBlock: 16 }}>
                <TableBody>
                    {data.slice(0, 5).map((row) => {
                        return (
                            <TableRow style={{ backgroundColor: 'white', paddingInline: 8 }}>
                                <TableCell style={{ width: '16%', border: 'none' }} align='left'>{row.feature}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{currency == 'USD' ? 'Regional Pricing' : row.basic}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{currency == 'USD' ? 'Regional Pricing' : row.startup}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{currency == 'USD' ? 'Regional Pricing' : row.growth}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{currency == 'USD' ? 'Regional Pricing' : row.pro}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{currency == 'USD' ? 'Regional Pricing' : row.enterprise}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            <Table style={{ marginBlock: 16 }}>
                <TableBody>
                    {data.slice(5, 17).map((row) => {
                        return (
                            <TableRow style={{ backgroundColor: 'white' }}>
                                <TableCell style={{ width: '16%', border: 'none', }} align='left'>{row.feature}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{row.basic}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{row.startup}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{row.growth}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{row.pro}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{row.enterprise}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            <Table style={{ marginBlock: 16 }}>
                <TableBody>
                    {data.slice(17, 22).map((row) => {
                        return (
                            <TableRow style={{ backgroundColor: 'white' }}>
                                <TableCell style={{ width: '16%', border: 'none', }} align='left'>{row.feature}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{row.basic}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{row.startup}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{row.growth}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{row.pro}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{row.enterprise}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            <Table style={{ marginBlock: 16 }}>
                <TableBody>
                    {data.slice(22, 27).map((row) => {
                        return (
                            <TableRow style={{ backgroundColor: 'white' }}>
                                <TableCell style={{ width: '16%', border: 'none', }} align='left'>{row.feature}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{row.basic}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{row.startup}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{row.growth}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{row.pro}</TableCell>
                                <TableCell style={{ width: '16%', border: 'none', }} align='center'>{row.enterprise}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </>
    )
}

export default PremiumPricing