import React, { useRef, useState } from 'react';
import colors from '../../Utils/colors';
import images from '../../Utils/images';

const SetupWebhook = ({ setIsWebhookOpen }) => {
  const [ccopysuccess, setCcopysuccess] = useState('');
  const [tcopysuccess, setTcopysuccess] = useState('');

  const copytoClipboard1 = () => {
    navigator.clipboard.writeText('https://pre-prod.cheerio.in:3443/webhooks');
    setCcopysuccess('Copied!');
  };
  const copytoClipboard2 = () => {
    navigator.clipboard.writeText('kJbc1W1YupprLcB8YZE0gla1T8APE7Td');
    setTcopysuccess('Copied!');
  };

  return (
    <>
      <div className="DarkBg" onClick={() => {}}>
        <div className="Center">
          <div
            className="verythinscroll"
            style={{
              width: 750,
              height: '80vh',
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 0,
              border: `1px solid ${colors.borderwhite}`,
              paddingBlock: 30,
              paddingInline: 30,
              overflowY: 'scroll',
            }}
          >
            <div
              className="d-flex flex-column justify-content-start align-items-center w-100"
              style={{ flex: 1 }}
            >
              <div className="d-flex flex-row justify-content-between align-items-center w-100">
                <h2
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 20,
                    fontWeight: 700,
                    color: colors.black,
                  }}
                >
                  {'Important Information'}
                </h2>
                <img
                  src={images.CMClose}
                  style={{ height: 32, width: 32, objectFit: 'contain', cursor: 'pointer' }}
                  onClick={() => {
                    setIsWebhookOpen(false);
                  }}
                ></img>
              </div>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  marginBlock: 16,
                  fontSize: 16,
                  fontWeight: 400,
                  color: colors.black,
                  width: '100%',
                }}
              >
                {
                  'Please make sure your WhatsApp Webhooks are set up on Cheerio. Setting up Webhooks is mandatory \
                        to access your replies and analytics data. Without Webhooks, you will not be able to access any of these.'
                }
              </p>
              <h2
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 20,
                  fontWeight: 700,
                  color: colors.black,
                  marginBlock: 16,
                  width: '100%',
                }}
              >
                {'Below are steps to setup WhatsApp Webhooks'}
              </h2>
              <img
                src={images.Webhook1}
                style={{ width: '100%', height: 'auto', objectFit: 'contain', marginBlock: 8 }}
              ></img>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  marginBlock: 16,
                  fontSize: 16,
                  fontWeight: 400,
                  color: colors.black,
                  width: '100%',
                }}
              >
                {'Go to '}
                <span style={{ color: colors.linkblue }}>{'developer.facebook.com'}</span>
                {
                  ' and click on Webhooks on the sidebar and then click on subscribe. This pop-up will come.'
                }
                <br />
                <br />
                {'You need to enter Callback URL as: '}
                <span style={{ color: colors.linkblue }}>
                  {'https://pre-prod.cheerio.in:3443/webhooks'}
                </span>
                <img
                  src={images.Copy}
                  style={{
                    height: 24,
                    width: 24,
                    objectFit: 'contain',
                    marginInline: 8,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    copytoClipboard1();
                  }}
                ></img>
                {ccopysuccess}
                <br />
                <br />
                {'Verify token as: '}
                <span style={{ fontWeight: 600 }}>{'kJbc1W1YupprLcB8YZE0gla1T8APE7Td'}</span>
                <img
                  src={images.Copy}
                  style={{
                    height: 24,
                    width: 24,
                    objectFit: 'contain',
                    marginInline: 8,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    copytoClipboard2();
                  }}
                ></img>
                {tcopysuccess}
              </p>
              <img
                src={images.Webhook2}
                style={{ width: '100%', height: 'auto', objectFit: 'contain', marginBlock: 8 }}
              ></img>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  marginBlock: 16,
                  fontSize: 16,
                  fontWeight: 400,
                  color: colors.black,
                  width: '100%',
                }}
              >
                {
                  'After you click on "verify and save" you will see all Webhooks, make sure to subscribe to ALL of them '
                }
                <span style={{ fontWeight: 600 }}>{'(most importantly messages).'}</span>
              </p>
              <img
                src={images.Webhook3}
                style={{ width: '100%', height: 'auto', objectFit: 'contain', marginBlock: 8 }}
              ></img>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  marginBlock: 16,
                  fontSize: 16,
                  fontWeight: 400,
                  color: colors.black,
                  width: '100%',
                }}
              >
                {
                  'After this, we will start saving your replies in our database and then show that to you on the Dashboard. Make sure to verify changes by going to the configuration tab and checking Webhook permissions.'
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupWebhook;
