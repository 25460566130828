import React, { useState, useEffect } from 'react'
import colors from '../../../Utils/colors';
import { useSelector } from 'react-redux';
import { fetchV2Scheduler, removeV2Scheduler } from '../../../Services';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

function ListSchedulersV2() {
  const authtoken = useSelector((state) => state.main.auth_token);
  const [fetchedData, setFetchedData] = useState({});
  const [rowsSch, setRowsSch] = useState([]);
  const [pageSch, setPageSch] = React.useState(0);
  const [rowsPerPageSch, setRowsPerPageSch] = React.useState(10);

  useEffect(()=>{
    makeScheduledAnnouncementAction(); 
  },[])

  const makeScheduledAnnouncementAction = () => {
    let token = authtoken;
    fetchV2Scheduler(token).then((res) => {
      if (res.flag) {
        setFetchedData(res?.data)
        setRowsSch(res?.data);
        console.log('make schedule ann res=========>', res);
      } else {
        console.log(res);
      }
    });
  };

  const deleteScheduler = (schedulerId) =>{
    let token = authtoken;
    removeV2Scheduler(schedulerId, token).then((res) => {
      if (res.flag) {
        console.log('remove schedule ann res=========>', res);
      } else {
        console.log(res);
      }
    });
    makeScheduledAnnouncementAction();
  }

  const handleChangePageSch = (event, newPage) => {
    setPageSch(newPage);
  };

  const handleChangeRowsPerPageSch = (event) => {
    setRowsPerPageSch(+event.target.value);
    setPageSch(0);
  };

  const scheduledColumns = [
    { id: 'campaignName', label: 'Campaign Name', minWidth: 225, align: 'start', width: '25%' },
    { id: 'scheduleAt', label: 'Scheduled At', minWidth: 135, align: 'center', width: '15%',
      format: (value) => {
        const date = new Date(value);
        const mm = date.toLocaleString('en-EN', { month: 'short' });
        const dd = date.getDate();
        return dd + '-' + mm;
      }
    },
    {
      id: 'labelName',
      label: 'Label',
      minWidth: 180,
      align: 'start', width: '20%',
      // align: 'right',
      format: (value) => {
        return value ? value : 'NA';
      },
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 225,
      align: 'center', width: '25%'
      // align: 'right',
      // format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'action',
      label: 'Action',
      minWidth: 135,
      align: 'center', width: '15%'
      // align: 'right',
      // format: (value) => value.toFixed(2),
    },
  ];


  

  return (

        <>
           {fetchedData?.length > 0 && (
          <>
            <div className="announcement_container__scheduled_header_container">
              <h3>
                {'Scheduled Campaigns '}<span style={{fontSize:18}}>
                  {'(Custom)'}
                </span>
              </h3>
              {/* <CheerioButton 
            borderStyle={{paddingBlock:10}}
            textStyle={{fontSize:12,fontWeight:600,color:colors.white}}
            btnText={'New Campaign'}
            backColor={colors.black}
            onclick={()=>{setOpenANModal(true)}}
            icon={images.IPlusWhite}
        /> */}
            </div>

            {/* <div
              className="d-flex flex-column justify-content-start align-items-center table-scheduler"
              style={{
                width: '100%',
                flex: 1,
                backgroundColor: colors.white,
                borderRadius: 16,
                marginBlock: 8,
                padding: 16,
                border: `1px solid ${colors.borderwhite}`,
              }}
            >
               <table class="table">
                <thead>
                  <tr class="table-light">
                    <th scope="col">Campaign Name</th>
                    <th scope="col">Scheduled on</th>
                    <th scope="col">Label Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                {fetchedData && fetchedData.map((item, index) => (
                  <tr>
                    <th scope="row">  {item?.input?.campaignName}</th>
                    <td>{item.scheduleAt}</td>
                    <td>{item.input.labelName}</td>
                    <td>{item.status}</td>
                    <td><button onClick={()=> deleteScheduler(item._id)} className="btn">Delete</button></td>
                  </tr>
                  ))}
                </tbody>
              </table>
            </div> */}

            <div className='scheduled_campaign_table_container'>
              <TableContainer sx={{flex: 1}}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {scheduledColumns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth , width: column.width,fontSize:16,fontWeight:500,color:colors.black,backgroundColor:colors.primary01}}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsSch
                      .slice(pageSch * rowsPerPageSch, pageSch * rowsPerPageSch + rowsPerPageSch)
                      .map((row) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {scheduledColumns.map((column) => {
                              const value = (column.label === 'Campaign Name' || column.label === 'Label') ? row?.input?.[column.id] : row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  { 
                                  column.format
                                  // {column.format && typeof value === 'number'
                                    ? 
                                    <p style={{color:colors.black,fontSize:14}}>
                                      {column.format(value)}
                                    </p>
                                    : 
                                    column.id === 'action' ? 
                                      <p style={{
                                        fontSize: 14,
                                        color: colors.linkblue,
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        deleteScheduler(row._id);
                                      }}
                                      >{'Delete'}</p> 
                                    : 
                                    <p style={{color:colors.black,fontSize:14}}>
                                      {value}
                                    </p>
                                    }
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rowsSch.length}
                rowsPerPage={rowsPerPageSch}
                page={pageSch}
                onPageChange={handleChangePageSch}
                onRowsPerPageChange={handleChangeRowsPerPageSch}
              />
            </div>
          </>
        )}       
        </>
  )
}

export default ListSchedulersV2