import React, { useCallback } from 'react'
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import images from '../../../Utils/images';
import CustomHandle from './CustomHandle';

const handleStyle = {backgroundColor: '#FF4D4D' ,height:8,width:8,borderRadius:4};

const SendEmailNode = ({data, isConnectable}) => {

    // const onChangeEmail = useCallback((evt)=>{
    //     data.subjectText = evt.target.value;
    // },[])

  return (
    <>
    <div className='d-flex flex-column justify-content-between align-items-center' style={{border:`1px solid ${colors.borderwhite}`,borderRadius:16,backgroundColor:colors.white01,width:250}}>
        <CustomHandle type='target' position={Position.Left} isConnectable={isConnectable} style={handleStyle} />
        <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{paddingInline:16,paddingBlock:8,backgroundColor:'#FF4D4D',borderTopLeftRadius:16,borderTopRightRadius:16}}>
            <div style={{height:4,width:'20%',borderRadius:2,backgroundColor:colors.white,marginBlockEnd:4}}></div>
            <div className='w-100 d-flex flex-row justify-content-between align-items-center' style={{}}>
                <p style={{padding:0,margin:0,fontSize:16,fontWeight:700,color:colors.white}}>
                    {'Send Email'}
                </p>
                <img src={images.Edit} style={{height:20,width:20,objectFit:'contain'}}></img>
            </div>
        </div>
        
        <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{paddingInline:16,paddingBlockStart:8,paddingBlockEnd:16}}>
            <p style={{padding:0,margin:0,marginBlock:4,fontSize:10,fontWeight:600,color:colors.black,width:'100%'}}>
                {'Email mode'}
            </p>
            <InputGroup className="my-1">
                <Form.Control type='text' placeholder={'Enter here'} className='w-100 btncustom'
                style={{borderRadius:8,fontSize:10,fontWeight:400,color:colors.black}}
                // isInvalid={(renewalText.length >= 640)}
                maxLength={640}
                value={data?.emailMode ? data.emailMode : ''}
                // onChange={onChangeEmail}
                />
            </InputGroup>
            <p style={{padding:0,margin:0,marginBlock:4,fontSize:10,fontWeight:600,color:colors.black,width:'100%'}}>
                {'Subject'}
            </p>
            <InputGroup className="my-1">
                <Form.Control type='text' placeholder={'Enter here'} className='w-100 btncustom'
                style={{borderRadius:8,fontSize:10,fontWeight:400,color:colors.black}}
                // isInvalid={(renewalText.length >= 640)}
                maxLength={640}
                value={data?.subject ? data.subject : ''}
                // onChange={onChangeEmail}
                />
            </InputGroup>
            {(data?.emailMode === 'Email Builder' || !data?.emailMode) &&
            <>
                <p style={{padding:0,margin:0,marginBlock:4,fontSize:10,fontWeight:600,color:colors.black,width:'100%'}}>
                    {'Template'}
                </p>
                <InputGroup className="my-1">
                    <Form.Select className='w-100 btncustom' style={{borderRadius:8,fontSize:10,fontWeight:400,color:colors.black}}>
                        <option>{data?.selectedTemplateName ? data.selectedTemplateName : 'Select template'}</option>
                    </Form.Select>
                </InputGroup> 
            </>
            }
            {(data?.emailMode === 'HTML Upload' || !data?.emailMode) && 
            <>
            <p style={{padding:0,margin:0,marginBlock:4,fontSize:10,fontWeight:600,color:colors.black,width:'100%'}}>
                {'HTML file'}
            </p>
            <InputGroup className="my-1">
                <Form.Control type='text' placeholder={'file name'} className='w-100 btncustom'
                style={{borderRadius:8,fontSize:10,fontWeight:400,color:colors.black}}
                // isInvalid={(renewalText.length >= 640)}
                maxLength={640}
                value={data?.HTMLFile?.name ? data.HTMLFile.name : ''}
                // onChange={onChangeEmail}
                />
            </InputGroup>
            </>
            }
            
        </div>
        <CustomHandle type='source' position={Position.Right} isConnectable={1} style={handleStyle} />
    </div>
    </>
  )
}

export default SendEmailNode