import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CheerioButton from '../../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import {
  ShopifyRemoveApi,
  ShopifyStoreIntegrationApi,
  getShopifyDetailsApi,
} from '../../../Services';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import axios from 'axios';
import { axiosInstance } from '../../../Config/axiosConfig';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { eventsend } from '../../../Config/analyticsFunctions';

const Setup = ({ setSelectedMenuItem = false }) => {
  const [shopURL, setShopURL] = useState('');
  const [shopName, setShopName] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [shopifyDetails, setShopifyDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const localdata = useSelector((state) => state.main.localdata);
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);

  useEffect(() => {
    eventsend("WooCommerce_card_Discover_page_clicked");
  }, [])

  const dataCall = async () => {
    return axiosInstance
      .get('woocommerce/addKeys' + `?userId=${localdata?._id}`, {
        headers: {
          'x-access-token': authtoken,
        },
      })
      .then((response) => response.data);
  };

  const { data, isLoading: loading, isError } = useQuery(['ServeDataFtch'], () => dataCall());

  if (loading) {
    return <p>Loading...</p>;
  }

  //console.log('Heu', data);
  const ShopifyIntegrationAPIcall = () => {
    setIsLoading(true);
    let data = {
      user_id: localdata?._id,
      shopURL: shopURL,
      store_name: shopName,
      access_token: accessToken,
      api_secret_key: apiKey,
    };
    let token = authtoken;
    axiosInstance
      .post('woocommerce/addKeys', data, {
        headers: {
          'x-access-token': token,
        },
      })
      .then((res) => {
        setIsLoading(false);
        window.location.reload();
      })
      .catch((e) => {
        toast.error('Something is wrong with the entered credentials! Please re-verify!');
        setIsLoading(false);
      });
  };

  return (
    <>
      <div
        className="h-100 w-100 verythinscrollnew"
        style={{
          overflowY: 'auto',
          backgroundColor: colors.white02,
          border: `1px solid ${colors.borderwhite}`,
        }}
      >
        <div
          className="d-flex flex-column justify-content-start align-items-center w-100 "
          style={{ flex: 1 }}
        >
          {/*(*/}
          <>
            <div
              className="d-flex flex-row justify-content-start align-items-center w-100"
              style={{ paddingInline: 30, marginBlockStart: 30, marginBlockEnd: 16 }}
            >
              <img
                src={images.woocommerceLogo}
                style={{ height: 48, width: 42, objectFit: 'contain' }}
              ></img>
              <div
                className="d-flex flex-column justify-content-between align-items-start"
                style={{ flex: 1, paddingInlineStart: 16 }}
              >
                <h2
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 20,
                    fontWeight: 700,
                    color: colors.black,
                  }}
                >
                  {'WooCommerce Store Integration'}
                </h2>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 16,
                    fontWeight: 400,
                    color: colors.greys04,
                  }}
                >
                  {'Easily configure WooCommerce store to automate your WooCommerce messages'}
                </p>
              </div>
            </div>

            {data.exists && (
              <>
                <h4
                  style={{
                    padding: 0,
                    margin: 0,
                    paddingInline: 30,
                    marginBlock: 16,
                    fontSize: 16,
                    fontWeight: 600,
                    color: colors.black,
                    width: '100%',
                  }}
                >
                  {'Shop URL'}
                </h4>
                <div
                  className="d-flex flex-row justify-content-between align-items-center"
                  style={{ width: 'calc( 100% - 60px )' }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.black,
                    }}
                  >
                    {data.shopURL}
                  </p>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <img
                      src={images.GreenCheck}
                      style={{ height: 24, width: 24, objectFit: 'contain' }}
                    ></img>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        paddingInlineStart: 8,
                        fontSize: 16,
                        fontWeight: 600,
                        color: colors.success03,
                      }}
                    >
                      {'Store Integration Successful'}
                    </p>
                  </div>
                </div>
                <CheerioButton
                  borderStyle={{
                    paddingBlock: 8,
                    marginBlock: 30,
                    paddingInline: 32,
                    marginInlineEnd: 'auto',
                    marginInlineStart: 30,
                    border: `1px solid ${colors.black}`,
                  }}
                  textStyle={{ fontSize: 12, fontWeight: 600, color: colors.black }}
                  btnText={'Delink'}
                  backColor={colors.white02}
                  onclick={() => {
                    eventsend("Delink_store__WooCommerce_integration_page_clicked");
                    let token = authtoken;
                    axiosInstance
                      .delete('woocommerce/addKeys' + `?userId=${localdata?._id}`, {
                        headers: {
                          'x-access-token': token,
                        },
                      })
                      .then((res) => {
                        window.location.reload();
                      });
                  }}
                />
                {setSelectedMenuItem && (
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      paddingInline: 30,
                      marginBlockEnd: 16,
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.black,
                      width: '100%',
                    }}
                  >
                    {'Customers data synced successfully!'}
                    <span
                      style={{ color: colors.linkblue, paddingInlineStart: 10, cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedMenuItem('Customers data');
                      }}
                    >
                      {'View data'}
                    </span>
                  </p>
                )}
              </>
            )}

            {!data.exists && (
              <>
                <Form className="w-75 me-auto" style={{ paddingInline: 30, paddingBlock: 16 }}>
                  <InputGroup className="" style={{ marginBottom: 16 }}>
                    <Form.Label style={{ fontSize: 16, fontWeight: 700, color: colors.black }}>
                      {'Store Name'}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Copy WooCommerce Store Name"
                      className="w-100 btncustom"
                      style={{ borderRadius: 8 }}
                      value={shopName}
                      onChange={(e) => {
                        setShopName(e.target.value);
                      }}
                    />
                  </InputGroup>
                  <InputGroup className="" style={{ marginBottom: 16 }}>
                    <Form.Label style={{ fontSize: 16, fontWeight: 700, color: colors.black }}>
                      {'Shop URL (Ex. www.shopname.com)'}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Copy WooCommerce Store Name"
                      className="w-100 btncustom"
                      style={{ borderRadius: 8 }}
                      value={shopURL}
                      onChange={(e) => {
                        let url = e.target.value;
                        url = url.replace(/^(https?:\/\/)/, '');
                        setShopURL(url);
                      }}
                    />
                  </InputGroup>
                  <InputGroup className="" style={{ marginBottom: 16 }}>
                    <Form.Label style={{ fontSize: 16, fontWeight: 700, color: colors.black }}>
                      {'Consumer Key'}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Copy token from WooCommerce Store"
                      className="w-100 btncustom"
                      style={{ borderRadius: 8 }}
                      value={accessToken}
                      onChange={(e) => {
                        setAccessToken(e.target.value);
                      }}
                      name={'ConsTest'}
                      autoComplete="off"
                    />
                  </InputGroup>
                  <InputGroup className="" style={{ marginBottom: 16 }}>
                    <Form.Label style={{ fontSize: 16, fontWeight: 700, color: colors.black }}>
                      {'Consumer secret'}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Copy key from WooCommerce Store"
                      className="w-100 btncustom"
                      style={{ borderRadius: 8 }}
                      name={'ConsKey'}
                      autoComplete="new-password"
                      value={apiKey}
                      onChange={(e) => {
                        setApiKey(e.target.value);
                      }}
                    />
                  </InputGroup>
                </Form>
                {!isLoading ? (
                  <CheerioButton
                    borderStyle={{
                      paddingBlock: 10,
                      marginBlock: 30,
                      paddingInline: 40,
                      marginInlineEnd: 'auto',
                      marginInlineStart: 30,
                    }}
                    textStyle={{ fontSize: 12, fontWeight: 600, color: colors.white }}
                    btnText={'Submit'}
                    backColor={colors.black}
                    onclick={() => {
                      eventsend("Connect_store__WooCommerce_integration_page_clicked")
                      console.log('Submit button clicked...');
                      ShopifyIntegrationAPIcall();
                    }}
                  />
                ) : (
                  <SpinnerLoader />
                )}
              </>
            )}

            <div
              className="d-flex flex-column justify-content-between align-items-start"
              style={{
                width: 'calc( 100% - 60px )',
                marginBlock: 16,
                border: `1px solid ${colors.borderwhite}`,
                borderRadius: 16,
                paddingBlock: 20,
                paddingInline: 20,
              }}
            >
              <h4
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 16,
                  fontWeight: 700,
                  color: colors.black,
                }}
              >
                {'Help Documentation'}
              </h4>
              <div
                className="d-flex flex-row justify-content-start align-items-center w-100"
                style={{ cursor: 'pointer', marginBlockStart: 16, marginBlockEnd: 8 }}
                onClick={() => {
                  window.open(
                    'https://cheerio-api-doc.notion.site/WooCommerce-Setup-Guide-For-Cheerio-a199935caae9415aa6e8051806de4b5a?pvs=4',
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
              >
                <img
                  src={images.HelpIcon}
                  style={{ height: 24, width: 24, objectFit: 'contain' }}
                ></img>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    paddingInlineStart: 8,
                    fontSize: 16,
                    fontWeight: 400,
                    color: colors.linkblue,
                  }}
                >
                  {'How to get consumer key & consumer secret'}
                </p>
              </div>
              {/* <div className='d-flex flex-row justify-content-start align-items-center w-100' style={{cursor:'pointer',marginBlock:8}}>
                        <img src={images.HelpIcon} style={{height:24,width:24,objectFit:'contain'}}></img>
                        <p style={{padding:0,margin:0,paddingInlineStart:8,fontSize:16,fontWeight:400,color:colors.linkblue}}>
                            {'How to setup automated message templates for your Shopify store?'}
                        </p>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100' style={{cursor:'pointer',marginBlockStart:8}}>
                        <img src={images.HelpIcon} style={{height:24,width:24,objectFit:'contain'}}></img>
                        <p style={{padding:0,margin:0,paddingInlineStart:8,fontSize:16,fontWeight:400,color:colors.linkblue}}>
                            {'How to setup automated messages Flows for your Shopify store?'}
                        </p>
                    </div> */}
            </div>
          </>
          {/*)*/}
        </div>
      </div>
    </>
  );
};

export default Setup;
