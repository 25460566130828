import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

export const SellerNameInput = ({ sellerName, setSellerName, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={sellerName}
          onChange={(e) => {
            setSellerName(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const CompanyNameInput = ({ companyName, setCompanyName, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={companyName}
          onChange={(e) => {
            setCompanyName(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const RenewalTextInput = ({ renewalText, setRenewalText, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <span
          className="ms-auto"
          style={{
            color: renewalText.length > 640 ? colors.error04 : colors.greys04,
            fontSize: 12,
            fontWeight: 400,
            padding: 0,
            margin: 0,
          }}
        >
          {`${renewalText.length}/640`}
        </span>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          isInvalid={renewalText.length >= 640}
          maxLength={640}
          value={renewalText}
          onChange={(e) => {
            setRenewalText(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const RNumberInput = ({ Rnumber, setRNumber, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={Rnumber}
          onChange={(e) => {
            setRNumber(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const CustomerNameInput = ({ CustomerName, setCustomerName, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={CustomerName}
          onChange={(e) => {
            setCustomerName(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const ProductNameInput = ({ productName, setProductName, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <span
          className="ms-auto"
          style={{
            color: productName.length > 640 ? colors.error04 : colors.greys04,
            fontSize: 12,
            fontWeight: 400,
            padding: 0,
            margin: 0,
          }}
        >
          {`${productName.length}/640`}
        </span>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={productName}
          onChange={(e) => {
            setProductName(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const OfferNameInput = ({ offerName, setOfferName, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={offerName}
          onChange={(e) => {
            setOfferName(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const DiscountInput = ({ discount, setDiscount, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={discount}
          onChange={(e) => {
            setDiscount(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const DaysInput = ({ days, setDays, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={days}
          onChange={(e) => {
            setDays(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const DaysTillInput = ({ daystill, setDaystill, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={daystill}
          onChange={(e) => {
            setDaystill(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const ProductLinkInput = ({ productLink, setProductLink, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={productLink}
          onChange={(e) => {
            setProductLink(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const ThankYouInput = ({ ThankYou, setThankYou, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <span
          className="ms-auto"
          style={{
            color: ThankYou.length > 640 ? colors.error04 : colors.greys04,
            fontSize: 12,
            fontWeight: 400,
            padding: 0,
            margin: 0,
          }}
        >
          {`${ThankYou.length}/640`}
        </span>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          isInvalid={ThankYou.length >= 640}
          maxLength={640}
          value={ThankYou}
          onChange={(e) => {
            setThankYou(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const LinkDescriptionInput = ({ LinkDesc, setLinkDesc, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <span
          className="ms-auto"
          style={{
            color: LinkDesc.length > 640 ? colors.error04 : colors.greys04,
            fontSize: 12,
            fontWeight: 400,
            padding: 0,
            margin: 0,
          }}
        >
          {`${LinkDesc.length}/640`}
        </span>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          isInvalid={LinkDesc.length >= 640}
          maxLength={640}
          value={LinkDesc}
          onChange={(e) => {
            setLinkDesc(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const LinkInput = ({ Link, setLink, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={Link}
          onChange={(e) => {
            setLink(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const Feature1Input = ({ Feature1, setFeature1, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <span
          className="ms-auto"
          style={{
            color: Feature1.length > 640 ? colors.error04 : colors.greys04,
            fontSize: 12,
            fontWeight: 400,
            padding: 0,
            margin: 0,
          }}
        >
          {`${Feature1.length}/640`}
        </span>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          maxLength={640}
          value={Feature1}
          onChange={(e) => {
            setFeature1(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const Feature2Input = ({ Feature2, setFeature2, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <span
          className="ms-auto"
          style={{
            color: Feature2.length > 640 ? colors.error04 : colors.greys04,
            fontSize: 12,
            fontWeight: 400,
            padding: 0,
            margin: 0,
          }}
        >
          {`${Feature2.length}/640`}
        </span>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          maxLength={640}
          value={Feature2}
          onChange={(e) => {
            setFeature2(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const Feature3Input = ({ Feature3, setFeature3, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <span
          className="ms-auto"
          style={{
            color: Feature3.length > 640 ? colors.error04 : colors.greys04,
            fontSize: 12,
            fontWeight: 400,
            padding: 0,
            margin: 0,
          }}
        >
          {`${Feature3.length}/640`}
        </span>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          maxLength={640}
          value={Feature3}
          onChange={(e) => {
            setFeature3(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const Feature4Input = ({ Feature4, setFeature4, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <span
          className="ms-auto"
          style={{
            color: Feature4.length > 640 ? colors.error04 : colors.greys04,
            fontSize: 12,
            fontWeight: 400,
            padding: 0,
            margin: 0,
          }}
        >
          {`${Feature4.length}/640`}
        </span>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          maxLength={640}
          value={Feature4}
          onChange={(e) => {
            setFeature4(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const NFeature1Input = ({ NFeature1, setNFeature1, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <span
          className="ms-auto"
          style={{
            color: NFeature1.length > 640 ? colors.error04 : colors.greys04,
            fontSize: 12,
            fontWeight: 400,
            padding: 0,
            margin: 0,
          }}
        >
          {`${NFeature1.length}/640`}
        </span>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          maxLength={640}
          value={NFeature1}
          onChange={(e) => {
            setNFeature1(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const NFeature2Input = ({ NFeature2, setNFeature2, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <span
          className="ms-auto"
          style={{
            color: NFeature2.length > 640 ? colors.error04 : colors.greys04,
            fontSize: 12,
            fontWeight: 400,
            padding: 0,
            margin: 0,
          }}
        >
          {`${NFeature2.length}/640`}
        </span>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          maxLength={640}
          value={NFeature2}
          onChange={(e) => {
            setNFeature2(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const NFeature3Input = ({ NFeature3, setNFeature3, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <span
          className="ms-auto"
          style={{
            color: NFeature3.length > 640 ? colors.error04 : colors.greys04,
            fontSize: 12,
            fontWeight: 400,
            padding: 0,
            margin: 0,
          }}
        >
          {`${NFeature3.length}/640`}
        </span>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          maxLength={640}
          value={NFeature3}
          onChange={(e) => {
            setNFeature3(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const GreetingInput = ({ Greeting, setGreeting, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={Greeting}
          onChange={(e) => {
            setGreeting(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const InfoTypeInput = ({ InfoType, setInfoType, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={InfoType}
          onChange={(e) => {
            setInfoType(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const InfoInput = ({ Info, setInfo, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <span
          className="ms-auto"
          style={{
            color: Info.length > 640 ? colors.error04 : colors.greys04,
            fontSize: 12,
            fontWeight: 400,
            padding: 0,
            margin: 0,
          }}
        >
          {`${Info.length}/640`}
        </span>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          isInvalid={Info.length >= 640}
          maxLength={640}
          value={Info}
          onChange={(e) => {
            setInfo(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const ProfessionInput = ({ Profession, setProfession, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={Profession}
          onChange={(e) => {
            setProfession(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const PromotionTextInput = ({ promotionText, setPromotionText, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={promotionText}
          onChange={(e) => {
            setPromotionText(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const DiscountCodeInput = ({ discountCode, setDiscountCode, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={discountCode}
          onChange={(e) => {
            setDiscountCode(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const OccasionInput = ({ occasion, setOccasion, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={occasion}
          onChange={(e) => {
            setOccasion(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const InstaHandleInput = ({ instaHandle, setInstaHandle, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={instaHandle}
          onChange={(e) => {
            setInstaHandle(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const SupportEmailInput = ({ supportEmail, setSupportEmail, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={supportEmail}
          onChange={(e) => {
            setSupportEmail(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const DurationInput = ({ duration, setDuration, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={duration}
          onChange={(e) => {
            setDuration(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

export const DiscountTextInput = ({ discountText, setDiscountText, heading, placeholder }) => {
  return (
    <>
      <InputGroup className="mb-2">
        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {heading}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8 }}
          value={discountText}
          onChange={(e) => {
            setDiscountText(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};
