import React from 'react';
import colors from '../../../Utils/colors';
import { AnnTemplateItem, SmsTemplateItem } from './NewComponents';
import { useSelector } from 'react-redux';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';

const AnnCompletion = ({
  shareTo,
  selectedTagsList,
  labelName,
  balance,
  item,
  variableList,
  templateV2Type,
  openCreateSmsModal,
  customSMSText
}) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);

  const mobileWidth = window.innerWidth < 900 ? true : false;
  console.log('bal', localdata?.balance);

  console.log("openCreateSmsModal", openCreateSmsModal)
  return (
    <>
      <div
        className="d-flex flex-column justify-content-start align-items-start w-100 verythinscrollnew"
        style={{
          flex: 1,
          overflowY: 'scroll',
          paddingBlock: 20,
          paddingInline: mobileWidth ? 0 : 30,
        }}
      >
        <h4
          style={{
            padding: 0,
            margin: 0,
            fontSize: 16,
            fontWeight: 700,
            color: colors.black,
            marginBlock: 8,
          }}
        >
          {'Sharing to:'}
        </h4>
        {shareTo === 'Tags' ? (
          <>
            <div
              className="d-flex flex-row justify-content-start align-items-center"
              style={{ flexWrap: 'wrap', marginBlock: 16 }}
            >
              {selectedTagsList &&
                selectedTagsList.map((item) => {
                  return (
                    <>
                      <div
                        className="d-flex flex-row justify-content-center align-items-center"
                        key={`key${item?._id}`}
                        style={{
                          paddingBlock: 4,
                          paddingInline: 20,
                          border: `1px solid #FEAF52`,
                          borderRadius: 8,
                          marginInlineEnd: 16,
                          marginBlock: 6,
                        }}
                      >
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            fontSize: 14,
                            fontWeight: 400,
                            color: '#FEAF52',
                          }}
                        >
                          {item?.tag_name}
                        </p>
                      </div>
                    </>
                  );
                })}
            </div>
          </>
        ) : (
          <>
            <div
              className="d-flex flex-row justify-content-center align-items-center"
              style={{
                paddingBlock: 4,
                paddingInline: 20,
                border: `1px solid #FEAF52`,
                borderRadius: 8,
                marginBlock: 16,
              }}
            >
              <p style={{ padding: 0, margin: 0, fontSize: 14, fontWeight: 400, color: '#FEAF52' }}>
                {labelName}
              </p>
            </div>
          </>
        )}

        <h4
          style={{
            padding: 0,
            margin: 0,
            fontSize: 16,
            fontWeight: 700,
            color: colors.black,
            marginBlock: 16,
          }}
        >
          {'Net required credits:'}
        </h4>
        <div
          className="d-flex flex-row justify-content-start align-items-center"
          style={{ width: '90%', marginBlockEnd: 16 }}
        >
          <div
            className="d-flex flex-row justify-content-between align-items-center"
            style={{ width: '30%', marginInlineEnd: 50 }}
          >
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                color: colors.greys04,
                padding: 0,
                margin: 0,
              }}
            >
              {'Required Conversations'}
            </p>
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                color: colors.primary03,
                padding: 0,
                margin: 0,
              }}
            >
              {balance?.mobileArr?.length
                ? balance?.mobileArr?.length
                : balance?.mobile_arr?.length}
            </p>
          </div>
          <div
            className="d-flex flex-row justify-content-between align-items-center"
            style={{ width: '30%' }}
          >
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                color: colors.greys04,
                padding: 0,
                margin: 0,
              }}
            >
              {'Available Conversations'}
            </p>
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                color: colors.primary03,
                padding: 0,
                margin: 0,
              }}
            >
              {templateV2Type === 'SMS Templates'
                ? localdata?.balance?.sms
                : localdata?.balance?.whatsapp}
            </p>
          </div>
        </div>

        <h4
          style={{
            padding: 0,
            margin: 0,
            fontSize: 16,
            fontWeight: 700,
            color: colors.black,
            marginBlock: 16,
          }}
        >
          {'Template used:'}
        </h4>
        {templateV2Type === 'SMS Templates' ? (
          <>
            {!openCreateSmsModal ?
              <SmsTemplateItem item={item?.templateData} onclickAction={() => { }} final={true} />
              : (
                <>
                  <div className="ann_template_v2_item_container" >
                    <div className="ann_template_v2_item">
                      <div className="ann_template_v2_item__row1">
                        <img src={images.CATemplate}></img>
                        <div className="text_container">
                          <p className="header_text">{"Custom SMS Template"}</p>
                          <p style={{
                            flexWrap: 'wrap',
                            whiteSpace: 'pre-wrap',
                          }}>
                            {`${customSMSText}`}
                          </p>
                        </div>
                      </div>

                      <div className="ann_template_v2_item__row2">
                        <CheerioButton
                          borderStyle={{ borderColor: colors.primary03, width: mobileWidth ? '100%' : '48%' }}
                          textStyle={{ fontSize: 12, fontWeight: 400, color: colors.white }}
                          btnText={'Use Template'}
                          backColor={colors.primary03}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
          </>
        ) : (
          <AnnTemplateItem item={item} onclickAction={() => { }} final={true} />
        )}
        <h4
          style={{
            padding: 0,
            margin: 0,
            fontSize: 16,
            fontWeight: 700,
            color: colors.black,
            marginBlockStart: 30,
            marginBlockEnd: 8,
          }}
        >
          {'Offer details:'}
        </h4>
        {variableList &&
          variableList.map((item, index) => {
            return (
              <>
                <p
                  key={index}
                  style={{
                    padding: 0,
                    margin: 0,
                    marginBlock: 2,
                    fontSize: 16,
                    fontWeight: 600,
                    color: colors.black,
                  }}
                >
                  {`Variable ${index + 1}: `}
                  <span style={{ fontWeight: 400 }}>{item}</span>
                </p>
              </>
            );
          })}
      </div>
    </>
  );
};

export default AnnCompletion;
