import React, { useEffect, useState } from 'react';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import './Modal.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setupWASMSApi, WAtestMessageApi } from '../../Services';
import ReactGA from 'react-ga4';
import { eventsend } from '../../Config/analyticsFunctions';
import CheerioButton from '../CheerioButton';

const SetupWA = ({ setIsOpen, gotowebhooksetup, proceedtoTemplate }) => {
  const [WASBNumber, setWASBNumber] = useState('');
  const [WASBName, setWASBName] = useState('');
  const [WASNumber, setWASNumber] = useState('');
  const [APIkey, setAPIkey] = useState('');
  const [status, setStatus] = useState('');
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [failureMessage, setFailureMessage] = useState('');
  const [successFinal, setSuccessFinal] = useState(false);
  const [applicationID, setApplicationID] = useState('');
  const [phoneID, setPhoneID] = useState('');

  const [STPhoneNo, setSTPhoneNo] = useState('');

  const [nextClicked, setNextClicked] = useState(false);

  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const WAdata = useSelector((state) => state.main.WAdata);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.event('setup_whatsapp_channel_pageview');
    eventsend('setup_whatsapp_channel_pageview');
    eventsend("Setup_WhatsApp_home_page_clicked");
    if (WAdata) {
      setWASBNumber(WAdata?.whatsapp_business_account_id);
      setWASBName(WAdata.business_name);
      setWASNumber(WAdata?.phone_number);
      setAPIkey(WAdata.auth_token);
      setApplicationID(WAdata.application_id);
      setPhoneID(WAdata?.phone_number_id);
    }
  }, []);

  const onclicksubmit = () => {
    dispatch({
      type: 'addWAdata',
      payload: {
        phone_number: WASNumber,
        phone_number_id: phoneID,
        whatsapp_business_account_id: WASBNumber,
        business_name: WASBName,
        auth_token: APIkey,
        application_id: applicationID,
      },
    });

    let data = {
      user_id: localdata?._id ? localdata?._id : _id,
      type: 'whatsapp',
      phone_number: WASNumber,
      phone_number_id: phoneID,
      whatsapp_business_account_id: WASBNumber,
      business_name: WASBName,
      auth_token: APIkey,
      application_id: applicationID,
    };
    let token = authtoken;
    ReactGA.event('setup_whatsapp_channel_submit_click', { data: data });
    eventsend('setup_whatsapp_channel_submit_click', { data: data });
    setupWASMSApi(data, token).then((res) => {
      if (res.flag) {
        const resData = res?.data?.saveConfig;
        setStatus(resData?.status);
      } else {
        setStatus('Error');
      }
    });
  };

  useEffect(() => {
    if (status == 'InProgress') {
      // setSuccess(true);
      setNextClicked(true);
    }
  }, [status]);

  // const onclicknext = () => {
  //     setFailure(true);
  //     setNextClicked(true);
  // }

  const onclicksendtest = () => {
    let data = {
      user_id: localdata?._id ? localdata?._id : _id,
      phone_number: STPhoneNo,
    };
    let token = authtoken;
    WAtestMessageApi(data, token).then((res) => {
      if (res.flag) {
        console.log('res ===> ', res);
        setSuccess(true);
      } else {
        console.log('error sent test message api ===> ', res);
        setFailureMessage(res?.message);
        setFailure(true);
      }
    });

    // setSuccess(true);
  };

  return (
    <>
      <div style={Styles.DarkBg}>
        <div className="" style={{ ...Styles.Center }}>
          <div
            className="d-flex flex-column justify-content-between py-3 align-items-center"
            style={{
              backgroundColor: colors.white,
              borderRadius: 16,
              width: 500,
              height: '90vh',
              zIndex: 0,
              paddingInline: 38,
            }}
          >
            <img
              src={images.CreatorClose}
              style={{
                height: 34,
                width: 34,
                objectFit: 'contain',
                position: 'absolute',
                top: 15,
                right: 15,
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsOpen(false);
              }}
            ></img>
            {success ? (
              <>
                <div
                  className="d-flex flex-column justify-content-between align-items-center w-100"
                  style={{ flex: 1 }}
                >
                  <div></div>
                  <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <img
                      src={images.CATSuccess}
                      style={{ height: 150, width: 150, objectFit: 'contain', paddingBlock: 16 }}
                    />
                    <text
                      style={{
                        fontSize: 20,
                        fontWeight: 700,
                        color: colors.success03,
                        paddingBlock: 16,
                      }}
                    >
                      {successFinal ? 'WhatsApp setup successful' : 'Message sent'}
                    </text>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <text
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: colors.black,
                        paddingBlock: 16,
                      }}
                    >
                      {successFinal ? '' : `Message sent to “${STPhoneNo}”`}
                    </text>
                    {successFinal ? (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            fontSize: 14,
                            fontWeight: 700,
                            color: colors.black,
                            width: '100%',
                          }}
                        >
                          {'NOTE'}
                        </p>
                        <div
                          className="d-flex flex-column justify-content-center align-items-center"
                          style={{
                            width: '100%',
                            borderRadius: 8,
                            backgroundColor: '#ECE1FF',
                            padding: 14,
                            marginBlockEnd: 16,
                          }}
                        >
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              fontSize: 14,
                              fontWeight: 400,
                              color: colors.black,
                            }}
                          >
                            {'Cheerio auto submits few essential templates to WhatsApp such as '}
                            <span style={{ fontWeight: 700 }}>
                              {'Sell a Course, Sell a Product and Share an Offer.'}
                            </span>
                            {' These templates are currently under review for approval'}
                          </p>
                        </div>
                        <div className="d-flex flex-row justify-content-center align-items-center w-100">
                          {/* <Button variant="primary btncustom" style={{width:'60%',marginBlock:24,backgroundColor:colors.primary03,borderWidth:0}}
                                    onClick={()=>{navigate('/creator');setIsOpen(false)}}>
                                        {'Back to home'}
                                    </Button> */}
                          <CheerioButton
                            borderStyle={{
                              marginBlock: 8,
                              borderColor: colors.primary03,
                              width: '40%',
                              paddingBlock: 10,
                              marginInlineEnd: 16,
                            }}
                            textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                            btnText={'Next'}
                            backColor={colors.primary03}
                            onclick={() => {
                              console.log('next clicked . . .');
                              gotowebhooksetup();
                            }}
                          />
                          <CheerioButton
                            borderStyle={{
                              marginBlock: 8,
                              width: '40%',
                              paddingBlock: 10,
                              marginInlineStart: 16,
                              border: `1px solid ${colors.primary03}`,
                            }}
                            textStyle={{ fontSize: 14, fontWeight: 600, color: colors.primary03 }}
                            btnText={'Back to home'}
                            backColor={colors.white}
                            onclick={() => {
                              console.log('back to home clicked . . .');
                              navigate('/creator');
                              setIsOpen(false);
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="d-flex flex-row justify-content-around align-items-center w-100">
                          <Button
                            variant="outline-primary btncustom2"
                            style={{
                              width: '40%',
                              marginBlock: 24,
                              borderColor: colors.primary03,
                              borderWidth: 1,
                              color: colors.primary03,
                            }}
                            onClick={() => {
                              // navigate('/creator');setIsOpen(false);
                              setSuccess(false);
                            }}
                          >
                            {'Didn’t Receive'}
                          </Button>
                          <Button
                            variant="primary btncustom"
                            style={{
                              width: '40%',
                              marginBlock: 24,
                              backgroundColor: colors.primary03,
                              borderWidth: 0,
                            }}
                            onClick={() => {
                              // setSuccessFinal(true)
                              proceedtoTemplate();
                            }}
                          >
                            {'Message Received'}
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : failure ? (
              <>
                <div
                  className="d-flex flex-column justify-content-between align-items-center w-100"
                  style={{ flex: 1 }}
                >
                  <div></div>
                  <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <img
                      src={images.CATFail}
                      style={{ height: 200, width: 200, objectFit: 'contain', paddingBlock: 16 }}
                    />
                    <text
                      style={{
                        fontSize: 20,
                        fontWeight: 700,
                        color: colors.error03,
                        paddingBlock: 16,
                      }}
                    >
                      {'Request Failed'}
                    </text>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <text
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: colors.error03,
                        paddingBlock: 16,
                        width: '80%',
                        flexWrap: 'wrap',
                        textAlign: 'center',
                        wordBreak: 'break-word',
                      }}
                    >
                      {'Failed to send message at this point of time, please try again.'}
                    </text>
                    <div className="d-flex flex-row justify-content-center align-items-center w-100">
                      <Button
                        variant="primary btncustom"
                        style={{
                          width: '60%',
                          marginBlock: 24,
                          backgroundColor: colors.primary03,
                          borderWidth: 0,
                        }}
                        onClick={() => {
                          navigate('/creator');
                          setIsOpen(false);
                        }}
                      >
                        {'Re-enter details'}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {nextClicked ? (
                  <>
                    <div className="d-flex flex-column justify-content-between align-items-center w-100">
                      <img
                        src={images.CreatorWA}
                        style={{ height: 64, width: 64, objectFit: 'contain', marginBlock: 8 }}
                      ></img>
                      <text style={{ ...Styles.TxtHeading, marginBlock: 4 }}>
                        {'Activate my channel'}
                      </text>
                      {/* <text style={{...Styles.TxtBody2,marginBlock:2,flexWrap:'wrap',textAlign:'center'}}>
                                {'Setup your WhatsApp channel with Cheerio in 2 easy steps and start sharing messages on WhatsApp.'}
                            </text> */}
                    </div>
                    <div
                      className="d-flex flex-column justify-content-center align-items-center w-100 px-1 verythinscroll"
                      style={{ overflowY: 'scroll' }}
                    >
                      <InputGroup className="my-1">
                        <Form.Label style={{ ...Styles.TxtBody }}>{'Phone Number'}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Add Phone no."
                          className="w-100 btncustom"
                          style={{ borderRadius: 8 }}
                          value={STPhoneNo}
                          onChange={(e) => {
                            setSTPhoneNo(e.target.value);
                          }}
                        />
                        <text
                          style={{
                            ...Styles.TxtBody2,
                            marginBlock: 4,
                            flexWrap: 'wrap',
                            fontWeight: 400,
                          }}
                        >
                          {'Add the phone number where you wish to send test WhatsApp message.'}
                        </text>
                      </InputGroup>
                      <text
                        className="w-100"
                        style={{
                          ...Styles.TxtBody2,
                          flexWrap: 'wrap',
                          fontWeight: 400,
                          marginBlock: 16,
                        }}
                      >
                        {
                          'Once you check and confirm you have received the message, your Whatsapp business account number will be activated on Cheerio'
                        }
                      </text>
                      <text
                        className="w-100"
                        style={{
                          ...Styles.TxtBody2,
                          flexWrap: 'wrap',
                          fontWeight: 400,
                          marginBlockStart: 16,
                          marginBlockEnd: 4,
                        }}
                      >
                        {'Message you will receive:'}
                      </text>
                      <text
                        className="w-100"
                        style={{ ...Styles.TxtBody, flexWrap: 'wrap', marginBlock: 4 }}
                      >
                        {'“Hello world”'}
                      </text>
                      <text
                        className="w-100"
                        style={{
                          ...Styles.TxtBody2,
                          flexWrap: 'wrap',
                          fontWeight: 400,
                          marginBlockStart: 16,
                          marginBlockEnd: 4,
                        }}
                      >
                        {'Number you will receive message from:'}
                      </text>
                      <text
                        className="w-100"
                        style={{ ...Styles.TxtBody, flexWrap: 'wrap', marginBlock: 4 }}
                      >
                        {`“${WASNumber}”`}
                      </text>
                      {/* <InputGroup className="mb-1">
                                <Form.Label style={{...Styles.TxtBody}}>{'Phone No.'}</Form.Label>
                                    <Form.Control type='text' placeholder="Add Phone no." className='w-100 btncustom'
                                        style={{borderRadius:8}}
                                        value={WASNumber}
                                        onChange={(e)=>{setWASNumber(e.target.value)}}
                                    />
                                    <text style={{...Styles.TxtBody2,marginBlock:4,flexWrap:'wrap',fontWeight:400}}>
                                        {'We will use this number to send messages on WhatsApp'}
                                    </text>
                            </InputGroup>
                            <InputGroup className="mb-1">
                                <Form.Label style={{...Styles.TxtBody}}>{'Business Name'}</Form.Label>
                                    <Form.Control type='text' placeholder="Enter name" className='w-100 btncustom'
                                        style={{borderRadius:8}}
                                        value={WASBName}
                                        onChange={(e)=>{setWASBName(e.target.value)}}
                                    />
                                    <text style={{...Styles.TxtBody2,marginBlock:4,flexWrap:'wrap',fontWeight:400}}>
                                        {'This name will be visible to customer on WhatsApp'}
                                    </text>
                            </InputGroup>
                            <InputGroup className="mb-1">
                                <Form.Label style={{...Styles.TxtBody}}>{'API key'}</Form.Label>
                                    <Form.Control type='text' placeholder="Enter key here" className='w-100 btncustom'
                                        style={{borderRadius:8}}
                                        value={APIkey}
                                        onChange={(e)=>{setAPIkey(e.target.value)}}
                                    />
                            </InputGroup> */}
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center w-100">
                      <Button
                        variant="primary btncustom"
                        style={{
                          width: '60%',
                          marginBlock: 8,
                          backgroundColor: colors.primary03,
                          borderWidth: 0,
                        }}
                        // onClick={()=>{onclicksubmit()}} disabled={WASBName&&WASNumber&&WASBNumber&&APIkey ? false : true}>
                        disabled={STPhoneNo ? false : true}
                        onClick={() => {
                          onclicksendtest();
                        }}
                      >
                        {'Send test message'}
                      </Button>
                      {/* <text style={{...Styles.TxtBody,fontWeight:600,marginBlockEnd:8}}>{'Don’t have these? '}
                                <text style={{color:colors.linkblue,cursor:'pointer'}} 
                                    onClick={()=>{
                                        setIsOpen(false);
                                        navigate('/documentation/whatsapp');
                                    }}
                                >
                                    {'Read documentation'} 
                                </text>
                            </text> */}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex flex-column justify-content-between align-items-center w-100">
                      <img
                        src={images.CreatorWA}
                        style={{ height: 64, width: 64, objectFit: 'contain', marginBlock: 8 }}
                      ></img>
                      <text style={{ ...Styles.TxtHeading, marginBlock: 4 }}>
                        {'Setup WhatsApp channel'}
                      </text>
                      <text
                        style={{
                          ...Styles.TxtBody2,
                          marginBlock: 2,
                          flexWrap: 'wrap',
                          textAlign: 'center',
                        }}
                      >
                        {
                          'Setup your WhatsApp channel with Cheerio in 2 easy steps and start sharing messages on WhatsApp.'
                        }
                      </text>
                    </div>
                    <div
                      className="d-flex flex-column justify-content-between align-items-center w-100 px-1 py-2 verythinscroll"
                      style={{ overflowY: 'scroll' }}
                    >
                      <InputGroup className="mb-2">
                        <Form.Label style={{ ...Styles.TxtBody }}>{'Phone Number'}</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Phone no."
                          className="w-100 btncustom"
                          style={{ borderRadius: 8 }}
                          value={WASNumber}
                          onChange={(e) => {
                            setWASNumber(e.target.value);
                          }}
                        />
                        <text
                          style={{
                            ...Styles.TxtBody2,
                            marginBlock: 4,
                            flexWrap: 'wrap',
                            fontWeight: 400,
                          }}
                        >
                          {'We will use this number to send messages on WhatsApp'}
                        </text>
                      </InputGroup>

                      <InputGroup className="mb-2">
                        <Form.Label style={{ ...Styles.TxtBody }}>{'Business Name'}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter name"
                          className="w-100 btncustom"
                          style={{ borderRadius: 8 }}
                          value={WASBName}
                          onChange={(e) => {
                            setWASBName(e.target.value);
                          }}
                        />
                        <text
                          style={{
                            ...Styles.TxtBody2,
                            marginBlock: 4,
                            flexWrap: 'wrap',
                            fontWeight: 400,
                          }}
                        >
                          {'This name will be visible to customer on WhatsApp'}
                        </text>
                      </InputGroup>

                      <InputGroup className="mb-2">
                        <Form.Label style={{ ...Styles.TxtBody }}>{'Application ID'}</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter ID here"
                          className="w-100 btncustom"
                          style={{ borderRadius: 8 }}
                          value={applicationID}
                          onChange={(e) => {
                            setApplicationID(e.target.value);
                          }}
                        />
                      </InputGroup>

                      <InputGroup className="mb-2">
                        <Form.Label style={{ ...Styles.TxtBody }}>{'Phone No. ID'}</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Phone no. ID"
                          className="w-100 btncustom"
                          style={{ borderRadius: 8 }}
                          value={phoneID}
                          onChange={(e) => {
                            setPhoneID(e.target.value);
                          }}
                        />
                      </InputGroup>

                      <InputGroup className="my-2">
                        <Form.Label style={{ ...Styles.TxtBody }}>
                          {'WhatsApp Business Account Number'}
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter business number "
                          className="w-100 btncustom"
                          style={{ borderRadius: 8 }}
                          value={WASBNumber}
                          onChange={(e) => {
                            setWASBNumber(e.target.value);
                          }}
                        />
                        <text
                          style={{
                            ...Styles.TxtBody2,
                            marginBlock: 4,
                            flexWrap: 'wrap',
                            fontWeight: 400,
                          }}
                        >
                          {'Your FB business acc. no.'}
                        </text>
                      </InputGroup>

                      <InputGroup className="mb-2">
                        <Form.Label style={{ ...Styles.TxtBody }}>{'API key'}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter key here"
                          className="w-100 btncustom"
                          style={{ borderRadius: 8 }}
                          value={APIkey}
                          onChange={(e) => {
                            setAPIkey(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </div>

                    <div className="d-flex flex-column justify-content-center align-items-center w-100">
                      <Button
                        variant="primary"
                        style={{
                          width: '60%',
                          marginBlock: 8,
                          backgroundColor: colors.primary03,
                          borderWidth: 0,
                        }}
                        onClick={() => {
                          onclicksubmit();
                        }}
                        disabled={
                          WASBName && WASNumber && WASBNumber && APIkey && applicationID && phoneID
                            ? false
                            : true
                        }
                      >
                        {/* onClick={()=>{onclicknext()}}> */}
                        {'Next'}
                      </Button>
                      <text style={{ ...Styles.TxtBody, fontWeight: 600, marginBlockEnd: 8 }}>
                        {'Don’t have these? '}
                        <text
                          style={{ color: colors.linkblue, cursor: 'pointer' }}
                          onClick={() => {
                            setIsOpen(false);
                            navigate('/documentation/whatsapp');
                          }}
                        >
                          {'Read documentation'}
                        </text>
                      </text>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupWA;

const Styles = {
  DarkBg: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    width: '100vw',
    height: '100vh',
    // flex:1,
    zIndex: 1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'fixed',
  },
  Center: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  TxtHeading: {
    fontSize: 24,
    fontWeight: 700,
    color: colors.black,
  },
  TxtBody: {
    fontSize: 14,
    fontWeight: 700,
    color: colors.black,
  },
  TxtBody2: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.greys04,
  },
  itemborder: {
    backgroundColor: colors.white,
    borderRadius: 16,
    boxShadow: '0px 0px 10px 0 rgba(0, 0, 0, 0.15)',
  },
};
