import React from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';

const PaymentSuccessModal = ({ setPSModalOpen, downloadInvoiceAction }) => {
  return (
    <>
    <div className='DarkBg'>
        <div className='Center'>
            <div className='d-flex flex-column justify-content-start align-items-center' style={{
                maxHeight:'80vh',
                width:540,
                backgroundColor:colors.white01,
                border:`1px solid ${colors.borderwhite}`,
                borderRadius:16,
                paddingBlock:20,
                paddingInline:30,
            }} onClick={()=>{
                // setPSModalOpen(false);
            }}>
                <img 
                    src={images.Success}
                    style={{
                        height:200,
                        width:200,
                        objectFit:'contain',
                        marginBlock:32,
                    }}
                />
                <h3 className='my-2' style={{fontSize:20,fontWeight:700,color:colors.black,textAlign:'center'}}>
                    {'Congratulations'}<br/>
                    {'Thanks for Subscribing to Cheerio AI'}
                </h3>
                <p className='my-3' style={{fontSize:16,fontWeight:400,color:colors.black,textAlign:'center'}}>
                    {'We have shared an email and WhatsApp message with you. Please use the curated link sent via email or WhatsApp to sign up for Cheerio with an active premium subscription.'}
                </p>
                <CheerioButton 
                    borderStyle={{ width: '96%', marginBlockStart: 24, borderColor: colors.primary03 }}
                    textStyle={{ color: colors.white }}
                    backColor={colors.primary03}
                    icon={images.downloadWhite}
                    btnText={'Download Invoice'}
                    // disabled={}
                    onclick={() => {
                      console.log("btn clicked ...")
                      downloadInvoiceAction();
                    }}
                />
            </div>
        </div>
    </div>
    </>
  )
}

export default PaymentSuccessModal