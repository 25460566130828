import './Modal.css';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

import colors from '../../Utils/colors';
import images from '../../Utils/images';
import { eventsend } from '../../Config/analyticsFunctions';

const GPTContactSalesModal = ({ centerstyle }) => {
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.event('contact_sales_modal_pageview');
    eventsend('contact_sales_modal_pageview');
    eventsend("ChatGPT_card_Discover_page_clicked");
  }, []);

  return (
    <>
      <div style={Styles.DarkBg}>
        <div style={{ ...Styles.Center, ...centerstyle }}>
          <div
            className="d-flex flex-column justify-content-evenly py-4 px-2"
            style={Styles.ModalContainer}
          >
            <img
              src={images.CreatorClose}
              style={Styles.CloseIcon}
              onClick={() => {
                navigate('/creator/discoverPlatforms');
              }}
            />

            <h1 style={Styles.TxtHeading}>{'Integrate Chat GPT'}</h1>

            <div style={Styles.IconContainer}>
              <img src={images.OpenAiLogo} style={Styles.ChatGPTIcon} alt="Chat GPT" />
              <p style={Styles.HyperTxtBody}>{'ChatGPT'}</p>
            </div>

            <div className="d-flex flex-column" style={Styles.OfferingsContainer}>
              <div className="d-flex" style={Styles.OfferingsItem}>
                <img src={images.AssuredBlack} style={Styles.Assured} alt="Assured" />
                <p style={Styles.TxtBody}>{'Create & Manage Chat GPT Assistants'}</p>
              </div>
              <div className="d-flex" style={Styles.OfferingsItem}>
                <img src={images.AssuredBlack} style={Styles.Assured} alt="Assured" />
                <p style={Styles.TxtBody}>{'Deploy Assistants to your WhatsApp'}</p>
              </div>
              <div className="d-flex" style={Styles.OfferingsItem}>
                <img src={images.AssuredBlack} style={Styles.Assured} alt="Assured" />
                <p style={Styles.TxtBody}>{'Reimagine Client Interaction with AI'}</p>
              </div>
            </div>

            <div className="d-flex" style={Styles.LimitedUseContainer}>
              <img src={images.Exclusive} style={Styles.Exclusive} alt="Exclusive" />
              <p style={Styles.TxtLimitedUse}>{'Limited Use Feature'}</p>
            </div>

            <Button
              variant="primary"
              style={Styles.ContactButton}
              onClick={() => {
                window.location.href = 'mailto:nishant@cheerio.in';
              }}
            >
              {'Get feature access'}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GPTContactSalesModal;

const Styles = {
  DarkBg: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    width: '100vw',
    height: '100vh',
    zIndex: 1,
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  Center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  ModalContainer: {
    backgroundColor: colors.white,
    borderRadius: 16,
    width: 400,
    padding: '20px 30px',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    alignItems: 'center',
    gap: 12,
  },
  CloseIcon: {
    height: 22,
    width: 22,
    objectFit: 'contain',
    position: 'absolute',
    top: 15,
    right: 15,
    cursor: 'pointer',
  },
  IconContainer: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 32,
    paddingTop: 16,
    gap: '12px',
    borderBottom: `1px solid ${colors.borderwhite}`,
  },
  ChatGPTIcon: {
    height: 72,
    width: 72,
    objectFit: 'contain',
  },
  Assured: {
    height: 20,
    width: 20,
    objectFit: 'contain',
  },
  Exclusive: {
    height: 20,
    width: 20,
    objectFit: 'contain',
  },
  TxtHeading: {
    fontSize: 24,
    fontWeight: 700,
    color: colors.black,
    textAlign: 'center',
    marginBottom: 20,
  },
  OfferingsContainer: {
    width: '100%',
    marginBottom: 20,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  OfferingsItem: {
    width: '100%',
    gap: '4px',
    padding: '10px 0',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  TxtBody: {
    fontSize: 16,
    fontWeight: 500,
    color: colors.black,
  },
  HyperTxtBody: {
    fontSize: 28,
    fontWeight: 600,
    color: colors.black,
  },
  LimitedUseContainer: {
    gap: '4px',
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  TxtLimitedUse: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.gray,
    textAlign: 'center',
  },
  ContactButton: {
    width: '95%',
    backgroundColor: colors.black02,
    padding: '10px 0',
    fontSize: 16,
    fontWeight: 600,
    borderRadius: 14,
  },
};
