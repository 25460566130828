import React from 'react'
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import images from '../../../Utils/images';
import CustomHandle from './CustomHandle';

const handleStyle = { backgroundColor: "#530FE3", height: 10, width: 10, borderRadius: 5 };

const NewContactNode = ({ data, isConnectable }) => {

    return (
        <>
            <div className='d-flex flex-column justify-content-between align-items-center' style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 16, backgroundColor: colors.white01, width: 250 }}>
                {/* <Handle type='target' position={Position.Left} isConnectable={isConnectable} /> */}
                <div className='w-100 d-flex flex-row justify-content-between align-items-center' style={{ paddingInline: 16, paddingBlock: 8, background: "#530FE3", borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
                    <p style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.white }}>
                        {'New contact create'}
                    </p>
                    <img src={images.Edit} style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}></img>
                </div>
                <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{ paddingInline: 16, paddingBlockStart: 8, paddingBlockEnd: 16 }}>
                    <p style={{ padding: 0, margin: 0, fontSize: 12, fontWeight: 400, color: colors.black }}>
                        {'On creating new contact'}
                    </p>
                </div>
                {!data?.options &&
                    <CustomHandle type='source' position={Position.Right} id="Event_UserInit" style={handleStyle} isConnectable={1} />
                }
            </div>
        </>
    )
}

export default NewContactNode