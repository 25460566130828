import { createSlice } from "@reduxjs/toolkit";

export const contactsRedux = createSlice({
  name: "contactsRedux",
  initialState: {
    editState: {
      name: null,
      email: null,
      mobile: null,
      labels: [],
      tags: []
    }
  },
  reducers: {
    updateEditContact: (state, action) => {
      state.editState = {...action.payload};
    },
  },
});

export const { updateEditContact } = contactsRedux.actions;
export default contactsRedux.reducer;
