import { trackPage, trackEvent, trackLink, saveContact } from "../Services"

const isCheerioApp = () => window.location.href.includes('https://app.cheerio.in/');

export const trackPages = async (pageName, localdata) => {
    if (isCheerioApp() && localdata?.email !== "priam@cheerio.in") {
        try {
            const data = {
                email: localdata?.email,
                page: window.location.href,
                properties: {
                    sib_name: pageName,
                    userId: localdata?._id,
                    userName: localdata?.name,
                    userPhone: localdata?.mobile,
                    companyName: localdata?.company_name,
                }
            }
            const res = await trackPage(data)
        } catch (e) {
            console.log('track page error', e)
        }
    }
}

export const handleTrackEvent = async (event, localdata) => {
    if (isCheerioApp() && localdata?.email !== "priam@cheerio.in") {
        try {
            const data = {
                email: localdata?.email,
                event: event.toString().toLowerCase(),
                properties: {
                    userId: localdata?._id,
                    userName: localdata?.name,
                    userPhone: localdata?.mobile,
                    companyName: localdata?.company_name,
                    isAppsumoUser: localdata?.isAppsumoUser,
                }
            }
            const response = await trackEvent(data)
            if (response?.flag) {
                console.log('event tracked successfully')
            }
            else {
                console.log('error in track event')
            }
        } catch (error) {
            console.log('error in track event', error);
        }
    }
}

export const handleTrackLinks = (link, linkName, localdata) => {
    if (isCheerioApp() && localdata?.email !== "priam@cheerio.in") {
        try {
            const data = {
                email: localdata?.email,
                link: link,
                properties: {
                    userId: localdata?._id,
                    userName: localdata?.name,
                    userPhone: localdata?.mobile,
                    companyName: localdata?.company_name,
                    name: linkName
                }
            }
            const response = trackLink(data)
            if (response?.flag) {
                console.log('track link success')
            } else {
                console.log('track link failed')
            }
        } catch (error) {
            console.log('track link error', error)
        }
    }
}
export const handleSaveContacts = async (user_name, user_email,mobile, user_country_code) => {
    try {
      const data = {
        email: user_email,
        attributes: {
          FIRSTNAME: user_name,
          SMS:`${user_country_code}${mobile}`
        },
        updateEnabled: true,
      };
  
      const response = await saveContact(data);
      console.log('API Response:', response);
      if (response?.flag) {
        console.log('Contact save success');
      } else {
        console.log('Contact save failed');
      }
    } catch (error) {
      console.log('Error in save contact:', error.response?.data || error.message);
    }
  };
  