import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import colors from '../../../Utils/colors';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { useSelector } from 'react-redux';
import { getSmsTemplates } from '../../../Services';
import '../styles.css';
import TemplatePreviewModal from '../../../Components/Modal/TemplatePreviewModal';
import { toast } from 'react-toastify';
const SmsTemplate = () => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const [isLoading, setIsLoading] = useState(false);
    const [smsTemplates, setSmsTemplates] = useState([]);
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [prevItem, setPrevItem] = useState(null);

    useEffect(() => {
        getSmsTemplatesAPIcall();
    }, []);

    const getSmsTemplatesAPIcall = () => {
        setIsLoading(true);
        let token = authtoken;

        getSmsTemplates(token).then((res) => {
            if (res?.flag) {
                setSmsTemplates(res?.data);
            } else {
                toast.error('Unexpected error occurred, please try again later', {  position: 'top-right' });
            }
            setIsLoading(false);
        });
    };

    return (
        <>
            <div className="d-flex flex-column justify-content-start align-items-center w-100">
                {previewModalOpen && (
                    <TemplatePreviewModal
                        setPreviewModalOpen={setPreviewModalOpen}
                        item={prevItem}
                        channel='sms'
                    />

                )}
                <div
                    className="d-flex flex-column justify-content-start align-items-center w-100 verythinscrollnew"
                    style={{ flex: 1, marginBlock: 16, overflowY: 'scroll' }}
                >
                    {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center w-100 h-100">
                            <SpinnerLoader />
                        </div>
                    ) : smsTemplates.length ? (
                        <div
                            style={{
                                width: '100%',
                                paddingInline: 24,
                                flex: 1
                            }}>
                            <table style={{ width: '100%' }}>
                                <thead>
                                    <tr style={{ backgroundColor: colors.white02 }}>
                                        <th
                                            style={{
                                                paddingBlock: 10,
                                                paddingInline: 16,
                                                border: `1px solid ${colors.greys03}`,
                                            }}
                                        >
                                            {'Template Name'}
                                        </th>
                                        <th
                                            style={{
                                                paddingBlock: 10,
                                                paddingInline: 16,
                                                border: `1px solid ${colors.greys03}`,
                                            }}
                                        >
                                            {'Created at'}
                                        </th>
                                        <th
                                            style={{
                                                paddingBlock: 10,
                                                paddingInline: 16,
                                                border: `1px solid ${colors.greys03}`,
                                            }}
                                        >
                                            {'Status'}
                                        </th>
                                        <th
                                            style={{
                                                paddingBlock: 10,
                                                paddingInline: 16,
                                                border: `1px solid ${colors.greys03}`,
                                            }}
                                        >
                                            {'Preview'}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {smsTemplates.map((item) => (
                                        <tr key={item.TemplateId} style={{ backgroundColor: colors.white01 }}>
                                            <td
                                                style={{
                                                    paddingBlock: 10,
                                                    paddingInline: 16,
                                                    border: `1px solid ${colors.greys03}`,
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    wordBreak: 'break-all',
                                                    fontSize: 16,
                                                    fontWeight: 600,
                                                    color: colors.black04,
                                                }}
                                            >
                                                {item.TemplateName}
                                            </td>
                                            <td
                                                style={{
                                                    paddingBlock: 10,
                                                    paddingInline: 16,
                                                    border: `1px solid ${colors.greys03}`,
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    wordBreak: 'break-all',
                                                    fontSize: 16,
                                                    fontWeight: 600,
                                                    color: colors.black04,
                                                }}
                                            >
                                                {item.CreatededDate}
                                            </td>
                                            <td
                                                style={{
                                                    paddingBlock: 10,
                                                    paddingInline: 16,
                                                    border: `1px solid ${colors.greys03}`,
                                                }}
                                            >
                                                <div
                                                    className="d-flex flex-row justify-content-center align-items-center w-100"
                                                    style={{
                                                        paddingInline: 10,
                                                        paddingBlock: 6,
                                                        backgroundColor: item.IsApproved ? '#CFFFCE' : '#ECE1FF',
                                                        borderRadius: 8,
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            padding: 0,
                                                            margin: 0,
                                                            fontSize: 16,
                                                            fontWeight: 400,
                                                            color: item.IsApproved ? '#008000' : '#6A0DAD',
                                                        }}
                                                    >
                                                        {item.IsApproved ? 'Approved' : 'Pending'}
                                                    </p>
                                                </div>
                                            </td>
                                            <td
                                                style={{
                                                    paddingBlock: 10,
                                                    paddingInline: 16,
                                                    border: `1px solid ${colors.greys03}`,
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    wordBreak: 'break-all',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontWeight: '500',
                                                        color: colors.linkblue,
                                                        width: '100%',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setPrevItem(item);
                                                        setPreviewModalOpen(true);
                                                    }}
                                                >
                                                    {'View'}
                                                </p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>No SMS templates found</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default SmsTemplate;
