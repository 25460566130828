import colors from '../../../Utils/colors';
import { Position } from 'reactflow';
import images from '../../../Utils/images';
import CustomHandle from './CustomHandle';

const handleStyle = { backgroundColor: '#30D3C0', height: 8, width: 8, borderRadius: 4 };

const UpdateChatStatusNode = ({ data, isConnectable }) => {
  return (
    <>
      <div
        className="d-flex flex-column justify-content-between align-items-center"
        style={{
          border: `1px solid ${colors.borderwhite}`,
          borderRadius: 16,
          backgroundColor: colors.white01,
          width: 250,
        }}
      >
        <CustomHandle
          type="target"
          position={Position.Left}
          isConnectable={isConnectable}
          style={handleStyle}
        />
        <div
          className="w-100 d-flex flex-column justify-content-start align-items-center"
          style={{
            paddingInline: 16,
            paddingBlock: 8,
            backgroundColor: '#30D3C0',
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          }}
        >
          <div
            style={{
              height: 4,
              width: '20%',
              borderRadius: 2,
              backgroundColor: colors.white,
              marginBlockEnd: 4,
            }}
          ></div>
          <div
            className="w-100 d-flex flex-row justify-content-between align-items-center"
            style={{}}
          >
            <p
              style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.white }}
            >
              Update Chat Status
            </p>
            <img src={images.Edit} style={{ height: 20, width: 20, objectFit: 'contain' }}></img>
          </div>
        </div>

        <div
          className="w-100 d-flex flex-column justify-content-start align-items-center"
          style={{ paddingInline: 16, paddingBlockStart: 8, paddingBlockEnd: 16 }}
        >
          <p style={{ fontSize: 12, fontWeight: 400 }}>Select status</p>
        </div>
        <CustomHandle
          type="source"
          position={Position.Right}
          isConnectable={1}
          style={handleStyle}
        />
      </div>
    </>
  );
};

export default UpdateChatStatusNode;
