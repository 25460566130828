import React from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { GoAlert } from "react-icons/go";

const WarningModal = ({ onClose }) => {
    const handleClose = () => {
        setIsModalVisible(false);
    }
    return (
        <>
            <div
                className="d-flex flex-row justify-content-between align-items-center w-100"
                style={{
                    borderRadius: 16,
                    background: '#EB5858',
                    paddingBlock: 16,
                    paddingInline: 24,
                    marginBlockEnd: 24,
                }}
            >
                <div
                    className="d-flex flex-row justify-content-center align-items-center"
                    style={{ flex: 1 }}
                >
                    <GoAlert
                        style={{
                            width: 30,
                            height: 30,
                            color: 'white'
                        }}

                    />
                    <p style={{ fontSize: 18, fontWeight: 400, color: colors.white01, paddingLeft: '1%' }}>
                        {'Attention: Your spam rate is very high, please ensure you are sending messages to people who have shared their email ID with you directly. Your email sending has been paused due to the high spam rate.'}
                    </p>
                </div>
                <img
                    src={images.CMCloseWhite}
                    style={{
                        height: 24,
                        width: 24,
                        objectFit: 'contain',
                        cursor: 'pointer',
                        color: colors.white01,
                    }}
                    onClick={onClose}
                ></img>
            </div>
        </>
    );
};

export default WarningModal;
