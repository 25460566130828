import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getCampaignListAPI, getClientAttributes } from '../../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { options } from 'linkifyjs';

const UpdateAttributeModal = ({ data, setData, prevNode }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);

  const [isLoading, setIsLoading] = useState(false);
  const [attributesList, setAttributesList] = useState(['name', 'email', 'phone']);
  const [variableList, setVariableList] = useState([]);
  const [attribute, setAttribute] = useState('');
  const [attributeNewValue, setAttributeNewValue] = useState('');

  useEffect(() => {
    setIsLoading(true);
    getUserAttributesAPICall();
    if (prevNode?.data?.variable) {
      setVariableList((prev) => {
        return [prevNode?.data?.variable];
      });
    }
    if (data?.attribute) {
      setAttribute(data?.attribute);
    }
    if (data?.attributeNewValue) {
      setAttributeNewValue(data?.attributeNewValue);
    }
  }, []);

  useEffect(() => {
    setData({ ...data, attributeNewValue: attributeNewValue, attribute: attribute });
  }, [attributeNewValue, attribute]);

  const getUserAttributesAPICall = () => {
    let token = authtoken;
    getClientAttributes(token).then((res) => {
      if (res?.flag) {
        setAttributesList((prev) => {
          const arr = res?.data.map((item) => item.attribute);
          return [...prev, ...arr];
        });
        // console.log("user attributes success res ---> ",res);
      } else {
        // console.log("user attributes failed res ---> ",res);
      }
    });
  };

  return (
    <>
      <div className="w-100 h-100 py-3 d-flex flex-column justify-content-start align-items-start">
        <h4 className="" style={{ marginBottom: '10px', fontWeight: '600' }}>
          {'Choose attribute'}
        </h4>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          style={{
            marginBottom: '5px',
            width: '100%',
          }}
          size="small"
          renderInput={(params) => (
            <TextField
              name="variable"
              // value={item.variable}
              // onChange={(e) => {
              //   console.log('trigger', e.target.value);
              //   handleChange(index, e);
              // }}
              size={'small'}
              placeholder={'Type/Select an attribute'}
              className={'restylingLabels'}
              InputProps={{
                notched: false,
                style: {
                  borderRadius: 4,
                },
              }}
              {...params}
            />
          )}
          value={attribute}
          onInputChange={(e, newValue) => {
            setAttribute(newValue);
          }}
          onChange={(e, newValue) => {
            setAttribute(newValue);
          }}
          freeSolo={true}
          options={attributesList.map((val) => `${val}`)}
        />
        <h4 className="" style={{ marginTop: '10px', fontWeight: '600' }}>
          {'Enter Value'}
        </h4>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          style={{
            marginBlock: '10px',
            width: '100%',
          }}
          size="small"
          renderInput={(params) => (
            <TextField
              name="variable"
              // value={item.variable}
              // onChange={(e) => {
              //   console.log('trigger', e.target.value);
              //   handleChange(index, e);
              // }}
              size={'small'}
              placeholder={'Type/Select'}
              className={'restylingLabels'}
              InputProps={{
                notched: false,
                style: {
                  borderRadius: 4,
                },
              }}
              {...params}
            />
          )}
          value={attributeNewValue}
          onInputChange={(e, newValue) => {
            setAttributeNewValue(newValue);
          }}
          onChange={(e, newValue) => {
            setAttributeNewValue(newValue);
          }}
          freeSolo={true}
          options={variableList}
        />
      </div>
    </>
  );
};

export default UpdateAttributeModal;
