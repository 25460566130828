import React, { useEffect, useRef, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import EventEmitter from '../../../Utils/EventEmitter';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { V2WorkflowS3Link, getClientAttributes, saveResponse } from '../../../Services';
import { getImagePath } from '../../../Utils';
import UploadValidator from '../../../Components/UploadValidator';
import UploaderText from '../../../Components/UploaderText';
import { Modal } from 'react-bootstrap';
import '../styles.css';
import UserAttributesModal from './UserAttributesModal';

const QuickReplyModal = ({
  nick,
  response,
  setNickName,
  setResponse,
  setAddNew,
  saveResponseApi,
  updateResponseApi,
  deleteResponseApi,
  edit = false,
  mediaFile,
  setMediaFile,
  data,
  setData,
  attributeList,
  show,
  setShow,
  InputOptions,
  setInputOptions,
}) => {
  const [messageText, setMessageText] = useState('');
  const [mediaType, setMediaType] = useState('image');
  const [upFile, setUpFile] = useState();
  const [upFile64, setUpFile64] = useState('');
  const [upFileName, setUpFileName] = useState('');
  const [s3Link, setS3Link] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const [variable, setVariable] = useState('');
  const [userAttributes, setUserAttributes] = useState();
  const [UAModalOpen, setUAModalOpen] = useState(false);
  const [UAFilterValue, setUAFilterValue] = useState('');
  const [keyPressed, setKeyPressed] = useState('none');

  const authtoken = useSelector((state) => state.main.auth_token);

  const upImageRef = useRef();
  const upVideoRef = useRef();
  const upDocRef = useRef();
  const selectedAttributeAction = (value) => {
    // console.log("selected ---> ",value)
    let currentWord = getWordAtCursor();
    setResponse((prev) => {
      let before = prev.substring(0, currentWord[1]);
      let after = prev.substring(currentWord[2] + 1);
      let editedText = before + `%${value}%` + after;
      return editedText;
    });
    document.getElementById('RespInputAttr').focus();
    setUAModalOpen(false);
  };
  function getWordAtCursor() {
    const input = document.getElementById('RespInputAttr');
    const cursorPosition = input.selectionStart;
    // Get the text content of the input field
    const text = input.value;
    // Find the start and end indices of the current word
    let startIndex = cursorPosition;
    let endIndex = cursorPosition;
    // Move startIndex to the beginning of the word
    while (startIndex > 0 && !isWordBoundary(text[startIndex - 1])) {
      startIndex--;
    }
    // Move endIndex to the end of the word
    while (endIndex < text.length && !isWordBoundary(text[endIndex])) {
      endIndex++;
    }
    // Extract the current word
    const currentWord = text.substring(startIndex, endIndex);
    // console.log("Current word:", currentWord);
    return [currentWord, startIndex, endIndex];
  }

  function isWordBoundary(char) {
    // Check if the character is a space or newline
    return char === ' ' || char === '\n';
  }

  const handlefileupload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (UploadValidator(file, mediaType)) {
        setUpFile(file);
        setUpFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',

      });
    }
  };

  const handleDragFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDropFile = async (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('file ===> ', e.dataTransfer.files[0]);
    const file = e.dataTransfer.files[0];
    if (file && type) {
      if (UploadValidator(file, type)) {
        setUpFile(file);
        setUpFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',

      });
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (upFile64) {
      getS3Link();
    }
  }, [upFile64]);

  const getS3Link = () => {
    let data = {
      image: upFile64,
      type: upFile?.type?.split('/')[0],
      format: upFile?.type?.split('/')[1],
    };
    let token = authtoken;
    V2WorkflowS3Link(data, token).then((res) => {
      if (res) {
        setS3Link(res?.data?.url);
        console.log('s3 link success response', res);
        setMediaFile({
          format: upFile?.type?.split('/')[1],
          s3URL: res?.data?.url,
          type: mediaType,
          fileName: upFile?.name ? upFile?.name : res?.data?.fileName,
        });
        toast.success('file uploaded successfully', { position: 'top-center' });
      } else {
        toast.error('Error occurred while uploading file, please try again later', {
          position: 'top-center',

        });
        console.log('s3 link error response', res);
      }
    });
  };

  useEffect(() => {
    if (mediaFile) {
      setMediaType(mediaFile?.type);
      setUpFileName(mediaFile?.fileName);
      setS3Link(mediaFile?.s3URL);
    }
  }, []);

  useEffect(() => {
    getUserAttributesAPICall();
  }, []);

  const getUserAttributesAPICall = () => {
    let token = authtoken;
    getClientAttributes(token).then((res) => {
      if (res?.flag) {
        setUserAttributes(res?.data);
        // console.log("user attributes success res ---> ",res);
      } else {
        // console.log("user attributes failed res ---> ",res);
      }
    });
  };

  const saveResponseApiCall = () => {
    const data = {
      nickName: nick,
      response: response,
      file: {
        format: upFile?.type?.split('/')[1],
        s3URL: s3Link,
        type: mediaType,
        fileName: upFileName,
      },
      buttons: InputOptions,
    };
    saveResponse(authtoken, data).then((res) => {
      if (res?.flag) {
        toast.success('Response saved', { position: 'top-center' });
        setShow(false);
      } else {
        toast.error(res);
      }
    });
  };

  useEffect(() => {
    // setData({
    //   ...data,
    //   header: {
    //     mediaType: mediaType,
    //     mediaName: upFileName,
    //     mediaFile: s3Link,
    //   },
    //   body: {
    //     message: messageText,
    //   },
    //   options: InputOptions,
    //   variable: variable,
    // });
  }, [messageText, InputOptions, mediaType, upFile, upFile64, s3Link, variable]);

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="quickReplyModal"
        contentClassName="quickReplyModal"
      >
        <Modal.Body
          style={{
            width: '100%',

            padding: '20px',

            borderRadius: '22px',
          }}
        >
          <div className="d-flex flex-column justify-content-start align-items-start">
            <div
              className="d-flex justify-content-between"
              style={{
                alignItems: 'center',
                borderBottom: '1px solid #CCCCCC',
                width: '100%',
                paddingBottom: '10px',
              }}
            >
              <p style={{ fontWeight: '700', fontSize: '20px' }}>{'Quick Reply'}</p>

              <div style={{ alignItems: 'center' }}>
                <img
                  src={images.CMClose}
                  onClick={() => {
                    setShow(false);
                  }}
                  style={{
                    cursor: 'pointer',
                    height: 22,
                    width: 22,
                    marginInlineStart: 8,
                  }}
                ></img>
              </div>
            </div>

            <InputGroup
              className="px-1"
              style={{
                width: '98%',
                marginBlockEnd: 20,
                marginBlockStart: '10px',
              }}
            >
              <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                {'Shortcut'}
              </Form.Label>
              <Form.Control
                value={nick}
                type="text"
                placeholder="Type Shortcut for templates"
                className="w-100 btncustom"
                style={{ borderRadius: 8 }}
                onChange={(e) => {
                  setNickName(e.target.value);
                }}
              />
            </InputGroup>
            <div
              className="px-1"
              style={{
                maxHeight: '65vh',
                overflowY: 'auto',
                width: '100%',
                scrollbarWidth: 'thin',
              }}
            >
              <InputGroup style={{ marginBlock: '' }}>
                <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                  {'Select media type'}
                </Form.Label>
                <Form.Select
                  type="text"
                  placeholder="Select media type"
                  className="w-100 btncustom dropdown"
                  style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
                  value={mediaType}
                  onChange={(e) => {
                    setMediaType(e.target.value);
                  }}
                >
                  <option
                    style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                    value={'image'}
                    key={'image'}
                  >
                    {'Image'}
                  </option>
                  <option
                    style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                    value={'video'}
                    key={'video'}
                  >
                    {'Video'}
                  </option>
                  <option
                    style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                    value={'document'}
                    key={'document'}
                  >
                    {'Document'}
                  </option>
                </Form.Select>
              </InputGroup>

              <div
                className="d-flex flex-column justify-content-center align-items-center w-100 my-2"
                style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 8, height: 260 }}
                onDragEnter={handleDragFile}
                onDragExit={handleDragFile}
                onDragOver={handleDragFile}
                onDrop={(e) => {
                  handleDropFile(e, mediaType);
                }}
                onClick={() => {
                  if (mediaType === 'image') {
                    upImageRef.current.click();
                  } else if (mediaType === 'video') {
                    upVideoRef.current.click();
                  } else if (mediaType === 'document') {
                    upDocRef.current.click();
                  }
                }}
              >
                {data?.header?.mediaFile || upFile || s3Link ? (
                  <>
                    {s3Link && (
                      // {}
                      <>
                        {mediaType === 'image' ? (
                          <img
                            src={getImagePath(s3Link)}
                            style={{ height: 180, width: '80%', objectFit: 'contain' }}
                          ></img>
                        ) : mediaType === 'document' ? (
                          <>
                            {/* <img src={getImagePath(s3Link)} style={{height:180,width:'80%',objectFit:'contain'}}></img> */}
                            {/* {upFile && 
              <embed src={URL.createObjectURL(s3Link)} style={{height:180,width:'80%',objectFit:'cover'}}></embed>
            } */}
                            {upFile ? (
                              <embed
                                src={URL.createObjectURL(upFile)}
                                style={{ height: 180, width: '80%', objectFit: 'cover' }}
                              ></embed>
                            ) : (
                              <img
                                src={images.CATREmail}
                                style={{ height: 180, width: '80%', objectFit: 'contain' }}
                              ></img>
                            )}
                          </>
                        ) : (
                          mediaType === 'video' && (
                            <video
                              src={getImagePath(s3Link)}
                              style={{ height: 180, width: '80%', objectFit: 'contain' }}
                            ></video>
                          )
                        )}
                      </>
                    )}
                    {upFileName && (
                      <div className="d-flex flex-row justify-content-between align-items-center w-75">
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 4,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.greys04,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            wordBreak: 'break-all',
                          }}
                        >
                          {upFileName}
                        </p>
                        <img
                          src={images.Cross}
                          style={{
                            height: 18,
                            width: 18,
                            marginInlineStart: 24,
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setUpFile(null);
                            setUpFile64(null);
                            setUpFileName('');
                            setS3Link(null);
                          }}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <img
                      src={images.ImgUpIcon}
                      style={{ height: 100, width: 70, objectFit: 'contain' }}
                    ></img>
                    <UploaderText
                      type={mediaType}
                      textStyle={{
                        fontSize: 14,
                        fontWeight: 600,
                        color: colors.greys04,
                        textAlign: 'center',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        wordBreak: 'break-all',
                      }}
                    />
                    {/* <p style={{padding:0,margin:0,marginBlock:4,fontSize:16,fontWeight:600,color:colors.greys04}}>
            {'Upload Image'}
          </p>
          <p style={{padding:0,margin:0,marginBlock:8,fontSize:14,fontWeight:600,color:colors.greys04,textAlign:'center'}}>
            {'350px x 350px to 1500px x 1500px'}<br/>
            {'Max file size: 5 MB'}
          </p> */}
                  </>
                )}
              </div>

              <input
                type="file"
                style={{ display: 'none' }}
                ref={upImageRef}
                accept="image/*"
                onChange={(e) => {
                  handlefileupload(e);
                }}
              />
              <input
                type="file"
                style={{ display: 'none' }}
                ref={upVideoRef}
                accept="video/*"
                onChange={(e) => {
                  handlefileupload(e);
                }}
              />
              <input
                type="file"
                style={{ display: 'none' }}
                ref={upDocRef}
                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.pdfx,.csv"
                onChange={(e) => {
                  handlefileupload(e);
                }}
              />

              {UAModalOpen && (
                <UserAttributesModal
                  setUserAttributesOpen={setUAModalOpen}
                  userAttributesData={userAttributes}
                  selectedUserAttributeAction={selectedAttributeAction}
                  filterValue={UAFilterValue}
                  keyPressed={keyPressed}
                  setKeyPressed={setKeyPressed}
                />
              )}

              <div style={{ marginTop: '10px' }}>
                <label
                  for="exampleFormControlInput1"
                  style={{ fontWeight: '600', fontSize: '14px', color: 'black' }}
                >
                  Response
                </label>

                <textarea
                  id="RespInputAttr"
                  rows="5"
                  class="form-control shadow-none"
                  placeholder="Add response"
                  style={{ resize: 'none' }}
                  value={response}
                  onChange={(e) => setResponse(e.target.value)}
                  required
                  onKeyDown={(event) => {
                    if (event.key === '%' && event.shiftKey) {
                      setUAModalOpen(true);
                    } else if (
                      event.key === 'Backspace' &&
                      response.charAt(event.target.selectionStart - 1) === '%'
                    ) {
                      setUAModalOpen(false);
                    } else if (event.key === 'ArrowUp' && UAModalOpen) {
                      event.preventDefault();
                      setKeyPressed('Up');
                    } else if (event.key === 'ArrowDown' && UAModalOpen) {
                      event.preventDefault();
                      setKeyPressed('Down');
                    } else if (event.key === 'Tab' && UAModalOpen) {
                      event.preventDefault();
                      setKeyPressed('Tab');
                    } else if (event.code === 'Space') {
                      setUAModalOpen(false);
                    }
                  }}
                />
              </div>
              <div
                className="w-100"
                style={{ height: 1, backgroundColor: colors.borderwhite, marginBlock: 16 }}
              ></div>
              {InputOptions.length > 3 && (
                <p style={{ fontSize: '12px', color: 'red' }}>
                  Note:- Incase of more than 3 buttons above media file will not appear in your
                  whatsapp message because of message limit.
                </p>
              )}
              {InputOptions &&
                InputOptions.map((item, index) => {
                  return (
                    <>
                      <div
                        key={`key==${index}`}
                        className="d-flex flex-column justify-content-start align-items-center w-100"
                      >
                        <div className="d-flex flex-row justify-content-between align-items-center w-100">
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              marginBlock: 4,
                              fontSize: 16,
                              fontWeight: 600,
                              color: colors.black,
                              width: '100%',
                            }}
                          >
                            {`Button ${index + 1}`}
                          </p>
                          <img
                            src={images.DeleteBlack}
                            style={{
                              height: 18,
                              width: 18,
                              objectFit: 'contain',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              let temp = [...InputOptions];
                              let index = temp.indexOf(item);
                              let delEle = temp.splice(index, 1);
                              setInputOptions(temp);
                            }}
                          ></img>
                        </div>

                        <InputGroup className="my-1">
                          <Form.Control
                            type="text"
                            placeholder={'Add text'}
                            className="w-100 btncustom"
                            style={{
                              borderRadius: 8,
                              fontSize: 14,
                              fontWeight: 400,
                              color: colors.black,
                            }}
                            // isInvalid={(renewalText.length >= 640)}
                            maxLength={20}
                            value={InputOptions[index].InputValue}
                            onChange={(e) => {
                              const tempObj = [...InputOptions];
                              tempObj[index].InputValue = e.target.value;
                              setInputOptions(tempObj);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </>
                  );
                })}

              {InputOptions.length < 8 && (
                <div className="d-flex justify-content-end">
                  <CheerioButton
                    borderStyle={{ marginBlockStart: 8, marginBlockEnd: 16 }}
                    textStyle={{
                      fontSize: 14,
                      padding: 0,
                      margin: 0,
                      fontWeight: 600,
                      color: colors.black,
                    }}
                    btnText={'Add Button'}
                    backColor={colors.white01}
                    onclick={() => {
                      console.log('add option clicked');
                      const tempArr = [...InputOptions];
                      tempArr.push({ InputValue: '' });
                      setInputOptions(tempArr);
                    }}
                  />
                </div>
              )}
            </div>
            {!edit ? (
              <CheerioButton
                btnText={'Save Response'}
                textStyle={{ color: colors.white }}
                backColor={colors.black}
                borderStyle={{
                  borderWidth: 0,
                  width: '100%',
                  marginTop: '10px',

                  borderRadius: '8px',
                }}
                disabled={false}
                onclick={() => {
                  saveResponseApiCall();
                }}
              />
            ) : (
              <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                <CheerioButton
                  btnText={'Update Response'}
                  textStyle={{ color: colors.white }}
                  backColor={colors.black}
                  borderStyle={{
                    borderWidth: 0,
                    width: '48%',
                    marginTop: '10px',

                    borderRadius: '8px',
                  }}
                  disabled={false}
                  onclick={() => {
                    updateResponseApi();
                  }}
                />
                <CheerioButton
                  btnText={'Delete Response'}
                  textStyle={{ color: colors.black }}
                  backColor={colors.white}
                  borderStyle={{
                    border: '1px solid black',
                    width: '48%',
                    marginTop: '10px',

                    borderRadius: '8px',
                  }}
                  disabled={false}
                  onclick={() => {
                    deleteResponseApi();
                  }}
                />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QuickReplyModal;
