import React from 'react';
import './../styles.css';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';

const PreviewModal = ({ setOpenPreviewModal, previewType }) => {
  return (
    <>
      <div
        className="DarkBg"
        onClick={() => {
          setOpenPreviewModal(false);
        }}
      >
        <div className="Center">
          <div
            className="d-flex flex-column justify-content-start align-items-center"
            style={{
              width: 570,
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 0,
              border: `1px solid ${colors.borderwhite}`,
              paddingBlock: 30,
              paddingInline: 30,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <h2
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: 20,
                  fontWeight: 700,
                  color: colors.black,
                }}
              >
                {previewType}
              </h2>
              <img
                src={images.CMClose}
                style={{ height: 32, width: 32, objectFit: 'contain', cursor: 'pointer' }}
                onClick={() => {
                  setOpenPreviewModal(false);
                }}
              ></img>
            </div>
            <div
              className="d-flex flex-column justify-content-start align-items-center w-100"
              style={{ marginBlockEnd: 40, marginBlockStart: 60 }}
            >
              {previewType == 'Order Fulfilled' && (
                <>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      paddingBlockEnd: 16,
                      position: 'relative',
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.white,
                        width: '100%',
                      }}
                    >
                      {'Hey '}
                      <span style={{ fontWeight: 700 }}>{'*[Customer name]*'}</span>
                      <br />
                      <br />
                      {
                        'Your order is on its way. You can check your shipment details or track your order by clicking on the button below. 📦'
                      }
                    </p>
                    <img
                      src={images.CreatorWA}
                      style={{
                        height: 34,
                        width: 34,
                        objectFit: 'contain',
                        position: 'absolute',
                        top: -22,
                        left: -22,
                      }}
                    ></img>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 10,
                        fontWeight: 400,
                        color: colors.white,
                        position: 'absolute',
                        bottom: 3,
                        right: 6,
                      }}
                    >
                      {'12:15 pm'}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      marginBlockStart: 8,
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 600,
                        color: colors.white,
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {'Track Order'}
                    </p>
                  </div>
                </>
              )}
              {previewType == 'Customer Feedback' && (
                <>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      paddingBlockEnd: 16,
                      position: 'relative',
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.white,
                        width: '100%',
                      }}
                    >
                      {'Hey '}
                      <span style={{ fontWeight: 700 }}>{'*[Customer name]*'}</span>
                      <br />
                      <br />
                      {'We hope you liked the '}
                      <span style={{ fontWeight: 700 }}>{'*[Product]*'}</span>
                      {' you purchased on '}
                      <span style={{ fontWeight: 700 }}>{'*[Date]*.'}</span>
                      {'✨Can you share your experience with us?'}
                    </p>
                    <img
                      src={images.CreatorWA}
                      style={{
                        height: 34,
                        width: 34,
                        objectFit: 'contain',
                        position: 'absolute',
                        top: -22,
                        left: -22,
                      }}
                    ></img>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 10,
                        fontWeight: 400,
                        color: colors.white,
                        position: 'absolute',
                        bottom: 3,
                        right: 6,
                      }}
                    >
                      {'12:15 pm'}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      marginBlockStart: 8,
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 600,
                        color: colors.white,
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {'I am happy'}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      marginBlockStart: 8,
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 600,
                        color: colors.white,
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {'I am very happy'}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      marginBlockStart: 8,
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 600,
                        color: colors.white,
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {'Not satisfied'}
                    </p>
                  </div>
                </>
              )}
              {previewType == 'Abandoned Cart' && (
                <>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      paddingBlockEnd: 16,
                      position: 'relative',
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.white,
                        width: '100%',
                      }}
                    >
                      {'Hi '}
                      <span style={{ fontWeight: 700 }}>{'*[Customer name]*'}</span>
                      {'! 👋'}
                      <br />
                      <br />
                      {'Thank you for your order.Your payment of '}
                      <span style={{ fontWeight: 700 }}>{'*[Amount]*'}</span>
                      {' for an order created from the '}
                      <span style={{ fontWeight: 700 }}>{'*SHOPIFY_STORE_NAME*'}</span>
                      {' store is still pending.Check out now before they run out of stock. ⏱️'}
                    </p>
                    <img
                      src={images.CreatorWA}
                      style={{
                        height: 34,
                        width: 34,
                        objectFit: 'contain',
                        position: 'absolute',
                        top: -22,
                        left: -22,
                      }}
                    ></img>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 10,
                        fontWeight: 400,
                        color: colors.white,
                        position: 'absolute',
                        bottom: 3,
                        right: 6,
                      }}
                    >
                      {'12:15 pm'}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      marginBlockStart: 8,
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 600,
                        color: colors.white,
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {'Complete Order'}
                    </p>
                  </div>
                </>
              )}
              {previewType == 'Order Cancelled' && (
                <>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      paddingBlockEnd: 16,
                      position: 'relative',
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.white,
                        width: '100%',
                      }}
                    >
                      {'Hi '}
                      <span style={{ fontWeight: 700 }}>{'*[Customer name]*!'}</span>
                      <br />
                      <br />
                      {'Your order '}
                      <span style={{ fontWeight: 700 }}>{'*#ID*'}</span>
                      {' amounting to '}
                      <span style={{ fontWeight: 700 }}>{'*Amount*'}</span>
                      {
                        ' has been cancelled. We’re sorry that this order didn’t work out for you, but we hope to see you again! 🙂'
                      }
                    </p>
                    <img
                      src={images.CreatorWA}
                      style={{
                        height: 34,
                        width: 34,
                        objectFit: 'contain',
                        position: 'absolute',
                        top: -22,
                        left: -22,
                      }}
                    ></img>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 10,
                        fontWeight: 400,
                        color: colors.white,
                        position: 'absolute',
                        bottom: 3,
                        right: 6,
                      }}
                    >
                      {'12:15 pm'}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      marginBlockStart: 8,
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 600,
                        color: colors.white,
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {'Visit website'}
                    </p>
                  </div>
                </>
              )}
              {previewType == 'Order Confirmed' && (
                <>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      paddingBlockEnd: 16,
                      position: 'relative',
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.white,
                        width: '100%',
                      }}
                    >
                      {'Hi '}
                      <span style={{ fontWeight: 700 }}>{'*[Customer name]*!'}</span>
                      <br />
                      <br />
                      {'Your order is confirmed 🎉Great news! We have received your order '}
                      <span style={{ fontWeight: 700 }}>{'*#ID*'}</span>
                      {' amounting to '}
                      <span style={{ fontWeight: 700 }}>{'*Amount*.'}</span>
                    </p>
                    <img
                      src={images.CreatorWA}
                      style={{
                        height: 34,
                        width: 34,
                        objectFit: 'contain',
                        position: 'absolute',
                        top: -22,
                        left: -22,
                      }}
                    ></img>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 10,
                        fontWeight: 400,
                        color: colors.white,
                        position: 'absolute',
                        bottom: 3,
                        right: 6,
                      }}
                    >
                      {'12:15 pm'}
                    </p>
                  </div>
                </>
              )}
              {previewType == 'Abandoned Cart 2' && (
                <>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      paddingBlockEnd: 16,
                      position: 'relative',
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.white,
                        width: '100%',
                      }}
                    >
                      {'Hi '}
                      <span style={{ fontWeight: 700 }}>{'*[Customer name]*'}</span>
                      {'! 👋'}
                      <br />
                      <br />
                      {
                        'We noticed that the item(s) is still in your Cart. We have an amazing offer for you. 😍Use Code - '
                      }
                      <span style={{ fontWeight: 700 }}>{'*CODE*'}</span>
                      {' and get '}
                      <span style={{ fontWeight: 700 }}>{'*[10%]*'}</span>
                      {' off with Free Shipping all over India. 😉Products added: '}
                      <span style={{ fontWeight: 700 }}>{'*Product Name*.'}</span>
                      {' To proceed please click the button below.'}
                    </p>
                    <img
                      src={images.CreatorWA}
                      style={{
                        height: 34,
                        width: 34,
                        objectFit: 'contain',
                        position: 'absolute',
                        top: -22,
                        left: -22,
                      }}
                    ></img>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 10,
                        fontWeight: 400,
                        color: colors.white,
                        position: 'absolute',
                        bottom: 3,
                        right: 6,
                      }}
                    >
                      {'12:15 pm'}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      marginBlockStart: 8,
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 600,
                        color: colors.white,
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {'Complete Order'}
                    </p>
                  </div>
                </>
              )}
              {previewType == 'Reorder' && (
                <>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      paddingBlockEnd: 16,
                      position: 'relative',
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.white,
                        width: '100%',
                      }}
                    >
                      {'Hi '}
                      <span style={{ fontWeight: 700 }}>{'*[Customer name]*'}</span>
                      {'! 👋'}
                      <br />
                      <br />
                      {'Thanks for making your last purchase of '}
                      <span style={{ fontWeight: 700 }}>{'*Product Name*.'}</span>
                      {
                        " *Here's a reminder to place an order of your favorite items before they go out of our stock*."
                      }
                    </p>
                    <img
                      src={images.CreatorWA}
                      style={{
                        height: 34,
                        width: 34,
                        objectFit: 'contain',
                        position: 'absolute',
                        top: -22,
                        left: -22,
                      }}
                    ></img>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 10,
                        fontWeight: 400,
                        color: colors.white,
                        position: 'absolute',
                        bottom: 3,
                        right: 6,
                      }}
                    >
                      {'12:15 pm'}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      marginBlockStart: 8,
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 600,
                        color: colors.white,
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {'Visit website'}
                    </p>
                  </div>
                </>
              )}
              {previewType == 'Abandoned cart 3' && (
                <>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      paddingBlockEnd: 16,
                      position: 'relative',
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.white,
                        width: '100%',
                      }}
                    >
                      {'Hi '}
                      <span style={{ fontWeight: 700 }}>{'*[Customer name]*'}</span>
                      {'! 👋'}
                      <br />
                      <br />
                      {
                        'We noticed that the item(s) is still in your Cart. Confused about what to order? 🤔We’ll keep your cart safe for you! Additionally, Use Code - '
                      }
                      <span style={{ fontWeight: 700 }}>{'*CODE29*'}</span>
                      {' and get '}
                      <span style={{ fontWeight: 700 }}>{'*20%*'}</span>
                      {' off with Free Shipping all over India. 😍Products added: '}
                      <span style={{ fontWeight: 700 }}>{'*Product(s) name*'}</span>
                      {'. Kindly complete your order now with '}
                      <span style={{ fontWeight: 700 }}>{'*SHOPIFY_STORE_NAME*.'}</span>
                    </p>
                    <img
                      src={images.CreatorWA}
                      style={{
                        height: 34,
                        width: 34,
                        objectFit: 'contain',
                        position: 'absolute',
                        top: -22,
                        left: -22,
                      }}
                    ></img>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 10,
                        fontWeight: 400,
                        color: colors.white,
                        position: 'absolute',
                        bottom: 3,
                        right: 6,
                      }}
                    >
                      {'12:15 pm'}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      marginBlockStart: 8,
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 600,
                        color: colors.white,
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {'Visit website'}
                    </p>
                  </div>
                </>
              )}
              {previewType == 'COD Order Confirmation' && (
                <>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      paddingBlockEnd: 16,
                      position: 'relative',
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.white,
                        width: '100%',
                      }}
                    >
                      {'Hello '}
                      <span style={{ fontWeight: 700 }}>{'*[Customer name]*'}</span>
                      <br />
                      <br />
                      {'Thank you for purchasing from '}
                      <span style={{ fontWeight: 700 }}>{'*SHOPIFY_STORE_NAME*.'}</span>
                      {
                        ' Your order has been created successfully. Since you’ve chosen Cash on Delivery, Click here to confirm or cancel the order.'
                      }
                    </p>
                    <img
                      src={images.CreatorWA}
                      style={{
                        height: 34,
                        width: 34,
                        objectFit: 'contain',
                        position: 'absolute',
                        top: -22,
                        left: -22,
                      }}
                    ></img>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 10,
                        fontWeight: 400,
                        color: colors.white,
                        position: 'absolute',
                        bottom: 3,
                        right: 6,
                      }}
                    >
                      {'12:15 pm'}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      marginBlockStart: 8,
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 600,
                        color: colors.white,
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {'Confirm Order'}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      marginBlockStart: 8,
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 600,
                        color: colors.white,
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {'Cancel Order'}
                    </p>
                  </div>
                </>
              )}
              {previewType == 'COD Order Confirm Status' && (
                <>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      paddingBlockEnd: 16,
                      position: 'relative',
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.white,
                        width: '100%',
                      }}
                    >
                      {
                        'Thank you for your confirmation. Your package is now being processed for shipping. Have a great day! 😀'
                      }
                    </p>
                    <img
                      src={images.CreatorWA}
                      style={{
                        height: 34,
                        width: 34,
                        objectFit: 'contain',
                        position: 'absolute',
                        top: -22,
                        left: -22,
                      }}
                    ></img>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 10,
                        fontWeight: 400,
                        color: colors.white,
                        position: 'absolute',
                        bottom: 3,
                        right: 6,
                      }}
                    >
                      {'12:15 pm'}
                    </p>
                  </div>
                </>
              )}
              {previewType == 'COD Order Follow up' && (
                <>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      paddingBlockEnd: 16,
                      position: 'relative',
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.white,
                        width: '100%',
                      }}
                    >
                      {'Hello '}
                      <span style={{ fontWeight: 700 }}>{'*[Customer name]*'}</span>
                      <br />
                      <br />
                      {'Thank you for purchasing from '}
                      <span style={{ fontWeight: 700 }}>{'*SHOPIFY_STORE_NAME*.'}</span>
                      {
                        " Your order has been created successfully. We noticed you haven't yet confirmed your order. Please tap on the button below to Confirm Order."
                      }
                    </p>
                    <img
                      src={images.CreatorWA}
                      style={{
                        height: 34,
                        width: 34,
                        objectFit: 'contain',
                        position: 'absolute',
                        top: -22,
                        left: -22,
                      }}
                    ></img>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 10,
                        fontWeight: 400,
                        color: colors.white,
                        position: 'absolute',
                        bottom: 3,
                        right: 6,
                      }}
                    >
                      {'12:15 pm'}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      width: 250,
                      backgroundColor: '#1F2C34',
                      borderRadius: 10,
                      padding: 6,
                      marginBlockStart: 8,
                    }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 14,
                        fontWeight: 600,
                        color: colors.white,
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {'Confirm Order'}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewModal;
