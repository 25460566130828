import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import colors from '../../../Utils/colors';
import { getAllPixelDetails } from '../../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
const Step1Modal = ({ show, handleClose, acc, setShowPixelEventModal, setSelectedAccount, selectedAccount }) => {
    const [selectedAdAccount, setSelectedAdAccount] = useState('');


    const handleSubmit = () => {
        const selectAccount = acc.find(item => item?.id === selectedAdAccount);
        if (selectAccount) {
            setShowPixelEventModal(false);
            setSelectedAccount(selectAccount);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Choose an Ad Account to sync Pixel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {acc?.length > 0 ? (
                    <Form>
                        {acc.map((a) => (
                            <Form.Check
                                key={a?.id}
                                type="radio"
                                id={a?.id}
                                label={a?.name}
                                name="adAccount"
                                value={a?.id}
                                checked={selectedAdAccount === a?.id}
                                onChange={(e) => setSelectedAdAccount(e.target.value)}
                                style={{ marginBottom: '10px' }}
                            />
                        ))}
                    </Form>
                ) : (
                    <div>No Ad accounts found.</div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Next
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const Step2Modal = ({ show, handleClose, pixelDetails, selectedPixel, setSelectedPixel }) => {
    const [pixelNew, setPixelNew] = useState(selectedPixel);

    const handleSubmit = () => {
        if (pixelNew) {
            setSelectedPixel(pixelNew);
        } else {
            toast.error("Please select a pixel", { position: 'top-center' });
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a pixel event</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pixelDetails.length > 0 ? (
                    <Form>
                        {pixelDetails.map((pixel) => (
                            <Form.Check
                                key={pixel?.id}
                                type="radio"
                                id={pixel?.id}
                                label={`${pixel?.name} (ID: ${pixel?.id})`}
                                name="pixelAccount"
                                value={pixel?.id}
                                checked={pixelNew === pixel?.id}
                                onChange={(e) => setPixelNew(e.target.value)}
                                style={{ marginBottom: '10px' }}
                            />
                        ))}
                    </Form>
                ) : (
                    <div>No pixels found for this account.</div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                {pixelDetails.length > 0 && (
                    <Button variant="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

const PixelEventModal = ({ data, setData, showPixelEventModal, setShowPixelEventModal, onSubmit, adAccounts }) => {
    const [showStep2, setShowStep2] = useState(false);
    const [pixelDetails, setPixelDetails] = useState([]);
    const [selectedAdAccount, setSelectedAdAccount] = useState(null);
    const [selectedPixelId, setSelectedPixelId] = useState(null);
    const authtoken = useSelector((state) => state.main.auth_token);
    const [selectedPixelData, setSelectedPixelData] = useState(data?.selectedPixel ? data?.selectedPixel : {});
    const [selectedEvent, setSelectedEvent] = useState(data?.event_name ? data?.event_name : 'AddToCart');
    const [allowed2proceed, setAllowed2proceed] = useState(false);

    useEffect(()=>{
        setData(prevData => ({
            ...prevData,
            event_name: selectedEvent,
        }));
    },[selectedEvent])

    useEffect(() => {
        if(data?.selectedAdAccount){
            setSelectedAdAccount({
                ...selectedAdAccount,
                id: data.selectedAdAccount,
            })
        }
    }, []);

    const onSaveAction = () => {
        if (data?.selectedAdAccount === selectedAdAccount?.id && data?.selectedPixel === selectedPixelData && selectedPixelId) {
            toast("Saving the data");
            onSubmit(data);
            setShowPixelEventModal(false);
        }
    }

    useEffect(()=>{
        if (data?.selectedAdAccount && data?.selectedPixel?.id && data?.selectedPixel?.name) {
            onSaveAction();
        }
    },[data])

    useEffect(() => {
        console.log('before selected');
        if (selectedAdAccount) {
            console.log('after selected',selectedAdAccount);
            setData(prevData => ({
                ...prevData,
                selectedAdAccount: selectedAdAccount?.id,
            }));
            console.log('api call');
            const apiData = { id: selectedAdAccount.id };
            if(allowed2proceed){
                getAllPixelDetailsApiCall(apiData);
            }
        }

        if (selectedPixelData) {
            setData(prevData => ({
                ...prevData,
                selectedPixel: selectedPixelData
            }));
        }
    }, [selectedPixelData, selectedAdAccount]);

    useEffect(() => {
        if (selectedPixelId) {
            const pixel = pixelDetails.find(pixel => pixel.id === selectedPixelId);
            setSelectedPixelData(pixel);
        }
    }, [selectedPixelId])

    const getAllPixelDetailsApiCall = (apiData) => {
        getAllPixelDetails(authtoken, apiData).then((res) => {
            if (res?.flag) {
                setPixelDetails(res?.data?.data || []);
                console.log('pixelDetails', res?.data?.data);
                setShowStep2(true);
            } else {
                toast.error(res?.message, { position: 'top-center' });
            }
            setAllowed2proceed(false);
        });
    };


    return (
        <>
            <div>
                <p>Sync your meta pixel account with CheerioAI to manage and create pixel events</p>
                <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 16 }}>
                    <Form.Label htmlFor="event-select" style={styles.label}>Select event</Form.Label>
                    <Form.Select
                        type="text"
                        placeholder="select"
                        className="w-100 btncustom dropdown"
                        style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black02 }}
                        value={selectedEvent}
                        onChange={(e)=>setSelectedEvent(e.target.value)}
                    >
                        <option
                            style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                            value={'Purchase'}
                            key={'Purchase'}
                        >
                            {'Purchase'}
                        </option>
                        <option
                            style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                            value={'LeadSubmitted'}
                            key={'LeadSubmitted'}
                        >
                            {'LeadSubmitted'}
                        </option>
                        <option
                            style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                            value={'InitiateCheckout'}
                            key={'InitiateCheckout'}
                        >
                            {'InitiateCheckout'}
                        </option>
                        <option
                            style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                            value={'AppointmentBooked'}
                            key={'AppointmentBooked'}
                        >
                            {'AppointmentBooked'}
                        </option>
                        <option
                            style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                            value={'LeadQualified'}
                            key={'LeadQualified'}
                        >
                            {'LeadQualified'}
                        </option>
                        <option
                            style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                            value={'LeadConverted'}
                            key={'LeadConverted'}
                        >
                            {'LeadConverted'}
                        </option>
                        <option
                            style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                            value={'AddToCart'}
                            key={'AddToCart'}
                        >
                            {'AddToCart'}
                        </option>
                        <option
                            style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                            value={'ViewContent'}
                            key={'ViewContent'}
                        >
                            {'ViewContent'}
                        </option>
                        <option
                            style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                            value={'OrderCreated'}
                            key={'OrderCreated'}
                        >
                            {'OrderCreated'}
                        </option>
                        <option
                            style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                            value={'OrderShipped'}
                            key={'OrderShipped'}
                        >
                            {'OrderShipped'}
                        </option>
                    </Form.Select>
                </InputGroup >
            </div>
            <Step1Modal
                show={showPixelEventModal}
                handleClose={() => setShowPixelEventModal(false)}
                acc={adAccounts || []}
                setShowPixelEventModal={setShowPixelEventModal}
                selectedAccount={selectedAdAccount}
                setSelectedAccount={(value)=>{
                    setAllowed2proceed(true)
                    setSelectedAdAccount(value)
                }}
            />
            <Step2Modal
                show={showStep2}
                handleClose={() => setShowStep2(false)}
                pixelDetails={pixelDetails}
                selectedPixel={selectedPixelId}
                setSelectedPixel={(value)=>{
                    setSelectedPixelId(value);
                    setShowStep2(false);
                }}
            />
        </>
    );
};
const styles = {
    container: {
        width: '300px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        backgroundColor: 'white',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 15px',
        borderBottom: '1px solid #eee',
    },
    title: {
        margin: 0,
        fontSize: '16px',
        fontWeight: 'bold',
    },
    closeButton: {
        background: 'none',
        border: 'none',
        fontSize: '20px',
        cursor: 'pointer',
    },
    content: {
        padding: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    selectWrapper: {
        position: 'relative',
        marginBottom: '15px',
    },
    select: {
        width: '100%',
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    arrow: {
        fontSize: '12px',
    },
    dropdown: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderTop: 'none',
        borderRadius: '0 0 4px 4px',
        listStyle: 'none',
        padding: 0,
        margin: 0,
        zIndex: 1,
    },
    dropdownItem: {
        padding: '8px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    },
    saveButton: {
        width: '100%',
        padding: '10px',
        backgroundColor: 'black',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '14px',
    },
};
export default PixelEventModal;