import React, { useEffect, useRef, useState } from 'react'
import colors from '../../Utils/colors'
import CheerioButton from '../../Components/CheerioButton'
import images from '../../Utils/images'
import { QRCode } from 'react-qrcode-logo';
import html2canvas from 'html2canvas';
import { useParams } from 'react-router-dom';
import { WixRedirectWALink, loginADMIN } from '../../Services';
import SpinnerLoader from '../../Components/SpinnerLoader';
import { toast } from 'react-toastify';

const WixQRCode = () => {

    const { code } = useParams();
    const ScreenWidth = window.innerWidth;

    const [isLoading, setIsLoading] = useState(true);
    const [waLink, setWaLink] = useState('');
    const [UpLogo, setUpLogo] = useState('');
    const upLogoRef = useRef();

    useEffect(() => {
        console.log("screen width ==> ", ScreenWidth)
        setWaLink('wa.cheerio.in/' + code);
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, [])

    const copyLinkToClipboard = async () => {
        var copyText = document.getElementById("WALink").innerHTML;
        console.log("copytext ", copyText);
        try {
            await navigator.clipboard.writeText(copyText);
            console.log("copy success");
            toast.success("Copied link successfully!", { position: "top-center", });
        } catch (err) {
            console.log("error failed to copy: ", err);
        }
    }

    const handleLogoUpload = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        setUpLogo(base64);
    }
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const downloadQR = () => {
        html2canvas(document.getElementById('QRContainer')).then((canvas) => {
            const pngUrl = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = "QRCode.png";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        })
    };

    return (
        <>
            <div className='d-flex flex-row justify-content-center align-items-start' style={{ height: '100%', width: '100%', paddingInline: 30, paddingBlock: 30, flexWrap: 'wrap' }}>
                {isLoading ? (
                    <>
                        <div className='d-flex flex-column justify-content-center align-items-center w-100 h-100'>
                            <SpinnerLoader />
                        </div>
                    </>
                ) : (
                    <>
                        {/* <div className='d-flex flex-'></div> */}

                        <div className='d-flex flex-column col-lg-4 col-sm-6 col-12 justify-content-start justify-content-sm-between align-items-center' style={{ maxHeight: 540, height: ScreenWidth > 576 ? 540 : 'auto', alignSelf: 'center' }}>

                            <div className='d-flex flex-column justify-content-between align-items-center w-100 my-sm-3 my-2'>
                                <h2 style={{ fontSize: 20, fontWeight: 700, color: colors.black, padding: 0, margin: 0, width: '100%' }} className=''>
                                    {'Here is your WhatsApp link'}
                                </h2>
                                <p style={{ fontSize: 16, fontWeight: 400, color: colors.greys04, padding: 0, margin: 0, paddingBlock: 16, width: '100%' }} className=''>
                                    {'Copy & Share it on your social media, website or anywhere you want'}
                                </p>
                            </div>

                            <div className='d-flex flex-column justify-content-between align-items-center w-100 my-sm-5 my-2'>
                                <div className='d-flex flex-row justify-content-start align-items-center w-100'>
                                    <span id='WALink' style={{ fontSize: 16, fontWeight: 400, color: colors.linkblue, padding: 0, margin: 0, paddingBlock: 16 }}>
                                        {waLink ? waLink : 'your link will appear here'}
                                    </span>
                                    {/* <img src={images.copyBlue} style={{height:20,width:20,objectFit:'contain',marginInline:16,cursor:'pointer'}} onClick={()=>{copyLinkToClipboard()}}></img> */}
                                </div>

                                <CheerioButton
                                    // borderStyle={{borderWidth:0,marginBlock:16,marginInlineEnd:'auto',width:'50%',display:ScreenWidth<576 && 'none'}}
                                    borderStyle={{ borderWidth: 0, marginBlock: 16, marginInlineEnd: 'auto', paddingInline: 30, paddingBlock: 8, display: ScreenWidth < 576 && 'none' }}
                                    textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
                                    btnText={'Download QR'}
                                    backColor={colors.primary03}
                                    icon={images.downloadWhite}
                                    onclick={() => { downloadQR() }}
                                />
                            </div>

                            <p className='my-sm-3 my-2' style={{ fontSize: 16, fontWeight: 600, color: colors.linkblue, padding: 0, margin: 0, paddingBlock: 16, cursor: 'pointer', display: ScreenWidth < 576 && 'none' }} onClick={() => {
                                window.open('https://app.cheerio.in/', '_blank')
                            }}>
                                {'Signup to Cheerio'}<span style={{ color: colors.black }}>{' for link tracking!'}</span>
                            </p>

                        </div>
                        <div className='d-flex flex-column col-lg-4 col-sm-6 col-12 justify-content-start justify-content-sm-between align-items-center' style={{ maxHeight: 540, height: ScreenWidth > 576 ? 540 : 'auto', alignSelf: 'center' }}>


                            <h2 style={{ fontSize: 20, fontWeight: 700, color: colors.black, padding: 0, margin: 0 }} className='my-sm-3 my-2'>
                                {'Customize your QR Code'}
                            </h2>

                            <div className='d-flex flex-column justify-content-between align-items-center w-100 my-sm-3 my-2' style={{ height: 100, cursor: 'pointer' }} onClick={() => {
                                if (UpLogo.length > 0) {
                                    setUpLogo('');
                                } else {
                                    upLogoRef.current.click();
                                }
                            }}>
                                {UpLogo.length > 0 ? (
                                    <>
                                        <img src={UpLogo} style={{ height: 100, width: 'auto', objectFit: 'contain', cursor: 'pointer' }} onClick={() => { setUpLogo('') }}></img>
                                    </>
                                ) : (
                                    <>
                                        <img src={images.Upload} style={{ height: 32, width: 32, objectFit: 'contain' }}></img>
                                        <p style={{ fontSize: 16, fontWeight: 400, color: colors.greys04, padding: 0, margin: 0, paddingBlock: 16 }}>
                                            {'Upload logo here'}
                                        </p>
                                    </>
                                )}
                            </div>


                            <div id='QRContainer' className='d-flex flex-column justify-content-evenly align-items-center my-sm-3 my-2' style={{ marginBlock: 16, height: 300, width: 260, backgroundColor: colors.white, border: `1px solid ${colors.borderwhite}`, borderRadius: 16 }}>
                                <QRCode
                                    id='QRCodeCheerio'
                                    value={waLink}
                                    logoImage={UpLogo}
                                    size={200}
                                    logoWidth={100}
                                    logoHeight={'auto'}
                                    logoOpacity={0.35}
                                    bgColor={'transparent'}
                                />
                                <div className='d-flex flex-row justify-content-center align-items-center'>
                                    <span style={{ fontSize: 16, fontWeight: 400, color: colors.greys04, padding: 0, margin: 0, paddingBlock: 16 }}>
                                        {'Powered by '}
                                    </span>
                                    <img src={images.HomeLogo} style={{ height: 16, width: 16, objectFit: 'contain', marginInline: 4 }} />
                                    <span style={{ fontSize: 16, fontWeight: 400, color: colors.greys04, padding: 0, margin: 0, paddingBlock: 16 }}>
                                        {' Cheerio'}
                                    </span>
                                </div>
                            </div>


                            <input
                                type='file'
                                style={{ display: 'none' }}
                                ref={upLogoRef}
                                accept='image/png image/jpeg image/jpg'
                                onChange={(e) => handleLogoUpload(e)}
                            />


                        </div>

                        <div className='d-flex d-sm-none flex-column col-lg-4 col-sm-6 col-12 justify-content-start justify-content-sm-between align-items-center' style={{ maxHeight: 540, height: ScreenWidth > 576 ? 540 : 'auto', alignSelf: 'center' }}>





                            <CheerioButton
                                borderStyle={{ borderWidth: 0, marginBlock: 16, paddingInline: 30, paddingBlock: 8 }}
                                textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
                                btnText={'Download QR'}
                                backColor={colors.primary03}
                                icon={images.downloadWhite}
                                onclick={() => { downloadQR() }}
                            />


                            <p className='my-sm-3 my-2' style={{ fontSize: 16, fontWeight: 600, color: colors.linkblue, padding: 0, margin: 0, paddingBlock: 16, cursor: 'pointer', textAlign: 'center' }} onClick={() => {
                                window.open('https://app.cheerio.in/', '_blank')
                            }}>
                                {'Signup to Cheerio'}<span style={{ color: colors.black }}>{' for link tracking!'}</span>
                            </p>

                        </div>
                        {/* <div className='d-flex flex-column justify-content-between align-items-center w-50 h-100' style={{paddingInline:16}}>
                    <div className='d-flex flex-column justify-content-between align-items-center w-100'>
                        <h2 style={{fontSize:20,fontWeight:700,color:colors.black,padding:0,margin:0,width:'100%'}}>
                            {'Here is your WhatsApp link'}
                        </h2>
                        <p style={{fontSize:16,fontWeight:400,color:colors.greys04,padding:0,margin:0,paddingBlock:16,width:'100%'}}>
                            {'Copy & Share it on your social media, website or anywhere you want'}
                        </p>
                    </div>
                    <div className='d-flex flex-column justify-content-between align-items-center w-100'>
                        <div className='d-flex flex-row justify-content-start align-items-center w-100'>
                            <span id='WALink' style={{fontSize:16,fontWeight:400,color:colors.linkblue,padding:0,margin:0,paddingBlock:16}}>
                                {waLink ? waLink : 'your link will appear here'}
                            </span>
                            <img src={images.copyBlue} style={{height:20,width:20,objectFit:'contain',marginInline:16,cursor:'pointer'}} onClick={()=>{copyLinkToClipboard()}}></img>
                        </div>
                        
                        <CheerioButton 
                            borderStyle={{borderWidth:0,marginBlock:16,marginInlineEnd:'auto',width:'50%'}}
                            textStyle={{fontSize:16,fontWeight:600,color:colors.white}}
                            btnText={'Download QR'}
                            backColor={colors.primary03}
                            icon={images.downloadWhite}
                            onclick={()=>{downloadQR()}}
                        />
                    </div>
                    <p style={{fontSize:16,fontWeight:600,color:colors.linkblue,padding:0,margin:0,paddingBlock:16,width:'100%',cursor:'pointer'}} onClick={()=>{
                        window.open('https://app.cheerio.in/','_blank')
                    }}>
                        {'Signup to Cheerio'}<span style={{color:colors.black}}>{' for link tracking!'}</span>
                    </p>
                </div>

                <div className='d-flex flex-column justify-content-between align-items-center w-50 h-100' style={{paddingInline:16}}>
                    <h2 style={{fontSize:20,fontWeight:700,color:colors.black,padding:0,margin:0}}>
                        {'Customize your QR Code'}
                    </h2>

                    <div className='d-flex flex-column justify-content-between align-items-center w-100' style={{height:100,cursor:'pointer'}} onClick={()=>{
                        if(UpLogo.length > 0){
                            setUpLogo('');
                        }else{
                            upLogoRef.current.click();
                        }
                    }}>
                        {UpLogo.length > 0 ? (
                            <>
                            <img src={UpLogo} style={{height:100,width:'auto',objectFit:'contain',cursor:'pointer'}} onClick={()=>{setUpLogo('')}}></img>
                            </>
                        ):(
                            <>
                            <img src={images.Upload} style={{height:32,width:32,objectFit:'contain'}}></img>
                            <p style={{fontSize:16,fontWeight:400,color:colors.greys04,padding:0,margin:0,paddingBlock:16}}>
                                {'Upload logo here'}
                            </p>
                            </>
                        )}
                    </div>

                    <div id='QRContainer' className='d-flex flex-column justify-content-evenly align-items-center' style={{marginBlock:16,height:300,width:260,backgroundColor:colors.white,border:`1px solid ${colors.borderwhite}`,borderRadius:16}}>
                        <QRCode
                            id='QRCodeCheerio'
                            value={waLink}
                            logoImage={UpLogo}
                            size={200}
                            logoWidth={100}
                            logoHeight={'auto'}
                            logoOpacity={0.35}
                            bgColor={'transparent'}
                        />
                        <div className='d-flex flex-row justify-content-center align-items-center'>
                            <span style={{fontSize:16,fontWeight:400,color:colors.greys04,padding:0,margin:0,paddingBlock:16}}>
                                {'Powered by '}
                            </span>
                            <img src={images.HomeLogo} style={{height:16,width:16,objectFit:'contain',marginInline:4}}/>
                            <span style={{fontSize:16,fontWeight:400,color:colors.greys04,padding:0,margin:0,paddingBlock:16}}>
                                {' Cheerio'}
                            </span>
                        </div>
                    </div>
                    <input 
                        type='file'
                        style={{display: 'none'}}
                        ref={upLogoRef}
                        accept='image/png image/jpeg image/jpg'
                        onChange={(e)=>handleLogoUpload(e)}
                    />
                </div> */}
                    </>
                )}
            </div>
        </>
    )
}

export default WixQRCode