import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserApi, sendBroadCast } from '../../../Services';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { localeData } from 'moment';
import CheerioButton from '../../../Components/CheerioButton';
import { eventsend } from '../../../Config/analyticsFunctions';
import { ValidateEmail } from '../../Chatbot/Setupbot';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { handleTrackEvent } from '../../../Utils/brevo';
import { Modal, ModalBody } from 'react-bootstrap';
const validatePhone = (number) => {
  if (number.length >= 10) {
    return true;
  } else {
    toast.error('Invalid phone number', { position: 'top-center' });
    return false;
  }
};

const BroadcastNew = () => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [sent, setSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState('WhatsApp message');
  const [broadCastData, setBroadCastData] = useState({
    recieverName: '',
    email: '',
    phoneNumber: '',
  });

  const BroadcastModal = () => {
    return (
      <div className="ModalDarkBg">
        <div className="Center">
          <div className="announcement_modal">
            <div
              className="d-flex flex-row justify-content-between align-items-center w-100"
              style={{ paddingBlock: '16px', borderBottom: '1px solid rgb(230,230,230)' }}
            >
              <div className="d-flex flex-row justify-content-center align-items-center">
                <h2
                  style={{
                    padding: 0,
                    margin: 0,
                    fontWeight: 700,
                    color: colors.black,
                    marginInline: 16,
                  }}
                >
                  Try Broadcasting
                </h2>
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <div
                  className="d-flex flex-row justify-content-center align-items-center"
                  id="ShareCampaignButton"
                  style={{
                    paddingInline: 16,
                    paddingBlock: 8,
                    border: `1px solid ${colors.primary03}`,
                    borderRadius: 8,
                    marginInlineEnd: 16,
                    backgroundColor: colors.primary03,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleTrackEvent('dashboard_try_broadcasting_button_clicked', localdata);
                    eventsend('try_broadcasting_button_clicked_web', { data: broadCastData });
                    if (
                      ValidateEmail(broadCastData.email) &&
                      validatePhone(broadCastData.phoneNumber)
                    ) {
                      sendBroadCastApiCall();
                      setIsLoading(true);
                    }
                    setOpenModal(false);
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 14,
                      fontWeight: 600,
                      color: colors.white,
                      marginInlineStart: 8,
                    }}
                  >
                    {'Send messages'}
                  </p>
                </div>
                <img
                  src={images.CMClose}
                  style={{
                    height: 32,
                    width: 32,
                    objectFit: 'contain',
                    marginInline: 4,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setOpenModal(false);
                  }}
                />
              </div>
            </div>
            <div
              className="d-flex flex-column justify-content-start align-items-center w-100 verythinscrollnew"
              style={{ flex: '1 1 0%', overflowY: 'scroll' }}
            >
              <div className="d-flex flex-column justify-content-between align-items-center w-100">
                <p style={{ padding: '10px', fontSize: '18px', marginTop: '0%' }}>
                  You will receive this Email, SMS & WhatsApp. With Cheerio you will be able to
                  share this of messages to your customers
                </p>
                <h2 style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  {`${selectedTab} Preview`}
                </h2>
              </div>
              <div
                className="d-flex flex-row justify-content-between align-items-center w-100"
                style={{ paddingInline: '60px' }}
              >
                <img
                  src={images.DownArrowTip}
                  style={{
                    height: '24px',
                    width: '24px',
                    objectFit: 'contain',
                    transform: 'rotate(90deg)',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (selectedTab == 'Email') {
                      setSelectedTab('SMS');
                    } else if (selectedTab == 'WhatsApp message') {
                      setSelectedTab('Email');
                    } else if (selectedTab == 'SMS') {
                      setSelectedTab('WhatsApp message');
                    }
                  }}
                />
                <div
                  className="d-flex flex-column justify-content-start align-items-center"
                  style={{ width: '50%' }}
                >
                  {selectedTab === 'WhatsApp message' && (
                    <div
                      className="d-flex flex-column justify-content-start align-items-start my-2 py-3"
                      style={{
                        width: '80%',
                        borderRadius: '16px',
                        height: 'auto',
                        backgroundSize: 'cover',
                        backgroundImage: `url(${images.CAWABack})`,
                        backgroundRepeat: 'no-repeat',
                      }}
                    >
                      <img
                        src={images.WAPreviewMessage}
                        alt="WhatsApp Preview message"
                        className="px-3"
                        style={{
                          width: '80%',
                          height: 'auto',
                          objectFit: 'contain',
                          borderRadius: '16px',
                          marginBottom: '60px',
                        }}
                      />
                    </div>
                  )}

                  {selectedTab === 'Email' && (
                    <div
                      className="d-flex flex-column justify-content-start align-items-start my-2 py-3"
                      style={{
                        width: '80%',
                        borderRadius: '16px',
                        height: 'auto',
                      }}
                    >
                      <img
                        src={images.EmailPreview}
                        alt="Email Preview"
                        className="px-3"
                        style={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'contain',
                          borderRadius: '16px',
                          marginBottom: '60px',
                        }}
                      />
                    </div>
                  )}

                  {selectedTab === 'SMS' && (
                    <div
                      className="d-flex flex-column justify-content-start align-items-start"
                      style={{
                        width: '100%',
                        borderRadius: '16px',
                        height: 'auto',
                      }}
                    >
                      <img
                        src={images.SMSPreview}
                        alt="SMS Preview"
                        className="px-3"
                        style={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'contain',
                          borderRadius: '16px',
                          marginBottom: '60px',
                        }}
                      />
                    </div>
                  )}
                </div>
                <img
                  src={images.DownArrowTip}
                  style={{
                    height: '24px',
                    width: '24px',
                    objectFit: 'contain',
                    transform: 'rotate(-90deg)',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (selectedTab == 'Email') {
                      setSelectedTab('WhatsApp message');
                    } else if (selectedTab == 'WhatsApp message') {
                      setSelectedTab('SMS');
                    } else if (selectedTab == 'SMS') {
                      setSelectedTab('Email');
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getuserapicall = () => {
    let token = authtoken;
    getUserApi(token).then((res) => {
      console.log('get user api response ==> ', res);
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
      } else {
        console.log('error get user api ===> ', res);
      }
    });
  };

  const sendBroadCastApiCall = () => {
    toast.info('Test message is being sent', {
      toastId: 1,
      position: 'top-center',

    });
    sendBroadCast(broadCastData, authtoken).then((res) => {
      if (res?.flag) {
        getuserapicall();
        toast.dismiss(1);
        toast.success('Message Sent', { position: 'top-center' });
        setIsLoading(false);

        setSent(true);
      } else {
        toast.error(res?.message, { position: 'top-center' });
        setSent(false);
        toast.dismiss(1);

        setIsLoading(false);
      }
    });
  };

  const InputDisabled =
    localdata?.balance?.sms === 0 &&
    localdata?.balance?.email === 0 &&
    localdata?.balance?.whatsapp === 0;
  const BtnDisabled =
    broadCastData.recieverName !== '' &&
    broadCastData.email !== '' &&
    broadCastData.phoneNumber !== '';

  return (
    <>
      <div className="test_container tile">
        <div className="test_container__left">
          <h3>{'Try sending a test campaign'}</h3>
          <p className="my-1">
            {'Test our broadcasting feature to send email, sms, whatsapp in one go'}
          </p>
          <h4 className="my-2">{'Test business details:'}</h4>
          <p>
            {'Email: admin@cheerio.in'}
            <br />
            {'Phone number: +91 80085 51357'}
          </p>
          <h4 className="my-2">
            {'Account: '}
            <span>{'Test mode'}</span>
          </h4>
          <CheerioButton
            borderStyle={{
              marginBlockStart: 8,
              border: '1px solid black',
            }}
            textStyle={{
              color: colors.black,
            }}
            btnText={'Schedule LIVE Demo'}
            backColor={colors.white01}
            icon={images.Calendar}
            onclick={() => {
              handleTrackEvent('dashboard_schedule_live_demo_button_clicked', localdata);
              console.log('schedule demo clicked . . .');
              window.open('https://calendly.com/avinash-cheerio/30min', '_blank');
            }}
          />
        </div>
        <div className="test_container__right">
          <>
            <InputGroup className="mb-2" style={{ width: '90%' }}>
              <Form.Control
                type="text"
                placeholder="Name"
                className="w-100 btncustom"
                style={{ borderRadius: 8 }}
                value={broadCastData.recieverName}
                onChange={(e) => {
                  setBroadCastData((prev) => ({
                    ...prev,
                    recieverName: e.target.value,
                  }));
                }}
                disabled={InputDisabled ? true : false}
              />
            </InputGroup>
            <InputGroup className="mb-2" style={{ width: '90%' }}>
              <Form.Control
                type="email"
                placeholder="Email Id"
                className="w-100 btncustom"
                style={{ borderRadius: 8 }}
                value={broadCastData.email}
                onChange={(e) => {
                  setBroadCastData((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
                disabled={InputDisabled ? true : false}
              />
            </InputGroup>
            <InputGroup className="mb-2" style={{ width: '90%' }}>
              <Form.Control
                type="phone"
                placeholder="Phone no"
                className="w-100 btncustom"
                style={{ borderRadius: 8 }}
                value={broadCastData.phoneNumber}
                onChange={(e) => {
                  setBroadCastData((prev) => ({
                    ...prev,
                    phoneNumber: e.target.value,
                  }));
                }}
                disabled={InputDisabled ? true : false}
              />
            </InputGroup>
          </>
          {InputDisabled && (
            <p className="my-1" style={{ width: '100%', color: colors.error03 }}>
              {
                'You have exhausted all free trial credits. Please get a plan to continue using Cheerio CRM'
              }
            </p>
          )}

          <CheerioButton
            borderStyle={{
              width: '90%',
              marginBlockStart: 10,
              border: '1px solid black',
            }}
            textStyle={{
              color: colors.white01,
            }}
            btnText={'Try Broadcasting'}
            backColor={colors.black}
            icon={images.CampaignWhite}
            disabled={BtnDisabled ? false : true}
            onclick={() => {
              setOpenModal(true);
            }}
          />
          {openModal && <BroadcastModal></BroadcastModal>}
        </div>
      </div>
    </>
  );
};

export default BroadcastNew;
