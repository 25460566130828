import React, { useEffect, useRef, useState } from 'react';
import Home from './Components/Home';
import Builder from './Components/Builder';
import AnnNewModal from '../AnnouncementsNew/Components/AnnNewModal';
import { PremiumOptionModal } from '../../Components/Modal/PremiumOptionModal';
import { useSelector } from 'react-redux';
import { eventsend } from '../../Config/analyticsFunctions';

const EmailBuilder = () => {
  const [currentScreen, setCurrentScreen] = useState('Home');
  const [selectedJSON, setSelectedJSON] = useState('');
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;
  const [isFTUser, setIsFTUser] = useState(false);

  const getRemainingDays = (e) => {
    const d = new Date(e);
    const date = new Date();
    let dd = d.toDateString();
    let cd = date.toDateString();
    let diff = Math.floor((Date.parse(dd) - Date.parse(cd)) / 86400000);
    return diff;
  };

  const remainingDays = getRemainingDays(localdata?.free_trail?.end_date);
  useEffect(() => {
    if (remainingDays > 0) {
      setIsFTUser(true);
    }
  }, [localdata]);

  useEffect(() => {
    eventsend('email_builder_pageview_web');
  }, []);

  return (
    <>
      {!isFTUser && !isPremiumUser && (
        <PremiumOptionModal
          option={'Email Builder'}
          openPremiumModal={true}
          setOpenPremiumModal={() => {}}
        ></PremiumOptionModal>
      )}
      <div className="w-100 h-100" style={{ paddingInline: 0, paddingBlock: 20 }}>
        {currentScreen === 'Home' && (
          <Home setCurrentScreen={setCurrentScreen} setSelectedJSON={setSelectedJSON} />
        )}
        {currentScreen === 'Builder' && (
          <Builder
            setCurrentScreen={setCurrentScreen}
            selectedJSON={selectedJSON}
            setSelectedJSON={setSelectedJSON}
          />
        )}
      </div>
    </>
  );
};

export default EmailBuilder;
