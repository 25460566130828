import React, { useRef, useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { getImagePath } from '../../../Utils';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import UploaderText from '../../../Components/UploaderText';
import { Autocomplete, TextField } from '@mui/material';

const CarouselCardsComponent = ({
  data,
  index,
  carouselData,
  handlefileupload,
  setbodyparams,
  setanncta,
  existingData,
  workFlow = false,
  attributesList,
}) => {
  useEffect(() => {
    LoadDynamicFields();
    checkURL();
  }, []);
  const [count, setCount] = useState();
  const [inputFields, setInputFields] = useState([]);
  const [mcta, setMcta] = useState([]);
  const [urlExists, setUrlExists] = useState(false);
  const [UFields, setUfields] = useState(false);
  const [countURL, setCountURL] = useState();

  const upDocRef = useRef();
  const upImageRef = useRef();
  const upVideoRef = useRef();

  const cHeader = data?.components?.filter((e) => e?.type === 'HEADER');
  const cBody = data?.components?.filter((e) => e?.type === 'BODY');
  const cButton = data?.components?.filter((e) => e?.type === 'BUTTONS');

  const LoadDynamicFields = () => {
    const variableRegex = /\{+\d+\}+/g;
    if (variableRegex.test(cBody[0].text)) {
      const count = cBody[0]?.text?.match(variableRegex).length;
      console.log('count ======>', count);
      setCount(count);
    }
  };

  const setExistingURL = () => {
    if (mcta && !UFields) {
      const tmcta = mcta;
      for (let i = 0; i < mcta?.length; i++) {
        tmcta[i].value = existingData?.carouselCards[index]?.cta[i]?.value;
      }

      setMcta(tmcta);
    }
    setUfields(true);
  };

  const checkURL = () => {
    const url = cButton[0]?.buttons?.filter((e) => {
      return (
        (e?.type === 'URL' && e?.example) ||
        (e?.type === 'COPY_CODE' && e?.example) ||
        e?.type === 'FLOW' ||
        e?.type === 'CATALOG'
      );
    });
    console.log('00000000000000 ', cButton[0]);
    setCountURL(url?.length);
    if (url?.length > 0) {
      setUrlExists(true);
      for (let i = 0; i < url?.length; i++) {
        setMcta((prev) => {
          const tempmcta = [...prev];
          tempmcta[i] = {
            index: cButton[0]?.buttons.indexOf(url[i]),
            type:
              url[i]?.type === 'URL'
                ? 'url'
                : url[i]?.type === 'CATALOG'
                ? 'catalog'
                : url[i]?.type === 'COPY_CODE'
                ? 'copy_code'
                : url[i]?.type === 'FLOW'
                ? 'flow'
                : '',
            value: '',
          };
          return tempmcta;
        });
      }
    } else {
      setMcta([]);
    }
  };

  useEffect(() => {
    if (count > 0) {
      if (
        existingData?.carouselCards &&
        existingData?.carouselCards[index]?.bodyParameters?.length > 0
      ) {
        for (let i = 0; i < count; i++) {
          setInputFields((prev) => {
            const tempArr = [...prev];
            tempArr.push({ variable: existingData?.carouselCards[index]?.bodyParameters[i] });
            return tempArr;
          });
        }
      } else {
        for (let i = 0; i < count; i++) {
          setInputFields((prev) => {
            const tempArr = [...prev];
            tempArr.push({ variable: `{{${i + 1}}}` });
            return tempArr;
          });
        }
      }
    }
  }, [count]);

  useEffect(() => {
    if (inputFields) {
      console.log('ififififif ', inputFields);
      setbodyparams(
        inputFields.map((a) => a.variable),
        index
      );
    }
  }, [inputFields]);

  useEffect(() => {
    if (
      mcta.length === countURL &&
      existingData?.carouselCards &&
      existingData?.carouselCards[index]?.cta?.length > 0
    ) {
      setExistingURL();
    }
    setanncta(mcta, index);
  }, [mcta]);

  const handleChange = (index, e) => {
    const tempObj = [...inputFields];
    tempObj[index][e.target.name] = e.target.value;
    setInputFields(tempObj);
  };

  return (
    <>
      <div
        className="w-100 d-flex flex-column justify-content-start align-items-center py-3"
        style={{ borderTop: `1px solid ${colors.borderwhite}` }}
      >
        {cHeader && (
          <>
            <h4
              style={{
                fontSize: 18,
                fontWeight: 700,
                color: colors.black,
                marginBlockEnd: 16,
                padding: 0,
                width: '100%',
              }}
            >
              {`Card ${index + 1}`}
            </h4>
            {cHeader[0]?.format !== 'TEXT' ? (
              <>
                <h4
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: colors.black,
                    marginBlockEnd: 16,
                    padding: 0,
                    width: '100%',
                  }}
                >
                  {'Upload file'}
                </h4>
                <div
                  className="d-flex flex-column justify-content-evenly align-items-center"
                  style={{
                    width: '100%',
                    border: `1px solid ${colors.greys03}`,
                    borderRadius: 16,
                    cursor: 'pointer',
                    height: 200,
                    backgroundColor: colors.white02,
                  }}
                  onClick={() => {
                    // console.log("cHeader[0] format ---> ",cHeader[0])
                    if (cHeader[0]?.format === 'IMAGE') {
                      console.log('image clicked...', index);
                      upImageRef.current.click();
                    } else if (cHeader[0]?.format === 'VIDEO') {
                      upVideoRef.current.click();
                    } else if (cHeader[0]?.format === 'DOCUMENT') {
                      upDocRef.current.click();
                    }
                  }}
                >
                  {carouselData[index]?.header?.link ? (
                    <>
                      {cHeader[0]?.format === 'IMAGE' ? (
                        <>
                          <img
                            src={getImagePath(carouselData[index]?.header?.link)}
                            style={{ height: 80, width: 120, objectFit: 'contain' }}
                          ></img>
                          <p
                            style={{
                              fontSize: 12,
                              fontWeight: 600,
                              color: colors.black,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              wordBreak: 'break-all',
                            }}
                          >
                            {carouselData[index]?.header?.fileName}
                            <img
                              src={images.Cross}
                              style={{
                                height: 18,
                                width: 18,
                                marginInlineStart: 20,
                                cursor: 'pointer',
                              }}
                            ></img>
                          </p>
                        </>
                      ) : cHeader[0]?.format === 'VIDEO' ? (
                        <>
                          <video
                            src={getImagePath(carouselData[index]?.header?.link)}
                            style={{ height: 80, width: 120, objectFit: 'contain' }}
                          ></video>
                          <p
                            style={{
                              fontSize: 12,
                              fontWeight: 600,
                              color: colors.black,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              wordBreak: 'break-all',
                            }}
                          >
                            {carouselData[index]?.header?.fileName}
                            <img
                              src={images.Cross}
                              style={{
                                height: 18,
                                width: 18,
                                marginInlineStart: 20,
                                cursor: 'pointer',
                              }}
                            ></img>
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <img
                        src={images.ImgUpIcon}
                        style={{ height: 80, width: 120, objectFit: 'contain' }}
                      ></img>
                      <UploaderText
                        type={cHeader[0]?.format}
                        textStyle={{
                          fontSize: 12,
                          fontWeight: 600,
                          color: colors.black,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          wordBreak: 'break-all',
                          textAlign: 'center',
                        }}
                      />
                      {/* <p style={{ fontSize: 12, fontWeight: 600, color: colors.black,textAlign:'center' }}>
                          {'Upload file here'}
                        </p> */}
                    </>
                  )}
                </div>
                <input
                  type="file"
                  id="DocUpload"
                  style={{ display: 'none' }}
                  ref={upDocRef}
                  accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.pdfx,.csv"
                  onChange={(e) => {
                    handlefileupload(e, index, cHeader[0]?.format);
                  }}
                />
                <input
                  type="file"
                  id="ImageUpload"
                  style={{ display: 'none' }}
                  ref={upImageRef}
                  accept="image/*"
                  onChange={(e) => {
                    handlefileupload(e, index, cHeader[0]?.format);
                  }}
                />
                <input
                  type="file"
                  id="VideoUpload"
                  style={{ display: 'none' }}
                  ref={upVideoRef}
                  accept="video/*"
                  onChange={(e) => {
                    handlefileupload(e, index, cHeader[0]?.format);
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </>
        )}

        {/* Body Element */}
        {inputFields &&
          inputFields.map((item, index) => {
            return (
              <>
                <InputGroup className="mb-3" key={index}>
                  <Form.Label
                    style={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                  >{`Variable ${index + 1}`}</Form.Label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    style={{
                      marginBottom: '5px',
                    }}
                    className="w-100 btncustom"
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        name="variable"
                        size={'small'}
                        placeholder={'Type/Select'}
                        className={'restylingLabels'}
                        InputProps={{
                          notched: false,
                          style: {
                            borderRadius: 4,
                          },
                        }}
                        {...params}
                      />
                    )}
                    value={item.variable}
                    onInputChange={(e, newValue) => {
                      setInputFields((prev) => {
                        const tempObj = [...prev];
                        tempObj[index].variable = newValue;
                        return tempObj;
                      });
                    }}
                    onChange={(e, newValue) => {
                      if (workFlow) {
                        setInputFields((prev) => {
                          const tempObj = [...prev];

                          tempObj[index].variable = newValue ? `%${newValue}%` : '';
                          return tempObj;
                        });
                      } else {
                        setInputFields((prev) => {
                          const tempObj = [...prev];

                          tempObj[index].variable = newValue ? `%${newValue}%` : '';
                          return tempObj;
                        });
                      }
                    }}
                    freeSolo={true}
                    options={workFlow ? Object.values(attributesList) : attributesList}
                    getOptionLabel={(option) => {
                      if (workFlow) {
                        return `${option}`;
                      } else {
                        if (typeof option == 'object') {
                          return `${option.key}`;
                        } else {
                          return `${option}`;
                        }
                      }
                    }}
                  />
                  {/* <Form.Control
                    type="text"
                    placeholder="Enter here"
                    className="w-100 btncustom"
                    name="variable"
                    style={{ borderRadius: 8 }}
                    value={item.variable}
                    onChange={(e) => {
                      handleChange(index, e);
                    }}
                  /> */}
                </InputGroup>
              </>
            );
          })}

        {/* URL Element */}
        {urlExists &&
          cButton[0]?.buttons
            ?.filter((e) => {
              return (e?.type === 'URL' && e?.example) || (e?.type === 'COPY_CODE' && e?.example);
            })
            .map((item, index) => {
              return (
                <>
                  <InputGroup className="mb-3" key={`key_${index}`}>
                    <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                      {item?.type === 'COPY_CODE'
                        ? `${item?.text}`
                        : `Call to Action URL ${index + 1}`}
                    </Form.Label>
                    {item?.type === 'URL' && (
                      <p>
                        Copy pasting the URL may not work. Please enter the URL you want to use.
                      </p>
                    )}

                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      style={{
                        marginBottom: '5px',
                      }}
                      size="small"
                      className="w-100 btncustom"
                      renderInput={(params) => (
                        <TextField
                          name="variable"
                          size={'small'}
                          placeholder={
                            item?.type === 'COPY_CODE' ? 'Enter code here' : 'Enter link here'
                          }
                          className={'restylingLabels'}
                          InputProps={{
                            notched: false,
                            style: {
                              borderRadius: 4,
                            },
                          }}
                          {...params}
                        />
                      )}
                      value={mcta[index].value}
                      onInputChange={(e, newValue) => {
                        setMcta((prev) => {
                          const newmcta = [...prev];
                          newmcta[index].value = newValue;
                          return newmcta;
                        });
                      }}
                      onChange={(e, newValue) => {
                        setMcta((prev) => {
                          const newmcta = [...prev];
                          newmcta[index].value = newValue ? `${newValue}` : '';
                          return newmcta;
                        });
                      }}
                      freeSolo={true}
                      options={
                        workFlow
                          ? Object.values(attributesList).map((val) => `%${val}%`)
                          : Object.keys(attributesList)
                              .map((key) => key)
                              .filter((option) => attributesList[option] !== undefined)
                      }
                    />

                    {/* <Form.Control
                      type="text"
                      placeholder={
                        item?.type === 'COPY_CODE' ? 'Enter code here' : 'Enter link here'
                      }
                      className="w-100 btncustom"
                      style={{ borderRadius: 8 }}
                      value={mcta[index].value}
                      onChange={(e) => {
                        setMcta((prev) => {
                          const newmcta = [...prev];
                          newmcta[index].value = e.target.value;
                          return newmcta;
                        });
                      }}
                    /> */}
                  </InputGroup>
                </>
              );
            })}
      </div>
    </>
  );
};

export default CarouselCardsComponent;
