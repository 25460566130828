import React from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import './styles.css';

const SidemenuOpenAi = ({
    openSetup,
    setOpenSetup,
    selectedMenuItem,
    setSelectedMenuItem,
}) => {
    const MenuSubItem = ({ title }) => {
        return (
            <>
                <div
                    className="Row justify-content-start w-100"
                    style={{
                        paddingBlock: 8,
                        paddingInlineStart: 24,
                        marginBlock: 4,
                        backgroundColor: title == selectedMenuItem ? '#F5F0FF' : '',
                        cursor: 'pointer',
                        borderRadius: 8,
                    }}
                    onClick={() => {
                        setSelectedMenuItem(title);
                    }}
                >
                    <p
                        style={{
                            padding: 0,
                            margin: 0,
                            marginInline: 8,
                            fontSize: 14,
                            fontWeight: 600,
                            color: title == selectedMenuItem ? colors.primary03 : colors.darkgrey,
                        }}
                    >
                        {title}
                    </p>
                </div>
            </>
        );
    };

    return (
        <>
            <div
                className="d-flex flex-column justify-content-start align-items-center h-100"
                style={{
                    width: 220,
                    backgroundColor: colors.white02,
                    border: `1px solid ${colors.borderwhite}`,
                }}
            >
                <div
                    className="d-flex flex-row justify-content-between align-items-center w-100"
                    style={{ marginBlock: 8, paddingBlock: 8, paddingInline: 16 }}
                >
                    <div
                        className="d-flex flex-row justify-content-start align-items-center"
                        style={{ paddingInline: 8 }}
                    >
                        <img src={images.SCog} style={{ height: 24, width: 24, objectFit: 'contain' }}></img>
                        <p
                            style={{
                                padding: 0,
                                margin: 0,
                                marginInline: 8,
                                fontSize: 14,
                                fontWeight: 600,
                                color: colors.black,
                            }}
                        >
                            {'Setup'}
                        </p>
                    </div>
                    <img
                        src={images.DownArrowTip}
                        style={{
                            height: 24,
                            width: 24,
                            objectFit: 'contain',
                            cursor: 'pointer',
                            transform: openSetup ? 'rotate(180deg)' : '',
                        }}
                        onClick={() => {
                            setOpenSetup(!openSetup);
                        }}
                    ></img>
                </div>
                <div
                    className="d-flex flex-column justify-content-start align-items-center w-100"
                    style={{ paddingInline: 16 }}
                >
                    {openSetup ? (
                        <>
                            <MenuSubItem title={'Integration'} />
                            <MenuSubItem title={'Assistants'} />
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
};

export default SidemenuOpenAi;
