import React, { useState } from 'react';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import colors from '../../../Utils/colors';
import { LinkNewPartnerAccount } from '../../../Services';
import { toast } from 'react-toastify';

const AddNewAccountModal = ({ setIsModalOpen, token, closerefreshaction }) => {
  const [facebookId, setFacebookId] = useState('');
  const [password, setPassword] = useState('');

  const linkNewPartnerAccountAPICall = () => {
    let data = {
      facebookId: facebookId,
      password: password,
    };
    LinkNewPartnerAccount(token, data).then((res) => {
      if (res?.flag) {
        toast.success('Successfully added new account!', { position: 'top-right' });
        // console.log("success linked")
      } else {
        toast.error('Failed to add new account, please try again later', { position: 'top-right' });
        // console.log("failure linked")
      }
      closerefreshaction();
    });
  };

  return (
    <>
      <div
        className="DarkBg"
        onClick={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="Center">
          <div
            className="ANA_modal_container"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="ANA_modal_container__header">
              <h3>{'Add new account'}</h3>
              <img
                src={images.CMClose}
                style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}
                onClick={() => {
                  setIsModalOpen(false);
                }}
              ></img>
            </div>
            <div className="ANA_modal_container__body">
              <InputGroup className="my-3">
                <Form.Label className="form_heading_style">{'Email / Contact'}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter here"
                  className="w-100 btncustom"
                  style={{ borderRadius: 8 }}
                  value={facebookId}
                  onChange={(e) => {
                    setFacebookId(e.target.value);
                  }}
                />
              </InputGroup>
              <InputGroup className="my-3">
                <Form.Label className="form_heading_style">{'Password'}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  className="w-100 btncustom"
                  style={{ borderRadius: 8 }}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </InputGroup>
              <p>{'Cheerio account password'}</p>
            </div>
            <CheerioButton
              borderStyle={{
                borderColor: colors.black,
                width: '100%',
                paddingBlock: 10,
              }}
              textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white01 }}
              btnText={'Add account'}
              backColor={colors.black}
              disabled={facebookId && password ? false : true}
              onclick={() => {
                console.log('add account clicked');
                linkNewPartnerAccountAPICall();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewAccountModal;
