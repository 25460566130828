import React, { useEffect, useState } from 'react';
import colors from '../../Utils/colors';

import './styles.css';
import styles from './styles.module.css';
import CheerioButton from '../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import { generatePayment, updateCredits } from '../../Services';
import { Modal } from 'react-bootstrap';
import images from '../../Utils/images';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Plans = () => {
  let emails = 0;
  let price = 0;
  let messages = 0;
  let mprice = 0;
  let wmessages = 0;
  let wprice = 0;

  const navigate = useNavigate();

  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);

  const [mails, setMails] = useState(0);
  const [Price, setPrice] = useState(0);
  const [sms, setSms] = useState(0);
  const [sPrice, setSprice] = useState(0);
  const [Wmsg, setwMsg] = useState(0);
  const [wMprice, setWmprice] = useState(0);
  const [key, setkey] = useState('-1');
  const [button1, setButton1] = useState(true);
  const [button2, setButton2] = useState(true);
  const [button3, setButton3] = useState(true);
  const [show, setShow] = useState(false);
  const [paid, setPaid] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  let gst = (Price + wMprice + sPrice) * 0.18;
  const total = Price + wMprice + sPrice + gst;
  const updateCreditApiCall = () => {
    if (paid) {
      let data = { user_id: _id, sms_balance: sms, whatsapp_balance: Wmsg, email_balance: mails };

      let token = authtoken;
      updateCredits(data, token).then((res) => {
        //console.log("get user api response ==> ", res);
        if (res.flag) {
          console.log('updated successfully');
        } else {
          console.log('error get user api ===> ', res);
        }
      });
    }
  };

  const generatePaymentApiCall = () => {
    let data = { amount: total, currency_type: 'INR', access_code: '2314dsaft5', type: 'web' };

    let token = authtoken;
    generatePayment(data, token).then((res) => {
      console.log('get user api response ==> ', res);
      if (res.flag) {
        window.location.replace(res.data?.PaidLink.short_url);
      } else {
        console.log('error get user api ===> ', res);
      }
    });
  };

  // const paidLinkStatusApiCall = ()=>{
  //   let data = {
  //     paid_link_id:paidLinkId,
  //   }

  //   let token = authtoken;
  //   paidLinkStatus(data,token).then((res)=>{
  //     console.log("get user api response ==> ", res);
  //     if (res.flag) {
  //       console.log("payment done");
  //       setPaid(true);
  //       setShow(true);
  //       console.log(paidLinkId)
  //     } else {
  //       console.log("error get user api ===> ", res);
  //     }
  //   });
  // }

  const successfulPayment = () => {
    setPaid(true);
    setShow(true);
    updateCreditApiCall();
  };

  useEffect(() => {
    divClick();
  }, [key]);

  useEffect(() => {
    const check = searchParams.get('razorpay_payment_link_status');
    if (check == 'paid') {
      successfulPayment();
    }
  });

  const divClick = () => {
    if (key >= 1 && key <= 4) {
      setButton1(false);
      setButton2(true);
      setButton3(true);
    } else if (key >= 5 && key <= 8) {
      setButton1(true);
      setButton2(false);
      setButton3(true);
    } else if (key >= 9 && key <= 12) {
      setButton1(true);
      setButton2(true);
      setButton3(false);
    }
  };

  var divs = document.getElementsByClassName('d-flex justify-content-between pt-2 my-3');
  for (var i = 0; i < divs.length; i++) {
    //do something to each div like

    if (divs[i].id === key) {
      divs[i].style.border = '1px solid green';
      if (divs[i].getAttribute('email')) {
        emails = parseInt(divs[i].getAttribute('email'));
        price = parseInt(divs[i].getAttribute('price'));
      } else if (divs[i].getAttribute('message')) {
        messages = parseInt(divs[i].getAttribute('message'));
        mprice = parseFloat(divs[i].getAttribute('price'));
      } else if (divs[i].getAttribute('wmessage')) {
        wmessages = parseInt(divs[i].getAttribute('wmessage'));
        wprice = parseInt(divs[i].getAttribute('price'));
      }
    } else {
      divs[i].style.border = '1px solid #d3d3d3';
    }
  }

  return (
    <>
      <h3
        className={`${styles['BodyHeader']}`}
        style={{
          color: colors.black,
          fontSize: '32px',
          marginTop: '20px',
        }}
      >
        {'Buy more credits'}
      </h3>

      <div className="d-flex justify-content-evenly container mt-3 " style={{ maxWidth: '100%' }}>
        <div
          style={{
            border: '1px solid #d3d3d3 ',
            marginRight: '10px',
            height: '78%',
            borderRadius: '16px',
            overflow: 'auto',
          }}
          className="d-flex justify-content-evenly container mt-3 pt-3"
        >
          <div style={{ width: '30%', marginLeft: '-40px' }}>
            <h6
              className={`${styles['BodyHeader']}`}
              style={{
                textAlign: 'center',
                color: colors.black,
              }}
            >
              {'Email Credits'}
            </h6>
            <p style={{ textAlign: 'center' }}>Choose a pack for you</p>
            <hr />
            <div
              id="1"
              style={{
                cursor: 'pointer',
                width: '100%',
                border: '1px solid #d3d3d3',
                borderRadius: '10px',
              }}
              onClick={() => {
                setkey('1');
              }}
              email="1000"
              price="100"
              className="d-flex justify-content-between pt-2 my-3"
            >
              <p style={{ paddingLeft: '5px' }}>
                <strong>1000</strong> Email
              </p>
              <p style={{ color: '#8E53F7', fontWeight: '600', paddingRight: '10px' }}>
                &#x20b9;100
              </p>
            </div>
            <div
              id="2"
              style={{
                cursor: 'pointer',
                width: '100%',
                border: '1px solid #d3d3d3',
                borderRadius: '10px',
              }}
              onClick={() => {
                setkey('2');
              }}
              email="5000"
              price="500"
              className="d-flex justify-content-between pt-2  my-3 "
            >
              <p style={{ paddingLeft: '5px' }}>
                <strong>5000</strong> Email
              </p>
              <p style={{ color: '#8E53F7', fontWeight: '600', paddingRight: '10px' }}>
                &#x20b9;500
              </p>
            </div>
            <div
              id="3"
              style={{
                cursor: 'pointer',
                width: '100%',
                border: '1px solid #d3d3d3',
                borderRadius: '10px',
              }}
              onClick={() => {
                setkey('3');
              }}
              email="10000"
              price="1000"
              className="d-flex justify-content-between pt-2  my-3 flex-column"
            >
              <div className="d-flex justify-content-between">
                <p style={{ paddingLeft: '5px' }}>
                  <strong>10,000</strong> Email
                </p>
                <p style={{ color: '#8E53F7', fontWeight: '600', paddingRight: '10px' }}>
                  &#x20b9;1000
                </p>
              </div>
              {/* <div><p style={{paddingLeft:"8px",fontSize:"14px",color:"#666666"}}>Recommended for you</p></div>} */}
            </div>
            <div
              id="4"
              style={{
                cursor: 'pointer',
                width: '100%',
                border: '1px solid #d3d3d3',
                borderRadius: '10px',
              }}
              onClick={() => {
                setkey('4');
              }}
              email="100000"
              price="10000"
              className="d-flex justify-content-between pt-2  my-3 "
            >
              <p style={{ paddingLeft: '5px' }}>
                <strong>100,000</strong> Email
              </p>
              <p style={{ color: '#8E53F7', fontWeight: '600', paddingRight: '10px' }}>
                &#x20b9;10000
              </p>
            </div>
            {/* <div  className='mt-5' align="center"><button type="button" style={{width:"250px",backgroundColor:"#ab5de0"}} class="btn btn-primary">Add to bucket</button></div> */}
            <div style={{ marginTop: '72px' }} align="center">
              <CheerioButton
                borderStyle={{
                  border: 'none',
                  width: '72%',

                  marginBlockStart: 16,
                  marginBlockEnd: 8,
                  padding: '10px',
                  borderRadius: '8px',
                }}
                disabled={button1}
                textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
                btnText={'Add to Bucket'}
                backColor={'#9357FF'}
                onclick={() => {
                  setMails(emails);
                  setPrice(price);
                }}
              />
            </div>
          </div>
          <div style={{ width: '30%' }}>
            <h6
              className={`${styles['BodyHeader']}`}
              style={{
                color: colors.black,
                textAlign: 'center',
              }}
            >
              {'SMS Credits'}
            </h6>
            <p style={{ textAlign: 'center' }}>Choose a pack for you</p>
            <hr />
            <div
              id="5"
              style={{
                cursor: 'pointer',
                width: '100%',
                border: '1px solid #d3d3d3',
                borderRadius: '10px',
              }}
              onClick={() => {
                setkey('5');
              }}
              message="250"
              price="87.5"
              className="d-flex justify-content-between pt-2 my-3 "
            >
              <p style={{ paddingLeft: '5px' }}>
                <strong>250</strong> Messages
              </p>
              <p style={{ color: '#8E53F7', fontWeight: '600', paddingRight: '10px' }}>
                &#x20b9;87.5
              </p>
            </div>
            <div
              id="6"
              style={{
                cursor: 'pointer',
                width: '100%',
                border: '1px solid #d3d3d3',
                borderRadius: '10px',
              }}
              onClick={() => {
                setkey('6');
              }}
              message="500"
              price="175"
              className="d-flex justify-content-between pt-2  my-3 "
            >
              <p style={{ paddingLeft: '5px' }}>
                <strong>500</strong> Messages
              </p>
              <p style={{ color: '#8E53F7', fontWeight: '600', paddingRight: '10px' }}>
                &#x20b9;175
              </p>
            </div>
            <div
              id="7"
              style={{
                cursor: 'pointer',
                width: '100%',
                border: '1px solid #d3d3d3',
                borderRadius: '10px',
              }}
              onClick={() => {
                setkey('7');
              }}
              message="5000"
              price="1750"
              className="d-flex justify-content-between pt-2  my-3 "
            >
              <p style={{ paddingLeft: '5px' }}>
                <strong>5000</strong> Messages
              </p>
              <p style={{ color: '#8E53F7', fontWeight: '600', paddingRight: '10px' }}>
                &#x20b9;1750
              </p>
            </div>
            <div
              id="8"
              style={{
                cursor: 'pointer',
                width: '100%',
                border: '1px solid #d3d3d3',
                borderRadius: '10px',
              }}
              onClick={() => {
                setkey('8');
              }}
              message="10000"
              price="3500"
              className="d-flex justify-content-between pt-2  my-3 "
            >
              <p style={{ paddingLeft: '5px' }}>
                <strong>10,000</strong> Messages
              </p>
              <p style={{ color: '#8E53F7', fontWeight: '600', paddingRight: '10px' }}>
                &#x20b9;3500
              </p>
            </div>
            {/* <div className='mt-5' align="center"><button type="button" style={{width:"250px",backgroundColor:"#ab5de0"}} class="btn btn-primary">Add to bucket</button></div> */}
            <div style={{ marginTop: '72px' }} align="center">
              <CheerioButton
                borderStyle={{
                  border: 'none',
                  width: '72%',

                  marginBlockStart: 16,
                  marginBlockEnd: 8,
                  padding: '10px',
                  borderRadius: '8px',
                }}
                disabled={button2}
                textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
                btnText={'Add to Bucket'}
                backColor={'#9357FF'}
                onclick={() => {
                  setSms(messages);
                  setSprice(mprice);
                }}
              />
            </div>
          </div>
          <div style={{ width: '30%' }}>
            <h6
              className={`${styles['BodyHeader']}`}
              style={{
                color: colors.black,
                textAlign: 'center',
              }}
            >
              {'WhatsApp Credits'}
            </h6>
            <p style={{ textAlign: 'center' }}>Choose a pack for you</p>
            <hr />
            <div
              id="9"
              style={{
                cursor: 'pointer',
                width: '100%',
                border: '1px solid #d3d3d3',
                borderRadius: '10px',
              }}
              onClick={() => {
                setkey('9');
              }}
              wmessage="250"
              price="200"
              className="d-flex justify-content-between pt-2 my-3 "
            >
              <p style={{ paddingLeft: '5px' }}>
                <strong>250</strong> Messages
              </p>
              <p style={{ color: '#8E53F7', fontWeight: '600', paddingRight: '10px' }}>
                &#x20b9;200
              </p>
            </div>
            <div
              id="10"
              style={{
                cursor: 'pointer',
                width: '100%',
                border: '1px solid #d3d3d3',
                borderRadius: '10px',
              }}
              onClick={() => {
                setkey('10');
              }}
              wmessage="500"
              price="500"
              className="d-flex justify-content-between pt-2  my-3 "
            >
              <p style={{ paddingLeft: '5px' }}>
                <strong>500</strong> Messages
              </p>
              <p style={{ color: '#8E53F7', fontWeight: '600', paddingRight: '10px' }}>
                &#x20b9;500
              </p>
            </div>
            <div
              id="11"
              style={{
                cursor: 'pointer',
                width: '100%',
                border: '1px solid #d3d3d3',
                borderRadius: '10px',
              }}
              onClick={() => {
                setkey('11');
              }}
              wmessage="5000"
              price="4000"
              className="d-flex justify-content-between pt-2  my-3 "
            >
              <p style={{ paddingLeft: '5px' }}>
                <strong>5000</strong> Messages
              </p>
              <p style={{ color: '#8E53F7', fontWeight: '600', paddingRight: '10px' }}>
                &#x20b9;4000
              </p>
            </div>
            <div
              id="12"
              style={{
                cursor: 'pointer',
                width: '100%',
                border: '1px solid #d3d3d3',
                borderRadius: '10px',
              }}
              onClick={() => {
                setkey('12');
              }}
              wmessage="10000"
              price="8000"
              className="d-flex justify-content-between pt-2  my-3 "
            >
              <p style={{ paddingLeft: '5px' }}>
                <strong>10,000</strong> Messages
              </p>
              <p style={{ color: '#8E53F7', fontWeight: '600', paddingRight: '10px' }}>
                &#x20b9;8000
              </p>
            </div>
            {/* <div className='mt-5' align="center"><button type="button" style={{width:"250px",backgroundColor:"#ab5de0"}} class="btn btn-primary">Add to bucket</button></div> */}
            <div style={{ marginTop: '72px' }} align="center">
              <CheerioButton
                borderStyle={{
                  border: 'none',
                  width: '72%',

                  marginBlockStart: 16,
                  marginBlockEnd: 8,
                  padding: '10px',
                  borderRadius: '8px',
                }}
                disabled={button3}
                textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
                btnText={'Add to Bucket'}
                backColor={'#9357FF'}
                onclick={() => {
                  setwMsg(wmessages);
                  setWmprice(wprice);
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="mt-3 pt-3 pl-3"
          style={{
            backgroundColor: '#FFF5E7',
            border: '1px solid #d3d3d3 ',
            borderRadius: '16px',
            height: '78%',
            width: '40%',
            overflowY: 'auto',
          }}
        >
          <h6
            className={`${styles['BodyHeader']}`}
            style={{
              color: colors.black,
            }}
          >
            {'Your Bucket'}
          </h6>
          <p style={{ paddingLeft: '20px' }}>Add Credits from available packs</p>
          <hr />
          <div style={{ height: '3%' }} className="d-flex flex-row  justify-content-between ">
            <p style={{ fontWeight: '400', paddingLeft: '20px' }}>Emails</p>
            <p style={{ color: 'Black', fontWeight: 'bold', paddingRight: '10px' }}>{mails}</p>
          </div>
          <div
            className="d-flex flex-row justify-content-between  align-items-center "
            style={{ marginBlockEnd: 8, height: '5%', paddingTop: '20px' }}
          >
            <p
              style={{
                fontSize: 24,
                fontWeight: 500,
                color: '#4D402F',
                paddingleft: 0,
                marginLeft: 20,
              }}
            >
              &#x20b9; {Price}
            </p>
          </div>
          <hr />

          <div style={{ height: '3%' }} className="d-flex flex-row justify-content-between">
            <p style={{ fontWeight: '400', paddingLeft: '20px' }}>Messages</p>
            <p style={{ color: 'Black', fontWeight: 'bold', paddingRight: '10px' }}>{sms}</p>
          </div>
          <div
            className="d-flex flex-row justify-content-between  align-items-center "
            style={{ marginBlockEnd: 8, height: '5%' }}
          >
            <p
              style={{
                fontSize: 24,
                fontWeight: 500,
                color: '#4D402F',
                paddingTop: 20,
                marginLeft: 20,
              }}
            >
              &#x20b9; {sPrice}
            </p>
          </div>
          <hr />

          <div style={{ height: '3%' }} className="d-flex flex-row justify-content-between">
            <p style={{ fontWeight: '400', paddingLeft: '20px' }}>WhatsApp Conversations</p>
            <p style={{ color: 'Black', fontWeight: 'bold', paddingRight: '10px' }}>{Wmsg}</p>
          </div>
          <div
            className="d-flex flex-row justify-content-between align-items-center "
            style={{ marginBlockEnd: 8, height: '5%' }}
          >
            <p
              style={{
                fontSize: 24,
                fontWeight: 500,
                color: '#4D402F',
                paddingTop: 20,
                marginLeft: 20,
              }}
            >
              &#x20b9; {wMprice}
            </p>
          </div>
          <hr />
          <div>
            <div style={{ height: '1%' }} className="d-flex flex-row justify-content-between">
              <p style={{ fontWeight: '400', paddingLeft: '20px' }}>Base Price</p>
              <p style={{ color: 'Black', fontWeight: 'bold', paddingRight: '10px' }}>
                {Price + sPrice + wMprice}
              </p>
            </div>
            <div style={{ height: '1%' }} className="d-flex flex-row justify-content-between">
              <p style={{ fontWeight: '400', paddingLeft: '20px' }}>GST</p>
              <p style={{ color: 'Black', fontWeight: 'bold', paddingRight: '10px' }}>{gst}</p>
            </div>
          </div>
          <div
            className="d-flex flex-row justify-content-between align-items-center mt-2"
            style={{ height: '3%', marginBlockEnd: 8 }}
          >
            <p
              style={{
                fontSize: 32,
                fontWeight: 600,
                color: '#4D402F',
                paddingLeft: 20,
                margin: 0,
              }}
            >
              &#x20b9; {Price || wMprice || sPrice ? total : 0}
            </p>
          </div>
          <div style={{ marginTop: '12%' }} align="center">
            <CheerioButton
              borderStyle={{
                border: 'none',
                width: '90%',

                marginBlockStart: 16,
                marginBlockEnd: 8,
                padding: '12px',
                borderRadius: '8px',
              }}
              textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
              btnText={'Buy Now'}
              backColor={'#000000'}
              onclick={() => {
                generatePaymentApiCall();
              }}
            />
          </div>
        </div>

        <Modal
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="example-custom-modal-styling-title"
          dialogClassName="my-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              <h6
                className={`${styles['BodyHeader']}`}
                style={{
                  color: colors.black,
                  fontSize: '24px',
                }}
              >
                {'Top Up'}
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center flex-column">
              <div className="mt-5">
                <img
                  src={images.CATSuccess}
                  style={{
                    height: 132.72,
                    width: 132.72,
                    marginInlineEnd: 8,
                  }}
                ></img>
              </div>
              <div>
                {' '}
                <h6
                  style={{
                    color: colors.black,
                    fontSize: '24px',
                    fontWeight: '700',
                  }}
                >
                  {'Top Up Successful'}
                </h6>
              </div>
              <div style={{ height: '42px', width: '455px', textAlign: 'center' }}>
                <p style={{ fontWeight: '400', fontSize: '16px', color: 'grey' }}>
                  Credits have been successfully updated into your account.If the balance is not
                  updated,kindly realod.
                </p>
              </div>
              <div className="d-flex justify-content gap-2 my-5">
                <CheerioButton
                  borderStyle={{
                    width: '163px',
                    height: '37px',
                    marginBlockStart: 16,
                    marginBlockEnd: 8,
                  }}
                  textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
                  btnText={'Run campaign'}
                  backColor={'#9357FF'}
                  onclick={() => {
                    navigate('/creator/announcements');
                  }}
                ></CheerioButton>
                <CheerioButton
                  borderStyle={{
                    width: '163px',
                    height: '37px',
                    marginBlockStart: 16,
                    marginBlockEnd: 8,
                  }}
                  textStyle={{ color: 'black', fontSize: 12, fontWeight: 600 }}
                  btnText={'Check balance'}
                  backColor={'white'}
                  onclick={() => {
                    navigate('/creator');
                  }}
                ></CheerioButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Plans;
