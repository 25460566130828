import React, { useEffect, useState } from 'react';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { freeTrialApi } from '../../Services';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { eventsend } from '../../Config/analyticsFunctions';
import './Modal.css';

const FreeTrialModal = ({ setIsOpen, centerstyle, onsuccess, isFtmSuccess = false }) => {
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);
  const navigate = useNavigate();
  const localdata = useSelector((state) => state.main.localdata);

  const [FTsuccess, setFTsuccess] = useState(isFtmSuccess);

  useEffect(() => {
    ReactGA.event('free_trial_modal_pageview');
    eventsend('free_trial_modal_pageview');
  }, []);

  const onclickfreetrial = () => {
    let data = {
      user_id: localdata?._id ? localdata?._id : _id,
    };
    let token = authtoken;
    ReactGA.event('free_trial_modal_button_click', { data: data });
    eventsend('free_trial_modal_button_click', { data: data });
    freeTrialApi(data, token).then((res) => {
      if (res.flag) {
        console.log('free trial success', res);
        setFTsuccess(true);
        onsuccess();
      } else {
        console.log('free trial error', res);
      }
    });
  };

  const getRemainingDays = (e) => {
    const d = new Date(e);
    const date = new Date();
    let dd = d.toDateString();
    let cd = date.toDateString();
    let diff = Math.floor((Date.parse(dd) - Date.parse(cd)) / 86400000);
    return diff;
  };

  return (
    <>
      <div style={Styles.DarkBg}>
        <div className="" style={{ ...Styles.Center, ...centerstyle }}>
          <div
            className="d-flex flex-column justify-content-evenly align-items-center py-4 px-2"
            style={{
              backgroundColor: colors.white,
              borderRadius: 16,
              width: 560,
              zIndex: 0,
              height: '90vh',
            }}
          >
            <img
              src={images.CreatorClose}
              style={{
                height: 34,
                width: 34,
                objectFit: 'contain',
                position: 'absolute',
                top: 15,
                right: 15,
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsOpen(false);
              }}
            ></img>
            {FTsuccess ? (
              <>
                <text
                  style={{
                    ...Styles.TxtHeading,
                    marginBlock: 16,
                    color: colors.success03,
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  {'Your Free Trial is Activated'}
                </text>
                <img
                  src={images.CATSuccess}
                  style={{ height: 200, width: 200, objectFit: 'contain' }}
                ></img>
                <div className="d-flex flex-column w-100 justify-content-evenly align-items-center">
                  <text
                    style={{ ...Styles.TxtBody, color: colors.error03 }}
                  >{`Trial expiring in 7 days`}</text>
                  <Button
                    variant="primary btncustom"
                    style={{
                      width: '70%',
                      marginBlockStart: 16,
                      marginBlockEnd: 16,
                      backgroundColor: colors.primary03,
                      borderWidth: 0,
                    }}
                    onClick={() => {
                      navigate('/subscription');
                    }}
                  >
                    {'Upgrade to premium'}
                  </Button>
                  <Button
                    variant="outline-primary btncustom"
                    style={{
                      width: '70%',
                      marginBlockEnd: 16,
                      color: colors.primary03,
                      backgroundColor: colors.white,
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: colors.primary03,
                    }}
                    onClick={() => {
                      navigate('/creator');
                      setIsOpen(false);
                    }}
                  >
                    {'Back to home'}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <text style={{ ...Styles.TxtHeading, marginBlock: 8 }}>
                  {'Cheerio Subscription'}
                </text>
                <text style={{ ...Styles.TxtBody, marginBlock: 4 }}>
                  {'Start your 7 days free trial'}
                </text>
                {/* <div className='d-flex flex-column justify-content-between align-items-center py-2 px-3 w-50' style={{...Styles.itemborder,marginBlock:24}}> */}
                <div
                  className="d-flex flex-column justify-content-between align-items-center py-2 px-3 w-75"
                  style={{ marginBlock: 24 }}
                >
                  <div
                    className="d-flex flex-row justify-content-between align-items-center w-100 py-1"
                    style={{
                      borderBottomColor: colors.borderwhite,
                      borderBottomWidth: 1,
                      borderBottomStyle: 'solid',
                    }}
                  >
                    <text style={{ ...Styles.TxtBody, fontWeight: 700 }}>{'Trial Plan'}</text>
                    <text style={{ ...Styles.TxtBody, fontWeight: 700 }}>{'\u20B9 0'}</text>
                  </div>
                  <div
                    className="d-flex flex-row justify-content-between align-items-center w-100 py-2"
                    style={{}}
                  >
                    <text style={{ ...Styles.TxtBody2 }}>{'CRM Tools'}</text>
                    <text style={{ ...Styles.TxtBody2, color: colors.primary03 }}>{'All'}</text>
                  </div>
                  <div
                    className="d-flex flex-row justify-content-between align-items-center w-100 py-2"
                    style={{}}
                  >
                    <text style={{ ...Styles.TxtBody2 }}>{'Email Credits'}</text>
                    <text style={{ ...Styles.TxtBody2, color: colors.primary03 }}>{'100'}</text>
                  </div>
                  <div
                    className="d-flex flex-row justify-content-between align-items-center w-100 py-2"
                    style={{}}
                  >
                    <text style={{ ...Styles.TxtBody2 }}>{'SMS Credits'}</text>
                    <text style={{ ...Styles.TxtBody2, color: colors.primary03 }}>{'100'}</text>
                  </div>
                  <div
                    className="d-flex flex-row justify-content-between align-items-center w-100 py-2"
                    style={{}}
                  >
                    <text style={{ ...Styles.TxtBody2 }}>{'WhatsApp Credits'}</text>
                    <text style={{ ...Styles.TxtBody2, color: colors.primary03 }}>{'10'}</text>
                  </div>
                  {/* <div className='d-flex flex-row justify-content-between align-items-center w-100 py-2' style={{}}>
                  <text style={{...Styles.TxtBody2}}>{'Processing Fees'}</text>
                  <text style={{...Styles.TxtBody2,color:colors.primary03}}>{'5%'}</text>
                </div> */}
                  {/* <Button variant="primary" style={{width:'100%',marginBlockStart:24,marginBlockEnd:8,backgroundColor:colors.primary03,borderWidth:0}}
                onClick={()=>{
                  if(localdata?.free_trail?.used_status == true){
                    navigate('/subscription')
                  }else{
                    onclickfreetrial()
                  }
                  }}>
                  {localdata?.free_trail?.used_status == true ? 'Buy Premium' : 'Start Trial'}
                </Button> */}
                </div>
                {
                  // FTsuccess ? (
                  //   <>
                  //   <text style={{...Styles.TxtBody,marginBlockEnd:16,color:colors.error03}}>{`Trial expiring in ${getRemainingDays(localdata.free_trail.end_date)} days`}</text>
                  //   </>
                  // ):
                  localdata?.free_trail?.used_status == true ? (
                    <>
                      <text
                        style={{ ...Styles.TxtBody, marginBlockEnd: 16, color: colors.error03 }}
                      >
                        {'Free trial expired.'}
                      </text>
                    </>
                  ) : (
                    <>
                      <text style={{ ...Styles.TxtBody, marginBlockEnd: 16 }}>
                        {'No cancellation needed, no charges applicable.'}
                      </text>
                    </>
                  )
                }

                <Button
                  variant="primary"
                  style={{
                    width: '70%',
                    marginBlockStart: 16,
                    marginBlockEnd: 16,
                    backgroundColor: colors.primary03,
                    borderWidth: 0,
                  }}
                  onClick={() => {
                    if (localdata?.free_trail?.used_status == true) {
                      navigate('/subscription');
                    } else {
                      onclickfreetrial();
                    }
                  }}
                >
                  {localdata?.free_trail?.used_status == true ? 'Buy Premium' : 'Start Trial'}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeTrialModal;

const Styles = {
  DarkBg: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    width: '100vw',
    height: '100vh',
    // flex:1,
    zIndex: 1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'fixed',
  },
  Center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  TxtHeading: {
    fontSize: 42,
    fontWeight: 900,
    color: colors.primary03,
  },
  TxtBody: {
    fontSize: 20,
    fontWeight: 600,
    color: colors.black03,
  },
  TxtBody2: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.black03,
  },
  itemborder: {
    backgroundColor: colors.white,
    borderRadius: 16,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.15),-2px -2px 10px 0 rgba(0, 0, 0, 0.15)',
  },
};
