import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import { toast } from 'react-toastify';
import SpinnerLoader from '../../../Components/SpinnerLoader';

const CheckAfterComponent = ({ item, setScheduleAtAction }) => {
  const [hours, setHours] = useState(item?.hours);
  const [minutes, setMinutes] = useState(item?.min);
  const [days, setDays] = useState(item?.days);

  useEffect(() => {
    if (minutes) {
      const timer = setTimeout(() => {
        const value = Math.max(0, Math.min(59, Number(minutes)));
        const multiplesValue = Math.floor(value / 5) * 5;
        if (multiplesValue == minutes) {
          return;
        } else {
          setMinutes(multiplesValue);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [minutes]);

  useEffect(() => {
    if (hours) {
      const htimer = setTimeout(() => {
        // const value = Math.max(0, Math.min(Number(hours)))
        const value = Math.max(0, Math.min(23, Number(hours)));
        console.log('hvalue ====> ', value);
        const multiplesValue = Math.round(value / 1) * 1;
        if (multiplesValue === hours) {
          return;
        } else {
          setHours(value);
        }
      }, 1000);
      return () => clearTimeout(htimer);
    }
  }, [hours]);

  useEffect(() => {
    if (days || hours || minutes) {
      setScheduleAtAction(days, hours, minutes);
    }
  }, [days, hours, minutes]);

  const getExecutionDate = () => {
    let execDate = new Date();
    execDate = new Date(
      execDate.getTime() +
      Number(days) * 24 * 60 * 60 * 1000 +
      Number(hours) * 60 * 60 * 1000 +
      Number(minutes) * 60 * 1000
    );
    return (
      execDate.toLocaleDateString() +
      ' , ' +
      execDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    );
  };

  return (
    <>
      <div
        className="d-flex flex-column justify-content-start align-items-center w-100 py-4 px-3"
        style={{ borderBottom: `1px solid ${colors.borderwhite}` }}
      >
        <h4 style={{ fontWeight: 600, width: '100%' }}>{'Check After'}</h4>
        <p style={{ color: colors.greys01, width: '100%' }}>
          {'Condition will be checked from current date & time, Set the time accordingly.'}
        </p>
        <div className="d-flex flex-row justify-content-evenly align-items-center w-100">
          <InputGroup className="mb-2" style={{ width: '30%', marginBlock: 16 }}>
            <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
              {'Days'}
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="select days"
              className="w-100 btncustom dropdown"
              style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
              value={days}
              onChange={(e) => {
                setDays(e.target.value);
              }}
              min={0}
            // max={23}
            ></Form.Control>
          </InputGroup>
          <InputGroup className="mb-2" style={{ width: '30%', marginBlock: 16 }}>
            <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
              {'Hours'}
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="select hours"
              className="w-100 btncustom dropdown"
              style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
              value={hours}
              onChange={(e) => {
                setHours(e.target.value);
              }}
              min={0}
              max={23}
            ></Form.Control>
          </InputGroup>
          <InputGroup className="mb-2" style={{ width: '30%', marginBlock: 16 }}>
            <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
              {'Minutes'}
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="select minutes"
              className="w-100 btncustom dropdown"
              style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
              value={minutes}
              onChange={(e) => {
                setMinutes(e.target.value);
              }}
              min={0}
              step={5}
              max={59}
            ></Form.Control>
          </InputGroup>
        </div>
        <p style={{ color: colors.black04, width: '100%' }}>
          {`Condition will execute approx on ${getExecutionDate()}`}
        </p>
      </div>
    </>
  );
};

const ConditionRowComponent = ({ item, setOptionsAction, onDeleteAction }) => {
  const [variable, setVariable] = useState(item?.variable1);
  const [condition, setCondition] = useState(item?.variable2);
  const [value, setValue] = useState(item?.variable3);

  useEffect(() => {
    console.log('item ==== ', item);
    console.log('item  1==== ', variable);
    console.log('item  2==== ', condition);
    console.log('item  3==== ', value);
    if (variable && item?.variable1 !== variable) {
      setVariable(item?.variable1);
    }
    if (condition && item?.variable2 !== condition) {
      setCondition(item?.variable2);
    }
    if (value && item?.variable3 !== value) {
      setValue(item?.variable3);
    }
  }, [item]);

  useEffect(() => {
    setOptionsAction(variable, condition, value);
  }, [variable, condition, value]);

  return (
    <>
      <div className="d-flex flex-row justify-content-evenly align-items-center w-100 ">
        <InputGroup className="mb-2" style={{ width: '30%' }}>
          <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
            {'Variable'}
          </Form.Label>
          <Form.Select
            type="text"
            placeholder="variable"
            className="w-100 btncustom dropdown"
            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
            value={variable}
            onChange={(e) => {
              setVariable(e.target.value);
            }}
          >
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              key="blankchoice"
              hidden
              value
            >
              {'Select'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'WA Message'}
              key={'WA Message'}
            >
              {'WA Message'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'link'}
              key={'link'}
            >
              {'link'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'Email'}
              key={'Email'}
            >
              {'Email'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'SMS'}
              key={'SMS'}
            >
              {'SMS'}
            </option>
          </Form.Select>
        </InputGroup>
        <InputGroup className="mb-2" style={{ width: '30%' }}>
          <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
            {'Condition'}
          </Form.Label>
          <Form.Select
            type="text"
            placeholder="condition"
            className="w-100 btncustom dropdown"
            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
            value={condition}
            onChange={(e) => {
              setCondition(e.target.value);
            }}
          >
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              key="blankchoice"
              hidden
              value
            >
              {'Select'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'equal to'}
              key={'equal to'}
            >
              {'equal to (==)'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'not equal to'}
              key={'not equal to'}
            >
              {'not equal to (!=)'}
            </option>
          </Form.Select>
        </InputGroup>
        <InputGroup className="mb-2" style={{ width: '30%' }}>
          <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
            {'Value'}
          </Form.Label>
          <Form.Select
            type="text"
            placeholder="value"
            className="w-100 btncustom dropdown"
            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          >
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              key="blankchoice"
              hidden
              value
            >
              {'Select'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'sent'}
              key={'sent'}
            >
              {'sent'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'delivered'}
              key={'delivered'}
            >
              {'delivered'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'open'}
              key={'open'}
            >
              {'open'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'clicked'}
              key={'clicked'}
            >
              {'clicked'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'replied'}
              key={'replied'}
            >
              {'replied'}
            </option>
          </Form.Select>
        </InputGroup>
        <img
          src={images.DeleteBlack}
          style={{
            height: 24,
            width: 20,
            objectFit: 'contain',
            marginInlineStart: 8,
            cursor: 'pointer',
          }}
          onClick={() => {
            onDeleteAction();
          }}
        ></img>
      </div>
    </>
  );
};

const ConditionModal = ({ data, setData }) => {
  const [existingData, setExistingData] = useState();
  const [options, setOptions] = useState(
    data?.options
      ? data?.options
      : [
        {
          variable1: '',
          variable2: '',
          variable3: '',
        },
      ]
  );
  const [scheduleAt, setScheduleAt] = useState(
    data?.scheduleAt
      ? data?.scheduleAt
      : {
        days: '00',
        hours: '00',
        min: '00',
      }
  );

  useEffect(() => {
    if (data?.options) {
      setExistingData(data?.options);
    }
  }, []);

  useEffect(() => {
    setData({
      ...data,
      options: options,
    });
    console.log('options changed ---> ', options);
  }, [options]);

  useEffect(() => {
    setData({
      ...data,
      scheduleAt: scheduleAt,
    });
  }, [scheduleAt]);

  return (
    <>
      <div className="w-100 h-100 flex-column justify-content-start align-items-start">
        <CheckAfterComponent
          item={scheduleAt}
          setScheduleAtAction={(days, hours, minutes) => {
            setScheduleAt({
              days: days,
              hours: hours,
              min: minutes,
            });
          }}
        />

        <div className="d-flex flex-column justify-content-start align-items-center w-100 py-3 px-3">
          {options &&
            options.map((item, index) => {
              return (
                <ConditionRowComponent
                  item={item}
                  setOptionsAction={(variable, condition, value) => {
                    setOptions((prev) => {
                      let temp = [...prev];
                      temp[index] = {
                        variable1: variable,
                        variable2: condition,
                        variable3: value,
                      };
                      return temp;
                    });
                  }}
                  onDeleteAction={() => {
                    if (index == 0) {
                      toast.error(
                        'Cannot delete first condition, atleast one condition is mandatory',
                        {}
                      );
                    } else {
                      setOptions((prev) => {
                        let temp = [...prev];
                        temp.splice(index, 1);
                        console.log('temp --- ', temp);
                        return temp;
                      });
                    }
                  }}
                />
              );
            })}
          <CheerioButton
            borderStyle={{ marginBlockStart: 16, width: '100%' }}
            textStyle={{
              color: colors.black,
              fontWeight: '600',
            }}
            btnText={'Add condition'}
            icon={images.IconAdd}
            backColor={colors.white}
            onclick={() => {
              console.log('node delete clicked');
              if (options.length < 5) {
                setOptions((prev) => {
                  let temp = [...prev];
                  temp.push({
                    variable1: '',
                    variable2: '',
                    variable3: '',
                  });
                  return temp;
                });
              } else {
                toast.error('You can only set upto 5 conditions', {});
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ConditionModal;
