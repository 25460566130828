import { useState, useRef, useEffect } from 'react';
import images from '../../Utils/images';
import CheerioButton from '../../Components/CheerioButton';
import { ProgressBar } from 'react-bootstrap';
const DropzoneCustom = (props) => {
  const [files, setFiles] = useState(null);
  const [upload, setUpload] = useState(false);
  const inputRef = useRef();
  const [countOfProgress, setCountOfProgress] = useState(0);
  const [incorrectFormat, setIncorrectFormat] = useState(true);

  useEffect(() => {
    if (files) {
      const timer = setInterval(() => {
        setCountOfProgress((oldProgress) => {
          if (oldProgress == 100) clearInterval(timer);
          return Math.min(oldProgress + Math.random() * 10, 100);
        });
      }, 200);
    }
  }, [files]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  };

  // send files to the server
  const handleUpload = () => {
    const formData = new FormData();
    console.log('child14', files[0].name);

    // console.log("child14",files[0].name);

    formData.append('Files', files);
    console.log('formData', formData);

    setUpload(true);
    props.handleCallback(files);
    if (
      files[0].type === 'text/plain' ||
      files[0].type === 'application/pdf' ||
      files[0].type === 'application/doc' ||
      files[0].type === 'application/msword' ||
      files[0].type === 'application/json'
    ) {
      setIncorrectFormat(false);
      props.handleCallback(files);
    } else {
      setIncorrectFormat(true);
    }

    //console.log(formData.getAll(files))
    // fetch(
    //   "link", {
    //     method: "POST",
    //     body: formData
    //   }
    // )
  };

  if (files)
    return upload ? (
      incorrectFormat ? (
        <div style={{ fontWeight: '700', fontSize: '16px', padding: '20px' }}>
          <div className="d-flex justify-content-between">
            <div className="d-flex" style={{ fontWeight: '600' }}>
              <img
                src={images.CFile}
                style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 8 }}
              ></img>

              <p>
                {' '}
                {Array.from(files).map((file, idx) => (
                  <p>{file.name}</p>
                ))}
              </p>
            </div>
            <img
              src={images.CDelete}
              style={{
                height: 24,
                width: 24,
                objectFit: 'contain',
                marginInlineStart: 8,
                marginLeft: '20px',
              }}
              onClick={() => {
                setFiles(null);
                props.handleCallback(null);
                setUpload(false);
              }}
            />
          </div>
          <ProgressBar
            variant="customcolor"
            style={{
              height: 8,
              width: 'calc( 100% - 30px)',
              borderRadius: 4,
              alignSelf: 'flex-start',
            }}
            now={countOfProgress}
          />
          {countOfProgress == 100 ? (
            <div className="d-flex" style={{ marginTop: '10px' }}>
              <img src={images.Cross} style={{ marginRight: '10px', height: '5%' }}></img>
              <p style={{ color: '#FF0000', fontWeight: '400', fontSize: '14px' }}>
                Error! Incorrect file format.
              </p>
            </div>
          ) : (
            <div className="d-flex" style={{ marginTop: '10px' }}>
              <img src={images.Loading} style={{ height: '5%' }}></img>
              <p style={{ color: '#666666', fontWeight: '400' }}>Uploading file...</p>
            </div>
          )}
        </div>
      ) : (
        <div style={{ fontWeight: '700', fontSize: '16px', padding: '20px' }}>
          <div className="d-flex justify-content-between">
            <div className="d-flex" style={{ fontWeight: '600' }}>
              <img
                src={images.CFile}
                style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 8 }}
              ></img>

              <p>
                {' '}
                {Array.from(files).map((file, idx) => (
                  <p>{file.name}</p>
                ))}
              </p>
            </div>
            <img
              src={images.CDelete}
              style={{
                height: 24,
                width: 24,
                objectFit: 'contain',
                marginInlineStart: 8,
                marginLeft: '20px',
              }}
              onClick={() => {
                setFiles(null);
                props.handleCallback(null);
                setUpload(false);
              }}
            />
          </div>
          {countOfProgress !== 100 ? (
            <ProgressBar
              variant="customcolor"
              style={{
                height: 8,
                width: 'calc( 100% - 30px)',
                borderRadius: 4,
                alignSelf: 'flex-start',
              }}
              now={countOfProgress}
            />
          ) : (
            ''
          )}
          {countOfProgress == 100 ? (
            <div className="d-flex" style={{ marginTop: '-10px' }}>
              <img src={images.Check} style={{ marginRight: '10px', height: '4%' }}></img>
              <p
                style={{ color: '#666666', fontWeight: '400', fontSize: '14px', marginTop: '-4px' }}
              >
                File uploaded
              </p>
            </div>
          ) : (
            <div className="d-flex" style={{ marginTop: '10px' }}>
              <img src={images.Loading} style={{ height: '5%' }}></img>
              <p style={{ color: '#666666', fontWeight: '400' }}>Uploading file...</p>
            </div>
          )}
          {/* <CheerioButton
            className="button"
                        borderStyle={{
                          height:"36px",
                          width: "165px",
                          marginBlockStart: 12,
                          marginBlockEnd: 8,
                          
                        }}
                        textStyle={{ color: "white",fontSize: 12, fontWeight: 600 }}
                        btnText={"Cancel"}
                        backColor={"#000000"} 
                   
                        onclick={() => {setFiles(null);props.handleCallback(null);setUpload(false)}}                 
                        
                      /> */}
        </div>
      )
    ) : (
      <div className="uploads">
        <ul>
          {Array.from(files).map((file, idx) => (
            <li key={idx}>{file.name}</li>
          ))}
        </ul>

        <div className="actions">
          <CheerioButton
            className="button"
            borderStyle={{
              height: '36px',
              width: '165px',
              marginBlockStart: 12,
              marginBlockEnd: 8,
            }}
            textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
            btnText={'Cancel'}
            backColor={'#000000'}
            onclick={() => setFiles(null)}
          />
          <CheerioButton
            className="button"
            borderStyle={{
              height: '36px',
              width: '165px',
              marginBlockStart: 12,
              marginBlockEnd: 8,
            }}
            textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
            btnText={'Upload'}
            backColor={'#000000'}
            onclick={() => handleUpload()}
          />
        </div>
      </div>
    );

  return (
    <>
      <div
        className="dropzone d-flex justify-content-around pt-5"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        style={{ height: '234px', backgroundColor: '#FAFAFA' }}
      >
        <div>
          <img
            src={images.Upload}
            style={{
              height: 99.33,
              width: 136.58,
              marginInlineEnd: 8,
            }}
          ></img>
          <p
            style={{
              fontSize: '12px',
              fontWeight: '600',
              marginTop: '15px',
              marginLeft: '10px',
              color: 'black',
            }}
          >
            {' '}
            Drag & drop file here
          </p>
          <input
            type="file"
            accept="text/plain,.json,.pdf,.doc"
            onChange={(event) => setFiles(event.target.files)}
            hidden
            ref={inputRef}
          />
        </div>
        <div
          style={{
            fontSize: '12px',
            fontWeight: '700',
            marginTop: '15px',
            marginLeft: '10px',
            color: 'black',
            paddingTop: '50px',
          }}
        >
          OR
        </div>
        <div style={{ paddingTop: '30px' }}>
          <CheerioButton
            className="button"
            borderStyle={{
              height: '36px',
              width: '165px',
              marginBlockStart: 12,
              marginBlockEnd: 8,
            }}
            textStyle={{ color: 'white', fontSize: 12, fontWeight: 600 }}
            btnText={'Browse Files'}
            backColor={'#000000'}
            icon={images.searchBox}
            onclick={() => inputRef.current.click()}
          />
        </div>
      </div>
    </>
  );
};

export default DropzoneCustom;
