import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import Form from 'react-bootstrap/Form';
import CheerioButton from '../../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import { V2AgentUpdate } from '../../../Services';

const SwitchItems = ({ title, setField, Checked, fieldName, disabled }) => {
  return (
    <>
      <div className="Row w-100" style={{ marginBlock: 4 }}>
        <p style={{ padding: 0, margin: 0, fontSize: 14, fontWeight: 400, color: colors.black }}>
          {title}
        </p>
        <Form.Check
          disabled={disabled}
          type="switch"
          className="btncustom"
          defaultChecked={Checked}
          onChange={(e) => setField(fieldName, e.target.checked)}
        />
      </div>
    </>
  );
};

const PermissionsModal = ({ setIsPermissionsModalOpen, selectedPeople }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const agentdata = useSelector((state) => state.main.agentdata);
  const [success, setSuccess] = useState(false);

  const PermissionsLabels = [
    { title: 'Contacts', field: 'contact' },
    { title: 'Campaigns', field: 'campaign' },
    { title: 'Analytics', field: 'analytics' },
    { title: 'Chat Replies', field: 'chatReplies' },
    { title: 'Access all chats', field: 'allChats' },
    { title: 'Email', field: 'email' },
    { title: 'Integrations', field: 'integrations' },
    { title: 'Automations', field: 'automation' },
    { title: 'Insta Replies', field: 'instaReplies' },
  ];
  const [permissions, setPermissions] = useState();

  useEffect(() => {
    console.log('people data ===> ', selectedPeople);
    setPermissions(selectedPeople?.permission);
  }, []);

  useEffect(() => {
    console.log('fffffffffffff ', permissions);
  }, [permissions]);

  const setField = (field, value) => {
    console.log('setField ===> ', field, value);
    setPermissions({
      ...permissions,
      [field]: value,
    });
  };

  const UpdateAgentV2APICall = () => {
    const data = {
      name: selectedPeople?.name,
      email: selectedPeople?.email,
      phone: selectedPeople?.phone,
      role: selectedPeople?.role,
      permission: permissions,
      status: selectedPeople?.status,
    };
    const token = authtoken;
    V2AgentUpdate(data, token).then((res) => {
      if (res?.flag) {
        console.log('success update ', res);
        setSuccess(true);
      } else {
        console.log('error update ', res);
      }
    });
  };

  return (
    <>
      <div
        className="DarkBg"
        onClick={() => {
          setIsPermissionsModalOpen(false);
        }}
      >
        <div className="Center">
          <div
            className="d-flex flex-column justify-content-start align-items-center"
            style={{
              width: 500,
              backgroundColor: colors.white,
              border: `1px solid ${colors.borderwhite}`,
              paddingBlock: 30,
              paddingInline: 40,
              borderRadius: 16,
              maxHeight: '80vh',
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className="d-flex flex-row justify-content-between align-items-center w-100"
              style={{ paddingBlockEnd: 16, borderBottom: `1px solid ${colors.borderwhite}` }}
            >
              <h3
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 16,
                  fontWeight: 600,
                  color: colors.black,
                }}
              >
                {'Manage Permissions'}
              </h3>
              <img
                src={images.CMClose}
                style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                onClick={() => {
                  setIsPermissionsModalOpen(false);
                }}
              ></img>
            </div>
            {success ? (
              <>
                <img
                  src={images.CATSuccess}
                  style={{ height: 160, width: 160, objectFit: 'contain', marginBlock: 20 }}
                ></img>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 16,
                    fontWeight: 700,
                    color: colors.black,
                    textAlign: 'center',
                    width: '60%',
                  }}
                >
                  {'Permissions Updated'}
                </p>
                <CheerioButton
                  borderStyle={{ borderWidth: 0, width: '100%', marginBlockStart: 16 }}
                  textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
                  btnText={'Close'}
                  backColor={colors.black}
                  onclick={() => {
                    setIsPermissionsModalOpen(false);
                  }}
                  // icon={images.MPPlus}
                  // iconStyle={{height}}
                  // disabled={builderReady ? false : true}
                />
              </>
            ) : (
              <>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    marginBlock: 16,
                    fontSize: 16,
                    fontWeight: 400,
                    color: colors.greys04,
                    width: '100%',
                  }}
                >
                  {`Name: ${selectedPeople?.name}`}
                  <br />
                  {`Email ID: ${selectedPeople?.email}`}
                  <br />
                  {`Phone no: ${selectedPeople?.phone}`}
                </p>
                <div
                  className="d-flex flex-row justify-content-between align-items-center w-100"
                  style={{}}
                >
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: 16,
                      fontWeight: 600,
                      color: colors.black,
                    }}
                  >
                    {'Features'}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: 16,
                      fontWeight: 600,
                      color: colors.black,
                    }}
                  >
                    {'Access'}
                  </p>
                </div>
                {permissions &&
                  PermissionsLabels.map((item, index) => {
                    return (
                      <SwitchItems
                        title={item.title}
                        setField={setField}
                        Checked={permissions?.[item.field] ? permissions?.[item.field] : false}
                        fieldName={item.field}
                        disabled={agentdata?._id ? true : false}
                      />
                    );
                  })}

                <CheerioButton
                  borderStyle={{ borderWidth: 0, width: '100%', marginBlockStart: 16 }}
                  textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
                  btnText={'Save Permissions'}
                  backColor={colors.primary03}
                  onclick={() => {
                    console.log('invite button click');
                    UpdateAgentV2APICall();
                  }}
                  // icon={images.MPPlus}
                  // iconStyle={{height}}
                  disabled={agentdata?._id ? true : false}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PermissionsModal;
