import React, { useState } from 'react'
import colors from '../../../Utils/colors'
import { MuiColorInput, matchIsValidColor } from 'mui-color-input'
import { TextField } from '@mui/material'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

const CFFormProperties = ({ formProps, updateFormProps }) => {

    const fonts = [
        'Segoe UI',
        'Arial',
        'Courier New',
        'Georgia',
        'Times New Roman',
        'Verdana',
        'Roboto',
        'Open Sans',
        'Lato',
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("dsfsdfsdf ", name, value)
        updateFormProps({ [name]: value });
    }

    return (
        <>
            <div className='d-flex flex-column justify-content-start align-items-center w-100' style={{
                backgroundColor: colors.white01,
                borderRadius: 12,
                border: `1px solid ${colors.borderwhite}`,
                paddingInline: 20,
                paddingBlock: 16,
                marginBlock: 8,
            }}>
                <h4 className='mb-2 w-100' style={{
                    fontSize: 18,
                    fontWeight: 700,
                }}>
                    {'Form properties'}
                </h4>
                <div className='d-flex flex-row justify-content-between align-items-cneter w-100 my-2'>
                    <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                        <p style={{ fontWeight: 500 }}>
                            {'Body Color'}
                        </p>
                        <MuiColorInput
                            value={formProps.bodyColor}
                            format={'hex'}
                            onChange={(color) => {
                                updateFormProps({ ['bodyColor']: color })
                            }}
                            size='small'
                            style={{ marginBlock: 8, width: '100%' }}
                        />
                    </div>
                    <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                        <p style={{ fontWeight: 500 }}>
                            {'Text Color'}
                        </p>
                        <MuiColorInput
                            value={formProps.textColor}
                            format={'hex'}
                            onChange={(color) => {
                                updateFormProps({ ['textColor']: color })
                            }}
                            size='small'
                            style={{ marginBlock: 8, width: '100%' }}
                        />
                    </div>
                    <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                        <p style={{ fontWeight: 500 }}>
                            {'Text Field Color'}
                        </p>
                        <MuiColorInput
                            value={formProps.textFieldColor}
                            format={'hex'}
                            onChange={(color) => {
                                updateFormProps({ ['textFieldColor']: color })
                            }}
                            size='small'
                            style={{ marginBlock: 8, width: '100%' }}
                        />
                    </div>

                </div>
                <div className='d-flex flex-row justify-content-between align-items-cneter w-100 my-2'>
                    <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                        <p style={{ fontWeight: 500 }}>
                            {'Font'}
                        </p>
                        <Select
                            name='font'
                            value={formProps.font}
                            onChange={handleChange}
                            style={{ marginBlock: 8, width: '100%' }}
                            size='small'
                        >
                            {fonts.map((font) => (
                                <MenuItem key={font} value={font}>
                                    {font}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                        <p style={{ fontWeight: 500 }}>
                            {'Border Radius (in px)'}
                        </p>
                        <TextField
                            name='borderRadius'
                            value={formProps.borderRadius}
                            onChange={(e)=>{
                                const value = e.target.value;
                                if(value === ''){
                                    handleChange(e)
                                }else{
                                    const clampedValue = Math.max(0, Math.min(48, Number(value)))
                                    updateFormProps({["borderRadius"]: clampedValue})
                                }
                            }}
                            // onChange={handleChange}
                            type='number'
                            size='small'
                            style={{ marginBlock: 8, width: '100%' }}
                            className='btncustom'
                            inputProps={{min:0, max:48}}
                        />
                    </div>
                    {/* <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{flex:1}}>
                <p style={{fontWeight:500}}>
                    {'Field Header Size (in px)'}
                </p>
                <TextField 
                    name='fieldHeaderSize'
                    value={formProps.fieldHeaderSize}
                    onChange={handleChange}
                    type='number'
                    size='small'
                    style={{marginBlock:8,width:'100%'}}
                    className='btncustom'
                />
            </div> */}
                    <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                        <p style={{ fontWeight: 500 }}>
                            {'Text Size (in px)'}
                        </p>
                        <TextField
                            name='textSize'
                            value={formProps.textSize}
                            // onChange={handleChange}
                            onChange={(e)=>{
                                const value = e.target.value;
                                if (value === '' || /^[0-9\b]+$/.test(value)) {
                                    handleChange(e);
                                }
                            }}
                            onBlur={(e)=>{
                                const { value } = e.target;
                                if (value !== '') {
                                    const limitedValue = Math.max(12, Math.min(18, Number(value)));
                                    updateFormProps({["textSize"]:limitedValue})
                                }
                            }}
                            type='number'
                            size='small'
                            style={{ marginBlock: 8, width: '100%' }}
                            className='btncustom'
                            inputProps={{min:12, max:18}}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CFFormProperties