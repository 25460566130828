import React from 'react';
import { getImagePath } from '../../../Utils';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import Button from 'react-bootstrap/Button';
import './styles.css';
import HEImages from '../../CreatorNew/Components/HEImages';

const Preview = ({
  data,
  selectedTab,
  DemoData,
  FollowUp = false,
  Reminder = false,
  ThankYou = false,
  ShareInfo = false,
  AddDiscount = false,
  RCOffer = false,
  CWinback = false,
  BINews = false,
  WelcomeInsta = false,
  LoyalCustomer = false,
  ThankYou2 = false,
  DoYouKnow = false,
  Maintenance = false,
  serviceMarathi = false,
  ReplyMultiple = false,
}) => {
  const getTime = (e) => {
    const d = new Date(e);
    const date = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs((date.getTime() - d.getTime()) / oneDay));
  };

  return (
    <>
      {selectedTab == 'Email' ? (
        <>
          <div
            className="d-flex flex-column justify-content-start align-items-center my-2"
            style={{ width: '80%', flex: 1, backgroundColor: colors.black02, cursor: 'default' }}
          >
            <div
              className="d-flex flex-row justify-content-start align-items-center px-2 w-100"
              style={{ height: 50, marginBlock: 8 }}
            >
              <HEImages
                userGeneratedSrc={DemoData?.logo ? DemoData?.logo : getImagePath(data.logo)}
                style={{ height: 34, width: 'auto', objectFit: 'contain', marginInline: 16 }}
              />
              {/* <img
                src={DemoData?.logo ? DemoData?.logo : getImagePath(data.logo)}
                style={{ height: 34, width: 'auto', objectFit: 'contain', marginInline: 16 }}
              ></img> */}
            </div>
            <text
              className="w-100 px-2"
              style={{
                fontSize: 32,
                fontWeight: 900,
                color: colors.white,
                textAlign: 'center',
                flexWrap: 'wrap',
                marginBlock: 8,
              }}
            >
              {data.product_name}
            </text>
            <HEImages
              userGeneratedSrc={
                DemoData?.thumbnail ? DemoData?.thumbnail : getImagePath(data.thumbnail)
              }
              style={{
                width: '80%',
                height: 'auto',
                objectFit: 'contain',
                borderRadius: 8,
                marginBlock: 8,
              }}
            />

            {/* <img
              className=""
              src={DemoData?.thumbnail ? DemoData?.thumbnail : getImagePath(data.thumbnail)}
              style={{
                width: '80%',
                height: 'auto',
                objectFit: 'contain',
                borderRadius: 8,
                marginBlock: 8,
              }}
            ></img> */}
            <text
              className=""
              style={{
                fontSize: 16,
                fontWeight: 400,
                color: colors.white,
                marginBlock: 16,
                width: '80%',
              }}
            >
              {'Hey Rohan,'}
              <br />
              <br />
              {data.template_type == 'Product'
                ? `${data.creator_name} has introduced their new product ${data.product_name}.`
                : data.template_type == 'Course'
                ? `${data.creator_name} has announced a new course on ${data.product_name}. First come first serve.`
                : data.template_type == 'Offer' &&
                  `${data.creator_name} has an exclusive offer for you on ${data.product_name}.`}
            </text>
            {data.template_type == 'Offer' ? (
              <>
                <text
                  style={{
                    fontSize: 26,
                    fontWeight: 800,
                    color: colors.white,
                    width: '80%',
                    textAlign: 'center',
                    marginBlock: 16,
                  }}
                >
                  <text style={{ fontSize: 46, color: '#FFDE9F' }}>{`${data.discounts}%`}</text>
                  {' OFF'}
                </text>
                <text
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: colors.white,
                    width: '80%',
                    textAlign: 'center',
                    flexWrap: 'wrap',
                    marginBlock: 8,
                  }}
                >
                  {`Offer valid for next ${
                    DemoData?.expired ? DemoData?.expired : getTime(data.expired)
                  } days only`}
                </text>
              </>
            ) : (
              <></>
            )}
            <Button
              variant="primary"
              className="btncustom"
              style={{
                width: '40%',
                marginBlock: 24,
                backgroundColor: colors.primary04,
                borderWidth: 0,
                cursor: 'default',
              }}
            >
              {data.template_type == 'Product'
                ? 'Buy now'
                : data.template_type == 'Course'
                ? 'Register now'
                : data.template_type == 'Offer' && 'Claim offer'}
            </Button>
            <img
              src={images.CAEmailBottom}
              style={{ width: '100%', height: 'auto', objectFit: 'contain', marginBlockStart: 8 }}
            ></img>
          </div>
        </>
      ) : selectedTab == 'WhatsApp' ? (
        <>
          <div
            className="d-flex flex-column justify-content-start align-items-start my-2 py-3 px-2"
            style={{
              backgroundImage: `url(${images.CAWABack})`,
              width: '80%',
              height: 'auto',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              borderRadius: 16,
            }}
          >
            <div
              className="w-75 pt-3 pb-1 d-flex flex-column justify-content-start align-items-center"
              style={{
                backgroundColor: '#1F2C34',
                marginBlockStart: 32,
                marginBlockEnd: ReplyMultiple === true ? 16 : 54,
                marginInline: 16,
                borderRadius: 16,
              }}
            >
              <HEImages
                userGeneratedSrc={
                  DemoData?.thumbnail ? DemoData?.thumbnail : getImagePath(data.thumbnail)
                }
                classname={'px-3'}
                style={{ width: '100%', height: 'auto', objectFit: 'contain', borderRadius: 16 }}
              />
              {/* <img
                className="px-3"
                src={DemoData?.thumbnail ? DemoData?.thumbnail : getImagePath(data.thumbnail)}
                style={{ width: '100%', height: 'auto', objectFit: 'contain', borderRadius: 16 }}
              ></img> */}
              {FollowUp ? (
                <>
                  <p
                    className="px-3"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                      marginBlock: 16,
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    {'Hello Rohan,'}
                    <br />
                    <br />
                    {`I am Nishant from ClickUp. You had enquired about our services in the recent past. I just wanted to touch base with you once again to check if you are still interested in availing any of our packages. We are running a 21% discount promotional offer till 28th April.`}
                    <br />
                    <br />
                    {`Just reply to this message and I will help you out at the earliest.`}
                    <br />
                    <br />
                    {'Regards,'}
                    <br />
                    {`ClickUp`}
                  </p>
                </>
              ) : Reminder ? (
                <>
                  <p
                    className="px-3"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                      marginBlock: 16,
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    {'Hello '}
                    <span style={{ fontWeight: 700 }}>{'Rohan,'}</span>
                    <br />
                    <br />
                    {'We would like to inform you that '}
                    <span style={{ fontWeight: 700 }}>{'your membership is up for renewal.'}</span>
                    {'Please contact us at '}
                    <span style={{ fontWeight: 700 }}>{'9876598765'}</span>
                    {' for more information/questions.'}
                    <br />
                    <br />
                    <span style={{ fontWeight: 700 }}>{'ClickUp'}</span>
                  </p>
                </>
              ) : ThankYou ? (
                <>
                  <p
                    className="px-3"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                      marginBlock: 16,
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    {'Hello '}
                    <span style={{ fontWeight: 700 }}>{'Rohan,'}</span>
                    <br />
                    <br />
                    {'Thank you for '}
                    <span style={{ fontWeight: 700 }}>{'installing our app.'}</span>
                    <br />
                    <br />
                    {'Please find below '}
                    <span style={{ fontWeight: 700 }}>
                      {'the YouTube link for the demo video of Cheerio -'}
                    </span>
                    <br />
                    <span style={{ fontWeight: 700 }}>{'https://youtu.be/OqfnppSZdwA'}</span>
                    <br />
                    <br />
                    {'Below are some of the key features:'}
                    <br />
                    <br />
                    <span style={{ fontWeight: 700 }}>{'WhatsApp Integration'}</span>
                    <br />
                    <span style={{ fontWeight: 700 }}>{'Email Integration'}</span>
                    <br />
                    <span style={{ fontWeight: 700 }}>{'Advanced analytics'}</span>
                    <br />
                    <span style={{ fontWeight: 700 }}>{'Link CTR tracking'}</span>
                    <br />
                    <br />
                    {'If you have any questions, please feel free to react out to us.'}
                    <br />
                    <br />
                    {'Best Regards'}
                    <br />
                    <span style={{ fontWeight: 700 }}>{'Adarsh'}</span>
                    <br />
                    <span style={{ fontWeight: 700 }}>{'Cheerio'}</span>
                  </p>
                </>
              ) : ShareInfo ? (
                <>
                  <p
                    className="px-3"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                      marginBlock: 16,
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    {'Hey '}
                    <span style={{ fontWeight: 700 }}>{'Rohan,'}</span>
                    <br />
                    <br />
                    {'Wishing you a very '}
                    <span style={{ fontWeight: 700 }}>{'Good Morning.'}</span>
                    <br />
                    <br />
                    {"Here's a "}
                    <span style={{ fontWeight: 700 }}>{'tip'}</span>
                    {' of the day we wanted to share with you.'}
                    <br />
                    <br />
                    <span style={{ fontWeight: 700 }}>
                      {
                        'When sending emails, make sure to add a prominent \
                            unsubscribe button and ensure to maintain footers with company address and contact info \
                            to reduce spam rate.'
                      }
                    </span>
                    <br />
                    <br />
                    {'In your journey to be an avid '}
                    <span style={{ fontWeight: 700 }}>{'marketer'}</span>
                    {' we are always there for you.'}
                    <br />
                    <br />
                    {
                      'If you are interested in knowing more about how we can help, click the link below.'
                    }
                    <br />
                    <br />
                  </p>
                </>
              ) : AddDiscount ? (
                <>
                  <p
                    className="px-3"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                      marginBlock: 16,
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    {'Dearest '}
                    <span style={{ fontWeight: 700 }}>{'Coding Superstars,'}</span>
                    <br />
                    <br />
                    {'These are Special times and they call for Special needs.'}
                    <br />
                    <br />
                    {'At '}
                    <span style={{ fontWeight: 700 }}>{'Cheerio'}</span>
                    {', we would like you to pamper yourself this '}
                    <span style={{ fontWeight: 700 }}>{'Diwali.'}</span>
                    <br />
                    <br />
                    {'Shop now and get additional '}
                    <span style={{ fontWeight: 700 }}>{'5% off.'}</span>
                    <br />
                    <br />
                    {'You can be rest assured that'}
                    <span style={{ fontWeight: 700 }}>
                      {' our api documentation and support is unmatched.'}
                    </span>
                    <br />
                    <br />
                  </p>
                </>
              ) : RCOffer ? (
                <>
                  <p
                    className="px-3"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                      marginBlock: 16,
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    {'Hi '}
                    <span style={{ fontWeight: 700 }}>{'John,'}</span>
                    <br />
                    <br />
                    {'Welcome to the '}
                    <span style={{ fontWeight: 700 }}>{'Cheerio family.'}</span>
                    {' Thank you for '}
                    <span style={{ fontWeight: 700 }}>{'signing up for our free trial.'}</span>
                    {" It's time to fill those carts again with "}
                    <span style={{ fontWeight: 700 }}>
                      {'our discounted licenses on messaging.'}
                    </span>
                    <br />
                    <br />
                    {'On the Special occasion of '}
                    <span style={{ fontWeight: 700 }}>{"Mother's day"}</span>
                    {', we are offering you a special discount of '}
                    <span style={{ fontWeight: 700 }}>{'25%.'}</span>
                    <br />
                    <br />
                    {'You can place your orders online on '}
                    <span style={{ fontWeight: 700 }}>{'cheerio.in'}</span>
                    <br />
                    <br />
                    {'Stock up on your essential '}
                    <span style={{ fontWeight: 700 }}>
                      {'requirements for web or app messaging.'}
                    </span>
                    {' Call us at'}
                    <span style={{ fontWeight: 700 }}>{' 9876598765.'}</span>
                    <br />
                    <br />
                  </p>
                </>
              ) : CWinback ? (
                <>
                  <p
                    className="px-3"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                      marginBlock: 16,
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    {'Congratulations '}
                    <span style={{ fontWeight: 700 }}>{'Rahul,'}</span>
                    <br />
                    <br />
                    {'We have a '}
                    <span style={{ fontWeight: 700 }}>{'surprise'}</span>
                    {' in store for you!'}
                    <br />
                    <br />
                    {'We have been missing you on '}
                    <span style={{ fontWeight: 700 }}>{'Cheerio web app.'}</span>
                    <br />
                    <br />
                    {"Why don't you check out the "}
                    <span style={{ fontWeight: 700 }}>{'chatbot we just released!'}</span>
                    <br />
                    <br />
                    {"In fact, here's a present for you, use the code "}
                    <span style={{ fontWeight: 700 }}>{'CH500.'}</span>
                    <br />
                    <br />
                    {'We will be waiting for you!'}
                    <br />
                    <br />
                    {'Hurry now, limited time offer!'}
                    <br />
                    <br />
                  </p>
                </>
              ) : BINews ? (
                <>
                  <p
                    className="px-3"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                      marginBlock: 16,
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    {'Hi '}
                    <span style={{ fontWeight: 700 }}>{'Nishant,'}</span>
                    <br />
                    <br />
                    {'Want to switch to '}
                    <span style={{ fontWeight: 700 }}>{'hassle free CRM'}</span>
                    {'? So what is stopping you?'}
                    <br />
                    <br />
                    <span style={{ fontWeight: 700 }}>{'Not getting a reliable support'}</span>
                    <br />
                    <span style={{ fontWeight: 700 }}>{'Too expensive'}</span>
                    <br />
                    <span style={{ fontWeight: 700 }}>
                      {"Don't have the time to compare alternatives"}
                    </span>
                    <br />
                    <br />
                    {'Welcome to '}
                    <span style={{ fontWeight: 700 }}>{'Cheerio'}</span>
                    {' where we have a solution to all your obstacles:'}
                    <br />
                    <br />
                    <span style={{ fontWeight: 700 }}>{'We have over 24 hours support'}</span>
                    <br />
                    <span style={{ fontWeight: 700 }}>
                      {'We have a special 10% discount for you'}
                    </span>
                    <br />
                    <span style={{ fontWeight: 700 }}>
                      {'We give feature by feature parity matrix'}
                    </span>
                    <br />
                    <br />
                    {'You can place your order online on'}
                    <span style={{ fontWeight: 700 }}>{' web.cheerio.in'}</span>
                    <br />
                    <br />
                    {'To know more, you can call us on '}
                    <span style={{ fontWeight: 700 }}>{'9876598765'}</span>
                    <br />
                    <br />
                  </p>
                </>
              ) : WelcomeInsta ? (
                <>
                  <p
                    className="px-3"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                      marginBlock: 16,
                      width: '100%',
                      flexWrap: 'wrap',
                      wordBreak: 'break-word',
                    }}
                  >
                    {'Hello '}
                    <span style={{ fontWeight: 700 }}>{'Nishant,'}</span>
                    <br />
                    <br />
                    {'Welcome to '}
                    <span style={{ fontWeight: 700 }}>{'Cheerio.'}</span>
                    {' We are glad you chose us for'}
                    <span style={{ fontWeight: 700 }}>{'CRM Marketing.'}</span>
                    <br />
                    <br />
                    {`Let's connect on Instagram for some fun`}
                    <br />
                    <span style={{ fontWeight: 700 }}>
                      {'https://www.instagram.com/cheeriosocial/'}
                    </span>
                    <br />
                    <br />
                    {`If you need anything, write to us at `}
                    <span style={{ fontWeight: 700 }}>{'admin@cheeerio.in'}</span>
                    <br />
                    <br />
                    {`Here's your `}
                    <span style={{ fontWeight: 700 }}>{`20%`}</span>
                    {` discount code to shop any item on the website --`}
                    <br />
                    <span style={{ fontWeight: 700 }}>{'https://www.cheerio.in'}</span>
                    <br />
                    <br />
                    {`⚡️No minimum purchase is required.`}
                    <br />
                    {`⚡️This discount code is valid for the next `}
                    <span style={{ fontWeight: 700 }}>{`2`}</span>
                    {` months.`}
                    <br />
                    <br />
                    {`Call us at `}
                    <span style={{ fontWeight: 700 }}>{'9876598765'}</span>
                  </p>
                </>
              ) : LoyalCustomer ? (
                <>
                  <p
                    className="px-3"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                      marginBlock: 16,
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    {'Hey '}
                    <span style={{ fontWeight: 700 }}>{'Arth 🤙,'}</span>
                    <br />
                    <br />
                    {'With more than '}
                    <span style={{ fontWeight: 700 }}>{'500'}</span>
                    {' units sold 🥳, our product '}
                    <span style={{ fontWeight: 700 }}>{'Cheerio WA'}</span>
                    {`is one of our best-seller🥇 and an ABSOLUTE favorite among our customers!`}
                    <br />
                    <br />
                    {`And that's why we want to give a chance to everyone to experience it. This month, we are giving `}
                    <span style={{ fontWeight: 700 }}>{'20'}</span>
                    {`% off on `}
                    <span style={{ fontWeight: 700 }}>{`Cheerio WA.`}</span>
                    {` Apply the code `}
                    <span style={{ fontWeight: 700 }}>{'CHEER20'}</span>
                    {` on checkout.`}
                    <br />
                    <br />
                    {`✅No minimum order required.`}
                    <br />
                    {`✅Cash on delivery available.`}
                    <br />
                    <br />
                    {`Call us at `}
                    <span style={{ fontWeight: 700 }}>{'9876598765'}</span>
                  </p>
                </>
              ) : ThankYou2 ? (
                <>
                  <p
                    className="px-3"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                      marginBlock: 16,
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    {'Hey '}
                    <span style={{ fontWeight: 700 }}>{'Arth 😊,'}</span>
                    <br />
                    <br />
                    {'Thankyou for recently buying '}
                    <span style={{ fontWeight: 700 }}>{`Cheerio`}</span>
                    {` Products.😍`}
                    <br />
                    <br />
                    {`To show our appreciation🥳, we've got a special offer for you FLAT `}
                    <span style={{ fontWeight: 700 }}>{`20%`}</span>
                    {` discount on all products.`}
                    <br />
                    <br />
                    {`Discount Code : `}
                    <span style={{ fontWeight: 700 }}>{`CHEER20`}</span>
                    <br />
                    <br />
                    {`Shop Now.`}
                  </p>
                </>
              ) : DoYouKnow ? (
                <>
                  <p
                    className="px-3"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                      marginBlock: 16,
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    {'Hi '}
                    <span style={{ fontWeight: 700 }}>{'Nishant,'}</span>
                    <br />
                    <br />
                    {`Do you know that `}
                    <span
                      style={{ fontWeight: 700 }}
                    >{`knowing your Prakriti helps you in the following ways:`}</span>
                    <br />
                    <br />
                    <span
                      style={{ fontWeight: 700 }}
                    >{`1. Get a Personalized treatment plan for your health issues`}</span>
                    <br />
                    <span
                      style={{ fontWeight: 700 }}
                    >{`2. Prevent any disease from occuring`}</span>
                    <br />
                    <span style={{ fontWeight: 700 }}>{`3. Improved quality of life`}</span>
                    <br />
                    <span
                      style={{ fontWeight: 700 }}
                    >{`4. Better management of chronic conditions`}</span>
                    <br />
                    <br />
                    {`You can `}
                    <span
                      style={{ fontWeight: 700 }}
                    >{`read about the 3 types of Prakriti on our Blog to know what they are and how you can manage your lifestyle better at https://bit.ly/42AUyHH`}</span>
                    <br />
                    <br />
                    {`You can also call us at `}
                    <span style={{ fontWeight: 700 }}>{`9876598765`}</span>
                    {` for any further information`}
                  </p>
                </>
              ) : Maintenance ? (
                <>
                  <p
                    className="px-3"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                      marginBlock: 16,
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    {'Hello '}
                    <span style={{ fontWeight: 700 }}>{'Nikhil,'}</span>
                    <br />
                    <br />
                    {`We are `}
                    <span
                      style={{ fontWeight: 700 }}
                    >{`looking into the issue that came to our server and will try to fix it as soon as possible.`}</span>
                    <br />
                    <br />
                    {`For more information feel free to reach to us.`}
                    <br />
                    <br />
                    {`Thanks`}
                    <br />
                    <span style={{ fontWeight: 700 }}>{`Team Eyecreative`}</span>
                    <br />
                  </p>
                </>
              ) : serviceMarathi ? (
                <>
                  <p
                    className="px-3"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                      marginBlock: 16,
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    {'नमस्ते '}
                    <span style={{ fontWeight: 700 }}>{'निखिल,'}</span>
                    <br />
                    <br />
                    {`आम्ही `}
                    <span
                      style={{ fontWeight: 700 }}
                    >{`आपल्यासाठी घेऊन आलो आहोत एक नवीन उत्पादन - रबर मॅट्स. ज्या आपण आपल्या रोजच्या वापरासाठी उपयोगात आणू शकतात.`}</span>
                    <br />
                    <br />
                    {`अधिक माहिती साठी आपण खाली दिलेल्या क्रमांकावर संपर्क करू शकतात अथवा या मेसेजला रिप्लाय देऊन माहिती मिळवू शकतात.`}
                    <br />
                    <br />
                    {`धन्यवाद`}
                    <br />
                  </p>
                </>
              ) : ReplyMultiple ? (
                <>
                  <p
                    className="px-3"
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                      marginBlock: 16,
                      width: '100%',
                      flexWrap: 'wrap',
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>{'"Good evening"'}</span>
                    {' Nishant,'}
                    <br />
                    <br />
                    {'You have to try '}
                    <span style={{ fontWeight: 700 }}>
                      {'"the new chatbot we have introduced".'}
                    </span>
                    <br />
                    <br />
                    {'Why?'}
                    <br />
                    <span style={{ fontWeight: 700 }}>{'- "It is made with NLP"'}</span>
                    <br />
                    <span style={{ fontWeight: 700 }}>
                      {'- "It is highly Customizable acc to business demand"'}
                    </span>
                    <br />
                    <span style={{ fontWeight: 700 }}>{'- "Can be trained in 2 minutes"'}</span>
                    <br />
                    <br />
                    {'What more?'}
                    <br />
                    <span style={{ fontWeight: 700 }}>{'- "It is completely free"'}</span>
                    <br />
                    <span style={{ fontWeight: 700 }}>
                      {'- "It is great for operations and support use cases"'}
                    </span>
                    <br />
                    <br />
                    {'Sounds Interesting?'}
                    <br />
                    <br />
                    {'Reply NOW to this message and get a '}
                    <span style={{ fontWeight: 700 }}>{'"free setup today"'}</span>
                    <br />
                  </p>
                </>
              ) : (
                <>
                  {data.template_type == 'customer_enquiry' ? (
                    <>
                      <p
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlock: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        {'Hello Rohan,'}
                        <br />
                        <br />
                        {`I am ${data.creator_name} from ${data?.business_name}. You had enquired about our services in the recent past. I just wanted to touch base with you once again to check if you are still interested in availing any of our packages. We are running a ${data?.promotion_text} promotional offer till ${data?.end_date}.`}
                        <br />
                        <br />
                        {`Just reply to this message and I will help you out at the earliest.`}
                        <br />
                        <br />
                        {'Regards,'}
                        <br />
                        {`${data?.business_name}`}
                      </p>
                    </>
                  ) : data.template_type == 'renewal_remainder' ? (
                    <>
                      <p
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlock: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        {'Hello Rohan,'}
                        <br />
                        <br />
                        {`We would like to inform you that ${data?.renewal_text}.Please contact us at ${data?.number} for more information / questions.`}
                        <br />
                        <br />
                        {`${data?.business_name}`}
                      </p>
                    </>
                  ) : data.template_type == 'thank_you_customer' ? (
                    <>
                      <p
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlock: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        {'Hello Rohan,'}
                        <br />
                        <br />
                        {`Thank you for ${data.thank_you_for}`}
                        <br />
                        <br />
                        {`Please find below ${data.link_desc} -`}
                        <br />
                        {`${data.link}`}
                        <br />
                        <br />
                        {'Below are some of the key features:'}
                        <br />
                        <br />
                        {`${data.feature_1}`}
                        <br />
                        {`${data.feature_2}`}
                        <br />
                        {`${data.feature_3}`}
                        <br />
                        {`${data.feature_4}`}
                        <br />
                        <br />
                        {'If you have any questions, please feel free to react out to us.'}
                        <br />
                        <br />
                        {'Best Regards'}
                        <br />
                        {`${data.creator_name}`}
                        <br />
                        {`${data.business_name}`}
                      </p>
                    </>
                  ) : data.template_type == 'share_info' ? (
                    <>
                      <p
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlock: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        {'Hey Rohan,'}
                        <br />
                        <br />
                        {`Wishing you a very ${data.greeting}`}
                        <br />
                        <br />
                        {`Here's a ${data.info_type} of the day we wanted to share with you.`}
                        <br />
                        <br />
                        {`${data.info}`}
                        <br />
                        <br />
                        {`In your journey to be an avid ${data.profession} we are always there for you.`}
                        <br />
                        <br />
                        {
                          'If you are interested in knowing more about how we can help, click the link below.'
                        }
                        <br />
                        <br />
                      </p>
                    </>
                  ) : data.template_type == 'additional_discount_offer' ? (
                    <>
                      <p
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlock: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        {`Dearest ${data.greeting},`}
                        <br />
                        <br />
                        {'These are Special times and they call for Special needs.'}
                        <br />
                        <br />
                        {`At ${data.business_name}, we would like you to pamper yourself this ${data.occassion}.`}
                        <br />
                        <br />
                        {`Shop now and get additional ${data.promotion_text}.`}
                        <br />
                        <br />
                        {`You can be rest assured that ${data.feature_1}.`}
                        <br />
                        <br />
                      </p>
                    </>
                  ) : data.template_type == 'repeatcustomer_offer' ||
                    data.template_type == 'repeatcustomer_offer2' ? (
                    <>
                      <p
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlock: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        {'Hi John,'}
                        <br />
                        <br />
                        {`Welcome to the ${data.business_name}.`}
                        {` Thank you for ${data.thank_you_for}.`}
                        {` It's time to fill those carts again with ${data.feature_1}.`}
                        <br />
                        <br />
                        {data.template_type == 'repeatcustomer_offer2'
                          ? ''
                          : `On the Special occasion of ${data.occassion}, `}
                        {`We are offering you a special discount of ${data.promotion_text}.`}
                        <br />
                        <br />
                        {`You can place your orders online on ${data.link}`}
                        <br />
                        <br />
                        {`Stock up on your essential ${data.feature_2}.`}
                        {` Call us at ${data.number}.`}
                        <br />
                        <br />
                      </p>
                    </>
                  ) : data.template_type == 'customer_win_back' ? (
                    <>
                      <p
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlock: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        {'Congratulations Rahul,'}
                        <br />
                        <br />
                        {`We have a ${data.promotion_text} in store for you!`}
                        <br />
                        <br />
                        {`We have been missing you on ${data.business_name}.`}
                        <br />
                        <br />
                        {`Why don't you check out the ${data.feature_1}!`}
                        <br />
                        <br />
                        {`In fact, here's a present for you, use the code ${data.discount_code}.`}
                        <br />
                        <br />
                        {'We will be waiting for you!'}
                        <br />
                        <br />
                        {'Hurry now, limited time offer!'}
                        <br />
                        <br />
                      </p>
                    </>
                  ) : data.template_type == 'business_info_newsletter' ? (
                    <>
                      <p
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlock: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        {'Hi Nishant,'}
                        <br />
                        <br />
                        {`Want to switch to ${data.product_name}? So what is stopping you?`}
                        <br />
                        <br />
                        {`${data.notworking_feature1}`}
                        <br />
                        {`${data.notworking_feature2}`}
                        <br />
                        {`${data.notworking_feature3}`}
                        <br />
                        <br />
                        {`Welcome to ${data.business_name} where we have a solution to all your obstacles:`}
                        <br />
                        <br />
                        {`${data.feature_1}`}
                        <br />
                        {`${data.feature_2}`}
                        <br />
                        {`${data.feature_3}`}
                        <br />
                        <br />
                        {`You can place your order online on ${data.link}`}
                        <br />
                        <br />
                        {`To know more, you can call us on ${data.number}`}
                        <br />
                        <br />
                      </p>
                    </>
                  ) : data.template_type == 'welcome_insta_message_2' ? (
                    <>
                      <p
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlock: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                          wordBreak: 'break-word',
                        }}
                      >
                        {'Hello Nishant,'}
                        <br />
                        <br />
                        {`Welcome to ${data.business_name}.`}
                        {` We are glad you chose us for ${data.product_name}.`}
                        <br />
                        <br />
                        {`Let's connect on Instagram for some fun`}
                        <br />
                        {`${data.instagram_handle}`}
                        <br />
                        <br />
                        {`If you need anything, write to us at ${data.support_email}`}
                        <br />
                        <br />
                        {`Here's your ${data.discount_text} discount code to shop any item on the website --`}
                        <br />
                        {`${data.link}`}
                        <br />
                        <br />
                        {`⚡️ No minimum purchase is required.`}
                        <br />
                        {`⚡️ This discount code is valid for the next ${data.duration} months.`}
                        <br />
                        <br />
                        {`Call us at ${data.number}`}
                      </p>
                    </>
                  ) : data.template_type == 'loyal_customers_new' ? (
                    <>
                      <p
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlock: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        {'Hey Arth 🤙,'}
                        <br />
                        <br />
                        {`With more than ${data.info}`}
                        {` units sold 🥳, our product ${data.product_name}`}
                        {` is one of our best-seller🥇 and an ABSOLUTE favorite among our customers!`}
                        <br />
                        <br />
                        {`And that's why we want to give a chance to everyone to experience it. This month, we are giving ${data.discounts}% off on ${data.product_name}.`}
                        {` Apply the code ${data.discount_code} on checkout.`}
                        <br />
                        <br />
                        {`✅ No minimum order required.`}
                        <br />
                        {`✅ Cash on delivery available.`}
                        <br />
                        <br />
                        {`Call us at ${data.number}`}
                      </p>
                    </>
                  ) : data.template_type == 'discount_all_products_new' ? (
                    <>
                      <p
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlock: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        {'Hey Arth 😊,'}
                        <br />
                        <br />
                        {`Thankyou for recently buying ${data.business_name} Products.😍`}
                        <br />
                        <br />
                        {`To show our appreciation 🥳, we've got a special offer for you FLAT ${data.discount_text} discount on all products.`}
                        <br />
                        <br />
                        {`Discount Code : ${data.discount_code}`}
                        <br />
                        <br />
                        {`Shop Now.`}
                      </p>
                    </>
                  ) : data.template_type == 'do_you_know' ? (
                    <>
                      <p
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlock: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        {'Hi Nishant,'}
                        <br />
                        <br />
                        {`Do you know that ${data.product_name}`}
                        <br />
                        <br />
                        {`${data.feature_1}`}
                        <br />
                        {`${data.feature_2}`}
                        <br />
                        {`${data.feature_3}`}
                        <br />
                        {`${data.feature_4}`}
                        <br />
                        <br />
                        {`You can ${data.info}`}
                        <br />
                        <br />
                        {`You can also call us at ${data.number} for any further information`}
                      </p>
                    </>
                  ) : data.template_type == 'maintainance_update' ? (
                    <>
                      <p
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlock: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        {'Hello Nikhil,'}
                        <br />
                        <br />
                        {`We are ${data.info}.`}
                        <br />
                        <br />
                        {`For more information feel free to reach to us.`}
                        <br />
                        <br />
                        {`Thanks`}
                        <br />
                        {`${data.business_name}`}
                      </p>
                    </>
                  ) : data.template_type == 'new_product_service_marathi' ? (
                    <>
                      <p
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlock: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        {'नमस्ते निखिल,'}
                        <br />
                        <br />
                        {`आम्ही ${data.product_name}.`}
                        <br />
                        <br />
                        {`अधिक माहिती साठी आपण खाली दिलेल्या क्रमांकावर संपर्क करू शकतात अथवा या मेसेजला रिप्लाय देऊन माहिती मिळवू शकतात.`}
                        <br />
                        <br />
                        {`धन्यवाद`}
                        <br />
                      </p>
                    </>
                  ) : data.template_type == 'reply_multiple' ? (
                    <>
                      <p
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlock: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        {`${data.creator_name} Nishant,`}
                        <br />
                        <br />
                        {`You have to try ${data.product_name}.`}
                        <br />
                        <br />
                        {'Why?'}
                        <br />
                        {`- ${data.feature_1}`}
                        <br />
                        {`- ${data.feature_2}`}
                        <br />
                        {`- ${data.feature_3}`}
                        <br />
                        <br />
                        {'What more?'}
                        <br />
                        {`- ${data.promotion_text}`}
                        <br />
                        {`- ${data.feature_4}`}
                        <br />
                        <br />
                        {'Sounds Interesting?'}
                        <br />
                        <br />
                        {`Reply NOW to this message and get a ${data.offer_name}`}
                        <br />
                      </p>
                    </>
                  ) : (
                    <>
                      <text
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlock: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        {'Hi Rohan,'}
                        <br />
                        <br />
                        {data.template_type == 'Product'
                          ? `${data.creator_name} has launched a new product "${data.product_name}".`
                          : data.template_type == 'Course'
                          ? `${data.creator_name} has launched a new course on "${data.product_name}."`
                          : data.template_type == 'Offer' &&
                            `${data.creator_name} has an exclusive offer for you on "${
                              data.product_name
                            }". ${data.discounts}% OFF for the next ${
                              DemoData?.expired ? DemoData?.expired : getTime(data.expired)
                            } days is available for you.`}
                      </text>
                      <text
                        className="px-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.white,
                          marginBlockStart: 8,
                          marginBlockEnd: 16,
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        {data.template_type == 'Product'
                          ? 'Buy now: '
                          : data.template_type == 'Course'
                          ? 'Register now: '
                          : data.template_type == 'Offer' && 'Claim now: '}
                        {/* <text style={{color:colors.linkblue,wordBreak:'break-word'}}>{data.template_link}</text> */}
                      </text>
                    </>
                  )}
                </>
              )}
              {ReplyMultiple !== true && (
                <div
                  className="d-flex flex-row justify-content-center align-items-center py-1"
                  style={{
                    width: '100%',
                    borderTopStyle: 'solid',
                    borderTopWidth: 1,
                    borderTopColor: colors.greys04,
                  }}
                >
                  <img
                    src={images.CAWAlink}
                    style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 10 }}
                  ></img>
                  <text
                    style={{ fontSize: 16, fontWeight: 400, color: colors.white, flexWrap: 'wrap' }}
                  >
                    {'Visit Website'}
                  </text>
                </div>
              )}
            </div>
            {ReplyMultiple == true && (
              <>
                <div
                  className="d-flex flex-row justify-content-center align-items-center py-2 my-1 w-75"
                  style={{ backgroundColor: '#1F2C34', borderRadius: 8, marginInline: 16 }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                    }}
                  >
                    {'Share Discounted Price'}
                  </p>
                </div>
                <div
                  className="d-flex flex-row justify-content-center align-items-center py-2 my-1 w-75"
                  style={{ backgroundColor: '#1F2C34', borderRadius: 8, marginInline: 16 }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                    }}
                  >
                    {'STOP'}
                  </p>
                </div>
                <div
                  className="d-flex flex-row justify-content-center align-items-center py-2 my-1 w-75"
                  style={{ backgroundColor: '#1F2C34', borderRadius: 8, marginInline: 16 }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 16,
                      fontWeight: 400,
                      color: colors.white,
                    }}
                  >
                    {'I want website link'}
                  </p>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        selectedTab == 'SMS' && (
          <>
            <div
              className="d-flex flex-column justify-content-start align-items-start my-2 py-2 px-3"
              style={{
                backgroundColor: '#1F2C34',
                width: '60%',
                paddingBlock: 24,
                borderRadius: 16,
              }}
            >
              <text
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: colors.white,
                  marginBlock: 16,
                  width: '100%',
                  flexWrap: 'wrap',
                }}
              >
                {'Hi, '}

                {data.template_type == 'Product'
                  ? `a new product has been added, "${data.product_name}" by "${data.creator_name}" that we at Cheerio think you will love! Check it out now`
                  : data.template_type == 'Course'
                  ? `a new course "${data.product_name}" by "${data.creator_name}" made available to you via Cheerio. To upskill, register now `
                  : data.template_type == 'Offer' &&
                    `we have an offer for you from ${data.creator_name},${data.product_name}. Buy Now`}
              </text>
              <p
                style={{
                  color: colors.linkblue,
                  marginTop: '-10px',
                }}
              >
                {data.template_link}
              </p>
              {data.template_type == 'Offer' && (
                <p
                  style={{
                    color: colors.white,
                    fontSize: '12px',
                    marginTop: '-3px',
                  }}
                >
                  {'-Powered by Cheerio'}
                </p>
              )}
              {/* <text
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: colors.white,
                  marginBlock: 16,
                  width: '100%',
                  flexWrap: 'wrap',
                }}
              >
                {data.template_type == 'Product'
                  ? 'Buy now: '
                  : data.template_type == 'Course'
                  ? 'Register now: '
                  : data.template_type == 'Offer' && 'Claim now: '}
                <text
                  style={{
                    color: colors.linkblue,
                    wordBreak: 'break-all',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {data.template_link}
                </text>
              </text> */}
            </div>
          </>
        )
      )}
    </>
  );
};

export default Preview;

const Styles = {
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  txtBody: {
    fontSize: 16,
    fontWeight: 400,
    color: colors.black,
  },
  txtOffer: {
    fontSize: 26,
    fontWeight: 400,
    color: colors.white,
    textAlign: 'center',
  },
};
