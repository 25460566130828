import React, { useEffect, useRef, useState } from 'react';
import colors from '../../Utils/colors';
import { TextField } from '@mui/material';
import images from '../../Utils/images';
import CheerioButton from '../../Components/CheerioButton';
import Switch from '@mui/material/Switch';
import CFFormProperties from './Components/CFFormProperties';
import CFLogo from './Components/CFLogo';
import { useNavigate, useParams } from 'react-router-dom';
import CFHeader from './Components/CFHeader';
import CFTitle from './Components/CFTitle';
import CFDescription from './Components/CFDescription';
import CFField from './Components/CFField';
import CFButton from './Components/CFButton';
import { toast } from 'react-toastify';
import CFPreview from './Components/CFPreview';
import { useSelector } from 'react-redux';
import {
  createEmbeddedForm,
  editEmbeddedForm,
  getEmbeddedFormsById,
  getClientAttributes,
} from '../../Services';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ShareModal from './Components/ShareModal'
import CFWatermark from './Components/CFWatermark'

const EFCreate = () => {
  const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''
    }`;
  const navigate = useNavigate();
  const authtoken = useSelector((state) => state.main.auth_token);
  const { id } = useParams();
  const formNameRef = useRef(null);

  const [formName, setFormName] = useState('Untitled Form');
  const [formActive, setFormActive] = useState(false);
  const [formID, setFormID] = useState(null);
  const [editField, setEditField] = useState(false);
  const [allAttributes, setAllAttributes] = useState(['name', 'email', 'mobile']);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [formProps, setFormProps] = useState({
    bodyColor: '#ffffff',
    textColor: '#000000',
    textFieldColor: '#ffffff',
    font: 'Segoe UI',
    borderRadius: '16',
    textSize: '16',
  });

  const [logoProps, setLogoProps] = useState({
    url: '',
    headerWidth: '30',
    padding: '0',
    cornerRadius: '8',
    alignment: 'center',
  });

  const [headerProps, setHeaderProps] = useState({
    url: '',
    headerWidth: '100',
    padding: '0',
    cornerRadius: '12',
    alignment: 'center',
  });

  const [titleProps, setTitleProps] = useState({
    title: '',
    font: 'Segoe UI',
    fontSize: '24',
    fontWeigth: 'Regular',
    alignment: 'center',
  });

  const [descriptionProps, setDescriptionProps] = useState({
    description: '',
    font: 'Segoe UI',
    fontSize: '16',
    fontWeigth: 'Regular',
    alignment: 'center',
  });

    const [buttonProps, setButtonProps] = useState({
        buttonText: '',
        buttonColor: '#835EF7',
        message: '',
        fixToBottom: false,
        alignment: 'center',
    })

    const [watermarkProps, setwatermarkProps] = useState({
        watermark: true,
    })

    const [formFields, setFormFields] = useState([
        { fieldName: 'Name', fieldType: 'text', mandatory: false, attributeName: 'name' },
        { fieldName: 'Email', fieldType: 'email', mandatory: false, attributeName: 'email' },
        { fieldName: 'Phone', fieldType: 'phone', mandatory: false, attributeName: 'mobile' },
        // Add more fields as needed
    ]);

  useEffect(() => {
    if (id) {
      getFormByID(id);
    }
  }, [id]);

  const getFormByID = (_id) => {
    let token = authtoken;
    getEmbeddedFormsById(token, _id).then((res) => {
      if (res?.flag) {
        setExistingData(res?.data);
        console.log('success ---> ', res);
      } else {
        console.log('error res ---> ', res);
      }
    });
  };

    const setExistingData = (data) => {
        setFormName(data?.formName);
        setFormActive(data?.active);
        setFormProps(data?.formProperties);
        setLogoProps(data?.logo);
        setHeaderProps(data?.headerImage);
        setTitleProps(data?.formTitle);
        setDescriptionProps(data?.formDescription);
        setFormFields(data?.formFields);
        setButtonProps(data?.formButton);
        setFormID(data?._id);
        setwatermarkProps(data?.watermark ?? {
            watermark: true,
        });
    }

  // useEffect(()=>{
  //     checkRequiredFields();
  // },[formFields])

  const checkRequiredFields = (updatedFields) => {
    const requiredFields = ['Name', 'Email', 'Phone'];
    const fieldNames = updatedFields.map((field) => field.fieldName);
    const missingFields = requiredFields.filter((field) => !fieldNames.includes(field));

    if (missingFields.length > 0) {
      toast.error(
        `Missing required fields: ${missingFields.join(
          ', '
        )}. These are required for workflows to function properly`,
        { position: 'top-right', autoClose: true }
      );
    }
  };

  const duplicateField = (index) => {
    if (formFields.length < 10) {
      const newField = { ...formFields[index] };
      const updatedFields = [...formFields];
      updatedFields.splice(index + 1, 0, newField);
      setFormFields(updatedFields);
    } else {
      alert("You can't add more than 10 fields.");
    }
  };

  const deleteField = (index) => {
    const updatedFields = formFields.filter((_, i) => i !== index);
    checkRequiredFields(updatedFields);
    setFormFields(updatedFields);
  };

  const updateField = (index, updatedField) => {
    const updatedFormFields = formFields.map((field, i) => (i === index ? updatedField : field));
    setFormFields(updatedFormFields);
  };

  const updateFormProps = (newProps) => {
    setFormProps((prevProps) => ({
      ...prevProps,
      ...newProps,
    }));
  };

  const updateLogoProps = (newProps) => {
    setLogoProps((prevProps) => ({
      ...prevProps,
      ...newProps,
    }));
  };

  const updateHeaderProps = (newProps) => {
    setHeaderProps((prevProps) => ({
      ...prevProps,
      ...newProps,
    }));
  };

  const updateTitleProps = (newProps) => {
    setTitleProps((prevProps) => ({
      ...prevProps,
      ...newProps,
    }));
  };

  const updateDescriptionProps = (newProps) => {
    setDescriptionProps((prevProps) => ({
      ...prevProps,
      ...newProps,
    }));
  };

    const updateButtonProps = (newProps) => {
        setButtonProps((prevProps) => ({
            ...prevProps,
            ...newProps,
        }));
    };

    const updateWatermarkProps = (newProps) => {
        setwatermarkProps((prevProps) => ({
            ...prevProps,
            ...newProps,
        }))
    }

    const saveFormAction = () => {
      if (formID) {
          editForm();
      } else {
          createForm();
      }
    }
    const createForm = () => {
        toast.loading('Creating embedded form', { toastId: 2 });
        let data = {
            formName: formName,
            formProperties: formProps,
            logo: logoProps,
            headerImage: headerProps,
            formTitle: titleProps,
            formDescription: descriptionProps,
            formFields: formFields,
            formButton: buttonProps,
            active: formActive,
            watermark: watermarkProps,
        };
        let token = authtoken;
        createEmbeddedForm(token, data).then((res) => {
            if (res?.flag) {
                toast.dismiss(2);
                toast.success('Embedded form created successfully')
                setFormID(res?.data?._id);
                console.log("response ---> ", res);
            } else {
                toast.dismiss(2);
                toast.error(res?.data)
                console.log("err ----> ", res);
            }
        })
    }

    const editForm = () => {
      if (formName.trim().length === 0) {
        toast.error('Form name cannot be blank');
      }
      else{
        toast.loading('Saving changes', { toastId: 3 });
        let data = {
            formName: formName,
            formProperties: formProps,
            logo: logoProps,
            headerImage: headerProps,
            formTitle: titleProps,
            formDescription: descriptionProps,
            formFields: formFields,
            formButton: buttonProps,
            active: formActive,
            formLink: `https://app.cheerio.in/form/${formID}`,
            _id: formID,
            watermark: watermarkProps,
        };
        let token = authtoken;
        editEmbeddedForm(token, data).then((res) => {
            if (res?.flag) {
                toast.dismiss(3);
                toast.success('Changes saved successfully')
                console.log("response ---> ", res);
            } else {
                toast.dismiss(3);
                toast.error(res?.data)
                console.log("err ----> ", res);
            }
        })
      }
       
    }

  useEffect(() => {
    if (editField) {
      if (formNameRef.current) {
        formNameRef.current.focus();
      }
    }
  }, [editField]);

  useEffect(() => {
    const indAttributesAPIcall = () => {
      let token = authtoken;
      getClientAttributes(token).then((res) => {
        if (res?.flag) {
          const attributes = res.data.map((item) => item.attribute);
          if (attributes.length === 0) {
            setAllAttributes(allAttributes);
          } else {
            const combinedAttributes = [...new Set([...attributes, ...allAttributes])];
            setAllAttributes(combinedAttributes);
          }
        } else {
          // console.log("ind attributes err ---> ",res)
        }
      });
    };
    indAttributesAPIcall();
  }, []);
  console.log('allAttributes ---> ', allAttributes);
  return (
    <>
      <div
        className="d-flex flex-column justify-content-start align-items-start"
        style={{
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        {open && <ShareModal setModalClose={handleClose} _id={formID} />}
        <div
          className="d-flex flex-row justify-content-between align-items-center w-100"
          style={{
            paddingBlock: 10,
            paddingInline: 30,
            borderBottom: `1px solid ${colors.borderwhite}`,
          }}
        >
          <div className="w-50 d-flex flex-row justify-content-start align-items-center">
            <img
              src={images.BackArrowBlack}
              style={{
                height: 20,
                width: 20,
                objectFit: 'contain',
                marginInlineEnd: 12,
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/creator/embeddableForms');
              }}
            ></img>
            {/* <h3 style={{
                    fontSize:20,
                    fontWeight:600,
                }}>
                    {'Create Embedded Form'}
                </h3> */}
            <TextField
              inputRef={formNameRef}
              defaultValue="Untitled"
              variant="standard"
              InputProps={{
                disableUnderline: true,
                fullWidth: true,
                style: {
                  fontSize: 20,
                  fontWeight: 600,
                },
              }}
              inputProps={{
                maxLength: 30,
              }}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: 'black',
                },
                input: { cursor: 'default' },
                width: 300,
              }}
              disabled={editField ? false : true}
              value={formName}
              onChange={(e) => {
                setFormName(e.target.value);
              }}
            />
            <img
              src={editField ? images.WITick : images.EditSender}
              style={{
                marginInlineStart: 6,
                height: 18,
                width: 18,
                objectFit: 'contain',
                cursor: 'pointer',
              }}
              onClick={() => {
                setEditField(!editField);
              }}
            ></img>
          </div>
          <div className="w-50 d-flex flex-row justify-content-end align-items-center">
            <div
              className="d-flex flex-row justify-content-center align-items-center"
              style={{ marginInline: 8 }}
            >
              <p>{formActive ? 'Active' : 'Inactive'}</p>
              <Switch
                checked={formActive}
                onChange={(e) => {
                  setFormActive(e.target.checked);
                }}
                sx={{ color: colors.primary03 }}
              />
            </div>
            <CheerioButton
              borderStyle={{ border: `1px solid ${colors.black}`, marginInline: 8 }}
              backColor={colors.white}
              textStyle={{ color: colors.black }}
              btnText={'Save Form'}
              disabled={false}
              onclick={() => {
                saveFormAction();
                // navigate('/creator/embeddableForms/create');
              }}
            />
            <CheerioButton
              borderStyle={{ border: `1px solid ${colors.primary03}`, marginInline: 8 }}
              backColor={colors.primary03}
              textStyle={{ color: colors.white }}
              btnText={'Share'}
              disabled={formID ? false : true}
              onclick={() => {
                handleOpen();
                // navigate('/creator/embeddableForms/create');
              }}
            />
          </div>
        </div>
        <div
          className="w-100 d-flex flex-row justify-content-between align-items-center"
          style={{ flex: 1, overflowY: 'hidden' }}
        >
          <div
            className="h-100 d-flex flex-column justify-content-start align-items-center verythinscrollnew"
            style={{
              width: '65%',
              overflowY: 'auto',
              paddingInline: 30,
              paddingBlock: 20,
            }}
          >
            <CFFormProperties formProps={formProps} updateFormProps={updateFormProps} />
            <CFLogo logoProps={logoProps} updateLogoProps={updateLogoProps} />
            <CFHeader headerProps={headerProps} updateHeaderProps={updateHeaderProps} />
            <CFTitle titleProps={titleProps} updateTitleProps={updateTitleProps} />
            <CFDescription
              descriptionProps={descriptionProps}
              updateDescriptionProps={updateDescriptionProps}
            />
            {formFields.map((field, index) => (
              <CFField
                key={index}
                index={index}
                field={field}
                updateField={updateField}
                duplicateField={() => {
                  duplicateField(index);
                }}
                deleteField={() => {
                  deleteField(index);
                }}
                attributes={allAttributes}
              />
            ))}

            <div
              className="d-flex flex-row justify-content-center align-items-center"
              style={{
                backgroundColor: colors.white01,
                borderRadius: 12,
                border: `1px solid ${colors.borderwhite}`,
                paddingInline: 20,
                paddingBlock: 16,
                marginBlock: 8,
                cursor: 'pointer',
              }}
              onClick={() => {
                if (formFields?.length < 10) {
                  const newField = { fieldName: 'New Field', fieldType: 'text', mandatory: false };
                  setFormFields([...formFields, newField]);
                } else {
                  toast.error("You can't add more than 10 fields.", {
                    position: 'top-right',
                    autoClose: true,
                  });
                }
              }}
            >
              <img
                src={images.PlusIcon}
                style={{
                  height: 16,
                  width: 16,
                  objectFit: 'contain',
                }}
              ></img>
              <p style={{ marginInlineStart: 8, color: colors.greys04 }}>{'Add a field'}</p>
            </div>

                        <CFButton
                            buttonProps={buttonProps}
                            updateButtonProps={updateButtonProps}
                        />

                        <CFWatermark 
                            watermarkProps={watermarkProps}
                            updateWatermarkProps={updateWatermarkProps}
                        />

                    </div>
                    <div className='h-100' style={{
                        width: '35%',
                        paddingInline: 30,
                        paddingBlock: 20,
                        overflowY: 'auto',
                    }}>
                        <CFPreview
                            formProps={formProps}
                            logoProps={logoProps}
                            headerProps={headerProps}
                            titleProps={titleProps}
                            descriptionProps={descriptionProps}
                            formFields={formFields}
                            buttonProps={buttonProps}
                            watermarkProps={watermarkProps}
                            openformurlaction={() => {
                                if (formID) {
                                    window.open(`${baseUrl}/form/${formID}`)
                                } else {
                                    toast.error('You need to save the form to see the url preview', { position: 'top-right', autoClose: true })
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default EFCreate;
