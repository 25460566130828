import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getCampaignListAPI } from '../../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { options } from 'linkifyjs';

const CampaignSentModal = ({ data, setData }) => {

    const authtoken = useSelector((state) => state.main.auth_token);
    const localdata = useSelector((state) => state.main.localdata);
    const [campaignList, setCampaignList] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getCampaignListAPICall();
    }, [])

    useEffect(() => {
        if (selectedCampaign?.campaign_id) {
            setData({
                ...data,
                campaignId: selectedCampaign?.campaign_id,
                campaignName: selectedCampaign?.template_name,
            })
        }
    }, [selectedCampaign])

    const getCampaignListAPICall = () => {
        let token = authtoken;
        getCampaignListAPI(token).then((res) => {
            if (res?.flag) {
                setCampaignList(res?.data?.list);
                setIsLoading(false);
            } else {
                toast.error('Error occurred while fetching campaign list, please try again later', {})
            }
        });
    }

    useEffect(() => {
        if (campaignList.length > 0 && data?.campaignId) {
            setExistingData();
        }
    }, [campaignList])

    const setExistingData = () => {
        let preselectedCampaign = campaignList.filter((item) => item?.campaign_id === data?.campaignId)
        setSelectedCampaign(preselectedCampaign[0]);
    }

    return (
        <>
            <div className='w-100 h-100 d-flex flex-column justify-content-start align-items-start'>
                <h4 className='' style={{ marginBlock: 16 }}>
                    {'Choose campaign'}
                </h4>
                <Autocomplete
                    disablePortal
                    getOptionLabel={(options) => options?.template_name || ""}
                    onChange={(e, v) => {
                        setSelectedCampaign(v);
                    }}
                    value={selectedCampaign}
                    key={options?.campaign_id}
                    id="choose-campaign-select"
                    options={campaignList}
                    sx={{ width: '100%', marginBlockEnd: 8 }}
                    loading={isLoading}
                    loadingText={'loading...'}
                    renderInput={(params) => <TextField {...params} label={"search / select campaign name"} />}
                />
            </div>
        </>
    )
}

export default CampaignSentModal