import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import colors from '../../../Utils/colors';
import { useSelector } from 'react-redux';
import { ShopifyCustomersApi, getShopifyDetailsApi } from '../../../Services';
import images from '../../../Utils/images';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import CheerioButton from '../../../Components/CheerioButton';
import CreateContactModal from './CreateContactModal';
import { axiosInstance } from '../../../Config/axiosConfig';
import { useQuery } from '@tanstack/react-query';

const CustomersData = () => {
  const [labelName, setLabelName] = useState('');
  const [shopifyDetails, setShopifyDetails] = useState();
  const [customerData, setCustomerData] = useState([]);
  const [IntegrationStatus, setIntegrationStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openCCModal, setOpenCCModal] = useState(false);

  const localdata = useSelector((state) => state.main.localdata);
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);

  // useEffect(() => {
  //   setIsLoading(true);
  //   getShopifyDetailsAPIcall();
  // }, []);
  //
  // const getShopifyDetailsAPIcall = () => {
  //   let data = {
  //     _id: localdata?._id,
  //   };
  //   let token = authtoken;
  //   getShopifyDetailsApi(data, token).then((res) => {
  //     if (res.flag) {
  //       setShopifyDetails(res?.data?.result);
  //       setIntegrationStatus(true);
  //     } else {
  //       setIntegrationStatus(false);
  //       console.log('no shopify details exist ===> ', res);
  //     }
  //     setIsLoading(false);
  //   });
  // };
  //
  // useEffect(() => {
  //   if (shopifyDetails?._id) {
  //     console.log('shopifyDetials ===> ', shopifyDetails);
  //     shopifyCustomersAPIcall();
  //   }
  // }, [shopifyDetails]);
  //
  // const shopifyCustomersAPIcall = () => {
  //   setIsLoading(true);
  //   let data = {
  //     storeName: shopifyDetails?.storeName,
  //     access_token: shopifyDetails?.access_token,
  //   };
  //   let token = authtoken;
  //   console.log('Data sent ===> ', data);
  //   ShopifyCustomersApi(data, token).then((res) => {
  //     if (res?.flag) {
  //       setCustomerData(res?.data?.customers);
  //       console.log('success res customer list ===> ', res);
  //     } else {
  //       console.log('error res customer list ===> ', res);
  //     }
  //     setIsLoading(false);
  //   });
  // };
  const dataCall = async () => {
    return axiosInstance.get('woocommerce/addKeys' + `?userId=${localdata?._id}`, {
      headers: {
        'x-access-token': authtoken,
      },
    }).then((response) => response.data);
  };

  const { data, isLoading: loading, isError } = useQuery(['ServeDataFtch'], () => dataCall());

  if (loading) {
    return <p>Loading...</p>;
  }

  const [cusData, setCusData] = useState([]);

  useEffect(()=>{
    if (data.exists) {
      axiosInstance
        .get("woocommerce/getAllCustomers?user_id="+localdata?._id, {
          headers: {
            'x-access-token': authtoken,
          },
        }).then(res=>{
        setCusData(res.data.userData);
      })
    }
  },[data, authtoken, localdata?._id])

  return (
    <>
      <div
        className="h-100 w-100 verythinscrollnew"
        style={{
          overflowY: 'auto',
          backgroundColor: colors.white02,
          border: `1px solid ${colors.borderwhite}`,
        }}
      >
        <div
          className="d-flex flex-column justify-content-start align-items-center w-100 "
          style={{ flex: 1 }}
        >
          {openCCModal && <CreateContactModal setOpenCCModal={setOpenCCModal} />}
          {isLoading ? (
            <>
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ height: '70vh' }}
              >
                <SpinnerLoader />
              </div>
            </>
          ) : (
            <>
              <div
                className="d-flex flex-row justify-content-between align-items-center w-100"
                style={{ marginBlockStart: 30, marginBlockEnd: 16, paddingInline: 30 }}
              >
                <h2
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 20,
                    fontWeight: 700,
                    color: colors.black,
                  }}
                >
                  {'Customers'}
                </h2>
                {data.exists && (
                  <CheerioButton
                    borderStyle={{ paddingBlock: 10 }}
                    textStyle={{ fontSize: 12, fontWeight: 600, color: colors.white }}
                    btnText={'Save as contact list'}
                    backColor={colors.black}
                    onclick={() => {
                      setOpenCCModal(true);
                    }}
                    icon={images.IPlusWhite}
                  />
                )}
              </div>

              {data.exists ? (
                <>
                  <div
                    className="d-flex flex-row justify-content-start align-items-center"
                    style={{
                      width: 'calc( 100% - 60px )',
                      height: 40,
                      backgroundColor: '#F0F2F2',
                      marginBlockStart: 16,
                    }}
                  >
                    <div
                      className="h-100"
                      style={{
                        width: '20%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRight: '1px solid #B3B3B3',
                      }}
                    >
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 14,
                          fontWeight: 600,
                          color: colors.black04,
                        }}
                      >
                        {'Name'}
                      </p>
                    </div>
                    <div
                      className="h-100"
                      style={{
                        width: '20%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRight: '1px solid #B3B3B3',
                      }}
                    >
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 14,
                          fontWeight: 600,
                          color: colors.black04,
                        }}
                      >
                        {'Phone no.'}
                      </p>
                    </div>
                    <div
                      className="h-100"
                      style={{
                        width: '20%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRight: '1px solid #B3B3B3',
                      }}
                    >
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 14,
                          fontWeight: 600,
                          color: colors.black04,
                        }}
                      >
                        {'Location'}
                      </p>
                    </div>

                    <div
                      className="h-100"
                      style={{
                        width: '40%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRight: '1px solid #B3B3B3',
                      }}
                    >
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 14,
                          fontWeight: 600,
                          color: colors.black04,
                        }}
                      >
                        {'Email ID'}
                      </p>
                    </div>

                    {/*<div*/}
                    {/*  className="h-100"*/}
                    {/*  style={{*/}
                    {/*    width: '15%',*/}
                    {/*    display: 'flex',*/}
                    {/*    justifyContent: 'center',*/}
                    {/*    alignItems: 'center',*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  <p*/}
                    {/*    style={{*/}
                    {/*      padding: 0,*/}
                    {/*      margin: 0,*/}
                    {/*      fontSize: 14,*/}
                    {/*      fontWeight: 600,*/}
                    {/*      color: colors.black04,*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    {'Orders Count'}*/}
                    {/*  </p>*/}
                    {/*</div>*/}
                  </div>

                  <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{ width: 'calc( 100% - 60px )' }}
                  >
                    {cusData &&
                      cusData.map((item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="d-flex flex-row justify-content-center align-items-center w-100"
                              style={{}}
                            >
                              <div
                                className="d-flex flex-row justify-content-start align-items-center"
                                style={{
                                  width: '20%',
                                  paddingBlock: 14,
                                  paddingInline: 10,
                                  borderRight: '1px solid #B3B3B3',
                                }}
                              >
                                <p
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: colors.black04,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    wordBreak: 'break-all',
                                  }}
                                >
                                  {item.name}
                                </p>
                              </div>
                              <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{
                                  width: '20%',
                                  paddingBlock: 14,
                                  paddingInline: 10,
                                  borderRight: '1px solid #B3B3B3',
                                }}
                              >
                                <p
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: colors.black04,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    wordBreak: 'break-all',
                                  }}
                                >
                                  {item.phone}
                                </p>
                              </div>
                              <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{
                                  width: '20%',
                                  paddingBlock: 14,
                                  paddingInline: 10,
                                  borderRight: '1px solid #B3B3B3',
                                }}
                              >
                                <p
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: colors.black04,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    wordBreak: 'break-all',
                                  }}
                                >
                                  {item.location}
                                </p>
                              </div>
                              <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{
                                  width: '40%',
                                  paddingBlock: 14,
                                  paddingInline: 10,
                                  borderRight: '1px solid #B3B3B3',
                                }}
                              >
                                <p
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: colors.black04,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    wordBreak: 'break-all',
                                  }}
                                >
                                  {item.email}
                                </p>
                              </div>
                              {/*<div*/}
                              {/*  className="d-flex flex-row justify-content-center align-items-center"*/}
                              {/*  style={{*/}
                              {/*    width: '15%',*/}
                              {/*    paddingBlock: 14,*/}
                              {/*    paddingInline: 10,*/}
                              {/*    cursor: 'pointer',*/}
                              {/*  }}*/}
                              {/*>*/}
                              {/*  <p*/}
                              {/*    style={{*/}
                              {/*      padding: 0,*/}
                              {/*      margin: 0,*/}
                              {/*      fontSize: 14,*/}
                              {/*      fontWeight: 400,*/}
                              {/*      color: colors.black04,*/}
                              {/*      textOverflow: 'ellipsis',*/}
                              {/*      whiteSpace: 'nowrap',*/}
                              {/*      overflow: 'hidden',*/}
                              {/*      wordBreak: 'break-all',*/}
                              {/*    }}*/}
                              {/*  >*/}
                              {/*    /!*{item?.orders_count}*!/*/}
                              {/*  </p>*/}
                              {/*</div>*/}
                            </div>
                          </>
                        );
                      })}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ height: '70vh' }}
                  >
                    <p style={{ fontSize: 16, fontWeight: 400, color: colors.greys04 }}>
                      {'Integrate WooCommerce Store to continue'}
                    </p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomersData;
