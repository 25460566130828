import React from 'react'
import colors from '../../../Utils/colors'
import { Switch } from '@mui/material'
import { useSelector } from 'react-redux';

const CFWatermark = ({ watermarkProps, updateWatermarkProps }) => {

    const localdata = useSelector((state) => state.main.localdata);

    return (
        <>
            <div className='d-flex flex-row justify-content-start align-items-center w-100' style={{
                backgroundColor: colors.white01,
                borderRadius: 12,
                border: `1px solid ${colors.borderwhite}`,
                paddingInline: 20,
                paddingBlock: 16,
                marginBlock: 8,
            }}>
                <h4 className='' style={{
                    fontSize: 18,
                    fontWeight: 700,
                    marginInlineEnd: 16,
                }}>
                    {'Watermark'}
                </h4>
                <Switch
                    checked={watermarkProps?.watermark}
                    onChange={(e) => {
                        updateWatermarkProps({ ['watermark']: e.target.checked })
                    }}
                    sx={{ color: colors.primary03 }}
                    size='small'
                    disabled={localdata?.premiumPlanName == 'Pro' || localdata?.premiumPlanName == 'Enterprise' || localdata?._id == '66c12a6a2447ac755dbf9233' ? false : true}
                />
            </div>
        </>
    )
}

export default CFWatermark