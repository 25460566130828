import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import SidemenuKylas from './Sidemenu';
import { SetupKylas } from './SetupKylas';
import { PremiumOptionModal } from '../../../Components/Modal/PremiumOptionModal';

const Kylas = () => {
  const [openSetup, setOpenSetup] = useState(true);
  const [openFlow, setOpenFlow] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState('Integration');
  const [selectedCampaignFlow, setSelectedCampaignFlow] = useState('');
  const localData = useSelector((state) => state.main.localdata);

  const isPremiumUser = localData?.premium;

  return (
    <>
      {!isPremiumUser && (
        <PremiumOptionModal
          option={'WooCommerce'}
          openPremiumModal={true}
          setOpenPremiumModal={() => {}}
        ></PremiumOptionModal>
      )}
      <div className="Row w-100 h-100" style={{}}>
        <SidemenuKylas
          openSetup={openSetup}
          setOpenSetup={setOpenSetup}
          openFlow={openFlow}
          setOpenFlow={setOpenFlow}
          selectedMenuItem={selectedMenuItem}
          setSelectedMenuItem={setSelectedMenuItem}
        />

        <div className="Column justify-content-start h-100" style={{ flex: 1 }}>
          {selectedMenuItem == 'Integration' && (
            <SetupKylas setSelectedMenuItem={setSelectedMenuItem} />
          )}
        </div>
      </div>
    </>
  );
};

export default Kylas;
