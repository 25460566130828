import { createSlice } from '@reduxjs/toolkit';
export const labelRedux = createSlice({
  name: 'labelRedux',
  initialState: {
    triggerState: 0,
  },
  reducers: {
    triggerReRender: (state) => {
      state.triggerState = state.triggerState + 1;
    },
  },
});
export const { triggerReRender } = labelRedux.actions;
export default labelRedux.reducer;
