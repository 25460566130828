import { createReducer } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

const initialState = {
  interest: [],
  showCTWA: false,
  redirectToLogin: false,
  campaignId: null,
  selectedEmailJson: null,
};

export const mainReducer = createReducer(initialState, {
  addLocaldata: (state, action) => {
    state.localdata = action.payload;
  },
  redirectToLogin: (state, action) => {
    state.redirectToLogin = action.payload;
  },
  addAgentdata: (state, action) => {
    state.agentdata = action.payload;
  },
  addWAdata: (state, action) => {
    state.WAdata = action.payload;
  },
  addMobile: (state, action) => {
    state.mobile = action.payload;
  },
  addGoogleId: (state, action) => {
    state.google_id = action.payload;
  },
  addSessionId: (state, action) => {
    state.session_id = action.payload;
  },
  addUserId: (state, action) => {
    state._id = action.payload;
  },
  addFacebookId: (state, action) => {
    state.facebook_id = action.payload;
  },
  addUserPic: (state, action) => {
    state.user_pic = action.payload;
  },
  addAuthToken: (state, action) => {
    state.auth_token = action.payload;
  },
  addUsername: (state, action) => {
    state.username = action.payload;
  },
  addCompanyname: (state, action) => {
    state.companyname = action.payload;
  },
  addEmail: (state, action) => {
    state.email = action.payload;
  },
  addGender: (state, action) => {
    state.gender = action.payload;
  },
  addProfilePic: (state, action) => {
    state.profile_pic = action.payload;
  },
  addAge: (state, action) => {
    state.age = action.payload;
  },
  addInterest: (state, action) => {
    state.interest = action.payload;
  },
  addLogin: (state, action) => {
    state.login_status = action.payload;
  },
  addBalance: (state, action) => {
    state.balance = action.payload;
  },
  addLastMessage: (state, action) => {
    state.last_message = action.payload;
  },
  tempFBToken: (state, action) => {
    state.tempFBToken = action.payload;
  },
  showCTWAReplies: (state, action) => {
    state.showCTWA = action.payload;
  },
  addCampaignId: (state, action) => {
    state.campaignId = action.payload;
  },
  addNumberVerified: (state, action) => {
    state.numberVerified = action.payload;
  },
  addSelectedEmailJson: (state, action) => {
    state.selectedEmailJson = action.payload;
  },
  logout: (state, action) => {
    storage.removeItem('persist:root');
    return {
      ...initialState,
      redirectToLogin: true,
    };
  },
});
