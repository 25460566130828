import React, { useEffect, useState } from 'react';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import './Modal.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setupWASMSApi } from '../../Services';
import ReactGA from 'react-ga4';
import { eventsend } from '../../Config/analyticsFunctions';

const SetupSMS = ({ setIsOpen, onclick }) => {
  const [DLTId, setDLTId] = useState('');
  const [SMSBName, setSMSBName] = useState('');
  const [status, setStatus] = useState('');
  const [success, setSuccess] = useState(false);

  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);

  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.event('setup_sms_channel_pageview');
    eventsend('setup_sms_channel_pageview');
  }, []);

  const onclicksubmit = () => {
    let data = {
      user_id: localdata?._id ? localdata?._id : _id,
      account_id: DLTId,
      type: 'sms',
      business_name: SMSBName,
      mobile: '',
    };
    let token = authtoken;
    ReactGA.event('setup_sms_channel_submit_click', { data: data });
    eventsend('setup_sms_channel_submit_click', { data: data });
    setupWASMSApi(data, token).then((res) => {
      if (res.flag) {
        const resData = res?.data?.saveConfig;
        setStatus(resData?.status == 'Active' ? 'success' : 'process');
      } else {
        setStatus('error');
      }
    });
  };

  useEffect(() => {
    if (status == 'success') {
      setSuccess(true);
    }
  }, [status]);

  return (
    <>
      <div style={Styles.DarkBg}>
        <div className="" style={{ ...Styles.Center }}>
          <div
            className="d-flex flex-column justify-content-evenly align-items-center py-4"
            style={{
              backgroundColor: colors.white,
              borderRadius: 16,
              width: 500,
              height: '90vh',
              zIndex: 0,
              paddingInline: 38,
            }}
          >
            <img
              src={images.CreatorClose}
              style={{
                height: 34,
                width: 34,
                objectFit: 'contain',
                position: 'absolute',
                top: 15,
                right: 15,
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsOpen(false);
              }}
            ></img>
            {success ? (
              <>
                <div className="d-flex flex-column justify-content-between align-items-center w-100">
                  <img
                    src={images.CATSuccess}
                    style={{ height: 150, width: 150, objectFit: 'contain', paddingBlock: 16 }}
                  />
                  <text
                    style={{
                      fontSize: 20,
                      fontWeight: 700,
                      color: colors.success03,
                      paddingBlock: 16,
                    }}
                  >
                    {'SMS Setup Successful'}
                  </text>
                  <Button
                    variant="primary"
                    style={{
                      width: '60%',
                      marginBlock: 24,
                      backgroundColor: colors.primary03,
                      borderWidth: 0,
                    }}
                    onClick={() => {
                      navigate('/creator');
                      setIsOpen(false);
                    }}
                  >
                    {'Back to Home'}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                  <img
                    src={images.CreatorSMSS}
                    style={{ height: 64, width: 64, objectFit: 'contain', marginBlock: 40 }}
                  ></img>
                  <text style={{ ...Styles.TxtHeading, marginBlock: 8 }}>
                    {'Setup SMS channel'}
                  </text>
                  <text
                    style={{
                      ...Styles.TxtBody2,
                      marginBlock: 8,
                      flexWrap: 'wrap',
                      textAlign: 'center',
                    }}
                  >
                    {
                      'Setup your SMS channel with Cheerio in 2 easy & quick steps and share SMS with audience'
                    }
                  </text>
                </div>

                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                  <InputGroup className="my-3">
                    <Form.Label style={{ ...Styles.TxtBody }}>{'DLT PE Id'}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Id"
                      className="w-100 btncustom"
                      style={{ borderRadius: 8 }}
                      value={DLTId}
                      onChange={(e) => {
                        setDLTId(e.target.value);
                      }}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <Form.Label style={{ ...Styles.TxtBody }}>{'Business Name'}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      className="w-100 btncustom"
                      style={{ borderRadius: 8 }}
                      value={SMSBName}
                      onChange={(e) => {
                        setSMSBName(e.target.value);
                      }}
                    />
                  </InputGroup>
                </div>

                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                  <Button
                    variant="primary"
                    style={{
                      width: '60%',
                      marginBlock: 16,
                      backgroundColor: colors.primary03,
                      borderWidth: 0,
                    }}
                    onClick={() => {
                      onclicksubmit();
                    }}
                    disabled={DLTId && SMSBName ? false : true}
                  >
                    {'Submit'}
                  </Button>
                  <text style={{ ...Styles.TxtBody, fontWeight: 600, marginBlockEnd: 16 }}>
                    {'Don’t have these? '}
                    <text
                      style={{ color: colors.linkblue, cursor: 'pointer' }}
                      onClick={() => {
                        setIsOpen(false);
                        navigate('/documentation/sms');
                      }}
                    >
                      {'Read documentation'}
                    </text>
                  </text>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupSMS;

const Styles = {
  DarkBg: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    width: '100vw',
    height: '100vh',
    zIndex: 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'fixed',
  },
  Center: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-28%,-50%)',
  },
  TxtHeading: {
    fontSize: 24,
    fontWeight: 700,
    color: colors.black,
  },
  TxtBody: {
    fontSize: 14,
    fontWeight: 700,
    color: colors.black,
  },
  TxtBody2: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.greys04,
  },
  itemborder: {
    backgroundColor: colors.white,
    borderRadius: 16,
    boxShadow: '0px 0px 10px 0 rgba(0, 0, 0, 0.15)',
  },
};
