import React from 'react';
import CheerioButton from '../CheerioButton';
import colors from '../../Utils/colors';

const DeleteModal = ({ setDeleteModalOpen, deleteAction }) => {
  return (
    <>
      <div
        className="ModalDarkBg"
        style={{ top: 300 }}
        onClick={() => {
          setDeleteModalOpen(false);
        }}
      >
        <div className="Center">
          <div
            className="delete_modal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="delete_modal__header">
              <h3>{'Delete Confirmation'}</h3>
            </div>
            <p>{'Deletion is a permanent action, please proceed with caution.'}</p>
            <div className="delete_modal__button_row">
              <CheerioButton
                borderStyle={{ borderColor: colors.black, width: '45%' }}
                textStyle={{ color: colors.white03 }}
                btnText={'Delete'}
                backColor={colors.red49}
                onclick={() => {
                  deleteAction();
                }}
              />
              <CheerioButton
                borderStyle={{ borderColor: colors.black, width: '45%' }}
                textStyle={{ color: colors.black }}
                btnText={'Cancel'}
                backColor={colors.white01}
                onclick={() => {
                  setDeleteModalOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
