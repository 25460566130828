import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import ReactGA from 'react-ga4';

import AuthSignUp from './AuthSignUp';
import ForgotPass from './ForgotPass';
//import AuthLogin from './AuthLogin';
import NewAuthLogin from './NewAuthLogin';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  checkAgentOTPV2,
  checkEmailOTP,
  checkEmailOTPV2,
  checkGmailUserV2,
  checkUserOTP,
  checkUserOTPV2,
  freeTrialApi,
  loginADMIN,
  newSignUp,
  userLogin,
  sendAgentOTPV2,
  sendEmailOTP,
  sendEmailOTPV2,
  sendUserOTP,
  sendUserOTPV2,
  userSignupV2Api,
  userSignupWithEmail,
  newPassword,
  newAgentPassword,
  checkEmailExists,
  checkPhoneExists,
  sendOTP_V3,
  checkAgentLogin,
  findAgent,
  checkAgentEmailOtp,
  twoFactorLogin,
  directpaymentSignup,
} from '../../../Services';
import axios from 'axios';
import OTPVerifyForSignUp from './OTPVerifyForSignUp';
import EventEmitter from '../../../Utils/EventEmitter';
import { eventsend, setuserid } from '../../../Config/analyticsFunctions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ValidateOtpPage from './ValidateOtpPage';
import SendOtpReq from './SendOtpReq';
import TwoFactorLoginPage from './TwoFactorLogin';
import { Beacon } from '@beacon.li/bar';
import { handleSaveContacts } from '../../../Utils/brevo';

export default function LoginSignUp({ openOtpAction, closeOtpAction }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _id = useSelector((state) => state.main?._id);
  const facebook_id = useSelector((state) => state.main?.facebook_id);
  const user_pic = useSelector((state) => state.main?.user_pic);
  const Mobile = useSelector((state) => state.main?.mobile);
  const username = useSelector((state) => state.main?.username);
  const company_name = useSelector((state) => state.main?.companyname);
  const gender = useSelector((state) => state.main?.gender);
  const age = useSelector((state) => state.main?.age);
  const interest = useSelector((state) => state.main?.interest);
  const auth_token = useSelector((state) => state.main?.auth_token);
  const email = useSelector((state) => state.main?.email);
  const localdata = useSelector((state) => state.main.localdata);
  const redirectToLogin = useSelector((state) => state.redirectToLogin);

  const [session_id, setSession_id] = useState('');
  const [isLogin, setIsLogin] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [detailsVerified, setDetailsVerified] = useState(false);
  const [verificationReqSent, setVerificationReqSent] = useState(false);
  const [isMobileLogin, setIsMobileLogin] = useState(true);
  const [countryCode, setCountryCode] = useState('');
  const [numbervalue, setNumberValue] = useState('');
  const [password, setPassword] = useState('');
  const [OTPsent, setOTPsent] = useState(false);
  const [emailOTPsent, setEmailOTPsent] = useState(false);
  const [phoneOTPsent, setPhoneOTPsent] = useState(false);
  const [OTPvalue, setOTPvalue] = useState('');
  const [phoneOTPvalue, setPhoneOTPvalue] = useState('');
  const [emailOTPvalue, setEmailOTPvalue] = useState('');
  const [incorrectOTP, setIncorrectOTP] = useState(false);
  const [incorrectEmail, setIncorrectEmail] = useState(false);
  const [incorrectFbId, setIncorrectFbId] = useState(false);
  const [incorrectPhoneOTP, setIncorrectPhoneOTP] = useState(false);
  const [incorrectEmailOTP, setIncorrectEmailOTP] = useState(false);
  const [googleUser, setGoogleUser] = useState([]);
  const [googleUserProfile, setGoogleUserProfile] = useState([]);
  const [user_email, setEmail] = useState('');
  const [isEmail, setIsEmail] = useState(false);
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [twoFactorModal, setTwoFactorModal] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [emailOtpVerified, setEmailOtpVerified] = useState(false);
  const [phoneOtpVerified, setPhoneOtpVerified] = useState(false);
  const [newPasswordValueOne, setNewPasswordValueOne] = useState('');
  const [newPasswordValueTwo, setNewPasswordValueTwo] = useState('');
  const [agentLogin, setAgentLogin] = useState(false);
  const [forgotId, setForgotId] = useState('');
  const [forgotToken, setForgotToken] = useState('');
  const [facebookId, setFacebookId] = useState('');
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [incorrectAgentEmail, setIncorrectAgentEmail] = useState(false);
  const toastId = React.useRef(null);
  const [directPaymentID, setDirectPaymentID] = useState(false);

  const [searchParams] = useSearchParams();
  const isAppSumo = searchParams.get('source') === 'appsumo';
  console.log('isapp', isAppSumo);

  useEffect(() => {
    eventsend('login_signuppage_clicked');
    if (location.pathname === '/login') {
      setIsLogin(true);
    } else if (location.pathname === '/singup') {
      setIsLogin(false);
    } else if (isAppSumo) {
      setIsLogin(false);
    } else if (location.pathname === '/') {
      navigate('/signup');
      setIsLogin(false);
    }
  }, []);

  // const [incorrectEmail,setIncorrectEmail] = useState(false);

  // const profiledataexists = _id && username && email && auth_token && company_name && Mobile;
  const profiledataexists = _id && facebook_id && email && auth_token;
  let emailLogin = false;
  const gmailExistingUser = (res) => {
    emailLogin = true;
    if (res?.data?.details) {
      dispatch({
        type: 'addUserId',
        payload: res?.data?.details?.user_detail?._id,
      });
      dispatch({
        type: 'addMobile',
        payload: res?.data?.details?.user_detail?.mobile,
      });
      dispatch({
        type: 'addUsername',
        payload: res?.data?.details?.user_detail?.name,
      });
      dispatch({
        type: 'addCompanyname',
        payload: res?.data?.details?.user_detail?.company_name,
      });
      dispatch({
        type: 'addUserPic',
        payload: res?.data?.details?.user_detail?.picture,
      });
      dispatch({
        type: 'addGender',
        payload: res?.data?.details?.user_detail?.gender,
      });
      dispatch({
        type: 'addAge',
        payload: res?.data?.details?.user_detail?.age_range,
      });
      dispatch({
        type: 'addInterest',
        payload: res?.data?.details?.user_detail?.interest,
      });
      dispatch({
        type: 'addEmail',
        payload: res?.data?.details?.user_detail?.email,
      });
      dispatch({
        type: 'addFacebookId',
        payload: res?.data?.details?.user_detail?.facebook_id,
      });

      dispatch({ type: 'addAuthToken', payload: res?.data?.details?.token });
      dispatch({ type: 'addLogin', payload: true });
      dispatch({
        type: 'addBalance',
        payload: res?.data?.details?.user_detail?.balance,
      });
    }
  };

  const existingEmailUsers = (res) => {
    emailLogin = true;
    if (res?.data?.details) {
      dispatch({
        type: 'addUserId',
        payload: res?.data?.details?.user_details?._id,
      });
      dispatch({
        type: 'addMobile',
        payload: res?.data?.details?.user_details?.mobile,
      });
      dispatch({
        type: 'addUsername',
        payload: res?.data?.details?.user_details?.name,
      });
      dispatch({
        type: 'addCompanyname',
        payload: res?.data?.details?.user_details?.company_name,
      });
      dispatch({
        type: 'addUserPic',
        payload: res?.data?.details?.user_details?.picture,
      });
      dispatch({
        type: 'addGender',
        payload: res?.data?.details?.user_details?.gender,
      });
      dispatch({
        type: 'addAge',
        payload: res?.data?.details?.user_details?.age_range,
      });
      dispatch({
        type: 'addInterest',
        payload: res?.data?.details?.user_details?.interest,
      });
      dispatch({
        type: 'addEmail',
        payload: res?.data?.details?.user_details?.email,
      });
      dispatch({
        type: 'addFacebookId',
        payload: res?.data?.details.user_details?.facebook_id,
      });
      dispatch({
        type: 'addLocaldata',
        payload: res?.data?.details?.user_details,
      });

      dispatch({ type: 'addAuthToken', payload: res?.data?.details?.token });
      dispatch({ type: 'addLogin', payload: true });
      // dispatch({
      //   type: "addBalance",
      //   payload: res?.data?._details?.balance,
      // });
    }
  };

  const existingUsers = (res) => {
    if (res?.data) {
      dispatch({
        type: 'addUserId',
        payload: res?.data?._details?._id,
      });
      dispatch({
        type: 'addMobile',
        payload: res?.data?._details?.mobile,
      });
      dispatch({
        type: 'addUsername',
        payload: res?.data?._details?.name,
      });
      dispatch({
        type: 'addCompanyname',
        payload: res?.data?._details?.company_name,
      });
      dispatch({
        type: 'addUserPic',
        payload: res?.data?._details?.picture,
      });
      dispatch({
        type: 'addGender',
        payload: res?.data?._details?.gender,
      });
      dispatch({
        type: 'addAge',
        payload: res?.data?._details?.age_range,
      });
      dispatch({
        type: 'addInterest',
        payload: res?.data?._details?.interests,
      });
      dispatch({
        type: 'addEmail',
        payload: res?.data?._details?.email,
      });
      dispatch({
        type: 'addFacebookId',
        payload: res?.data?._details?.facebook_id,
      });
      dispatch({
        type: 'addNumberVerified',
        payload: res?.data?._details?.number_verified,
      });
      dispatch({ type: 'addAuthToken', payload: res?.data?.token });
      dispatch({ type: 'addLogin', payload: true });
      dispatch({
        type: 'addBalance',
        payload: res?.data?._details?.balance,
      });
    }
  };

  const existingAgentUsers = (res) => {
    if (res?.data) {
      dispatch({
        type: 'addAgentdata',
        payload: res?.data?.agent,
      });
      dispatch({
        type: 'addUserId',
        payload: res?.data?.agent?._id,
      });
      dispatch({
        type: 'addMobile',
        payload: res?.data?.agent?.phone,
      });
      dispatch({
        type: 'addUsername',
        payload: res?.data?.agent?.name,
      });
      dispatch({
        type: 'addCompanyname',
        payload: res?.data?.agent?.company_name,
      });
      dispatch({
        type: 'addUserPic',
        payload: res?.data?.agent?.picture,
      });
      dispatch({
        type: 'addGender',
        payload: res?.data?.agent?.gender,
      });
      dispatch({
        type: 'addAge',
        payload: res?.data?.agent?.age_range,
      });
      dispatch({
        type: 'addInterest',
        payload: res?.data?.agent?.interests,
      });
      dispatch({
        type: 'addEmail',
        payload: res?.data?.agent?.email,
      });
      dispatch({
        type: 'addFacebookId',
        payload: res?.data?.agent?.userId,
      });

      dispatch({ type: 'addAuthToken', payload: res?.data?.token });
      dispatch({ type: 'addLogin', payload: true });
      dispatch({
        type: 'addBalance',
        payload: res?.data?.agent.balance,
      });
    }
  };

  const newExistingUsers = (res) => {
    if (res?.data) {
      dispatch({
        type: 'addUserId',
        payload: res?.data?.userDetails?._id,
      });
      dispatch({
        type: 'addMobile',
        payload: res?.data?.userDetails?.mobile,
      });
      dispatch({
        type: 'addUsername',
        payload: res?.data?.userDetails?.name,
      });
      dispatch({
        type: 'addCompanyname',
        payload: res?.data?.userDetails?.company_name,
      });
      dispatch({
        type: 'addUserPic',
        payload: res?.data?.userDetails?.picture,
      });
      dispatch({
        type: 'addEmail',
        payload: res?.data?.userDetails?.email,
      });
      dispatch({
        type: 'addFacebookId',
        payload: res?.data?.userDetails?.facebook_id,
      });
      dispatch({
        type: 'addNumberVerified',
        payload: res?.data?.userDetails?.number_verified,
      });
      dispatch({ type: 'addAuthToken', payload: res?.data?.token });
      dispatch({ type: 'addLogin', payload: true });
      dispatch({
        type: 'addBalance',
        payload: res?.data?.userDetails?.balance,
      });
    }
  };

  useEffect(() => {
    if (auth_token && auth_token !== '' && _id !== '' && _id) {
      localStorage.setItem('token', auth_token);
    }
  }, [auth_token]);

  useEffect(() => {
    if (redirectToLogin) {
      setIsLogin(true);
      navigate('/');
    }
  }, [redirectToLogin, navigate]);

  useEffect(() => {
    /* if (countryCode == '91') {
      if (phoneOtpVerified && emailOtpVerified) {
        setOtpVerified(true);
      }
    } else {
      if (emailOtpVerified) {
        setOtpVerified(true);
      }
    } */
    // console.log("after email profiledata", profiledataexists);
    // console.log("after gmailcheckuser profile data",profiledataexists);
    // if (otpVerified && emailLogin)
    if (otpVerified) {
      if (profiledataexists) {
        navigate('/creator');
      } else {
        if (!toast.isActive(toastId.current)) {
          toast.error('Invalid credentials', {
            position: 'top-center',
            toastId: 1,
          });
        }
        //navigate('/manageprofile');
        // alert('under dev')
        setOtpVerified(false);
      }
    }
  }, [otpVerified]);

  /* 
    useEffect(() => {
      // console.log("after profiledata", profiledataexists);
      // console.log("after gmailcheckuser profile data",profiledataexists);
      if (otpVerified) {
        if (profiledataexists) {
          navigate('/creator');
        } else {
          isLogin
            ? toast.error("Account doesn't exist", { position: 'top-center' })
            : signUpWithMobileNumber();
          // alert('under dev')
          setOtpVerified(false);
        }
      }
    }, [otpVerified]);
   */
  // const adminLogin = () => {
  //   console.log("admin login called");
  //   let data = {
  //     email: process.env.REACT_APP_ADMIN_ID,
  //     password: process.env.REACT_APP_ADMIN_PASSWORD,
  //   };
  //   console.log("admin login data ===> ",data);
  //   loginADMIN(data).then((res) => {
  //     if (res?.flag) {
  //       setAuthToken(res?.token);
  //       console.log("admin login successful");
  //     } else {
  //       console.log("admin login failed ===> ",res);
  //     }
  //   });
  // };

  const sendOtpApiCall = () => {
    if (!toast.isActive(toastId.current)) {
      toast('Sending OTP', { toastId: 1, position: 'top-center' });
    }
    let data = {
      country_code: countryCode.length > 1 ? `+${countryCode}` : '',
      mobile: numbervalue.split('-').join(''),
      // password: password,
      email_id: user_email,
      // name: name,
      // company: companyName,
    };
    console.log('OTP call generating ==> ', data);
    sendOTP_V3(data)
      .then((res) => {
        if (res?.flag) {
          console.log('OTP sent Response ', res);

          setPhoneOTPsent(true);
          setEmailOTPsent(true);
          setSession_id(res?.data?.mobileSessionId);
          toast.dismiss(1);
          setTimeout(() => {
            toast('OTP Sent', { toastId: 1, position: 'top-center' });
          }, 2000);
          setIsOpen(true);
        } else {
          toast.dismiss(1);
          setTimeout(() => {
            toast.error(`Error: ${res?.message}`, {
              position: 'top-center',
              toastId: 1,
            });
          }, 2000);
        }
      })
      .catch((e) => {
        toast.dismiss(1);
        setTimeout(() => {
          toast(e, { position: 'top-center', toastId: 1 });
        }, 2000);
      });
  };

  const resetAuthStates = () => {
    setNumberValue('');
    setEmail('');
    setPassword('');
    setNewPasswordValueOne('');
    setNewPasswordValueTwo('');
    setName('');
    setCompanyName('');
    setFacebookId('');
    setPhoneOTPvalue('');
    setEmailOTPvalue('');
    setPhoneNumber('');
  };

  const newSignUpApiCall = () => {
    if (!toast.isActive(toastId.current)) {
      toast('Verifying OTPs', { toastId: 1, position: 'top-center', id: 1 });
    }
    let data = {
      country_code: `+${countryCode}`,
      mobile: numbervalue.split('-').join(''),
      sessionId: session_id,
      password: password,
      email_id: user_email,
      name: name,
      company: companyName,
      phoneOTPvalue: phoneOTPvalue,
      emailOTPvalue: emailOTPvalue,
    };
    console.log('SignUp data sending ==> ', data);
    newSignUp(data)
      .then((res) => {
        console.log('Response : ', res);
        if (res?.flag) {
          console.log('New user sign up', res);
          Beacon.loadUser(res?.data?.userData?.newUserResponse?._id, {
            name: res?.data?.userData?.newUserResponse?.company_name,
            isPremiumUser: true,
            currentPlan: 'Premium',
          });
          console.log('User ID', res?.data?.userData?.newUserResponse?._id);
          console.log('Company name ', res?.data?.userData?.newUserResponse?.company_name);
          if (res?.data?.mobileSessionId) {
            setPhoneOtpVerified(true);
          }
          toast.dismiss(1);
          setTimeout(() => {
            toast('OTP Verified', { toastId: 1, position: 'top-center' });
          }, 2000);
          setEmailOtpVerified(true);
          setOtpVerified(true);
          setIncorrectEmailOTP(false);
          setIncorrectPhoneOTP(false);
          setIsLogin(true);
          setIsOpen(false);
          setuserid(res?.data?.userData?.newUserResponse?._id);
          if (res?.data?.userData?.newUserResponse?._id) {
            dispatch({
              type: 'addUserId',
              payload: res?.data?.userData.newUserResponse._id,
            });
            dispatch({
              type: 'addMobile',
              payload: res?.data?.userData.newUserResponse.mobile,
            });
            dispatch({
              type: 'addUsername',
              payload: res?.data?.userData.newUserResponse.name,
            });
            dispatch({
              type: 'addCompanyname',
              payload: res?.data?.userData.newUserResponse.company_name,
            });
            dispatch({
              type: 'addUserPic',
              payload: res?.data?.userData.newUserResponse.picture,
            });
            dispatch({
              type: 'addEmail',
              payload: res?.data?.userData.newUserResponse.email,
            });
            dispatch({
              type: 'addFacebookId',
              payload: res?.data?.userData.newUserResponse.facebook_id,
            });
            dispatch({ type: 'addAuthToken', payload: res?.data?.userData.token });
            dispatch({ type: 'addLogin', payload: true });
            dispatch({
              type: 'addBalance',
              payload: res?.data?.userData.newUserResponse.balance,
            });
            // onclickfreetrial(res?.token)
            if (res?.data?.userData?.newUserResponse?.isAppsumoUser) {
              EventEmitter.emit('appSumoWelcome');
              navigate('/creator');
            }
            onclickfreetrial(res?.data?.userData.token, res?.data?.userData?.newUserResponse._id);
            resetAuthStates();
          }
        } else {
          setIncorrectEmailOTP(true);
          if (countryCode == '91') {
            setIncorrectPhoneOTP(true);
          }
          toast.dismiss(1);
          setTimeout(() => {
            toast.error(`Error: Incorrect OTP entered`, {
              position: 'top-center',
              toastId: 1,
            });
          }, 2000);
          setTimeout(() => {
            setIncorrectEmailOTP(false);
            setIncorrectPhoneOTP(false);
          }, 6000);
        }
      })
      .catch((e) => {
        toast.dismiss(1);
        setTimeout(() => {
          toast.error(`Error: ${e}`, { position: 'top-center', toastId: 1 });
        }, 2000);
      });
  };

  const directPaymentSignUpAPICall = () => {
    if (!toast.isActive(toastId.current)) {
      toast('Verifying OTPs', { toastId: 1, position: 'top-center', id: 1 });
    }
    let data = {
      name: name,
      email: user_email,
      mobile: numbervalue.split('-').join(''),
      password: password,
      countryCode: `+${countryCode}`,
      company: companyName,
      // country_code: `+${countryCode}`,
      // mobile: numbervalue.split('-').join(''),
      // sessionId: session_id,
      // password: password,
      // email_id: user_email,
      // name: name,
      // company: companyName,
      // phoneOTPvalue: phoneOTPvalue,
      // emailOTPvalue: emailOTPvalue,
    };
    let _id = directPaymentID;
    directpaymentSignup(data, _id)
      .then((res) => {
        console.log('Response : ', res);
        if (res?.flag) {
          // console.log('New user sign up', res);
          // Beacon.loadUser(res?.data?.userData?.newUserResponse?._id, {
          //   name: res?.data?.userData?.newUserResponse?.company_name,
          //   isPremiumUser: true,
          //   currentPlan: 'Premium',
          // });
          // console.log('User ID', res?.data?.userData?.newUserResponse?._id);
          // console.log('Company name ', res?.data?.userData?.newUserResponse?.company_name);
          // if (res?.data?.mobileSessionId) {
          //   setPhoneOtpVerified(true);
          // }
          toast.dismiss(1);
          setTimeout(() => {
            toast('OTP Verified', { toastId: 1, position: 'top-center' });
          }, 2000);
          // setEmailOtpVerified(true);
          // setOtpVerified(true);
          // setIncorrectEmailOTP(false);
          // setIncorrectPhoneOTP(false);
          // setIsLogin(true);
          // setIsOpen(false);
          // setuserid(res?.data?.userData?.newUserResponse?._id);
          // if (res?.data?.userData?.newUserResponse?._id) {
          //   dispatch({
          //     type: 'addUserId',
          //     payload: res?.data?.userData.newUserResponse._id,
          //   });
          //   dispatch({
          //     type: 'addMobile',
          //     payload: res?.data?.userData.newUserResponse.mobile,
          //   });
          //   dispatch({
          //     type: 'addUsername',
          //     payload: res?.data?.userData.newUserResponse.name,
          //   });
          //   dispatch({
          //     type: 'addCompanyname',
          //     payload: res?.data?.userData.newUserResponse.company_name,
          //   });
          //   dispatch({
          //     type: 'addUserPic',
          //     payload: res?.data?.userData.newUserResponse.picture,
          //   });
          //   dispatch({
          //     type: 'addEmail',
          //     payload: res?.data?.userData.newUserResponse.email,
          //   });
          //   dispatch({
          //     type: 'addFacebookId',
          //     payload: res?.data?.userData.newUserResponse.facebook_id,
          //   });
            dispatch({ type: 'addAuthToken', payload: res?.data?.token });
            // dispatch({ type: 'addLogin', payload: true });
            // dispatch({
            //   type: 'addBalance',
            //   payload: res?.data?.userData.newUserResponse.balance,
            // });
            // onclickfreetrial(res?.token)
            if (res?.data?.token) {
              navigate('/creator');
            }
            // onclickfreetrial(res?.data?.userData.token, res?.data?.userData?.newUserResponse._id);
          //   resetAuthStates();
          // }
        } else {
          setIncorrectEmailOTP(true);
          if (countryCode == '91') {
            setIncorrectPhoneOTP(true);
          }
          toast.dismiss(1);
          setTimeout(() => {
            toast.error(`Error: Incorrect OTP entered`, {
              position: 'top-center',
              toastId: 1,
            });
          }, 2000);
          setTimeout(() => {
            setIncorrectEmailOTP(false);
            setIncorrectPhoneOTP(false);
          }, 6000);
        }
      })
      .catch((e) => {
        toast.dismiss(1);
        setTimeout(() => {
          toast.error(`Error: ${e}`, { position: 'top-center', toastId: 1 });
        }, 2000);
      });
  };

  const checkEmailApiCall = () => {
    let data = {
      email_id: facebookId.toLowerCase(),
    };
    console.log('Checking email ==> ', data);
    checkEmailExists(data).then((res) => {
      try {
        if (res?.flag) {
          console.log(res);
          sendNewEmailOtp();
        } else {
          setIncorrectEmail(true);
          toast(res?.message, { position: 'top-center', toastId: 1 });
        }
      } catch (err) {
        toast(err.message, { toastId: 1, position: 'top-center' });
        setIncorrectEmail(true);
      }
    });
  };

  /* useEffect(() => {
    if (incorrectEmail) {
      checkPhoneApiCall();
    }
  }, [incorrectEmail])
 */
  const checkAgentApiCall = () => {
    let data = {
      facebookId: facebookId,
    };
    findAgent(data).then((res) => {
      try {
        if (res?.flag) {
          sendAgentOtp();
        } else {
          if (!toast.isActive(toastId.current)) {
            toast(res?.message, { toastId: 1, position: 'top-center' });
          }
          setIncorrectAgentEmail(true);
        }
      } catch (err) {
        toast(err.message, { toastId: 1, position: 'top-center' });
        setIncorrectAgentEmail(true);
      }
    });
  };

  const checkPhoneApiCall = () => {
    let data = {
      mobile: facebookId,
    };
    console.log('Checking Phone num. ==>', data);
    checkPhoneExists(data).then((res) => {
      try {
        if (res) {
          if (res?.flag) {
            console.log(res);
            sendNewPhoneOtp();
          } else {
            if (!toast.isActive(toastId.current)) {
              toast(res?.message, { toastId: 1, position: 'top-center' });
            }
            setIncorrectEmail(false);
          }
        }
      } catch (err) {
        toast.error(err.message, { toastId: 1, position: 'top-center' });
        setIncorrectEmail(false);
      }
    });
  };

  const sendAgentOtp = () => {
    if (ValidateEmail(facebookId)) {
      setEmail(facebookId);
      sentEmailOtp();
    } else {
      toast.dismiss(1);
      setTimeout(() => {
        toast.error('Enter a valid email address', { toastId: 1, position: 'top-center' });
      }, 1000);
      // setCountryCode("+91");
      // setNumberValue(facebookId);
      // sendOTPapicall();
      // setIncorrectAgentEmail(true);
    }
  };

  const sendNewEmailOtp = () => {
    let data = {
      email_id: facebookId ? facebookId : user_email,
    };
    console.log(data);
    sendEmailOTPV2(data).then((res) => {
      console.log('send otp res', res);
      if (res?.flag) {
        setIsOpen(true);
        setEmailOTPsent(true);
        if (forgotPassword) {
          setVerificationReqSent(true);
        }
        console.log('Verification Req Sent? : ', verificationReqSent);
      }
    });
  };

  const sendNewPhoneOtp = () => {
    let data = {
      mobile_number: facebookId,
    };
    sendUserOTPV2(data).then((res) => {
      if (res?.flag) {
        setIsOpen(true);
        setPhoneOTPsent(true);
        setIncorrectEmail(true);
        console.log('Send phone OTP response => ', res?.data);
        setSession_id(res?.data?.session_id);
        if (forgotPassword) {
          setVerificationReqSent(true);
        }
      }
    });
  };

  const validatePhoneOtp = () => {
    console.log('Session id =>', session_id);
    let data = {
      mobile: facebookId,
      session_id: session_id,
      otp_input: phoneOTPvalue,
    };
    checkUserOTPV2(data)
      .then((res) => {
        if (res?.flag) {
          console.log('Checking OTP response => ', res?.data);
          setForgotToken(res?.data?.token);
          setForgotId(res?.data?._details?._id);
          console.log('Forgot id => ', forgotId);
          setDetailsVerified(true);
          setPhoneOtpVerified(true);
        } else {
          setIncorrectPhoneOTP(true);
          setTimeout(() => {
            setIncorrectPhoneOTP(false);
          }, 10000);
          setPhoneOTPvalue('');
        }
      })
      .catch((err) => {
        console.log('Incorrect OTP');
        setIncorrectPhoneOTP(true);
        setTimeout(() => {
          setIncorrectPhoneOTP(false);
        }, 10000);
        setPhoneOTPvalue('');
        return 'Incorrect OTP entered';
      });
  };

  const validateEmailOtp = () => {
    let data = {
      email_id: facebookId,
      otp: emailOTPvalue,
    };
    checkEmailOTPV2(data)
      .then((res) => {
        // console.log('res res ', );
        if (res?.flag) {
          if (res?.data?.details.checkSame) {
            //  existingEmailUsers(res);
            setForgotToken(res?.data?.details?.token);
            setForgotId(res?.data?.details?.user_details?._id);
            setEmailOtpVerified(true);
            setDetailsVerified(true);
          }
        } else {
          setIncorrectEmailOTP(true);
          setTimeout(() => {
            setIncorrectEmailOTP(false);
          }, 10000);
          setEmailOTPvalue('');
        }
      })
      .catch((err) => {
        console.log('Incorrect OTP');
        setIncorrectEmailOTP(true);
        setTimeout(() => {
          setIncorrectEmailOTP(false);
        }, 10000);
        setEmailOTPvalue('');
        return 'Incorrect OTP entered';
      });
  };

  const agentLoginApiCall = () => {
    let data = {
      facebook_id: facebookId,
      password: password,
    };
    toast('Logging you in', { position: 'top-center', toastId: 1 });
    console.log('Login data sending ==> ', data);
    checkAgentLogin(data)
      .then((res) => {
        console.log('Response:', res);
        if (res?.flag) {
          console.log('Agent login', res);
          toast.dismiss(1);
          setOtpVerified(true);
          setuserid(res?.data?.agent?._id);
          existingAgentUsers(res);
          setAuthToken(res?.data?.token);
          setInvalidCredentials(false);
          setTimeout(() => {
            toast('Succesfully logged in', { position: 'top-center', toastId: 1 });
          }, 1500);
          // if(res?.data?._details == null){
          //   signUpWithMobileNumber();
          //
        } else {
          toast.dismiss(1);
          setInvalidCredentials(true);
          setTimeout(() => {
            setInvalidCredentials(false);
          }, 6000);
          setPassword('');
          setTimeout(() => {
            toast('Invalid credentials', { position: 'top-center', toastId: 1 });
          }, 1500);
        }
      })
      .catch((err) => {
        toast(err, { position: 'top-center', toastId: 1 });
        setInvalidCredentials(true);
        setTimeout(() => {
          setInvalidCredentials(false);
        }, 10000);
        setPassword('');
        return 'Incorrect Password entered';
      });
  };

  const twoFactorLoginApiCall = () => {
    if (isNaN(phoneOTPvalue)) {
      toast.error('Please only enter numeric input for OTP', {
        position: 'top-center',
        toastId: 1,
      });
    } else {
      let data = {
        mobile: numbervalue,
        phoneOTPvalue: phoneOTPvalue,
        sessionId: session_id,
      };
      twoFactorLogin(data)
        .then((res) => {
          if (res?.flag) {
            console.log('User login', res);
            setOtpVerified(true);
            setuserid(res?.data?._details?._id);
            existingUsers(res);
            setIsLogin(true);
            if (!toast.isActive(toastId.current)) {
              toast('Sucessfully Logged you in...', {
                toastId: 1,
                position: 'top-center',
              });
            }
            resetAuthStates();
          } else {
            setIncorrectPhoneOTP(true);
            setTimeout(() => {
              setIncorrectPhoneOTP(false);
            }, 5000);
          }
        })
        .catch((e) => {
          if (!toast.isActive(toastId.current)) {
            toast(e, { position: 'top-center', toastId: 1 });
          }
        });
    }
  };

  const newLoginApiCall = () => {
    let data = {
      facebook_id: facebookId,
      password: password,
    };
    toast('Logging you in', { position: 'top-center', toastId: 1 });
    console.log('Login data sending ==> ', data);
    userLogin(data)
      .then((res) => {
        if (res?.flag) {
          console.log('User login', res);
          const user_name = res?.data?.userDetails?.name;
          const user_email = res?.data?.userDetails?.email;
          const user_mobile = res?.data?.userDetails?.mobile;
          const user_country_code = res?.data?.userDetails?.country_code;
          handleSaveContacts(user_name, user_email, user_mobile, user_country_code);
          if (res?.data?.userDetails?.isTwoFactorEnabled) {
            toast.dismiss(1);
            setTimeout(() => {
              toast('Onto OTP Page for 2 Factor Authentication', {
                toastId: 2,
                position: 'top-center',
              });
            }, 500);
            setNumberValue(res?.data?.userDetails?.mobile);
            setSession_id(res?.data?.phoneOTPSession);
            setTwoFactorModal(true);
            setIsOpen(true);
            setIsLogin(true);
            setCountryCode('91');
          } else {
            setOtpVerified(true);
            setuserid(res?.data?.userDetails._id);
            newExistingUsers(res);
            setIsLogin(true);
            toast.dismiss(1);
            if (!toast.isActive(toastId.current)) {
              toast('Sucessfully Logged you in...', {
                toastId: 1,
                position: 'top-center',
              });
            }
            resetAuthStates();
          }
        } else {
          toast.dismiss(1);
          toast('Invalid Credentials', { toastId: 1, position: 'top-center' });
          setInvalidCredentials(true);
          setTimeout(() => {
            setInvalidCredentials(false);
          }, 5000);
        }
      })
      .catch((e) => {
        if (!toast.isActive(toastId.current)) {
          toast(e, { position: 'top-center', toastId: 1 });
        }
      });
  };

  const resetPasswordApiCall = () => {
    if (!toast.isActive(toastId.current)) {
      toast('Changing Password...', { toastId: 1, position: 'top-center' });
    }
    let data = {
      id: forgotId,
      password_one: newPasswordValueOne,
      password_two: newPasswordValueTwo,
    };
    console.log('Login data sending ==> ', data);
    newPassword(data, forgotToken, forgotId)
      .then((res) => {
        if (res?.flag) {
          console.log('User Password Change Response', res);
          setForgotPassword(false);
          setIsLogin(true);
          setIsOpen(false);
          setVerificationReqSent(false);
          toast.dismiss(1);
          setDetailsVerified(false);
          setTimeout(() => {
            toast('Password Changed', { toastId: 1, position: 'top-center' });
          }, 2000);
          setIncorrectEmail(false);
          resetAuthStates();
        } else {
          toast.dismiss(1);
          setTimeout(() => {
            toast.error(`Error: ${res?.response.data.message}`, {
              toastId: 1,
              position: 'top-center',
            });
          }, 2000);
        }
      })
      .catch((e) => {
        toast.dismiss(1);
        setTimeout(() => {
          toast.error(`Error: ${e}`, { toastId: 1, position: 'top-center' });
        }, 2000);
      });
  };

  const resetAgentPasswordApiCall = () => {
    if (!toast.isActive(toastId.current)) {
      toast('Changing Agent Password...', { toastId: 1, position: 'top-center' });
    }

    let data = {
      id: forgotId,
      password_one: newPasswordValueOne,
      password_two: newPasswordValueTwo,
    };

    console.log('DATA SENDING FOR AGENT PASSWORD CHANGE: ', data);
    newAgentPassword(data, forgotToken, forgotId)
      .then((res) => {
        if (res?.flag) {
          console.log('AGENT PASSWORD CHANGE RESPONSE', res);
          toast.dismiss(1);
          setForgotPassword(false);
          setIsLogin(true);
          setIsOpen(false);
          setVerificationReqSent(false);
          setDetailsVerified(false);
          setIncorrectEmail(false);
          setTimeout(() => {
            toast('Agent Password Changed', {
              toastId: 1,
              position: 'top-center',
            });
          }, 2000);
          resetAuthStates();
        } else {
          toast.dismiss(1);
          setTimeout(() => {
            toast.error(`Error: ${res?.response.data.message}`, {
              toastId: 1,
              position: 'top-center',
            });
          }, 2000);
        }
      })
      .catch((e) => {
        toast.dismiss(1);
        setTimeout(() => {
          toast.error(`Error: ${e}`, { toastId: 1, position: 'top-center' });
        }, 2000);
      });
  };

  const sendOTPapicall = () => {
    if (numbervalue?.length > 5 || facebookId?.length > 5) {
      let data = {
        country_code: `${countryCode}`,
        mobile_number: numbervalue
          ? numbervalue.split('-').join('')
          : facebookId.split('-').join(''),
      };
      console.log('send otp data sent ===> ', data);
      if (agentLogin) {
        sendAgentOTPV2(data)
          .then((res) => {
            if (res?.flag) {
              console.log('send sms otp res ====> ', res);
              setSession_id(res?.apiKey);
              setOTPsent(true);
              toast.dismiss(1);
              setVerificationReqSent(true);
              if (!toast.isActive(toastId.current)) {
                toast('OTP Sent', { position: 'top-center', toastId: 1 });
              }
              setIsOpen(true);
            } else {
              if (!toast.isActive(toastId.current)) {
                toast.error(`Error: ${res?.response.data.message}`, {
                  position: 'top-center',
                  toastId: 1,
                });
              }
            }
          })
          .catch((e) => {
            if (!toast.isActive(toastId.current)) {
              toast(e, { position: 'top-center', toastId: 1 });
            }
          });
      } else {
        sendUserOTPV2(data)
          .then((res) => {
            if (res?.flag) {
              console.log('send sms otp res ====> ', res);
              setSession_id(res?.data?.session_id);
              setOTPsent(true);
              toast.dismiss(1);
              if (!toast.isActive(toastId.current)) {
                toast('OTP Sent', { position: 'top-center', toastId: 1 });
              }
              setIsOpen(true);
              // if (res?.data?.user_details?._id) {
              //   dispatch({
              //     type: "addUserId",
              //     payload: res?.data?.user_details?._id,
              //   });
              //   dispatch({
              //     type: "addMobile",
              //     payload: res?.data?.user_details?.mobile,
              //   });
              //   dispatch({
              //     type: "addUsername",
              //     payload: res?.data?.user_details?.name,
              //   });
              //   dispatch({
              //     type: "addCompanyname",
              //     payload: res?.data?.user_details?.company_name,
              //   });
              //   dispatch({
              //     type: "addUserPic",
              //     payload: res?.data?.user_details?.picture,
              //   });
              //   dispatch({
              //     type: "addGender",
              //     payload: res?.data?.user_details?.gender,
              //   });
              //   dispatch({
              //     type: "addAge",
              //     payload: res?.data?.user_details?.age_range,
              //   });
              //   dispatch({
              //     type: "addInterest",
              //     payload: res?.data?.user_details?.interests,
              //   });
              //   dispatch({
              //     type: "addEmail",
              //     payload: res?.data?.user_details?.email,
              //   });
              //   dispatch({ type: "addAuthToken", payload: res?.data?.token });
              //   dispatch({ type: "addLogin", payload: true });
              //   dispatch({
              //     type: "addBalance",
              //     payload: res?.data?.user_details?.balance,
              //   });
              // }
            } else {
              if (!toast.isActive(toastId.current)) {
                toast.error(`Error: ${res?.response.data.message}`, {
                  position: 'top-center',
                  toastId: 1,
                });
              }
            }
          })
          .catch((e) => {
            if (!toast.isActive(toastId.current)) {
              toast(e, { position: 'top-center', toastId: 1 });
            }
          });
      }
    } else {
      if (!toast.isActive(toastId.current)) {
        toast('Please enter mobile number', {
          position: 'top-center',
          toastId: 1,
        });
      }
    }
  };

  const onClickVerify = () => {
    console.log('*************************************************************');
    console.log('*************************************************************');
    console.log('*************************************************************');
    console.log('onClickVerify called');
    let data = {
      session_id: session_id,
      otp_input: phoneOTPvalue,
      mobile: numbervalue ? numbervalue.split('-').join('') : facebookId.split('-').join(''),
    };

    if (agentLogin) {
      checkAgentOTPV2(data)
        .then((res) => {
          setPhoneOTPvalue('');
          if (res?.flag) {
            if (forgotPassword) {
              setDetailsVerified(true);
            }
            setOTPsent(false);
            //existingAgentUsers(res);
            if (res?.data?.status) {
              setForgotId(res?.data?.agent?._id);
              setForgotToken(res?.data?.token);
              // if(res?.data?._details == null){
              //   signUpWithMobileNumber();
              // }
            } else {
              setIncorrectPhoneOTP(true);
              setTimeout(() => {
                setIncorrectPhoneOTP(false);
              }, 1000);
              setPhoneOTPvalue('');
              return 'Incorrect OTP entered';
            }
            console.log(res?.message);
          } else {
            setIncorrectPhoneOTP(true);
            setTimeout(() => {
              setIncorrectPhoneOTP(false);
            }, 1000);
            setPhoneOTPvalue('');
            return 'Incorrect OTP entered';
          }
        })
        .catch((err) => {
          console.log('Incorrect OTP');
          setIncorrectPhoneOTP(true);
          setTimeout(() => {
            setIncorrectPhoneOTP(false);
          }, 10000);
          setOTPvalue('');
          return 'Incorrect OTP entered';
        });
    } else {
      checkUserOTPV2(data)
        .then((res) => {
          setOTPvalue('');
          if (res?.flag) {
            setPhoneOTPsent(false);
            existingUsers(res);

            if (res?.data?.status) {
              setAuthToken(res?.data?.token);
              setPhoneOtpVerified(true);

              // if(res?.data?._details == null){
              //   signUpWithMobileNumber();
              // }
            } else {
              setIncorrectPhoneOTP(true);
              setTimeout(() => {
                setIncorrectPhoneOTP(false);
              }, 1000);
              setPhoneOTPvalue('');
              return 'Incorrect OTP entered';
            }
            console.log(res?.message);
          } else {
            setIncorrectPhoneOTP(true);
            setTimeout(() => {
              setIncorrectPhoneOTP(false);
            }, 1000);
            setPhoneOTPvalue('');
            return 'Incorrect OTP entered';
          }
        })
        .catch((err) => {
          console.log('Incorrect OTP');
          setIncorrectPhoneOTP(true);
          setTimeout(() => {
            setIncorrectPhoneOTP(false);
          }, 10000);
          setPhoneOTPvalue('');
          return 'Incorrect OTP entered';
        });
    }
  };

  function ValidateEmail(mail) {
    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(mail)) {
      return true;
    }
    //  toast('You have entered an invalid email address!', { position: 'top-center' });
    if (!isLogin) {
      toast.dismiss(1);
      toast('You have entered an invalid email address!', { position: 'top-center', toastId: 1 });
    }
    //setIncorrectEmail(true);
    return false;
  }

  function ValidatePassword(password) {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+\[\]{};':"\\|,.<>\/?`~])[A-Za-z\d!@#$%^&*()\-_=+\[\]{};':"\\|,.<>\/?`~]{8,}/.test(
        password
      )
    ) {
      return true;
    }
    toast(
      'You need to enter a password of minimum 8 characters with one uppercase and lowercase letter, one number, and one special character',
      { position: 'top-center' }
    );
    return false;
  }

  const sentEmailOtp = () => {
    if (ValidateEmail(facebookId)) {
      let data = {
        email_id: facebookId,
      };
      sendEmailOTPV2(data).then((res) => {
        //console.log("send otp res", res);
        if (res?.flag) {
          setIsOpen(true);
          setOTPsent(true);
          setVerificationReqSent(true);
          //   if (res?.data?.user_details?._id) {
          //     dispatch({
          //       type: "addUserId",
          //       payload: res?.data?.user_details?._id,
          //     });
          //     dispatch({
          //       type: "addMobile",
          //       payload: res?.data?.user_details?.mobile,
          //     });
          //     dispatch({
          //       type: "addUsername",
          //       payload: res?.data?.user_details?.name,
          //     });
          //     dispatch({
          //       type: "addCompanyname",
          //       payload: res?.data?.user_details?.company_name,
          //     });
          //     dispatch({
          //       type: "addUserPic",
          //       payload: res?.data?.user_details?.picture,
          //     });
          //     dispatch({
          //       type: "addGender",
          //       payload: res?.data?.user_details?.gender,
          //     });
          //     dispatch({
          //       type: "addAge",
          //       payload: res?.data?.user_details?.age_range,
          //     });
          //     dispatch({
          //       type: "addInterest",
          //       payload: res?.data?.user_details?.interests,
          //     });
          //     dispatch({
          //       type: "addEmail",
          //       payload: res?.data?.user_details?.email,
          //     });
          //     dispatch({ type: "addAuthToken", payload: res?.data?.token });
          //     dispatch({ type: "addLogin", payload: true });
          //     dispatch({
          //       type: "addBalance",
          //       payload: res?.data?.user_details?.balance,
          //     });
          //   }
        }
      });
    }
  };

  const verifyAgentEmailOtp = () => {
    let data = {
      email_id: facebookId,
      otp: emailOTPvalue,
    };
    checkAgentEmailOtp(data)
      .then((res) => {
        console.log('Response data', res);
        if (res?.flag) {
          if (res?.data?.checkSame) {
            console.log('AGENT CHECK=>', res);
            setEmailOtpVerified(true);
            setDetailsVerified(true);
            setForgotId(res?.data?.agent._id);
            setForgotToken(res?.data?.token);
          } else {
            setIncorrectEmailOTP(true);
            setTimeout(() => {
              setIncorrectEmailOTP(false);
            }, 6000);
            setEmailOTPvalue('');
            return 'Incorrect OTP entered';
          }
        } else {
          setIncorrectEmailOTP(true);
          setTimeout(() => {
            setIncorrectEmailOTP(false);
          }, 6000);
          setEmailOTPvalue('');
          return 'Incorrect OTP entered';
        }
      })
      .catch((err) => {
        console.log('Incorrect OTP');
        setIncorrectEmailOTP(true);
        setTimeout(() => {
          setIncorrectEmailOTP(false);
        }, 10000);
        setEmailOTPvalue('');
        return 'Incorrect OTP entered';
      });
  };

  /* const verifyEmailOtp = () => {
    let data = {
      email_id: user_email,
      otp: emailOTPvalue,
    };
    checkEmailOTPV2(data)
      .then((res) => {
        // console.log('res res ', );
        if (res?.flag) {
          if (res?.data?.details.checkSame) {
            existingEmailUsers(res);
            setAuthToken(res?.data?.details?.token);
            if (res?.data?.details.user_details === null && !isLogin) {
              signUpWithEmailAction();
            }
            setEmailOtpVerified(true);
            if (forgotPassword) {
              setDetailsVerified(true);
            }
            setForgotId(res?.data?.details.user_details.parentId);
            setForgotToken(res?.data?.details?.token);
            //  if (profiledataexists) {
            //   console.log("harry",profiledataexists);
            //   navigate("/creator");
            // } else {
            //   navigate("/creatorprofile");
            //   // alert('under dev')
            // }
          } else {
            setIncorrectEmailOTP(true);
            setTimeout(() => {
              setIncorrectEmailOTP(false);
            }, 1000);
            setEmailOTPvalue('');
            return 'Incorrect OTP entered';
          }
          console.log(res?.message);
        }
      })
      .catch((err) => {
        console.log('Incorrect OTP');
        setIncorrectEmailOTP(true);
        setTimeout(() => {
          setIncorrectEmailOTP(false);
        }, 10000);
        setEmailOTPvalue('');
        return 'Incorrect OTP entered';
      });
  };
 */
  const checkGmailUserApiCall = (resp) => {
    let data = {
      email: resp.email,
      family_name: resp.family_name,
      given_name: resp.given_name,
      hd: resp.hd,
      id: resp.id,
      locale: resp.locale,
      name: resp.email,
      picture: resp.picture,
      verified_email: resp.verified_email,
      facebook_id: resp.facebook_id,
    };
    checkGmailUserV2(data).then((res) => {
      if (res?.flag) {
        if (res?.data?.details.check) {
          setAuthToken(res?.data?.details?.token);

          gmailExistingUser(res);
          if (res?.data?.details.user_detail === null && !isLogin) {
            signUpWithEmailAction();
          }
          setOtpVerified(true);
        } else {
          setIncorrectOTP(true);
          setTimeout(() => {
            setIncorrectOTP(false);
          }, 1000);
          setOTPvalue('');
          return 'Incorrect OTP entered';
        }
      }
    });
  };

  const googleloginaction = useGoogleLogin({
    onSuccess: (res) => setGoogleUser(res),
    onError: (err) => console.log('Google login failed: ', err),
  });

  const checkRequire = (name, value) => {
    if (value) {
      return true;
    } else {
      toast(`Please enter ${name}`, { position: 'top-center' });
      // Alert.alert('',`${validatorsTxt.PleaseEnter} ${name}`);
      return false;
    }
  };

  const signUpAction = (type = 'google') => {
    if (
      checkRequire('Name', name) &&
      checkRequire('Phone Number', numbervalue) &&
      checkRequire('Email', user_email) &&
      ValidateEmail(user_email) &&
      checkRequire('Company Name', companyName) &&
      checkRequire('Password', password)
    ) {
      if (countryCode == '91') {
        sendOTPapicall();
      } else {
        sentEmailOtp();
      }
    }
  };

  const resendOtpAction = (type = 'google') => {
    if (ValidateEmail(facebookId)) {
      resendEmailOtp();
    } else {
      setNumberValue(facebookId);
      resendPhoneOtp();
    }
  };

  const resendEmailOtp = (type = 'google') => {
    sendNewEmailOtp();
  };

  const resendPhoneOtp = (type = 'google') => {
    sendOTPapicall();
  };

  const startTrialAction = (type = 'google') => {
    if (
      checkRequire('Name', name) &&
      checkRequire('Email', user_email) &&
      ValidateEmail(user_email) &&
      ValidatePassword(password) &&
      checkRequire('Company Name', companyName)
    ) {
      if (isAppSumo) {
        sendOtpApiCall();
      } else if (checkRequire('Phone Number', numbervalue)) {
        sendOtpApiCall();
      }
    }
  };

  const startVerifyAction = async () => {
    //  setFacebookId(facebookId.toLowerCase());
    eventsend('Send_OTP_forget_pass_pop_up_clicked');
    setTimeout(() => {
      if (checkRequire('Facebook Id', facebookId)) {
        if (ValidateEmail(facebookId)) {
          if (!agentLogin) {
            console.log('$$$$$$$$$$$$$$$$$$');
            console.log('SETINCORRECT EMAIL', incorrectEmail);
            checkEmailApiCall();
          } else {
            checkAgentApiCall();
          }
        } else {
          if (!agentLogin) {
            checkPhoneApiCall();
          }
          if (agentLogin) {
            toast.dismiss(1);
            setTimeout(() => {
              toast.error('Enter a valid email address', {
                position: 'top-center',
                toastId: 1,
              });
            }, 1000);
          }
        }
      }
    }, 100);
  };

  const verifyEmailOtpAction = (type = 'google') => {
    if (!agentLogin) {
      validateEmailOtp();
    } else {
      verifyAgentEmailOtp();
    }
  };

  const verifyPhoneOtpAction = (type = 'google') => {
    if (!agentLogin) {
      validatePhoneOtp();
    } else {
      onClickVerify();
    }
  };

  const submitPasswordAction = (type = 'google') => {
    if (ValidatePassword(newPasswordValueOne) && ValidatePassword(newPasswordValueTwo)) {
      if (!agentLogin) {
        resetPasswordApiCall();
      } else {
        resetAgentPasswordApiCall();
      }
    } else {
      setIsOpen(true);
      setDetailsVerified(true);
      setVerificationReqSent(true);
    }
  };

  const newLoginAction = (type = 'google') => {
    if (ValidatePassword(password)) {
      if (!agentLogin) {
        newLoginApiCall();
      } else {
        if (!ValidateEmail(facebookId)) {
          toast.error('Enter a valid email address', {
            position: 'top-center',
            toastId: 1,
          });
        } else {
          agentLoginApiCall();
        }
      }
    }
  };

  const twoFactorLoginAction = () => {
    twoFactorLoginApiCall();
  };

  const signUpWithMobileNumber = (type = 'mobile') => {
    const data = {
      name: name,
      email: user_email,
      login_type: type,
      contry_code: `+${countryCode}`,
      mobile: numbervalue.split('-').join(''),
      facebook_id: numbervalue.split('-').join(''),
      fcm_token: '',
      picture: '',
      company_name: companyName,
    };

    console.log('data signup with mobile number  ', data);
    userSignupV2Api(data, authToken).then((res) => {
      console.log('res signup resres res res res  ', res);
      if (res?.flag) {
        if (res?.data?._id) {
          dispatch({
            type: 'addUserId',
            payload: res?.data?._id,
          });
          dispatch({
            type: 'addMobile',
            payload: res?.data?.mobile,
          });
          dispatch({
            type: 'addUsername',
            payload: res?.data?.name,
          });
          dispatch({
            type: 'addCompanyname',
            payload: res?.data?.company_name,
          });
          dispatch({
            type: 'addUserPic',
            payload: res?.data?.picture,
          });
          dispatch({
            type: 'addGender',
            payload: res?.data?.gender,
          });
          dispatch({
            type: 'addAge',
            payload: res?.data?.age_range,
          });
          dispatch({
            type: 'addInterest',
            payload: res?.data?.interests,
          });
          dispatch({
            type: 'addEmail',
            payload: res?.data?.email,
          });
          dispatch({ type: 'addAuthToken', payload: res?.token });
          dispatch({ type: 'addLogin', payload: true });
          dispatch({
            type: 'addBalance',
            payload: res?.data?.balance,
          });
          // onclickfreetrial(res?.token)

          onclickfreetrial(res?.token, res?.data?._id);
        }
      }
    });
  };

  const signUpWithEmailAction = (type = 'email') => {
    const data = {
      name: name,
      email: user_email,
      login_type: type,
      contry_code: `+${countryCode}`,
      mobile: numbervalue.split('-').join(''),
      facebook_id: numbervalue.split('-').join(''),
      fcm_token: '',
      picture: '',
      company_name: companyName,
    };

    console.log('data  ', data);
    userSignupWithEmail(data, authToken).then((res) => {
      console.log('res signup resres res res res  ', res);
      if (res?.flag) {
        if (res?.data?._id) {
          dispatch({
            type: 'addUserId',
            payload: res?.data?._id,
          });
          dispatch({
            type: 'addMobile',
            payload: res?.data?.mobile,
          });
          dispatch({
            type: 'addUsername',
            payload: res?.data?.name,
          });
          dispatch({
            type: 'addCompanyname',
            payload: res?.data?.company_name,
          });
          dispatch({
            type: 'addUserPic',
            payload: res?.data?.picture,
          });
          dispatch({
            type: 'addGender',
            payload: res?.data?.gender,
          });
          dispatch({
            type: 'addAge',
            payload: res?.data?.age_range,
          });
          dispatch({
            type: 'addInterest',
            payload: res?.data?.interests,
          });
          dispatch({
            type: 'addEmail',
            payload: res?.data?.email,
          });
          dispatch({ type: 'addAuthToken', payload: res?.token });
          dispatch({ type: 'addLogin', payload: true });
          dispatch({
            type: 'addBalance',
            payload: res?.data?.balance,
          });

          //navigate("/creator");

          onclickfreetrial(res?.token, res?.data?._id);
        }
      }
    });
  };

  const onclickfreetrial = (token, id) => {
    if (isAppSumo) {
      return;
    } else {
      let data = {
        user_id: id,
      };
      console.log('free trial data sent : ', data);
      console.log('free trial token sent : ', token);
      ReactGA.event('free_trial_modal_button_click', { data: data });
      eventsend('free_trial_modal_button_click', { data: data });
      freeTrialApi(data, token).then((res) => {
        console.log('i am gere ');
        navigate('/creator');

        if (res?.flag) {
          console.log('free trial success', res);
          setTimeout(() => {
            EventEmitter.emit('show_free_trial_success', { isFree: true });
          }, 500);
        } else {
          console.log('free trial error', res);
        }
      });
    }
  };

  // useEffect(() => {

  //   console.log("sign up status",signUpStatus)
  //  if(signUpStatus){
  //   navigate("/creator");

  //           // EventEmitter.emit("get_free_trial", { isFree: true });

  //           onclickfreetrial(authToken);
  //         }

  // }, []);

  // const EETrial = () => {
  //   navigate("/creator");
  //         setTimeout(() => {
  //           console.log("EETrial event fired");
  //           EventEmitter.emit("get_free_trial", { isFree: true });
  //         }, 1000);
  // }

  const verifyOtpAction = () => {
    eventsend('Verfiy_OTP_OTP_popup_clicked');
    eventsend('Verify_Email_OTP_OTP_popup_clicked');
    if (directPaymentID) {
      directPaymentSignUpAPICall();
    } else {
      newSignUpApiCall();
    }
  };
  // countryCode != '91' ? verifyEmailOtp() : onClickVerify();

  useEffect(() => {
    if (googleUser) {
      console.log('/////////////////////////////////');
      console.log('//// google user data', googleUser);
      console.log('/////////////////////////////////');
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${googleUser.access_token}`,
              Accept: 'application/json',
            },
          }
        )
        .then((res) => {
          //console.log("GG",res);
          setGoogleUserProfile(res?.data);
          if (isLogin) {
            checkGmailUserApiCall(res?.data);
          } else {
            signUpGmail(res?.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [googleUser]);

  const signUpGmail = (res) => {
    const data = {
      name: res?.given_name,
      email: res?.email,
      login_type: 'mobile',
      contry_code: ``,
      mobile: '',
      facebook_id: res?.id,
      fcm_token: '',
      picture: '',
      company_name: '',
    };

    userSignupV2Api(data, authToken).then((res) => {
      if (res?.flag) {
        if (res?.data?._id) {
          dispatch({
            type: 'addUserId',
            payload: res?.data?._id,
          });
          dispatch({
            type: 'addMobile',
            payload: res?.data?.mobile,
          });
          dispatch({
            type: 'addUsername',
            payload: res?.data?.name,
          });
          dispatch({
            type: 'addCompanyname',
            payload: res?.data?.company_name,
          });
          dispatch({
            type: 'addUserPic',
            payload: res?.data?.picture,
          });
          dispatch({
            type: 'addGender',
            payload: res?.data?.gender,
          });
          dispatch({
            type: 'addAge',
            payload: res?.data?.age_range,
          });
          dispatch({
            type: 'addInterest',
            payload: res?.data?.interests,
          });
          dispatch({
            type: 'addEmail',
            payload: res?.data?.email,
          });
          dispatch({
            type: 'facebookId',
            payload: res?.data?.facebook_id,
          });
          dispatch({ type: 'addAuthToken', payload: res?.token });
          dispatch({ type: 'addLogin', payload: true });
          dispatch({
            type: 'addBalance',
            payload: res?.data?.balance,
          });
          // onclickfreetrial(res?.token)

          onclickfreetrial(res?.token, res?.data?._id);
        }
      }
    });
  };

  // useEffect(() => {
  //   if (googleUserProfile.id) {
  //     console.log("+++++++++++++++++++++++++++++++++++++++++++");
  //     console.log("++++++++++", googleUserProfile);
  //     console.log("+++++++++++++++++++++++++++++++++++++++++++");
  //     dispatch({ type: "addEmail", payload: googleUserProfile.email });
  //     dispatch({ type: "addUsername", payload: googleUserProfile.name });
  //     dispatch({ type: "addUserPic", payload: googleUserProfile.picture });
  //     dispatch({ type: "addGoogleId", payload: googleUserProfile.id });
  //     navigate("/creatorprofile");
  //   }
  // }, [googleUserProfile]);

  return (
    <>
      {!isLogin && emailOTPsent && phoneOTPsent && modalIsOpen && !forgotPassword ? (
        //console.log("oyp",modalIsOpen),
        <OTPVerifyForSignUp
          countryCode={countryCode}
          setPhoneOTPvalue={setPhoneOTPvalue}
          phoneOTPvalue={phoneOTPvalue}
          setEmailOTPvalue={setEmailOTPvalue}
          emailOTPvalue={emailOTPvalue}
          verifyOtpAction={verifyOtpAction}
          closeOtpAction={() => {
            setIsOpen(false);
            setEmailOTPvalue('');
            setPhoneOTPvalue('');
            setIncorrectEmailOTP(false);
            setIncorrectPhoneOTP(false);
          }}
          number={numbervalue}
          email={user_email}
          resendEmailOtpAction={resendEmailOtp}
          resendPhoneOtpAction={resendPhoneOtp}
          isIndia={countryCode == '91'}
          incorrectPhoneOTP={incorrectPhoneOTP}
          incorrectEmailOTP={incorrectEmailOTP}
        />
      ) : (
        <></>
      )}
      {isLogin && forgotPassword && verificationReqSent && modalIsOpen && detailsVerified ? (
        <ForgotPass
          submitPasswordAction={submitPasswordAction}
          newPasswordValueOne={newPasswordValueOne}
          newPasswordValueTwo={newPasswordValueTwo}
          setNewPasswordValueOne={setNewPasswordValueOne}
          setNewPasswordValueTwo={setNewPasswordValueTwo}
          closeOtpAction={() => {
            setIsOpen(false);
            setVerificationReqSent(false);
            setDetailsVerified(false);
            setForgotPassword(false);
            setPhoneOTPvalue('');
            setEmailOTPvalue('');
            setNewPasswordValueOne('');
            setNewPasswordValueTwo('');
            setIncorrectEmail(false), setIncorrectAgentEmail(false);
          }}
        />
      ) : (
        <></>
      )}
      {isLogin && forgotPassword && verificationReqSent && modalIsOpen && !detailsVerified ? (
        <ValidateOtpPage
          email={user_email}
          resendOtpAction={resendOtpAction}
          verifyPhoneOtpAction={verifyPhoneOtpAction}
          incorrectEmail={incorrectEmail}
          verifyEmailOtpAction={verifyEmailOtpAction}
          facebookId={facebookId}
          emailOTPvalue={emailOTPvalue}
          phoneOTPvalue={phoneOTPvalue}
          setEmailOTPvalue={setEmailOTPvalue}
          setPhoneOTPvalue={setPhoneOTPvalue}
          closeOtpAction={() => {
            setIsOpen(false);
            setVerificationReqSent(false);
            setForgotPassword(false);
            setPhoneOTPvalue('');
            setEmailOTPvalue('');
            setIncorrectEmailOTP(false);
            setIncorrectPhoneOTP(false);
            setIncorrectEmail(false), setIncorrectAgentEmail(false);
          }}
          incorrectEmailOTP={incorrectEmailOTP}
          incorrectPhoneOTP={incorrectPhoneOTP}
          incorrectAgentEmail={incorrectAgentEmail}
        />
      ) : (
        <></>
      )}
      {isLogin && forgotPassword && !verificationReqSent && modalIsOpen && !detailsVerified ? (
        <SendOtpReq
          facebookId={facebookId}
          setFacebookId={setFacebookId}
          email={user_email}
          setEmail={setEmail}
          closeOtpAction={() => {
            setIsOpen(false);
            setForgotPassword(false), setIncorrectEmail(false), setIncorrectAgentEmail(false);
          }}
          incorrectEmail={incorrectEmail}
          incorrectFbId={incorrectFbId}
          startVerifyAction={startVerifyAction}
          setVerificationReqSent={setVerificationReqSent}
          agentLogin={agentLogin}
        />
      ) : (
        <></>
      )}
      {isLogin && !forgotPassword && modalIsOpen && twoFactorModal ? (
        <TwoFactorLoginPage
          numbervalue={numbervalue}
          closeOtpAction={() => {
            setIsOpen(false);
            setPhoneOTPvalue('');
            setTwoFactorModal(false);
            setIncorrectPhoneOTP(false), setFacebookId('');
            setPassword('');
          }}
          incorrectPhoneOTP={incorrectPhoneOTP}
          setPhoneOTPvalue={setPhoneOTPvalue}
          resendPhoneOtp={resendPhoneOtp}
          phoneOTPvalue={phoneOTPvalue}
          twoFactorLoginAction={twoFactorLoginAction}
        />
      ) : (
        <></>
      )}
      {isLogin ? (
        <NewAuthLogin
          loginAction={() => {
            if (isAppSumo) {
              navigate('/signup?source=appsumo');
            } else {
              navigate('/signup');
            }
            setIsLogin(false);
          }}
          passwordAction={setForgotPassword}
          invalidCredentials={invalidCredentials}
          password={password}
          setPassword={setPassword}
          googleloginaction={googleloginaction}
          email={user_email}
          setEmail={setEmail}
          newLoginAction={newLoginAction}
          setIsOpen={setIsOpen}
          setFacebookId={setFacebookId}
          facebookId={facebookId}
          agentLogin={agentLogin}
          setAgentLogin={setAgentLogin}
        />
      ) : (
        <AuthSignUp
          loginAction={() => {
            if (isAppSumo) {
              navigate('/login?source=appsumo');
            } else {
              navigate('/login');
            }
            setIsLogin(true);
          }}
          openOtpAction={() => setIsOpen(true)}
          closeOtpAction={closeOtpAction}
          name={name}
          setName={setName}
          countryCode={countryCode}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          setCountryCode={setCountryCode}
          numbervalue={numbervalue}
          setNumberValue={setNumberValue}
          password={password}
          setPassword={setPassword}
          email={user_email}
          setEmail={setEmail}
          companyName={companyName}
          setCompanyName={setCompanyName}
          setPhoneOTPvalue={setPhoneOTPvalue}
          phoneOTPvalue={phoneOTPvalue}
          setEmailOTPvalue={setEmailOTPvalue}
          emailOTPvalue={emailOTPvalue}
          phoneOTPsent={phoneOTPsent}
          emailOTPsent={emailOTPsent}
          startTrialAction={startTrialAction}
          googleloginaction={googleloginaction}
          incorrectOTP={incorrectOTP}
          setDirectPaymentID={setDirectPaymentID}
        />
      )}
    </>
  );
}

const styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
