import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../styles.css';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import { ValidateEmail } from '../../Chatbot/Setupbot';

export const MailRepliesModal = ({
  show,
  setShow,
  mailApiCall,
  email,
  setEmail,
  reportName = 'Export Report',
}) => {
  const [success, setSuccess] = useState(false);
  //const [isLoading,setIsLoading] = useState(false);

  return (
    <div>
      <Modal
        show={show}
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="my-modal"
        contentClassName="mailCsv"
      >
        <Modal.Body style={{ paddingX: '20px' }}>
          {/* { isLoading ? <div className="d-flex flex-column align-items-center" style={{height:"100%",margin:"30%"}}>
        <NewLoader></NewLoader>
      </div> :<> */}
          <div className="d-flex justify-content-between ">
            <p style={{ fontWeight: '700', fontSize: '24px' }}>{reportName}</p>

            <div className="d-flex flex-row">
              <img
                src={images.CMClose}
                onClick={() => setShow(false)}
                style={{
                  cursor: 'pointer',
                  height: 22,
                  width: 22,
                  marginInlineEnd: 8,
                }}
              ></img>
            </div>
          </div>
          <hr style={{ marginTop: '-2px' }}></hr>
          {success ? (
            <>
              <div className="d-flex align-items-center flex-column" style={{ marginTop: '50px' }}>
                <img
                  src={images.CircleCheckGreen}
                  style={{
                    alignItems: 'center',
                    marginInlineEnd: 8,
                  }}
                ></img>
                <p style={{ fontWeight: '700', fontSize: '16px', marginTop: '20px' }}>
                  Report Shared Successfully
                </p>
                <p style={{ fontSize: '14px', color: 'grey', marginTop: '' }}>
                  Kindly check your email.
                </p>
              </div>

              <div style={{ marginLeft: '10px', marginTop: '30px' }}>
                <p style={{ fontSize: '12px', color: 'grey' }}>
                  <i>
                    Please Note:-Please anticipate a potential delay in the delivery of the email,
                    especially in the case of a substantial report(~approx 30mins). We kindly
                    request your patience during this period... If not received try again.
                  </i>
                </p>
                <CheerioButton
                  className="button"
                  borderStyle={{
                    height: '36px',
                    borderRadius: '9px',
                    width: '95%',
                    marginBlockStart: 12,
                    marginBlockEnd: 8,
                    marginBottom: '20px',
                  }}
                  textStyle={{ color: 'black', fontSize: 16, fontWeight: 600 }}
                  btnText={'Resend Email'}
                  backColor={'white'}
                  onclick={() => {
                    // if (templateName !== '') {
                    //   mailApiCall();
                    // } else if (userSpecific) {
                    //   mailUserSpecificCsvApiCall();
                    //   // setIsLoading(true);
                    // } else {
                    //   mailCsvApiCall();
                    //   //setIsLoading(true);
                    // }
                    mailApiCall();

                    setShow(false);
                    toast.success('Report Sent', { position: 'top-center' });
                  }}
                />
              </div>
            </>
          ) : (
            <div>
              <div class="form-group mt-4">
                <label
                  for="exampleFormControlInput1"
                  style={{ fontWeight: '700', fontSize: '14px', color: 'black' }}
                >
                  Email Id
                </label>
                <input
                  class="form-control shadow-none"
                  placeholder="john@cheerio.in"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label
                  style={{ color: 'grey', fontSize: '14px', fontWeight: '400', marginLeft: '10px' }}
                >
                  You will receive your report on this email
                </label>
              </div>
              <div style={{ marginLeft: '10px', marginTop: '10rem' }}>
                <CheerioButton
                  className="button"
                  borderStyle={{
                    height: '36px',
                    borderRadius: '9px',

                    marginBlockStart: 12,
                    marginBlockEnd: 8,
                    marginBottom: '20px',
                    border: 'none',
                  }}
                  id="SendReportButton"
                  textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
                  btnText={'Send Report'}
                  backColor={'#8E53F7'}
                  onclick={() => {
                    if (ValidateEmail(email)) {
                      mailApiCall();
                      setSuccess(true);
                    }
                  }}
                />
              </div>
            </div>
          )}
          {/* </>} */}
        </Modal.Body>
      </Modal>
    </div>
  );
};
