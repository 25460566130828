import React from 'react';
import colors from '../../Utils/colors';
import images from '../../Utils/images';

const PreviewCustomTemplate = ({ body, header, footerData, buttonData, CarouselData }) => {
  const formatText = (text, arr) => {
    let formattedText = text;

    formattedText = formattedText.replace(/\*(.*?)\*/g, '<b>$1</b>'); // Bold
    formattedText = formattedText.replace(/_(.*?)_/g, '<i>$1</i>'); // Italics
    formattedText = formattedText.replace(/~(.*?)~/g, '<s>$1</s>'); // Strike-through
    formattedText = formattedText.replace(/\n/g, '<br>');
    const regex = formattedText.match(/\{\{(\d+)\}\}/g) ?? [];
    regex.map((reg, index) => {
      formattedText = formattedText.replace(reg, function ($0) {
        //if (count === newArr.length) count = 0;

        return arr[index] ?? reg;
      });
    });
    return formattedText;
  };

  return (
    <>
      <div
        className="d-flex flex-column justify-content-start w-100 px-4 py-4 h-100"
        style={{ marginBlockStart: 20, overflow: 'auto', alignItems: CarouselData ? 'flex-start' : 'center' }}
      // style={{ position: 'relative', top: '30px', alignItems: CarouselData ? 'flex-start' : 'center' }}
      >
        <div
          className="d-flex flex-column justify-content-start align-items-center"
          style={{
            width: '70%',
            borderRadius: 10,
            padding: 16,
            backgroundColor: '#1F2C34',
            position: 'relative',
          }}
        >
          <img
            alt="WA_logo"
            src={images.CreatorWA}
            style={{
              height: 32,
              width: 32,
              objectFit: 'contain',
              position: 'absolute',
              top: -14,
              left: -14,
            }}
          ></img>
          {header.format !== 'TEXT' && header.format !== 'NONE' && (
            <div
              className=""
              style={{
                minHeight: '150px',
                height: '100%',
                width: '100%',
                backgroundColor: colors.borderwhite,
                borderRadius: 8,
              }}
            >
              {header.format === 'IMAGE' && (
                <img
                  src={header.example.header_handle[0]?.base64 || header.image_url}
                  style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                />
              )}
              {header.format === 'VIDEO' && (
                <>
                  <video
                    src={header.example.header_handle[0]?.base64}
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover',
                      // marginTop: 20,
                    }}
                  ></video>
                </>
              )}
              {header.format === 'DOCUMENT' && (
                <embed
                  src={header.example.header_handle[0]?.base64}
                  width="100%"
                  height="100%"
                  type="application/pdf"
                />
              )}
            </div>
          )}
          <>
            {header.format === 'TEXT' && (
              // <span style={{ padding: 0, margin: 0, paddingBlockStart: 10, fontSize: 14, fontWeight: 'bold', color: colors.white, width: '100%', wordWrap: 'break-word' }}>{headerText}</span>
              <span
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 'bold',
                  color: colors.white,
                  width: '100%',
                  wordWrap: 'break-word',
                }}
              >
                {formatText(header.text, header.example.header_text)}
              </span>
            )}

            <p
              style={{
                color: colors.white,

                width: '100%',
              }}
              dangerouslySetInnerHTML={{ __html: formatText(body?.text, body?.example?.body_text) }}
            />
            {footerData.text !== '' ? (
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.greys03,
                  width: '100%',
                  wordWrap: 'break-word',
                }}
              >
                {footerData.text}
              </p>
            ) : null}
          </>
        </div>

        {buttonData.buttons.map((button, index) => {
          if (buttonData.buttons.length > 3 && index > 2) {
            return null;
          }

          return (
            <div
              key={button.uuid}
              className="d-flex flex-column justify-content-start align-items-center"
              style={{
                width: '70%',
                borderRadius: 8,
                paddingBlock: 6,
                bottom: '4px',
                position: 'relative',
                backgroundColor: '#1F2C34',
                marginTop: 10,
                fontSize: 12,
                height: 30,
                paddingInline: 5
              }}
            >
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  fontWeight: 600,
                  color: colors.white,
                }}
              >
                {buttonData.buttons.length > 3 && index === 2 ? 'See Options' : button.text}
              </p>
            </div>
          );
        })}

        {/* carousel */}
        {CarouselData && (
          <>
            <div className='d-flex flex-row justify-content-start align-items-stretch my-2' style={{ width: 'fit-content', overflowX: 'auto' }}>
              {CarouselData.map((item, index) => {
                const cHeader = item?.components?.filter((e) => e?.type === 'HEADER');
                const cBody = item?.components?.filter((e) => e?.type === 'BODY');
                const cButton = item?.components?.filter((e) => e?.type === 'BUTTONS');
                return (
                  <>
                    <div className='me-2 d-flex flex-column justify-content-start' style={{ width: 250 }}>
                      <div
                        className="d-flex flex-column justify-content-start align-items-center"
                        style={{
                          width: '100%',
                          borderRadius: 10,
                          padding: 16,
                          backgroundColor: '#1F2C34',
                          flex: 1,
                          // whiteSpace:'pre-wrap',
                          // position: 'relative',
                        }}
                      >
                        <div
                          className=""
                          style={{
                            // minHeight: '100px',
                            height: '100px',
                            width: '100%',
                            backgroundColor: colors.borderwhite,
                            borderRadius: 8,
                          }}
                        >

                          {cHeader[0]?.example?.header_handle && (
                            <>
                              {cHeader[0]?.format === 'VIDEO' ? (
                                <>
                                  <video
                                    controls
                                    src={cHeader[0]?.example?.header_handle[0]?.base64}
                                    style={{ height: '100%', width: '100%', borderRadius: 8, objectFit: 'cover' }}
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={cHeader[0]?.example?.header_handle[0]?.base64}
                                    style={{ height: '100%', width: '100%', borderRadius: 8, objectFit: 'cover' }}
                                  />
                                </>
                              )}
                            </>)}
                          {/* )}  */}
                        </div>


                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            fontSize: 14,
                            fontWeight: 400,
                            color: colors.white,
                            width: '100%',
                            marginBlockStart: 8,
                          }}
                        >
                          {cBody[0]?.text}
                          {/* {BodyElement[0].text} */}
                        </p>
                      </div>
                      {cButton &&
                        cButton[0]?.buttons.map((item, index) => {
                          return (
                            <>
                              <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{
                                  width: '100%',
                                  borderRadius: 10,
                                  paddingBlock: 8,
                                  backgroundColor: '#1F2C34',
                                  marginBlock: 4,
                                }}
                                key={index}
                              >
                                <p
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: colors.white,
                                  }}
                                >
                                  {item.text}
                                </p>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </>
                )
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PreviewCustomTemplate;
